import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
const Acceptance = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div>
      <h2 className="mb-8 mt-8 text-[1.5rem] font-bold">Acceptance</h2>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Employee Notes:
      </h2>
      <textarea
        type="text"
        name=""
        
        placeholder="Enter employee notes"
        className={`lg:mb-5  ${
          dash === "/Dashboard/application"
            ? "lg:text-[1.1rem]"
            : "lg:text-[1.1rem]"
        } w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
      />
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        I declare that all the responses on this form are true and correct to
        the best of my knowledge and belief
      </h2>
      <div className="flex flex-col ml-5 mb-5 mt-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          I agree with the above statement
        </Checkbox>
      </div>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Employee Signature:
      </h2>
      <input
        type="text"
        name=""
        
        placeholder="Employee Signature"
        className={`lg:mb-5 ${
          dash === "/Dashboard/application"
            ? "lg:text-[1.1rem]"
            : "lg:text-[1.1rem]"
        } w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
      />
    </div>
  );
};

export default Acceptance;
