import React, {  useState } from "react";
import ErrorModal from "../../Modals/errorModal";
import { useNavigate } from "react-router-dom";
import logo from "../../../../imgs/dollar.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const RequestPage = ({ setRefetch, refetch }) => {
  const govAgencyReqData = useSelector((state) => state.govAgencyReqData);
  const data = govAgencyReqData.length > 0 ? govAgencyReqData[0] : [];
  const [status, setStatus] = useState(data.status);
  console.log(govAgencyReqData);
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [amount, setAmount] = useState(
    data.status === "requested" || data?.status === "mhsss_rejected"
      ? data.amount
      : ""
  );
  const [note, setNote] = useState(
    data.status === "requested" || data?.status === "mhsss_rejected"
      ? data.note
      : ""
  );
  const router = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const token = useSelector((state) => state.token);
console.log(setRefetch, refetch)
  const Request = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-requests/by-govt-agency`,
        {
          amount: amount,
          note: note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShow(true);
        // setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };

  const SendReminder = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-requests/notify-mhsss`,
        // null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShow(true);
        // setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };
  return (
    <div className="mt-1">
      <div className="w-full  relative sm:px-10 p-4">
        <h1
          className={`${
            status === "requested"
              ? "sm:mx-6 mx-2 text-blue-500 text-[1.1rem] font-semibold"
              : status === "mhsss_rejected"
              ? "sm:mx-6 mx-2 text-red-500 text-[1.1rem] font-semibold"
              : "hidden"
          }`}
        >
          {status === "requested"
            ? "Request Pending"
            : status === "mhsss_rejected"
            ? "Request Rejected"
            : ""}
        </h1>
        <h1 className="sm:mx-6 mx-2 text-[#535353] text-[0.93rem] font-semibold mt-1 sm:w-[70%]">
          {status === "requested"
            ? `Your request, submitted on ${moment(data?.createdAt).format(
                "MM/DD/YYYY hh:mm A"
              )}, is still pending with MHSSS. Below is your request detail`
            : status === "mhsss_rejected"
            ? `Your request, submitted on ${moment(data?.createdAt).format(
                "MM/DD/YYYY hh:mm A"
              )}, has been rejected by MHSSS. Here are the details of your request`
            : " Please provide the specific details needed to request money from MHSSS"}
        </h1>
        <div className="bg-[#F6F4F4] sm:m-6 m-2 rounded-md  py-2 border sm:w-[70%]">
          <div className="flex items-center px-5 mt-4 rounded-md flex-col gap-5 p-5">
            <div className="flex items-center w-full">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Amount <span className="text-red-600">*</span>
              </label>

              <div
                className={`flex lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 justify-between ${
                  status === "requested" || status === "mhsss_rejected"
                    ? "pointer-events-none"
                    : ""
                }`}
              >
                <input
                  type="number"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className=""
                />
                <p className="font-bold text-lg">$</p>
              </div>
            </div>

            <div className="flex items-center w-full">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Note <span className="text-red-600"></span>
              </label>
              <textarea
                type="text"
                placeholder="Note"
                className={`lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 mb-2 ${
                  status === "requested" || status === "mhsss_rejected"
                    ? "pointer-events-none"
                    : ""
                }`}
                value={note}
                rows={4}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            status === "mhsss_rejected"
              ? "sm:m-6 m-2 rounded-md  py-2 "
              : "hidden"
          }`}
        >
          <div className="grid grid-cols-12 gap-4 mt-2">
            <div className="sm:col-span-2 col-span-4 text-md font-medium leading-6 text-gray-600">
              Rejected On:
            </div>
            <div className="sm:col-span-10 col-span-8 text-md leading-6 font-semibold">
              {moment(data?.updatedAt).format("MM/DD/YYYY hh:mm A")}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mt-2">
            <div className="sm:col-span-2 col-span-4 text-md font-medium leading-6 text-gray-600">
              {status === "mhsss_rejected" ? "Rejection Note :" : ""}
            </div>
            <div className="sm:col-span-10 col-span-8 text-md leading-6 font-semibold">
              {data?.rejectReason}
            </div>
          </div>
        </div>

        <div className="flex items-center md:justify-start justify-center mb-5 sm:px-10">
          <button
            className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
            onClick={() => {
              if (status === "mhsss_rejected") {
                setStatus("");
                setNote("");
                setAmount("");
                return;
              }
              if (status === "requested") {
                SendReminder();
                return;
              }
              setShowConfirm(true);
            }}
          >
            {status === "requested"
              ? "Send Reminder"
              : status === "mhsss_rejected"
              ? "Generate New Request"
              : "Send Request"}
          </button>
        </div>
      </div>

      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to request money from MHSSS?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                setShowConfirm(false);
                Request();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2 text-[1rem]">
            {status === "requested" ? "Notification Sent" : "Request Sent"}
          </h1>
          <p className="text-center mt-[1rem] mb-5">
            {status === "requested"
              ? "Reminder has been successfully sent to MHSSS"
              : "Request has been successfully sent to MHSSS"}
          </p>
          <div className="flex gap-4 w-full] items-center justify-center">
            <button
              onClick={() => {
                setShow(false);
                router("/Merchant/dashboard");
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Okay
            </button>
          </div>
        </div>
      </div>

      {showErrModal && <ErrorModal err={err} setShow={setShowErrModal} />}
    </div>
  );
};

export default RequestPage;
