import AddGro from "../components/AddGro/addGro";
import Nav from "../components/Nav/nav";
import Vpopup from "../components/Popup/Vpopup";
import Vppopup from "../components/Popup/Vppopup";
import Whatsapp from "../Whatsapp/whatsapp";

const AddGroAdmin = () => {
  return (
    <div>
      <Nav />
      <AddGro />
      {/* <Popup /> */}
      <Vpopup />
      <Vppopup />
      <Whatsapp />
    </div>
  );
};

export default AddGroAdmin;
