import AddPensioner from "../components/AddPensionerByEmployee/addPensioner";
import Nav from "../components/Nav/nav";
import Vpopup from "../components/Popup/Vpopup";
import Vppopup from "../components/Popup/Vppopup";
import Popup from "../components/Popup/popup";
import Whatsapp from "../Whatsapp/whatsapp";
import PageHeader from "../components/pensionerVerification/pageHeader/pageHeader";
import { useSelector } from "react-redux";

const AddPensionerPage = () => {
  const verificationStart = useSelector((state) => state.verificationStart);
  return (
    <>
      {verificationStart ? (
        <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
          <PageHeader
            header={true}
            title={"Add Pensioner"}
            route={"/pensionerVerification/selectedProgram"}
          />

          <div className="flex justify-center mt-3 ">
            <div className={`sm:w-[95%] w-full bg-white`}>
              <AddPensioner />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Nav />
          <AddPensioner />
          <Popup />
          <Vpopup />
          <Vppopup />
          <Whatsapp />
        </div>
      )}
    </>
  );
};

export default AddPensionerPage;
