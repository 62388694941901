import Profile from "../components/events/eventListing/eventListing"
import Nav from "../components/Nav/nav";

const ProfPage = () => {
  return (
    <div>
      <Nav />
      <Profile />
    </div>
  );
};

export default ProfPage;
