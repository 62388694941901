import React, { useEffect, useState } from "react";
import PageHeader from "./pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import axios from "axios";

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  console.log(distributionCenter);
  const dataUser = useSelector((state) => state.dataUser);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [programs, setPrograms] = useState([]);

  // const programs = [
  //   {
  //     value: "child-protection",
  //     label: "Child Protection",
  //     icon: family1,
  //   },
  //   {
  //     value: "senior-citizen-Pension",
  //     label: "Senior Citizen Pension",
  //     icon: oldMan,
  //   },
  //   {
  //     value: "economical",
  //     label: "Economical",
  //     icon: saving,
  //   },
  //   { value: "medical", label: "Medical", icon: healthcare },

  //   {
  //     value: "learning-lab",
  //     label: "Learning Lab",
  //     icon: family1,
  //   },
  //   {
  //     value: "palm-geriatric-home",
  //     label: "Palm Geriatric Home",
  //     icon: family1,
  //   },
  //   {
  //     value: "plwd",
  //     label: "PLWD ",
  //     icon: family1,
  //   },
  //   {
  //     value: "women-empowerment",
  //     label: "Women Empowerment",
  //     icon: womenEmp,
  //   },
  // ];

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Select Program"}
        route={"/pensionerVerification/selectLocation"}
      />

      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div className={`flex items-center justify-between`}>
            <h3 className="font-medium">Location</h3>
            <p
              className="text-primary cursor-pointer font-medium"
              onClick={() => {
                router("/pensionerVerification/selectLocation");
              }}
            >
              Change
            </p>
          </div>
          <div className="bg-white p-3 rounded-md mt-1 mb-7">
            {distributionCenter?.coverage}
            {" "}

            {
              distributionCenter?.distributioncenter?.distributionlocation
                ?.distributionregion?.regionNo
            }
          </div>
          <div
            className={`grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 `}
          >
            {programs?.map((program, index) => (
              <div
                key={index}
                className={`flex flex-row items-center justify-start p-2  shadow-md rounded-md mb-3 border cursor-pointer ${
                  selectedProgram === program.name
                    ? "bg-primary text-white"
                    : "text-gray-600"
                }`}
                onClick={() => {
                  if (selectedProgram === program.name) {
                    setSelectedProgram(null);
                    return;
                  }
                  setSelectedProgram(program.name);
                  dispatch({ type: "SELECTED_PROGRAM", num: program.name });
                }}
              >
                <div
                  className={`flex items-center justify-center bg-white p-2 rounded-full`}
                >
                  <img src={program?.icon} alt="alt" className="w-5 h-5 " />
                </div>

                <h1 className={`font-semibold text-[0.9rem]  ml-2 capitalize`}>
                  {formatProgramNames(program?.name)}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            if (selectedProgram === null) {
              setErr("Please select a program");
              setShowErr(true);
              return;
            }
            router("/pensionerVerification/selectedProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectProgram;
