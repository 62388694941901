import { useSelector, useDispatch } from "react-redux";
import add from "../../imgs/addb.png";
import across from "../../imgs/across.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Checkbox, Switch } from "antd";
import axios from "axios";

const AddBenificiary = () => {
  const [color, setColor] = useState("#F7542E");
  const [dataWow, setDataWow] = useState([]);
  const [test, setTest] = useState(false);
  const [ownBen, setOwnBen] = useState(false);
  const [ownPen, setOwnPen] = useState("");
  const [meBen, setMeBen] = useState(false);
  const [meDefaultBen, setMeDefaultBen] = useState(false);
  const [show, setShow] = useState(false);
  const [dd, setDd] = useState(false);
  const [err, setErr] = useState("");
  console.log( color, ownBen, ownPen);
  const [allBens, setAllBens] = useState([]);
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const bicurrent = useSelector((state) => state.bicurrent);
  const token = useSelector((state) => state.token);
  const option = useSelector((state) => state.option);
  const dataBen = useSelector((state) => state.dataBen);
  const fromAwaited = useSelector((state) => state.fromAwaited);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (bicurrent > 1) {
      setColor("#1BA397");
    }
  }, [bicurrent]);
  const addHandler = (e) => {
    dispatch({
      type: "penId",
      num: `${e}`,
    });
    if (dash === "/Dashboard/BeneficiaryQuestions") {
      dispatch({ type: "SET", num: 1 });
      dispatch({ type: "BRSET", num: 0 });
    } else {
      dispatch({ type: "ISET", num: 10 });
    }

    // dispatch({ type: "BRSET", num: 0 });
    // dispatch({ type: "BISET", num: 0 });
    // dispatch({ type: "SET", num: 4 });
  };
  useEffect(() => {
    if (dash === "/") {
      setDataWow(dataBen);
    } else {
      setDataWow(dataBen.pensionPrograms);
    }
    // );
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [dataWow, allBens, meBen, meDefaultBen, dd]);

  const nextHandler = () => {
    if (allBens.length !== 0) {
      if (fromAwaited === "Ben") {
        dispatch({ type: "ISET", num: 18 });
      } else {
        dispatch({ type: "ISET", num: 17 });
      }
    }
  };

  useEffect(() => {
    let wow;
    if (dash === "/") {
      wow = token;
    }
    if (option !== "Merchant") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-pensioner`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setAllBens(data.data);

          const wow = data.data.filter((item) => {
            return item.relationship === "Me";
          });

          if (wow.length !== 0) {
            setMeBen(wow[0].isActive);
            setMeDefaultBen(wow[0].isDefault);
          } else {
            setMeBen(false);
          }
        })
        .catch(() => {});
    }
  }, [dd]);
  return (
    <div className=" flex justify-center">
      <div className="sm:w-[70%] w-[100%] mt-10">
        {}
        <div className="flex justify-center">
          {dataWow && (
            <div>
              {dataWow.length !== 0 && (
                <div>
                  {dataWow.map((item) => {
                    return (
                      <div>
                        <div className="mb-8 sm:w-[25rem] w-[18.5rem] ">
                          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2 sm:ml-0 ml-5">
                            {/* {item.name.charAt(0).toUpperCase() + item.name.slice(1)} */}
                            Senior Citizen Pension - 65+ Years
                          </h2>
                          <div
                            className=" text-white mt-3"
                            //   onClick={() => dispatch({ type: "SET", num: 4 })}
                          >
                            <div className="flex mt-5 items-center">
                              <div
                                className="cursor-pointer"
                                onClick={() => addHandler(item.id)}
                              >
                                <img
                                  className="scale-[0.7] sm:ml-0 sm:mr-0 ml-5 mr-4"
                                  src={add}
                                  alt=""
                                />
                              </div>

                              <div>
                                {}
                                {allBens && (
                                  <div>
                                    {allBens.length !== 0 &&
                                      allBens.map((item) => {
                                        return (
                                          <div
                                            className={`flex items-center mt-[0.5rem] justify-between pr-2 ml-5 sm:w-[22rem] w-[15.5rem] h-auto bg-[#E8E8E8] rounded-xl mb-5 pt-2 pb-2 ${
                                              item.relationship === "Me"
                                                ? "hidden"
                                                : ""
                                            }`}
                                          >
                                            {item.beneficiaryuser && (
                                              <div
                                                className="flex pl-3"
                                                onClick={() => setEdit(!edit)}
                                              >
                                                <div>
                                                  <img
                                                    className="w-[3rem] h-[3rem] rounded-full"
                                                    src={
                                                      item.beneficiaryuser
                                                        .beneficiaryperson.image
                                                    }
                                                    alt=""
                                                  />
                                                </div>

                                                <div className="ml-3">
                                                  <h2 className="font-bold text-[1rem] text-black">
                                                    {`${item.beneficiaryuser.beneficiaryperson.firstName} ${item.beneficiaryuser.beneficiaryperson.lastName}`}
                                                  </h2>
                                                  <p className="text-[0.8rem] text-black">
                                                    {item.relationship}
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                            <div className="flex items-center ">
                                              <Switch
                                                size="small"
                                                style={{
                                                  backgroundColor:
                                                    item.isDefault
                                                      ? "#57d38c"
                                                      : "#c3c6c9",
                                                }}
                                                checked={item.isDefault}
                                                onClick={(e) => {
                                                  console.log(e, "333333333");
                                                  axios
                                                    .post(
                                                      `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle?beneficiaryUserId=${item.beneficiaryuserId}`,
                                                      {
                                                        programId: `${item.pensionprogram.id}`,
                                                        isDefault: e,
                                                      },
                                                      {
                                                        headers: {
                                                          Authorization: `Bearer ${token}`,
                                                        },
                                                      }
                                                    )
                                                    .then((data) => {
                                                      console.log(data, "]]");
                                                      if (dd) {
                                                        setDd(false);
                                                      } else {
                                                        setDd(true);
                                                      }
                                                    })
                                                    .catch((err) => {});
                                                }}
                                              />
                                              <p className="text-[0.8rem] ml-1 text-primary">
                                                {item.isDefault
                                                  ? "Default"
                                                  : ""}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="ml-2 bg-[#E8E8E8] sm:w-[25rem] w-[18.5rem] pl-3 pt-3 rounded-lg flex flex-col">
                    <Checkbox
                      // checked={copArr[index].isCurrent}
                      onChange={(e) => {
                        setOwnBen(e.target.checked);

                        setOwnPen(dataWow[0].id);
                        axios
                          .post(
                            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/toggle-self`,
                            {
                              programId: dataWow[0].id,
                              isActive: e.target.checked,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                          .then((data) => {
                            setMeBen(data.data.beneficiaryInfo.isActive);
                            setMeDefaultBen(
                              data.data.beneficiaryInfo.isDefault
                            );
                            if (dd) {
                              setDd(false);
                            } else {
                              setDd(true);
                            }
                            axios
                              .post(
                                `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle-self`,
                                {
                                  programId: dataWow[0].id,
                                  isDefault:
                                    data.data.beneficiaryInfo.isDefault,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                }
                              )
                              .then((data) => {
                                setMeDefaultBen(
                                  data.data.beneficiaryInfo.isDefault
                                );
                                if (dd) {
                                  setDd(false);
                                } else {
                                  setDd(true);
                                }
                              })
                              .catch((err) => {});
                          })
                          .catch((err) => {
                            setShow(true);
                            setErr(err.response.data.message);
                          });
                      }}
                      checked={meBen}
                      className={"checkk"}
                    >
                      I am my own Beneficiary
                    </Checkbox>
                    <Checkbox
                      // checked={copArr[index].isCurrent}
                      // onChange={(e) => {

                      // }}
                      onClick={(e) => {
                        if (meBen) {
                          axios
                            .post(
                              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle-self`,
                              {
                                programId: dataWow[0].id,
                                isDefault: e.target.checked,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .then((data) => {
                              setMeDefaultBen(
                                data.data.beneficiaryInfo.isDefault
                              );
                              if (dd) {
                                setDd(false);
                              } else {
                                setDd(true);
                              }
                            })
                            .catch((err) => {});
                        } else {
                          setShow(true);
                          setErr(
                            "Please Mark ' I am my own Beneficiary first'"
                          );
                        }
                      }}
                      checked={meDefaultBen}
                      className={"checkk"}
                    >
                      Default
                    </Checkbox>
                  </div>
                  <div className="flex mt-6 justify-end">
                    <button
                      type="submit"
                      onClick={nextHandler}
                      className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={() => setShow(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
        {/* <div
          className={` ${
            dash === "/Dashboard/BeneficiaryQuestions" ? "hidden" : ""
          } flex sm:justify-end justify-center`}
        >
          <button
            className={`lg:text-[1.1rem] bg-[${color}] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            onClick={skipHandler}
          >
            {word}
          </button>
        </div> */}
        {edit ? (
          <div>
            <h1></h1>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddBenificiary;
