import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import done from "../../../imgs/done.svg";
const SuccessModal = ({ setShow, err, setValue, verify, data }) => {
  const sapmoney = useSelector((state) => state.sapmoney);
  const depositMoney = useSelector(
    (state) => state.pensionerSenderTypeVerification
  );
  const router = useNavigate();
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[20rem] flex items-center p-6 bg-white flex-col">
        <img src={done} alt="done" className="w-20 h-20" />

        <p className="text-center  mb-5 mt-4 text-[1rem] font-semibold px-8">
          {err}
        </p>
        <button
          onClick={() => {
            // View Requests  of pensioner that is requested by child on sap money side
            if (verify === "reqListing") {
              router("/SapMoney/Merchant/Pensioner/Request/Listing");
              return;
            }

            // deposit money to pensioner account
            if (verify === "MerchantResponse" && depositMoney) {
              router("/SapMoney/Parent/Respond/Child", {
                state: { data: data, merchantSideChildReq: true },
              });
              return;
            }
            if (verify === "Verify") {
              //   router(`/Merchant/PensionerProfile/Pay`);
              if (sapmoney) {
                router("/Merchant/SapMoney/Pay");
              } else {
                router(`/Merchant/PensionerProfile/Pay`);
              }
            }
            if (setValue) {
              setValue("");
            }
            setShow(false);
          }}
          className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[65%]"
        >
          {verify === "reqListing" ? "View Requests" : "Pay Now"}
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
