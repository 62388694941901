import React, { useState, useEffect, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleFilled, DeleteFilled } from "@ant-design/icons";
import check from "../../../../imgs/add.png";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import pro from "../../../../imgs/pro.png";
import img from "../../../../imgs/impLogo.png";
import trash from "../../../../imgs/trash.png";

import axios from "axios";
import FaceVerificatioNModal from "../../../../utlis/FaceVerficationLoaderModal";
import { detectObjectsInImage } from "../../../../utlis/modelLoader";
import { message } from "antd";

let file;
const Main = ({ data, setShowForm }) => {
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [display, setDisplay] = useState(pro);
  const [showConfirm, setShowConfirm] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [relation, setRelation] = useState("");
  const [purpose, setPurpose] = useState("");
  const [reason, setReason] = useState("");
  const [gid, setGid] = useState("");
  // const [gidImage, setGidImage] = useState(null);
  const [gidFrontImage, setGidFrontImage] = useState(null);
  const [gidBackImage, setGidBackImage] = useState(null);
  const [image, setImage] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Relation");
  const faceApi = useSelector((state) => state.faceApi);
  // const [notHuman, setNotHuman] = useState(false);
  // const [isPurposeOpen, setIsPurposeOpen] = useState(false);
  // const [selectedPurpose, setSelectedPurpose] = useState("Purpose");
  const [gidDisplay, setGidDisplay] = useState(null);
  const [gidBackDisplay, setGidBackDisplay] = useState(null);
  const token = useSelector((state) => state.token);
  console.log("token", data);

  const qrRef = useRef();

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector("canvas");
    if (canvas) {
      const dataUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "qr-code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    dispatch({ type: "FACE_API", num: false });
  }, []);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setFirstName(data?.firstName);
    if (data?.image) {
      setDisplay(data?.image);
    }
    setMiddleName(data?.middleName);
    // setDisplay(data?.image);
    setLastName(data?.lastName);
    setEmail(data?.email);
    setPhone(data?.phone);
    setRelation(data?.relation);
    setSelectedOption(data?.relation);
    setPurpose(data?.purpose);
    // setSelectedPurpose(data?.purpose);
    setGid(data?.gid);
    setAmountPaid(data?.amountPaid);
    setReason(data?.reason);
    setGidBackDisplay(data?.gidBackImage);
    setGidDisplay(data?.gidFrontImage);
    setGidBackImage(data?.gidBackImage);
    setGidFrontImage(data?.gidFrontImage);
    setDateOfBirth(data?.dateOfBirth);
    setImage(data?.image);
  }, []);

  const handleOptionClick = (option, type) => {
    if (type === "purpose") {
      // setSelectedPurpose(option.label);
      // setIsPurposeOpen(false);
      setPurpose(option.value);
    } else {
      setSelectedOption(option.label);
      setIsOpen(false);
      setRelation(option.value);
    }
  };

  const FaceError = () => {
    messageApi.open({
      // type: 'success',
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="mage" width="50" height="50" />
          <h3>
            Please upload your profile image again; it's not verified as a human
            face.
          </h3>
        </div>
      ),
      duration: 6,
    });
  };

  const options = [
    // { label: "Options", value: "" },
    { label: "Son", value: "son" },
    { label: "Daughter", value: "daughter" },
    { label: "Grandson", value: "grandson" },
    { label: "Granddaughter", value: "granddaughter" },
    { label: "Father", value: "father" },
    { label: "Mother", value: "mother" },
    { label: "Grandfather", value: "grandfather" },
    { label: "Grandmother", value: "grandmother" },
    { label: "Brother", value: "brother" },
    { label: "Sister", value: "sister" },
    { label: "Uncle", value: "uncle" },
    { label: "Aunt", value: "aunt" },
    { label: "Cousin", value: "cousin" },
    { label: "Nephew", value: "nephew" },
    { label: "Niece", value: "niece" },
  ];

  const pay = () => {
    const body = {
      relation,
      image,
      dateOfBirth,
      gid,
      gidFrontImage,
      gidBackImage,
    };

    console.log(body);
    // return;

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${data?.id}/update-via-link/for-child`,
        body
      )
      .then((data) => {
        setShowConfirm(true);
        // setRefetch(!refetch);
        setShowForm(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShowError(true);
      });
  };

  const imageHandler = async (e) => {
    const file = e.target.files[0];

    if (file) {
      dispatch({ type: "FACE_API", num: true });
      const detectedObjects = await detectObjectsInImage(file);

      const hasHuman = detectedObjects.some(
        (object) => object.class === "person"
      );
      dispatch({ type: "FACE_API", num: false });
      if (hasHuman) {
        // setNotHuman(false);
        imageUploder(e);
        console.log("The image contains a human.");
      } else {
        // setNotHuman(true);
        FaceError();
        console.log("The image does not contain a human.");
      }
      e.target.value = null;
    }
  };

  const imageUploder = async (e, type, side) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    // type !== "gid" ? setDisplay(base641) : setGidDisplay;
    if (type !== "gid") {
      setDisplay(base641);
    } else if (type === "gid") {
      side === "front" ? setGidDisplay(base641) : setGidBackDisplay(base641);
    }

    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

    const api = type === "gid" ? "profile" : "profile";

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file?fileType=${api}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${tok}`,
      },
    })
      .then((data) => {
        if (type === "gid") {
          side === "front"
            ? setGidFrontImage(data?.data?.url)
            : setGidBackImage(data?.data?.url);
        } else {
          setImage(data?.data?.url);
        }
        // type === "gid"
        //   ? setGidImage(data?.data?.url)
        //   : setImage(data?.data?.url);
      })
      .catch((err) => {});
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const validateEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsValid(validateEmail(value));
  };

  return (
    <div className="sm:px-24">
      {contextHolder}
      <div className="bg-primary sm:px-10 px-3 py-4 sm:m-8 text-center text-white text-[1.1rem]">
        Child Profile Form
      </div>
      {/* Upper Div */}
      <div className="bg-white sm:m-8 rounded-md sm:px-10 px-3 py-8 relative mt-12">
        {/* Image Upload */}
        <div className="absolute rounded-full bg-[#D9EFED] w-28 h-28 p-2 z-10 -top-15  left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            className={`flex items-center gap-4 ${
              user !== "pensioner" ? "" : ""
            }`}
          >
            <label className="cursor-pointer relative">
              <div className="relative w-[6rem] h-[6rem] overflow-hidden border-2 border-white rounded-full mb-4">
                <img
                  className="object-cover w-[6rem] h-[6rem]"
                  alt=""
                  src={display}
                />
              </div>
              <div className="bg-primary rounded-full absolute bottom-4 right-2">
                <img src={check} className="lg:w-7 w-5" alt="" />
              </div>
              <input
                type="file"
                className="hidden"
                accept="image/png, image/gif, image/jpeg"
                onChange={imageHandler}
              />
            </label>
          </div>
        </div>

        {/* email and phoen no */}

        <div className="flex justify-center items-center px-3 py-3 mt-4 rounded-md bg-[#F6F4F4] mt-[5rem] pointer-events-none">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                Phone Number <span className="text-red-600"></span>
              </label>
              {/* <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                international
                onChange={(phone) => setPhone(phone)}
                countryCallingCodeEditable={false}
                className="w-full bg-gray-100"
              /> */}
              <input
                type="text"
                placeholder="Phone Number"
                className={`lg:text-[1.1rem] w-[16rem] bg-white border-[1px] rounded-[0.3rem] p-2 ${
                  !isValid ? "border-red-500" : ""
                }`}
                value={phone}
              />
            </div>
            <div className="flex flex-col items-start w-full gap-1">
              <div className="flex items-center w-full gap-1 mt-2">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[4rem]">
                  Email <span className="text-red-600"></span>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className={`lg:text-[1.1rem] w-[16rem] bg-white border-[1px] rounded-[0.3rem] p-2 ${
                    !isValid ? "border-red-500" : ""
                  }`}
                  value={email}
                  onChange={handleChange}
                />
              </div>
              {!isValid && email && (
                <p className="text-red-500 text-sm mt-1 ml-[4rem]">
                  Invalid email format
                </p>
              )}
            </div>
          </div>
        </div>

        {/* firstName */}
        <div className="flex justify-center items-center px-3 py-3 mt-4 rounded-md">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                First Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="lg:text-[1.1rem] w-[20rem] bg-gray-100 border-[1px] rounded-[0.3rem] p-2 border-none pointer-events-none capitalize"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Middle Name <span className="text-red-600"></span>
              </label>
              <input
                type="text"
                placeholder="Middle Name"
                className="lg:text-[1.1rem] w-[20rem] bg-gray-100 border-[1px] rounded-[0.3rem] p-2 border-none pointer-events-none capitalize"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Last Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className="lg:text-[1.1rem] w-[20rem] bg-gray-100 border-[1px] rounded-[0.3rem] p-2 border-none pointer-events-none capitalize"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Date of Birth <span className="text-red-600"></span>
              </label>
              {/* <DatePicker
                className="w-[15rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                placeholder="Select Date of Birth"
              /> */}
              <input
                value={dateOfBirth}
                type="date"
                placeholder="Date of Birth"
                className="lg:text-[1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 text-gray-600"
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3 ">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Relation <span className="text-red-600"></span>
              </label>
              <div className="relative inline-block w-[20rem]">
                <div
                  className="bg-white border border-gray-300 rounded px-4 py-2 cursor-pointer text-gray-500 flex justify-between items-center"
                  onClick={toggleSelect}
                >
                  {selectedOption}
                  <svg
                    className="w-4 h-4 inline ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                    />
                  </svg>
                </div>
                {isOpen && (
                  <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-b shadow-md h-32 overflow-y-auto z-10">
                    {options.map((option) => (
                      <>
                        <div
                          key={option.value}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.label}
                        </div>
                        <hr className="text-gray-400" />
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                GID #<span className="text-red-600"></span>
              </label>
              <input
                type="text"
                placeholder="GID"
                className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                value={gid}
                onChange={(e) => setGid(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* GID AND GID IMAGE */}
        <div className="flex justify-center items-center px-3 py-3  rounded-md">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center flex-col w-full gap-3">
              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                  GID Front Image <span className="text-red-600"></span>
                </label>

                <label className={`cursor-pointer relative w-[20rem]`}>
                  <div className="lg:mb-5 lg:text-[1.1rem]  bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                    <div>
                      <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                    </div>
                    <p className="text-[#9CA6C2] ml-2">Upload Front Image</p>
                  </div>
                  <input
                    type="file"
                    className="hidden w-[100%] cursor-poniter"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => imageUploder(e, "gid", "front")}
                  />
                </label>
              </div>
              <div className="w-full">
                <label
                  className={`text-gray-700 text-[0.9rem] font-semibold w-full ${
                    gidDisplay ? "" : "hidden"
                  }`}
                >
                  GID Front <span className="text-red-600"></span>
                </label>
                <div
                  className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                    gidDisplay ? "" : "hidden"
                  }`}
                >
                  <img
                    onClick={() => {
                      // setIShow(true);
                    }}
                    className="w-[25rem] h-[13rem]"
                    src={gidDisplay}
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {
                      setGidDisplay(null);
                      setGidFrontImage(null);
                    }}
                  >
                    <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center flex-col w-full gap-3">
              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                  GID Back Image <span className="text-red-600"></span>
                </label>

                <label className={`cursor-pointer relative w-[20rem]`}>
                  <div className="lg:mb-5 lg:text-[1.1rem]  bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                    <div>
                      <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                    </div>
                    <p className="text-[#9CA6C2] ml-2">Upload Back Image</p>
                  </div>
                  <input
                    type="file"
                    className="hidden w-[100%] cursor-poniter"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => imageUploder(e, "gid", "back")}
                  />
                </label>
              </div>
              <div className="w-full">
                <label
                  className={`text-gray-700 text-[0.9rem] font-semibold w-full ${
                    gidBackDisplay ? "" : "hidden"
                  }`}
                >
                  GID Back <span className="text-red-600"></span>
                </label>
                <div
                  className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                    gidBackDisplay ? "" : "hidden"
                  }`}
                >
                  <img
                    onClick={() => {
                      // setIShow(true);
                    }}
                    className="w-[25rem] h-[13rem]"
                    src={gidBackDisplay}
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {
                      setGidBackDisplay(null);
                      setGidBackImage(null);
                    }}
                  >
                    <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 justify-between"></div>

        <div className="flex items-center px-5 mt-4 rounded-md flex-col gap-5 pointer-events-none">
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
              Amount <span className="text-red-600"></span>
            </label>

            <div className="flex lg:text-[1.1rem] w-[20rem] bg-gray-100 border-[1px] rounded-[0.3rem] p-2 justify-between border-none">
              <input
                value={amountPaid}
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => setAmountPaid(e.target.value)}
                className="bg-gray-100"
              />
              <p className="font-bold text-lg">$</p>
            </div>
          </div>

          <div className="flex items-center w-full ">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
              Purpose <span className="text-red-600"></span>
            </label>
            <input
              type="text"
              placeholder="Purpose"
              className="lg:text-[1.1rem] w-[20rem] bg-gray-100 border-[1px] rounded-[0.3rem] p-2 border-none pointer-events-none capitalize"
              value={purpose}
            />
          </div>
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
              Reason <span className="text-red-600"></span>
            </label>
            <textarea
              type="text"
              placeholder="Reason"
              className="lg:text-[1.1rem] w-[20rem] bg-gray-100 border-[1px] rounded-[0.3rem] p-2 mb-4 border-none"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center  justify-center mb-5 sm:px-10 mt-5 flex-col">
          <button
            onClick={() => {
              if (gidFrontImage === null || gidBackImage === null) {
                setError("Please upload GID front and back image");
                setShowError(true);
                return;
              }

              if (gid === "") {
                setError("Please enter GID number");
                setShowError(true);
                return;
              }

              if (relation === "") {
                setError("Please select relation");
                setShowError(true);
                return;
              }

              if (image === null) {
                setError("Please upload image");
                setShowError(true);
                return;
              }

              if (dateOfBirth === null) {
                setError("Please enter date of birth");
                setShowError(true);
                return;
              }

              pay();

              // setShowConfirm(true);
            }}
            className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
          >
            Generate QR Code
          </button>
          <p className="font-semibold mt-3">
            You need to represent this QR code
          </p>
          <p className="font-semibold">to merchant to collect money</p>
        </div>
      </div>

      {/* Below div */}

      {/* Confirmation Modal */}
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[35rem]  pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <p
            className="absolute top-3 right-4 font-semibold text-lg cursor-pointer"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            X
          </p>
          <div ref={qrRef} className="mt-4">
            <QRCodeCanvas value={`${data?.paymentId}::quickpay`} size={180} />
          </div>
          <div className="flex gap-4 w-[90%] items-center justify-center mt-5 mb-2">
            <button
              onClick={() => {
                downloadQRCode();
              }}
              className="w-[13rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Download QR Code
            </button>
          </div>

          <p className="text-center text-gray-400 font-semibold">OR</p>
          <h2 className="mt-1 font-semibold text-[1rem] mb-2">Payment ID</h2>
          <input
            type="text"
            className={`lg:text-[0.85rem] w-[18rem] text-center bg-gray-100 border-[1px] rounded-[0.3rem] p-2 pointer-events-none`}
            value={data?.paymentId}
          />
          <p className="text-center mt-2 sm:w-[70%] w-[85%]">
            You can also show this ID to the merchant if they cannot scan your
            QR code
          </p>
          <p className="sm:text-center text-center text-[#EF4D5C]  inline-block align-text-bottom w-full mt-3" >
            Please wait at the merchant's location until the payment is
            complete. You will be notified upon successful processing or
            rejection. A receipt will be sent to you afterward.
          </p>
        </div>
      </div>

      {/* recipt Modal */}

      {faceApi === true && <FaceVerificatioNModal />}
      {showError && <ErrorModal setShow={setShowError} err={error} />}
    </div>
  );
};

export default Main;
