import React, { useEffect, useState } from "react";
import across from "../../../imgs/across.png";
import logo from "../../../imgs/logo12.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileDiv from "../profileDiv/profileDiv";
import ApproveModal from "../modal/approveModal";
import RejectModal from "../modal/rejectModal";
const SelectLocation = () => {
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);
  const loc = useLocation();
  const fromProfile = loc?.state?.fromProfile;
  const pensionerData = loc?.state?.pensionerData;
  const veriPriority = useSelector((state) => state.veriPriority);
  const dataUser = useSelector((state) => state.dataUser);
  const [verify, setVerify] = useState(false);
  const [show, setShow] = useState(false);
  const [showVer, setShowVer] = useState(false);
  const [err, setErr] = useState("");
  const [reject, setReject] = useState(false);
  const [state, setState] = useState(false);
  const [note, setNote] = useState("");
  const [ip, setIp] = useState("");
  const [location, setLocation] = useState("");
  const [proof, setProof] = useState("");
  const [refetch, setRefetch] = useState(false);
  const router = useNavigate();
  console.log("TestPrior", veriPriority);
  useEffect(() => {
    if (veriPriority?.proofUrl) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
          {
            fileNames: [veriPriority?.proofUrl],
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setProof(data.data[0].url);
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
        console.log("IP", response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation(`${latitude}, ${longitude}`);
            console.log("Loc", `${latitude}, ${longitude}`);
          },
          (error) => {
            console.error("Error fetching location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchIp();
    fetchLocation();
  }, []);
  const update = () => {
    let body;
    if (state === "approved") {
      body = {
        priorityRequest: {
          status: "approved",
        },
        consumerInfo: {
          ip: ip,
          location: location,
        },
      };
    } else if (state === "rejected") {
      if (note === "") {
        setErr("Please enter Reason...");
        setShow(true);
        return;
      } else {
        body = {
          priorityRequest: {
            status: "rejected",
            reason: note,
          },
          consumerInfo: {
            ip: ip,
            location: location,
          },
        };
      }
    }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/priority-request/${veriPriority?.id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        if (state === "approved") {
          setVerify(true);
        } else {
          router("/pensionerVerification/PriorityRequests");
        }
        setReject(false);
        setShowVer(false);
      })
      .catch(() => {
        // setErr(err.response.data.message);
        // setShow(true);
      });
  };
  return (
    <div>
      <div className="flex justify-center bg-white">
        <div
          className={`rounded-sm md:mx-20 mx-2 m-5 md:p-6 p-2 md:w-[90%] w-full`}
        >
          <ProfileDiv
            data={fromProfile ? pensionerData : veriPriority?.pensionerUser}
            refetch={refetch}
            setRefetch={setRefetch}
            check={fromProfile ? "fromProfile" : "prior"}
          />
          <div className="w-full mt-4 bg-[#F5F5F5] md:pl-6 md:pr-6 pr-3 pl-3 p-8">
            <div
              className={`bg-white p-3 flex items-center justify-between w-full`}
            >
              <h2 className="font-medium">Priority category</h2>
              <h2 className="text-red-600 font-semibold capitalize">
                {veriPriority?.priorityCategory}
              </h2>
            </div>
            <div className="flex  mt-5 md:px-6 md:gap-10 gap-4 md:flex-row flex-col">
              <div className="md:w-1/5 w-full md:ml-12">
                <img
                  src={proof}
                  alt=""
                  className="sm:h-56 md:w-48 md:h-52 h-60 w-full"
                />
              </div>

              <div className="flex flex-col md:w-3/5 s:w-[80%] w-full">
                <label className="font-semibold text-gray-700 text-[0.9rem] mb-1">
                  Note
                </label>
                <textarea
                  rows={6}
                  value={veriPriority?.notes}
                  readOnly
                  className="bg-white p-2"
                />
              </div>
            </div>
            {user === "employee" &&
            !privilege.includes("Approve priority") ? null : (
              <div
                className={`${
                  veriPriority?.status === "approved"
                    ? "hidden"
                    : "flex justify-center mt-12 gap-8"
                }`}
              >
                <button
                  type="submit"
                  className="ml-5 cursor-pointer rounded-sm pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
                  onClick={() => {
                    // setVerify(true);
                    setShowVer(true);
                    setState("approved");
                  }}
                >
                  APPROVE
                </button>
                <button
                  className="cursor-pointer rounded-sm pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[12rem]"
                  onClick={() => {
                    setReject(true);
                    setState("rejected");
                  }}
                >
                  REJECT
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {verify && (
        <ApproveModal
          setVerify={setVerify}
          text={
            "The pensioner has been successfully approved for priority status"
          }
          title={"APPROVED"}
        />
      )}

      {reject && (
        <RejectModal
          setShow={setReject}
          title={"REJECTED"}
          text={
            "The pensioner’s application for priority treatment has been denied. Please ensure to communicate this decision to the pensioner and provide any necessary assistance or guidance for further actions they may need to take"
          }
          setNote={setNote}
          note={note}
          func={update}
        />
      )}

      <div
        className={`${
          showVer ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div className="w-[16] h-[16]" onClick={() => setShowVer(false)}>
            <img src={logo} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-5 ">
            Are you sure you want to Approve Priority Request?
          </p>
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => setShowVer(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              No
            </button>
            <button
              onClick={update}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[9999] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
