import AddGroE from "../components/AddGroEmp/addGroEmp";
import Nav from "../components/Nav/nav";
import Vpopup from "../components/Popup/Vpopup";
import Vppopup from "../components/Popup/Vppopup";
import Whatsapp from "../Whatsapp/whatsapp";

const AddGroEmp = () => {
  return (
    <div>
      <Nav />
      <AddGroE />
      {/* <Popup /> */}
      <Vpopup />
      <Vppopup />
      <Whatsapp />
    </div>
  );
};

export default AddGroEmp;
