import React from 'react'
import Ppayment from "../components/MHSSS Accounting/pensionerFinancials/PensionerPdetails/paymentIndex"

const paymentM = () => {
  return (
    <div>
     <Ppayment/>
    </div>
  )
}

export default paymentM