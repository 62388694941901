import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import GroEaccount from "../components/GeneralRegisterOfficer/groadmin/Edit employee/Eaccount"

const GroEProf = () => {
  return (
    <div>
      <Nav />
     <GroEaccount/>
     <Whatsapp/>
    </div>
  )
}

export default GroEProf