import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import across from "../../../imgs/across.png";
import gallary from "../../../imgs/picture.png";
import device from "../../../imgs/sign.png";
import codeHandler from "../../Register/codeHandler/codehandle";
import { PlusCircleFilled } from "@ant-design/icons";
import { Checkbox } from "antd";
let file;
const NinthStep = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [verify, setVerify] = useState(false);
  const [terms, setTerms] = useState(false);
  const [sigD, setSigD] = useState(false);
  const [pop, setPop] = useState(false);
  const [err, setErr] = useState("");
  const [check, setCheck] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const nextHandler = (e) => {
    e.preventDefault();

    if (!check) {
      setErr("Please accept the terms and conditions");
      setShow(true);
      return;
    }

    if (!imageUrl) {
      setErr("Please upload your signature");
      setShow(true);
      return;
    }

    let data = {
      signature: imageUrl,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-consent`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch({ type: "IINCREMENT" });
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  const imageHandler = async (e) => {
    file = e.target.files[0];
    setSigD(false);
    const base641 = await convertToBase64(file);
    setImage(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    setPop(false);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantIdentificationDocument`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setImageUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setImage(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setImageUrl(data.data.url);
      })
      .catch(() => {});
  };
  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }
  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }
  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setImage("");
      })
      .catch(function () {});
  }
  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="mt-5 p-5 mx-auto max-w-screen-sm">
      <div className="flex flex-col items-center bg-slate-50 p-10 rounded-md">
        <div className="mb-4 flex flex-col ">
          <h1 className="text-[1.2rem] font-semibold text-center mb-4">
            Data Confirmation
          </h1>
          <Checkbox
            checked={check}
            onChange={(e) => {
              if (verify === false) {
                setErr("Please click and view terms and conditions.");
                setShow(true);
              } else setCheck(e.target.checked);
            }}
            className={`font-semibold text-[1rem] text-gray-900 mb-2 cursor-pointer checkamount
            `}
          >
            I accept these terms and conditions
          </Checkbox>
          <p
            onClick={() => {
              setTerms(true);
            }}
            className="text-primary font-semibold cursor-pointer"
          >
            Click here to view the terms and conditions.
          </p>
          <p className="text-gray-400 mt-2 font-semibold">
            Thank you for providing your information.Please provide your
            signature below.
          </p>
          <p className="text-gray-800 font-semibold mt-3 text-lg">Signature</p>
        </div>
        <label
          onClick={() => setPop(true)}
          htmlFor="special-input"
          className={` cursor-pointer relative`}
        >
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
            </div>
            <p className="text-[#9CA6C2] ml-2">Upload Signature</p>
          </div>
          {/* <input
            type="file"
            className="hidden w-[100%] cursor-poniter"
            id="special-input"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler}
          /> */}
        </label>
        <div className="flex flex-col items-center">
          <div className="w-[25rem] h-[13rem]">
            {image && (
              <img
                className={`w-[25rem] h-[13rem]`}
                src={image}
                alt="signature"
              />
            )}
          </div>
        </div>
        {sigD && (
          <div
            className="flex gap-5 sm:w-[23rem] w-[18rem]"
            style={{
              padding: "10px",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            <button
              type="button"
              onClick={() => {
                GetWhitSigPic();
              }}
              className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
            >
              Open Pad
            </button>
            <button
              type="button"
              onClick={() => {
                ConfirmSign();
              }}
              className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
            >
              Get Sign
            </button>
            <button
              type="button"
              onClick={() => {
                ReSign();
              }}
              className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
            >
              Reset
            </button>
          </div>
        )}
        {sigD && (
          <div
            className="flex gap-5 sm:w-[23rem] w-[18rem]"
            style={{
              padding: "10px",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            <p
              onClick={downloadFile}
              className="text-primary hover:underline cursor-pointer font-semibold"
            >
              Download SDK
            </p>
          </div>
        )}
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={nextHandler}
          className="text-lg bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8"
        >
          Submit
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          terms ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[24rem] pb-6 flex items-center p-3 bg-white flex-col">
          {/* <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)} 
          >
            <img src={logo} alt="" />
          </div> */}
          <div className="w-full flex flex-col items-center justify-center">
            <h1 className="w-full text-center text-lg font-semibold mt-3">
              Confirmation
            </h1>
            <p className="mt-[0.2rem] mb-1 w-full p-2 text-justify px-2 leading-6">
              I, agree and understand that by signing the Electronic Signature
              Acknowledgment and Consent Form, that all electronic signatures
              are the legal equivalent of my manual/handwritten signature and I
              consent to be legally bound to this agreement. I further agree my
              signature on this document is as valid as if I signed the document
              in writing. This is to be used in conjunction with the use of
              electronic signatures on all forms regarding any and all future
              documentation with a signature requirement, should I elect to have
              signed electronically. Under penalty of perjury, I here with
              affirm that my electronic signature, and all future electronic
              signatures, were signed by myself with full knowledge and consent
              and am legally bound to these terms and conditions.
            </p>
          </div>
          <button
            onClick={() => {
              setVerify(true);
              setTerms(false);
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[50%] flex items-center justify-between mt-10 mb-10">
            {/* <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            /> */}
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={imageHandler}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NinthStep;
