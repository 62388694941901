import BeneficiaryI from "../components/Beneficiary/BeneficiaryI";
import BenificiaryR from "../components/Beneficiary/BeneficiaryR";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import Whatsapp from "../Whatsapp/whatsapp";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddBenificiary from "../components/Beneficiary/AddBenificiary";
import { useEffect, useState } from "react";
let Show = [AddBenificiary, BenificiaryR, BeneficiaryI];
const BeneficiaryPage = () => {
  const router = useNavigate();
  const wowt = useSelector((state) => state.wowt);
  const [test, setTest] = useState(false);
  const location = useLocation();
  const dataUser = useSelector((state) => state.dataUser);
  const user = dataUser.data.user.roles[0];
  const dash = location.pathname;
  const dispatch = useDispatch();
  const Dis = Show[wowt];
  console.log(wowt, "wowt");

  useEffect(() => {
    if (dash === "/EDashboard/BeneficiaryQuestions") {
      Show = [BenificiaryR, BeneficiaryI];
    } else {
      Show = [BenificiaryR, BeneficiaryI];
    }
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [Show]);
  return (
    <div>
      <Nav />
      <div className="relative flex justify-start sm:pl-[9rem] pl-[2rem] items-center text-[1.8rem] font-bold sm:mb-[3rem] mb-[2rem] ">
        <div
          onClick={() => {
            dispatch({ type: "SET", num: 0 });
            dispatch({ type: "BRSET", num: 0 });
            dispatch({ type: "BISET", num: 0 });
            if (user === "employee") router("/EDashboard/Pensioner_HistoryP");
            else if (user === "superadmin")
              router("/superAdminDashboard/Pensioner_HistoryP");
            else if (user === "admin")
              router("/AdminDashboard/Pensioner_HistoryP");
            else {
              router("/Dashboard/listing");
            }
          }}
          className="cursor-pointer pt-[5rem] mr-2"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="pt-[5rem] sm:text-[1.8rem] text-[1.4rem]">
          {wowt === 0
            ? "Beneficiary Registration"
            : "Beneficiary Identification"}
        </h2>
      </div>
      <div className="mb-[5rem]">
        <Dis />
      </div>
      <Whatsapp />
    </div>
  );
};

export default BeneficiaryPage;
