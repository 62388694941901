import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import back5 from "../../imgs/back5.png";
import { Popover } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
const StatusHistory = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  let role;
  if (dash === "/AdminDashboard/historyStatus") role = "admin";
  else if (dash === "/EDashboard/historyStatus") role = "employee";
  else role = "superadmin";
  const universalPenId = useSelector((state) => state.universalPenId);
  const dataUser = useSelector((state) => state.dataUser);
  const [statusData, setStatusData] = useState([]);
  useEffect(() => {
    if (dash === "/EDashboard/historyStatus") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/status/history/by-employee`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          console.log("status", data);
          setStatusData(data?.data);
        })

        .catch(() => {});
    } else if (dash === "/AdminDashboard/historyStatus") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/status/history/by-admin`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          setStatusData(data?.data);
        })

        .catch(() => {});
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/status/history/by-superadmin`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          setStatusData(data?.data);
        })

        .catch(() => {});
    }
  }, []);
  const columnHeaders = [
    "ID",
    "Date/Time",
    "Employee ID",
    "Employee Name",
    "Role",
    "Action",
    "Reason",
    "Note",
    "Device IP",
    "Location LBS/GEO",
  ];
  return (
    <div className="p-4 sm:p-10 sm:pl-[7rem] sm:pr-[7rem] p-[2rem] pl-[1rem] pr-[1rem] ">
      <div className="relative flex items-center mt-4">
        <div
          onClick={() => {
            router(
              dash === "/EDashboard/historyStatus"
                ? "/EDashboard/profPage"
                : dash === "/AdminDashboard/historyStatus"
                ? "/AdminDashboard/profPage"
                : "/superAdminDashboard/profPage"
            );
          }}
          className="cursor-pointer"
        >
          <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold sm:text-[1.8rem] text-[1.6rem] ml-4">
          Status History
        </h2>
      </div>
      <div className="overflow-x-auto w-full mt-10 bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
            <tr>
              {columnHeaders.map((columnHeader) => (
                <th
                  scope="col"
                  className="px-4 py-3 font-semibold tracking-wider"
                >
                  {columnHeader}
                </th>
              ))}
            </tr>
          </thead>
          {statusData.length === 0 ? (
            <h1 className="flex items-center justify-center text-center text-[1.1rem] p-5">
              No Data Found
            </h1>
          ) : (
            <tbody>
              {statusData.map((item, index) => (
                <tr className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer">
                  <th
                    scope="row"
                    className="px-4 py-5 font-medium text-gray-900  whitespace-nowrap "
                  >
                    {index + 1}
                  </th>
                  <td className="px-4 py-5 whitespace-nowrap">
                    <p>
                      {moment(item?.createdAt).format("MM/DD/YYYY")}
                    </p>
                    <p> {moment(item?.createdAt).format("hh:mm A")}</p>
                  </td>
                  <td className="px-4 py-5">
                    <div>{item?.actionByUser?.uniqueId}</div>
                  </td>
                  <td className="px-4 py-5">
                    <div>
                      {item?.actionByUser?.person?.firstName}{" "}
                      {item?.actionByUser?.person?.lastName}
                    </div>
                  </td>
                  <td className="px-4 py-5">
                    <div className="capitalize">
                      {item?.actionByUser?.roles[0]}
                    </div>
                  </td>
                  <td className="px-4 py-5 text-center">
                    <div
                      className={`flex items-center justify-center p-1 rounded-md text-white  text-center
                    ${
                      item?.toggle === "approved"
                        ? "bg-primary"
                        : item?.toggle === "enabled"
                        ? "bg-primary"
                        : "bg-[#F7542E]"
                    } capitalize text-center px-3`}
                    >
                      {item?.toggle}
                    </div>
                  </td>
                  <td className="px-4 py-5">
                    <Popover content={item.reason}>
                      <div className="inline-block max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {item?.reason}
                      </div>
                    </Popover>
                  </td>
                  <td className="px-4 py-5">
                    <Popover content={item.note}>
                      <div className="inline-block max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {item?.note === null ? "---" : item.note}
                      </div>
                    </Popover>
                  </td>
                  <td className="px-4 py-5">
                    <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 pl-5 pr-5 rounded-md  focus:border-none cursor-pointer">
                      {item?.ip === null ? "----" : `${item?.ip}`}
                    </div>
                  </td>
                  <td className="px-4 py-5">
                    <div className="flex items-center justify-center ml-6">
                      {item?.location && (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${item?.location}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline hover:text-blue-700 cursor-pointer"
                        >
                          View Location
                        </a>
                      )}
                      {item?.location === null && "No Location"}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
export default StatusHistory;
