import React, { useEffect, useState } from "react";
import MD1 from "../../../imgs/mhss.png";
import MD2 from "../../../imgs/MD2.png";
import MD3 from "../../../imgs/MD3.png";
import MD4 from "../../../imgs/walletImg.png";
import reportIcon from "../../../imgs/reportIcon.svg";
import walletNew from "../../../imgs/walletNew.png";
import transactionNew from "../../../imgs/transactionNew.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Chart from "./chart";
import MerchantChart from "./merchantChart";

const Card = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);
  const user = useSelector((state) => state.user);
  const merchantStores = useSelector((state) => state.merchantStores);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState("");

  useEffect(() => {
    const api = isGovernmentAgency
      ? "v1/bank-balance-info/govt-agency-merch/own-balance-report"
      : `v1/merch-transaction-payments/own-transaction-balance-report/for-merch`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response?.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const api =
      user === "merchant-employee"
        ? "v1/merch-employee-store/my"
        : "v1/merch-store";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        if (user === "merchant-employee") {
          const store = data?.data?.map((item) => item.merchstore);
          dispatch({ type: "MERCHANT_STORES", num: store });
        } else {
          dispatch({ type: "MERCHANT_STORES", num: data?.data?.rows });
        }
      })
      .catch(() => {});
  }, []);

  const items = [
    {
      key: "1",
      icon: "",
      label: `Transactions`,
      content: "View past transactions record",
      img: transactionNew,
      route: "/Merchant/Transactions",
    },
    {
      key: "2",
      icon: "",
      label: `MHSSS Payments`,
      content: "View MHSSS payment transactions",
      img: MD1,
      route: "/Merchant/MHSSS/Payments",
    },
    {
      key: "3",
      icon: "",
      label: `SAP Money`,
      content: "View and manage transactions.",
      img: walletNew,
      // route: "/SapMoney",
      route: "/Merchant/SapMoney/dashboard",
    },
    {
      key: "4",
      icon: "",
      label: `Reports`,
      content: "View past reports",
      img: reportIcon,
      route: "/Merchant/Reports",
    },
  ];

  const values = [
    {
      title: "Outstanding",
      value:
        Number(data?.totalPendingPayAndFee ?? 0) +
        Number(data?.totalPastDuePayAndFee ?? 0),
      bg: "bg-[#EF4D5C]",
    },
    {
      title: "Cash Received",
      value: Number(data?.totalMhsssPayAndFeePaid ?? 0),
      bg: "bg-[#1BA397]",
    },
    {
      title: "Cash Paid",
      value:
        Number(data?.totalMerchPaidAmount ?? 0) +
        Number(data?.totalPastDuePay ?? 0) +
        Number(data?.totalPendingPay ?? 0),
      bg: "bg-[#256091]",
    },
    {
      title: "Fee Received",
      value: Number(data?.totalMHsssFeePaid ?? 0),
      bg: "bg-[#F18700]",
    },
    {
      title: "Fee Pending",
      value: Math.max(
        0,
        (Number(data?.totalPendingFee) || 0) +
          (Number(data?.totalPastDueFee) || 0)
      ),
      bg: "bg-[#F6B207]",
    },
  ];

  return (
    <div>
      <div
        className={`${
          isGovernmentAgency &&
          (user === "merchant-owner" || user === "merchant-admin")
            ? "px-6 py-3 relative"
            : "hidden"
        }`}
      >
        <div className="  flex ">
          <div className="flex sm:space-x-4 md:w-4/5 w-full bg-[#F6F4F4] p-4 md:flex-row flex-col sm:gap-0 gap-4">
            {/* Bank Balance Section */}
            <div className="bg-white sm:p-6 p-3 rounded-lg shadow-md flex flex-col justify-center items-center md:w-[30%] w-full sm:mt-0 mt-12">
              <div className="text-3xl font-semibold text-black">
                {data?.totalBankBalance
                  ? ` $${data?.totalBankBalance}`
                  : " -----"}
              </div>
              <div className="text-sm text-gray-600 mt-2">Bank Balance</div>
            </div>

            {/* Cash In/Out Section */}

            <div className="bg-white md:px-5 sm:p-0 p-3 rounded-lg shadow-md flex sm:flex-row flex-col-reverse justify-between items-center md:w-[70%] w-full">
              <div className="flex flex-col items-start sm:ml-5 w-full px-4 sm:mb-0 mb-4">
                <div className="flex items-center mb-3 justify-between w-full">
                  <div className="flex flex-row items-center flex-1">
                    <span className="w-3 h-3 bg-[#256091] rounded-full mr-2"></span>
                    <span className="text-sm font-medium text-gray-700">
                      Cash Received
                    </span>
                  </div>
                  <p className="font-semibold  text-[0.96rem]">
                    ${data?.totalIn ? data?.totalIn : 0}
                  </p>
                </div>
                <div className="flex items-center mb-3 justify-between w-full">
                  <div className="flex flex-row items-center flex-1">
                    <span className="w-3 h-3 bg-primary  rounded-full mr-2"></span>
                    <span className="text-sm font-medium text-gray-700">
                      Cash Paid
                    </span>
                  </div>
                  <p className="font-semibold text-[0.96rem]">
                    ${data?.totalOutPay ? data?.totalOutPay : 0}
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-row items-center flex-1">
                    <span className="w-3 h-3 bg-[#FFCD69] rounded-full mr-2"></span>
                    <span className="text-sm font-medium text-gray-700">
                      Fee Out
                    </span>
                  </div>
                  <p className="font-semibold text-[0.96rem]">
                    ${data?.totalOutFee ? data?.totalOutFee : 0}
                  </p>
                </div>
              </div>
              <div className="mt-0">
                <Chart data={data} />
              </div>
            </div>
          </div>

          {/* Request Money Button */}
          <div className="ml-12 absolute top-6 sm:right-4 right-10 ">
            <button
              className="bg-[#256091] text-white px-4 py-2 rounded-lg shadow-md "
              onClick={() => {
                router("/Merchant/GovAgency/Request/MHSSS");
              }}
            >
              Request Money
            </button>
          </div>
        </div>
      </div>

      {/* Normal Merchnat Section */}
      <div
        className={`${
          !isGovernmentAgency &&
          (user === "merchant-owner" || user === "merchant-admin")
            ? // ? "hidden"
              "px-6 py-2 relative"
            : "hidden"
        }`}
      >
        <div className="  flex ">
          <div className="flex sm:space-x-4 w-full bg-[#F5F4F4] px-4 p-2 md:flex-row flex-col sm:gap-10 gap-4 px-6">
            {/* Cash In/Out Section */}
            <div className="mt-0 sm:ml-7">
              <MerchantChart data={data} />
            </div>
            <div className="md:px-10 rounded-lg  flex flex-row sm:justify-between items-center md:w-[50%] w-full gap-10 ">
              <div className="flex flex-col items-start sm:ml-5 w-full px-7">
                {values.map((item, index) => (
                  <div className="flex items-center mb-3 justify-between w-full">
                    <div className="flex flex-row items-center flex-1">
                      <span
                        className={`w-3 h-3 ${item?.bg} rounded-full mr-2`}
                      ></span>
                      <span className="text-sm font-medium text-gray-700">
                        {item.title}
                      </span>
                    </div>
                    <p
                      className={`${
                        item?.title === "Outstanding"
                          ? "font-bold  text-[1rem]"
                          : "font-semibold  text-[0.99rem]"
                      }`}
                    >
                      ${item.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Request Money Button */}
        </div>
      </div>

      {/* Other Cards */}
      <div
        className={`grid   gap-8 p-6 cursor-pointer ${
          user === "merchant-employee"
            ? "sm:grid-cols-3 grid-cols-1"
            : "sm:grid-cols-3 grid-cols-1"
        }`}
      >
        {items
          .filter((item) => !(item.key === "2" && user === "merchant-employee"))
          .map((item, index) => (
            <div
              key={index}
              className="border-4 border-green-100 p-10 bg-gray-100 rounded-lg shadow-lg"
              onClick={() => {
                // if (item.key === "3") {
                //   dispatch({ type: "SIDEBAR", num: "3" });
                // }
                if (item.key === "3") {
                  if (dataUser?.data?.merchuser?.status === "approved") {
                    if (merchantStores?.length === 0) {
                      dispatch({ type: "MERCHANT_SAP_TYPE", num: "HQ" });
                      dispatch({
                        type: "MERCHANT_SAP_TYPE_DATA",
                        num: dataUser?.data,
                      });
                      dispatch({ type: "SAP_MONEY_ENABLE", num: true });
                      router("/SapMoney");
                      return;
                    }
                  } else {
                    return;
                  }
                }
                router(item.route);
              }}
            >
              <div className="flex flex-col justify-center items-center">
                <div className="w-[5rem] h-[5rem] mb-4 overflow-hidden">
                  <img
                    src={item.img}
                    alt={item.label}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="text-primary font-bold text-xl mb-2 text-center">
                  {item.label}
                </div>
                <p className="text-gray-700 text-center font-semibold">
                  {item.content}
                </p>
              </div>
              {item.text && (
                <p className="text-center font-semibold mt-8 text-primary italic">
                  {item.text}
                </p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Card;
