export const selectedTransections = (state = 0, action) => {
  switch (action.type) {
    case "IdSET":
      return action.num;
    default:
      return state;
  }
};
export const totalPayment = (state = 0, action) => {
  switch (action.type) {
    case "Payment":
      return action.num;
    default:
      return state;
  }
};
export const aiCurrent = (state = 1, action) => {
  switch (action.type) {
    case "ISET":
      return action.num;
    default:
      return state;
  }
};
export const vcd = (state = false, action) => {
  switch (action.type) {
    case "VCD":
      return true;
    case "NVCD":
      return false;
    default:
      return state;
  }
};
export const sap = (state = false, action) => {
  switch (action.type) {
    case "SAP":
      return true;
    case "NSAP":
      return false;
    default:
      return state;
  }
};
