import React from "react";

const Card = ({ item }) => {
  return (
    <div
      className="p-2 md:w-[23%] sm:w-1/2 w-full cursor-pointer "
      onClick={() => {
        // setActive(item.key);
      }}
    >
      <div
        className={`border bg-white shadow-md px-4 py-4 rounded-lg transform transition duration-500 hover:scale-100`}
      >
        <div className="flex flex-col gap-6">
          <div className="flex gap-4 items-center justify-between w-full">
            <div className="flex flex-col items-center">
              <h1 className="text-gray-700 text-[1rem] font-semibold">
                {item.label}
              </h1>
              <p className="leading-relaxed text-center text-gray-500 font-bold">
                {item?.count}
              </p>
            </div>
            <div className="flex flex-col">
              <img src={item.icon} alt="" className="w-8 h-8" />
            </div>
          </div>
          <div className="inline-block">
            <p
              className={`p-1 ${
                item.loss !== ""
                  ? "bg-red-100 text-red-500"
                  : "bg-green-100 text-green-500"
              } pl-2 pr-2 rounded-md font-bold inline-block`}
            >
              {item?.loss !== "" ? item.loss : item.profit}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
