import React from "react";
const infoModal = ({ content, setShow,mhsss }) => {
  return (
    <div className="w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10">
      <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-[25rem]">
        <div className="bg-white px-2 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:ml-3 sm:mt-0 sm:text-left">
              <h3
                className={`${mhsss ? "hidden":"text-base font-semibold leading-6 text-gray-900"}`}
                id="modal-title"
              >
                Information
              </h3>
              <div className="mt-2">
                <p className={`${mhsss ? "hidden":"text-sm text-gray-500"}`}>{content}</p>
                <p className={`${mhsss ? "text-sm text-gray-500":"hidden"}`} style={{ whiteSpace: 'pre-line' }}>{content}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 py-3 flex flex-row-reverse px-6">
          <button
            onClick={() => setShow(false)}
            type="button"
            className="inline-flex  justify-center rounded-md bg-primary px-6 py-2 text-sm font-semibold text-white shadow-sm  ml-3 w-auto"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default infoModal;
