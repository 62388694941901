import React from "react";
import PlaceAutocomplete from "./PlaceAutocomplete";

function MapsPage() {
  return (
    <div className="App">
      <h1 className="text-center mb-2 text-[1.5rem] text-primary font-semibold">
        Search
      </h1>
      <PlaceAutocomplete />
    </div>
  );
}

export default MapsPage;
