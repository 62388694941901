import React, { useState, useEffect } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import leftIndex from "../../../imgs/ic_left_index.png";
import rightIndex from "../../../imgs/ic_right_index.png";
import rightThumb from "../../../imgs/ic_right_thumb.png";
import leftThumb from "../../../imgs/ic_left_thumb.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import across from "../../../imgs/across.png";
import logo from "../../../imgs/logo12.png";
import EmpRegisterPol1 from "../../Pol/empRegisterPol1";
let images;
const SelectLocation = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const pol1 = useSelector((state) => state.pol1);
  const reset = useSelector((state) => state.reset);
  const penVerStatus = useSelector((state) => state.penVerStatus);
  const [refetch, setRefetch] = useState(false);
  const data = useSelector((state) => state?.profileData);
  const veriSelectedPensioner = useSelector(
    (state) => state.veriSelectedPensioner
  );
  const [note1, setNote1] = useState("");
  const [type, setType] = useState(true);
  const cam = useSelector((state) => state.cam);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [showVer, setShowVer] = useState(false);
  const [err, setErr] = useState("");
  const [dis, setDis] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${veriSelectedPensioner?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch(() => {});
  }, [reset]);
  useEffect(() => {
    console.log("Profile", data);
    console.log("ProfileCam", cam);
    dispatch({
      type: "polIdSet",
      num: `${data?.user?.id}`,
    });
    dispatch({ type: "polStatusSet", num: data?.user?.polStatus });
    dispatch({ type: "camF" });
    dispatch({
      type: "polNameSet",
      num: `${data?.person?.firstName} ${data?.person?.lastName}`,
    });
    images = [
      {
        src: leftThumb,
        alt: "Left Thumb",
        added: data?.pensionerInfo?.leftThumbs
          ? data?.pensionerInfo?.leftThumbs
          : null,
      },
      {
        src: rightThumb,
        alt: "Right Thumb",
        added: data?.pensionerInfo?.rightThumbs
          ? data?.pensionerInfo?.rightThumbs
          : null,
      },
      {
        src: leftIndex,
        alt: "Left Index",
        added: data?.pensionerInfo?.leftIndices
          ? data?.pensionerInfo?.leftIndices
          : null,
      },
      {
        src: rightIndex,
        alt: "Right Index",
        added: data?.pensionerInfo?.rightIndices
          ? data?.pensionerInfo?.rightIndices
          : null,
      },
    ];
  }, [data, reset]);
  const Verification = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${veriSelectedPensioner?.id}/change-pensioner-verification-status`,
        {
          verificationStatus: type
            ? "Biometric Verified"
            : "Verification Rejected",
          note: note1,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShowVer(false);
        router("/pensionerVerification/Profile");
        if (type)
          dispatch({
            type: "PEN_VER_STATUS",
            num: "Biometric Verified",
          });
        else
          dispatch({
            type: "PEN_VER_STATUS",
            num: "Verification Rejected",
          });
      })
      .catch((err) => {
        setShowVer(false);
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  useEffect(() => {
    if (penVerStatus === "Biometric Verified") setDis(true);
    else setDis(false);
  }, []);
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader header={true} title={"Biometrics"} route={-1} />
      <div className="flex items-center justify-center flex-col bg-[#F4F2F2] mb-10 p-4 ml-[2rem] mr-[2rem] relative">
        <div
          className={`rounded-sm flex mt-[2rem] items-center flex-col gap-2 bg-white m-5 p-3 pl-6 pr-6 w-[80%]`}
        >
          <div className="w-full flex items-center justify-between">
            <div className="w-[30%] flex items-center justify-between">
              <p className="text-lg font-semibold text-gray-300">Pol Status</p>
              <p
                className={`${
                  data?.user?.polStatus === "active-pol"
                    ? "text-primary"
                    : "text-red-500"
                } text-lg font-semibold capitalize`}
              >
                {data?.user?.polStatus}
              </p>
            </div>
            {data?.user?.polStatus !== "active-pol" && (
              <div>
                {penVerStatus === "Identification Detail Verified" && (
                  <button
                    onClick={() => {
                      dispatch({ type: "pol1" });
                      dispatch({ type: "cam" });
                    }}
                    type="button"
                    className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
                  >
                    Update POL
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={`rounded-sm flex mt-[1rem] items-center flex-col gap-2 bg-white m-5 p-6 w-[80%]`}
        >
          <div className="p-6 border w-[70%] rounded-lg grid grid-cols-2 gap-4 mt-8 mb-8">
            {images?.map((image, index) => (
              <div
                key={index}
                className="w-full h-48 flex items-center justify-center border rounded-lg bg-gray-100"
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className={`object-contain w-24 h-24 ${
                    image?.added !== null ? "opacity-100" : "opacity-30"
                  }`}
                />
              </div>
            ))}
          </div>
          {penVerStatus === "Identification Detail Verified" && (
            <button
              type="button"
              onClick={() => {
                router("/pensionerVerification/BiometricsUpdate");
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-white text-primary border font-semibold border-primary  rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
            >
              Update
            </button>
          )}
        </div>

        {penVerStatus === "Identification Detail Verified" && (
          <div
            className={`flex w-full items-center justify-center mt-6 gap-2 mb-5`}
          >
            <button
              type="button"
              onClick={() => {
                setShowVer(true);
                setType(true);
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 `}
            >
              Biometric Verified
            </button>
            <button
              type="button"
              onClick={() => {
                // router("/pensionerVerification/Profile");
                setShowVer(true);
                setType(false);
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-red-600 text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Unverified
            </button>
          </div>
        )}
      </div>
      <div className={`${pol1 ? "" : "hidden"}`}>
        <EmpRegisterPol1 setRefetch={setRefetch} refetch={refetch} />
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showVer ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowVer(false)}>
            <img src={logo} alt=""  className="w-16 h-16" />
          </div>
          {type ? (
            <p className="text-center mt-6 mb-5 font-semibold">
              Are you sure to mark this pensioner as verified?
            </p>
          ) : (
            <p className="text-center mt-6 mb-5 font-semibold">
              Are you sure to reject this Pensioner?
            </p>
          )}
          <textarea
            type="text"
            name=""
            onChange={(e) => setNote1(e.target.value)}
            value={note1}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => setShowVer(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                Verification();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
