import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Checkbox } from "antd";
import across from "../../../imgs/across.png";
import cross from "../../../imgs/cross.png";
import { useLocation, useNavigate } from "react-router-dom";
const Banking = () => {
  const router = useNavigate();
  const option = useSelector((state) => state.option);
  console.log(option);
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const dataUser = useSelector((state) => state.dataUser);
  const namePerson = useSelector((state) => state.namePerson);
  const profileData = useSelector((state) => state.profileData);
  const location = useLocation();
  const dash = location.pathname;
  const [sigg, setSigg] = useState("");
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [test, setTest] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [val, setVal] = useState(true);
  const [allDis, setAllDis] = useState(false);
  const [tab, setTab] = useState(0);
  const [InternationalBankInfo, setInternationalBankInfo] = useState([]);
  const [LocalBankInfo, setLocalBankInfo] = useState([]);
  const [isLocalAccountActive, setIsLocalAccountActive] = useState(false);
  const [isInternationalAccountActive, setIsInternationalAccountActive] =
    useState(false);
  const [isInternational, setIsInternational] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [inactivateModal, setInactivateModal] = useState(false);
  const [bankId, setBankId] = useState(null);
  const [bankStatus, setBankStatus] = useState("");
  const [percentage, setPercentage] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const token = useSelector((state) => state.token);
  console.log("Bank", InternationalBankInfo);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/country-banks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        // const BankOptions = data.data.map((item) => {
        //   return { label: item.name, value: item.id };
        // });
        // setAllBanks(BankOptions);
        setBanks(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedBank === "") return;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/country-bank-branches?countrybankId=${selectedBank}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        // const BranchOptions = data.data.map((item) => {
        //   return { label: item.name, value: item.id };
        // });
        // setAllBranches(BranchOptions);
        // setBranches(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedBank]);
  const changeHandler = (e) => {
    if (e === "International") {
      setTab(0);
    } else {
      setTab(1);
    }
  };
  useEffect(() => {
    if (dash === "/Dashboard/PensionerAccount") {
      if (
        dataUser.data.user.status === "pending" ||
        dataUser.data.user.status === "deferred" ||
        dataUser.data.user.status === "re-submitted" ||
        dataUser.data.user.status === "in-review" ||
        dataUser.data.user.status === "verified"
      ) {
        setAllDis(true);
      }
    } else if (
      dash === "/EDashboard/PensionerAccount" &&
      profileData.user.isDisabled
    ) {
      setAllDis(true);
    }
  }, []);
  useEffect(() => {
    if (
      dash === "/EDashboard/PensionerAccount" ||
      dash === "/AdminDashboard/PensionerAccount" ||
      dash === "/superAdminDashboard/PensionerAccount"
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/bank-info/pensioner?pensionerUserId=${profileData.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          const InternationalBank = data?.data?.filter(
            (item) => item.isInternational === true
          );
          // Sort local banks based on isActive
          const LocalBank = data?.data?.filter(
            (item) => item.isInternational === false
          );
          InternationalBank.sort(
            (a, b) => (b.isActive ? 1 : -1) - (a.isActive ? 1 : -1)
          );
          LocalBank.sort(
            (a, b) => (b.isActive ? 1 : -1) - (a.isActive ? 1 : -1)
          );
          setInternationalBankInfo(InternationalBank);
          setLocalBankInfo(LocalBank);

          return {
            internationalBank: InternationalBank,
            localBank: LocalBank,
          };
          // setInfo(true);
          // setBankName(data.data.bankName);
          // setBankAddress(data.data.bankAddress);
          // setAccountTitle(data.data.accountTitle);
          // setAccountNo(data.data.accountNo);
          // setConfirmAccountNo(data.data.accountNo);
          // if (data.data.swiftNo) {
          //   setSwiftNo(data.data.swiftNo);
          // }
          // setRoutingNo(data.data.routingNo);
          // setAccountType(data.data.accountType);
          // setHomeAddress(data.data.homeAddress);
          // setIsInternational(data.data.isInternational);
        })
        .then(({ internationalBank, localBank }) => {
          const activeInternationalBank = internationalBank?.filter(
            (item) => item.isActive === true
          );
          const activeLocalBank = localBank?.filter(
            (item) => item.isActive === true
          );

          // Do something with the values
          if (activeInternationalBank.length !== 0) {
            setIsInternationalAccountActive(true);
          } else {
            setIsInternationalAccountActive(false);
          }

          if (activeLocalBank.length !== 0) {
            setIsLocalAccountActive(true);
          } else {
            setIsLocalAccountActive(false);
          }
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/bank-info`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          const InternationalBank = data?.data?.filter(
            (item) => item.isInternational === true
          );
          InternationalBank.sort(
            (a, b) => (b.isActive ? 1 : -1) - (a.isActive ? 1 : -1)
          );
          const LocalBank = data?.data?.filter(
            (item) => item.isInternational === false
          );
          LocalBank.sort(
            (a, b) => (b.isActive ? 1 : -1) - (a.isActive ? 1 : -1)
          );
          setInternationalBankInfo(InternationalBank);
          setLocalBankInfo(LocalBank);

          return {
            internationalBank: InternationalBank,
            localBank: LocalBank,
          };

          // setInfo(true);
          // setBankName(data.data.bankName);
          // setBankAddress(data.data.bankAddress);
          // setAccountTitle(data.data.accountTitle);
          // setAccountNo(data.data.accountNo);
          // setConfirmAccountNo(data.data.accountNo);
          // if (data.data.swiftNo) {
          //   setSwiftNo(data.data.swiftNo);
          // }
          // setRoutingNo(data.data.routingNo);
          // setAccountType(data.data.accountType);
          // setHomeAddress(data.data.homeAddress);
          // setIsInternational(data.data.isInternational);
        })
        .then(({ internationalBank, localBank }) => {
          const activeInternationalBank = internationalBank?.filter(
            (item) => item.isActive === true
          );
          const activeLocalBank = localBank?.filter(
            (item) => item.isActive === true
          );

          // Do something with the values
          if (activeInternationalBank.length !== 0) {
            setIsInternationalAccountActive(true);
          } else {
            setIsInternationalAccountActive(false);
          }

          if (activeLocalBank.length !== 0) {
            setIsLocalAccountActive(true);
          } else {
            setIsLocalAccountActive(false);
          }
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
    let sett;
    if (profileData.pensionerInfo) {
      if (profileData.pensionerInfo.signature !== null) {
        sett = [profileData.pensionerInfo.signature];
      } else if (profileData.pensionerInfo.leftIndex !== null) {
        sett = [profileData.pensionerInfo.leftIndex];
      } else if (profileData.pensionerInfo.rightIndex !== null) {
        sett = [profileData.pensionerInfo.rightIndex];
      } else if (profileData.pensionerInfo.leftThumb !== null) {
        sett = [profileData.pensionerInfo.leftThumb];
      } else {
        sett = [profileData.pensionerInfo.rightThumb];
      }

      const body1 = [sett];
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
          {
            fileNames: body1,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setSigg(data.data[0].url);
          setFullName(
            `${profileData.person.firstName} ${profileData.person.lastName}`
          );
          setTitle(`${profileData.person.title}`);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  }, [refresh]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [isInternational]);

  const valHandler = () => {
    // setConfirmAccountNo(e.target.value);
    if (
      document.getElementById("password").value ===
      document.getElementById("validate").value
    ) {
      // document.getElementById("message").style.color = "green";
      document.getElementById("message1").style.color = "black";
      document.getElementById("message2").style.color = "black";
      document.getElementById("message1").innerHTML = "Bank Account No";
      document.getElementById("message2").innerHTML = "Confirm Bank Account No";
      setVal(true);
    } else {
      // document.getElementById("message").style.color = "red";
      document.getElementById("message1").style.color = "red";
      document.getElementById("message2").style.color = "red";
      document.getElementById("message1").innerHTML = "Bank Account No**";
      document.getElementById("message2").innerHTML =
        "Confirm Bank Account No**";
      setVal(false);
    }
  };
  const checkHandler = (setState, fieldName, value, itemId) => {
    setState((prevData) =>
      prevData.map((item) =>
        item.id === itemId ? { ...item, [fieldName]: value } : item
      )
    );
  };

  const handleChange = (setState, fieldName, value, itemId) => {
    if (fieldName === "bankName") {
      setSelectedBank(value);
      const selectedBankValue = banks.find((item) => item.id === value);
      setState((prevData) =>
        prevData?.map((item) =>
          item.id === itemId
            ? { ...item, [fieldName]: selectedBankValue?.name }
            : item
        )
      );
    } else {
      setState((prevData) =>
        prevData?.map((item) =>
          item.id === itemId ? { ...item, [fieldName]: value } : item
        )
      );
    }
  };

  const handleInputChange = (setState, fieldName, value, itemId) => {
    const inputValue = value;

    // Use regular expression to remove non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the state with the sanitized numeric value
    setState((prevData) =>
      prevData?.map((item) =>
        item.id === itemId ? { ...item, [fieldName]: numericValue } : item
      )
    );
  };

  const nextHandler = (itemId, item) => {
    console.log("item", item);
    // e.preventDefault();
    if (!val) {
      setErr("Please confirm the account number");
      setShow(true);
    } else {
      if (one && two && three) {
        setIsLoading(true);
        let wow = true;
        let body = {
          bankName: item?.bankName,
          bankAddress: item?.bankAddress,
          accountTitle: item?.accountTitle,
          accountNo: item?.accountNo,
          // "isInternational": item?.isInternational,
          routingNo: item?.routingNo,
          accountType: item?.accountType,
          homeAddress: item?.homeAddress,
        };

        if (item?.isInternational === true) {
          body.swiftNo = item?.swiftNo;
          if (item?.swiftNo !== "") {
            wow = true;
          } else {
            wow = false;
            setErr("Please enter the swift number");
            setShow(true);
          }
        }

        if (wow) {
          if (dash === "/Dashboard/PensionerAccount") {
            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${itemId}`,
                body,
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then(() => {
                setOne(false);
                setTwo(false);
                setThree(false);
                setRefresh(!refresh);
                setIsLoading(false);
              })
              .catch((err) => {
                setErr(err.response.data.message);
                setShow(true);
                setIsLoading(false);
              });
          } else {
            body.percentage = item?.percentage;

            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${itemId}/update-by-staff`,
                { bankInfo: body },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then(() => {
                setOne(false);
                setTwo(false);
                setThree(false);
                setRefresh(!refresh);
                setIsLoading(false);
              })
              .catch((err) => {
                setErr(err.response.data.message);
                setShow(true);
                setIsLoading(false);
              });
          }
        }
      } else {
        setErr("Please select all the checkboxes");
        setShow(true);
      }
    }
  };

  const changeStatus = (status, itemId) => {
    const api =
      dash === "/Dashboard/PensionerAccount"
        ? "status"
        : "status/change-by-staff";
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${itemId}/${api}`,
        {
          isActive: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setRefresh(!refresh);
      })
      .catch((err) => {
        if (err.response.data.message === "Already two active bankInfo found") {
          setErr("Already two active bank accounts found");
        } else {
          setErr(err.response.data.message);
        }
        setShow(true);
      });
  };

  const ActivateAccount = () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${bankId}/update-by-staff`,
        {
          bankInfo: {
            percentage: percentage,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        axios
          .patch(
            `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${bankId}/status/change-by-staff`,
            {
              isActive: !bankStatus,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            setInactivateModal(false);
            setRefresh(!refresh);
          })
          .catch((err) => {
            if (
              err.response.data.message === "Already two active bankInfo found"
            ) {
              setErr("Already two active bank accounts found");
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
          });
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
        setIsLoading(false);
      });
  };
  return (
    <div>
      {/* Tabs */}
      <div className="flex justify-between">
        <div className="lg:mb-[2rem] flex mb-[2rem]">
          <div
            className="cursor-pointer"
            onClick={() => changeHandler("International")}
          >
            <h2
              className={`lg:text-[1.1rem] ${
                tab === 0 ? "font-semibold" : ""
              } `}
            >
              Local Bank
            </h2>
            <div
              className={`lg:w-[7rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                tab === 0 ? "bg-primary" : "bg-[#E8E8E8]"
              } `}
            ></div>
          </div>

          <div
            className="cursor-pointer"
            onClick={() => changeHandler("Local")}
          >
            <h2
              className={`lg:text-[1.1rem] ml-6 ${
                tab === 1 ? "font-semibold" : ""
              } `}
            >
              International Bank
            </h2>
            <div
              className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                tab === 1 ? "bg-primary" : "bg-[#E8E8E8]"
              }  `}
            ></div>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              user === "employee"
                ? router("/EDashboard/addBank")
                : user === "admin"
                ? router("/AdminDashboard/addBank")
                : user === "superadmin"
                ? router("/superAdminDashboard/addBank")
                : router("/Dashboard/addBank");
            }}
            className={`${
              // profileData.user.status === "pending" ||
              (isLocalAccountActive === false &&
                isInternationalAccountActive === false) ||
              (isLocalAccountActive === true &&
                isInternationalAccountActive === false) ||
              (isLocalAccountActive === false &&
                isInternationalAccountActive === true) ||
              (InternationalBankInfo.length === 0 &&
                LocalBankInfo.length === 0) ||
              (InternationalBankInfo.length === 0 &&
                LocalBankInfo.length !== 0) ||
              (InternationalBankInfo.length !== 0 && LocalBankInfo.length === 0)
                ? ""
                : "hidden"
            } lg:text-[1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-4 pl-4 `}
          >
            Add Bank
          </button>
        </div>
      </div>
      {/* international */}
      {tab === 1 && (
        <>
          {InternationalBankInfo.length !== 0 ? (
            InternationalBankInfo?.map((item) => (
              <div key={item.id}>
                <div className="flex justify-between items-center">
                  <p></p>
                  <h1 className="text-center font-bold md:text-[1.3rem]">
                    International Bank
                  </h1>
                  <p
                    className={`text-[0.9rem] text-white p-1 ${
                      item?.isActive === true ? "bg-primary" : "bg-[#d64242]"
                    } 
      pl-4 pr-4 rounded-md text-white focus:border-none font-bold capitalize`}
                  >
                    {item?.isActive === false ? "InActive" : "Active"}
                  </p>
                </div>

                {/* Render other content based on item */}
                <form
                  onSubmit={nextHandler}
                  action=""
                  className=" mt-10 pointer-events-none"
                >
                  <div className="flex justify-center items-center">
                    <div className="w-[100%]">
                      <div>
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Name of Bank
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          placeholder="Name of Bank"
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "bankName",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.bankName}
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Name of Bank Branch
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          placeholder="Name of Bank"
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "bankName",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.branchName}
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Address of Branch
                        </h2>
                        <textarea
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "bankAddress",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.bankAddress}
                          placeholder="Address of Branch"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Name(s) on Account
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "accountTitle",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.accountTitle}
                          placeholder="Name(s) on Account"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2
                          id="message1"
                          className="mb-4 text-[1.1rem] font-semibold"
                        >
                          Bank Account No
                        </h2>
                        <input
                          type="text"
                          name=""
                          id="password"
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "accountNo",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.accountNo}
                          placeholder="Bank Account No"
                          className={` ${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2
                          id="message2"
                          className="mb-4 text-[1.1rem] font-semibold"
                        >
                          Confirm Bank Account No
                        </h2>
                        <input
                          type="text"
                          name=""
                          id="validate"
                          value={item?.accountNo}
                          onChange={valHandler}
                          placeholder="Confirm Bank Account No"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        {/* <div style={{ marginBottom: "1rem" }}>
                  <label htmlFor="validate">
                    <span id="message"></span>
                  </label>
                </div> */}
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Routing #
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          // onChange={handleChange(setRoutingNo)}
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "routingNo",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.routingNo}
                          placeholder="Routing #"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Percentage
                        </h2>
                        <input
                          disabled={user === "pensioner" ? true : false}
                          type="text"
                          name=""
                          
                          value={`${item?.percentage}%`}
                          onChange={(e) =>
                            handleInputChange(
                              setInternationalBankInfo,
                              "percentage",
                              e.target.value,
                              item.id
                            )
                          }
                          placeholder="Percentage"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                      </div>
                      <div>
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Bank Account Type
                        </h2>
                        {}
                        <div
                          className={`${allDis ? "pointer-events-none" : ""}`}
                        >
                          <div className="mb-4 mt-2">
                            <input
                              type="radio"
                              id="saving"
                              name="fav_language"
                              value="Saving"
                              checked={
                                item?.accountType === "Saving" ? true : false
                              }
                              // onChange={checkHandler}
                              onChange={(e) =>
                                checkHandler(
                                  setInternationalBankInfo,
                                  "accountType",
                                  e.target.value,
                                  item.id
                                )
                              }
                            ></input>
                            <label
                              htmlFor="saving"
                              className="mr-8 ml-1 text-[0.9rem]"
                            >
                              Saving
                            </label>
                            <input
                              type="radio"
                              id="chequing"
                              name="fav_language"
                              // onChange={checkHandler}
                              onChange={(e) =>
                                checkHandler(
                                  setInternationalBankInfo,
                                  "accountType",
                                  e.target.value,
                                  item.id
                                )
                              }
                              checked={
                                item?.accountType === "Chequing" ? true : false
                              }
                              value="Chequing"
                            ></input>
                            {}
                            <label
                              htmlFor="chequing"
                              className=" ml-1 text-[0.9rem]"
                            >
                              Chequing
                            </label>
                          </div>
                        </div>
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Home Address
                        </h2>
                        <textarea
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "homeAddress",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.homeAddress}
                          placeholder="Home Address"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2
                          className={`${
                            item?.isInternational ? "" : "hidden"
                          } mb-4 text-[1.1rem] font-semibold`}
                        >
                          Swift #
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setInternationalBankInfo,
                              "swiftNo",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.swiftNo}
                          placeholder="Swift #"
                          style={{ textTransform: "uppercase" }}
                          className={`${
                            item?.isInternational ? "" : "hidden"
                          } ${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className={` mb-4 text-[1.1rem] font-semibold`}>
                          International Account
                        </h2>
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              // setFour(true);
                              setIsInternational(true);
                            } else {
                              // setFour(false);
                              setIsInternational(false);
                            }
                          }}
                          checked={item?.isInternational}
                          disabled
                          className={"checkk2"}
                          style={{
                            marginLeft: "1rem",
                            pointerEvents: `${allDis ? "none" : "auto"}`,
                          }}
                        >
                          True
                        </Checkbox>

                        <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                          SURRENDER OF VOUCHER DETAILS
                        </h2>
                        <p className="text-[0.9rem]  mb-4">
                          I {namePerson} of my ownn free will Surrender my
                          voucher to MHSSS
                        </p>
                        <div className="ml-4">
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                setOne(true);
                              } else {
                                setOne(false);
                              }
                            }}
                            checked={one}
                            defaultChecked={one}
                            className={"checkk2"}
                            style={{
                              pointerEvents: `${allDis ? "none" : "auto"}`,
                            }}
                          >
                            I agree to the statement above
                          </Checkbox>
                        </div>
                        {namePerson && (
                          <div>
                            <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                              SURRENDER OF BANK INFORMATION DETAILS
                            </h2>
                            <p className="text-[0.9rem] mb-4">
                              I {namePerson} of my ownn free will Surrender my
                              voucher to MHSSS
                            </p>
                            <div className="ml-4">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setTwo(true);
                                  } else {
                                    setTwo(false);
                                  }
                                }}
                                checked={two}
                                defaultChecked={two}
                                className={"checkk2"}
                                style={{
                                  pointerEvents: `${allDis ? "none" : "auto"}`,
                                }}
                              >
                                I agree to the statement above
                              </Checkbox>
                            </div>
                          </div>
                        )}
                        <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                          WAIVING MY RIGHTS TO BE PAID IN PERSON IN ACCORDANCE
                          WITH THE OLD AGE PENSIONER ACT, CAP. 36:30
                        </h2>
                        <p className="text-[0.9rem] mb-4">
                          I {namePerson} of my ownn free will Surrender my
                          voucher to MHSSS
                        </p>
                        <div className="ml-4">
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                setThree(true);
                              } else {
                                setThree(false);
                              }
                            }}
                            checked={three}
                            defaultChecked={three}
                            style={{
                              pointerEvents: `${allDis ? "none" : "auto"}`,
                            }}
                            className={"checkk2"}
                          >
                            I agree to the statement above
                          </Checkbox>
                        </div>
                        <div className="mt-4 mb-4">
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={sigg}
                            alt=""
                          />
                        </div>
                        <input
                          type="text"
                          name=""
                          
                          value={`${title} ${fullName}`}
                          className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        iShow ? "" : "hidden"
                      } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                    >
                      <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                        <img
                          className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                          src={cross}
                          onClick={() => setIShow(false)}
                          alt=""
                        />
                        <img
                          className="w-[45rem] h-[28rem]"
                          src={sigg}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {user === "employee" &&
                !privilege?.includes("Edit banking") ? null : (
                  <div className="flex gap-5 lg:w-[40%]">
                    <button
                      //   onClick={updateHandler}
                      onClick={() => {
                        if (item?.isActive === false) {
                          setInactivateModal(true);
                          setBankId(item?.id);
                          setBankStatus(item?.isActive);
                        } else {
                          changeStatus(item?.isActive, item?.id);
                        }
                      }}
                      // type="submit"
                      className={`${
                        ((profileData.user.status === "pending" ||
                          profileData.user.status === "deferred" ||
                          profileData.user.status === "re-submitted" ||
                          profileData.user.status === "in-review" ||
                          profileData.user.status === "verified") &&
                          dash === "/Dashboard/PensionerAccount") ||
                        user === "pensioner"
                          ? "hidden"
                          : ""
                      } 
                          ${
                            item?.isActive === false
                              ? "bg-primary"
                              : "bg-[#DE2827]"
                          }
                          relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr w-[40%] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      <span className="">{`${
                        item?.isActive === false ? "Activate" : "Inactivate"
                      }`}</span>
                      <div
                        className={`w-[100%] h-[100%] ${
                          isLoading ? "" : "hidden"
                        } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                      ></div>
                    </button>
                    <button
                      // onClick={() => nextHandler(item?.id, item)}
                      onClick={() => {
                        option === "Pensioner"
                          ? router(`/Dashboard/addBank`, {
                              state: {
                                bankInfo: item,
                                type: "Edit",
                              },
                            })
                          : router(`/EDashboard/addBank`, {
                              state: {
                                bankInfo: item,
                                type: "Edit",
                              },
                            });
                      }}
                      type="submit"
                      className={`${
                        ((profileData.user.status === "pending" ||
                          profileData.user.status === "in-review" ||
                          profileData.user.status === "verified" ||
                          profileData.user.status === "re-submitted" ||
                          profileData.user.status === "deferred") &&
                          dash === "/Dashboard/PensionerAccount") ||
                        item?.isActive === false
                          ? "hidden"
                          : ""
                      } 
                      relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary w-[40%] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      {/* <span>Save</span> */}
                      <span>Edit</span>
                      <div
                        className={`w-[100%] h-[100%] ${
                          isLoading ? "" : "hidden"
                        } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                      ></div>
                    </button>
                  </div>
                )}
                <br />
                <hr className="mb-[3rem]" />
              </div>
            ))
          ) : (
            <h1 className="text-center font-bold text:[1.3rem]">
              No International Bank Details Added
            </h1>
          )}
        </>
      )}

      {/* Local */}
      {tab === 0 && (
        <>
          {LocalBankInfo.length !== 0 ? (
            LocalBankInfo?.map((item) => (
              <div key={item.id}>
                <div className="flex justify-between items-center">
                  <p></p>
                  <h1 className="text-center font-bold md:text-[1.3rem]">
                    Local Bank
                  </h1>
                  <p
                    className={`text-[0.9rem] text-white p-1 ${
                      item?.isActive === true ? "bg-primary" : "bg-[#d64242]"
                    } 
      pl-4 pr-4 rounded-md text-white focus:border-none font-bold capitalize`}
                  >
                    {item?.isActive === false ? "InActive" : "Active"}
                  </p>
                </div>
                <form onSubmit={nextHandler} action="" className=" mt-10">
                  <div className="flex justify-center items-center">
                    <div className="w-[100%]">
                      <div>
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Name of Bank
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          placeholder="Name of Bank"
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "bankName",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.countrybank?.name}
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Branch Name
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          placeholder="Branch Name"
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "branchName",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.countrybankbranch?.name}
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Address of Branch
                        </h2>
                        <textarea
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "bankAddress",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.countrybankbranch?.address}
                          placeholder="Address of Branch"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Name(s) on Account
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "accountTitle",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.accountTitle}
                          placeholder="Name(s) on Account"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2
                          id="message1"
                          className="mb-4 text-[1.1rem] font-semibold"
                        >
                          Bank Account No
                        </h2>
                        <input
                          type="text"
                          name=""
                          id="password"
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "accountNo",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.accountNo}
                          placeholder="Bank Account No"
                          className={` ${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2
                          id="message2"
                          className="mb-4 text-[1.1rem] font-semibold"
                        >
                          Confirm Bank Account No
                        </h2>
                        <input
                          type="text"
                          name=""
                          id="validate"
                          value={item?.accountNo}
                          onChange={valHandler}
                          placeholder="Confirm Bank Account No"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Routing #
                        </h2>
                        <input
                          type="text"
                          name=""
                          
                          // onChange={handleChange(setRoutingNo)}
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "routingNo",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.routingNo}
                          placeholder="Routing #"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Percentage
                        </h2>
                        <input
                          disabled={user === "pensioner" ? true : false}
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleInputChange(
                              setLocalBankInfo,
                              "percentage",
                              e.target.value,
                              item.id
                            )
                          }
                          value={`${item?.percentage}%`}
                          placeholder="Percentage"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />
                      </div>
                      <div>
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Bank Account Type
                        </h2>
                        {}
                        <div
                          className={`${allDis ? "pointer-events-none" : ""}`}
                        >
                          <div className="mb-4 mt-2">
                            <input
                              type="radio"
                              id="saving"
                              name="fav_language"
                              value="Saving"
                              checked={
                                item?.accountType === "Saving" ? true : false
                              }
                              // onChange={checkHandler}
                              onChange={(e) =>
                                checkHandler(
                                  setLocalBankInfo,
                                  "accountType",
                                  e.target.value,
                                  item.id
                                )
                              }
                            ></input>
                            <label
                              htmlFor="saving"
                              className="mr-8 ml-1 text-[0.9rem]"
                            >
                              Saving
                            </label>
                            <input
                              type="radio"
                              id="chequing"
                              name="fav_language"
                              // onChange={checkHandler}
                              onChange={(e) =>
                                checkHandler(
                                  setLocalBankInfo,
                                  "accountType",
                                  e.target.value,
                                  item.id
                                )
                              }
                              checked={
                                item?.accountType === "Chequing" ? true : false
                              }
                              value="Chequing"
                            ></input>
                            {}
                            <label
                              htmlFor="chequing"
                              className=" ml-1 text-[0.9rem]"
                            >
                              Chequing
                            </label>
                          </div>
                        </div>
                        <h2 className="mb-4 text-[1.1rem] font-semibold">
                          Home Address
                        </h2>
                        <textarea
                          type="text"
                          name=""
                          
                          onChange={(e) =>
                            handleChange(
                              setLocalBankInfo,
                              "homeAddress",
                              e.target.value,
                              item.id
                            )
                          }
                          value={item?.homeAddress}
                          placeholder="Home Address"
                          className={`${
                            allDis ? "pointer-events-none" : ""
                          } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                        />

                        <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                          SURRENDER OF VOUCHER DETAILS
                        </h2>
                        <p className="text-[0.9rem]  mb-4">
                          I {namePerson} of my ownn free will Surrender my
                          voucher to MHSSS
                        </p>
                        <div className="ml-4">
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                setOne(true);
                              } else {
                                setOne(false);
                              }
                            }}
                            checked={one}
                            defaultChecked={one}
                            className={"checkk2"}
                            style={{
                              pointerEvents: `${allDis ? "none" : "auto"}`,
                            }}
                          >
                            I agree to the statement above
                          </Checkbox>
                        </div>
                        {namePerson && (
                          <div>
                            <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                              SURRENDER OF BANK INFORMATION DETAILS
                            </h2>
                            <p className="text-[0.9rem] mb-4">
                              I {namePerson} of my ownn free will Surrender my
                              voucher to MHSSS
                            </p>
                            <div className="ml-4">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setTwo(true);
                                  } else {
                                    setTwo(false);
                                  }
                                }}
                                checked={two}
                                defaultChecked={two}
                                className={"checkk2"}
                                style={{
                                  pointerEvents: `${allDis ? "none" : "auto"}`,
                                }}
                              >
                                I agree to the statement above
                              </Checkbox>
                            </div>
                          </div>
                        )}
                        <h2 className="mb-4 mt-6 text-[1.1rem] font-semibold">
                          WAIVING MY RIGHTS TO BE PAID IN PERSON IN ACCORDANCE
                          WITH THE OLD AGE PENSIONER ACT, CAP. 36:30
                        </h2>
                        <p className="text-[0.9rem] mb-4">
                          I {namePerson} of my ownn free will Surrender my
                          voucher to MHSSS
                        </p>
                        <div className="ml-4">
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                setThree(true);
                              } else {
                                setThree(false);
                              }
                            }}
                            checked={three}
                            defaultChecked={three}
                            style={{
                              pointerEvents: `${allDis ? "none" : "auto"}`,
                            }}
                            className={"checkk2"}
                          >
                            I agree to the statement above
                          </Checkbox>
                        </div>
                        <div className="mt-4 mb-4">
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={sigg}
                            alt="img"
                          />
                        </div>
                        <input
                          type="text"
                          name=""
                          
                          value={`${title} ${fullName}`}
                          className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        iShow ? "" : "hidden"
                      } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                    >
                      <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                        <img
                          className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                          src={cross}
                          onClick={() => setIShow(false)}
                          alt=""
                        />
                        <img
                          className="w-[45rem] h-[28rem]"
                          src={sigg}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {user === "employee" &&
                !privilege?.includes("Edit banking") ? null : (
                  <div className="flex gap-5 lg:w-[50%]">
                    <button
                      onClick={() => {
                        if (item?.isActive === false) {
                          setInactivateModal(true);
                          setBankId(item?.id);
                          setBankStatus(item?.isActive);
                        } else {
                          changeStatus(item?.isActive, item?.id);
                        }
                      }}
                      // onClick={() => { item?.isActive === false ? setInactivateModal(true) : changeStatus(item?.isActive, item?.id) }}
                      // type="submit"
                      className={`${
                        ((profileData.user.status === "pending" ||
                          profileData.user.status === "verified" ||
                          profileData.user.status === "in-review" ||
                          profileData.user.status === "re-submitted" ||
                          profileData.user.status === "deferred") &&
                          dash === "/Dashboard/PensionerAccount") ||
                        user === "pensioner"
                          ? "hidden"
                          : ""
                      } 
                          ${
                            item?.isActive === false
                              ? "bg-primary"
                              : "bg-[#DE2827]"
                          }
                          relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr w-[40%] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      <span className="">{`${
                        item?.isActive === false ? "Activate" : "Inactivate"
                      }`}</span>
                      <div
                        className={`w-[100%] h-[100%] ${
                          isLoading ? "" : "hidden"
                        } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                      ></div>
                    </button>
                    <button
                      onClick={() => {
                        option === "Pensioner"
                          ? router(`/Dashboard/addBank`, {
                              state: {
                                bankInfo: item,
                                type: "Edit",
                              },
                            })
                          : router(`/EDashboard/addBank`, {
                              state: {
                                bankInfo: item,
                                type: "Edit",
                              },
                            });
                      }}
                      // type="submit"
                      className={`${
                        ((profileData.user.status === "pending" ||
                          profileData.user.status === "verified" ||
                          profileData.user.status === "in-review" ||
                          profileData.user.status === "deferred" ||
                          profileData.user.status === "re-submitted") &&
                          dash === "/Dashboard/PensionerAccount") ||
                        item?.isActive === false
                          ? "hidden"
                          : ""
                      } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary w-[40%] text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      <span>Edit</span>
                      <div
                        className={`w-[100%] h-[100%] ${
                          isLoading ? "" : "hidden"
                        } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                      ></div>
                    </button>
                  </div>
                )}
                <br className="mb-[3rem]" />
                <hr className="mb-[3rem]" />
              </div>
            ))
          ) : (
            <h1 className="text-center font-bold text:[1.3rem]">
              No Local Bank Details Added
            </h1>
          )}
        </>
      )}

      <div
        className={`${
          inactivateModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-5 bg-white flex-col">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-3">
            Activate Bank Account
          </label>
          <div className="w-full mb-2">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Percentage <span className="text-red-500">*</span>
            </label>
            <input
              disabled={user === "pensioner" ? true : false}
              type="text"
              name=""
              
              // value={`${item?.percentage}%`}
              onChange={(e) => setPercentage(e.target.value)}
              placeholder="Enter Percentage"
              className={`${
                allDis ? "pointer-events-none" : ""
              } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setInactivateModal(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57d38c] w-[60%]"
              onClick={() => {
                ActivateAccount();
              }}
            >
              Activate
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default Banking;
