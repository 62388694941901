import React, { useEffect, useState } from "react";
import Stores from "../../MerchantEmployees/Profile/Tabs/merchantStores";
import Company from "../../Merchant/Profile/Tabs/Company";
import Headquarter from "../../Merchant/Profile/Tabs/headquater";
import circle from "../../../imgs/circle.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
const Main = () => {
  const location = useLocation();
  const { totalOutStanding, merchuser } = location.state || {};
  console.log(totalOutStanding, merchuser);
  const [active, setActive] = useState("1");
  const [company, setCompany] = useState("");
  const mhhsMerchUser = useSelector((state) => state.mhhsMerchUser);
  const mhhsMerchEmpId = useSelector((state) => state.mhhsMerchEmpId);
  const mhhsMerchId = useSelector((state) => state.mhhsMerchId);
  const id =
    mhhsMerchUser === "merchant-employee" ? mhhsMerchEmpId : mhhsMerchId;
  // const [storesTotalPages, setStoresTotalPages] = useState(0);
  const storesCurrentPage = 1;
  const dataUser = useSelector((state) => state.dataUser);
  const [merchStores, setMerchStores] = useState([]);
  const [headquarter, setHeaqQuarter] = useState();
  const merchantInfo = {};
  const selectedOutstandingStatus = useSelector(
    (state) => state.selectedOutstandingStatus
  );
  console.log(selectedOutstandingStatus);
  const tabs = [
    {
      key: "1",
      label: "Company Details",
      component: <Company company={company} />,
      user: "merchant-owner",
    },

    {
      key: "2",
      label: "Headquarter",
      component: (
        <Headquarter
          headquarter={headquarter}
          mailAddress={headquarter?.merchheadquarterMailaddrs?.[0]}
          // company={merchantInfo?.merchCompany}
        />
      ),
      user: "merchant-owner",
    },
    {
      key: "3",
      label: "Stores",
      component: <Stores stores={merchStores} />,
      user: "merchant-owner",
    },
  ];

  //Get Compnay
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-company/for-mhsss?merchUserId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setCompany(data.data);
      })
      .catch((err) => {});
  }, []);

  //Get Stores
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-store/for-mhsss`, {
        params: {
          limit: 3,
          skip: 0,
          page: storesCurrentPage,
          sortBy: "createdAt:desc",
          merchUserId: id,
        },
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        setMerchStores(data?.data?.rows);
        // setStoresTotalPages(data?.data?.totalPages);
      })
      .catch(() => {});
  }, [storesCurrentPage]);

  //Get HeadQuarter
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter/for-mhsss?merchUserId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setHeaqQuarter(data.data);
      })
      .catch(() => {});
  }, []);

  const DisplayDiv = ({ title, content }) => {
    return (
      <div className="sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3 gap-2 w-full">
        <dt className="text-sm font-medium text-gray-700 font-semibold mb-2 sm:mb-0">
          {title}
        </dt>
        <dd className="mt-0 text-md text-gray-900 sm:mt-0 sm:col-span-2 font-bold">
          {content}
        </dd>
      </div>
    );
  };
  return (
    <div className="p-10">
      <div>
        <div className="relative w-full h-auto pb-[2rem] pl-[3rem] pr-[3rem] pt-[2rem] bg-[#D9EFED]">
          <div>
            <img
              src={circle}
              alt=""
              className="absolute sm:top-[-10%] sm:left-[-3%] sm:w-[10rem] sm:h-[10rem] sm:block hidden"
            />
            <img
              src={circle}
              alt=""
              className="absolute sm:hidden top-[-5%]  left-1/2 transform -translate-x-1/2 w-[8rem] h-[8rem]"
            />

            <img
              src={company?.logo}
              alt="profile"
              className="hidden sm:block absolute sm:top-[-10%] sm:left-[-3%] sm:w-[10rem] sm:h-[10rem] rounded-full p-2"
            />

            <img
              src={company?.logo}
              alt="profile"
              className="absolute sm:hidden rounded-full p-2 top-[-5%]  left-1/2 transform -translate-x-1/2 w-[8rem] h-[8rem]"
            />
            <div className="hidden sm:block absolute text-center sm:top-[9rem] top-[6rem]  sm:left-5 ">
              <h2 className="text-md font-semibold">
                {company !== null ? company?.name : "------"}
              </h2>

              <p
                className={`${
                  merchantInfo?.merchUser?.status === "rejected"
                    ? "text-red-600"
                    : merchantInfo?.merchUser?.status === "approved"
                    ? "text-primary"
                    : "text-blue-600"
                }  font-semibold text-md capitalize`}
              >
                {merchantInfo?.merchUser?.status}
              </p>
            </div>
            <div className="absolute sm:hidden rounded-full p-2 left-1/2 transform -translate-x-1/2 absolute text-center sm:top-[9rem] top-[6rem]  sm:left-5 ">
              <h2 className="text-md font-semibold">
                {company !== null ? company?.name : "------"}
              </h2>

              <p
                className={`${
                  merchantInfo?.merchUser?.status === "rejected"
                    ? "text-red-600"
                    : merchantInfo?.merchUser?.status === "approved"
                    ? "text-primary"
                    : "text-blue-600"
                }  font-semibold text-md capitalize`}
              >
                {merchantInfo?.merchUser?.status}
              </p>
            </div>
          </div>
          <div className="flex items-center sm:ml-32 sm:mt-0 mt-32">
            <div className="flex sm:gap-1 flex-col sm:flex-row">
              <div>
                <h3 className="text-lg sm:font-semibold font-bold sm:mb-1 mb-1 text-primary sm:px-3">
                  Personal Info
                </h3>
                {/* <DisplayDiv
                  title="Name"
                  content={
                    merchuser?.merchperson
                      ? `${merchuser?.merchperson?.firstName || "----"} 
      ${merchuser?.merchperson?.middleName || ""} 
      ${merchuser?.merchperson?.lastName || ""}`
                      : ""
                  }
                /> */}
                <DisplayDiv
                  title="Date of Birth"
                  content={merchuser?.merchperson?.dateOfBirth}
                />
                <DisplayDiv title="Email" content={merchuser?.email} />
                <DisplayDiv
                  title="Phone No"
                  content={
                    merchuser?.countryCode && merchuser?.phoneNo
                      ? `${merchuser.countryCode} ${merchuser.phoneNo}`
                      : " "
                  }
                />
              </div>
              <div className="mt-3 sm:mt-0">
                <h3 className="text-lg sm:font-semibold font-bold  sm:mb-1 mb-1 text-primary sm:px-3">
                  Other Info
                </h3>

                <DisplayDiv
                  title="Total Outstanding"
                  content={`$${totalOutStanding}`}
                />
                <DisplayDiv
                  title="Last Transaction"
                  content={moment(merchuser?.[0]?.createdAt)?.format(
                    "DD[th] MMM , YYYY"
                  )}
                />
                <DisplayDiv
                  title="Cashing Charges"
                  content={`$${
                    selectedOutstandingStatus?.feeAmount +
                    " " +
                    selectedOutstandingStatus?.feeType
                  }`}
                />
                <DisplayDiv
                  title="Payment Terms"
                  content={`NET ${selectedOutstandingStatus?.paymentTerms}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Tabs */}
      <div>
        <ul className="grid grid-flow-col text-center border-b border-gray-200 text-gray-500 mt-4">
          {tabs.map((tab) => (
            <li
              key={tab.key}
              onClick={() => setActive(tab.key)}
              className={`flex justify-center border-b-2 text-md gap-2 ${
                active === tab.key
                  ? "border-primary text-primary font-semibold text-[1rem]"
                  : "border-transparent"
              } hover:text-primary hover:border-primary py-2`}
            >
              {tab.label}
            </li>
          ))}
        </ul>

        {/* Show the selected component */}
        <div className="sm:p-10 py-2">
          {tabs.find((tab) => tab.key === active)?.component}
        </div>
      </div>
    </div>
  );
};

export default Main;
