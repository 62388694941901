import { useLocation } from "react-router-dom";
import { Checkbox, DatePicker } from "antd";

const Residance = () => {
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div>
      <h2 className="mb-8 text-[1.5rem] font-bold mt-8">Residance</h2>
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Were you absent from The Country in the last 5 years?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <DatePicker
        style={{ marginBottom: "1.2rem" }}
        className={`${
          dash === "/Dashboard/application" ? "date-class1" : "date-class"
        } `}
        placeholder="From"
      />
      <DatePicker
        style={{ marginBottom: "1.2rem" }}
        className={`${
          dash === "/Dashboard/application" ? "date-class1" : "date-class"
        } `}
        placeholder="To"
      />
      <input
        type="text"
        name=""
        
        placeholder="Location"
        className={`lg:mb-5 ${
          dash === "/Dashboard/application"
            ? "lg:text-[1.1rem]"
            : "lg:text-[1.1rem]"
        } w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
      />
      <input
        type="text"
        name=""
        
        placeholder="Reason"
        className={`lg:mb-5 ${
          dash === "/Dashboard/application"
            ? "lg:text-[1.1rem]"
            : "lg:text-[1.1rem]"
        } w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
      />
      <h2
        className={`mb-4 ${
          dash === "/Dashboard/application" ? "text-[1.1rem]" : "text-[1.1rem] "
        } font-semibold`}
      >
        Number of months in the last 5 years out of the country
      </h2>
      <input
        type="text"
        name=""
        
        placeholder="Number of years"
        className={`lg:mb-5 ${
          dash === "/Dashboard/application"
            ? "lg:text-[1.1rem]"
            : "lg:text-[1.1rem]"
        } w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
      />
    </div>
  );
};
export default Residance;
