// import prof from "../../../imgs/prof.png";
import { Pagination, Popover } from "antd";
// import bs1 from "../../../imgs/bs1.png";
// import bs2 from "../../../imgs/bs2.png";
// import bs3 from "../../../imgs/bs3.png";
// import bs4 from "../../../imgs/change.png";
// import form from "../../../imgs/form.png";
// import perso from "../../../imgs/perso.png";
// import dis from "../../../imgs/dis.png";
// import p4 from "../../../imgs/p4.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import SearchFilter from "./SearchFilter";
const Paget = ({ search, setSearch }) => {
  const token = useSelector((state) => state.token);
  const totalPages = 1;
  const [page, setPage] = useState(1);
  const [pensioners, setPensioners] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    dispatch({ type: "SELECTED_PENSIONER", num: {} });
    dispatch({ type: "SELECTED_PENSIONER_VOUCHERS", num: [] });
    dispatch({ type: "ACTIVE_PENSIONER_SESSION", num: null });
    dispatch({ type: "ACTIVE_VOUCHERS", num: [] });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-pensioner-search-history/own`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPensioners(response?.data);
      })
      .catch((error) => {
        setSearch("");
        console.log(error);
      });
  }, [refetch]);

  // const customItemRender = (current, type, originalElement) => {
  //   return (
  //     <span className="bg-red-500 text-white px-3 py-2 rounded-md">
  //       {current}
  //     </span>
  //   );

  //   return originalElement;
  // };

  const router = useNavigate();
  const dispatch = useDispatch();
  // const option2 = useSelector((state) => state.option2);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const pageHandler = (e) => {
    setPage(e);
  };
  const columnHeaders = [
    "Sr. No",
    "Name",
    "Pensioner ID",
    "GID",
    "Gender",
    // "Booklet No.",
    "Phone",
    "DOB",
    "Status",
    "Created Date/Time",
  ];

  return (
    <section className="p-2">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-green-100 ">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            {/* search */}
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by GID, First Name or Last Name"
                    required=""
                    value={search}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
            {/* filter */}
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              {/* <button
                type="button"

                className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none"
              >
                <svg
                  className="h-3.5 w-3.5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add product
              </button> */}

              <div className="flex items-center space-x-3 w-full md:w-auto">
                {/* <button
                  id="actionsDropdownButton"
                  data-dropdown-toggle="actionsDropdown"
                  className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                  type="button"
                >
                  <svg
                    className="-ml-1 mr-1.5 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    />
                  </svg>
                  Actions
                </button>
                <div
                  id="actionsDropdown"
                  className="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow"
                >
                  <ul
                    className="py-1 text-sm text-gray-700"
                    aria-labelledby="actionsDropdownButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block py-2 px-4 hover:bg-gray-100 "
                      >
                        Mass Edit
                      </a>
                    </li>
                  </ul>
                  <div className="py-1">
                    <a
                      href="#"
                      className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Delete all
                    </a>
                  </div>
                </div> */}
                <div className="relative">
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                    type="button"
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Search By
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>

                  {show && (
                    <div
                      id="filterDropdown"
                      className="z-20 absolute top-full right-0 mt-2 w-[20rem] p-3 bg-white rounded-lg shadow "
                    >
                      <SearchFilter
                        setPensioners={setPensioners}
                        setRefetch={setRefetch}
                        refetch={refetch}
                        setShow={setShow}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider"
                    >
                      {columnHeader}
                    </th>
                  ))}
                  {/* <th scope="col" className="px-4 py-3">Product name</th>
                            <th scope="col" className="px-4 py-3">Category</th>
                            <th scope="col" className="px-4 py-3">Brand</th>
                            <th scope="col" className="px-4 py-3">Description</th>
                            <th scope="col" className="px-4 py-3">Price</th>
                            <th scope="col" className="px-4 py-3">
                                <span className="sr-only">Actions</span>
                            </th> */}
                </tr>
              </thead>
              {pensioners?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center text-gray-500 text-lg"
                    >
                      <p className="p-10">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {pensioners
                    ?.filter((pen) =>
                      `${pen?.user?.person?.firstName} -${pen?.user?.person?.lastName} - ${pen?.user?.gid}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((pen, index) => (
                      <tr
                        className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                        onClick={() => {
                          dispatch({ type: "SELECTED_PENSIONER", num: pen });
                          dispatch({
                            type: "SELECTED_PENSIONER_PROGRAMS",
                            num: pen?.pensionPrograms,
                          });
                          router(
                            `/Merchant/PensionerProfile/${pen?.user?.person?.id}`
                          );
                        }}
                      >
                        <th
                          scope="row"
                          className="px-3 py-4 font-medium text-gray-900  whitespace-nowrap "
                        >
                          {index + 1 + (page - 1) * 6}.
                        </th>

                        <td className="px-4 py-5 flex items-center">
                          <img
                            className="w-10 h-10 rounded-full mr-4"
                            src={pen?.user?.person?.image}
                            alt={`${pen?.user?.person?.firstName} ${pen?.user?.person?.lastName}`}
                          />
                          <div>
                            {pen?.user?.person?.firstName}{" "}
                            {pen?.user?.person?.lastName}
                          </div>
                        </td>
                        <td className="px-4 py-5">
                          <Popover content={pen.uniqueId}>
                            <div className="inline-block max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                              {pen?.user?.uniqueId.substring(0, 5)}..
                            </div>
                          </Popover>
                        </td>
                        <td className="px-4 py-5">{pen?.user?.gid}</td>
                        <td className="px-4 py-5">
                          {pen?.user?.person === null
                            ? "----"
                            : `${pen?.user?.person.gender}`}
                        </td>
                        {/* <td className="px-4 py-5">
                          <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 text-center rounded-md  focus:border-none cursor-pointer">
                            145352
                          </div>
                        </td> */}
                        <td className="px-4 py-5">
                          {pen?.user?.countryCode !== null
                            ? pen?.user?.countryCode + " " + pen?.user?.phoneNo
                            : "----"}
                        </td>
                        <td className="px-4 py-5">
                          {pen?.person?.dateOfBirth}
                        </td>
                        <td className="px-4 py-5">
                          <Popover
                            content={`${
                              pen.status === "pending" ? "In-Process" : ""
                            }`}
                            placement="bottomLeft"
                          >
                            <div
                              className={`border-none ${
                                pen?.user?.status === "pending" &&
                                !pen.isDisabled
                                  ? "bg-[#2F84F1]"
                                  : pen?.user?.isDisabled ||
                                    pen?.user?.status === "rejected"
                                  ? "bg-[#D64242]"
                                  : " bg-primary"
                              }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
                            >
                              {pen?.user?.isDisabled
                                ? "Disabled"
                                : pen?.user?.status}
                            </div>
                          </Popover>
                        </td>
                        <td className="px-4 py-5">
                          <p>{moment(pen?.createdAt).format("MM/DD/YYYY")}</p>
                          <p> {moment(pen?.createdAt).format("hh:mm A")}</p>
                        </td>
                      </tr>
                    ))}

                  {/* Conditional rendering of a div when the length is zero */}
                  {pensioners &&
                    pensioners?.filter((pen) =>
                      `${pen?.user?.person?.firstName} -${pen?.user?.person?.lastName} - ${pen?.user?.gid}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ).length === 0 && (
                      <tr>
                        <td
                          colSpan="9"
                          className="text-center text-gray-500 py-10 text-lg"
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Paget;
