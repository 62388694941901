import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import wallet from "../../../../imgs/wallet.png";
import CashMethods from "./cash/CashMethods";
import EwalletModal from "./eWalletCash/eWalletModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Main = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [selectedPayment, setSelectedPayment] = useState("");
  const [show, setShow] = useState(false);
  const totaPayment = useSelector((state) => state.totalPayment);
  const [selectedBankId, setSelectedBankId] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const selectedOutstandingStatus = useSelector(
    (state) => state.selectedOutstandingStatus
  );
  const selectedOutstandingMerchant = useSelector(
    (state) => state.selectedOutstandingMerchant
  );
  console.log(selectedOutstandingStatus);
  const handlePaymentClick = (paymentType) => {
    setSelectedPayment(paymentType);
  };
  const [defaultMethod, setDefaultMethod] = useState("");
  const mhhsMerchUser = useSelector((state) => state.mhhsMerchUser);
  const mhhsMerchEmpId = useSelector((state) => state.mhhsMerchEmpId);
  const mhhsMerchId = useSelector((state) => state.mhhsMerchId);
  const merchantId =
    mhhsMerchUser === "merchant-employee" ? mhhsMerchEmpId : mhhsMerchId;
  const [banking, setBanking] = useState("");
  const [ewallet, setEwallet] = useState("");
  const [showWallet, setShowWallet] = useState(false);
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/MHSSS/paymentReceipt");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const paymentOptions = [
    {
      label: "Bank",
      value: "bank",
      onClick: () => {
        setSelectedBankId("");
        handlePaymentClick("Bank");
      },
    },
    {
      label: "SAP-Wallet",
      value: "sap wallet",
      onClick: () => {
        setSelectedBankId("");
        handlePaymentClick("e-Wallet");
        router("/MHSSS/ewalletPay");
      },
    },
    {
      label: "Cheque",
      value: "cheque",
      onClick: () => {
        setSelectedBankId("");
        handlePaymentClick("Cheque");
        router("/MHSSS/chequePay");
      },
    },
    // {
    //   label: "Cash",
    //   value: "cash via QR",
    //   onClick: () => {
    //     setSelectedBankId("");
    //     handlePaymentClick("Cash");
    //   },
    // },
    // {
    //   label: "Cash/E-Wallet",
    //   value: "cash/e-wallet",
    //   onClick: () => {
    //     setSelectedBankId("");
    //     handlePaymentClick("Cash/e-Wallet");
    //   },
    // },
  ];

  useEffect(() => {
    if (selectedOutstandingStatus) {
      if (selectedOutstandingStatus?.defaultBankInfoId) {
        setDefaultMethod("Bank");
        Banking();
      }
      if (selectedOutstandingStatus?.defaultChequeInfo) {
        setDefaultMethod("Cheque");
        Cheque();
      }
      if (selectedOutstandingStatus?.defaultSapWalletInfo) {
        setDefaultMethod("SAP-Wallet");
        EWallet();
      }
    }
  }, []);

  //getBanking
  const Banking = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setBanking(data.data);
      })
      .catch(() => {});
  };

  //getCheque
  const Cheque = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        // setCheque(data.data);
      })
      .catch(() => {});
  };

  //get E-wallet
  const EWallet = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/${selectedOutstandingStatus?.defaultSapWalletInfo}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setEwallet(data.data);
      })
      .catch(() => {});
  };

  const maskAccountNumber = (accountNumber) => {
    let maskedNumber = "";
    for (let i = 0; i < accountNumber?.length; i++) {
      if (i < accountNumber?.length - 4) {
        maskedNumber += "X";
      } else {
        maskedNumber += accountNumber[i];
      }
    }
    return maskedNumber;
  };

  return (
    <div className="flex flex-col w-full min-h-[82vh] relative rounded-md bg-white shadow-lg flex pt-4 pb-10 p-6">
      <div className="p-2 mt-2">
        <h1 className="font-semibold">
          Total Amount to be Paid
          <span className="ml-4 font-bold text-primary text-[18px]">{`$${totaPayment}`}</span>
        </h1>
      </div>
      <h1 className="font-bold p-2 mb-1 text-lg">Default Payment Method</h1>

      <div
        className={`${
          defaultMethod === "Cheque"
            ? "hidden"
            : "bg-[#D9EFED] md:w-[40%] sm:w-[60%] w-[80%] relative rounded-lg p-3 cursor-pointer h-auto"
        }`}
      >
        <div className="flex flex-row items-center p-2 ">
          <div
            className={` bg-red-500  top-5 flex items-center justify-center rounded-full w-14 h-14`}
          >
            {/* <span className="text-white text-lg font-semibold flex items-center justify-center">
              {banking?.accountTitle?.charAt(0)}
            </span> */}
            <img
              src={
                defaultMethod === "Bank" ? banking?.countrybank?.logo : wallet
              }
              alt="logo"
              className={`"w-14 h-14 ${
                defaultMethod !== "Bank" ? "rounded-full" : "rounded-full"
              }`}
            />
          </div>
          <div className="flex items-center ml-4">
            <div className="ml-3 flex flex-col mb-4">
              <p className="font-semibold text-lg capitalize">
                {defaultMethod === "Bank"
                  ? selectedOutstandingMerchant?.merchuser?.merchcompany?.name
                  : ewallet?.phone}
              </p>
              <p className="text-sm">{banking?.countrybank?.name}</p>
              <p>
                {defaultMethod === "Bank"
                  ? maskAccountNumber(banking?.accountNo)
                  : ewallet?.email}
              </p>
            </div>
          </div>

          <div className="absolute bottom-0 right-0 bg-primary text-white p-1 rounded-tl-lg rounded-br-lg px-2">
            {defaultMethod}
          </div>
        </div>
      </div>

      {defaultMethod === "Cheque" && (
        <div className="bg-[#F6F4F4] w-[60%] relative rounded-lg p-3">
          <button
            className={`
            bg-primary text-white              
            rounded-full px-6 py-1 font-semibold`}
            onClick={() => {
              // dispatch({
              //   type: "SELECTED_PAYMENT_METHOD",
              //   num: "cheque",
              // });
              // setSelectedBankId("");
              // handlePaymentClick("Cheque");
              // router("/MHSSS/chequePay");
            }}
          >
            Cheque
          </button>
        </div>
      )}

      <h1 className="font-bold p-2 mb-1 text-lg mt-3">Other Payment Methods</h1>

      <div className="sm:w-[60%] w-[80%] bg-[#F6F4F4] rounded-lg gap-6 grid  sm:grid-cols-4 grid-cols-2 p-4">
        {paymentOptions
          .filter((option) => option.label !== defaultMethod)
          .map((option, index) => (
            <button
              key={index}
              className={`
            ${
              defaultMethod === option.label
                ? "bg-primary text-white"
                : "bg-white pointer-events-none text-gray-500"
            }
            rounded-full px-4 py-2 font-semibold`}
              onClick={() => {
                option.onClick();
                dispatch({
                  type: "SELECTED_PAYMENT_METHOD",
                  num: option.value,
                });
              }}
            >
              {option.label}
            </button>
          ))}
      </div>
      <div className="flex justify-center mt-7">
        <button
          onClick={() => {
            if (defaultMethod === "Bank") {
              router("/MHSSS/bankPay");
              dispatch({
                type: "SELECTED_PAYMENT_METHOD",
                num: "bank",
              });
              setSelectedPayment("bank");
              // setShowConfirm(true);
              setSelectedBankId("");
              handlePaymentClick("Bank");
            } else if (defaultMethod === "SAP-Wallet") {
              dispatch({
                type: "SELECTED_PAYMENT_METHOD",
                num: "sap-wallet",
              });
              setSelectedPayment("SAP-Wallet");
              setSelectedBankId("");
              handlePaymentClick("sap Wallet");
              // router("/MHSSS/ewalletPay");
              setShowWallet(true);
            } else if (defaultMethod === "Cheque") {
              dispatch({
                type: "SELECTED_PAYMENT_METHOD",
                num: "cheque",
              });
              setSelectedBankId("");
              handlePaymentClick("Cheque");
              router("/MHSSS/chequePay");
            }
          }}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>

      {/* {selectedPayment === "Bank" && (
        <Bank
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          data={banking}
        
        />
      )} */}
      {selectedPayment === "Cash" && <CashMethods />}

      {selectedPayment === "Cash/e-Wallet" && <CashMethods />}
      {selectedPayment === "sap Wallet" && (
        <EwalletModal showWallet={showWallet} setShowWallet={setShowWallet} />
      )}
    </div>
  );
};

export default Main;
