import React from "react";
import PageHeader from "./pageHeader/pageHeader";
import Table from "./table";
import { useSelector } from "react-redux";

const SelectLocation = () => {
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader
        header={true}
        title={`${formatProgramNames(selectedProgram)} Verification Queue`}
        route={"/pensionerVerification/selectedProgram"}
      />

      <div className="flex justify-center">
        <div className={`sm:w-[95%] w-full`}>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
