import React, { useState } from "react";
import { FaWhatsapp, FaEnvelope, FaLink } from "react-icons/fa";
import loc from "../../../imgs/loc.png";
import group from "../../../imgs/group.png";
import { Button } from "antd";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EventDetails = ({ data }) => {
  const router = useNavigate();
  const sub = useSelector((state) => state.subEvent);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [copied, setCopied] = useState(false);

  const currentUrl = window.location.href;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleShare = (platform) => {
    let shareUrl = "";
    if (platform === "whatsapp") {
      shareUrl = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`;
    } else if (platform === "email") {
      shareUrl = `mailto:?subject=Check this out&body=${encodeURIComponent(
        currentUrl
      )}`;
    }
    window.open(shareUrl, "_blank");
  };
  const containerStyle = {
    width: "100%",
    height: "250px",
  };

  const center = {
    lat: parseFloat(data.coordinates.split(", ")[0]),
    lng: parseFloat(data.coordinates.split(", ")[1]),
  };

  return (
    <div className="flex w-full pb-[5rem] items-center justify-center">
      <div className="w-full flex flex-col mt-4 w-full ">
        <h1 className="text-2xl font-semibold ">
          {data.name}{" "}
          <span className="ml-3 text-sm bg-gray-200 rounded-full pl-4 pr-4">
            {data.Type}
          </span>
        </h1>
        <div className="flex gap-10 mt-1">
          <div className="flex gap-2">
            <img src={loc} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">{data.location}</p>
          </div>
          <div className="flex gap-2">
            <img src={group} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">
              {data.members} Guest capacity
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-end mt-4">
          <Button className="border border-dotted bg-gray-50 hover:bg-gray-200 cursor-pointer text-black font-semibold">
            See All Photos
          </Button>
        </div>
        <div key={data.id} className="w-full flex gap-2 mt-2">
          <div className="w-[60%] h-[320px]">
            <img
              className="w-full h-full rounded-xl object-cover"
              src={data.coverPhoto}
              alt={data.name}
            />
          </div>
          <div className="w-[40%] h-[320px] flex flex-wrap gap-2">
            <img
              className="w-[49%] h-[48%] rounded-xl object-cover"
              src={data.images[0]}
              alt={data.name}
            />
            <img
              className="w-[49%] h-[48%] rounded-xl object-cover"
              src={data.images[1]}
              alt={data.name}
            />
            <img
              className="w-[49%] h-[48%] rounded-xl object-cover"
              src={data.images[2]}
              alt={data.name}
            />
            <img
              className="w-[49%] h-[48%] rounded-xl object-cover"
              src={data.images[3]}
              alt={data.name}
            />
          </div>
        </div>
        <div className="w-full flex gap-4">
          <div className="mt-6 w-[80%]">
            <h1 className="font-semibold text-gray-800 text-lg">Description</h1>
            <p className="mb-10">
              Join us at the Tech Innovators Conference 2024 in New York, NY,
              from June 1st to June 3rd. This event brings together leading
              minds in technology to discuss the latest advancements, share
              innovative ideas, and network with industry professionals. Don't
              miss the keynote sessions, hands-on workshops, and opportunities
              to connect with fellow tech enthusiasts.
            </p>
            <LoadScript googleMapsApiKey="AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="w-[20%] p-6 flex flex-col items-center justify-center rounded-xl bg-gray-100 mt-6">
            <p className="font-semibold">
              Total of <b className="underline">{data.members}</b> members can
              attend this meeting between...
            </p>
            <div className="w-full mt-4">
              {/* <p className="pl-3 text-center font-semibold pr-3">
                From
              </p> */}
              <p className="pl-3 font-semibold pr-3 text-center bg-secondary rounded-full">
                {data.startDate}
              </p>
              <p className="pl-3 text-center font-semibold pr-3">-</p>
              <p className="pl-3  text-center font-semibold pr-3 bg-secondary rounded-full">
                {data.endDate}
              </p>
            </div>
            <div className="flex flex-col w-[70%] gap-6 mt-5">
              <button
                className={`text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
              >
                Join Now
              </button>
              <button
                onClick={() => {
                  setShowPopup(true);
                }}
                className={`text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
              >
                Share
              </button>
            </div>
            {!sub && (
              <div className="mt-4">
                <p
                  onClick={() => {
                    dispatch({ type: "SUB" });
                  }}
                  className="font-semibold  text-primary cursor-pointer hover:underline"
                >
                  View Sub Event
                </p>
              </div>
            )}
            {!sub && (
              <div className="mt-2">
                <p
                  onClick={() => {
                    router("/addSubEvent");
                  }}
                  className="font-semibold  text-primary cursor-pointer hover:underline"
                >
                  Add Sub Event
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 w-[32%] rounded-lg shadow-lg">
            <div className="mb-4 w-full flex gap-2 items-center justify-center">
              <input
                type="text"
                value={currentUrl}
                readOnly
                className="w-[80%] p-2 border rounded-lg"
              />
              <button
                onClick={handleCopyLink}
                className="bg-blue-500 text-white w-[20%] p-2 rounded-lg ml-2"
              >
                {copied ? "Copied!" : "Copy"}
              </button>
            </div>
            <div className="flex justify-around mt-4">
              <button
                className="text-green-500"
                onClick={() => handleShare("whatsapp")}
              >
                <FaWhatsapp size={32} />
              </button>
              <button
                className="text-red-500"
                onClick={() => handleShare("email")}
              >
                <FaEnvelope size={32} />
              </button>
              <button
                className="text-blue-500"
                onClick={() => handleCopyLink()}
              >
                <FaLink size={32} />
              </button>
            </div>
            <button
              className="mt-4 bg-red-500 text-white p-2 rounded-lg w-full"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
