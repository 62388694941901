import React from 'react'
import PaymentReceipt from "../components/MHSSS Accounting/outstanding/paymentReceipt/paymentRecepit"

const paymentM = () => {
  return (
    <div>
     <PaymentReceipt/>
    </div>
  )
}

export default paymentM