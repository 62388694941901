import map from "../../imgs/map.png";
import emblem from "../../imgs/emblem.png";
import background from "../../imgs/background.png";
import spLogo from "../../imgs/spLogo.png";
import Logotransparent from "../../imgs/Logotransparent.png";
import icon from "../../imgs/icon.png";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
const DigitalCard = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  console.log(profileData);
  const [signature, setSignature] = useState("");
  const Div = ({ title, content }) => {
    return (
      <div className="flex">
        <span className="w-1/3 text-gray-500 font-bold">{title} :</span>
        <span className="w-2/3  uppercase font-bold text-gray-600 ">
          {content}
        </span>
      </div>
    );
  };
  const Card = () => {
    return (
      <div className="w-[82%] text-[9px] flex flex-col gap-0.2">
        <div className="flex">
          <span className="w-1/3 text-gray-500 font-bold">CARD / BOOKNO :</span>
          <span className="w-2/3  uppercase font-bold text-gray-600 ">
            {profileData?.user?.cardNumber?.substring(0, 4)}
            &nbsp;{profileData?.user?.cardNumber?.substring(4, 8)}
            &nbsp;{profileData?.user?.cardNumber?.substring(8, 12)}
            &nbsp;{profileData?.user?.cardNumber?.substring(12, 16)}
          </span>
        </div>
        <div className="flex">
          <span className="w-1/3 text-gray-500 font-bold">NAME :</span>
          <span className="w-2/3  uppercase font-bold text-gray-600 ">
            <span className="font-bold text-gray-900 text-[12px]">
              {profileData?.person?.lastName
                ? profileData?.person?.lastName
                : ""}
            </span>{" "}
            <br />
            {profileData?.person?.firstName
              ? profileData?.person?.firstName
              : ""}{" "}
            {profileData?.person?.middleName && (
              <>{profileData?.person?.middleName}&nbsp;</>
            )}
          </span>
        </div>
        <Div
          title={"DATE OF BIRTH"}
          content={
            profileData?.person?.dateOfBirth
              ? moment(profileData?.person?.dateOfBirth).format("DD MMM,YYYY")
              : ""
          }
        />
        <Div
          title={"GENDER"}
          content={profileData?.person?.gender ? profileData.person.gender : ""}
        />
        <Div
          title={"GOVERNMENT ID"}
          content={profileData?.user?.gid ? profileData?.user?.gid : ""}
        />
        <Div
          title={"PROGRAM"}
          content={
            profileData?.pensionPrograms?.[0]?.name === "senior-citizen-Pension"
              ? "SENIOR CITIZEN PENSION"
              : profileData?.pensionPrograms?.[0]?.name
          }
        />

        <div className="flex">
          <span className="w-1/3 text-gray-500 font-bold">ADDRESS :</span>
          <span className="w-2/3 uppercase font-bold text-gray-600 ">
            {profileData?.person?.addresses?.[0] && (
              <>
                {profileData?.person?.addresses[0]?.regionNo} <br />
                LOT {profileData?.person?.addresses[0]?.lot} ,{" "}
                {profileData?.person?.addresses[0]?.address} <br />
                {profileData?.person?.addresses[0]?.ward} ,{" "}
                {profileData?.person?.addresses[0]?.city}
              </>
            )}
          </span>
        </div>
      </div>
    );
  };
  useEffect(() => {
    let sett;
    if (profileData?.pensionerInfo) {
      if (profileData?.pensionerInfo?.signature !== null) {
        sett = [profileData?.pensionerInfo?.signature];
      } else if (profileData?.pensionerInfo?.leftIndex !== null) {
        sett = [profileData.pensionerInfo.leftIndex];
      } else if (profileData?.pensionerInfo?.rightIndex !== null) {
        sett = [profileData.pensionerInfo.rightIndex];
      } else if (profileData?.pensionerInfo?.leftThumb !== null) {
        sett = [profileData?.pensionerInfo?.leftThumb];
      } else {
        sett = [profileData?.pensionerInfo?.rightThumb];
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
        {
          fileNames: sett,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setSignature(data?.data[0]?.url);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <div>
        <div className="w-[32rem] h-[17.9rem] rounded-xl shadow-2xl overflow-hidden">
          <div className="w-full h-[87%] rounded-tl-xl rounded-tr-xl">
            <div className="w-full h-[24%] bg-[#BE1E2D] rounded-t-xl rounded-bt-xl">
              <div
                className="flex items-center justify-between text-white px-6 py-2"
                style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
              >
                <img className="w-12 h-12 rounded-full" src={spLogo} alt="" />
                <div>
                  <p className="text-center mt-1 font-normal text-xs">
                    CO-OPERATIVE REPUBLIC OF GUYANA{" "}
                  </p>
                  <p className="font-bold text-lg">
                    SENIOR CITIZEN PENSION CARD
                  </p>
                </div>
                <img
                  className="w-12 h-12 rounded-full bg-white p-1"
                  src={emblem}
                  alt=""
                />
              </div>
            </div>
            <div
              className="overflow-hidden relative w-full h-[76%] rounded-bl-xl rounded-br-xl flex justify-center items-center"
              style={{ ontFamily: "Arial, Helvetica, sans-serif", backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
              <div>
                <img
                  className="h-[11rem]  absolute top-[-6%] left-[50%]"
                  src={map}
                  alt=""
                />
              </div>
              {/* <div className="absolute">
              <img
                className="w-[60rem] h-[25rem] opacity-90"
                src={pattern}
                alt=""
              />
            </div>
            <div className="absolute top-[-50%] left-[-70%]">
              <img
                className="w-[60rem] h-[25rem] opacity-90 rotate-10"
                src={pattern}
                alt=""
              />
            </div> */}

              <div className="absolute top-4 left-[5%] ">
                <div className="relative bg-gray-100 w-[6rem] h-[7.5rem]">
                  {profileData?.person?.image && (
                    <img
                      src={profileData?.person?.image}
                      // alt="Your Image"
                      className="mx-auto h-full object-cover z-10"
                    />
                  )}

                  <div className="absolute inset-0">
                    <div className="border-t-[2.3px] border-l-[2.3px] h-6 w-6 border-gray-400 absolute inset-0 top-[-2%] left-[-2%] transform -translate-x-1 -translate-y-1"></div>
                    <div className="border-b-[2.3px] border-r-[2.3px] h-6 w-6 border-gray-400 absolute bottom-[-5%] right-[-5%]"></div>
                      
                  </div>
                </div>

                <img
                  className="w-8 h-8 absolute bottom-[-35%] left-[-10%]"
                  src={icon}
                  alt="star"
                />
                {signature && (
                  <img
                    className="w-[4rem] h-[2rem] transparent-background mt-3 absolute bottom-[-35%] left-[30%] "
                    src={signature}
                    alt=""
                  />
                )}
              </div>

              <div className="absolute top-3 left-[30%] w-[70%]">
                {/* <div className="flex justify-center items-center h-screen"> */}
                <Card />
                {/* </div> */}
              </div>

              {/* <div className="absolute top-4 left-[30%] w-[70%]">
                
                <Div title={"CARD / BOOKNO"} content={"1234 5678 1234 5678"} />

                <div className="grid grid-cols-2 w-[14rem]">
                  <p className="text-[9.5px] font-bold text-gray-500">NAME :</p>
                  <p className="text-[9.5px] font-bold text-gray-600 uppercase">
                    <span className="font-bold text-gray-900">
                      {profileData?.person?.lastName
                        ? profileData?.person?.lastName
                        : ""}
                    </span>{" "}
                    <br />
                  
                    {profileData?.person?.firstName
                      ? profileData?.person?.firstName
                      : ""}{" "}
                    {profileData?.person?.middleName && (
                      <>{profileData?.person?.middleName}&nbsp;</>
                    )}
                  </p>
                </div>
                <Div
                  title={"DATE OF BIRTH"}
                  content={
                    profileData?.person?.dateOfBirth
                      ? moment(profileData?.person?.dateOfBirth).format(
                          "DD MMM,YYYY"
                        )
                      : ""
                  }
                />
                <Div
                  title={"GENDER"}
                  content={
                    profileData?.person?.gender ? profileData.person.gender : ""
                  }
                />
                <Div
                  title={"GOVERNMENT ID"}
                  content={profileData?.user?.gid ? profileData?.user?.gid : ""}
                />
                <Div title={"PROGRAM"} content={"PENSION"} />
                <div className="grid grid-cols-2 w-[14rem]">
                  <p className="text-[9px] font-bold text-gray-500">ADDRESS</p>
                  <p
                    className={`${
                      profileData?.person?.addresses[0]
                        ? "text-[9px] font-bold text-gray-600 uppercase"
                        : "hidden"
                    }`}
                  >
                    {profileData?.person?.addresses[0]?.regionNo} <br />
                    LOT {profileData?.person?.addresses[0]?.lot} <br />
                    {profileData?.person?.addresses[0]?.ward},
                    {profileData?.person?.addresses[0]?.city}
                  </p>
                </div>
              </div> */}

              {/* <div className="absolute top-3 right-[3%] w-[4rem] h-[4rem] border ">
                
                <QRCode
                  style={{ height: "4rem" }}
                  value={profileData?.user?.uniqueId}
                />
               
              </div> */}
              <div className="absolute top-0 right-[3%] w-[4rem] h-[4rem]  ml-[4.5rem] mt-2 flex justify-center items-center">
                {profileData.user && (
                  <QRCode
                    style={{ height: "3rem" }}
                    value={profileData.user.uniqueId}
                  />
                )}
              </div>
              <div className="absolute top-28 right-[2%] w-[3rem] h-[4rem] border opacity-40">
                {profileData.person && (
                  <div className="relative">
                    <img
                      className="w-[3rem] h-[4rem] object-cover"
                      src={profileData.person.image}
                      alt=""
                    />
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                  </div>
                )}
              </div>

              <div className="absolute top-[73%] right-[60%] rounded-full">
                <div className="relative">
                  <img
                    className="w-[2.4rem] h-[2.4rem] object-cover rounded-full"
                    src={Logotransparent}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gray-200 opacity-50 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full h-[13%] bg-[#BE1E2D] rounded-bl-xl rounded-br-xl p-2"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            <p className="text-white text-sm text-center w-[30rem] mb-2 font-sans px-6">
              MINISTRY OF HUMAN SERVICES AND SOCIAL SECURITY
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalCard;
