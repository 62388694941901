import React from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import ErrorModal from "../Merchant/Modals/errorModal";
const SelectLocation = () => {
  const router = useNavigate();
  // const [selectedData, setSelectedData] = useState([]);
  const records = [
    {
      date1: "3/12/22",
      date2: "4/15/22",
      date3: "5/18/22",
      distributionName: "Distribution A",
      status: "Pending",
      programName: "Senior Citizen Pension",
      // verifiedPensioners: 150,
    },
    {
      date1: "4/15/22",
      date2: "5/18/22",
      date3: "6/21/22",
      distributionName: "Distribution B",
      status: "In-Process",
      programName: "Senior Citizen Pension",
      verifiedPensioners: 200,
    },
    {
      date1: "5/18/22",
      date2: "6/21/22",
      date3: "7/24/22",
      distributionName: "Distribution C",
      status: "Pending",
      programName: "Senior Citizen Pension",
      verifiedPensioners: 175,
    },
    {
      date1: "6/21/22",
      date2: "7/24/22",
      date3: "8/27/22",
      distributionName: "Distribution D",
      status: "In-Process",
      programName: "Senior Citizen Pension",
      verifiedPensioners: 220,
    },
    {
      date1: "7/24/22",
      date2: "8/27/22",
      date3: "9/30/22",
      distributionName: "Distribution E",
      status: "Pending",
      programName: "Senior Citizen Pension",
      verifiedPensioners: 190,
    },
  ];

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Scheduled Dates"}
        route={"/pensionerVerificationScheduling/selectLocation"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[90%] w-full`}
        >
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 grid-cols-1  w-full">
                {records.map((record, index) => (
                  <div
                    key={index}
                    onClick={()=>
                    {
                      router("/scheduleVerification/schedulesDetails")
                    }
                    }
                    className="bg-white p-4  cursor-pointer shadow-md rounded-md mb-4"
                  >
                    <div className="flex justify-between mb-2">
                      <h2 className="font-semibold text-lg">
                        {record.distributionName}
                      </h2>
                      <span
                        className={`font-semibold ${
                          record?.status === "In-Process"
                            ? "text-[#E5A608]"
                            : "text-gray-500"
                        }`}
                      >
                        {record.status}
                      </span>
                    </div>
                    <h2 className="font-semibold text-gray-400 mb-1">
                      Scheduled Dates
                    </h2>
                    <div className="mb-2 flex gap-1 w-full items-center justify-between">
                      {[record.date1, record.date2, record.date3].map(
                        (date, idx) => (
                          <div
                            key={idx}
                            className="bg-primary p-2 w-[33%] text-center font-semibold rounded-md text-white mb-1"
                          >
                            {date}
                          </div>
                        )
                      )}
                    </div>
                    <div className="flex justify-between mb-2">
                      <span className="font-semibold text-gray-400">
                        Program
                      </span>
                      <span className="font-medium">{record.programName}</span>
                    </div>
                    {record?.verifiedPensioners && (
                      <div className="flex justify-between">
                        <span className="font-semibold text-gray-400">
                          Verified Pensioners
                        </span>
                        <span className="font-medium">
                          {record.verifiedPensioners}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            router("/scheduleVerification/selectProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div> */}
      {/* {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />} */}
    </div>
  );
};

export default SelectLocation;
