import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import { useNavigate } from "react-router-dom";
const PowerBiPage = () => {
  const router = useNavigate();
  const user = localStorage.getItem("role");
  return (
    <div className="">
      <Nav />
      <div className="relative flex justify-start pl-[10rem] pr-[20rem] items-center text-[1.8rem] font-bold mb-[5rem]">
        <div
          onClick={() => {
            user === "admin"
              ? router("/AdminDashboard")
              : user === "superadmin"
              ? router("/superAdminDashboard")
              : router("/EDashboard");
          }}
          className="cursor-pointer pt-[5rem] mr-5"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <div className="flex pt-[5rem]">
          <h2>Reports</h2>
        </div>
      </div>
      <iframe
        title="Mohsss_Report"
        width="100%"
        height="541.25"
        src="https://app.powerbi.com/view?r=eyJrIjoiMzc2MjIyOTQtNTRmZC00MzUxLWI1ODktMGZmOTY1MGU5Y2M4IiwidCI6IjE1Y2M4YzAxLTQ0M2EtNDNmOS1iODQxLTM3YjI3MjUxZjFjYyJ9"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default PowerBiPage;
