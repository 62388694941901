import Ellipse from "../../../imgs/backg.svg";
import pensionerImg from "../../../imgs/pensioners.png";
import employeeImg from "../../../imgs/employeeimg.png";
import React from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
let items;
const GroDash = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();

  if(dash==="/GroAdminDash") 
  {
  items = [
    {
      key: "1",
      title: `GRO Employees`,
      content: "View the list of all of the employees that are registered with MHSSS.",
      img: employeeImg,
      route: "/GroAdminDash/Employees",
    },
    {
      key: "2",
      title: `Pensioners`,
      content: "View the list of all of the pensioners that are registered with MHSSS.",
      img: pensionerImg,
      route: "/GroAdminDash/Pensioners",
    },
  ];}
  return (
    <div className="top-[8rem]" style={{
      width:"100%",
      height:"100%",
      backgroundImage:`url(${Ellipse})`,
      backgroundSize:"cover",
      backgroundPosition:"center 10%", 
      backgroundPositionY:"-140px",
    }}>
      <div className="flex items-center justify-center">
        <div className="md:w-[85%] mt-[3rem] rounded-md w-[95%] p-[4rem] mb-16  sm:min-h-[960px] bg-[#F8F5F5] shadow-lg">
          <div className="w-full flex flex-wrap justify-center items-center gap-8 md:gap-x-8 sm:min-h-[400px]">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                router(item?.route);
              }}
              className="relative w-[220px] borders rounded-md p-10 mb-[30px] h-[150px] p-2 cursor-pointer custom-shadow"
              style={{
                background: 'linear-gradient(white, #F8F5F5)',
                height:`150px`
              }}
            >
              <div className="absolute top-[-40px] left-1/2 flex p-5 border-[3px] border-[#F8F5F5] p-[1px] items-center justify-center transform -translate-x-1/2 bg-[#8BD6B5] rounded-full h-[80px] w-[80px]">
              <img src={item?.img} alt="" className="h-[40px] w-[40px]" />
              </div>
              <div className="flex items-center justify-center flex-col mt-6 w-full h-full">
                <div className="flex items-center justify-center w-full flex-col">
              <div className="text-center mt-1 h-[30px] ">
                <h1 className="text-black mb-1 text-[16px] font-bold" style={{fontFamily:"poppins", fontWeight:"600"}}>
                  {item?.title}
                </h1>
              </div>
              <div className="text-center mt-1 min-h-[60px]">
                <p className="text-black mb-1 text-[13px] " style={{fontFamily:"poppins", fontWeight:"500"}}>
                  {item?.content}
                </p>
              </div>
              </div>
            </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default GroDash;
