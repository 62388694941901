import Listing from "../components/inventory/buy/buy";
import Nav from "../components/inventory/vendor/navbar";
const ListingPage = () => {
  return (
    <div>
      <Nav />
      <Listing />
    </div>
  );
};

export default ListingPage;