import axios from "axios";
import React, { useEffect, useState } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import NoUserFoundModal from "./noUserFoundModal";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const type = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);
  const [contactMethod, setContactMethod] = useState("phone");
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("");
  const [phoneNoo, setPhoneNoo] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const [noUserFound, setNoUserFound] = useState(false);
  const [users, setUsers] = useState([]);
  const [gid, setGid] = useState("");
  const [afterApi, setAfterApi] = useState(false);

  const handleOptionChange = (event) => {
    setContactMethod(event.target.value);
    if (event.target.value === "phone") {
      setEmailValue("");
      setGid("");
    }
    if (event.target.value === "email") {
      setGid("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "gid") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
  };
  const Search = () => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists

      // Create a new cancel token source for this request

      const params = {
        ...(gid && { gid }),
        // ...(lastName && { lastName }),
        // ...(dob && { dateOfBirth: dob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        ...(emailValue && { email: emailValue }),
      };

      fetchPenioners(params);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
    };
  };
  const fetchPenioners = (params) => {
    const api = user === "pensioner" ? "for-pensioner" : "for-merch";
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/search/${api}`,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
          params,
        }
      )
      .then((data) => {
        if (
          data?.data?.user?.status === "pending" &&
          data?.data?.user?.person === null
        ) {
          setErr("Account approval is still pending, and the profile is incomplete");
          setShowErrorModal(true);
          return;
        }

        if (
          data?.data?.user?.status === "pending" 
         
        ) {
          setErr("You can't make payments to this account as approval is still pending");
          setShowErrorModal(true);
          return;
        }
        // dispatch({ type: "SAP_PAYMENT_SELECTED_PERSON", num: data?.data });
        console.log(data?.data);
        dispatch({
          type: "SAP_PAYMENT_SELECTED_PERSON",
          num: {
            id: data?.data?.id,
            type: data?.data?.type,
            image:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.image
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.image
                : data?.data?.merchheadquarter?.image,
            fromId: data?.data?.user?.id,
            email:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.email
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryEmail
                : data?.data?.merchheadquarter?.primaryEmail,

            name:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.firstName +
                  " " +
                  data?.data?.user?.person?.lastName
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.customStoreName
                : data?.data?.merchCompany?.name,

            phone:
              data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryPhone
                : data?.data?.type === "merch-headquarter"
                ? data?.data?.merchheadquarter?.primaryPhone
                : data?.data?.type === "pensioner"
                ? data?.data?.user?.countryCode && data?.data?.user?.phoneNo
                  ? `${data?.data?.user?.countryCode}${data?.data?.user?.phoneNo}`
                  : "-------"
                : "-------",
          },
        });
        if (user === "pensioner") {
          dispatch({ type: "PAYMENT", num: true });
          return;
        }
        router("/SapMoney/Merchant/Pay/PhoneNumber");
      })
      .catch((err) => {
        if (
          err?.response?.data?.message ===
          "No sap wallet info found against provided email,phone number or gid."
        ) {
          setNoUserFound(true);
          return;
        }
        if (
          err?.response?.data?.message ===
          "No sap wallet info found against provided email, phone or gid"
        ) {
          setNoUserFound(true);
          return;
        }
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  useEffect(() => {
    dispatch({
      type: "PENSIONER_SENDER_TYPE_VERIFICATION",
      num: false,
    });
    const api =
      user === "pensioner"
        ? "v1/sapwallet-histories/recently-paid-sapwallets/for-pensioner"
        : `v1/sapwallet-histories/recently-paid-sapwallets/for-merch?${
            type === "store"
              ? `fromMerchStore=${typeData?.id}`
              : `fromMerchHeadquarter=${typeData?.merchuser?.merchheadquarterId}`
          }`;
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
      })
      .then((data) => {
        setUsers(data?.data);
        setAfterApi(true);
        console.log(data);
      })
      .catch((err) => {
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  }, []);

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPhone(event);
      if (event) {
        const wow = parsePhoneNumber(event);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };

  return (
    <div className="p-3 sm:px-8">
      <div>
        <h1 className="font-semibold text-[1rem]">
          Enter a Phone Number or Email
        </h1>
        <p>
          ( Receiver should have SAP money account linked with this phone number
          or email )
        </p>

        <div className="my-4 sm:w-2/5 w-full gap-3 bg-[#F7F7F7] px-4 py-4 rounded-md">
          <div className="flex gap-4 mb-4">
            <div className="mb-2 flex items-center">
              <input
                type="radio"
                id="gidOption"
                name="contactMethod"
                value="gid"
                className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                checked={contactMethod === "gid"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="gidOption"
                className="cursor-pointer text-sm inline-flex items-center ml-2"
              >
                GID
              </label>
            </div>
            <div className="mb-2 flex items-center">
              <input
                type="radio"
                id="phoneOption"
                name="contactMethod"
                value="phone"
                className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                checked={contactMethod === "phone"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="phoneOption"
                className="cursor-pointer text-sm inline-flex items-center ml-2"
              >
                Phone Number
              </label>
            </div>

            <div className="mb-2 flex items-center">
              <input
                type="radio"
                id="emailOption"
                name="contactMethod"
                value="email"
                className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                checked={contactMethod === "email"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="emailOption"
                className="cursor-pointer text-sm inline-flex items-center ml-2"
              >
                Email
              </label>
            </div>
          </div>

          {contactMethod === "gid" && (
            <div className="w-full mb-3 mt-2">
              <input
                type="text"
                name=""
                onChange={(e) => setGid(e.target.value)}
                placeholder="Enter GID"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
              />
            </div>
          )}
          {contactMethod === "phone" && (
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="GY"
              international
              value={phone}
              onChange={handleChange(setPhone, "tt")}
              countryCallingCodeEditable={false}
              className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
            />
          )}

          {contactMethod === "email" && (
            <div className="w-full mb-3 mt-2">
              <input
                type="text"
                name=""
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                placeholder="Enter Email"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={`${users.length === 0 && afterApi === true ? "hidden" : ""}`}
      >
        <h1 className="font-semibold text-[1rem]">Recently Paid</h1>

        <div className="inline-block grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 gap-5 mt-4 bg-[#F7F7F7] p-4 rounded-md sm:w-[80%] w-full">
          {users?.map((beneficiary, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-3 cursor-pointer"
              onClick={() => {
                dispatch({
                  type: "SAP_PAYMENT_SELECTED_PERSON",
                  num: {
                    id: beneficiary?.toSapWalletInfoId,
                    type: beneficiary?.ownerInfo?.type,
                    fromId: beneficiary?.ownerInfo?.user?.id,
                    image:
                      beneficiary?.ownerInfo?.type === "pensioner"
                        ? beneficiary?.ownerInfo?.person?.image
                        : beneficiary?.ownerInfo?.type === "merch-store"
                        ? beneficiary?.ownerInfo?.merchStore?.image
                        : beneficiary?.ownerInfo?.merchHeadquarter?.image,

                    email:
                      beneficiary?.ownerInfo?.type === "pensioner"
                        ? beneficiary?.ownerInfo?.user?.email
                        : beneficiary?.ownerInfo?.type === "merch-store"
                        ? beneficiary?.ownerInfo?.merchStore?.primaryEmail
                        : beneficiary?.ownerInfo?.merchHeadquarter
                            ?.primaryEmail || "-------",

                    name:
                      beneficiary?.ownerInfo?.type === "pensioner"
                        ? beneficiary?.ownerInfo?.person?.firstName +
                          " " +
                          beneficiary?.ownerInfo?.person?.lastName
                        : beneficiary?.ownerInfo?.type === "merch-store"
                        ? beneficiary?.ownerInfo?.merchStore?.customStoreName
                        : beneficiary?.ownerInfo?.merchCompany?.name,

                    phone:
                      beneficiary?.ownerInfo?.type === "merch-store"
                        ? beneficiary?.ownerInfo?.merchStore?.primaryPhone ||
                          "-------"
                        : beneficiary?.ownerInfo?.type === "merch-headquarter"
                        ? beneficiary?.ownerInfo?.merchHeadquarter
                            ?.primaryPhone || "-------"
                        : beneficiary?.ownerInfo?.type === "pensioner"
                        ? beneficiary?.ownerInfo?.user?.countryCode &&
                          beneficiary?.ownerInfo?.user?.phoneNo
                          ? `${beneficiary?.ownerInfo?.user?.countryCode}${beneficiary?.ownerInfo?.user?.phoneNo}`
                          : "-------"
                        : "-------",
                  },
                });
                // if (user === "pensioner") {
                //   dispatch({ type: "PAYMENT", num: true });
                //   return;
                // }
                if (user === "pensioner") {
                  dispatch({ type: "PAYMENT", num: true });
                  return;
                }
                router("/SapMoney/Merchant/Pay/PhoneNumber");
              }}
            >
              <img
                src={
                  beneficiary?.ownerInfo?.type === "pensioner"
                    ? beneficiary?.ownerInfo?.person?.image
                    : beneficiary?.ownerInfo?.type === "merch-store"
                    ? beneficiary?.ownerInfo?.merchStore?.image
                    : beneficiary?.ownerInfo?.merchHeadquarter?.image
                }
                alt="profile"
                className="w-14 h-14 rounded-full"
              />
              <p className="font-bold mt-2 text-center">
                {beneficiary?.ownerInfo?.type === "pensioner"
                  ? beneficiary?.ownerInfo?.person?.firstName
                  : beneficiary?.ownerInfo?.type === "merch-store"
                  ? beneficiary?.ownerInfo?.merchStore?.customStoreName
                  : beneficiary?.ownerInfo?.merchCompany?.name}
                <span
                  className={`${
                    beneficiary?.ownerInfo?.type === "pensioner" ? "" : "hidden"
                  }`}
                >
                  {" "}
                  {beneficiary?.ownerInfo?.person?.lastName}
                </span>
              </p>
              <p className="text-xs font-semibold text-gray-400">
                {beneficiary?.ownerInfo?.type === "merch-store"
                  ? beneficiary?.ownerInfo?.merchStore?.primaryPhone
                  : beneficiary?.ownerInfo?.type === "merch-headquarter"
                  ? beneficiary?.ownerInfo?.merchHeadquarter?.primaryPhone
                  : beneficiary?.ownerInfo?.type === "pensioner"
                  ? beneficiary?.ownerInfo?.user?.countryCode &&
                    beneficiary?.ownerInfo?.user?.phoneNo
                    ? `${beneficiary?.ownerInfo?.user?.countryCode}${beneficiary?.ownerInfo?.user?.phoneNo}`
                    : beneficiary?.ownerInfo?.user?.gid
                  : ""}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full mt-6 flex">
        <button
          onClick={() => {
            if (emailValue === "" && phone === "" && gid === "") {
              setErr("Please add one of the fields to search");
              setShowErrorModal(true);
              return;
            }
            Search();
          }}
          className="cursor-pointer  rounded-md px-6 py-2 text-white bg-primary w-[8rem] text-md"
        >
          Next
        </button>
      </div>
      {noUserFound && (
        <NoUserFoundModal
          setShow={setNoUserFound}
          type={contactMethod}
          value={
            gid ? gid : emailValue ? emailValue : `+${countryCode + phoneNoo}`
          }
        />
      )}
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
    </div>
  );
};

export default Main;
