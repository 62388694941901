import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import person from "../../../imgs/profile.png";
import back from "../../../imgs/back5.png";
import tick from "../../../imgs/across.png";
import logo1 from "../../../imgs/logo12.png";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PhoneOutlined } from "@ant-design/icons";

const Header = () => {
  const dispatch = useDispatch();
  const selectedChatUser = useSelector((state) => state.selectedChatUser);
  const selectedChatPhoneNo = useSelector((state) => state.selectedChatPhoneNo);
  const dataUser = useSelector((state) => state.dataUser);
  const empNum =
    dataUser?.data?.user?.countryCode + dataUser?.data?.user?.phoneNo;

  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const [checkCall, setCheckCall] = useState(false);
  const [err, setErr] = useState(false);
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log(windowWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCall = () => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/whatsapp/voice-call?to=${selectedChatPhoneNo}&from=${empNum}`;
    axios({
      method: "post",
      url: apiUrl,
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then(() => {
        setCheckCall(true);
        setTimeout(() => {
          setCheckCall(false);
        }, 5000);
      })
      .catch((error) => {
        setErr(error.response.data.message);
        setShow(true);
        console.error("Error:", error);
        // Handle the error
      });
  };
  return (
    <>
    <div className="py-2 px-3 bg-gray-200 border-2 border-gray-200 flex flex-row justify-between items-center">
      <div className="flex items-center">
        <div>
          <img
            className={`${
              windowWidth === 768 || windowWidth <= 768 ? "" : "hidden"
            } w-6 h-6 rounded-full`}
            src={back}
            alt="User Avatar"
            onClick={() => {
              dispatch({ type: "CHAT_SELECTED_USER", num: null });
            }}
          />
        </div>
        <div>
          <img
            className={`${
              selectedChatUser === null ? "hidden" : ""
            }w-10 h-10 rounded-full`}
            src={
              selectedChatUser?.person === null
                ? person
                : selectedChatUser?.person?.image
            }
            alt="User Avatar"
          />
        </div>
        <div className="ml-4">
          <p className="text-gray-800 capitalize font-semibold text-[1rem]">
            {selectedChatUser === null
              ? ""
              : selectedChatUser?.person === null
              ? selectedChatUser?.countryCode + " " + selectedChatUser?.phoneNo
              : selectedChatUser?.person?.firstName +
                " " +
                selectedChatUser?.person?.lastName}
          </p>
          {/* <p className="text-gray-700 text-xs mt-1">
            Pensioner
          </p> */}
        </div>
      </div>

      <div className="flex gap-4">
        <div
          className="mr-3"
          onClick={() => {
            router("/meeting");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-gray-500 cursor-pointer"
          >
            <path d="M23 7l-7 5 7 5V7z" />
            <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
          </svg>
        </div>
        <div
          onClick={() => {
            // router("/meeting");
          }}
        >
          <PhoneOutlined
            onClick={handleCall}
            style={{ color: "#6b7280", fontSize: "24px" }}
          />
        </div>
      </div>

      {/* <div className="flex">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="fill-current text-gray-500">
            <path d="M15.9 14.3H15l-.3-.3c1-1.1 1.6-2.7 1.6-4.3 0-3.7-3-6.7-6.7-6.7S3 6 3 9.7s3 6.7 6.7 6.7c1.6 0 3.2-.6 4.3-1.6l.3.3v.8l5.1 5.1 1.5-1.5-5-5.2zm-6.2 0c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
          </svg>
        </div>
        <div className="ml-6">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="fill-current text-gray-500">
            <path d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z" />
          </svg>
        </div>
        <div className="ml-6">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="fill-current text-gray-600">
            <path d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z" />
          </svg>
        </div>
      </div> */}
    
    </div>
    <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={tick} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            {err}
          </p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          checkCall ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
         
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <p className="text-center mt-[1rem] mb-5">
            You will reciece a call on your phone in a while
            </p>

          <button
            type="submit"
            onClick={() => setCheckCall(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
