import { Dropdown, Pagination, Popover } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { MailFilled, PhoneFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
const Paget = ({ data, page, setPage, totalPages }) => {
  // const [totalPages, setTotalPages] = useState(1);
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);
  const location = useLocation();
  const dash = location.pathname;
  const [notesModal, setNotesModal] = useState(false);
  const [merchantModal, setMerchantModal] = useState(false);
  const [note, setNote] = useState("");
  const router = useNavigate();
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const items = [
    {
      label: "View Details ",
      key: "1",

      // icon: <img style={{ width: "1.5rem" }} src={bs1} />,
    },
  ];
  const selectItem = (key, item) => {
    console.log(key, item);
    setNote("");
    if (key.key === "1") {
      router("/Merchant/MHSSS/Payment/detail", {
        state: {
          id: item?.id,
          selectedTransactionsVouchers: item,
        },
      });
    }
  };
  const pageHandler = (e) => {
    setPage(e);
  };
  // const columnHeaders = [
  //   "Sr No",
  //   "Amount",
  //   "Programme",
  //   "Payment ID",
  //   // "Status",
  //   "Store / HQ",
  //   "Status",
  //   "Date / Time",
  //   "Action",
  // ];

  const columnHeaders = isGovernmentAgency
    ? [
        "Sr No",
        "Requested On",
        "Amount",
        "Payment ID",
        "Status",
        "Transaction Date & Time",
        "Action",
      ]
    : [
        "Sr No",
        "Amount",
        "Programme",
        "Payment ID",
        "Store / HQ",
        "Status",
        "Date / Time",
        "Action",
      ];

  return (
    <section className="p-2">
      <div className="mx-auto max-w-screen-xl sm:px-4 lg:px-2 ">
        {data?.length === 0 ? (
          <h1 className="text-center text-lg text-gray-500 p-10">
            No Data Found
          </h1>
        ) : (
          <div className="bg-white  relative shadow-md  overflow-hidden border-2 border-green-100 ">
            {/* table */}

            <div className="overflow-x-auto custom-scrollbar">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {/* {columnHeaders.map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-4 py-3 font-semibold tracking-wider"
                      >
                        {columnHeader}
                      </th>
                    ))} */}
                    {columnHeaders
                      .filter(
                        (header) =>
                          !(
                            (dash === "/Dashboard/transaction_history" &&
                              header === "Paid to") ||
                            (dash !== "/Dashboard/transaction_history" &&
                              header === "Merchant") ||
                            (isGovernmentAgency && header === "Programme") ||
                            (isGovernmentAgency && header === "Store / HQ")
                          )
                      )
                      .map((columnHeader) => (
                        <th
                          scope="col"
                          className="px-4 py-3 font-semibold tracking-wider"
                        >
                          {columnHeader}
                        </th>
                      ))}
                  </tr>
                </thead>

                <tbody>
                  {data?.map((data, index) => (
                    <tr className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer">
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900  whitespace-nowrap  "
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>

                      <td
                        className={`${
                          isGovernmentAgency ? "px-4 py-5" : "hidden"
                        }`}
                      >
                        {moment(
                          data?.bankbalancerequest !== null
                            ? data?.bankbalancerequest?.createdAt
                            : ""
                        ).isValid()
                          ? moment(data?.bankbalancerequest?.createdAt).format(
                              "MM/DD/YYYY hh:mm A"
                            )
                          : "-----"}
                      </td>

                      <td className="px-4 py-5">
                        $
                        {data?.merchtransactionpayment?.amountPaid
                          ? data?.merchtransactionpayment?.amountPaid +
                            data?.merchtransactionpayment?.feePaid
                          : isGovernmentAgency
                          ? data?.amount
                          : data?.totalAmount}
                      </td>
                      {/* <td
                        className={`${
                          dash === "/Dashboard/transaction_history"
                            ? "hidden"
                            : "px-4 py-5 flex items-center"
                        }`}
                      >
                        <img
                              className="w-10 h-10 rounded-full mr-4"
                              src={cardimg2}
                              alt={`${pen?.person?.firstName} ${pen?.person?.lastName}`}
                            />
                        <div>
                          {data?.user?.person?.firstName +
                            " " +
                            data?.user?.person?.lastName}
                        </div>
                      </td> */}

                      {/* <td className="px-4 py-5">{pen.gid}</td> */}

                      <td
                        className={`${
                          isGovernmentAgency ? "hidden" : "px-4 py-5"
                        }`}
                      >
                        {data?.pensionbook?.pensionprogram?.name !== null
                          ? data?.pensionbook?.pensionprogram?.name ===
                            "senior-citizen-Pension"
                            ? "Senior Citizen Pension"
                            : data?.pensionbook?.pensionprogram?.name
                          : "----"}
                      </td>

                      <td className="px-4 py-5">
                        <Popover
                          content={
                            data?.merchtransactionpayment?.transactionPaymentId
                              ? data?.merchtransactionpayment
                                  ?.transactionPaymentId
                              : isGovernmentAgency
                              ? data?.paymentId
                              : "-----"
                          }
                        >
                          <div className="inline-block max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {data?.merchtransactionpayment?.transactionPaymentId
                              ? data?.merchtransactionpayment
                                  ?.transactionPaymentId
                              : isGovernmentAgency
                              ? data?.paymentId
                              : "-----"}
                            ..
                          </div>
                        </Popover>
                      </td>

                      <td
                        className={`${
                          isGovernmentAgency ? "hidden" : "px-4 py-5"
                        }`}
                      >
                        {data?.merchstore !== null
                          ? data?.merchstore?.customStoreName
                          : data?.merchuser?.merchcompany?.name}
                      </td>

                      <td
                        className={`px-4 py-5 capitalize font-bold ${
                          data?.mhsssPayStatus === "success" ||
                          isGovernmentAgency
                            ? "text-primary"
                            : data?.mhsssPayStatus === "pending"
                            ? "text-yellow-500"
                            : "text-red-500"
                        }`}
                      >
                        {data?.mhsssPayStatus === "success" ||
                        isGovernmentAgency
                          ? "Received"
                          : data?.mhsssPayStatus}
                      </td>

                      <td className="px-4 py-5">
                        <Popover
                          content={`${
                            data.status === "pending" ? "In-Process" : ""
                          }`}
                          placement="bottomLeft"
                        >
                          <li className="flex items-center">
                            {/* {data?.createdAt?.substring(0, 10)}{" "}
                            {moment(data?.createdAt).format("h:mm A")} */}
                            {moment(data ? data?.createdAt : "").isValid()
                              ? moment(data?.createdAt).format(
                                  "MM/DD/YYYY h:mm A"
                                )
                              : "-----"}
                          </li>
                        </Popover>
                      </td>

                      <td className="px-4 py-5">
                        <Dropdown
                          menu={{
                            items,
                            onClick: (key) => selectItem(key, data),
                          }}
                          trigger={["click"]}
                        >
                          <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-gray-200 justify-center items-center h-[1.2rem] rounded-[1rem]">
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          </div>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav
              className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 ">
                Showing{" "}
                <span className="font-semibold text-gray-900 ">
                  Page {page}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 ">
                  {totalPages}
                </span>
              </span>
              <div className={`flex justify-end mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </nav>
          </div>
        )}
        <div
          className={`${
            notesModal
              ? "w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center"
              : "hidden"
          } `}
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <h3 className="text-lg font-semibold text-gray-900 ">Notes</h3>
                <button
                  onClick={() => {
                    setNotesModal(false);
                  }}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                  data-modal-toggle="select-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="p-4 md:p-5">
                <p className="text-gray-500 capitalize mb-4">{note}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Merchant Modal */}
        <div
          className={`${
            merchantModal
              ? "w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center"
              : "hidden"
          } `}
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <h3 className="text-lg font-semibold text-gray-900 ">
                  Merchant Details
                </h3>
                <button
                  onClick={() => {
                    setMerchantModal(false);
                  }}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                  data-modal-toggle="select-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="p-4 md:p-5">
                <div
                  className={`${
                    dash === "/Dashboard/transaction_history"
                      ? "bg-white flex items-center"
                      : "hidden"
                  }`}
                >
                  <div>
                    <h4 className="text-primary text-lg">Merchant Details</h4>
                    <p>John Doe</p>
                    <div className="flex items-center">
                      <span className="mr-2">
                        <MailFilled className="text-gray-500" />
                      </span>
                      <p>johndoe@gmail.com</p>
                    </div>
                    <div className="flex items-center">
                      <span className="mr-2">
                        <PhoneFilled className="text-gray-500" />
                      </span>
                      <p>+92 334 5645454</p>
                    </div>
                  </div>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSD05qtx53NlqwzZzPAWSn3wROizWvKc7UDA&s"
                    alt="Profile"
                    className="w-20 h-20  border-2 border-primary rounded-full ml-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Paget;
