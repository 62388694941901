import back5 from "../../imgs/back5.png";
import SecondR from "../Register/stepsR/SecondR";
import FourthR from "../Register/stepsR/FourthR";
import Mid from "../Register/stepsR/mid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThirdI from "../Identification/stepsI/ThirdI";
import IdentificationPage from "../Identification/stepsI/identification";

const AddGro = () => {
  const icurrent = useSelector((state) => state.icurrent);
  const router = useNavigate();
  let Dis;
  const dispatch = useDispatch();
  const Show = [
    SecondR,
    Mid,
    FourthR,
    IdentificationPage,
    // FirstI,
    // SecondI,
    ThirdI
  ];
  Dis = Show[icurrent];

  return (
    <div>
      <div className="relative flex justify-between sm:px-[7rem] px-[2rem] sm:mt-[5rem] mt-[2rem] mb-[2rem]">
      <div className="flex items-center">
        <div
          onClick={() => {
            dispatch({ type: "SET", num: 0 });
            dispatch({ type: "BRSET", num: 0 });
            dispatch({ type: "BISET", num: 0 });
            router("/superAdminDashboard/allGroAdmins");
          }}
        //   className="cursor-pointer pt-[5rem] mr-5"
        >
          <img className="sm:w-[1.7rem] w-[1.2rem] cursor-pointer" src={back5} alt="" />
        </div>
        <h2 className="font-bold ml-[1rem] sm:text-[1.8rem] text-[1.4rem]">Add GRO Admin</h2>
      </div>
      </div>
      <div className=" mb-[5rem]">
        <Dis />
      </div>
    </div>
  );
};

export default AddGro;
