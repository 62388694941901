import { Collapse } from "antd";
const { Panel } = Collapse;
const ContactInfo = ({ company }) => {
  const callback = (key) => {
    console.log(key);
  };
  return (
    <div className="">
      <div className="">
        <Collapse
          // defaultActiveKey={["1"]}
          accordion={true}
          onChange={callback}
          style={{ backgroundColor: "white" }}
        >
          <Panel
            header="Emails"
            key="2"
            className="p-3 text-md text-gray-800 font-medium"
          >
            <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
              <div className="sm:px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-600">
                  Email Addresses
                </h3>
              </div>
              <div className="mt-6 border-t border-gray-300">
                {company?.emails?.length === 0 && (
                  <div className="sm:px-4 py-6  sm:px-0 text-center">
                    No Email Addresses Found
                  </div>
                )}
                <dl className="divide-y divide-gray-300">
                  {company?.emails?.map((con, index) => (
                    <div
                      key={index}
                      className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                    >
                      <dt className="text-sm font-semibold leading-6 text-gray-900">
                        Email Address {index + 1}
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {con}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </Panel>

          <Panel
            header="Home Phones"
            key="3"
            className="p-3 text-md text-gray-800 font-medium"
          >
            <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
              <div className="sm:px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-600">
                  Home Phones
                </h3>
              </div>
              <div className="mt-6 border-t border-gray-300">
                {company?.homePhones?.length === 0 && (
                  <div className="sm:px-4 py-6  sm:px-0 text-center">
                    No Home Phones Found
                  </div>
                )}
                <dl className="divide-y divide-gray-300">
                  {company?.homePhones?.map((con, index) => (
                    <div
                      key={index}
                      className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                    >
                      <dt className="text-sm font-semibold leading-6 text-gray-900">
                        Home Phone {index + 1}
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {con}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </Panel>
          <Panel
            header="Work Phones"
            key="4"
            className="p-3 text-md text-gray-800 font-medium"
          >
            <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
              <div className="sm:px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-600">
                  Work Phones
                </h3>
              </div>
              <div className="mt-6 border-t border-gray-300">
                {company?.workPhones?.length === 0 && (
                  <div className="sm:px-4 py-6  sm:px-0 text-center">
                    No Work Phones Found
                  </div>
                )}
                <dl className="divide-y divide-gray-300">
                  {company?.workPhones?.map((con, index) => (
                    <div
                      key={index}
                      className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                    >
                      <dt className="text-sm font-semibold leading-6 text-gray-900">
                        Work Phone {index + 1}
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {con}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default ContactInfo;
