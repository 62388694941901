import React, { useEffect } from "react";
import LineChart from "./lineChart";
import TotalBudget from "./totalBudget";
import balancebg from "../../../imgs/balancebg.png";
import outstandingbg from "../../../imgs/outstandingbg.png";
import pinkbg from "../../../imgs/pinkbg.png";
import greenbg from "../../../imgs/greenbg.png";
import { useSelector } from "react-redux";
import axios from "axios";
const Dashboard = () => {
  const token = useSelector((state) => state.token);
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/graphs-data/outstanding-dashboard-graph?year=2024`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(() => {
        // console.log(err);
        // setErr(err.response.data.message);
        // setShowErrModal(true);
      });
  }, []);
  const data = [
    { title: "Merchant Outstanding", value: "$10M", background: balancebg },
    { title: "Paid to Merchants", value: "$25k", background: outstandingbg },
    { title: "Fee Paid to Merchants", value: "$11k", background: pinkbg },
    { title: "Paid to Pensioner", value: "$11k", background: greenbg },
  ];
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4">
      {/* Total Budget */}
      <div className="bg-white p-4 border col-span-1">
        <h2 className="text-xl font-bold">Total Budget</h2>
        <TotalBudget />
        <div className="space-y-2">
          <p>
            <span className="inline-block w-3 h-3 rounded-full bg-blue-500 mr-2"></span>{" "}
            $10M Senior Citizen Pension
          </p>
          <p>
            <span className="inline-block w-3 h-3 rounded-full bg-yellow-500 mr-2"></span>{" "}
            $5M Disability Program
          </p>
        </div>
      </div>
      {/* Main Chart */}
      <div className="bg-[#F6FBF9] p-4 col-span-1 lg:col-span-2">
        <LineChart />
      </div>

      <div className="bg-[#B60011] p-4 rounded-lg shadow-md col-span-1 text-white">
        <h2 className="text-xl font-bold">Total Budget</h2>
        <div className="text-white p-4  col-span-1 lg:col-span-3">
          <h3 className="text-lg font-bold">Due Payment</h3>
          <p className="text-2xl">$15k</p>
        </div>
      </div>
      {/* Main Chart */}
      <div className="col-span-1 lg:col-span-2">
        <div className="grid grid-cols-2 gap-4">
          {data.map((item, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg shadow-md  text-white pb-12 `}
              style={{
                backgroundImage: `url(${item.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h3 className="text-md font-semibold">{item.title}</h3>
              <p className="text-2xl font-bold">{item.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
