import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import thumb from "../../../../imgs/Thumb.png";
import logo from "../../../../imgs/sapNew.svg";
import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import dec from "../../../../imgs/decline.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import pro from "../../../../imgs/pro.png";
import logoSCP from "../../../../imgs/logomhs.png";
import { useSelector } from "react-redux";
import RequestDeclined from "../../requestMoney/childResponse/modal";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
const Main = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const data = location?.state?.data;
  const merPenReqDetail = useSelector((state) => state.merPenReqDetail);
  const merPenReqCheck = useSelector((state) => state.merPenReqCheck);
  const merchReq = merPenReqCheck
    ? merPenReqCheck
    : location?.state?.merchantSideChildReq;
  const otherData = useSelector((state) => state.childData);

  const router = useNavigate();
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("Paid");
  const [showErr, setShowErr] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showRejectMsg, setShowRejectMsg] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmPay, setShowConfirmPay] = useState(false);
  const [showAccept, setShowAccept] = useState("");
  const [reason, setReason] = useState("");
  const [id, setId] = useState("");
  const childData =
    user === "pensioner" || merchReq === true ? data : otherData;
  const merchantSapType = useSelector((state) => state.merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  console.log("Test", merchantSapTypeData);
  console.log("Test2", merchantSapType);
  console.log("Test3", dataUser);
  const [amountPaid, setAmountPaid] = useState("");
  const [verify, setVerify] = useState("");
  const [paidFull, setPaidFull] = useState(false);
  const token = useSelector((state) => state.token);
  const [declineModal, setDeclineModal] = useState(false);

  const handlePrint = () => {
    const data = {
      transactionId: "1214325465",
      transactionDate: "26th Feb 2024",
      transactionType: "Bank Transfer",
      programName: "Senior Citizen Pension",
      merchantsName: "Abu Bakar",
      store: "YUP Store",
      status: "Paid",
      amount: "$100",
      pensionerName: "Pensioner",
      voucherCode: "12X3",
      email: "info@example.com",
      phone: "+234XXXXXXXX",
    };
    const mhsss = logoSCP;
    const callImg = slipcall;
    const emailImg = slipemail;
    const bg = slipbg;
    const printFunc = (mhsss, callImg, emailImg) => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentWindow.document;
      let printableContent = `
        <html>
            <head>
                <title>Print</title>
                <style>
                    body {
                        font-size: 25pt;
                    }
                    @media print {
                        .printable-container {
                            -webkit-print-color-adjust: exact;
                            color-adjust: exact; 
                            background-color: #ffffff;
                            background-image: url('${bg}');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                            margin-bottom: 1.5rem;
                            padding: 0.5rem;
                        }
                        .d1 {
                            border-radius: 0.25rem;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            padding-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .d2 {
                            width: 6rem; 
                            height: 5rem;
                            padding-top: 1rem;
                        }
                        .d3 {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .hh1 {
                            font-weight: 600;
                            text-align: center;
                            width: 65%;
                        }
                        .d4 {
                            display: flex;
                            width: 80%;
                            flex-direction: column;
                            border-bottom: 1px solid #e2e8f0;
                            padding-bottom: 1rem;
                            font-size: 1rem;
                        }
                        .pp {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .ss {
                            color: #718096;
                        }
                        .d5 {
                            display: flex;
                            flex-direction: column;
                            padding-top: 0.5rem;
                            font-size: 1rem;
                            width: 80%;
                        }
                        .d6 {
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                        }
                        .call {
                            width: 1.25rem;
                            height: 1.25rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .mail {
                            width: 1.25rem;
                            height: 1rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .d7 {
                            display: flex;
                            width: 100% ;
                            align-items: center;
                        }
                        .sss {
                            font-weight: 600;
                        }
                    }
                </style>
            </head>
            <body>
                <div class="printable-container">
                    <div class="d1">
                        <img id="mhsss" alt="chippz" class="d2" src="${mhsss}" />
                        <div class="d3">
                            <h4 class="hh1">Ministry of Human Services and Social Security</h4>
                        </div>
                        <div class="d4">
                            <p class="pp">
                                <span class="ss">Payment Id</span>
                                <span>${showAccept?.paymentId}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Transaction Date</span>
                                <span>${showAccept?.updatedAt.substring(
                                  0,
                                  10
                                )}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Program Name</span>
                                <span>${data.programName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Merchants Name</span>
                                <span>${
                                  dataUser?.data?.merchuser?.merchCompany?.name
                                }</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Store</span>
                                <span class="sss">${
                                  merchantSapType === "store"
                                    ? merchantSapTypeData?.customStoreName
                                    : "HeadQuarter"
                                }</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Status</span>
                                <span class="sss">${status}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Amount</span>
                                <span class="sss">${
                                  childData?.amountPaid
                                }</span>
                            </p>
                        </div>
                        <div class="d5">
                            <p class="pp">
                                <span class="ss">Pensioner Name</span>
                                <span>${
                                  childData?.firstName +
                                  " " +
                                  childData?.lastName
                                }</span>
                            </p>
                                <div class="d6">
                                    <p class="d7">
                                        <img id="emailImg" class="mail" alt="chippz" src="${emailImg}" />
                                        ${childData?.email}
                                    </p>
                                    <p class="d7">
                                        <img id="callImg" class="call" alt="chippz" src="${callImg}" />
                                        ${
                                          childData?.phoneNo
                                            ? childData.countryCode +
                                              childData?.phoneNo
                                            : "-----"
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        `;
      iframeDocument.open();
      iframeDocument.write(printableContent);
      iframeDocument.close();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    };
    const imageSources = [mhsss, callImg, emailImg];
    let loadedImages = 0;
    imageSources.forEach((src) => {
      const img = new Image();
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageSources.length) {
          printFunc(mhsss, callImg, emailImg);
        }
      };
      img.src = src;
    });
  };

  useEffect(() => {
    if (user === "pensioner") {
      return;
    }

    const id =
      merchReq === true ? data?.pensionerUserId : childData?.pensionerUser?.id;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/for-merch?pensionerUserId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setId(data?.data?.id);
      })

      .catch((err) => {
        setErr(err?.response?.data.message);
        setShowErr(true);
      });
  }, []);
  const ConfirmPay = () => {
    let data =
      merchantSapType === "store"
        ? { merchstoreId: merchantSapTypeData.id }
        : {
            merchheadquarterId:
              merchantSapTypeData.merchuser?.merchheadquarterId,
          };
    data.sapwalletinfoId = id;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${childData.id}/credit-quickpayee/merch-accept`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log("Accept", data?.data);
        setShowAccept(data?.data);
        setStatus("Paid");
        if (childData?.requestedByPensioner === true) {
          setShowConfirmPay(false);
          setShow(true);
          return;
        }

        setShowConfirm(true);
        setShowConfirmPay(false);
      })
      .catch((err) => {
        setErr(err?.response?.data.message);
        setShowErr(true);
        setShowConfirmPay(false);
      });
  };
  const RejectPay = () => {
    let data =
      merchantSapType === "store"
        ? { merchstoreId: merchantSapTypeData.id }
        : {
            merchheadquarterId:
              merchantSapTypeData.merchuser?.merchheadquarterId,
          };
    data.rejectionNote = reason;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${childData.id}/credit-quickpayee/merch-reject`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setStatus("Rejected");
        setShowReject(false);
        setShowRejectMsg(true);
        setReason("");
      })
      .catch((err) => {
        setErr(err?.response?.data.message);
        setShowErr(true);
      });
  };

  const RequestCall = (type) => {
    if (type === "accept") {
      setShowConfirmModal(false);
    } else {
      setDeclineModal(false);
    }
    let data = {
      payStatus: type === "accept" ? "request-accepted" : "request-rejected",
    };
    if (type !== "accept" && reason) {
      data.rejectionNote = reason;
    }

    if (type === "accept" && amountPaid !== "") {
      data.amountPaid = parseInt(amountPaid, 10);
    }

    if(merPenReqCheck){
      if(merchantSapType === "HQ"){
        data.merchheadquarterId = merchantSapTypeData?.merchuser?.merchheadquarterId;
      }
      if (merchantSapType === "store") {
        data.merchstoreId = merchantSapTypeData?.id;
      }
    }

    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

    const api = merPenReqCheck ? "status/by-merch" : "status";

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${childData.id}/debit/child-request/${api}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((data) => {
        shareLink(data?.data?.id);
        if (type !== "accept") {
          setShowReject(false);
          setShowRejectMsg(true);
          setReason("");
          return;
        }
        setShowConfirm(true);
      })
      .catch((err) => {
        setErr(err?.response?.data.message);
        setShowErr(true);
      });
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const shareLink = (id) => {
    const api = merPenReqCheck
      ? "merch-share-link-with-child"
      : "share-link-with-child";
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    const link = `https://sap-staging.jabcomp.gy/pensioner/response/${id}`;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${id}/${api}`,
        { link }, // Correcting the request body format
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((response) => {
        // router(`/pensioner/response/${id}`);

        console.log("Link shared successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error sharing link:", error.response.data.message);
        setErr(error.response.data.message);
      });
  };
  return (
    <>
      <div className="bg-[#F6F4F4] sm:m-6 m-2 rounded-md sm:px-10 px-6 py-8">
        <div
          className={` ${
            childData?.requestedByPensioner === true
              ? "hidden"
              : "flex items-center gap-4"
          }`}
        >
          <div className="relative w-[6rem] h-[6rem] overflow-hidden border-2 border-white rounded-full mb-4 ">
            <img
              className="object-cover w-[6rem] h-[6rem]"
              alt=""
              src={childData?.image ? childData?.image : pro}
            />
          </div>

          <div className="">
            <h4 className="sm:text-md font-semibold text-primary truncate  leading-none">
              {childData?.firstName} {childData?.middleName}{" "}
              {childData?.lastName}
            </h4>
            <p className="mt-1 text-sm font-semibold">{childData?.phone}</p>
            <p className="mt-1 text-sm font-semibold">{childData?.email}</p>
          </div>
        </div>
        <h1
          className={` ${
            childData?.requestedByPensioner === true
              ? "text-gray-800 whitespace-nowrap text-[0.94rem] font-bold sm:w-[40%] w-full"
              : "hidden"
          }`}
        >
          Sender Detail
        </h1>
        <div
          className={`${
            childData?.requestedByPensioner === true
              ? "hidden"
              : "flex justify-center items-center px-6 py-4 mt-4 rounded-md bg-white"
          }`}
        >
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center flex-col w-full gap-3">
              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  DOB
                </label>
                <p className="font-semibold text-sm text-gray-500">
                  {childData?.dateOfBirth}
                </p>
              </div>

              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Relation
                </label>
                <p className="font-semibold text-sm text-gray-500 capitalize">
                  {childData?.relation}
                </p>
              </div>
              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" || merPenReqCheck === true ? "" : "hidden"
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Amount Requested
                </label>
                <p className="font-bold text-[0.95rem] text-gray-700 ">
                  ${childData?.amountPaid}
                </p>
              </div>
              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" ? "hidden" : ""
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Purpose
                </label>
                <p className="font-semibold text-sm text-gray-500 capitalize">
                  {childData?.purpose}
                </p>
              </div>
              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" ? "" : "hidden"
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Purpose
                </label>
                <p className="font-semibold text-sm text-gray-700 capitalize ">
                  {childData?.purpose}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start w-full gap-1">
              <div className="flex items-center w-full flex-col gap-1 mb-2">
                {childData?.gid !== null ? (
                  <div className="flex items-center w-full  gap-1 justify-between mb-3">
                    <label className="text-gray-700 text-[0.9rem] font-semibold w-[4rem]">
                      GID #
                    </label>
                    <p className="font-semibold text-sm text-gray-500">
                      {childData?.gid}
                    </p>
                    <p>{""}</p>
                  </div>
                ) : (
                  ""
                )}
                <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
                  {childData?.gidFrontImage !== null ? (
                    <img
                      className="w-full h-[6rem]"
                      alt=""
                      src={childData?.gidFrontImage}
                    />
                  ) : (
                    ""
                  )}
                  {childData?.gidBackImage !== null ? (
                    <img
                      className="w-full h-[6rem]"
                      alt=""
                      src={childData?.gidBackImage}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            childData?.requestedByPensioner === true
              ? "flex sm:justify-center sm:flex-row flex-col  items-center sm:px-8 py-6 mt-3 px-4 rounded-md bg-white"
              : "hidden"
          }`}
        >
          <div className="flex justify-between sm:gap-8  gap-6 w-full ">
            <div className="flex items-center flex-col w-full gap-3">
              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Name
                </label>
                <p className="font-semibold text-sm text-gray-500 capitalize">
                  {childData?.firstName} {childData?.middleName}{" "}
                  {childData?.lastName}
                </p>
              </div>

              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Amount
                </label>
                <p className="font-semibold text-sm text-gray-500 capitalize">
                  ${childData?.amountPaid}
                </p>
              </div>
              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" ? "" : "hidden"
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Purpose
                </label>
                <p className="font-bold text-[0.95rem] text-gray-700 ">
                  {childData?.purpose}
                </p>
              </div>
              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" ? "hidden" : ""
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Purpose
                </label>
                <p className="font-semibold text-sm text-gray-500 capitalize">
                  {childData?.purpose}
                </p>
              </div>
              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" ? "" : "hidden"
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Purpose
                </label>
                <p className="font-semibold text-sm text-gray-700 capitalize ">
                  {childData?.purpose}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between sm:gap-8  gap-6 w-full sm:mt-0 mt-3">
            <div className="flex items-center flex-col w-full gap-3">
              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Phone
                </label>
                <p className="font-semibold text-sm text-gray-500">
                  {childData?.phone ? childData?.phone : "-----"}
                </p>
              </div>

              <div className="flex items-center w-full gap-3">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Email
                </label>
                <p className="font-semibold text-sm text-gray-500 ">
                  {childData?.email ? childData?.email : "-----"}
                </p>
              </div>

              <div
                className={`flex items-center w-full gap-3 ${
                  user === "pensioner" ? "hidden" : ""
                }`}
              >
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                  Relation
                </label>
                <p className="font-semibold text-sm text-gray-500 capitalize">
                  {childData?.relation}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center py-3 mt-1 rounded-md ${
            user === "pensioner" || merchReq ? "hidden" : ""
          }`}
        >
          <Checkbox
            className={`checkk2`}
            checked={verify}
            onClick={(e) => setVerify(e.target.checked)}
          >
            {childData?.requestedByPensioner === true
              ? "The details above have been verified and amount has been collected"
              : "The details above have been verified."}
          </Checkbox>
        </div>
        <div className="flex justify-start gap-1 flex-col py-3  rounded-md">
          <p className="text-gray-700 whitespace-nowrap text-[0.9rem] font-semibold sm:w-[40%] w-full mb-1">
            {user === "pensioner"
              ? "Store Detail"
              : merchReq
              ? "Pensioner Details ( Requested From )"
              : childData?.requestedByPensioner === true
              ? "Receiver Detail"
              : " Pensioner Details( Sender )"}
          </p>
          <div
            className={`flex items-center gap-4 bg-white sm:w-[45%] w-full p-5 rounded-md  ${
              user === "pensioner" ? "" : "hidden"
            }`}
          >
            <div className="flex items-center">
              <img
                className="w-16 h-16 rounded-full mr-3"
                src={
                  data?.merchstore !== null
                    ? data?.merchstore?.image
                    : data?.merchCompany?.logo
                }
                alt="profile"
              />
              <div className="text-sm">
                <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                  {data?.merchstore !== null
                    ? data?.merchstore?.customStoreName
                    : data?.merchCompany?.name}
                </h4>

                <p className="mt-1">
                  {data?.merchstore !== null
                    ? data?.merchstore?.primaryPhone
                    : data?.merchheadquarter?.primaryPhone}
                </p>
                <p className="mt-1">
                  {data?.merchstore !== null
                    ? data?.merchstore?.primaryEmail
                    : data?.merchheadquarter?.primaryEmail}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`flex items-center gap-4 bg-white sm:w-[45%] w-full p-2 rounded-md ${
              user === "pensioner" ? "hidden" : ""
            }`}
          >
            <div className="relative w-[6rem] h-[6rem] overflow-hidden border-2 border-white rounded-full mb-4 ">
              <img
                className="object-cover w-[6rem] h-[6rem]"
                alt=""
                src={
                  merchReq
                    ? data?.user?.person?.image
                    : childData?.pensionerUser?.image
                }
              />
            </div>

            <div className="">
              <h4 className="sm:text-md font-semibold text-primary truncate  leading-none">
                {merchReq
                  ? data?.user?.person?.firstName +
                    " " +
                    data?.user?.person?.lastName
                  : childData?.pensionerUser?.firstName +
                    " " +
                    childData?.pensionerUser?.lastName}
              </h4>
              <p className=" text-sm font-semibold mt-1">
                GID {merchReq ? data?.user?.gid : childData?.pensionerUser?.gid}
              </p>

              <p className=" text-sm font-semibold">
                {merchReq
                  ? data?.user?.countryCode !== null &&
                    data?.user?.countryCode + " " + data?.user?.person?.phoneNo
                  : childData?.pensionerUser?.countryCode !== null
                  ? childData?.pensionerUser?.countryCode +
                    " " +
                    childData?.pensionerUser?.phoneNo
                  : ""}
              </p>
              <p className=" text-sm font-semibold">
                {merchReq ? data?.user?.email : childData?.pensionerUser?.email}
              </p>
            </div>
          </div>
        </div>

        <div
          className={`flex justify-start gap-1 flex-col py-3  rounded-md ${
            user === "pensioner" || merchReq ? "hidden" : ""
          }`}
        >
          <div className="flex items-center w-full  gap-6">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[13rem]">
              {childData?.requestedByPensioner === true
                ? "Amount to be transfered"
                : " Amount to be paid"}
            </label>
            <p className="font-semibold text-primary text-lg font-bold">
              ${childData?.amountPaid}
            </p>
          </div>
        </div>

        {/* Change Amount */}
        <div
          className={`${
            user === "pensioner" || merchReq
              ? "flex justify-start  gap-1 flex-col py-5 px-5 mt-4  rounded-md bg-white"
              : "hidden"
          }`}
        >
          <p className="text-gray-700 whitespace-nowrap text-[0.9rem] font-semibold w-full mb-1">
            Alter Amount
          </p>
          <div
            className={`${
              paidFull
                ? "pointer-events-none flex lg:text-[1.1rem] sm:w-[20rem] w-full bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 justify-between"
                : "flex lg:text-[1.1rem] sm:w-[20rem] w-full bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 justify-between"
            }`}
          >
            <input
              type="number"
              placeholder="Enter Amount"
              onChange={(e) => setAmountPaid(e.target.value)}
              className="bg-[#F6F4F4]"
              value={amountPaid}
            />
            <p className="font-bold text-lg">$</p>
          </div>
          <Checkbox
            className={`checkk4 mt-6`}
            checked={paidFull}
            onChange={(e) => {
              setPaidFull(e.target.checked);
              setAmountPaid("");
            }}
          >
            Pay complete amount
          </Checkbox>
        </div>
      </div>
      <div
        className={`${
          childData?.requestedByPensioner === true
            ? "hidden"
            : "flex items-center gap-2 md:justify-start mb-5 sm:px-10 justify-center py-4 sm:py-0"
        }`}
      >
        <button
          onClick={() => {
            if (user === "pensioner") {
              if (amountPaid === "" && !paidFull) {
                setErr(
                  `Please confirm the payment amount; either you can check the box to pay the complete amount or you can change the amount in the alter amount section. $.`
                );
                setShowErr(true);
                return;
              }
              setShowConfirmModal(true);
              return;
            }
            if (merPenReqCheck) {
              setShowConfirmModal(true);
              return;
            }

            if (!verify) {
              setErr("Please verify the details above before proceeding.");
              setShowErr(true);
            } else {
              setShowConfirmPay(true);
            }
          }}
          className="bg-primary text-white rounded-md py-2 px-8 cursor-pointer"
        >
          {user === "pensioner" || merchReq ? "Accept" : "Initiate Payment"}
        </button>
        <button
          onClick={() => {
            if (user === "pensioner") {
              setDeclineModal(true);
              return;
            }
            setShowReject(true);
          }}
          className="bg-red-600 text-white rounded-md py-2 px-8 cursor-pointer"
        >
          {user === "pensioner" || merchReq ? "Reject" : " Reject Payment"}
        </button>
      </div>
      {/* Transfer Button */}
      <div
        className={`${
          childData?.requestedByPensioner === true
            ? "flex items-center gap-2 md:justify-start mb-5 sm:px-10 justify-center py-4 sm:py-0"
            : "hidden"
        }`}
      >
        <button
          onClick={() => {
            if (!verify) {
              setErr("Please verify the details above before proceeding.");
              setShowErr(true);
            } else {
              setShowConfirmPay(true);
            }
          }}
          className="bg-primary text-white rounded-md py-2 px-8 cursor-pointer"
        >
          Transfer Amount
        </button>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2 text-primary text-[1rem]">
            Request Accepted
          </h1>
          <p
            className={`text-center w-[85%] mt-2 mb-2 ${
              user === "pensioner" || merPenReqCheck === true ? "hidden" : ""
            }`}
          >
            The amount has been transferred from the pensioner's e-wallet to
            your e-wallet.
          </p>
          <p
            className={`text-center w-[85%] mt-2 mb-6 ${
              user === "pensioner" || merPenReqCheck === true ? "hidden" : ""
            }`}
          >
            You can now pay the cash amount to the receiver.
          </p>
          <p
            className={`text-center w-[85%] mt-2 mb-4 ${
              user === "pensioner" || merPenReqCheck === true ? "" : "hidden"
            }`}
          >
            <span className="font-bold">
              Amount ${paidFull ? childData?.amountPaid : amountPaid}
            </span>{" "}
            will be paid to {capitalize(childData?.firstName)}{" "}
            {capitalize(childData?.middleName)}{" "}
            {capitalize(childData?.lastName)}, if he goes to merchant with in 24
            hours to collect money
          </p>
          <div
            className={` ${
              user === "pensioner"
                ? "flex gap-4 w-[70%] items-center justify-center"
                : "flex gap-4 w-[70%] items-center justify-center"
            }`}
          >
            <button
              onClick={() => {
                setShowConfirm(false);
                setShow(true);
                merPenReqCheck ? router("/SapMoney/Merchant/Pensioner/Request/Listing"):
                router("/SapMoney/Pensioner/AllRequest");
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              {user === "pensioner" || merPenReqCheck === true
                ? "Okay"
                : "Cash Paid"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showReject ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl sm:w-[28rem] w-[24rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <div className="flex items-center flex-col w-full">
            <label className="text-gray-700 mt-4 text-[0.9rem] font-semibold w-[20rem] mb-2">
              Reason: <span className="text-red-600">*</span>
            </label>
            <textarea
              type="text"
              placeholder="Reason"
              className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 mb-2"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="flex gap-4 mt-6 w-[70%] items-center justify-center">
            <button
              onClick={RejectPay}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Done
            </button>
            <button
              onClick={() => {
                setShowReject(false);
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-red-600 border-red-600 ${
                user === "pensioner" ? "hidden" : ""
              }`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {/* recipt Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-4">
            {user === "pensioner" || merPenReqCheck === true
              ? "Request Accepted"
              : childData?.requestedByPensioner === true
              ? "Payment Sent"
              : "Payment Completed"}
          </h1>
          <p
            className={`${
              childData?.requestedByPensioner === true
                ? "text-center w-[90%] mt-2"
                : "hidden"
            }`}
          >
            Amount has been Transferred to pensioner SAP Money account
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between mt-3">
            <button
              onClick={() => {
                setShow(false);
                router("/Sap/Money");
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" || merPenReqCheck === true
                  ? "w-full"
                  : "w-[8rem]"
              }`}
            >
              Done
            </button>
            <button
              onClick={() => {
                handlePrint();
                setShow(false);
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" || merPenReqCheck === true ? "hidden" : ""
              }`}
            >
              Print Receipt
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showRejectMsg ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-1 flex items-center justify-center rounded-full">
            <img src={dec} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-4 mb-6">
            {user === "pensioner" ? "Request" : "Payment"} Rejected
          </h1>
          <div className="flex gap-4 w-[70%] items-center justify-center">
            <button
              onClick={() => {
                setShowRejectMsg(false);
                router("/Sap/Money");
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" ? "w-full" : "w-[8rem]"
              }`}
            >
              Done
            </button>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      <div
        className={`${
          showConfirmModal ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl sm:w-[28rem] w-[24rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-2 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">
              ${paidFull ? childData?.amountPaid : amountPaid}
            </span>{" "}
            to {capitalize(childData?.firstName)}{" "}
            {capitalize(childData?.middleName)}{" "}
            {capitalize(childData?.lastName)}?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                RequestCall("accept");
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmModal(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showConfirmPay ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl sm:w-[28rem] w-[24rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-2 mb-6">
            Are you sure you want to make this payment?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                ConfirmPay();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmPay(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showErr && (
        <div className="w-[100%] h-[100%] fixed z-[300] top-0 left-0 bg-slate-500/50 flex justify-center items-center">
          <div className="relative rounded-xl sm:w-[30rem] w-[24rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[25px] left-[42%]"
              onClick={() => setShowErr(false)}
            >
              <img src={logo} alt="Close" />
            </div>
            <div className="mt-4 w-[80%] mt-[6rem]">
              <h1 className="mb-4 w-full text-center font-semibold">{err}</h1>
            </div>
            <button
              onClick={() => setShowErr(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%] mt-4"
            >
              OK
            </button>
          </div>
        </div>
      )}
      {declineModal && (
        <RequestDeclined
          setShow={setDeclineModal}
          note={reason}
          setNote={setReason}
          data={data}
          setShowError={setShowErr}
          setErr={setErr}
          type={"pen"}
          RequestCall={RequestCall}
        />
      )}
    </>
  );
};

export default Main;
