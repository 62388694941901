import { useEffect, useState } from "react";
import Main from "./detailDiv";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import across from "../../../../../imgs/across.png";
import axios from "axios";
const Paget = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [paidFull, setPaidFull] = useState(false);
  const selectedGovAgency = useSelector((state) => state.selectedGovAgency);
  console.log(selectedGovAgency);
  const [amount, setAmount] = useState(selectedGovAgency?.amount);
  const user = useSelector((state) => state.user);
  const dataUser = useSelector((state) => state.dataUser);
  const [showError, setShowError] = useState(false);
  const [err, setErr] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [verify, setVerify] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    setAmount(selectedGovAgency?.amount);
    dispatch({ type: "REQ_AMOUNT", num: selectedGovAgency?.amount });
  }, []);
  const Reject = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-requests/${selectedGovAgency?.id}/reject`,
        {
          rejectReason: note,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((response) => {
        router("/MHSSS/GovernmentAgency/Requests");
        setVerify(false);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowError(true);
      });
  };

  return (
    <section className="p-2">
      <div className="mx-auto max-w-screen-xl ">
        <div className="bg-white w-full flex flex-col">
          <Main />
          <div className="bg-[#FAFAFA] mx-4 p-4 py-6">
            <div className="grid grid-cols-12 gap-4">
              <div className="sm:col-span-2 col-span-4  text-md font-medium leading-6 text-gray-600">
                Status
              </div>
              <div
                className={`border-none ${
                  selectedGovAgency?.status === "Average"
                    ? "bg-blue-500"
                    : selectedGovAgency?.status === "mhsss_rejected"
                    ? "bg-red-500"
                    : "bg-primary"
                }  py-1 rounded-md text-center text-white focus:border-none cursor-pointer capitalize w-[8rem]`}
              >
                {selectedGovAgency?.status === "mhsss_rejected"
                  ? "Rejected"
                  : selectedGovAgency?.status === "mhsss_paid"
                  ? "Paid"
                  : "Requested"}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 mt-4 flex items-center justify-center">
              <div className="sm:col-span-2 col-span-4 text-md font-medium leading-6 text-gray-600">
                {selectedGovAgency?.status === "mhsss_paid"
                  ? "Amount Paid"
                  : "Amount Requested"}
              </div>
              <div className="sm:col-span-10 col-span-8 bg-white px-2 py-1 text-[0.96rem] font-semibold leading-6 rounded-md w-[15rem] text-gray-600 sm:w-[10rem]">
                ${selectedGovAgency?.amount}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 mt-2  flex items-center justify-center">
              <div className="sm:col-span-2 col-span-4  text-md font-medium leading-6 text-gray-600">
                {selectedGovAgency?.status === "mhsss_rejected"
                  ? "Rejection Note"
                  : "Note"}
              </div>
              <div className="sm:col-span-10 col-span-8 text-md leading-6">
                {selectedGovAgency?.status === "mhsss_rejected"
                  ? selectedGovAgency?.rejectReason
                  : selectedGovAgency?.note}
              </div>
            </div>
          </div>

          {/* Change Amount */}
          <div
            className={`${
              selectedGovAgency?.status === "requested" ? "" : "hidden"
            }`}
          >
            <div
              className={` "flex justify-start  gap-1 flex-col py-5 px-5 mt-4  rounded-md bg-white`}
            >
              <p className="text-gray-700 whitespace-nowrap text-[0.9rem] font-semibold w-full mb-1">
                Alter Amount
              </p>
              <div
                className={`${
                  paidFull
                    ? "pointer-events-none flex lg:text-[1.1rem] sm:w-[20rem] w-full bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 justify-between"
                    : "flex lg:text-[1.1rem] sm:w-[20rem] w-full bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 justify-between"
                }`}
              >
                <input
                  type="number"
                  placeholder="Enter Amount"
                  onChange={(e) => setAmount(e.target.value)}
                  className="bg-[#F6F4F4]"
                  // value={amountPaid}
                />
                <p className="font-bold text-lg">$</p>
              </div>
              {/* <Checkbox
              className={`checkk4 mt-6`}
              checked={paidFull}
              onChange={(e) => {
                setPaidFull(e.target.checked);
                //   setAmountPaid("");
              }}
            >
              Pay complete amount
            </Checkbox> */}
            </div>
            <div className="flex w-full flex-row items-center px-4">
              <span className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer">
                i
              </span>
              <p className=" ml-2 text-right text-[14px] break-words text-[#7E7E7E]">
                The amount requested can be edited before pay
              </p>
            </div>

            {/* Buttons */}

            <div
              className={`flex items-center gap-2 md:justify-start mb-5 sm:px-10 justify-center py-4 sm:py-4 mt-2`}
            >
              <button
                className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
                onClick={() => {
                  dispatch({ type: "REQ_AMOUNT", num: amount });
                  router("/MHSSS/GovernmentAgency/Requests/payment");
                }}
              >
                {"Pay Now"}
              </button>
              <button
                onClick={() => {
                  setVerify(true);
                }}
                className="bg-red-600 text-white rounded-md py-2 px-12 cursor-pointer"
              >
                {"Decline"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {/* <div
        className={`${
          showConfirmModal ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-2 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${amount}</span> to{" "}
            
            {sapPaymentSelectedPerson?.name || "----"}
          </p>

          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                payment();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmModal(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div> */}

      {/* Declined */}

      <div
        className={`${
          verify ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center rounded-full">
            <img src={across} alt="" className="w-full h-full" />
          </div>

          <p className="text-center w-[90%] text-gray-500 mt-[1rem] mb-2 font-semibold">
            Are you sure you want to reject the request?
          </p>
          <div className="flex flex-col w-[85%]">
            <label className="text-gray-600 text-[0.8rem] font-semibold mb-1">
              Rejection Note
            </label>
            <textarea
              type="text"
              name=""
              onChange={(e) => setNote(e.target.value)}
              value={note}
              placeholder="Enter Rejection Note"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div className="w-[85%] flex items-center justify-center gap-2">
            <button
              onClick={() => {
                setVerify(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[50%]"
            >
              Cancel
            </button>

            <button
              onClick={() => {
                Reject();
                setVerify(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[50%]"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {showError && <ErrorModal err={err} setShow={setShowError} />}
    </section>
  );
};

export default Paget;
