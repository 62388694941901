import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);
const ChartCard = ({ chartdata, allData }) => {
  function formatNumber(amount) {
    const number = parseFloat(amount, 10);
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  }
  console.log(chartdata);
  const data = {
    labels: ["Total Pensioners", "Cash Out", "Pending"],
    datasets: [
      {
        // label: "# of Votes",
        data: [
          allData?.totalActivePensioners,
          allData?.totalPaidPensioners,
          allData?.totalActivePensioners - allData?.totalPaidPensioners,
        ],
        backgroundColor: ["#455A64", "#1BA397", "#1896D1"],
        borderColor: ["#455A64", "#1BA397", "#1896D1"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        // position: "right",
      },
      tooltip: {
        enabled: true, // Set this to false to disable tooltips
      },
    },
  };

  return (
    <div className="w-full flex flex-col p-2">
      <div className="flex justify-between">
        <div className="text-[#455A64]">
          <p className="sm:text-lg">
            {allData?.newlyAddedPensioners} Newly Added
          </p>
          <p className="sm:text-xl font-semibold ">Total Pensioner</p>
          <p className="sm:text-xl font-bold">
            {allData?.totalActivePensioners}
          </p>
        </div>
        <div className="text-primary">
          <p className="sm:text-lg">
            {allData?.totalActivePensioners !== 0
              ? (
                  (allData?.totalPaidPensioners /
                    allData?.totalActivePensioners) *
                  100
                ).toFixed(2)
              : 0}
            %
          </p>
          <p className="sm:text-xl font-semibold ">Cash Out</p>
          <p className="sm:text-xl font-bold">{allData?.totalPaidPensioners}</p>
        </div>
      </div>

      <div className="m-auto sm:w-[300px] sm:h-[300px] w-[200px] h-[200px]">
        <Pie data={data} options={options} />
      </div>

      <div className="flex justify-between">
        <div className="text-[#1896D1]">
          <p className="sm:text-lg">
            {allData?.totalActivePensioners !== 0
              ? (
                  ((allData?.totalActivePensioners -
                    allData?.totalPaidPensioners) /
                    allData?.totalActivePensioners) *
                  100
                ).toFixed(2)
              : 0}
            %
          </p>
          <p className="sm:text-xl font-semibold ">Pending</p>
          <p className="sm:text-xl font-bold">
            {allData?.totalActivePensioners - allData?.totalPaidPensioners}
          </p>
        </div>
        <div className="text-[#464646]">
          <p className="sm:text-2xl font-bold ">Paid</p>
          <p className="sm:text-2xl font-bold">
            {formatNumber(allData?.totalPaidAmount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
