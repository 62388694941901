import React from 'react';
// import sample from "../../../imgs/sample.jpg";
const SampleImageModal = ({ setShow,img,title}) => {
  return (
    <div
    className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
  >
    <div className="relative p-4 w-full max-w-md max-h-full">
      <div className="relative bg-white rounded-lg shadow ">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
          <h3 className="text-lg font-semibold text-gray-900 ">
            {title}
          </h3>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center "
            data-modal-toggle="select-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <img src={img} alt="voucher" className='w-full h-[15rem] p-3'/>

        {/* <div className="p-4 md:p-5">
          <p className="text-gray-500  mb-4">
            Select the Vouchers :
          </p>
          <ul className="space-y-4 max-h-96 overflow-y-scroll custom-scrollbar p-4">
            {activeVouchers.map((voc, index) => (
              <li key={index} onClick={() => handleSelection(index)}>
                <input
                  type="checkbox"
                  checked={selectedVouchers.includes(voc)}
                  onClick={() => alert("clicked")}
                  //   onChange={() => handleSelection(index)}
                  className="hidden peer"
                  required
                />
                <label
                  className={`inline-flex items-center justify-between w-full p-3 ${
                    selectedVouchers.includes(voc)
                      ? "bg-[#F5F5F5] text-primary border-primary"
                      : "text-gray-900 bg-white"
                  } shadow-sm border border-gray-300 rounded-lg cursor-pointer  peer-checked:border-primary peer-checked:text-primary `}
                >
                  <div className="block">
                    <div className="w-full text-md font-semibold">
                      {getMonthName(voc.month)}
                    </div>
                    <div className="w-full text-gray-500">
                      Booklet # {selectedProgramPensionBook?.bookletNo}
                    </div>
                  </div>
                  <img src={vc} alt="voc" className="w-6 h-6" />
                </label>
              </li>
            ))}
          </ul>
          <div className="flex p-4 gap-2">
          <button
            onClick={() => setShowVouchers(false)}
            className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-[#DE2827]  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Cancel
          </button>
          <button
            onClick={() => getAmount()}
            className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Calcualte
          </button>
        </div>
        </div> */}
       
      </div>
    </div>
    
  </div>

  )
};

export default SampleImageModal;
