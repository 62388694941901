import Contact from "../components/Contact/contact";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const ContactPage = () => {
  return (
    <div>
      <Nav />
      <Contact />
      <Whatsapp/>
    </div>
  );
};

export default ContactPage;
