import React, { useEffect, useState } from "react";
import ContactHeader from "./ContactList/Header";
import Contacts from "./ContactList/Contacts";
import ChatBodyHeader from "./ChatBody/Header";
import Messages from "./ChatBody/Messages";
import Input from "./ChatBody/Input";
import { useDispatch, useSelector } from "react-redux";

const MainComponent = () => {
  const [refetch, setRefetch] = React.useState(false);
  const selectedChatUser = useSelector((state) => state.selectedChatUser);
  const [search, setSearch] = React.useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "CHAT_MESSAGES", messages: [] });
    dispatch({ type: "CHAT_SELECTED_PHONE_NO", num: null });
    dispatch({ type: "CHAT_SELECTED_USER", num: null });
    dispatch({ type: "CHAT_SELECTED_NEW_USER", data: null });
  }, []);

  const [showContacts, setShowContacts] = useState(true);
  const handleContactClick = () => {
    setShowContacts(false);
    // Logic to set selected chat user
  };
  console.log(showContacts);

  return (
    <>
      {/* Mobile Screen UI */}
      <div className="mobile-screen">
        <div className="w-full h-28"></div>
        <div className="container mx-auto mt-[-128px]">
          <div className="py-6 h-screen">
            <div className="flex flex-col">
              {/* Contacts Section */}
              {selectedChatUser === null ? (
                <div className="border flex flex-col bg-white h-full">
                  <ContactHeader />
                  <div className="py-4 px-6 rounded-lg">
                    <input
                      type="text"
                      className="w-full px-4 py-2 text-sm rounded-lg bg-gray-200"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <Contacts
                    refetch={refetch}
                    search={search}
                    onContactClick={handleContactClick}
                  />
                </div>
              ) : (
                // Chat Section with Back Button
                <div className="flex flex-col h-screen">
                  <div className="flex-none">
                    <ChatBodyHeader />
                  </div>
                  <div className="flex-1 overflow-y-auto">
                    <Messages refetch={refetch} />
                  </div>
                  <div className="flex-none">
                    <Input refetch={refetch} setRefetch={setRefetch} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Larger Screen UI */}

      <div className="larger-screen">
        <div className="w-full h-32"></div>
        <div className="container mx-auto mt-[-128px]">
          <div className="py-6 h-screen">
            <div className="flex border border-grey rounded shadow-lg h-full">
              {/* Left */}
              <div className="w-1/3 border flex flex-col bg-white h-full">
                <ContactHeader />
                <div className="py-4 px-6 rounded-lg">
                  <input
                    type="text"
                    className="w-full px-4 py-2 text-sm rounded-lg  bg-gray-200 "
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <Contacts refetch={refetch} search={search} />
              </div>
              {/* Right */}
              <div className="w-2/3 border flex flex-col">
                {selectedChatUser === null ? (
                  <div className="flex-1 flex justify-center items-center">
                    <p className="text-gray-500 sm:text-xl text-md">
                      Select a chat to start messaging
                    </p>
                  </div>
                ) : (
                  <>
                    <ChatBodyHeader />
                    <Messages refetch={refetch} />
                    <Input refetch={refetch} setRefetch={setRefetch} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainComponent;
