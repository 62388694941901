import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./sideBar";
import Main from "./Main";

import { useDispatch, useSelector } from "react-redux";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import axios from "axios";

// const Index = () => {
//   const dataUser = useSelector((state) => state?.dataUser);
//   const openSideBar = useSelector((state) => state.openSideBar);
//   const user = useSelector((state) => state.user);
//   const token = useSelector((state) => state.token);
//   const dispatch = useDispatch();
//   const type = useSelector((state) => state.merchantSapType);
//   const typeData = useSelector((state) => state.merchantSapTypeData);

//   useEffect(() => {
//     dispatch({ type: "SIDEBAR", num: "1" });
//     dispatch({ type: "OPEN_SIDEBAR", num: false });
//     dispatch({ type: "SELECTED_PENSIONER_SAP", num: null });
//     dispatch({ type: "TOTAL_BALANCE", num: 0 });
//   }, []);

//   useEffect(() => {
//     // if (user !== "pensioner") {
//     //   return;
//     // }
//     if (
//       user === "pensioner" ||
//       user === "merchant-owner" ||
//       user === "merchant-employee" ||
//       user === "admin" ||
//       user === "superadmin" ||
//       user === "employee"
//     ) {
//       const api =
//         user === "pensioner"
//           ? "v1/sap-wallet-info/own"
//           : user === "admin" || user === "superadmin" || user === "employee"
//           ? "v1/sap-wallet-info/mhsss-global"
//           : `v1/sap-wallet-info/by-store-or-hq/for-merch?${
//               type === "store"
//                 ? `merchstoreId=${typeData?.id}`
//                 : `merchheadquarterId=${typeData?.merchuser?.merchheadquarterId}`
//             }`;
//       const tok =
//         user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
//       axios
//         .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
//           headers: {
//             Authorization: `Bearer ${tok}`,
//           },
//         })
//         .then((data) => {
//           dispatch({ type: "TOTAL_BALANCE", num: data?.data?.totalBalance });
//         })
//         .catch((err) => {});
//     }
//   }, []);

//   return (
//     <>
//       <div className="mobile-screen h-screen flex flex-col">
//         {/* Header with hamburger icon */}
//         <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
//           {/* <MenuOutlined
//             className="text-2xl cursor-pointer"
//             onClick={toggleSidebar}
//           /> */}
//           <MenuUnfoldOutlined
//             className="text-2xl cursor-pointer"
//             onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
//           />

//           <h1 className="text-xl font-bold">Dashboard</h1>
//         </header>

//         {/* Sidebar with conditional rendering based on showSidebar state */}
//         {openSideBar && (
//           <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
//             <Sidebar />
//           </div>
//         )}
//         <Header />
//         {/* Main content area */}
//         <div className="flex-grow overflow-y-auto">
//           {/* Content area */}
//           <main className="flex-grow overflow-y-auto p-4">
//             <Main />
//           </main>
//         </div>
//       </div>

//       <div className="larger-screen">
//         <div className="flex flex-row h-[100%] p-5 gap-7 bg-[#ebfff3]">
//           <aside
//             className={`w-1/5 rounded-lg`}
//             style={{
//               backgroundColor: "white",
//               boxShadow:
//                 "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
//               zIndex: 10, // Ensure sidebar is above the content
//             }}
//           >
//             <Sidebar />
//           </aside>
//           <div className="flex flex-col w-4/5 gap-5 ">
//             <header
//               className="h-12 shrink-0 rounded-lg"
//               //   style={{
//               //     backgroundColor: "white",
//               //     boxShadow:
//               //       "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
//               //   }}
//             >
//               <Header />
//             </header>
//             <main
//               className="flex-grow rounded-lg p-3"
//               style={{
//                 backgroundColor: "white",
//                 boxShadow:
//                   "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
//               }}
//             >
//               <Main />
//             </main>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();

  const dataUser = useSelector((state) => state?.dataUser);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const type = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "1" });
    dispatch({ type: "OPEN_SIDEBAR", num: false });
    dispatch({ type: "SELECTED_PENSIONER_SAP", num: null });
    dispatch({ type: "TOTAL_BALANCE", num: 0 });
  }, []);

  useEffect(() => {
    // if (user !== "pensioner") {
    //   return;
    // }
    if (
      user === "pensioner" ||
      user === "merchant-owner" ||
      user === "merchant-employee" ||
      user === "admin" ||
      user === "superadmin" ||
      user === "employee" ||
      user === "merchant-admin"
    ) {
      const api =
        user === "pensioner"
          ? "v1/sap-wallet-info/own"
          : user === "admin" || user === "superadmin" || user === "employee"
          ? "v1/sap-wallet-info/mhsss-global"
          : `v1/sap-wallet-info/by-store-or-hq/for-merch?${
              type === "store"
                ? `merchstoreId=${typeData?.id}`
                : `merchheadquarterId=${typeData?.merchuser?.merchheadquarterId}`
            }`;
      const tok =
        user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        })
        .then((data) => {
          dispatch({ type: "TOTAL_BALANCE", num: data?.data?.totalBalance });
        })
        .catch((err) => {});
    }
  }, []);
  const w = window.innerWidth;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    dispatch({ type: "PAYMENT", num: false });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`${
          windowSize?.width <= 768
            ? "h-screen flex flex-col"
            : "flex flex-row p-5 gap-7 min-h-screen bg-[#ebfff3] w-full"
        }`}
      >
        {windowSize?.width <= 768 ? (
          <>
            <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
              <MenuUnfoldOutlined
                className="text-2xl cursor-pointer"
                onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
              />
              <h1 className="text-xl font-bold">Dashboard</h1>
            </header>
            {openSideBar && (
              <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
                <Sidebar />
              </div>
            )}
          </>
        ) : (
          <aside
            className="w-1/5 rounded-lg"
            style={{
              backgroundColor: "white",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
            }}
          >
            <Sidebar />
          </aside>
        )}
        <div
          className={`${
            windowSize?.width <= 768
              ? "flex-grow overflow-y-auto p-4"
              : "flex flex-col w-4/5 gap-5  "
          }`}
        >
          <header className="sm:h-12 h-20 shrink-0 rounded-lg">
            <Header />
          </header>
          <main
            className={`${
              windowSize?.width <= 768 ? "" : "flex-grow mt-2 rounded-lg p-3  "
            }`}
            style={
              windowSize?.width <= 768
                ? {}
                : {
                    backgroundColor: "white",
                    boxShadow:
                      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                  }
            }
          >
            <Main />
          </main>
        </div>
      </div>
    </>
  );
};
export default Index;
