const HospitalsArray = [
  { label: "Aishalton Hospital", value: "Aishalton Hospital" },
  { label: "Balwant Singh Hospital", value: "Balwant Singh Hospital" },
  { label: "Bartica Hospital", value: "Bartica Hospital" },
  { label: "Charity Hospital", value: "Charity Hospital" },
  { label: "Davis Memorial Hospital", value: "Davis Memorial Hospital" },
  { label: "Fort Wellington Hospital", value: "Fort Wellington Hospital" },
  { label: "Georgetown Public Hospital", value: "Georgetown Public Hospital" },
  { label: "Kamarang Hospital", value: "Kamarang Hospital" },
  { label: "Kato Hospital", value: "Kato Hospital" },
  { label: "Kumaka/Moruca Hospital", value: "Kumaka/Moruca Hospital" },
  { label: "Kwakwani Hospital", value: "Kwakwani Hospital" },
  { label: "Leguan Hospital", value: "Leguan Hospital" },
  { label: "Lethem Hospital", value: "Lethem Hospital" },
  { label: "Linden Hospital Complex", value: "Linden Hospital Complex" },
  { label: "Mahdia Hospital", value: "Mahdia Hospital" },
  { label: "Mabaruma Hospital", value: "Mabaruma Hospital" },
  { label: "Medical Arts Center", value: "Medical Arts Center" },
  { label: "Mibicuri Hospital", value: "Mibicuri Hospital" },
  {
    label: "New Amsterdam Public Hospital",
    value: "New Amsterdam Public Hospital",
  },
  {
    label: "Pakera/Matthew Ridge Hospital",
    value: "Pakera/Matthew Ridge Hospital",
  },
  { label: "Port Kaituma Hospital", value: "Port Kaituma Hospital" },
  { label: "Port Mourant Hospital", value: "Port Mourant Hospital" },
  { label: "Prasad Hospital", value: "Prasad Hospital" },
  { label: "Suddie Hospital", value: "Suddie Hospital" },
  { label: "Skeldon Hospital", value: "Skeldon Hospital" },
  { label: "St. Joseph Mercy Hospital", value: "St. Joseph Mercy Hospital" },
  { label: "Wakenaam Hospital", value: "Wakenaam Hospital" },
  {
    label: "West Demerara Regional Hospital",
    value: "West Demerara Regional Hospital",
  },
  { label: "Wismar Hospital", value: "Wismar Hospital" },
  { label: "Woodland Hospital", value: "Woodland Hospital" },
  { label: "Other", value: "Other" },
];
export default HospitalsArray;
