/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import logo from "../../../imgs/logo12.png";
import notifications from "../../../imgs/notifications.png";
import activehome from "../../../imgs/home.png";
import bank from "../../../imgs/bank.svg";
import across from "../../../imgs/across.png";
import inactivelogOff from "../../../imgs/logOff.png";
import person_pin from "../../../imgs/person_pin.svg";
import demography from "../../../imgs/demography.png";
import Activeperson_pin from "../../../imgs/Activeperson_pin.svg";
import Inactivenotifications from "../../../imgs/Inactivenotifications.svg";
import emp from "../../../imgs/emp.svg";
import activeEmp from "../../../imgs/activeEmp.svg";
import activedemography from "../../../imgs/activedemography.svg";
import home from "../../../imgs/home.svg";
import support from "../../../imgs/support.svg";
import activeSupport from "../../../imgs/activeSupport.svg";
import account_balance from "../../../imgs/account_balance.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { MenuFoldOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );

  const handleLogOff = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-auth/logout`, {
        refreshToken: `${dataUser?.data?.merchTokens?.refresh?.token}`,
      })
      .then(() => {
        dispatch({ type: "MERCHANT_SAP_TYPE", num: "" });
        dispatch({
          type: "MERCHANT_SAP_TYPE_DATA",
          num: "",
        });
        dispatch({
          type: "SELECTED_STORE",
          num: null,
        });
        dispatch({ type: "authStateSet", num: "false" });
        dispatch({ type: "tokenSet", num: "0" });
        dispatch({ type: "tokenRef", num: "0" });
        dispatch({ type: "setUserData", num: [] });
        dispatch({ type: "BRSET", num: "0" });
        dispatch({ type: "RSET", num: 0 });
        dispatch({ type: "ISET", num: 1 });
        dispatch({ type: "BRSET", num: 0 });
        dispatch({ type: "BISET", num: 0 });
        dispatch({ type: "ASET", num: 0 });
        dispatch({
          type: "HEADQUARTER_PRIVILEGES_MERCHANT_EMPLOYEE",
          num: [],
        });
        router("/");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };
  // Define an array of menu items
  const menuItems = [
    {
      key: "1",
      activeIcon: activehome,
      text: "Dashboard",
      inactiveIcon: home,
      router: "/Merchant/dashboard",
    },
    {
      key: "8",
      activeIcon: activeEmp,
      text: "Employees",
      inactiveIcon: emp,
      router: "/Merchant/Employee/List",
      hideForEmployee: true,
    },
    {
      key: "2",
      activeIcon: account_balance,
      text: "Balance and Banking",
      inactiveIcon: bank,
    },
    {
      key: "3",
      activeIcon: activedemography,
      text: "My Reports",
      router: "/Merchant/Reports",
      inactiveIcon: demography,
    },

    {
      key: "4",
      activeIcon: Inactivenotifications,
      text: "Notifications",
      inactiveIcon: notifications,
    },
    {
      key: "5",
      activeIcon: Activeperson_pin,
      text: "Profile",
      router: "/Merchant/profile",
      inactiveIcon: person_pin,
      // hideForEmployee: true,
    },
    {
      key: "6",
      activeIcon: activeSupport,
      text: "Support",
      inactiveIcon: support,
    },
    {
      key: "7",
      activeIcon: inactivelogOff,
      text: "Log Off",
      inactiveIcon: inactivelogOff,
    },
  ];

  return (
    <div className="bg-white rounded-lg flex space-between flex-col h-full ">
      <aside
        id="logo-sidebar"
        aria-label="Sidebar"
        className="h-full border-r border-gray-200"
      >
        <div className="px-4 py-6">
          <div className="flex justify-between">
            <a
              href="#"
              className="flex items-center mb-4"
              onClick={(event) => {
                dispatch({ type: "SIDEBAR", num: "1" });
                event.preventDefault();
                router("/Merchant/dashboard");
              }}
            >
              <img src={logo} className="h-8 mr-2" alt="Flowbite Logo" />
              <span className="text-xl font-bold text-primary">Merchant</span>
            </a>
            <MenuFoldOutlined
              className="text-2xl cursor-pointer text-gray-600 sm:hidden"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: false })}
            />
          </div>

          <ul className="space-y-2 font-medium text-primary text-[1rem]">
            {menuItems
              .filter(
                (item) =>
                  !(user === "merchant-employee" && item.hideForEmployee)
              )
              .map((menuItem) => (
                <li
                  key={menuItem.key}
                  onClick={() => {
                    if (menuItem.key === "7") {
                      handleLogOff();
                    }
                    dispatch({ type: "SIDEBAR", num: menuItem.key });
                  }}
                >
                  <a
                    href="#"
                    className={`flex items-center p-3 rounded-lg ${
                      sidebar === menuItem.key
                        ? "bg-primary text-white"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      router(menuItem.router);
                      dispatch({ type: "OPEN_SIDEBAR", num: false });
                    }}
                  >
                    <img
                      src={
                        sidebar === menuItem.key
                          ? menuItem.activeIcon
                          : menuItem.inactiveIcon
                      }
                      alt={menuItem.text}
                      className="h-5 w-5 mr-3"
                    />
                    <span
                      className={
                        sidebar === menuItem.key ? "text-white" : "text-primary"
                      }
                    >
                      {menuItem.text}
                    </span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </aside>

      <div className="flex mt-auto items-center justify-center text-center gap-3 sm:hidden">
        <Avatar
          src={
            user === "merchant-owner" || user === "merchant-admin"
              ? dataUser?.data?.merchuser?.merchCompany?.logo
              : merchantEmployeeInfo?.data?.merchPerson?.image
          }
          style={{
            backgroundColor: "#1BA397",
          }}
          shape="circle"
          size={48}
        />
        <div className="text-center text-gray-600 text-md ">
          <span className="block font-bold ">
            {(dataUser?.data?.user?.person ||
              merchantEmployeeInfo?.data?.merchPerson) && (
              <h2
                className={`font-bold ${
                  user === "merchant-employee" ? "" : "hidden"
                }`}
              >
                {merchantEmployeeInfo?.data?.merchPerson?.firstName}&nbsp;
                {merchantEmployeeInfo?.data?.merchPerson?.lastName}
              </h2>
            )}
            <h2
              className={`font-bold ${
                user === "merchant-employee" ? "hidden" : ""
              }`}
            >
              {dataUser?.data?.merchuser?.merchCompany?.name}
            </h2>
          </span>
        </div>
      </div>
      {/* Profile Section */}
      <p className="text-gray-400 mt-auto mb-6 text-center px-4 py-2">
        Version 1.2.0
      </p>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
