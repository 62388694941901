import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import First from "./Steps/FirstStep";
import Second from "./Steps/SecondStep";
import Third from "./Steps/ThirdStep";
import Fourth from "./Steps/FourthStep";
import Fifth from "./Steps/FifthStep";
import Sixth from "./Steps/SixthStep";
import Seventh from "./Steps/SeventhStep";
import Eigth from "./Steps/EigthStep";
const Employee = () => {
  const ecurrent = useSelector((state) => state.ecurrent);
  const router = useNavigate();
  const merchantStores = useSelector((state) => state.merchantStores);
  console.log(merchantStores);
  const Show =
    merchantStores.length > 0
      ? [First, Second, Third, Fourth, Fifth, Sixth, Seventh,Eigth]
      : [First, Second, Third, Fourth, Fifth, Sixth,Eigth];
  const Dis = Show[ecurrent];
  return (
    <div className="">
      <div
        className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                router(-1)
                // dispatch({ type: "ALL_TRANSACTIONS", num: "All" });
                // router("/Merchant/Transactions");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Add Employee
            </h2>
          </div>
        </div>
      </div>
      <Dis />
    </div>
  );
};

export default Employee;
