import React from "react";
import GovAgencyReq from "../components/MHSSS Accounting/govAgencies/govBalance/requests/index";

const Gov = () => {
  return (
    <div>
      <GovAgencyReq />
    </div>
  );
};

export default Gov;
