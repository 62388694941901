import React from "react";
import print from "../../../../imgs/print.png";
import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import logo from "../../../../imgs/logomhs.png";
import imageUrl from "../../../../imgs/bgset.png";
import profile from "../../../../imgs/profile.png";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const App = () => {
  const dispatch = useDispatch();
  // const {
  //   token: { borderRadiusLG },
  // } = theme.useToken();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "1" });
  }, []);

  const handlePrint = () => {
    const data = {
      transactionId: "1214325465",
      transactionDate: "26th Feb 2024",
      transactionType: "Bank Transfer",
      programName: "Senior Citizen Pension",
      merchantsName: "Abu Bakar",
      store: "YUP Store",
      status: "Paid",
      amount: "$3000",
      pensionerName: "Pensioner",
      voucherCode: "12X3",
      email: "info@example.com",
      phone: "+234XXXXXXXX",
    };
    const mhsss = logo;
    const callImg = slipcall;
    const emailImg = slipemail;
    const bg = slipbg;
    const printFunc = (mhsss, callImg, emailImg) => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentWindow.document;
      let printableContent = `
        <html>
            <head>
                <title>Print</title>
                <style>
                    body {
                        font-size: 25pt;
                    }
                    @media print {
                        .printable-container {
                            -webkit-print-color-adjust: exact;
                            color-adjust: exact; 
                            background-color: #ffffff;
                            background-image: url('${bg}');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                            margin-bottom: 1.5rem;
                            padding: 0.5rem;
                        }
                        .d1 {
                            border-radius: 0.25rem;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            padding-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .d2 {
                            width: 6rem; 
                            height: 5rem;
                            padding-top: 1rem;
                        }
                        .d3 {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .hh1 {
                            font-weight: 600;
                            text-align: center;
                            width: 65%;
                        }
                        .d4 {
                            display: flex;
                            width: 80%;
                            flex-direction: column;
                            border-bottom: 1px solid #e2e8f0;
                            padding-bottom: 1rem;
                            font-size: 1rem;
                        }
                        .pp {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .ss {
                            color: #718096;
                        }
                        .d5 {
                            display: flex;
                            flex-direction: column;
                            padding-top: 0.5rem;
                            font-size: 1rem;
                            width: 80%;
                        }
                        .d6 {
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                        }
                        .call {
                            width: 1.25rem;
                            height: 1.25rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .mail {
                            width: 1.25rem;
                            height: 1rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .d7 {
                            display: flex;
                            width: 100% ;
                            align-items: center;
                        }
                        .sss {
                            font-weight: 600;
                        }
                    }
                </style>
            </head>
            <body>
                <div class="printable-container">
                    <div class="d1">
                        <img id="mhsss" alt="chippz" class="d2" src="${mhsss}" />
                        <div class="d3">
                            <h4 class="hh1">Ministry of Human Services and Social Security</h4>
                        </div>
                        <div class="d4">
                            <p class="pp">
                                <span class="ss">Transaction Id</span>
                                <span>${data.transactionId}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Transaction Date</span>
                                <span>${data.transactionDate}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Program Name</span>
                                <span>${data.programName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Merchants Name</span>
                                <span>${data.merchantsName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Store</span>
                                <span class="sss">${data.store}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Status</span>
                                <span class="sss">${data.status}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Amount</span>
                                <span class="sss">${data.amount}</span>
                            </p>
                        </div>
                        <div class="d5">
                            <p class="pp">
                                <span class="ss">Pensioner Name</span>
                                <span>${data.pensionerName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Voucher Code</span>
                                <span>${data.voucherCode}</span>
                                </p>
                                <div class="d6">
                                    <p class="d7">
                                        <img id="emailImg" class="mail" alt="chippz" src="${emailImg}" />
                                        ${data.email}
                                    </p>
                                    <p class="d7">
                                        <img id="callImg" class="call" alt="chippz" src="${callImg}" />
                                        ${data.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        `;
      iframeDocument.open();
      iframeDocument.write(printableContent);
      iframeDocument.close();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    };
    const imageSources = [mhsss, callImg, emailImg];
    let loadedImages = 0;
    imageSources.forEach((src) => {
      const img = new Image();
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageSources.length) {
          printFunc(mhsss, callImg, emailImg);
        }
      };
      img.src = src;
    });
  };
  return (
    <div className="flex flex-col w-full overflow-hidden px-8 py-8 min-h-[82vh] p-4 rounded-md bg-white">
      <div className="h-full relative rounded-tl-xl rounded-tr-xl bg-[#E0EFE899] border border-primary shadow-lg">
        <div className="rounded-tl-lg  rounded-tr-lg w-full p-4 flex justify-between items-center h-[15%] bg-primary">
          <div className="text-left text-center text-white">
            <p className="text-sm">Sent: 08th Mar, 2024 10:05 PM</p>
          </div>
          <div className="text-right text-white">
            <p className="text-sm">Amount: $200</p>
          </div>
        </div>
        <div className="w-full mb-4 py-4 h-[80%] gap-7 flex items-center justify-center flex-col">
          <div className="w-[90%] h-[6rem] rounded-lg flex">
            <div className="h-[6rem] w-[6rem] rounded-full border-[5px] border-white overflow-hidden">
              <img
                src={profile}
                alt="Background"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="w-[40%] ml-4 h-full flex items-center border-r flex-col gap-y-1 pr-4 p-2">
              <div className=" flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Merchant Name :
                </h2>
                <p className="w-[50%] text-right font-semibold text-[12px]">
                  Riva
                </p>
              </div>
              <div className="flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Program Name :
                </h2>
                <p className="w-[50%] text-right font-semibold text-[12px]">
                  SCP
                </p>
              </div>
              <div className="flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Store :
                </h2>
                <p className="w-[50%] text-right font-semibold text-[12px]">
                  ABC Store
                </p>
              </div>
            </div>
            <div className="w-[40%] ml-4 h-full flex items-center flex-col gap-y-1 pr-4 p-2">
              <div className=" flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Requested On :
                </h2>
                <p className="w-[50%] text-right font-semibold text-[12px]">
                  07th Mar, 2024 8:00 P.m.
                </p>
              </div>
              <div className="flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Notes :
                </h2>
                <p className="w-[50%] text-right font-semibold text-[12px]">
                  Urgently needed
                </p>
              </div>
              <div className="flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Transaction Id :
                </h2>
                <p className="w-[50%] text-right font-semibold text-[12px]">
                  265626562
                </p>
              </div>
              <div className="flex w-full">
                <h2 className="w-[50%] text-left font-semibold text-[13px]">
                  Received On :
                </h2>
                <p className="w-[50%] text-right font-semibold flex justify-end text-[12px]">
                  <span className="p-1 w-[80px] rounded-xl bg-red-300 flex items-center justify-center text-[#DA1E28]">
                    Pending
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-[90%] mb-4 h-[6rem] rounded-lg flex">
            <div
              className="w-full h-full flex rounded-full rounded-tr-none rounded-br-none"
              style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="h-[6rem] w-[6rem] rounded-full border-[5px] border-white overflow-hidden">
                <img
                  src={profile}
                  alt="Background"
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="w-[40%] ml-4 h-full flex items-center border-r flex-col gap-y-1 pr-4 p-2">
                <div className=" flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Pensioner Name :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    Akhil
                  </p>
                </div>
                <div className="flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Phone Number :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    +52-12563-125
                  </p>
                </div>
                <div className="flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Email Id :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    ABC@gmail.com
                  </p>
                </div>
              </div>
              <div className="w-[40%] ml-4 h-full flex items-center flex-col gap-y-1 pr-4 p-2">
                <div className=" flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Voucher code :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    AMc124555
                  </p>
                </div>
                <div className="flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Transaction Id :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    265626562
                  </p>
                </div>
                <div className="flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Paid on :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    6th Mar, 2024 6:10 pm
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={handlePrint}
          className="absolute bottom-[-4px] cursor-pointer right-[-25px] w-[100px] h-[45px] mr-4 flex items-center justify-center"
          style={{
            backgroundImage: `url(${print})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <span className="text-primary flex items-center justify-center hover:text-[#0D6B5A] font-semibold">
            Print
          </span>
        </div>
      </div>
    </div>
  );
};
export default App;
