import Eregistration from "./Parts/Eregistration";
import Eidentification from "./Parts/Eidentification";
import { useState, useEffect } from "react";
import back5 from "../../../../imgs/back5.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Eaccount = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const empId = useSelector((state) => state.universalEmpId);
  const profileData = useSelector((state) => state.profileData);
  const reset = useSelector((state) => state.reset);
  const [wow, setWow] = useState(0);
  const dataUser = useSelector((state) => state.dataUser);

  const Show = [Eregistration, Eidentification];
  const Dis = Show[wow];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  useEffect(() => {
    let api;
    if (
      dash === "/GroAdminDash/Employees/Profile/EditEmployee" ||
      dash === "/superAdminDashboard/GroempProfile/EditEmployee"
    )
      api = `v1/users/gro-employee-profile/${empId}`;
    else if (dash === "/superAdminDashboard/GroAdminProfile/EditAdmin")
      api = `v1/users/gro-admin-profile/${empId}`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        dispatch({ type: "setProfileData", num: data?.data });
      })
      .catch(() => {});
  }, [reset]);
  return (
    <div className="pt-[5rem] pb-[5rem] md:pl-[12rem] md:pr-[12rem] pl-4 pr-4">
      <div>
        <div className="flex items-center mb-[3rem]">
          <div className="cursor-pointer">
            <img
              onClick={() => {
                dash === "/superAdminDashboard/GroempProfile/EditEmployee"
                  ? router("/superAdminDashboard/GroempProfile")
                  : dash === "/superAdminDashboard/GroAdminProfile/EditAdmin"
                  ? router("/superAdminDashboard/GroAdminProfile")
                  : router("/GroAdminDash/Employees/Profile");
              }}
              className="w-[1.7rem]"
              src={back5}
              alt=""
            />
          </div>
          <div className="font-bold sm:text-[1.8rem] text-[1.2rem] ml-[1rem]">
            Edit{" "}
            {profileData.user.roles[0] === "gro-employee"
              ? "GRO Employee"
              : "GRO Admin"}
          </div>
        </div>
        <div className="lg:mb-[4rem] flex mb-[2rem] sm:pr-0 sm:pl-0 pr-2 pl-2">
          <div className="cursor-pointer" onClick={() => changeHandler("R")}>
            <h2
              className={`lg:text-[1.1rem] ${
                wow === 0 ? "font-semibold" : ""
              } `}
            >
              Registration Details
            </h2>
            <div
              className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
              } `}
            ></div>
          </div>

          <div className="cursor-pointer" onClick={() => changeHandler("I")}>
            <h2
              className={`lg:text-[1.1rem] ml-6 ${
                wow === 1 ? "font-semibold" : ""
              } `}
            >
              Identification Details
            </h2>
            <div
              className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ml-6 ${
                wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
              } `}
            ></div>
          </div>
        </div>
        <div>
          <Dis />
        </div>
      </div>
    </div>
  );
};

export default Eaccount;
