export const merchantStatus = (state = false, action) => {
  switch (action.type) {
    case "MERCHANT_STATUS":
      return action.num;
    default:
      return state;
  }
};

export const openSideBar = (state = false, action) => {
  switch (action.type) {
    case "OPEN_SIDEBAR":
      return action.num;
    default:
      return state;
  }
};

export const merchantCompanyReducer = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_COMPANY":
      return action.num;
    default:
      return state;
  }
};
export const merchType = (state = "", action) => {
  switch (action.type) {
    case "MERCH":
      return "merch";
    case "GOV":
      return "gov";
    default:
      return state;
  }
};

export const selectedPensioner = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_PENSIONER":
      return action.num;
    default:
      return state;
  }
};

export const selectedStore = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_STORE":
      return action.num;
    default:
      return state;
  }
};
export const empType = (state = "", action) => {
  switch (action.type) {
    case "STORE":
      return "store";
    case "HQ":
      return "hq";
    default:
      return state;
  }
};

export const completeProfile = (state = false, action) => {
  switch (action.type) {
    case "COMPLETE_PROFILE":
      return action.num;
    default:
      return state;
  }
};

export const allTransactions = (state = false, action) => {
  switch (action.type) {
    case "ALL_TRANSACTIONS":
      return action.num;
    default:
      return state;
  }
};

export const payment = (state = false, action) => {
  switch (action.type) {
    case "PAYMENT":
      return action.num;
    default:
      return state;
  }
};

export const allReports = (state = false, action) => {
  switch (action.type) {
    case "ALL_REPORTS":
      return action.num;
    default:
      return state;
  }
};

export const selectedPensionerPrograms = (state = [], action) => {
  switch (action.type) {
    case "SELECTED_PENSIONER_PROGRAMS":
      return action.num;
    default:
      return state;
  }
};
export const selectedPensionerVouchers = (state = [], action) => {
  switch (action.type) {
    case "SELECTED_PENSIONER_VOUCHERS":
      return action.num;
    default:
      return state;
  }
};

export const selectedProgramPensionBook = (state = [], action) => {
  switch (action.type) {
    case "SELECTED_PROGRAM_PENSION_BOOK":
      return action.num;
    default:
      return state;
  }
};

export const selectedReportPensioner = (state = [], action) => {
  switch (action.type) {
    case "SELECTED_REPORT_PENSIONER":
      return action.num;
    default:
      return state;
  }
};

export const activePensionerSession = (state = null, action) => {
  switch (action.type) {
    case "ACTIVE_PENSIONER_SESSION":
      return action.num;
    default:
      return state;
  }
};
export const isGovernmentAgency = (state = false, action) => {
  switch (action.type) {
    case "GovTrue":
      return true;
    case "GovFalse":
      return false;
    default:
      return state;
  }
};

export const merchantStores = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_STORES":
      return action.num;
    default:
      return state;
  }
};

export const activeVouchers = (state = null, action) => {
  switch (action.type) {
    case "ACTIVE_VOUCHERS":
      return action.num;
    default:
      return state;
  }
};

export const voucherPayResponse = (state = null, action) => {
  switch (action.type) {
    case "VOUCHER_PAY_RESPONSE":
      return action.num;
    default:
      return state;
  }
};

export const selectedVoucherForCash = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_VOUCHER_FOR_CASH":
      return action.num;
    default:
      return state;
  }
};

export const voucherAmount = (state = null, action) => {
  switch (action.type) {
    case "VOUCHER_AMOUNT":
      return action.num;
    default:
      return state;
  }
};

export const merchantEmployee = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_EMPLOYEE":
      return action.num;
    default:
      return state;
  }
};
export const merchantEmployeePass = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_Pass":
      return action.num;
    default:
      return state;
  }
};
export const merchantEmployeeId = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_EId":
      return action.num;
    default:
      return state;
  }
};

export const merchantEmployeeInfo = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_EMPLOYEE_INFO":
      return action.num;
    default:
      return state;
  }
};

export const selectedMerchantEmployeeInfo = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_MERCHANT_EMPLOYEE_INFO":
      return action.num;
    default:
      return state;
  }
};

export const definedPrivilegesMerchant = (state = [], action) => {
  switch (action.type) {
    case "DEFINED_PRIVILEGES_MERCHANT":
      return action.num;
    default:
      return state;
  }
};

export const employeeLinkedStores = (state = [], action) => {
  switch (action.type) {
    case "EMPLOYEE_LINKED_STORES":
      return action.num;
    default:
      return state;
  }
};

export const employeeHeadQuarterPrivileges = (state = [], action) => {
  switch (action.type) {
    case "HEADQUARTER_PRIVILEGES_MERCHANT_EMPLOYEE":
      return action.num;
    default:
      return state;
  }
};

export const selectedMerchantForApprovalId = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_MERCHANT_FOR_APPROVAL_ID":
      return action.num;
    default:
      return state;
  }
};

export const selectedMerchantForApproval = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_MERCHANT_FOR_APPROVAL":
      return action.num;
    default:
      return state;
  }
};

export const selectedMerchantForApprovalBankInfo = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_MERCHANT_FOR_APPROVAL_BANK_INFO":
      return action.num;
    default:
      return state;
  }
};

export const selectedMerchantForApprovalChequeInfo = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_MERCHANT_FOR_APPROVAL_CHEQUE_INFO":
      return action.num;
    default:
      return state;
  }
};

export const selectedMerchantForApprovalEwalletInfo = (
  state = null,
  action
) => {
  switch (action.type) {
    case "SELECTED_MERCHANT_FOR_APPROVAL_EWALLET_INFO":
      return action.num;
    default:
      return state;
  }
};

export const startPaymentDate = (state = null, action) => {
  switch (action.type) {
    case "START_PAYMENT_DATE":
      return action.num;
    default:
      return state;
  }
};

export const govAgencyReqData = (state = null, action) => {
  switch (action.type) {
    case "GOV_AGENCY_REQ_DATA":
      return action.num;
    default:
      return state;
  }
};
