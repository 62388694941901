import React from "react";
const Card = ({ item }) => {
  return (
    <div
      className="p-4 md:w-1/5 sm:w-1/2 w-full cursor-pointer "
      onClick={() => {
        // setActive(item.key);
      }}
    >
      <div
        className={`border shadow-md px-4 py-4 rounded-lg transform transition duration-500 hover:scale-100 bg-[#D9EFED]`}
      >
        <h2 className={`title-font font-semibold text-[0.9rem] `}>{item?.label}</h2>
        <p className="leading-relaxed text-center text-primary text-3xl font-bold">{item?.count}</p>
      </div>
    </div>
  );
};

export default Card;
