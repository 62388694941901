import React, { useEffect, useState } from "react";
import Sidebar from "../../Dashboard/Sidebar";
import Main from "./main";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div
        className={`${
          windowSize?.width <= 768
            ? "h-screen flex flex-col"
            : "flex flex-row p-5 gap-7 h-screen bg-[#ebfff3] w-full"
        }`}
      >
        {windowSize?.width <= 768 ? (
          <>
            <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
              <MenuUnfoldOutlined
                className="text-2xl cursor-pointer"
                onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
              />
              <h1 className="text-xl font-bold"> MHSSS Payment Detail</h1>
            </header>
            {openSideBar && (
              <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
                <Sidebar />
              </div>
            )}
          </>
        ) : (
          <aside
            className="w-1/5 rounded-lg"
            style={{
              backgroundColor: "white",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
            }}
          >
            <Sidebar />
          </aside>
        )}
        <div
          className={`${
            windowSize?.width <= 768
              ? "flex-grow overflow-y-auto p-4"
              : "flex flex-col w-4/5 gap-5 overflow-y-scroll rounded-lg bg-white"
          }`}
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <Main />
        </div>
      </div>
    </>
  );
};

export default Index;
