import { useSelector } from "react-redux";
import logo1 from "../../imgs/logo12.png";
import { useState } from "react";
import { useEffect } from "react";
const StatsPart = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const [pending, setPending] = useState(false);
  const [approved, setApproved] = useState(false);
  useEffect(() => {
    if (dataUser.data.user.status === "pending") {
      setPending(true);
    } else if (dataUser.data.user.status === "approved") {
      setApproved(true);
    }
  }, []);
  // hover:bg-[#cbcbcb]
  return (
    <div className="flex flex-col items-center mt-4 sm:mt-8 mb-4 sm:mb-8">
      <div
        className={`flex items-center ${
          pending || approved ? "bg-[#cbcbcb]" : "bg-[#E8E8E8]"
        } p-3 pl-5 pr-5 sm:w-[42rem] w-full mb-2`}
      >
        <div>
          <img className="w-12 sm:w-16" src={logo1} alt="" />
        </div>
        <p className="ml-4">Your account is in pending state</p>
      </div>
      <div
        className={`flex items-center ${
          approved ? "bg-[#cbcbcb]" : "bg-[#E8E8E8]"
        } p-3 pl-5 pr-5 sm:w-[42rem] w-full mb-2`}
      >
        <div>
          <img className="w-12 sm:w-16" src={logo1} alt="" />
        </div>
        <p className="ml-4">Your Account has been approved</p>
      </div>
      <div className="flex items-center bg-[#E8E8E8] p-3 pl-5 pr-5 sm:w-[42rem] w-full mb-2">
        <div>
          <img className="w-12 sm:w-16" src={logo1} alt="" />
        </div>
        <p className="ml-4">Your Application has been submitted</p>
      </div>
      <div className="flex items-center bg-[#E8E8E8] p-3 pl-5 pr-5 sm:w-[42rem] w-full mb-2">
        <div>
          <img className="w-12 sm:w-16" src={logo1} alt="" />
        </div>
        <p className="ml-4">Your Application form has been rejected</p>
      </div>
      <div
        className={`flex items-center ${
          dataUser.data.user.roles[0] === "pensioner" ? "" : "hidden"
        } bg-[#E8E8E8] p-3 pl-5 pr-5 sm:w-[42rem] w-full mb-2`}
      >
        <div>
          <img className="w-12 sm:w-16" src={logo1} alt="" />
        </div>
        <p className="ml-4">
          Beneficial has been added to your account against SCP -program
        </p>
      </div>
      <div
        className={`flex items-center ${
          dataUser.data.user.roles[0] === "pensioner" ? "" : "hidden"
        } bg-[#E8E8E8] p-3 pl-5 pr-5 sm:w-[42rem] w-full mb-2`}
      >
        <div>
          <img className="w-12 sm:w-16" src={logo1} alt="" />
        </div>
        <p className="ml-4">
          Your pension amount has been credited to your account
        </p>
      </div>
    </div>
  );
};

export default StatsPart;
