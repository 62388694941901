import React, { useEffect, useRef, useState } from "react";
import logo1 from "../../../../imgs/logo12.png";

import sample from "../../../../imgs/voucher.jpg";

import img from "../../../../imgs/impLogo.png";
import vp from "../../../../imgs/vp.svg";
import bv from "../../../../imgs/bv.svg";
import uimage from "../../../../imgs/uimage.svg";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import AmountModal from "../Pay/AmountModal";

import ErrorModal from "../../Modals/errorModal";
import SampleImageModal from "../../Modals/sampleImageModal";
import { compareImages } from "../../../../utlis/CompareImages";
import VerificationModal from "../../../../utlis/Scanner";
import { message } from "antd";
import sample1 from "../../../../imgs/voucher.jpg";
import { PlusCircleFilled, DeleteFilled } from "@ant-design/icons";

let file;
const PayMain = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);

  const [verify, setVerify] = useState(false);
  const router = useNavigate();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const selectedPensionerPrograms = useSelector(
    (state) => state.selectedPensionerPrograms
  );
  const token = useSelector((state) => state.token);
  const [programs, setPrograms] = useState([]);
  console.log(programs);
  const [stores, setStores] = useState([]);
  // const activeVouchers = useSelector((state) => state.activeVouchers);
  const selectedProgramPensionBook = useSelector(
    (state) => state.selectedProgramPensionBook
  );
  const voucherAmount = useSelector((state) => state.voucherAmount);
  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAmountModal, setShowAmountModal] = useState(false);
  // const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  // const [showStoreModal, setShowStoreModal] = useState(false);
  // const [selectedStore, setSelectedStore] = useState();
  // const currentDate = new Date();
  const [showVouchers, setShowVouchers] = useState(false);
  const [err, setErr] = useState("");
  const amount = "";
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  // const [selectedVoucherIds, setSelectedVoucherIds] = useState([]);
  const [note, setNote] = useState("");
  const paymentDescription = "";
  const [paymentImage, setPaymentImage] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [display, setDisplay] = useState(null);
  const dispatch = useDispatch();
  // const [voucherDisplay, setVoucherDisplay] = useState(upload);
  const [sampleModal, setSampleModal] = useState(false);
  const faceApi = useSelector((state) => state.faceApi);
  const [previousImage, setPreviousImage] = useState("");
  const [noVoucher, setNoVoucher] = useState(false);
  const fileInputRef = useRef(null);
  // const merchantStores = useSelector((state) => state.merchantStores);
  const user = useSelector((state) => state.user);
  const employeeHeadQuarterPrivileges = useSelector(
    (state) => state.employeeHeadQuarterPrivileges
  );
  const selectedStore = useSelector((state) => state.selectedStore);
  const [timerRunning, setTimerRunning] = useState(false);
  const merchantSapType = useSelector((state) => state.merchantSapType);
  console.log("merchantSapType", merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);
  console.log("merchantSapTypeData", merchantSapTypeData);
  console.log("selectedStore", selectedStore);
  const sapmoney = useSelector((state) => state.sapmoney);
  console.log(timerRunning);
  // Function to start the timer
  const startTimer = () => {
    setTimerRunning(true);
  };

  // Function to handle timer reset
  // const handleTimerReset = () => {
  //   setTimerRunning(false); // Stop the timer
  //   setTimeout(() => {
  //     setTimerRunning(true); // Start the timer after a short delay
  //   }, 100); // Adjust the delay as needed
  // };
  const getMonthName = (month) => {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };

  const sapMethods = [
    {
      id: 1,
      paymentMethod: "SAP-Wallet",
      value: "sap-wallet",
    },
  ];

  const methods = [
    {
      paymentMethod: "SAP-Wallet",
      value: "sap-wallet",
    },
    {
      paymentMethod: "Bank",
      value: "bank",
    },

    {
      paymentMethod: "Cash",
      value: "cash via QR",
    },

    {
      paymentMethod: "Cash/ E-Wallet",
      value: "cash/e-wallet",
    },
  ];

  const paymentMethods = sapmoney ? sapMethods : methods;

  useEffect(() => {
    dispatch({ type: "FACE_API", num: false });
  }, []);

  //get Stores
  useEffect(() => {
    const api =
      user === "merchant-owner" || user === "merchant-admin"
        ? "v1/merch-store"
        : "v1/merch-employee-store/my";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        if (user === "merchant-owner" || user === "merchant-admin") {
          setStores(data?.data?.rows);
        } else {
          const store = data?.data?.map((item) => item.merchstore);
          setStores(store);
        }
      })
      .catch(() => {});
    // setStores(merchantStores)
  }, []);

  useEffect(() => {
    const updatedPrograms = selectedPensionerPrograms?.map((i) => ({
      value: i?.id,
      label:
        i?.name === "senior-citizen-Pension"
          ? "Senior Citizen Pension"
          : i?.name?.charAt(0).toUpperCase() + i?.name?.slice(1),
    }));

    setPrograms(updatedPrograms);
  }, []);

  const VoucherError = () => {
    messageApi.open({
      // type: 'success',
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="mage" width="50" height="50" />
          <h3>
            Please upload voucher image again; it's not matched with the sample
            image
          </h3>
        </div>
      ),
      duration: 6,
    });
  };

  const calculateFileHash = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const buffer = e.target.result;
        const cryptoKey = await crypto.subtle.digest("SHA-256", buffer);
        const hashArray = Array.from(new Uint8Array(cryptoKey));
        const hashHex = hashArray
          .map((byte) => byte.toString(16).padStart(2, "0"))
          .join("");
        resolve(hashHex);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const checkImage = async (e, index) => {
    const file = e.target.files[0];
    const newImageHash = await calculateFileHash(file);
    console.log("New Image Hash:", newImageHash);
    console.log("Previous Image Hash:", previousImage);

    if (newImageHash === previousImage) {
      console.log("Same Image");
      VoucherError();
      fileInputRef.current.value = null;
      return;
    }

    if (file) {
      try {
        const image2DataUrl = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
        dispatch({ type: "FACE_API", num: true });

        const similarity = await compareImages(sample, image2DataUrl);
        dispatch({ type: "FACE_API", num: false });
        if (similarity <= 20) {
          setNoVoucher(false);
          handleFileUpload(e, index);
        } else {
          setNoVoucher(true);
          setPreviousImage(newImageHash);
          VoucherError();
        }

        fileInputRef.current.value = null;

        console.log("Similarity Result Check:", similarity);
        // Handle similarity result as needed
      } catch (error) {
        console.error("Error comparing images:", error);
      }
    } else {
      console.error("Please upload both images before comparing.");
    }
  };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];

    if (file) {
      try {
        // Upload the file to the server and get the URL
        const serverImageUrl = await uploadFileToServer(file);

        // Assuming selectedVouchers is a state variable
        const updatedVouchers = [...selectedVoucherForCash];

        // Create a base64 representation of the file
        const base64Image = await convertToBase64(file);

        // Set the URL and base64 image value in the state for the specified index
        updatedVouchers[index] = {
          ...updatedVouchers[index],
          DisplayImage: base64Image,
          serverImageUrl,
        };

        setSelectedVouchers(updatedVouchers);
        // Update the state with the new image values
        dispatch({ type: "SELECTED_VOUCHER_FOR_CASH", num: updatedVouchers });
      } catch (error) {
        // Handle error if needed
      }
    }
  };

  const uploadFileToServer = (file) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=paymentRecipet`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          resolve(data.data.url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const Pay = () => {
    setVerify(false);

    setVerify(false);
    dispatch({ type: "VOUCHER_AMOUNT", num: voucherAmount });
    dispatch({ type: "SELECTED_VOUCHER_FOR_CASH", num: selectedVouchers });
    console.log("selectedVouchers", selectedVoucherForCash);

    let body = {
      vouchers: [],
      merchDetail: {},
    };

    selectedVoucherForCash?.map((voc) => {
      const voucherObject = {
        voucherImage: voc?.serverImageUrl,
        couponId: voc?.id,
      };

      body.vouchers.push(voucherObject);
    });

    const merchDetailObject = {
      pensionprogramId: selectedPensionerPrograms?.[0]?.id,
      paymentMethod: paymentMethod,
      note: note,
      pensionerUserId: selectedPensioner?.user?.id,
      pensionbookId: selectedProgramPensionBook?.id,
      merchstoreId: selectedStore?.id,
    };

    // Only include paymentId if it exists
    if (paymentId) {
      merchDetailObject.paymentId = paymentId;
    }
    if (paymentImage) {
      merchDetailObject.paymentImage = paymentImage;
    }
    if (paymentDescription) {
      merchDetailObject.paymentDescription = paymentDescription;
    }

    if (merchantSapType !== "HQ" && sapmoney === true) {
      merchDetailObject.merchstoreId = merchantSapTypeData?.id;
    }

    if (sapmoney === false && selectedStore?.type === "store") {
      merchDetailObject.merchstoreId = selectedStore?.value?.id;
    }

    if (isGovernmentAgency) {
      delete merchDetailObject.merchstoreId;
    }

    body.merchDetail = merchDetailObject;

    const api = isGovernmentAgency
      ? "v1/merch-redeem-coupon/by-govt-agency"
      : "v1/merch-redeem-coupon";

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        dispatch({ type: "VOUCHER_PAY_RESPONSE", num: data?.data });
        sapmoney
          ? router("/Merchant/SapMoney/PensionerProfile/Reciept")
          : router("/Merchant/PensionerProfile/Reciept");
      })
      .catch((err) => {
        console.log(err);
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const handlePaymentClick = (method) => {
    if (paymentMethod === method.value) {
      // If the clicked method is already selected, unselect it
      setPaymentMethod(null);
    } else {
      // Otherwise, select the clicked method
      setPaymentMethod(method.value);
    }
  };

  const items = [
    "Merchant Stamp",
    "Date",
    "Pensioner GID",
    "Employee Signature",
    "Pensioner Name",
  ];

  // const [isSliding, setIsSliding] = useState(false);
  // const [startX, setStartX] = useState(0);
  // const [offsetX, setOffsetX] = useState(0);
  // const slideButtonRef = useRef(null);

  const imageHandler = (setState, setUrl) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase643(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=paymentRecipet`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase643(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  // // Condition check function
  // const checkConditions = () => {
  //   // Replace with actual conditions
  //   return true; // Assume conditions are met
  // };

  // const handleMouseDown = (e) => {
  //   if (checkConditions()) {
  //     setIsSliding(true);
  //     setStartX(e.clientX);
  //   }
  // };

  // const handleMouseMove = (e) => {
  //   if (isSliding) {
  //     const moveOffsetX = e.clientX - startX;
  //     setOffsetX(Math.max(0, Math.min(240, moveOffsetX)));
  //   }
  // };

  // const handleMouseUp = () => {
  //   if (isSliding) {
  //     if (offsetX > 200) {
  //       alert('Payment processed!');
  //       // Add payment processing logic here
  //     }
  //     setOffsetX(0);
  //     setIsSliding(false);
  //   }
  // };

  // Condition check function
  // const checkConditions = () => {
  //   // Replace with actual conditions
  //   return true; // Assume conditions are met
  // };

  // const handleMouseDown = (e) => {
  //   if (checkConditions()) {
  //     setIsSliding(true);
  //     setStartX(e.clientX);
  //     document.addEventListener("mousemove", handleMouseMove);
  //     document.addEventListener("mouseup", handleMouseUp);
  //   }
  // };

  // const handleMouseMove = (e) => {
  //   if (isSliding) {
  //     const offsetX = e.clientX - startX;
  //     if (offsetX > 0) {
  //       slideButtonRef.current.style.transform = `translateX(${Math.min(
  //         240,
  //         offsetX
  //       )}px)`;
  //     }
  //   }
  // };

  // const handleMouseUp = (e) => {
  //   if (isSliding) {
  //     const offsetX = e.clientX - startX;
  //     if (offsetX > 200) {
  //       alert("Payment processed!");
  //       // Add payment processing logic here
  //     }
  //     slideButtonRef.current.style.transform = "translateX(0)";
  //     setIsSliding(false);
  //     document.removeEventListener("mousemove", handleMouseMove);
  //     document.removeEventListener("mouseup", handleMouseUp);
  //   }
  // };

  // const checkConditions = () => {
  //   // Replace with actual conditions
  //   return true; // Assume conditions are met
  // };

  // const handleMouseDown = (e) => {
  //   if (checkConditions()) {
  //     setIsSliding(true);
  //     setStartX(e.clientX);
  //   }
  // };

  // const handleMouseMove = (e) => {
  //   if (isSliding) {
  //     const moveOffsetX = e.clientX - startX;
  //     setOffsetX(Math.max(0, Math.min(240, moveOffsetX)));
  //   }
  // };

  // const handleMouseUp = () => {
  //   // if (isSliding) {
  //   if (offsetX > 200) {
  //     alert("Payment processed!");
  //     // Add payment processing logic here
  //     // }
  //     setOffsetX(0);
  //     setIsSliding(false);
  //   }
  // };

  return (
    <>
      <div className={`${sapmoney ? "mt-3" : "sm:mt-[-2rem] mt-3"}`}>
        {contextHolder}
        <div className="sm:mx-4 mb-12">
          <div className="sm:p-8 p-3 bg-white rounded-lg">
            <div className="w-full mb-10 relative">
              <div className="bg-[#F5F5F5] p-6 rounded-md">
                {/* <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-500"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-3 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div> */}
                <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
                  <div className="flex items-center sm:flex-row flex-col ">
                    <div className="sm:w-28 sm:h-28 w-20 h-20 bg-white border  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                      <img
                        src={selectedPensioner?.user?.person?.image}
                        alt="profile"
                        className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                      />
                    </div>
                    <div className="ml-4 space-y-1 ">
                      <h1 className="text-xl font-semibold text-primary capitalize">
                        {selectedPensioner?.user?.person?.firstName}{" "}
                        {selectedPensioner?.user?.person?.middleName &&
                          selectedPensioner?.user?.person?.middleName + " "}
                        {selectedPensioner?.user?.person?.lastName}{" "}
                        {/* <span className="font-light text-gray-600">27</span> */}
                      </h1>
                      <div className="space-y-1">
                        <p className="text-md font-medium text-gray-700">
                          GID :{" "}
                          <span className="text-gray-800 text-md font-bold">
                            {selectedPensioner?.user?.gid}
                          </span>
                        </p>
                        <p className={`text-md font-medium text-gray-600`}>
                          DOB :{" "}
                          <span className="text-gray-800 text-md font-bold">
                            {selectedPensioner?.user?.person?.dateOfBirth}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`pt-5 p-4 rounded-md mb-6
              bg-[#F6F4F4] mt-4
                `}
              >
                <div className="flex items-center">
                  <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                    <img src={vp} alt="vp" className="w-8 h-8" />
                    {/* <SlidersFilled
                    style={{ fontSize: "26px", color: "#1BA397" }}
                  /> */}
                  </div>
                  <div className="ml-2">
                    <h3
                      className={`text-lg font-semibold mb-1 
                        text-primary
                      `}
                    >
                      {selectedPensioner?.pensionPrograms?.[0]?.name ===
                      "senior-citizen-Pension"
                        ? "Senior Citizen Pension"
                        : selectedPensioner?.pensionPrograms?.[0]?.name}
                    </h3>
                    <p
                      className={`text-[0.92rem]
                       text-gray-500 font-semibold
                       ${selectedProgramPensionBook?.bookletNo ? "" : "hidden"}
                      `}
                    >
                      {`Booklet # ${selectedProgramPensionBook?.bookletNo} `} (
                      {moment(selectedProgramPensionBook?.createdAt).format(
                        "YYYY"
                      )}
                      )
                    </p>
                  </div>
                </div>
                <h2 className="ml-4 mr-4 mt-3 text-gray-600 font-semibold mb-2 text-[0.94rem]">
                  Selected Vouchers
                </h2>

                <div
                  className={`bg-white ml-2 mr-2 border flex items-center justify-between p-4 rounded-lg mb-4  
             
                `}
                >
                  <div className="w-full  rounded-lg relative grid grid-cols-2 md:grid-cols-4 gap-3">
                    {selectedVoucherForCash?.map((voc, index) => (
                      <div
                        key={index}
                        className={`flex items-center justify-between p-3 rounded-md             
                bg-[#F6F4F4]`}
                      >
                        <div className="flex items-center">
                          {/* <div className="flex items-center justify-center w-10 h-10"> */}
                          <img src={bv} alt="vc" className="w-6 h-6" />
                          {/* </div> */}
                          <div className="ml-4">
                            <h3
                              className={`text-[1rem] font-semibold mb-1 text-primary
                  `}
                            >
                              {getMonthName(voc.month)}{" "}
                              {moment(voc.createdAt).format("YYYY")}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <h1 className="ml-4 mr-4 mt-6 text-gray-600 font-semibold mb-2 text-[1rem]">
                  Total Amount to be Paid
                  <span>
                    <strong className="text-primary ml-6 text-2xl font-semibold">
                      ${voucherAmount?.toFixed(2)}
                    </strong>
                  </span>
                </h1>
                {/* <SelectVoucher/> */}
              </div>

              <div
                className={`${
                  isGovernmentAgency ? "hidden" : "bg-[#F6F4F4] p-4"
                }`}
              >
                <h1 className="font-semibold text-[1.1rem] mr-4">
                  {(merchantSapType !== "HQ" && sapmoney === true) ||
                  (selectedStore?.type === "store" && sapmoney === false)
                    ? "Selected Store"
                    : "Headquarter"}
                  {merchantSapType !== "HQ" && sapmoney === true && (
                    <span className="text-gray-500 ml-5">
                      {merchantSapTypeData?.customStoreName}
                    </span>
                  )}
                  {merchantSapType === "HQ" && sapmoney === true && (
                    <span className="text-gray-500 ml-5">
                      {merchantSapTypeData?.merchuser?.merchCompany?.name}
                    </span>
                  )}

                  {selectedStore?.type === "store" && sapmoney === false && (
                    <span className="text-gray-500 ml-5">
                      {selectedStore?.value?.customStoreName}
                    </span>
                  )}
                  {selectedStore?.type === "HQ" && sapmoney === false && (
                    <span className="text-gray-500 ml-5">
                      {selectedStore?.value?.name}
                    </span>
                  )}
                </h1>
              </div>

              <div className={`bg-[#F6F4F4]`}>
                <div className="flex sm:flex-row flex-col gap-4  w-full sm:items-center mt-2 p-4 rounded-md">
                  <div className="flex-shrink-0">
                    <h1 className="font-semibold text-[1.1rem] mr-4">
                      Payment Method
                    </h1>
                  </div>
                  <div className="sm:flex flex-grow grid grid-cols-2 rounded-lg gap-3 items-center justify-start sm:gap-2 p-2">
                    {paymentMethods &&
                      paymentMethods.map((method) => (
                        <button
                          key={method.id}
                          className={`bg-${
                            paymentMethod === method.value
                              ? "[#1BA397] text-white"
                              : "white"
                          } rounded-full px-5 py-2 font-semibold shadow-md sm:text-[0.9rem] text-xs`}
                          onClick={() => {
                            handlePaymentClick(method);
                          }}
                        >
                          {method.paymentMethod}
                        </button>
                      ))}
                  </div>
                </div>
                <div
                  className={`p-4 md:p-5 ${
                    paymentMethod === "bank" ? "" : "hidden"
                  } `}
                >
                  <div className="p-4 bg-white">
                    <div className="mb-4">
                      <label className="mb-3">
                        Bank Payment Transaction number
                      </label>
                      <input
                        type="text"
                        className="w-full p-3 bg-[#F4F5FE]
                rounded-lg mt-2"
                        placeholder="Enter transaction #"
                        onChange={(e) => {
                          setPaymentId(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Attach Receipt (for bank Transfer only)
                      </h2>
                      <label
                        //   htmlFor="special-input"
                        className={`cursor-pointer relative`}
                      >
                        <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                          <div>
                            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                          </div>
                          <p className="text-[#9CA6C2] ml-2">Receipt Image</p>
                        </div>
                        <input
                          type="file"
                          className="hidden w-[100%] cursor-poniter"
                          // id="special-input"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={imageHandler(setDisplay, setPaymentImage)}
                        />
                      </label>
                      <div
                        className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8]
                          ${display !== null ? "" : "hidden"}`}
                      >
                        <img
                          onClick={() => {
                            // setIShow(true);
                          }}
                          src={display}
                          className="w-[13rem] h-[6rem]"
                          //   src={GFront}
                          alt=""
                        />
                        <div
                          className="absolute bottom-0 right-0 bg-white cursor-pointer"
                          onClick={() => {
                            setDisplay(null);
                            setPaymentImage("");
                          }}
                        >
                          <DeleteFilled
                            className="sm:w-[3rem] w-[2rem]"
                            style={{ fontSize: "30px", color: "#1BA397" }}
                            onClick={() => {
                              setPaymentImage("");
                              setDisplay(null);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <h2 className="text-[1rem] font-semibold px-3">
                  Upload Voucher Image
                </h2>
                <div className="px-5 py-3 bg-[#F6F4F4] mt-5 rounded-md">
                  <p className="text-md mt-2">
                    Image requirements :{" "}
                    <span
                      onClick={() => {
                        setSampleModal(true);
                      }}
                      className="cursor-pointer text-primary font-semibold"
                    >
                      See Sample Image
                    </span>
                  </p>
                  {/* <p className="text-[#DE2827] text-xs font-semibold">
                                Please add the following information to the
                                paper voucher.
                              </p> */}
                  <div className="grid sm:grid-cols-3 gap-2 py-2 font-semibold sm:grid-cols-5">
                    <div className="flex flex-col gap-1 text-orange-600">
                      {items.slice(0, 3).map((item, index) => (
                        <div key={index}>
                          {index + 1}. {item}
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-1 text-orange-600">
                      {items.slice(3).map((item, index) => (
                        <div key={index}>
                          {index + 4}. {item}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex gap-6 p-4">
                    {selectedVoucherForCash.map((entry, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center w-40"
                      >
                        <label
                          htmlFor={`file-input-${index}`}
                          className={`flex items-center justify-center w-full mb-4 rounded-lg bg-white shadow-md ${
                            entry?.DisplayImage ? "p-0" : "p-5"
                          } cursor-pointer`}
                        >
                          <img
                            className={`${
                              entry?.DisplayImage
                                ? "object-cover w-full"
                                : "w-[2rem] h-[2rem]"
                            }`}
                            src={
                              entry?.DisplayImage ? entry?.DisplayImage : uimage
                            }
                            alt=""
                          />
                        </label>
                        <input
                          id={`file-input-${index}`}
                          type="file"
                          className="hidden"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={(e) => checkImage(e, index)}
                          ref={fileInputRef}
                        />
                        <span className="text-teal-600 font-semibold">
                          {getMonthName(entry?.month)}{" "}
                          {moment(entry?.createdAt).format("YYYY")}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h2 className="text-[1rem] font-semibold px-3 ">Notes</h2>
                <textarea
                  className="w-full p-4 mt-2 bg-[#F6F4F4] rounded-md"
                  placeholder="Add a note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  rows={4}
                ></textarea>
              </div>
            </div>
          </div>

          {/* <div
            className=" w-72 h-auto rounded-full cursor-pointer overflow-hidden"
            onMouseEnter={() => {
              if (note === "") {
                setErr("Please add a note");
                setShowErrorModal(true);
                return;
              }
            }}
          >
            <SlideButton
              caret={
                <span className="text-red-600 font-bold text-lg">{">"}</span>
              }
              mainText="Slide to pay $3000"
              overlayText="Slide to pay $3000"
              classList="my-class"
              caretClassList="my-caret-class"
              overlayClassList="my-overlay-class"
              onSlideDone={function () {
                if (note === "") {
                  setErr("Please add a note");
                  setShowErrorModal(true);
                  return;
                }
              }}
              reset={reset}
            />
          </div> */}

          {/* <button
            onClick={() => {
              setReset((counter) => counter + 1);
            }}
          >
            Reset
          </button> */}

          {/* <div
            className="relative w-72 h-14 bg-red-600 rounded-full flex items-center justify-center cursor-pointer overflow-hidden"
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            <div
              ref={slideButtonRef}
              className="absolute left-0 top-0 w-14 h-14 bg-white rounded-full flex items-center justify-center transition-transform duration-300"
              style={{ transform: `translateX(${offsetX}px)` }}
              onMouseDown={handleMouseDown}
            >
              <span className="text-red-600 font-bold text-lg">{">"}</span>
            </div>
            <span className="text-white font-bold text-lg pointer-events-none">
              Slide to pay $3000
            </span>
          </div> */}

          {/* Next Button */}
          <div className="w-full flex items-center justify-center">
            <div
              className={`lg:mt-[1.5rem] flex mt-4 items-center justify-end justify-center sm:w-[90%] `}
            >
              <button
                type="button"
                // onClick={() => dispatch({ type: "IINCREMENT" })}
                onClick={() => {
                  // if (selectedVoucherIds.length === 0) {
                  //   setErr("Please select a voucher");
                  //   setShowErrorModal(true);
                  //   return;
                  // }

                  const missingImage = selectedVoucherForCash?.some(
                    (voucher) => !voucher.serverImageUrl
                  );

                  if (employeeHeadQuarterPrivileges.length < 0) {
                    if (stores.length > 0) {
                      if (
                        selectedStore === undefined ||
                        selectedStore === null ||
                        selectedStore === ""
                      ) {
                        setErr("Please select a store");
                        setShowErrorModal(true);
                        return;
                      }
                    }
                  }

                  if (missingImage) {
                    setErr("Please upload a image for each selected voucher");
                    setShowErrorModal(true);
                    return;
                  }

                  if (note === "") {
                    setErr("Please add a note");
                    setShowErrorModal(true);
                    return;
                  }

                  if (!paymentMethod) {
                    setErr("Please select a payment method");
                    setShowErrorModal(true);
                    return;
                  }
                  if (paymentMethod === "bank" && paymentId === "") {
                    setErr("Please enter a transaction number");
                    setShowErrorModal(true);
                    return;
                  }

                  if (paymentMethod === "bank" && !paymentImage) {
                    setErr("Please upload a receipt image");
                    setShowErrorModal(true);
                    return;
                  }

                  // All conditions are met, update the state
                  setVerify(true);
                  startTimer();
                }}
                className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8  `}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>

        {/* Verfify Modal */}
        <div
          tabindex="-1"
          className={` ${
            verify ? "" : "hidden"
          } fixed top-0 left-0 w-full h-full flex items-center justify-center bg-slate-500/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
              {/* <Timer isRunning={timerRunning} onReset={handleTimerReset} /> */}
              <button
                type="button"
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  "
                onClick={() => {
                  setVerify(false);
                  setTimerRunning(false);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <div className="flex items-center justify-center w-22 h-22 rounded-full mx-auto">
                  <img src={logo1} alt="" className="w-20 h-20" />
                </div>

                <h3 className="text-center mt-3 mb-3">
                  {`Please confirm that you are paying `}
                  <strong>${voucherAmount?.toFixed(2)}</strong>
                  {` amount to the pensioner`}
                </h3>

                <div className="flex gap-2 pb-2">
                  <button
                    onClick={() => {
                      // router("/Merchant/PensionerProfile/Reciept");
                      Pay();
                    }}
                    className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      setVerify(false);
                    }}
                    className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-[#DE2827]  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {showVouchers && (
          <VouchersModal
            setShowVouchers={setShowVouchers}
            setShowAmountModal={setShowAmountModal}
            setAmount={setAmount}
            selectedVouchers={selectedVouchers}
            setSelectedVouchers={setSelectedVouchers}
            selectedVoucherIds={selectedVoucherIds}
            setSelectedVoucherIds={setSelectedVoucherIds}
          />
        )} */}
        {showAmountModal && (
          <AmountModal
            setShowAmountModal={setShowAmountModal}
            setShowVouchers={setShowVouchers}
            amount={amount}
          />
        )}

        {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
        {sampleModal && (
          <SampleImageModal
            setShow={setSampleModal}
            img={sample1}
            title={"Sample Voucher Image"}
          />
        )}
        {faceApi === true && <VerificationModal />}
      </div>
    </>
  );
};

export default PayMain;
