import React from 'react'
import back5 from "../../imgs/back5.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Notifications = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const router = useNavigate();
  const user=dataUser?.data?.user?.roles[0]
  const notifications = [
    { type: 'confirmation', message: 'Transaction successful!' },
    { type: 'confirmation', message: 'Your order has been placed.' },
    { type: 'informational', message: 'New message received.' },
    { type: 'informational', message: 'Reminder: Meeting at 3 PM.' },
    { type: 'alert', message: 'Critical update required!' },
    { type: 'alert', message: 'Low battery: 10% remaining.' },
    { type: 'error', message: 'Error: Unable to connect to server.' },
    { type: 'error', message: 'Invalid username or password.' },
    { type: 'completion', message: 'Task completed!' },
    { type: 'completion', message: 'Download finished.' },
    { type: 'progress', message: 'Upload 50% complete.' },
    { type: 'progress', message: 'Installation in progress...' },
    { type: 'reminder', message: "Don't forget to submit your report." },
    { type: 'reminder', message: 'Upcoming event: Birthday tomorrow.' },
    { type: 'achievement', message: 'Congratulations! You\'ve reached a new level.' },
    { type: 'achievement', message: 'Milestone achieved!' }
  ];
  return (
    <div>
        <div className='bg-[#fff] border-b border-black-500 w-full h-[65px] sm:h[40px] text-center flex items-center justify-center'>
        <div
          className={`flex gap-x-4 justify-start items-center mt-4 w-[95%]`}
            
        >
          <div
            onClick={() => {
              user==="employee" ? router("/EDashboard") : user=== "admin" ? router("/AdminDashboard") : user==="superadmin"? router("/superAdminDashboard"): user==="gro-employee"? router("/GRO"): user==="gro-admin"? router("/GroAdminDash") : router("/Dashboard");
            }}
            className="top-[2.8] cursor-pointer"
          >
            <img className="w-[1.7rem] w-[1.2rem] flex items-center justify-center mt-1" src={back5} alt="" />
          </div>
            <h1 className='text-2xl font-bold '>
                Notifications 
            </h1>
        </div>
        </div>
        {notifications && notifications.map((item) =>
        <div className="w-full h-[30%] bg-[#fff] m-0 border-b border-black-200 pb-4 pr-4 pt-4 pl-10"><h2 className="font-bold mb-1 capitalize">{item.type}:</h2><p className="ml-1 mr-1">{item.message}</p></div>
      )}
    </div>
  )
}

export default Notifications