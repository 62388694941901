import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import Notifications from "../components/Nav/notifications"

const notifications = () => {
  return (
    <div>
      <Nav />
     <Notifications/>
     <Whatsapp/>
    </div>
  )
}

export default notifications