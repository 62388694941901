import { useLocation, useNavigate } from "react-router-dom";
import pro from "../../../../imgs/pro.png";
import voucher1 from "../../../../imgs/sample.jpg";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { image } from "@tensorflow/tfjs";

let api;
const Employee = () => {
  const router = useNavigate();
  const location = useLocation();
  const data1 = location?.state?.selectedTransactionsVouchers;
  const type = data1?.source === "merchant-transaction" ? "voc" : "other";
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState();

  useEffect(() => {
    if (user === "pensioner") {
      if (data1?.source === "sapwallet-to-sapwallet") {
        if (
          dataUser?.data?.user?.id === data1?.fromUserId &&
          (data1?.FromMerchHeadquarter !== null ||
            data1?.FromMerchStore !== null)
        ) {
          return setData({
            name:
              data1?.FromMerchHeadquarter !== null
                ? data1?.FromMerchCompany?.name
                : data1?.FromMerchStore !== null
                ? data1?.FromMerchStore?.customStoreName
                : `${data1?.FromUser?.person?.firstName || ""} 
       ${
         data1?.FromUser?.person?.middleName
           ? data1?.FromUser?.person?.middleName + " "
           : ""
       } 
       ${data1?.FromUser?.person?.lastName || ""}`,
            image:
              data1?.FromMerchHeadquarter !== null
                ? data1?.FromMerchCompany?.logo
                : data1?.FromMerchStore !== null
                ? data1?.FromMerchStore?.image
                : data1?.sapwalletinfo?.user?.person?.image,
            email:
              data1?.FromMerchHeadquarter !== null
                ? data1?.FromMerchHeadquarter?.primaryEmail
                : data1?.FromMerchStore !== null
                ? data1?.FromMerchStore?.primaryEmail
                : data1?.FromUser?.email,
            // email: data1?.sapwalletinfo?.email,
            phone:
              data1?.FromMerchHeadquarter !== null
                ? data1?.FromMerchHeadquarter?.primaryPhone
                : data1?.FromMerchStore !== null
                ? data1?.FromMerchStore?.primaryPhone
                : data1?.FromUser?.countryCode !== null
                ? data1?.FromUser?.countryCode + data1?.FromUser?.phoneNo
                : "",
            // phone: data1?.sapwalletinfo?.phone,
            amountPaid: data1?.amount,
            payStatus: data1?.type === "credit" ? "Received" : "Paid",
            createdAt: data1?.createdAt,
            merchheadquarter: data1?.FromMerchHeadquarter,
            merchCompany: data1?.FromMerchCompany,
            merchstore: data1?.FromMerchStore,
            gid: data1?.sapwalletinfo?.user?.gid,
            type: data1?.type,
          });
        }
      }
      if (dataUser?.data?.user?.id === data1.fromUserId) {
        return setData({
          name:
            data1?.sapwalletinfo?.type === "merch-headquarter"
              ? data1?.sapwalletinfo?.merchCompany?.name
              : data1?.sapwalletinfo?.type === "merch-store"
              ? data1?.sapwalletinfo?.merchstore?.customStoreName
              : data1?.sapwalletinfo?.user?.person?.firstName +
                " " +
                data1?.sapwalletinfo?.user?.person?.lastName,
          image:
            data1?.sapwalletinfo?.type === "pensioner"
              ? data1?.sapwalletinfo?.user?.person?.image
              : data1?.sapwalletinfo?.type === "merch-headquarter"
              ? data1?.sapwalletinfo?.merchheadquarter?.image
              : data1?.sapwalletinfo?.merchstore?.image,
          email: data1?.sapwalletinfo?.email,
          phone: data1?.sapwalletinfo?.phone,
          amountPaid: data1?.amount,
          payStatus: data1?.type === "credit" ? "Received" : "Paid",
          createdAt: data1?.createdAt,
          merchheadquarter: data1?.FromMerchHeadquarter,
          merchCompany: data1?.FromMerchCompany,
          merchstore: data1?.FromMerchStore,
          gid: data1?.sapwalletinfo?.user?.gid,
          type: data1?.type,
        });
      }
      if (
        data1?.source === "quick-payee" ||
        data1?.source === "other-quick-payment"
      ) {
        DetailApi();
      } else {
        return setData({
          name:
            data1?.FromMerchHeadquarter !== null
              ? data1?.FromMerchCompany?.name
              : data1?.FromMerchStore !== null
              ? data1?.FromMerchStore?.customStoreName
              : `${data1?.FromUser?.person?.firstName || ""} 
       ${
         data1?.FromUser?.person?.middleName
           ? data1?.FromUser?.person?.middleName + " "
           : ""
       } 
       ${data1?.FromUser?.person?.lastName || ""}`,
          image:
            data1?.FromMerchHeadquarter !== null
              ? data1?.FromMerchCompany?.logo
              : data1?.FromMerchStore !== null
              ? data1?.FromMerchStore?.image
              : data1?.FromUser?.person?.image,
          email:
            data1?.FromMerchHeadquarter !== null
              ? data1?.FromMerchHeadquarter?.primaryEmail
              : data1?.FromMerchStore !== null
              ? data1?.FromMerchStore?.primaryEmail
              : data1?.FromUser?.email,
          phone:
            data1?.FromMerchHeadquarter !== null
              ? data1?.FromMerchHeadquarter?.primaryPhone
              : data1?.FromMerchStore !== null
              ? data1?.FromMerchStore?.primaryPhone
              : data1?.FromUser?.countryCode !== null
              ? data1?.FromUser?.countryCode + data1?.FromUser?.phoneNo
              : "",
          amountPaid: data1?.amount,
          payStatus: data1?.type === "credit" ? "Received" : "Paid",
          createdAt: data1?.createdAt,
          merchheadquarter: data1?.FromMerchHeadquarter,
          merchCompany: data1?.FromMerchCompany,
          merchstore: data1?.FromMerchStore,
          gid: data1?.sapwalletinfo?.user?.gid,
          type: data1?.type,
        });
      }
    }

    if (data1?.source === "sapwallet-to-sapwallet" && data1?.type === "debit") {
      return setData({
        name:
          data1?.sapwalletinfo?.type === "pensioner"
            ? data1?.sapwalletinfo?.user?.person?.firstName +
              " " +
              data1?.sapwalletinfo?.user?.person?.lastName
            : data1?.sapwalletinfo?.type === "merch-headquarter"
            ? data1?.sapwalletinfo?.merchCompany?.name
            : data1?.sapwalletinfo?.merchstore?.customStoreName,
        image:
          data1?.sapwalletinfo?.type === "pensioner"
            ? data1?.sapwalletinfo?.user?.person?.image
            : data1?.sapwalletinfo?.type === "merch-headquarter"
            ? data1?.sapwalletinfo?.merchheadquarter?.image
            : data1?.sapwalletinfo?.merchstore?.image,
        email: data1?.sapwalletinfo?.email,
        phone: data1?.sapwalletinfo?.phone,
        amountPaid: data1?.amount,
        payStatus: data1?.type === "credit" ? "Received" : "Paid",
        createdAt: data1?.createdAt,
        merchheadquarter: data1?.FromMerchHeadquarter,
        merchCompany: data1?.FromMerchCompany,
        merchstore: data1?.FromMerchStore,
        gid: data1?.sapwalletinfo?.user?.gid,
        type: data1?.type,
      });
    }

    if (data1?.source === "sapwallet-to-sapwallet" && data1?.type === "credit") {
      const checkFromUser =
        data1?.fromMerchHeadquarter === null &&
        data1?.fromMerchStore === null &&
        data1?.sapwalletinfo?.user === null;
      return setData({
        name:
          checkFromUser === true
            ? data1?.FromUser?.person?.firstName +
              " " +
              data1?.FromUser?.person?.lastName
            : data1?.FromMerchHeadquarter !== null
            ? data1?.FromMerchCompany?.name || "HQ"
            : data1?.FromMerchStore !== null
            ? data1?.FromMerchStore?.customStoreName || "Store"
            : data1?.sapwalletinfo?.user?.person?.firstName +
              " " +
              data1?.sapwalletinfo?.user?.person?.lastName,
        // image:
        //   data1?.sapwalletinfo?.type === "pensioner"
        //     ? data1?.sapwalletinfo?.user?.person?.image
        //     : data1?.sapwalletinfo?.type === "merch-headquarter"
        //     ? data1?.sapwalletinfo?.merchheadquarter?.image
        //     : data1?.sapwalletinfo?.merchstore?.image,
        image:
          data1?.FromMerchHeadquarter !== null
            ? data1?.FromMerchCompany?.logo
            : data1?.FromMerchStore !== null
            ? data1?.FromMerchStore?.image
            : data1?.FromUser?.person?.image,
        email:
          data1?.FromMerchHeadquarter !== null
            ? data1?.FromMerchHeadquarter?.primaryEmail
            : data1?.FromMerchStore !== null
            ? data1?.FromMerchStore?.primaryEmail
            : data1?.FromUser?.email,
        phone:
          data1?.FromMerchHeadquarter !== null
            ? data1?.FromMerchHeadquarter?.primaryPhone
            : data1?.FromMerchStore !== null
            ? data1?.FromMerchStore?.primaryPhone
            : data1?.FromUser?.countryCode !== null
            ? data1?.FromUser?.countryCode + data1?.FromUser?.phoneNo
            : "",
        amountPaid: data1?.amount,
        payStatus: data1?.type === "credit" ? "Received" : "Paid",
        createdAt: data1?.createdAt,
        merchheadquarter: data1?.FromMerchHeadquarter,
        merchCompany: data1?.FromMerchCompany,
        merchstore: data1?.FromMerchStore,
        gid: data1?.sapwalletinfo?.user?.gid,
        type: data1?.type,
      });
    }

    DetailApi();
  }, []);

  const DetailApi = () => {
    let id;

    if (data1?.source === "quick-payee") {
      id = data1?.pensionerquickpayeeId;
      api =
        user === "pensioner"
          ? `v1/pensioner-quick-payee/${id}/for-pensioner`
          : `v1/pensioner-quick-payee/${id}/for-merch`;
    }

    if (data1?.source === "other-quick-payment") {
      id = data1?.otherquickpaymentId;
      api =
        user === "pensioner"
          ? `v1/other-quick-payments/${id}/for-pensioner`
          : `v1/other-quick-payments/${id}/for-merch`;
    }
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
      })
      .then((data) => {
        const val = data?.data;

        const setCommonData = (additionalData = {}) => {
          setData({
            name: val?.firstName
              ? `${val?.firstName} ${val?.lastName}`
              : val?.name,
            email: val?.email,
            phone: val?.phone,
            amountPaid: val?.amountPaid,
            payStatus: val?.payStatus,
            paymentId: val?.paymentId,
            createdAt: val?.createdAt,
            relation: val?.relation,
            purpose: val?.purpose,
            note: val?.reason || val?.note,
            merchCompany: val?.merchCompany,
            merchstore: val?.merchstore,
            merchheadquarter: val?.merchheadquarter,
            gid: val?.gid,
            ...additionalData,
          });
        };

        if (data1?.source === "quick-payee") {
          const additionalData = {
            type: data1?.type,

            Paid: val?.pensionerUser,
            requstedBy:
              val?.requestedByPensioner === true ? "Pensioner" : "Child",
          };
          setCommonData(additionalData);
        } else if (data1?.source === "other-quick-payment") {
          const additionalData = {
            type: user === "pensioner" ? "credit" : "debit",
            payStatus: user === "pensioner" ? "Received" : "Paid",
            Receiver: val?.receiverDetails,
            OtherOption: {
              name:
                data1?.FromMerchHeadquarter !== null
                  ? data1?.FromMerchCompany?.name
                  : data1?.FromMerchStore !== null
                  ? data1?.FromMerchStore?.customStoreName
                  : `${data1?.FromUser?.person?.firstName || ""} 
         ${
           data1?.FromUser?.person?.middleName
             ? data1?.FromUser?.person?.middleName + " "
             : ""
         } 
         ${data1?.FromUser?.person?.lastName || ""}`,
              image:
                data1?.FromMerchHeadquarter !== null
                  ? data1?.FromMerchCompany?.logo
                  : data1?.FromMerchStore !== null
                  ? data1?.FromMerchStore?.image
                  : data1?.FromUser?.person?.image,
              email:
                data1?.FromMerchHeadquarter !== null
                  ? data1?.FromMerchHeadquarter?.primaryEmail
                  : data1?.FromMerchStore !== null
                  ? data1?.FromMerchStore?.primaryEmail
                  : data1?.FromUser?.email,
              phone:
                data1?.FromMerchHeadquarter !== null
                  ? data1?.FromMerchHeadquarter?.primaryPhone
                  : data1?.FromMerchStore !== null
                  ? data1?.FromMerchStore?.primaryPhone
                  : data1?.FromUser?.countryCode !== null
                  ? data1?.FromUser?.countryCode + data1?.FromUser?.phoneNo
                  : "",
            },
          };
          setCommonData(additionalData);
        }
      })
      .catch(() => {});
  };

  console.log("data", data);

  const voucher = [
    {
      "Voucher Code": "13233",
    },
    {
      "Voucher Type": "23243",
    },
  ];

  const Div = ({ title, value }) => (
    <>
      <div
        className={`sm:px-4 py-[4px] px-2 ${
          value === null ||
          value === "" ||
          value === undefined ||
          value === "null"
            ? "hidden"
            : `sm:grid sm:grid-cols-4  sm:px-0 grid-cols-2 grid text-[0.95rem] ${
                type === "voc" ? "sm:gap-8" : "sm:gap-2"
              }`
        }`}
      >
        <dt
          className={`leading-6 font-semibold ${
            title === "Amount"
              ? "text-white font-semibold text-[1rem] sm:mt-0 mt-1"
              : "text-gray-800 "
          }`}
        >
          {title}
        </dt>
        <dd
          className={` sm:col-span-2 sm:mt-0  ${
            title === "Email" ? "break-words" : "capitalize"
          }  ${
            title === "Status" && value === "Paid"
              ? "text-primary font-bold"
              : "text-gray-700"
          } ${title === "Status" ? "font-bold" : ""} ${
            title === "Amount"
              ? "text-white font-semibold text-[1rem]  mt-1"
              : ""
          }`}
        >
          {value}
        </dd>
      </div>
    </>
  );

  const Div2 = ({ title, value }) => (
    <div className="grid grid-cols-2 gap-4 text-[0.95rem] py-[4px] px-2">
      <div
        className={`leading-6 font-semibold ${
          title === "Amount"
            ? "text-white font-semibold text-[1rem]"
            : "text-gray-800 "
        }`}
      >
        {title}
      </div>
      <div
        className={` mt-1  sm:mt-0  capitalize   ${
          title === "Total Amount" ? "text-primary font-bold" : "text-gray-700"
        } ${title === "Status" ? "font-bold" : ""} ${
          title === "Amount" ? "text-white font-semibold text-[1rem]" : ""
        }`}
      >
        {value}
      </div>
    </div>
  );

  const Voucher = () => (
    <div className="bg-[#F6F4F4] p-1 flex-1 rounded-sm">
      <img src={voucher1} alt="voucher" className="w-full h-32 p-1 " />
      <div className="p-2">
        <div className="flex justify-between">
          <h1 className="text-sm font-semibold">Voucher Code</h1>
          <p className="text-sm">123456</p>
        </div>
        <div className="flex justify-between">
          <h1 className="text-sm font-semibold">Voucher Type</h1>
          <p className="text-sm">123456</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-2">
      <div className="sm:px-3 mt-6 sm:mt-5">
        <div
          className={`${
            data?.type === "debit"
              ? "bg-[#EF4D5C]  p-1 mb-4"
              : "bg-primary  p-1 mb-4"
          }`}
        >
          <Div
            title={"Amount"}
            value={`$${data?.amountPaid ? data?.amountPaid : ""}`}
          />
        </div>

        {/* sap to sap Wallet */}
        <div
          className={`${
            user !== "pensioner" && data1?.source === "sapwallet-to-sapwallet"
              ? ""
              : "hidden"
          }`}
        >
          <h1 className="mt-5  text-gray-800 font-semibold text-[0.95rem] sm:mx-0 mx-2">
            {data1?.type === "credit" ? "Sender Details" : "Receiver Details"}
          </h1>
          <div className="bg-[#F6F4F4] p-4 mt-2 mb-4 border">
            <div className="flex items-center">
              <img
                className="w-16 h-16 rounded-full mr-3"
                src={data?.image ? data?.image : pro}
                alt="profile"
              />
              <div className="text-sm">
                <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                  {data?.name ? data?.name : ""}
                </h4>

                <p className="mt-1">
                  {data?.phone !== null ? data?.phone : data?.gid}
                </p>

                <p className="mt-1">{data?.email ? data?.email : ""}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Reciver One */}
        <div
          className={`${
            user !== "pensioner" && data1?.source === "other-quick-payment"
              ? ""
              : "hidden"
          }`}
        >
          <div className="bg-[#F6F4F4] p-4 mt-4 mb-4 border">
            <div className="flex items-center">
              <img
                className="w-16 h-16 rounded-full mr-3"
                src={
                  data?.Receiver?.person?.image
                    ? data?.Receiver?.person?.image
                    : pro
                }
                alt="profile"
              />
              <div className="text-sm">
                <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                  {data?.Receiver?.person
                    ? data?.Receiver?.person?.firstName +
                      " " +
                      data?.Receiver?.person?.lastName
                    : ""}
                </h4>

                <p className="mt-1">
                  {data?.Receiver?.user?.countryCode !== null
                    ? data?.Receiver?.user?.countryCode +
                      data?.Receiver?.user?.phoneNo
                    : ""}
                </p>
                <p className="mt-1">
                  {data?.Receiver?.user?.email !== null
                    ? data?.Receiver?.user?.email
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Paid One Pensioner */}
        <div
          className={`${
            user !== "pensioner" && data1?.source === "quick-payee"
              ? ""
              : "hidden"
          }`}
        >
          <h1 className="mt-3  text-gray-800 font-semibold text-[0.95rem] sm:mx-0 mx-2">
            {data?.type === "credit" ? "Sender Details" : "Receiver Details"}
          </h1>
          <div className="bg-[#F6F4F4] p-4 mt-2 mb-4 border">
            <div className="flex items-center">
              <img
                className="w-16 h-16 rounded-full mr-3"
                src={data?.Paid?.image ? data?.Paid?.image : pro}
                alt="profile"
              />
              <div className="text-sm">
                <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                  {data?.Paid
                    ? data?.Paid?.firstName + " " + data?.Paid?.lastName
                    : ""}
                </h4>

                <p className="mt-1">
                  {data?.Paid?.countryCode !== null
                    ? data?.Paid?.countryCode + data?.Paid?.phoneNo
                    : ""}
                </p>
                <p className="mt-1">
                  {data?.Paid?.email !== null ? data?.Paid?.email : ""}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Large Div */}
        <div className={`${type === "voc" ? "flex" : "block"} `}>
          <div className="mt-8 flex-1">
            <div
              className={`${
                (user !== "pensioner" &&
                  data1?.source === "other-quick-payment") ||
                (user !== "pensioner" && data1?.source === "quick-payee") ||
                data1?.source === "sapwallet-to-sapwallet"
                  ? "hidden"
                  : ""
              }`}
            >
              <Div title={"Name"} value={`${data?.name ? data?.name : ""}`} />
              <Div
                title={"Email"}
                value={`${data && data?.email ? data?.email : ""}`}
              />
              <Div
                title={"Phone"}
                value={`${data && data?.phone ? data?.phone : ""}`}
              />
              {data?.requestedByChild === false &&
                data?.requestedByPensioner === false && (
                  <Div title={"Total Amount"} value={`$${data?.amountPaid}`} />
                )}

              {data?.payStatus === "merchant-paid" && (
                <Div title={"Transaction Id"} value={data?.paymentId} />
              )}

              <Div
                title={"Date & Time"}
                value={moment(data1?.createdAt).format("MM/DD/yyyy hh:mm A")}
              />
              <Div title={"Relation"} value={data?.relation} />
              {/* <Div title={"Reason"} value={data?.reason} /> */}
              <Div title={"Purpose"} value={data?.purpose} />
              <Div title={"Note"} value={data?.note} />
              <Div
                title={"Transaction Type"}
                value={
                  data1?.source === "other-quick-payment" ||
                  data1?.source === "quick-payee"
                    ? "Third Party"
                    : data1?.source === "sapwallet-to-sapwallet"
                    ? "Pay to SAP Members"
                    : "Other"
                }
              />

              <div
                className={`sm:px-4 py-[4px] px-2 sm:grid sm:grid-cols-4  sm:px-0 grid-cols-2 grid text-[0.95rem] sm:gap-2`}
              >
                <dt
                  className={`leading-6 font-semibold text-gray-800
                  `}
                >
                  Status
                </dt>
                <dd
                  className={` mt-1  sm:col-span-2 sm:mt-0  capitalize ${
                    data?.payStatus === "merchant_paid" &&
                    data?.type === "debit"
                      ? "text-[#EF4D5C] font-bold"
                      : data?.payStatus === "merchant_paid" &&
                        data?.type === "credit"
                      ? "text-primary font-bold"
                      : data?.payStatus === "Received"
                      ? "text-primary font-bold"
                      : data?.payStatus === "Paid"
                      ? "text-[#EF4D5C] font-bold"
                      : "text-gray-700"
                  }`}
                >
                  {data?.payStatus === "merchant_paid" && data?.type === "debit"
                    ? "Paid"
                    : data?.payStatus === "merchant_paid" &&
                      data?.type === "credit"
                    ? "Received"
                    : data?.payStatus === "transfered"
                    ? "Pending"
                    : data?.payStatus}
                </dd>
              </div>
            </div>

            {/* Transaction method */}
            <div
              className={`${
                (user !== "pensioner" &&
                  data1?.source === "other-quick-payment") ||
                (user !== "pensioner" && data1?.source === "quick-payee") ||
                data1?.source === "sapwallet-to-sapwallet"
                  ? ""
                  : "hidden"
              }`}
            >
              <div
                className={`sm:px-4 py-[4px] px-2 sm:grid sm:grid-cols-4  sm:px-0 grid-cols-2 grid text-[0.95rem] sm:gap-2`}
              >
                <dt
                  className={`leading-6 font-semibold text-gray-800
                  `}
                >
                  Status
                </dt>
                <dd
                  className={` mt-1  sm:col-span-2 sm:mt-0  capitalize ${
                    data?.payStatus === "merchant_paid" &&
                    data?.type === "debit"
                      ? "text-[#EF4D5C] font-bold"
                      : data?.payStatus === "merchant_paid" &&
                        data?.type === "credit"
                      ? "text-primary font-bold"
                      : data?.payStatus === "Received"
                      ? "text-primary font-bold"
                      : data?.payStatus === "Paid"
                      ? "text-[#EF4D5C] font-bold"
                      : "text-gray-700"
                  }`}
                >
                  {data?.payStatus === "merchant_paid" && data?.type === "debit"
                    ? "Paid"
                    : data?.payStatus === "merchant_paid" &&
                      data?.type === "credit"
                    ? "Received"
                    : data?.payStatus === "transfered"
                    ? "Pending"
                    : data?.payStatus}
                </dd>
              </div>

              <Div
                title={"Date & Time"}
                value={moment(data?.createdAt).format("MM/DD/yyyy hh:mm A")}
              />
              <Div
                title={"Transaction Id "}
                value={`${data?.paymentId ? data?.paymentId : ""}`}
              />
              <Div
                title={"Transaction Type"}
                value={
                  data1?.source === "other-quick-payment" ||
                  data1?.source === "quick-payee"
                    ? "Third Party"
                    : data1?.source === "sapwallet-to-sapwallet"
                    ? "Pay to SAP Members"
                    : "Other"
                }
              />
              <Div
                title={"Requested By"}
                value={
                  data1?.source === "quick-payee" &&
                  data?.requstedBy === "Pensioner"
                    ? data?.Paid?.firstName + " " + data?.Paid?.lastName
                    : data1?.source === "quick-payee" &&
                      data?.requstedBy === "Child"
                    ? data?.name
                    : ""
                }
              />
            </div>

            {/*             
            <div
              className={`${
                user !== "pensioner" && data1.source === "other-quick-payment"
                  ? "hidden"
                  : ""
              }`}
            > */}

            <div className={`${user !== "pensioner" ? "hidden" : ""}`}>
              <h2 className="mt-3 sm:px-4 px-2 text-gray-800 font-semibold text-[0.95rem] mb-1">
                {data?.type === "debit" ? "Receiver Details" : "Sender Details"}
              </h2>
              <div className="bg-[#F6F4F4] p-4 mt-2 sm:ml-4 border sm:w-[40%] w-[90%]">
                <div className="flex items-center">
                  <img
                    className="w-16 h-16 rounded-full mr-3"
                    src={
                      // data?.merchstore !== null
                      //   ? data?.merchstore?.image
                      //   : data?.merchCompany?.logo
                      data?.type === "credit" &&
                      data1?.source === "other-quick-payment"
                        ? data?.OtherOption?.image
                        : data?.image
                    }
                    alt="profile"
                  />
                  <div className="text-sm">
                    <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                      {/* {data?.merchstore !== null
                        ? data?.merchstore?.customStoreName
                        : data?.merchCompany?.name} */}
                      {data?.type === "credit" &&
                      data1?.source === "other-quick-payment"
                        ? data?.OtherOption?.name
                        : data?.name}
                    </h4>

                    <p className="mt-1">
                      {/* {data?.merchstore !== null
                        ? data?.merchstore?.primaryPhone
                        : data?.merchheadquarter?.primaryPhone} */}
                      {data?.type === "credit" &&
                      data1?.source === "other-quick-payment"
                        ? data?.OtherOption?.phone
                        : data?.phone}
                    </p>
                    <p className="mt-1">
                      {/* {data?.merchstore !== null
                        ? data?.merchstore?.primaryEmail
                        : data?.merchheadquarter?.primaryEmail} */}
                      {data?.type === "credit" &&
                      data1?.source === "other-quick-payment"
                        ? data?.OtherOption?.email
                        : data?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                user !== "pensioner" &&
                (data1.source === "other-quick-payment" ||
                  data1.source === "quick-payee")
                  ? ""
                  : "hidden"
              }`}
            >
              <h2 className="mt-3 sm:px-4 text-gray-800 font-semibold text-[0.95rem] sm:mx-0 mx-2">
                Cash Paid{" "}
                {data1.source === "other-quick-payment" ||
                (data1?.source === "quick-payee" &&
                  data?.requstedBy === "Pensioner")
                  ? "By"
                  : "To"}
              </h2>
              <div className="bg-[#F6F4F4] mt-2 sm:ml-3 ml-2 border sm:w-[50%] w-full py-3">
                <Div title={"Name"} value={data?.name ? data?.name : ""} />
                <Div title={"GID"} value={data?.gid ? data?.gid : ""} />
                <Div title={"Email"} value={data?.email ? data?.email : ""} />
                <Div title={"Phone"} value={data?.phone ? data?.phone : ""} />
              </div>
            </div>
          </div>

          <div className={`flex-1 ${type === "voc" ? "" : "hidden"}`}>
            <h1>Vouchers</h1>
            <div className="grid grid-cols-2 gap-3">
              {voucher.map((item) => (
                <Voucher />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee;
