import React, { useState, useRef } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
import sample from '../imgs/sample.jpg';
const ImageSimilarityComparator = () => {
  const [image1, setImage1] = useState(sample);
  const [image2, setImage2] = useState(null);
  const [similarityScore, setSimilarityScore] = useState(null);
  const image1Ref = useRef(null);
  const image2Ref = useRef(null);
  const modelRef = useRef(null);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [isFirstCompare, setIsFirstCompare] = useState(true);
  const loadModel = async () => {
    console.log('Loading model...');
    const mobilenetModel = await mobilenet.load();
    modelRef.current = mobilenetModel;
    setIsModelLoaded(true);
    console.log('Model loaded!');
  };
  const handleImageChange = async (event, setImageFunc, imageRef) => {
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageFunc(imageUrl);
      if (imageRef.current) {
        imageRef.current.src = imageUrl;
      }
      // Load the model only on the first comparison
      if (isFirstCompare) {
        await loadModel();
        setIsFirstCompare(false);
      }
      // Compare images after the model has loaded
      compareImages();
    }
  };
  const compareImages = () => {
    if (!isModelLoaded || !image1Ref.current || !image2Ref.current) {
      console.error('Model or images not ready.');
      return;
    }
    console.log('Comparing images...');
    const img1 = image1Ref.current;
    const img2 = image2Ref.current;
    const embeddings1 = tf.tidy(() => modelRef.current.infer(img1, 'conv_preds').toFloat());
    const embeddings2 = tf.tidy(() => modelRef.current.infer(img2, 'conv_preds').toFloat());
    const diff = tf.sub(embeddings1, embeddings2).square().sum();
    const similarity = diff.sqrt().dataSync()[0];
    console.log('Similarity Score:', similarity.toFixed(2));
    setSimilarityScore(similarity);
    embeddings1.dispose();
    embeddings2.dispose();
  };

  return (
    <div className='p-20'>
      <h1>Image Similarity Comparator</h1>

      <div>
        <input
          type='file'
          accept='image/png, image/gif, image/jpeg'
          className='mt-2'
          onChange={(event) => handleImageChange(event, setImage1, image1Ref)}
        />
        {image1 && <img src={image1} alt='Image 1' className='w-[200px] mt-2 h-[200px]' ref={image1Ref} />}
      </div>

      <div>
        <input
          type='file'
          accept='image/png, image/gif, image/jpeg'
          className='mt-2'
          onChange={(event) => handleImageChange(event, setImage2, image2Ref)}
        />
        {image2 && <img src={image2} alt='Image 2' className='w-[200px] mt-2 h-[200px]' ref={image2Ref} />}
      </div>

      {similarityScore !== null && (
        <p>Similarity Score: {similarityScore.toFixed(2)}</p>
      )}
    </div>
  );
};

export default ImageSimilarityComparator;
