import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import eye from "../../../../imgs/eyee.png";
import axios from "axios";
import cross from "../../../../imgs/cross.png";
import across from "../../../../imgs/across.png";
import { useLocation } from "react-router-dom";
let data;
const LoginE = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const [confirmPass, setConfirmPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [showPopReset, setShowPopReset] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [test, setTest] = useState(true);
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };
  const togglePasswordVisibility = (setter) => {
    setter((prev) => !prev);
  };
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    if (profileData?.user) {
      data = profileData?.user;
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [profileData]);
  const resetHandler = () => {
    if (newPass !== confirmPass) {
      setShow(true);
      setErr("Please confirm password again");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/auth/change-password`,
          {
            oldPassword: oldPass,
            newPassword: newPass,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          setShowPopReset(false);
          setNewPass("");
          setOldPass("");
          setConfirmPass("");
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  return (
    <div>
      {data && (
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Login Credentials
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Employee ID:</h2>
          <input
            type="text"
            readOnly
            placeholder={"Employee ID"}
            value={`${data.uniqueId}`}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Mobile:</h2>
          <input
            type="text"
            readOnly
            placeholder={"Phone"}
            value={`${data.countryCode}${data.phoneNo}`}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Email:</h2>
          <input
            type="email"
            readOnly
            value={data.email}
            placeholder={"Email"}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
          />
          {dash === "/EDashboard/Account" && (
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold flex items-center">
                Password:{" "}
                <span
                  onClick={() => setShowPopReset(true)}
                  className="ml-auto cursor-pointer text-blue-500 hover:text-blue-700"
                >
                  Change password
                </span>
              </h2>
              <input
                type="password"
                readOnly
                value={"*******"}
                placeholder={"Password"}
                className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
              />
            </div>
          )}
        </div>
      )}
      {showPopReset && (
        <div
          className={`w-full h-full fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <h2 className="mb-[1rem] text-[1.5rem] font-bold">
              Reset Password
            </h2>
            <div
              className="absolute top-3 right-4 cursor-pointer"
              onClick={() => {
                setShowPopReset(false);
              }}
            >
              <img className="scale-[0.8]" src={cross} alt="Close" />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="oldPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Current Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showOldPass ? "text" : "password"}
                  placeholder="Current Password"
                  onChange={handleChange(setOldPass)}
                  value={oldPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowOldPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="newPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                New Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                  type={showNewPass ? 'text' : 'password'}
                  placeholder="New Password"
                  onChange={handleChange(setNewPass)}
                  value={newPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowNewPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="confirmPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Confirm Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                  type={showConfirmPass ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  onChange={handleChange(setConfirmPass)}
                  value={confirmPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowConfirmPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <button
              onClick={resetHandler}
              className="w-full bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-5"
            >
              Reset
            </button>
          </div>
        </div>
      )}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] z-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginE;
