import AddBank from "../components/PAccount/parts/AddBank.js";
// import Nav from "../../components/Nav/nav";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";
import { useNavigate, useLocation } from "react-router-dom";
import back5 from "../imgs/back5.png";
import { useSelector } from "react-redux";
const Account = () => {
  const router = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const bankInfo = location.state;
  console.log(bankInfo);
  return (
    <div>
      <Nav />
      <div className="pt-[5rem] sm:pl-[12rem] sm:pr-[12rem] pl-4 pr-4">
        <div className="relative flex items-center sm:ml-0 ml-[2rem]">
          <div
            onClick={() => {
              // user === "employee"
              //   ? router("/EDashboard/PensionerAccount")
              //   : user === "admin"
              //   ? router("/AdminDashboard/PensionerAccount")
              //   : user === "superadmin"
              //   ? router("/superAdminDashboard/PensionerAccount")
              //   : router("/Dashboard/PensionerAccount");
              router(-1)
            }}
            className="absolute top-[1] cursor-pointer sm:left-[-4%] left-[-7%]"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <div className="font-bold sm:text-[1.8rem] text-[1.2rem]">
            {bankInfo?.type === "Edit" ? "Edit Bank" : "Add Bank"}
            {/* Add Bank */}
          </div>
        </div>
      </div>
      <div>
        <AddBank />
      </div>
      <Whatsapp />
    </div>
  );
};

export default Account;
