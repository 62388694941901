import { Pagination } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
let columnHeader;
const Paget = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const totalPages = 1;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);

  if (dash === "/MHSSS/GovernmentAgency/Details/CreditedTransactions") {
    columnHeader = [
      "Requested On",
      "Payment ID",
      "Transaction Date/Time",
      "Payment Method",
      "Amount",
    ];
  } else if (dash === "/MHSSS/GovernmentAgency/Details/InternalFundTransfer") {
    columnHeader = [
      "Transaction Date/Time",
      "Payment ID",
      "Transaction Type",
      "Amount",
    ];
  } else if (dash === "/MHSSS/GovernmentAgency/Details/VoucherCashing") {
    columnHeader = [
      "Transacton Date/Time",
      "Payment Method",
      "Pensioner",
      "Phone",
      "GID",
      "Program",
      "Booklet#",
      "Voucher Cashing ID",
      "Amount",
    ];
  } else {
    columnHeader = [
      "Transaction Type",
      "Transaction Date/Time",
      "Name",
      "Phone",
      "Amount",
      "Status",
    ];
  }

  const creditedTransactionsRecords = [
    {
      RequestedOn: "2024-01-01",
      PaymentID: "PMT12345",
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentMethod: "Bank Transfer",
      Amount: "$500",
    },
    {
      RequestedOn: "2024-01-03",
      PaymentID: "PMT12346",
      TransactionDateTime: "2024-01-04 11:00 AM",
      PaymentMethod: "Credit Card",
      Amount: "$600",
    },
    {
      RequestedOn: "2024-01-05",
      PaymentID: "PMT12347",
      TransactionDateTime: "2024-01-06 12:00 PM",
      PaymentMethod: "Cheque",
      Amount: "$700",
    },
    {
      RequestedOn: "2024-01-07",
      PaymentID: "PMT12348",
      TransactionDateTime: "2024-01-08 01:00 PM",
      PaymentMethod: "Cash",
      Amount: "$800",
    },
    {
      RequestedOn: "2024-01-09",
      PaymentID: "PMT12349",
      TransactionDateTime: "2024-01-10 02:00 PM",
      PaymentMethod: "Bank Transfer",
      Amount: "$900",
    },
    {
      RequestedOn: "2024-01-11",
      PaymentID: "PMT12350",
      TransactionDateTime: "2024-01-12 03:00 PM",
      PaymentMethod: "Credit Card",
      Amount: "$1000",
    },
    {
      RequestedOn: "2024-01-11",
      PaymentID: "PMT12350",
      TransactionDateTime: "2024-01-12 03:00 PM",
      PaymentMethod: "Credit Card",
      Amount: "$1000",
    },
  ];

  const internalFundTransferRecords = [
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12345",
      TransactionType: "Internal Transfer",
      Amount: "$1500",
    },
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12346",
      TransactionType: "Internal Transfer",
      Amount: "$1600",
    },
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12347",
      TransactionType: "Internal Transfer",
      Amount: "$1700",
    },
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12348",
      TransactionType: "Internal Transfer",
      Amount: "$1800",
    },
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12349",
      TransactionType: "Internal Transfer",
      Amount: "$1900",
    },
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12350",
      TransactionType: "Internal Transfer",
      Amount: "$2000",
    },
    {
      TransactionDateTime: "2024-01-02 10:00 AM",
      PaymentID: "IFT12350",
      TransactionType: "Internal Transfer",
      Amount: "$2000",
    },
  ];

  const voucherCashingRecords = [
    {
      TransactionDateTime: "2024-03-01 10:00 AM",
      PaymentMethod: "Bank",
      Pensioner: "John Smith",
      Phone: "(555) 123-4567",
      GID: "G12345",
      Program: "Program A",
      BookletNumber: "B001",
      VoucherCashingID: "VC12345",
      Amount: "$2500",
    },
    {
      TransactionDateTime: "2024-03-02 11:00 AM",
      PaymentMethod: "Cheque",
      Pensioner: "Jane Doe",
      Phone: "(555) 234-5678",
      GID: "G12346",
      Program: "Program B",
      BookletNumber: "B002",
      VoucherCashingID: "VC12346",
      Amount: "$2600",
    },
    {
      TransactionDateTime: "2024-03-03 12:00 PM",
      PaymentMethod: "Cash",
      Pensioner: "Alice Johnson",
      Phone: "(555) 345-6789",
      GID: "G12347",
      Program: "Program C",
      BookletNumber: "B003",
      VoucherCashingID: "VC12347",
      Amount: "$2700",
    },
    {
      TransactionDateTime: "2024-03-04 01:00 PM",
      PaymentMethod: "Bank",
      Pensioner: "Bob Brown",
      Phone: "(555) 456-7890",
      GID: "G12348",
      Program: "Program D",
      BookletNumber: "B004",
      VoucherCashingID: "VC12348",
      Amount: "$2800",
    },
    {
      TransactionDateTime: "2024-03-05 02:00 PM",
      PaymentMethod: "Cheque",
      Pensioner: "Carol White",
      Phone: "(555) 567-8901",
      GID: "G12349",
      Program: "Program E",
      BookletNumber: "B005",
      VoucherCashingID: "VC12349",
      Amount: "$2900",
    },
    {
      TransactionDateTime: "2024-03-06 03:00 PM",
      PaymentMethod: "Cash",
      Pensioner: "David Black",
      Phone: "(555) 678-9012",
      GID: "G12350",
      Program: "Program F",
      BookletNumber: "B006",
      VoucherCashingID: "VC12350",
      Amount: "$3000",
    },
    {
      TransactionDateTime: "2024-03-06 03:00 PM",
      PaymentMethod: "Cash",
      Pensioner: "David Black",
      Phone: "(555) 678-9012",
      GID: "G12350",
      Program: "Program F",
      BookletNumber: "B006",
      VoucherCashingID: "VC12350",
      Amount: "$3000",
    },
  ];

  const defaultRecords = [
    {
      TransactionType: "Third Party",
      TransactionDateTime: "2024-04-01 10:00 AM",
      Name: "Emily Green",
      Phone: "(555) 789-0123",
      Amount: "$84673",
      Status: "Paid",
    },
    {
      TransactionType: "Grocery",
      TransactionDateTime: "2024-04-02 11:00 AM",
      Name: "Frank Blue",
      Phone: "(555) 890-1234",
      Amount: "$84673",
      Status: "Paid",
    },
    {
      TransactionType: "Voucher Cashing",
      TransactionDateTime: "2024-04-03 12:00 PM",
      Name: "Grace Yellow",
      Phone: "(555) 901-2345",
      Amount: "$84673",
      Status: "Paid",
    },
    {
      TransactionType: "Voucher Cashing",
      TransactionDateTime: "2024-04-04 01:00 PM",
      Name: "Henry Red",
      Phone: "(555) 012-3456",
      Amount: "$84673",
      Status: "Paid",
    },
    {
      TransactionType: "Third Party",
      TransactionDateTime: "2024-04-05 02:00 PM",
      Name: "Isla Pink",
      Phone: "(555) 123-4567",
      Amount: "$84673",
      Status: "Paid",
    },
    {
      TransactionType: "Third Party",
      TransactionDateTime: "2024-04-06 03:00 PM",
      Name: "Jack Purple",
      Phone: "(555) 234-5678",
      Amount: "$84673",
      Status: "Paid",
    },
    {
      TransactionType: "Grocery",
      TransactionDateTime: "2024-04-06 03:00 PM",
      Name: "Jack Purple",
      Phone: "(555) 234-5678",
      Amount: "$84673",
      Status: "Paid",
    },
  ];

  const pageHandler = (e) => {
    if (search) {
      setSearch("");
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  const renderTableBody = () => {
    if (dash === "/MHSSS/GovernmentAgency/Details/CreditedTransactions") {
      return creditedTransactionsRecords.length === 0 ? (
        <tbody>
          <tr>
            <td
              colSpan="5"
              className="text-center text-gray-500 text-lg bg-white"
            >
              <p className="p-10 py-20">No data found</p>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {creditedTransactionsRecords.map((data, index) => (
            <tr
              key={index}
              onClick={() => console.log(index)}
              className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
            >
              <td className="px-4 py-2 whitespace-nowrap">
                {data.RequestedOn}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">{data.PaymentID}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                <div>{data.TransactionDateTime.split(" ")[0]}</div>
                <div>
                  {data.TransactionDateTime.split(" ")[1] +
                    " " +
                    data.TransactionDateTime.split(" ")[2]}
                </div>
              </td>

              <td className="px-4 py-2 whitespace-nowrap">
                {data.PaymentMethod}
              </td>
              <td className="px-4 py-2 whitespace-nowrap font-semibold text-primary">
                {data.Amount}
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (
      dash === "/MHSSS/GovernmentAgency/Details/InternalFundTransfer"
    ) {
      return internalFundTransferRecords.length === 0 ? (
        <tbody>
          <tr>
            <td
              colSpan="4"
              className="text-center text-gray-500 text-lg bg-white"
            >
              <p className="p-10 py-20">No data found</p>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {internalFundTransferRecords.map((data, index) => (
            <tr
              key={index}
              onClick={() => console.log(index)}
              className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
            >
              <td className="px-4 py-2 whitespace-nowrap">
                <div>{data.TransactionDateTime.split(" ")[0]}</div>
                <div>
                  {data.TransactionDateTime.split(" ")[1] +
                    " " +
                    data.TransactionDateTime.split(" ")[2]}
                </div>
              </td>
              <td className="px-4 py-2 whitespace-nowrap">{data.PaymentID}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                {data.TransactionType}
              </td>
              <td className="px-4 py-2 whitespace-nowrap font-semibold text-primary">
                {data.Amount}
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (dash === "/MHSSS/GovernmentAgency/Details/VoucherCashing") {
      return voucherCashingRecords.length === 0 ? (
        <tbody>
          <tr>
            <td
              colSpan="9"
              className="text-center text-gray-500 text-lg bg-white"
            >
              <p className="p-10 py-20">No data found</p>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {voucherCashingRecords.map((data, index) => (
            <tr
              key={index}
              onClick={() => {
                router("/MHSSS/GovernmentAgency/VoucherDetails");
                // dispatch({ type: "NSAP" });
                dispatch({ type: "VCD" });
              }}
              className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
            >
              <td className="px-4 py-2 whitespace-nowrap">
                <div>{data.TransactionDateTime.split(" ")[0]}</div>
                <div>
                  {data.TransactionDateTime.split(" ")[1] +
                    " " +
                    data.TransactionDateTime.split(" ")[2]}
                </div>
              </td>

              <td className="px-4 py-2 whitespace-nowrap">
                {data.PaymentMethod}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">{data.Pensioner}</td>
              <td className="px-4 py-2 whitespace-nowrap">{data.Phone}</td>
              <td className="px-4 py-2 whitespace-nowrap">{data.GID}</td>
              <td className="px-4 py-2 whitespace-nowrap">{data.Program}</td>
              <td className="px-4 py-2 whitespace-nowrap">
                {data.BookletNumber}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {data.VoucherCashingID}
              </td>
              <td className="px-4 py-2 whitespace-nowrap font-semibold text-primary">
                {data.Amount}
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else {
      return defaultRecords.length === 0 ? (
        <tbody>
          <tr>
            <td
              colSpan="5"
              className="text-center text-gray-500 text-lg bg-white"
            >
              <p className="p-10 py-20">No data found</p>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {defaultRecords.map((data, index) => (
            <tr
              key={index}
              onClick={() => {
                router("/MHSSS/GovernmentAgency/VoucherDetails");
                dispatch({ type: "SAP" });
                if (data.TransactionType === "Voucher Cashing")
                  dispatch({ type: "VCD" });
                else dispatch({ type: "NVCD" });
              }}
              className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
            >
              <td className="px-4 py-2 whitespace-nowrap">
                {data.TransactionType}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                <div>{data.TransactionDateTime.split(" ")[0]}</div>
                <div>
                  {data.TransactionDateTime.split(" ")[1] +
                    " " +
                    data.TransactionDateTime.split(" ")[2]}
                </div>
              </td>
              <td className="px-4 py-2 whitespace-nowrap">{data.Name}</td>
              <td className="px-4 py-2 whitespace-nowrap">{data.Phone}</td>
              <td
                className={`${
                  data?.Status === "Received" ? "text-primary" : "text-red-500"
                } font-semibold px-4 py-2 whitespace-nowrap`}
              >
                {data.Amount}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                <div
                  className={`${
                    data?.Status === "Received" ? "bg-primary" : "bg-red-500"
                  } font-semibold p-1 pl-2 pr-2 rounded-lg text-white text-center whitespace-nowrap`}
                >
                  {data.Status}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    }
  };

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl relative  h-auto">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#D9EFED]">
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeader.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {renderTableBody()}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Paget;
