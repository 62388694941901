import Nav from "../components/Nav/nav";
import StatsPart from "../components/StatusP/StatusDiv";
import Whatsapp from "../Whatsapp/whatsapp";

const StatusPage = () => {
  return (
    <div>
      <Nav />
      <StatsPart />
      <Whatsapp/>
    </div>
  );
};

export default StatusPage;
