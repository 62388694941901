import React from "react";
// import cardimg2 from "../../../imgs/cardimg2.png";
import { useSelector } from "react-redux";

const ProfileCard = () => {
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  return (
    <div className="w-full relative rounded-lg">
     <div
              className={`${
                selectedPensioner?.user?.status === "rejected"
                  ? "bg-[#C53F3F]"
                  : selectedPensioner?.user?.status === "in-review"
                  ? "bg-blue-600"
                  : selectedPensioner?.user?.status === "pending"
                  ? "bg-yellow-500"
                  : "bg-primary"
              }  absolute top-4 right-5 sm:top-8 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
            >
              {selectedPensioner?.user?.status}
            </div>
            <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
              <div className="flex items-center sm:flex-row flex-col">
                <div className="sm:w-36 sm:h-36 w-20 h-20 bg-indigo-100  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                  <img
                    src={selectedPensioner?.user?.person?.image}
                    alt="profile"
                    className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                  />
                </div>
                <div className="ml-4">
                  <h1 className="text-2xl sm:text-3xl font-semibold text-gray-800 capitalize">
                    {selectedPensioner?.user?.person?.firstName}{" "}
                    {selectedPensioner?.user?.person?.middleName &&
                      selectedPensioner?.user?.person?.middleName + " "}
                    {selectedPensioner?.user?.person?.lastName}{" "}
                    {/* <span className="font-light text-gray-600">27</span> */}
                  </h1>
                  <div className="mt-4 space-y-2">
                    <p className="text-md font-medium text-gray-700">
                      GID:{" "}
                      <span className="text-md text-gray-500">
                        {selectedPensioner?.user?.gid}
                      </span>
                    </p>
                    <p
                      className={`text-md font-medium text-gray-700 `}
                    >
                      DOB:{" "}
                      <span className="text-gray-500 text-md">
                        {selectedPensioner?.user?.person?.dateOfBirth}
                      </span>
                    </p>
                    <p
                      className={`text-md font-medium text-gray-700 `}
                    >
                      Gender:{" "}
                      <span className="text-gray-500 text-md">
                        {selectedPensioner?.user?.person?.gender}
                      </span>
                    </p>
                  </div>
                 
                </div>
              </div>
            </div>
    </div>
  );
};

export default ProfileCard;
