import EcardView from "../components/EmployeeAccount/EcardView";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const EmployeeViewPage = () => {
  return (
    <div>
      <Nav />
      <EcardView />
      <Whatsapp/>
    </div>
  );
};

export default EmployeeViewPage;
