import e1 from "../../../../imgs/e1.png";
import e2 from "../../../../imgs/e2.png";
import e3 from "../../../../imgs/e3.png";
import PensionerTable from "./PensionerTable";
import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
const Listingh = () => {
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const privilege = useSelector((state) => state.myPrivileges);
  const dispatch = useDispatch();
  const user=useSelector((state)=>state.user)
  const [count, setCount] = useState({
    active: 0,
    inactive: 0,
    all: 0,
  });
  useEffect(() => {
    dispatch({ type: "gidFilterSet", num: "" });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=pensioner`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setCount({
          active: data?.data?.active,
          inactive: data?.data?.inactive,
          all: data?.data?.total,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div
        className="sm:p-10 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="flex justify-between items-center mt-4">
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() =>
                  user === "employee"
                    ? router("/EDashboard")
                    : user === "admin"
                    ? router("/AdminDashboard")
                    : user === "superadmin"
                    ? router("/superAdminDashboard")
                    : ""
                }
                className="sm:w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Pensioners
              </h2>
            </div>

            {user === "employee" &&
            privilege &&
            !privilege?.includes("Create pensioner") ? null : (
              //   <Dropdown
              //   menu={{
              //     items: user === "superadmin" ? items2 : items3,
              //     onClick,
              //   }}
              // >
              //   <a onClick={(e) => e.preventDefault()}>

              <button
                onClick={() => {
                  dispatch({ type: "ISET", num: 0 });
                  dispatch({ type: "PENSIONER" });
                  router("/EDashboard/addPensioner");
                  dispatch({ type: "Reverse" });
                }}
                className={`${
                  dataUser.data.user.status === "pending"
                    ? "pointer-events-none"
                    : ""
                } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
              >
                Create Pensioner
              </button>
            )}
          </div>
          {/* sm:flex-row  flex-col sm:ml-0 flex ml-[4rem] w-full sm:justify-between mt-[3rem] mb-8 */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:ml-0 place-items-center mt-[3rem] mb-8">
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                  Total Pensioners
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{count?.all}</p>
              </div>
              <div>
                <img className="w-[2.1rem] mt-4" src={e2} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                  Active Pensioners
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{count?.active}</p>
              </div>
              <div>
                <img className="w-[2.2rem] mt-4" src={e3} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                  Inactive Pensioners
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">
                  {count?.inactive}
                </p>
              </div>
              <div>
                <img className="w-[2rem] mt-5" src={e1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <PensionerTable />
    </>
  );
};

export default Listingh;
