import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import across from "../../imgs/across.png";
import cross from "../../imgs/cross.png";
import axios from "axios";
const Vpopup = () => {
  const veri = useSelector((state) => state.veri);
  const token = useSelector((state) => state.token);
  const popData = useSelector((state) => state.popData);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const option = useSelector((state) => state.option);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [err, setErr] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [startCountdown, setStartCountdown] = useState(false);
  useEffect(() => {
    if (startCountdown && seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
    if (seconds === 0) {
      setShow(false);
      setSeconds(60);
    }
  }, [startCountdown, seconds]);

  const handleStartClick = () => {
    setStartCountdown(true);
  };
  const completeHandler = (e) => {
    setCode(e);
  };
  const checkHandler = () => {
    
    if (code === "") {
      setShow1(true);
      setErr("Please enter the verification code");
    }
    else if(option==="Merchant") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/verify-email`,
          {
            verifyCode: code,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          
          dispatch({ type: "veri" });
          dispatch({ type: "IINCREMENT" });
          setCode("")
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow1(true);
        });
    }
    else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/auth/verify-email`,
          {
            verifyCode: code,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "veri" });
          dispatch({ type: "IINCREMENT" });
          setCode("")
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow1(true);
        });
    }
  };

  const resendHandler = () => {
    setShow(true);
    handleStartClick();
    if(option==="Merchant") {
      axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/resend-email-otp`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        
      })
      .catch(() => {
        
      });
    }
    else {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-email-otp`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        
      }) 
      .catch(() => {
        
      });
    }
  };
  return (
    <div
      className={`fixed top-0 w-full h-full  bg-slate-500/50  justify-center items-center ${
        veri ? "flex" : "hidden"
      }`}
    >
      <div className="sm:pt-[2rem] sm:pl-[2.2rem] sm:pr-[2.2rem] md:w-[50%] sm:h-[24rem] lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[35%] lg:h-[23rem] w-[90%] h-[22rem] bg-white rounded-2xl flex items-center flex-col p-6 pl-5 pr-5 relative">
        <h2 className="mb-[1rem] text-[1.5rem] font-bold">
          Email Verification
        </h2>
        <div
              className="absolute top-3 right-4 cursor-pointer"
              onClick={() => {
                dispatch({ type: "veri" })
              }}
            >
              <img className="scale-[0.8]" src={cross} />
            </div>
        <p className="text-center text-[1rem] mb-[2rem]">
          Please enter the six digit verification code that was sent to &nbsp;
          <span className="font-semibold">{popData.email}</span>
        </p>
        <ReactInputVerificationCode length={6} onCompleted={completeHandler} value={code}/>
        <p
          className={`mt-4 text-center text-[0.85rem] ${show ? "hidden" : ""}`}
        >
          Did not receive the verification code?&nbsp;
          <span
            className="font-medium text-[0.8rem] text-[#F7542E] cursor-pointer"
            onClick={resendHandler}
          >
            RESEND
          </span>
        </p>
        <p
          className={`mt-4 text-center text-[0.85rem] ${show ? "" : "hidden"}`}
        >
          Code has been sent. Resend code in &nbsp;
          <span className="font-semibold text-[#F7542E] cursor-pointer">
            {seconds}s
          </span>
        </p>
        <div className="flex w-[100%] justify-center" style={{marginBottom:'12px'}}>
          <button
            onClick={() => {
              dispatch({ type: "veri" });
              dispatch({ type: "IINCREMENT" });
            }}
            className="w-[45%] bg-[#F7542E] mr-4 text-white p-3 text-medium text-[1.1rem] rounded-lg mt-4 cursor-pointer"
          >
            Skip
          </button>
          <button
            onClick={checkHandler}
            className=" bg-primary text-white w-[45%] p-3 text-medium text-[1.1rem] rounded-lg mt-4 cursor-pointer"
          >
            Verify
          </button>
        </div>
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Vpopup;
