import placeholder from "../../../imgs/placeholder.png";
import p1 from "../../../imgs/p1.png";
import p2 from "../../../imgs/p2.png";
import p3 from "../../../imgs/p3.png";
import p4 from "../../../imgs/p4.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

let loginData;
let personData;
let locationData;
let infoData;
let witness1;
let witness2;
let employeeInfo;
const RegistrationP = () => {
  const profileData = useSelector((state) => state.profileData);
  const reset = useSelector((state) => state.reset);
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const location = useLocation();
  const dash = location.pathname;
  const [signature, setSignature] = useState(placeholder);
  const [leftThumb, setLeftThumb] = useState(placeholder);
  const [rightThumb, setRightThumb] = useState(placeholder);
  const [rightIndex, setRightIndex] = useState(placeholder);
  const [leftIndex, setLeftIndex] = useState(placeholder);
  const [wit1Img, setWit1Img] = useState(placeholder);
  const [wit2Img, setWit2Img] = useState(placeholder);
  const [test, setTest] = useState(true);
  console.log(wit1Img)
  useEffect(() => {
    return () => {
      loginData = null;
      personData = null;
      locationData = null;
      infoData = null;
      employeeInfo = null;
    };
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [loginData, personData, locationData, infoData, witness1]);
  useEffect(() => {
    if (benDone === "Done") {
      if (profileData.beneficiaryUser) {
        loginData = profileData.beneficiaryUser;
        // employeeInfo = profileData.employeeInfo;
      }
    } else {
      if (profileData.user) {
        loginData = profileData.user;
        employeeInfo = profileData.employeeInfo;
      }
    }

    if (benDone === "Done") {
      if (profileData.beneficiaryPerson) {
        personData = profileData.beneficiaryPerson;
        if (profileData.beneficiaryPerson.beneficiaryaddresses.length >= 1) {
          locationData = profileData.beneficiaryPerson.beneficiaryaddresses;
        }
      }
    } else {
      if (profileData.person) {
        personData = profileData.person;
        if (profileData.person.addresses.length >= 1) {
          locationData = profileData.person.addresses;
        }
      }
    }

    if (profileData.pensionerInfo || profileData.beneficiaryInfo) {
      if (dash === "/Dashboard/cardView" || dash === "/EDashboard/cardView") {
        infoData = profileData.beneficiaryInfo;
      } else {
        infoData = profileData.pensionerInfo;
      }

      if (infoData) {
        if (infoData.signature !== null) {
          const body1 = [infoData.signature];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setSignature(data.data[0].url);
            })
            .catch(() => {});
        }
        if (infoData?.leftThumbs?.length > 0) {
          const body2 = [infoData.leftThumbs[0]];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body2,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setLeftThumb(data.data[0].url);
            })
            .catch(() => {});
        }
        if (infoData?.rightThumbs?.length > 0) {
          const body3 = [infoData.rightThumbs[0]];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body3,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setRightThumb(data.data[0].url);
            })
            .catch(() => {});
        }
        if (infoData?.rightIndices?.length > 0) {
          const body4 = [infoData.rightIndices[0]];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setRightIndex(data.data[0].url);
            })
            .catch(() => {});
        }
        if (infoData?.leftIndices?.length > 0) {
          const body5 = [infoData.leftIndices[0]];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body5,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setLeftIndex(data.data[0].url);
            })
            .catch(() => {});
        }
      }
    }

    if (profileData.beneficiaryUser) {
      if (profileData.witnesses) {
        if (profileData.witnesses.length !== 0) {
          witness1 = profileData.witnesses[0];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: [profileData.witnesses[0].signature],
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setWit1Img(data.data[0].url);
            })
            .catch(() => {});
        }

        if (profileData.witnesses.length > 1) {
          witness2 = profileData.witnesses[1];
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: [profileData.witnesses[1].signature],
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setWit2Img(data.data[0].url);
            })
            .catch(() => {});
        }
      }
    }
  }, [profileData, reset]);

  useEffect(() => {
    if (test) {
      setTest(false);
    } else {
      setTest(true);
    }
  }, [profileData]);
const imageStates = [
    { label: 'Signature', state: signature },
    { label: 'Left Thumb', state: leftThumb },
    { label: 'Right Thumb', state: rightThumb },
    { label: 'Right Index', state: rightIndex },
    { label: 'Left Index', state: leftIndex },
  ];
  console.log("test",employeeInfo)
  return (
    <div className="flex w-full">
      <div className="w-full bg-[#e0e5ec] rounded-xl p-2 mb-[3rem]">
        {personData && (
          <div className="mb-4 bg-white w-full sm:h-[10rem] rounded-lg flex  sm:flex-row flex-col">
            <div className="sm:w-[9.5rem] sm:h-full h-[5rem] w-[100%] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p4} alt="" />
            </div>
            <div className="pl-2 pr-6 p-2 flex sm:flex-row flex-col pt-3 justify-between w-full flex-1">
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Title</p>
                  <p className="text-[0.9rem]">{personData?.title}</p>
                </div>
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">DOB</p>
                  <p className="text-[0.9rem]">{personData?.dateOfBirth}</p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">First Name</p>
                  <p className="text-[0.9rem]">{personData?.firstName}</p>
                </div>

                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Gender</p>
                  <p className="text-[0.9rem]">{personData?.gender}</p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Middle Name</p>
                  <p className="text-[0.9rem]">
                    {personData?.middleName === null
                      ? "---"
                      : `${personData?.middleName}`}
                  </p>
                </div>
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Marital Status</p>
                  <p className="text-[0.9rem]">
                    {personData?.unionStatus === null
                      ? "---"
                      : `${personData?.unionStatus}`}
                  </p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Last Name</p>
                  <p className="text-[0.9rem]">{personData?.lastName}</p>
                </div>
                <div>
                  <p className="text-[0.9rem] font-bold">Ethnicity</p>
                  <p className="text-[0.9rem]">
                    {personData?.ethnicity === null
                      ? "---"
                      : `${personData?.ethnicity}`}
                  </p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Call Name</p>
                  <p className="text-[0.9rem]">
                    {personData?.callName === null
                      ? "---"
                      : `${personData?.callName}`}
                  </p>
                </div>
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Nationality</p>
                  <p className="text-[0.9rem]">
                    {personData.nationality === null
                      ? "---"
                      : `${personData?.nationality}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {locationData && (
          <div className="mb-4 bg-white w-full sm:h-[10rem] rounded-lg flex  sm:flex-row flex-col">
            <div className="sm:w-[9.5rem] sm:h-full h-[5rem] w-[100%] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p3} alt="" />
            </div>
            <div className="pl-2 pr-6 p-2 flex sm:flex-row flex-col pt-3 justify-between w-full flex-1">
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Lot# </p>
                  <p className="text-[0.9rem]">{locationData[0]?.lot}</p>
                </div>
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Region Name</p>
                  <p className="text-[0.9rem]">
                    {" "}
                    {locationData[0]?.regionName}
                  </p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Address</p>
                  <p className="text-[0.9rem]">{locationData[0]?.address}</p>
                </div>

                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">City/Town</p>
                  <p className="text-[0.9rem]">{locationData[0]?.city}</p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Region No</p>
                  <p className="text-[0.9rem]">
                    {locationData[0]?.regionNo === null
                      ? "---"
                      : `${locationData[0]?.regionNo}`}
                  </p>
                </div>
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Village/Ward</p>
                  <p className="text-[0.9rem]">
                    {locationData[0].ward === null
                      ? "---"
                      : `${locationData[0]?.ward}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {locationData && (
            <div className="mt-4 bg-white sm:w-full w-[100%] sm:h-[10rem] rounded-lg flex sm:flex-row flex-col">
              <div className="sm:w-[11.5rem] sm:h-full h-[5rem] w-[100%] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p3} alt="" />
              </div>
              <div className="p-2 flex pt-3 w-full">
                <div className="sm:ml-[0.8rem] w-[100%]">
                  <div className="mb-4 flex sm:flex-row flex-col-reverse justify-between ">
                    <div className=" max-w-[8rem]">
                      <p className="text-[0.9rem] font-bold">Lot</p>
                      <p className="text-[0.9rem]">{locationData[0].lot}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-[0.9rem] font-bold">Address</p>
                      <p className="text-[0.9rem]">{locationData[0].address}</p>
                    </div>
                  </div>
                  <div className="flex mb-4 sm:flex-row flex-col justify-between ">
                    <div className="flex justify-between">
                      <div className="">
                        <p className="text-[0.9rem] font-bold">Region No</p>
                        <p className="text-[0.9rem]">
                          {locationData[0].regionNo}
                        </p>
                      </div>
                      <div className="sm:ml-[4rem] ">
                        <p className="text-[0.9rem] font-bold">Region Name</p>
                        <p className="text-[0.9rem] sm:max-w-[100%] max-w-[3rem]">
                          {locationData[0].regionName}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="sm:ml-[2rem]">
                        <p className="text-[0.9rem] font-bold">City/Town</p>
                        <p className="text-[0.9rem]">{locationData[0].city}</p>
                      </div>
                      <div className="sm:ml-[4rem]">
                        <p className="text-[0.9rem] font-bold">Village/Ward</p>
                        <p className="text-[0.9rem]">{locationData[0].ward}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

        {loginData && (
          <div className="mt-4 bg-white w-full sm:h-[6rem] rounded-lg flex sm:flex-row flex-col">
            <div className="sm:w-[9.5rem] sm:h-full h-[5rem] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p2} alt="" />
            </div>
            <div className="pl-2 pr-6 p-2 flex sm:flex-row flex-col pt-3 w-full flex-1">
              {profileData?.user?.roles[0] === "pensioner" && (
                <div className="sm:ml-[2rem] flex justify-between flex-col">
                  <div className="pt-2">
                    <p className="text-[0.9rem] font-bold">GID</p>
                    <p className="text-[0.9rem]">{loginData?.gid}</p>
                  </div>
                </div>
              )}
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Email</p>
                  <p className="text-[0.9rem]">
                    {loginData?.email === null ? "---" : `${loginData?.email}`}
                  </p>
                </div>
              </div>
              <div className="sm:ml-[2rem] flex justify-between flex-col">
                <div className="pt-2">
                  <p className="text-[0.9rem] font-bold">Phone</p>
                  <p className="text-[0.9rem]">
                    {loginData?.phoneNo === null
                      ? "---"
                      : `${loginData?.countryCode}${loginData?.phoneNo}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {infoData && (
          // <div className="mt-4 bg-slate-600 w-full sm:h-[17.5rem] rounded-lg flex sm:flex-row flex-col">
          //   <div className="sm:w-[9.5rem] sm:h-full h-[5rem]  bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
          //     <img className="w-[2rem]" src={p1} alt="" />
          //   </div>
          //   <div className="p-2 flex sm:flex-row flex-col pt-3 ">
          //     <div className={`flex ${leftThumb}`}>
          //       <div className="ml-[0.8rem]">
          //         <div
          //           className={`mb-4 ${leftThumb === placeholder ? "hidden" : ""
          //             }`}
          //         >
          //           <p className="text-[0.9rem] font-bold">Thumb Left</p>
          //           <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
          //             <img
          //               className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
          //               src={leftThumb}
          //               alt=""
          //             />
          //           </div>
          //         </div>
          //         <div
          //           className={` ${leftIndex === placeholder ? "hidden" : ""
          //             }`}
          //         >
          //           <p className="text-[0.9rem] font-bold">Index Left</p>
          //           <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
          //             <img
          //               className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
          //               src={leftIndex}
          //               alt=""
          //             />
          //           </div>
          //         </div>
          //       </div>
          //       <div className="ml-[2rem]">
          //         <div
          //           className={`mb-4 ${rightThumb === placeholder ? "hidden" : ""
          //             }`}
          //         >
          //           <p className="text-[0.9rem] font-bold">Thumb Right</p>
          //           <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
          //             <img
          //               className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
          //               src={rightThumb}
          //               alt=""
          //             />
          //           </div>
          //         </div>
          //         <div
          //           className={`mb-4 mr-[2rem] ${rightIndex === placeholder ? "hidden" : ""
          //             }`}
          //         >
          //           <p className="text-[0.9rem] font-bold">Index Right</p>
          //           <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
          //             <img
          //               className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
          //               src={rightIndex}
          //               alt=""
          //             />
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="">
          //       <div
          //         className={`mb-4 mr-[2rem] ${signature === placeholder ? "hidden" : ""
          //           }`}
          //       >
          //         <p className="text-[0.9rem] font-bold">Signature</p>
          //         <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
          //           <img
          //             className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
          //             src={signature}
          //             alt=""
          //           />
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <div className="mt-4 bg-white w-full sm:h-[17.5rem] rounded-lg flex sm:flex-row flex-col">
            <div className="sm:w-[9.5rem] sm:h-full sm:mr-7 h-[5rem]  bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p1} alt="" />
            </div>
            <div className="grid p-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4   pt-3">
              {imageStates.map(
                (imageState, index) =>
                  imageState?.state !== placeholder && (
                    <div className="" key={index}>
                      <p className="text-[0.9rem] font-bold">
                        {imageState?.label}
                      </p>
                      <div className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]">
                        <img
                          className="sm:w-[9rem] sm:h-[6rem] w-[7rem] h-[4rem]"
                          src={imageState?.state}
                          alt=""
                        />
                      </div>
                      {/* <img src={imageState.state} alt={imageState.label} style={{ maxWidth: '100%', height: 'auto' }} /> */}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
        {/* ${
                profileData.beneficiaryUser.roles[0] === "beneficiary"
                  ? ""
                  : "hidden"
              } */}
        {/* {employeeInfo && (
          <div
            className={` mt-4 bg-white w-full sm:h-[5rem] rounded-lg flex sm:flex-row flex-col`}
          >
            <div className="sm:w-[9.5rem] sm:h-full h-[5rem] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p1} alt="" />
            </div>
            <div className="p-2 flex pt-3">
              <div className="ml-[0.8rem] flex">
                <div className="mb-4">
                  <p className="text-[0.9rem] font-bold">Employer</p>
                  <p className="text-[0.9rem]">{employeeInfo?.employer}</p>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* {employeeInfo && (
            <div className="mt-4 bg-white w-full sm:h-[6rem] rounded-lg flex sm:flex-row flex-col">
              <div className="sm:w-[9.5rem] sm:h-full h-[5rem] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
                <img className="w-[2rem]" src={p1} alt="" />
              </div>
              <div className="p-2 flex pt-3">
                <div className="ml-[0.8rem]">
                  <div className="mb-4">
                    <p className="text-[0.9rem] font-bold">Role</p>
                    <p className="text-[0.9rem] capitalize">
                      {employeeInfo.role === null ? "---" : `${employeeInfo.role}`}
                    </p>
                  </div>
                </div>
                <div className="ml-[3rem]">
                  <div>
                    <p className="text-[0.9rem] font-bold">Supervisor</p>
                    <p className="text-[0.9rem]">
                      {employeeInfo.supervisor === null
                        ? "---"
                        : `${employeeInfo.supervisor}`}
                    </p>
                  </div>
                </div>
                <div className="ml-[3rem]">
                  <div>
                    <p className="text-[0.9rem] font-bold">Department</p>
                    <p className="text-[0.9rem]">
                      {employeeInfo.departmentOrLocation === null
                        ? "---"
                        : `${employeeInfo.departmentOrLocation}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        {employeeInfo && (
          <div className="mt-4 bg-white w-full sm:h-[6rem] rounded-lg flex sm:flex-row flex-col">
            <div className="sm:w-[9.5rem] sm:h-full h-[5rem] bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p1} alt="" />
            </div>
            <div className="p-2 flex flex-col sm:flex-row sm:pt-3">
              <div className="ml-[0.8rem] mb-2 sm:mb-0">
                <div>
                  <p className="text-[0.9rem] font-bold">Role</p>
                  <p className="text-[0.9rem] capitalize">
                    {employeeInfo.role === null
                      ? "---"
                      : `${employeeInfo.role}`}
                  </p>
                </div>
              </div>
              <div className="ml-[0.8rem] sm:ml-[3rem] mb-2 sm:mb-0">
                <div>
                  <p className="text-[0.9rem] font-bold">Supervisor</p>
                  <p className="text-[0.9rem]">
                    {employeeInfo.supervisor === null
                      ? "---"
                      : `${employeeInfo.supervisor}`}
                  </p>
                </div>
              </div>
              <div className="ml-[0.8rem] sm:ml-[3rem]">
                <div>
                  <p className="text-[0.9rem] font-bold">Department</p>
                  <p className="text-[0.9rem]">
                    {employeeInfo.departmentOrLocation === null
                      ? "---"
                      : `${employeeInfo.departmentOrLocation}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {witness2 && benDone === "Done" && (
          <div className={`  mt-4 bg-white w-full h-[9rem] rounded-lg flex`}>
            <div className="w-[9.5rem] h-full bg-[#F7542E] rounded-bl-lg rounded-tl-lg flex justify-center items-center">
              <img className="w-[2rem]" src={p1} alt="" />
            </div>
            <div className="p-2 flex pt-3">
              <div className="ml-[0.8rem] flex">
                <div className="mb-4">
                  <p className="text-[0.9rem] font-bold">Witness 2 Name</p>
                  <p className="text-[0.9rem]">{witness2.name}</p>
                </div>
                <div className="ml-[2rem]">
                  <p className="text-[0.9rem] font-bold">ID</p>
                  <p className="text-[0.9rem]">{witness2.govtID}</p>
                </div>
                <div className="ml-[2rem]">
                  <p className="text-[0.9rem] font-bold">Signature</p>
                  <div className="w-[9rem] h-[6rem]">
                    <img className="w-[9rem] h-[6rem]" src={wit2Img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationP;
