import { useState, useEffect } from "react";
import axios from "axios";
import { DatePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import back5 from "../../../imgs/back5.png";
const { Option } = Select;
const Mid = () => {
  const router = useNavigate();
  const [bodyStyle, setBodyStyle] = useState(null);
  const [type, setType] = useState(null);
  const [engine, setEngine] = useState(null);
  const [exteriorColor, setExteriorColor] = useState(null);
  const [interiorColor, setInteriorColor] = useState(null);
  const [licensePlate, setLicensePlate] = useState("");
  const [VIN, setVIN] = useState("");
  const [mileage, setMileage] = useState("");
  const [steering, setSteering] = useState(null);
  const [location, setLocation] = useState("");
  const [cost, setCost] = useState("");
  const [assignedTo, setAssignedTo] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [registeredVehicleIdentifier, setRegisteredVehicleIdentifier] =
    useState("");
  const [expiredStatus, setExpiredStatus] = useState(null);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    axios
      .get("https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json")
      .then((response) => {
        console.log("Response", response);
        const manufacturersList = response.data.Results.map((make) => ({
          label: make.Make_Name,
          value: make.Make_Name,
        }));
        setManufacturers(manufacturersList);
        console.log("Manufecturer", manufacturersList.length);
      })
      .catch((error) => {
        console.error("Error fetching manufacturers:", error);
      });
  }, []);
  useEffect(() => {
    if (selectedManufacturer) {
      console.log("Selected manufacturer:", selectedManufacturer);
      axios
        .get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${selectedManufacturer}?format=json`
        )
        .then((response) => {
          const modelsList = response.data.Results.map((model) => ({
            label: model.Model_Name,
            value: model.Model_Name,
          }));
          setModels(modelsList);
          setSelectedModel(null);
        })
        .catch((error) => {
          console.error("Error fetching models:", error);
        });
    } else {
      setModels([]);
    }
  }, [selectedManufacturer]);
  return (
    <div className=" w-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-full flex items-center justify-between sm:w-[70%] w-[80%]">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => router("/Maintenance-Vehicles")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Add Vehicle
              </h2>
            </div>
          </div>
        </div>
      </div>
      <form action="" className="mt-10 sm:mt-[5rem] mb-[5rem]" onSubmit={""}>
        <div className="flex justify-center flex-col items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Manufacturer <span className="text-red-600">*</span>
                </label>
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Manufacturer"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(option) => {
                    console.log("Options", option);
                    setSelectedManufacturer(option);
                  }}
                  options={manufacturers}
                  value={manufacturers.find(
                    (option) => option.value === selectedManufacturer
                  )}
                />
              </div>

              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Model
                </label>
                <Select
                  placeholder="Select Model"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(option) => setSelectedModel(option)}
                  options={models}
                  value={models.find(
                    (option) => option.value === selectedModel
                  )}
                  showSearch
                  allowClear
                  disabled={!selectedManufacturer}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Manufacturing Year
                </label>
                <DatePicker
                  picker="year"
                  placeholder="Select Year"
                  className="lg:text-[1.1rem]  h-[44px] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Body Style
                </label>
                <Select
                  placeholder="Select Body Style"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setBodyStyle(value)}
                  value={bodyStyle}
                >
                  <Option value="SUV">SUV</Option>
                  <Option value="Sedan">Sedan</Option>
                  <Option value="Truck">Truck</Option>
                  <Option value="Van">Van</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Type
                </label>
                <Select
                  placeholder="Select Type"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setType(value)}
                  value={type}
                >
                  <Option value="Gas">Gas</Option>
                  <Option value="Hybrid">Hybrid</Option>
                  <Option value="Electric">Electric</Option>
                  <Option value="Diesel">Diesel</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Engine
                </label>
                {/* <input
                  type="text"
                  placeholder="Engine"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setEngine(e.target.value)}
                  value={engine}
                /> */}
                <Select
                  placeholder="Select Engine"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setEngine(value)}
                  value={engine}
                >
                  <Option value="1.0 CC">1.0 CC</Option>
                  <Option value="1.5 CC">1.5 CC</Option>
                  <Option value="1.9 CC">1.9 CC</Option>
                  <Option value="2.0 CC">2.0 CC</Option>
                  <Option value="2.2 CC">2.2 CC</Option>
                  <Option value="2.5 CC">2.5 CC</Option>
                  <Option value="3.0 CC">3.0 CC</Option>
                  <Option value="4.0 CC">4.0 CC</Option>
                  <Option value="5.0 CC">5.0 CC</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Exterior Color
                </label>
                {/* <input
                  type="text"
                  placeholder="Exterior Color"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setExteriorColor(e.target.value)}
                  value={exteriorColor}
                /> */}
                <Select
                  placeholder="Select Exterior Color"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setExteriorColor(value)}
                  value={exteriorColor}
                >
                  <Option value="Aqua">Aqua</Option>
                  <Option value="Black">Black</Option>
                  <Option value="Blue">Blue</Option>
                  <Option value="Brown">Brown</Option>
                  <Option value="Fuchsia">Fuchsia</Option>
                  <Option value="Gray">Gray</Option>
                  <Option value="Green">Green</Option>
                  <Option value="Lime">Lime</Option>
                  <Option value="Navy">Navy</Option>
                  <Option value="Orange">Orange</Option>
                  <Option value="Pink">Pink</Option>
                  <Option value="Purple">Purple</Option>
                  <Option value="Red">Red</Option>
                  <Option value="Silver">Silver</Option>
                  <Option value="Teal">Teal</Option>
                  <Option value="White">White</Option>
                  <Option value="Yellow">Yellow</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Interior Color
                </label>
                {/* <input
                  type="text"
                  placeholder="Interior Color"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setInteriorColor(e.target.value)}
                  value={interiorColor}
                /> */}
                <Select
                  placeholder="Select interior Color"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setInteriorColor(value)}
                  value={interiorColor}
                >
                  <Option value="Aqua">Aqua</Option>
                  <Option value="Black">Black</Option>
                  <Option value="Blue">Blue</Option>
                  <Option value="Brown">Brown</Option>
                  <Option value="Fuchsia">Fuchsia</Option>
                  <Option value="Gray">Gray</Option>
                  <Option value="Green">Green</Option>
                  <Option value="Lime">Lime</Option>
                  <Option value="Navy">Navy</Option>
                  <Option value="Orange">Orange</Option>
                  <Option value="Pink">Pink</Option>
                  <Option value="Purple">Purple</Option>
                  <Option value="Red">Red</Option>
                  <Option value="Silver">Silver</Option>
                  <Option value="Teal">Teal</Option>
                  <Option value="White">White</Option>
                  <Option value="Yellow">Yellow</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  License Plate
                </label>
                <input
                  type="text"
                  placeholder="License Plate"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setLicensePlate(e.target.value)}
                  value={licensePlate}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  VIN
                </label>
                <input
                  type="text"
                  placeholder="VIN"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setVIN(e.target.value)}
                  value={VIN}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Mileage
                </label>
                <input
                  type="text"
                  placeholder="Mileage"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setMileage(e.target.value)}
                  value={mileage}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Steering
                </label>
                <Select
                  placeholder="Select Steering"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setSteering(value)}
                  value={steering}
                >
                  <Option value="Left">Left</Option>
                  <Option value="Right">Right</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Location
                </label>
                <input
                  type="text"
                  placeholder="Location"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Cost
                </label>
                {/* <Select
                  placeholder="Select Cost"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setCost(value)}
                  value={cost}
                >
                  <Option value="New">New</Option>
                  <Option value="Used">Used</Option>
                  <Option value="Foreign Used">Foreign Used</Option>
                </Select> */}
                <input
                  type="text"
                  placeholder="Cost"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setCost(e.target.value)}
                  value={cost}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Assigned to
                </label>
                <Select
                  placeholder="Assigned to"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setAssignedTo(value)}
                  value={assignedTo}
                >
                  <Option value="Minister">Minister</Option>
                  <Option value="Permanent Secretary">
                    Permanent Secretary
                  </Option>
                  <Option value="Regional Director">Regional Director</Option>
                  <Option value="Employee">Employee</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Drivers
                </label>
                {/* <input
                  type="text"
                  placeholder="Drivers"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setDrivers(e.target.value)}
                  value={drivers}
                /> */}
                <Select
                  placeholder="Select Drivers"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setDrivers(value)}
                  value={drivers}
                >
                  <Option value="Anita Persaud">Anita Persaud</Option>
                  <Option value="Bibi Ali">Bibi Ali</Option>
                  <Option value="John Samson">John Samson</Option>
                  <Option value="Shawn Singh">Shawn Singh</Option>
                  <Option value="Zafar Mohamed">Zafar Mohamed</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Registered Vehicle Identifier
                </label>
                <input
                  type="text"
                  placeholder="Registered Vehicle Identifier"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) =>
                    setRegisteredVehicleIdentifier(e.target.value)
                  }
                  value={registeredVehicleIdentifier}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Expired Status
                </label>
                <Select
                  placeholder="Select Expired Status"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setExpiredStatus(value)}
                  value={expiredStatus}
                >
                  <Option value="End of Life">End of Life</Option>
                  <Option value="Accident">Accident</Option>
                  <Option value="Sold">Sold</Option>
                  <Option value="Donate">Donate</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div
            className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
          >
            <button
              type="button"
              className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Add Vehicle
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Mid;
