import React from "react";
import Financial from "./Banking/index"
const Stores = () => {
  return (
    <div className="w-full p-2">
      <div className="w-full h-[80%] gap-7 flex items-center justify-center flex-col">
      <Financial/>
      </div>
    </div>
  );
};
export default Stores;
