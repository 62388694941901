import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import across from "../../../imgs/across.png";
const Banking1 = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const location = useLocation();
  const router = useNavigate();
  const dash = location.pathname;
  const bankInfo = location.state;
  const profileData = useSelector((state) => state.profileData);
  console.log(profileData, "profileData");
  const namePerson = useSelector((state) => state.namePerson);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const [test, setTest] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [iimg, setIimg] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [swiftNo, setSwiftNo] = useState("");
  const [routingNo, setRoutingNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [isInternational, setIsInternational] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [percentage, setPercentage] = useState(100);
  const [otherbankModal, setOtherbankModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [allBranches, setAllBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchName, setBranchName] = useState("");
  const [addedBank, setAddedBank] = useState(0);

  useEffect(() => {
    if (bankInfo?.type === "Edit") {
      if (bankInfo?.bankInfo?.isInternational === true) {
        setBankAddress(bankInfo?.bankInfo?.bankAddress);
      } else {
        setBankAddress(bankInfo?.bankInfo?.countrybankbranch?.address);
      }
      setSelectedBank(bankInfo?.bankInfo?.countrybank?.id);
      setBankName(bankInfo?.bankInfo?.bankName);
      setBranchName(bankInfo?.bankInfo?.branchName);
      setAccountTitle(bankInfo?.bankInfo?.accountTitle);
      setAccountNo(bankInfo?.bankInfo?.accountNo);
      setSwiftNo(bankInfo?.bankInfo?.swiftNo);
      setRoutingNo(bankInfo?.bankInfo?.routingNo);
      setAccountType(bankInfo?.bankInfo?.accountType);
      setHomeAddress(bankInfo?.bankInfo?.homeAddress);
      setPercentage(bankInfo?.bankInfo?.percentage);
      setSelectedBranch(bankInfo?.bankInfo?.countrybankbranch?.id);
      setIsInternational(bankInfo?.bankInfo?.isInternational);
      setSwiftNo(bankInfo?.bankInfo?.swiftNo);
    }
  }, [bankInfo]);

  useEffect(() => {
    if (addedBank === 1) {
      setOtherbankModal(true);
      setBankName("");
      setBankAddress("");
      setAccountTitle("");
      setAccountNo("");
      setSwiftNo("");
      setRoutingNo("");
      setAccountType("");
      setHomeAddress("");
      setOne(false);
      setTwo(false);
      setThree(false);
      // setIsInternational(false);
      setConfirm("");
      // setVal("");
    } else if (addedBank === 2) {
      if (dash === "/EDashboard/addPensioner") {
        router("/EDashboard/BeneficiaryQuestions");
        dispatch({ type: "SET", num: 3 });
        dispatch({ type: "POP" });
        dispatch({ type: "IINCREMENT" });
        // router("/EDashboard/Pensioner_HistoryP");
      } else {
        dispatch({ type: "IINCREMENT" });
      }
    }
  }, [addedBank, dash]);

  const nextHandler = (e) => {
    e.preventDefault();
    if (
      bankName ||
      bankAddress ||
      accountTitle ||
      accountNo ||
      isInternational ||
      routingNo ||
      accountType ||
      homeAddress ||
      swiftNo ||
      branchName
    ) {
      if (!one || !two || !three) {
        setErr("Please agreed to all the statement");
        setShow(true);
      } else {
        let wow = true;
        let body = {
          // bankName: banks.find((item) => item.id === selectedBank)?.name,
          // bankAddress,
          accountTitle,
          accountNo,
          isInternational,
          routingNo,
          accountType,
          homeAddress,
          percentage,
          // branchName,
        };
        if (isInternational) {
          body.bankName = bankName;
          body.branchName = branchName;
          body.bankAddress = bankAddress;
        } else {
          body.countrybankId = selectedBank;
          body.countrybankbranchId = selectedBranch;
        }
        if (isInternational) {
          body.swiftNo = swiftNo;
          if (swiftNo !== "") {
            wow = true;
          } else {
            wow = false;
            setErr("Please enter the swift number");
            setShow(true);
          }
        }

        if (wow) {
          if (
            dash === "/EDashboard/addBank" ||
            dash === "/AdminDashboard/addBank" ||
            dash === "/superAdminDashboard/addBank"
          ) {
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/bank-info/add-by-staff?pensionerUserId=${profileData?.user?.id}`,
                { bankInfo: body },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {
                // router("/EDashboard/BeneficiaryQuestions")
                // // router("/EDashboard/Pensioner_HistoryP");
                // dispatch({ type: "SET", num: 3 });
                // dispatch({ type: "POP" });

                // dispatch({ type: "IINCREMENT" });
                // setPercentage(100 - data?.data?.percentage)
                if (data?.data?.isInternational === true) {
                  setIsInternational(false);
                } else {
                  setIsInternational(true);
                }
                setAddedBank(1);
                // console.log("bankDash", dash);
                if (dash === "/EDashboard/addBank")
                  router("/EDashboard/PensionerAccount");
                else if (dash === "/AdminDashboard/addBank")
                  router("/AdminDashboard/PensionerAccount");
                else if (dash === "/superAdminDashboard/addBank")
                  router("/superAdminDashboard/PensionerAccount");
              })
              .catch((err) => {
                setErr(err.response.data.message);
                setShow(true);
              });
          } else {
            axios
              .post(`${process.env.REACT_APP_BASE_URL}/v1/bank-info`, body, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((data) => {
                // console.log(data?.data);

                if (data?.data?.isInternational === true) {
                  setIsInternational(false);
                } else {
                  setIsInternational(true);
                }
                setAddedBank(1);
              })
              .catch((err) => {
                if (
                  err.response.data.message === "Default bank found with 100%"
                ) {
                  setErr("You have already added a bank with 100%");
                  setShow(true);
                } else {
                  setErr(err.response.data.message);
                  setShow(true);
                }
              });
          }
        }
      }
    }
  };

  const EditBank = (e) => {
    e.preventDefault();

    // e.preventDefault();
    // if (!val) {
    //   setErr("Please confirm the account number");
    //   setShow(true);
    // } else {
    if (one && two && three) {
      let wow = true;
      let body = {
        // bankName: banks.find((item) => item.id === selectedBank)?.name,
        // bankAddress,
        accountTitle,
        accountNo,
        // isInternational,
        routingNo,
        accountType,
        homeAddress,
        percentage,
        // branchName,
      };

      if (isInternational) {
        body.bankName = bankName;
      } else {
        body.countrybankId = selectedBank;
        body.countrybankbranchId = selectedBranch;
      }
      if (isInternational === true) {
        body.swiftNo = swiftNo;
        if (swiftNo !== "") {
          wow = true;
        } else {
          wow = false;
          setErr("Please enter the swift number");
          setShow(true);
        }
      }

      if (wow) {
        if (dash === "/Dashboard/addBank") {
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${bankInfo?.bankInfo?.id}`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              setOne(false);
              setTwo(false);
              setThree(false);
            })
            .catch((err) => {
              setErr(err.response.data.message);
              setShow(true);
            });
        } else {
          body.percentage = percentage;

          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/v1/bank-info/${bankInfo?.bankInfo?.id}/update-by-staff`,
              { bankInfo: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              setOne(false);
              setTwo(false);
              setThree(false);
            })
            .catch((err) => {
              setErr(err.response.data.message);
              setShow(true);
            });
        }
      }
    } else {
      setErr("Please select all the checkboxes");
      setShow(true);
    }
    // }
  };

  useEffect(() => {
    let sett;
    if (profileData?.pensionerInfo?.signature !== null) {
      sett = [profileData?.pensionerInfo?.signature];
    } else if (profileData?.pensionerInfo?.leftIndex !== null) {
      sett = [profileData?.pensionerInfo?.leftIndex];
    } else if (profileData?.pensionerInfo?.rightIndex !== null) {
      sett = [profileData?.pensionerInfo?.rightIndex];
    } else if (profileData?.pensionerInfo?.leftThumb !== null) {
      sett = [profileData?.pensionerInfo?.leftThumb];
    } else {
      sett = [profileData?.pensionerInfo?.rightThumb];
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
        {
          fileNames: sett,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setIimg(data.data[0].url);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [iimg]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
    valHandler();
  };
  const checkHandler = (e) => {
    setAccountType(e.target.value);
  };

  const conHandler = (e) => {
    setConfirm(e.target.value);
    valHandler();
  };
  const valHandler = () => {
    if (
      document.getElementById("password").value ===
      document.getElementById("validate").value
    ) {
      // document.getElementById("message").style.color = "green";
      document.getElementById("message1").style.color = "#596F96";
      document.getElementById("message2").style.color = "#596F96";
      document.getElementById("message1").innerHTML = "Bank Account No";
      document.getElementById("message2").innerHTML = "Confirm Bank Account No";
      // setVal(false);
    } else {
      // document.getElementById("message").style.color = "red";
      document.getElementById("message1").style.color = "red";
      document.getElementById("message2").style.color = "red";
      document.getElementById("message1").innerHTML = "Bank Account No**";
      document.getElementById("message2").innerHTML =
        "Confirm Bank Account No**";
      // setVal(true);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/country-banks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        const BankOptions = data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        // setAllBanks(BankOptions);
        console.log(BankOptions, "data");
        setBanks(data.data);
        // if (bankInfo?.bankInfo) {
        //   const bankId = data?.data?.find(
        //     (item) => item.name === bankInfo?.bankInfo?.bankName
        //   )?.id;
        //   setSelectedBank(bankId);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedBank === "") return;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/country-bank-branches?countrybankId=${selectedBank}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        const BranchOptions = data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        setAllBranches(BranchOptions);
        setBranches(data.data);
        console.log(data.data, "branches");
        // if (bankInfo?.bankInfo) {
        //   const branchId = data?.data?.find(
        //     (item) => item.name === bankInfo?.bankInfo?.branchName
        //   )?.id;
        //   console.log(branchId, "branchId");
        //   setSelectedBranch(branchId);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedBank]);

  const SelectBank = (e) => {
    setSelectedBank(e);
    console.log(e, "e");
    console.log("banks", banks);
    const selectedBankValue = banks.find((item) => item.id === e);

    setSwiftNo(selectedBankValue?.swiftCode);
  };

  const SelectBranch = (e) => {
    setSelectedBranch(e);
    const selectedBranchValue = branches.find((item) => item.id === e);
    setBranchName(selectedBranchValue?.name);
    setBankAddress(selectedBranchValue?.address);
  };

  const options = banks.map((item) => ({
    value: item.id,
    label: item.name,
    imageUrl: item.logo,
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>{option?.label}</span>
      </div>
    </Option>
  );

  return (
    <div>
      <div className="flex justify-center">
        <form
          onSubmit={bankInfo?.type === "Edit" ? EditBank : nextHandler}
          action=""
          className=" mt-10"
        >
          <div className="flex justify-center items-center">
            <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
              <div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Select Bank Account
                </h2>
                <div className="p-4 flex gap-4">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        // setFour(true);
                        setIsInternational(false);
                        setBankName("");
                        setBankAddress("");
                        setSelectedBank("");
                        setSelectedBranch("");
                        setBranchName("");
                      } else {
                        // setFour(false);
                        setIsInternational(false);
                      }
                    }}
                    checked={isInternational === false}
                    className={`${
                      bankInfo?.type === "Edit"
                        ? "checkk2 pointer-events-none"
                        : "checkk2"
                    }`}
                    // disabled={bankInfo?.type === "Edit"}
                  >
                    Local
                  </Checkbox>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        // setFour(true);
                        setIsInternational(true);
                        setBankName("");
                        setBankAddress("");
                        setSelectedBank("");
                        setSelectedBranch("");
                        setBranchName("");
                      } else {
                        // setFour(false);
                        setIsInternational(false);
                      }
                    }}
                    checked={isInternational}
                    className={`${
                      bankInfo?.type === "Edit"
                        ? "checkk2 pointer-events-none"
                        : "checkk2"
                    }`}
                  >
                    International
                  </Checkbox>
                </div>
                <div className={`${isInternational ? "hidden" : ""}`}>
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Select Bank
                  </h2>
                  {/* <Select
                    placeholder="Select Bank"
                    className="wow w-[100%] mb-4"
                    options={allBanks}
                    value={selectedBank}
                    optionFilterProp="children"
                    onChange={(e) => SelectBank(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  /> */}
                  <Select
                    placeholder="Select Bank"
                    // className="wow w-[100%] mb-4"
                    className="w-[100%] woww mb-4"
                    renderOption={renderOption}
                    onChange={(e) => SelectBank(e)}
                    value={selectedBank}
                  >
                    {options?.map(renderOption)}
                  </Select>
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Select Branch
                  </h2>
                  <Select
                    placeholder="Select Bank Branch"
                    className="wow w-[100%] mb-4"
                    options={allBranches}
                    optionFilterProp="children"
                    value={selectedBranch}
                    onChange={(e) => SelectBranch(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </div>

                <div className={`${isInternational ? "" : "hidden"}`}>
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Bank Name
                  </h2>
                  <input
                    type="text"
                    name=""
                    
                    placeholder="Name of Bank"
                    onChange={handleChange(setBankName)}
                    value={bankName}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Branch Name
                  </h2>
                  <input
                    type="text"
                    name=""
                    
                    placeholder="Name of Bank"
                    onChange={handleChange(setBranchName)}
                    value={branchName}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                {/* <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Name of Bank
                </h2>
                <input
                  type="text"
                  name=""
                  
                  placeholder="Name of Bank"
                  onChange={handleChange(setBankName)}
                  value={bankName}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                /> */}
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Address of Branch
                </h2>
                <textarea
                  type="text"
                  name=""
                  
                  onChange={handleChange(setBankAddress)}
                  value={bankAddress}
                  placeholder="Address of Branch"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Name(s) on Account
                </h2>
                <input
                  type="text"
                  name=""
                  
                  onChange={handleChange(setAccountTitle)}
                  value={accountTitle}
                  placeholder="Name(s) on Account"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2
                  id="message1"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Bank Account No
                </h2>
                <input
                  type="text"
                  name=""
                  id="password"
                  onChange={handleChange(setAccountNo)}
                  value={accountNo}
                  placeholder="Bank Account No"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2
                  id="message2"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Confirm Bank Account No
                </h2>
                <input
                  type="text"
                  name=""
                  id="validate"
                  value={confirm}
                  onChange={conHandler}
                  placeholder="Confirm Bank Account No"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />

                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Routing #
                </h2>
                <input
                  type="text"
                  name=""
                  
                  onChange={handleChange(setRoutingNo)}
                  value={routingNo}
                  placeholder="Routing #"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Percentage
                </h2>
                <input
                  type="number"
                  name=""
                  
                  onChange={handleChange(setPercentage)}
                  value={percentage}
                  placeholder="Percentage"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Bank Account Type
                </h2>
                <div className="mb-4 mt-2">
                  <input
                    type="radio"
                    id="saving"
                    name="fav_language"
                    value="Saving"
                    onChange={checkHandler}
                    checked={accountType === "Saving"}
                  ></input>
                  <label htmlFor="saving" className="mr-8 ml-1 text-[0.9rem]">
                    Saving
                  </label>
                  <input
                    type="radio"
                    id="chequing"
                    name="fav_language"
                    onChange={checkHandler}
                    checked={accountType === "Chequing"}
                    value="Chequing"
                  ></input>
                  <label htmlFor="chequing" className=" ml-1 text-[0.9rem]">
                    Chequing
                  </label>
                </div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Home Address
                </h2>
                <textarea
                  type="text"
                  name=""
                  
                  onChange={handleChange(setHomeAddress)}
                  value={homeAddress}
                  placeholder="Home Address"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2
                  className={`${
                    isInternational ? "" : "hidden"
                  } text-[#596F96] text-[0.88rem] font-semibold mb-2`}
                >
                  Swift #
                </h2>
                <input
                  type="text"
                  name=""
                  
                  onChange={handleChange(setSwiftNo)}
                  value={swiftNo}
                  style={{ textTransform: "uppercase" }}
                  placeholder="Swift #"
                  className={`${
                    isInternational ? "" : "hidden"
                  } lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
                {/* <div className="mb-4 bg-[#f5f5ff] p-4 pt-8">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFour(true);
                        setIsInternational(true);
                      } else {
                        setFour(false);
                        setIsInternational(false);
                      }
                    }}
                    className={"checkk2"}
                    checked={isInternational}
                  >
                    International Account
                  </Checkbox>
                </div> */}
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  SURRENDER OF VOUCHER DETAILS
                </h2>
                <p className="text-[0.8rem]  mb-4">
                  I {namePerson} of my own free will Surrender my voucher to
                  MHSSS
                </p>
                <div className="ml-4">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOne(true);
                      } else {
                        setOne(false);
                      }
                    }}
                    checked={one}
                    className={"checkk2"}
                  >
                    I agree to the statement above
                  </Checkbox>
                </div>
                {namePerson && (
                  <div>
                    <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                      SURRENDER OF BANK INFORMATION DETAILS
                    </h2>
                    <p className="text-[0.8rem] mb-4">
                      I {namePerson} of my own free will Surrender my voucher to
                      MHSSS
                    </p>
                    <div className="ml-4">
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setTwo(true);
                          } else {
                            setTwo(false);
                          }
                        }}
                        checked={two}
                        className={"checkk2"}
                      >
                        I agree to the statement above
                      </Checkbox>
                    </div>
                  </div>
                )}
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  WAIVING MY RIGHTS TO BE PAID IN PERSON IN ACCORDANCE WITH THE
                  OLD AGE PENSIONER ACT, CAP. 36:30
                </h2>
                <p className="text-[0.8rem] mb-4">
                  I {namePerson} of my own free will Surrender my voucher to
                  MHSSS
                </p>
                <div className="ml-4">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setThree(true);
                      } else {
                        setThree(false);
                      }
                    }}
                    checked={three}
                    className={"checkk2"}
                  >
                    I agree to the statement above
                  </Checkbox>
                </div>
                <div className="mt-4 mb-4">
                  <img className="w-[25rem] h-[13rem]" src={iimg}  alt=""/>
                </div>
                {}
                <input
                  type="text"
                  name=""
                  
                  value={
                    dash === "/EDashboard/addPensioner"
                      ? `${namePerson}`
                      : `${profileData?.person?.title} ${profileData?.person?.firstName} ${profileData?.person?.lastName}`
                  }
                  className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
          </div>
          <div className="lg:mt-[3rem] flex mt-8 items-center sm:justify-end justify-center w-[100%] sm:w-[85%]">
            <button
              type="submit"
              className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              {bankInfo?.type === "Edit" ? "Save" : "Add"}
            </button>
          </div>
        </form>
        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShow(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>

        <div
          className={`${
            otherbankModal ? "" : "hidden"
          } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            {/* <div
                        className="absolute top-[-11%] left-[42%]"
                        onClick={() => setShow1(false)}
                    >
                        <img src={across} alt="" />
                    </div> */}
            <p className="text-center text-[1.1rem] mt-[3rem] mb-5">
              {`New ${
                isInternational === false ? "International Bank" : "Local Bank"
              } details has been added successfully.`}
            </p>
            <button
              onClick={() => setOtherbankModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-primary text-white w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banking1;
