import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
const Vppopup = ({
  setValue,
  value,
  setShowSuccessModal,
  setShowErrModal,
  err,
  setErr,
}) => {
  console.log(err);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  // const option = useSelector((state) => state.option);
  const [code1, setCode1] = useState("");
  // const [show, setShow] = useState(false);
  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const [seconds, setSeconds] = useState(60);
  const startCountdown = false;
  useEffect(() => {
    if (startCountdown && seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
    if (seconds === 0) {
      // setShow(false);
      setSeconds(60);
    }
  }, [startCountdown, seconds]);

  // const handleStartClick = () => {
  //   setStartCountdown(true);
  // };

  const completeHandler = (e) => {
    setCode1(e);
  };
  const checkHandler = () => {
    if (code1 === "") {
      setShowErrModal(true);
      console.log("selectedVoucherForCash", selectedVoucherForCash);
      setErr("Please enter the verification code");
    } else {
      let couponIds = [];
      selectedVoucherForCash?.map((item) => {
        couponIds.push(item.id);
      });

      setValue("");
      const api =
        value === "otp"
          ? "verify-phone-otp"
          : value === "pin"
          ? "verify-pin"
          : "verify-email-otp";
      // setValue("");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/merch-verify-pensioner/${api}?pensionerUserId=${selectedPensioner?.user?.id}`,
          {
            [value !== "pin" ? "verifyCode" : "pinCode"]: code1,
            couponIds,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "ACTIVE_PENSIONER_SESSION", num: "verified" });
          setErr("Pensioner Successfully Verified ");

          setShowSuccessModal(true);
        })
        .catch((err) => {
          if (err.response.data.message === "verification code not found") {
            setErr("Please enter the correct verification code");
            setShowErrModal(true);
          } else {
            setErr(err.response.data.message);
            setShowErrModal(true);
          }
        });
    }
  };
  // const resendHandler = () => {
  //   setShow(true);
  //   handleStartClick();
  //   if (option === "Merchant") {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/resend-phone-otp`,
  //         null,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {})
  //       .catch((err) => {});
  //   } else {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-phone-otp`,
  //         null,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {})
  //       .catch((err) => {});
  //   }
  // };
  return (
    <div
      tabindex="-1"
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-slate-500/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="relative lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[30%]  p-4 w-full max-w-md max-h-full bg-white mx-3 sm:mx-0 rounded-2xl flex items-center flex-col p-6 pl-5 pr-5">
        <button
          type="button"
          className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
          onClick={() => {
            setValue("");
          }}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <h2 className="mb-[1rem] text-[1.5rem] font-bold">
          {value === "email"
            ? "Email Verification"
            : value === "pin"
            ? "PIN Verification"
            : "OTP Verification"}
        </h2>
        <p
          className={`text-center text-[1rem] mb-[2rem] ${
            value === "pin" ? "hidden" : ""
          }`}
        >
          Please enter the six digit verification code that was sent to &nbsp;
          <span className="font-semibold">
            {value === "email"
              ? `${selectedPensioner?.user?.email}`
              : `${
                  selectedPensioner?.user?.countryCode +
                  " " +
                  selectedPensioner?.user?.phoneNo
                }`}
          </span>
          {/* <span className="font-semibold">{`${popData.countryCode}${popData.phoneNo}`}</span> */}
        </p>
        <p
          className={`text-center text-[1rem] mb-[2rem] ${
            value === "pin" ? "" : "hidden"
          }`}
        >
          Please enter the four-digit PIN code you've configured &nbsp;
          {/* <span className="font-semibold">{value=== "email" ? "johndoe@gmail.com" :"+92 334 5830223"}</span> */}
          {/* <span className="font-semibold">{`${popData.countryCode}${popData.phoneNo}`}</span> */}
        </p>
        <ReactInputVerificationCode
          className="--ReactInputVerificationCode-itemWidth"
          length={value === "pin" ? 4 : 6}
          onCompleted={completeHandler}
          value={code1}
        />
        {/* <p
          className={`mt-4 text-center text-[0.85rem] ${
            value === "pin" ? "hidden" : ""
          }`}
        >
          Did not receive the verification code?&nbsp;
          <span
            className="font-medium text-[0.8rem] text-[#F7542E] cursor-pointer"
            // onClick={resendHandler}
          >
            RESEND
          </span>
        </p>
        <p
          className={`mt-4 text-center text-[0.85rem] ${show ? "" : "hidden"}`}
        >
          Code has been sent. Resend code in &nbsp;
          <span className="font-semibold text-[#F7542E] cursor-pointer">
            {seconds}s
          </span>
        </p> */}
        <button
          onClick={checkHandler}
          className="w-full bg-primary text-white p-3 text-medium  sm:text-[0.9rem]  text-[1.1rem] rounded-lg mt-4"
        >
          Verify
        </button>
      </div>
      {/* {showSuccessModal && (
        <SuccessModal
          setShow={setShowSuccessModal}
          err={err}
          setValue={setValue}
          verify={"Verify"}
        />
      )}
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />} */}
    </div>
  );
};

export default Vppopup;
