import { useDispatch, useSelector } from "react-redux";
import back5 from "../imgs/back5.png";
import Nav from "../components/Nav/nav";
import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import pdf from "../imgs/pdf.png";
import { DatePicker, Dropdown, Input, Select } from "antd";
import bs1 from "../imgs/bs1.png";
import bs2 from "../imgs/bs2.png";
import bs3 from "../imgs/bs3.png";
import hist from "../imgs/history.png";
import dow from "../imgs/downloadicon.png";
import add from "../imgs/addb.png";
import across from "../imgs/across.png";
import days from "dayjs";
import Whatsapp from "../Whatsapp/whatsapp";
import moment from "moment";
const AllApplications = () => {
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);
  const application = useSelector((state) => state.appId);
  const [oldage, setOldAge] = useState([]);
  const privilege = useSelector((state) => state.myPrivileges);
  const [actualUrl, setActualUrl] = useState(false);
  const [test, setTest] = useState(false);
  const [dateTime, setDateTime] = useState(null);
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const [items, setItems] = useState([]);
  console.log(dateTime);
  const user = useSelector((state) => state.user);
  const app = localStorage.getItem("app");
  const [show1, setShow1] = useState(false);
  const [statusReason, setStatusReason] = useState("");
  const [privateStatusReason, setPrivateStatusReason] = useState("");
  const [statuss, setStatus] = useState(null);
  const [appId, setAppId] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [interval, setInterval] = useState("monthly");
  const [to, setTo] = useState(null);
  const [from, setFrom] = useState(null);
  const [amount, setAmount] = useState("");
  const subrole = localStorage.getItem("subrole");
  const [programPayment, setProgramPayment] = useState([]);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [programId, setProgramId] = useState("");
  const [pdfurl, setPdf] = useState("");
  const utilities = ["Electricty", "Water", "Gas", "Others"];
  useEffect(() => {
    console.log("APP", application);
  }, [application]);
  useEffect(() => {
    if (dash !== "/Dashboard/allApplications") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
          dispatch({ type: "pensionerId", num: data.data.user.id });
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    if (user === "superadmin" || subrole === "director of social services") {
      if (dash !== "/Dashboard/allApplications") {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/v1/program-payments`, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then((data) => {
            console.log("Testing2", data?.data);
            setProgramPayment(data?.data);
          })

          .catch(() => {});
      }
    }
  }, []);
  useEffect(() => {
    if (user === "employee") {
      if (
        privilege &&
        privilege.includes("View application") &&
        privilege &&
        privilege.includes("Delete application") &&
        privilege &&
        privilege.includes("Sign Application")
      ) {
        setItems([
          {
            label: "View ",
            key: "1",
            icon: <img style={{ width: "1.5rem" }} src={bs1} alt=""/>,
          },
          {
            label: "Download",
            key: "3",
            icon: <img style={{ width: "1.3rem" }} src={dow}  alt=""/>,
          },
          {
            label: "Update",
            key: "2",
            icon: <img style={{ width: "1.5rem" }} src={bs2}  alt=""/>,
          },
          {
            label: "Delete ",
            key: "4",
            icon: (
              <img
                style={{ width: "1.1rem", marginLeft: "0.28rem" }}
                src={bs3}
                 alt=""
              />
            ),
          },
          {
            label: "View History",
            key: "5",
            icon: <img style={{ width: "1.5rem" }} src={hist} alt="img"/>,
          },
        ]);
      } else if (
        privilege &&
        privilege.includes("View application") &&
        privilege &&
        privilege.includes("Delete application")
      ) {
        setItems([
          {
            label: "View ",
            key: "1",
            icon: <img style={{ width: "1.5rem" }} src={bs1}  alt=""/>,
          },
          {
            label: "Download",
            key: "3",
            icon: <img style={{ width: "1.3rem" }} src={dow}  alt=""/>,
          },
          {
            label: "Delete ",
            key: "4",
            icon: (
              <img
                style={{ width: "1.1rem", marginLeft: "0.28rem" }}
                src={bs3}
                 alt=""
              />
            ),
          },
          {
            label: "View History",
            key: "5",
            icon: <img style={{ width: "1.5rem" }} src={hist} alt="img" />,
          },
        ]);
      } else if (
        privilege &&
        privilege.includes("View application") &&
        privilege &&
        privilege.includes("Sign Application")
      ) {
        setItems([
          {
            label: "View ",
            key: "1",
            icon: <img style={{ width: "1.5rem" }} src={bs1}  alt=""/>,
          },
          {
            label: "Download",
            key: "3",
            icon: <img style={{ width: "1.3rem" }} src={dow}  alt=""/>,
          },
          {
            label: "Update",
            key: "2",
            icon: <img style={{ width: "1.5rem" }} src={bs2}  alt=""/>,
          },
          {
            label: "View History",
            key: "5",
            icon: <img style={{ width: "1.5rem" }} src={hist} alt="img" />,
          },
        ]);
      } else if (privilege && privilege.includes("View application")) {
        setItems([
          {
            label: "View ",
            key: "1",
            icon: <img style={{ width: "1.5rem" }} src={bs1}  alt=""/>,
          },
          {
            label: "Download",
            key: "3",
            icon: <img style={{ width: "1.3rem" }} src={dow}  alt="img"/>,
          },
          {
            label: "View History",
            key: "5",
            icon: <img style={{ width: "1.5rem" }} src={hist} alt="img" />,
          },
        ]);
      } else {
        setItems([]);
      }
    } else if (user === "admin" || user === "superadmin") {
      setItems([
        {
          label: "View ",
          key: "1",
          icon: <img style={{ width: "1.5rem" }} src={bs1} alt="img" />,
        },
        {
          label: "Download ",
          key: "3",
          icon: <img style={{ width: "1.3rem" }} src={dow} alt="img" />,
        },
        {
          label: "Update",
          key: "2",
          icon: <img style={{ width: "1.5rem" }} src={bs2} alt="img" />,
        },
        {
          label: "View History",
          key: "5",
          icon: <img style={{ width: "1.5rem" }} src={hist} alt="img" />,
        },
      ]);
    } else {
      setItems([
        {
          label: "View ",
          key: "1",
          icon: <img style={{ width: "1.5rem" }} src={bs1}  alt="img"/>,
        },
        {
          label: "Download",
          key: "3",
          icon: <img style={{ width: "1.3rem" }} src={dow}  alt="img"/>,
        },
        {
          label: "Delete ",
          key: "4",
          icon: (
            <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3}  alt="img"/>
          ),
        },
        {
          label: "View History",
          key: "5",
          icon: <img style={{ width: "1.5rem" }} src={hist} alt="img"/>,
        },
      ]);
    }
  }, [privilege]);

  let items2 = [
    {
      label: "View ",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1} alt="img" />,
    },
  ];

  useEffect(() => {
    if (
      dash === "/EDashboard/allApplications" ||
      dash === "/AdminDashboard/allApplications" ||
      dash === "/superAdminDashboard/allApplications"
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/pensioner-recent?pensionerUserId=${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log("Testing", data);
          setProgramId(data?.data[0]?.program?.name);
          const OPApplication = data.data.filter((item) => {
            return item.program.name === "senior-citizen-Pension";
          });
          setOldAge(OPApplication);
        })
        .catch(() => {});
    } else {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/applications/my-recent`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          const wow = data.data.filter((item) => {
            return item.program.name === "senior-citizen-Pension";
          });
          setOldAge(wow);
        })
        .catch(() => {});
    }
  }, [refetch]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [oldage]);
  const onClick =
    (appId) =>
    ({ key }) => {
      if (key === "1") {
        if (pdfurl !== "") {
          createPdfUrl(pdfurl);
        } else {
          const randomLinkElement = document.getElementById("randomLink");
          window.open(randomLinkElement);
        }
      }
      if (key === "3") {
        if (pdfurl !== "") {
          DownloadPdf(pdfurl);
        } else {
          const randomLinkElement = document.getElementById("randomLink");
          window.open(randomLinkElement);
        }
      }
      if (key === "5") {
        dispatch({ type: "appId", num: appId });
        router("/Application/History");
      }
      if (key === "2") {
        setAppId(appId);
        if (
          user === "superadmin" ||
          subrole === "director of social services"
        ) {
          console.log("Testing,", programPayment);
          programPayment?.filter((item) => {
            if (item?.program === programId) {
              setInterval(item?.interval);
              setAmount(item?.amount);
            }
          });
        }

        setTo(null);
        setFrom(null);
        setShow1(true);
      }
    };

  const onClick2 = (e) => {
    setAppId(e);
  };
  useEffect(() => {
    if (oldage.length !== 0) {
      if (oldage[0]?.applications?.length !== 0) {
        const body2 = [oldage[0]?.applications[0]?.applicationpdfs[0]?.url];
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body2,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            setActualUrl(data?.data[0]?.url);
          })
          .catch(() => {});
      }
    }
  }, [oldage]);

  useEffect(() => {
    if (
      dash === "/EDashboard/allApplications" ||
      dash === "/superAdminDashboard/allApplications" ||
      dash === "/AdminDashboard/allApplications"
    ) {
    } else {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/users/my-profile`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
          dispatch({ type: "pensionerId", num: data.data.user.id });
          dispatch({ type: "data3Set", num: data });
        })
        .catch(() => {});
    }
  }, []);

  const createPdfUrl = async (pdfurl) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
        {
          fileNames: [pdfurl],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      );

      const pdfData = response.data;

      if (pdfData && pdfData.length > 0 && pdfData[0].url) {
        const pdfUrl = pdfData[0].url;
        setActualUrl(pdfUrl);
        var encodedUrl = encodeURIComponent(pdfUrl);
        var iFrameUrl =
          "https://docs.google.com/viewer?url=" + encodedUrl + "&embedded=true";
        window.open(iFrameUrl);
        setPdf("");
      } else {
        console.error("PDF URL is not available or invalid.");
      }
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const DownloadPdf = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
        {
          fileNames: [pdfurl],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setActualUrl(data?.data[0]?.url);
        window.open(data?.data[0]?.url);
        setPdf("");
      })
      .catch(() => {});
  };
  // const handleFromDateChange = (date, dateString) => {
  //   setFrom(date);
  //   if (interval === "annually") {
  //     const oneYear = new Date(date);
  //     const selectedYear = oneYear.getFullYear();
  //     const lastDayOfYear = new Date(selectedYear, 11, 31);
  //     setTo(lastDayOfYear);
  //   }

  //   if (interval === "monthly") {
  //     setFrom(dateString);
  //   }
  // };
  const handleFromDateChange = (date, dateString) => {
    setFrom(date);
    // if (interval === "annually") {
    //   const oneYear = new Date(date);
    //   const selectedYear = oneYear.getFullYear();
    //   const lastDayOfYear = new Date(selectedYear, 11, 31);
    //   setTo(lastDayOfYear);
    // }

    if (interval === "annually") {
      const oneYear = new Date(date);
      const selectedYear = oneYear.getFullYear();
      // Increment the year by 1
      const nextYear = selectedYear + 1;
      // Set to March 31 of next year
      const lastDayOfMarchNextYear = new Date(nextYear, 2, 31);
      setTo(lastDayOfMarchNextYear);
    }

    if (interval === "monthly") {
      setFrom(dateString);
    }
  };
  const handleToDateChange = (date, dateString) => {
    console.log(date);
    if (interval === "monthly") {
      setTo(dateString);
    }
  };
  const signApplication = () => {
    // Validation conditions
    if (statuss === "") {
      setErr("Please Select Status");
      setShow(true);
    } else if (statusReason === "") {
      setErr("Please Enter Public Notes");
      setShow(true);
    } else if (
      user === "superadmin" ||
      subrole === "director of social services"
    ) {
      if (from === null) {
        setErr("Please Select From Date");
        setShow(true);
      } else if (to === null) {
        setErr("Please Select To Date");
        setShow(true);
      } else {
        // Conditions met for the superadmin or director of social services
        handleApiCall();
      }
    } else {
      // Conditions met for other users
      handleApiCall();
    }
  };
  const handleApiCall = () => {
    const data = {
      status: statuss,
      statusReason: statusReason,
      privateStatusReason: privateStatusReason,
      ip: "100.333.2.1",
      location: "123.43434343, 545.4343454433",
    };
    if (privateStatusReason === "") {
      delete data.privateStatusReason;
    }

    if (user === "superadmin" || subrole === "director of social services") {
      data.from = moment(from).format("YYYY-MM-DD");
      data.to = moment(to).format("YYYY-MM-DD");
    }
    const api =
      user === "superadmin"
        ? `v1/sign-application/by-superadmin?applicationId=${appId}`
        : user === "admin"
        ? `v1/sign-application/by-admin?applicationId=${appId}`
        : `v1/sign-application?applicationId=${appId}`;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, data, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        // Handle success
        setStatus(null);
        setStatusReason("");
        setPrivateStatusReason("");
        setAmount("");
        setTo(null);
        setFrom(null);
        setShow1(false);
        setRefetch(!refetch);
      })
      .catch((err) => {
        // Handle error
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };
  return (
    <div>
      <Nav />
      <div className="md:p-10 md:pl-[7rem] md:pr-[7rem] p-8">
        <div className={`flex items-center mb-[2rem]`}>
          <div className="cursor-pointer">
            <img
              onClick={() => {
                //   if (dash === "/Dashboard/listing") {
                //     router("/Dashboard");
                //   } else {
                //     router("/EDashboard/Pensioner_HistoryP");
                //   }
                if (dash === "/EDashboard/allApplications") {
                  router("/EDashboard/profPage");
                } else if (dash === "/AdminDashboard/allApplications") {
                  router("/AdminDashboard/profPage");
                } else if (dash === "/superAdminDashboard/allApplications") {
                  router("/superAdminDashboard/profPage");
                } else {
                  router("/Dashboard");
                }
              }}
              className="sm:w-[1.7rem] w-[1.4rem]"
              src={back5}
              alt=""
            />
          </div>
          <h2 className="font-bold text-[1.4rem] sm:text-[1.8rem] ml-[1rem] ">
            Applications
          </h2>
        </div>

        {user === "employee" &&
        !privilege.includes("Add new application") ? null : (
          <div className="flex items-center sm:mt-[4rem] mt-[2rem]">
            <label className="text-[#596F96] text-[1.2rem] font-semibold ml-[20px]">
              Senior-Citizen Pensioners
            </label>
            {(user === "pensioner" &&
              app === "1" &&
              oldage[0]?.applications[0]?.finalStatus === "rejected") ||
            user === "employee" ||
            user === "admin" ||
            user === "superadmin" ? (
              <div
                // className={` cursor-pointer ml-2 ${
                //   oldage[0].finalStatus === "rejected" ? "" : "hidden"
                // }`}
                onClick={() => {
                  dispatch({ type: "question1Set", num: [] });
                  dispatch({ type: "question2Set", num: [] });
                  dispatch({ type: "question3Set", num: [] });
                  dispatch({ type: "question4Set", num: [] });
                  dispatch({ type: "question5Set", num: [] });
                  dispatch({ type: "question6Set", num: [] });
                  dispatch({ type: "questionxSet", num: [] });
                  dispatch({ type: "questionySet", num: [] });
                  dispatch({ type: "question9Set", num: [] });
                  dispatch({ type: "questionaSet", num: [] });
                  dispatch({ type: "questionbSet", num: [] });
                  dispatch({ type: "questioncSet", num: [] });
                  dispatch({ type: "questiondSet", num: [] });
                  dispatch({ type: "questioneSet", num: [] });
                  dispatch({ type: "questionzSet", num: [] });
                  dispatch({ type: "answersSet", num: [] });
                  dispatch({ type: "placeSet", num: "" });
                  dispatch({ type: "answersSet", num: [] });
                  localStorage.removeItem("electricityBill");
                  localStorage.removeItem("waterBill");
                  if (dash === "/EDashboard/allApplications") {
                    router("/EDashboard/application");
                  } else if (dash === "/AdminDashboard/allApplications") {
                    router("/AdminDashboard/application");
                  } else if (dash === "/superAdminDashboard/allApplications") {
                    router("/superAdminDashboard/application");
                  } else {
                    router("/Dashboard/application");
                  }
                }}
              >
                <img
                  className="scale-[0.8] ml-2 cursor-pointer"
                  src={add}
                  alt=""
                />
              </div>
            ) : null}
          </div>
        )}
        {oldage[0]?.applications.length > 0 && (
          <div
            className={`bg-slate-50 rounded-md p-3 mb-10 shadow-lg ${
              oldage[0]?.applications[0]?.applicationpdfs?.length !== 0
                ? ""
                : "hidden"
            }`}
          >
            {user === "employee" && !privilege.includes("View application") ? (
              <h1>No Application Found</h1>
            ) : (
              <>
                {oldage[0]?.applications[0]?.applicationpdfs?.length !== 0 && (
                  <div className="flex flex-wrap sm:justify-start justify-center bg-slate-50 rounded-md p-3 ">
                    <div className="w-full sm:w-[40%] tab:w-[60%]">
                      <ul className="flex flex-row mt-3 justify-between items-center rounded-md bg-[#E8E8E8] hover:bg-[#cbcbcb] p-3 mb-3">
                        <div className="mb-3 sm:mb-0">
                          <img className="w-[4rem]" src={pdf} alt="" />
                        </div>
                        <a
                          className="hidden"
                          id="randomLink"
                          href={`${actualUrl}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Random Link
                        </a>
                        <div className="flex flex-col  items-center">
                          <h2 className="text-[#596F96] text-[0.9rem] font-bold capitalize mb-6 sm:mr-4">
                            {oldage[0]?.applications[0]?.finalStatus}
                          </h2>
                          <li
                            className={`relative flex items-center ${
                              dash === "/Dashboard/allApplications"
                                ? "hidden"
                                : ""
                            }`}
                          >
                            <Dropdown
                              menu={{
                                items: items,
                                onClick: onClick(
                                  oldage[0]?.applications[0]?.id
                                ),
                              }}
                              trigger={["click"]}
                              onClick={() => {
                                setPdf(
                                  oldage[0]?.applications[0]?.applicationpdfs[0]
                                    ?.url
                                );
                                onClick2(
                                  oldage[0]?.applications[0]?.id,
                                  oldage[0]?.applications[0]?.programId
                                );
                              }}
                            >
                              <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                                <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              </div>
                            </Dropdown>
                          </li>
                        </div>
                      </ul>
                    </div>
                    {/* ))} */}
                  </div>
                )}

                <hr />

                <h1
                  className={`${
                    user === "pensioner" ||
                    oldage[0]?.applications[0]?.applicationpdfs?.length < 2
                      ? "hidden"
                      : ""
                  }  text-[1.2rem] text-gray-700 font-semibold mt-3 ml-[20px]`}
                >
                  Previous Applications
                </h1>
                {oldage?.applications?.length !== 0 && (
                  <div
                    className={`${
                      user === "pensioner" ? "hidden" : ""
                    }flex flex-wrap  sm:justify-start justify-center  sm:gap-3 bg-slate-50 rounded-md p-3  gap-2 `}
                  >
                    {oldage[0]?.applications[0]?.applicationpdfs
                      ?.filter((a) => a.isCurrent === false)
                      .map((item, index) => (
                        <div
                          className="w-[40%] sm:w-[30%] md:w-[20.33%] lg:w-[15.33%] xl:w-[15.33%]  "
                          key={index}
                        >
                          <ul className="flex flex-row mt-3 gap-2 justify-between items-center rounded-md bg-[#E8E8E8] hover:bg-[#cbcbcb] p-3 mb-3">
                            <div className="flex-shrink-0 mb-3 sm:mb-0">
                              <img
                                className="sm:w-[3rem] w-[3rem] tab:w-[3rem]"
                                src={pdf}
                                alt=""
                              />
                            </div>
                            <a
                              className="hidden"
                              id="randomLink"
                              href={`${actualUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Random Link
                            </a>
                            <div className="flex flex-col  items-center">
                              <h2 className="text-[#596F96] text-[0.9rem] font-bold capitalize mb-6 sm:mr-4">
                                {item?.finalStatus}
                              </h2>
                              <li
                                className={`relative flex items-center ${
                                  dash === "/Dashboard/allApplications"
                                    ? "hidden"
                                    : ""
                                }`}
                              >
                                <Dropdown
                                  menu={{
                                    items: items2,
                                    onClick: onClick(
                                      oldage[0]?.applications[0]?.id
                                    ),
                                  }}
                                  trigger={["click"]}
                                  onClick={() => {
                                    onClick2(item?.id);
                                    setPdf(item?.url);
                                  }}
                                >
                                  <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                                    <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                    <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                    <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                                  </div>
                                </Dropdown>
                              </li>
                            </div>
                          </ul>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center  `}
      >
        <div className="relative rounded-xl w-[29rem]  pb-4 pt-4 flex flex-col items-center p-4 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2">
            Approve Application
          </label>

          <div
            className="overflow-y-auto overflow-y-scroll custom-scrollbar max-h-96 p-5"
            style={{ overflowX: "hidden" }}
          >
            {/* Status Select */}
            <div className="w-full mb-2">
              <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                Status <span className="text-red-500">*</span>
              </label>
              <Select
                // value={statuss}
                onChange={(e) => {
                  setStatus(e);
                }}
                placeholder="Select Status"
                showSearch
                value={statuss}
                optionFilterProp="children"
                className="wow1 capitalize"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={[
                  { label: "Approved", value: "approved" },
                  { label: "Rejected", value: "rejected" },
                ]}
              />
            </div>
            <div className="w-full mb-2">
              <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                Date & Time
              </label>
              <DatePicker
                showTime
                placeholder="Approval Date & Time"
                // value={dateTime}
                onChange={(value) => setDateTime(value)}
                getPopupContainer={(trigger) => trigger.parentElement}
                className="sqw lg:text-[1.1rem] h-[2.5rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
              />
            </div>
            {/* Reason Textarea */}
            <div className="w-full mb-2">
              <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
                Public Notes <span className="text-red-500">*</span>
              </label>
              <textarea
                onChange={(e) => setStatusReason(e.target.value)}
                value={statusReason}
                type="text"
                placeholder="Enter Public Notes"
                className="sqw lg:text-[1.1rem] h-[4rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
              />
            </div>
            {/* Private Reason Textarea */}
            <div className="w-full mb-2">
              <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                Private Notes
              </label>
              <textarea
                onChange={(e) => setPrivateStatusReason(e.target.value)}
                value={privateStatusReason}
                type="text"
                placeholder="Enter Private Notes"
                className="sqw lg:text-[1.1rem] h-[3rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
              />
            </div>
            {user === "superadmin" ||
            subrole === "director of social services" ? (
              <>
                <div className="w-full mb-3">
                  <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold capitalize">{`Amount ${interval}`}</label>
                  <Input
                    // onChange={(e) => setPrivateStatusReason(e.target.value)}
                    value={amount}
                    type="text"
                    placeholder="Enter Amount"
                    className="sqw lg:text-[1.1rem] h-[3rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2 cursor-not-allowed"
                  />
                </div>

                <div className="w-full mb-3">
                  <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                    Include Utilities
                  </label>
                  <div className="grid  grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {utilities &&
                      utilities.map((privilege, index) => (
                        <div
                          key={index} // Replace 'id' with the actual unique identifier
                          className="p-2  transition duration-300 ease-in-out bg-white"
                        >
                          <label className="text-sm font-semibold text-gray-600 flex items-center">
                            <input
                              type="checkbox"
                              className="mr-2 w-4 h-4 text-slate-500"
                              // checked={selectedPrivileges.includes(privilege)}
                              // onChange={() => togglePrivilege(privilege)}
                            />
                            {privilege}
                          </label>
                          <input
                            placeholder="Amount"
                            className="mt-2 px-2 py-1 bg-gray-100 rounded-md w-full"
                          />
                        </div>
                      ))}
                  </div>
                </div>
                {interval === "annually" && (
                  <div className="w-full flex gap-2 mb-3">
                    <div className="w-full">
                      <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                        From
                      </label>
                      <DatePicker
                        type="text"
                        placeholder="From Date"
                        disabledDate={disabledDate}
                        // value={from ? days(from) : null}
                        onChange={handleFromDateChange}
                        allowClear
                      />
                    </div>
                    <div className="w-full">
                      <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                        To
                      </label>
                      <DatePicker
                        type="text"
                        placeholder="To Date"
                        value={to ? moment(to) : null}
                        onChange={handleToDateChange}
                        disabled={interval === "annually"}
                        allowClear
                      />
                    </div>
                  </div>
                )}
                {interval === "monthly" && (
                  <div className="w-full flex gap-2 mb-3">
                    <div className="w-full">
                      <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                        From
                      </label>
                      <DatePicker
                        type="text"
                        placeholder="From Date"
                        disabledDate={disabledDate}
                        // value={moment(from)}
                        // value={from ? moment(from) : null}
                        value={from ? days(from, "YYYY-MM-DD") : null}
                        onChange={handleFromDateChange}
                        allowClear
                      />
                    </div>
                    <div className="w-full">
                      <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                        To
                      </label>
                      <DatePicker
                        type="text"
                        placeholder="To Date"
                        allowClear
                        // value={moment(to)}
                        value={to ? days(to, "YYYY-MM-DD") : null}
                        onChange={handleToDateChange}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {/* Buttons */}
            <div className="flex justify-end mt-2">
              <button
                onClick={() => {
                  setStatus(null);
                  setStatusReason("");
                  setPrivateStatusReason("");
                  setTo(null);
                  setFrom(null);
                  setShow1(false);
                  setAmount("");
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Cancel
              </button>
              <button
                className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
                onClick={() => {
                  signApplication();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      {/* <div className='pdf-container'>
        {actualUrl && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <Viewer fileUrl={actualUrl}
            plugins={[defaultLayoutPluginInstance]} />
        </Worker></>}
        {!viewPdf && <div className="w-full text-center mt-2 mb-6">No pdf file selected</div>}
      </div> */}
      <Whatsapp />
    </div>
  );
};

export default AllApplications;
