import React, { useState } from "react";
import reqSent from "../../../../imgs/reqSent.svg";
import reqCan from "../../../../imgs/reqCan.svg";
import axios from "axios";
const Main = ({ setShow, data, setRefetch, refetch }) => {
  const AcceptModal = () => {
    return (
      <div className={`bg-white rounded-lg relative `}>
        <div className="relative rounded-xl sm:w-[28rem] w-[22rem] flex items-center flex-col mb-6">
          <div className="w-[7rem] h-[7rem] flex items-center justify-center rounded-full">
            <img src={reqSent} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[80%] mb-1 font-semibold text-primary text-lg">
            Request Accepted
          </p>
          <p className="text-center w-[82%]">
            Please pay the amount within 24 hours, as the link will expire.
          </p>
          <div className="w-full px-4">
            <p className="mb-2 text-primary mt-2 flex items-start font-semibold ml-10 text-[0.93rem]">
              Your Payment ID
            </p>
          </div>
          <input
            type="text"
            className="lg:text-[0.89rem] text-gray-800 font-semibold w-[18rem] text-center bg-gray-100 border-[1px] rounded-[0.3rem] p-2 pointer-events-none"
            value={data?.paymentId}
          />
          <div className="flex gap-4 w-[70%] items-center justify-center mt-4">
            <button
              onClick={() => {
                setRefetch(!refetch);
                setShow(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary cursor-pointer"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${
        setShow ? "" : "hidden"
      } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
    >
      <AcceptModal />
    </div>
  );
};

export default Main;
