import cardimg from "../../imgs/placeholder.png";
import code from "../../imgs/Qr Code.png";
import logo from "../../imgs/impLogo.png";
import map from "../../imgs/map.png";
import pattern from "../../imgs/pattern.png";
import signature1 from "../../imgs/signature 1.png";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
const DigitalCard = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const [signature, setSignature] = useState("");

  const initialsArray = profileData?.pensionPrograms?.map((program) => {
    const firstLetter = program.name.split('-')[0]?.charAt(0)?.toUpperCase();
    return firstLetter;
  });
  const result = initialsArray?.join("&nbsp;&nbsp;");
  useEffect(() => {
    let sett;
    if (profileData?.pensionerInfo) {
      if (profileData?.pensionerInfo?.signature !== null) {
        sett = [profileData?.pensionerInfo?.signature];
      } else if (profileData?.pensionerInfo?.leftIndex !== null) {
        sett = [profileData.pensionerInfo.leftIndex];
      } else if (profileData?.pensionerInfo?.rightIndex !== null) {
        sett = [profileData.pensionerInfo.rightIndex];
      } else if (profileData?.pensionerInfo?.leftThumb !== null) {
        sett = [profileData?.pensionerInfo?.leftThumb];
      } else {
        sett = [profileData?.pensionerInfo?.rightThumb];
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
        {
          fileNames: sett,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        
        setSignature(data?.data[0]?.url);
      })
      .catch(() => {
        
      });
  }, []);
  return (
    <div>
      {/* <div className="w-[32rem] h-[16.5rem] rounded-xl shadow-2xl overflow-hidden">
        <div className="relative w-full h-[30%] bg-[#0651B0] rounded-tl-xl rounded-tr-xl">
          <div className="triangle-bl top-[24.5%] absolute"></div>
          <div className="triangle-br top-[-26.5%] right-0 absolute"></div>
          <div className="diamond top-[-25%] right-[48%] absolute"></div>
          <div className="absolute w-[5.2rem] h-[5rem] text-white font-bold right-3 top-1 text-[2rem]">
            <img className="w-[5.2rem] h-[5rem]" src={logo} alt="" />
          </div>
          <div className="absolute rounded-full w-[6rem] h-[6rem] left-[8%] top-[23%]">
            <img
              className="w-[6rem] h-[6rem] rounded-full object-cover"
              src={cardimg}
              alt=""
            />
          </div>
        </div>
        <div className="w-full h-[70%] rounded-bl-xl rounded-br-xl">
          <div>
            <div className="flex items-center">
              <div className="mt-1 ml-[10rem]">
                <div className="relative leading-[1.1rem]">
                  <span className="font-bold text-[1.4rem] text-[#0651B0]">
                    Marcus Webster
                  </span>
                  <br></br>
                  <span className="font-semibold text-[0.9rem]">
                    1434&nbsp;&nbsp;5677&nbsp;&nbsp;9012&nbsp;&nbsp;3464
                  </span>
                </div>
              </div>
              <div className="w-[4.7rem] h-[4rem] border-2 border-[#0651B0] ml-[4.5rem] mt-2 flex justify-center items-center">
                <img className="w-[4rem] h-[3.5rem]" src={code} alt="" />
              </div>
            </div>
          </div>
          <div className="flex mt-2 ml-5">
            <div className="border-r-[2px] border-black w-[8rem] h-[5rem] border-dotted">
              <p className="leading-[1.1rem]">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  D.O.B.
                </span>
                <br></br>
                <span className="font-semibold text-[0.7rem]">10/22/2000</span>
              </p>
              <p className="leading-[1.1rem] mt-1">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  Date of Approval
                </span>
                <br></br>
                <span className="font-semibold text-[0.7rem]">02/04/2017</span>
              </p>
            </div>
            <div className="border-r-2 w-[5rem] border-black h-[5rem] border-dotted ml-6">
              <p className="leading-[1.1rem]">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  Gender
                </span>
                <br></br>
                <span className="font-semibold text-[0.7rem]">Male</span>
              </p>
              <p className="leading-[1.1rem] mt-1">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  SCP ID
                </span>
                <br></br>
                <span className="font-semibold text-[0.7rem]">2344323</span>
              </p>
            </div>
            <div className="w-[9.6rem] ml-3">
              <p className="leading-[1.1rem]">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  GID
                </span>
                <br></br>
                <span className="font-semibold text-[0.7rem]">1234321</span>
              </p>
              <div className="flex border-black border-r-2 border-dotted h-[2.5rem]">
                <p className="leading-[1.1rem] mt-1">
                  <span className="font-bold text-[0.8rem] text-[#0651B0]">
                    Region
                  </span>
                  <br></br>
                  <span className="font-semibold text-[0.7rem]">
                    Pomeroon-Supenaam
                  </span>
                </p>
              </div>
            </div>
            <div className="ml-3 mt-[2rem] relative">
              <div className="absolute h-[2rem] w-[4.5rem] left-[-12%] top-[-72%]">
                <img className="h-[2rem] w-[4.5rem]" src={flag} alt="" />
              </div>
              <div className="w-[8rem] h-[3rem] absolute top-0 right-[-80%]">
                <img className="w-[8rem] h-[3rem]" src={signature} alt="" />
              </div>
              <p className="text-[0.6rem] font-semibold mt-[2rem]">
                Singnature
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {profileData.person && (
        <div className="w-[32rem] h-[16.5rem] rounded-xl shadow-2xl overflow-hidden">
          <div className="relative w-full h-[30%] bg-[#0651B0] rounded-tl-xl rounded-tr-xl">
            <div className="triangle-bl top-[24.5%] absolute"></div>
            <div className="triangle-br top-[-26.5%] right-0 absolute"></div>
            <div className="diamond top-[-25%] right-[48%] absolute"></div>
            <div className="absolute w-[5.2rem] h-[5rem] text-white font-bold right-3 top-1 text-[2rem]">
              <img className="w-[5.2rem] h-[5rem]" src={logo} alt="" />
            </div>
            {}
            <div className="absolute rounded-full w-[6.5rem] h-[6.5rem] left-[8%] top-[23%] z-10">
              {profileData.person && (
                <img
                  className="w-[6.5rem] h-[6.5rem] rounded-full object-cover"
                  src={profileData.person.image}
                  alt=""
                />
              )}
              <img
                className={`w-[6.5rem] ${
                  profileData.person ? "hidden" : ""
                } h-[6.5rem] rounded-full object-cover`}
                src={cardimg}
                alt=""
              />
            </div>
            <div>
              <img
                className="h-[17rem] opacity-60 absolute top-[-5%] left-[28%]"
                src={map}
                alt=""
              />
            </div>
          </div>
          <div className="overflow-hidden relative w-full h-[70%] rounded-bl-xl rounded-br-xl flex justify-center items-center">
            <div className="absolute">
              <img
                className="w-[60rem] h-[25rem] opacity-90"
                src={pattern}
                alt=""
              />
            </div>
            <div className="absolute top-[-50%] left-[-70%]">
              <img
                className="w-[60rem] h-[25rem] opacity-90 rotate-10"
                src={pattern}
                alt=""
              />
            </div>
            {/* <div className="absolute top-[-50%] left-[-70%]">
            <img
              className="w-[60rem] h-[25rem] opacity-90"
              src={pattern}
              alt=""
            />
          </div> */}

            <div className="absolute leading-[1.1rem] top-[10%]" style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
              {profileData.person && (
                <span className="font-bold text-[1.4rem] text-[#0651B0] capitalize">
                {profileData?.person?.firstName}&nbsp;
                {profileData?.person?.middleName && (
                  <>
                    {profileData?.person?.middleName}&nbsp;
                  </>
                )}
                {profileData?.person?.lastName}
              </span>
              )}
              <span
                className={`font-bold text-[1.4rem] text-[#0651B0] ${
                  profileData.person ? "hidden" : ""
                }`}
              >
                ------
              </span>
              {profileData.user && (
                <span className="text-[0.9rem] mt-1.5 font-bold" >
                  {profileData.user.cardNumber.substring(0, 4)}
                  &nbsp;{profileData.user.cardNumber.substring(4, 8)}
                  &nbsp;{profileData.user.cardNumber.substring(8, 12)}
                  &nbsp;{profileData.user.cardNumber.substring(12, 16)}
                </span>
              )}
            </div>
            <div className="absolute top-0 right-[3%] w-[4rem] h-[4rem] border-2 border-[#0651B0] ml-[4.5rem] mt-2 flex justify-center items-center">
              {profileData.user && (
                    <QRCode style={{ height:'4rem'}}
                        value={profileData.user.uniqueId}
                    />
                )}
            </div>
            <div className="absolute top-[46%] left-[5%] border-r-[2px] border-black w-[8rem] h-[5rem] border-dotted">
              <p className="leading-[1.1rem] mb-3">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  DOB
                </span>
                <br></br>
                {profileData.person && (
                  <span className="font-bold text-[0.7rem]">
                    {moment(profileData.person.dateOfBirth).format("DD/MM/YYYY")}
                  </span>
                )}
                <span
                  className={`font-bold text-[0.7rem] ${
                    profileData.person ? "hidden" : ""
                  }`}
                >
                  --/--/----
                </span>
              </p>
              <p className="leading-[1.1rem] mt-1">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  Date of Approval
                </span>
                <br></br>
                <span className="font-bold text-[0.7rem]">{profileData?.approvalDate !== null ?moment(profileData?.approvalDate).format("DD/MM/YYYY") : "--/--/----"}</span>
              </p>
            </div>
            <div className="absolute top-[46%] left-[29%] border-r-2 w-[5rem] border-black h-[5rem] border-dotted ml-6">
              <p className="leading-[1.1rem] mb-3">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  Gender
                </span>
                <br></br>
                {profileData.person && (
                  <span className="font-bold text-[0.7rem]">
                    {profileData.person.gender}
                  </span>
                )}
                <span
                  className={`font-bold text-[0.7rem] ${
                    profileData.person ? "hidden" : ""
                  }`}
                >
                  ----
                </span>
              </p>
              <p className="leading-[1.1rem] mt-1">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  SCP ID
                </span>
                <br></br>
                <span className="font-bold text-[0.7rem]">
                  {profileData.user.uniqueId}
                </span>
              </p>
            </div>
            <div className="absolute top-[46%] left-[50%] w-[9.6rem] ml-3">
              <div className="flex mb-2">
                <p className="leading-[1.1rem]">
                  <span className="font-bold text-[0.8rem] text-[#0651B0]">
                    Region
                  </span>
                  <br></br>
                  {profileData?.person?.addresses !== 0 && (
                    <span className={`font-bold text-[0.7rem] `}>
                      {profileData?.person?.addresses[0]?.regionNo?.substring(7, 8)}
                    </span>
                  )}
                  <span
                    className={`font-bold text-[0.7rem] ${
                      profileData?.person ? "hidden" : ""
                    }`}
                  >
                    2
                  </span>
                </p>
                <p className="leading-[1.1rem] ml-[2rem]">
                  <span className="font-bold text-[0.8rem] text-[#0651B0]">
                    GID
                  </span>
                  <br></br>
                  <span className="font-bold text-[0.7rem]">
                    {" "}
                    {profileData?.user?.gid === null
                      ? "---"
                      : `${profileData?.user?.gid}`}
                  </span>
                </p>
              </div>
              <div className="flex  h-[2.5rem] border-black border-r-2 border-dotted ">
                <p className="leading-[1.1rem] mt-1">
                  <span className="font-bold text-[0.8rem] text-[#0651B0]">
                    Village/Ward
                  </span>
                  <br></br>
                  {profileData?.person?.addresses !== 0 && (
                    <span className={`font-semibold text-[0.7rem] `}>
                      {profileData?.person?.addresses[0]?.ward}
                    </span>
                  )}
                  <span
                    className={`font-semibold text-[0.7rem] ${
                      profileData?.person ? "hidden" : ""
                    }`}
                  >
                    Bel Air
                  </span>
                </p>
              </div>
            </div>
            <div className="absolute ml-3 mt-[2rem] top-[50%] left-[82%]">
              <p className="absolute top-[-98%] leading-[1.1rem] mt-1">
                <span className="font-bold text-[0.8rem] text-[#0651B0] capitalize">
                  {profileData?.user?.status}
                </span>
                <br></br>
                <span className="font-semibold text-[0.8rem] text-[#0651B0]">
                  {result && result}
                </span>
              </p>
              <div className="w-[8rem] h-[3rem] absolute top-0 ">
                <img className="w-[4rem] h-[2rem]" src={signature} alt="" />
              </div>
              <p className="text-[0.6rem] font-bold mt-[2rem] ">
                Singnature
              </p>
            </div>
          </div>
          {/* <div className="w-full h-[70%] rounded-bl-xl rounded-br-xl">
          <div>
            
          </div>
          <div className="flex mt-2 ml-5">
            
            
            
            
          </div>
        </div> */}
        </div>
      )}

      <div
        className={` ${
          profileData.person ? "hidden" : ""
        } w-[32rem] h-[16.5rem] rounded-xl shadow-2xl overflow-hidden`}
      >
        <div className="relative w-full h-[30%] bg-[#0651B0] rounded-tl-xl rounded-tr-xl">
          <div className="triangle-bl top-[24.5%] absolute"></div>
          <div className="triangle-br top-[-26.5%] right-0 absolute"></div>
          <div className="diamond top-[-25%] right-[48%] absolute"></div>
          <div className="absolute w-[5.2rem] h-[5rem] text-white font-bold right-3 top-1 text-[2rem]">
            <img className="w-[5.2rem] h-[5rem]" src={logo} alt="" />
          </div>
          <div className="absolute rounded-full w-[6.5rem] h-[6.5rem] left-[8%] top-[23%] z-10">
            <img
              className={`w-[6.5rem] h-[6.5rem] rounded-full object-cover`}
              src={cardimg}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-[17rem] opacity-60 absolute top-[-5%] left-[28%]"
              src={map}
              alt=""
            />
          </div>
        </div>
        <div className="overflow-hidden relative w-full h-[70%] rounded-bl-xl rounded-br-xl flex justify-center items-center">
          <div className="absolute">
            <img
              className="w-[60rem] h-[25rem] opacity-90"
              src={pattern}
              alt=""
            />
          </div>
          <div className="absolute top-[-50%] left-[-70%]">
            <img
              className="w-[60rem] h-[25rem] opacity-90 rotate-10"
              src={pattern}
              alt=""
            />
          </div>
          {/* <div className="absolute top-[-50%] left-[-70%]">
            <img
              className="w-[60rem] h-[25rem] opacity-90"
              src={pattern}
              alt=""
            />
          </div> */}

          <div className="absolute leading-[1.1rem] top-[10%]">
            <span className={`font-bold text-[1.4rem] text-[#0651B0]`}>
              ----
            </span>
            <br></br>
            <span className="font-semibold text-[0.9rem]">
              ----&nbsp;&nbsp;----&nbsp;&nbsp;----&nbsp;&nbsp;----
            </span>
          </div>
          <div className="absolute top-0 right-[3%] w-[4.7rem] h-[4rem] border-2 border-[#0651B0] ml-[4.5rem] mt-2 flex justify-center items-center">
            <img className="w-[4rem] h-[3.5rem]" src={code} alt="" />
          </div>
          <div className="absolute top-[46%] left-[5%] border-r-[2px] border-black w-[8rem] h-[5rem] border-dotted">
            <p className="leading-[1.1rem] mb-3">
              <span className="font-bold text-[0.8rem] text-[#0651B0]">
                DOB
              </span>
              <br></br>

              <span className={`font-semibold text-[0.7rem]`}>--/--/----</span>
            </p>
            <p className="leading-[1.1rem] mt-1">
              <span className="font-bold text-[0.8rem] text-[#0651B0]">
                Date of Approval
              </span>
              <br></br>
              <span className="font-semibold text-[0.7rem]">--/--/----</span>
            </p>
          </div>
          <div className="absolute top-[46%] left-[29%] border-r-2 w-[5rem] border-black h-[5rem] border-dotted ml-6">
            <p className="leading-[1.1rem] mb-3">
              <span className="font-bold text-[0.8rem] text-[#0651B0]">
                Gender
              </span>
              <br></br>
              <span className="font-semibold text-[0.7rem]">----</span>
            </p>
            <p className="leading-[1.1rem] mt-1">
              <span className="font-bold text-[0.8rem] text-[#0651B0]">
                SCP ID
              </span>
              <br></br>
              <span className="font-semibold text-[0.7rem]">-------</span>
            </p>
          </div>
          <div className="absolute top-[46%] left-[50%] w-[9.6rem] ml-3">
            <div className="flex mb-2">
              <p className="leading-[1.1rem]">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  Region
                </span>
                <br></br>

                <span className={`font-semibold text-[0.7rem] `}>-</span>
              </p>
              <p className="leading-[1.1rem] ml-[3rem]">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  GID
                </span>
                <br></br>
                <span className="font-semibold text-[0.7rem]">---------</span>
              </p>
            </div>
            <div className="flex  h-[2.5rem] border-black border-r-2 border-dotted ">
              <p className="leading-[1.1rem] mt-1">
                <span className="font-bold text-[0.8rem] text-[#0651B0]">
                  Village/Ward
                </span>
                <br></br>

                <span className={`font-semibold text-[0.7rem] `}>----</span>
              </p>
            </div>
          </div>
          <div className="absolute ml-3 mt-[2rem] top-[50%] left-[82%]">
            <p className="absolute top-[-98%] leading-[1.1rem] mt-1">
              <span className="font-bold text-[0.8rem] text-[#0651B0]">
             Pending
              </span>
              <br></br>
              <span className="font-bold text-[0.7rem]">
                {result}
              </span>
            </p>
            <div className="w-[8rem] h-[3rem] absolute top-0 right-[-80%]">
              <img className="w-[8rem] h-[3rem]" src={signature1} alt="" />
            </div>
            <p className="text-[0.6rem] font-semibold mt-[2rem]">Singnature</p>
          </div>
        </div>
        {/* <div className="w-full h-[70%] rounded-bl-xl rounded-br-xl">
          <div>
            
          </div>
          <div className="flex mt-2 ml-5">
            
            
            
            
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DigitalCard;
