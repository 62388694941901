import AddAdmin from "../components/AddAdmin/addAdmin";
import Nav from "../components/Nav/nav";
import Vpopup from "../components/Popup/Vpopup";
import Vppopup from "../components/Popup/Vppopup";
import Popup from "../components/Popup/popup";
import Whatsapp from "../Whatsapp/whatsapp";

const AddAdminPage = () => {
  return (
    <div>
      <Nav />
      <AddAdmin />
      <Popup />
      <Vpopup />
      <Vppopup />
      <Whatsapp />
    </div>
  );
};

export default AddAdminPage;
