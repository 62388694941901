import { useDispatch } from "react-redux";
import Application from "../components/Application/application";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import Whatsapp from "../Whatsapp/whatsapp";
import { useLocation, useNavigate } from "react-router-dom";
const ApplicationPage = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div className="">
      <Nav />
      <div className="flex flex-col w-full p-4 items-center justify-center">
        <div className="relative flex justify-start md:mt-[4rem] mt-6 md:w-[75%] w-[85%] items-center text-[1.8rem] font-bold mb-[3rem]">
          <div className="cursor-pointer flex w-full items-center">
            <img
              onClick={() => {
                dispatch({ type: "ASET", num: 0 });
                dispatch({ type: "placeSet", num: null });
                dispatch({ type: "answersSet", num: [] });
                if (dash === "/EDashboard/application") {
                  router("/EDashboard/allApplications");
                } else if (dash === "/AdminDashboard/application") {
                  router("/AdminDashboard/allApplications");
                } else if (dash === "/superAdminDashboard/application") {
                  router("/superAdminDashboard/allApplications");
                } else {
                  router("/Dashboard/allApplications");
                }
              }}
              className="w-[1.7rem]"
              src={back5}
              alt=""
            />
            <h2 className="ml-2">Application</h2>
          </div>
        </div>
        <div className="w-full mb-[5rem]">
          <Application />
        </div>
      </div>
      <Whatsapp />
    </div>
  );
};

export default ApplicationPage;
