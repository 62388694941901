import React, { useEffect, useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import axios from "axios";
import { Select } from "antd";
import { Calendar } from "react-multi-date-picker";
import deleteimg from "../../../imgs/delete.svg";
import moment from "moment/moment";
import { TimePicker } from "antd";
const SelectProgram = () => {
  const { Option } = Select;
  const router = useNavigate();
  const dispatch = useDispatch();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const [selectedDateandTime, setSelectedDateandTime] = useState([
    {
      date: "",
      startTime: "",
      endTime: "",
    },
  ]);

  console.log(selectedDateandTime);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const options = programs?.map((item) => ({
    value: item?.name,
    label: item?.name,
    imageUrl: item?.icon,
  }));

  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>{formatProgramNames(option?.label)}</span>
      </div>
    </Option>
  );

  const [timeSlots, setTimeSlots] = useState([
    { time: "9:00am" },
    { time: "12:00pm" },
    { time: "2:00pm" },
    { time: "6:00pm" },
  ]);


  const addTimeSlot = () => {
    setSelectedDateandTime([...selectedDateandTime, { date: "" }]);
    setTimeSlots([...timeSlots, { time: "" }]);
  };

  const removeTimeSlot = (index) => {
    const updatedSlots = selectedDateandTime.filter((_, i) => i !== index);
    setSelectedDateandTime(updatedSlots);
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Choose date & time"}
        route={"/scheduleVerification/schedulesDetails"}
      />

      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div className={`flex items-center justify-between`}>
            <h3 className="font-medium mb-1 text-[0.93rem]">Selected Center</h3>
            <p
              className="text-primary cursor-pointer font-medium"
              onClick={() => {
                router("/pensionerVerification/selectLocation");
              }}
            >
              Change
            </p>
          </div>
          <div className="bg-white p-3 rounded-md mt-1 mb-4">
            {distributionCenter?.distributioncenter?.centerName}
            {" , "}

            {
              distributionCenter?.distributioncenter?.distributionlocation
                ?.distributionregion?.regionNo
            }
          </div>
          <div>
            <h3 className="font-medium mb-1 text-[0.93rem]">Program</h3>

            <Select
              placeholder="Select Program"
              // className="wow w-[100%] mb-4"
              className="w-[100%] woww mb-4"
              renderOption={renderOption}
            >
              {options?.map(renderOption)}
            </Select>
          </div>
          <div>
            <h3 className="font-medium mb-1 text-[0.93rem]">
              Choose the dates
            </h3>

            <div className="flex gap-5 mt-3">
              <Calendar
                multiple
                value={selectedDates}
                onChange={(dates) => {
                  // Map over the dates and convert them to plain JS Date or formatted string
                  const formattedDates = dates.map((date) => date.toDate()); // or date.format() for string format
                  setSelectedDates(formattedDates);
                }}
                minDate={today} // Disable past dates
              />
              <div
                className={`${
                  selectedDates.length === 0 ? "hidden" : "bg-white w-full p-4"
                }`}
              >
                <p className="mb-4">Set Schedule</p>
                <div className="grid grid-cols-7 gap-3 mb-3">
                  {selectedDates?.map((date) => (
                    <button
                      key={date}
                      onClick={() => setSelectedDate(date)}
                      className={`px-4 py-2 rounded-lg ${
                        selectedDate === date
                          ? "bg-primary text-white"
                          : "bg-gray-100"
                      } font-medium text-md`}
                    >
                      {moment(date).format("DD") + " "}
                      {moment(date).format("MMM")}
                    </button>
                  ))}
                </div>

                <div className="space-y-3">
                  {selectedDateandTime?.map((slot, index) => (
                    <div key={index} className="flex space-x-2 items-center">
                      <TimePicker
                        placeholder="Start Time"
                        format={"HH:mm A"}
                        value={
                          slot?.startTime
                            ? moment(slot.startTime, "HH:mm A")
                            : null
                        }
                        onChange={(time, timeString) => {
                          setSelectedDateandTime((prev) => {
                            const newSlots = [...prev];
                            newSlots[index].startTime = timeString;

                            // Check if selectedDate exists before setting the date
                            if (selectedDate) {
                              newSlots[index].date = selectedDate;
                            }
                            return newSlots;
                          });
                        }}
                      />
                      <TimePicker
                        placeholder="End Time"
                        format={"HH:mm A"}
                        value={
                          slot?.endTime ? moment(slot.endTime, "HH:mm A") : null
                        }
                        onChange={(time, timeString) => {
                          setSelectedDateandTime((prev) => {
                            const newSlots = [...prev];
                            newSlots[index].endTime = timeString;

                            // Check if selectedDate exists before setting the date
                            if (selectedDate) {
                              newSlots[index].date = selectedDate;
                            }
                            return newSlots;
                          });
                        }}
                      />
                      <button
                        onClick={() => removeTimeSlot(index)}
                        className="text-red-500"
                      >
                        <img src={deleteimg} alt="delete" className="w-7 h-7" />
                      </button>
                    </div>
                  ))}
                </div>

                {/* Add Time Slot */}
                <div className="flex items-center mt-4">
                  <button
                    onClick={addTimeSlot}
                    className="flex items-center space-x-2 text-teal-500 font-semibold"
                  >
                    {/* <FiPlus /> */}
                    <span>Add time slot</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            // if (selectedProgram === null) {
            //   setErr("Please select a program");
            //   setShowErr(true);
            //   return;
            // }
            // router("/scheduleVerification/selectEmployees");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectProgram;
