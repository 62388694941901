import React, { useState, useEffect } from "react";
import thumb from "../../../../../imgs/Thumb.png";
import { Input } from "antd";
import PhoneInput from "react-phone-number-input";
const Main = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [prompt, setPrompt] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (show) {
      console.log("doneeeee");
      const timeout = setTimeout(() => {
        setShow(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [show]);
  return (
    <div className="flex flex-col w-full p-4 overflow-hidden min-h-[82vh] rounded-md bg-white">
        <div className="w-[50%] p-4 pl-6 pr-6 flex flex-col gap-2 rounded-md bg-[#F6FBF9]">
          <div className="w-full flex gap-2 flex-col">
            <>
              <label htmlFor="accountHolderName" className="text-sm font-semibold">Name</label>
              <Input
                id="accountHolderName"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="bg-[#F6F4F4] rounded-lg border-none h-[2.5rem]"
              />
            </>
            <>
              {/* Input for Account Number */}
              <label htmlFor="accountNumber" className="text-sm font-semibold">Email Id</label>
              <Input
                id="accountNumber"
                placeholder="Enter Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-[#F6F4F4] rounded-lg border-none h-[2.5rem]"
              />
            </>
            <>
              <label className="text-sm font-semibold">
              Phone Number<span className="text-sm font-normal text-[#00000096]">(Number should be linked with a E-wallet account)</span></label>
              <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={(event)=>setPrompt(event)}
                  international
                  countryCallingCodeEditable={false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
            </>
        <div className="w-full mt-8">
          <button
            onClick={() => {
              setShow(true);
            }}
            className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
          >
           Send OTP
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-100 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">eWallet is Successfully added</h1>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Main;
