import React from "react";
import { useNavigate } from "react-router-dom";
import back5 from "../../../../imgs/back5.png";
import VoucherCard from "./VouchersCard"
const Voucher = () => {
  const router = useNavigate();
  return (
    <div>
      <div className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem] flex flex-col items-center justify-center">
        <div className="flex flex-col w-full space-between">
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => router("/Merchant/PensionerProfile/ABC123")}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Pensioner Vouchers
            </h2>
          </div>
         
        </div>
      </div>
      <VoucherCard/>
    </div>
  );
};

export default Voucher;
