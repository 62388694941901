import back5 from "../../../../imgs/back5.png";
import reqHistory from "../../../../imgs/reqHistory.svg";
import { useNavigate } from "react-router-dom";
import RequestPage from "./requestPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
const Listingh = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [afterApi, setAfterApi] = useState(false);
  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-requests/list-for-govt-agency?limit=1&skip=0&page=1&sortBy=createdAt:desc`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setAfterApi(true);
        dispatch({ type: "GOV_AGENCY_REQ_DATA", num: data?.data?.rows });
        setData(data?.data?.rows);
      })
      .catch(() => {});
  }, [refetch]);

  return (
    <div className="">
      <div
        className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem] "
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            justifyContent: "space-between",
          }}
          className="flex-1"
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                router("/Merchant/dashboard");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Request Money
            </h2>
          </div>
        </div>

        <div
          className="flex items-center justify-center gap-2 cursor-pointer"
          onClick={() => {
            router("/Merchant/GovAgency/Request/MHSSS/History");
          }}
        >
          <h1 className="font-semibold text-[0..95rem]">
            View Request History
          </h1>
          <img src={reqHistory} alt="" className="w-9 h-9" />
        </div>
      </div>
      {afterApi && (
        <RequestPage data={data} setRefetch={setRefetch} refetch={refetch} />
      )}
    </div>
  );
};

export default Listingh;
