import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
let model;
export const loadModel = async () => {
  if (!model) {
    console.log('Loading model...');
    await tf.ready();
    model = await cocoSsd.load();
  }
  return model;
};
export const detectObjectsInImage = async (file) => {
  const imageElement = document.createElement('img');
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = async () => {
      imageElement.src = reader.result;
      imageElement.onload = async () => {
        const loadedModel = await loadModel();
        const detectedObjects = await loadedModel.detect(imageElement);
        resolve(detectedObjects);
      };
    };
    reader.readAsDataURL(file);
  });
};
