import LoginCredentials from "./tabsR/loginCredentials";
import Witness from "./tabsR/witness";
import PersonalDetails1 from "../../PAccount/parts/tabsR/personalDetails";
import Location1 from "../../PAccount/parts/tabsR/location";
import Identification2 from "../../PAccount/parts/tabsR/identification";
import { useState } from "react";
import { useSelector } from "react-redux";

const Bregistration = () => {
  const [wow, setWow] = useState(0);
  const privilege=useSelector(state=>state.myPrivilges)
  const user =localStorage.getItem("role")
  const Show = [
    LoginCredentials,
    PersonalDetails1,
    Location1,
    Identification2,
    Witness,
  ];
  const Dis = Show[wow];
  // className = "absolute left-[-18%] top-[8%]";
  return (
    <div className="">
      <div className="flex mb-8 flex-wrap">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Login
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Info
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Address
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-primary text-white" : "bg-[#EDEDED]"
          }  flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Identification
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>
        {/* privilege && privilege.includes("Edit witness") */}

            {
              user === "employee" && privilege && !privilege.includes("Edit witness") ? null : (
                <button
                onClick={() => setWow(4)}
                className={`${
                  wow === 4 ? "bg-primary text-white" : "bg-[#EDEDED]"
                } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
              >
                Witness
                <div
                  className={`${
                    wow === 4 ? "" : "hidden"
                  } arrow-down absolute right-[35%] bottom-[-38%]`}
                ></div>
              </button>
              )
            }
       
      </div>
      <Dis />
    </div>
  );
};

export default Bregistration;
