import React from 'react'
import EWallet from "../components/MHSSS Accounting/outstanding/PayOutstanding/eWalletPay/eWalletIndex"

const OutStandingListing = () => {
  return (
    <div>
     <EWallet/>
    </div>
  )
}

export default OutStandingListing