import React from 'react'
import OutStanding from "../components/MHSSS Accounting/outstanding/outstanding"

const OutStandingListing = () => {
  return (
    <div>
     <OutStanding/>
    </div>
  )
}

export default OutStandingListing