import React, { useState, useEffect } from "react";

import logo from "../../../imgs/sapNew.svg";
import reqSent from "../../../imgs/reqSent.svg";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import ErrorModal from "../../Merchant/Modals/errorModal";

import axios from "axios";
import { parsePhoneNumber } from "react-phone-number-input";

import { useNavigate } from "react-router-dom";
import { purposeOptions } from "../constants/purpose";

const Main = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  const [isValid, setIsValid] = useState(true);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [relation, setRelation] = useState("");
  const [purpose, setPurpose] = useState(null);
  const [reason, setReason] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Relation");

  const [isPurposeOpen, setIsPurposeOpen] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState("Purpose");

  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    dispatch({ type: "FACE_API", num: false });
  }, []);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, type) => {
    if (type === "purpose") {
      setSelectedPurpose(option.label);
      setIsPurposeOpen(false);
      setPurpose(option.value);
    } else {
      setSelectedOption(option.label);
      setIsOpen(false);
      setRelation(option.value);
    }
  };

  const options = [
    // { label: "Options", value: null },
    { label: "Son", value: "son" },
    { label: "Daughter", value: "daughter" },
    { label: "Grandson", value: "grandson" },
    { label: "Granddaughter", value: "granddaughter" },
    { label: "Father", value: "father" },
    { label: "Mother", value: "mother" },
    { label: "Grandfather", value: "grandfather" },
    { label: "Grandmother", value: "grandmother" },
    { label: "Brother", value: "brother" },
    { label: "Sister", value: "sister" },
    { label: "Uncle", value: "uncle" },
    { label: "Aunt", value: "aunt" },
    { label: "Cousin", value: "cousin" },
    { label: "Nephew", value: "nephew" },
    { label: "Niece", value: "niece" },
  ];

  const pay = () => {
    console.log(phoneNoo, countryCode);
    if (phone === "" && email === "") {
      setError("Enter either phone number or email");
      setShowError(true);
      return;
    }
    if (firstName === "") {
      setError("First Name is required");
      setShowError(true);
      return;
    }

    if (lastName === "") {
      setError("Last Name is required");
      setShowError(true);
      return;
    }
    if (amountPaid === "") {
      setError("Amount is required");
      setShowError(true);
      return;
    }

    if (relation === "") {
      setError("Relation is required");
      setShowError(true);
      return;
    }

    if (purpose === null) {
      setError("Purpose is required");
      setShowError(true);
      return;
    }

    //   setShowConfirm(true);

    const body = {
      firstName,
      lastName,
      amountPaid,

      relation,
    };

    if (email !== "") {
      body.email = email;
    }

    if (phoneNoo && countryCode && phone !== "") {
      body.phone = phone;
    }

    if (middleName !== "") {
      body.middleName = middleName;
    }
    if (purpose !== "") {
      body.purpose = purpose;
    }

    if (reason !== "") {
      body.reason = reason;
    }
    // if (user === "merchant-owner" || user === "merchant-employee") {
    //   if (merchantSapType === "HQ") {
    //     body.merchheadquarterId =
    //       merchantSapTypeData?.merchuser?.merchheadquarterId;
    //   } else {
    //     body.merchstoreId = merchantSapTypeData?.id;
    //   }
    //   body.payStatus = "merchant_paid";
    //   body.pensionerUserId = selectedPensionerSap?.user?.id;
    //   delete body.image;
    // }

    // console.log(body);
    // return;

    const api = user === "pensioner" ? "debit" : "credit";
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/credit/parent-request`,
        body,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((data) => {
        console.log(data?.data);
        // router(`/child/response/${data?.data?.id}`)
        shareLink(data?.data?.id);
        setShow(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShow(true);
      });
  };

  const shareLink = (id) => {
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    const link = `https://sap-staging.jabcomp.gy/child/response/${id}`;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${id}/share-link-with-child`,
        { link }, // Correcting the request body format
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((response) => {
        // Handle successful response
        console.log("Link shared successfully:", response.data);
        // setShow(true); // Show success modal or message
      })
      .catch((error) => {
        // Handle error response
        console.error("Error sharing link:", error.response.data.message);
        setError(error.response.data.message);
        // setShow(true); // Show error modal or message
      });
  };

  const validateEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsValid(validateEmail(value));
  };

  return (
    <>
      {/* Upper Div */}
      <div className="sm:m-6 rounded-md px-4 py-4">
        {/* Image Upload */}

        <h2 className="font-bold text-md">Enter Phone number or Email</h2>

        {/* email and phoen no */}

        <div className="flex justify-center items-center sm:px-6 px-3 py-3 mt-4 rounded-md bg-[#F6F4F4]">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                Phone Number <span className="text-red-600"></span>
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                international
                onChange={(phone) => {
                  const value = phone || ""; // Use empty string if phone is null or undefined
                  setPhone(value);
                  setPrompt(value);
                  const wow = parsePhoneNumber(value);
                  console.log(prompt);

                  let count = wow?.countryCallingCode;
                  let num = wow?.nationalNumber;
                  setCountryCode(count);
                  setPhoneNoo(num);
                }}
                countryCallingCodeEditable={false}
                className="w-full"
              />
            </div>
            <div className="flex flex-col items-start w-full gap-1 mt-2">
              <div className="flex items-center w-full gap-1">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[4rem]">
                  Email <span className="text-red-600"></span>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className={`lg:text-[1.1rem] w-[16rem] bg-white border-[1px] rounded-[0.3rem] p-2 ${
                    !isValid ? "border-red-500" : ""
                  }`}
                  value={email}
                  onChange={handleChange}
                />
              </div>
              {!isValid && email && (
                <p className="text-red-500 text-sm mt-1 ml-[4rem]">
                  Invalid email format
                </p>
              )}
            </div>
          </div>
        </div>

        {/* First Name and last name */}
        <div className="flex justify-center items-center sm:px-5 py-3 mt-4 rounded-md">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                First Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="lg:text-[1.1rem] w-[20rem] bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Middle Name <span className="text-red-600"></span>
              </label>
              <input
                type="text"
                placeholder="Middle Name"
                className="lg:text-[1.1rem] w-[20rem] bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Last Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className="lg:text-[1.1rem] w-[20rem] bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="flex items-center w-full gap-3 ">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Relation <span className="text-red-600">*</span>
              </label>
              <div className="relative inline-block w-[20rem]">
                <div
                  className="bg-[#F6F4F4] border border-gray-300 rounded px-4 py-2 cursor-pointer text-gray-500 flex justify-between items-center"
                  onClick={toggleSelect}
                >
                  {selectedOption}
                  <svg
                    className="w-4 h-4 inline ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                    />
                  </svg>
                </div>
                {isOpen && (
                  <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-b shadow-md h-32 overflow-y-auto z-10">
                    {options.map((option) => (
                      <>
                        <div
                          key={option.value}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.label}
                        </div>
                        <hr className="text-gray-400" />
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center sm:px-5 mt-4 rounded-md flex-col gap-5 sm:w-[50%] w-full">
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[9rem]">
              Amount <span className="text-red-600">*</span>
            </label>

            <div className="flex lg:text-[1.1rem] w-[20rem] bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 justify-between">
              <input
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => setAmountPaid(e.target.value)}
                className="bg-[#F6F4F4]"
              />
              <p className="font-bold text-lg">$</p>
            </div>
          </div>
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[9rem]">
              Purpose <span className="text-red-600">*</span>
            </label>
            <div className="relative inline-block w-[20rem]">
              <div
                className="bg-[#F6F4F4] border border-gray-300 rounded px-4 py-2 cursor-pointer text-gray-500 flex justify-between items-center"
                onClick={() => setIsPurposeOpen(!isPurposeOpen)}
              >
                {selectedPurpose}
                <svg
                  className="w-4 h-4 inline ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                  />
                </svg>
              </div>
              {isPurposeOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-b shadow-md h-32 overflow-y-auto">
                  {purposeOptions.map((option) => (
                    <>
                      <div
                        key={option.value}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
                        onClick={() => handleOptionClick(option, "purpose")}
                      >
                        {option.label}
                      </div>
                      <hr className="text-gray-400" />
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[9rem]">
              <span className="text-red-600"></span>
            </label>
            <textarea
              type="text"
              placeholder="Type note here"
              className="lg:text-[1.1rem] w-[20rem] bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 mb-4"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center mb-5 sm:px-10">
        <button
          onClick={() => {
            pay();
          }}
          className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
        >
          Send Request
        </button>
      </div>

      {/* Confirmation Modal */}
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-2 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${amountPaid}</span> to {firstName}
            {lastName}?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                pay();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>

      {/* recipt Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] flex items-center justify-center bg-white rounded-full">
            <img src={reqSent} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold text-primary text-[1.1rem]">Request Sent</h1>
          <p
            className={`${
              user === "pensioner" ? "text-center mt-[1rem] mb-5" : "hidden"
            }`}
          >
            Request has been successfully sent to{" "}
            <span className="capitalize font-bold text-md">
              {firstName} {lastName}
            </span>
            . We will let you know once he/she accept or decline the request
          </p>
          <p
            className={`${
              user === "pensioner" ? "hidden" : "text-center mt-[1rem] mb-5"
            }`}
          >
            Amount has been Transferred to pensioner SAP Money account
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                setShow(false);
                router("/Sap/Money");
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" ? "w-full" : "w-[8rem]"
              }`}
            >
              Okay
            </button>
            {/* <button
              onClick={() => {
                handlePrint();
                setShow(false);
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" ? "hidden" : ""
              }`}
            >
              Print Receipt
            </button> */}
          </div>
        </div>
      </div>

      {showError && <ErrorModal setShow={setShowError} err={error} />}
    </>
  );
};

export default Main;
