import back5 from "../../imgs/back5.png";
import SecondR from "../Register/stepsR/SecondR";
import Mid from "../Register/stepsR/mid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThirdR from "../Register/stepsR/ThirdR";
import FifthR from "../Register/stepsR/FifthR";
// import SecondI from "../Identification/stepsI/SecondI";
import ThirdI from "../Identification/stepsI/ThirdI";
import FourthI from "../Identification/stepsI/FourthI";
import Banking1 from "../Identification/stepsI/Banking";
// import FirstI from "../Identification/stepsI/FirstI";
import Identification from "../Identification/stepsI/identification";
// import pensionBook from "../PAccount/parts/PensionBook"
import PensionBook from "../pensionerVerification/addPensioner/newPensionBook";

const AddPensioner = () => {
  const verificationStart = useSelector((state) => state.verificationStart);
  const icurrent = useSelector((state) => state.icurrent);
  const dataUser = useSelector((state) => state.dataUser);
  const user = dataUser.data.user.roles[0];
  const router = useNavigate();
  const dispatch = useDispatch();
  console.log("test", icurrent);
  const Show = verificationStart
    ? [SecondR, Mid, ThirdR, FifthR, Identification, ThirdI, PensionBook]
    : [SecondR, Mid, ThirdR, FifthR, Identification, ThirdI, FourthI, Banking1];
  // const Show = [
  //   SecondR,
  //   Mid,
  //   ThirdR,
  //   FifthR,
  //   Identification,
  //   ThirdI,
  //   FourthI,
  //   Banking1,
  // ];
  const Dis = Show[icurrent];
  return (
    <div>
      <div
        className={`${
          verificationStart
            ? "mt-[3rem] "
            : "relative flex justify-between sm:px-[7rem] px-[2rem] sm:mt-[5rem] mt-[2rem] mb-[2rem]"
        }`}
      >
        <div
          className={` ${verificationStart ? "hidden" : "flex items-center"}`}
        >
          <div
            onClick={() => {
              dispatch({ type: "SET", num: 0 });
              dispatch({ type: "BRSET", num: 0 });
              dispatch({ type: "BISET", num: 0 });
              if (verificationStart === true) {
                router("/pensionerVerification/listing");
                return;
              }
              if (user === "employee") router("/EDashboard/Pensioner_HistoryP");
              else if (user === "superadmin")
                router("/superAdminDashboard/Pensioner_HistoryP");
              else if (user === "admin")
                router("/AdminDashboard/Pensioner_HistoryP");
            }}
            // className="cursor-pointer pt-[5rem] mr-5"
          >
            <img className="w-[1.7rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold ml-[1rem] sm:text-[1.8rem] text-[1.4rem]">
            Add Pensioner
          </h2>
        </div>
      </div>
      <div className=" mb-[5rem]">
        <Dis />
      </div>
    </div>
  );
};

export default AddPensioner;
