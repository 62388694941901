import React from "react";
import logo1 from "../../../imgs/logo12.png";
import { useNavigate } from "react-router-dom";
const VerificationSchduleDone = () => {
  const router = useNavigate();
  return (
    <div
      className={` w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl sm:w-[29rem] w-[24rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
        <div
          className="w-24 h-24"
          // onClick={() => setShow1(false)}
        >
          <img src={logo1} alt="" className="w-20 h-20"/>
        </div>
        <p className="text-center text-lg text-primary font-bold mb-2 mt-3">
          Verification Scheduled Successfully
        </p>
        <p className="text-center mt-[1rem] w-[85%]">
          Verification for the Hem Kund Distribution Centre has been
          successfully scheduled for the following dates:
          <b> 16th, 18th, and 19th of August.</b>
        </p>
        <p className="text-center mt-[1rem] mb-8 w-[85%] font-medium">
          This information will be shared with all employees and pensioners
          associated with this distribution centre through phone and email
        </p>
        <div className="w-full flex items-center justify-center gap-2 absolute bottom-0">
          <button
            type="button"
            onClick={() => router("/scheduleVerification/schedules")}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary w-full"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationSchduleDone;
