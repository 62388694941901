import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import thumb from "../../../../../imgs/Thumb.png";
import logo from "../../../../../imgs/mhsss_Accounting-logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { DeleteFilled, UploadOutlined } from "@ant-design/icons";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import axios from "axios";
const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dash = location.pathname;
  const router = useNavigate();
  const totaPayment = useSelector((state) => state.totalPayment);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [image, setImage] = useState(null);
  const [total, setTotal] = useState(0);
  const [cop, setCop] = useState(0);
  const [cur, setCur] = useState(0);
  const [details, setDetails] = useState([
    {
      currency: "",
      copies: "",
    },
  ]);
  const selectedTransactionsVouchers = useSelector(
    (state) => state.selectedTransactionsVouchers
  );
  const selectedPaymentMethod = useSelector(
    (state) => state.selectedPaymentMethod
  );
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const Pay = () => {
    const body = {
      merchtransactionId: selectedTransactionsVouchers?.[0]?.merchtransactionId,
      paymentMethod: selectedPaymentMethod,
      paymentId: "89998998",
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transaction-payments`,

        body,

        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "AFTER_PAYMENT_DATA", num: data.data });
        setShow(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowError(true);
      });
  };

  const handleAddDetail = () => {
    setTotal(total + cur * cop);
    setDetails([...details, { currency: "", copies: "" }]);
    setCur(0);
    setCop(0);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (dash === "/MHSSS/ewalletcashPay/verify") {
      setSubTitle("( with Amount, Date and Signature)");
      setTitle("Upload cash receipt");
    } else if (dash === "/MHSSS/cashPay") {
      setSubTitle("( with Amount, Date and Signature)");
      setTitle("Step 2 : payment verification");
    }
  });

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDeleteImage = () => {
    setImage(null);
  };
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/MHSSS/paymentReceipt");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const handleDetailChange = (index, field, value) => {
    if (field === "currency") setCur(value);
    else if (field === "copies") setCop(value);
    const updatedDetails = [...details];
    updatedDetails[index][field] = value;
    setDetails(updatedDetails);
    console.log(updatedDetails);
    console.log(total);
  };
  const handleRemoveDetail = (indexToRemove) => {
    setTotal(
      total - details[indexToRemove].currency * details[indexToRemove].copies
    );
    setDetails(details.filter((detail, index) => index !== indexToRemove));
  };

  return (
    <div className="flex flex-col w-full overflow-hidden min-h-[82vh] p-4 relative rounded-md bg-[#FFFFFFE5]">
      <div className="p-2 mt-2 mb-6">
        <h1 className="font-semibold">
          Total Amount to be Paid{" "}
          <span className="ml-4 font-bold text-primary text-[16px]">{`$${totaPayment}`}</span>
        </h1>
      </div>
      <div className="flex w-[70%] flex-col rounded-md mb-6 bg-[#FFFFFFE5]">
        {details.map((detail, index) => (
          <div
            key={index}
            className="flex gap-x-6 p-4 w-full border-t  cursor-pointer "
          >
            <div className="flex items-center gap-x-4">
              <h1 className="font-semibold">Select currency</h1>
              <select
                value={detail.currency}
                onChange={(e) =>
                  handleDetailChange(index, "currency", e.target.value)
                }
                className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-gray-200 "
              >
                <option value="" selected hidden>
                  Currency
                </option>
                <option value="50">$50</option>
                <option value="100">$100</option>
                <option value="500">$500</option>
                <option value="1000">$1000</option>
                <option value="5000">$5000</option>
              </select>
            </div>
            <div className="flex items-center gap-x-4">
              <h1 className="font-semibold ">Number of Copies</h1>{" "}
              <input
                placeholder="Copies"
                value={detail.copies}
                onChange={(e) =>
                  handleDetailChange(index, "copies", e.target.value)
                }
                className="w-[6rem] h-[2rem] pl-4 pr-4 rounded-lg bg-[#D9EFED] p-2 flex items-center justify-center text-center"
              />
            </div>
            {index === details.length - 1 && (
              <div
                className="flex items-center w-[3rem] justify-center ml-12 font-semibold text-primary rounded-lg cursor-pointer"
                onClick={handleAddDetail}
              >
                Add
              </div>
            )}
            {index < details.length - 1 && (
              <div
                className="flex items-center w-[3rem] justify-center ml-12 font-semibold text-primary rounded-lg cursor-pointer"
                onClick={() => handleRemoveDetail(index)}
              >
                <DeleteFilled />
              </div>
            )}
          </div>
        ))}
        <div className="w-full h-[3rem] bg-[#F6F4F4] rounded-b-md flex p-4 items-center justify-end">
          <h1 className="text-gray-400 font-semibold text-sm ">
            Calculated Amount
            <span className="font-semibold ml-4 text-black">${total}</span>
          </h1>
        </div>
      </div>
      <h1 className="font-bold p-2 mb-1">
        {title}
        <span className="font-normal ">{subTitle}</span>
      </h1>
      <div className="w-full bg-[#D9EFED] flex-col rounded-lg gap-6 flex p-4">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className={`border-dashed border-2 border-primary flex items-center justify-center rounded-lg text-center w-[18rem] h-[13rem] relative`}
        >
          {image ? (
            <>
              <img src={image} alt="Uploaded" className="w-full h-full" />
              <button
                className="absolute bottom-1 right-1"
                onClick={handleDeleteImage}
              >
                <DeleteFilled className="text-2xl text-primary cursor-pointer " />
              </button>
            </>
          ) : (
            <div className="w-[80%] flex flex-col gap-1 flex items-center justify-center">
              <UploadOutlined className="text-5xl text-primary cursor-pointer " />
              <p>Drag & Drop Your File(s) here to upload </p>
              <p>Or</p>
              <label className="mt-4 w-[80%] cursor-pointer inline-block bg-primary text-white py-2 px-4 rounded-lg">
                Browse Computer
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileSelect}
                />
              </label>
            </div>
          )}
        </div>
        <div className="w-[18rem] flex items-center justify-center">
          <button
            onClick={() => {
              setShowConfirm(true);
            }}
            className={`w-[12rem] p-1 border-2 font-bold text-white rounded-lg  ${
              !image
                ? "bg-gray-300 cursor-not-allowed border-gray-300"
                : "border-primary bg-primary"
            }`}
            disabled={!image}
          >
            Payment Completed
          </button>
        </div>
      </div>

      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${totaPayment}</span> has been
            transferred to the Merchant's account
          </p>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${totaPayment}</span> to merchant?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                Pay();
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showError && <ErrorModal setShow={setShowError} err={err} />}
    </div>
  );
};

export default Main;
