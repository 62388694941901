import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

const index = ({ year, setYear, data }) => {
  const Div = ({ title, content }) => {
    return (
      <div>
        <div className="flex flex-row justify-between">
          <div className="text-md font-medium leading-6 text-gray-600">
            {title}
          </div>
          <div
            className={`text-md leading-6 text-gray-700 capitalize ${
              title === "Status"
                ? content === "Redeemed"
                  ? "text-primary font-semibold"
                  : "text-red-600 font-semibold"
                : ""
            }`}
          >
            {title === "Amount"
              ? `$${content}`
              : title === "Program"
              ? content === "senior-citizen-Pension"
                ? "Senior Citizen Pension"
                : content
              : content}
          </div>
        </div>
        {/* <hr className="py-2 text-gray-50" /> */}
      </div>
    );
  };
  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };

  return (
    <div>
      <div className="py-4">
        <h1 className="text-[1rem] font-semibold mb-4  ">Filter By year</h1>
        <DatePicker
          picker="year"
          // value={year}
          onChange={(date, dateString) => {
            if (setYear) {
              setYear(dateString);
            }
          }}
        />
      </div>
      {data?.length === 0 && (
        <h1 className="text-center text-lg text-gray-500 p-10">
          No Payment Made in {year}{" "}
        </h1>
      )}

      <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">
        {data?.map((data, index) => (
          <div
            key={index}
            className="relative flex flex-col border border-gray-100  rounded-md  px-3 sm:px-5  py-4  mb-4 shadow-sm gap-1 border-b-2 border-gray-100"
          >
            <div className="flex justify-between">
              <h1 className="text-primary font-semibold text-lg mb-2">
                ${data?.amountPaid}
              </h1>
              <p className="text-gray-500">
                {moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
               
              </p>
            </div>

            <Div title="Transaction ID" content={data?.transactionId} />

            <Div
              title="Voucher Month"
              content={getMonthName(data?.coupon?.month)}
            />

            <Div title={"Payed By"} content={data?.paymentMethod} />
            <Div title="Program" content={data?.pensionprogram?.name} />
            <Div
              title="Booklet Number"
              content={data?.pensionbook?.bookletNo}
            />
            <Div title="Status" content={data?.coupon?.status} />
          </div>
        ))}
      </div>
      {/* {data?.map((item, index) => (
        <div
          className="flex justify-between items-center border-b pb-4"
          key={index}
        >
          <div className="flex items-center">
            <div className="bg-green-100 h-10 w-10 flex items-center justify-center rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div className="ml-4">
              <p className="text-lg font-semibold text-gray-800">
                ${item?.amountPaid}
              </p>
              <p className="text-sm font-medium text-gray-600">
                {moment(item?.createdAt)?.format("Do MMMM, YYYY")}
              </p>
              <p>
                Voucher Month : <span>{getMonthName(item?.coupon?.month)}</span>
              </p>
              <p>
                Transaction ID :{" "}
                <span>{getMonthName(item?.transactionId)}</span>
              </p>
              <p>
                Transaction ID :{" "}
                <span>{getMonthName(item?.transactionId)}</span>
              </p>
            </div>
          </div>
          <div className="flex items-center">
           
            <div className="ml-4">
              <p className="text-lg font-semibold text-gray-800">
                {moment(data?.createdAt).format("DD MMM, YYYY")}{" "}
                {moment(data?.createdAt).format("hh:mm A")}
              </p>
              <p className="text-sm font-medium text-gray-600">Time</p>
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
};

export default index;
