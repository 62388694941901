import Eregistration from "./Parts/Eregistration";
import Eidentification from "./Parts/Eidentification";
import { useState, useEffect } from "react";
import back5 from "../../imgs/back5.png";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Eaccount = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const employeeData = location?.state && location?.state?.employeeData;
  const profileData = useSelector((state) => state.profileData);
  const [wow, setWow] = useState(0);
  const dataUser = useSelector((state) => state.dataUser);
  const Show = [Eregistration, Eidentification];
  const reset = useSelector((state) => state.reset);
  const Dis = Show[wow];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/my-profile/`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch(() => {
      });
  }, [reset]);
  return (
    <div className="pt-[5rem] pb-[5rem] md:pl-[12rem] md:pr-[12rem] pl-4 pr-4">
      {profileData && (
        <div>
          <div className="flex items-center mb-[3rem] ">
            <div
              onClick={() => {
                employeeData?   router("/AdminDashboard") : dataUser?.data?.user.roles[0] === "admin" ? router("/adminDashboard") : dataUser.data.user.roles[0]==="superadmin"? router("/superAdminDashboard"): dataUser.data.user.roles[0]==="gro-admin"? router("/GroAdminDash"): dataUser.data.user.roles[0]==="gro-employee"? router("/GRO"):
                router("/EDashboard")}}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.4rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">{employeeData ? "Employee Account" : (dataUser.data.user.roles[0] === "admin" ? "Admin Account" : dataUser.data.user.roles[0]==="superadmin"? "Super Admin Account": dataUser.data.user.roles[0]==="gro-employee"? "Gro Employee Account": dataUser.data.user.roles[0]==="gro-admin"? "GRO Admin Account":"Employee Account")}</h2>
            </div>
            {/* <div className="font-bold sm:text-[1.8rem] text-[1.4rem]">
            {employeeData ? "Employee Account" : (dataUser.data.user.roles[0] === "admin" ? "Admin Account" : dataUser.data.user.roles[0]==="superadmin"? "Super Admin Account": dataUser.data.user.roles[0]==="gro-employee"? "Gro Employee Account": dataUser.data.user.roles[0]==="gro-admin"? "GRO Admin Account":"Employee Account")}

            </div> */}
          </div>
          <div className="lg:mb-[4rem] flex mb-[2rem]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                  wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${
                  wow === 1 ? "font-semibold" : ""
                } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                  wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                }  `}
              ></div>
            </div>
          </div>
          <div>
            <Dis />
          </div>
        </div>
      )}
    </div>
  );
};

export default Eaccount;
