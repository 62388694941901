import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {PlusCircleFilled} from "@ant-design/icons";
import logo1 from "../../../../../../imgs/logo12.png";
import dayjs from "dayjs";
import { DatePicker, Select } from "antd";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import { useLocation } from "react-router-dom";
import moment from "moment";
let data;
let govData = [];
let passData = [];
let oneData = [];
let drivData = [];
let file;
let w1;
let w2;
let w3;
let w4;
const Part1 = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const universalEmpId = useSelector((state) => state.universalEmpId);
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const [govId, setGovId] = useState("");
  const [govExp, setGovExp] = useState(null);
  const [govCountry, setGovCountry] = useState("");
  const [govFront, setGovFront] = useState("");
  const [govBack, setGovBack] = useState("");
  const [passId, setPassId] = useState("");
  const [passExp, setPassExp] = useState(null);
  const [passCountry, setPassCountry] = useState("");
  const options1 = useMemo(() => countryList().getData(), []);
  const [passFront, setPassFront] = useState("");
  const [passBack, setPassBack] = useState("");
  const [oneImg, setOneImg] = useState("");
  const [oneId, setOneId] = useState("");
  const [test, setTest] = useState(true);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoadingx, setIsLoadingx] = useState(false);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [display2, setDisplay2] = useState("");
  const [display3, setDisplay3] = useState("");
  const [display4, setDisplay4] = useState("");
  const [display5, setDisplay5] = useState("");
  const [display6, setDisplay6] = useState("");
  const [oneExp, setOneExp] = useState(null);
  const [oneIssued, setOneIssued] = useState(null);
  const [oneCountry, setOneCountry] = useState(null);
  const [allDis, setAllDis] = useState(false);
  const [drivId, setDrivId] = useState("");
  const [drivExp, setDrivExp] = useState(null);
  const [drivCountry, setDrivCountry] = useState(null);
  const [drivFront, setDrivFront] = useState("");
  const [drivBack, setDrivBack] = useState("");
  const [option, setOption] = useState("Government ID");

  let optionI;

  if (dash === "/Dashboard/PensionerAccount" || dash === "/superAdminDashboard/PensionerAccount" || dash === "/AdminDashboard/PensionerAccount" || dash==="/EDashboard/PensionerAccount") {
    optionI = [
      {
        label: "Government ID",
        value: "Government ID",
      },
      {
        label: "Passport",
        value: "Passport",
      },
      {
        label: "Driving License",
        value: "Driving License",
      },
      {
        label: "Birth certificate",
        value: "Birth certificate",
      },
      {
        label: "One Card",
        value: "One Card",
      },

    ]
  }
  else {
    optionI = [
      {
        label: "Government ID",
        value: "Government ID",
      },
      {
        label: "Passport",
        value: "Passport",
      },
      {
        label: "Driving License",
        value: "Driving License",
      },
      {
        label: "One Card",
        value: "One Card",
      },

    ]
  }

  // useEffect(() => {
  //   if (benDone !== "Done") {
  //     setStatus(profileData?.user?.status);
  //     setRoles(profileData?.user?.roles[0]);

  //   }
  // }, []);
  useEffect(() => {
    if (profileData.verificationDocs) {
        data = profileData.verificationDocs;
      govData = data.filter((item) => {
        return item.type === "government id";
      });
      if (govData.length !== 0) {
        setGovId(govData[0].docIDValue);
        setGovExp(govData[0].expiryDate);

        if (govData[0].countryIssued.length > 2) {
          setGovCountry(govData[0].countryIssued);
          w2 = govData[0].countryIssued;
          w1 = countryList().getValue(govData[0].countryIssued);
        } else {
          setGovCountry(countryList().getLabel(govData[0].countryIssued));
          w1 = govData[0].countryIssued;
          w2 = countryList().getLabel(govData[0].countryIssued);
        }
      }
      passData = data.filter((item) => {
        return item.type === "passport";
      });

      if (passData.length !== 0) {
        setPassId(passData[0].docIDValue);
        setPassExp(passData[0].expiryDate);

        if (passData[0].countryIssued.length > 2) {
          setPassCountry(passData[0].countryIssued);
          w3 = countryList().getValue(passData[0].countryIssued);
          w4 = passData[0].countryIssued;
        } else {
          setPassCountry(countryList().getLabel(passData[0].countryIssued));
          w4 = countryList().getLabel(passData[0].countryIssued);
          w3 = passData[0].countryIssued;
        }
      }
      oneData = data.filter((item) => {
        return item.type === "one card";
      });
      if (oneData.length !== 0) {
        setOneId(oneData[0].docIDValue);
        setOneCountry(oneData[0].countryIssued);
        setOneIssued(oneData[0].issuedDate);
        setOneExp(oneData[0].expiryDate);
      }

      if (govData.length !== 0) {
        if (govData[0].docimages.length !== 0)
         {
          let wow11;
            wow11 = govData[0].docimages.filter((item) => {
              return item.type === "front image";
            });
          const body1 = [wow11[0].imageUrl];
          setGovFront(wow11[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {


              setDisplay(data.data[0].url);
            })
            .catch(() => {

            });
          if (govData[0].docimages.length > 1)
          {
            let wow11;
              wow11 = govData[0].docimages.filter((item) => {
                return item.type === "back image";
              });
            const body2 = [wow11[0].imageUrl];
            setGovBack(wow11[0].imageUrl);

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
                {
                  fileNames: body2,
                },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {

                setDisplay1(data.data[0].url);
              })
              .catch(() => {

              });
          }
        }
      }
      if (passData.length !== 0) {
        if (passData[0].docimages.length !== 0)
        {
          let wow11;
            wow11 = passData[0].docimages.filter((item) => {
              return item.type === "front image";
            });
          const body1 = [wow11[0].imageUrl];
          setPassFront(wow11[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {


              setDisplay2(data.data[0].url);
            })
            .catch(() => {

            });
          if (passData[0].docimages.length > 1) {
            let wow11;
              wow11 = passData[0].docimages.filter((item) => {
                return item.type === "back image";
              });
            const body2 = [wow11[0].imageUrl];
            setPassBack(wow11[0].imageUrl);
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
                {
                  fileNames: body2,
                },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {


                setDisplay3(data.data[0].url);
              })
              .catch(() => {

              });
          }
        }
      }

      if (oneData.length !== 0) {
        if (oneData[0].docimages.length !== 0) {
          let body3;
            body3 = [oneData[0].docimages[0].imageUrl];
            setOneImg(oneData[0].docimages[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body3,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {


              setDisplay4(data.data[0].url);
            })
            .catch(() => {

            });
        }
      }

      drivData = data.filter((item) => {
        return item.type === "driver license";
      });
      if (drivData.length !== 0) {
        setDrivId(drivData[0].docIDValue);
        setDrivExp(drivData[0].expiryDate);

        if (drivData[0].countryIssued.length > 2) {
          setDrivCountry(drivData[0].countryIssued);
          w1 = countryList().getValue(drivData[0].countryIssued);
          w2 = drivData[0].countryIssued;
        } else {
          setDrivCountry(countryList().getLabel(drivData[0].countryIssued));
          w2 = countryList().getLabel(drivData[0].countryIssued);
          w1 = drivData[0].countryIssued;
        }
      }
      if (drivData.length !== 0) {
        if (drivData[0].docimages.length !== 0){
          let wow11;
            wow11 = drivData[0].docimages.filter((item) => {
              return item.type === "front image";
            });
          const body1 = [wow11[0].imageUrl];
          setDrivFront(wow11[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              

              setDisplay5(data.data[0].url);
            })
            .catch(() => {
              
            });
          if (drivData[0].docimages.length > 1) {
            let wow11;
              wow11 = drivData[0].docimages.filter((item) => {
                return item.type === "back image";
              });
            if (wow11.length !== 0) {
              const body2 = [wow11[0].imageUrl];
              setDrivBack(wow11[0].imageUrl);
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
                  {
                    fileNames: body2,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  

                  setDisplay1(data.data[0].url);
                })
                .catch(() => {
                  
                });
            }
          }
        }
      }
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [profileData]);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [govData, passData, w1, w2, w3, w4,drivData]);

  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
        load(false);
      })
      .catch(() => {

      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateHandler = () => {
    setIsLoadingx(true);
    const body = [];

    if (govId || govCountry || govExp || govFront !== "" || govBack !== "") {
      const govData = {
        type: "government id",
        docIDName: "govt id",
        docIDValue: `${govId}`,
        countryIssued: `${govCountry}`,
        docImages: [],
      };
      if (govFront !== "") {
        govData.docImages[0] = {
          type: "front image",
          imageUrl: `${govFront}`,
        };
      }
      if (govBack !== "") {
        govData.docImages[1] = {
          type: "back image",
          imageUrl: `${govBack}`,
        };
      }
      body.push(govData);
    }

    if (passId || passCountry || passExp || passFront !== "" || passBack !== "") {
      const passData = {
        type: "passport",
        docIDName: "passport no",
        docIDValue: `${passId}`,
        countryIssued: `${passCountry}`,
        expiryDate: `${passExp}`,
        docImages: [],
      };
      if (passFront !== "") {
        passData.docImages[0] = {
          type: "front image",
          imageUrl: `${passFront}`,
        };
      }
      if (passBack !== "") {
        passData.docImages[1] = {
          type: "back image",
          imageUrl: `${passBack}`,
        };
      }
      body.push(passData);
    }
    if (oneImg !== "" || oneId) {
      const oneData = {
        type: "one card",
        docIDName: "one card",
        docIDValue: `${oneId}`,
        countryIssued: `${oneCountry}`,
        expiryDate: `${oneExp}`,
        issuedDate: `${oneIssued}`,
        docImages: [],
      };
      if (oneImg !== "") {
        oneData.docImages[0] = {
          type: "front image",
          imageUrl: `${oneImg}`,
        };
      }
      body.push(oneData);
    }

    if (drivId || drivCountry || drivExp || drivFront !== "" || drivBack !== "") {
      const drivData = {
        type: "driver license",
        docIDName: "drivers license no",
        docIDValue: `${drivId}`,
        countryIssued: `${drivCountry}`,
        expiryDate: `${drivExp}`,
        docImages: [],
      };
      if (drivFront !== "") {
        drivData.docImages[0] = {
          type: "front image",
          imageUrl: `${drivFront}`,
        };
      }
      if (drivBack !== "") {
        drivData.docImages[1] = {
          type: "back image",
          imageUrl: `${drivBack}`,
        };
      }
      body.push(drivData);
    }
    let wow
    if (dash === "/GroAdminDash/Employees/Profile/EditEmployee" || dash==="/superAdminDashboard/GroempProfile/EditEmployee")
       wow=`${process.env.REACT_APP_BASE_URL}/v1/verification-docs/gro-employee?userId=${universalEmpId}`
      else if(dash === "/superAdminDashboard/GroAdminProfile/EditAdmin")
      wow=`${process.env.REACT_APP_BASE_URL}/v1/verification-docs/gro-admin?userId=${universalEmpId}`
      axios
        .put(
          wow,
          {
            verificationDocs: body,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {

          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {

          if (
            err.response.data.message === '"expiryDate" must be a valid date'
          ) {
            setErr("Please verify if expiration date is available on card");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
          setIsLoadingx(false);
        });
  };

  const handleChange = (setState, check) => (event) => {

    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [w1, w2, w3, w4, govExp, display2, display3, display4,display5,display6]);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  useEffect(() => {
    if (benDone !== "Done") {
      if (
        dash === "/EDashboard/PensionerAccount" &&
        profileData.user.isDisabled
      ) {
        setAllDis(true);
      } else if ((profileData.user.status === "pending" || dataUser?.data?.user?.status === "verified"|| dataUser?.data?.user?.status === "re-submitted" || dataUser?.data?.user?.status === "deferred" || dataUser?.data?.user?.status === "in-review") &&
        dash === "/Dashboard/PensionerAccount"
      ) {
        setAllDis(true);
      }
    }
  }, []);
  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };

  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };

  return (
    <div>
       <div className="w-full">
        <label
          className="text-[1.2rem] font-semibold mb-2"
        >
          Select Document:
        </label>
        <Select
          placeholder="Document Type"
          optionFilterProp="children"
          defaultValue={optionI[0].value}
          className="wow9"
          style={{ marginBottom: "1.2rem", marginTop: "5px" }}
          onChange={(val) => setOption(val)}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          size="middle"
          options={optionI}
        />
      </div>
      {option === "Government ID" && (  <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">
          Government ID
        </h2>
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Government ID #:
        </h2>
        <input
          type="text"
          name=""
          
          onChange={handleChange(setGovId)}
          value={govId}
          placeholder="Government ID #"
          className={` ${allDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
       

        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Country Issued:
        </h2>
        { }

        {/* {w1 && w2 && ( */}
        <Select
          placeholder="Country Issued"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${govCountry === "" ? "none" : ""}`,
          }}
          defaultValue={{
            value: `${w1}`,
            label: `${w2}`,
          }}
          value={govCountry}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          options={options1}
          onChange={handleChange(setGovCountry, "tg")}
        />
        {/* )} */}

        <Select
          placeholder="Country Issued"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${govCountry === "" ? "" : "none"}`,
          }}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          options={options1}
          onChange={handleChange(setGovCountry, "tg")}
        />

          <div className="grid grid-cols-2 gap-4">
          <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input"
            className={`${isLoading || isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
              } ursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay, setGovFront, setIsLoading)}
            />
          </label>
          <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display} alt="" />
           
          </div>
         
        </div>
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Image Back:</h2>
          <label
            htmlFor="special-input1"
            className={`${isLoading || isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
              } ursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Back</p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input1"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay1, setGovBack, setIsLoading1)}
            />
          </label>
          <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
            { }
            <img className="w-[25rem] h-[13rem]" src={display1} alt="" />
          
          </div>
        </div>
          </div>
        
      </div>
)}
      {option === "Passport" && ( <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">Passport</h2>
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Passport #:</h2>
        <input
          type="text"
          name=""
          
          placeholder="Passport #"
          onChange={handleChange(setPassId)}
          value={passId}
          className={` ${allDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Expiration Date:
        </h2>
        {passExp !== null && (
          <DatePicker
            placeholder="Expiration Date"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
            }}
            defaultValue={dayjs(`${passExp}`, "YYYY-MM-DD")}
            onChange={handleChange(setPassExp, "tgf")}
            disabledDate={disabledDate}
            className="date-class"
          />
        )}
        <DatePicker
          placeholder="Expiration Date"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${passExp === null ? "" : "none"}`,
          }}
          onChange={handleChange(setPassExp, "tgf")}
          disabledDate={disabledDate}
          className="date-class"
        />
        {/* <input
          type="text"
          name=""
          
          value={passExp}
          placeholder="Government ID #"
          className={` ${
            profileData.user.status === "pending"
              ? "pointer-events-none"
              : "pointer-events-auto"
          } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        /> */}
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Country Issued
        </h2>
        { }
        {/* {w3 && w4 && ( */}
          <Select
            placeholder="Country Issued"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
              display: `${passCountry === "" ? "none" : ""}`,
            }}
            defaultValue={{
              value: `${w3}`,
              label: `${w4}`,
            }}
            value={passCountry}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={options1}
            onChange={handleChange(setPassCountry, "tg")}
          />
        {/* )} */}
        {/* { } */}
        <Select
          placeholder="Country Issued"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${passCountry === "" ? "" : "none"}`,
          }}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          options={options1}
          onChange={handleChange(setPassCountry, "tg")}
        />
        <div className="grid grid-cols-2 gap-4">
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input2"
            className={`${isLoading || isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
              } ursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input2"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay2, setPassFront, setIsLoading2)}
            />
          </label>
          <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display2} alt="" />
            {/* <div
              className={`absolute ${
                isLoading2 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div> */}
            {/* <div
              className="absolute bottom-0 right-0 bg-white cursor-pointer"
              onClick={() => setDisplay2("")}
            >
              <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
            </div> */}
          </div>
          {/* <div>
            <img className="w-[25rem] h-[13rem] mb-4" src={passFront} />
          </div> */}
        </div>
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image 2nd of the Signature page:
          </h2>
          <label
            htmlFor="special-input5"
            className={`${isLoading || isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
              } ursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">
                Image 2nd of the Signature page
              </p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input5"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay3, setPassBack, setIsLoading3)}
            />
          </label>
          <div className={`relative mb-4 ${display3 ? "" : "hidden"}`}>
          
            <img className="w-[25rem] h-[13rem]" src={display3} alt="" />
           
          </div>
        
        </div>
          </div>
       
      </div>)}
      {option === "One Card" && ( <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-4">
          One Card
        </h2>
        <div className="">
            <div>
              <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
                One Card #:
              </h2>
              <input
                type="text"
                name=""
                
                placeholder="One Card #"
                value={oneId}
                onChange={(e) => {
                  setOneId(e.target.value);
                }}
                className={` ${
                  allDis ? "pointer-events-none" : "pointer-events-auto"
                } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              />
            </div>
            <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
              Country Issued
            </h2>
            {}
            {/* {w3 && w4 && ( */}
            <Select
              placeholder="Country Issued"
              optionFilterProp="children"
              className="wow"
              style={{
                marginBottom: "1.2rem",
                pointerEvents: `${allDis ? "none" : "auto"}`,
              }}
              defaultValue={{
                value: `${w3}`,
                label: `${w4}`,
              }}
              value={oneCountry}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              options={options1}
              onChange={handleChange(setOneCountry, "tg")}
            />
          </div>
          <div className="flex w-full sm:flex-row flex-col items-center justify-between ">
            <div className="sm:w-[49%] w-full">
              <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
                Issued Date:
              </h2>
              <DatePicker
                placeholder="Issued Date"
                style={{
                  marginBottom: "1.2rem",
                  pointerEvents: `${allDis ? "none" : "auto"}`,
                }}
                defaultValue={oneIssued?dayjs(`${oneIssued}`, "YYYY-MM-DD"):null}
                disabledDate={disabledDate1}
                onChange={handleChange(setOneIssued, "tgf")}
                className="date-class"
              />
            </div>
            <div className="sm:w-[49%] w-full">
              <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
                Expiration Date:
              </h2>
              <DatePicker
                placeholder="Expiration Date"
                style={{
                  marginBottom: "1.2rem",
                  pointerEvents: `${allDis ? "none" : "auto"}`,
                }}
                defaultValue={oneExp?dayjs(`${oneExp}`, "YYYY-MM-DD"):null}
                disabledDate={disabledDate}
                onChange={handleChange(setOneExp, "tgf")}
                className="date-class"
              />
            </div>
          </div>
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input7"
            className={`${isLoading || isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
              } ursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input7"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay4, setOneImg, setIsLoading3)}
            />
          </label>
          <div className={`relative mb-4 ${display4 ? "" : "hidden"}`}>
            
            <img className="w-[25rem] h-[13rem]" src={display4} alt="" />
           
          </div>
         
        </div>
      </div>)}
      {option === "Driving License" && ( <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">
          Driver's License
        </h2>
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Driver's License #:
        </h2>
        <input
          type="text"
          name=""
          
          placeholder="Driver's License #"
          onChange={handleChange(setDrivId)}
          value={drivId}
          className={`${
            allDis ? "pointer-events-none" : "pointer-events-auto"
          } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Expiration Date:
        </h2>
        {drivExp !== null && (
          <DatePicker
            placeholder="Expiration Date"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
            }}
            defaultValue={dayjs(`${drivExp}`, "YYYY-MM-DD")}
            // disabledDate={disabledDate}
            onChange={handleChange(setDrivExp, "tgf")}
            disabledDate={disabledDate}
            className="date-class"
          />
        )}
        <DatePicker
          placeholder="Expiration Date"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${drivExp === null ? "" : "none"}`,
          }}
          onChange={handleChange(setDrivExp, "tgf")}
          disabledDate={disabledDate}
          className="date-class"
        />
       
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
          Country Issued:
        </h2>
        {}
        {w1 && w2 && (
          <Select
            placeholder="Country Issued"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
              display: `${drivCountry === null ? "none" : ""}`,
            }}
            defaultValue={{
              value: `${w1}`,
              label: `${w2}`,
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={options1}
            onChange={handleChange(setDrivCountry, "tg")}
          />
        )}
        <Select
          placeholder="Country Issued"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            pointerEvents: `${allDis ? "none" : "auto"}`,
            display: `${drivCountry !== null ? "none" : ""}`,
          }}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          options={options1}
          onChange={handleChange(setDrivCountry, "tg")}
        />
        <div className="grid grid-cols-2 gap-4">
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input1"
            className={`${
              isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input1"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay5, setDrivFront, setIsLoading1)}
            />
          </label>
          <div className={`relative mb-4 ${display5 ? "" : "hidden"}`}>
            {}
            <img className="w-[25rem] h-[13rem]" src={display5} alt="" />
            
          </div>
          
        </div>
        <div>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>Image Back:</h2>
          <label
            htmlFor="special-input2"
            className={`${
              isLoading1 || isLoading2 || isLoading3 || allDis
                ? "pointer-events-none"
                : ""
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Back</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input2"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay6, setDrivBack, setIsLoading2)}
            />
          </label>
          <div className={`relative mb-4 ${display6 ? "" : "hidden"}`}>
           
            <img className="w-[25rem] h-[13rem]" src={display6} alt="" />
           
          </div>
        
        </div>
        </div>
        
      </div>)}


    
     

     

      <div
        className={`${show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
      <div
        className={`${dataUser.data.user.roles[0] === "employee" &&
            dataUser.data.user.status === "pending"
            ? "hidden"
            : ""
          }`}
      >
        <button
          onClick={updateHandler}
          className={`${allDis ? "hidden" : ""
            } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          {/* <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
          <span>Save</span>
          <div
            className={`w-[100%] h-[100%] ${isLoadingx ? "" : "hidden"
              } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </div>
    </div>
  );
};

export default Part1;