import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import done from "../../../imgs/done.svg";

const SuccessModal = ({ setShow, value, title,route }) => {
  const sapmoney = useSelector((state) => state.sapmoney);
  const router = useNavigate();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl sm:w-[25rem] w-[22rem] flex items-center p-6 bg-white flex-col">
        <img src={done} alt="done" className="w-20 h-20" />
        <p className="text-center sm:w-[80%] w-full mb-1 font-semibold text-gray-800 text-lg">
          {title}
        </p>

        <p className="text-center  mb-5 mt-1 text-[0.85rem] font-semibold sm:px-8 px-4 text-gray-500">
          {value}
        </p>
        <button
          onClick={() => {
            setShow(false);
            router(route);
          }}
          className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
