import React from 'react'
import Vouchers from "../components/MHSSS Accounting/outstanding/PersonTransaction/Vouchers/vouchers"

const paymentM = () => {
  return (
    <div>
     <Vouchers/>
    </div>
  )
}

export default paymentM