import React, { useState, useEffect } from "react";
import { Select } from "antd";
import thumb from "../../../../imgs/Thumb.png";
import logo from "../../../../imgs/qpLogo.svg";
import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import logoSCP from "../../../../imgs/logomhs.png";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import axios from "axios";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import MainForm from "./mainForm";
import { useParams } from "react-router-dom";

const Main = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${id}/for-child`
      )
      .then((data) => {
        setData(data.data);
        setShowForm(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  }, [refetch]);

  // useEffect(() => {
  //   setShowForm(false);
  // }, [refetch]);

  console.log(data);

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>

      {
        showForm ? (   <div
          className={`flex justify-center items-center  bg-[#D9EFED] p-10 ${
            showForm ? "h-screen" : "h-auto"
          } `}
        >
          <div
            className={`bg-white rounded-lg relative ${showForm ? "" : "hidden"}`}
          >
            <div className="relative rounded-xl sm:w-[25rem] w-[22rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col mb-6">
              <div className="w-[8rem] h-[8rem] p-2 flex items-center justify-center rounded-full">
                {/* Replace with your logo */}
                <img src={logo} alt="" className="w-full h-full" />
              </div>
              <p className="text-center w-[80%] mb-1 font-semibold">
                {capitalize(data?.pensionerUser?.firstName)}{" "}
                {data?.pensionerUser?.middleName && capitalize(data?.pensionerUser?.middleName)}{" "}
                {capitalize(data?.pensionerUser?.lastName)} is sending ${data?.amountPaid} to
                you.
              </p>
              <p className="">To collect that amount from Merchants Store,</p>
              <p className="mb-4">you have to complete basic information here.</p>
              <div className="flex gap-4 w-[70%] items-center justify-center">
                <button
                  onClick={() => {
                    setShowForm(false);
                    setOpenForm(true);
                  }}
                  className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary cursor-pointer"
                >
                  Open Form
                </button>
              </div>
            </div>
            <div className="absolute bg-[#1BA397] bottom-0 text-white text-center w-full rounded-md p-2 mt-3">
              This message is coming from SAP Money
            </div>
          </div>
         
        </div>):(
          <div className=" bg-[#D9EFED] h-auto sm:p-3">
          <MainForm
            data={data}
            refetch={refetch}
            setRefetch={setRefetch}
            setShowForm={setShowForm}
          />
        </div>
        )
      }
   
      {/* {openForm && (
          <div className=" bg-[#D9EFED] h-auto p-3">
            <MainForm
              data={data}
              refetch={refetch}
              setRefetch={setRefetch}
              setShowForm={setShowForm}
            />
          </div>
        )} */}
      {/* Error modal component */}
      {show && <ErrorModal setShow={setShow} error={err} />}
    </div>
  );
};

export default Main;
