import Nav from "../components/Nav/nav";
import Pol from "../components/Pol/pol";
import Whatsapp from "../Whatsapp/whatsapp";

const PolPage = () => {
  return (
    <div>
      <Nav />
      <Pol />
      <Whatsapp/>
    </div>
  );
};

export default PolPage;
