import React, { useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
const pensioners = [
  {
    id: 1,
    name: "John Doe",
    status: "Verified",
    gid: 123456,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-10 14:00",
  },
  {
    id: 2,
    name: "Jane Smith",
    status: "Pending",
    gid: 654321,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-11 09:30",
  },
  {
    id: 3,
    name: "Alice Johnson",
    status: "Verified",
    gid: 789012,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-12 11:45",
  },
  {
    id: 4,
    name: "Bob Brown",
    status: "Rejected",
    gid: 234567,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-13 16:00",
  },
  {
    id: 5,
    name: "Charlie Davis",
    status: "Pending",
    gid: 345678,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-14 08:15",
  },
  {
    id: 6,
    name: "Diana Evans",
    status: "Verified",
    gid: 456789,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-15 13:30",
  },
  {
    id: 7,
    name: "Edward Fox",
    status: "Rejected",
    gid: 567890,
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    dateTime: "2024-09-16 17:45",
  },
];

const Pensioner = () => {
  const PensionerCard = ({ pensioner }) => {
    const getStatusColor = (status) => {
      switch (status) {
        case "Rejected":
          return "text-red-500";
        case "Pending":
          return "text-gray-500";
        case "Verified":
          return "text-primary";
        default:
          return "text-black";
      }
    };

    return (
      <div className="bg-white p-4 rounded-lg flex items-center gap-4 cursor-pointer shadow">
        <img
          src={pensioner.profileImage}
          alt={`${pensioner.name}'s profile`}
          className="w-16 h-16 rounded-full"
        />
        <div className="flex-1">
          <h3 className="font-semibold">{pensioner.name}</h3>
          <p className="text-[#475554] capitalize font-semibold">
            GID {pensioner.gid}
          </p>
        </div>
        <div className="text-right">
          <p className={`font-semibold ${getStatusColor(pensioner.status)}`}>
            {pensioner.status}
          </p>
          <p className="font-semibold">{pensioner.dateTime}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Pensioners"}
        route={"/scheduleVerification/schedulesDetails"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[90%] w-full`}
        >
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="grid cursor-pointer sm:grid-cols-2 md:grid-cols-3 gap-4 grid-cols-1  w-full">
                {pensioners.map((pensioner) => (
                  <PensionerCard key={pensioner.id} pensioner={pensioner} />
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pensioner;
