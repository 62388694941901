import Pregistration from "./parts/Pregistration";
import Pidentification from "./parts/Pidentification";
import { useEffect, useState } from "react";
import back5 from "../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import logo1 from "../../imgs/logo12.png";
import CardFront from "../PensionerCards/CardFront"
import CardBack from "../PensionerCards/CardBack"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const Paccount = () => {
  const router = useNavigate();
  const user=useSelector((state)=>state.user)
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);
  const reset = useSelector((state) => state.reset);
  const statusCheck = useSelector((state) => state.statusCheck);
  console.log(statusCheck);
  const profileData = useSelector((state) => state.profileData);
  const token = useSelector((state) => state.token);
  const location = useLocation();
  const dash = location.pathname;
  const [show1, setShow1] = useState(false);
  const [showRe, setShowRe] = useState(false);
  const [wow, setWow] = useState(0);
  const Show = [Pregistration, Pidentification];
  const Dis = Show[wow];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  useEffect(() => {
    // /v1/beneficiary/users/profile?beneficiaryUserId=&programId=
    if (
      location.pathname === "/EDashboard/PensionerAccount" ||
      location.pathname === "/AdminDashboard/PensionerAccount" ||
      location.pathname === "/superAdminDashboard/PensionerAccount"
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch(() => {});
    } else {
      console.log("accesssss", dataUser);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/users/my-profile/`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log("data", data);
          if (data.data.user.status === "verified") {
            dispatch({ type: "verified" });
          } else if (data.data.user.status === "in-review") {
            dispatch({ type: "inreview" });
          } else if (data.data.user.status === "deferred") {
            dispatch({ type: "deferred" });
          } else if (data.data.user.status === "rejected") {
            dispatch({ type: "rejected" });
          } else if (data.data.user.status === "pending") {
            dispatch({ type: "pending" });
          } else if (data.data.user.status === "re-submitted") {
            dispatch({ type: "resubmitted" });
          }

          dispatch({ type: "setProfileData", num: data.data });
          console.log(statusCheck);
          if (
            dash === "/Dashboard/PensionerAccount" &&
            data?.data?.user?.status === "rejected"
          ) {
            setShow1(true);
          } else setShow1(false);
        })
        .catch(() => {});
    }
  }, [universalPenId, reset]);
  const resubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/profile/re-submit`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({ type: "RESET" });
      })
      .catch(() => {});
  };
  return (
    <div className="flex items-center justify-center">
      <div className="md:w-[90%] w-full pt-[5rem] md:pt-[2rem] md:pb-[5rem] p-4">
       
        {profileData && (
          <div className="w-full">
            <div className="flex items-center justify-between sm:ml-0 ml-0 mb-[1rem] md:w-[80%]">
              <div className="flex items-center sm:ml-0 ml-0 mb-[1rem]">
                <div
                  onClick={() => {
                    if (dash === "/Dashboard/PensionerAccount") {
                      router("/Dashboard");
                    } else {
                      // router("/EDashboard/Pensioner_HistoryP");
                      user === "employee"
                        ? router("/EDashboard/profPage")
                        : user === "admin"
                        ? router("/AdminDashboard/profPage")
                        : router("/superAdminDashboard/profPage");
                    }
                  }}
                  className="cursor-pointer"
                >
                  <img
                    className="sm:w-[1.7rem] w-[1.2rem]"
                    src={back5}
                    alt=""
                  />
                </div>
                <div className="font-bold sm:text-[1.8rem] text-[1.2rem]">
                  {location.pathname === "/EDashboard/PensionerAccount"
                    ? "Edit Pensioner"
                    : "Pensioner Account"}
                </div>
              </div>
              {show1 === true && (
                <button
                  onClick={() => setShowRe(true)}
                  className=" text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md"
                >
                  Re Submit
                </button>
              )}
            </div>
            <div className="lg:mb-[2rem] flex mb-[1rem] md:w-[80%]">
              <div
                className="cursor-pointer"
                onClick={() => changeHandler("R")}
              >
                <h2
                  className={`lg:text-[1.1rem] ${
                    wow === 0 ? "font-semibold" : ""
                  } `}
                >
                  Registration Details
                </h2>
                <div
                  className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                    wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                  } `}
                ></div>
              </div>

              <div
                className="cursor-pointer"
                onClick={() => changeHandler("I")}
              >
                <h2
                  className={`lg:text-[1.1rem] ml-6 ${
                    wow === 1 ? "font-semibold" : ""
                  } `}
                >
                  Identification Details
                </h2>
                <div
                  className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                    wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                  }  `}
                ></div>
              </div>
            </div>
          
             {profileData.user && (
              <div className="">
                {profileData?.user?.roles[0] === "pensioner" && (
                  // <div className="sm:flex-row flex flex-col h-[25rem] w-full">
                  <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
                    <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                      <CardFront />
                    </div>
                    <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                      <CardBack />
                    </div>
                  </div>
                  // </div>
                )}
              </div>
            )}
            <div>
              <Dis />
            </div>
          </div>
        )}
        <div
          className={`${
            showRe ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
            <div
            // className="absolute top-[-11%] left-[42%]"
            // onClick={() => setShow1(false)}
            >
              <img src={logo1} alt="" className="w-20 h-20"/>
            </div>
            <p className="flex h-[7rem] items-center justify-center text-center">
              Once submitted, no further edits will be allowed. Confirm your
              information is accurate before resubmitting.
            </p>
            <button
              type="button"
              onClick={() => {
                resubmit();
                setShowRe(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              Re Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paccount;
