import React, { useEffect } from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { useParams } from "react-router-dom";
function Video() {
  var randomId = "";
  const routeParams = useParams();
  if (routeParams.id) {
    randomId = routeParams.id;
  }
  const generateId = () => {
    randomId =
      "id-" +
      Math.random().toString(36).substr(2, 9) +
      "-" +
      Date.now().toString(36);
    // setMessage(`${randomId}`);
  };
  useEffect(() => {
    if (randomId === "" || randomId === undefined) {
      generateId();
    }
    const apiKey = "3ae8ef05-ddb5-4293-ad5e-7fe1ec6ad6e4";
    const meetingId = randomId;
    const name = "";
    const config = {
      name: name,
      meetingId: meetingId,
      apiKey: apiKey,
      region: "sg001", // region for new meeting
      containerId: null,
      // redirectOnLeave: "https://www.videosdk.live/",
      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible
      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,
      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "PIN", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },
      branding: {
        enabled: true,
        logoURL:
          "https://res.cloudinary.com/dhmolukeg/image/upload/v1701262100/logo12_zbfrfw.png",
        name: "SCP App",
        poweredBy: false,
      },
      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        drawOnWhiteboard: true, // Can draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle meeting recording
        toggleLivestream: true, //can toggle live stream
        removeParticipant: true, // Can remove participant
        endMeeting: true, // Can end meeting
        changeLayout: true, //can change layout
      },
      joinScreen: {
        visible: true, // Show the join screen ?
        title: "SCP Meeting", // Meeting title
        meetingUrl: window.location.href + `/${randomId}`, // Meeting joining url
      },
      notificationSoundEnabled: true,
      debug: true, // pop up error during invalid config or netwrok error
      maxResolution: "sd", // "hd" or "sd"
      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started
    };
    const meeting = new VideoSDKMeeting();
    debugger;
    console.log("meeting link", meeting, meeting.meetingUrl);
    meeting.init(config);
  }, []);
  return <div></div>;
}
export default Video;
