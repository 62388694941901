import Identification from "../Identification/stepsI/identification";
import ThirdI from "../Identification/stepsI/ThirdI";
import { useSelector } from "react-redux";
import FifthB from "./stepsBR/FifthBR";
const BeneficiaryI = () => {
  const bicurrent = useSelector((state) => state.bicurrent);
  const Show = [Identification ,ThirdI, FifthB];
  const Dis = Show[bicurrent];
  return (
    <div>
      <div>
        <Dis />
      </div>
    </div>
  );
};

export default BeneficiaryI;
