import React from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import ContactPerson from "./contactPersonMer";
const { Panel } = Collapse;

const Index = ({ data, contactPerson }) => {
  const callback = (key) => {
    console.log(data, key);
  };
  console.log("tester", data);
  const Header = ({ data }) => {
    console.log("dataaa", data);
    return (
      <div className="">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <img
              className="sm:w-16 sm:h-16 w-12 h-12 rounded-full object-cover border-2 border-primary p-1"
              src={data?.image}
              alt="Profile"
            />
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex items-center space-x-2">
              <p className="text-lg font-semibold text-gray-900 capitalize truncate">
                {data?.firstName}
              </p>
              <p className="text-lg font-semibold text-gray-900 truncate">
                {data?.lastName}
              </p>
            </div>
            <p className="text-md text-gray-500 truncate "></p>
            <p
              className={`${
                data?.position ? "text-sm text-gray-400 truncate " : "hidden"
              }`}
            >
              Position: {data?.position}
            </p>
          </div>
          {/* <div className="inline-flex items-center text-base text-primary text-[0.9rem] font-semibold cursor-pointer">
            Edit
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className={`${contactPerson?.length === 0 ? "hidden" : "sm:p-10"}`}>
      {/* <div className="p-4 bg-white rounded-lg border shadow-md sm:p-8   mb-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold leading-none text-gray-900 ">
            Owner Details
          </h3>

        </div>
        <div className="flex items-center">
          <img
            className="w-24 h-24 rounded-full mr-3"
            src={data?.image}
            alt="jane"
          />
          <div className="text-sm">
            <h4
              className="sm:text-lg font-semibold text-primary truncate  leading-none"
            >
              {data?.firstName}&nbsp;
              {data?.middleName && (
                <>
                  {data?.middleName}&nbsp;
                </>
              )}
              {data?.lastName}
            </h4>
            <p className="mt-1">{data?.emails?.[0]}</p>
          </div>
        </div>


      </div> */}

      <div className={`${contactPerson?.length === 0 ? "hidden" : "w-full"}`}>
        <div className="p-4 bg-white rounded-lg border shadow-md sm:p-8  ">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold leading-none text-gray-900 ">
              Admins
            </h3>
            <a
              href="#"
              className="text-sm font-medium text-orange-600 hover:underline"
            >
              {/* View all */}
            </a>
          </div>
          <div className="flow-root">
            <div>
              <Collapse
                defaultActiveKey={["1"]}
                onChange={callback}
                accordion={true}
                bordered={false}
                style={{ backgroundColor: "white" }}
              >
                {contactPerson?.map((con, index) => {
                  return (
                    <Panel
                      showArrow={false}
                      extra={
                        <CaretRightOutlined style={{ fontSize: "12px" }} />
                      }
                      header={<Header data={con.merchperson} />}
                      key={index + 1}
                      className=" text-md text-gray-800 font-medium "
                    >
                      <ContactPerson
                        title={con?.merchperson?.firstName + " " + con?.merchperson?.lastName}
                        data={con}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
