import React from "react";
import { MailFilled, PhoneFilled } from "@ant-design/icons";
const StoreModal = ({ setShow, email, phone, store, data }) => {
  console.log(data);
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow  ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {store ? "Store Info" : "Contact Info"}
            </h3>
            <button
              onClick={() => {
                setShow(false);
              }}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className=" max-h-96 overflow-y-scroll custom-scrollbar">
            <div className="p-5 md:p-5">
              <div className={`${store ? "flex flex-col items-center" : "hidden"}`}>
                <div className="flex-shrink-0 items-center justify-center">
                  <img
                    className="sm:w-20 sm:h-20 w-16 h-16 rounded-full object-cover border-2 border-primary p-1"
                    src={data?.merchstore?.image}
                    alt="Profile"
                  />
                </div>
                <div className={`${store ? "" : "hidden"} mt-1`}>
                  <div className="flex items-center text-center">
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-center">
                        <p className="text-md font-semibold text-primary truncate">
                          {data?.merchstore?.customStoreName}
                        </p>
                      </div>
                      <p className="text-md text-gray-500 truncate "></p>
                    </div>
                  </div>
                  <p className="text-gray-500 ">Address :</p>
                  <div className="space-y-4 p-4">
                    <div className="w-full p-3 text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer shadow-sm">
                      <div>
                        {data?.merchstore?.lot && (
                          <span>{data.merchstore.lot}, </span>
                        )}
                        {data?.merchstore?.address && (
                          <span>{data.merchstore.address}, </span>
                        )}
                        {data?.merchstore?.region && (
                          <span>{data.merchstore.region}, </span>
                        )}
                        {data?.merchstore?.regionName && (
                          <span>{data.merchstore.regionName}, </span>
                        )}
                        {data?.merchstore?.city && (
                          <span>{data.merchstore.city}, </span>
                        )}
                        {data?.merchstore?.state && (
                          <span>{data.merchstore.state}, </span>
                        )}
                        {data?.merchstore?.village && (
                          <span>{data.merchstore.village} </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-gray-500 ">
                Email Address :
              </p>
              <ul className="space-y-4 p-4">
                {email?.map((voc, index) => (
                  <li key={index}>
                    <div className="w-full p-3 text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer shadow-sm">
                      <div className="flex gap-2 items-center">
                        <MailFilled className="text-primary" />
                        <div className="w-full text-md font-semibold">
                          {voc}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <p
                className={`${
                  phone.length === 0
                    ? "hidden"
                    : "text-gray-500 "
                }`}
              >
                Contact Number :
              </p>
              <ul className="space-y-4 p-4">
                {phone?.map((voc, index) => (
                  <li key={index}>
                    <div className="w-full p-3 text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer shadow-sm">
                      <div className="flex gap-2 items-center">
                        <PhoneFilled className="text-primary" />
                        <div className="w-full text-md font-semibold">
                          {voc}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreModal;
