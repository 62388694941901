import { useState, useRef } from "react";
import { TimePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import back5 from "../../../imgs/back5.png";
import check from "../../../imgs/add.png";
import moment from "moment";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
const { Option } = Select;
let file;
const Mid = () => {
  const router = useNavigate();
  const [manufacturer, setManufacturer] = useState(null);
  const [type, setType] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [licensePlate, setLicensePlate] = useState("");
  const [addShift, setAddShift] = useState([]);
  const [workingHoursStart, setWorkingHoursStart] = useState(null);
  const [workingHoursEnd, setWorkingHoursEnd] = useState(null);
  const [breakTimeStart, setBreakTimeStart] = useState(null);
  const [breakTimeEnd, setBreakTimeEnd] = useState(null);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const initialShifts = daysOfWeek.map((day) => ({
    dayName: day,
    shifts: [
      { shiftName: "Working hours", start: "", end: "" },
      { shiftName: "Break time", start: "", end: "" },
    ],
  }));
  const [show1, setShow1] = useState(false);
  const [err, setErr] = useState("");
  const fileInputRef = useRef(null);

  const [shifts, setShifts] = useState(initialShifts);
  const [display, setDisplay] = useState(
    "https://i.pinimg.com/736x/07/0e/49/070e49397296fccce124f8bdfdf8b980.jpg"
  );

  const imageHandler = (setState) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const convertTo12Hour = (time24h) => {
    let [hours, minutes] = time24h.split(":");
    let modifier = "AM";

    hours = parseInt(hours, 10);
    if (hours >= 12) {
      modifier = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }

    return `${hours} : ${minutes} ${modifier}`;
  };

  const handleValues = () => {
    if (selectedDays.length === 0) {
      setErr("Please specify the days for setting up shift timings.");
      setShow1(true);
    } else if (
      workingHoursStart === null ||
      workingHoursEnd === null ||
      breakTimeStart === null ||
      breakTimeEnd === null
    ) {
      setErr("Please insert all of timings to assign.");
      setShow1(true);
    } else {
      const convertTo24Hour = (time12h) => {
        const [time, modifier] = time12h.split(" ");
        let [hours, minutes] = time.split(":");
        if (hours === "12") {
          hours = "00";
        }
        if (modifier === "PM") {
          hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
      };

      const formattedValueWorkingStart =
        workingHoursStart === null ? "" : convertTo24Hour(workingHoursStart);
      const formattedValueWorkingEnd =
        workingHoursEnd === null ? "" : convertTo24Hour(workingHoursEnd);
      const formattedValueBreakStart =
        breakTimeStart === null ? "" : convertTo24Hour(breakTimeStart);
      const formattedValueBreakend =
        breakTimeEnd === null ? "" : convertTo24Hour(breakTimeEnd);

      setShifts((prevShifts) => {
        const updatedShifts = prevShifts.map((shift) => {
          if (selectedDays.includes(shift.dayName)) {
            return {
              ...shift,
              shifts: shift.shifts.map((s) => {
                if (s.shiftName === "Working hours") {
                  return {
                    ...s,
                    start: formattedValueWorkingStart,
                    end: formattedValueWorkingEnd,
                  };
                } else if (s.shiftName === "Break time") {
                  return {
                    ...s,
                    start: formattedValueBreakStart,
                    end: formattedValueBreakend,
                  };
                }
                return s;
              }),
            };
          }
          return shift;
        });

        setSelectedDays([]);

        const addedDays = updatedShifts
          .map((day) => {
            if (
              day.shifts.some((shift) => shift.start !== "" && shift.end !== "")
            ) {
              return day.dayName;
            }
          })
          .filter((day) => day !== undefined);

        setAddShift(addedDays);

        return updatedShifts;
      });
    }
  };

  const toggleDaySelection = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  const handleDeleteShift = (dayIndex) => {
    const updatedShifts = [...shifts];
    updatedShifts[dayIndex].shifts.forEach((shift) => {
      shift.start = "";
      shift.end = "";
    });

    setShifts(updatedShifts);

    const addedDays = updatedShifts
      .filter((day) =>
        day.shifts.some((shift) => shift.start !== "" && shift.end !== "")
      )
      .map((day) => day.dayName);

    setAddShift(addedDays);
  };

  return (
    <div className=" w-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-full flex items-center justify-between sm:w-[70%] w-[80%]">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => router("/Maintenance-Drivers")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Add Driver
              </h2>
            </div>
          </div>
        </div>
      </div>
      <form action="" className="mt-10 sm:mt-[5rem] mb-[5rem]" onSubmit={""}>
        <div className="flex items-center justify-center mb-4 flex-col">
          <label htmlFor="special-input" className="cursor-pointer relative">
            <div className="relative w-[8.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262]  rounded-full mb-4 ">
              <img
                className="object-cover w-[8.5rem] h-[8.5rem]"
                src={display}
                alt=""
              />
            </div>

            <div
              className={`bg-primary
               rounded-full absolute bottom-4 right-2`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay)}
              ref={fileInputRef}
            />
          </label>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Upload Driver Image <span className="text-red-600">*</span>
          </h2>
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="First Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Middle Name
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="Middle Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="Last Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  License Number
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="License Number"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  License Type
                </label>
                <Select
                  placeholder="Select Type"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setType(value)}
                  value={type}
                >
                  <Option value="LTV">LTV</Option>
                  <Option value="HTV">HTV</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  License Plate
                </label>
                <input
                  type="text"
                  placeholder="License Plate"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  onChange={(e) => setLicensePlate(e.target.value)}
                  value={licensePlate}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Assigned to
                </label>
                <Select
                  placeholder="Assigned to"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setAssignedTo(value)}
                  value={assignedTo}
                >
                  <Option value="Minister">Minister</Option>
                  <Option value="Permanent Secretary-PS">
                    Permanent Secretary
                  </Option>
                  <Option value="Regional Director">Regional Director</Option>
                  <Option value="Employee">Employee</Option>
                </Select>
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Assign Vehicle <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Select Vehicle"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  onChange={(value) => setManufacturer(value)}
                  value={manufacturer}
                >
                  <Option value="Acura">Acura</Option>
                  <Option value="Alfa-Romeo">Alfa-Romeo</Option>
                  <Option value="Aston Martin">Aston Martin</Option>
                  <Option value="Austin-Healey">Austin-Healey</Option>
                  <Option value="Audi">Audi</Option>
                  <Option value="Autobianchi">Autobianchi</Option>
                  <Option value="Avanti">Avanti</Option>
                  <Option value="Bentley">Bentley</Option>
                  <Option value="Benz">Benz</Option>
                  <Option value="BMW">BMW</Option>
                  <Option value="Bristol">Bristol</Option>
                  <Option value="British Leyland">British Leyland</Option>
                  <Option value="Bugatti">Bugatti</Option>
                  <Option value="Buick">Buick</Option>
                  <Option value="Cadillac">Cadillac</Option>
                  <Option value="Chevrolet">Chevrolet</Option>
                  <Option value="Chrysler">Chrysler</Option>
                  <Option value="Citroën">Citroën</Option>
                  <Option value="Cleveland">Cleveland</Option>
                  <Option value="Cooper">Cooper</Option>
                  <Option value="Cushman">Cushman</Option>
                  <Option value="Daewoo">Daewoo</Option>
                  <Option value="Daihatsu">Daihatsu</Option>
                  <Option value="Daimler">Daimler</Option>
                  <Option value="Datsun">Datsun</Option>
                  <Option value="Delorean">Delorean</Option>
                  <Option value="Dodge">Dodge</Option>
                  <Option value="Eagle">Eagle</Option>
                  <Option value="Edsel">Edsel</Option>
                  <Option value="Falcon">Falcon</Option>
                  <Option value="Ferguson">Ferguson</Option>
                  <Option value="Ferrari">Ferrari</Option>
                  <Option value="Fiat">Fiat</Option>
                  <Option value="Ford">Ford</Option>
                  <Option value="General Motors">General Motors</Option>
                  <Option value="Global Electric Motorcars">
                    Global Electric Motorcars
                  </Option>
                  <Option value="GMC">GMC</Option>
                  <Option value="Hudson">Hudson</Option>
                  <Option value="Hummer">Hummer</Option>
                  <Option value="Hyundai">Hyundai</Option>
                  <Option value="Infiniti">Infiniti</Option>
                  <Option value="Isuzu">Isuzu</Option>
                  <Option value="Jaguar">Jaguar</Option>
                  <Option value="Jeep">Jeep</Option>
                  <Option value="Kia">Kia</Option>
                  <Option value="Lamborghini">Lamborghini</Option>
                  <Option value="Lancia">Lancia</Option>
                  <Option value="Land Rover">Land Rover</Option>
                  <Option value="Lexus">Lexus</Option>
                  <Option value="Lincoln">Lincoln</Option>
                  <Option value="Lotus">Lotus</Option>
                  <Option value="Maserati">Maserati</Option>
                  <Option value="Maybach">Maybach</Option>
                  <Option value="Mazda">Mazda</Option>
                  <Option value="McLaren">McLaren</Option>
                  <Option value="Mercedes">Mercedes</Option>
                  <Option value="Mercedes-Benz">Mercedes-Benz</Option>
                  <Option value="Mercury">Mercury</Option>
                  <Option value="MG">MG</Option>
                  <Option value="Mini">Mini</Option>
                  <Option value="Mitsubishi">Mitsubishi</Option>
                  <Option value="Morris">Morris</Option>
                  <Option value="Nissan">Nissan</Option>
                  <Option value="Oldsmobile">Oldsmobile</Option>
                  <Option value="Opel">Opel</Option>
                  <Option value="Peugeot">Peugeot</Option>
                  <Option value="Pontiac">Pontiac</Option>
                  <Option value="Porsche">Porsche</Option>
                  <Option value="Proton">Proton</Option>
                  <Option value="Renault">Renault</Option>
                  <Option value="Rolls Royce">Rolls Royce</Option>
                  <Option value="Saab">Saab</Option>
                  <Option value="Saturn">Saturn</Option>
                  <Option value="Scion">Scion</Option>
                  <Option value="Skoda Auto">Skoda Auto</Option>
                  <Option value="Subaru">Subaru</Option>
                  <Option value="Suzuki">Suzuki</Option>
                  <Option value="Tata">Tata</Option>
                  <Option value="Tesla">Tesla</Option>
                  <Option value="Toyota">Toyota</Option>
                  <Option value="Triumph">Triumph</Option>
                  <Option value="Vauxhall">Vauxhall</Option>
                  <Option value="Volkswagen">Volkswagen</Option>
                  <Option value="Volvo">Volvo</Option>
                  <Option value="Yamaha">Yamaha</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="sm:w-[70%] w-[80%] mt-8 mb-8 bg-[#F4F5FE] shadow-md p-4 rounded-md">
            <div>
              <>
                <div
                  key="workingHours"
                  className="flex items-center mt-2  flex-col w-full"
                  style={{ marginBottom: "8px" }}
                >
                  <p className="font-bold w-full text-[12px] mb-2 capitalize">
                    Working Hours
                  </p>
                  <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <label>
                      Start Time:
                      <div className="relative">
                        <TimePicker
                          showNow={false}
                          value={
                            workingHoursStart
                              ? moment(workingHoursStart, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setWorkingHoursStart(value.format("hh:mm A"));
                            }
                          }}
                          allowClear={false}
                        />
                        {workingHoursStart && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setWorkingHoursStart(null)}
                          />
                        )}
                      </div>
                    </label>
                    <label>
                      End Time:
                      <div className="relative">
                        <TimePicker
                          needConfirm={false}
                          showNow={false}
                          value={
                            workingHoursEnd
                              ? moment(workingHoursEnd, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setWorkingHoursEnd(value.format("hh:mm A"));
                            }
                          }}
                          allowClear={false}
                        />
                        {workingHoursEnd && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setWorkingHoursEnd(null)}
                          />
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div
                  key="breakTime"
                  className="flex items-center  flex-col w-full"
                  style={{ marginBottom: "8px" }}
                >
                  <p className="font-bold w-full text-[12px] mb-2 capitalize">
                    Break Time
                  </p>
                  <div className="w-full  grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <label>
                      Start Time:
                      <div className="relative">
                        <TimePicker
                          showNow={false}
                          value={
                            breakTimeStart
                              ? moment(breakTimeStart, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setBreakTimeStart(value.format("hh:mm A"));
                            }
                          }}
                          needConfirm={false}
                          allowClear={false}
                        />
                        {breakTimeStart && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setBreakTimeStart(null)}
                          />
                        )}
                      </div>
                    </label>
                    <label>
                      End Time:
                      <div className="relative">
                        <TimePicker
                          needConfirm={false}
                          showNow={false}
                          value={
                            breakTimeEnd
                              ? moment(breakTimeEnd, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setBreakTimeEnd(value.format("hh:mm A"));
                            }
                          }}
                          allowClear={false}
                        />
                        {breakTimeEnd && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setBreakTimeEnd(null)}
                          />
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "-ms-overflow-style": "none",
                }}
              >
                {daysOfWeek.map((day) => (
                  <div
                    key={day}
                    onClick={() => toggleDaySelection(day)}
                    className={`cursor-pointer mt-2 mb-2 rounded-full mr-1 font-semibold  pl-4 pr-4 p-1 ${
                      selectedDays.includes(day)
                        ? "bg-primary text-white"
                        : "bg-white"
                    } ${
                      addShift.includes(day)
                        ? "pointer-events-none opacity-40"
                        : ""
                    }`}
                  >
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={() => {
                  handleValues();
                }}
                className={`lg:text-[1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-1  pr-6 pl-6 mt-2`}
              >
                Set Shifts
              </button>
              <div className="flex flex-col mt-4 gap-1 w-full">
                {shifts.map(
                  (day, index) =>
                    day.shifts.some(
                      (shift) => shift.start !== "" && shift.end !== ""
                    ) && (
                      <div
                        key={day.dayName}
                        className="flex flex-wrap w-full bg-primary shadow-md relative text-white pl-4 pr-4 p-1 rounded-lg"
                      >
                        {day.shifts.some(
                          (shift) => shift.start !== "" && shift.end !== ""
                        ) && (
                          <h2 className="mb-1 capitalize font-bold ">
                            {day.dayName}
                          </h2>
                        )}
                        <div className="flex w-full  items-center justify-between">
                          {day.shifts.map(
                            (shift, shiftIndex) =>
                              shift.start !== "" &&
                              shift.end !== "" && (
                                <div
                                  key={shift.shiftName}
                                  className="flex w-full sm:flex-row flex-col pr-4 mb-2"
                                >
                                  <span className="font-semibold mr-2">
                                    {shift.shiftName}:
                                  </span>{" "}
                                  {convertTo12Hour(shift.start)} -{" "}
                                  {convertTo12Hour(shift.end)}
                                </div>
                              )
                          )}
                        </div>
                        <DeleteOutlined
                          className="absolute top-0 text-xl right-2"
                          onClick={() => handleDeleteShift(index)}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div
            className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
          >
            <button
              type="button"
              className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Add Driver
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Mid;
