import { DatePicker, Popover } from "antd";
import imageP from "../../../../imgs/printer 1.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Main from "./Main";
import axios from "axios";
import moment from "moment";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import dayjs from "dayjs";
// import SearchFilter from "./SearchFilter";
const Paget = () => {
  const sapmoney = useSelector((state) => state?.sapmoney);
  const router = useNavigate();
  const dispatch = useDispatch();
  const mhhsMerchId = useSelector((state) => state.mhhsMerchId);
  const [searchTerm, setSearchTerm] = useState("");
  const search = "";
  const page = 1;
  const dataUser = useSelector((state) => state.dataUser);
  const pagewithsearch = "";
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [allData, setAllData] = useState([]);
  const selectedTransactions = [];
  const [showError, setShowError] = useState(false);
  const [paymentDate, setPaymentDate] = useState("");
  const [searchDop, setSearchDop] = useState("");
  const [totalOutStanding, setTotalOutStanding] = useState(0);
  const [apiCalled, setApiCalled] = useState(false);
  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      const params = {
        limit: 6,
        skip: 0,
        // skip:
        //   search || searchDob || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(searchDop && { paymentDate: searchDop }),
        // ...(search && { search }),
        // ...(searchDob && { dateOfBirth: searchDob }),
        // ...(countryCode &&
        //   phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
      };
      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [
    page,
    search,
    searchDop,
    // countryCode,
    // phoneNoo,
    // pagewithsearch,
    // searchTerm,
  ]);

  useEffect(() => {
    dispatch({ type: "SELECTED_TRANSACTIONS_VOUCHERS", num: [] });
  }, []);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transactions/list-by-merchId?merchUserId=${mhhsMerchId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setAllData(data?.data?.rows);
        // setTotalPages(data?.data?.totalPages);
        setTotalOutStanding(data?.data?.totalOustanding);
        setShow(false);
        setApiCalled(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columnHeaders = [
    "Requested on",
    "Store",
    "Phone",
    "Pensioner",
    "GID",
    "SCP Book",
    "Amount",
    "Status",
  ];

  const nextHandler = (data) => {
    // if (selectedTransactions.length === 0) {
    //   setShowError(true);
    //   setErr("Please select a transaction to proceed");
    // } else {
    // dispatch({ type: "SELECTED_TRANSACTIONS", num: selectedTransactions });

    // if (data?.mhsssPayStatus === "pending") {
    // if (data?.merchuser?.role === "merchant-employee") {
    //   getMerchantOwner(data?.merchheadquarterId);
    //   dispatch({ type: "SELECTED_TRANSACTIONS", num: data });
    //   return;
    // }

    if (sapmoney === true) {
      dispatch({ type: "MHSSS_PAY_STATUS", num: data?.mhsssPayStatus });
      dispatch({ type: "SELECTED_TRANSACTIONS", num: data });
      router("/SapMoney/MHSSS/outstanding/vouchers");
      return;
    }

    dispatch({ type: "MHSSS_PAY_STATUS", num: data?.mhsssPayStatus });
    dispatch({ type: "SELECTED_TRANSACTIONS", num: data });
    router("/MHSSS/vouchers");
    // }
    // }
  };
  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const disabledDate1 = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };
  // const getMerchantOwner = (id) => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/v1/merch-user/owner-by-hq/for-mhsss?merchHeadquarterId=${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       dispatch({
  //         type: "MHHS_MERCH_ID",
  //         num: data?.data?.id,
  //       });
  //       router("/MHSSS/vouchers");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl ">
        <div className=" relative sm:rounded-lg overflow-hidden">
          <div className="flex flex-row items-center justify-end mb-4 gap-2">
            {/* <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="relative flex items-center">
                <h1 className="mr-2 font-semibold text-[15px]">Sort By : </h1>
                <select
                  id="sortByDropdown"
                  className="w-full md:w-auto pl-4 pr-4 h-[40px] text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="all">ALL</option>
                  <option value="name">Sort by Name</option>
                  <option value="date">Sort by Date</option>
                </select>
              </div>
            </div> */}
            <div className="w-[45%]  md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <div className="relative w-full">
                    <button
                      id="filterDropdownButton"
                      data-dropdown-toggle="filterDropdown"
                      className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                      type="button"
                      onClick={showHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter By
                      <svg
                        className="-mr-1 ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>

                    {show && (
                      <div
                        id="filterDropdown"
                        className="z-50 absolute top-full right-0 mt-2 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                      >
                        <div className="mt-5">
                          <DatePicker
                            onChange={(e, f) => setPaymentDate(f)}
                            value={
                              paymentDate !== "" ? moment(paymentDate) : null
                            }
                            placeholder="Payment Date"
                            disabledDate={disabledDate1}
                            // value={wow !== "" ? set : null}
                            // onChange={(e, f) => change1Handler(e, f)}
                          />
                        </div>

                        <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => {
                              if (paymentDate !== null && paymentDate !== "")
                                setSearchDop(paymentDate);
                              setShow(false);
                            }}
                            className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              setSearchDop("");
                              setPaymentDate("");
                              setShow(false);
                            }}
                            className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[20rem]">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search"
                    required=""
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="w-[120px] flex items-center justify-center gap-4 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg h-[37px] pl-4 pr-4 p-2">
              <img src={imageP} alt="print" />
              Print
            </div>
          </div>
          <Main totalOutStanding={totalOutStanding} data={allData} />
        </div>
        {/* table */}
        <div className="overflow-x-auto custom-scrollbar mt-2 w-full h-full rounded-tl-lg rounded-tr-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
              <tr>
                {columnHeaders.map((columnHeader) => (
                  <th
                    scope="col"
                    className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                  >
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>

            {allData?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center text-gray-500 text-lg">
                    <p className={`${apiCalled === true ? "p-10" : "hidden"}`}>
                      No data found
                    </p>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {allData?.map((data, index) => (
                  <tr
                    // onClick={() => handleRowSelect(data)}
                    onClick={() => {
                      nextHandler(data);
                    }}
                    className={`border-b border-[1px] border-[#d9d9d970] text-md cursor-pointer ${
                      selectedTransactions.some(
                        (transaction) =>
                          transaction.transactionId === data?.transactionId
                      )
                        ? "bg-[#1BA397] text-white" // Apply background color when the transaction is selected
                        : "bg-gray-50 hover:bg-[#1BA397] hover:text-white" // Default background color
                    }`}
                  >
                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      {data?.mhsssPayStatus === "pending" && (
                        <input
                          className="cursor-pointer w-4 h-4"
                          type="checkbox"
                          checked={selectedTransactions.some(
                            (transaction) =>
                              transaction?.transactionId === data?.transactionId
                          )}
                          onChange={() => handleRowSelect(data)}
                        />
                      )}
                    </td> */}
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div>
                        {moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.merchstore === null
                        ? "------"
                        : data?.merchstore?.customStoreName}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.merchuser?.countryCode !== null
                        ? data?.merchuser?.countryCode +
                          " " +
                          data?.merchuser?.phoneNo
                        : "------"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap capitalize">
                      {data?.user?.person?.firstName +
                        " " +
                        data?.user?.person?.lastName}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {" "}
                      {data?.user?.gid}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.pensionbook?.bookletNo}
                    </td>
                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      {getMonthName(data?.coupon?.month)}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap capitalize">
                      {data?.pensionprogram?.name === "oldage-pensioner"
                        ? "SCP"
                        : data?.pensionprogram?.name}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.transactionId}
                    </td> */}
                    <td className="px-4 py-3 whitespace-nowrap">
                      ${data?.totalAmount}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <Popover
                        // content={`${
                        //   data.Status === "pending" ? "In-Process" : ""
                        // }`}
                        placement="bottomLeft"
                      >
                        <div
                          className={`border-none ${
                            data?.mhsssPayStatus === "pending"
                              ? "bg-blue-500"
                              : data?.mhsssPayStatus === "rejected"
                              ? "bg-[#F35929]"
                              : "bg-primary"
                          }  p-1 pl-5 pr-5 rounded-md text-center text-white focus:border-none cursor-pointer capitalize`}
                        >
                          {data?.mhsssPayStatus === "success"
                            ? "Sent"
                            : data?.mhsssPayStatus}
                        </div>
                      </Popover>
                    </td>
                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      <Dropdown
                        menu={{
                          items,
                          // onClick: (key) => onClick(key, item),
                        }}
                        trigger={["click"]}
                        onClick={(e) => {
                          // Stop the propagation of the click event
                          e.stopPropagation();
                        }}
                      >
                        <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        </div>
                      </Dropdown>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <nav
          className="flex bg-white flex-col h-[6rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
          aria-label="Table navigation"
        >
          {/* <span className="text-sm font-normal text-gray-500 ">
            Showing{" "}
            <span className="font-semibold text-gray-900 ">Page {1} </span>
            of <span className="font-semibold text-gray-900 ">{100}</span>
          </span>
          <div className={`flex justify-end mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={totalPages}
              showSizeChanger={false}
              onChange={""}
              current={1}
            />
          </div> */}
          {/* <button
            onClick={nextHandler}
            className="cursor-pointer flex items-center justify-center rounded-xl text-white text-[15px] bg-primary h-[35px] w-[120px]"
          >
            Next
          </button> */}
        </nav>
      </div>
      {showError && <ErrorModal err={""} setShow={setShowError} />}
    </section>
  );
};

export default Paget;
