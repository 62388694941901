import React from "react";
import noUser from "../../../imgs/noUser.svg";
const noUserFoundModal = ({ setShow, type, value }) => {
  return (
    <div
      className={`${
        setShow ? "" : "hidden"
      } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
    >
      <div className="relative rounded-md w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
        <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
          <img src={noUser} alt="" className="w-full h-full" />
        </div>
        <h2 className="font-bold text-[1rem]">User Not Found</h2>
        <p className="text-center w-[70%] mt-2 mb-6">
          <span className="font-semibold">{value}</span> Didn’t have sap money account. Kindly verify the {type} and
          try again.
        </p>
        <div className="flex gap-4 w-[100%] items-center justify-center">
          <button
            onClick={() => {
              setShow(false);
            }}
            className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default noUserFoundModal;
