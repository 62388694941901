import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";
import Biometric from "../components/biometric/add/index";

const Account = () => {
  return (
    <div>
      <Nav />
      <Biometric />
      <Whatsapp />
    </div>
  );
};

export default Account;
