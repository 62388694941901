import React, { useEffect, useState } from "react";
import PageHeader from "./pageHeader/pageHeader";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorModal from "../Merchant/Modals/errorModal";
import ApproveModal from "../pensionerVerification/modal/approveModal";
import { useDispatch, useSelector } from "react-redux";
let items;
const SelectLocation = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [items, setItems] = useState([]);
  const [answer, setAnswer] = useState("");
  const [reset, setReset] = useState(false);
  const [question, setQuestion] = useState(null);
  const [verify, setVerify] = useState(false);
  const [count, setCount] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/security-questions/all`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        console.log("dataaaaaa", data);
        const options = data?.data.map((item) => ({
          label: item.question,
          value: item.id,
        }));
        setItems(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/security-questions/count-my-answers`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        if (data?.data?.countMySecurityQuestionAnswers === 3) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/v1/security-question-config`,
              {
                headers: {
                  Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
                },
              }
            )
            .then((data) => {
              if (data?.data?.canBeReset) setCount(1);
              else setCount(4);
            })
            .catch(() => {});
        } else setCount(data?.data?.countMySecurityQuestionAnswers + 1);
      })
      .catch(() => {});
  }, [reset]);
  const Submit = () => {
    if (question === null || question === "") {
      setErr("Please Select a question.");
      setShowErr(true);
    } else if (answer === "") {
      setErr("Please Provide answer for the question.");
      setShowErr(true);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/security-questions/user-add-answer`,
          {
            securityquestionId: question,
            answer: answer,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          console.log("question", data);
          setQuestion(null);
          setAnswer("");
          setReset(!reset);
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShowErr(true);
        });
    }
  };
  useEffect(() => {
    if (count === 4) setVerify(true);
  }, [count]);
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Set Up Security Questions"}
        route={"/"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <form action="">
            <div className="w-[100%] flex flex-col">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                To enhance your account security, please select and answer 3
                security questions below
              </label>
              <div className="bg-white  md:w-[50%] sm:w-[75%] w-full pt-6 p-2 pb-6 flex flex-col gap-1">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Question {count}{" "}
                  <span className="text-semibold text-primary">{`(${count}/3 Questions)`}</span>
                </label>
                <Select
                  placeholder="Select Question"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => {
                    setQuestion(value);
                  }}
                  style={{ marginBottom: "0.5rem" }}
                  className="border-none wow"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  value={question}
                  options={items}
                />
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                  value={answer}
                  placeholder="Answer"
                  className="lg:text-[1.1rem] w-full bg-gray-50 border-none rounded-[0.3rem] p-2 mb-4"
                />
                <label className="text-[#808080] ml-1 font-semibold text-[0.8rem]">
                  Use something memorable but not easily guessed.
                </label>
              </div>
              <h1 className="text-[#596F96] text-[0.8rem] mt-[3rem] font-semibold mb-2">
                <span className="font-semibold text-black"> Note:</span> Once
                you submit your answers, you will not be able to change them or
                view them later.
              </h1>
              <button
                type="button"
                onClick={Submit}
                className={`lg:text-[1rem] mt-4 bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
              >
                Submit Answer
              </button>
            </div>
          </form>
        </div>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {verify && (
        <ApproveModal
          setVerify={setVerify}
          text={
            "Your security questions have been successfully updated. Click 'Okay' to continue to your dashboard."
          }
          title={"Security Question Updated"}
          route="/Dashboard"
          type="question"
        />
      )}
    </div>
  );
};

export default SelectLocation;
