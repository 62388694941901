import { Select, DatePicker, Button } from "antd";
import { useRef, useState } from "react";
import check from "../../../imgs/add.png";
import trash from "../../../imgs/trash.png";
import back5 from "../../../imgs/back5.png";
import { EnvironmentOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";

let file;
const Mid = () => {
  const router = useNavigate();
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);
  const [display2, setDisplay2] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQOG5QYuRv7Ubd6rETJT3qcjjUiN09IXUIV3wGE-QmMw&s"
  );
  const [test, setTest] = useState(false);
  const [lName2, setLName2] = useState("");
  const [locationt, setLocationt] = useState("");
  const [imagesSub, setImagesSub] = useState([]);
  const fileInputRef = useRef(null);

  const handleLocateMe = (setState) => {
    if (navigator.geolocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Make a request to Google Maps Geocoding API
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU`;

            fetch(apiUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.results && data.results.length > 0) {
                  const locationName = data.results[0].formatted_address;
                  setLocationt(`${latitude}, ${longitude}`);
                  setState(locationName);
                } else {
                  console.error("Could not retrieve location name",locationt);
                }
              })
              .catch((error) => {
                console.error("Error getting location:", error.message);
              });
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  const imageHandler = (setState) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const imageHandlerSub = async (e) => {
    const file = e.target.files[0];
    const base64Image = await convertToBaseSub(file);
    setImagesSub([...imagesSub, base64Image]);
  };
  function convertToBaseSub(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <div className=" w-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-full flex items-center justify-between sm:w-[70%] w-[80%]">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => router("/EmployeeDashBoard/events")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Add Event
              </h2>
            </div>
          </div>
        </div>
      </div>
      <form action="" className="mt-10 sm:mt-[5rem] mb-[5rem]" onSubmit={""}>
        <div className="flex items-center justify-center mb-4 flex-col">
          <label htmlFor="special-input" className="cursor-pointer relative">
            <div className="relative w-[8.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262]  rounded-full mb-4 ">
              <img
                className="object-cover w-[8.5rem] h-[8.5rem]"
                src={display2}
                alt=""
              />
            </div>

            <div
              className={`bg-primary
                 rounded-full absolute bottom-4 right-2`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay2)}
              ref={fileInputRef}
            />
          </label>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Upload Event Cover Photo <span className="text-red-600">*</span>
          </h2>
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="flex items-center  sm:w-[70%] w-[80%] justify-center flex-col ">
            <Button
              onClick={() => handleLocateMe(setLName2)}
              className="flex items-center justify-center bg-gray-300 w-[13rem] h-12 font-semibold text-[1rem] text-gray-700"
              icon={<EnvironmentOutlined />}
            >
              Locate Me <span className="text-red-600">*</span>
            </Button>
            <input
              type="text"
              placeholder="Location"
              value={lName2}
              className=" lg:text-[1.1rem] w-[100%] bg-white mt-3 border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Event Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Event Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Start Date <span className="text-red-600">*</span>
                </label>
                <DatePicker
                  placeholder="Start Date"
                  style={{ marginBottom: "1.2rem" }}
                  disabledDate={disabledDate1}
                  className="date-class  h-[2.7rem]"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  End Date <span className="text-red-600">*</span>
                </label>
                <DatePicker
                  placeholder="End Date"
                  style={{ marginBottom: "1.2rem" }}
                  disabledDate={disabledDate1}
                  className="date-class h-[2.7rem]"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Event Type <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Event Type"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={[
                    {
                      value: "Public",
                      label: "Public",
                    },
                    {
                      value: "Private",
                      label: "Private",
                    },
                  ]}
                />
              </div>

              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Number of persons Allowed{" "}
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Number of persons Allowed"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Address <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Address"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <label
              htmlFor="specia"
              className="cursor-pointer  sm:w-[70%] w-[80%] relative"
            >
              <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                <div>
                  <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                </div>
                <p className="text-[#9CA6C2] ml-2">Add Sub Event Images</p>
              </div>
              <input
                type="file"
                className="hidden w-[100%] cursor-pointer"
                id="specia"
                accept="image/png, image/gif, image/jpeg"
                onChange={imageHandlerSub}
              />
            </label>
            <div className=" sm:w-[70%] w-[80%] flex gap-4 justify-between flex-wrap">
              {imagesSub.map((image, index) => (
                <div key={index} className="relative mb-4 w-[30%]">
                  <img
                    onClick={() => {}}
                    className="w-full h-48 rounded-xl object-cove"
                    src={image}
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {
                      setImagesSub(imagesSub.filter((_, i) => i !== index));
                    }}
                  >
                    <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div
            className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
          >
            <button
              type="button"
              className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Confirm Sub Event
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Mid;
