const Contact = ({ headquarter, type }) => {
  const phone =
    type === "Store" || type === "Contact Person"
      ? headquarter?.phones
      : headquarter?.contacts;
  console.log("Test", headquarter);
  return (
    <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
      <div className="sm:px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-600">
          {type} Contacts
        </h3>
      </div>
      {(type === "Store" || type === "Head Quarter") && (
        <div className="sm:px-4 py-2 pt-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-semibold leading-6 text-gray-900">
            Primary Contact
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
            {headquarter.primaryPhone}
          </dd>
        </div>
      )}
      <div className="mt-2 border-t border-gray-300">
        <dl className="divide-y divide-gray-300">
          {phone?.map((con, index) => (
            <div
              key={index}
              className="sm:px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
            >
              <dt className="text-sm font-semibold leading-6 text-gray-900">
                Contact {index + 1}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                {con}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Contact;
