import { Collapse } from "antd";
import BankInfo from "./BankInfo";
import Cheque from "./Cheque";
import { useSelector } from "react-redux";
const { Panel } = Collapse;
const Banking = () => {
  const selectedMerchantForApprovalBankInfo=useSelector(state=>state.selectedMerchantForApprovalBankInfo)
  const selectedMerchantForApprovalChequeInfo=useSelector(state=>state.selectedMerchantForApprovalChequeInfo)
  // const selectedMerchantForApprovalEwalletInfo=useSelector(state=>state.selectedMerchantForApprovalEwalletInfo)
  return (
    <div className="w-full">
      <div className="p-2 rounded-lg">
        <div className="">
          <Collapse
            // defaultActiveKey={["1"]}
            // accordion={true}
            // onChange={callback}
            className="flex flex-col gap-4 border-none bg-[#F6F4F4]"
          >
            <Panel
              header="Banking Details"
              key="1"
              className="p-3 text-md text-gray-800 font-medium bg-white rounded-md shadow-none border-none"
            >
              <BankInfo data={selectedMerchantForApprovalBankInfo} />
            </Panel>
            <Panel
              header="Cheque Details"
              key="2"
              className="p-3 text-md text-gray-800 font-medium bg-white rounded-md shadow-none border-none"
            >
              <Cheque data={selectedMerchantForApprovalChequeInfo} />
            </Panel>

            {/* <Panel
              header="E-Wallet Details"
              key="3"
              className="p-3 text-md text-gray-800 font-medium bg-white rounded-md shadow-none border-none"
            >
              <Ewallet data={selectedMerchantForApprovalEwalletInfo} />
            </Panel> */}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Banking;
