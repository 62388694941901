import Baccount from "../components/BAccount/Baccount";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const BAccount = () => {
  return (
    <div>
      <Nav />
      <Baccount />
      <Whatsapp />
    </div>
  );
};

export default BAccount;
