import React, { useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
// import axios from "axios";
// import ErrorModal from "../Merchant/Modals/errorModal";
const dateTabs = [
  {
    date: "10 Aug",
    workingHours: ["10:00 AM", "11:00 AM"],
    breakTimes: ["12:00 PM", "01:00 PM"],
  },
  {
    date: "11 Aug",
    workingHours: ["02:00 PM", "03:00 PM"],
    breakTimes: ["04:00 PM", "05:00 PM"],
  },
  {
    date: "12 Aug",
    workingHours: ["06:00 PM", "07:00 PM"],
    breakTimes: ["08:00 PM", "09:00 PM"],
  },
];
const data1 = [
  {
    title: "Verified",
    value: 40618,
    bg: "#1BA397",
  },
  {
    title: "In-process",
    value: 20618,
    bg: "#FFCD69",
  },
  {
    title: "Rejected",
    value: 100618,
    bg: "#CE1126",
  },
  {
    title: "Pending",
    value: 100618,
    bg: "#C1C0BC",
  },
];
const LegendComponent = ({ data }) => (
  <div className="flex flex-col items-start">
    {data.map((entry, index) => (
      <div key={`legend-${index}`} className="flex items-center mb-2">
        <div
          className="w-4 h-4 rounded-full"
          style={{ backgroundColor: entry.bg }}
        />
        <span className="ml-2">{entry.title}</span>
      </div>
    ))}
  </div>
);
const COLORS = data1.map((item) => item.bg);
const SelectLocation = () => {
  const router = useNavigate();
  const [selectedDate, setSelectedDate] = useState(dateTabs[0]);
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Schedule Detail"}
        route={"/scheduleVerification/schedules"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="grid cursor-pointer sm:grid-cols-2 grid-cols-1 gap-4 grid-cols-1  w-full">
                <div className="flex flex-col gap-2">
                  <div className="w-full">
                    <div className="flex justify-between">
                      <span className="font-medium">Selected Center</span>
                      <span className="font-semibold text-gray-400">
                        Kitty Mandir, Region 9
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium">Program</span>
                      <span className=" font-semibold text-gray-400">
                        Senior Citizen Program
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium">Status</span>
                      <span className="font-semibold text-[#E5A608]">
                        In-Process
                      </span>
                    </div>
                  </div>
                  <div className="bg-white p-6  pb-8 shadow-md rounded-md w-full max-w-lg mx-auto">
                    <label className="block font-medium mb-2">
                      Date & Time
                    </label>
                    <div className="flex space-x-4 mb-4">
                      {dateTabs.map((tab, idx) => (
                        <div
                          key={idx}
                          onClick={() => setSelectedDate(tab)}
                          className={`p-2 rounded-md ${
                            selectedDate.date === tab.date
                              ? "bg-primary text-white"
                              : "bg-gray-200"
                          }`}
                        >
                          {tab.date}
                        </div>
                      ))}
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      {selectedDate.workingHours.map((time, idx) => (
                        <div key={idx} className="p-2 bg-gray-100 rounded-md">
                          {time}
                        </div>
                      ))}
                      {selectedDate.breakTimes.map((time, idx) => (
                        <div key={idx} className="p-2 bg-gray-100 rounded-md">
                          {time}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-2 bg-[#FAFAFA] pl-4 pr-4 rounded-md gap-2">
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col gap-1">
                        <span className="font-medium">Total Pensioner</span>
                        <span className="font-semibold bg-white pl-12 pr-12 p-2 text-center">
                          161854
                        </span>
                      </div>
                      <span
                        onClick={() =>
                          router("/scheduleVerification/PensionerDetails")
                        }
                        className="font-medium cursor-pointer text-primary"
                      >
                        View detail
                      </span>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                      <LegendComponent data={data1} />
                    </div>
                    <div className="flex-grow">
                      <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                          <Pie
                            data={data1}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                          >
                            {data1.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-4 mt-6 bg-white rounded-md flex gap-12 ">
                <div className="flex justify-between w-[25%]">
                  <span className="font-medium">Employees</span>
                  <span className="font-medium">15</span>
                </div>
                <span
                  onClick={() =>
                    router("/scheduleVerification/EmployeeDetails")
                  }
                  className="font-medium cursor-pointer text-primary"
                >
                  View detail
                </span>
              </div>
            </div>
          </form>
        </div>
        <div
          className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
        >
          <button
            type="button"
            onClick={() => {
              router("/scheduleVerification/EditSchedule");
            }}
            className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
          >
            Change Schedule
          </button>
        </div>
      </div>
      {/* <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            router("/scheduleVerification/selectProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div> */}
      {/* {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />} */}
    </div>
  );
};

export default SelectLocation;
