import React, { useEffect, useRef, useState } from "react";
import { Avatar, DatePicker, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import ErrorModal from "../Modals/errorModal";
import moment from "moment";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import arrow from "../../../imgs/Vector.png";
const Header = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const token = useSelector((state) => state.token);
  const [showDropdown, setShowDropdown] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [dob, setDob] = useState("");
  // const [searchDob, setSearchDob] = useState("");
  const [gid, setGid] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [addparams, setAddParams] = useState(false);
  const [pensioners, setPensioners] = useState([]);
  const [history, setHistory] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [count, setCount] = useState(1);
  // const [dataFetched, setDataFetched] = useState(false);
  const user = useSelector((state) => state.user);
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );

  const firstName =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.user?.person?.firstName
      : merchantEmployeeInfo?.data?.merchPerson?.firstName;
  const lastNameShow =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.user?.person?.lastName
      : merchantEmployeeInfo?.data?.merchPerson?.lastName;

  const dropdownRef = useRef(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const items = [
    {
      label: "Profile ",
      key: "1",
      icon: <UserOutlined className="some" />,
    },
    {
      label: "Log Off",
      key: "2",
      icon: <LogoutOutlined className="some" />,
    },
  ];

  const onClick = ({ key }) => {
    if (key === "1") {
      dispatch({ type: "SIDEBAR", num: "5" });
      router("/Merchant/profile");
    } else if (key === "2") {
      handleLogOff();
    }
  };

  //logout
  const handleLogOff = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-auth/logout`, {
        refreshToken: `${dataUser?.data?.merchTokens?.refresh?.token}`,
      })
      .then(() => {
        dispatch({ type: "authStateSet", num: "false" });
        dispatch({ type: "tokenSet", num: "0" });
        dispatch({ type: "tokenRef", num: "0" });
        dispatch({ type: "setUserData", num: [] });
        dispatch({ type: "BRSET", num: "0" });
        dispatch({ type: "RSET", num: 0 });
        dispatch({ type: "ISET", num: 1 });
        dispatch({ type: "BRSET", num: 0 });
        dispatch({ type: "BISET", num: 0 });
        dispatch({ type: "ASET", num: 0 });
        router("/");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };

  useEffect(() => {
    dispatch({ type: "ALL_TRANSACTIONS", num: "grid" });
  }, []);

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
      if (event) {
        const wow = parsePhoneNumber(event);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };

  const Search = () => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        ...(gid && { gid }),
        ...(lastName && { lastName }),
        ...(dob && { dateOfBirth: dob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        ...(email && { email }),
      };

      fetchPenioners(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  };
  const fetchPenioners = (params, cancelTokenSource) => {
    // setShow(false);
    let api;
    // if (gid !== "" && email === "" && lastName === "" && dob === "" && countryCode === null && phoneNoo === null) {
    //     api = "pensioner-profile-by-gid"
    // }
    // else{
    api = "pensioner-profile-by-filters";
    // }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/${api}/by-merchant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        // if (gid !== "" && email === "" && lastName === "" && dob === "" && countryCode === null && phoneNoo === null) {
        //     dispatch({ type: "SELECTED_PENSIONER", num: data?.data });
        //     router(`/Merchant/PensionerProfile/${data?.data?.person?.id}`)
        // }
        // else{
        if (data?.data?.length === 0) {
          setErr("No Pensioner Found");
          setShowModal(true);
        } else {
          // setShowDropdown(true);
          setHistory(false);
          setPensioners(data?.data);
        }
        // }
      })
      .catch((err) => {
        // setShow(false);
        setErr(err.response.data.message);
        setShowModal(true);
        console.log(err);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    dispatch({ type: "SELECTED_PENSIONER", num: {} });
    dispatch({ type: "SELECTED_PENSIONER_VOUCHERS", num: [] });
    dispatch({ type: "ACTIVE_PENSIONER_SESSION", num: null });
    dispatch({ type: "ACTIVE_VOUCHERS", num: [] });
  }, []);
  //Search History
  useEffect(() => {
    if (showDropdown && count === 1) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-pensioner-search-history/own`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setPensioners(response?.data);
          setCount(2);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [showDropdown, refetch]);

  // Reset dataFetched when modal is closed
  useEffect(() => {
    if (!showDropdown) {
      // setDataFetched(false);
    }
  }, [showDropdown]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setShowDropdown(true);
  };

  const handleSelectOption = (option) => {
    setSearchTerm(`${option.name} - ${option.gid}`);
    setShowDropdown(false);
  };
  return (
    <div className="p-4 flex justify-between items-center sm:flex-row flex-col-reverse z-10">
      {/* Search Container */}
      {/* <div className="flex items-center sm:w-[33%] w-[50%]" ref={dropdownRef}> */}
      <div
        className={`${
          dataUser?.data?.merchuser?.status === "pending" ||
          dataUser?.data?.merchuser?.status === "deferred" ||
          dataUser?.data?.merchuser?.status === "rejected"
            ? "pointer-events-none flex items-center sm:w-[35%] w-[90%] sm:pt-0 pt-5"
            : "flex items-center sm:w-[35%] w-[90%] sm:pt-0 pt-5"
        }`}
      >
        <label className="sr-only">Search</label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2      "
            placeholder="Search Pensioner"
            required=""
            value={searchTerm}
            onChange={handleInputChange}
            // onClick={() => setShowDropdown(true)}
            onClick={() => setShowDropdown(!showDropdown)}
          />
          {showDropdown && (
            <div className="z-50 origin-top-left absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 max-h-96 overflow-y-auto overflow-y-scroll custom-scrollbar">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="h-7 w-7 absolute top-2 right-2 mb-6  cursor-pointer text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                onClick={showHandler}
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clipRule="evenodd"
                />
              </svg> */}
              <div className="relative">
                {show && (
                  <div className="left-0 mt-0  p-3 pr-4 pl-4  rounded-md  bg-white ring-1 ring-black ring-opacity-5">
                    <input
                      type="text"
                      // id="gid-search"
                      value={gid}
                      onChange={(e) => {
                        setGid(e.target.value);
                      }}
                      className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
                                
                                `}
                      placeholder="Search By GID"
                    />
                    <button
                      onClick={() => {
                        setAddParams(true);
                      }}
                      className={` ${
                        addparams
                          ? "hidden"
                          : "text-primary font-semibold p-2  w-full"
                      }`}
                    >
                      Search with additional parameters
                    </button>

                    <div className={`${addparams ? "" : "hidden"}`}>
                      <div className="mt-2">
                        <input
                          type="text"
                          // id="gid-search"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3"
                          placeholder="Last Name"
                        />
                      </div>

                      <div className="mt-2">
                        <DatePicker
                          onChange={(e, f) => setDob(f)}
                          value={dob !== "" ? moment(dob) : null}
                          placeholder="Date of birth"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setShowPhone(true);
                      }}
                      className={`
                                ${
                                  showPhone
                                    ? "hidden"
                                    : "mt-2 flex items-center justify-center"
                                }
                                ${
                                  addparams
                                    ? "mt-2 flex items-center justify-center"
                                    : "hidden"
                                }`}
                    >
                      <button className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center">
                        +
                      </button>
                    </div>

                    <div
                      className={`${
                        showPhone
                          ? "mt-2 flex items-center justify-center"
                          : "hidden"
                      }`}
                    >
                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="GY"
                        value={prompt}
                        onChange={handleChange(setPrompt, "tt")}
                        international
                        countryCallingCodeEditable={false}
                      />

                      {/* Button to hide phone number again */}
                      <button
                        onClick={() => setShowPhone(false)}
                        className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center ml-2"
                      >
                        -
                      </button>
                    </div>

                    <div
                      onClick={() => {
                        setShowEmail(true);
                      }}
                      className={`                              
                                ${
                                  showEmail
                                    ? "hidden"
                                    : "mt-2 flex items-center justify-center"
                                }
                                ${
                                  addparams && showPhone
                                    ? "mt-2 flex items-center justify-center"
                                    : "hidden"
                                }`}
                    >
                      <button className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center">
                        +
                      </button>
                    </div>

                    <div
                      className={`${
                        showEmail
                          ? "mt-2 flex items-center justify-center"
                          : "hidden"
                      }`}
                    >
                      <input
                        type="text"
                        // id="gid-search"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3"
                        placeholder="Email"
                      />

                      {/* Button to hide phone number again */}
                      <button
                        onClick={() => setShowEmail(false)}
                        className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center ml-2"
                      >
                        -
                      </button>
                    </div>

                    <div className="mt-2 flex w-full gap-2 justify-center">
                      <button
                        onClick={() => {
                          // if (prompt) {
                          //     const wow = parsePhoneNumber(prompt);
                          //     if (wow) {
                          //         let count = wow.countryCallingCode;
                          //         let num = wow.nationalNumber;
                          //         setCountryCode(count);
                          //         setPhoneNoo(num);
                          //     }
                          // }

                          // Set values and make the search call
                          if (dob !== null && dob !== "") {
                            // setSearchDob(dob);
                          }

                          // Hide the modal
                          // setShow(false);

                          // Make the search call
                          Search();
                        }}
                        className={`bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8 ${
                          gid === "" &&
                          email === "" &&
                          lastName === "" &&
                          dob === "" &&
                          countryCode === null &&
                          phoneNoo === null
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        Search
                      </button>

                      <button
                        onClick={() => {
                          setGid("");
                          setEmail("");
                          // setSearchDob("");
                          setDob("");
                          setPhoneNoo(null);
                          setPrompt("");
                          setCountryCode(null);
                          // setShow(false);
                          setAddParams(false);
                          setShowPhone(false);
                          setShowEmail(false);
                          if (
                            gid !== "" ||
                            email !== "" ||
                            lastName !== "" ||
                            dob !== "" ||
                            countryCode !== null ||
                            phoneNoo !== null
                          ) {
                            setRefetch(!refetch);
                            setShowDropdown(true);
                            setShowPhone(false);
                            setShowEmail(false);
                            setCount(1);
                          }
                        }}
                        className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <hr />
              {pensioners.length === 0 ? (
                <p className="text-center p-4 text-gray-400">
                  No Pensioner Found
                </p>
              ) : (
                <div>
                  <p className="text-center p-2 text-gray-400 mt-1">
                    Recent Search
                  </p>
                  <ul className="py-1">
                    {pensioners
                      ?.filter((option) =>
                        `${
                          history
                            ? option?.pensionerUser?.person?.firstName
                            : option?.user?.person?.firstName
                        } - ${
                          history
                            ? option?.pensionerUser?.gid
                            : option?.user?.gid
                        }`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((option) => (
                        <li
                          key={
                            history
                              ? option?.pensionerUser?.person?.id
                              : option?.user?.person?.id
                          }
                          onClick={() => {
                            handleSelectOption(option);
                            dispatch({
                              type: "SELECTED_PENSIONER",
                              num: option,
                            });
                            dispatch({
                              type: "SELECTED_PENSIONER_PROGRAMS",
                              num: option?.pensionPrograms,
                            });
                            router(
                              `/Merchant/PensionerProfile/${
                                history
                                  ? option?.pensionerUser?.person?.id
                                  : option?.user?.person?.id
                              }`
                            );
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer bg-[#F4F5FE] mx-3 my-2 rounded-lg border-2 border-[#E9E9E9]"
                        >
                          <div className="flex items-center gap-2">
                            <Avatar
                              src={
                                history
                                  ? option?.pensionerUser?.person?.image
                                  : option?.user?.person?.image
                              }
                              style={{
                                backgroundColor: "#1BA397",
                              }}
                              shape="circle"
                              size={44}
                            />
                            <div>
                              <h1 className="text-[1rem] font-semibold mb-1">
                                {history
                                  ? option?.pensionerUser?.person?.firstName
                                  : option?.user?.person?.firstName}{" "}
                                {history
                                  ? option?.pensionerUser?.person?.lastName
                                  : option?.user?.person?.lastName}
                              </h1>
                              <p>
                                GID:{" "}
                                {history
                                  ? option?.pensionerUser?.gid
                                  : option?.user?.gid}
                              </p>
                            </div>
                            {/* <div className="ml-auto flex items-center gap-1">
                              <img
                                className="p-2 border-2 border-gray-300 bg-white rounded-lg w-[2.5rem] text-center"
                                src={
                                  option?.user?.roles?.[0] === "pensioner"
                                    ? pen
                                    : ben
                                }
                                alt=""
                              />
                              <span className="p-2 border-2 border-gray-300 bg-gray-400 rounded-lg w-[2.5rem] text-center">
                                {option?.user?.person?.gender === "Male"
                                  ? "M"
                                  : "F"}
                              </span>
                            </div> */}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Profile Photo */}
      <div className="flex items-center space-x-2 mr-2 hidden sm:flex">
        <Avatar
          src={
            user === "merchant-owner" || user === "merchant-admin"
              ? dataUser?.data?.merchuser?.merchCompany?.logo
              : merchantEmployeeInfo?.data?.merchPerson?.image
          }
          style={{
            backgroundColor: "#1BA397",
          }}
          shape="circle"
          size={48}
        />
        <div className="text-center">
          <span className="block font-bold text-gray-600">
            {/* {dataUser?.data?.merchuser?.merchCompany?.name}  */}
            <Dropdown
              menu={{
                items,
                onClick,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className="min-w-[100px] flex items-center capitalize  justify-center">
                  {(dataUser?.data?.user?.person ||
                    merchantEmployeeInfo?.data?.merchPerson) && (
                    <h2
                      className={`font-bold ${
                        user === "merchant-employee" ? "" : "hidden"
                      }`}
                    >
                      {firstName}&nbsp;
                      {lastNameShow}
                    </h2>
                  )}
                  <h2
                    className={`font-bold ${
                      user === "merchant-employee" ? "hidden" : ""
                    }`}
                  >
                    {dataUser?.data?.merchuser?.merchCompany?.name}
                  </h2>
                  <img
                    src={arrow}
                    alt=""
                    className="ml-1 mt-1 flex items-center justify-center sm:block hidden"
                  />
                </div>
              </a>
            </Dropdown>
          </span>
          <span className="block text-gray-600">
            <span className="font-semibold text-primary capitalize sm:block hidden">
              Today {moment(new Date()).format("MMM DD, YYYY")}
            </span>
          </span>
        </div>
        {/* <Badge count={9} overflowCount={10} offset={[0, 10]} style={{
                    backgroundColor: '#166944',
                }}> */}
        {/* <Avatar src={dataUser?.data?.merchuser?.merchCompany?.logo} style={{
                    backgroundColor: '#1BA397',
                }} shape="circle" size={48} /> */}
        {/* </Badge> */}
      </div>

      {showModal && (
        <ErrorModal show={showModal} setShow={setShowModal} err={err} />
      )}
    </div>
  );
};

export default Header;
