import React, { useState } from "react";
import icon from "../../../../../imgs/cashIcon.png";
import PhoneVerification from "../VerficationModals/PhoneVerification";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import SuccessModal from "../../../../Merchant/Modals/successModal";
import axios from "axios";
import { useSelector } from "react-redux";
const CashMethods = () => {
  const token = useSelector((state) => state.token);
  const [value, setValue] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const selectedOutstandingMerchant = useSelector(
    (state) => state.selectedOutstandingMerchant
  );
  console.log("selectedoutstandingmerchant", selectedOutstandingMerchant);
  const headingsData = [
    {
      value: "otp",
      heading: "OTP",
      title: "Velidate the profile by registered phone",
      imageUrl: icon,
    },
    {
      heading: "Pin Code",
      value: "pin",
      title: "Velidate the profile by registered phone",
      imageUrl: icon,
    },
    {
      value: "ques",
      heading: "Security Question",
      title: "Velidate the profile by registered phone",
      imageUrl: icon,
    },
    {
      heading: "Email Verification",
      value: "email",
      title: "Velidate the profile by registered phone",
      imageUrl: icon,
    },
  ];
  const sendOTP = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/employee-verify-merchant/send-phone-otp?merchUserId=${selectedOutstandingMerchant?.merchuser?.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setValue("otp");
      })
      .catch(() => {
        setShowErrModal(true);
        setErr("Something went wrong, please try again later.");
      });
  };

  const sendEmail = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/employee-verify-merchant/send-email-otp?merchUserId=${selectedOutstandingMerchant?.merchuser?.id}`,
        null,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setValue("email");
      })
      .catch(() => {
        setShowErrModal(true);
        setErr("Something went wrong, please try again later.");
      });
  };
  return (
    <div>
      <div className="w-full mt-4 p-2">
        <h1 className="font-semibold">Step 1 : Verify the Merchant</h1>
        <div className="w-full flex flex-wrap mt-4 gap-4">
          {headingsData.map((item, index) => (
            <div
              key={index}
              // onClick={()=>router("/MHSSS/cashPay")}
              onClick={() =>
                item?.value === "otp"
                  ? sendOTP()
                  : item?.value === "email"
                  ? sendEmail()
                  : setValue(item.value)
              }
              className="w-[40%] p-2 flex cursor-pointer gap-4 bg-[#D9EFED] rounded-full pl-6 pr-6"
            >
              <div className="flex items-center h-full ">
                <img
                  src={item.imageUrl}
                  alt={item.heading}
                  className="h-full"
                />
              </div>
              <div className="flex flex-col w-[80%]">
                <h2 className="font-semibold">{item.heading}</h2>
                <p className="text-[#5D5C5C]">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {value === "otp" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {value === "email" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {
        value === "pin" && (
          <PhoneVerification
            setValue={setValue}
            value={value}
            setShowErrModal={setShowErrModal}
            err={err}
            setErr={setErr}
            setShowSuccessModal={setShowSuccessModal}
          />
        )
      }
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
      {showSuccessModal && (
        <SuccessModal
          setShow={setShowSuccessModal}
          err={err}
          setValue={setValue}
          verify={"Verify"}
        />
      )}
    </div>
  );
};

export default CashMethods;
