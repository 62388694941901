
export const UnionStatus=[
    {
        value: "common law",
        label: "Common Law",
    },
    {
        value: "divorced",
        label: "Divorced",
    },
    {
        value: "married",
        label: "Married",
    },
    {
        value: "separated",
        label: "Separated",
    },
    {
        value: "single",
        label: "Single",
    },
    {
        value: "visiting relation",
        label: "Visiting Relation",
    },
    {
        value: "widowed",
        label: "Widowed",
    },
]
export const Religion = [
    {
        value: "Affrican Traditional Religions",
        label: "Affrican Traditional Religions",
    },
    {
        value: "Animism",
        label: "Animism",
    },
    {
        value: "Baha",
        label: "Baha",
    },
    {
        value: "Buddhism",
        label: "Buddhism",
    },
    {
        value: "Cao Dai",
        label: "Cao Dai",
    },
    {
        value: "Chinese traditional religion",
        label: "Chinese traditional religion",
    },
    {
        value: "Christianity",
        label: "Christianity",
    },
    {
        value: "Islam",
        label: "Islam",
    },
    {
        value: "Jainism",
        label: "Jainism",
    },
    {
        value: "Judaism",
        label: "Judaism",
    },
    {
        value: "Neo-Paganism",
        label: "Neo-Paganism",
    },
    {
        value: "Rastafari",
        label: "Rastafari",
    },
    {
        value: "Secular/Nonreligious/Agnostic/Atheist",
        label: "Secular/Nonreligious/Agnostic/Atheist",
    },
    {
        value: "Shinto",
        label: "Shinto",
    },
    {
        value: "Sikhism",
        label: "Sikhism",
    },
    {
        value: "Spiritism",
        label: "Spiritism",
    },
    {
        value: "Tenrikyo",
        label: "Tenrikyo",
    },
    {
        value: "Unitarian Universalism",
        label: "Unitarian Universalism",
    },
    {
        value: "Zoroastrianism",
        label: "Zoroastrianism",
    },
    {
        value: "Others",
        label: "Others",
    },
]

export const Ethnicity = [
    {
        value: "African",
        label: "African",
    },
    {
        value: "Amerindian",
        label: "Amerindian",
    },
    {
        value: "Chinese",
        label: "Chinese",
    },
    {
        value: "East Indian",
        label: "East Indian",
    },
    {
        value: "European",
        label: "European",
    },
    {
        value: "Mixed Race",
        label: "Mixed Race",
    },
    {
        value: "Portuguese",
        label: "Portuguese",
    },
    {
        value: "Other",
        label: "Other",
    },
]

export const  Positions=[
        { value: "Chief Executive Officer (CEO)", label: "Chief Executive Officer (CEO)" },
        { value: "Chief Operating Officer (COO)", label: "Chief Operating Officer (COO)" },
        { value: "Chief Financial Officer or Controller (CFO)", label: "Chief Financial Officer or Controller (CFO)" },
        { value: "Chief Marketing Officer (CMO)", label: "Chief Marketing Officer (CMO)" },
        { value: "Chief Technology Officer (CTO)", label: "Chief Technology Officer (CTO)" },
        { value: "President", label: "President" },
        { value: "Vice President", label: "Vice President" },
        { value: "Executive Assistants", label: "Executive Assistants" },
        { value: "Marketing Manager", label: "Marketing Manager" },
        { value: "Product Manager", label: "Product Manager" },
        { value: "Project Manager", label: "Project Manager" },
        { value: "Finance Manager", label: "Finance Manager" },
        { value: "Human Resources Manager", label: "Human Resources Manager" },
        { value: "Customer Service Representative", label: "Customer Service Representative" },
        { value: "Sales Representative", label: "Sales Representative" },
        { value: "Accountant", label: "Accountant" },
        { value: "Human Resource Personnel", label: "Human Resource Personnel" },
        { value: "Business Analyst", label: "Business Analyst" },
        { value: "Marketing Specialist", label: "Marketing Specialist" },
        { value: "Purchasing Manager", label: "Purchasing Manager" },
        { value: "Office Manager", label: "Office Manager" },
        { value: "Quality Control/Safety Manager", label: "Quality Control/Safety Manager" },
        { value: "Customer Service Representative", label: "Customer Service Representative" },
        { value: "Admin / Clerks", label: "Admin / Clerks" },
        { value: "Interns / Trainees", label: "Interns / Trainees" },
        { value: "Sales Manager", label: "Sales Manager" },
        { value: "Marketing Specialist", label: "Marketing Specialist" },
        { value: "Operations Coordinator", label: "Operations Coordinator" },
        { value: "IT Support Specialist", label: "IT Support Specialist" },
        { value: "Financial Analyst", label: "Financial Analyst" },
        { value: "Warehouse Manager", label: "Warehouse Manager" },
        { value: "Coordinator", label: "Coordinator" },
        { value: "General Laborer", label: "General Laborer" },
        { value: "General Staff", label: "General Staff" },
        { value: "Store Manager", label: "Store Manager" },
        { value: "Supply Chain Manager", label: "Supply Chain Manager" },
        { value: "Customer Service Manager", label: "Customer Service Manager" },
        { value: "Facilities Manager", label: "Facilities Manager" },
        { value: "Operation Director", label: "Operation Director" },
        { value: "Bookkeeper", label: "Bookkeeper" },
        { value: "Senior Accountant", label: "Senior Accountant" },
        { value: "Tax Accountant", label: "Tax Accountant" },
        { value: "Supervisor", label: "Supervisor" },
        { value: "Receptionist", label: "Receptionist" },
        { value: "Administrative Secretary", label: "Administrative Secretary" },
        { value: "Front Desk Supervisor", label: "Front Desk Supervisor" },
        { value: "IT Manager", label: "IT Manager" },
        { value: "Advertising Manager", label: "Advertising Manager" },
        { value: "Marketing Manager", label: "Marketing Manager" },
        { value: "Public Relations Manager", label: "Public Relations Manager" },
      ];
    

export const Nationalties = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
];