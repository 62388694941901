import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownCircleFilled, UpCircleFilled } from "@ant-design/icons";
import across from "../../../imgs/across.png";
import { Select } from "antd";
const Banking1 = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const token = useSelector((state) => state.token);
  const [cheque, setCheque] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [bankName, setBankName] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNo, setAccountNo] = useState(null);
  const [routingNo, setRoutingNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [address, setAddress] = useState("");
  const [emailCheque, setEmailCheque] = useState("");
  const [phoneCheque, setPhoneCheque] = useState("");
  const [addressCheque, setaddressCheque] = useState("");
  const [checkBank, setCheckBank] = useState(false);
  const [checkCheque, setCheckCheque] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [allBranches, setAllBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [confirm, setConfirm] = useState("");
  const [branchName, setBranchName] = useState("");
  console.log(bankName, allBanks, branchName);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/country-banks/merch`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        const BankOptions = data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        setAllBanks(BankOptions);
        setBanks(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedBank === null) return;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/country-bank-branches/merch?countrybankId=${selectedBank}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        const BranchOptions = data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        setAllBranches(BranchOptions);
        setBranches(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedBank]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
    valHandler();
  };

  const conHandler = (e) => {
    setConfirm(e.target.value);
    valHandler();
  };
  const valHandler = () => {
    console.log(accountNo, confirm, accountNo === confirm);

    if (accountNo !== confirm) {
      // document.getElementById("message").style.color = "green";
      document.getElementById("message1").style.color = "#596F96";
      document.getElementById("message2").style.color = "#596F96";
      document.getElementById("message1").innerHTML = "Bank Account No";
      document.getElementById("message2").innerHTML = "Confirm Bank Account No";
      // setVal(false);
    } else {
      // document.getElementById("message").style.color = "red";
      document.getElementById("message1").style.color = "red";
      document.getElementById("message2").style.color = "red";
      document.getElementById("message1").innerHTML = "Bank Account No**";
      document.getElementById("message2").innerHTML =
        "Confirm Bank Account No**";
      // setVal(true);
    }
  };

  const nextHandler = (e) => {
    e.preventDefault();

    if (accountTitle || accountNo || routingNo || accountType || address) {
      let body = {
        countrybankId: selectedBank,
        countrybankbranchId: selectedBranch,
        // bankName: bankName,
        accountTitle: accountTitle,
        accountNo: accountNo,
        accountType: accountType,
        routingNo: routingNo,
        // address: address,
        // branchName: branchName,
      };

      if (selectedBank === null) {
        setErr("Please select a bank");
        setShow(true);
        return;
      }

      if (selectedBranch === null) {
        setErr("Please select a branch");
        setShow(true);
        return;
      }

      if (accountNo === null) {
        setErr("Please enter account number");
        setShow(true);
        return;
      }

      if (accountNo !== confirm) {
        setErr("Account number and confirm account number must be the same");
        setShow(true);
        return;
      }

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setCheckBank(true);
          // dispatch({ type: "IINCREMENT" });
        })
        .catch((err) => {
          setCheckBank(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      setErr("All of bank fields are required");
      setShow(true);
    }
    if (emailCheque || phoneCheque || addressCheque) {
      let data = {
        email: emailCheque,
        phone: phoneCheque,
        mailingAddress: addressCheque,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setCheckCheque(true);
          // dispatch({ type: "IINCREMENT" });
        })
        .catch((err) => {
          setCheckCheque(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      setCheckCheque(true);
    }
    // if (emailWallet || phoneWallet) {
    //   let data = {
    //     email: emailWallet,
    //     phone: phoneWallet,
    //   };
    //   axios
    //     .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-ewallet-info`, data, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //     .then((data) => {
    //       setCheckWallet(true);
    //       // dispatch({ type: "IINCREMENT" });
    //     })
    //     .catch((err) => {
    //       setCheckWallet(false);
    //       setErr(err.response.data.message);
    //       setShow(true);
    //     });
    // } else {
    //   setCheckWallet(true);
    // }
  };
  const checkHandler = (e) => {
    setAccountType(e.target.value);
  };
  useEffect(() => {
    console.log("test", checkBank, checkCheque);
    if (checkBank === true && checkCheque === true) {
      //&& checkWallet === true
      dispatch({ type: "IINCREMENT" });
    }
  }, [checkBank, checkCheque]);

  const SelectBank = (e) => {
    setBranches([]);
    setSelectedBranch(null);
    setBranchName("");
    setSelectedBank(e);
    console.log(e, "e");
    console.log("banks", banks);
    const selectedBankValue = banks.find((item) => item.id === e);
    setBankName(selectedBankValue.id);
  };

  const SelectBranch = (e) => {
    setSelectedBranch(e);
    const selectedBranchValue = branches.find((item) => item.id === e);
    setBranchName(selectedBranchValue.id);
    setAddress(selectedBranchValue.address);
  };

  const options = banks.map((item) => ({
    value: item.id,
    label: item.name,
    imageUrl: item.logo,
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>{option?.label}</span>
      </div>
    </Option>
  );
  return (
    <div>
      <div className="flex justify-center">
        <div className=" mt-10 grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[70%]">
          <div className="w-full">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Select Bank
            </h2>
            {/* <Select
              placeholder="Select Bank"
              className="wow w-[100%] mb-4"
              options={allBanks}
              optionFilterProp="children"
              onChange={(e) => SelectBank(e)}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            /> */}
            <Select
              placeholder="Select Bank"
              // className="wow w-[100%] mb-4"
              className="w-[100%] woww mb-4"
              renderOption={renderOption}
              onChange={(e) => SelectBank(e)}
              value={selectedBank}
            >
              {options?.map(renderOption)}
            </Select>
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Select Branch
            </h2>
            <Select
              placeholder="Select Branch"
              className="wow w-[100%] mb-4"
              options={allBranches}
              optionFilterProp="children"
              onChange={(e) => SelectBranch(e)}
              value={selectedBranch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />

            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Name(s) on Account <span className="text-red-600">*</span>
            </h2>
            <input
              type="text"
              id="password"
              onChange={(e) => setAccountTitle(e.target.value)}
              value={accountTitle}
              placeholder="Name(s) on Account"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2
              id="message1"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Bank Account No <span className="text-red-600">*</span>
            </h2>
            <input
              type="text"
              name=""
              id="password"
              onChange={handleChange(setAccountNo)}
              value={accountNo}
              placeholder="Bank Account No"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2
              id="message2"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Confirm Bank Account No <span className="text-red-600">*</span>
            </h2>
            <input
              type="text"
              value={confirm}
              onChange={conHandler}
              placeholder="Confirm Bank Account No"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div className="w-full">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Routing # <span className="text-red-600">*</span>
            </h2>
            <input
              type="text"
              onChange={(e) => setRoutingNo(e.target.value)}
              value={routingNo}
              placeholder="Routing #"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Branch & Address <span className="text-red-600">*</span>
            </h2>
            <textarea
              type="text"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              placeholder="Add address"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-2"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Bank Account Type <span className="text-red-600">*</span>
            </h2>
            <div className="mb-4 mt-2">
              <input
                type="radio"
                id="saving"
                name="fav_language"
                value="Saving"
                onChange={checkHandler}
                checked={accountType === "Saving"}
              ></input>
              <label htmlFor="saving" className="mr-8 ml-1 text-[0.9rem]">
                Saving
              </label>
              <input
                type="radio"
                id="chequing"
                name="fav_language"
                onChange={checkHandler}
                checked={accountType === "Chequing"}
                value="Chequing"
              ></input>
              <label htmlFor="chequing" className=" ml-1 text-[0.9rem]">
                Chequing
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center mt-4 flex-col">
        <div className="w-[70%] text-black-500 text-[0.98rem] font-semibold mb-3">
          <h2>Additional Financial info</h2>
        </div>
        <div className="flex items-center w-[70%] text-black-500 text-[0.98rem] font-semibold mb-3 rounded-[1rem] bg-slate-200 p-2">
          <span className="w-[90%]">Cheque</span>
          <span
            onClick={() => setCheque(true)}
            className={`${cheque ? "hidden" : ""} cursor-pointer ml-auto mb-2`}
          >
            <DownCircleFilled className=" text-primary text-xl mr-2" />
          </span>
          <span
            onClick={() => setCheque(false)}
            className={`${cheque ? "" : "hidden"} cursor-pointer ml-auto mb-2`}
          >
            <UpCircleFilled className=" text-primary text-xl mr-2" />
          </span>
        </div>
        <div
          className={`${
            cheque ? "" : "hidden"
          } mt-4 mb-4 grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[70%]`}
        >
          <div className="w-full">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Email
            </h2>
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => setEmailCheque(e.target.value)}
              value={emailCheque}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div className="w-full">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Phone Number
            </h2>
            <input
              type="text"
              onChange={(e) => setPhoneCheque(e.target.value)}
              value={phoneCheque}
              placeholder="Phone Number"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div>
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Mailing Address
            </h2>
            <textarea
              type="text"
              onChange={(e) => setaddressCheque(e.target.value)}
              value={addressCheque}
              placeholder="Add mailing address"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-2"
            />
          </div>
        </div>
        {/* <div className="flex items-center w-[70%] text-black-500 text-[0.98rem] font-semibold mb-3 rounded-[1rem] bg-slate-200 p-2">
          <span className="w-[90%]">E-Wallet</span>
          <span
            onClick={() => setWallet(true)}
            className={`${wallet ? "hidden" : ""} cursor-pointer ml-auto mb-2`}
          >
            <DownCircleFilled className=" text-primary text-xl mr-2" />
          </span>
          <span
            onClick={() => setWallet(false)}
            className={`${wallet ? "" : "hidden"} cursor-pointer ml-auto mb-2`}
          >
            <UpCircleFilled className=" text-primary text-xl mr-2" />
          </span>
        </div> */}
        {/* <div
          className={`${
            wallet ? "" : "hidden"
          } mt-4 mb-4 grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[70%]`}
        >
          <div className="w-full">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Email
            </h2>
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => setEmailWallet(e.target.value)}
              value={emailWallet}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div className="w-full">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Phone Number
            </h2>
            <input
              type="text"
              onChange={(e) => setPhoneWallet(e.target.value)}
              value={phoneWallet}
              placeholder="Phone Number"
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
        </div> */}
      </div>
      <div className="lg:mt-[3rem] flex mt-8 items-center sm:justify-end justify-center w-[85%]">
        <button
          type="button"
          onClick={nextHandler}
          className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banking1;
