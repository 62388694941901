import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup/popup";
import { useDispatch, useSelector } from "react-redux";
import Vpopup from "../components/Popup/Vpopup";
import Vppopup from "../components/Popup/Vppopup";
import logo from "../imgs/impLogo.png";
import { useEffect } from "react";
import Register from "../components/Register/Register";
import Login from "../components/Login/login";
const Initial = () => {
  const option = useSelector((state) => state.option);
  const rcurrent = useSelector((state) => state.rcurrent);
  const brcurrent = useSelector((state) => state.brcurrent);
  const Show1 = [Login, Register];
  const Dis1 = Show1[rcurrent];
  const router = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (brcurrent === 2) {
      dispatch({ type: "BRSET", num: 0 });
      dispatch({ type: "answersSet", num: [] });
    }
  }, []);
  return (
    <div>
      <div className=" w-full min-h-screen flex pb-12 items-center flex-col bg-[#D9EFED]">
        <div className="sm:w-[80%] mb-4 w-[80%] flex justify-between items-center">
          <div
            className="pt-2 mb-1 flex items-center justify-start cursor-pointer"
            onClick={() => {
              dispatch({ type: "PENSIONER" });
              dispatch({ type: "RSET", num: 0 });
              dispatch({ type: "ISET", num: 1 });
              dispatch({ type: "ASET", num: 0 });
              dispatch({ type: "BRSET", num: 0 });
              dispatch({ type: "IdDoneSet", num: "" });
              dispatch({ type: "answersSet", num: [] });
            }}
          >
            <img
              className="mt-2 sm:w-[6rem] w-[4rem]"
              src={logo}
              alt=""
              onClick={() => {
                router("/");
              }}
            />
          </div>
          <div className="sm:text-[2rem] w-full flex items-center justify-center text-[1.3rem] mt-[0.5rem] font-bold">
            {option === "Merchant" ? (
              <p
                onClick={() => {
                  router("/");
                }}
                className=" sm:mr-[6rem] mr-0 cursor-pointer"
              >
                Merchant
                {/* <span className="text-primary cursor-pointer">Pension</span> */}
              </p>
            ) : (
              <p
                onClick={() => {
                  router("/");
                }}
                className=" sm:mr-[6rem] mr-0 cursor-pointer"
              >
                Senior Citizen{" "}
                <span className="text-primary cursor-pointer">Pension</span>
              </p>
            )}
          </div>
        </div>
        <div className="w-[90%] flex justify-center items-center">
          <div
            className={`bg-gradient-to-b bg-[#F8F5F5] border-white sm:min-h-[25rem]  sm:pb-[3.5rem] pb-[2rem] overflow-hidden w-[90%] mb-[2rem] shadow-2xl rounded-2xl rounded-tl-2xl`}
          >
            <Dis1 />
          </div>
        </div>
      </div>
      <Popup />
      <Vpopup />
      <Vppopup />
    </div>
  );
};

export default Initial;
