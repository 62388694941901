import React from 'react'
import Dash from "../components/MHSSS Accounting/dash/dashboard"

const DashB = () => {
  return (
    <div>
     <Dash/>
    </div>
  )
}

export default DashB