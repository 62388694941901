import { Pagination } from "antd";
import { useState } from "react";
const PensionerTable = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  setTotalPages(1);
  const [search, setSearch] = useState("");
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const columnHeaders = [
    "SR.",
    "Driver Name",
    "Driver Contact",
    "Vendor Name",
    "Vendor Contact",
    "Vehicle Manufacturer",
    "License Plate",
    "Vehicle Location",
    "Last Maintenance Date",
    "Next Maintenance Date",
    "Bill",
    "Maintenance Type",
  ];

  const maintenanceData = [
    {
      DriverName: "John Doe",
      DriverContact: "555-1234",
      VendorName: "AutoTech Services",
      VendorContact: "555-5678",
      VehicleManufacturer: "Toyota",
      LicensePlate: "ABC123",
      VehicleLocation: "New York",
      LastMaintenanceDate: "2024-05-15",
      NextMaintenanceDate: "2024-11-15",
      bill: "$200",
      MaintenanceType: "Routine Checkup",
    },
    {
      DriverName: "Alice Johnson",
      DriverContact: "555-8765",
      VendorName: "Speedy Auto Repairs",
      VendorContact: "555-4321",
      VehicleManufacturer: "Ford",
      LicensePlate: "DEF456",
      VehicleLocation: "Los Angeles",
      LastMaintenanceDate: "2024-04-20",
      NextMaintenanceDate: "2024-10-20",
      bill: "$200",
      MaintenanceType: "Major Service",
    },
    {
      DriverName: "Charlie Davis",
      DriverContact: "555-9876",
      VendorName: "City Garage",
      VendorContact: "555-5432",
      VehicleManufacturer: "Tesla",
      LicensePlate: "GHI789",
      VehicleLocation: "San Francisco",
      LastMaintenanceDate: "2024-03-10",
      NextMaintenanceDate: "2024-09-10",
      bill: "$200",
      MaintenanceType: "Battery Replacement",
    },
    {
      DriverName: "Eve Foster",
      DriverContact: "555-3456",
      VendorName: "TechPro Auto Care",
      VendorContact: "555-7890",
      VehicleManufacturer: "BMW",
      LicensePlate: "JKL012",
      VehicleLocation: "Chicago",
      LastMaintenanceDate: "2024-06-05",
      NextMaintenanceDate: "2024-12-05",
      bill: "$200",
      MaintenanceType: "Brake Replacement",
    },
    {
      DriverName: "Grace Hill",
      DriverContact: "555-2345",
      VendorName: "Precision Motors",
      VendorContact: "555-6789",
      VehicleManufacturer: "Chevrolet",
      LicensePlate: "MNO345",
      VehicleLocation: "Houston",
      LastMaintenanceDate: "2024-02-15",
      NextMaintenanceDate: "2024-08-15",
      bill: "$200",
      MaintenanceType: "Electrical System Check",
    },
    {
      DriverName: "Ian Jones",
      DriverContact: "555-6789",
      VendorName: "Expert Mechanics",
      VendorContact: "555-1234",
      VehicleManufacturer: "Honda",
      LicensePlate: "PQR678",
      VehicleLocation: "Miami",
      LastMaintenanceDate: "2024-01-20",
      NextMaintenanceDate: "2024-07-20",
      bill: "$200",
      MaintenanceType: "Tire Replacement",
    },
  ];

  const pageHandler = (e) => {
    setPage(e);
  };

  return (
    <section className="p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by Vehicle"
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto"></div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold whitespace-nowrap tracking-wider"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {maintenanceData.length === 0 ? (
                <h1 className="text-center text-[1.1rem] p-5">No Data Found</h1>
              ) : (
                <tbody>
                  {maintenanceData.map((data, index) => (
                    <tr
                      key={index}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                      onClick={() => {}}
                    >
                      <th
                        scope="row"
                        className="px-4 py-5 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data?.DriverName}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.DriverContact}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VendorName}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VendorContact}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VehicleManufacturer}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.LicensePlate}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VehicleLocation}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.LastMaintenanceDate}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.NextMaintenanceDate}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.bill}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.MaintenanceType}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default PensionerTable;
