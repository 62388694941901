import React, { useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { DeleteFilled } from "@ant-design/icons";
import figA from "../../../imgs/fingerActive.png";
import leftT from "../../../imgs/leftThumb.png";
import across from "../../../imgs/across.png";
import Tprint from "../../../imgs/thubprint.png";
import rightT from "../../../imgs/rightThumb.png";
import leftI from "../../../imgs/leftIndex.png";
import rightI from "../../../imgs/rightIndex.png";
import { useLocation, useNavigate } from "react-router-dom";
import codeHandler from "../../Register/codeHandler/codehandle";
import {
  PlusCircleFilled,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
const SelectLocation = () => {
  const location = useLocation();
  const router = useNavigate();
  const dash = location?.pathname;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const profileData = useSelector((state) => state?.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const [thumbLeft, setThumbLeft] = useState([]);
  const [thumbRight, setThumbRight] = useState([]);
  const [indexRight, setIndexRight] = useState([]);
  const [indexLeft, setIndexLeft] = useState([]);
  const [thumbLeftD, setThumbLeftD] = useState([]);
  const [thumbRightD, setThumbRightD] = useState([]);
  const [indexRightD, setIndexRightD] = useState([]);
  const [indexLeftD, setIndexLeftD] = useState([]);
  const [thumbLOpen, setThumbLOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [thumbROpen, setThumbROpen] = useState(false);
  const [indexLOpen, setIndexLOpen] = useState(false);
  const [indexROpen, setIndexROpen] = useState(false);
  const [currentFinger, setCurrentFinger] = useState(null);
  const imageHandlerFig = (file) => async () => {
    switch (currentFinger) {
      case "thumbLeft":
        // setThumbLeft((prev) => [...prev, data.data.url]);
        setThumbLeftD((prev) => [...prev, figA]);
        break;
      case "thumbRight":
        // setThumbRight((prev) => [...prev, data.data.url]);
        setThumbRightD((prev) => [...prev, figA]);
        break;
      case "indexLeft":
        // setIndexLeft((prev) => [...prev, data.data.url]);
        setIndexLeftD((prev) => [...prev, figA]);
        break;
      case "indexRight":
        // setIndexRight((prev) => [...prev, data.data.url]);
        setIndexRightD((prev) => [...prev, figA]);
        break;
      default:
        break;
    }
    console.log("FingerprintCapture");
    console.log("Testing Fingerprint A", thumbLeft);
    console.log("Testing Fingerprint B", thumbLeft);
    console.log("Testing Fingerprint C", thumbLeft);
    console.log("Testing Fingerprint D", thumbLeft);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        switch (currentFinger) {
          case "thumbLeft":
            setThumbLeft((prev) => [...prev, data.data.url]);
            // setThumbLeftD((prev) => [...prev, figA]);
            break;
          case "thumbRight":
            setThumbRight((prev) => [...prev, data.data.url]);
            // setThumbRightD((prev) => [...prev, figA]);
            break;
          case "indexLeft":
            setIndexLeft((prev) => [...prev, data.data.url]);
            // setIndexLeftD((prev) => [...prev, figA]);
            break;
          case "indexRight":
            setIndexRight((prev) => [...prev, data.data.url]);
            // setIndexRightD((prev) => [...prev, figA]);
            break;
          default:
            break;
        }
      })
      .catch(() => {});
  };
  const updateHandler = () => {
    if (dash === "/pensionerVerification/BiometricsUpdate") {
      const checkAllIndentity =
        (profileData?.pensionerInfo?.leftIndices?.length ?? 0) === 0 &&
        (profileData?.pensionerInfo?.leftThumbs?.length ?? 0) === 0 &&
        (profileData?.pensionerInfo?.rightIndices?.length ?? 0) === 0 &&
        (profileData?.pensionerInfo?.rightThumbs?.length ?? 0) === 0
          ? false
          : true;

      const api =
        checkAllIndentity === false
          ? `v1/pensioner-info/add-by-staff?pensionerUserId=${profileData.user.id}`
          : `v1/pensioner-info/${profileData.pensionerInfo.id}/update-by-staff`;
      const method = checkAllIndentity === false ? "post" : "put";
      let body;
      body = {
        leftThumbs: thumbLeft,
        rightThumbs: thumbRight,
        leftIndices: indexLeft,
        rightIndices: indexRight,
      };
      if (thumbLeft.length < 4) {
        delete body.leftThumbs;
      }
      if (thumbRight.length < 4) {
        delete body.rightThumbs;
      }
      if (indexLeft.length < 4) {
        delete body.leftIndices;
      }
      if (indexRight.length < 4) {
        delete body.rightIndices;
      }
      // } else {
      //   body = {
      //     leftThumbs: [thumbLeft],
      //     rightThumbs: [thumbRight],
      //     leftIndices: [indexLeft],
      //     rightIndices: [indexRight],
      //   };
      // }

      axios[method](
        `${process.env.REACT_APP_BASE_URL}/${api}`,
        {
          pensionerInfo: body,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
        .then(() => {
          dispatch({ type: "RESET" });
          router("/pensionerVerification/Biometrics");
        })
        .catch((err) => {
          if (
            err?.response?.data?.message ===
            `"pensionerInfo" must contain at least one of [signature, leftThumbs, rightThumbs, leftIndices, rightIndices]`
          ) {
            setErr("Please enter all impressions for atleast one finger.");
          }
          if (
            err?.response?.data?.message ===
            `"body" must contain at least one non-empty value`
          ) {
            setErr("Please enter all impressions for atleast one finger.");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    }
    // else {
    //   let body;
    //   body = {
    //     leftThumbs: thumbLeft,
    //     rightThumbs: thumbRight,
    //     leftIndices: indexLeft,
    //     rightIndices: indexRight,
    //   };
    //   if (thumbLeft.length < 4) {
    //     delete body.leftThumbs;
    //   }
    //   if (thumbRight.length < 4) {
    //     delete body.rightThumbs;
    //   }
    //   if (indexLeft.length < 4) {
    //     delete body.leftIndices;
    //   }
    //   if (indexRight.length < 4) {
    //     delete body.rightIndices;
    //   }
    //   axios
    //     .put(`${process.env.REACT_APP_BASE_URL}/v1/pensioner-info`, body, {
    //       headers: {
    //         Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
    //       },
    //     })
    //     .then(() => {
    //       dispatch({ type: "RESET" });
    //     })
    //     .catch((err) => {
    //       setErr(err.response.data.message);
    //       setShow(true);
    //     });
    // }
  };

  function ConfirmFig() {
    setIndexLeft([figA, figA, figA, figA]);
    var json = { action: "14" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("FingerprintResponse", response);
        const base64Str = response?.data?.FigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );

        imageHandlerFig(file)();
      })
      .catch(function () {});
  }
  const deleteImage = (index, val) => {
    switch (val) {
      case "thumbLeft":
        setThumbLeft((prev) => prev.filter((_, i) => i !== index));
        setThumbLeftD((prev) => prev.filter((_, i) => i !== index));
        break;
      case "thumbRight":
        setThumbRight((prev) => prev.filter((_, i) => i !== index));
        setThumbRightD((prev) => prev.filter((_, i) => i !== index));
        break;
      case "leftIndex":
        setIndexLeft((prev) => prev.filter((_, i) => i !== index));
        setIndexLeftD((prev) => prev.filter((_, i) => i !== index));
        break;
      case "rightIndex":
        setIndexRight((prev) => prev.filter((_, i) => i !== index));
        setIndexRightD((prev) => prev.filter((_, i) => i !== index));
        break;
      default:
        break;
    }
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader header={true} title={"Biometrics Update"} route={-1} />
      <div className="flex items-center justify-center flex-col bg-[#F4F2F2] mb-10 p-4 ml-[2rem] mr-[2rem] relative">
        <div
          className={`rounded-sm flex mt-[1rem] items-center flex-col gap-2 bg-white m-5 p-6 w-[80%]`}
        >
          <div className="mt-10 mb-10 flex w-[90%] flex-col items-center gap-4 justify-center">
            <div className="sm:w-[60%] w-[95%]  mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
              <div className="w-full h-[2.5rem] flex items-center justify-between">
                <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                  <div className="flex gap-1 items-center">
                    <img src={leftT} alt="" />
                    <p>Left Thumb</p>
                  </div>
                  {!thumbLOpen && (
                    <RightOutlined
                      onClick={() => {
                        setThumbLOpen(true);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                  {thumbLOpen && (
                    <DownOutlined
                      onClick={() => {
                        setThumbLOpen(false);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                </div>
                <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                  <div className="flex items-center justify-center flex-col">
                    <p className="font-bold text-[11px]">
                      {thumbLeftD.length}/4
                    </p>
                    <p className="font-bold text-[11px]">uploaded</p>
                  </div>

                  <label className="">
                    <PlusCircleFilled
                      onClick={() => {
                        setCurrentFinger("thumbLeft");
                        ConfirmFig();
                      }}
                      className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                        thumbLeft.length === 4
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                    />
                  </label>
                </div>
              </div>
              {thumbLOpen && (
                <div className="w-[70%] flex flex-wrap gap-2">
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbLeftD.length > 0 ? thumbLeftD[0] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbLeftD.length > 0 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(0, "thumbLeft")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbLeftD.length > 1 ? thumbLeftD[1] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbLeftD.length > 1 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(1, "thumbLeft")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbLeftD.length > 2 ? thumbLeftD[2] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbLeftD.length > 2 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(2, "thumbLeft")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbLeftD.length > 3 ? thumbLeftD[3] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbLeftD.length > 3 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(3, "thumbLeft")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="sm:w-[60%] w-[90%] mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
              <div className="w-full h-[2.5rem] flex items-center justify-between">
                <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                  <div className="flex gap-1 items-center">
                    <img src={rightT} alt="" />
                    <p>Right Thumb</p>
                  </div>
                  {!thumbROpen && (
                    <RightOutlined
                      onClick={() => {
                        setThumbROpen(true);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                  {thumbROpen && (
                    <DownOutlined
                      onClick={() => {
                        setThumbROpen(false);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                </div>
                <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                  <div className="flex items-center justify-center flex-col">
                    <p className="font-bold text-[11px]">
                      {thumbRightD.length}/4
                    </p>
                    <p className="font-bold text-[11px]">uploaded</p>
                  </div>

                  <label className="">
                    <PlusCircleFilled
                      onClick={() => {
                        setCurrentFinger("thumbRight");
                        ConfirmFig();
                      }}
                      className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                        thumbRight.length === 4
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                    />
                  </label>
                </div>
              </div>
              {thumbROpen && (
                <div className="w-[70%] flex flex-wrap gap-2">
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbRightD.length > 0 ? thumbRightD[0] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbRightD.length > 0 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(0, "thumbRight")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbRightD.length > 1 ? thumbRightD[1] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbRightD.length > 1 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(1, "thumbRight")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbRightD.length > 2 ? thumbRightD[2] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbRightD.length > 2 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(2, "thumbRight")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={thumbRightD.length > 3 ? thumbRightD[3] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        thumbRightD.length > 3 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(3, "thumbRight")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="sm:w-[60%] w-[90%] mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
              <div className="w-full h-[2.5rem] flex items-center justify-between">
                <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                  <div className="flex gap-1 items-center">
                    <img src={leftI} alt="" />
                    <p>Left Index</p>
                  </div>
                  {!indexLOpen && (
                    <RightOutlined
                      onClick={() => {
                        setIndexLOpen(true);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                  {indexLOpen && (
                    <DownOutlined
                      onClick={() => {
                        setIndexLOpen(false);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                </div>
                <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                  <div className="flex items-center justify-center flex-col">
                    <p className="font-bold text-[11px]">
                      {indexLeftD.length}/4
                    </p>
                    <p className="font-bold text-[11px]">uploaded</p>
                  </div>

                  <label className="">
                    <PlusCircleFilled
                      onClick={() => {
                        setCurrentFinger("indexLeft");
                        ConfirmFig();
                      }}
                      className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                        indexLeft.length === 4
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                    />
                  </label>
                </div>
              </div>
              {indexLOpen && (
                <div className="w-[70%] flex flex-wrap gap-2">
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexLeftD.length > 0 ? indexLeftD[0] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexLeftD.length > 0 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(0, "leftIndex")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexLeftD.length > 1 ? indexLeftD[1] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexLeftD.length > 1 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(1, "leftIndex")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexLeftD.length > 2 ? indexLeftD[2] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexLeftD.length > 2 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(2, "leftIndex")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexLeftD.length > 3 ? indexLeftD[3] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexLeftD.length > 3 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(3, "leftIndex")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="sm:w-[60%] w-[90%] mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
              <div className="w-full h-[2.5rem] flex items-center justify-between">
                <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                  <div className="flex gap-1 items-center">
                    <img src={rightI} alt="" />
                    <p>Right Index</p>
                  </div>
                  {!indexROpen && (
                    <RightOutlined
                      onClick={() => {
                        setIndexROpen(true);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                  {indexROpen && (
                    <DownOutlined
                      onClick={() => {
                        setIndexROpen(false);
                      }}
                      className="font-bold cursor-pointer"
                    />
                  )}
                </div>
                <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                  <div className="flex items-center justify-center flex-col">
                    <p className="font-bold text-[11px]">
                      {indexRightD.length}/4
                    </p>
                    <p className="font-bold text-[11px]">uploaded</p>
                  </div>

                  <label className="">
                    <PlusCircleFilled
                      onClick={() => {
                        setCurrentFinger("indexRight");
                        ConfirmFig();
                      }}
                      className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                        indexRight.length === 4
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                    />
                  </label>
                </div>
              </div>
              {indexROpen && (
                <div className="w-[70%] flex flex-wrap gap-2">
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexRightD.length > 0 ? indexRightD[0] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexRightD.length > 0 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(0, "rightIndex")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexRightD.length > 1 ? indexRightD[1] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexRightD.length > 1 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(1, "rightIndex")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexRightD.length > 2 ? indexRightD[2] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexRightD.length > 2 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(2, "rightIndex")}
                    />
                  </div>
                  <div className="relative w-[3rem] h-[3rem]">
                    <img
                      src={indexRightD.length > 3 ? indexRightD[3] : Tprint}
                      alt=""
                      className="w-full h-full border bg-white p-1 rounded-lg"
                    />
                    <DeleteFilled
                      className={`${
                        indexRightD.length > 3 ? "" : "hidden"
                      } absolute top-0 right-0 text-red-500 cursor-pointer`}
                      onClick={() => deleteImage(3, "rightIndex")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
        >
          <button
            type="button"
            onClick={() => {
              updateHandler();
            }}
            className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-2 pl-2 w-[15rem]`}
          >
            Update
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
