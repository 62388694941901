// import React from "react";
// import { Layout, theme } from "antd";
// import Sidebar from "../dash/sideBar";
// import back from "../../../imgs/backIcon.png";
// import Table from "./Table"
// import { useNavigate } from "react-router-dom";
// // import Card from "./Card";
// const { Content, Sider } = Layout;
// const App = () => {
//   const {
//     token: { borderRadiusLG },
//   } = theme.useToken();
//   const router = useNavigate();
//   return (
//     <Layout
//       style={{
//         padding: 35,
//         margin: 0,
//         background: "#D9EFED",
//       }}
//     >
//       <Sider
//         breakpoint="lg"
//         width={280}
//         collapsedWidth="0"
//         onBreakpoint={(broken) => {
//           console.log(broken);
//         }}
//         onCollapse={(collapsed, type) => {
//           console.log(collapsed, type);
//         }}
//         className="flex flex-col justify-between h-full"
//         style={{
//           borderRadius: borderRadiusLG,
//           width: "20px",
//           height: "88vh",
//           boxShadow:
//             "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
//         }}
//       >
//         <Sidebar />
//       </Sider>

//       <Layout
//         className="flex flex-col items-center justify-center"
//         style={{
//           background: "#D9EFED",
//         }}
//       >
//         <div className="flex items-center w-[95%]">
//           <div className="flex items-center justify-center">

//             <h2 className="mb-4 text-xl font-bold flex items-center justify-center"><img onClick={()=>
//             {
//                 router("/MHSSS/dashboard");
//             }} src={back} className="w-3 h-3 mr-2 cursor-pointer" alt="back" /> Merchants Outstanding</h2>
//           </div>
//         </div>
//         <div className="w-[95%]">
//           <Table/>
//           </div>
//       </Layout>
//     </Layout>
//   );
// };
// export default App;
import React, { useEffect, useState } from "react";
import Header from "../dash/PagesHeader";
import Sidebar from "../dash/sideBar";
import Main from "./table";
import { useDispatch, useSelector } from "react-redux";
import { MenuUnfoldOutlined } from "@ant-design/icons";
const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${
        windowSize?.width <= 768
          ? "h-screen flex flex-col"
          : "flex flex-row p-7 gap-5 h-screen bg-[#D9EFED] w-full"
      }`}
    >
      {windowSize?.width <= 768 ? (
        <>
          <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
            <MenuUnfoldOutlined
              className="text-2xl cursor-pointer"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
            />
            <h1 className="text-xl font-bold">Merchants Outstanding</h1>
          </header>
          {openSideBar && (
            <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
              <Sidebar />
            </div>
          )}
        </>
      ) : (
        <aside
          className="w-[20%] rounded-lg"
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <Sidebar />
        </aside>
      )}
      <div
        className={`${
          windowSize?.width <= 768
            ? "flex-grow overflow-y-auto p-4"
            : "flex flex-col w-[78%] gap-3 p-3 "
        }`}
      >
        <header className="h-6 shrink-0 rounded-lg">
          <Header
            type={"Merchants Outstanding"}
            header={false}
            route={"/MHSSS/dashboard"}
          />
        </header>
        <main
          className={`${
            windowSize?.width <= 768
              ? ""
              : "flex-grow rounded-lg p-3 overflow-y-scroll "
          }`}
          style={
            windowSize?.width <= 768
              ? {}
              : {
                  // backgroundColor: "white",
                  // boxShadow:
                  //   "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                }
          }
        >
          <Main />
        </main>
      </div>
    </div>
  );
};

export default Index;
