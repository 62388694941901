import { useEffect, useState } from "react";
import LoginCredentials1 from "./tabsR/loginCredentials";
import PersonalDetails1 from "./tabsR/personalDetails";
import Location1 from "./tabsR/location";
import Identification2 from "./tabsR/identification";
import PensionBookDetails from "./PensionBook";
import Banking from "./BankingDetails";
import Biometric from "./tabsR/biometric";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Pregistration = () => {
  const dataUser = useSelector((state) => state.dataUser);
  console.log(dataUser);
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const financeState = location.state?.finance;
  useEffect(() => {
    if (financeState) {
      setWow(4);
    } else {
      setWow(0);
    }
  }, [financeState]);
  const [wow, setWow] = useState(0);
  const Show = [
    LoginCredentials1,
    PersonalDetails1,
    Location1,
    Identification2,
    Biometric,
    PensionBookDetails,
    Banking,
  ];
  const Dis = Show[wow];
  return (
    <div className="">
      <div className="flex flex-wrap mt-8 mb-[5rem]">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          <div className=" text-center w-[100%]">Login</div>
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          <div className=" text-center w-[100%]">Info</div>
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          <div className=" text-center w-[100%]">Address</div>
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          <div className="text-center w-[100%]">Signature </div>
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>
        <button
          onClick={() => setWow(4)}
          className={`${
            wow === 4 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          <div className="text-center w-[100%]">Biometric</div>
          <div
            className={`${
              wow === 4 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        {(user === "employee" && privilege?.includes("View pension book")) ||
        (privilege?.includes("View pension book") &&
          privilege?.includes("Edit pension book")) ? (
          <div className="">
            <button
              onClick={() => setWow(5)}
              className={`${
                wow === 5 ? "bg-primary text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
            >
              <div className="text-center w-[100%]">
                Pension Books / Cash Grant
              </div>
              <div
                className={`${
                  wow === 5 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
          </div>
        ) : user === "pensioner" ||
          user === "admin" ||
          user === "superadmin" ? (
          // Show the button for pensioner users without any conditions
          <div className="">
            <button
              onClick={() => setWow(5)}
              className={`${
                wow === 5 ? "bg-primary text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
            >
              <div className="text-center w-[100%]">
                Pension Books / Cash Grant
              </div>
              <div
                className={`${
                  wow === 5 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
          </div>
        ) : null}

        {(user === "employee" && privilege.includes("View banking")) ||
        (privilege.includes("View banking") &&
          privilege.includes("Edit banking")) ? (
          <div className="">
            <button
              onClick={() => setWow(6)}
              className={`${
                wow === 6 ? "bg-primary text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
            >
              <div className="text-center w-[100%]">Banking</div>
              <div
                className={`${
                  wow === 6 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
          </div>
        ) : (user === "pensioner" && dataUser?.pensionBooks?.lenght === 0) ||
          user === "admin" ||
          user === "superadmin" ? (
          // Show the button for pensioner users without any conditions
          <div className="">
            <button
              onClick={() => setWow(6)}
              className={`${
                wow === 6 ? "bg-primary text-white" : "bg-[#EDEDED]"
              } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
            >
              <div className="text-center w-[100%]">Banking</div>
              <div
                className={`${
                  wow === 6 ? "" : "hidden"
                } arrow-down absolute right-[35%] bottom-[-38%]`}
              ></div>
            </button>
          </div>
        ) : null}
      </div>
      <Dis />
    </div>
  );
};

export default Pregistration;
