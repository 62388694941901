import React, { useState, useEffect } from "react";
import bank from "../../../../imgs/bankImg.png";
import { useSelector } from "react-redux";
import thumb from "../../../../imgs/Thumb.png";
import { Select, Checkbox, Input } from "antd";
import axios from "axios";
const Main = () => {
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [show, setShow] = useState(false);
  const token = useSelector((state) => state.token);
  const [allBanks, setAllBanks] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [allBranches, setAllBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  useEffect(() => {
    if (show) {
      console.log("doneeeee");
      const timeout = setTimeout(() => {
        setShow(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [show]);
  const handleCheckboxChange = (value) => {
    if (accountType === value) {
      setAccountType(null);
    } else {
      setAccountType(value);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/country-banks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        const BankOptions = data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        setAllBanks(BankOptions);
        setBanks(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedBank === null) return;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/country-bank-branches?countrybankId=${selectedBank}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        const BranchOptions = data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        setAllBranches(BranchOptions);
        setBranches(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedBank]);

  const SelectBank = (e) => {
    setBranches([]);
    setSelectedBranch(null);
    setSelectedBank(e);
    console.log(e, "e");
    console.log("banks", banks);
  };
  const SelectBranch = (e) => {
    setSelectedBranch(e);
    const selectedBranchValue = branches.find((item) => item.id === e);
    setBranchAddress(selectedBranchValue?.address);
    // setBankAddress(selectedBranchValue?.address);
  };
  return (
    <div className="flex flex-col w-full p-4 overflow-hidden min-h-[82vh] rounded-md bg-white">
      <h1 className="font-semibold text-xl ml-6">Add Another Bank Detail</h1>
      <div className="p-4 sm:pl-10 pt-8 w-[50%] flex items-center flex-col gap-2">
        <div className="w-full p-2 pl-4 pr-4 flex flex-col gap-2 rounded-md bg-[#F6FBF9]">
          <h1 className="font-semibold text-sm">Select Bank</h1>
          <div className="w-full flex gap-2 items-center">
            <div className="w-[7rem] flex items-center justify-center gap-1 pr-1 h-[2.5rem] bg-white">
              <img src={bank} alt="bank" className="w-[2.5rem] h-full" />
              <h1 className="font-semibold">Axis Bank</h1>
            </div>
            <div className="w-[7rem] flex items-center justify-center gap-1 pr-1 h-[2.5rem] bg-white">
              <img src={bank} alt="bank" className="w-[2.5rem] h-full" />
              <h1 className="font-semibold">Axis Bank</h1>
            </div>
            <div className="w-[7rem] flex items-center justify-center gap-1 pr-1 h-[2.5rem] bg-white">
              <img src={bank} alt="bank" className="w-[2.5rem] h-full" />
              <h1 className="font-semibold">Axis Bank</h1>
            </div>
          </div>
          <label className="font-semibold text-sm mt-3">Select Bank</label>
          <div className="w-full">
            <Select
              placeholder="Select bank"
              optionFilterProp="children"
              style={{ marginBottom: "0.5rem" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              className=""
              value={selectedBank}
              onChange={(e) => {
                SelectBank(e);
              }}
              options={allBanks}
            />
          </div>
          <label className="font-semibold text-sm">Select Branch</label>
          <div className="w-full">
            <Select
              placeholder="Select Branch"
              optionFilterProp="children"
              style={{ marginBottom: "1.2rem" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              className=""
              value={selectedBranch}
              onChange={(e) => {
                SelectBranch(e);
              }}
              options={allBranches}
            />
          </div>
        </div>
        <div className="w-full p-2 pl-4 pr-2 flex flex-col gap-2 rounded-md bg-[#F6FBF9]">
          <div className="w-full flex gap-2 flex-col">
            {/* Input for Account Holder Name */}
            <>
              <label htmlFor="accountHolderName">Account Holder Name</label>
              <Input
                id="accountHolderName"
                placeholder="Enter account holder name"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </>
            <>
              {/* Input for Account Number */}
              <label htmlFor="accountNumber">Account Number</label>
              <Input
                id="accountNumber"
                placeholder="Enter account number"
                type="number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </>
            <>
              {/* Input for Confirm Account Number */}
              <label htmlFor="confirmAccountNumber">
                Confirm Account Number
              </label>
              <Input
                id="confirmAccountNumber"
                placeholder="Confirm account number"
                type="number"
                value={confirmAccountNumber}
                onChange={(e) => setConfirmAccountNumber(e.target.value)}
              />
            </>
            <>
              {/* Input for Routing Number */}
              <label htmlFor="routingNumber">Routing Number</label>
              <Input
                id="routingNumber"
                placeholder="Enter routing number"
                type="number"
                value={routingNumber}
                onChange={(e) => setRoutingNumber(e.target.value)}
              />
            </>
            <>
              {/* Input for Branch Address */}
              <label htmlFor="branchAddress">Branch Address</label>
              <Input
                id="branchAddress"
                placeholder="Enter branch address"
                value={branchAddress}
                onChange={(e) => setBranchAddress(e.target.value)}
              />
            </>
            <>
              {/* Checkboxes for Account Type */}
              <label>Account Type</label>
              <div className="flex gap-4">
                <Checkbox
                  checked={accountType === "current"}
                  onChange={() => handleCheckboxChange("current")}
                >
                  Current
                </Checkbox>
                <Checkbox
                  checked={accountType === "savings"}
                  onChange={() => handleCheckboxChange("savings")}
                >
                  Savings
                </Checkbox>
              </div>
            </>
          </div>
        </div>
        <div className="w-full p-2 pl-4 pr-4 flex flex-col gap-2 rounded-md bg-[#F6FBF9]">
          {/* Input for Phone Number */}
          <label htmlFor="phoneNumber">Phone Number</label>
          <Input
            id="phoneNumber"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          {/* Input for Email Address */}
          <label htmlFor="emailAddress">Email Address</label>
          <Input
            id="emailAddress"
            placeholder="Enter email address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />

          {/* Input for Address */}
          <label htmlFor="address">Address</label>
          <Input.TextArea
            id="address"
            placeholder="Enter address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="w-full mt-8">
          <button
            onClick={() => {
              setShow(true);
            }}
            className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
          >
            Add Bank
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-100 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Bank is Successfully added</h1>
        </div>
      </div>
    </div>
  );
};

export default Main;
