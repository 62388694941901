import Nav from "../components/Nav/nav";
import DigitalCardBack from "../components/card/cardBack";

const CardBackPage = () => {
  return (
    <div>
      <Nav />
      <DigitalCardBack />
    </div>
  );
};

export default CardBackPage;
