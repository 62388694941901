import { useLocation, useNavigate } from "react-router-dom";
import waiting1 from "../../../../../imgs/waiting1.png";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import { useState } from "react";
const Awaited = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const merchantEmployeePass = useSelector(
    (state) => state.merchantEmployeePass
  );
  const merchantEmployee = useSelector((state) => state.merchantEmployee);
  const token = useSelector((state) => state.token);
  // const [show, setShow] = useState(false);
  // const [err, setErr] = useState("");
  const dash = location.pathname;
  const router = useNavigate();
  let idd = merchantEmployee?.data?.merchUser?.id;
  console.log(idd);
  const handleShare = () => {
    // Previous Api
    // v1/merch-auth/merch-employee/credentials?merchUserId
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/merch-user/credentials?merchUserId=${idd}`,
        {
          password: merchantEmployeePass,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({ type: "MERCHANT_Pass", num: "" });
        router("/Merchant/Employee/List");
        dispatch({ type: "ESET", num: 0 });
      })
      .catch(() => {
        // setErr(err.response.data.message);
        // setShow(true);
      });
  };
  return (
    <div className="flex justify-center">
      <div className="flex flex-col text-center justify-center items-center w-[70%]">
        <div className="w-[311px] h-[297px]">
          <img className="scale-[1]" src={waiting1} alt="" />
        </div>
        <p>
          Employee Account has been created successfully. You can share the
          credentials with the employee.
        </p>
        <div>
          <div className="mt-5 flex gap-2 flex-col">
            {/* {url !== "" && (
              <a
                href={`${url}`}
                target="_blank"
                className={`${option === "Employee" ? "hidden" : ""}`}
              >
                <button className="lg:text-[1.1rem] bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8">
                  View Request
                </button>
              </a>
            )} */}
            <button
              onClick={handleShare}
              className={`ml-5 lg:text-[1.1rem] bg-primary ${
                dash === "/Dashboard/application" ? "hidden" : ""
              } text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Share Login Credentials
            </button>
            <button
              onClick={() => {
                router("/Merchant/Employee/List");
                dispatch({ type: "ESET", num: 0 });
              }}
              className={`ml-5 lg:text-[1.1rem] bg-[#F7542E] ${
                dash === "/Dashboard/application" ? "hidden" : ""
              } text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awaited;
