import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import back5 from "../../../imgs/back5.png";
import Personal from "./Tabs/Personal";
import ContactPerson from "./Tabs/ContactPerson";
import Headquarter from "./Tabs/headquater";
import Stores from "./Tabs/Stores";
import Documentation from "./Tabs/Documentation";
import EmployeeDocumentation from "./Tabs/EmployeeDocs/Documentation";
import TaxInformation from "./Tabs/EmployeeDocs/TaxInformation";
import Company from "./Tabs/Company";
import Banking from "./Tabs/Banking";
import Owner from "./Tabs/Owner";
import EmployeeProfile from "./Tabs/EmployeePersonal";
import EmployeeAddress from "./Tabs/EmployeeAddress";
import EmployeeInfo from "./Tabs/EmployeeInfo";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
const Profile = () => {
  const isGovernmentAgency = useSelector((state) => state?.isGovernmentAgency);
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const state = location.state;
  const dispatch = useDispatch();
  // Access the state object properties
  const employeeProfile = state && state.employeeProfile;
  const [refetch, setRefetch] = useState(false);
  const [active, setActive] = useState("1");
  const token = useSelector((state) => state.token);
  const merchantEmployeeId = useSelector((state) => state.merchantEmployeeId);
  const [personal, setPersonal] = useState();
  const [headquarter, setHeadquarter] = useState();
  const [mailAddress, setMailAddress] = useState();
  const [stores, setStores] = useState([]);
  const [company, setCompany] = useState();
  const [documentation, setDocumentation] = useState([]);
  const [identification, setIdentification] = useState([]);
  const [banking, setBanking] = useState();
  const [cheque, setCheque] = useState();
  const [ewallet, setEwallet] = useState();
  const [contactPerson, setContactPerson] = useState([]);
  const user = useSelector((state) => state.user);
  const [employeeDocs, setEmployeeDocs] = useState([]);
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  useEffect(() => {
    if (user === "merchant-employee") {
      setEmployeeDocs(merchantEmployeeInfo?.data?.merchDocuments);
      merchantEmployeeInfo?.data?.merchDocuments?.map((doc, docindex) => {
        doc?.merchdocimages?.map((img, index) => {
          imageConverter(img?.imageUrl, docindex, index, "employeeDocs");
        });
      });
    }
  }, []);

  useEffect(() => {
    if (
      user === "merchant-owner" ||
      (user === "merchant-admin" && employeeProfile === true)
    ) {
      setEmployeeDocs(merchantEmployeeInfo?.data?.merchDocuments);
      merchantEmployeeInfo?.data?.merchDocuments?.map((doc, docindex) => {
        doc?.merchdocimages?.map((img, index) => {
          imageConverter(img?.imageUrl, docindex, index, "employeeDocs");
        });
      });
    }
  }, [merchantEmployeeInfo]);

  // get Company
  useEffect(() => {
    if (user === "merchant-owner" || user === "merchant-admin") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-company/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setCompany(data.data);
        })
        .catch(() => {});
    }
  }, []);

  //get Personal
  useEffect(() => {
    if (user === "merchant-owner") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-person/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setPersonal(data.data);
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    if (user === "merchant-admin") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-user/my-owner/for-merch-admin-or-employee`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setPersonal(data.data?.merchperson);
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    if (user === "merchant-admin") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-document/my-owner-docs/for-merch-admin`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setIdentification(data.data);
          data?.data?.map((doc, docindex) => {
            doc?.merchdocimages?.map((img, index) => {
              imageConverter(img?.imageUrl, docindex, index, "identification");
            });
          });
        })
        .catch(() => {});
    }
  }, []);
  //get contact Person
  useEffect(() => {
    if (user === "merchant-owner") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-my-merch-admins?sortBy=createdAt:desc`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setContactPerson(data.data.rows);
          // data?.data?.map((contact, contactIndex) => {
          //   contact?.merchcontactpersondocs?.map((doc, docIndex) => {
          //     doc.images?.map((img, imageIndex) => {
          //       imageConverterContact(
          //         img,
          //         contactIndex,
          //         docIndex,
          //         imageIndex,
          //         "contactPerson"
          //       );
          //     });
          //   });
          // });
        })
        .catch(() => {});
    }
  }, []);

  // get Head Quarters
  useEffect(() => {
    if (user === "merchant-owner" || user === "merchant-admin") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setHeadquarter(data.data);
        })
        .catch(() => {});
    }
  }, []);

  // get Head Quarter mail addresses
  useEffect(() => {
    if (user === "merchant-owner" || user === "merchant-admin") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter/my-hq-mail-address`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setMailAddress(data.data);
        })
        .catch(() => {});
    }
  }, []);

  //get Stores
  useEffect(() => {
    if (user === "merchant-owner" || user === "merchant-admin") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-store`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setStores(data?.data?.rows);
        })
        .catch(() => {});
    }
  }, []);

  // get Documentation
  useEffect(() => {
    if (user === "merchant-owner" || user === "merchant-admin") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-company-doc/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setDocumentation(data.data);
          data?.data?.map((doc, docindex) => {
            doc?.merchcompanydocimgs?.map((img, index) => {
              imageConverter(img?.imageUrl, docindex, index, "documentation");
            });
          });
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    if (
      user === "merchant-owner" ||
      (user === "merchant-admin" && employeeProfile === true)
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantEmployeeId}/merch-employee/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "MERCHANT_EMPLOYEE_INFO", num: data });
        })
        .catch(() => {});
    }
  }, [refetch]);

  // Image Urls
  const imageConverter = (url, docIndex, imageIndex, type) => {
    const api =
      type === "documentation"
        ? "merchantDocument"
        : "merchantIdentificationDocument";
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-file-presigned-url?fileType=${api}`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        type === "documentation"
          ? setDocumentation((prev) => {
              let newArr = [...prev];
              newArr[docIndex].merchcompanydocimgs[imageIndex].imageUrl =
                data?.data?.[0];
              return newArr;
            })
          : type === "identification"
          ? setIdentification((prev) => {
              let newArr = [...prev];
              newArr[docIndex].merchdocimages[imageIndex].imageUrl =
                data?.data?.[0];
              return newArr;
            })
          : type === "employeeDocs"
          ? setEmployeeDocs((prev) => {
              let newArr = [...prev];
              newArr[docIndex].merchdocimages[imageIndex].url =
                data?.data?.[0]?.url;
              return newArr;
            })
          : setContactPerson((prev) => {
              let newArr = [...prev];
              newArr[docIndex].merchcontactpersondocs[docIndex].images[
                imageIndex
              ] = data?.data?.[0]?.url;
              return newArr;
            });
      })
      .catch(() => {
        // Handle error
      });
  };

  // get Identification
  useEffect(() => {
    if (user === "merchant-owner") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-document`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setIdentification(data.data);
          data?.data?.map((doc, docindex) => {
            doc?.merchdocimages?.map((img, index) => {
              imageConverter(img?.imageUrl, docindex, index, "identification");
            });
          });
        })
        .catch(() => {});
    }
  }, []);

  //getBanking
  useEffect(() => {
    if (user === "merchant-owner") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setBanking(data.data);
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    if (user === "merchant-admin") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/my-owner-bank-info/for-merch-admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setBanking(data.data);
        })
        .catch(() => {});
    }
  }, []);

  //getCheque
  useEffect(() => {
    if (user === "merchant-owner") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setCheque(data.data);
        })
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    if (user === "merchant-admin") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/my-owner-cheque-info/for-merch-admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setCheque(data.data);
        })
        .catch(() => {});
    }
  }, []);
  // useEffect(() => {
  //   if (user === "merchant-owner" || user === "merchant-admin") {
  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/own`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((data) => {
  //         setCheque(data.data);
  //       })
  //       .catch(() => {});
  //   }
  // }, []);

  //getEwallet
  // useEffect(() => {
  //   if (user === "merchant-owner") {
  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-ewallet-info/own`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((data) => {
  //         setEwallet(data.data);
  //       })
  //       .catch(() => {});
  //   }
  // }, []);

  // const imageConverterContact = (
  //   url,
  //   contactIndex,
  //   docIndex,
  //   imageIndex,
  //   type
  // ) => {
  //   console.log(type);
  //   const api = "merchantIdentificationDocument";
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-file-presigned-url?fileType=${api}`,
  //       {
  //         fileNames: [url],
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       setContactPerson((prev) => {
  //         let newArr = [...prev];
  //         newArr[contactIndex].merchcontactpersondocs[docIndex].images[
  //           imageIndex
  //         ] = data?.data[0]?.url;
  //         return newArr;
  //       });
  //     })
  //     .catch(() => {
  //       // Handle error
  //     });
  // };

  const tabs = [
    {
      key: "1",
      label: "Company Details",
      component: <Company company={company} />,
      user: "merchant-owner",
    },
    {
      key: "2",
      label: "Email, Phone & Password",
      component: <Personal data={personal} check={"owner"} />,
    },
    {
      key: "3",
      label: isGovernmentAgency ? "Director Details" : "Owner Details",
      component: (
        <Owner
          data={personal}
          contactPerson={contactPerson}
          identification={identification}
          gov={isGovernmentAgency ? true : false}
        />
      ),
      user: "merchant-owner",
    },
    {
      key: "4",
      label: "Admin Details",
      component: (
        <ContactPerson data={personal} contactPerson={contactPerson} />
      ),
      user: "merchant-owner",
    },
    // {
    //   key: "3",
    //   label: "Employee Details",
    //   component: (
    //     <Employee data={employees} />
    //   ),
    // },

    {
      key: "5",
      label: "Headquarter",
      component: (
        <Headquarter headquarter={headquarter} mailAddress={mailAddress} />
      ),
      user: "merchant-owner",
    },
    // {
    //   key: "6",
    //   label: "Stores",
    //   component: <Stores stores={stores} />,
    //   user: "merchant-owner",
    // },
    {
      key: "7",
      label: "Documents",
      component: <Documentation documentation={documentation} />,
    },
    // {
    //   key: "8",
    //   label: "Identification",
    //   component: <Identification identification={identification} />,
    // },
    {
      key: "8",
      label: "Financial Information",
      component: (
        <Banking banking={banking} cheque={cheque} ewallet={ewallet} />
      ),
    },
  ];
  const adminTabs = [
    {
      key: "1",
      label: "Company Details",
      component: <Company company={company} />,
      user: "merchant-owner",
    },
    {
      key: "2",
      label: "Email, Phone & Password",
      component: <Personal data={personal} check={"owner"} />,
    },
    {
      key: "3",
      label: isGovernmentAgency ? "Director Details" : "Owner Details",
      component: (
        <Owner
          data={personal}
          contactPerson={contactPerson}
          identification={identification}
          gov={isGovernmentAgency ? true : false}
        />
      ),
      user: "merchant-owner",
    },
    // {
    //   key: "4",
    //   label: "Admin Details",
    //   component: (
    //     <ContactPerson data={personal} contactPerson={contactPerson} />
    //   ),
    //   user: "merchant-owner",
    // },
    // {
    //   key: "3",
    //   label: "Employee Details",
    //   component: (
    //     <Employee data={employees} />
    //   ),
    // },

    {
      key: "5",
      label: "Headquarter",
      component: (
        <Headquarter headquarter={headquarter} mailAddress={mailAddress} />
      ),
      user: "merchant-owner",
    },
    // {
    //   key: "6",
    //   label: "Stores",
    //   component: <Stores stores={stores} />,
    //   user: "merchant-owner",
    // },
    {
      key: "7",
      label: "Documents",
      component: <Documentation documentation={documentation} />,
    },
    // {
    //   key: "8",
    //   label: "Identification",
    //   component: <Identification identification={identification} />,
    // },
    {
      key: "8",
      label: "Financial Information",
      component: (
        <Banking banking={banking} cheque={cheque} ewallet={ewallet} />
      ),
    },
  ];

  const employeeTabs = [
    {
      key: "1",
      label: "Personal Detail",
      component: (
        <EmployeeProfile data={merchantEmployeeInfo?.data?.merchPerson} />
      ),
      user: "merchant-employee",
    },
    {
      key: "2",
      label: "Email, Phone & Password",
      component: (
        <Personal data={merchantEmployeeInfo?.data?.merchUser} check={"emp"} />
      ),
    },
    {
      key: "3",
      label: "Address",
      component: (
        <EmployeeAddress data={merchantEmployeeInfo?.data?.merchAddresses} />
      ),
      user: "merchant-employee",
    },

    {
      key: "4",
      label: "Identification",
      component: <EmployeeDocumentation documentation={employeeDocs} />,
    },
    {
      key: "5",
      label: "Tax Information",
      component: <TaxInformation documentation={employeeDocs} />,
    },
    // {
    //   key: "6",
    //   label: "Employee Info",
    //   component: (
    //     <EmployeeInfo
    //       data={merchantEmployeeInfo?.data?.merchEmployeeInfo}
    //       setRefetch={setRefetch}
    //       refetch={refetch}
    //     />
    //   ),
    //   user: "merchant-owner",
    // },
  ];

  const handleTabClick = (key) => {
    setActive(key);
  };

  if (!isGovernmentAgency) {
    tabs.splice(5, 0, {
      key: "6",
      label: "Stores",
      component: <Stores stores={stores} />,
      user: "merchant-owner",
    });
  }
  if (!isGovernmentAgency) {
    adminTabs.splice(5, 0, {
      key: "6",
      label: "Stores",
      component: <Stores stores={stores} />,
      user: "merchant-owner",
    });
  }

  return (
    <div>
      <div
        className={`${
          dash === "/SapMoney/Profile"
            ? "hidden"
            : "sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem] flex flex-col items-center justify-center"
        }`}
      >
        <div
          className={` ${
            dash === "/SapMoney/Profile"
              ? "hidden"
              : "flex flex-col w-full space-between"
          }`}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                employeeProfile === true
                  ? router("/Merchant/Employee/List")
                  : router("/Merchant/dashboard");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-3 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              {employeeProfile === true ? "Employee Profile" : "Profile"}
            </h2>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="sm:px-8 mt-6">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200  ">
          <ul className="flex flex-wrap -mb-px">
            {(user === "merchant-employee" || employeeProfile === true
              ? employeeTabs
              : user === "merchant-admin"
              ? adminTabs
              : tabs
            ).map((tab) => (
              <li
                key={tab.key}
                onClick={() => handleTabClick(tab.key)}
                className={`${
                  active === tab.key
                    ? "border-primary text-primary font-bold"
                    : "border-transparent"
                } text-[1rem] cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-primary hover:border-primary`}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>

        <div className="p-2">
          {
            (user === "merchant-employee" || employeeProfile === true
              ? employeeTabs
              : user === "merchant-admin"
              ? adminTabs
              : tabs
            ).find((tab) => tab.key === active)?.component
          }
        </div>
      </div>
    </div>
  );
};

export default Profile;
