import React, { useEffect, useState } from "react";
import searchImg from "../../../imgs/searchM.png";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
const Header = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const profile = dataUser?.data?.user?.person?.image;
  const Name =
    dataUser?.data?.user?.person?.firstName +
    " " +
    dataUser?.data?.user?.person?.lastName;
  const [show, setShow] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [err, setErr] = useState("");
  const [afterSearch, setAfterSearch] = useState(false);
  useEffect(() => {
    dispatch({ type: "MHHS_MERCH_ID", num: null });
    dispatch({type: "SELECTED_OUTSTANDING_MERCHANT",num: null});
    dispatch({type: "SELECTED_OUTSTANDING_STATUS",num: null});
  }, []);
  const Search = () => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      const params = {
        ...(companyName && { search: companyName }),
      };

      fetchPenioners(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  };
  const fetchPenioners = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-user/merch-owner-list`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setAfterSearch(true);
        if (data?.data?.length === 0) {
          alert("No Merchant Found");
          // setErr("No Merchant Found");
          // setShowModal(true);
        } else {
          setList(data?.data?.rows);
        }
        // }
      })
      .catch((err) => {
        setShow(false);
        setErr(err.response.data.message);
        setShowModal(true);
        console.log(err);
      });
  };
  return (
    <div className="">
      <div className="flex items-center h-[3rem] w-[95%] justify-between text-lg">
        <h2 className="mb-3 font-bold">Welcome {Name}</h2>
        {/* <img
              src={logo}
              className="h-[8rem] w-[8rem]"
              alt="Flowbite Logo"
            /> */}
        <div className="flex items-center">
          <span className="mr-2 font-semibold">{Name}</span>
          <img src={profile} alt="Profile" className="w-10 h-10 rounded-full" />
        </div>
      </div>
      <div className="w-[95%]">
        <div className="relative sm:w-[35%] w-[90%]">
          <input
            type="text"
            placeholder="Search Merchant"
            className="md:bg-white bg-gray-200  pl-4 pr-4 py-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
            onClick={() => {
              setShow(!show);
            }}
          />
          <div className="absolute inset-y-0 right-0 flex cursor-pointer mr-3 items-center justify-center">
            <img src={searchImg} className="w-5 h-5" alt="Search icon" />
          </div>
          {show && (
            <div className="origin-top-left absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 max-h-96 overflow-y-auto overflow-y-scroll custom-scrollbar">
              <div className="relative">
                {show && (
                  <div className="left-0 mt-0  p-3 pr-4 pl-4  rounded-md  bg-white ring-1 ring-black ring-opacity-5">
                    <input
                      type="text"
                      // id="gid-search"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
                                
                                `}
                      placeholder="Search By Company Name"
                    />

                    <div className="mt-2 flex w-full gap-2 justify-center">
                      <button
                        onClick={() => {
                          Search();
                        }}
                        className={`bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8 ${
                          companyName === ""
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <hr />
              <div className={`${afterSearch ? "" : "hidden"}`}>
                {list?.length === 0 ? (
                  <p className="text-center p-4 text-gray-400">
                    No Record Found
                  </p>
                ) : (
                  <div>
                    <p className="text-center  text-gray-400 mt-2">
                      Search Result
                    </p>
                    <ul className="py-1">
                      {list?.map((option) => (
                        <li
                          key={option?.user?.person?.id}
                          onClick={() => {
                            dispatch({
                              type: "MHHS_MERCH_ID",
                              num: option?.id,
                            });
                            dispatch({
                              type: "SELECTED_OUTSTANDING_MERCHANT",
                              num: option,
                            });
                            router("/MHSSS/dashboard/outstanding/person");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer bg-[#F4F5FE] mx-3 my-2 rounded-lg border-2 border-[#E9E9E9]"
                        >
                          <div className="flex items-center gap-2">
                            <Avatar
                              src={option?.merchcompany?.logo}
                              style={{
                                backgroundColor: "#1BA397",
                              }}
                              shape="circle"
                              size={44}
                            />
                            <div>
                              <h1 className="text-[0.9rem] font-semibold capitalize">
                                {option?.merchcompany?.name}{" "}
                              </h1>
                              <p>
                                {/* GID:{" "} */}
                                {option?.email}
                              </p>
                              <p
                                className={`capitalize ${
                                  option?.status === "pending"
                                    ? "text-[#F35929]"
                                    : option?.status === "rejected"
                                    ? "text-[#D64242]"
                                    : " text-primary"
                                }`}
                              >
                                {option?.status}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && <ErrorModal setShow={setShowModal} message={err} />}
    </div>
  );
};

export default Header;
