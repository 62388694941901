import React from "react";
import across from "../../../imgs/across.png";
const errorModal = ({ setShow, err }) => {
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
    >
      <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
        <div
          className="absolute top-[-11%] left-[42%]"
          onClick={() => setShow(false)}
        >
          <img src={across} alt="" />
        </div>
        <p className="text-center mt-[3rem] mb-5 text-md">
          {err && err.charAt(0).toUpperCase() + err.slice(1)}
        </p>

        <button
          onClick={() => setShow(false)}
          className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default errorModal;
