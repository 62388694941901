import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import { Pagination, Popover } from "antd";
import ContactInfo from "../../../Merchant/Transactions/contactInfo";

// import SearchFilter from "./SearchFilter";

const Paget = () => {
  const router = useNavigate();

  const token = useSelector((state) => state.token);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);

  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  

  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [showContact, setShowContact] = useState(false);
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);

  const type = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);
  const columnHeaders = [
    "Sr No",
    "Amount",
    "Programme",
    "Voucher Transaction ID",
    "Booklet #",
    // "Status",
    "Store / HQ",
    "Contact Info",
    "Date / Time",
    // "Action",
  ];

  useEffect(() => {
    // if (user !== "pensioner") {
    //   return;
    // }
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const pensionerParams = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
      };

      const merchParams = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
      };

      // Conditionally add merchheadquarterId or merchstoreId based on type
      if (type === "HQ") {
        merchParams.merchheadquarterId =
          typeData?.merchuser?.merchheadquarterId;
      } else if (type === "store") {
        merchParams.merchstoreId = typeData?.id;
      }

      const params = user === "penioner" ? pensionerParams : merchParams;

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [
    page
   
  ]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api =
      user === "pensioner"
        ? "v1/merch-transactions/for-pensioner"
        : "v1/sapmoney-dashboard/program-transactions/for-merch";
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setData(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        // setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    // if (search) {
    //   // If searching, update searchPage
    //   setPagewithsearch(e);
    // } else {
    //   // If not searching, update page
    setPage(e);
    // }
  };

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl relative  h-auto bg-white">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#D9EFED]">
          {/* table */}
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders
                    .filter((columnHeader) => {
                      if (
                        user !== "pensioner" &&
                        columnHeader === "Store / HQ"
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-3 py-4 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                </tr>
              </thead>
              {data?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p className="p-10 py-20">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {data?.map((pen, index) => (
                    <tr
                      onClick={() => {
                        router(
                          "/SapMoney/Pensioner/programTransaction/detail",
                          {
                            state: {
                              id: pen?.id,
                              selectedTransactionsVouchers: pen,
                            },
                          }
                        );
                      }}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900  whitespace-nowrap  "
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>

                      <td className="px-4 py-5">
                        {pen?.totalAmount === null
                          ? "----"
                          : `$ ${pen?.totalAmount}`}
                      </td>

                      <td className="px-4 py-5">
                        {pen?.pensionbook?.pensionprogram?.name !== null
                          ? pen?.pensionbook?.pensionprogram?.name ===
                            "senior-citizen-Pension"
                            ? "Senior Citizen Pension"
                            : pen?.pensionbook?.pensionprogram?.name
                          : "----"}
                      </td>

                      <td className="px-4 py-5">
                        <Popover content={pen?.transactionId}>
                          <div className="inline-block max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {pen?.transactionId?.substring(0, 16)}
                          </div>
                        </Popover>
                      </td>
                      <td className="px-4 py-5">
                        {pen?.pensionbook?.bookletNo}
                      </td>
                      <td
                        className={`px-4 py-5 ${
                          user !== "pensioner" ? "hidden" : ""
                        }`}
                      >
                        {pen?.merchstore !== null
                          ? pen?.merchstore?.customStoreName
                          : pen?.merchuser?.merchcompany?.name}
                      </td>

                      <td className={`px-4 py-5 `}>
                        <p
                          className="text-primary"
                          onClick={() => {
                            {
                              setShowContact(true);
                              if (pen?.merchstore === null) {
                                setEmail(pen?.merchheadquarter?.emails);
                                setPhone(pen?.merchheadquarter?.contacts);
                              } else {
                                setEmail(pen?.merchstore?.emails);
                                setPhone(pen?.merchstore?.phones);
                              }
                            }
                          }}
                        >
                          View Details
                        </p>
                      </td>
                      <td className="px-4 py-5">
                        <Popover
                          content={`${
                            pen.status === "pending" ? "In-Process" : ""
                          }`}
                          placement="bottomLeft"
                        >
                          <li className="flex items-center">
                            {/* {pen?.createdAt?.substring(0, 10)}{" "} */}
                            {moment(pen?.createdAt).format(
                              "MM/DD/YYYY hh:mm A"
                            )}
                          </li>
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
                // current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
      {showContact && (
        <ContactInfo setShow={setShowContact} email={email} phone={phone} />
      )}
    </section>
  );
};

export default Paget;
