import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import back5 from "../../imgs/back5.png";
import { Select } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment/moment";
function capitalizeWords(str) {
  return str
    .split("-") // Split the string at hyphens
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join("-"); // Join the words back together with hyphens
}
const HistoryTable = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [categories, setCategories] = useState([]);
  const universalPenId = useSelector((state) => state.universalPenId);
  const dataUser = useSelector((state) => state.dataUser);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [all, setAll] = useState(true);
  const [allData, setAllData] = useState([]);
  useEffect(() => {
    const api = "v1/activities/categories";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        const catagoriesArray = data.data.map((category) => ({
          value: category,
          label: capitalizeWords(category),
        }));
        catagoriesArray.sort((a, b) => a.label.localeCompare(b.label));
        setCategories(catagoriesArray);
      })

      .catch(() => {});
  }, []);

  useEffect(() => {
    if (!all) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/activities/pensioner/category?userId=${universalPenId}&activityCategory=${selectedHistory}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          // console.log("dataHistoryList", data.data)
          console.log("dataHistoryList", data);
          setAllData(data.data);
        })

        .catch(() => {});
    }
  }, [selectedHistory]);
  useEffect(() => {
    if (all) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/activities/pensioner/all?userId=${universalPenId}&limit=3&skip=0&page=1&sortBy=createdAt:desc`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          setAllData(data.data.rows);
          setAll(false);
        })

        .catch(() => {});
    }
  }, []);
  const columnHeaders = [
    "ID",
    "Date/Time",
    "Employee ID",
    "Employee Name",
    "Employee Email",
    "Role",
    "Module",
    "Action",
    "Device IP",
    "Location LBS/GEO",
  ];
  return (
    <div className="flex items-center w-full justify-center flex-col">
      <div className="md:w-[90%] w-full p-4">
        <div className="flex items-center mt-4">
          <div className="cursor-pointer">
            <img
              onClick={() => {
                {
                  dash === "/superAdminDashboard/history"
                    ? router("/superAdminDashboard/profPage")
                    : dash === "/EDashboard/history"
                    ? router("/EDashboard/profPage")
                    : router("/AdminDashboard/profPage");
                }
              }}
              className="sm:w-[1.7rem] w-[1.2rem]"
              src={back5}
              alt=""
            />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] text-[1.6rem] ml-[1rem]">
            History
          </h2>
        </div>
        <div className="sm:ml-0  w-full mt-2 sm:mt-[2rem] mb-8 bg-slate-50 p-4 sm:p-[35px] rounded-2xl relative">
          <h1 className="text-center text-lg sm:text-[1.6rem] font-bold">
            Select category to view history
          </h1>
          <div className="mt-4">
            <h2 className="mb-2 text-sm sm:text-base font-semibold">
              Categories:
            </h2>
            <Select
              placeholder="Select category"
              optionFilterProp="children"
              className="wow"
              style={{
                marginBottom: "1.2rem",
                //   pointerEvents: `${allDis ? "none" : "auto"}`,
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              onChange={(value) => setSelectedHistory(value)}
              value={selectedHistory}
              options={categories}
            />
          </div>
          {/* table */}
          <div className="overflow-x-auto w-full mt-10 bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap font-semibold tracking-wider"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {allData.length === 0 ? (
                <h1 className="flex items-center justify-center text-center text-[1.1rem] p-5">
                  No Data Found
                </h1>
              ) : (
                <tbody>
                  {allData.map((item, index) => (
                    <tr className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer">
                      <th
                        scope="row"
                        className="px-4 py-5 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {index + 1}
                      </th>
                      <td className="px-4 py-5 whitespace-nowrap">
                        <p>{moment(item?.createdAt).format("MM/DD/YYYY")}</p>
                        <p> {moment(item?.createdAt).format("hh:mm A")}</p>
                      </td>
                      <td className="px-4 py-5">
                        {item?.actionByUser?.uniqueId}
                      </td>
                      <td className="px-4 py-5">
                        {item?.actionByUser?.person?.firstName}{" "}
                        {item?.actionByUser?.person?.lastName}
                      </td>
                      <td className="px-4 py-5">{item?.actionByUser?.email}</td>
                      <td className="px-4 py-5">{item?.actionByUser?.roles}</td>
                      <td className="px-4 py-5">{item?.entityName}</td>
                      <td className="px-4 py-5">
                        <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 pl-5 pr-5 rounded-md  focus:border-none cursor-pointer">
                          {item?.action}
                        </div>
                      </td>
                      <td className="px-4 py-5">
                        <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 pl-5 pr-5 rounded-md  focus:border-none cursor-pointer">
                          {item?.ip === null ? "----" : `${item?.ip}`}
                        </div>
                      </td>
                      <td className="px-4 py-5">
                        <div className="flex items-center justify-center ml-6 whitespace-nowrap">
                          {item?.location && (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${item?.location}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline hover:text-blue-700 cursor-pointer"
                            >
                              View Location
                            </a>
                          )}
                          {item?.location === null && "No Location"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryTable;
