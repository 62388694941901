const DeathReasons = [
  {
    label: "Accidental discharge of firearms",
    value: "Accidental discharge of firearms",
  },
  { label: "Accidental drowning", value: "Accidental drowning" },
  {
    label: "Accidental exposure to smoke, fire and flames",
    value: "Accidental exposure to smoke, fire and flames",
  },
  { label: "Accidental falls", value: "Accidental falls" },
  { label: "Accidental poisoning", value: "Accidental poisoning" },
  { label: "Accidental suffocation", value: "Accidental suffocation" },
  { label: "Accidents", value: "Accidents" },
  {
    label: "Accidents (unintentional injuries)",
    value: "Accidents (unintentional injuries)",
  },
  { label: "Alcoholic liver disease", value: "Alcoholic liver disease" },
  { label: "Alzheimer disease", value: "Alzheimer disease" },
  { label: "Anemia", value: "Anemia" },
  {
    label: "Aortic aneurysm and ripping",
    value: "Aortic aneurysm and ripping",
  },
  { label: "Benign tumors", value: "Benign tumors" },
  { label: "Birth defects", value: "Birth defects" },
  { label: "Blood poisoning", value: "Blood poisoning" },
  { label: "Bladder cancer", value: "Bladder cancer" },
  { label: "Brain cancer", value: "Brain cancer" },
  { label: "Breast cancer", value: "Breast cancer" },
  { label: "Cancer", value: "Cancer" },
  {
    label: "Cancer of the plasma cells in blood",
    value: "Cancer of the plasma cells in blood",
  },
  {
    label: "Cervical and uterine cancers",
    value: "Cervical and uterine cancers",
  },
  {
    label: "Chronic liver disease (non-alcoholic)",
    value: "Chronic liver disease (non-alcoholic)",
  },
  {
    label: "Chronic liver disease and cirrhosis",
    value: "Chronic liver disease and cirrhosis",
  },
  {
    label: "Chronic lower respiratory diseases",
    value: "Chronic lower respiratory diseases",
  },
  { label: "Childbirth and pregnancy", value: "Childbirth and pregnancy" },
  {
    label: "Clostridium difficile bacterial infection",
    value: "Clostridium difficile bacterial infection",
  },
  {
    label: "Colon, rectal and anal cancers",
    value: "Colon, rectal and anal cancers",
  },
  {
    label: "Complications of medical or surgical care",
    value: "Complications of medical or surgical care",
  },
  { label: "COVID-19", value: "COVID-19" },
  { label: "Diabetes", value: "Diabetes" },
  { label: "Esophageal cancer", value: "Esophageal cancer" },
  { label: "Gallbladder disorders", value: "Gallbladder disorders" },
  {
    label: "Hardening and narrowing of the arteries",
    value: "Hardening and narrowing of the arteries",
  },
  { label: "Heart disease", value: "Heart disease" },
  { label: "High blood pressure", value: "High blood pressure" },
  { label: "HIV", value: "HIV" },
  { label: "Homicide by gunshot", value: "Homicide by gunshot" },
  {
    label: "Homicide not related to guns",
    value: "Homicide not related to guns",
  },
  { label: "Inflammation of the kidney", value: "Inflammation of the kidney" },
  { label: "Kidney cancer", value: "Kidney cancer" },
  { label: "Kidney disease", value: "Kidney disease" },
  {
    label: "Killings by law enforcement officers",
    value: "Killings by law enforcement officers",
  },
  { label: "Leukemia", value: "Leukemia" },
  { label: "Liver cancer", value: "Liver cancer" },
  { label: "Lung cancer", value: "Lung cancer" },
  { label: "Motor vehicle accidents", value: "Motor vehicle accidents" },
  { label: "Natural Causes", value: "Natural Causes" },
  {
    label: "Nephritis, nephrotic syndrome, and nephrosis",
    value: "Nephritis, nephrotic syndrome, and nephrosis",
  },
  { label: "Non-Hodgkin lymphoma", value: "Non-Hodgkin lymphoma" },
  { label: "Nutritional deficiencies", value: "Nutritional deficiencies" },
  { label: "Obesity", value: "Obesity" },
  { label: "Oral cancer", value: "Oral cancer" },
  { label: "Ovarian cancer", value: "Ovarian cancer" },
  { label: "Pneumonia and influenza", value: "Pneumonia and influenza" },
  { label: "Pneumonitis", value: "Pneumonitis" },
  { label: "Pancreatic cancer", value: "Pancreatic cancer" },
  { label: "Parkinson disease", value: "Parkinson disease" },
  { label: "Perinatal conditions", value: "Perinatal conditions" },
  { label: "Prostate cancer", value: "Prostate cancer" },
  { label: "Skin cancer", value: "Skin cancer" },
  { label: "Stomach cancer", value: "Stomach cancer" },
  { label: "Stroke", value: "Stroke" },
  {
    label: "Stroke (cerebrovascular diseases)",
    value: "Stroke (cerebrovascular diseases)",
  },
  { label: "Suicide by gunshot", value: "Suicide by gunshot" },
  { label: "Suicide by poisoning", value: "Suicide by poisoning" },
  {
    label: "Suicide by strangulation or suffocation",
    value: "Suicide by strangulation or suffocation",
  },
  {
    label: "Symptoms, signs and abnormal clinical and laboratory findings",
    value: "Symptoms, signs and abnormal clinical and laboratory findings",
  },
  { label: "Viral hepatitis", value: "Viral hepatitis" },
  { label: "Other", value: "Other" },
];
export default DeathReasons;
