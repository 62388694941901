import React from 'react'

const BankInfo = ({data}) => {
  console.log(data)
  return (
    <div className='p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8'>
    <div className="px-4 sm:px-0">
      <h3 className="text-base font-semibold leading-7 text-gray-600">
        Banking Details
      </h3>
      {/* <p classname="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          Personal details and application.
        </p> */}
    </div>
    <div className="mt-6 border-t border-gray-300">
      <dl className="divide-y divide-gray-300">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Bank Name
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
            {data?.countrybank?.name}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Branch Name
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
            {data?.countrybankbranch?.name}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ">
          <dt className="text-sm font-medium leading-6 text-gray-900 ">
            Account Name
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
            {data?.accountTitle}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
           Account No
          </dt>
          <dd className="mt-1 text-sm leading-6 text-blue-400 sm:col-span-2 sm:mt-0">
           
              {data?.accountNo}
          
          </dd>

        </div>
    
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
          Routing No
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {data?.routingNo}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
           Account Type
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {data?.accountType}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
           Branch Address
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {data?.countrybankbranch?.address}
          </dd>
        </div>
      </dl>
    </div>
  </div>
  )
}

export default BankInfo