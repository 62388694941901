import { useEffect, useState } from "react";
import pop from "../../imgs/pop.png";
import { useSelector, useDispatch } from "react-redux";
const Popup = () => {
  const dispatch = useDispatch();
  const option = useSelector((state) => state.option);
  const popup = useSelector((state) => state.popup);
  const icurrent = useSelector((state) => state.icurrent);
  const bicurrent = useSelector((state) => state.bicurrent);
  const brcurrent = useSelector((state) => state.brcurrent);
  const [word1, setWord1] = useState("");
  const [word2, setWord2] = useState("");
  const [show, setShow] = useState(false);
  const [buttonWord, setButtonWord] = useState("Continue");
  useEffect(() => {
    if (option === "Pensioner") {
      if (icurrent > 1 && icurrent < 5) {
        setWord1("Your Registration data has been successfully submited.");
        setWord2("Just one more step left");
        setShow(false);
      } else if (icurrent > 5 && icurrent < 9) {
        setWord1("Your Identification data has been successfully submited.");
        setWord2("");
        setButtonWord("Continue");
        setShow(false);
      } else if (icurrent > 10 && icurrent < 14) {
        setWord1(
          "Your Beneficiary registration data has been successfully submited."
        );
        setWord2("Just one more steps left");
        setShow(false);
      } else if (icurrent > 14 && icurrent < 17) {
        setWord1(
          "Your Beneficiary Identification data has been successfully submited."
        );
        setWord2("");
        setButtonWord("Ok");
        setShow(false);
      } else if (icurrent >= 17) {
        setWord1("Your Request has been sent to MHSSS for Approval");
        setWord2("");
        setButtonWord("Ok");
      }
    } else {
      if (icurrent > 5) {
        setWord1("Your Identification data has been successfully submited.");
        setWord2("");
        setButtonWord("Ok");
        setShow(false);
      } else {
        setWord1("Your Registration data has been successfully submited.");
        setWord2("Just one more step left");
        setShow(false);
      }
    }
  }, [option, icurrent, brcurrent, bicurrent]);

  const skipHandler = () => {
    dispatch({ type: "POP" });
    dispatch({ type: "SET", num: 6 });
  };
  const continueHandler = () => {
    dispatch({ type: "POP" });
  };
  return (
    <div
      className={`fixed w-[100%] h-[100%] top-0 bg-slate-500/50 flex justify-center items-center text-center ${
        popup ? "" : "hidden"
      }`}
    >
      <div className="lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[40%] lg:h-[22rem] w-[80%] sm:h-[18rem] h-[22.2rem] bg-white rounded-2xl flex items-center flex-col p-6 pl-5 pr-5">
        <div className="w-[148.8px] h-[135.8px]">
          <img className="scale-[1]" src={pop} alt="" />
        </div>
        <p className="lg:mt-[2rem] lg:text-[1rem] mt-5">
          <span className="font-semibold">
            {word1}&nbsp;{word2}
          </span>
        </p>
        <div className="flex w-full">
          <button
            onClick={skipHandler}
            className={`lg:mr-5 lg:text-[1.1rem] lg:mt-[2rem] ${
              show ? "" : "hidden"
            } w-full bg-[#F7542E] text-white pt-2 pb-2 rounded-md mt-5`}
          >
            Skip
          </button>
          <button
            onClick={continueHandler}
            className="lg:text-[1.1rem] lg:mt-[2rem] w-full bg-primary text-white pt-2 pb-2 rounded-md mt-5"
          >
            {buttonWord}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
