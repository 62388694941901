export const selectedPensionerSap = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_PENSIONER_SAP":
      return action.num;
    default:
      return state;
  }
};
export const childData = (state = [], action) => {
  switch (action.type) {
    case "Quick":
      return action.num;
    default:
      return state;
  }
};

export const merchantSapType = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_SAP_TYPE":
      return action.num;
    default:
      return state;
  }
};

export const merchantSapTypeData = (state = null, action) => {
  switch (action.type) {
    case "MERCHANT_SAP_TYPE_DATA":
      return action.num;
    default:
      return state;
  }
};

export const sapMoneyEnable = (state = false, action) => {
  switch (action.type) {
    case "SAP_MONEY_ENABLE":
      return action.num;
    default:
      return state;
  }
};

export const sapPaymentSelectedPerson = (state = null, action) => {
  switch (action.type) {
    case "SAP_PAYMENT_SELECTED_PERSON":
      return action.num;
    default:
      return state;
  }
};

export const programTransactionType = (state = null, action) => {
  switch (action.type) {
    case "PROGRAM_TRANSACTION_TYPE":
      return action.num;
    default:
      return state;
  }
};

export const transactionType = (state = "program", action) => {
  switch (action.type) {
    case "TRANSACTION_TYPE":
      return action.num;
    default:
      return state;
  }
};

export const pensionerSenderTypeVerification = (state = false, action) => {
  switch (action.type) {
    case "PENSIONER_SENDER_TYPE_VERIFICATION":
      return action.num;
    default:
      return state;
  }
};

export const totalBalance = (state = 0, action) => {
  switch (action.type) {
    case "TOTAL_BALANCE":
      return action.num;
    default:
      return state;
  }
};

export const sapMoneyStores = (state = [], action) => {
  switch (action.type) {
    case "SAP_MONEY_STORES":
      return action.num;
    default:
      return state;
  }
};

export const requestPageEnable = (state = false, action) => {
  switch (action.type) {
    case "REQUEST_PAGE_ENABLE":
      return action.num;
    default:
      return state;
  }
};

export const mhsssPayStatus = (state = null, action) => {
  switch (action.type) {
    case "MHSSS_PAY_STATUS":
      return action.num;
    default:
      return state;
  }
};

//For Merchant respond on behalf of pensioner
export const merPenReqDetail = (state = null, action) => {
  switch (action.type) {
    case "MER_PEN_REQ_DETAIL":
      return action.num;
    default:
      return state;
  }
};

//For Merchant respond on behalf of pensioner Check is this flow is enable or not
export const merPenReqCheck = (state = false, action) => {
  switch (action.type) {
    case "MER_PEN_REQ_ENABLE":
      return action.num;
    default:
      return state;
  }
};

//set the uuid when pensioner deposit money to his account for history
export const uuid = (state = null, action) => {
  switch (action.type) {
    case "UUID":
      return action.num;
    default:
      return state;
  }
};

