export const purposeOptions = [
  // { label: "Options", value: null },
  { label: "Purchase", value: "purchase" },
  { label: "Subscription", value: "subscription" },
  { label: "Donation", value: "donation" },
  { label: "Membership Fee", value: "membership_fee" },
  { label: "Bill Payment", value: "bill_payment" },
  { label: "Loan Repayment", value: "loan_repayment" },
  { label: "Refund", value: "refund" },
  { label: "Service Fee", value: "service_fee" },
  { label: "Deposit", value: "deposit" },
  { label: "Insurance Premium", value: "insurance_premium" },
  { label: "Birthday Gift", value: "birthday_gift" },
  { label: "Education", value: "education" },
];
