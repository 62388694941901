import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import InfoModal from "./infoModal";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
const Main = () => {
  const sapmoney = useSelector((state) => state.sapmoney);
  const mhsssPayStatus = useSelector((state) => state.mhsssPayStatus);
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [amount, setAmount] = useState(0);
  const selectedVouchers = [];
  const data = [];
  const [transFee, setTransFee] = useState(0);
  // const [allTran, setAllTran] = useState(false);
  const [voucherId, setVoucherId] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const selectedTransactions = useSelector(
    (state) => state.selectedTransactions
  );
  // console.log("selectedTransactions", selectedTransactions);
  const selectedTransactionsVouchers = useSelector(
    (state) => state.selectedTransactionsVouchers
  );
  const selectedOutstandingStatus = useSelector(
    (state) => state.selectedOutstandingStatus
  );
  const selectedOutstandingMerchant = useSelector(
    (state) => state.selectedOutstandingMerchant
  );
  console.log(
    "selectedOutstandingMerchant",
    mhsssPayStatus,
    sapmoney,
    selectedOutstandingMerchant
  );
  // console.log("selectedTransactionsVouchers", selectedTransactionsVouchers);
  const [voucherImage, setVoucherImage] = useState("");
  const [voucherModal, setVoucherModal] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [content, setContent] = useState("");
  const [showData, setShowData] = useState(false);
  const [allVerified, setAllVerified] = useState(false);
  const [reason, setReason] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [vocStatus, setVocStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/for-mhsss?merchTransactionId=${selectedTransactions?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({
          type: "SELECTED_TRANSACTIONS_VOUCHERS",
          num: data?.data?.rows,
        });
        setShowData(true);
        const allVocVerified = data?.data?.rows?.every(
          (item) =>
            item?.merchredeemcouponstatuses?.[0]?.statusByMhsss === "accepted"
        );

        const totalAmountPaid = data?.data?.rows?.reduce(
          (sum, item) => sum + (item?.amountPaid ?? 0),
          0
        );

        setAmount(totalAmountPaid);
        setTransFee(
          selectedOutstandingStatus?.feeAmount *
            selectedTransactionsVouchers?.length
        );

        const done = allVocVerified ? true : false;
        setAllVerified(done);
      })
      .catch(() => {
        // Handle error
      });
  }, [refetch]);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-user/${id}/status`, {
  //       headers: {
  //         Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //       },
  //     })
  //     .then((data) => {
  //       dispatch({
  //         type: "SELECTED_OUTSTANDING_STATUS",
  //         num: data?.data,
  //       });
  //     })
  //     .catch((err) => {
  //       // Handle error
  //     });
  // }, []);

  useEffect(() => {
    dispatch({ type: "ISET", num: 0 });
    let totalAmount = 0;
    selectedVouchers.forEach((voucherId) => {
      const voucher = data.find((voucher) => voucher.id === voucherId);
      if (voucher) {
        voucher.transactionDetails.forEach((detail) => {
          if (detail.label === "Pending Amount") {
            totalAmount += parseInt(detail.value.replace("$", ""), 10);
          }
        });
      }
    });
    // setTotalPendingAmount(totalAmount);
    if (selectedTransactionsVouchers?.length === selectedVouchers?.length) {
      // setAllTran(true);
    } else {
      // setAllTran(false);
    }
    console.log("total", amount);
  }, [selectedVouchers, selectedTransactionsVouchers]);

  const Div = ({ label, value }) => {
    return (
      <div className="flex justify-between space-y-2">
        <div className={`text-gray-600 flex items-center`}>{label}</div>
        <div
          className={`${
            label === "Amount :" ? "font-bold" : ""
          } flex items-center capitalize`}
        >
          {value}
        </div>
      </div>
    );
  };
  const DivB = ({ label, value }) => {
    return (
      <div className="flex justify-between space-y-2">
        <div className={`text-gray-600 flex items-center`}>{label}</div>
        <div className={`font-semibold flex items-center capitalize`}>
          {value}
        </div>
      </div>
    );
  };

  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };
  const changeVocuherStatus = (status) => {
    const body = {
      statusByMhsss: status,
    };
    if (status === "rejected") {
      body.rejectNoteByMhsss = reason;
    }
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/${voucherId}/status/for-mhsss`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setShowModal(false);
        setVoucherModal(false);
        setRefetch(!refetch);
        setReason("");
        // console.log("data", data);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
        // Handle error
      });
  };

  // const handleSelectVoucher = (voucherId) => {
  //   console.log("voucherId", voucherId);
  //   if (selectedVouchers.includes(voucherId?.id)) {
  //     // If voucherId is already selected, remove it from the selectedVouchers array
  //     setSelectedVouchers(selectedVouchers.filter((id) => id !== voucherId?.id));
  //     // Decrement the value in the amount state by the voucher amount
  //     setAmount((prevAmount) => prevAmount - voucherId.amountPaid);
  //   } else {
  //     // If voucherId is not selected, add it to the selectedVouchers array
  //     setSelectedVouchers([...selectedVouchers, voucherId]);
  //     // Increment the value in the amount state by the voucher amount
  //     setAmount((prevAmount) => prevAmount + voucherId.amountPaid);
  //   }
  // };
  const imageConverter = (url) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=paymentRecipet`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setVoucherImage(data.data[0]?.url);
        setVoucherModal(true);
      })
      .catch(() => {
        // Handle error
      });
  };
  useEffect(() => {
    if (selectedOutstandingStatus?.feeType === "per transaction") {
      setTransFee(selectedOutstandingStatus?.feeAmount);
    } else {
      setTransFee(
        selectedOutstandingStatus?.feeAmount * selectedVouchers.length
      );
    }
  }, [selectedVouchers]);
  console.log("amount", amount, "fee", transFee);
  return (
    <div className="flex flex-col w-full rounded-md bg-[#D9EFED] flex">
      {/* <div className="w-full flex items-center justify-end pb-2 p-1">
        <button
        onClick={()=>{
          router("/MHSSS/merchProfile")
        }}
          className={`w-[12rem] flex pl-2 pr-2 font-bold items-center justify-center rounded-md justify-end text-primary border bg-white border-primary text-[15px] h-[35px]`}
        >
          See Merchant Profile
        </button>
      </div> */}
      <div className="flex flex-col w-full relative rounded-md bg-white shadow-lg gap-6 flex pt-8 pb-10 p-4">
        <div className="flex gap-2">
          <div className="p-4 rounded-md w-[50%] bg-gray-200">
            <DivB
              label={"Merchant Name :"}
              value={
                selectedTransactions?.merchuser?.merchcompany !== null
                  ? selectedTransactions?.merchuser?.merchcompany?.name
                  : "------"
              }
            />

            {/* <DivB
              label={"Requested On :"}
              value={
                moment(selectedTransactions?.createdAt).format("DD MMM,YYYY") +
                " " +
                moment(selectedTransactions?.createdAt)?.format("HH:mm A")
              }
            /> */}
            <DivB
              label={"Program :"}
              value={
                selectedTransactionsVouchers?.[0]?.pensionprogram?.name ===
                "senior-citizen-Pension"
                  ? "Senior Citizen Pension"
                  : selectedTransactionsVouchers?.[0]?.pensionprogram?.name
              }
            />
            <DivB
              label={"Store :"}
              value={
                selectedTransactions?.merchstore
                  ? selectedTransactions?.merchstore?.customStoreName
                  : "-----"
              }
            />
          </div>
          <div className="p-4 rounded-md w-[50%] bg-gray-200">
            <DivB
              label={"Pensioner Name :"}
              value={`${
                selectedTransactionsVouchers?.[0]?.user?.person?.firstName ?? ""
              } ${
                selectedTransactionsVouchers?.[0]?.user?.person?.lastName ?? ""
              }`.trim()}
            />
            <DivB
              label={"GID No :"}
              value={selectedTransactionsVouchers?.[0]?.user?.gid}
            />

            <div className="flex justify-between space-y-2">
              <div className={`text-gray-600 flex items-center`}>
                Voucher transaction ID :
                <p
                  className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                  onClick={() => {
                    setContent(
                      "Auto generated transaction ID when Merchant redeemed pensioner’s vouchers."
                    );
                    setShowInfo(true);
                  }}
                >
                  i
                </p>
              </div>
              <div className={`font-semibold flex items-center capitalize`}>
                {selectedOutstandingMerchant?.transactionId}
              </div>
            </div>
            <DivB
              label={"Voucher cashing date:"}
              value={moment(
                selectedTransactionsVouchers?.[0]?.createdAt
              ).format("MM/DD/YYYY hh:mm A")}
            />
            <DivB
              label={"Booklet # :"}
              value={selectedTransactionsVouchers?.[0]?.pensionbook?.bookletNo}
            />
          </div>
        </div>
        <div>
          {/* <div className="flex flex-wrap gap-4">
            {selectedTransactionsVouchers?.map((data, ind) => (
              <div
                key={ind}
                className="w-[20rem] h-auto shadow-lg rounded-lg border border-[#1BA397] bg-[#F4F8F6] p-2 px-3"
              >
                <div className="w-full mt-1 p-2 space-y-2">
                  <Div label={"Amount :"} value={`$${data?.amountPaid}`} />

                  <Div label={"Notes :"} value={data?.note} />
                  <Div label={"Notes :"} value={"By Merchant Employee Latest EMPLOYEE"} />
                 
                </div>
                <div className="w-full pl-2 pr-2 ">
                  <div className="flex justify-between">
                    <div className={`text-gray-600 flex items-center`}>
                      Voucher cashing ID :{" "}
                      <p
                        className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                        onClick={() => {
                          setContent(
                           "Auto generated Unique Transaction ID for each voucher cashing."
                          );
                          setShowInfo(true);
                        }}
                      >
                        i
                      </p>
                    </div>
                    <div className={`flex items-center capitalize relative`}>
                      {data?.transactionId}
                    </div>
                  </div>
                </div>

                <div className="w-full pl-2 pr-2">
                  <div className="flex justify-between space-y-2">
                    <div className={`text-gray-600 flex items-center`}>
                      Voucher :
                    </div>
                    <div className={`flex items-center capitalize`}>
                      {getMonthName(data?.coupon?.month)}{" "}
                      <span
                        className="ml-2 text-red-500 cursor-pointer"
                        onClick={() => {
                          setVoucherId(
                            data?.merchredeemcouponstatuses[0]
                              ?.merchredeemcouponId
                          );
                          setVoucherAmount(data?.amountPaid);
                          imageConverter(data?.voucherImage);
                          setVocStatus(
                            data?.merchredeemcouponstatuses[0]?.statusByMhsss
                          );
                        }}
                      >
                        view
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`mb-1 text-gray-700 mt-1 ${
                    data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                    "rejected"
                      ? ""
                      : "hidden"
                  }`}
                >
                  <label className="px-2 mb-1">Reason</label>
                  <textarea
                    className="bg-white p-3 w-full rounded-md h-[3rem] mb-2"
                    value={
                      data?.merchredeemcouponstatuses[0]?.rejectNoteByMhsss
                    }
                    disabled
                  />
                </div>
                <div className="w-full flex items-center relative mt-4 bg-none h-[24px]">
                  <div className="w-full flex items-center gap-x-4 justify-end">
                    <p
                      className={`mb-1 font-semibold ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted"
                          ? "text-primary"
                          : "text-red-600"
                      }`}
                    >
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                      "accepted"
                        ? "Voucher viewed and verified"
                        : data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                          "rejected"
                        ? "Voucher Rejected"
                        : ""}
                    </p>

                    <div
                      className={`border rounded-md border w-6 h-6 flex items-center justify-center mr-[5px] mb-1
                      ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "pending"
                          ? "hidden"
                          : ""
                      }
                      ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted"
                          ? "bg-primary"
                          : data?.merchredeemcouponstatuses[0]
                              ?.statusByMhsss === "rejected"
                          ? "bg-red-600"
                          : ""
                      }`}
                      style={{
                        transform: "scale(1.2)",
                      }}
                    >
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted" && (
                        <svg
                          className="w-4 h-4 text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "rejected" && (
                        <p className="font-semibold text-white">X</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 p-6 cursor-pointer">
            {selectedTransactionsVouchers?.map((data, ind) => (
              <div
                key={ind}
                className="h-auto shadow-lg rounded-lg border border-[#1BA397] bg-[#F4F8F6] p-2 px-3"
              >
                <div className="w-full mt-1 p-2 space-y-2">
                  <Div label={"Amount :"} value={`$${data?.amountPaid}`} />
                  {/* 
                  <Div label={"Notes :"} value={data?.note} />
                  <Div label={"Notes :"} value={"By Merchant Employee Latest EMPLOYEE"} /> */}
                </div>
                <div className="w-full pl-2 pr-2 ">
                  <div className="flex justify-between">
                    <div className={`text-gray-600 flex items-center`}>
                      Voucher cashing ID :{" "}
                      <p
                        className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                        onClick={() => {
                          setContent(
                            "Auto generated Unique Transaction ID for each voucher cashing."
                          );
                          setShowInfo(true);
                        }}
                      >
                        i
                      </p>
                    </div>
                    <div className={`flex items-center capitalize relative`}>
                      {data?.transactionId}
                    </div>
                  </div>
                </div>

                <div className="w-full pl-2 pr-2 mt-1">
                  <div className="flex justify-between space-y-2">
                    <div className={`text-gray-600 flex items-center`}>
                      Voucher :
                    </div>
                    <div className={`flex items-center capitalize`}>
                      {getMonthName(data?.coupon?.month)}{" "}
                      <span
                        className="ml-2 text-red-500 cursor-pointer"
                        onClick={() => {
                          setVoucherId(
                            data?.merchredeemcouponstatuses[0]
                              ?.merchredeemcouponId
                          );
                          // setVoucherAmount(data?.amountPaid);
                          imageConverter(data?.voucherImage);
                          setVocStatus(
                            data?.merchredeemcouponstatuses[0]?.statusByMhsss
                          );
                        }}
                      >
                        {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted"
                          ? "View"
                          : "Verify"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`mb-1 text-gray-700 mt-1 ${
                    data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                    "rejected"
                      ? ""
                      : "hidden"
                  }`}
                >
                  <label className="px-2 mb-1">Reason</label>
                  <textarea
                    className="bg-white p-3 w-full rounded-md h-[3rem] mb-2"
                    value={
                      data?.merchredeemcouponstatuses[0]?.rejectNoteByMhsss
                    }
                    disabled
                  />
                </div>
                <div className="w-full flex items-center relative mt-4 bg-none h-[24px]">
                  <div className="w-full flex items-center gap-x-4 justify-end">
                    <p
                      className={`mb-1 font-semibold ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted"
                          ? "text-primary"
                          : "text-red-600"
                      }`}
                    >
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                      "accepted"
                        ? "Voucher viewed and verified"
                        : data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                          "rejected"
                        ? "Voucher Rejected"
                        : ""}
                    </p>

                    <div
                      className={`border rounded-md border w-6 h-6 flex items-center justify-center mr-[5px] mb-1
                      ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "pending"
                          ? "hidden"
                          : ""
                      }
                      ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted"
                          ? "bg-primary"
                          : data?.merchredeemcouponstatuses[0]
                              ?.statusByMhsss === "rejected"
                          ? "bg-red-600"
                          : ""
                      }`}
                      style={{
                        transform: "scale(1.2)",
                      }}
                    >
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted" && (
                        <svg
                          className="w-4 h-4 text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "rejected" && (
                        <p className="font-semibold text-white">X</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <div className="text-gray-400 flex w-full">
              {/* <p className="w-[22px] h-[22px] text-center flex items-center justify-center bg-gray-400 text-semibold text-white rounded-full mr-2">
                ?
              </p> */}
              All the vouchers should be verified to proceed with the payment
            </div>
          </div>
        </div>
        <div
          className={`${
            mhsssPayStatus === "success" || (sapmoney === true && mhsssPayStatus === "success")
              ? "hidden"
              : ""
          }`}
        >
          {allVerified && (
            <div
              className={`${
                showData ? "block" : "hidden"
              } w-full rounded-lg flex flex-col h-[10rem] border bg-white p-2`}
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="w-[40%] flex items-center justify-center flex-col">
                <div className="w-full flex items-center justify-between border-b pb-3 pl-2 pr-2">
                  <h1 className="font-weight-regular ">
                    Total Voucher Payment
                  </h1>
                  <h1 className="font-semibold text-sm">
                    {amount === 0 ? "Amount" : `$${amount}`}
                  </h1>
                </div>
              </div>
              <div className="w-[42%] flex items-center justify-center flex-col">
                <div className="w-full flex items-center justify-between pt-2 pl-2 pr-2">
                  <h1 className="font-weight-regular ">Voucher cashing fee</h1>
                  <div className="flex relative">
                    <h1 className="font-semibold text-sm">
                      {selectedOutstandingStatus?.feeType === "per transaction"
                        ? `$${selectedOutstandingStatus?.feeAmount}`
                        : `$${
                            selectedOutstandingStatus?.feeAmount *
                            (selectedTransactionsVouchers?.length ?? 0)
                          }`}
                    </h1>
                    <p
                      className="ml-2 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full mr-2 cursor-pointer"
                      onClick={() => setShowAmount(!showAmount)}
                    >
                      i
                    </p>
                    {showAmount && (
                      <div className="w-[15rem] absolute bg-primary text-white rounded-lg px-2 py-1 top-0 left-0 transform -translate-y-full">
                        {selectedOutstandingStatus?.feeType ===
                        "per transaction"
                          ? `MHSSS has set $${selectedOutstandingStatus?.feeAmount} per transaction 
                        fee for this merchant`
                          : `MHSSS has set $${selectedOutstandingStatus?.feeAmount} per voucher 
                        fee for this merchant`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full rounded-lg flex items-center justify-between h-[10rem] bg-white">
                <div className="rounded-full bg-[#D9EFED] p-2 pl-4 pr-4 flex w-[40%] items-center justify-between">
                  <h2 className="font-weight-regular text-sm">
                    Total amount to be paid
                  </h2>
                  <h1
                    className={`${
                      amount === 0 ? "text-[#464646]" : "text-black"
                    } font-bold`}
                  >
                    {/* {amount === 0 ? "Amount" : `$${amount + transFee}`} */}
                    {amount === 0
                      ? "Amount"
                      : `${
                          selectedOutstandingStatus?.feeType ===
                          "per transaction"
                            ? `$${
                                selectedOutstandingStatus?.feeAmount + amount
                              }`
                            : `$${
                                selectedOutstandingStatus?.feeAmount *
                                  (selectedTransactionsVouchers?.length ?? 0) +
                                amount
                              }`
                        }`}
                  </h1>
                </div>
                {user === "employee" &&
                !privilege.includes("Pay merchant transaction") ? null : (
                  <button
                    onClick={() => {
                      if (sapmoney === true) {
                        dispatch({
                          type: "Payment",
                          num: `${
                            selectedOutstandingStatus?.feeType ===
                            "per transaction"
                              ? `${
                                  selectedOutstandingStatus?.feeAmount + amount
                                }`
                              : `${
                                  selectedOutstandingStatus?.feeAmount *
                                    (selectedTransactionsVouchers?.length ??
                                      0) +
                                  amount
                                }`
                          }`,
                        });
                        router("/SapMoney/MHSSS/outstanding/payment");
                        return;
                      }
                      dispatch({
                        type: "Payment",
                        num: `${
                          selectedOutstandingStatus?.feeType ===
                          "per transaction"
                            ? `${
                                selectedOutstandingStatus?.feeAmount + amount
                              }`
                            : `${
                                selectedOutstandingStatus?.feeAmount *
                                  (selectedTransactionsVouchers?.length ?? 0) +
                                amount
                              }`
                        }`,
                      });
                      router("/MHSSS/payoutstandings");
                    }}
                    className={`w-[5rem] mr-1 flex pl-2 pr-2 font-bold items-center justify-center rounded-xl text-white text-[15px] h-[35px] ${
                      amount === 0
                        ? "bg-[#DDE1E6] cursor-not-allowed"
                        : "bg-[#1BA397] cursor-pointer  hover:bg-[#2AB9A7]"
                    }`}
                    disabled={amount === 0}
                  >
                    Pay Now
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {voucherModal && (
          <div
            className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow ">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                  <h3 className="text-lg font-semibold text-gray-900 ">
                    Voucher Image
                  </h3>
                  <button
                    onClick={() => setVoucherModal(false)}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                    data-modal-toggle="select-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <img
                  src={voucherImage}
                  alt="voucher"
                  className="w-full h-[15rem] p-3"
                />
                {user === "employee" &&
                !privilege.includes(
                  "Accept or reject cashed voucher"
                ) ? null : (
                  <div
                    className={`${
                      vocStatus === "accepted"
                        ? "hidden"
                        : "flex items-center justify-center gap-2 pb-4"
                    }`}
                  >
                    <button
                      onClick={() => {
                        changeVocuherStatus("accepted");
                      }}
                      className={`w-[5rem] flex pl-2 pr-2 font-bold items-center justify-center rounded-xl text-white text-[15px] h-[35px] bg-primary`}
                    >
                      Verified
                    </button>
                    <button
                      onClick={() => {
                        // setVoucherModal(false);
                        setShowModal(true);
                      }}
                      className={`w-[5rem] flex pl-2 pr-2 font-bold items-center justify-center rounded-xl text-white text-[15px] h-[35px] bg-red-500`}
                    >
                      Rejected
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Rejected Modal */}
        {showModal && (
          <div
            className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow p-4">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                  <h3 className="text-lg font-semibold text-gray-900 ">
                    Reject Voucher
                  </h3>
                  <button
                    onClick={() => {
                      setShowModal(false);
                      setReason("");
                    }}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                    data-modal-toggle="select-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <p className="mt-2 mb-2">
                  Are you sure you want to reject the voucher?
                </p>
                <textarea
                  placeholder="Reason"
                  className="bg-gray-100 w-full mb-2 p-3 rounded-md"
                  rows={3}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
                <div
                  className={`${
                    vocStatus === "accepted"
                      ? "hidden"
                      : "flex items-center justify-center gap-2 pb-4"
                  }`}
                >
                  <button
                    onClick={() => {
                      setShowModal(false);
                      setReason("");
                    }}
                    className={`w-[5rem] flex pl-2 pr-2 font-bold items-center justify-center rounded-xl text-white text-[15px] h-[35px] bg-red-500`}
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      changeVocuherStatus("rejected");
                    }}
                    className={`w-[5rem] flex pl-2 pr-2 font-bold items-center justify-center rounded-xl text-white text-[15px] h-[35px] bg-primary`}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showInfo && <InfoModal setShow={setShowInfo} content={content} />}
        {show && <ErrorModal err={err} setShow={setShow} />}
      </div>
    </div>
  );
};

export default Main;
