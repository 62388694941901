import { DatePicker, Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import InfoModal from "../../outstanding/PersonTransaction/Vouchers/infoModal";
// import SearchFilter from "./SearchFilter";
const Paget = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");
  const countryCode = null;
  const phoneNoo = null;
  const [searchDop, setSearchDop] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [outstanding, setOutstanding] = useState([]);
  const [paymentDate, setPaymentDate] = useState("");
  const [content, setContent] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const columnHeaders = [
    "SR.",
    "Merchant Name",
    "Merchant ID",
    "Phone",
    "Email",
    "Date of Birth",
    "Status",
    "Created Date/Time",
  ];

  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip:
          search || searchDop || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(searchDop && { paymentDate: searchDop }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, searchDop, countryCode, phoneNoo, pagewithsearch]);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-owners-by-payment-filters/for-mhsss?defaultPayMethods[]=sap-wallet`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setOutstanding(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const getMerchantOwner = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/owner-by-hq/for-mhsss?merchHeadquarterId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({
          type: "MHHS_MERCH_EMP_ID",
          num: data?.data?.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disabledDate1 = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };
  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl relative  h-auto bg-white">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#ebfff3]">
          <div className="w-full flex flex-row items-center justify-end mb-4 gap- sm:bg-[#ebfff3]">
            {/* search */}

            {/* filter */}
            <div className="w-[45%]  md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <div className="relative w-full">
                    <button
                      id="filterDropdownButton"
                      data-dropdown-toggle="filterDropdown"
                      className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                      type="button"
                      onClick={showHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter By
                      <svg
                        className="-mr-1 ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>

                    {show && (
                      <div
                        id="filterDropdown"
                        className="z-50 absolute top-full right-0 mt-2 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                      >
                        <div className="mt-5">
                          <DatePicker
                            onChange={(e, f) => setPaymentDate(f)}
                            value={
                              paymentDate !== "" ? moment(paymentDate) : null
                            }
                            placeholder="Payment Date"
                            disabledDate={disabledDate1}
                            // value={wow !== "" ? set : null}
                            // onChange={(e, f) => change1Handler(e, f)}
                          />
                        </div>

                        <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => {
                              if (paymentDate !== null && paymentDate !== "")
                                setSearchDop(paymentDate);
                              setShow(false);
                            }}
                            className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              setSearchDop("");
                              setPaymentDate("");
                              setShow(false);
                            }}
                            className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-[20rem] w-[55%]">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search"
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <p
              className={`
              cursor-pointer ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                
            `}
              onClick={() => {
                setContent(
                  `Search by Following:
                  1. Amount Paid
                  2. GID
                  3. Pensioner lastname
                  4. Store Name
                  5. Merchant lastname
                  6. Booklet No
                  7. Merchant Phone
                  8. Pensioner Phone
                  `
                );
                setShowInfo(true);
              }}
            >
              i
            </p>
          </div>

          {/* table */}
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>

              {outstanding?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p className="p-10 py-20">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {outstanding.map((data, index) => (
                    <tr
                      onClick={() => {
                        //   if (data?.merchperson === null) {
                        //     setError("Incomplete Merchant Profile");
                        //     setShowError(true);
                        //     return;
                        //   }
                        dispatch({
                          type: "SELECTED_MERCHANT_FOR_APPROVAL_ID",
                          num: data.id,
                        });

                        dispatch({
                          type: "MHHS_MERCH_USER",
                          num: data?.role,
                        });
                        if (data?.role === "merchant-employee") {
                          getMerchantOwner(data?.merchheadquarterId);
                        }
                        dispatch({
                          type: "MHHS_MERCH_ID",
                          num: data.id,
                        });
                        dispatch({
                          type: "SELECTED_OUTSTANDING_MERCHANT",
                          num: data,
                        });
                        router("/SapMoney/MHSSS/outstanding/person");

                        //   router("/MHSSS/Merchant/Profile");
                      }}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {search
                          ? index + 1 + (pagewithsearch - 1) * 6
                          : index + 1 + (page - 1) * 6}
                        .
                      </th>
                      <td className="px-4 py-3 whitespace-nowrap capitalize">
                        <div className="flex items-center">
                          <img
                            className="w-10 ml-0  h-10 rounded-full mr-2 border-2 border-primary "
                            src={
                              data?.merchcompany !== null
                                ? data?.merchcompany?.logo
                                : "profile"
                            }
                            alt={`pro`}
                          />
                          <p className="mr-6" style={{ whiteSpace: "nowrap" }}>
                            {data?.merchcompany !== null
                              ? data?.merchcompany?.name
                              : "----"}
                          </p>
                        </div>
                      </td>

                      <td className="px-6  py-5">
                        <Popover content={data.uniqueId}>
                          <div className="inline-block flex items-center justify-center max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {data.uniqueId.substring(0, 5)}..
                          </div>
                        </Popover>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.countryCode !== null
                          ? `${data?.countryCode} ${data?.phoneNo ?? ""}`
                          : "----"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.email}
                      </td>

                      <td className="px-4 py-3 whitespace-nowrap">
                        {dayjs(data?.dateOfBirth)?.format("YYYY-MM-DD")}
                      </td>

                      <td className="px-4 py-3 whitespace-nowrap">
                        <Popover
                          content={`${
                            data?.status === "pending" ? "In-Process" : ""
                          }`}
                          placement="bottomLeft"
                        >
                          <div
                            className={`border-none ${
                              data?.status === "pending"
                                ? "bg-[#2F84F1]"
                                : data?.status === "rejected"
                                ? "bg-[#D64242]"
                                : " bg-primary"
                            }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
                          >
                            {data?.status}
                          </div>
                        </Popover>
                      </td>
                      <td className="px-4 py-3">
                        <p>{moment(data?.createdAt).format("MM/DD/YYYY")}</p>
                        <p> {moment(data?.createdAt).format("hh:mm A")}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
      {showInfo && (
        <InfoModal
          show={showInfo}
          setShow={setShowInfo}
          content={content}
          mhsss={true}
        />
      )}
    </section>
  );
};

export default Paget;
