import Aaccount from "../components/AAccount/Aaccount";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const AAccount = () => {
  return (
    <div>
      <Nav />
      <Aaccount />
      <Whatsapp />
    </div>
  );
};

export default AAccount;
