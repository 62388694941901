import { Dropdown, Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import bs1 from "../../imgs/bs1.png";
import across from "../../imgs/across.png";
import axios from "axios";
import moment from "moment";
import EmpRegisterPol from "./empRegisterPol";
import EmpRegisterPol1 from "./empRegisterPol1";
const PolTable = () => {
  const pol = useSelector((state) => state.pol);
  const pol1 = useSelector((state) => state.pol1);
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [pensioner, setPensioner] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [show1, setShow1] = useState(false);
  const [err, setErr] = useState("");
  const [checkProfile, setCheckProfile] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const api = search ? `search=${search}` : ``;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioners-by-pol?limit=6&page=${page}&sortBy=createdAt:desc&${api}&polStatusList[]=active-pol&polStatusList[]=no-pol`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setPensioner(data.data.rows);

        setTotalPages(data.data.totalPages);
      })
      .catch(() => {});
  }, [page, search, refetch]);
  const columnHeaders = [
    "ID",
    "Name",
    "Pensioner ID",
    "GID",
    "Phone",
    "DOB",
    "POL Status",
    "Created Date/Time",
    "Action",
  ];

  const items = [
    {
      label: "POL Upload",
      key: "1",
      icon: <img style={{ width: "1.5rem" }} src={bs1}  alt="img"/>,
    },
    {
      label: "POL Capture",
      key: "2",
      icon: <img style={{ width: "1.5rem" }} src={bs1}  alt="img"/>,
    },
  ];

  const items1 = [
    {
      label: "POL Already Uploaded",
    },
  ];
  const onClick = ({ key, index }) => {
    console.log(index);
    if (checkProfile !== null) {
      if (key === "1") {
        dispatch({ type: "pol" });
      } else if (key === "2") {
        dispatch({ type: "pol1" });
        dispatch({ type: "cam" });
      }
    } else {
      setShow1(true);
      setErr("Personal data is missing or does not exist in profile");
    }
  };
  const onClick2 = (e, polStatus, profile) => {
    setCheckProfile(profile);
    if (profile === null) {
    } else {
      dispatch({ type: "polIdSet", num: e });
      dispatch({ type: "polStatusSet", num: polStatus });
      dispatch({
        type: "polNameSet",
        num: `${profile.firstName} ${profile.lastName}`,
      });
    }
    // setBuffer(e);
  };
  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  return (
    <section className="p-4 md:w-[90%] w-full">
      <div className="">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50 ">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            {/* search */}
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by GID or Last Name "
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
          {/* table */}
          <div className="overflow-x-auto">
            {pensioner.length === 0 ? (
              <h1 className="text-center text-[1.1rem] p-5 h-40">
                No Data Found
              </h1>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 whitespace-nowrap font-semibold tracking-wider"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {pensioner.map((pen, index) => (
                    <tr
                      key={index}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-3 py-5 font-medium text-gray-900  whitespace-nowrap"
                      >
                        {search
                          ? index + 1 + (pagewithsearch - 1) * 6
                          : index + 1 + (page - 1) * 6}
                        .
                      </th>
                      <td className="px-6 p-2 py-5">
                        <div className="flex items-center">
                          <p className="mr-6" style={{ whiteSpace: "nowrap" }}>
                            {pen?.person?.firstName} {pen?.person?.lastName}
                          </p>
                        </div>
                      </td>
                      <td className="px-6  py-5">
                        <Popover content={pen.uniqueId}>
                          <div className="inline-block flex items-center justify-center max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {pen.uniqueId.substring(0, 5)}..
                          </div>
                        </Popover>
                      </td>
                      <td className="px-6  py-5">{pen.gid}</td>

                      <td
                        className="px-6  py-5"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {pen?.countryCode !== null
                          ? pen?.countryCode + " " + pen?.phoneNo
                          : "----"}
                      </td>
                      <td
                        className="px-6  py-5"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {pen?.person?.dateOfBirth}
                      </td>

                      <td className="px-6  py-5">
                        <Popover
                          // content={`${item.status === "pending" ? "In-Process" : ""}`}
                          placement="bottomLeft"
                        >
                          <div
                            className={`border-none  whitespace-nowrap flex items-center justify-center ${
                              pen?.polStatus === "no-pol"
                                ? "bg-[#2F84F1]"
                                : " bg-primary"
                            }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                          >
                            {pen?.polStatus === "no-pol"
                              ? pen?.polStatus.toUpperCase()
                              : pen?.polStatus.toUpperCase()}
                          </div>
                        </Popover>
                      </td>
                      <td className="px-6  py-5">
                        <p>{moment(pen?.createdAt).format("MM/DD/YYYY")}</p>
                        <p> {moment(pen?.createdAt).format("hh:mm A")}</p>
                      </td>
                      <td className="px-6 py-5">
                        <li
                          className={`relative flex items-center pointer  whitespace-nowrap`}
                        >
                          <Dropdown
                            menu={{
                              items:
                                pen?.polStatus === "no-pol" ||
                                pen?.polStatus === "expired-pol"
                                  ? items
                                  : items1,
                              onClick,
                            }}
                            className={`${
                              pen?.polStatus === "active-pol" ? "no-hover" : ""
                            }`}
                            onClick={() =>
                              onClick2(pen?.id, pen?.polStatus, pen?.person)
                            }
                            trigger={["click"]}
                          >
                            <div
                              className={`w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]
                }
                `}
                            >
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            </div>
                          </Dropdown>
                        </li>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
      <div className={`${pol ? "" : "hidden"}`}>
        <EmpRegisterPol setRefetch={setRefetch} refetch={refetch} />
      </div>
      {}
      <div className={`${pol1 ? "" : "hidden"}`}>
        <EmpRegisterPol1 setRefetch={setRefetch} refetch={refetch} />
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } z-[50] w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </section>
  );
};

export default PolTable;
