import React from "react";
import GovAgency from "../components/MHSSS Accounting/govAgencies/govtAgencyDetails/detailsListings/index";

const Gov = () => {
  return (
    <div>
      <GovAgency />
    </div>
  );
};

export default Gov;
