import impLogo from "../../imgs/impLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
const Popup1 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dataUser = useSelector((state) => state.dataUser);
  const [word, setWord] = useState("");
  const router = useNavigate();
  const dash = location.pathname;
  const merchantStatus = useSelector((state) => state.merchantStatus);
  useEffect(() => {
    if (merchantStatus === "rejected") {
      setWord(
        "Your Account has been rejected by MHSSS because of the following reason:"
      );
    } else if (merchantStatus === "pending") {
      if (dash === "/Dashboard/listing") {
        setWord(
          "Click the confirm button below to change the default Beneficiary to the one you seleceted"
        );
      } else {
        setWord(
          "Your Account is in Pending mode. You will be allowed access to all funtionality once the account is approved"
        );
      }
    } else if (merchantStatus === "deferred") {
      setWord(
        "Your Account has been deferred by MHSSS because of the following reason:"
      );
    }
  }, []);
  return (
    <div
      className={`${
        merchantStatus === "pending" ||
        merchantStatus === "deferred" ||
        merchantStatus === "verified" ||
        merchantStatus === "rejected"
          ? "fixed top-0 w-full h-full  bg-slate-500/50 flex justify-center items-center z-10"
          : "hidden"
      }`}
    >
      <div
        className={`relative ${
          dash === "/Dashboard/listing"
            ? "h-[13.5rem] lg:w-[32%]"
            : "h-max-content lg:w-[34%]"
        }  bg-white w-[80%] rounded-lg flex flex-col items-center p-3 text-center`}
      >
        <img
          className={`w-[6rem] h-[6rem] ${
            dash === "/Dashboard/listing" ? "hidden" : ""
          }`}
          src={impLogo}
          alt=""
        />
        <div className="">
          {merchantStatus === "rejected" || merchantStatus === "deferred" ? (
            <h2 className="sm:text-[1.1rem] text-[1.1rem] font-bold text-[#F35929]">
              Account {merchantStatus === "rejected" ? "Rejected" : "Deferred"}
            </h2>
          ) : (
            <h2 className="sm:text-[1.4rem] text-[1.1rem] font-bold mt-3">
              Welcome {dataUser?.data?.merchuser?.merchCompany?.name}!
            </h2>
          )}

          <p
            className={`${
              merchantStatus === "rejected"
                ? "sm:text-[0.9rem] text-[0.9rem] px-12 mt-1"
                : "sm:text-[1rem] text-[0.9rem] font-medium sm:mt-5 mt-2 pr-2 pl-2"
            }`}
          >
            {word}
          </p>
          <p
            className={`${
              merchantStatus === "rejected"
                ? "sm:text-[0.9rem] text-[0.9rem] font-bold mt-2"
                : "hidden"
            }`}
          >
            {dataUser?.data?.merchStatus?.reason && (
              <p>
                {dataUser.data.merchStatus.reason.charAt(0).toUpperCase() +
                  dataUser.data.merchStatus.reason.slice(1)}
              </p>
            )}
          </p>
          <p
            className={`${
              merchantStatus === "rejected"
                ? "sm:text-[0.9rem] text-[0.9rem] mt-1"
                : "hidden"
            }`}
          >
            {dataUser?.data?.merchStatus?.reason && (
              <div>
                <p>Please Verify it and re-submit your application.</p>
              </div>
            )}
          </p>
          <div className="flex justify-center mb-2">
            <button
              onClick={() => dispatch({ type: "MERCHANT_STATUS", num: "" })}
              className={`${
                merchantStatus === "pending" || merchantStatus === "verified"
                  ? "hidden"
                  : ""
              } bg-[#F7542E] text-white rounded-xl pt-2 pb-2 px-12 font-normal mt-3`}
            >
              {dash === "/Dashboard/listing" ? "Cancel" : "Later"}
            </button>

            <button
              onClick={() => {
                if (
                  merchantStatus === "rejected" ||
                  merchantStatus === "deferred"
                ) {
                  router("/Merchant/profile");
                  dispatch({ type: "MERCHANT_STATUS", num: "" });
                } else {
                  dispatch({ type: "MERCHANT_STATUS", num: "" });
                }
              }}
              className="ml-3 bg-primary text-white rounded-xl pt-2 pb-2 px-12 font-normal mt-3"
            >
              {merchantStatus === "rejected" || merchantStatus === "deferred"
                ? "Verify"
                : "Proceed"}
            </button>
          </div>
        </div>
        {/* <img
          src={cross}
          onClick={() => dispatch({ type: "MERCHANT_STATUS", num: "" })}
          className="absolute scale-75 top-3 right-3 cursor-pointer"
          alt=""
        /> */}
      </div>
    </div>
  );
};

export default Popup1;
