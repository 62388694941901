import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Card from "./Card";
import Table from "./table";
const Listing = () => {
  const router = useNavigate();
  const [test, setTest] = useState(false);
  const items = [
    {
      key: "1",

      label: `ALL Orders`,
      count: 745,
    },
    {
      key: "2",

      label: `Pending Payments`,
      count: 342,
    },
    {
      key: "3",

      label: `Processing`,
      count: 546,
    },
    {
      key: "4",

      label: `Completed`,
      count: 442,
    },
  ];

  // useEffect(() => {
  //   if (!test) {
  //     setTest(true);
  //   } else {
  //     setTest(false);
  //   }
  // }, []);

  return (
    <div className=" bg-gray-50 flex items-center justify-center flex-col pb-[4rem]">
      <div className=" w-[90%]">
        <div
          className="sm:p-10 pl-2 pr-2 pb-[4rem] bg-gray-50 w-full md:pl-[2rem] md:pr-[2rem] mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
            className=" bg-gray-50"
          >
            <div
              className={`flex  bg-gray-50 justify-between items-center pt-4`}
            >
              <div className="top-[2.8] cursor-pointer flex items-center  bg-gray-50  gap-2 justify-center">
                <img
                  onClick={() => {
                    router("/Inventory-Vendor-DashBoard");
                  }}
                  className="w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Orders
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap bg-gray-50 w-full justify-between  md:pl-[1rem] md:pr-[1rem]  place-items-center gap-4 mt-[1rem] mb-8">
          {items.map((item) => (
            <Card key={item.key} item={item} />
          ))}
        </div>

        <Table />
      </div>
    </div>
  );
};

export default Listing;
