import React, { useEffect, useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const apiKey = "AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU";
const PlaceAutocomplete = () => {
  const [city, setCity] = useState("");
  const [place, setPlace] = useState(null);
  const dispatch = useDispatch();
  const placePop = useSelector((state) => state.placePop);
  const handlePlaceSelect = async (placeId) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`
      );
      setPlace(response.data.result);
      setCity("");
    } catch (error) {
      console.error("Error fetching place details:", error);
      setCity("");
    }
  };
  useEffect(() => {
    const wow = document.getElementById("place");

    if (wow) {
      wow.focus();
    }
  }, [placePop]);
  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={["places"]}>
      <div>
        <Autocomplete
          onLoad={(autocomplete) => (window.autocomplete = autocomplete)}
          onPlaceChanged={() => {
            handlePlaceSelect(window.autocomplete.getPlace().place_id);
            dispatch({ type: "placeSet", num: window.autocomplete.getPlace() });
            dispatch({ type: "placePop" });
          }}
        >
          <input
            onChange={(e) => {
              setCity(e.target.value);
            }}
            value={city}
            type="text"
            id="place"
            autofocus="autofocus"
            className="border-[1px] border-primary p-2 rounded-xl w-[100%]"
            placeholder="Search"
          />
        </Autocomplete>

        {place && (
          <div>
            {}
            <h2>{place.name}</h2>
            <p>{place.formatted_address}</p>
            <p>{place.types.join(", ")}</p>
          </div>
        )}
      </div>
    </LoadScript>
  );
};

export default PlaceAutocomplete;
