import Paccount from "../components/PAccount/Paccount";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const PAccount = () => {
  return (
    <div>
      <Nav />
      <Paccount />
      <Whatsapp/>
    </div>
  );
};

export default PAccount;
