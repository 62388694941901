import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import back5 from "../../imgs/back5.png";
import { Select } from "antd";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";

const Program = () => {
  const user = useSelector((state) => state.user);
  const router = useNavigate();
  const dispatch = useDispatch();
  const [cashId, setCashId] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const reset = useSelector((state) => state.reset);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [reason, setReason] = useState("");
  const [type, setType] = useState(null);
  const [maxlimit, setMaxLimit] = useState("");
  const [minlimit, setMinLimit] = useState("");

  const setLimit = () => {
    const api = "v1/transaction-limit";
    const method = "post";

    // Define the request body based on the operation
    const requestBody = {
      type: type,
      maxLimit: maxlimit,
      minLimit: minlimit,
    };

    if (reason !== "") {
      requestBody.reason = reason;
    }

    axios[method](`${process.env.REACT_APP_BASE_URL}/${api}`, requestBody, {
      headers: {
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then(() => {
        setErr("Successfully Saved Wallet Transaction Setting");
        setShow1(true);
        dispatch({ type: "RESET" });
        setTimeout(() => {
          setShow1(false);
        }, 5000);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };

  useEffect(() => {
    if (type === null) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/transaction-limit?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setMaxLimit(data?.data?.maxLimit);
        setMinLimit(data?.data?.minLimit);
        setReason(data?.data?.reason);
      })
      .catch(() => {});
  }, [type, reset]);

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4">
        <div className="flex items-center mt-4">
          <div
            onClick={() => {
              user === "admin"
                ? router("/AdminDashboard")
                : router("/superAdminDashboard");
            }}
            className="cursor-pointer"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] ml-[1rem] text-[1.4rem]">
            SAP Money Payments
          </h2>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          <h2 className="font-bold sm:text-[1.4rem] ml-[1rem] text-[1.4rem] text-center">
            SAP Money Payment Setting
          </h2>
          <div className="p-8 mt-4 flex flex-col gap-3">
            <div className="w-full mb-2">
              <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
                Type <span className="text-red-500">*</span>
              </label>
              <Select
                // value={statuss}
                onChange={(e) => {
                  setType(e);
                }}
                placeholder="Select Type"
                showSearch
                optionFilterProp="children"
                className="wow capitalize  rounded-[0.3rem]"
                //   className="sqw lg:text-[1.1rem] h-[4rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={[
                  {
                    value: "pensioner-quick-payee",
                    label: "Quick Pay",
                  },
                  {
                    value: "sap-wallet-to-sap-wallet",
                    label: "Sapwallet Transaction",
                  },
                  {
                    value: "bank-to-sapwallet",
                    label: "Bank to Sapwallet",
                  },
                  {
                    value: "sapwallet-to-bank",
                    label: "Sapwallet to Bank",
                  },
                  {
                    value: "other-quick-payment",
                    label: "Other Quick Payments",
                  },
                ]}
              />
            </div>

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              <div className="w-full mb-2">
                <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
                  Min Limit <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  placeholder="Min Limit"
                  value={minlimit}
                  className="lg:text-[1.1rem] w-[100%]  rounded-[0.5rem] p-2 border bg-white border-gray-300"
                  onChange={(e) => {
                    setMinLimit(e.target.value);
                  }}
                />
              </div>
              <div className="w-full mb-2">
                <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
                  Max Limit <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  value={maxlimit}
                  placeholder="Max Limit"
                  className="lg:text-[1.1rem] w-[100%]  rounded-[0.5rem] p-2 border border-gray-300"
                  onChange={(e) => {
                    setMaxLimit(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* Reason Textarea */}
            <div className="w-full mb-2">
              <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
                Reason <span className="text-red-500">*</span>
              </label>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                // value={statusReason}
                type="text"
                placeholder="Enter Reason"
                className="sqw lg:text-[1.1rem] h-[6rem] w-full border rounded-[0.3rem] p-2 border-gray-300"
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[10rem]"
              onClick={() => {
                if (type === null) {
                  setErr("Please Select Type");
                  setShow(true);
                  return;
                }
                if (minlimit === "") {
                  setErr("Please Enter Min Limit");
                  setShow(true);
                  return;
                }
                if (maxlimit === "") {
                  setErr("Please Enter Max Limit");
                  setShow(true);
                  return;
                }
                if (reason === "") {
                  setErr("Please Enter Reason");
                  setShow(true);
                  return;
                }

                setLimit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-primary text-white w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      {show && <ErrorModal setShow={setShow} err={err} />}
    </div>
  );
};

export default Program;
