import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import placeholder from "../../../../../../imgs/placeholder.png";
import {PlusCircleFilled} from "@ant-design/icons";
import { Select } from "antd";
import logo1 from "../../../../../../imgs/logo12.png";

let data;
let nisData = [];
let tinData = [];
let file;
const Part3 = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const universalEmpId = useSelector((state) => state.universalEmpId);
  const [nisImg, setNisImg] = useState("");
  const [nisId, setNisId] = useState("");
  const [tinId, setTinId] = useState("");
  const [tinImg, setTinImg] = useState("");
  const dispatch = useDispatch();
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoadingx, setIsLoadingx] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const [test, setTest] = useState(true);
  const [allDis, setAllDis] = useState(false);
  const [option, setOption] = useState("National Insurance");

  let optionI;
    optionI = [
      {
        label: "National Insurance",
        value: "National Insurance",
      },
      {
        label: "Tax Information",
        value: "Tax Information",
      },
    ]
  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
        load(false);
        setAllDis(false)
      })
      .catch(() => {
        
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    if (profileData.verificationDocs){
        data = profileData.verificationDocs;
      nisData = data.filter((item) => {
        return item.type === "national insurance";
      });
      if (nisData.length !== 0) {
        setNisId(nisData[0].docIDValue);
      }
      tinData = data.filter((item) => {
        return item.type === "taxpayer identification";
      });
      if (tinData.length !== 0) {
        setTinId(tinData[0].docIDValue);
      }
      
      if (nisData.length !== 0) {
        if (nisData[0].docimages.length !== 0) {
          let body1;
            body1 = [nisData[0].docimages[0].imageUrl];
            setNisImg(nisData[0].docimages[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              

              setDisplay(data.data[0].url);
            })
            .catch(() => {
              
            });
        }
      }
      if (tinData.length !== 0) {
        if (tinData[0].docimages.length !== 0) {
          let body2;
            body2 = [tinData[0].docimages[0].imageUrl];
            setTinImg(tinData[0].docimages[0].imageUrl);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body2,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              

              setDisplay1(data.data[0].url);
            })
            .catch(() => {
              
            });
        }
      }
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
    
  }, []);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [nisData, tinData]);
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const updateHandler = () => {
    setIsLoadingx(true);
    const body = [];
    if (nisImg !== "" || nisId) {
      const nisData = {
        type: "national insurance",
        docIDName: "national insurance",
        docIDValue: `${nisId}`,
        docImages: [],
      };
      if (nisImg !== "") {
        nisData.docImages[0] = {
          type: "front image",
          imageUrl: `${nisImg}`,
        };
      }
      body.push(nisData);
    }
    if (tinImg !== "" || tinId) {
      const tisData = {
        type: "taxpayer identification",
        docIDName: "taxpayer identification",
        docIDValue: `${tinId}`,
        docImages: [],
      };
      if (tinImg !== "") {
        tisData.docImages[0] = {
          type: "front image",
          imageUrl: `${tinImg}`,
        };
      }
      body.push(tisData);
    }
    let wow
    if (dash === "/GroAdminDash/Employees/Profile/EditEmployee" || dash==="/superAdminDashboard/GroempProfile/EditEmployee")
       wow=`${process.env.REACT_APP_BASE_URL}/v1/verification-docs/gro-employee?userId=${universalEmpId}`
      else if(dash === "/superAdminDashboard/GroAdminProfile/EditAdmin")
      wow=`${process.env.REACT_APP_BASE_URL}/v1/verification-docs/gro-admin?userId=${universalEmpId}`
      axios
        .put(
          wow,
          {
            verificationDocs: body,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          
          if (
            err.response.data.message === '"expiryDate" must be a valid date'
          ) {
            setErr("Please verify if expiration date is available on card");
          } else {
            setErr(err.response.data.message);
          }
          setIsLoadingx(false);
          setShow(true);
        });
  };
  return (
    <div>
       <div className="w-full">
        <label
          className="text-[1.2rem] font-semibold mb-2"
        >
          Select Document:
        </label>
        <Select
          placeholder="Document Type"
          optionFilterProp="children"
          defaultValue={optionI[0].value}
          className="wow9"
          style={{ marginBottom: "1.2rem", marginTop: "5px" }}
          onChange={(val) => setOption(val)}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          size="middle"
          options={optionI}
        />
      </div>
     
      {option === "National Insurance" && ( <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">
          National Insurance
        </h2>
        <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>NIS #:</h2>
        <input
          type="text"
          name=""
          
          placeholder="NIS #"
          onChange={handleChange(setNisId)}
          value={nisId}
          className={`${
            allDis ? "pointer-events-none" : "pointer-events-auto"
          } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />

        <div className={`${nisImg === placeholder ? "hidden" : ""}`}>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input"
            className={`${
              isLoading1 || isLoading2 || allDis
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay, setNisImg, setIsLoading1)}
            />
          </label>
          <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
            <img className="w-[25rem] h-[13rem]" src={display} alt="" />
           
          </div>
         
        </div>
      </div>)}
      {option === "Tax Information" && ( <div>
        <h2 className="mb-4 text-center text-[1.5rem] font-bold">
          Tax Information
        </h2>
        <h2 className={` mb-4 lg:text-[1.1rem] font-semibold`}>TIN #:</h2>
        <input
          type="text"
          name=""
          
          placeholder="TIN #"
          value={tinId}
          onChange={handleChange(setTinId)}
          className={`${
            allDis ? "pointer-events-none" : "pointer-events-auto"
          } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
        />
        <div className={`${tinImg === placeholder ? "hidden" : ""}`}>
          <h2 className={`mb-4 lg:text-[1.1rem] font-semibold`}>
            Image Front:
          </h2>
          <label
            htmlFor="special-input1"
            className={`${
              isLoading1 || isLoading2 || allDis
                ? "pointer-events-none"
                : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
              </div>
              <p className="text-[#9CA6C2] ml-2">Image Front</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input1"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay1, setTinImg, setIsLoading2)}
            />
          </label>
          <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
            {/* <div
              className={`absolute ${
                isLoading2 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div> */}
            <img className="w-[25rem] h-[13rem]" src={display1} alt="" />
            {/* <div
              className="absolute bottom-0 right-0 bg-white cursor-pointer"
              onClick={() => setDisplay1("")}
            >
              <img className="w-[3rem]" src={trash} alt="" />
            </div> */}
          </div>
          {/* <div>
            <img className="w-[25rem] h-[13rem] mb-4" src={tinImg} />
          </div> */}
        </div>
      </div>)}
     
      <div
        className={`${
          dataUser.data.user.roles[0] === "employee" &&
          dataUser.data.user.status === "pending"
            ? "hidden"
            : ""
        }`}
      >
        <button
          onClick={updateHandler}
          className={`${
            allDis ? "hidden" : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          {/* <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
          <span>Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default Part3;