import axios from "axios";
import { useState } from "react";
import across from "../../../../../imgs/across.png";
import store1 from "../../../../../imgs/store1.svg";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import StoreModal from "../../../Pensioner/Pay/StoreModal";
import LinkStoreModal from "../Modal/LinkStoreModal";
import SuccessModal from "../../../Modals/successModal";
import ErrorModal from "../../../Modals/errorModal";
const SeventhStep = () => {
  const merchantStores = useSelector((state) => state.merchantStores);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  console.log(value);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  console.log(selectedItems);
  const [showsuccessModal, setShowsuccessModal] = useState(false);
  const [showerrorModal, setShowerrorModal] = useState(false);

  // const handleAddLink = () => {
  //   if (selectedStore && selectedRole) {
  //     setSelectedItems([
  //       ...selectedItems,
  //       { store: selectedStore, role: selectedRole, name: StoreName },
  //     ]);
  //   }
  // };

  const handleDelete = (id, index) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/${id}/by-merchant`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        const updatedItems = [...selectedItems];
        updatedItems.splice(index, 1);
        setSelectedItems(updatedItems);
        setErr("Store unlinked successfully");
        setShowsuccessModal(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowerrorModal(true);
        console.log(err);
      });
  };

  // const nextHandler = (e) => {
  //   e.preventDefault();
  //   var body;

  //   const updatedSelectedItems = selectedItems.map((item) => ({
  //     merchstoreId: item.store,
  //     role: item.role,
  //   }));

  //   body = {
  //     stores: updatedSelectedItems,
  //   };
  //   // }
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/by-merchant?merchUserId=${merchantEmployee?.data?.merchUser?.id}`,
  //       body,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then(() => {
  //       // if (merchantStores?.length > 0) {
  //       // dispatch({ type: "EINCREMENT" });
  //       //   return;
  //       // }
  //     })
  //     .catch(() => {
  //       // dispatch({ type: "EINCREMENT" });
  //     });
  // };
  // const handleChange = (setState) => (event) => {
  //   setState(event.target.value);
  // };
  return (
    <div className="flex justify-center">
      <div action="" className="sm:w-[70%] w-[85%] mt-3">
        <div className="bg-slate-50 p-5 rounded-md">
          <p className="text-center text-[1rem] text-gray-800">
            Is this employee worked with other store? If yes please select or
            click next.
          </p>
          <ul className=" space-y-4 max-h-96 overflow-y-scroll custom-scrollbar p-4 mt-5">
            {merchantStores?.map((voc, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedStore(voc);
                  setShowStoreModal(false);
                }}
              >
                <div
                  className="w-full p-3 
                     text-gray-900 bg-white
                     relative
                      border border-gray-300 rounded-lg cursor-pointer shadow-sm"
                >
                  <div className="flex gap-2">
                    <img src={store1} alt="store" className="w-6 h-6" />
                    <div className="w-full text-md font-semibold">
                      {voc?.customStoreName}
                    </div>
                  </div>

                  <div className="w-full text-gray-500 text-xs mt-2">
                    {voc?.lot +
                      ", " +
                      voc?.address +
                      ", " +
                      voc?.region +
                      ", " +
                      voc?.regionName}
                  </div>
                  <div
                    className="absolute top-2 right-4"
                    // className={`${selectedItems?.some(item => item.store.id === voc?.id)? "pointer-events-none" :"absolute top-2 right-4"}`}
                    onClick={() => {
                      if (
                        selectedItems?.some((item) => item.store.id === voc?.id)
                      ) {
                        setErr("Store already linked");
                        setShowerrorModal(true);
                        return;
                      }
                      setShowModal(true);
                    }}
                  >
                    <LinkOutlined
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: selectedItems?.some(
                          (item) => item.store.id === voc?.id
                        )
                          ? "#1BA397"
                          : "black",
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {/* <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Store:
          </h2>
          <div>
            <Select
              placeholder="Select Store"
              optionFilterProp="children"
              className="wow"
              style={{ marginBottom: "1.2rem" }}
              onChange={(val, lab) => {
                setSelectedStore(val);
                setStoreName(lab.label);
              }}
              size="middle"
              options={merchantStores?.map((store) => ({
                value: store.id,
                label: store.customStoreName,
              }))}
            />
          </div>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Roles/Position:
          </h2>
          <div>
            <Select
              placeholder="Select Role/Position"
              optionFilterProp="children"
              className="wow"
              style={{ marginBottom: "1.2rem" }}
              onChange={(val) => setSelectedRole(val)}
              size="middle"
              options={[
                { value: "Manager", label: "Manager" },
                { value: "Financial", label: "Financial" },
                { value: "Sales Clerk", label: "Sales Clerk" },
                { value: "Customer Support", label: "Customer Support" },
                { value: "Staff", label: "Staff" },
                { value: "Operations", label: "Operations" },
              ]}
            />
          </div>

          <div className="flex flex-col items-center rounded-md">
            <button
              className="bg-primary text-white rounded-[0.5rem] p-2 pr-12 pl-12 mb-6 text-[1.1rem]"
              onClick={handleAddLink}
            >
              Link Store
            </button>
          </div> */}

          <div className="flex flex-col mt-4 gap-1 w-full">
            {selectedItems?.map((item, index) => (
              <div
                key={index}
                className="flex flex-wrap w-full bg-primary shadow-md relative text-white pl-4 pr-4 p-1 rounded-lg"
              >
                <h2 className="mb-1 capitalize font-bold ">
                  {item?.store?.customStoreName}
                </h2>

                <div className="flex w-full  items-center justify-between">
                  <div
                    key={index}
                    className="flex w-full sm:flex-row flex-col pr-4 mb-2"
                  >
                    <span className="font-semibold mr-2">
                      Role : {item.role}
                    </span>{" "}
                  </div>
                </div>
                <DeleteOutlined
                  className="absolute top-0 text-xl right-2"
                  onClick={() => handleDelete(item?.id, index)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          <button
            onClick={() => {
              dispatch({ type: "EINCREMENT" });
            }}
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 mb-6`}
          >
            Next
          </button>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      {showStoreModal && (
        <StoreModal
          setShowStoreModal={setShowStoreModal}
          stores={merchantStores}
          setSelectedStore={setSelectedStore}
        />
      )}
      {showModal && (
        <LinkStoreModal
          setShow={setShowModal}
          setSelectedItems={setSelectedItems}
          selectedStore={selectedStore}
          selectedItems={selectedItems}
          setShowerrorModal={setShowerrorModal}
          setShowsuccessModal={setShowsuccessModal}
          setErr={setErr}
          setShowModal={setShowModal}
        />
      )}

      {showerrorModal && <ErrorModal setShow={setShowerrorModal} err={err} />}
      {showsuccessModal && (
        <SuccessModal
          setShow={setShowsuccessModal}
          err={err}
          setValue={setValue}
        />
      )}
    </div>
  );
};

export default SeventhStep;
