import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Headqaurters from "./Headquarters";
import Store from "./Stores";
import axios from "axios";
const Employee = () => {
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  const router = useNavigate();
  const dispatch = useDispatch();
  const selectedMerchantEmployeeInfo = useSelector(
    (state) => state.selectedMerchantEmployeeInfo
  );
  const merchantEmployeeId = useSelector((state) => state.merchantEmployeeId);
  const token = useSelector((state) => state.token);
  const [active, setActive] = useState("1");
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [url, setUrl] = useState("");
  const [Tabs, setTabs] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const merchantStores = useSelector((state) => state.merchantStores);
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);
  console.log("stores", merchantStores);
  const TabsMer = [
    {
      key: "1",
      label: "Headqaurters",
      component: (
        <Headqaurters
          selectedPrivileges={selectedPrivileges}
          setSelectedPrivileges={setSelectedPrivileges}
          url={url}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      ),
      user: "merchant-owner",
    },
    {
      key: "2",
      label: "Store",
      component:
        merchantStores?.length > 0 ? (
          <Store />
        ) : (
          <div className="flex justify-center items-center h-96">
            <h1 className="text-2xl text-gray-400">No Record Found</h1>
          </div>
        ),
    },
  ];
  const TabsGOV = [
    {
      key: "1",
      label: "Headqaurters",
      component: (
        <Headqaurters
          selectedPrivileges={selectedPrivileges}
          setSelectedPrivileges={setSelectedPrivileges}
          url={url}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      ),
      user: "merchant-owner",
    },
  ];

  useEffect(() => {
    if (isGovernmentAgency) setTabs(TabsGOV);
    else setTabs(TabsMer);
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantEmployeeId}/merch-employee/profile`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       dispatch({ type: "SELECTED_MERCHANT_EMPLOYEE_INFO", num: data });
  //       setImage(data?.data?.merchEmployeeInfo?.signature);
  //     })
  //     .catch((err) => {});
  // }, []);

  //Get Head Quarter Privileges
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges?merchEmployeeUserId=${merchantEmployeeInfo?.id}&merchHeadquarterId=${merchantEmployeeInfo?.merchheadquarterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        const privileges = data?.data?.map((privilege) => privilege.privilege);
        setSelectedPrivileges(privileges);
      })

      .catch(() => {});
  }, [refetch]);

  useEffect(() => {
    imageConverterContact();
  }, []);

  //Linked Stores
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store?merchUserId=${merchantEmployeeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "EMPLOYEE_LINKED_STORES", num: data?.data });
      })
      .catch(() => {});
  }, []);

  const imageConverterContact = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-file-presigned-url?fileType=merchantIdentificationDocument`,
        {
          fileNames: [
            selectedMerchantEmployeeInfo?.data?.merchEmployeeInfo?.signature,
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setUrl(data?.data?.[0]?.url);
      })
      .catch(() => {
        // Handle error
      });
  };

  return (
    <div className="">
      <div
        className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                router("/Merchant/Employee/List");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Roles & Privileges
            </h2>
            {/* <button
              className={`text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
              onClick={() => {
                {
                  router("/Merchant/Add/Employee");
                  dispatch({ type: "ESET", num: 0 });
                }
              }}
            >
              Create Employee
            </button> */}
          </div>
        </div>
      </div>
      {/* Tabs */}

      <div className="sm:px-20 mt-4 sm:mt-0">
        <div
          className={`grid ${
            isGovernmentAgency ? "grid-cols-1" : "grid-cols-2"
          } gap-0`}
        >
          {Tabs.map((tab) => (
            <button
              className={`${
                active === tab.key
                  ? "border-primary bg-primary font-bold"
                  : "text-primary bg-gray-100 font-semibold"
              } text-white p-3 rounded shadow-md flex items-center justify-center`}
              onClick={() => setActive(tab.key)}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {" "}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />{" "}
              </svg>{" "}
              {tab.label}
            </button>
          ))}
        </div>{" "}
        <div className="p-2 border mb-6">
          {Tabs.find((tab) => tab.key === active)?.component}
        </div>
      </div>
    </div>
  );
};

export default Employee;
