import React, { useEffect, useRef, useState } from "react";

import { Avatar, DatePicker, Dropdown, Input } from "antd";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import ErrorModal from "../../Merchant/Modals/errorModal";
import moment from "moment";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import arrow from "../../../imgs/Vector.png";
import search1 from "../../../imgs/searchM.png";

const Header = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const token = useSelector((state) => state.token);
  const [showDropdown, setShowDropdown] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [searchDob, setSearchDob] = useState("");
  const [gid, setGid] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showLastName, setShowLastName] = useState(false);
  const [showDob, setShowDob] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [addparams, setAddParams] = useState(false);
  const [pensioners, setPensioners] = useState([]);
  const [history, setHistory] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [count, setCount] = useState(1);
  const [dataFetched, setDataFetched] = useState(false);
  const user = useSelector((state) => state.user);
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );

  const firstName =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.user?.person?.firstName
      : merchantEmployeeInfo?.data?.merchPerson?.firstName;
  const lastNameShow =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.user?.person?.lastName
      : merchantEmployeeInfo?.data?.merchPerson?.lastName;

  const dropdownRef = useRef(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [items, setItems] = useState([
    {
      label: "Profile ",
      key: "1",
      icon: <UserOutlined className="some" />,
    },
    {
      label: "Log Off",
      key: "2",
      icon: <LogoutOutlined className="some" />,
    },
  ]);

  const onClick = ({ key }) => {
    if (key === "1") {
      dispatch({ type: "SIDEBAR", num: "5" });
      router("/Merchant/profile");
    } else if (key === "2") {
      handleLogOff();
    }
  };

  //logout
  const handleLogOff = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-auth/logout`, {
        refreshToken: `${dataUser?.data?.merchTokens?.refresh?.token}`,
      })
      .then((data) => {
        dispatch({ type: "authStateSet", num: "false" });
        dispatch({ type: "tokenSet", num: "0" });
        dispatch({ type: "tokenRef", num: "0" });
        dispatch({ type: "setUserData", num: [] });
        dispatch({ type: "BRSET", num: "0" });
        dispatch({ type: "RSET", num: 0 });
        dispatch({ type: "ISET", num: 1 });
        dispatch({ type: "BRSET", num: 0 });
        dispatch({ type: "BISET", num: 0 });
        dispatch({ type: "ASET", num: 0 });
        router("/");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };

  useEffect(() => {
    dispatch({ type: "ALL_TRANSACTIONS", num: "grid" });
  }, []);

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
      if (prompt) {
        const wow = parsePhoneNumber(prompt);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };

  const Search = () => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        ...(gid && { gid }),
        ...(lastName && { lastName }),
        ...(dob && { dateOfBirth: dob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        ...(email && { email }),
      };

      fetchPenioners(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  };

  const Search2 = () => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        ...(gid && { gid }),
        ...(lastName && { lastName }),
        ...(dob && { dateOfBirth: dob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        ...(email && { email }),
      };

      fetchPenioners2(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  };

  const fetchPenioners2 = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/search/for-merch?gid=0389`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        dispatch({
          type: "SAP_PAYMENT_SELECTED_PERSON",
          num: {
            id: data?.data?.id,
            type: data?.data?.type,
            image:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.image
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.image
                : data?.data?.merchheadquarter?.image,

            email:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.email
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryEmail
                : data?.data?.merchheadquarter?.primaryEmail,

            name:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.firstName +
                  " " +
                  data?.data?.user?.person?.lastName
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.customStoreName
                : data?.data?.merchCompany?.name,

            phone:
              data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryPhone
                : data?.data?.type === "merch-headquarter"
                ? data?.data?.merchheadquarter?.primaryPhone
                : data?.data?.type === "pensioner"
                ? data?.data?.user?.countryCode && data?.data?.user?.phoneNo
                  ? `${data?.data?.user?.countryCode}${data?.data?.user?.phoneNo}`
                  : "-------"
                : "-------",
          },
        });
        router("/Merchant/SapMoney/SelectType");
      })
      .catch((err) => {
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowModal(true);
        console.log(err);
      });
  };
  const fetchPenioners = (params, cancelTokenSource) => {
    let api;

    api = "pensioner-profile-by-filters";

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/${api}/by-merchant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        const option = data?.data?.[0];

        if (data?.data?.length === 0) {
          setErr("No Pensioner Found");
          setShowModal(true);
        } else {
          // Search2();
          dispatch({
            type: "SELECTED_PENSIONER",
            num: option,
          });
          dispatch({
            type: "SELECTED_PENSIONER_PROGRAMS",
            num: option?.pensionPrograms,
          });

          router("/Merchant/SapMoney/SelectType");
        }
      })
      .catch((err) => {
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowModal(true);
        console.log(err);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      Search();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    dispatch({ type: "SELECTED_PENSIONER", num: {} });
    dispatch({ type: "SELECTED_PENSIONER_VOUCHERS", num: [] });
    dispatch({ type: "ACTIVE_PENSIONER_SESSION", num: null });
    dispatch({ type: "ACTIVE_VOUCHERS", num: [] });
  }, []);

  // Reset dataFetched when modal is closed
  useEffect(() => {
    if (!showDropdown) {
      setDataFetched(false);
    }
  }, [showDropdown]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setShowDropdown(true);
  };

  const handleSelectOption = (option) => {
    setSearchTerm(`${option.name} - ${option.gid}`);
    setShowDropdown(false);
  };
  return (
    <div className="flex justify-between items-center sm:flex-row flex-col-reverse z-10">
      {/* Search Container */}
      {/* <div className="flex items-center sm:w-[33%] w-[50%]" ref={dropdownRef}> */}
      <div
        className={`${
          dataUser?.data?.merchuser?.status === "pending" ||
          dataUser?.data?.merchuser?.status === "deferred" ||
          dataUser?.data?.merchuser?.status === "rejected"
            ? "pointer-events-none flex items-center w-full sm:pt-0 pt-5"
            : "flex items-center w-full sm:pt-0 pt-5"
        }`}
      >
        <label className="sr-only">Search</label>
        <div className="relative w-full">
          <div className="relative">
            <input
              type="text"
              placeholder="Search GID"
              className="pl-6 pr-10 py-3 px-4 w-full rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
              value={gid}
              onChange={(e) => {
                setGid(e.target.value);
              }}
              onKeyPress={handleKeyPress}

              // style={{ maxWidth: "390px" }}
              // onClick={() => setShowDropdown(!showDropdown)}
            />

            <img
              src={search1}
              className="absolute inset-y-0 right-0 w-6 h-6 fill-current text-gray-400 mr-3 mt-3 cursor-pointer"
              alt="search"
              onClick={() => {
                Search();
              }}
            />
          </div>
          {showDropdown && (
            <div className="z-10 origin-top-left absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 max-h-96 overflow-y-auto overflow-y-scroll custom-scrollbar">
              <div className="relative">
                {show && (
                  <div className="left-0 mt-0  p-3 pr-4 pl-4  rounded-md  bg-white ring-1 ring-black ring-opacity-5">
                    <input
                      type="text"
                      // id="gid-search"
                      value={gid}
                      onChange={(e) => {
                        setGid(e.target.value);
                      }}
                      className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
                                
                                `}
                      placeholder="Search By GID"
                    ></input>
                    <button
                      onClick={() => {
                        setAddParams(true);
                      }}
                      className={` ${
                        addparams
                          ? "hidden"
                          : "text-primary font-semibold p-2  w-full"
                      }`}
                    >
                      Search with additional parameters
                    </button>

                    <div className={`${addparams ? "" : "hidden"}`}>
                      <div className="mt-2">
                        <input
                          type="text"
                          // id="gid-search"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3"
                          placeholder="Last Name"
                        />
                      </div>

                      <div className="mt-2">
                        <DatePicker
                          onChange={(e, f) => setDob(f)}
                          value={dob !== "" ? moment(dob) : null}
                          placeholder="Date of birth"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setShowPhone(true);
                      }}
                      className={`
                                ${
                                  showPhone
                                    ? "hidden"
                                    : "mt-2 flex items-center justify-center"
                                }
                                ${
                                  addparams
                                    ? "mt-2 flex items-center justify-center"
                                    : "hidden"
                                }`}
                    >
                      <button className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center">
                        +
                      </button>
                    </div>

                    <div
                      className={`${
                        showPhone
                          ? "mt-2 flex items-center justify-center"
                          : "hidden"
                      }`}
                    >
                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="GY"
                        value={prompt}
                        onChange={handleChange(setPrompt, "tt")}
                        international
                        countryCallingCodeEditable={false}
                      />

                      {/* Button to hide phone number again */}
                      <button
                        onClick={() => setShowPhone(false)}
                        className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center ml-2"
                      >
                        -
                      </button>
                    </div>

                    <div
                      onClick={() => {
                        setShowEmail(true);
                      }}
                      className={`                              
                                ${
                                  showEmail
                                    ? "hidden"
                                    : "mt-2 flex items-center justify-center"
                                }
                                ${
                                  addparams && showPhone
                                    ? "mt-2 flex items-center justify-center"
                                    : "hidden"
                                }`}
                    >
                      <button className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center">
                        +
                      </button>
                    </div>

                    <div
                      className={`${
                        showEmail
                          ? "mt-2 flex items-center justify-center"
                          : "hidden"
                      }`}
                    >
                      <input
                        type="text"
                        // id="gid-search"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3"
                        placeholder="Email"
                      />

                      {/* Button to hide phone number again */}
                      <button
                        onClick={() => setShowEmail(false)}
                        className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center ml-2"
                      >
                        -
                      </button>
                    </div>

                    <div className="mt-2 flex w-full gap-2 justify-center">
                      <button
                        onClick={() => {
                          if (dob !== null && dob !== "") {
                            setSearchDob(dob);
                          }

                          Search();
                        }}
                        className={`bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8 ${
                          gid === "" &&
                          email === "" &&
                          lastName === "" &&
                          dob === "" &&
                          countryCode === null &&
                          phoneNoo === null
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        Search
                      </button>

                      <button
                        onClick={() => {
                          setGid("");
                          setEmail("");
                          setSearchDob("");
                          setDob("");
                          setPhoneNoo(null);
                          setPrompt("");
                          setCountryCode(null);
                          // setShow(false);
                          setAddParams(false);
                          setShowPhone(false);
                          setShowEmail(false);
                          if (
                            gid !== "" ||
                            email !== "" ||
                            lastName !== "" ||
                            dob !== "" ||
                            countryCode !== null ||
                            phoneNoo !== null
                          ) {
                            setRefetch(!refetch);
                            setShowDropdown(true);
                            setShowPhone(false);
                            setShowEmail(false);
                            setCount(1);
                          }
                        }}
                        className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <hr />
              {pensioners.length === 0 ? (
                <p className="text-center p-4 text-gray-400">
                  No Pensioner Found
                </p>
              ) : (
                <div>
                  <p className="text-center p-2 text-gray-400 mt-1">
                    Recent Search
                  </p>
                  <ul className="py-1">
                    {pensioners
                      ?.filter((option) =>
                        `${
                          history
                            ? option?.pensionerUser?.person?.firstName
                            : option?.user?.person?.firstName
                        } - ${
                          history
                            ? option?.pensionerUser?.gid
                            : option?.user?.gid
                        }`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((option) => (
                        <li
                          key={
                            history
                              ? option?.pensionerUser?.person?.id
                              : option?.user?.person?.id
                          }
                          onClick={() => {
                            handleSelectOption(option);
                            dispatch({
                              type: "SELECTED_PENSIONER",
                              num: option,
                            });
                            dispatch({
                              type: "SELECTED_PENSIONER_PROGRAMS",
                              num: option?.pensionPrograms,
                            });

                            router("/Merchant/SapMoney/SelectType");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer bg-[#F4F5FE] mx-3 my-2 rounded-lg border-2 border-[#E9E9E9]"
                        >
                          <div className="flex items-center gap-2">
                            <Avatar
                              src={
                                history
                                  ? option?.pensionerUser?.person?.image
                                  : option?.user?.person?.image
                              }
                              style={{
                                backgroundColor: "#1BA397",
                              }}
                              shape="circle"
                              size={44}
                            />
                            <div>
                              <h1 className="text-[1rem] font-semibold mb-1">
                                {history
                                  ? option?.pensionerUser?.person?.firstName
                                  : option?.user?.person?.firstName}{" "}
                                {history
                                  ? option?.pensionerUser?.person?.lastName
                                  : option?.user?.person?.lastName}
                              </h1>
                              <p>
                                GID:{" "}
                                {history
                                  ? option?.pensionerUser?.gid
                                  : option?.user?.gid}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <ErrorModal show={showModal} setShow={setShowModal} err={err} />
      )}
    </div>
  );
};

export default Header;
