import { Checkbox, Pagination } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Paget = () => {
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const router = useNavigate();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const dataUser = useSelector((state) => state.dataUser);
  const [records, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("requested");
  const [type, setType] = useState("requested");
  const columnHeaders = [
    "Sr. No",
    "Requested On",
    "Govt. Agency",
    // "Admin",
    "Address",
    "Phone",
    "Email",
    "Balance",
    "Status",
  ];

  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: 0,
        page: 1,
        ...(search && { search }),
        status,
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [search, status]);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-requests/list-for-mhsss`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setRecord(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    if (search) {
      setSearch("");
      setPagewithsearch(e);
    } else {
      setPage(e);
    }
  };

  const handleTypeChange = (method) => {
    if (type === method) {
      setType(null);
    } else {
      setType(method);
    }
  };

  const getMerchantOwner = (id, data) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/owner-by-hq/for-mhsss?merchHeadquarterId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: "SELECTED_GOV_AGENCY",
          num: {
            ...data,
            id: data?.id,
            merchId: res?.data?.id,
            image: data?.reqByMerchCompany?.logo,
            name: data?.reqByMerchCompany?.name,
            phone: data?.merchheadquarter?.primaryPhone,
            email: data?.merchheadquarter?.primaryEmail,
            location:
              data?.merchheadquarter?.city +
              ", " +
              data?.merchheadquarter?.region,
            balance:
              data?.balanceInfo !== null ? data?.balanceInfo?.bankBalance : 0,
            limit: data?.balanceInfo !== null ? data?.balanceInfo?.lowLimit : 0,
          },
        });
        router("/MHSSS/GovernmentAgency/Requests/detail");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section className="p-4">
      <div className="mx-auto max-w-screen-xl relative  h-auto">
        <div className="relative sm:rounded-lg overflow-hidden ">
          <div className="flex flex-row items-center justify-end mb-4 gap-2">
            <div className="w-[45%] border rounded-lg md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <div className="relative w-full">
                    <button
                      id="filterDropdownButton"
                      data-dropdown-toggle="filterDropdown"
                      className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900  focus:z-10     "
                      type="button"
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter By
                      <svg
                        className="-mr-1 ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>

                    {show && (
                      <div
                        id="filterDropdown"
                        className="z-50 absolute top-full right-0 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                      >
                        <div className="mt-5 flex flex-col gap-3">
                          <Checkbox
                            onChange={() => handleTypeChange("requested")}
                            checked={type === "requested"}
                            className="checkk4"
                          >
                            Requested
                          </Checkbox>
                          <Checkbox
                            onChange={() => handleTypeChange("mhsss_paid")}
                            checked={type === "mhsss_paid"}
                            className="checkk4"
                          >
                            Paid
                          </Checkbox>
                          <Checkbox
                            onChange={() => handleTypeChange("mhsss_rejected")}
                            checked={type === "mhsss_rejected"}
                            className="checkk4"
                          >
                            Rejected
                          </Checkbox>
                        </div>

                        <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => {
                              if (type !== null) {
                                setStatus(type);
                              }
                              setShow(false);
                            }}
                            className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              setType("requested");
                              setStatus("requested");

                              setShow(false);
                            }}
                            className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {records?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p className="p-10 py-20">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {records?.map((data, index) => (
                    <tr
                      onClick={
                        () => {
                          if (data?.reqByRole === "merchant-admin") {
                            getMerchantOwner(
                              data?.reqByMerchHeadquarterId,
                              data
                            );
                            return;
                          }
                          dispatch({
                            type: "SELECTED_GOV_AGENCY",
                            num: {
                              ...data,
                              id: data?.id,
                              merchId: data?.reqByMerchUserId,
                              image: data?.reqByMerchCompany?.logo,
                              name: data?.reqByMerchCompany?.name,
                              phone: data?.merchheadquarter?.primaryPhone,
                              email: data?.merchheadquarter?.primaryEmail,
                              location:
                                data?.merchheadquarter?.city +
                                ", " +
                                data?.merchheadquarter?.region,
                              balance:
                                data?.balanceInfo !== null
                                  ? data?.balanceInfo?.bankBalance
                                  : 0,
                              limit:
                                data?.balanceInfo !== null
                                  ? data?.balanceInfo?.lowLimit
                                  : 0,
                            },
                          });
                          router("/MHSSS/GovernmentAgency/Requests/detail");
                        }
                        // }
                      }
                      className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>

                      <td className="px-4 py-2 flex flex-col whitespace-nowrap capitalize">
                        <p>{moment(data?.createdAt).format("MM/DD/YYYY")}</p>
                        <p> {moment(data?.createdAt).format("hh:mm A")}</p>
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-primary capitalize">
                        <div>{data?.reqByMerchCompany?.name}</div>
                      </td>
                      {/* <td className="px-4 py-2 text-primary whitespace-nowrap">
                        <div>{data?.Admin}</div>
                      </td> */}
                      <td className="px-4 py-2 whitespace-nowrap">
                        {data?.merchheadquarter?.city +
                          " , " +
                          data?.merchheadquarter?.region}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap">
                        {data?.merchheadquarter?.primaryPhone !== null
                          ? data?.merchheadquarter?.primaryPhone
                          : ""}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap">
                        {data?.merchheadquarter?.primaryEmail !== null
                          ? data?.merchheadquarter?.primaryEmail
                          : ""}
                      </td>
                      <td
                        className={`border-none px-4 py-2 font-semibold ${
                          data?.BalanceStatus === "Low"
                            ? "text-red-600"
                            : "text-primary"
                        }  rounded-md text-center focus:border-none cursor-pointer capitalize`}
                      >
                        ${data?.amount}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap">
                        <div
                          className={`border-none ${
                            data?.status === "Average"
                              ? "bg-blue-500"
                              : data?.status === "mhsss_rejected"
                              ? "bg-red-500"
                              : "bg-primary"
                          }  p-1 pl-5 pr-5 rounded-md text-center text-white focus:border-none cursor-pointer capitalize`}
                        >
                          {data?.status === "mhsss_rejected"
                            ? "Rejected"
                            : data?.status === "mhsss_paid"
                            ? "Paid"
                            : "Requested"}
                        </div>
                      </td>
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                        <Dropdown
                          menu={{
                            items,
                            // onClick: (key) => onClick(key, item),
                          }}
                          trigger={["click"]}
                          onClick={(e) => {
                            // Stop the propagation of the click event
                            e.stopPropagation();
                          }}
                        >
                          <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          </div>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
      {/* {showInfo && (
        <InfoModal
          show={showInfo}
          setShow={setShowInfo}
          content={content}
          mhsss={true}
        />
      )} */}
    </section>
  );
};

export default Paget;
