import { DatePicker } from "antd";
import React from "react";

const VoucherList = ({ year, setYear, data }) => {
  const vouchers = [
    {
      no: "ABCDE12345",
      amount: "$ 1500",
      person: "self",
      date: "15 February, 2023",
      text: "Cash out",
    },
    {
      no: "XYZ12345",
      amount: "$ 1200",
      person: "self",
      date: "10 March, 2023",
      text: "Verification error",
    },
    {
      no: "PQRS12345",
      amount: "$ 800",
      person: "Beneficiary",
      date: "5 April, 2023",
      text: "Payment received",
    },
    {
      no: "LMNO12345",
      amount: "$ 1000",
      person: "self",
      date: "20 May, 2023",
      text: "Cash withdrawal",
    },
  ];

  return (
    <div>
      <div className="py-4">
        <h1 className="text-[1rem] font-semibold mb-4  ">Filter By year</h1>
        <DatePicker
          picker="year"
          // value={year}
          onChange={(date, dateString) => {
            if (setYear) {
              setYear(dateString);
            }
          }}
        />
      </div>
      {data?.length === 0 && (
        <h1 className="text-center text-lg text-gray-500 p-10">
          No Program Data Available for this year {year}
        </h1>
      )}
      {data?.map((pay, index) => (
        <div
          key={index}
          className="flex flex-col border border-gray-100 py-4 rounded-md px-4 mb-4 shadow-sm gap-1"
        >
          <h1 className="text-primary text-lg font-semibold capitalize ">
            {pay?.program === "senior-citizen-Pension"
              ? "Senior Citizen Pension"
              : pay?.program}
          </h1>
          <p className="text-gray-700 text-[0.99rem] font-semibold capitalize">
            Per {pay?.interval==="annualy" ? "Year" : "Month"} ${pay?.amount}
          </p>
          {/* <div className="flex justify-between items-center">
            <div>
              <p>From</p>
              <p>
                Per {pay?.interval} ${pay?.amount}
              </p>
            </div>
            <h1 className="text-primary text-ld font-semibold capitalize ">
              {pay?.program === "oldage-pensioner"
                ? "Senior Citizen Pension"
                : pay?.program}
            </h1>
            <p>
              Per {pay?.interval} ${pay?.amount}
            </p>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default VoucherList;
