import { useLocation } from "react-router-dom";
import { Select } from "antd";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import eye from "../../../imgs/eyee.png";
import across from "../../../imgs/across.png";

const FirstB = () => {
  const option = useSelector((state) => state.option);
  const penId = useSelector((state) => state.penId);
  console.log(penId,"penId")
  const profileData=useSelector((state)=>state.profileData)
  const universalPenId = useSelector((state) => state.universalPenId);

  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const pensionerAdd = location?.state?.fromDashboard
  const EmployeeAddBenficiary=location?.state?.fromEmployeeDashboard
  // console.log(EmployeeAddBenficiary,"EmployeeAddBenficiary")
 
  const [prompt, setPrompt] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  // const [phoneNo, setPhoneNo] = useState("");
  const [relationship, setRelationship] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [test, setTest] = useState(false);
  const [programs, setPrograms] = useState([{}]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [err, setErr] = useState("");
  const dash = location.pathname;
  //Get Programs
  useEffect(() => {
    if(EmployeeAddBenficiary){
      axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/programs-beneficiaries/${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        const updatedPrograms = data.data.map((i) => ({
          value: i?.program?.id,
          label: i?.program?.name === 'senior-citizen-Pension'
            ? 'Senior Citizen Pensioner'
            : i?.program?.name.charAt(0).toUpperCase() + i?.program?.name?.slice(1),
        }));
        // To set the updated array in the state, you can use the setPrograms function
        setPrograms(updatedPrograms);
      })

      .catch(() => {

      });
    }
    else if(pensionerAdd || dash==="/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions"){
      const tok= (dash==="/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions") ? profileData?.tokens?.access?.token : dataUser.data.tokens.access.token
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/programs-beneficiaries`,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((data) => {
        const updatedPrograms = [{
          value: data?.data[0]?.program?.id,
          label: data?.data[0]?.program?.name === 'senior-citizen-Pension'? 'Senior Citizen Pensioner' : data?.data[0]?.program?.name.charAt(0).toUpperCase() +  data?.data[0]?.program?.name?.slice(1),
        }];

        // To set the updated array in the state, you can use the setPrograms function
        setPrograms(updatedPrograms);
        
      })

      .catch(() => {
        
      });
    }
  }, []);

  const handleChange = (setState, check) => (event) => {
    
    if (check === "tt") {
      setState(event);
    } else {
      setState(event.target.value);
    }
  };
  const nextHandler = (e) => {
    e.preventDefault();
    let body;
    if (email && prompt === "") {
      body = {
        relationship,
        programId: pensionerAdd || dash==="/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions" ? selectedProgram : `${penId}`,
        email: email,
        gid,
        password: password,
      };
    } else if (prompt && email === "") {
      const wow = parsePhoneNumber(prompt);
      let countryCode = wow.countryCallingCode;
      let number = wow.nationalNumber;
      body = {
        relationship,
        programId:pensionerAdd || dash==="/EDashboard/BeneficiaryQuestions"  || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions"? selectedProgram : `${penId}`,
        gid,
        countryCode: `+${countryCode}`,
        phoneNo: number,
        password: password,
      };
    } else if (email && prompt) {
      const wow = parsePhoneNumber(prompt);
      let countryCode = wow.countryCallingCode;
      let number = wow.nationalNumber;
      body = {
        relationship,
        programId:pensionerAdd || dash==="/EDashboard/BeneficiaryQuestions"  || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions"? selectedProgram : `${penId}`,
        countryCode: `+${countryCode}`,
        phoneNo: number,
        email: email,
        gid,
        password: password,
      };
    } else {
      body = {
        relationship,
        programId: pensionerAdd || dash==="/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions" ? selectedProgram : `${penId}`,
        gid: gid,
        password: password,
      };
    }
  
    if (dash === "/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions"){
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/signup-by-staff`,
          { accountInfo: body },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          localStorage.setItem("addPenEmp", true);
          dispatch({
            type: "benId",
            num: `${data.data.id}`,
          });
          if (
            dash === "/Dashboard/BeneficiaryQuestions" ||
            dash === "/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions"
          ) {
            dispatch({ type: "penId" , num: selectedProgram});
            dispatch({ type: "BRSET", num: 1 });
           
          } else {
            dispatch({ type: "IINCREMENT" });
          }

          // dispatch({ type: "veri2" });
        })
        .catch((err) => {
          if(selectedProgram=="" || selectedProgram==null)
          {
            setErr("Program is a required");
          }
          else if (
            err.response.data.message ===
            '"relationship" must be one of [Me, Husband, Wife, Father, Mother, Son, Daughter, Brother, Sister, Legal guardian]'
          ) {
            setErr("Relationship is a required field");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    } else {
      
      
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/signup`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
   
          dispatch({
            type: "benId",
            num: `${data.data.id}`,
          });
          if (dash === "/Dashboard/BeneficiaryQuestions") {
            dispatch({ type: "BRSET", num: 1 });
            dispatch({ type: "penId" , num: selectedProgram});
         
          } else {
            dispatch({ type: "IINCREMENT" });
          }

          // dispatch({ type: "veri2" });
        })
        .catch((err) => {
          if(selectedProgram=="" || selectedProgram==null)
          {
            setErr("Program is a required");
          }
          else if (
            err.response.data.message ===
            '"relationship" must be one of [Me, Husband, Wife, Father, Mother, Son, Daughter, Brother, Sister, Legal guardian]'
          ) {
            setErr("Relationship is a required field");
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    }
  };
  const passShowHandler = () => {
    const wow = document.getElementById("Pass12");

    if (wow.type === "password") {
      wow.type = "text";
      if (test) {
        setTest(false);
      } else {
        setTest(true);
      }
    } else {
      wow.type = "password";
      if (test) {
        setTest(false);
      } else {
        setTest(true);
      }
    }
   
  };
  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} action="">
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="">
                <label
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Relation
                </label>
                <Select
                  placeholder="Relation"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={handleChange(setRelationship, "tt")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={[
                    {
                      value: "Brother",
                      label: "Brother",
                    },
                    {
                      value: "Daughter",
                      label: "Daughter",
                    },
                    {
                      value: "Father",
                      label: "Father",
                    },
                    {
                      value: "Husband",
                      label: "Husband",
                    },
                    {
                      value: "Legal guardian",
                      label: "Legal Guardian",
                    },
                    {
                      value: "Mother",
                      label: "Mother",
                    },
                    {
                      value: "Sister",
                      label: "Sister",
                    },
                    {
                      value: "Son",
                      label: "Son",
                    },
                    {
                      value: "Wife",
                      label: "Wife",
                    },
                  ]}
                />
              </div>
              <div className="">
                <label
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Phone Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={handleChange(setPrompt, "tt")}
                  international
                  countryCallingCodeEditable={false}
                  required={option === "Employee" ? true : false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
              </div>
              <div className="mt-3">
                <label
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  onChange={handleChange(setEmail)}
                  value={email}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
            <div>
              <div className="">
                <label
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  GID *
                </label>
                <input
                  type="text"
                  placeholder="GID"
                  // onChange={handleChange(setGid)}
                  onChange={(event) => {
                    const enteredValue = event.target.value;
                    if (!isNaN(enteredValue)) {
                      // If it's a valid number, update the state with the value
                      setGid(enteredValue);
                    } else {
                      // If it's not a valid number, display an error message
                      // setErr("GID must be a valid number");
                      // setShow(true);
                    }
                  }}
                  value={gid}
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              {(pensionerAdd || dash==="/EDashboard/BeneficiaryQuestions" || dash==="/AdminDashboard/BeneficiaryQuestions" || dash==="/superAdminDashboard/BeneficiaryQuestions") && (
                <div className="mt-1">
                  <label
                    className="text-[#596F96] text-[0.88rem] font-semibold"
                  >
                    Program
                  </label>
                  <Select
                    placeholder="Program"
                    optionFilterProp="children"
                    className="wow"
                    style={{ marginBottom: "2", marginTop: "0.5rem" }}
                    onChange={(e)=>setSelectedProgram(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={programs}
                  />
                </div>
              )}

              <div className="w-full">
                <div className={dash === "/EDashboard/BeneficiaryQuestions" || pensionerAdd || EmployeeAddBenficiary ? "mt-6" : "mt-3"}>
                  <label
                    htmlFor="Pass12"
                    className="text-[#596F96] text-[0.88rem] font-semibold"
                  >
                    Password
                  </label>
                  <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                    <input
                      type="password"
                      placeholder="Password"
                      className="w-[100%]"
                      id="Pass12"
                      onChange={handleChange(setPassword)}
                      value={password}
                      required
                    ></input>
                    <img
                      className="w-[1.6rem] cursor-pointer"
                      onClick={passShowHandler}
                      src={eye}
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex sm:justify-end justify-center w-[100%]">
                  <button
                    onClick={nextHandler}
                    className="relative justify-center flex cursor-pointer mt-10 bg-primary pt-2 pb-2 pl-[2rem] pr-[2rem] text-white rounded-xl"
                  >
                    {/* <Spin
                      style={{
                        display: `${isLoading ? "" : "none"}`,
                        marginRight: "1rem",
                      }}
                      indicator={antIcon}
                    /> */}
                    <span>Next</span>
                    {/* <div
                      className={`w-[100%] h-[100%] ${isLoading ? "" : "hidden"
                        } absolute top-0 bg-slate-400 opacity-40 rounded-2xl`}
                    ></div> */}
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </form>



      <div
        className={`${show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstB;
