import Page from "./driverListing";
import e1 from "../../../imgs/e1.png";
import e2 from "../../../imgs/e2.png";
import e3 from "../../../imgs/e3.png";
import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Card from "../initial/Card";
const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const items = [
    {
      key: "1",

      label: `Total Drivers`,
      count: 90,
    },
    {
      key: "2",

      label: `Active Drivers`,
      count: 46,
    },
    {
      key: "3",

      label: `Inactive Drivers`,
      count: 44,
    },
  ];
  return (
    <>
      <div
        className="sm:p-10 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={`flex justify-between items-center mt-4`}>
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() => {
                  user === "employee"
                    ? router("/Maintenance")
                    : user === "admin"
                    ? router("//Maintenance")
                    : user === "superadmin"
                    ? router("/Maintenance")
                    : router("/Maintenance");
                }}
                className="w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Drivers
              </h2>
            </div>
            <button
              onClick={() => {
                router("/Maintenance-Add-Driver");
              }}
              className={`text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Add Driver
            </button>
          </div>
          <div className="flex flex-wrap justify-between sm:ml-0 place-items-center gap-4 mt-[1rem] mb-4">
            {items.map((item) => (
              <Card key={item.key} item={item} />
            ))}
          </div>
        </div>
      </div>
      <Page />
    </>
  );
};

export default Listing;
