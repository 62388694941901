import { Pagination } from "antd";
import { useState } from "react";
const PensionerTable = () => {
  const [page, setPage] = useState(1);
  const totalPages = 1;
  const [search, setSearch] = useState("");
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const columnHeaders = [
    "ID",
    "Manufacturer",
    "Year",
    "Model",
    "BodyStyle",
    "Type",
    "Engine",
    "Exterior Color",
    "Interior Color",
    "License Plate",
    "Mileage",
    "Steering",
    "Location",
    "Cost",
    "Assigned To",
    "Drivers",
  ];

  const vehicleData = [
    {
      ID: 1,
      Manufacturer: "Toyota",
      Year: 2020,
      Model: "Camry",
      BodyStyle: "Sedan",
      Type: "Hybrid",
      Engine: "2.5L 4-Cylinder",
      ExteriorColor: "Silver",
      InteriorColor: "Black",
      LicensePlate: "ABC123",
      Mileage: 15000,
      Steering: "Left",
      Location: "New York",
      Cost: "New",
      AssignedTo: "Employee",
      Drivers: ["John Doe", "Jane Smith"],
    },
    {
      ID: 2,
      Manufacturer: "Ford",
      Year: 2019,
      Model: "F-150",
      BodyStyle: "Truck",
      Type: "Gas",
      Engine: "3.5L V6",
      ExteriorColor: "Blue",
      InteriorColor: "Gray",
      LicensePlate: "DEF456",
      Mileage: 30000,
      Steering: "Left",
      Location: "Los Angeles",
      Cost: "Used",
      AssignedTo: "Regional Director",
      Drivers: ["Alice Johnson", "Bob Brown"],
    },
    {
      ID: 3,
      Manufacturer: "Tesla",
      Year: 2021,
      Model: "Model S",
      BodyStyle: "Sedan",
      Type: "Electric",
      Engine: "Dual Motor",
      ExteriorColor: "Red",
      InteriorColor: "White",
      LicensePlate: "GHI789",
      Mileage: 5000,
      Steering: "Left",
      Location: "San Francisco",
      Cost: "New",
      AssignedTo: "Minister",
      Drivers: ["Charlie Davis", "Diana Evans"],
    },
    {
      ID: 4,
      Manufacturer: "BMW",
      Year: 2018,
      Model: "X5",
      BodyStyle: "SUV",
      Type: "Diesel",
      Engine: "3.0L Inline-6",
      ExteriorColor: "Black",
      InteriorColor: "Beige",
      LicensePlate: "JKL012",
      Mileage: 45000,
      Steering: "Left",
      Location: "Chicago",
      Cost: "Used",
      AssignedTo: "PS",
      Drivers: ["Eve Foster", "Frank Green"],
    },
    {
      ID: 5,
      Manufacturer: "Chevrolet",
      Year: 2022,
      Model: "Bolt EV",
      BodyStyle: "SUV",
      Type: "Electric",
      Engine: "Electric Motor",
      ExteriorColor: "White",
      InteriorColor: "Black",
      LicensePlate: "MNO345",
      Mileage: 1000,
      Steering: "Left",
      Location: "Houston",
      Cost: "New",
      AssignedTo: "Employee",
      Drivers: ["Grace Hill", "Henry Ivers"],
    },
    {
      ID: 6,
      Manufacturer: "Honda",
      Year: 2017,
      Model: "Civic",
      BodyStyle: "Sedan",
      Type: "Gas",
      Engine: "2.0L 4-Cylinder",
      ExteriorColor: "Gray",
      InteriorColor: "Black",
      LicensePlate: "PQR678",
      Mileage: 35000,
      Steering: "Left",
      Location: "Miami",
      Cost: "Foreign Used",
      AssignedTo: "Employee",
      Drivers: ["Ian Jones", "Jill King"],
    },
  ];

  const pageHandler = (e) => {
    setPage(e);
  };
  return (
    <section className="p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by Model, License Plate"
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto"></div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold whitespace-nowrap tracking-wider"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {vehicleData.length === 0 ? (
                <h1 className="text-center text-[1.1rem] p-5">No Data Found</h1>
              ) : (
                <tbody>
                  {vehicleData.map((data, index) => (
                    <tr
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                      onClick={() => {}}
                    >
                      <th
                        scope="row"
                        className="px-4 py-5 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data?.Manufacturer}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Year}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Model}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.BodyStyle}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Type}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Engine}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.ExteriorColor}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.InteriorColor}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.LicensePlate}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Mileage}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Steering}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Cost}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.AssignedTo}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Drivers}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Location}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default PensionerTable;
