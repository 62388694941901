import React, { useState, useRef, useEffect } from "react";
import qwqw from "../imgs/qwqw.png";
import cross from "../imgs/cross.svg";
import io from "socket.io-client";
import { Input, Button } from "antd";
import { useSelector } from "react-redux";
import {
  SendOutlined,
  PaperClipOutlined,
  PictureOutlined,
  FileAddOutlined,
  PlaySquareOutlined,
  VideoCameraOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
let file;
const Whatsapp = () => {
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const pensioner = useSelector((state) => state.pensionerInfo);
  const [showButtons, setShowButtons] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [recMsg, setRecMsg] = useState("");
  const [msgId, setMsgId] = useState("");
  const [image, setImage] = useState(false);
  const [mediaConfirm, setMediaConfirm] = useState(false);
  const [video, setVideo] = useState(false);
  const [doc, setDoc] = useState(false);
  const [medId, setMedId] = useState(null);
  const [myFile, setMyFile] = useState(null);
  const popupRef = useRef(null);
  // const [toNum, setToNum] = useState("+1 (612) 396-8705");
  const [medUrl, setMedUrl] = useState("");
  const toNum = "+923369795959";
  const [check, setCheck] = useState(false);
  const user = useSelector((state) => state.user);
  const comMessage = [
    {
      message: "Hey there",
      text: "Welcome to SCP, Thanks for using the platform. We are here to help you with your queries.",
    },
    {
      message: "How can we help you today?",
    },
  ];
  const textButton = [
    {
      text: "I want to know about my pension",
    },
    {
      text: "How to create default beneficiery ?",
    },
    {
      text: "How to create application ?",
    },
  ];
  const handleSendMessage = () => {
    if (msg.trim() === "") return; // Don't add empty messages
    setMessages([...messages, msg]);
    setMsg(""); // Clear the input field
  };
  function handleRecMessage() {
    if (msg.trim() === "") return;
    setMessages([...messages, recMsg]);
    setRecMsg("");
  }
  const handleButtonClick = () => {
    setShowButtons(!showButtons);
  };
  let url = "https://graph.facebook.com/v18.0/136722186191857/messages";

  // const appendMessage=()=>{
  //   if(object.entry[0].changes[0].value.messages[0].text.body){
  //     setMessages([...messages, object.entry[0].changes[0].value.messages[0].text.body]);
  //   }
  // }

  // let payloadWithoutTemplate = {
  //   messaging_product: "whatsapp",
  //   recipient_type: "individual",
  //   to: toNum,
  //   type: "text",
  //   text: {
  //     preview_url: false,
  //     body: `${msg}`,
  //   },
  // };

  let payload = {
    messaging_product: "whatsapp",
    to: toNum, //+16123968705
    type: "template",
    template: {
      name: "oap_1st_message",
      language: {
        code: "en",
      },
      components: [
        {
          type: "body",
          parameters: [
            {
              type: "text",
              text: `${msg}`,
            },
          ],
        },
      ],
    },
  };
  let imageBody = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: toNum,
    type: "IMAGE",
    image: {
      id: `${medId}`,
      caption: "",
    },
  };
  let videoBody = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: toNum,
    type: "VIDEO",
    video: {
      id: `${medId}`,
      caption: "",
    },
  };
  let docBody = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: toNum,
    type: "DOCUMENT",
    document: {
      id: `${medId}`,
      caption: "",
    },
  };
  const headers = {
    Authorization: `Bearer EAADWiFSM1JMBOyoku3edPprlZAb8UrRFS4ljoEGIWQcEg1591POoLPAjBZAfU4hRk6oFq8NscGE7FgSUB2SZCI9xZCFMsgNjjsuPymZCmc5R5fk2ajVtMntJ8k3ndNZBcZA8Jjbz3eYon8RmcVG3XNawO6ZBBScS2hzeaDmN2SBBY2pyoVUsBluTgLcCiGj0IuCo`,
    "Content-Type": "application/json",
  };
  const sendmsg = () => {
    setMediaConfirm(false);
    if (!image && !video && !doc) {
      axios
        .post(url, payload, {
          headers: headers,
        })
        .then(function (response) {
          History(response?.data?.messages[0]?.id, msg, "text");
          handleSendMessage();
          const responseData = response.data;
          setMsgId(responseData.data.messages[0].id);
        })
        .catch(function () {});
    }
    if (image) {
      axios
        .post(url, imageBody, {
          headers: headers,
        })
        .then(function (response) {
          History(response?.data?.messages[0]?.id, medUrl, "image");

          setImage(false);
          // setMedId(null);
          setMessages([...messages, "Attachment Sent..."]);
          const responseData = response.data;
          setMsgId(responseData.data.messages[0].id);
        })
        .catch(function () {});
    }
    if (video) {
      axios
        .post(url, videoBody, {
          headers: headers,
        })
        .then(function (response) {
          History(response?.data?.messages[0]?.id, medUrl, "video");

          setVideo(false);
          // setMedId(null);
          setMessages([...messages, "Attachment Sent..."]);
          const responseData = response.data;
          setMsgId(responseData.data.messages[0].id);
        })
        .catch(function () {});
    }
    if (doc) {
      axios
        .post(url, docBody, {
          headers: headers,
        })
        .then(function (response) {
          History(response?.data?.messages[0]?.id, medUrl, "document");
          setDoc(false);
          // setMedId(null);
          setMessages([...messages, "Attachment Sent..."]);
          const responseData = response.data;
          setMsgId(responseData.data.messages[0].id);
        })
        .catch(function () {});
    }
  };

  const imageHandler = async (e) => {
    file = e.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=whatsapp`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setMedUrl(data?.data?.url);
      })
      .catch(() => {});
  };

  const History = (mesId, content, type) => {
    const history = {
      senderCountryCode: pensioner?.user?.countryCode,
      senderPhoneNo: pensioner?.user?.phoneNo,
      recipientCountryCode: "+592",
      recipientPhoneNo: "6203710",
      content: content,
      messageId: mesId,
      webhookEventType: "message",
      messageStatus: "accepted",
      isOutGoing: false,
      type: type,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/whatsapp-chat`, history, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function () {
        setMsg("");
        setMedId(null);
      })
      .catch(function () {});
  };
  const togglePopup = () => {
    setCheck(true);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setCheck(false);
    setPopupOpen(false);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       closePopup();
  //     }
  //   };

  //   if (isPopupOpen) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isPopupOpen]);
  function settingrecord() {
    return messages.map((message, index) => (
      <div
        key={index}
        className={`m-3 bg-primary text-[0.97rem] text-white p-3 rounded-md max-w-max ml-auto`}
      >
        {message}
      </div>
    ));
  }
  useEffect(() => {
    if (check) {
      const socket = io("https://oap-api-staging.jabcomp.gy");
      socket.on("whatsapp-webhook", (data) => {
        console.log("Testing webhook", data);
        if (data?.entry[0]?.changes[0]?.value?.contacts[0]?.wa_id ===  toNum) {
          console.log(
            "receive number",
            data?.entry[0]?.changes[0]?.value?.contacts[0]?.wa_id
          );
          console.log(
            "receive Message",
            data?.entry[0]?.changes[0]?.value?.messages[0]?.text?.body
          );
          const rec =
            data?.entry[0]?.changes[0]?.value?.messages[0]?.text?.body;
          setRecMsg(rec);
          handleRecMessage();
          const extractTextBody = (data, msgId) => {
            console.log("dataNew", data);
            console.log("messageId", msgId);

            if (data.entry) {
              for (const entry of data.entry) {
                if (entry.changes) {
                  for (const change of entry.changes) {
                    if (change.value.messages) {
                      for (const message of change.value.messages) {
                        if (message.id === msgId) {
                          console.log("message received");
                          return message.text.body;
                        }
                      }
                    }
                  }
                }
              }
            }
            return null;
          };
          // Extract the text body and update the state
          const extractedTextBody = extractTextBody(data, msgId);
          if (extractedTextBody) {
            setMessages([...messages, extractedTextBody]);
          } // setMessage(`Received: ${JSON.stringify(data)}`);
        }
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [check]);
  // function convertToBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // }
  const handleChange = (check) => async (e) => {
    file = e.target.files[0];
    // const base641 = await convertToBase64(file);
    // setDisplayx(base641);
    setMyFile(file.name);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("messaging_product", "whatsapp");
    formData.forEach((value, key) => {
      console.log(key, value);
    });
    if (check === "image") {
      setImage(true);
      setVideo(false);
      setDoc(false);
    } else if (check === "video") {
      setImage(false);
      setVideo(true);
      setDoc(false);
    } else if (check === "file") {
      setImage(false);
      setVideo(false);
      setDoc(true);
    }
    const header = {
      Authorization: `Bearer EAADWiFSM1JMBOyoku3edPprlZAb8UrRFS4ljoEGIWQcEg1591POoLPAjBZAfU4hRk6oFq8NscGE7FgSUB2SZCI9xZCFMsgNjjsuPymZCmc5R5fk2ajVtMntJ8k3ndNZBcZA8Jjbz3eYon8RmcVG3XNawO6ZBBScS2hzeaDmN2SBBY2pyoVUsBluTgLcCiGj0IuCo`,
      "Content-Type": "multipart/form-data",
    };
    axios({
      method: "post",
      url: `https://graph.facebook.com/v18.0/136722186191857/media`,
      data: formData,
      headers: header,
    })
      .then((data) => {
        setMedId(data.data.id);
        imageHandler(e);

        setShowButtons(false);
        setMediaConfirm(true);
      })
      .catch((err) => {
        console.log("errrrrrrrrrrr", err);
      });
  };
  const handleMediaclose = () => {
    setMediaConfirm(false);
    setMedId(null);
    setImage(false);
    setVideo(false);
    setDoc(false);
  };
  return (
    <div
      className={`${
        user === "pensioner" && pensioner?.user?.countryCode !== null
          ? ""
          : "hidden"
      }`}
      style={{ position: "fixed", bottom: "0", right: "0", margin: "16px" }}
    >
      <div
        className={`fixed cursor-pointer w-[50px] h-[50px] bottom-[3%] right-[3%] ${
          isPopupOpen ? "bg-white rounded-full" : "bg-[#25D366]"
        } rounded-full flex justify-center items-center`}
        onClick={isPopupOpen ? closePopup : togglePopup}
      >
        <img
          className="w-[45px] h-[45px]"
          src={isPopupOpen ? cross : qwqw}
          alt=""
        />
      </div>

      {isPopupOpen && (
        <div
          className="fixed bottom-[5rem] right-[4px] md:bottom-[5rem] md:right-[16px] lg:bottom-[5rem] lg:right-[16px] bg-white p-0 m-0 rounded-lg shadow-md flex flex-col text-left h-[500px] w-[350px] lg:h-[500px] lg:w-[400px] md:h-[500px] md:w-[400px]"
          ref={popupRef}
        >
          <div className="bg-primary rounded-tl-[7px] rounded-tr-[7px] w-full mb-[20px] h-[100px] shadow-md flex">
            <div className="w-[60px] ml-[20px] top-[10px] mt-[20px]">
              <img className="w-[40px]" src={qwqw} alt="wp" />
            </div>
            <div className="w-[320px] h-full flex justify-center flex-col text-white">
              <h2 className="text-[20px] mb-[4px]">Contact Support</h2>
              <p className="text-[12px]">
                Hey! Here you can Chat with us on <b>Whatsapp</b>
              </p>
            </div>
            {dataUser?.data?.user?.roles[0] !== "pensioner" && (
              <div>
                <VideoCameraOutlined
                  onClick={() => router("/meeting")}
                  className="text-white text-[23px] mr-[12px] mt-[12px] cursor-pointer"
                />
              </div>
            )}
          </div>
          {/* <Paragraph
            disabled
            className="mb-[10px] ml-[22px] cursor-auto"
          >
            The team typically replies in few minutes...
          </Paragraph> */}
          <div className="msgstore  w-full h-[350px] border-[1px]  bg-white mb-[10px] overflow-auto">
            <div className="p-3 ml-4 mr-4">
              {comMessage?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col text-[0.97rem] items-start max-w-max justify-start w-full bg-slate-100 p-[10px] rounded-[10px] mb-[10px]"
                >
                  {item?.message}
                  <br />
                  {item?.text && <div className="mt-2">{item?.text}</div>}
                  {/* {item?.text} */}
                </div>
              ))}
              {textButton?.map((item, index) => (
                <button
                  key={index}
                  onClick={() => setMsg(item?.text)}
                  className="flex flex-col text-[0.97rem] font-semibold items-start border-2 text-primary border-primary  max-w-max justify-start w-full bg-white p-[10px] rounded-[10px] mb-[10px]"
                >
                  {item?.text}
                </button>
              ))}
              {settingrecord()}
            </div>
          </div>
          {mediaConfirm && (
            <div className="relative">
              <div className="z-10 fixed bottom-[16px] right-[16px] bg-white p-[5px] rounded-tl-[5px] rounded-tr-[5px] shadow-md flex flex-col text-left h-[100px] lg:h-[120px] md:h-[120px] w-full max-w-[400px]">
                {/* Popup content */}
                <p className="flex flex-1 m-[5px] text-base">
                  Do You want to send {myFile} ?
                </p>
                <div className="flex items-end justify-end p-[5px]">
                  <Button
                    className="rounded-full text-white w-[40px] h-[40px] cursor-pointer bg-red-500 flex justify-center items-center"
                    onClick={handleMediaclose}
                  >
                    <CloseOutlined
                      style={{ borderColor: "white", fontSize: "18px" }}
                    />
                  </Button>
                  <Button
                    className="rounded-full text-black w-[40px] h-[40px] cursor-pointer bg-[#6EE17E] ml-[3px] flex justify-center items-center"
                    onClick={sendmsg}
                  >
                    <SendOutlined className="text-white" />
                  </Button>
                </div>
              </div>
            </div>
          )}

          <div className="flex item-center justify-center flex-row w-full h-[50px]">
            <Input
              placeholder="Type Your Message"
              className="md:ml-[18px] lg:ml-[18px] ml:[15px] rounded-[20px] h-[40px] lg:w-[340px] md:w-[340px] w-[240px] mb-[10px]"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              onPressEnter={sendmsg}
            ></Input>
            {/* <Input style={{ width: '300px',marginBottom:'25px' }}></Input> */}
            <Button
              className="rounded-full text-black w-[40px] h-[40px] cursor-pointer bg-primary ml-[3px] flex justify-center items-center"
              onClick={sendmsg}
            >
              <SendOutlined className="text-white" />
            </Button>
            <Button className="button-containerrr" onClick={handleButtonClick}>
              <PaperClipOutlined className="iconeatach" />
            </Button>
            {showButtons && (
              <div className="popup-containerrr">
                <div className="btnmedia">
                  <label htmlFor="file-image">
                    <PictureOutlined className="iconeatach" />
                  </label>

                  <input
                    id="file-image"
                    style={{ display: "none" }}
                    accept="image/*"
                    onInput={handleChange("image")}
                    type="file"
                  />
                </div>
                <div className="btnmedia">
                  <label htmlFor="file-video">
                    <PlaySquareOutlined className="iconeatach" />
                  </label>

                  <input
                    id="file-video"
                    style={{ display: "none" }}
                    accept="video/*"
                    onInput={handleChange("video")}
                    type="file"
                  />
                </div>
                <div className="btnmedia">
                  <label htmlFor="file-doc">
                    <FileAddOutlined className="iconeatach" />
                  </label>

                  <input
                    id="file-doc"
                    style={{ display: "none" }}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                    onInput={handleChange("file")}
                    type="file"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Whatsapp;
