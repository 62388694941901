import leftIndex from "../../imgs/ic_left_index.png";
import rightIndex from "../../imgs/ic_right_index.png";
import rightThumb from "../../imgs/ic_right_thumb.png";
import leftThumb from "../../imgs/ic_left_thumb.png";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
let images;
const Main = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const data = useSelector((state) => state?.profileData);
  const universalPenId = useSelector((state) => state.universalPenId);
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    images = [
      {
        src: leftThumb,
        alt: "Left Thumb",
        added: data?.pensionerInfo?.leftThumbs
          ? data?.pensionerInfo?.leftThumbs
          : null,
      },
      {
        src: rightThumb,
        alt: "Right Thumb",
        added: data?.pensionerInfo?.rightThumbs
          ? data?.pensionerInfo?.rightThumbs
          : null,
      },
      {
        src: leftIndex,
        alt: "Left Index",
        added: data?.pensionerInfo?.leftIndices
          ? data?.pensionerInfo?.leftIndices
          : null,
      },
      {
        src: rightIndex,
        alt: "Right Index",
        added: data?.pensionerInfo?.rightIndices
          ? data?.pensionerInfo?.rightIndices
          : null,
      },
    ];
  }, [data]);

  return (
    <div className="sm:ml-0  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
      <div className="flex items-center">
        <img
          className="w-24 h-24 rounded-full mr-3"
          src={data?.person?.image}
          alt="jane"
        />
        <div className="text-sm">
          <h4 className="sm:text-lg font-semibold text-primary truncate  leading-none">
            {data?.person?.title && <>{data?.person?.title}&nbsp;</>}
            {data?.person?.firstName}&nbsp;
            {data?.person?.middleName && <>{data?.person?.middleName}&nbsp;</>}
            {data?.person?.lastName}
          </h4>
          <p className="mt-1">GID: {data?.user?.gid}</p>
        </div>
      </div>
      <div className="p-6 border rounded-lg grid grid-cols-2 gap-4 mt-8">
        {images?.map((image, index) => (
          <div
            key={index}
            className="w-full h-48 flex items-center justify-center border rounded-lg bg-gray-100"
          >
            <img
              src={image.src}
              alt={image.alt}
              className={`object-contain w-24 h-24 ${
                image?.added !== null ? "opacity-100" : "opacity-30"
              }`}
            />
          </div>
        ))}
      </div>

      {/* <div className="p-4 border rounded-lg grid grid-cols-2 gap-4">
          <div className="w-full h-48 flex items-center justify-center border rounded-lg bg-gray-100">
            <img
              src={leftT}
              alt="Image 1"
              className="object-contain w-24 h-24"
            />
          </div>
          <div className="w-full h-48 flex items-center justify-center border rounded-lg bg-gray-100">
            <img
              src={rightT}
              alt="Image 2"
              className="object-contain w-24 h-24"
            />
          </div>
          <div className="w-full h-48 flex items-center justify-center border rounded-lg bg-gray-100">
            <img
              src={leftI}
              alt="Image 3"
              className="object-contain w-24 h-24"
            />
          </div>
          <div className="w-full h-48 flex items-center justify-center border rounded-lg bg-gray-100">
            <img
              src={rightI}
              alt="Image 4"
              className="object-contain w-24 h-24"
            />
          </div>
        </div> */}
      {(dash === "/Edashboard/PensionerAccount/Biometric/Add" ||
        dash === "/superAdminDashboard/PensionerAccount/Biometric/Add" ||
        dash === "/AdminDashboard/PensionerAccount/Biometric/Add") && (
        <div className="flex items-center justify-center w-full mt-10 ">
          <button
            onClick={() => {
              user === "employee"
                ? router("/Edashboard/PensionerAccount/Biometric/Add")
                : user === "admin"
                ? router("/AdminDashboard/PensionerAccount/Biometric/Add")
                : router("/superAdminDashboard/PensionerAccount/Biometric/Add");
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-[3rem] pr-[3rem] text-white bg-primary"
          >
            Add/Update
          </button>
        </div>
      )}
    </div>
  );
};

export default Main;
