import React from 'react'
import Cash from "../components/MHSSS Accounting/outstanding/PayOutstanding/cash/cashIndex"

const DashB = () => {
  return (
    <div>
     <Cash/>
    </div>
  )
}

export default DashB