import React from 'react';
import Nav from '../components/Nav/nav';
import Whatsapp from '../Whatsapp/whatsapp';
import PProfile from '../components/GeneralRegisterOfficer/pProfileGRO';

const PProfileGRO = () => {
  return (
    <div>
      <Nav />
      <PProfile />
      <Whatsapp />
    </div>
  );
};

export default PProfileGRO;
