import { Pagination, Checkbox, Select } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import prof from "../../imgs/profile.png";
import axios from "axios";
import { useState } from "react";
const { Option } = Select;
const Paget = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [allMerchants, setAllMerchants] = useState(true);
  const [merchantsNearMe, setMerchantsNearMe] = useState(false);
  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const columnHeaders = [
    "SR.",
    // "Merchant",
    "Company Name",
    "Business Type",
    "Agency Type",
    "Year Founded",
    "Email",
    "Phone",
    "Location",
  ];
  const handleAllMerchantsChange = () => {
    setAllMerchants(true);
    setMerchantsNearMe(false);
  };

  const handleMerchantsNearMeChange = () => {
    setAllMerchants(false);
    setMerchantsNearMe(true);
  };
  useEffect(() => {
    let url;
    if (allMerchants) {
      if (!searchTerm)
        url = `${process.env.REACT_APP_BASE_URL}/v1/merch-store/stores-headquater`;
      else
        url = `${process.env.REACT_APP_BASE_URL}/v1/merch-store/stores-headquater?region=Region ${searchTerm}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          console.log("dsssss", data?.data);
          setAllData(data?.data);
          // setTotalPages(data?.data?.totalPages);
          // console.log(data?.data?.totalPages);
        })
        .catch(() => {});
    } else if (merchantsNearMe) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;

          // Construct the API request body
          const requestBody = {
            location: {
              latitude: latitude,
              longitude: longitude,
            },
          };

          // If sortBy is provided, add it to the body
          if (sortBy) {
            requestBody.distance = sortBy;
          }

          const url = `${process.env.REACT_APP_BASE_URL}/v1/merch-store/near-store-headquater`;

          axios
            .post(url, requestBody, {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            })
            .then((data) => {
              // Handle the response data
              console.log(data);
              setAllData(data?.data);
              // You can set state or perform other actions with the response data here
            })
            .catch((error) => {
              // Handle any errors
              console.error(error);
            });
        });
      }
    }
  }, [page, sortBy, searchTerm, merchantsNearMe, allMerchants]);
  return (
    <section className=" w-full">
      <div className="mx-auto min-w-screen-xl ">
        <div className=" relative sm:rounded-lg overflow-hidden">
          <div className="flex flex-row items-center justify-end mb-4 gap-2">
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="rounded-lg border border-gray-200 p-2">
                <Checkbox
                  className="checkk4"
                  checked={allMerchants}
                  onClick={handleAllMerchantsChange}
                >
                  All Merchants
                </Checkbox>
              </div>
              <div className="rounded-lg border border-gray-200 p-2">
                <Checkbox
                  className="checkk4"
                  checked={merchantsNearMe}
                  onClick={handleMerchantsNearMeChange}
                >
                  Merchants Near Me
                </Checkbox>
              </div>
            </div>
            {allMerchants && (
              <div className="flex w-[15rem] items-center">
                <h1 className="mr-2 font-semibold w-[40%] text-[15px]">
                  Filter By :{" "}
                </h1>
                <Select
                  size="large"
                  placeholder="Region #"
                  className="p-2 w-full h-[50px] text-sm font-medium text-gray-900 bg-white rounded-lg border-gray-200"
                  value={searchTerm}
                  onChange={(value) => setSearchTerm(value)}
                >
                  <Option value="1">Region 1</Option>
                  <Option value="2">Region 2</Option>
                  <Option value="3">Region 3</Option>
                  <Option value="4">Region 4</Option>
                  <Option value="5">Region 5</Option>
                  <Option value="6">Region 6</Option>
                  <Option value="7">Region 7</Option>
                  <Option value="8">Region 8</Option>
                  <Option value="9">Region 9</Option>
                </Select>
              </div>
            )}
            {merchantsNearMe && (
              <div className="flex w-[15rem] items-center">
                <h1 className="mr-2 font-semibold w-[40%] text-[15px]">
                  Filter By :{" "}
                </h1>
                <Select
                  size="large"
                  placeholder="Distance"
                  className="p-2 w-full h-[50px] text-sm font-medium text-gray-900 bg-white rounded-lg border-gray-200"
                  value={sortBy}
                  onChange={(value) => setSortBy(value)}
                >
                  <Option value="5">5 mile</Option>
                  <Option value="10">10 mile</Option>
                  <Option value="15">15 mile</Option>
                </Select>
              </div>
            )}
            {/* <div className="w-full md:w-[20rem]">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Region #"
                    required=""
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div> */}
          </div>
        </div>
        <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
              <tr>
                {columnHeaders.map((columnHeader, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                  >
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            {allData?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center text-gray-500 text-lg">
                    <p className="p-10">No data found</p>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {allData.map((data, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      // router("/MHSSS/outstanding/person")
                      console.log("dataaa", data);
                    }}
                    className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
                  >
                    <th
                      scope="row"
                      className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                    >
                      {index + 1 + (page - 1) * 6}.
                    </th>

                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      <div>
                        {data?.merchperson?.firstName}{" "}
                        {data?.merchperson?.lastName}
                      </div>
                    </td> */}
                    <td className="px-4 py-3 whitespace-nowrap capitalize">
                      <div className="flex items-center">
                        <img
                          className="w-10 ml-0  h-10 rounded-full mr-2 border-2 border-primary "
                          src={
                            data?.type === "Store"
                              ? data?.merchuser?.merchcompany?.logo
                                ? data?.merchuser?.merchcompany?.logo
                                : prof
                              : data?.merchUser?.merchcompany?.logo
                              ? data?.merchUser?.merchcompany?.logo
                              : prof
                          }
                          alt={`pro`}
                        />
                        <p
                          className="mr-6 capitalize"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {data?.type === "Store"
                            ? data?.merchuser?.merchcompany?.name
                            : data?.merchUser?.merchcompany?.name}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.type === "Store"
                        ? data?.merchuser?.merchcompany?.businessType
                        : data?.merchUser?.merchcompany?.businessType}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.type === "Store"
                        ? data?.merchuser?.merchcompany?.isGovtAgency === false
                          ? "Utility"
                          : "Governmnet"
                        : data?.merchUser?.merchcompany?.businessType === false
                        ? "Utility"
                        : "Governmnet"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.type === "Store"
                        ? data?.merchuser?.merchcompany?.yearFounded
                        : data?.merchUser?.merchcompany?.yearFounded}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.primaryPhone}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data?.primaryEmail}
                    </td>
                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      <Popover
                        content={`${
                          data.Status === "pending" ? "In-Process" : ""
                        }`}
                        placement="bottomLeft"
                      >
                        <div
                          className={`border-none ${
                            data.Status === "Pending"
                              ? "bg-[#2F84F1]"
                              : data?.Status === "rejected"
                              ? "bg-[#D64242]"
                              : " bg-primary"
                          }  p-1 pl-5 pr-5 rounded-md text-center w-[6rem] text-white focus:border-none cursor-pointer capitalize`}
                        >
                          {data.Status}
                        </div>
                      </Popover>
                    </td> */}
                    <td className="px-4 py-3 whitespace-nowrap hover:text-white">
                      {data?.coordinates && (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${data?.coordinates}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline hover:text-white cursor-pointer"
                        >
                          View Location
                        </a>
                      )}
                      {data?.coordinates === null && "No Location"}
                    </td>
                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      <Dropdown
                        menu={{
                          items,
                        }}
                        trigger={["click"]}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        </div>
                      </Dropdown>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* <nav
          className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500 ">
            Showing{" "}
            <span className="font-semibold text-gray-900 ">Page {page} </span>
            of{" "}
            <span className="font-semibold text-gray-900 ">{totalPages}</span>
          </span>
          <div className={`flex justify-end mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={totalPages * 10}
              showSizeChanger={false}
              onChange={pageHandler}
              current={searchTerm ? pagewithsearch : page}
            />
          </div>
        </nav> */}
      </div>
    </section>
  );
};

export default Paget;
