import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleChatMessages from "../../chat/SingleChatMessage";
import axios from "axios";
const ChatMessagesContainer = ({ refetch }) => {
  const token = useSelector((state) => state.token);
  const chatMessages = useSelector((state) => state.chatMessages);
  const selectedChatPhoneNo = useSelector((state) => state.selectedChatPhoneNo);
  const messagesContainerRef = useRef(null);
  const selectedChatUser = useSelector((state) => state.selectedChatUser);
  const [messagesTotalPages, setMessagesTotalPages] = useState(0);
  const [messagesCurrentPage, setMessagesCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const loadMoreMessages = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/v1/whatsapp-chat?phoneNumber=${selectedChatPhoneNo}&skip=0&page=${
          messagesCurrentPage + 1
        }&limit=5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setMessagesCurrentPage((prevPage) => prevPage + 1);
        setMessagesTotalPages(data?.data?.totalPages);
       
        const reversedMessages = data?.data?.rows?.reverse();

        // Use Promise.all to wait for all async operations to complete
        const messagesPromises = reversedMessages?.map(async (message) => {
          // Check if the type is "image"
          if (
            message.type === "image" ||
            message.type === "video" ||
            message.type === "document"
          ) {
            try {
              // Call the ImageConverter function
              const imageUrl = await ImageConverter(message?.content);

              // Add the imageUrl to the object
              message.Url = imageUrl;
            } catch (error) {
              console.error("Error fetching image URL:", error);
              // Handle the error if needed
            }
          }

          return message;
        });

        Promise.all(messagesPromises)
          .then((messages) => {
            const newMessages = [...messages, ...chatMessages];
           
            dispatch({ type: "CHAT_MESSAGES", messages: newMessages });
          })
          .catch((error) => {
            console.error("Error processing messages:", error);
          });
      })
      .catch((error) => {
        console.error("Error loading more messages:", error);
      });
  };

  useEffect(() => {
    if (selectedChatUser) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/whatsapp-chat?phoneNumber=${selectedChatPhoneNo}&skip=0&page=1&limit=5`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setMessagesCurrentPage(data?.data?.page);
          setMessagesTotalPages(data?.data?.totalPages);
         
          const reversedMessages = data?.data?.rows?.reverse();

          // Use Promise.all to wait for all async operations to complete
          const messagesPromises = reversedMessages?.map(async (message) => {
            // Check if the type is "image"
            if (
              message.type === "image" ||
              message.type === "video" ||
              message.type === "document"
            ) {
              try {
                // Call the ImageConverter function
                const imageUrl = await ImageConverter(message?.content);

                // Add the imageUrl to the object
                message.Url = imageUrl;
              } catch (error) {
                console.error("Error fetching image URL:", error);
                // Handle the error if needed
              }
            }

            return message;
          });

          Promise.all(messagesPromises)
            .then((messages) => {
              dispatch({ type: "CHAT_MESSAGES", messages });
            })
            .catch((error) => {
              console.error("Error processing messages:", error);
            });
        })
        .catch((err) => {});
    }
  }, [selectedChatUser, refetch]);

  const ImageConverter = (url) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=whatsapp`,
          {
            fileNames: [url],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Resolve with the image URL
          resolve(response.data[0].url);
        })
        .catch((error) => {
          // Reject with the error
          reject(error);
        });
    });
  };

  useEffect(() => {
    // Scroll to the bottom when chatMessages change
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  return (
    <div
      ref={messagesContainerRef}
      className="p-4 h-full h-[calc(100% - 160px)] overflow-y-scroll custom-scrollbar"
      // style={{ scrollbarWidth: "thin", scrollbarColor: "#4CAF50 #FFFFFF" }}
    >
      {messagesCurrentPage < messagesTotalPages && (
        <div
          className="cursor-pointer flex justify-center items-center py-1 text-primary font-semibold"
          onClick={loadMoreMessages}
        >
          Load More
        </div>
      )}
      {chatMessages?.map((m, index) => {
        return <SingleChatMessages message={m} key={index} />;
      })}
    </div>
  );
};

export default ChatMessagesContainer;
