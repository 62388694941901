import Listing from "../components/Dash/Listing/listing";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";
const ListingPage = () => {
  return (
    <div>
      <Nav />
      <Listing />
      <Whatsapp />
    </div>
  );
};

export default ListingPage;
