import Nav from "../components/Nav/nav";
import DigitalCard from "../components/card/card";

const CardPage = () => {
  return (
    <div>
      <Nav />
      <DigitalCard />
    </div>
  );
};

export default CardPage;
