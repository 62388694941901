import { useDispatch, useSelector } from "react-redux";
import across from "../../../imgs/across.png";
import { useState } from "react";
import { Button, Input, Select, Space, Checkbox } from "antd";
import { useEffect } from "react";
import axios from "axios";
import check from "../../../imgs/add.png";
import deleteicon from "../../../imgs/bs3.png";
import building from "../../../imgs/building.svg";
import { EnvironmentOutlined } from "@ant-design/icons";
let file;
const FourthR = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const govAgency = useSelector((state) => state.isGovernmentAgency);
  const [test, setTest] = useState(false);
  const [display, setDisplay] = useState(building);
  const [address, setAddress] = useState(null);
  const [addressM, setAddressM] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [checkGov, setCheckGov] = useState(false);
  const [checkGovM, setCheckGovM] = useState(false);
  const [regionNo, setRegionNo] = useState(null);
  const [postOfficeOptions, setPostOfficeOptions] = useState(null);
  const [postOffice, setPostOffice] = useState(null);
  const [postOfficeM, setPostOfficeM] = useState(null);
  const [regionNoM, setRegionNoM] = useState(null);
  const [regionName, setRegionName] = useState(null);
  const [regionNameM, setRegionNameM] = useState(null);
  const [lot, setLot] = useState(null);
  const [lotM, setLotM] = useState(null);
  const [city, setCity] = useState(null);
  const [cityM, setCityM] = useState(null);
  const [locationt, setLocationt] = useState(null);
  const [ward, setWard] = useState(null);
  const [wardM, setWardM] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allCitiesM, setAllCitiesM] = useState("");
  const [allWards, setAllWards] = useState("");
  const [allWardsM, setAllWardsM] = useState("");
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [numbers, setNumbers] = useState([]);
  const [newNumber, setNewNumber] = useState("");
  const [emails, setEmails] = useState([]);
  const [lName, setLName] = useState("");
  const [mailing, setMailing] = useState(false);
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");

  useEffect(() => {
    console.log("test", govAgency);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleLocateMe = () => {
    if (navigator.geolocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Make a request to Google Maps Geocoding API
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU`;

            fetch(apiUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.results && data.results.length > 0) {
                  const locationName = data.results[0].formatted_address;
                  setLocationt(`${latitude}, ${longitude}`);
                  setLName(locationName);
                } else {
                  console.error("Could not retrieve location name");
                }
              })
              .catch((error) => {
                console.error("Error getting location:", error.message);
              });
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      }
    }
  };

  const addNumber = () => {
    if (newNumber.trim() !== "") {
      setNumbers([...numbers, newNumber]);
      setNewNumber("");
    }
  };

  const removeNumber = (index) => {
    const updatedLinks = [...numbers];
    updatedLinks.splice(index, 1);
    setNumbers(updatedLinks);
  };

  const nextHandler = (e) => {
    e.preventDefault();
    if (primaryPhone === "") {
      setErr("Please enter Phone Number");
      setShow(true);
      return;
    }
    if (primaryEmail === "") {
      setErr("Please enter HQ Email Address");
      setShow(true);
      return;
    }
    let mailingBody;
    mailingBody = {
      lot: lotM,
      address: addressM,
      region: regionNoM,
      regionName: regionNameM,
      city: cityM,
      village: wardM,
    };
    let data;
    data = {
      image: imageUrl,
      region: regionNo,
      regionName: regionName,
      coordinates: locationt,
      lot: lot,
      address: address,
      city: city,
      village: ward,
      mailAddress: mailingBody,
      primaryEmail: primaryEmail,
      primaryPhone: `+${primaryPhone}`,
    };
    if (govAgency === true) {
      data.merchpostofficeId = postOffice;
    }
    if (emails.length !== 0) {
      data.emails = emails;
    }
    if (numbers.length !== 0) {
      data.contacts = numbers;
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        if (govAgency === true) {
          dispatch({ type: "IINCREMENT" });
          dispatch({ type: "IINCREMENT" });
        } else dispatch({ type: "IINCREMENT" });
      })
      .catch((err) => {
        console.log(err);
        if (lot === null || lot === "") setErr("Lot Number is required");
        else if (address === null || address === "")
          setErr("Address is required");
        else if (regionNo === null || regionNo === "")
          setErr("Region No is required");
        else if (regionName === null || regionName === "")
          setErr("Region Name is required");
        else if (city === null || city === "") setErr("Town/City is required");
        else if (ward === null || ward === "")
          setErr("Ward/Village is required");
        else if (
          err.response.data.message === '"image" is not allowed to be empty'
        ) {
          setErr("Please uplaod Headquarter Picture");
        } else if (
          err?.response?.data?.message === `"[0]" must be a valid email`
        ) {
          setErr("Enter valid email address");
        } else if (
          err?.response?.data?.message ===
          `"contacts" does not contain 1 required value(s)`
        ) {
          setErr("Enter contact number");
        }
        if (
          lotM === "" ||
          addressM === "" ||
          regionNoM === null ||
          regionNameM === "" ||
          cityM === null ||
          wardM === null
        ) {
          setErr("Enter complete mailing address");
        } else setErr(err.response.data.message);
        setShow(true);
      });
  };
  useEffect(() => {
    if (govAgency === true) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-post-offices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          console.log("data", data);
          const wow = data?.data?.map((postOffice) => {
            return {
              label: `${postOffice.name}`,
              value: `${postOffice.id}`,
            };
          });
          wow.push({
            label: "Other",
            value: "Other",
          });
          setPostOfficeOptions(wow);
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else if (postOffice !== "Other" && postOffice !== null) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-post-office-address?merchpostofficeId=${postOffice}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setLot(data?.data?.lot);
          setAddress(data?.data?.address);
          setRegionNo(data?.data?.regionNo);
          setRegionName(data?.data?.regionName);
          setCity(data?.data?.city);
          setWard(data?.data?.village);
          setCheckGov(true);
        })
        .catch(() => {});
    } else if (postOffice === "Other") {
      setLot("");
      setCheckGov(false);
      setAddress("");
      setRegionNo(null);
      setRegionName("");
      setCity(null);
      setWard(null);
    }
  }, [postOffice]);
  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else if (postOfficeM !== "Other" && postOfficeM !== null) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-post-office-address?merchpostofficeId=${postOfficeM}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setLotM(data?.data?.lot);
          setAddressM(data?.data?.address);
          setRegionNoM(data?.data?.regionNo);
          setRegionNameM(data?.data?.regionName);
          setCityM(data?.data?.city);
          setWardM(data?.data?.village);
          setCheckGovM(true);
        })
        .catch(() => {});
    } else if (postOfficeM === "Other") {
      setLotM("");
      setCheckGovM(false);
      setAddressM("");
      setRegionNoM(null);
      setRegionNameM("");
      setCityM(null);
      setWardM(null);
    }
  }, [postOfficeM]);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else if (govAgency !== true || postOffice === "Other") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          setRegionName(data.data.data[0]);
        })
        .catch((err) => {});
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else if (
      regionNoM !== null &&
      (govAgency !== true || postOfficeM === "Other")
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNoM}`
        )
        .then((data) => {
          setRegionNameM(data.data.data[0]);
        })
        .catch(() => {});
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [regionNoM]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else if (govAgency !== true || postOffice === "Other") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
        })
        .catch(() => {});
    }
  }, [regionName]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else if (
      regionNameM !== "" &&
      (govAgency !== true || postOfficeM === "Other")
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNoM}&regionName=${regionNameM}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCitiesM(wow);
        })
        .catch(() => {});
    }
  }, [regionNameM]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else if (govAgency !== true || postOffice === "Other") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
        })
        .catch(() => {});
    }
  }, [city]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else if (
      cityM !== null &&
      (govAgency !== true || postOfficeM === "Other")
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNoM}&regionName=${regionNameM}&city=${cityM}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWardsM(wow);
        })
        .catch(() => {});
    }
  }, [cityM]);

  const handleChange = (setChange, check) => (event) => {
    console.log("event", event);
    if (check === "tt" || check === "tn") {
      setChange(event);
    } else {
      setChange(event.target.value);
    }
  };

  const addHadler = (e) => {
    setAddress(e.target.value);
  };
  const lotHadler = (e) => {
    setLot(e.target.value);
  };
  const handleEmailChange = (e, index) => {
    const updatedEmails = [...emails]; // Create a copy of the emails array
    updatedEmails[index] = e.target.value; // Update the value at the specified index
    setEmails(updatedEmails); // Update the state with the modified array
  };
  const addEmail = () => {
    const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (newEmail.trim() !== "" && emailFormatRegex.test(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail("");
    } else {
      setErr("Please enter a valid email address");
      setShow(true);
    }
  };
  const removeEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };
  const imageHandler = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantHQStorePics`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setImageUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    console.log("rea", regionName);
    if (mailing === true) {
      setPostOfficeM(postOffice);
      setLotM(lot);
      setAddressM(address);
      setRegionNoM(regionNo);
      setRegionNameM(regionName);
      setCityM(city);
      setWardM(ward);
      setAllCitiesM(allCities);
      setAllWardsM(allWards);
    } else {
      setPostOfficeM(null);
      setLotM("");
      setAddressM("");
      setRegionNoM(null);
      setRegionNameM("");
      setCityM(null);
      setWardM(null);
      setAllCitiesM(null);
      setAllWardsM(null);
    }
  }, [mailing]);
  const handleMailing = () => {
    setMailing(!mailing);
  };
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[50%] w-[85%] mt-10"
        onSubmit={nextHandler}
      >
        <div className="flex items-center justify-center mb-4 flex-col">
          <label htmlFor="special-input" className="cursor-pointer relative">
            <div className="relative w-[8.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262]  rounded-full mb-4 ">
              <img
                className="object-cover w-[8.5rem] h-[8.5rem]"
                src={display}
                alt=""
              />
            </div>

            <div
              className={`bg-primary rounded-full absolute bottom-4 right-2`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler}
            />
          </label>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Upload Headquarter Picture <span className="text-red-600">*</span>
          </h2>
        </div>
        <div className="w-[100%] mb-4">
          <div className="flex flex-col">
            <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
              Primary Phone Number <span className="text-red-600">*</span>
            </h2>
            <div className="relative">
              <span className="absolute left-2 top-1/2 font-bold text-[1rem] transform z-[20] -translate-y-1/2 text-black">
                +
              </span>
              <Input
                placeholder="Primary Phone Number"
                value={primaryPhone}
                className="pl-6 p-2"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[0-9()-]*$/.test(inputValue)) {
                    setPrimaryPhone(inputValue);
                  }
                }}
                type="tel"
              />
            </div>
          </div>
        </div>
        <div className="w-[100%] mb-6">
          <div className="flex flex-col">
            <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
              Primary Email <span className="text-red-600">*</span>
            </h2>

            <Input
              placeholder="Primary Email"
              value={primaryEmail}
              className="p-2"
              onChange={(e) => setPrimaryEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="w-[100%] mb-4">
          <div className="flex flex-col">
            <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
              Other Contact Details <span className="text-red-600"></span>
            </h2>
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input
                placeholder="Phone Number"
                value={newNumber}
                className="p-2"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\+?[0-9()-]*$/.test(inputValue))
                    setNewNumber(e.target.value);
                }}
                type="tel"
              />
              <Button className="bg-slate-300  h-12" onClick={addNumber}>
                Add
              </Button>
            </Space.Compact>

            {numbers.map((number, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2 p-2 bg-gray-200 shadow-md rounded-md border border-gray-300"
              >
                <Input
                  placeholder="Number"
                  value={number}
                  className="bg-slate-200 mr-2"
                  type="tel"
                />

                <button type="button" onClick={() => removeNumber(index)}>
                  <img src={deleteicon} alt="" className="w-6 h-6" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="w-[100%] mb-6">
          <div className="flex flex-col">
            {/* <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
              Email Address
            </h2> */}
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input
                placeholder="HQ Email Address"
                value={newEmail}
                className="p-2"
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <Button className="bg-slate-300  h-12" onClick={addEmail}>
                Add
              </Button>
            </Space.Compact>

            {emails?.map((link, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2 p-2 bg-gray-200 shadow-md rounded-md border border-gray-300"
              >
                <Input
                  placeholder="Enter URL"
                  value={link}
                  className="bg-slate-200 mr-2"
                  onChange={(e) => handleEmailChange(e, index)}
                />

                <button type="button" onClick={() => removeEmail(index)}>
                  <img src={deleteicon} alt="" className="w-6 h-6" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
            Address:
          </h2>

          <div className="flex items-center justify-center flex-col ">
            <Button
              onClick={handleLocateMe}
              className="flex items-center justify-center bg-gray-300 w-[13rem] h-12 font-semibold text-[1rem] text-gray-700"
              icon={<EnvironmentOutlined />}
            >
              Locate Me <span className="text-red-600">*</span>
            </Button>
            <input
              type="text"
              placeholder="Location"
              value={lName}
              className=" lg:text-[1.1rem] w-[100%] bg-white mt-3 border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          {govAgency === true && (
            <>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Post Office <span className="text-red-600">*</span>
              </h2>
              <Select
                placeholder="Post Office"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                onChange={handleChange(setPostOffice, "tn")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                value={postOffice}
                options={postOfficeOptions}
              />
            </>
          )}
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Lot # <span className="text-red-600">*</span>
          </h2>
          <input
            type="text"
            readOnly={checkGov}
            placeholder="Lot #"
            value={lot}
            onChange={lotHadler}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Address <span className="text-red-600">*</span>
          </h2>
          <input
            type="text"
            value={address}
            readOnly={checkGov}
            placeholder="Address"
            onChange={addHadler}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Region No <span className="text-red-600">*</span>
          </h2>
          <Select
            placeholder="Region No"
            optionFilterProp="children"
            className="wow"
            disabled={checkGov}
            style={{ marginBottom: "1.2rem" }}
            onChange={handleChange(setRegionNo, "tn")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            value={regionNo}
            options={allRegions}
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Region Name <span className="text-red-600">*</span>
          </h2>
          <input
            type="text"
            onChange={handleChange(setRegionName, "tttt")}
            value={regionName}
            readOnly={checkGov}
            placeholder="Region Name"
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Town/City <span className="text-red-600">*</span>
          </h2>
          <Select
            placeholder="Town/City"
            optionFilterProp="children"
            className="wow"
            disabled={checkGov}
            style={{ marginBottom: "1.2rem" }}
            onChange={handleChange(setCity, "tt")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            value={city}
            options={allCities}
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Ward/Village <span className="text-red-600">*</span>
          </h2>
          <Select
            placeholder="Ward/Village"
            optionFilterProp="children"
            className="wow"
            disabled={checkGov}
            style={{ marginBottom: "1.2rem" }}
            value={ward}
            showSearch
            onChange={handleChange(setWard, "tt")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={allWards}
          />
        </div>
        <div>
          <h2 className="mb-4 text-[1.1rem] font-semibold text-[#596F96]">
            Mailing Address:
          </h2>

          {/* <div className="flex items-center justify-center flex-col ">
                <Button
                onClick={handleLocateMe}
                  className="flex items-center justify-center bg-gray-300 w-[13rem] h-12 font-semibold text-[1rem] text-gray-700"
                  icon={<EnvironmentOutlined />}
                >
                  Locate Me
                </Button>
                <input
                type="text"
                placeholder="Location"
                value={lName}
                className=" lg:text-[1.1rem] w-[100%] bg-white mt-3 border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              </div> */}
          <div className="ml-0">
            <Checkbox
              checked={mailing}
              onChange={handleMailing}
              className={"checkk"}
            >
              Is your mailing address is same as above address?
            </Checkbox>
          </div>
          {govAgency === true && (
            <>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Post Office <span className="text-red-600">*</span>
              </h2>
              <Select
                placeholder="Post Office"
                optionFilterProp="children"
                className="wow"
                disabled={mailing}
                style={{ marginBottom: "1.2rem" }}
                onChange={handleChange(setPostOfficeM, "tn")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                value={postOfficeM}
                options={postOfficeOptions}
              />
            </>
          )}
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Lot # <span className="text-red-600">*</span>
          </h2>
          <input
            type="text"
            readOnly={mailing || checkGovM}
            placeholder="Lot #"
            value={lotM}
            onChange={(e) => setLotM(e.target.value)}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Address <span className="text-red-600">*</span>
          </h2>
          <input
            type="text"
            readOnly={mailing || checkGovM}
            value={addressM}
            placeholder="Address"
            onChange={(e) => setAddressM(e.target.value)}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Region No <span className="text-red-600">*</span>
          </h2>
          <Select
            placeholder="Region No"
            optionFilterProp="children"
            className="wow"
            disabled={mailing || checkGovM}
            style={{ marginBottom: "1.2rem" }}
            onChange={handleChange(setRegionNoM, "tn")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            value={regionNoM}
            options={allRegions}
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Region Name <span className="text-red-600">*</span>
          </h2>
          <input
            type="text"
            readOnly={mailing || checkGovM}
            onChange={handleChange(setRegionNameM, "tttt")}
            value={regionNameM}
            placeholder="Region Name"
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Town/City <span className="text-red-600">*</span>
          </h2>
          <Select
            placeholder="Town/City"
            optionFilterProp="children"
            className="wow"
            disabled={mailing || checkGovM}
            style={{ marginBottom: "1.2rem" }}
            onChange={handleChange(setCityM, "tt")}
            value={cityM}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={allCitiesM}
          />
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Ward/Village <span className="text-red-600">*</span>
          </h2>
          <Select
            placeholder="Ward/Village"
            optionFilterProp="children"
            className="wow"
            disabled={mailing || checkGovM}
            style={{ marginBottom: "1.2rem" }}
            value={wardM}
            showSearch
            onChange={handleChange(setWardM, "tt")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={allWardsM}
          />
        </div>

        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[100%]">
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default FourthR;
