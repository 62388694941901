import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
const Vppopup = ({
  setValue,
  value,
  setShowErrModal,
  err,
  setErr,
  uuid,
  setShowConfirmModal,
  setShowSuccessModal,
}) => {
  const depositMoney = useSelector(
    (state) => state.pensionerSenderTypeVerification
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [code1, setCode1] = useState("");
  const [seconds, setSeconds] = useState(60);
  const sapPaymentSelectedPerson = useSelector(
    (state) => state.sapPaymentSelectedPerson
  );
  const startCountdown = false;
  useEffect(() => {
    if (startCountdown && seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
    if (seconds === 0) {
      // setShow(false);
      setSeconds(60);
    }
  }, [startCountdown, seconds]);

  const completeHandler = (e) => {
    setCode1(e);
  };
  const checkHandler = () => {
    if (code1 === "") {
      setShowErrModal(true);

      setErr("Please enter the verification code");
    } else {
      setValue("");
      const type =
        value === "otp"
          ? "verify-phone-otp"
          : value === "pin"
          ? "verify-pin"
          : "verify-email-otp";
      // setValue("");

      const requestType =
        value === "otp"
          ? "merch-verify-phone-otp"
          : value === "pin"
          ? "merch-verify-pin"
          : "merch-verify-email-otp";

      const api = depositMoney
        ? `v1/sap-wallet-info/merch-verify-pensioner/${type}?pensionerUserId=${sapPaymentSelectedPerson?.fromId}`
        : `v1/users/${requestType}?userId=${sapPaymentSelectedPerson?.fromId}`;
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            [value !== "pin" ? "verifyCode" : "pinCode"]: code1,
            tempUUID: uuid,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          if (depositMoney) {
            dispatch({
              type: "UUID",
              num: data?.data?.tempUUID,
            });
            setErr("Pensioner Successfully Verified ");
            setShowConfirmModal(true);
            return;
          }
          setShowSuccessModal(true);
          setErr("Pensioner Successfully Verified ");
        })
        .catch((err) => {
          if (err.response.data.message === "verification code not found") {
            setErr("Please enter the correct verification code");
            setShowErrModal(true);
          } else {
            setErr(err.response.data.message);
            setShowErrModal(true);
          }
        });
    }
  };

  return (
    <div
      tabindex="-1"
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-slate-500/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="relative lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[30%] mx-4 p-4 w-full max-w-md max-h-full bg-white rounded-2xl flex items-center flex-col p-6 pl-5 pr-5">
        <button
          type="button"
          className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
          onClick={() => {
            setValue("");
          }}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <h2 className="mb-[1rem] text-[1.5rem] font-bold">
          {value === "email"
            ? "Email Verification"
            : value === "pin"
            ? "PIN Verification"
            : "OTP Verification"}
        </h2>
        <p
          className={`text-center text-[1rem] mb-[2rem] ${
            value === "pin" ? "hidden" : ""
          }`}
        >
          Please enter the six digit verification code that was sent to &nbsp;
          <span className="font-semibold">
            {value === "email"
              ? `${sapPaymentSelectedPerson?.email}`
              : `${sapPaymentSelectedPerson?.phone}`}
          </span>
        </p>
        <p
          className={`text-center text-[1rem] mb-[2rem] ${
            value === "pin" ? "" : "hidden"
          }`}
        >
          Please enter the four-digit PIN code you've configured &nbsp;
        </p>
        <ReactInputVerificationCode
          className="--ReactInputVerificationCode-itemWidth"
          length={value === "pin" ? 4 : 6}
          onCompleted={completeHandler}
          value={code1}
        />

        <button
          onClick={checkHandler}
          className="w-full bg-primary text-white p-3 text-medium  sm:text-[0.9rem]  text-[1.1rem] rounded-lg mt-4"
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default Vppopup;
