import ReactDOM from "react-dom/client";
import "./index.css";
import across from "../src/imgs/across.png";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { legacy_createStore as createStore } from "redux";
import allReducers from "./components/reducers";
import { Provider } from "react-redux";
import { Spin } from "antd";
// import axios from "axios";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import useAxiosLoader from "./utlis/AxiosLoader";
import {
  FpjsProvider,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, allReducers);
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
const AppContainer = () => {
  // Use 'useState' to manage the loading state
  const { loading, show, error, setShow } = useAxiosLoader();
  // const [loading, setLoading] = useState(false);
  // const [ref, setRef] = useState(false);
  // const [err, setErr] = useState("");
  // const router = useNavigate()

  //Previous Code

  // axios.interceptors.request.use((request) => {
  //   setLoading(true);
  //   return request;
  // });

  // axios.interceptors.response.use(
  //   (response) => {
  //     setLoading(false);
  //     return response;
  //   },
  //   (error) => {
  //     setLoading(false);
  //     if (error.response.data.code === 401 && error.response.data.message === "please authenticate") {
  //       // setRef(true)
  //       setErr("Session Expired: Please Login Again")
  //       setShow(true)
  //       router("/")
  //     }
  //     if (!ref)
  //       return Promise.reject(error);
  //     setRef(false);
  //   }
  // );
  // useEffect(() => {
  //   const requestInterceptor = axios.interceptors.request.use((request) => {
  //     setLoading(true);
  //     return request;
  //   });

  //   const responseInterceptor = axios.interceptors.response.use(
  //     (response) => {
  //       console.log("laoding")
  //       setLoading(false);
  //       return response;
  //     },
  //     (error) => {
  //       setLoading(false);
  //       if (error.response.data.code === 401 && error.response.data.message === "please authenticate") {
  //         setErr("Session Expired: Please Login Again");
  //         setShow(true);
  //         router("/");
  //       }
  //       if (!ref) return Promise.reject(error);
  //       setRef(false);
  //     }
  //   );

  //   // Cleanup function
  //   return () => {
  //     // Remove the interceptors when the component unmounts
  //     axios.interceptors.request.eject(requestInterceptor);
  //     axios.interceptors.response.eject(responseInterceptor);
  //   };
  // }, [router,ref]);

  return (
    <>
      <Spin
        tip="Loading..."
        style={{ top: "18%", fontSize: "40px", color: "#1BA397" }}
        fullscreen={"true"}
        size="large"
        spinning={loading}
        delay={100}
      >
        <div className="relative">
          <App />
        </div>
      </Spin>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1rem] mt-[3rem] mb-5">{error}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};
root.render(
  // <React.StrictMode>
  // <React.StrictMode>
  <FpjsProvider
    loadOptions={{
      apiKey: "lpt6aqaMOohelz6Qa9CF",
      endpoint: [
        // "https://metrics.yourwebsite.com",
        FingerprintJSPro.defaultEndpoint,
      ],
      scriptUrlPattern: [
        // "https://metrics.yourwebsite.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        FingerprintJSPro.defaultScriptUrlPattern,
      ],
      // region: "eu"
    }}
  >
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContainer />
        </PersistGate>
      </Provider>
    </Router>
  </FpjsProvider>
  // </React.StrictMode>,
  // document.getElementById("root")
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
