
import logoo from "../../imgs/impLogo.png";
import { DatePicker } from "antd";
import { useState } from "react";
import {PlusCircleFilled} from "@ant-design/icons";
import { Checkbox } from "antd";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import across from "../../imgs/across.png";

let file;
const AdditionalDocs = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);
  const profileData = useSelector((state) => state.profileData);
  const [documentName, setDocumentName] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [dateIssued, setDateIssued] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [imageFront, setImageFront] = useState("");
  const [imageBack, setImageBack] = useState("");
  const [officeUse, setOfficeUse] = useState(false);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");
console.log(isLoading1, isLoading2)

  const handleChange = (setState, check) => (event) => {
    
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
        load(false);
      })
      .catch(() => {

      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    let body;
    body = {
      type: "official-doc",
      docIDName: documentName,
      docIDValue: documentNumber,
      isPrivate: officeUse,
      docImages: [],
    };
    if (expiryDate) {
      body.expiryDate = expiryDate;
    }
    // Check if issuedDate is provided before adding it to the body
    if (dateIssued) {
      body.issuedDate = dateIssued;
    }
    if (imageFront !== "") {
      body.docImages[0] = {
        type: "front image",
        imageUrl: `${imageFront}`,
      };
    }
    if (imageBack !== "") {
      body.docImages[1] = {
        type: "back image",
        imageUrl: `${imageBack}`,
      };
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/official-docs?pensionerUserId=${universalPenId}`,
        {
          officialDocs: [body],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setMsg("Documents Uploaded")
        setShowUpload(true)
        setTimeout(() => {
          setShowUpload(false)
        }, 1500);
        setIsLoading(false);
      })
      .catch((err) => {
        
        setErr(err.response.data.message);
        setShow(true)
        setIsLoading(false);
      });
  };
  return (
    <div
      className={`${profileData?.user?.isDisabled ? "pointer-events-none" : ""}`}
    >
      <form
        onSubmit={submitHandler}
        className="md:pl-[18rem] md:pr-[18rem] mb-[5rem] pl-[3rem] pr-[3rem] "
      >
        <div className="flex flex-col">
          <label
            className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
          >
            Document Name
          </label>
          <input
            type="text"
            name=""
            value={documentName}
            onChange={handleChange(setDocumentName)}
            placeholder="Document Name"
            className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
          />
        </div>
        <div className="flex flex-col">
          <label
            className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
          >
            Document #
          </label>
          <input
            type="text"
            name=""
            onChange={handleChange(setDocumentNumber)}
            value={documentNumber}
            placeholder="Document #"
            className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
          />
        </div>
        <div className="flex flex-col">
          <label
            className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
          >
            Date Issued
          </label>
          <DatePicker
            placeholder="Date Issued"
            style={{
              marginBottom: "1.2rem",
            }}
            // defaultValue={dayjs(`${passExp}`, "YYYY-MM-DD")}
            onChange={handleChange(setDateIssued, "tgf")}
            disabledDate={disabledDate1}
            className="date-class"
          />
        </div>
        <div className="flex flex-col">
          <label
            className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
          >
            Expiration Date
          </label>
          <DatePicker
            placeholder="Expiration Date"
            style={{
              marginBottom: "1.2rem",
            }}
            // defaultValue={dayjs(`${passExp}`, "YYYY-MM-DD")}
            onChange={handleChange(setExpiryDate, "tgf")}
            disabledDate={disabledDate}
            className="date-class"
          />
        </div>
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Image Front:
        </h2>
        <label htmlFor="special-input" className={` cursor-pointer relative`}>
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
            </div>
            <p className="text-[#9CA6C2] ml-2">Image Front</p>
          </div>
          <input
            type="file"
            className="hidden"
            id="special-input"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler(setDisplay, setImageFront, setIsLoading1)}
          />
        </label>
        <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
          <img className="w-[25rem] h-[13rem]" src={display} alt="" />
          {/* <div
            className={`absolute ${
              isLoading1 ? "" : "hidden"
            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
          >
            <Spin size="large" />
          </div> */}
        </div>
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Image Back:
        </h2>
        <label htmlFor="special-input1" className={` cursor-pointer relative`}>
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center"/>
            </div>
            <p className="text-[#9CA6C2] ml-2">Image Back:</p>
          </div>
          <input
            type="file"
            className="hidden"
            id="special-input1"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler(setDisplay1, setImageBack, setIsLoading2)}
          />
        </label>
        <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
          <img className="w-[25rem] h-[13rem]" src={display1} alt="" />
          {/* <div
            className={`absolute ${
              isLoading2 ? "" : "hidden"
            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
          >
            <Spin size="large" />
          </div> */}
        </div>
        <div className="ml-4">
          <Checkbox
            // checked={copArr[index].isCurrent}
            onChange={(e) => setOfficeUse(e.target.checked)}
            className={"checkk"}
          >
            For office use only
          </Checkbox>
        </div>
        <button
          type="submit"
          className={`relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          {/* <Spin
            style={{ display: `${isLoading ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
          <span className={`${isLoading ? "ml-3" : ""}`}>Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoading ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showUpload ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute left-[42%]"
          >
            <img src={logoo} alt=""  style={{width:'50px'}} onClick={() => setShowUpload(false)}/>
          </div>
          <p className="text-center mt-[4rem] mb-1">{msg}</p>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDocs;
