import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";

import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";

// import SearchFilter from "./SearchFilter";
let url;
const Paget = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDop, setSearchDop] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [outstanding, setOutstanding] = useState([]);
  const transactionType = useSelector((state) => state.programTransactionType);
  const [afterApi, setAfterApi] = useState(false);
  const merchantSapType = useSelector((state) => state.merchantSapType);
  console.log(transactionType);
  console.log(merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);

  const [searchTerm, setSearchTerm] = useState("");

  const columnHeaders = [
    "SR.",
    "Date Time",
    "Voucher transaction ID",
    "Program Name",
    "Booklet #",
    "Amount",
    // "Actions",
  ];

  useEffect(() => {
    const delay = 200;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const merchParams = {
        limit: 6,
        skip:
          search || searchDop || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(transactionType?.type === "store"
          ? { merchstoreId: transactionType?.merchStore?.id }
          : { merchheadquarterId: transactionType?.merchHeadquarter?.id }),
      };

      const penParams = {
        // limit: 6,
        // skip:
        //   search || searchDop || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        // page: search ? pagewithsearch : page,
        // sortBy: "createdAt:desc",
        ...(merchantSapType === "store"
          ? { merchstoreId: merchantSapTypeData?.id }
          : {
              merchheadquarterId:
                merchantSapTypeData?.merchuser?.merchheadquarterId,
            }),
      };

      console.log("params", penParams);

      const params = user === "pensioner" ? merchParams : penParams;
      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [
    page,
    search,
    searchDop,
    countryCode,
    phoneNoo,
    pagewithsearch,
    searchTerm,
  ]);

  const fetchEmployees = (params, cancelTokenSource) => {
    if (user === "pensioner") {
      return;
    } else if (
      user === "merchant-owner" ||
      user === "merchant-employee" ||
      user === "merchant-admin"
    ) {
      let id =
        merchantSapType === "store"
          ? merchantSapTypeData.id
          : merchantSapTypeData.merchuser?.merchheadquarterId;

      if (merchantSapType === "store") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/sapmoney-dashboard/contacted-pensioners/for-merch`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/sapmoney-dashboard/contacted-pensioners/for-merch`;
      }
    }

    const tok =
      user === "pensioner" ? dataUser.data.tokens.access.token : token;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sapmoney-dashboard/contacted-pensioners/for-merch`,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setOutstanding(data?.data);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  const showHandler = (check) => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl relative  h-auto bg-white">
        <div className="relative sm:rounded-lg overflow-hidden ">
          {/* table */}

          {outstanding?.length === 0 ? (
            <div className="flex items-center justify-center mt-12">
              <p className="text-gray-400 text-sm">No Pensioners yet</p>
            </div>
          ) : (
            <div className="grid md:grid-cols-4  gap-3 mt-4 mb-2  sm:grid-cols-4 grid-cols-4 sm:p-4">
              {outstanding &&
                outstanding?.map((data, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center cursor-pointer p-4 rounded-lg shadow-md border-2 border-green-100  bg-gray-100"
                    onClick={() => {
                      dispatch({ type: "PROGRAM_TRANSACTION_TYPE", num: data });
                      router(
                        "/SapMoney/Merchant/Pensioner/ProgramTransactions"
                      );
                    }}
                  >
                    <img
                      src={data?.image}
                      alt="profile"
                      className="w-12 h-12 rounded-full"
                    />
                    <p className="text-sm font-semibold mt-2 text-center">
                      {data?.firstName} {data?.lastName}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Paget;
