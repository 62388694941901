import Ellipse from "../../../imgs/backg.svg";
import back5 from "../../../imgs/back5.png";
import a from "../../../imgs/shopa.png";
import b from "../../../imgs/shopb.png";
import c from "../../../imgs/shopc.png";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
let items;
const AdminPanel = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  items = [
    {
      key: "1",
      title: `Samuel’s Grocery Shop`,
      img: a,
      route: "/Inventory-Categories",
    },
    {
      key: "2",
      title: `ABC Grocery Shop`,
      img: b,
      route: "/Inventory-Categories",
    },
    {
      key: "3",
      title: `XYZ Grocery Shop`,
      img: c,
      route: "/Inventory-Categories",
    },
    // {
    //   key: "4",
    //   title: `Andrew Grocery Store`,
    //   img: Sea,
    //   route: "/Inventory-Categories",
    // },
    // {
    //   key: "8",
    //   title: `Collen Grocery Store`,
    //   img: Snacks,
    //   //   route: "/Inventory-Products",
    //   route: "/Inventory-Categories",
    // },
  ];
  const Shop = ({ item }) => {
    return (
      <div className="flex w-full items-center rounded-xl bg-white shadow-lg justify-between pl-4 pr-4 p-2 border-b border-gray-300">
        <div className="flex h-full items-center">
          <img
            src={item.img}
            alt={item.title}
            onClick={() => {
              router(item.route);
            }}
            className="w-18 h-18 rounded-md cursor-pointer"
          />
          <div className="ml-4 flex-grow">
            <h2
              onClick={() => {
                router(item.route);
              }}
              className="text-lg hover:text-[#1BA397] cursor-pointer font-semibold"
            >
              {item.title}
            </h2>
          </div>
        </div>
        <div>
          <ArrowRightOutlined
            onClick={() => {
              router(item.route);
            }}
            className="text-md hover:bg-gray-500 cursor-pointer p-2 font-semibold text-white bg-gray-400 rounded-full"
          />
        </div>
      </div>
    );
  };
  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${Ellipse})`,
        backgroundSize: "cover",
        backgroundPosition: "center 10%",
        backgroundPositionY: "-80px",
      }}
    >
      <div className="flex items-center justify-center">
        <div className="md:w-[85%] mt-[3rem] rounded-md w-[95%] p-[4rem] pt-[2rem] mb-16  sm:min-h-[960px] bg-[#F8F5F5] shadow-lg">
          <div
            onClick={() => {
              if (user === "superadmin") router("/Inventory");
              else if (user === "admin") router("/Inventory");
              else if (user === "employee") router("/Inventory");
              else router("/Inventory");
            }}
            className="cursor-pointer flex w-full items-center justify-center"
          >
            <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              Vendors
            </h2>
          </div>
          <div className="w-full flex flex-wrap mt-[3rem] justify-center items-center gap-8 md:gap-x-8 gap-y-4 sm:min-h-[200px]">
            {items?.map((item, index) => (
              <Shop key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
