import React from "react";
import thumb from "../../../imgs/Thumb.png";
import { useNavigate } from "react-router-dom";

const ApproveModal = ({ setVerify, text, title, route, type }) => {
  const router = useNavigate();
  return (
    <div
      className={` fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
    >
      <div className="relative rounded-xl w-[23rem] pb-6 pt-4 flex items-center p-5 bg-white flex-col relative">
        <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
          <img src={thumb} alt="" className="w-full h-full" />
        </div>
        <h1 className="font-bold mt-2 text-primary text-[1.1rem] capitalize">
          {title}
        </h1>
        <p className="text-center w-[90%] text-[#757575] mt-3 mb-10">{text}</p>

        <div className="w-full flex items-center justify-center gap-2 absolute bottom-0 mt-4">
          <button
            onClick={() => {
              setVerify(false);
              if (type !== null || type !== undefined) {
                if (type === "pensionBook" || type === "question") {
                  router(route);
                  return;
                }
              }
              router("/pensionerVerification/PriorityRequests");
            }}
            className="cursor-pointer rounded-md pt-[0.7rem] pb-[0.7rem] pl-10 pr-10 text-white bg-primary w-full"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;
