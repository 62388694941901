import React, { useState, useRef, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
const ObjectDetection = () => {
  const [model, setModel] = useState(null);
  const [objects, setObjects] = useState([]);
  const imageRef = useRef(null);
  useEffect(() => {
    const loadModel = async () => {
      await tf.ready();
      const loadedModel = await cocoSsd.load();
      setModel(loadedModel);
    };
    loadModel();
  }, []);
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const imageElement = imageRef.current;
        imageElement.src = reader.result;
        imageElement.onload = async () => {
          const detectedObjects = await model.detect(imageElement);
          setObjects(detectedObjects);
          const hasHuman = detectedObjects.some(
            (object) => object.class === "person"
          );
          if (hasHuman) {
            console.log("The image contains a human.");
          } else {
            console.log("The image does not contain a human.");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <h1>Object Detection</h1>
      {model ? (
        <div style={{ margin: "20px" }}>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          <img ref={imageRef} alt="Input" style={{ maxWidth: "100%" }} />
          <div>
            {objects.map((object, index) => (
              <div
                key={index}
                style={{ border: "2px solid red", margin: "5px" }}
              >
                {`${object.class}: Confidence ${object.score.toFixed(3)}`}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>Loading model...</p>
      )}
    </div>
  );
};

export default ObjectDetection;

// import React, { useState, useRef, useEffect } from 'react';
// import * as tf from '@tensorflow/tfjs';
// import * as blazeface from '@tensorflow-models/blazeface';

// const FaceDetection = () => {
//   const [model, setModel] = useState(null);
//   const [faces, setFaces] = useState([]);
//   const imageRef = useRef(null);

//   useEffect(() => {
//     const loadModel = async () => {
//       await tf.ready();
//       const loadedModel = await blazeface.load();
//       setModel(loadedModel);
//     };

//     loadModel();
//   }, []);

//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];

//     if (file) {
//       const reader = new FileReader();

//       reader.onload = async () => {
//         const imageElement = imageRef.current;
//         imageElement.src = reader.result;

//         imageElement.onload = async () => {
//           const detectedFaces = await model.estimateFaces(imageElement);
//           setFaces(detectedFaces);

//           if (detectedFaces.length > 0) {
//             console.log('The image contains a human.');
//           } else {
//             console.log('The image does not contain a human.');
//           }
//         };
//       };

//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <div>
//       <h1>Face Detection</h1>
//       {model ? (
//         <div style={{ margin: '20px' }}>
//           <input type="file" accept="image/*" onChange={handleImageChange} />
//           <img ref={imageRef} alt="Input" style={{ maxWidth: '50%' }} />
//           <div>
//             {/* {faces.map((face, index) => (
//               <div key={index} style={{ border: '2px solid red', margin: '5px' }}>
//                 {`Face ${index + 1}: Confidence ${face.faceInViewConfidence.toFixed(3)}`}
//               </div>
//             ))} */}
//           </div>
//         </div>
//       ) : (
//         <p>Loading model...</p>
//       )}
//     </div>
//   );
// };

// export default FaceDetection;
