import React, { useState } from "react";
import eye from "../../../../../../imgs/eyee.png";
import cross from "../../../../../../imgs/cross.png";
import axios from "axios";
import { useSelector } from "react-redux";
const VerifyPassword = ({ setShow, setErr, setErrorShow,setShowPopRPin,setPin,setPass,pass }) => {
  const [showVPass, setShowVPass] = useState(false);
  const token = useSelector((state) => state.token);
  const VerifyPass = () => {
    if (pass === "") {
      setErrorShow(true);
      setErr("Please enter your password");
    } else {
      setShow(false);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-user/my-pincode`,
          {
            password: pass,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
            setPin(data?.data?.pinCode);
          //   setShowPv(false);
            setShowPopRPin(true);
        })
        .catch((err) => {
            // setShowPopRPin(true);
          setErr(err.response.data.message);
          setErrorShow(true);
        });
    }
  };
  const togglePasswordVisibility = (setter) => {
    setter((prev) => !prev);
  };
  return (
    <div
      className={`w-[100%] z-[10%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
        <div className="flex items-center justify-center flex-col sm:w-[20rem]">
          <h2 className="mb-[1rem] text-sm mt-8 font-bold">
            Please verify your password to View/Reset PIN
          </h2>
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => {
              setShow(false);
              setPass("");
            }}
          >
            <img className="scale-[0.8]" src={cross} alt="Close" />
          </div>
          <div className="flex flex-col w-full px-2">
            <div className="flex mt-2 justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-2">
              <input
                type={showVPass ? "text" : "password"}
                placeholder="Enter Password"
                onChange={(e) => {
                  setPass(e.target.value);
                }}
                value={pass}
                className="w-[100%]"
                autoComplete="new-password"
              />
              <img
                className="w-[1.6rem] cursor-pointer"
                onClick={() => togglePasswordVisibility(setShowVPass)}
                src={eye}
                alt="Toggle Password Visibility"
              />
            </div>
            <button
              onClick={VerifyPass}
              className="w-full bg-primary text-white p-2 mb-2 text-[1rem]  rounded-lg mt-2"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPassword;
