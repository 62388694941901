import e1 from "../../../../imgs/e1.png";
import e2 from "../../../../imgs/e2.png";
import e3 from "../../../../imgs/e3.png";
import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import PagetGRO from "./GROlist";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PensionerTable from "../Listing/PensionerGroListing";

const ListingGROAdmin = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [test, setTest] = useState(false);
  const [total, setTotal] = useState(null);
  const [active, setActive] = useState(null);
  const [inActive, setInActive] = useState(null);
  const [wow, setWow] = useState(0);

  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=gro-admin`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setTotal(data?.data?.total);
        setActive(data?.data?.active);
        setInActive(data?.data?.inactive);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <div
        className="md:p-3 p-3 md:pl-[6rem] md:pr-[6rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => router("/superAdminDashboard")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                GRO Admin
              </h2>
            </div>

            <button
              onClick={() => {
                dispatch({ type: "ISET", num: 0 });
                router("/superAdminDashboard/addGroAdmin");
              }}
              className={`${
                dataUser.data.user.status === "pending"
                  ? "pointer-events-none"
                  : ""
              } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Create GRO Admin
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:ml-0 place-items-center mt-[3rem] mb-8">
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Total GRO Admins
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{total}</p>
              </div>
              <div>
                <img className="w-[2.1rem] mt-4" src={e2} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[0.73rem] font-semibold text-[#797979]">
                  Active GRO Admins
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{active}</p>
              </div>
              <div>
                <img className="w-[2.2rem] mt-4" src={e3} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Inactive GRO Admins
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{inActive}</p>
              </div>
              <div>
                <img className="w-[2rem] mt-5" src={e1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:p-10 p-3 md:pl-[8rem] md:pr-[8rem]  flex ">
        <div className="cursor-pointer" onClick={() => changeHandler("R")}>
          <h2
            className={`lg:text-[1.1rem] ${wow === 0 ? "font-semibold" : ""} `}
          >
            Employees
          </h2>
          <div
            className={`lg:w-[7.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
              wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
            } `}
          ></div>
        </div>

        <div className="cursor-pointer" onClick={() => changeHandler("I")}>
          <h2
            className={`lg:text-[1.1rem] ml-6 ${
              wow === 1 ? "font-semibold" : ""
            } `}
          >
            Pensioners
          </h2>
          <div
            className={`lg:w-[7.5rem] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
              wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
            }  `}
          ></div>
        </div>
      </div>

      {wow === 1 ? (
        <div>
          <PensionerTable />
        </div>
      ) : (
        <div>
          <PagetGRO />
        </div>
      )}
    </>
  );
};

export default ListingGROAdmin;
