import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { useLocation } from "react-router";
import fingerprintWhite from "../../../imgs/fingerprintWhite.png";
import gallary from "../../../imgs/picture.png";
import device from "../../../imgs/sign.png";
import SignatureBlack from "../../../imgs/signatureBlack.png";
import figA from "../../../imgs/fingerActive.png";
// import imageWhite from "../../../imgs/ImageTabWhite.png";
import SignatureWhite from "../../../imgs/signatureWhite.png";
import leftT from "../../../imgs/leftThumb.png";
import Tprint from "../../../imgs/thubprint.png";
import rightT from "../../../imgs/rightThumb.png";
import leftI from "../../../imgs/leftIndex.png";
import rightI from "../../../imgs/rightIndex.png";
import tick from "../../../imgs/across.png";
import codeHandler from "../codeHandler/codehandle";
import {
  PlusCircleFilled,
  UploadOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import across from "../../../imgs/across.png";
let file;
const FifthR = () => {
  const location = useLocation();
  const dash = location.pathname;
  const benId = useSelector((state) => state.benId);
  const penId = useSelector((state) => state.penId);
  const dataUser = useSelector((state) => state.dataUser);
  const popData = useSelector((state) => state.popData);
  const icurrent = useSelector((state) => state.icurrent);
  const veri = useSelector((state) => state.verify);
  const dispatch = useDispatch();
  console.log("verify", veri);
  const token = useSelector((state) => state.token);
  const [display, setDisplay] = useState("");
  // const [display1, setDisplay1] = useState("");
  // const [display2, setDisplay2] = useState("");
  const [sig, setSig] = useState(true);
  const [sigD, setSigD] = useState(false);
  const [pop, setPop] = useState(false);
  const [thumb, setthumb] = useState(false);
  // const [dev, setDev] = useState(false);
  // const [imp, setImp] = useState(false);
  const [gal, setGal] = useState(false);
  // const [display3, setDisplay3] = useState("");
  // const [display4, setDisplay4] = useState("");
  const [signature, setSignature] = useState("");
  const [thumbLeft, setThumbLeft] = useState([]);
  const [thumbRight, setThumbRight] = useState([]);
  const [indexRight, setIndexRight] = useState([]);
  const [indexLeft, setIndexLeft] = useState([]);
  const [thumbLeftD, setThumbLeftD] = useState([]);
  const [thumbRightD, setThumbRightD] = useState([]);
  const [indexRightD, setIndexRightD] = useState([]);
  const [indexLeftD, setIndexLeftD] = useState([]);
  const [thumbLOpen, setThumbLOpen] = useState(false);
  const [thumbROpen, setThumbROpen] = useState(false);
  const [indexLOpen, setIndexLOpen] = useState(false);
  const [indexROpen, setIndexROpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFinger, setCurrentFinger] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [verify, setVerify] = useState(false);
  const [value, setValue] = useState("CSS");
  const [word, setWord] = useState("");
  const [showError, setShowError] = useState(false);
  const [err, setErr] = useState("");
  const checkHandler = (e) => {
    console.log("check", e.target.value);
    setValue(e.target.value);
  };
  const nextHandler = (e) => {
    e.preventDefault();
    // if((icurrent <= 5 && dash === "/Dashboard/BeneficiaryQuestions") || (icurrent === 14 && dash==="/")
    // || dash === "/EDashboard/BeneficiaryQuestions" || dash === "/Dashboard/BeneficiaryQuestions"){
    //   if(word===""){
    //     setShowError(true);
    //     setErr("Please enter amount or percentage");
    //   }
    // }
    // else
    // dispatch({ type: "SIG" });
    // setthumb(true);
    // setSig(false);
    if (signature === "" && veri) {
      setErr("Please Upload Signature");
      setShowError(true);
    } else if (
      thumbLeft.length === 0 &&
      thumbRight.length === 0 &&
      indexLeft.length === 0 &&
      indexRight.length === 0 &&
      !veri
    ) {
      setErr("Please Upload FingerPrint");
      setShowError(true);
    } else if (
      (icurrent <= 5 && dash === "/Dashboard/BeneficiaryQuestions") ||
      (icurrent === 14 && dash === "/") ||
      dash === "/EDashboard/BeneficiaryQuestions" ||
      dash === "/Dashboard/BeneficiaryQuestions" ||
      dash === "/superAdminDashboard/BeneficiaryQuestions" ||
      dash === "/AdminDashboard/BeneficiaryQuestions"
    ) {
      if (word === "") {
        setShowError(true);
        setErr("Please enter percentage");
      } else {
        let body = {};

        if (signature && veri) {
          body.signature = signature;
        }
        if (thumbLeft.length !== 0 && !veri) {
          body.leftThumbs = thumbLeft;
        }
        if (thumbRight.length !== 0 && !veri) {
          body.rightThumbs = thumbRight;
        }
        if (indexLeft.length !== 0 && !veri) {
          body.leftIndices = indexLeft;
        }
        if (indexRight.length !== 0 && !veri) {
          body.rightIndices = indexRight;
        }
        if (icurrent <= 5) {
          if (dash === "/Dashboard/BeneficiaryQuestions") {
            if (value !== "" && veri) {
              if (value === "HTML") {
                body.amount = word;
              } else if (value === "CSS") {
                body.percentage = word;
              }
            }
            body.programId = penId;
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=${benId}`,
                body,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((data) => {
                console.log(data, "change");
                if (dash === "/Dashboard/BeneficiaryQuestions") {
                  dispatch({ type: "SET", num: 1 });
                  dispatch({ type: "BISET", num: 0 });
                  console.log("called");
                } else {
                  if (!gal && veri) {
                    dispatch({ type: "FIG" });
                    setthumb(true);
                  } else if (gal || !veri) {
                    dispatch({ type: "POP" });
                    dispatch({ type: "IINCREMENT" });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
                if (
                  err.response.data.message ===
                  `"percentage" must be less than or equal to 100`
                ) {
                  setShowError(true);
                  setErr("Percentage must be less than or equal to 100");
                } 
               else if (
                  err.response.data.message ===
                  `"percentage" must be a number`
                ) {
                  setShowError(true);
                  setErr("Percentage is Required.");
                } 
                else {
                  setShowError(true);
                  setErr(err.response.data.message);
                }
              });
          } else if (
            dash === "/EDashboard/BeneficiaryQuestions" ||
            dash === "/superAdminDashboard/BeneficiaryQuestions" ||
            dash === "/AdminDashboard/BeneficiaryQuestions"
          ) {
            if (value !== "" && veri) {
              if (value === "HTML") {
                body.amount = word;
              } else if (value === "CSS") {
                body.percentage = word;
              }
            }
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/add-by-staff?beneficiaryUserId=${benId}&programId=${penId}`,
                { beneficiaryInfo: body },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then(() => {
                dispatch({ type: "SET", num: 1 });
                dispatch({ type: "BISET", num: 0 });
                dispatch({ type: "POP" });
              })
              .catch((err) => {
                if (
                  err.response.data.message ===
                  `"percentage" must be less than or equal to 100`
                ) {
                  setShowError(true);
                  setErr("Percentage must be less than or equal to 100");
                } 
                else if (
                   err.response.data.message ===
                   `"percentage" must be a number`
                 ) {
                   setShowError(true);
                   setErr("Percentage is Required.");
                 } else {
                  setShowError(true);
                  setErr(err.response.data.message);
                }
              });
          } else {
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info`,
                body,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((data) => {
                if (!gal && veri) {
                  dispatch({ type: "FIG" });
                  setthumb(true);
                } else if (gal || !veri) {
                  dispatch({ type: "POP" });
                  dispatch({ type: "IINCREMENT" });
                }
                dispatch({ type: "BISET", num: 0 });
                dispatch({ type: "data3Set", num: data });
              })
              .catch((err) => {
                if (
                  err.response.data.message ===
                  `"percentage" must be less than or equal to 100`
                ) {
                  setShowError(true);
                  setErr("Percentage must be less than or equal to 100");
                } 
                else if (
                   err.response.data.message ===
                   `"percentage" must be a number`
                 ) {
                   setShowError(true);
                   setErr("Percentage is Required.");
                 } else {
                  setShowError(true);
                  setErr(err.response.data.message);
                }
              });
          }
        } else {
          if (value !== "" && veri) {
            if (value === "HTML") {
              body.amount = word;
            } else if (value === "CSS") {
              body.percentage = word;
            }
          }
          if (
            dash === "/EDashboard/BeneficiaryQuestions" ||
            dash === "/superAdminDashboard/BeneficiaryQuestions" ||
            dash === "/AdminDashboard/BeneficiaryQuestions"
          ) {
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/add-by-staff?beneficiaryUserId=${benId}&programId=${penId}`,
                { beneficiaryInfo: body },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then(() => {
                dispatch({ type: "SET", num: 1 });
                dispatch({ type: "BISET", num: 0 });
                dispatch({ type: "POP" });
              })
              .catch((err) => {
                if (
                  err.response.data.message ===
                  `"percentage" must be less than or equal to 100`
                ) {
                  setShowError(true);
                  setErr("Percentage must be less than or equal to 100");
                }
                else if (
                   err.response.data.message ===
                   `"percentage" must be a number`
                 ) {
                   setShowError(true);
                   setErr("Percentage is Required.");
                 }  else {
                  setShowError(true);
                  setErr(err.response.data.message);
                }
              });
          } else {
            body.programId = penId;
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=${benId}`,
                body,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then(() => {
                if (dash === "/Dashboard/BeneficiaryQuestions") {
                  dispatch({ type: "SET", num: 1 });
                  dispatch({ type: "BISET", num: 0 });
                } else {
                  if (!gal && veri) {
                    dispatch({ type: "FIG" });
                    setthumb(true);
                  } else if (gal || !veri) {
                    dispatch({ type: "POP" });
                    dispatch({ type: "IINCREMENT" });
                  }
                }
              })
              .catch((err) => {
                if (
                  err.response.data.message ===
                  `"percentage" must be less than or equal to 100`
                ) {
                  setShowError(true);
                  setErr("Percentage must be less than or equal to 100");
                }
                else if (
                   err.response.data.message ===
                   `"percentage" must be a number`
                 ) {
                   setShowError(true);
                   setErr("Percentage is Required.");
                 }  else {
                  setShowError(true);
                  setErr(err.response.data.message);
                }
              });
          }
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=/${benId}`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "SET", num: 2 });
                dispatch({ type: "BISET", num: 0 });
              } else {
                if (!gal && veri) {
                  dispatch({ type: "FIG" });
                  setthumb(true);
                } else if (gal || !veri) {
                  dispatch({ type: "POP" });
                  dispatch({ type: "IINCREMENT" });
                }
              }
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                `"percentage" must be less than or equal to 100`
              ) {
                setShowError(true);
                setErr("Percentage must be less than or equal to 100");
              } 
              else if (
                 err.response.data.message ===
                 `"percentage" must be a number`
               ) {
                 setShowError(true);
                 setErr("Percentage is Required.");
               } else {
                // setShowError(true);
                // setErr(err.response.data.message);
              }
            });
        }
      }
    } else {
      let body = {};
      if (signature && veri) {
        body.signature = signature;
      }
      if (thumbLeft.length > 0 && !veri) {
        body.leftThumbs = thumbLeft;
      }
      if (thumbRight.length > 0 && !veri) {
        body.rightThumbs = thumbRight;
      }
      if (indexLeft.length > 0 && !veri) {
        body.leftIndices = indexLeft;
      }
      if (indexRight.length > 0 && !veri) {
        body.rightIndices = indexRight;
      }
      if (icurrent <= 5) {
        if (dash === "/Dashboard/BeneficiaryQuestions") {
          if (value !== "" && veri) {
            if (value === "HTML") {
              body.amount = word;
            } else if (value === "CSS") {
              body.percentage = word;
            }
          }
          body.programId = penId;
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=${benId}`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((data) => {
              console.log(data, "change");
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "SET", num: 1 });
                dispatch({ type: "BISET", num: 0 });
                console.log("called");
              } else {
                if (!gal && veri) {
                  dispatch({ type: "FIG" });
                  setthumb(true);
                } else if (gal || !veri) {
                  dispatch({ type: "POP" });
                  dispatch({ type: "IINCREMENT" });
                }
              }
            })
            .catch((err) => {
              console.log(err);
              if (
                err.response.data.message ===
                `"percentage" must be less than or equal to 100`
              ) {
                setShowError(true);
                setErr("Percentage must be less than or equal to 100");
              }
              else if (
                 err.response.data.message ===
                 `"percentage" must be a number`
               ) {
                 setShowError(true);
                 setErr("Percentage is Required.");
               }  else {
                setShowError(true);
                setErr(err.response.data.message);
              }
            });
        } else if (dash === "/EDashboard/addPensioner") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/add-by-staff?pensionerUserId=${popData.id}`,
              { pensionerInfo: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              if (!gal && veri) {
                dispatch({ type: "FIG" });
                setthumb(true);
              } else if (gal || !veri) {
                dispatch({ type: "POP" });
                dispatch({ type: "IINCREMENT" });
              }
              dispatch({ type: "data3Set", num: data });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                `"percentage" must be less than or equal to 100`
              ) {
                setShowError(true);
                setErr("Percentage must be less than or equal to 100");
              }
              else if (
                 err.response.data.message ===
                 `"percentage" must be a number`
               ) {
                 setShowError(true);
                 setErr("Percentage is Required.");
               }  else {
                setShowError(true);
                setErr(err.response.data.message);
              }
            });
        } else {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/v1/pensioner-info`, body, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((data) => {
              if (!gal && veri) {
                dispatch({ type: "FIG" });
                setthumb(true);
              } else if (gal || !veri) {
                dispatch({ type: "POP" });
                dispatch({ type: "IINCREMENT" });
              }

              dispatch({ type: "BISET", num: 0 });
              dispatch({ type: "data3Set", num: data });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                `"percentage" must be less than or equal to 100`
              ) {
                setShowError(true);
                setErr("Percentage must be less than or equal to 100");
              } 
              else if (
                 err.response.data.message ===
                 `"percentage" must be a number`
               ) {
                 setShowError(true);
                 setErr("Percentage is Required.");
               } else {
                setShowError(true);
                setErr(err.response.data.message);
              }
            });
        }
      } else {
        if (value !== "" && veri) {
          if (value === "HTML") {
            body.amount = word;
          } else if (value === "CSS") {
            body.percentage = word;
          }
        }
        if (
          dash === "/EDashboard/BeneficiaryQuestions" ||
          dash === "/superAdminDashboard/BeneficiaryQuestions" ||
          dash === "/AdminDashboard/BeneficiaryQuestions"
        ) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/add-by-staff?beneficiaryUserId=${benId}&programId=${penId}`,
              { beneficiaryInfo: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "SET", num: 1 });
              dispatch({ type: "BISET", num: 0 });
              dispatch({ type: "POP" });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                `"percentage" must be less than or equal to 100`
              ) {
                setShowError(true);
                setErr("Percentage must be less than or equal to 100");
              }
              else if (
                 err.response.data.message ===
                 `"percentage" must be a number`
               ) {
                 setShowError(true);
                 setErr("Percentage is Required.");
               }  else {
                setShowError(true);
                setErr(err.response.data.message);
              }
            });
        } else {
          body.programId = penId;
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=${benId}`,
              body,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "SET", num: 1 });
                dispatch({ type: "BISET", num: 0 });
              } else {
                if (!gal && veri) {
                  dispatch({ type: "FIG" });
                  setthumb(true);
                } else if (gal || !veri) {
                  dispatch({ type: "POP" });
                  dispatch({ type: "IINCREMENT" });
                }
              }
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                `"percentage" must be less than or equal to 100`
              ) {
                setShowError(true);
                setErr("Percentage must be less than or equal to 100");
              } 
              else if (
                 err.response.data.message ===
                 `"percentage" must be a number`
               ) {
                 setShowError(true);
                 setErr("Percentage is Required.");
               } else {
                setShowError(true);
                setErr(err.response.data.message);
              }
            });
        }
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info?beneficiaryUserId=/${benId}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (dash === "/Dashboard/BeneficiaryQuestions") {
              dispatch({ type: "SET", num: 2 });
              dispatch({ type: "BISET", num: 0 });
            } else {
              if (!gal && veri) {
                dispatch({ type: "FIG" });
                setthumb(true);
              } else if (gal || !veri) {
                dispatch({ type: "POP" });
                dispatch({ type: "IINCREMENT" });
              }
            }
          })
          .catch((err) => {
            if (
              err.response.data.message ===
              `"percentage" must be less than or equal to 100`
            ) {
              setShowError(true);
              setErr("Percentage must be less than or equal to 100");
            }
            else if (
               err.response.data.message ===
               `"percentage" must be a number`
             ) {
               setShowError(true);
               setErr("Percentage is Required.");
             }  else {
              // setShowError(true);
              // setErr(err.response.data.message);
            }
          });
      }
    }
  };

  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const changeHandler = (e) => {
    setWord(e.target.value);
  };
  const imageHandler = (setState, setUrl, load) => async (e) => {
    setPop(false);
    setSigD(false);
    load(true);
    file = e.target.files[0];
    console.log("Fileee", file);
    if (file === undefined) {
      setIsLoading(false);
      setIsLoading1(false);
      setIsLoading2(false);
      setIsLoading3(false);
      setIsLoading4(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      console.log("base", base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          setGal(true);
          load(false);
        })
        .catch(() => {});
    }
  };
  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setSignature(data.data.url);
      })
      .catch(() => {});
  };
  const imageHandlerFig = (file) => async () => {
    setIsLoading(true);
    switch (currentFinger) {
      case "thumbLeft":
        // setThumbLeft((prev) => [...prev, data.data.url]);
        setThumbLeftD((prev) => [...prev, figA]);
        break;
      case "thumbRight":
        // setThumbRight((prev) => [...prev, data.data.url]);
        setThumbRightD((prev) => [...prev, figA]);
        break;
      case "indexLeft":
        // setIndexLeft((prev) => [...prev, data.data.url]);
        setIndexLeftD((prev) => [...prev, figA]);
        break;
      case "indexRight":
        // setIndexRight((prev) => [...prev, data.data.url]);
        setIndexRightD((prev) => [...prev, figA]);
        break;
      default:
        break;
    }
    console.log("FingerprintCapture");
    console.log("Testing Fingerprint A", thumbLeft);
    console.log("Testing Fingerprint B", thumbLeft);
    console.log("Testing Fingerprint C", thumbLeft);
    console.log("Testing Fingerprint D", thumbLeft);
    // file = e.target.files[0];
    const base64 = await convertToBase64(file);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        switch (currentFinger) {
          case "thumbLeft":
            setThumbLeft((prev) => [...prev, data.data.url]);
            // setThumbLeftD((prev) => [...prev, figA]);
            break;
          case "thumbRight":
            setThumbRight((prev) => [...prev, data.data.url]);
            // setThumbRightD((prev) => [...prev, figA]);
            break;
          case "indexLeft":
            setIndexLeft((prev) => [...prev, data.data.url]);
            // setIndexLeftD((prev) => [...prev, figA]);
            break;
          case "indexRight":
            setIndexRight((prev) => [...prev, data.data.url]);
            // setIndexRightD((prev) => [...prev, figA]);
            break;
          default:
            break;
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    console.log("ThumbL", thumbLeft);
    console.log("ThumbR", thumbRight);
    console.log("IndexL", indexLeft);
    console.log("IndexR", indexRight);
  }, [thumbLeft, thumbRight, indexLeft, indexRight]);

  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }
  function ConfirmFig() {
    var json = { action: "14" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("FingerprintResponse", response);
        const base64Str = response?.data?.FigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );

        imageHandlerFig(file)();
      })
      .catch(function () {});
  }
  function ConfirmSig() {
    setPop(false);
    setSigD(false);
    var json = { action: "14" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        const base64Str = response?.data?.FigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );

        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplay("");
      })
      .catch(function () {});
  }
  const deleteImage = (index, val) => {
    switch (val) {
      case "thumbLeft":
        setThumbLeft((prev) => prev.filter((_, i) => i !== index));
        setThumbLeftD((prev) => prev.filter((_, i) => i !== index));
        break;
      case "thumbRight":
        setThumbRight((prev) => prev.filter((_, i) => i !== index));
        setThumbRightD((prev) => prev.filter((_, i) => i !== index));
        break;
      case "leftIndex":
        setIndexLeft((prev) => prev.filter((_, i) => i !== index));
        setIndexLeftD((prev) => prev.filter((_, i) => i !== index));
        break;
      case "rightIndex":
        setIndexRight((prev) => prev.filter((_, i) => i !== index));
        setIndexRightD((prev) => prev.filter((_, i) => i !== index));
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <form
        className="flex items-center justify-center flex-col"
        action=""
        onSubmit={nextHandler}
      >
        <h2
          className={`
             text-[0.9rem]
             text-center mb-6 text-[#F25A29] font-semibold `}
        >
          Please upload at least one, Signature or one Fingerprint
        </h2>
        <div className="sm:w-full flex flex-col items-center justify-center">
          <div className="w-full flex items-center justify-center gap-12">
            {veri && (
              <div
                onClick={() => {
                  setSig(true);
                  setthumb(false);
                }}
                className={`w-[4rem] h-[4rem] ${
                  veri ? "bg-primary" : "bg-white"
                } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
              >
                <img src={veri ? SignatureWhite : SignatureBlack} alt="" />
              </div>
            )}
            {!veri && (
              <div
                onClick={() => {
                  setSig(false);
                  setthumb(true);
                }}
                className={`w-[4rem] h-[4rem] ${
                  !veri ? "bg-primary" : "bg-white"
                } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
              >
                <img src={!veri ? fingerprintWhite : fingerprintWhite} alt="" />
              </div>
            )}
          </div>
          {gal && veri ? (
            <h2
              className={`
             text-[0.9rem]
             text-center mb-2 mt-2 text-[#F25A29] font-semibold `}
            >
              Uploaded from gallery
            </h2>
          ) : (
            ""
          )}
          {veri && (
            <div
              className={`border-dashed bg-white mt-4 border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-[18rem] h-[13rem] relative`}
            >
              {display ? (
                <>
                  <img src={display} alt="Uploaded" className="w-full h-full" />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setSignature("");
                      setDisplay("");
                      setGal(false);
                    }}
                  >
                    <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                  </button>
                </>
              ) : (
                <div className="w-[80%] flex flex-col gap-1 flex items-center justify-center">
                  {/* <UploadOutlined className="text-5xl text-primary cursor-pointer " /> */}
                  <div className="flex items-center justify-center">
                    <label
                      // htmlFor="file-upload"
                      className="text-5xl text-primary cursor-pointer"
                    >
                      <UploadOutlined />
                    </label>
                    {/* <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={imageHandler(
                        setDisplay,
                        setSignature,
                        setIsLoading
                      )}
                    /> */}
                  </div>
                  <p>Upload Signature or Thumbprint Here</p>
                  <Button
                    onClick={() => {
                      setPop(true);
                    }}
                    className="mt-4 w-[80%] cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg"
                  >
                    Upload
                  </Button>
                </div>
              )}
            </div>
          )}
          {sigD && veri && (
            <div
              className="flex gap-5 sm:w-[23rem] w-[18rem]"
              style={{
                padding: "10px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              <button
                type="button"
                onClick={() => {
                  GetWhitSigPic();
                }}
                className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
              >
                Open Pad
              </button>
              <button
                type="button"
                onClick={() => {
                  ConfirmSign();
                }}
                className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
              >
                Get Sign
              </button>
              <button
                type="button"
                onClick={() => {
                  ReSign();
                }}
                className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
              >
                Reset
              </button>
            </div>
          )}
          {sigD && veri && (
            <div
              className="flex gap-5 sm:w-[23rem] w-[18rem]"
              style={{
                padding: "10px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              <p
                onClick={downloadFile}
                className="text-primary hover:underline cursor-pointer font-semibold"
              >
                Download SDK
              </p>
            </div>
          )}
          {!veri && (
            <div className="mt-4 flex flex-col items-center justify-center">
              <div className="sm:w-[23rem] w-[18rem]  mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
                <div className="w-full h-[2.5rem] flex items-center justify-between">
                  <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                    <div className="flex gap-1 items-center">
                      <img src={leftT} alt="" />
                      <p>Left Thumb</p>
                    </div>
                    {!thumbLOpen && (
                      <RightOutlined
                        onClick={() => {
                          setThumbLOpen(true);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                    {thumbLOpen && (
                      <DownOutlined
                        onClick={() => {
                          setThumbLOpen(false);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                  </div>
                  <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                    <div className="flex items-center justify-center flex-col">
                      <p className="font-bold text-[11px]">
                        {thumbLeftD.length}/4
                      </p>
                      <p className="font-bold text-[11px]">uploaded</p>
                    </div>

                    <label className="">
                      <PlusCircleFilled
                        onClick={() => {
                          setCurrentFinger("thumbLeft");
                          ConfirmFig();
                        }}
                        className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                          thumbLeft.length === 4
                            ? "pointer-events-none opacity-50"
                            : ""
                        }`}
                      />
                      {/* <input
                        disabled={thumbLeft.length === 5}
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={imageHandlerFig()}
                      /> */}
                    </label>
                  </div>
                </div>
                {thumbLOpen && (
                  <div className="w-[70%] flex flex-wrap gap-2">
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbLeftD.length > 0 ? thumbLeftD[0] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbLeftD.length > 0 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(0, "thumbLeft")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbLeftD.length > 1 ? thumbLeftD[1] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbLeftD.length > 1 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(1, "thumbLeft")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbLeftD.length > 2 ? thumbLeftD[2] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbLeftD.length > 2 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(2, "thumbLeft")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbLeftD.length > 3 ? thumbLeftD[3] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbLeftD.length > 3 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(3, "thumbLeft")}
                      />
                    </div>
                    {/* <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbLeftD.length > 4 ? thumbLeftD[4] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbLeftD.length > 4 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(4, "thumbLeft")}
                      />
                    </div> */}
                  </div>
                )}
              </div>
              <div className="sm:w-[23rem] w-[18rem] mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
                <div className="w-full h-[2.5rem] flex items-center justify-between">
                  <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                    <div className="flex gap-1 items-center">
                      <img src={rightT} alt="" />
                      <p>Right Thumb</p>
                    </div>
                    {!thumbROpen && (
                      <RightOutlined
                        onClick={() => {
                          setThumbROpen(true);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                    {thumbROpen && (
                      <DownOutlined
                        onClick={() => {
                          setThumbROpen(false);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                  </div>
                  <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                    <div className="flex items-center justify-center flex-col">
                      <p className="font-bold text-[11px]">
                        {thumbRightD.length}/4
                      </p>
                      <p className="font-bold text-[11px]">uploaded</p>
                    </div>

                    <label className="">
                      <PlusCircleFilled
                        onClick={() => {
                          setCurrentFinger("thumbRight");
                          ConfirmFig();
                        }}
                        className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                          thumbRight.length === 4
                            ? "pointer-events-none opacity-50"
                            : ""
                        }`}
                      />
                      {/* <input
                        disabled={thumbRight.length === 5}
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={imageHandlerFig()}
                      /> */}
                    </label>
                  </div>
                </div>
                {thumbROpen && (
                  <div className="w-[70%] flex flex-wrap gap-2">
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbRightD.length > 0 ? thumbRightD[0] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbRightD.length > 0 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(0, "thumbRight")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbRightD.length > 1 ? thumbRightD[1] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbRightD.length > 1 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(1, "thumbRight")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbRightD.length > 2 ? thumbRightD[2] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbRightD.length > 2 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(2, "thumbRight")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbRightD.length > 3 ? thumbRightD[3] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbRightD.length > 3 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(3, "thumbRight")}
                      />
                    </div>
                    {/* <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={thumbRightD.length > 4 ? thumbRightD[4] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          thumbRightD.length > 4 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(4, "thumbRight")}
                      />
                    </div> */}
                  </div>
                )}
              </div>
              <div className="sm:w-[23rem] w-[18rem] mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
                <div className="w-full h-[2.5rem] flex items-center justify-between">
                  <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                    <div className="flex gap-1 items-center">
                      <img src={leftI} alt="" />
                      <p>Left Index</p>
                    </div>
                    {!indexLOpen && (
                      <RightOutlined
                        onClick={() => {
                          setIndexLOpen(true);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                    {indexLOpen && (
                      <DownOutlined
                        onClick={() => {
                          setIndexLOpen(false);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                  </div>
                  <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                    <div className="flex items-center justify-center flex-col">
                      <p className="font-bold text-[11px]">
                        {indexLeftD.length}/4
                      </p>
                      <p className="font-bold text-[11px]">uploaded</p>
                    </div>

                    <label className="">
                      <PlusCircleFilled
                        onClick={() => {
                          setCurrentFinger("indexLeft");
                          ConfirmFig();
                        }}
                        className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                          indexLeft.length === 4
                            ? "pointer-events-none opacity-50"
                            : ""
                        }`}
                      />
                      {/* <input
                        disabled={indexLeft.length === 5}
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={imageHandlerFig()}
                      /> */}
                    </label>
                  </div>
                </div>
                {indexLOpen && (
                  <div className="w-[70%] flex flex-wrap gap-2">
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexLeftD.length > 0 ? indexLeftD[0] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexLeftD.length > 0 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(0, "leftIndex")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexLeftD.length > 1 ? indexLeftD[1] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexLeftD.length > 1 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(1, "leftIndex")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexLeftD.length > 2 ? indexLeftD[2] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexLeftD.length > 2 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(2, "leftIndex")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexLeftD.length > 3 ? indexLeftD[3] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexLeftD.length > 3 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(3, "leftIndex")}
                      />
                    </div>
                    {/* <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexLeftD.length > 4 ? indexLeftD[4] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexLeftD.length > 4 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(4, "leftIndex")}
                      />
                    </div> */}
                  </div>
                )}
              </div>
              <div className="sm:w-[23rem] w-[18rem] mt-2 p-2 h-auto bg-gray-300 rounded-lg flex flex-col gap-2">
                <div className="w-full h-[2.5rem] flex items-center justify-between">
                  <div className="w-[70%] h-[2.5rem] rounded-lg bg-white flex items-center p-2 justify-between">
                    <div className="flex gap-1 items-center">
                      <img src={rightI} alt="" />
                      <p>Right Index</p>
                    </div>
                    {!indexROpen && (
                      <RightOutlined
                        onClick={() => {
                          setIndexROpen(true);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                    {indexROpen && (
                      <DownOutlined
                        onClick={() => {
                          setIndexROpen(false);
                        }}
                        className="font-bold cursor-pointer"
                      />
                    )}
                  </div>
                  <div className="w-[26%] h-[2.5rem] flex items-center justify-between">
                    <div className="flex items-center justify-center flex-col">
                      <p className="font-bold text-[11px]">
                        {indexRightD.length}/4
                      </p>
                      <p className="font-bold text-[11px]">uploaded</p>
                    </div>

                    <label className="">
                      <PlusCircleFilled
                        onClick={() => {
                          setCurrentFinger("indexRight");
                          ConfirmFig();
                        }}
                        className={`text-primary cursor-pointer text-2xl flex items-center justify-center ${
                          indexRight.length === 4
                            ? "pointer-events-none opacity-50"
                            : ""
                        }`}
                      />
                      {/* <input
                        disabled={indexRight.length === 5}
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={imageHandlerFig()}
                      /> */}
                    </label>
                  </div>
                </div>
                {indexROpen && (
                  <div className="w-[70%] flex flex-wrap gap-2">
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexRightD.length > 0 ? indexRightD[0] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexRightD.length > 0 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(0, "rightIndex")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexRightD.length > 1 ? indexRightD[1] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexRightD.length > 1 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(1, "rightIndex")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexRightD.length > 2 ? indexRightD[2] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexRightD.length > 2 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(2, "rightIndex")}
                      />
                    </div>
                    <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexRightD.length > 3 ? indexRightD[3] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexRightD.length > 3 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(3, "rightIndex")}
                      />
                    </div>
                    {/* <div className="relative w-[3rem] h-[3rem]">
                      <img
                        src={indexRightD.length > 4 ? indexRightD[4] : Tprint}
                        alt=""
                        className="w-full h-full border bg-white p-1 rounded-lg"
                      />
                      <DeleteFilled
                        className={`${
                          indexRightD.length > 4 ? "" : "hidden"
                        } absolute top-0 right-0 text-red-500 cursor-pointer`}
                        onClick={() => deleteImage(4, "rightIndex")}
                      />
                    </div> */}
                  </div>
                )}
              </div>
              {/* <button
                type="button"
                onClick={() => {
                  ConfirmFig();
                }}
                className="border p-2 bg-primary mt-5 w-[33%] rounded-md font-semibold text-white"
              >
                Get Fingerprint
              </button> */}
            </div>
          )}
        </div>
        {veri && (
          <div className="flex justify-center items-center mt-2">
            {/* <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]"> */}
            <div>
              <div
                className={`flex flex-col sm:w-[23rem] w-[18rem] ${
                  icurrent <= 6 &&
                  dash !== "/Dashboard/BeneficiaryQuestions" &&
                  dash !== "/EDashboard/BeneficiaryQuestions" &&
                  dash !== "/superAdminDashboard/BeneficiaryQuestions" &&
                  dash !== "/AdminDashboard/BeneficiaryQuestions"
                    ? "hidden"
                    : ""
                }
                `}
              >
                <label
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  Payment Amount
                </label>
                <div className="mb-4 mt-2">
                  {/* <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                    onChange={checkHandler}
                  ></input>
                  <label htmlFor="html" className="mr-8 ml-1 text-[0.9rem]">
                    Amount
                  </label> */}
                  <input
                    type="radio"
                    // id="css"
                    name="fav_language"
                    value="CSS"
                    onChange={checkHandler}
                  ></input>
                  <label htmlFor="css" className=" ml-1 text-[0.9rem]">
                    Percentage
                  </label>
                </div>
                <input
                  className={`lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                  type="text"
                  name="input2"
                  onChange={changeHandler}
                  placeholder={value === "HTML" ? "$" : "% Percentage"}
                  id="css"
                />
              </div>
              {/* </div> */}
              {/* <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Signature
              </h2>
              <label
                htmlFor="special-input"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Signature</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay, setSignature, setIsLoading)}
                />
              </label>
              <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay("");
                    setSignature("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input");
                    wow.value = "";
                  }}
                >
                  <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Thumb Print Left
              </h2>
              <label
                htmlFor="special-input1"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input1"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay1,
                    setThumbLeft,
                    setIsLoading1
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay1("");
                    setThumbLeft("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input1");
                    wow.value = "";
                  }}
                >
                  <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow1 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow1(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Thumb Print Right
              </h2>
              <label
                htmlFor="special-input2"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input2"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay2,
                    setThumbRight,
                    setIsLoading2
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow2(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display2}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay2("");
                    setThumbRight("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input2");
                    wow.value = "";
                  }}
                >
                  <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                </div>
              </div>
            </div>
            <div
              className={`${
                iShow2 ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow2(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={display2} alt="" />
              </div>
            </div>
            <div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Index Print Left
              </h2>
              <label
                htmlFor="special-input3"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input3"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay3,
                    setIndexLeft,
                    setIsLoading3
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display3 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow3(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display3}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay3("");
                    setIndexLeft("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input3");
                    wow.value = "";
                  }}
                >
                  <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow3 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow3(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display3} alt="" />
                </div>
              </div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Index Print Right
              </h2>
              <label
                htmlFor="special-input4"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input4"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay4,
                    setIndexRight,
                    setIsLoading4
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display4 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow4(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display4}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 cursor-pointer bg-white"
                  onClick={() => {
                    setDisplay4("");
                    setIndexRight("");
                    setIsLoading(false);
                    setIsLoading1(false);
                    setIsLoading2(false);
                    setIsLoading3(false);
                    setIsLoading4(false);
                    const wow = document.getElementById("special-input4");
                    wow.value = "";
                  }}
                >
                  <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                </div>
              </div>
              <div
                className={`${
                  iShow4 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow4(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display4} alt="" />
                </div>
              </div>
            </div> */}
            </div>
          </div>
        )}
        <div
          className={`lg:mt-[3rem] flex mt-6 items-center sm:justify-end justify-center sm:w-[85%]`}
        >
          <button
            type="submit"
            className={`${
              isLoading1 || isLoading || isLoading2 || isLoading3 || isLoading4
                ? "pointer-events-none"
                : ""
            } lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          verify ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setVerify(false)}
          >
            <img src={tick} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            Please enter at least one, Signature or one fingerprint
          </p>

          <button
            type="submit"
            onClick={() => setVerify(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showError ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowError(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowError(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[80%] flex items-center justify-between mt-10 mb-10">
            <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            />
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={imageHandler(setDisplay, setSignature, setIsLoading)}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FifthR;
