
import { useDispatch, useSelector } from "react-redux";

import IncomingReqTable from "./incomigReqTable";
import OutgoingReqTable from "./outgoingReqTable";

const Employee = () => {
 
  const dispatch = useDispatch();
  const transactionType = useSelector((state) => state.transactionType);


  const Tabs = [
    {
      key: "program",
      label: "Incoming Requests",
      component: <IncomingReqTable />,
    },
    {
      key: "general",
      label: "Outgoing requests",
      component: <OutgoingReqTable />,
    },
  ];



  return (
    <div className="p-2">
      <div className="sm:px-8 mt-6 sm:mt-5">
        <div className="grid grid-cols-2 gap-0 ">
          {Tabs.map((tab) => (
            <button
            className={`${
                tab.key === transactionType
                  ? "border-primary bg-primary font-semibold text-white"
                  : "text-[#B7B7B7] bg-[#ECECEC] font-semibold"
              }  p-3 rounded text-[0.95rem] shadow-md flex items-center justify-center`}
              onClick={() =>
                dispatch({ type: "TRANSACTION_TYPE", num: tab.key })
              }
            >
              {tab.label}
            </button>
          ))}
        </div>{" "}
        <div className="mt-6">
          {Tabs.find((tab) => tab.key === transactionType)?.component}
        </div>
      </div>
    </div>
  );
};

export default Employee;
