import { useLocation } from "react-router-dom";
import { Checkbox } from "antd";

const Nationality = () => {
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div>
      <h2 className="mb-8 text-[1.5rem] font-bold">Nationality</h2>
      <h2 className={`mb-4 text-[1.1rem]  font-semibold`}>
        Are you residing in The Country?
      </h2>
      <div className="flex flex-col ml-5 mb-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Yes
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          No
        </Checkbox>
      </div>
      <h2 className={`mb-4 text-[1.1rem] font-semibold`}>Guyanese by?</h2>
      <div className="flex flex-col ml-5">
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Birth
        </Checkbox>
        <Checkbox
          className={`${
            dash === "/Dashboard/application" ? "checkk1" : "checkk"
          }`}
        >
          Naturalization
        </Checkbox>
      </div>
    </div>
  );
};

export default Nationality;
