import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import thumb from "../../../../../imgs/Thumb.png";
import { useNavigate } from "react-router-dom";
import Ewallett from "../../../../../imgs/eWallet3.png";
import logo from "../../../../../imgs/mhsss_Accounting-logo.png";
import { DeleteFilled } from "@ant-design/icons";
const Main = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const totaPayment = useSelector((state) => state.totalPayment);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState(0);
  const [cop, setCop] = useState(0);
  const [cur, setCur] = useState(0);
  const [details, setDetails] = useState([
    {
      currency: "",
      copies: "",
    },
  ]);
  const handleAddDetail = () => {
    setTotal(total + cur * cop);
    setDetails([...details, { currency: "", copies: "" }]);
    setCur(0)
    setCop(0)
  };
  const ewallets = [
    {
      image: Ewallett,
      phone: "+1234567890",
      email: "user1@example.com",
    },
    {
      image: Ewallett,
      phone: "+1987654321",
      email: "user2@example.com",
    },
  ];
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/MHSSS/ewalletcashPay/verify");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);
  const handleDetailChange = (index, field, value) => {
    if (field === "currency") setCur(value);
    else if (field === "copies") setCop(value);
    const updatedDetails = [...details];
    updatedDetails[index][field] = value;
    setDetails(updatedDetails);
    console.log(updatedDetails);
    console.log(total);
  };
  const handleRemoveDetail = (indexToRemove) => {
    setTotal(
      total - details[indexToRemove].currency * details[indexToRemove].copies
    );
    setDetails(details.filter((index) => index !== indexToRemove));
  };
  return (
    <div className="flex flex-col w-full overflow-hidden min-h-[82vh] p-4 relative rounded-md bg-[#FFFFFFE5]">
      <div className="p-2 mt-2 border-b pb-4">
        <h1 className="font-semibold">
          Total Amount to be Paid{" "}
          <span className="ml-4 font-bold text-primary text-[16px]">{`$${totaPayment}`}</span>
        </h1>
      </div>
      <div className="w-full flex flex-col gap-4 p-2 mt-5">
        <div className="w-full items-center flex gap-2">
          {" "}
          <h1 className="font-semibold">Enter Cash Amount</h1>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-[10rem] h-[2rem] pl-4 pr-4 rounded-full bg-gray-200 p-2"
          />
          <span className="font-semibold">$</span>
        </div>
        <div className="flex w-[70%] flex-col rounded-md bg-[#FFFFFFE5]">
          {details.map((detail, index) => (
            <div
              key={index}
              className="flex gap-x-6 p-4 w-full border-t  cursor-pointer "
            >
              <div className="flex items-center gap-x-4">
                <h1 className="font-semibold">Select currency</h1>
                <select
                  value={detail.currency}
                  onChange={(e) =>
                    handleDetailChange(index, "currency", e.target.value)
                  }
                  className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-gray-200 "
                >
                  <option value="" selected hidden>Currency</option>
                  <option value="50">$50</option>
                  <option value="100">$100</option>
                  <option value="500">$500</option>
                  <option value="1000">$1000</option>
                  <option value="5000">$5000</option>
                </select>
              </div>
              <div className="flex items-center gap-x-4">
                <h1 className="font-semibold ">Number of Copies</h1>{" "}
                <input
                  placeholder="Copies"
                  value={detail.copies}
                  onChange={(e) =>
                    handleDetailChange(index, "copies", e.target.value)
                  }
                  className="w-[6rem] h-[2rem] pl-4 pr-4 rounded-lg bg-[#D9EFED] p-2 flex items-center justify-center text-center"
                />
              </div>
              {index === details.length - 1 && (
                <div
                  className="flex items-center w-[3rem] justify-center ml-12 font-semibold text-primary rounded-lg cursor-pointer"
                  onClick={handleAddDetail}
                >
                  Add
                </div>
              )}
              {index < details.length - 1 && (
                <div
                  className="flex items-center w-[3rem] justify-center ml-12 font-semibold text-primary rounded-lg cursor-pointer"
                  onClick={() => handleRemoveDetail(index)}
                >
                  <DeleteFilled />
                </div>
              )}
            </div>
          ))}
          <div className="w-full h-[3rem] bg-[#F6F4F4] rounded-b-md flex p-4 items-center justify-end">
            <h1 className="text-gray-400 font-semibold text-sm ">
              Calculated Amount
              <span className="font-semibold ml-4 text-black">${total}</span>
            </h1>
          </div>
        </div>

        <div className=" flex flex-col gap-4 pt-2">
          <p className="text-sm text-gray-300">
            Pending Amount will be paid via E-wallet(detail below)
          </p>
          <div className="flex gap-4">
            {ewallets.map((ewallet, index) => (
              <div
                key={index}
                className="flex gap-2 p-2 cursor-pointer h-[4.5rem] bg-[#D9EFED] rounded-lg"
              >
                <img
                  src={ewallet.image}
                  alt="ewallet"
                  className="h-[90%] w-auto rounded-full"
                />
                <div className="flex flex-col justify-center">
                  <p className="text-sm font-semibold">{ewallet.phone}</p>
                  <p className="text-sm font-semibold">{ewallet.email}</p>
                </div>
              </div>
            ))}
            <div onClick={()=>
            {
              dispatch({ type: "ISET", num: 1 });
            }} className="flex gap-2 p-2 w-[4.5rem] text-center cursor-pointer h-[4.5rem] flex items-center justify-center font-semibold bg-[#D9EFED] rounded-lg">
              {" "}
              Add another
            </div>
          </div>
          <textarea
            placeholder="Add note here"
            className="w-[60%] h-[5rem] p-2 rounded-lg bg-gray-200"
          ></textarea>
          <button
            onClick={() => {
              setShowConfirm(true);
            }}
            className={`w-[12rem] p-1 border-2 font-bold text-white rounded-lg border-primary bg-primary`}
          >
            Process Payment
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${totaPayment}</span> has been
            transferred to the Merchant's account
          </p>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${totaPayment}</span> to ABC bank of
            merchant?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                setShowConfirm(false);
                // setShow(true);
                router("/MHSSS/ewalletcashPay/verify");
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
