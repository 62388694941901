export const category = (state = "", action) => {
  switch (action.type) {
    case "Cat":
      return action.num;
    default:
      return state;
  }
};
export const role = (state = "", action) => {
  switch (action.type) {
    case "Role":
      return action.num;
    default:
      return state;
  }
};

export const cartData = (state = [], action) => {
  switch (action.type) {
    case "Cart":
      return action.num;
    default:
      return state;
  }
};
export const favData = (state = [], action) => {
  switch (action.type) {
    case "Fav":
      return action.num;
    default:
      return state;
  }
};
export const productData = (state = [], action) => {
  switch (action.type) {
    case "Product":
      return action.num;
    default:
      return state;
  }
};
