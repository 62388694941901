/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import notifications from "../../../imgs/notifications.png";
import activdash from "../../../imgs/dashactive.png";
import Inactivdash from "../../../imgs/dashnactive.png";
import bank from "../../../imgs/bank.png";
import across from "../../../imgs/across.png";
import inactivelogOff from "../../../imgs/logOff.png";
import sapPro from "../../../imgs/sapPro.svg";
import sapProActive from "../../../imgs/sapProActive.svg";
import reqMoney from "../../../imgs/reqMney.svg";
import reqMoneyActive from "../../../imgs/reqMoneyActive.svg";
import Inactivenotifications from "../../../imgs/Inactivenotifications.svg";
import banking from "../../../imgs/banking.svg";
import banking1 from "../../../imgs/banking1.svg";
import { MenuFoldOutlined } from "@ant-design/icons";

// import activedemography from "../../../imgs/activedemography.svg";

import sapMoney from "../../../imgs/sapMoney.png";

import account_balance from "../../../imgs/account_balance.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  // const user = dataUser?.data?.user?.roles[0];
  const menuItems = [
    {
      key: "1",
      activeIcon: activdash,
      text: "Dashboard",
      inactiveIcon: Inactivdash,
      router: "/SapMoney",
    },
    {
      key: "2",
      activeIcon: account_balance,
      text: "Wallet",
      inactiveIcon: bank,
      // router: "/SapMoney/Wallet",
    },
    {
      key: "3",
      activeIcon: reqMoneyActive,
      text: "Requests",
      inactiveIcon: reqMoney,
      router:
        user === "pensioner"
          ? "/SapMoney/Pensioner/AllRequest"
          : "/SapMoney/Merchant/AllRequest",
    },
    {
      key: "4",
      activeIcon: banking1,
      text: "Banking",
      router: "/SapMoney/Banking",
      inactiveIcon: banking,
    },
    {
      key: "5",
      activeIcon: sapProActive,
      text: "Profile",
      router: "/SapMoney/Profile",
      inactiveIcon: sapPro,
    },
    {
      key: "6",
      activeIcon: Inactivenotifications,
      text: "Notification",
      inactiveIcon: notifications,
      router: "/SapMoney/Notifications",
    },

    {
      key: "7",
      activeIcon: inactivelogOff,
      text: "Go Back",
      inactiveIcon: inactivelogOff,
      router:
        user === "pensioner"
          ? "/Dashboard"
          : user === "employee"
          ? "/EDashboard"
          : user === "admin"
          ? "/adminDashboard"
          : user === "superadmin"
          ? "/superAdminDashboard"
          : user === "merchant-owner"
          ? "/Merchant/dashboard"
          : user === "merchant-admin"
          ? "/Merchant/dashboard"
          : user === "merchant-employee"
          ? "/Merchant/dashboard"
          : "/",
    },
  ];

  return (
    <div className="bg-white rounded-lg flex space-between flex-col h-full ">
      <aside
        id="logo-sidebar"
        aria-label="Sidebar"
        className="h-full border-r border-gray-200"
      >
        <div className="px-4 py-6">
          {/* <a
            href="#"
            className="flex items-center mb-6"
            onClick={() => {
              router("/MHSSS/dashboard");
            }}
          > */}
          <div className="flex justify-end">
            <MenuFoldOutlined
              className="text-2xl cursor-pointer text-gray-600 sm:hidden"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: false })}
            />
          </div>

          <div
            className="flex items-center justify-center mb-6 cursor-pointer"
            onClick={() => {
              router("/SapMoney");
            }}
          >
            <img src={sapMoney} className="w-32" alt="logo" />
          </div>

          <ul className="space-y-1 font-medium text-primary text-[0.9rem]">
            {menuItems
              .filter(
                (menuItem) =>
                  menuItem.text !== "Requests" ||
                  user === "pensioner" ||
                  user === "merchant-owner" ||
                  user === "merchant-employee" ||
                  user === "merchant-admin"
              )
              .map((menuItem) => (
                <li
                  key={menuItem.key}
                  onClick={() => {
                    dispatch({ type: "SIDEBAR", num: menuItem.key });
                  }}
                >
                  <a
                    href="#"
                    className={`flex items-center p-3 ${
                      sidebar === menuItem.key
                        ? "bg-primary text-white rounded-lg"
                        : "text-gray-900 border-b hover:bg-gray-100"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({ type: "OPEN_SIDEBAR", num: false });
                      router(menuItem.router);
                    }}
                  >
                    <img
                      src={
                        sidebar === menuItem.key
                          ? menuItem.activeIcon
                          : menuItem.inactiveIcon
                      }
                      alt={menuItem.text}
                      className="h-6 w-6 mr-3 font-bold"
                    />
                    <span
                      className={
                        sidebar === menuItem.key ? "text-white" : "text-primary"
                      }
                    >
                      {menuItem.text}
                    </span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </aside>

      {/* Profile Section */}
      <p className="text-gray-400 mt-auto mb-6 text-center px-4 py-2">
        Version 1.0.0
      </p>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed  top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          {/* <p className="text-center mt-[3rem] mb-5">{err}</p> */}
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
