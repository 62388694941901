import axios from "axios";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PlusCircleFilled } from "@ant-design/icons";
let file;
const EmployeeInfo = ({
  setShow,
  url,
  setShowErrorModal,
  setErr,
  setRefetch,
  refetch,
}) => {
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  console.log(merchantEmployeeInfo);
  const [role, setRole] = useState("");
  const check = true;
  const token = useSelector((state) => state.token);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState();

  useEffect(() => {
    if (merchantEmployeeInfo?.data?.merchEmployeeInfo) {
      setRole(merchantEmployeeInfo?.data?.merchEmployeeInfo?.role);
      setImage(url);
      setImageUrl(merchantEmployeeInfo?.data?.merchEmployeeInfo?.signature);
    }
  }, []);

  const imageHandler = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setImage(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantIdentificationDocument`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setImageUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const nextHandler = (e) => {
    e.preventDefault();
    setShow(false);

    var body;
    if (check) {
      body = {
        role,
        signature: imageUrl,
      };
      // }
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-info/${merchantEmployeeInfo?.data?.merchEmployeeInfo?.id}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setShow(false);
          setRefetch(!refetch);
        })
        .catch((err) => {
          setShowErrorModal(true);
          setErr(err.response.data.message);
        });
    }
  };

  return (
    <>
      <div
        className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
              <h3 className="text-lg font-semibold text-gray-900 ">
                Edit Employee Info
              </h3>
              <button
                onClick={() => {
                  setShow(false);
                }}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                data-modal-toggle="select-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex justify-center">
              <div action="" className="w-[85%] mt-3" onSubmit={nextHandler}>
                <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
                  Roles/Position:
                </h2>
                <div>
                  <Select
                    placeholder="Select Role/Position"
                    optionFilterProp="children"
                    className="wow"
                    style={{
                      marginBottom: "1.2rem",
                    }}
                    value={role}
                    onChange={(val) => setRole(val)}
                    size="middle"
                    options={[
                      {
                        value: "Manager",
                        label: "Manager",
                      },
                      {
                        value: "Financial",
                        label: "Financial",
                      },
                      {
                        value: "Sales Clerk",
                        label: "Sales Clerk",
                      },
                      {
                        value: "Customer Support",
                        label: "Customer Support",
                      },

                      {
                        value: "Staff",
                        label: "Staff",
                      },
                      {
                        value: "Operations",
                        label: "Operations",
                      },
                    ]}
                  />
                </div>

                <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
                  Upload Signature:
                </h2>
                <div className="flex flex-col items-center bg-slate-50 p-7 rounded-md mb-4">
                  <label
                    htmlFor="special-input"
                    className={` cursor-pointer relative`}
                  >
                    <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                      <div>
                        <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                      </div>
                      <p className="text-[#9CA6C2] ml-2">Upload Signature</p>
                    </div>
                    <input
                      type="file"
                      className="hidden w-[100%] cursor-poniter"
                      id="special-input"
                      accept="image/png, image/gif, image/jpeg"
                      onInput={imageHandler}
                    />
                  </label>
                  <div className="flex flex-col items-center">
                    <div className="w-[15rem] h-[8rem]">
                      {image && (
                        <img
                          className={`w-[15rem] h-[8rem]`}
                          src={image}
                          alt="signature"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center pb-6">
                  <button
                    onClick={() => setShow(false)}
                    className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={nextHandler}
                    className="ml-5 cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeInfo;
