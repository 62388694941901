import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Profile from "./merchantProfile";
import back5 from "../../../imgs/back5.png";
const Prof = () => {
  const user = useSelector((state) => state.user);
  const merchType = useSelector((state) => state.merchType);
  const router = useNavigate();
  return (
    <div className="sm:p-10 pl-2 pr-2 md:pl-[5rem] md:pr-[5rem] mt-[1rem] sm:mt-[0rem] flex flex-col items-center justify-center">
      <div className="flex flex-col w-full justify-between">
        <div className="flex justify-between items-center mt-4">
          <div className="top-[2.8] cursor-pointer flex items-center gap-2 justify-center">
            <img
              onClick={() => {
                user === "employee"
                  ? router("/EDashboard/Merchant/Listing")
                  : user === "admin"
                  ? router("/adminDashboard/Merchant/Listing")
                  : user === "superadmin"
                  ? router("/superAdminDashboard/Merchant/Listing")
                  : router("/Dashboard");
              }}
              className="w-[1.7rem] w-[1.2rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
              {merchType === "merch" ? "Merchant" : "Government Agency"}
            </h2>
          </div>
          {/* 
          <button className="pointer-events-none text-white bg-primary pl-5 pr-5 pt-2 pb-2 px-6 rounded-lg sm:text-lg text-md">
            Save
          </button> */}
        </div>
      </div>
      <Profile />
    </div>
  );
};

export default Prof;
