import React from "react";
import { Collapse } from "antd";
import Profile from "./Profile";
import ContactInfo from "../Personal/ContactInfo";
const { Panel } = Collapse;

const Index = ({ data }) => {
  const callback = (key) => {
    console.log(key);
  };

  return (
    <div className="sm:p-10">
      <div className="p-4 bg-white rounded-lg border shadow-md sm:p-8   mb-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold leading-none text-gray-900 ">
            Personal Detail
          </h3>
        </div>

        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            <Panel
              header="Profile"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Profile data={data} />
            </Panel>
            <Panel
              header="Contact Info"
              key="2"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <ContactInfo company={data} />
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Index;
