import Wallet from "../components/eWallet/wallet";

const ProfPage = () => {
  return (
    <div>
      <Wallet/>
    </div>
  );
};

export default ProfPage;
