import React, { useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
const employees = [
  {
    id: 1,
    name: "John Doe",
    status: "Pensioner Verified",
    role: "admin",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 25,
  },
  {
    id: 2,
    name: "Jane Smith",
    status: "Pensioner Verified",
    role: "superadmin",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 30,
  },
  {
    id: 3,
    name: "Alice Johnson",
    status: "Pensioner Verified",
    role: "employee",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 15,
  },
  {
    id: 4,
    name: "Bob Brown",
    status: "Pensioner Verified",
    role: "admin",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 20,
  },
  {
    id: 5,
    name: "Charlie Davis",
    status: "Pensioner Verified",
    role: "employee",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 18,
  },
  {
    id: 6,
    name: "Diana Evans",
    status: "Pensioner Verified",
    role: "superadmin",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 28,
  },
  {
    id: 7,
    name: "Edward Fox",
    status: "Pensioner Verified",
    role: "admin",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 22,
  },
  {
    id: 8,
    name: "Fiona Green",
    status: "Pensioner Verified",
    role: "employee",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 12,
  },
  {
    id: 9,
    name: "George Harris",
    status: "Pensioner Verified",
    role: "superadmin",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 35,
  },
  {
    id: 10,
    name: "Hannah White",
    status: "Pensioner Verified",
    role: "employee",
    profileImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQi2Mm5P8j09P4hPKa1B-t9eIOHzHmR7IBkw&s",
    pensionerVerified: 14,
  },
];

const Employee = () => {
  const EmployeeCard = ({ employee }) => {
    return (
      <div className="bg-white p-4 rounded-lg flex items-center gap-4 cursor-pointer">
        <img
          src={employee.profileImage}
          alt={`${employee.name}'s profile`}
          className="w-16 h-16 rounded-full"
        />
        <div className="flex-1">
          <h3 className="font-semibold">{employee.name}</h3>
          <p className="text-primary capitalize font-semibold">
            {employee.role}
          </p>
        </div>
        <div className="text-right">
          <p className="font-semibold">Pensioner Verified</p>
          <p className="text-primary font-semibold">
            {employee.pensionerVerified}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Employees"}
        route={"/scheduleVerification/schedulesDetails"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[90%] w-full`}
        >
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="grid cursor-pointer sm:grid-cols-2 md:grid-cols-3 gap-4 grid-cols-1  w-full">
                {employees.map((employee) => (
                  <EmployeeCard key={employee.id} employee={employee} />
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            router("/scheduleVerification/selectProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div> */}
      {/* {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />} */}
    </div>
  );
};

export default Employee;
