import React from 'react'
import Profile from "../components/MHSSS Accounting/Profile/profileIndex"

const paymentM = () => {
  return (
    <div>
     <Profile/>
    </div>
  )
}

export default paymentM