import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import GroEmployee from "../components/GeneralRegisterOfficer/groadmin/groAdminEmployee"

const GroAdminDash = () => {
  return (
    <div>
      <Nav />
     <GroEmployee/>
     <Whatsapp/>
    </div>
  )
}

export default GroAdminDash