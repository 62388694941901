import axios from "axios";
import InfoModal from "../../../MHSSS Accounting/outstanding/PersonTransaction/Vouchers/infoModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ContactInfo from "../../../Merchant/Transactions/contactInfo";
const Main = () => {
  const location = useLocation();
  const dash = location.pathname;
  const [id, setId] = useState(location?.state?.id);
  // const id = location?.state?.id;
  const [selectedTransactionsVouchers, setSelectedTransactionsVouchers] =
    useState(location?.state?.selectedTransactionsVouchers);
  // const selectedTransactionsVouchers =
  //   location?.state?.selectedTransactionsVouchers;
  console.log("Transaction", selectedTransactionsVouchers);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [showContact, setShowContact] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const [showInfo, setShowInfo] = useState(false);
  const [voucher, setVouchers] = useState([]);
  const [content, setContent] = useState("");
  // const [showData, setShowData] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (id) {
      const api =
        user === "pensioner"
          ? "for-pensioner"
          : user === "admin" || user === "superadmin" || user === "employee"
          ? "for-mhsss"
          : "for-merchant";

      const tok =
        user === "pensioner" ||
        user === "admin" ||
        user === "superadmin" ||
        user === "employee"
          ? dataUser?.data?.tokens?.access?.token
          : dataUser?.data?.merchTokens?.access?.token;
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/${api}?merchTransactionId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${tok}`,
            },
          }
        )
        .then((data) => {
          // setShowData(true);
          setVouchers(data?.data?.rows);
          data?.data?.rows?.map((item, index) => {
            imageConverter(item?.voucherImage, index);
          });
        })
        .catch(() => {
          // Handle error
        });
    }
  }, [id]);

  useEffect(() => {
    if (dash !== "/SapMoney/Merchant/MHSSSTransaction/detail") {
      return;
    }

    const tok =
      user === "pensioner" ||
      user === "admin" ||
      user === "superadmin" ||
      user === "employee"
        ? dataUser?.data?.tokens?.access?.token
        : dataUser?.data?.merchTokens?.access?.token;

    const api =
      user === "admin" || user === "superadmin" || user === "employee"
        ? "for-mhsss"
        : user === "pensioner"
        ? "for-pensioner"
        : "for-merch";

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transaction-payments/${id}/${api}`,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        setId(data?.merchtransactionId);

        setSelectedTransactionsVouchers((prevState) => {
          const merchtransactionpayment = {
            amountPaid: data?.amountPaid,
            feePaid: data?.feePaid,
            transactionPaymentId: data?.transactionPaymentId,
            updatedAt: data?.createdAt,
          };

          return {
            ...data,
            ...prevState,
            mhsssPayStatus: data?.merchtransaction?.mhsssPayStatus,
            transactionId: data?.merchtransaction?.transactionId,
            merchtransactionpayment,
            createdAt: data?.merchtransaction?.createdAt,
          };
        });
      })
      .catch(() => {
        // Handle error
      });
  }, []);

  console.log("Vouchers", selectedTransactionsVouchers);

  const Div = ({ label, value }) => {
    return (
      <div className="flex justify-between items-center">
        <div
          className={`${
            user !== "pensioner"
              ? "text-gray-600 flex items-center text-[0.8rem]"
              : "flex items-center"
          } `}
        >
          {label}
        </div>
        <div
          className={`
          ${
            user !== "pensioner"
              ? "font-semibold flex items-center capitalize text-[0.8rem]"
              : "text-sm font-bold flex items-center capitalize"
          } `}
        >
          {value}
        </div>
      </div>
    );
  };

  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };

  const imageConverter = (url, index) => {
    const api =
      user === "pensioner" ||
      user === "admin" ||
      user === "superadmin" ||
      user === "employee"
        ? "v1/file/get-image-url?fileType=paymentRecipet"
        : "v1/file/merchat-file-presigned-url?fileType=paymentRecipet";
    const tok =
      user === "pensioner" ||
      user === "admin" ||
      user === "superadmin" ||
      user === "employee"
        ? dataUser?.data?.tokens?.access?.token
        : dataUser?.data?.merchTokens?.access?.token;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/${api}`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((data) => {
        setVouchers((prev) => {
          let newArr = [...prev];
          if (data?.data?.length > 0 && data.data[0]?.url) {
            newArr[index].url = data.data[0].url;
          } else {
            console.error("URL is undefined");
          }

          return newArr;
        });

        // setVouchers((prev) => {
        //     let newArr = [...prev];
        //     newArr[contactIndex].merchcontactpersondocs[docIndex].images[
        //       imageIndex
        //     ] = data?.data[0]?.url;
        //     return newArr;
        //   });
      })
      .catch((err) => {
        // Handle error
      });
  };

  const HR = () => {
    return <hr className="border-t-1 border-gray-300" />;
  };

  return (
    <div className="flex flex-col w-full rounded-md flex sm:px-14">
      <div className="flex flex-col w-full relative rounded-md bg-white shadow-lg gap-4 flex pt-8 pb-10 p-4 sm:px-6">
        <div className="flex  sm:flex-row flex-col">
          <div className="w-full  p-3 rounded-md bg-[#F4F8F6] ">
            <div className="w-full p-4 sm:gap-10 flex sm:flex-row flex-col h-auto rounded-md sm:px-7 gap-3">
              <div className="sm:w-[45%] w-full flex flex-col gap-3">
                <div
                  className={`${
                    selectedTransactionsVouchers?.mhsssPayStatus === "success"
                      ? ""
                      : "hidden"
                  }`}
                >
                  <Div
                    label="Payment Date :"
                    value={moment(
                      selectedTransactionsVouchers?.merchtransactionpayment
                        ?.updatedAt
                    ).format("MM/DD/YYYY hh:mm A")}
                  />
                </div>
                <HR />
                <div className="flex justify-between items-center">
                  <div
                    className={`${
                      user !== "pensioner"
                        ? "text-gray-600 flex items-center text-[0.8rem]"
                        : "text-gray-600 flex items-center"
                    } `}
                  >
                    Payment ID :
                    <p
                      className={`${
                        user !== "pensioner"
                          ? "w-[16px] h-[16px] ml-1  text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                          : "w-[16px] h-[16px] ml-1  text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                      }`}
                      onClick={() => {
                        setContent(
                          "Auto generated ID from MHSSS payment to the merchant."
                        );
                        setShowInfo(true);
                      }}
                    >
                      i
                    </p>
                  </div>
                  <div
                    className={`
          ${
            user !== "pensioner"
              ? "font-semibold flex items-center capitalize text-[0.8rem]"
              : "text-sm font-bold flex items-center capitalize"
          } `}
                  >
                    {selectedTransactionsVouchers?.merchtransactionpayment
                      ?.transactionPaymentId
                      ? selectedTransactionsVouchers?.merchtransactionpayment
                          ?.transactionPaymentId
                      : "-----"}
                  </div>
                </div>
                <HR />

                {/* <Div
                  label="Voucher Cashing Fee :"
                  value={`$${selectedTransactionsVouchers?.feePaid}`}
                /> */}
                {/* <Div
                  label="Booklet # :"
                  value={`${selectedTransactionsVouchers?.pensionbook?.bookletNo}(${selectedTransactionsVouchers?.pensionbook?.year})`}
                /> */}
                <Div
                  label="Program :"
                  value={
                    selectedTransactionsVouchers?.pensionbook?.pensionprogram
                      ?.name === "senior-citizen-Pension"
                      ? "Senior Citizen Pension"
                      : selectedTransactionsVouchers?.pensionbook
                          ?.pensionprogram?.name
                  }
                />
                <HR />
                <Div
                  label="Transaction Type :"
                  value={`${
                    voucher && voucher?.length > 0 && voucher[0]?.paymentMethod
                      ? voucher[0]?.paymentMethod
                      : ""
                  }`}
                />
                {/* <Div title="Store Name 1:" content={"Fullfilment"} />
              <Div title="Store Name 2:" content={"Yup Store"} /> */}
              </div>
              <div className="sm:w-[55%] w-full flex flex-col gap-3">
                <div
                  className={`bg-[#1BA397] text-white px-3 p-[5.2px] flex justify-between `}
                >
                  <p>Store/HQ</p>
                  <p>
                    {selectedTransactionsVouchers?.merchstore !== null
                      ? selectedTransactionsVouchers?.merchstore
                          ?.customStoreName
                      : selectedTransactionsVouchers?.merchuser?.merchcompany
                          ?.name}
                  </p>
                  <p
                    className={`${
                      selectedTransactionsVouchers?.merchstore === null
                        ? "hidden"
                        : "cursor-pointer"
                    }`}
                    onClick={() => {
                      setShowContact(true);
                      if (selectedTransactionsVouchers?.merchstore === null) {
                        setEmail(
                          selectedTransactionsVouchers?.merchheadquarter?.emails
                        );
                        setPhone(
                          selectedTransactionsVouchers?.merchheadquarter
                            ?.contacts
                        );
                      } else {
                        setEmail(
                          selectedTransactionsVouchers?.merchstore?.emails
                        );
                        setPhone(
                          selectedTransactionsVouchers?.merchstore?.phones
                        );
                      }
                    }}
                  >
                    View
                  </p>
                </div>
                <Div
                  label="Total Amount :"
                  // value={`$${selectedTransactionsVouchers?.totalAmount}`}
                  value={`$${
                    selectedTransactionsVouchers?.merchtransactionpayment
                      ?.amountPaid
                      ? selectedTransactionsVouchers?.merchtransactionpayment
                          ?.amountPaid +
                        selectedTransactionsVouchers?.merchtransactionpayment
                          ?.feePaid
                      : selectedTransactionsVouchers?.totalAmount
                      ? selectedTransactionsVouchers?.totalAmount
                      : ""
                  }`}
                />
                <HR />
                <div className="flex justify-between items-center">
                  <div
                    className={`${
                      user !== "pensioner"
                        ? "text-gray-600 flex items-center text-[0.8rem]"
                        : "text-gray-600 flex items-center"
                    } `}
                  >
                    Status :
                  </div>
                  <div
                    className={`text-sm font-bold flex items-center capitalize
                    ${
                      selectedTransactionsVouchers?.mhsssPayStatus ===
                        "success" &&
                      (user === "pensioner" ||
                        user === "merchnat-owner" ||
                        user === "merchant-employee")
                        ? "text-primary"
                        : selectedTransactionsVouchers?.mhsssPayStatus ===
                            "success" &&
                          (user === "admin" ||
                            user === "superadmin" ||
                            user === "employee")
                        ? "text-red-600"
                        : selectedTransactionsVouchers?.mhsssPayStatus ===
                          "pending"
                        ? "text-yellow-500"
                        : "text-red-500"
                    }
         `}
                  >
                    {selectedTransactionsVouchers?.mhsssPayStatus ===
                      "success" &&
                    (user === "pensioner" ||
                      user === "merchnat-owner" ||
                      user === "merchant-employee")
                      ? "Received"
                      : selectedTransactionsVouchers?.mhsssPayStatus ===
                          "success" &&
                        (user === "admin" ||
                          user === "superadmin" ||
                          user === "employee")
                      ? "Paid"
                      : selectedTransactionsVouchers?.mhsssPayStatus}
                  </div>
                </div>
                <HR />
                <Div label="Number of Vouchers :" value={voucher?.length} />
              </div>
            </div>
            <div className="bg-white  sm:p-4 sm:ml-5 sm:mr-5 p-2">
              <h1 className="text-primary font-semibold">Pensioner Profile</h1>
              <div className="w-full p-4 sm:gap-10 flex sm:flex-row flex-col h-auto rounded-md sm:px-5 gap-3">
                <div className="sm:w-[45%] flex flex-col gap-3">
                  <Div
                    label="GID :"
                    value={
                      selectedTransactionsVouchers?.user !== null
                        ? selectedTransactionsVouchers?.user?.gid
                        : "------"
                    }
                  />
                  <HR />
                  <Div
                    label="Pensioner's Name :"
                    value={
                      selectedTransactionsVouchers?.user !== null
                        ? `${
                            selectedTransactionsVouchers?.user?.person
                              ?.firstName || ""
                          } ${
                            selectedTransactionsVouchers?.user?.person
                              ?.middleName || ""
                          } ${
                            selectedTransactionsVouchers?.user?.person
                              ?.lastName || ""
                          }`.trim()
                        : "------"
                    }
                  />
                  <HR />
                  <Div
                    label="Booklet # :"
                    value={`${
                      selectedTransactionsVouchers?.pensionbook?.bookletNo
                        ? selectedTransactionsVouchers?.pensionbook?.bookletNo
                        : ""
                    }${`${
                      selectedTransactionsVouchers?.pensionbook?.year
                        ? `(${selectedTransactionsVouchers?.pensionbook?.year})`
                        : ""
                    }`}`}
                  />
                </div>
                <div className="sm:w-[55%] flex flex-col gap-3">
                  <div className="sm:hidden block">
                    <HR />
                  </div>
                  <div className="flex justify-between items-center">
                    <div
                      className={`${
                        user !== "pensioner"
                          ? "text-gray-600 flex items-center text-[0.8rem]"
                          : "text-gray-600 flex items-center"
                      } `}
                    >
                      Voucher Transaction ID :
                      <p
                        className={`${
                          user !== "pensioner"
                            ? "w-[16px] h-[16px] ml-1  text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                            : "w-[16px] h-[16px] ml-1  text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                        }`}
                        onClick={() => {
                          setContent(
                            "Auto generated transaction ID when Merchant redeemed pensioner’s vouchers."
                          );
                          setShowInfo(true);
                        }}
                      >
                        i
                      </p>
                    </div>
                    <div
                      className={`
          ${
            user !== "pensioner"
              ? "font-semibold flex items-center capitalize text-[0.8rem]"
              : "text-sm font-bold flex items-center capitalize"
          } `}
                    >
                      {selectedTransactionsVouchers?.transactionId}
                    </div>
                  </div>
                  <HR />
                  <Div
                    label="Voucher Cashing Date :"
                    value={moment(
                      selectedTransactionsVouchers?.createdAt
                    ).format("MM/DD/YYYY hh:mm A")}
                  />
                  <HR />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Vouchers */}
        {/* <div>
          <div
            className={`${
              user === "pensioner"
                ? "grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4 p-6 cursor-pointer"
                : "grid lg:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 p-6 cursor-pointer"
            }`}
          >
            {voucher?.map((data, ind) => (
              <div
                key={ind}
                className="h-auto shadow-lg rounded-lg border border-[#1BA397] bg-[#F4F8F6] p-2 px-3"
              >
                <img src={data?.url} alt="" className="w-full h-32 mt-2" />

                <div className="w-full mt-1 p-2 space-y-2">
                  <Div label={"Amount :"} value={`$${data?.amountPaid}`} />
                 
                </div>
                <div className="w-full pl-2 pr-2 ">
                  <div className="flex justify-between">
                    <div className={`text-gray-600 flex items-center`}>
                      Voucher cashing ID :{" "}
                      <p
                        className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                        onClick={() => {
                          setContent(
                            "Auto generated Unique Transaction ID for each voucher cashing."
                          );
                          setShowInfo(true);
                        }}
                      >
                        i
                      </p>
                    </div>
                    <div className={`flex items-center capitalize relative`}>
                      {data?.transactionId}
                    </div>
                  </div>
                </div>

                <div className="w-full pl-2 pr-2 mt-1">
                  <div className="flex justify-between space-y-2">
                    <div className={`text-gray-600 flex items-center`}>
                      Voucher :
                    </div>
                    <div className={`flex items-center capitalize`}>
                      {getMonthName(data?.coupon?.month)}{" "}
                      {moment(data?.coupon?.year).format("YYYY")}
                    </div>
                  </div>
                </div>
                <div className="w-full pl-2 pr-2 mt-1">
                  <div className="flex justify-between space-y-2">
                    <div className={`text-gray-600 flex items-center`}>
                      Status :
                    </div>
                    <div
                      className={`flex items-center capitalize text-primary font-semibold`}
                    >
                      REDEEMED
                    </div>
                  </div>
                </div>
               
              </div>
            ))}
          </div>
        </div> */}

        <div>
          <div
            className={`${
              user === "pensioner"
                ? "grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4 p-4 cursor-pointer"
                : "grid lg:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 p-4 cursor-pointer px-6"
            }`}
          >
            {voucher?.map((data, ind) => (
              <div
                key={ind}
                className={`shadow-lg rounded-xl border  bg-[#F4F8F6] p-4 ${
                  data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                  "accepted"
                    ? "border-[#1BA397]"
                    : "border-red-600"
                }`}
              >
                <div className="w-full mb-2">
                  <div className="flex justify-between text-[0.8rem]">
                    <div className="text-gray-600 flex items-center">
                      Voucher No:
                    </div>
                    <div className="flex items-center capitalize">
                      {getMonthName(data?.coupon?.month)}{" "}
                      {moment(data?.coupon?.year).format("YYYY")}
                    </div>
                  </div>
                </div>
                <div className="w-full mb-2">
                  <div className="flex justify-between text-[0.8rem]">
                    <div className="text-gray-600 flex items-center">
                      Voucher cashing ID:{" "}
                      <p
                        className="ml-1 w-4 h-4 text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                        onClick={() => {
                          setContent(
                            "Auto generated Unique Transaction ID for each voucher cashing."
                          );
                          setShowInfo(true);
                        }}
                      >
                        i
                      </p>
                    </div>
                    <div className="flex items-center capitalize">
                      {data?.transactionId}
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 mt-2">
                  <div className="w-3/5">
                    <img
                      src={data?.url}
                      alt=""
                      className="w-full h-20 mt-2 object-cover rounded"
                    />
                  </div>
                  <div className="w-2/5 flex flex-col justify-end items-end">
                    <div className="text-gray-600 mb-2 font-semibold text-[1rem]">
                      ${data?.amountPaid}
                    </div>
                    <p
                      className={`font-semibold ${
                        data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                        "accepted"
                          ? "text-primary"
                          : "text-red-600"
                      }`}
                    >
                      {data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                      "accepted"
                        ? "Voucher Verified"
                        : data?.merchredeemcouponstatuses[0]?.statusByMhsss ===
                          "rejected"
                        ? "Voucher Rejected"
                        : ""}
                    </p>
                  </div>
                </div>
                {data?.merchredeemcouponstatuses?.[0]?.statusByMhsss ===
                  "rejected" && (
                  <div className="mt-2">
                    <label className="block text-gray-700 mb-1">Reason</label>
                    <textarea
                      className="bg-white p-3 w-full rounded-md h-auto mb-2"
                      value={
                        data?.merchredeemcouponstatuses?.[0]?.rejectNoteByMhsss
                      }
                      disabled
                    />
                    <button className="bg-primary text-center w-full rounded-md p-2 text-white">
                      Upload New Voucher
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {showInfo && <InfoModal setShow={setShowInfo} content={content} />}
        {showContact && (
          <ContactInfo
            setShow={setShowContact}
            email={email}
            phone={phone}
            store={true}
            data={selectedTransactionsVouchers}
          />
        )}
      </div>
    </div>
  );
};

export default Main;
