import { Pagination, Popover, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import profile from "../../imgs/profile.png";
import ErrorModal from "../Merchant/Modals/errorModal";
import PageHeader from "./pageHeader/pageHeader";
import AddPensioner from "./modal/addPensioner";
import axios from "axios";
import moment from "moment";

const Paget = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.searchResult);
  const dataUser = useSelector((state) => state?.dataUser);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [prompt, setPrompt] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [noUserFound, setNoUserFound] = useState(false);
  const [gid, setGid] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [lastName, setLastName] = useState("");
  const [bookletNo, setBookletNo] = useState("");

  useEffect(() => {
    setError("");
    setTotalPages(1);
    console.log(pagewithsearch, prompt);
  }, []);

  useEffect(() => {
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_BANK_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_CHEQUE_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_EWALLET_INFO",
      num: null,
    });
  }, []);
  const columnHeaders = [
    "Ticket#",
    "Waiting Time",
    "Pensioner Name",
    "GID",
    "Gender",
    "DOB",
    "Phone",
    "Email",
    "Approved On",
    "Created On",
    "Status",
  ];

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  // const handleChange = (setState, check) => (event) => {
  //   if (check === "tt") {
  //     setPrompt(event);
  //   } else {
  //     setState(event.target.value);
  //   }
  // };
  const calculateTime = (createdAt) => {
    const now = moment();
    const duration = moment.duration(now.diff(moment(createdAt)));

    // Format the output in a compact form
    let compactTimeDifference = "";

    if (duration.days() > 0) {
      compactTimeDifference += `${duration.days()}d `;
    }
    if (duration.hours() > 0) {
      compactTimeDifference += `${duration.hours()}h `;
    }
    if (duration.minutes() > 0) {
      compactTimeDifference += `${duration.minutes()}m `;
    }

    // Add seconds if it's less than a minute ago
    if (!compactTimeDifference && duration.seconds() > 0) {
      compactTimeDifference += `${duration.seconds()}s `;
    }

    // compactTimeDifference += "ago";
    return compactTimeDifference;
  };
  return (
    <>
      <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
        <PageHeader header={true} title={"Search Result"} route={-1} />
        <div className="flex justify-center">
          <div className={`sm:w-[95%] w-full`}>
            <section className="w-full sm:pl-4 pl-2 sm:pr-4 pr-2">
              <div className="mx-auto max-w-screen-xl ">
                <div className="relative sm:rounded-lg overflow-hidden mt-5 ">
                  {searchResult?.length === 0 ? (
                    <AddPensioner />
                  ) : null}

                  <div
                    className={`${searchResult?.length === 0 ? "hidden" : ""}`}
                  >
                    <div
                      className={`overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar ${
                        searchResult?.length === 0 ? "hidden" : ""
                      }`}
                    >
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                          <tr>
                            {columnHeaders.map((columnHeader, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                              >
                                {columnHeader}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        {searchResult?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td
                                colSpan="10"
                                className="text-center text-gray-500 text-lg"
                              >
                                <p className="p-10">No data found</p>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {searchResult?.map((data, index) => (
                              <tr
                                onClick={() => {
                                  dispatch({
                                    type: "VERI_SELECTED_PENSIONER",
                                    num: { data, id: data.id },
                                  });
                                  dispatch({
                                    type: "PEN_VER_QUEUE",
                                    num:
                                      data?.verificationqueues?.length > 0
                                        ? data?.verificationqueues[0]
                                        : [],
                                  });
                                  dispatch({
                                    type: "PEN_VER_STATUS",
                                    num:
                                      data?.verificationStatus === null
                                        ? "Verification Pending"
                                        : data?.verificationStatus?.toggle,
                                  });
                                  // dispatch({
                                  //   type: "SEARCH_MODAL_COUNT",
                                  //   num: 2,
                                  // });
                                  router("/pensionerVerification/Profile");
                                }}
                                key={index}
                                className="odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-gray-100  text-md cursor-pointer"
                              >
                                <th
                                  scope="row"
                                  className="px-4 py-3 font-medium text-primary whitespace-nowrap"
                                >
                                  {data.verificationqueues.length > 0
                                    ? `${data?.verificationqueues?.[0]?.token}`
                                    : "-----"}
                                </th>
                                <td className="px-6 py-6 font-semibold text-primary whitespace-nowrap">
                                  {data.verificationqueues.length > 0
                                    ? `${calculateTime(
                                        data?.verificationqueues?.[0]?.createdAt
                                      )}`
                                    : "-----"}
                                </td>
                                <td className="px-2 py-5">
                                  <div className="flex items-center">
                                    <Avatar
                                      src={
                                        data?.person?.image !== null
                                          ? data?.person?.image
                                          : profile
                                      }
                                      className="border-2  border-primary mr-2 w-10 h-10"
                                    />
                                    <p
                                      className="mr-6 text-primary"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {data?.person?.firstName
                                        ? data.person.firstName + " "
                                        : "-----"}

                                      {data?.person?.lastName
                                        ? data.person.lastName
                                        : "-----"}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-6 py-4 text-primary whitespace-nowrap">
                                  {data.gid}
                                </td>
                                <td className="px-6 py-4  text-primary text-primary whitespace-nowrap">
                                  {data?.person?.gender
                                    ? data?.person?.gender
                                    : "-----"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {data?.person?.dateOfBirth
                                    ? moment(data?.person?.dateOfBirth).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "-----"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {data?.countryCode !== null
                                    ? `+${
                                        data?.countryCode + " " + data?.phoneNo
                                      }`
                                    : "-----"}{" "}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {data?.email ? data?.email : "-----"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {data?.status === "approved" ? (
                                    <>
                                      <p>
                                        {dayjs(data?.statusChangeDate).format(
                                          "MM/DD/YYYY "
                                        )}
                                      </p>
                                      <p>
                                        {dayjs(data?.statusChangeDate).format(
                                          " hh:mm A"
                                        )}
                                      </p>
                                    </>
                                  ) : (
                                    "-----"
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <p>
                                    {moment(data?.createdAt).format(
                                      "MM/DD/YYYY "
                                    )}
                                  </p>
                                  <p>
                                    {moment(data?.createdAt).format(" hh:mm A")}
                                  </p>
                                </td>

                                <td className="px-6 py-5">
                                  <Popover>
                                    <div
                                      className={`border-none ${
                                        data?.verificationStatus === null
                                          ? "bg-[#EF4D5C]"
                                          : "bg-primary"
                                      } inline-block whitespace-nowrap p-1 px-5 rounded-xl flex items-center justify-center text-white focus:border-none cursor-pointer capitalize`}
                                    >
                                      {data?.verificationStatus === null
                                        ? "Verification Pending"
                                        : data?.verificationStatus?.toggle}
                                      {/* {data?.status} */}
                                      {/* Verification Pending */}
                                    </div>
                                  </Popover>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <nav
                      className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
                      aria-label="Table navigation"
                    >
                      <span className="text-sm font-normal text-gray-500">
                        Showing{" "}
                        <span className="font-semibold text-gray-900 ">
                          Page {page}{" "}
                        </span>
                        of{" "}
                        <span className="font-semibold text-gray-900 ">
                          {totalPages}
                        </span>
                      </span>
                      <div className={`flex justify-end mt-7`}>
                        <Pagination
                          defaultCurrent={1}
                          total={totalPages * 10}
                          showSizeChanger={false}
                          onChange={pageHandler}
                          current={page}
                        />
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {showError && <ErrorModal err={error} setShow={setShowError} />}
    </>
  );
};

export default Paget;
