import { Pagination, Dropdown } from "antd";
import { useState } from "react";
const Paget = () => {
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  // setTotalPages(1);
  const items = [
    {
      label: "View ",
      key: "1",
    },
    {
      label: "Update",
      key: "5",
    },
    {
      label: "Delete ",
      key: "4",
    },
  ];

  const onClick = (key, item) => {
    console.log(key, item);
  };

  const columnHeaders = [
    "SR.",
    "Order",
    "Amount",
    "Earnings",
    "Status",
    "Shipment",
    "Customer",
    "Shipment Address",
    "Actions",
  ];
  const ordersData = [
    {
      Order: "12345",
      Amount: "$100",
      Earnings: "$20",
      Status: "Completed",
      Shipment: "Express",
      Customer: "John Doe",
      ShipmentA: "123 Main St, City, Country",
      Date: "2024-06-10",
    },
    {
      Order: "12346",
      Amount: "$75",
      Earnings: "$15",
      Status: "Pending",
      Shipment: "Standard",
      Customer: "Jane Smith",
      ShipmentA: "456 Elm St, Town, Country",
      Date: "2024-06-09",
    },
    {
      Order: "12347",
      Amount: "$120",
      Earnings: "$30",
      Status: "Processing",
      Shipment: "Express",
      Customer: "Michael Johnson",
      ShipmentA: "789 Oak St, Village, Country",
      Date: "2024-06-08",
    },
    {
      Order: "12348",
      Amount: "$50",
      Earnings: "$10",
      Status: "Rejected",
      Shipment: "Standard",
      Customer: "Sarah Lee",
      ShipmentA: "101 Pine St, Hamlet, Country",
      Date: "2024-06-07",
    },
    {
      Order: "12349",
      Amount: "$90",
      Earnings: "$18",
      Status: "Completed",
      Shipment: "Express",
      Customer: "David Brown",
      ShipmentA: "111 Willow St, City, Country",
      Date: "2024-06-06",
    },
    {
      Order: "12350",
      Amount: "$65",
      Earnings: "$13",
      Status: "Processing",
      Shipment: "Standard",
      Customer: "Emily Wilson",
      ShipmentA: "222 Cedar St, Town, Country",
      Date: "2024-06-05",
    },
  ];

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  return (
    <>
      <section className="w-full  bg-gray-50 pl-6 pr-6">
        <div className="mx-auto max-w-screen-xl ">
          <div className="relative sm:rounded-lg overflow-hidden">
            <div className="w-full flex sm:flex-row flex-col-reverse sm:items-center sm:justify-end mb-2">
              <div className="flex flex-col-reverse sm:items-center sm:justify-end gap-2 mt-3 sm:flex-row">
                {/* search */}
                <div className="w-full md:w-[20rem]">
                  <form className="flex items-center">
                    <label htmlFor="simple-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 "
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                        placeholder="Search Order"
                        required=""
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>
                {ordersData?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="9"
                        className="text-center text-gray-500 text-lg"
                      >
                        <p className="p-10">No data found</p>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {ordersData.map((data, index) => (
                      <tr
                        onClick={() => {}}
                        className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                      >
                        <th
                          scope="row"
                          className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                        >
                          {search
                            ? index + 1 + (pagewithsearch - 1) * 6
                            : index + 1 + (page - 1) * 6}
                          .
                        </th>
                        <td className="px-4 py-3 whitespace-nowrap capitalize">
                          <div className="flex flex-col">
                            <p className="text-gray-800 font-semibold">
                              Order {data.Order}
                            </p>
                            <p className="text-gray-400 font-semibold">
                              {data.Date}
                            </p>
                          </div>
                        </td>
                        <td className="px-6  py-5">{data?.Amount}</td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.Earnings}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div
                            className={`${
                              data.Status === "Completed"
                                ? "bg-green-100 text-green-500 rounded-full pl-3 pr-3 p-1"
                                : data.Status === "Rejected"
                                ? "bg-red-100 text-red-500 rounded-full pl-3 pr-3 p-1"
                                : data.Status === "Processing"
                                ? "bg-gray-100 text-gray-500 rounded-full pl-3 pr-3 p-1"
                                : "bg-blue-100 text-blue-500 rounded-full pl-3 pr-3 p-1"
                            } text-center font-semibold`}
                          >
                            {data?.Status}{" "}
                          </div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.Shipment}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.Customer}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.ShipmentA}
                        </td>
                        <td className="px-6  py-5">
                          <Dropdown
                            menu={{
                              items,
                              onClick: (key) => onClick(key, items),
                            }}
                            trigger={["click"]}
                            onClick={() => {}}
                          >
                            <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            </div>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <nav
              className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 ">
                Showing{" "}
                <span className="font-semibold text-gray-900 ">
                  Page {page}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 ">
                  {totalPages}
                </span>
              </span>
              <div className={`flex justify-end mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Paget;
