import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../../imgs/profile.png";
import { useParams } from "react-router-dom";

const Contacts = ({ refetch, search }) => {
  const token = useSelector((state) => state.token);
  const [contacts, setContacts] = useState([]);

  const selectedChatPhoneNo = useSelector((state) => state.selectedChatPhoneNo);
  const selectedChatNewUser = useSelector((state) => state.selectedChatNewUser);
  const dispatch = useDispatch();
  const { number } = useParams();
  const today = new Date();
  const formattedToday = today.toISOString();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const exampleObject = {
    createdAt: formattedToday,
    gid: "",
    id: selectedChatNewUser?.user?.id,
    name: number,
    countryCode: selectedChatNewUser?.user?.countryCode,
    phoneNo: selectedChatNewUser?.user?.phoneNo,
    phoneNumberWithCode: number,
    profileUrl: "message",
    updatedAt: "2024-01-11T16:34:47.000Z",
    person: null,
    whatsappChats: [],
  };

  // useEffect(() => {
  //   if (search.length >= 3 || search === "") {
  //     const api = search === "" ? "v1/whatsapp-chat/users?skip=0&page=1&limit=6" : `v1/whatsapp-chat/users?search=${search}&skip=0&page=1&limit=5`;

  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((data) => {
          // setCurrentPage(data?.data?.page)
          // setTotalPages(data?.data?.totalPages)
          // const modifiedArray = data.data?.rows?.map((item) => ({
          //   ...item,
          //   phoneNumberWithCode: item.countryCode + item.phoneNo,
          // }));
          // setContacts(modifiedArray);

          // if (number !== undefined) {
          //   const numberExists = modifiedArray.some(
          //     (contact) => contact.phoneNumberWithCode === number
          //   );
          //   console.log(numberExists, "numberExists");
          //   const matchingContact = modifiedArray.find(
          //     (contact) => contact.phoneNumberWithCode === number
          //   );
          //   if (numberExists) {
          //     dispatch({
          //       type: "CHAT_SELECTED_PHONE_NO",
          //       num: number,
          //     });
          //     dispatch({ type: "CHAT_SELECTED_USER", num: matchingContact });
          //   }
          //   if (!numberExists) {
          //     dispatch({
          //       type: "CHAT_SELECTED_PHONE_NO",
          //       num: number,
          //     });
          //     setContacts([exampleObject, ...modifiedArray]);
          //     dispatch({ type: "CHAT_SELECTED_USER", num: exampleObject });
          //   }
          // }
  //       })
  //       .catch((err) => {});
  //   }
  // }, [refetch, search]);


  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled by the user.');
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: 0,
        page: 1,
        ...(search && { search }),
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled by the user.');
      }
    };
  }, [search, refetch]);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/whatsapp-chat/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setCurrentPage(data?.data?.page)
        setTotalPages(data?.data?.totalPages)
        const modifiedArray = data.data?.rows?.map((item) => ({
          ...item,
          phoneNumberWithCode: item.countryCode + item.phoneNo,
        }));
        setContacts(modifiedArray);

        if (number !== undefined) {
          const numberExists = modifiedArray.some(
            (contact) => contact.phoneNumberWithCode === number
          );
          console.log(numberExists, "numberExists");
          const matchingContact = modifiedArray.find(
            (contact) => contact.phoneNumberWithCode === number
          );
          if (numberExists) {
            dispatch({
              type: "CHAT_SELECTED_PHONE_NO",
              num: number,
            });
            dispatch({ type: "CHAT_SELECTED_USER", num: matchingContact });
          }
          if (!numberExists) {
            dispatch({
              type: "CHAT_SELECTED_PHONE_NO",
              num: number,
            });
            setContacts([exampleObject, ...modifiedArray]);
            dispatch({ type: "CHAT_SELECTED_USER", num: exampleObject });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="bg-white flex-1 overflow-auto custom-scrollbar ml-4 mr-4">
      {contacts?.map((entry, index) => (
        <div
          key={index}
          onClick={() => {
            dispatch({
              type: "CHAT_SELECTED_PHONE_NO",
              num: entry?.phoneNumberWithCode,
            });
            // dispatch({ type: "CHAT_MESSAGES", messages: [] });
            dispatch({ type: "CHAT_SELECTED_USER", num: entry });
          }}
          className={`px-6 flex items-center cursor-pointer ${selectedChatPhoneNo === entry?.phoneNumberWithCode
              ? "bg-primary text-white rounded-lg hover:bg-primary"
              : "bg-white text-gray-700 rounded-lg hover:bg-gray-100 "
            }`}
        >
          <div>
            <img
              className="h-12 w-12 rounded-full"
              src={entry?.person === null ? profile : entry?.person?.image}
              alt={`${entry?.phoneNumberWithCode} Avatar`}
            />
          </div>
          <div
            className={`${selectedChatPhoneNo === entry?.phoneNumberWithCode
                ? ""
                : "border-b border-gray-300 ml-4 flex-1  py-4"
              }ml-4 flex-1  py-4`}
          >
            <div className="flex items-bottom justify-between">
              <p className="capitalize font-semibold text-[1rem]">
                {entry?.person === null
                  ? entry?.countryCode + " " + entry?.phoneNo
                  : entry?.person?.firstName + " " + entry?.person?.lastName}
              </p>
              <p className="text-xs ">
                {moment(entry?.createdAt).format("h:mm A")}
              </p>
            </div>
            <div className="w-40">
              <p className="text-sm  overflow-hidden whitespace-nowrap overflow-ellipsis">
                {entry?.whatsappChats[0]?.type === "image" ? "image" :
                  entry?.whatsappChats[0]?.type === "video" ?
                    "Video" : entry?.whatsappChats[0]?.type === "document" ? "document" : entry?.whatsappChats[0]?.content}
              </p>
            </div>
          
          </div>
        </div>
      ))}
      {
        totalPages > currentPage && <div className=" cursor-pointer font-semibold flex justify-center items-center py-1 text-primary">
          <button onClick={() => {
            axios
              .get(`${process.env.REACT_APP_BASE_URL}/v1/whatsapp-chat/users?&skip=0&page=${currentPage + 1}&limit=6`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((data) => {
                setCurrentPage(data?.data?.page)
                setTotalPages(data?.data?.totalPages)
                const modifiedArray = data.data?.rows?.map((item) => ({
                  ...item,
                  phoneNumberWithCode: item.countryCode + item.phoneNo,
                }));
                setContacts([...contacts, ...modifiedArray]);
              })
              .catch((err) => { });
          }}>Load More</button>
        </div>
      }
    </div>
  );
};

export default Contacts;
