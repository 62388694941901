import { DatePicker, Pagination } from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import moment from "moment/moment";
const PensionerTable = () => {
  const [page, setPage] = useState(1);
  const totalPages = 1;
  const [show, setShow] = useState("");
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  console.log(countryCode, phoneNoo, searchDob);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }

  const columnHeaders = [
    "SR.",
    "Vendor ID",
    "Vendor Name",
    "Service Category",
    "Contact Number",
    "Email Address",
    "License Number",
    "Certification Type",
    "Expiration Date",
    "Status",
  ];

  const vendorData = [
    {
      VendorID: 123423,
      VendorName: "AutoPro Mechanics",
      ServiceCategory: "VehicleMaintenance",
      ContactNumber: "123-456-7890",
      EmailAddress: "info@autopromechanics.com",
      LicenseNumber: "L1234567",
      CertificationType: "ASECertified",
      ExpirationDate: "2025-12-31",
      Status: "Active",
    },
    {
      VendorID: 22343,
      VendorName: "Speedy Tune-Up",
      ServiceCategory: "VehicleMaintenance",
      ContactNumber: "098-765-4321",
      EmailAddress: "service@speedytuneup.com",
      LicenseNumber: "L2345678",
      CertificationType: "AutomotiveServiceExcellence",
      ExpirationDate: "2024-10-15",
      Status: "Active",
    },
    {
      VendorID: 323432,
      VendorName: "QuickFix Auto Care",
      ServiceCategory: "VehicleMaintenance",
      ContactNumber: "456-789-0123",
      EmailAddress: "info@quickfixautocare.com",
      LicenseNumber: "L3456789",
      CertificationType: "NAPA AutoCareCenter",
      ExpirationDate: "2023-11-30",
      Status: "Active",
    },
    {
      VendorID: 2234,
      VendorName: "Midas Auto Service",
      ServiceCategory: "VehicleMaintenance",
      ContactNumber: "321-654-9870",
      EmailAddress: "contact@midasautoservice.com",
      LicenseNumber: "L4567890",
      CertificationType: "MidasCertified",
      ExpirationDate: "2026-05-20",
      Status: "Inactive",
    },
    {
      VendorID: 512,
      VendorName: "TuneTech Garage",
      ServiceCategory: "VehicleMaintenance",
      ContactNumber: "789-012-3456",
      EmailAddress: "info@tunetechgarage.com",
      LicenseNumber: "L5678901",
      CertificationType: "AAAApprovedAutoRepair",
      ExpirationDate: "2025-08-10",
      Status: "Active",
    },
    {
      VendorID: 6231,
      VendorName: "Grease Monkey",
      ServiceCategory: "VehicleMaintenance",
      ContactNumber: "654-321-0987",
      EmailAddress: "info@greasemonkey.com",
      LicenseNumber: "L6789012",
      CertificationType: "GreaseMonkeyCertified",
      ExpirationDate: "2024-09-15",
      Status: "Inactive",
    },
  ];

  const pageHandler = (e) => {
    setPage(e);
  };

  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  return (
    <section className="p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            {/* search */}
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by Email , Last Name "
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            {/* filter */}
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto">
                {/* <div className="rounded-lg border border-gray-200 p-2">
                  <Checkbox
                    className={`checkk4`}
                    // checked={checkBuffer === item ? true : false}
                    // onClick={() => changeHandler(item, index)}
                    // checked={status}
                    // onClick={() => setStatus(!status)}
                  >
                    Ceased Pensioners
                  </Checkbox>
                </div> */}
                <div className="relative">
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                    type="button"
                    onClick={showHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Sort By
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>

                  {show && (
                    <div
                      id="filterDropdown"
                      className="z-20 absolute top-full md:right-0 mt-2 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                    >
                      <div className="mt-5">
                        <DatePicker
                          onChange={(e, f) => setDob(f)}
                          value={dob !== "" ? moment(dob) : null}
                          placeholder="Date of birth"
                          // value={wow !== "" ? set : null}
                          // onChange={(e, f) => change1Handler(e, f)}
                        />
                      </div>

                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="GY"
                        value={prompt}
                        onChange={handleChange(setPrompt, "tt")}
                        international
                        countryCallingCodeEditable={false}
                        // required={option === "Employee" ? true : false}
                        // className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                      />
                      <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                        <button
                          onClick={() => {
                            if (prompt) {
                              const wow = parsePhoneNumber(prompt);

                              let count = wow.countryCallingCode;
                              let num = wow.nationalNumber;
                              setCountryCode(count);
                              setPhoneNoo(num);
                            }
                            if (dob !== null && dob !== "") setSearchDob(dob);
                            setShow(false);
                          }}
                          className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => {
                            setSearchDob("");
                            setDob("");
                            setPhoneNoo(null);
                            setPrompt("");
                            setCountryCode(null);
                            setShow(false);
                          }}
                          className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold whitespace-nowrap tracking-wider"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {vendorData.length === 0 ? (
                <h1 className="text-center text-[1.1rem] p-5">No Data Found</h1>
              ) : (
                <tbody>
                  {vendorData.map((data, index) => (
                    <tr
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                      onClick={() => {}}
                    >
                      <th
                        scope="row"
                        className="px-4 py-5 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data?.VendorID}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VendorName}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.ServiceCategory}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.ContactNumber}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.EmailAddress}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.LicenseNumber}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.CertificationType}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.ExpirationDate}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        <div
                          className={`border-none flex items-center justify-center ${
                            data.Status === "Inactive"
                              ? "bg-[#D64242]"
                              : " bg-primary"
                          }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                        >
                          {data.Status}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default PensionerTable;
