import e1 from "../../imgs/e1.png";
import e2 from "../../imgs/e2.png";
import e3 from "../../imgs/e3.png";
import back5 from "../../imgs/back5.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import PagetGRO from "./pagetGroTab";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
const PListingGRO = () => {
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const [total, setTotal] = useState(null);
  const [active, setActive] = useState(null);
  const [inActive, setInActive] = useState(null);
  let location = useLocation();
  const dash = location.pathname;
  // useEffect(()=>{
  //   dispatch({type:"gidFilterSet",num:""})
  // },[])
  // const showHandler = (check) => {
  //   if (check === "apply") {
  //     dispatch({ type: "phoneFilterSet", num: wow });
  //   } else if (check === "cancel") {
  //     wow = "";
  //     dispatch({ type: "phoneFilterSet", num: "" });
  //   }
  //   if (!show) {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }
  // };

  // const changeHandler = (e) => {

  //   dispatch({ type: "gidFilterSet", num: e.target.value });
  // };
  // const change1Handler = (event, f) => {

  //   if (event === null) {
  //     wow = "";
  //   } else {
  //     set = event;
  //     wow = f;
  //   }
  //   if (!test) {
  //     setTest(true);
  //   } else {
  //     setTest(false);
  //   }
  // }
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=pensioner`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setTotal(data?.data?.total);
        setActive(data?.data?.active);
        setInActive(data?.data?.inactive);
      })
      .catch(() => {});
  }, []);
  console.log("Location", location);
  return (
    <div className="w-full flex items-center justify-center flex-col">
      <div className="p-4 sm:w-[82%] w-full flex flex-col items-center justify-center">
        <div className="w-full flex flex-col items-center justify-center">
          <div className="flex w-full justify-center items-center mt-4">
            <div className="cursor-pointer w-full flex items-center">
              <img
                onClick={() =>
                  router(
                    dash === "/GroAdminDash/Pensioners" ? "/GroAdminDash" : "/"
                  )
                }
                className={`sm:w-[1.7rem] w-[1.2rem] ${
                  dash === "/GroAdminDash/Pensioners" ? "" : "hidden"
                }`}
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[2rem] text-[1.4rem]">
                Pensioners
              </h2>
            </div>
          </div>
          <div className="gap-4 flex-wrap ml-0 flex items-center justify-center sm:justify-between w-full mt-[3rem] mb-8">
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2] ">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Total Pensioners
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{total}</p>
              </div>
              <div>
                <img className="w-[2.1rem] mt-4" src={e2} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[0.73rem] font-semibold text-[#797979]">
                  Active Pensioners
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{active}</p>
              </div>
              <div>
                <img className="w-[2.2rem] mt-4" src={e3} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Inactive Pensioners
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{inActive}</p>
              </div>
              <div>
                <img className="w-[2rem] mt-5" src={e1} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div
        className={`${
          dataUser?.data?.user?.status === "pending" ? "pointer-events-none" : ""
        } relative sm:mt-4 mt-[2rem]`}
      >
        <Tabs defaultActiveKey="1" items={items} />
        <div className="absolute sm:top-1 sm:right-0 top-[-2.5rem] w-[100%] sm:w-[55%] flex justify-between">
          <Input
            size="large"
            placeholder="Search by gid/email/lastName"
            prefix={<SearchOutlined />}
            // onChange={changeHandler}
            onChange={(e) => setSearch(e.target.value)}
            className="Search"
            style={{ width: "100%" }}
          />
          <div
            onClick={showHandler}
            className="border w-[8rem] h-[2.2rem] rounded-lg ml-2 cursor-pointer flex justify-between items-center p-1 pl-2 pr-2"
          >
            <p>Sort By</p>
            <div>
              <img className="scale-[0.7]" src={down} alt="" />
            </div>
          </div>
          <div
            className={`${
              show ? "" : "hidden"
            } absolute right-[-10%] top-14 w-[18rem] h-[11.5rem] bg-white shadow-2xl rounded-xl p-4`}
          >
            <h2 className=" font-semibold text-[1.2rem] mb-2">Filters</h2>
            <div className="mt-5">
              <DatePicker
                value={wow !== "" ? set : null}
                onChange={(e, f) => change1Handler(e, f)}
              />
            </div>
            <div className="mt-4">
              <button
                onClick={() => showHandler("apply")}
                className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                Apply
              </button>
              <button
                onClick={() => showHandler("cancel")}
                className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div> */}
      </div>
      <PagetGRO />
    </div>
  );
};

export default PListingGRO;
