import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";
import RolePrivliges from "../components/Roles&Privliges"

const rolesPrivileges = () => {
  return (
    <div>
      <Nav />
     <RolePrivliges/>
     <Whatsapp/>
    </div>
  )
}

export default rolesPrivileges