import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import EviewGro from "../components/GeneralRegisterOfficer/groadmin/Edit employee/viewEmp"

const GroEProf = () => {
  return (
    <div>
      <Nav />
     <EviewGro/>
     <Whatsapp/>
    </div>
  )
}

export default GroEProf