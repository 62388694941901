import Dash from "../components/eWallet/dash";

const ProfPage = () => {
  return (
    <div>
      <Dash />
    </div>
  );
};

export default ProfPage;
