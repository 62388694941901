import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Banking = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const [bankInfo, setBankInfo] = useState(null);
  console.log(bankInfo);
  const user= useSelector((state) => state.user);
  useEffect(() => {
    if (user === "merchant-owner" || user === "merchant-admin" || user === "merchant-employee" || user==="employee"){
      return
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/bank-info`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        const filteredData = data?.data?.filter(item => 
          item?.isInternational === false && item?.isActive === true
        );
        console.log(filteredData);
        setBankInfo(filteredData?.[0]);
      })
      .catch((err) => {
        // setErr(err.response.data.message);
        // setShow(true);
      });
  }, []);

  const maskAccountNumber = (accountNumber) => {
    let maskedNumber = "";
    for (let i = 0; i < accountNumber?.length; i++) {
      if (i < accountNumber?.length - 4) {
        maskedNumber += "X";
      } else {
        maskedNumber += accountNumber[i];
      }
    }
    return maskedNumber;
  };
  return (
    <div className="p-3 font-semibold px-6">
      {bankInfo !== null ? (
        <>
      <h1>Local Bank detail linked with your sap-wallet</h1>
        <div className="grid md:grid-cols-5 sm:grid-cols-2 grid-cols-1 py-4">
          <div className="bg-[#F6FBF9] flex flex-col items-center px-8 py-6 rounded-md border shadow-sm">
            <div
              className={`
             bg-[#F35929]
             flex items-center justify-center rounded-full w-12 h-12 `}
            >
              <span className="text-white text-xl font-bold flex items-center justify-center">
                {bankInfo?.bankName?.charAt(0)}
              </span>
            </div>
            <p className="text-md text-gray-900 mt-3 font-semibold">
              {bankInfo?.bankName}
            </p>
            <p>{maskAccountNumber(bankInfo?.accountNo)} </p>
          </div>
        </div>
        </>
      ) : (
        <p className="text-gray-500">No bank linked to your sap-wallet</p>
      )}

      {/* <button
        onClick={() => {
          router("/SapMoney/AddBank");
        }}
        className="bg-primary hover:bg-primary-dark text-white px-4 py-2 rounded-md w-[15rem] flex items-center justify-center"
      >
        <p className="text-2xl font-semibold mr-1">+</p>
        <p className="text-base font-semibold">Add Another Bank</p>
      </button> */}
    </div>
  );
};

export default Banking;
