import React, {useEffect} from "react";
import { Layout, theme } from "antd";
import Sidebar from "./sideBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import eWall from "../../imgs/SAP_Money.png"
const { Content, Sider } = Layout;
const App = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const image = dataUser?.data?.user?.person?.image;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const recentTransactions = [
    {
      id: "001",
      name: "Online Purchase",
      date: "2024-03-05",
      amount: 50.25,
      type: "expense",
      category: "Shopping",
      paymentMethod: "Credit Card",
      status: "Completed",
      accountName: "My Bank Account",
    },
    {
      id: "002",
      name: "Gas Station",
      date: "2024-03-01",
      amount: 45.75,
      type: "expense",
      category: "Transportation",
      paymentMethod: "Debit Card",
      status: "Completed",
      accountName: "My Bank Account",
    },
    {
      id: "003",
      name: "Pension",
      date: "2024-03-07",
      amount: 1000,
      type: "income",
      category: "Income",
      paymentMethod: "Direct Deposit",
      status: "Completed",
      accountName: "Pension Account",
    },
    {
      id: "004",
      name: "Grocery Store",
      date: "2024-02-28",
      amount: 70.0,
      type: "expense",
      category: "Groceries",
      paymentMethod: "Cash",
      status: "Completed",
      accountName: "My Bank Account",
    },
    {
      id: "005",
      name: "Utilities Bill",
      date: "2024-02-25",
      amount: 100.0,
      type: "expense",
      category: "Utilities",
      paymentMethod: "Online Banking",
      status: "Completed",
      accountName: "My Bank Account",
    },
  ];
  useEffect(()=>
  {
    dispatch({ type: "SIDEBARE", num: 2 });
  },[])
  return (
    <Layout
      style={{
        padding: 5,
        margin: 0,
        background: "#ebfff3",
      }}
    >
      <Sider
        breakpoint="lg"
        width={230}
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        className="flex flex-col justify-between h-full"
        style={{
          borderRadius: borderRadiusLG,
          width: "230px",
          background: "#ebfff3",
          // backgroundColor:"white",
          height: "100vh",
        }}
      >
        <Sidebar />
      </Sider>

      <Layout
        style={{
          background: "#ebfff3",
        }}
      >
        <div
          style={{
            margin: "0px 16px 0",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
            // boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;',
          }}
        >
          {/* <DashHeader /> */}
        </div>
        <Content
        // style={{
        //   margin: "24px 16px 10px",
        //   boxShadow:
        //     "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        //   padding: 26,
        //   background: colorBgContainer,
        //   borderRadius: borderRadiusLG,
        //   minHeight: "fit-content",
        // }}
        >
          <div className="w-full mt-2 flex justify-between items-center mb-4">
            <h1 className="text-2xl pl-2 font-semibold w-[215px]"><span className="text-red-500">SAP </span>Wallet</h1>
            <img
                src={eWall}
                alt="eWallet"
                className="h-35 w-40"
              />
            <div className="flex items-center space-x-4">
              {/* Search Field */}
              <input
                type="text"
                placeholder="Search..."
                className="px-4 py-2 bg-white rounded-lg focus:outline-none"
              />
              {/* Profile Image */}
              <img
                src={image}
                alt="Profile"
                className="h-10 w-10 rounded-full"
              />
              <h1 className="text-md pr-4 font-semibold">
                {dataUser?.data?.user?.person?.firstName}
                {" "}
                {dataUser?.data?.user?.person?.lastName}
              </h1>
            </div>
          </div>
          <div className="flex items-center flex-col justify-center gap-x-2">
            <div className="w-full">
              <div className="bg-white shadow-md rounded-md">
                <div className="p-6">
                  <div className="flex justify-between gap-x-2">
                    {/* Total Balance Card */}
                    <div className="bg-[#F25A29] hover:bg-[#F79371] cursor-pointer text-white p-6 rounded-lg shadow-md w-1/3">
                      <h2 className="text-lg font-semibold">Total Balance</h2>
                      <p className="text-3xl font-bold mt-2">$2400</p>
                    </div>
                    {/* Total Spend Card */}
                    <div className="bg-[#8BD6B5] hover:bg-[#A6E8C9] cursor-pointer text-white p-6 rounded-lg shadow-md w-1/3">
                      <h2 className="text-lg font-semibold">Total Spend</h2>
                      <p className="text-3xl font-bold mt-2">$1200</p>
                    </div>
                    {/* Total Income Card */}
                    <div className="bg-primary cursor-pointer  text-white p-6 rounded-lg shadow-md w-1/3">
                      <h2 className="text-lg font-semibold">Total Income</h2>
                      <p className="text-3xl font-bold mt-2">$1700</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {recentTransactions.length === 0 ? (
              <h1 className="text-center text-[1.1rem] mt-2 p-5 h-40">
                No Data Found
              </h1>
            ) : (
                <div className="w-full rounded-md ">
                    <h1 className="text-[1.2rem]  font-semibold mt-2 p-2">Transections :</h1>
              <table className="w-full rounded-md text-sm text-left mt-1 text-gray-500">
                <thead className="text-xs rounded-md text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      ID
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Amount
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Category
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Method
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 font-semibold tracking-wider">
                      Bank Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {recentTransactions.map((transaction, index) => (
                    <tr
                      key={index}
                      className={`border-b  ${
                        index % 2 === 0
                          ? "bg-gray-50 hover:bg-gray-200"
                          : "bg-white hover:bg-gray-100"
                      } text-md cursor-pointer rounded-md`}
                    >
                      <td className="px-3 py-5 font-medium">
                        {transaction.id}
                      </td>
                      <td className="px-3 py-5">{transaction.name}</td>
                      <td className="px-3 py-5">{transaction.date}</td>
                      <td
                        className={`px-3 py-5 font-semibold ${
                          transaction.type === "income"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {transaction.type === "income" ? "+" : "-"}$
                        {transaction.amount}
                      </td>
                      <td
                        className={`px-3 py-5 ${
                          transaction.type === "income"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {transaction.type === "income" ? "Income" : "Expense"}
                      </td>
                      <td className="px-3 py-5">{transaction.category}</td>
                      <td className="px-3 py-5">{transaction.paymentMethod}</td>
                      <td className="px-3 py-5">{transaction.status}</td>
                      <td className="px-3 py-5">{transaction.accountName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;
