import { useDispatch, useSelector } from "react-redux";
import across from "../../../imgs/across.png";
import { useState } from "react";
import { Button, Input, Select, Space, Checkbox } from "antd";
import { useEffect } from "react";
import axios from "axios";
import { PlusCircleFilled } from "@ant-design/icons";
import check from "../../../imgs/add.png";
import deleteicon from "../../../imgs/bs3.png";
import { EnvironmentOutlined } from "@ant-design/icons";
import shop from "../../../imgs/store.svg";
const FourthR = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [test, setTest] = useState(false);
  const [locationt, setLocationt] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [allRegions, setAllRegions] = useState("");
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [mailingS, setMailingS] = useState(false);
  const [mailingHQ, setMailingHQ] = useState(false);
  const [addresses, setAddresses] = useState([
    {
      customStoreName: "",
      inchargeName: "",
      coordinates: "",
      primaryPhone: "",
      primaryEmail: "",
      phones: [],
      emails: [],
      lot: "",
      lName: "",
      address: "",
      region: null,
      regionName: "",
      city: null,
      village: null,
      image: "",
      cityOptions: [],
      wardOptions: [],
      display: shop,
      mailAddress: {
        sameAsHeadquarter: false,
        sameAsStore: false,
        lot: "",
        address: "",
        region: null,
        regionName: "",
        city: null,
        village: null,
        cityOptionsM: [],
        wardOptionsM: [],
      },
    },
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleInputChange = (type, index, value) => {
    if (type === "region") {
      getRegionName(value, index);
    }

    const updatedAddresses = [...addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      [type]: value,
    };
    setAddresses(updatedAddresses);
  };
  const handleInputChangeM = (type, index, value) => {
    if (type === "region") {
      getRegionNameM(value, index);
    }

    const updatedAddresses = [...addresses];
    updatedAddresses[index].mailAddress = {
      ...updatedAddresses[index].mailAddress,
      [type]: value,
    };
    setAddresses(updatedAddresses);
  };
  const getRegionName = (regionNum, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}`
      )
      .then((data) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          region: regionNum,
          regionName: data.data.data[0],
        };
        setAddresses(updatedAddresses);
        getCities(regionNum, data.data.data[0], index);
      })
      .catch(() => {});
  };
  const getRegionNameM = (regionNumM, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNumM}`
      )
      .then((data) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[index].mailAddress = {
          ...updatedAddresses[index].mailAddress,
          region: regionNumM,
          regionName: data.data.data[0],
        };
        setAddresses(updatedAddresses);
        getCitiesM(regionNumM, data.data.data[0], index);
      })
      .catch(() => {});
  };
  const getCities = (regionNum, regionName, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          region: regionNum,
          regionName: regionName,
          cityOptions: wow,
        };
        setAddresses(updatedAddresses);
      })
      .catch(() => {});
  };
  const getCitiesM = (regionNumM, regionNameM, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNumM}&regionName=${regionNameM}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        const updatedAddresses = [...addresses];
        updatedAddresses[index].mailAddress = {
          ...updatedAddresses[index].mailAddress,
          region: regionNumM,
          regionName: regionNameM,
          cityOptionsM: wow,
        };
        setAddresses(updatedAddresses);
      })
      .catch(() => {});
  };

  const getWards = (regionNum, regionName, city, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}&city=${city}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          region: regionNum,
          regionName: regionName,
          city: city,
          wardOptions: wow,
        };
        setAddresses(updatedAddresses);
      })
      .catch(() => {});
  };
  const getWardsM = (regionNumM, regionNameM, cityM, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNumM}&regionName=${regionNameM}&city=${cityM}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        const updatedAddresses = [...addresses];
        updatedAddresses[index].mailAddress = {
          ...updatedAddresses[index].mailAddress,
          region: regionNumM,
          regionName: regionNameM,
          city: cityM,
          wardOptionsM: wow,
        };
        setAddresses(updatedAddresses);
      })
      .catch(() => {});
  };
  const handleLocateMe = (index) => {
    if (navigator.geolocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Make a request to Google Maps Geocoding API
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU`;

            fetch(apiUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.results && data.results.length > 0) {
                  const locationName = data.results[0].formatted_address;
                  setLocationt(`${latitude}, ${longitude}`);
                  // setLName(locationName);
                  const updatedAddresses = [...addresses];
                  updatedAddresses[index] = {
                    ...updatedAddresses[index],
                    coordinates: `${latitude}, ${longitude}`,
                    lName: locationName,
                  };
                  setAddresses(updatedAddresses);
                } else {
                  console.error("Could not retrieve location name");
                }
              })
              .catch((error) => {
                console.error("Error getting location:", error.message);
              });
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      }
    }
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch(() => {});
  }, []);
  const nextHandler = (e, type) => {
    e.preventDefault();

    if (type === "skip") {
      dispatch({ type: "IINCREMENT" });
      return;
    }

    // const body = addresses.map((address) => {
    //   const {
    //     cityOptions,
    //     wardOptions,
    //     lName,
    //     display,
    //     mailAddress: { cityOptionsM, wardOptionsM, ...mailRest },
    //     ...rest
    //   } = address;
    //   return {
    //     ...rest,
    //     mailAddress: mailRest,
    //   };
    // });
    const body = addresses.map((address) => {
      const {
        cityOptions,
        wardOptions,
        lName,
        display,
        mailAddress: { cityOptionsM, wardOptionsM, ...mailRest },
        phones,
        emails,
        ...rest
      } = address;
      const filteredAddress = {
        ...rest,
        mailAddress: mailRest,
      };

      if (phones.length > 0) {
        filteredAddress.phones = phones;
      }

      if (emails.length > 0) {
        filteredAddress.emails = emails;
      }

      return filteredAddress;
    });
    console.log(body);

    // return
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-store`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch({ type: "IINCREMENT" });
      })
      .catch((err) => {
        if (
          err?.response?.data?.message ===
          `"phones" does not contain 1 required value(s)`
        ) {
          setErr("Phone number is required");
        } else if (
          err?.response?.data?.message === `"[0]" must be a valid email`
        ) {
          setErr("Enter valid email address");
        } else if (
          err.response.data.message === '"image" is not allowed to be empty'
        ) {
          setErr("Please uplaod Store Picture");
        } else setErr(err?.response?.data?.message);
        setShow(true);
      });
  };
  const addAddressHandler = (check, index) => {
    if (check === "add") {
      setAddresses((prevAddresses) => [
        ...prevAddresses,
        {
          customStoreName: "",
          inchargeName: "",
          coordinates: "",
          phones: [],
          emails: [],
          lot: "",
          address: "",
          region: null,
          regionName: "",
          city: null,
          village: null,
          image: "",
          cityOptions: [],
          wardOptions: [],
          display: shop,
          mailAddress: {
            sameAsHeadquarter: false,
            sameAsStore: false,
            lot: "",
            address: "",
            region: null,
            regionName: "",
            city: null,
            village: null,
          },
        },
      ]);
    } else {
      setAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses.splice(index, 1);
        return updatedAddresses;
      });
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };
  // const addEmail = (index) => {
  //   if (newEmail.trim() !== "") {
  //     const updatedAddresses = [...addresses];
  //     const updatedEmails = [...updatedAddresses[index].emails, newEmail];

  //     updatedAddresses[index] = {
  //       ...updatedAddresses[index],
  //       emails: updatedEmails,
  //     };
  //     setAddresses(updatedAddresses);
  //     setNewEmail("");
  //   }
  // };

  const addEmail = (index) => {
    const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (newEmail.trim() !== "" && emailFormatRegex.test(newEmail)) {
      const updatedAddresses = [...addresses];
      const updatedEmails = [...updatedAddresses[index].emails, newEmail];

      updatedAddresses[index] = {
        ...updatedAddresses[index],
        emails: updatedEmails,
      };
      setAddresses(updatedAddresses);
      setNewEmail("");
    } else {
      setErr("Please enter a valid email address");
      setShow(true);
    }
  };

  const removeEmail = (indexToRemove, addressIndex) => {
    const updatedAddresses = [...addresses];
    const updatedEmails = updatedAddresses[addressIndex].emails.slice();
    updatedEmails.splice(indexToRemove, 1);

    updatedAddresses[addressIndex] = {
      ...updatedAddresses[addressIndex],
      emails: updatedEmails,
    };
    setAddresses(updatedAddresses);
  };
  const addNumber = (addressIndex) => {
    if (newNumber.trim() !== "") {
      const updatedAddresses = [...addresses];
      const updatedNumbers = [
        ...updatedAddresses[addressIndex].phones,
        newNumber,
      ];

      updatedAddresses[addressIndex] = {
        ...updatedAddresses[addressIndex],
        phones: updatedNumbers,
      };
      setAddresses(updatedAddresses);
      setNewNumber("");
    }
  };

  const removeNumber = (indexToRemove, addressIndex) => {
    const updatedAddresses = [...addresses];
    const updatedNumbers = updatedAddresses[addressIndex].phones.slice();
    updatedNumbers.splice(indexToRemove, 1);

    updatedAddresses[addressIndex] = {
      ...updatedAddresses[addressIndex],
      phones: updatedNumbers,
    };
    setAddresses(updatedAddresses);
  };
  const imageHandler = async (e, index) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(index, file);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantCompanyLogo`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          image: data.data.url,
          display: base64,
        };
        setAddresses(updatedAddresses);
      })
      .catch(() => {});
  };
  function convertToBase64(index, file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const handleMailingS = (index) => {
    setMailingS(!mailingS);
    if (mailingHQ === true) {
      setMailingHQ(false);
    }
    if (mailingS === false) {
      const updatedAddresses = [...addresses];
      updatedAddresses[index].mailAddress = {
        ...updatedAddresses[index].mailAddress,
        sameAsHeadquarter: false,
        sameAsStore: true,
        lot: updatedAddresses[index].lot,
        address: updatedAddresses[index].address,
        region: updatedAddresses[index].region,
        regionName: updatedAddresses[index].regionName,
        city: updatedAddresses[index].city,
        village: updatedAddresses[index].village,
      };
      setAddresses(updatedAddresses);
    } else {
      const updatedAddresses = [...addresses];
      updatedAddresses[index].mailAddress = {
        ...updatedAddresses[index].mailAddress,
        sameAsHeadquarter: false,
        sameAsStore: false,
        lot: "",
        address: "",
        region: null,
        regionName: "",
        city: null,
        village: null,
      };
      setAddresses(updatedAddresses);
    }
  };
  const handleMailingHQ = (index) => {
    setMailingHQ(!mailingHQ);
    if (mailingS === true) {
      setMailingS(false);
    }
    if (mailingHQ === false) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter/my-hq-mail-address`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          const updatedAddresses = [...addresses];
          updatedAddresses[index].mailAddress = {
            ...updatedAddresses[index].mailAddress,
            sameAsHeadquarter: true,
            sameAsStore: false,
            lot: data?.data?.lot,
            address: data?.data?.address,
            region: data?.data?.region,
            regionName: data?.data?.regionName,
            city: data?.data?.city,
            village: data?.data?.village,
          };
          setAddresses(updatedAddresses);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      const updatedAddresses = [...addresses];
      updatedAddresses[index].mailAddress = {
        ...updatedAddresses[index].mailAddress,
        sameAsHeadquarter: false,
        sameAsStore: false,
        lot: "",
        address: "",
        region: null,
        regionName: "",
        city: null,
        village: null,
      };
      setAddresses(updatedAddresses);
    }
  };
  return (
    <div className="flex items-center justify-center flex-col">
      {addresses.map((add, index) => {
        return (
          <div key={`Store-${index}`} className="flex justify-center sm:w-[55%] w-[85%] ">
            <form
              action=""
              className="mt-10 w-full"
              onSubmit={nextHandler}
            >
              <div
                className={`flex justify-end cursor-pointer mt-[3rem] ${
                  index === 0 ? "hidden" : ""
                }`}
                onClick={() => addAddressHandler("remove", index)}
              >
                <p className="text-[red] underline text-[0.94rem]">
                  Remove Store
                </p>
              </div>
              <div className="flex items-center justify-center mb-4 flex-col">
                <label htmlFor={`${index}`} className="cursor-pointer relative">
                  <div className="relative w-[8.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262]  rounded-full mb-4 ">
                    <img
                      className="object-cover w-[8.5rem] h-[8.5rem]"
                      src={add.display}
                      alt=""
                    />
                  </div>
                  <div
                    className={`bg-primary
                     rounded-full absolute bottom-4 right-2`}
                  >
                    <img src={check} className="lg:w-7 w-5" alt="" />
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    id={`${index}`}
                    accept="image/png, image/gif, image/jpeg"
                    onInput={(e) => imageHandler(e, index)}
                  />
                </label>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Upload Store Picture <span className="text-red-600">*</span>
                </h2>
              </div>

              <div className="flex justify-center items-center flex-col">
                <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full">
                  <div className="w-[100%]">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Store Incharge <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        value={add.inchargeName}
                        onChange={(e) =>
                          handleInputChange(
                            "inchargeName",
                            index,
                            e.target.value
                          )
                        }
                        placeholder="Full Name"
                        className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Custom Store Name{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        value={add.customStoreName}
                        onChange={(e) =>
                          handleInputChange(
                            "customStoreName",
                            index,
                            e.target.value
                          )
                        }
                        placeholder="Custom Store Name"
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[100%] mb-4">
                <div className="flex flex-col">
                  <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
                    Primary Phone Number <span className="text-red-600">*</span>
                  </h2>
                  <Input
                    placeholder="Primary Phone Number"
                    // value={primaryPhone}
                    className="p-2"
                    onChange={(e) =>
                      handleInputChange("primaryPhone", index, e.target.value)
                    }
                    // onChange={(e) => {
                    //   const inputValue = e.target.value;
                    //   if (/^\+?[0-9()-]*$/.test(inputValue))
                    //     setPrimaryPhone(e.target.value);
                    // }}
                    type="tel"
                  />
                </div>
              </div>
              <div className="w-[100%] mb-6">
                <div className="flex flex-col">
                  <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
                    Primary Email <span className="text-red-600">*</span>
                  </h2>

                  <Input
                    placeholder="Primary Email"
                    // value={primaryEmail}
                    className="p-2"
                    onChange={(e) =>
                      handleInputChange("primaryEmail", index, e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="w-[100%] mb-6">
                <div className="flex flex-col">
                  <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
                    Other Contact Details <span className="text-red-600"></span>
                  </h2>
                  <Space.Compact
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input
                      placeholder="Store Phone Number"
                      value={newNumber}
                      className="p-2"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\+?[0-9()-]*$/.test(inputValue))
                          setNewNumber(e.target.value);
                      }}
                      type="tel"
                    />
                    <Button
                      className="bg-slate-300  h-12"
                      onClick={() => addNumber(index)}
                    >
                      Add
                    </Button>
                  </Space.Compact>

                  {add?.phones?.map((link, ind) => (
                    <div
                      key={`phone-${ind}`}
                      className="flex items-center justify-between mt-2 p-2 bg-gray-200 shadow-md rounded-md border border-gray-300"
                    >
                      <Input
                        placeholder="Enter URL"
                        value={link}
                        className="bg-slate-200 mr-2"
                      />

                      <button
                        type="button"
                        onClick={() => removeNumber(ind, index)}
                      >
                        <img src={deleteicon} alt="" className="w-6 h-6" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[100%] mb-6">
                <div className="flex flex-col">
                  {/* <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
                    Email Address
                  </h2> */}
                  <Space.Compact
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input
                      placeholder="Store Email Address"
                      value={newEmail}
                      className="p-2"
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <Button
                      className="bg-slate-300  h-12"
                      onClick={() => addEmail(index)}
                    >
                      Add
                    </Button>
                  </Space.Compact>

                  {add?.emails?.map((link, ind) => (
                    <div
                      key={`email-${ind}`}
                      className="flex items-center justify-between mt-2 p-2 bg-gray-200 shadow-md rounded-md border border-gray-300"
                    >
                      <Input
                        placeholder="Enter URL"
                        value={link}
                        className="bg-slate-200 mr-2"
                      />

                      <button
                        type="button"
                        onClick={() => removeEmail(ind, index)}
                      >
                        <img src={deleteicon} alt="" className="w-6 h-6" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h2 className="mb-4 text-[0.88rem] font-semibold text-[#596F96]">
                  Address
                </h2>

                <div className="flex items-center justify-center flex-col ">
                  <Button
                    onClick={() => {
                      handleLocateMe(index);
                      handleInputChange("coordinates", index, locationt);
                    }}
                    className="flex items-center justify-center bg-gray-300 w-[13rem] h-12 font-semibold text-[1rem] text-gray-700"
                    icon={<EnvironmentOutlined />}
                  >
                    Locate Me <span className="text-red-600">*</span>
                  </Button>
                  <input
                    type="text"
                    readOnly
                    placeholder="Location"
                    value={add?.lName}
                    className=" lg:text-[1.1rem] w-[100%] bg-white mt-3 border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>

                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Lot # <span className="text-red-600">*</span>
                </h2>
                <input
                  type="text"
                  name=""
                  placeholder="Lot #"
                  value={add.lot}
                  onChange={(e) =>
                    handleInputChange("lot", index, e.target.value)
                  }
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Address <span className="text-red-600">*</span>
                </h2>
                <input
                  type="text"
                  name=""
                  value={add.address}
                  placeholder="Address"
                  onChange={(e) =>
                    handleInputChange("address", index, e.target.value)
                  }
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Region No <span className="text-red-600">*</span>
                </h2>
                <Select
                  placeholder="Region No"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={(value) =>
                    handleInputChange("region", index, value)
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  value={add.region}
                  options={allRegions}
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Region Name <span className="text-red-600">*</span>
                </h2>
                <input
                  type="text"
                  name=""
                  onChange={(e) =>
                    handleInputChange("regionName", index, e.target.value)
                  }
                  value={add.regionName}
                  placeholder="Region Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Town/City <span className="text-red-600">*</span>
                </h2>
                <Select
                  placeholder="Town/City"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={(value) => {
                    handleInputChange("city", index, value);
                    getWards(add.region, add.regionName, value, index);
                  }}
                  value={add.city}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={add.cityOptions}
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Ward/Village <span className="text-red-600">*</span>
                </h2>
                <Select
                  placeholder="Ward/Village"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  value={add.village}
                  showSearch
                  onChange={(value) =>
                    handleInputChange("village", index, value)
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={add.wardOptions}
                />
              </div>
              <div>
                <h2 className="mb-4 text-[1.1rem] font-semibold text-[#596F96]">
                  Mailing Address:
                </h2>

                {/* <div className="flex items-center justify-center flex-col ">
                <Button
                onClick={handleLocateMe}
                  className="flex items-center justify-center bg-gray-300 w-[13rem] h-12 font-semibold text-[1rem] text-gray-700"
                  icon={<EnvironmentOutlined />}
                >
                  Locate Me
                </Button>
                <input
                type="text"
                placeholder="Location"
                value={lName}
                className=" lg:text-[1.1rem] w-[100%] bg-white mt-3 border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              </div> */}
                <div className="ml-0">
                  <Checkbox
                    checked={add.mailAddress.sameAsStore}
                    onChange={() => handleMailingS(index)}
                    className={"checkk"}
                  >
                    Is your mailing address is same as above address?
                  </Checkbox>
                </div>
                <div className="ml-0 mb-2">
                  <Checkbox
                    checked={add.mailAddress.sameAsHeadquarter}
                    onChange={() => handleMailingHQ(index)}
                    className={"checkk"}
                  >
                    Is your mailing address is same as Head Quarter's mailing
                    address?
                  </Checkbox>
                </div>
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Lot # <span className="text-red-600">*</span>
                </h2>
                <input
                  type="text"
                  placeholder="Lot #"
                  value={add.mailAddress.lot}
                  onChange={(e) =>
                    handleInputChangeM("lot", index, e.target.value)
                  }
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Address <span className="text-red-600">*</span>
                </h2>
                <input
                  type="text"
                  value={add.mailAddress.address}
                  placeholder="Address"
                  onChange={(e) =>
                    handleInputChangeM("address", index, e.target.value)
                  }
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Region No <span className="text-red-600">*</span>
                </h2>
                <Select
                  placeholder="Region No"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={(value) =>
                    handleInputChangeM("region", index, value)
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  value={add.mailAddress.region}
                  options={allRegions}
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Region Name <span className="text-red-600">*</span>
                </h2>
                <input
                  type="text"
                  onChange={(e) =>
                    handleInputChangeM("regionName", index, e.target.value)
                  }
                  value={add.mailAddress.regionName}
                  placeholder="Region Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Town/City <span className="text-red-600">*</span>
                </h2>
                <Select
                  placeholder="Town/City"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={(value) => {
                    handleInputChangeM("city", index, value);
                    getWardsM(
                      add.mailAddress.region,
                      add.mailAddress.regionName,
                      value,
                      index
                    );
                  }}
                  value={add.mailAddress.city}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={add.mailAddress.cityOptionsM}
                />
                <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Ward/Village <span className="text-red-600">*</span>
                </h2>
                <Select
                  placeholder="Ward/Village"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  value={add.mailAddress.village}
                  showSearch
                  onChange={(value) =>
                    handleInputChangeM("village", index, value)
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={add.mailAddress.wardOptionsM}
                />
              </div>
              <div
                className="flex justify-center items-center mt-4"
                onClick={() => addAddressHandler("add")}
              >
                <div className="flex justify-center items-center cursor-pointer">
                  <div>
                    <PlusCircleFilled className=" text-primary flex justify-center items-center text-2xl mr-2" />
                  </div>
                  <p className="text-primary">Add More Stores</p>
                </div>
              </div>
            </form>
          </div>
        );
      })}
      <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[55%] w-[85%]">
        <button
          onClick={(e) => {
            nextHandler(e, "skip");
          }}
          className={`lg:text-[1.1rem] bg-gray-300 text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Skip
        </button>
        <button
          onClick={(e) => {
            nextHandler(e, "next");
          }}
          className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default FourthR;
