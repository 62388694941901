import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import { Pagination, Popover } from "antd";
import ContactInfo from "../../../Merchant/Transactions/contactInfo";

// import SearchFilter from "./SearchFilter";

const Paget = () => {
  const router = useNavigate();

  const token = useSelector((state) => state.token);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [showContact, setShowContact] = useState(false);
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);

  const type = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);
  const columnHeaders = [
    "Sr No",
    "Sender",
    "Receiver",
    "Date / Time",
    // "Phone",
    "Amount",
    "Status",
  ];

  useEffect(() => {
    // if (user !== "pensioner") {
    //   return;
    // }
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const pensionerParams = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
        requestType: "incoming",
      };

      const merchParams = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
        
      };

      // Conditionally add merchheadquarterId or merchstoreId based on type
      if (type === "HQ") {
        merchParams.merchheadquarterId =
          typeData?.merchuser?.merchheadquarterId;
      } else if (type === "store") {
        merchParams.merchstoreId = typeData?.id;
      }

      const params = user === "pensioner" ? pensionerParams : merchParams;

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api = "v1/pensioner-quick-payee/query-for-merch";
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setData(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        // setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    // if (search) {
    //   // If searching, update searchPage
    //   setPagewithsearch(e);
    // } else {
    //   // If not searching, update page
    setPage(e);
    // }
  };

  const transformString = (inputString) => {
    // Split the string by either '_' or '-'
    const parts = inputString?.split(/[_-]/);
    // Capitalize the first letter of each part and join them with a space
    const transformedString = parts
      ?.map((part) => part?.charAt(0)?.toUpperCase() + part?.slice(1))
      ?.join(" ");
    return transformedString;
  };

  return (
    <section className="p-5">
      <div className="mx-auto max-w-screen-xl relative  h-auto bg-white">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#D9EFED]">
          {/* table */}
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders
                    .filter((columnHeader) => {
                      if (
                        user !== "pensioner" &&
                        columnHeader === "Store / HQ"
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-3 py-4 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                </tr>
              </thead>
              {data?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p className="p-10 py-20">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {data?.map((data, index) => (
                    <tr
                      onClick={() => {
                        // if (data?.payStatus === "requested") {
                        //   router("/SapMoney/Parent/Respond/Child", {
                        //     state: { data: data, merchantSideChildReq: true },
                        //   });
                        //   return;
                        // }
                        dispatch({ type: "MER_PEN_REQ_DETAIL", num: data });
                        dispatch({ type: "MER_PEN_REQ_ENABLE", num: true });
                        router("/SapMoney/RequestDetail", {
                          state: { data: data, merchantSideChildReq: true },
                        });
                      }}
                      className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {index + 1}.
                      </th>

                      <td className="px-4 py-3 whitespace-nowrap capitalize">
                        {data?.firstName === null
                          ? "------"
                          : data?.firstName + " " + data?.lastName}
                      </td>

                      {/* <td
                        className={`px-4 py-3 whitespace-nowrap capitalize ${
                          user !== "pensioner" ? "hidden" : ""
                        }`}
                      >
                        <div>
                          {data?.merchstore !== null
                            ? data?.merchstore?.customStoreName
                            : data?.merchCompany !== undefined &&
                              data?.merchCompany !== null
                            ? data?.merchCompany?.name
                            : "----------"}
                        </div>
                      </td> */}
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div>
                          {moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
                        </div>
                      </td>

                      <td className="px-4 py-3 whitespace-nowrap capitalize">
                        {data?.purpose}
                      </td>

                      {/* <td
                        className={`px-4 py-3 whitespace-nowrap ${
                          data?.type === "credit" &&
                          data?.payStatus !== "request-rejected" &&
                          data?.payStatus !== "merchant_rejected"
                            ? "text-primary font-semibold"
                            : data?.payStatus === "request-rejected" ||
                              data?.payStatus === "merchant_rejected"
                            ? "text-gray-400 font-semibold"
                            : "text-[#F35929] font-semibold"
                        }`}
                      >
                        {data?.type === "credit" ? "+" : "-"}${data?.amountPaid}
                      </td> */}

                      <td className={`px-4 py-3`}>${data?.amountPaid}</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <Popover
                          // content={`${
                          //   data.Status === "pending" ? "In-Process" : ""
                          // }`}
                          placement="bottomLeft"
                        >
                          <div
                            className={`border-none ${
                              data?.payStatus === "requested" ||
                              data?.payStatus === "transfered"
                                ? "bg-[#E9AE00] text-white"
                                : // : data?.payStatus === "request-accepted"
                                // ? "bg-primary text-white"
                                data?.payStatus === "merchant_rejected" ||
                                  data?.payStatus === "request-rejected"
                                ? "bg-[#FF3636] text-white"
                                : data?.payStatus === "expired"
                                ? "bg-[#E8DCD8] text-gray-700"
                                : "bg-primary text-white"
                            }  p-1 pl-4 pr-4 rounded-md text-center  focus:border-none cursor-pointer capitalize`}
                          >
                            {transformString(
                              data?.payStatus === "merchant_paid"
                                ? "Paid"
                                : data?.payStatus === "transfered"
                                ? "Pending"
                                : data?.payStatus
                            )}
                          </div>
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
                // current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
      {showContact && (
        <ContactInfo setShow={setShowContact} email={email} phone={phone} />
      )}
    </section>
  );
};

export default Paget;
