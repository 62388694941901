import { TimePicker } from "antd";
import React, { useState } from "react";
import deleteimg from "../../../imgs/delete.svg";
import moment from "moment";
const SearchModal = ({ setShow }) => {
  const [selectedDate, setSelectedDate] = useState();
  const selectedDates = [
    "2022-09-07",
    "2022-09-08",
    "2022-09-09",
    "2022-09-10",
    "2022-09-11",
  ];
  const selectedDateandTime = [
    {
      date: "2022-09-07",
      startTime: "09:00 AM",
      endTime: "12:00 PM",
    },
    {
      date: "2022-09-08",
      startTime: "02:00 PM",
      endTime: "06:00 PM",
    },
    {
      date: "2022-09-09",
      startTime: "09:00 AM",
      endTime: "12:00 PM",
    },
  ];

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div
        className={`bg-[#F6F4F4] rounded-lg relative sm:w-[30rem] w-[23rem] h-auto px-6 py-6`}
      >
        <div className="w-full gap-3  rounded-md">
          <h1 className="font-semibold text-gray-800 text-[0.9rem] mb-2">
            Change Schedule
          </h1>
          <div className=" p-6">
            <div className="grid grid-cols-5 gap-3 mb-3">
              {selectedDates?.map((date) => (
                <button
                  key={date}
                  onClick={() => setSelectedDate(date)}
                  className={`px-4 py-2 rounded-lg ${
                    selectedDate === date
                      ? "bg-primary text-white"
                      : "bg-white"
                  } font-medium text-md`}
                >
                  {moment(date).format("DD") + " "}
                  {moment(date).format("MMM")}
                </button>
              ))}
            </div>
            <div className="space-y-3 mt-4">
              {selectedDateandTime?.map((slot, index) => (
                <div key={index} className="flex space-x-2 items-center">
                  <TimePicker
                    placeholder="Start Time"
                    format={"HH:mm A"}
                    value={
                      slot?.startTime ? moment(slot.startTime, "HH:mm A") : null
                    }
                    className="pointer-events-none bg-gray-100"
                  />
                  <TimePicker
                    placeholder="End Time"
                    format={"HH:mm A"}
                    value={
                      slot?.endTime ? moment(slot.endTime, "HH:mm A") : null
                    }
                    className="pointer-events-none bg-gray-100"
                  />
                  <button
                    //   onClick={() => removeTimeSlot(index)}
                    className="text-red-500"
                  >
                    <img src={deleteimg} alt="delete" className="w-7 h-7" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-5">
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                // searchFunction();
                setShow(false);
              }}
              type="submit"
              className="ml-5 cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
