import React, { useState, useEffect, useRef } from "react";

import logo from "../../../../imgs/sapNew.svg";
import reqSent from "../../../../imgs/reqSent.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import DeclinedModal from "./modal";
import MainForm from "./responseModal";
import { useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";

const Main = () => {
  const [note, setNote] = useState("");
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [type, setType] = useState("");
  const [showDeclined, setShowDeclined] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${id}/for-child`
      )
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  }, [refetch]);

  const Accept = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${data?.id}/credit/parent-request/status`,
        {
          payStatus: "request-accepted",
        }
      )
      .then((data) => {
        setShowForm(true);
        setType("accept");
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const qrRef = useRef();

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector("canvas");
    if (canvas) {
      const dataUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "qr-code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div
      className={`bg-[#D9EFED] flex flex-col ${
        showForm ? "h-screen" : "h-screen"
      }`}
    >
      <div
        className={`flex justify-center items-center flex-col  bg-[#D9EFED]  gap-5 p-6 ${
          data?.payStatus === "request-accepted" ? "h-auto" : "h-screen"
        } ${data?.payStatus === "request-accepted" ? "p-2" : "p-10"}`}
      >
        <div className={`bg-white rounded-lg relative ${showForm ? "" : ""}`}>
          <div className="relative rounded-xl sm:w-[28rem] w-[22rem]  sm:pb-8 sm:pt-5 flex items-center p-6  flex-col mb-6">
            <div
              className={`flex items-center justify-center rounded-full ${
                data?.payStatus === "request-accepted"
                  ? "w-[4rem] h-[4rem]"
                  : "w-[8rem] h-[8rem]"
              }`}
            >
              {/* Replace with your logo */}
              <img
                src={data?.payStatus === "request-accepted" ? reqSent : logo}
                alt=""
                className="w-full h-full"
              />
            </div>
            <div
              className={` flex items-center justify-center flex-col ${
                data?.payStatus === "request-accepted" ||
                data?.payStatus === "request-rejected"
                  ? "hidden"
                  : ""
              }`}
            >
              <p className="text-center w-[80%] mb-1 font-semibold">
                {capitalize(data?.pensionerUser?.firstName)}{" "}
                {data?.pensionerUser?.middleName &&
                  capitalize(data?.pensionerUser?.middleName)}{" "}
                {capitalize(data?.pensionerUser?.lastName)} is requesting $
                {data?.amountPaid}.
              </p>
              <p className="text-center">
                {" "}
                {capitalize(data?.pensionerUser?.firstName)}{" "}
                {data?.pensionerUser?.middleName &&
                  capitalize(data?.pensionerUser?.middleName)}{" "}
                {capitalize(data?.pensionerUser?.lastName)} has requested a
                payment of ${data?.amountPaid} {/* </p> */}
                {/* <p className="mb-2"> */}
                from you for{" "}
                {data?.purpose
                  ? data?.purpose
                  : data?.reason
                  ? data?.reason
                  : ""}
                .
              </p>
            </div>

            <div
              className={`flex items-center justify-center flex-col ${
                data?.payStatus === "request-accepted" ||
                data?.payStatus === "request-rejected"
                  ? "hidden"
                  : ""
              }`}
            >
              <p className="text-center w-full mb-1 font-semibold">
                Are you going to pay that amount to{" "}
                {capitalize(data?.pensionerUser?.firstName)}{" "}
                {data?.pensionerUser?.middleName &&
                  capitalize(data?.pensionerUser?.middleName)}{" "}
                {capitalize(data?.pensionerUser?.lastName)}
              </p>
              <div className="flex gap-4 w-[70%] items-center justify-center mt-2">
                <button
                  onClick={() => {
                    Accept();
                  }}
                  className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary cursor-pointer"
                >
                  Accept
                </button>
                <button
                  onClick={() => {
                    setShowDeclined(true);
                    setNote("");
                    // setType("reject");
                    // setShow(true);
                  }}
                  className="w-[8rem] p-1 border-2 font-bold text-[#EF4D5C] rounded-lg bg-white border-[#EF4D5C] cursor-pointer"
                >
                  Decline
                </button>
              </div>
            </div>

            <div
              className={`flex items-center justify-center flex-col ${
                data?.payStatus === "request-accepted" ||
                data?.payStatus === "request-rejected"
                  ? ""
                  : "hidden"
              }`}
            >
              <p
                className={`text-center w-full mb-1 font-bold text-lg font-bold
              ${
                data?.payStatus === "request-accepted"
                  ? "text-primary"
                  : "text-red-500"
              }
              `}
              >
                {data?.payStatus === "request-accepted"
                  ? "Request Accepted"
                  : "Request Declined"}
              </p>
            </div>
            <h2
              className={`${
                data?.payStatus === "request-rejected" ? " " : "hidden"
              }`}
            >
              Rejection Note
            </h2>
            <textarea
              className={`h-auto border-[1px] border-gray-300 rounded-md p-2 mt-2 bg-gray-100 resize-none w-[90%] ${
                data?.payStatus === "request-rejected" ? "" : "hidden"
              }`}
              placeholder="Rejection Note"
              value={data?.rejectionNote}
            ></textarea>
            <div
              className={`flex items-center justify-center flex-col ${
                data?.payStatus === "request-accepted" ? "" : "hidden"
              }`}
            >
              <p className="text-center w-[90%] text-sm text-gray-600">
                <span
                  className={`${
                    data?.requestedByChild === true ? "" : "hidden"
                  }`}
                >
                  {capitalize(data?.pensionerUser?.firstName)}{" "}
                  {data?.pensionerUser?.middleName &&
                    capitalize(data?.pensionerUser?.middleName)}{" "}
                  {capitalize(data?.pensionerUser?.lastName)} has approved a
                  payment of{" "}
                  <span className="font-bold text-[0.98rem]">${data?.amountPaid}</span>.{" "}
                </span>
                Please {data?.requestedByChild === true ? "collect" : "pay"} the
                amount within 24 hours, as the link will expire
              </p>
              <div ref={qrRef} className="mt-4">
                <QRCodeCanvas
                  value={`${data?.paymentId}::quickpay`}
                  size={130}
                />
              </div>
              <div className="flex gap-4 w-[90%] items-center justify-center mt-5 mb-2">
                <button
                  onClick={() => {
                    downloadQRCode();
                  }}
                  className="w-[13rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
                >
                  Download QR Code
                </button>
              </div>
              <p>OR</p>
              <div className="w-full px-4">
                <p className="mb-2 text-gray-700 mt-1  text-center font-semibold  text-[0.93rem]">
                  Your Payment ID
                </p>
              </div>
              <input
                type="text"
                className="lg:text-[0.89rem] text-gray-800 font-semibold w-[18rem] text-center bg-gray-100 border-[1px] rounded-[0.3rem] p-2 pointer-events-none"
                value={data?.paymentId}
              />
              <p className="text-center w-full mt-1 text-sm text-gray-600">
                You can also show this ID to the merchant if they cannot
                scan your QR code
              </p>
            </div>
          </div>
          <div
            className={`absolute  bottom-2 text-gray-400 text-center w-full rounded-md p-2 mt-3 ${
              data?.payStatus === "request-accepted" ||
              data?.payStatus === "request-rejected"
                ? "hidden"
                : ""
            }`}
          >
            This message is coming from SAP Money
          </div>
        </div>
        <p
          className={` ${
            data?.payStatus === "request-accepted"
              ? "sm:text-center text-center text-[#EF4D5C] inline-block align-text-bottom"
              : "hidden"
          }`}
        >
          Please wait at the merchant's location until the payment is complete.
          You will be notified upon successful processing or rejection. A
          receipt will be sent to you afterward.
        </p>
      </div>

      {/* Error modal component */}
      {show && <ErrorModal setShow={setShow} err={err} />}
      {showDeclined && (
        <DeclinedModal
          setShow={setShowDeclined}
          note={note}
          setNote={setNote}
          data={data}
          setShowError={setShow}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      )}
      {showForm && (
        <MainForm
          setShow={setShowForm}
          setRefetch={setRefetch}
          refetch={refetch}
          type={type}
          setType={setType}
          data={data}
          setShowError={setShow}
          setErr={setErr}
        />
      )}
    </div>
  );
};

export default Main;
