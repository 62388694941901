import React from "react";
import { useDispatch, useSelector } from "react-redux";
import search from "../../../imgs/searchM.png";
// import person from "../../../imgs/profile.png";
import bell from "../../../imgs/bell.svg";
import back from "../../../imgs/backIcon.png";
import Search from "./search";

import { useNavigate } from "react-router-dom";
import { Badge } from "antd";
const Header = ({ type, header, route, routeFind, routeCondition, value }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  // const location = useLocation();
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const merchantType = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);
  // const merchantEmployeeInfo = useSelector(
  //   (state) => state.merchantEmployeeInfo
  // );
  const companyStore =
    merchantType === "HQ"
      ? typeData?.merchuser?.merchCompany?.name
      : typeData?.customStoreName;

  const profile =
    merchantType === "HQ"
      ? typeData?.merchuser?.merchCompany?.logo
      : merchantType === "store"
      ? typeData?.image
      : dataUser?.data?.user?.person?.image;
  const Name =
    user !== "merchant-owner" &&
    user !== "merchant-employee" &&
    user !== "merchant-admin"
      ? dataUser?.data?.user?.person?.firstName +
        " " +
        dataUser?.data?.user?.person?.lastName
      : user === "merchant-owner" ||
        user === "merchant-employee" ||
        user === "merchant-admin"
      ? companyStore
      : "John Doe";
  return (
    <>
      <div
        className={` ${
          user === "merchant-owner" ||
          user === "merchant-employee" ||
          user === "merchant-admin"
            ? "sm:flex items-center w-[99%] sm:justify-between"
            : "hidden"
        }`}
        style={{ flexWrap: "nowrap", alignItems: "center" }}
      >
        <div className="flex items-center justify-start flex-grow">
          {header === false ? (
            <div className="flex items-center justify-center">
              <h2 className="mb-4 text-xl font-bold flex items-center justify-center">
                <img
                  onClick={() => {
                    routeFind === false
                      ? dispatch({ type: routeCondition, num: value })
                      : router(route);
                  }}
                  src={back}
                  className="w-3 h-3 mr-2 cursor-pointer"
                  alt="back"
                />
                {type}
              </h2>
            </div>
          ) : (
            <h2 className="mb-2 font-bold text-2xl sm:block hidden">
              Welcome <span className="text-primary">{Name}</span>,
            </h2>
          )}
        </div>
        <div className="flex items-center gap-2 flex-grow justify-end ">
          {/* flex-grow mr-2 bg-blue-200 */}
          <div className=" w-[20rem] mr-2 ">
            <Search />
          </div>
          <Badge
            count={9}
            overflowCount={10}
            offset={[0, 10]}
            size="small"
            style={{
              backgroundColor: "#F35929",
            }}
          >
            <img src={bell} className="w-8 h-8" alt="Notification icon" />
          </Badge>
          <div className="items-center ml-6 sm:flex hidden">
            <h1 className="text-gray-700 text-lg font-semibold mr-3">{Name}</h1>
            <img
              src={
                dataUser?.data?.user?.person
                  ? dataUser?.data?.user?.person?.image
                  : profile
              }
              alt="Profile"
              className="w-10 h-10 rounded-full"
              style={{ marginLeft: "auto" }}
            />
          </div>
        </div>
      </div>

      <div
        className={`flex items-center w-full sm:justify-between ${
          user === "pensioner" ||
          user === "employee" ||
          user === "admin" ||
          user === "superadmin"
            ? ""
            : "hidden"
        }`}
        style={{ flexWrap: "nowrap", alignItems: "center" }}
      >
        <div className="flex items-center ">
          {header === false ? (
            <div className="flex items-center justify-center">
              <h2 className="mb-4 text-xl font-bold flex items-center justify-center">
                <img
                  onClick={() => {
                    routeFind === false
                      ? dispatch({ type: routeCondition, num: value })
                      : router(route);
                    // router(route);
                  }}
                  src={back}
                  className="w-3 h-3 mr-2 cursor-pointer"
                  alt="back"
                />{" "}
                {type}
              </h2>
            </div>
          ) : (
            <h2 className={`mb-2 font-bold text-2xl sm:block hidden`}>
              Welcome{" "}
              <span className="text-primary">
                {/* {dataUser?.data?.user?.person?.firstName +
                " " +
                dataUser?.data?.user?.person?.lastName} */}
                {Name}
              </span>
              ,
            </h2>
          )}
        </div>
        <div className="flex items-center justify-center gap-2 mt-3 w-full sm:w-auto ">
          <div
            className={`relative mr-2 w-[18rem] sm:w-auto ${
              user === "employee" || user === "admin" || user === "superadmin"
                ? "pointer-events-none"
                : ""
            }`}
            onClick={() => {
              router("/SapMoney/Pay/PhoneNumber");
            }}
          >
            <input
              type="text"
              placeholder="Pay by name or phone number"
              className="pl-5  py-3 px-4 w-[18rem] sm:w-[17rem] rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
            />

            <img
              src={search}
              className="absolute inset-y-0 right-0 w-6 h-6 fill-current text-gray-400 mr-3 mt-3"
              alt="search"
            />
          </div>

          <Badge
            count={9}
            overflowCount={10}
            offset={[0, 10]}
            size="small"
            style={{
              backgroundColor: "#F35929",
            }}
          >
            <img src={bell} className="w-8 h-8" alt="Notification icon" />
          </Badge>
          <div className={`items-center ml-6 sm:flex hidden`}>
            <h1 className="text-gray-700 text-lg font-semibold mr-3">
              {/* {dataUser?.data?.user?.person?.firstName +
              " " +
              dataUser?.data?.user?.person?.lastName} */}
              {Name}
            </h1>
            <img
              src={
                dataUser?.data?.user?.person
                  ? dataUser?.data?.user?.person?.image
                  : profile
              }
              alt="Profile"
              className="w-10 h-10 rounded-full ml-3 mr-6"
              style={{ marginLeft: "auto" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
