import { Select, Pagination, Avatar, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import profile from "../../../imgs/profile.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import ErrorModal from "../../Merchant/Modals/errorModal";

const Paget = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const distributionCenter = useSelector((state) => state?.distributionCenter);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const options = [
    { value: "pending", label: "Verification Pending" },
    { value: "Basic Detail Verified", label: "Basic Detail Verified" },
    { value: "Identification Detail Verified", label: "Identification Detail Verified" },
    { value: "Biometric Verified", label: "Biometric Verified" },
    { value: "Pension Book Distributed", label: "Pension Book Distributed" },
  ];
  useEffect(() => {
    setShowSearchModal(true);
  }, []);

  useEffect(() => {
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_BANK_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_CHEQUE_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_EWALLET_INFO",
      num: null,
    });
  }, []);
  const columnHeaders = [
    "Ticket#",
    "Waiting Time",
    "Pensioner Name",
    "GID",
    "Gender",
    "DOB",
    "Phone",
    "Email",
    "Approved On",
    "Created On",
    "Status",
  ];
  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      const params = {
        limit: 6,
        skip:
          search || searchDob || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(search && { search: search }),
        ...(status && { verificationStatus: status }),
        priorityStatus: "approved",
        distributioncoverageId: distributionCenter?.id,
        programName: "senior-citizen-Pension",
        // ...(searchDob && { paymentDate: searchDob }),
        // ...(countryCode &&
        //   phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
      };
      fetchEmployees(params, newCancelTokenSource);
    }, delay);
    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, searchDob, countryCode, status, phoneNoo, pagewithsearch]);
  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/priority-request/pensioners-priority-list-by-distribution-coverage`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        console.log("dataaaaaa", data?.data);
        setAllData(data?.data?.rows);
        // setAllData([]);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  const calculateTime = (createdAt) => {
    const now = moment();
    const duration = moment.duration(now.diff(moment(createdAt)));

    // Format the output in a compact form
    let compactTimeDifference = "";

    if (duration.days() > 0) {
      compactTimeDifference += `${duration.days()}d `;
    }
    if (duration.hours() > 0) {
      compactTimeDifference += `${duration.hours()}h `;
    }
    if (duration.minutes() > 0) {
      compactTimeDifference += `${duration.minutes()}m `;
    }

    // Add seconds if it's less than a minute ago
    if (!compactTimeDifference && duration.seconds() > 0) {
      compactTimeDifference += `${duration.seconds()}s `;
    }

    // compactTimeDifference += "ago";
    return compactTimeDifference;
  };
  return (
    <>
      <section className="w-full pl-4 pr-4">
        <div className="mx-auto max-w-screen-xl ">
          <div className="relative sm:rounded-lg overflow-hidden ">
          <div className="w-full flex sm:flex-row flex-col items-center justify-end gap-2 mb-4">
              <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
                <div className="flex sm:w-[16rem] md:w-full w-[20rem] items-center">
                  <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-[5rem]">
                    Filter By
                  </p>
                  <Select
                    placeholder="Select Status"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={status}
                    onChange={(value) => setStatus(value)}
                    size="middle"
                    className="w-full wow"
                    options={options}
                  />
                </div>
              </div>
              <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
                <form className="flex sm:w-[16rem] md:w-full w-[15rem] items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-3     "
                      placeholder="Search"
                      required=""
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>

            {/* table */}
            <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>
                {allData.length === 0 ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="10"
                        className="text-center text-gray-500 text-lg"
                      >
                        <p className="p-10">No data found</p>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {allData.map((data, index) => (
                      <tr
                        onClick={() => {
                          dispatch({
                            type: "VERI_SELECTED_PENSIONER",
                            num: { data, id: data?.pensionerUser?.id },
                          });
                          dispatch({
                            type: "PEN_VER_QUEUE",
                            num:
                              data?.pensionerUser?.verificationqueues?.length >
                              0
                                ? data?.pensionerUser?.verificationqueues[0]
                                : [],
                          });
                          dispatch({
                            type: "PEN_VER_STATUS",
                            num:
                              data?.verificationStatus === null
                                ? "Verification Pending"
                                : data?.verificationStatus?.toggle,
                          });
                          // dispatch({ type: "SEARCH_MODAL_COUNT", num: 2 });
                          router("/pensionerVerification/Profile");
                        }}
                        key={index}
                        className="odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-gray-100  text-md cursor-pointer"
                      >
                        <th
                          scope="row"
                          className="px-4 py-3 font-medium text-primary whitespace-nowrap"
                        >
                          {data?.pensionerUser?.verificationqueues.length > 0
                            ? `${data?.pensionerUser?.verificationqueues?.[0]?.token}`
                            : "-----"}
                        </th>
                        <td className="px-6 py-6 font-semibold text-primary whitespace-nowrap">
                          {data?.pensionerUser?.verificationqueues.length > 0
                            ? `${calculateTime(
                                data?.pensionerUser?.verificationqueues?.[0]?.createdAt
                              )}`
                            : "-----"}
                        </td>
                        <td className="px-2 py-5">
                          <div className="flex items-center">
                            {/* <img
                            className="w-20 ml-0  h-20 rounded-full mr-2 border-2 border-primary "
                            src={
                              item?.beneficiaryuser?.beneficiaryperson !== null
                                ? item?.beneficiaryuser?.beneficiaryperson
                                    ?.image
                                : profile
                            }
                            alt={`pro`}
                          /> */}
                            <Avatar
                              src={
                                data?.pensionerUser?.person?.image
                                  ? data?.pensionerUser?.person?.image
                                  : profile
                              }
                              className="border-2  border-primary mr-2 w-10 h-10"
                            />
                            <p
                              className="mr-6 text-primary capitalize"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {data?.pensionerUser?.person?.firstName
                                ? data.pensionerUser?.person.firstName + " "
                                : "-----"}

                              {data?.pensionerUser?.person?.lastName
                                ? data.pensionerUser?.person.lastName
                                : "-----"}
                            </p>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-primary whitespace-nowrap">
                          {data?.pensionerUser?.gid}
                        </td>
                        <td className="px-6 py-4  text-primary text-primary whitespace-nowrap">
                          {data?.pensionerUser?.person?.gender
                            ? data?.pensionerUser?.person?.gender
                            : "-----"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {dayjs(data.pensionerUser?.person?.dateOfBirth).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.countryCode !== null
                            ? `+${data?.pensionerUser?.countryCode + " " + data?.pensionerUser?.phoneNo}`
                            : "-----"}{" "}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.email ? data?.pensionerUser?.email : "-----"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.status === "approved" ? (
                            <>
                              <p>
                                {dayjs(data?.pensionerUser?.statusChangeDate).format(
                                  "MM/DD/YYYY "
                                )}
                              </p>
                              <p>
                                {dayjs(data?.pensionerUser?.statusChangeDate).format(
                                  " hh:mm A"
                                )}
                              </p>
                            </>
                          ) : (
                            "-----"
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <p>{dayjs(data?.pensionerUser?.createdAt).format("MM/DD/YYYY ")}</p>
                          <p>{dayjs(data?.pensionerUser?.createdAt).format(" hh:mm A")}</p>
                        </td>
                        <td className="px-6 py-5">
                          <Popover>
                            <div
                              className={`border-none ${
                                data?.verificationStatus === null
                                  ? "bg-[#EF4D5C]"
                                  : "bg-primary"
                              } inline-block whitespace-nowrap p-1 px-5 rounded-xl flex items-center justify-center text-white focus:border-none cursor-pointer capitalize`}
                            >
                              {data?.verificationStatus === null
                                ? "Verification Pending"
                                : data?.verificationStatus?.toggle}
                              {/* {data?.status} */}
                              {/* Verification Pending */}
                            </div>
                          </Popover>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <nav
              className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500">
                Showing{" "}
                <span className="font-semibold text-gray-900 ">
                  Page {page}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 ">
                  {totalPages}
                </span>
              </span>
              <div className={`flex justify-end mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </nav>
          </div>
        </div>
      </section>
      {showError && <ErrorModal err={error} setShow={setShowError} />}
    </>
  );
};

export default Paget;
