import { Avatar, Collapse } from "antd";
import Address from "./address";
import Email from "./email";
import MailAddress from "./mailaddress";
import Number from "./number";
import { useSelector } from "react-redux";
const { Panel } = Collapse;
const Headquarter = ({ headquarter, mailAddress, company }) => {
  const dataUser = useSelector((state) => state.dataUser);
  const callback = (key) => {
    console.log(key);
  };
  // bg-[#F4F5FE]

  return (
    <div className="sm:p-10">
      <div className="p-6 bg-white rounded-lg sm:p-8  mb-8 shadow-md ">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-bold leading-none text-gray-900 ">
            Headquarter
          </h3>
        </div>
        <div className="flex items-center">
          <Avatar
            className="sm:w-24 sm:h-24 w-16 h-16 rounded-full object-cover mr-4 border-2 border-primary"
            src={headquarter?.image}
            alt="Profile"
          />
          <div className="text-sm">
            <h4 className="sm:text-xl text-md font-semibold text-primary leading-none mb-2">
              {company
                ? company?.name
                : dataUser?.data?.merchuser?.merchCompany?.name}{" "}
              Headquarter
            </h4>
            {/* <p className="text-md text-gray-600 font-medium">
              Since:{" "}
              {company
                ? company?.yearFounded
                : dataUser?.data?.merchuser?.merchCompany?.yearFounded}
            </p> */}
            {/* <p className="text-md text-gray-600 font-medium">
                            Mon-Fri, 9:00 AM - 5:00 PM
                        </p> */}
          </div>
        </div>
        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            {/* <Panel
                            header="Contact Details"
                            key="1"
                            className="p-3 text-md text-gray-800 font-medium"
                        >
                            <Contact headquarter={headquarter} type={"Head Quarter"} />
                        </Panel> */}
            <Panel
              header="Emails"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Email headquarter={headquarter} type={"Head Quarter"} />
            </Panel>
            <Panel
              header="Contacts"
              key="2"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Number headquarter={headquarter} type={"Head Quarter"} />
            </Panel>
            <Panel
              header="Address"
              key="3"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Address
                headquarter={headquarter}
                title={"Headquarter Address"}
              />
            </Panel>

            <Panel
              header="Mail Address"
              key="4"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <MailAddress
                headquarter={mailAddress}
                title={"Headquarter Mail Address"}
              />
            </Panel>
          </Collapse>
        </div>
        {/* <div className="flex gap-6 w-full p-2 flex-col sm:flex-row mt-6">
          <div className="bg-white sm:w-2/4 w-full p-4 rounded-md">
            <h3 className="text-lg font-bold leading-none text-gray-900 mb-3">
              Address
            </h3>
            <p className="text-gray-700 text-md">
              Street 91090 Ezra Gardens Locality East Berbice-Corentyne, East
              Bank Berbice Country The Country, GY (GUY)Subregion South America
              Region Americas Continent South America
            </p>
          </div>
          <div className="bg-white sm:w-2/4 w-full p-4 rounded-md">
            <h3 className="text-lg font-bold leading-none text-gray-900 mb-3">
              Contact
            </h3>
            <div className="py-3 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm text-gray-700 font-bold">Email address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                johndoe@example.com
              </dd>
            </div>
            <div className="py-3 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm text-gray-700 font-bold">Phone number</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                (123) 456-7890
              </dd>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Headquarter;
