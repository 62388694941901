import React from 'react'
import Pfinancials from "../components/MHSSS Accounting/pensionerFinancials/PListing/Pfinancials"

const paymentM = () => {
  return (
    <div>
     <Pfinancials/>
    </div>
  )
}

export default paymentM