import React from 'react'
import Payment from "../components/MHSSS Accounting/payment/payment"

const paymentM = () => {
  return (
    <div>
     <Payment/>
    </div>
  )
}

export default paymentM