import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import History from "../components/History/historyTable"

const history = () => {
  return (
    <div>
      <Nav />
     <History/>
     <Whatsapp/>
    </div>
  )
}

export default history