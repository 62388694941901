import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
let data;
const LoginE = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const [test, setTest] = useState(true);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    if (profileData.user) {
      
      data = profileData.user;
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [profileData]);
  return (
    <div>
      {data && (
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Login Credentials
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Employee ID:</h2>
          <input
            type="text"
            readOnly
            placeholder={"Employee ID"}
            value={`${data.uniqueId}`}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Mobile:</h2>
          <input
            type="text"
            readOnly
            placeholder={"Phone"}
            value={`${data.countryCode}${data.phoneNo}`}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Email:</h2>
          <input
            type="email"
            readOnly
            value={data.email}
            placeholder={"Email"}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
          />
        </div>
      )}
    </div>
  );
};

export default LoginE;
