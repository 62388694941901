import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const Employee = () => {
  const router = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const merPenReqDetail = useSelector((state) => state.merPenReqDetail);
  const merPenReqCheck = useSelector((state) => state.merPenReqCheck);
  const merchReq = merPenReqCheck
    ? merPenReqCheck
    : location?.state?.merchantSideChildReq;
  const data = merchReq ? merPenReqDetail : location?.state?.data;
  const childData = location?.state?.childData;
  const user = useSelector((state) => state.user);
  console.log("merPenReqDetail", merPenReqDetail);
  const Div = ({ title, value }) => (
    <div
      className={`sm:px-4 py-[4px] px-2 ${
        value === null
          ? "hidden"
          : "sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 grid-cols-2 grid text-[0.95rem]"
      }`}
    >
      <dt
        className={`leading-6 font-semibold ${
          title === "Amount"
            ? "text-white font-semibold text-[1.3rem]"
            : "text-gray-800 "
        }`}
      >
        {title}
      </dt>
      <dd
        className={`sm:col-span-2 sm:mt-0  capitalize  ${
          title === "Total Amount" ? "text-primary font-bold" : "text-gray-700"
        } ${title === "Status" ? "font-bold" : ""} ${
          title === "Amount" ? "text-white font-semibold text-[1.3rem]" : ""
        }`}
      >
        {value}
      </dd>
    </div>
  );

  return (
    <div className="p-2">
      <div className="sm:px-8 mt-6 sm:mt-5">
        {/* <div
          className={` ${
            data?.requestedByChild === false &&
            data?.requestedByPensioner === false
              ? "flex items-center"
              : "hidden"
          }`}
        >
          <img
            className="w-16 h-16 rounded-full mr-3"
            src={data?.image !== null ? data?.image : pro}
            alt="profile"
          />
          <div className="text-sm">
            <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
              {data?.firstName + " " + data?.lastName}
            </h4>

            <p className="mt-1">{data?.emails?.[0]}</p>
          </div>
        </div> */}
        <div className="mt-8">
          <div
            className={`${
              data?.requestedByChild === false &&
              data?.requestedByPensioner === false
                ? "bg-[#969696]  p-2 mb-4"
                : "bg-[#969696]  p-2 mb-4"
            }`}
          >
            <Div title={"Amount"} value={`$${data?.amountPaid}`} />
          </div>
          <Div
            title={"Name"}
            value={`${data?.firstName + " " + data?.lastName}`}
          />
          {/* {data?.requestedByChild === false &&
            data?.requestedByPensioner === false && (
              <Div title={"Total Amount"} value={`$${data?.amountPaid}`} />
            )} */}

          {data?.payStatus === "merchant-paid" && (
            <Div title={"Transaction Id"} value={data?.paymentId} />
          )}

          <Div
            title={"Date & Time"}
            value={moment(data?.createdAt).format("MM/DD/yyyy hh:mm A")}
          />
          <Div title={"Relation"} value={data?.relation} />
          {/* <Div title={"Reason"} value={data?.reason} /> */}
          <Div title={"Purpose"} value={data?.purpose} />
          <Div title={"Note"} value={data?.reason} />
          <Div
            title={"Status"}
            value={
              data?.payStatus === "merchant_paid"
                ? "Paid"
                : data?.payStatus === "transfered"
                ? "Pending"
                : data?.payStatus
            }
          />
          <div
            className={`sm:px-4 py-[4px]  ${
              data?.rejectionNote === null
                ? "hidden"
                : "sm:grid sm:grid-cols-1 sm:gap-2 sm:px-0 grid-cols-1 grid text-[0.95rem]"
            }`}
          >
            <dt className=" leading-6 text-gray-800 font-semibold">Reason</dt>
            <textarea
              readOnly
              className={`bg-[#F6F4F4] p-4 mt-0  border sm:w-[40%] w-[90%]]
              `}
            >
              {data?.rejectionNote}
            </textarea>
          </div>
          <div className={`${merchReq ? "hidden" : ""}`}>
            <div
              className={`${
                data?.merchstore === null && data?.merchCompany === undefined
                  ? "hidden"
                  : ""
              }`}
            >
              <h2 className="mt-5 sm:px-4 text-gray-800 font-semibold text-[0.95rem]">
                Store / HQ Detail
              </h2>
              <div className="bg-[#F6F4F4] p-4 mt-4 sm:ml-4 border sm:w-[40%] w-[90%]">
                <div className="flex items-center">
                  <img
                    className="w-16 h-16 rounded-full mr-3"
                    src={
                      data?.merchstore !== null
                        ? data?.merchstore?.image
                        : data?.merchCompany?.logo
                    }
                    alt="profile"
                  />
                  <div className="text-sm">
                    <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                      {data?.merchstore !== null
                        ? data?.merchstore?.customStoreName
                        : data?.merchCompany?.name}
                    </h4>

                    {/* <p className="mt-1">{"+582 8956589"}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex justify-start gap-1 flex-col py-3  rounded-md ${
              merchReq &&
              data?.requestedByChild === true &&
              data?.payStatus === "requested"
                ? ""
                : "hidden"
            }`}
          >
            <h2 className="mt-5 sm:px-4 text-gray-800 font-semibold text-[0.95rem]">
              Pensioner Details ( Requested From )
            </h2>
            <div
              className={`flex items-center gap-4 bg-[#F6F4F4] w-full p-5 rounded-md  ${
                user === "pensioner" ? "" : "hidden"
              }`}
            >
              <div className="flex items-center">
                <img
                  className="w-16 h-16 rounded-full mr-3"
                  src={
                    data?.merchstore !== null
                      ? data?.merchstore?.image
                      : data?.merchCompany?.logo
                  }
                  alt="profile"
                />
                <div className="text-sm">
                  <h4 className="sm:text-lg font-semibold text-gray-600 truncate leading-none capitalize">
                    {data?.merchstore !== null
                      ? data?.merchstore?.customStoreName
                      : data?.merchCompany?.name}
                  </h4>

                  <p className="mt-1">
                    {data?.merchstore !== null
                      ? data?.merchstore?.primaryPhone
                      : data?.merchheadquarter?.primaryPhone}
                  </p>
                  <p className="mt-1">
                    {data?.merchstore !== null
                      ? data?.merchstore?.primaryEmail
                      : data?.merchheadquarter?.primaryEmail}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`flex items-center gap-4 bg-[#F6F4F4] w-full p-2 rounded-md ${
                user === "pensioner" ? "hidden" : ""
              }`}
            >
              <div className="relative w-[6rem] h-[6rem] overflow-hidden border-2 border-white rounded-full mb-4 ">
                <img
                  className="object-cover w-[6rem] h-[6rem]"
                  alt=""
                  src={
                    merchReq
                      ? data?.user?.person?.image
                      : childData?.pensionerUser?.image
                  }
                />
              </div>

              <div className="">
                <h4 className="sm:text-md font-semibold text-primary truncate  leading-none">
                  {merchReq
                    ? data?.user?.person?.firstName +
                      " " +
                      data?.user?.person?.lastName
                    : childData?.pensionerUser?.firstName +
                      " " +
                      childData?.pensionerUser?.lastName}
                </h4>
                <p className=" text-sm font-semibold mt-1">
                  GID{" "}
                  {merchReq ? data?.user?.gid : childData?.pensionerUser?.gid}
                </p>

                <p className=" text-sm font-semibold">
                  {merchReq
                    ? data?.user?.countryCode !== null &&
                      data?.user?.countryCode +
                        " " +
                        data?.user?.person?.phoneNo
                    : childData?.pensionerUser?.countryCode +
                      " " +
                      childData?.pensionerUser?.phoneNo}
                </p>
                <p className=" text-sm font-semibold">
                  {merchReq
                    ? data?.user?.email
                    : childData?.pensionerUser?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            data?.requestedByChild === true && data?.payStatus === "requested"
              ? "flex justify-start mt-5 ml-3"
              : "hidden"
          }`}
        >
          <button
            className="bg-primary text-white px-5 py-2 rounded-md"
            onClick={() => {
              // router("/SapMoney/Parent/Respond/Child", {
              //   state: { data: data },
              // });

              dispatch({
                type: "SAP_PAYMENT_SELECTED_PERSON",
                num: {
                  penId: data?.pensionerUserId,
                  quickpayId: data?.id,
                  phone:
                    data?.user?.countryCode !== null
                      ? data?.user?.countryCode + data?.user?.person?.phoneNo
                      : "-------",
                  email:
                    data?.user?.email !== null ? data?.user?.email : "-------",
                },
              });
              router("/SapMoney/Merchant/Request/Verification", {
                state: { data: data, merchantSideChildReq: true },
              });

              // router("/SapMoney/Parent/Respond/Child", {
              //   state: { data: data, merchantSideChildReq: true },
              // });
              return;
            }}
          >
            Respond on Behalf of Receiver
          </button>
        </div>
      </div>
    </div>
  );
};

export default Employee;
