import React from "react";

const Transaction = () => {
  const transaction = [
    {
      name: "John Doe",
      amount: 200,
      type: "withdraw",
      date: "12 Mar",
      time: "01:50 PM",
    },
    {
      name: "Yup Store",
      amount: 30,
      type: "deposit",
      date: "25 Mar",
      time: "01:50 PM",
    },
    {
      name: "Medical Store",
      amount: 200,
      type: "withdraw",
      date: "12 Mar",
      time: "01:50 PM",
    },
    
  ];
  return (
    <div className="p-3">
      <h1 className="text-lg font-semibold text-gray-800">Today</h1>
      <div className="bg-[#F5F5F5] p-4 mt-2 rounded-md">
        {transaction.map((transaction, index) => (
          <>
            <div key={index} className="flex justify-between items-center ">
              <div className="flex items-center">
                <div
                  className={`${
                    transaction?.type === "deposit"
                      ? "bg-primary"
                      : "bg-[#F55F6D]"
                  } flex items-center justify-center rounded-full w-10 h-10`}
                >
                  <span className="text-white text-lg font-semibold flex items-center justify-center">
                    {transaction.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <p className="font-semibold">{transaction?.name}</p>
                  <p className="text-sm">
                    {transaction.date} {""} {transaction?.time}
                  </p>
                </div>
              </div>

              <div>
                <p
                  className={`${
                    transaction?.type === "deposit"
                      ? "bg-primary"
                      : "bg-[#F55F6D]"
                  } text-white px-3 rounded-md`}
                >
                  ${transaction.amount}
                </p>
              </div>
            </div>
            <hr className="py-1" />
          </>
        ))}
      </div>

      <h1 className="text-lg font-semibold text-gray-800 mt-3">Yesterday</h1>
      <div className="bg-[#F5F5F5] p-4 mt-2 rounded-md">
        {transaction.map((transaction, index) => (
          <>
            <div key={index} className="flex justify-between items-center ">
              <div className="flex items-center">
                <div
                  className={`${
                    transaction?.type === "deposit"
                      ? "bg-primary"
                      : "bg-[#F55F6D]"
                  } flex items-center justify-center rounded-full w-10 h-10`}
                >
                  <span className="text-white text-lg font-semibold flex items-center justify-center">
                    {transaction.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <p className="font-semibold">{transaction?.name}</p>
                  <p className="text-sm">
                    {transaction.date} {""} {transaction?.time}
                  </p>
                </div>
              </div>

              <div>
                <p
                  className={`${
                    transaction?.type === "deposit"
                      ? "bg-primary"
                      : "bg-[#F55F6D]"
                  } text-white px-3 rounded-md`}
                >
                  ${transaction.amount}
                </p>
              </div>
            </div>
            <hr className="py-1" />
          </>
        ))}
      </div>
    </div>
  );
};

export default Transaction;
