import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AmountSend from "../../modals/amountSend";
import { Avatar } from "antd";

const Main = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [searchType, setSearchType] = useState("GID");
  const [searchValue, setSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNoo, setPhoneNoo] = useState("");
  const [pensioners, setPensioners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [err, setErr] = useState("");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const token = useSelector((state) => state.token);
  const [email, setEmail] = useState("");
  const [gid, setGid] = useState("");
  const [afterApi, setAfterApi] = useState(false);
  const [amount, setAmount] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const Search = () => {
    dispatch({ type: "SELECTED_PENSIONER_SAP", num: null });
    setAfterApi(false);
    setPensioners([]);
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        ...(gid && { gid }),
        // ...(lastName && { lastName }),
        // ...(dob && { dateOfBirth: dob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        ...(email && { email }),
      };

      fetchPenioners(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  };
  const fetchPenioners = (params, cancelTokenSource) => {
    let api;

    api = "pensioner-profile-by-filters";

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/${api}/by-merchant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setAfterApi(true);
        if (data?.data?.length === 0) {
          setPensioners([]);
          // setErr("No Pensioner Found");
          // setShowModal(true);
        } else {
          // setShowDropdown(true);

          setPensioners(data?.data);
        }
        // }
      })
      .catch((err) => {
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowModal(true);
      });
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
      if (prompt) {
        const wow = parsePhoneNumber(prompt);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };

  const sendAmountPensioner = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/ewallet-payment/merchant`,
        {
          amount: amount,
          pensionerUserId: "7b0cb792-7afc-4f45-8b2f-0a7abcbc84ff",
          receivedCashFrom: "pensioner",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setShowSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectedItems, setSelectedItems] = useState([]);
  console.log(selectedItems);

  const handleCheckboxChange = (id, option) => {
    setSelectedItems((prevSelectedPensioner) =>
      prevSelectedPensioner === id ? null : id
    );
    dispatch({ type: "SELECTED_PENSIONER_SAP", num: option });
  };

  // const isSelected = selectedItems.includes(option?.user?.person?.id);

  return (
    <div className="p-8">
      <div className="bg-[#F6F4F4] p-8 rounded-lg  mb-4">
        <h2 className="text-lg font-semibold mb-2">Search Pensioner</h2>
        <div className="">
          <div className="flex space-x-4  p-2">
            <div className="flex items-center  bg-white px-4 py-1 rounded-lg border h-[3rem] w-[27rem] overflow-hidden">
              {searchType === "Phone" ? (
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={handleChange(setPrompt, "tt")}
                  international
                  countryCallingCodeEditable={false}
                  // className="mr-4 border:none"
                  className="custom-phone-input"
                />
              ) : (
                <input
                  type="text"
                  placeholder={`Search ${searchType}`}
                  value={
                    searchType === "GID"
                      ? gid
                      : searchType === "Email"
                      ? email
                      : searchValue
                  }
                  onChange={(e) =>
                    searchType === "GID"
                      ? setGid(e.target.value)
                      : searchType === "Email"
                      ? setEmail(e.target.value)
                      : setSearchValue(e.target.value)
                  }
                  className="p-2 bg-transparent outline-none text-gray-700 w-[18rem]"
                />
              )}

              <div className="border-l border-gray-300 h-full"></div>
              <select
                value={searchType}
                onChange={(e) => {
                  setSearchType(e.target.value);
                  setGid("");
                  setEmail("");
                  setSearchValue("");
                  setPrompt("");
                  setCountryCode("");
                  setPhoneNoo("");
                }}
                className="p-2 bg-transparent text-gray-700 outline-none w-[9rem]"
              >
                <option value="GID">GID</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone Number</option>
              </select>
            </div>

            <button
              className="bg-primary text-white  px-10 rounded-lg text-lg"
              onClick={() => {
                Search();
              }}
            >
              Search
            </button>
          </div>
          {pensioners.length === 0 && afterApi === true ? (
            <p className="p-4 text-gray-700 text-lg text-center mt-5">
              No Pensioner Found
            </p>
          ) : (
            <div>
              <ul className="py-1">
                {pensioners?.map((option) => (
                  <li
                    key={option?.user?.person?.id}
                    className={`w-[80%]  sm:w-[27rem] block px-4 py-3 text-sm text-gray-700 cursor-pointer mx-3 my-2 rounded-md ${
                      selectedItems === option?.user?.person?.id
                        ? "bg-[#D9EFED]"
                        : "bg-[#FFFFFF]"
                    }`}
                  >
                    <div className="relative flex items-center gap-2">
                      <Avatar
                        src={option?.user?.person?.image}
                        style={{
                          backgroundColor: "#1BA397",
                        }}
                        shape="circle"
                        className="w-24 h-24 rounded-full"
                      />
                      <div>
                        <h1 className="text-[1rem] font-bold mb-1">
                          {option?.user?.person?.firstName}{" "}
                          {option?.user?.person?.lastName}
                        </h1>
                        <p className="font-semibold">
                          GID: {option?.user?.gid}
                        </p>
                        <p className="font-semibold">{option?.user?.email}</p>
                      </div>
                      <input
                        type="checkbox"
                        className="absolute top-[-9px] right-[-15px] w-[1.3rem] h-[1.3rem]"
                        checked={selectedItems === option?.user?.person?.id}
                        onChange={() =>
                          handleCheckboxChange(option?.user?.person?.id, option)
                        }
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="bg-[#F6F4F4] p-8 rounded-lg ">
        <h2 className="text-lg font-semibold mb-2">Received Cash from</h2>
        <div className="flex space-x-4 py-2 text-[1rem]">
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="cashSource"
              value="Pensioner"
              className="form-radio custom-radio"
              onChange={(e) => setSelectedType(e.target.value)}
              checked={selectedType === "Pensioner"}
            />
            <span>Pensioner</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="cashSource"
              value="SomeoneElse"
              className="form-radio custom-radio"
              onChange={(e) => setSelectedType(e.target.value)}
              checked={selectedType === "SomeoneElse"}
            />
            <span>Someone else</span>
          </label>
        </div>
        <div className={`${selectedType === "Pensioner" ? "" : "hidden"}`}>
          <h2 className="text-lg font-semibold mb-2 mt-4">Amount Received</h2>
          <input
            type="text"
            placeholder={`Enter Amount`}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="p-3 bg-white outline-none text-gray-700 w-[40%] rounded-lg"
          />
        </div>
      </div>
      <div
        className={`flex items-center justify-center mt-8 ${
          selectedType === null ? "hidden" : ""
        }`}
      >
        <button
          // onClick={nextHandler}
          onClick={() => {
            if (selectedType === "Pensioner") {
              sendAmountPensioner();
            } else {
              router("/SapMoney/QuickPay");
            }
          }}
          className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 px-12`}
        >
          {selectedType === "Pensioner" ? "Add to SAP Money" : "Next"}
        </button>
      </div>
      {showModal && <ErrorModal err={err} setShowModal={setShowModal} />}
      {showSuccessModal && (
        <AmountSend
          setShow={setShowSuccessModal}
          value={"Amount has been Transferred to pensioner SAP Money account"}
          title={"Payment Sent"}
          route={"/SapMoney"}
        />
      )}
    </div>
  );
};

export default Main;
