import React, { useEffect, useState } from "react";
import store1 from "../../../imgs/store1.svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CalendarFilled, DownOutlined } from "@ant-design/icons";
import ErrorModal from "../Modals/errorModal";
import ChartCard from "./ChartCard";
import moment from "moment";
import { DatePicker } from "antd";
import axios from "axios";
import StoreModal from "../Pensioner/Pay/StoreModal";
import days from "dayjs";
const ProfileCard = () => {
  const dispatch = useDispatch();
  const [selectedStore, setSelectedStore] = useState();
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([12, 19, 3, 5]);
  const [stores, setStores] = useState("");
  const currentDate = new Date();
  const [month, setMonth] = useState(moment(currentDate).format("MM"));
  const [year, setYear] = useState(moment(currentDate).format("YYYY"));
  const [showErrModal, setShowErrModal] = useState(false);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [allData, setAllData] = useState([]);
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  console.log("DataReport", dataUser);
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/reports/statistics?month=${month}&year=${year}`;

    // Check if selectedStore has an id before adding it to the API URL
    const finalApiUrl = selectedStore?.id
      ? `${apiUrl}&merchstoreId=${selectedStore.id}`
      : apiUrl;

    axios
      .get(finalApiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  }, [month, year, selectedStore]);

  //get Stores
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-store`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setStores(data?.data?.rows);
      })
      .catch(() => {});
  }, []);

  return (
    <div className="mt-[-2rem] sm:mt-[-4rem]">
      <div className="sm:mx-8 mb-12">
        <div className="sm:p-8 p-3 bg-white  mt-8 rounded-lg">
          <div className="w-full  sm:p-8 py-4 px-5 rounded-lg mb-10 relative border">
            <div
              className={`ml-2 mr-2 flex items-center justify-between pt-5 p-4 rounded-lg mb-4 cursor-pointer 
              bg-[#F4F5FE]
                `}
            >
              <div className="flex items-center w-full">
                <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                  <CalendarFilled
                    style={{ fontSize: "26px", color: "#1BA397" }}
                  />
                </div>
                <div className="ml-4 w-full">
                  <DatePicker
                    className="date-classMerchant"
                    value={date ? days(date, "YYYY-MM-DD") : null}
                    onChange={(date, dateString) => {
                      console.log(date);
                      setDate(dateString);
                      setMonth(moment(dateString).format("MM"));
                      setYear(moment(dateString).format("YYYY"));
                    }}
                  />
                  {/* <input
                    type="text"
                    name=""
                    
                    placeholder="Search By transaction number"
                    className={`
              lg:text-[1rem]
           w-[100%] bg-zinc-50 rounded-[0.5rem] p-3`}
                  /> */}
                </div>
              </div>
            </div>

            {!dataUser?.data?.merchuser?.isGovtAgency && (
              <div
                className={`ml-2 mr-2 flex items-center justify-between pt-5 p-4 rounded-lg mb-4 cursor-pointer 
              bg-[#F4F5FE]
                `}
                onClick={() => setShowStoreModal(true)}
              >
                <div className="flex items-center w-full">
                  <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                    <img src={store1} alt="store" className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3
                      className={`text-lg font-semibold mb-1 
                        text-primary
                      `}
                    >
                      Stores
                    </h3>
                    <p>{selectedStore?.customStoreName}</p>
                  </div>
                  <div className={` ml-auto mr-2`}>
                    <DownOutlined style={{ color: "#1BA397" }} />
                  </div>
                </div>
              </div>
            )}
            <div
              className={`${
                selectedStore !== undefined ? "" : ""
              } ml-2 mr-2 items-center justify-between pt-5 p-4 rounded-lg mb-4 cursor-pointer bg-[#F4F5FE] shadow-md border`}
            >
              <ChartCard chartdata={data} allData={allData} />
            </div>

            <div
              onClick={() => {
                dispatch({ type: "ALL_REPORTS", num: "All" });
              }}
              className={`${
                dataUser?.data?.merchuser?.status === "pending" ||
                dataUser?.data?.merchuser?.status === "deferred" ||
                dataUser?.data?.merchuser?.status === "rejected"
                  ? "pointer-events-none ml-2 mr-2 flex items-center justify-between pt-5 p-4 rounded-lg mb-4 cursor-pointer bg-white shadow-md border"
                  : "ml-2 mr-2 flex items-center justify-between pt-5 p-4 rounded-lg mb-4 cursor-pointer bg-white shadow-md border"
              }
                `}
            >
              <div className="flex items-center">
                <div className="ml-4">
                  <h3
                    className={`text-lg font-bold mb-1 
                        text-gray-600
                      `}
                  >
                    Reports
                  </h3>
                  <p
                    className={`text-sm
                       text-gray-500
                      
                      `}
                  >
                    View detailed report on individual pensioner
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className={`ml-2 mr-2 flex items-center justify-between pt-5 p-4 rounded-lg mb-4 cursor-pointer 
              bg-[#F5F5F5]
                `}
            >
              <div className="flex items-center w-full">
                <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                  <img src={com} alt="store" className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <h3
                    className={`text-lg font-semibold mb-1 
                        text-[#878D96]
                      `}
                  >
                    My Complaints
                  </h3>
                  <p
                    className={`text-sm
                       text-[#878D96]
                      
                      `}
                  >
                    No New Message
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {showStoreModal && (
        <StoreModal
          setShowStoreModal={setShowStoreModal}
          stores={stores}
          setSelectedStore={setSelectedStore}
        />
      )}
      {showErrModal && <ErrorModal err={err} setShow={setShowErrModal} />}
    </div>
  );
};

export default ProfileCard;
