import { Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import { useDispatch, useSelector } from "react-redux";
import { purposeOptions } from "../../constants/purpose";
import logo from "../../../../imgs/sapNew.svg";
import { useLocation, useNavigate } from "react-router-dom";
import AmountModal from "../../modals/amountSend";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import Verification from "./verification";

const Payment = () => {
  const location = useLocation();
  const penProfile = location?.state?.penProfile;
  console.log(penProfile, "penProfile");
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const uuid = useSelector((state) => state.uuid);
  const dataUser = useSelector((state) => state.dataUser);
  const [name, setName] = useState("");
  const [gid, setGid] = useState("");
  const [purpose, setPurpose] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const sapPaymentSelectedPerson = useSelector(
    (state) => state.sapPaymentSelectedPerson
  );
  console.log(sapPaymentSelectedPerson, "pERSON");

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [value, setValue] = useState("");
  const [selectedType, setSelectedType] = useState("Store");
  const [phone, setPhone] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const verify = useSelector((state) => state.pensionerSenderTypeVerification);
  const merchantSapType = useSelector((state) => state.merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);
  const [regionNo, setRegionNo] = useState("");
  const [regionName, setRegionName] = useState("");
  const [lot, setLot] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState([]);
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);
  const totalBalance = useSelector((state) => state.totalBalance);

  const dispatch = useDispatch();

  useEffect(() => {
    if (penProfile) {
      setSelectedType("Pensioner");
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          setCity(null);
          setAllCities("");
          setAllWards("");
          setRegionName("");
          setWard(null);
          setRegionName(data.data.data[0]);
        })
        .catch((err) => {});
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
        })
        .catch((err) => {});
    }
  }, [regionName]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
        })
        .catch((err) => {});
    }
  }, [city]);

  const payment = () => {
    setShowConfirmModal(false);
    if (amount === "") {
      setErr("Please enter amount");
      setShowErrorModal(true);
      return;
    }

    if (purpose === "" && selectedType !== "Pensioner") {
      setErr("Please select purpose");
      setShowErrorModal(true);
      return;
    }

    const body = {
      toSapWalletInfoId: sapPaymentSelectedPerson?.id,
      amount: amount,
      purpose: purpose,
    };

    if (reason !== "") {
      body.note = reason;
    }

    if (selectedType === "Other") {
      delete body.notes;
      delete body.amount;
      delete body.toSapWalletInfoId;

      body.amountPaid = amount;
      body.sapwalletinfoId = sapPaymentSelectedPerson?.id;

      console.log("lot");

      if (lot !== "") {
        body.lot = lot;
      }

      if (reason !== "") {
        body.note = reason;
      }
      if (name !== "") {
        body.name = name;
      }

      if (phoneNoo && countryCode && phone !== "") {
        body.phone = phone;
      }

      if (email !== "") {
        body.email = email;
      }

      if (gid !== "") {
        body.gid = gid;
      }

      if (regionNo !== "") {
        body.regionNo = regionNo;
      }

      if (regionName !== "") {
        body.regionName = regionName;
      }

      if (city !== "") {
        body.city = city;
      }

      if (ward !== "") {
        body.village = ward;
      }
    }

    if (selectedType === "Pensioner") {
      body.fromUserId = sapPaymentSelectedPerson?.fromId;
      body.tempUUID = uuid;
    }

    if (merchantSapType === "store") {
      body.merchstoreId = merchantSapTypeData?.id;
    } else {
      body.merchheadquarterId =
        merchantSapTypeData?.merchuser?.merchheadquarterId;
    }

    const api =
      selectedType === "Other"
        ? "v1/other-quick-payments"
        : "v1/sap-wallet-info/merchant-pay-sap-member";

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setValue(
          `Amount $${amount} has been Paid to  ${sapPaymentSelectedPerson?.name}`
        );

        setShowAmountModal(true);
      })
      .catch((err) => {
        console.log("Error:", err); // Log the error for debugging
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const makeFav = () => {
    const body = {
      sapwalletinfoId: sapPaymentSelectedPerson?.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/favorite-person/pensioner`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setShowAmountModal(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const validateEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsValid(validateEmail(value));
  };

  const handleChange1 = (setChange, check) => (event) => {
    if (check === "tt" || check === "tn") {
      setChange(event);
    } else {
      setChange(event.target.value);
    }
  };

  return (
    <div className="w-full">
      <div className="sm:p-5">
        {/* Pesnioer */}
        <div className="bg-[#F5F5F5] p-6 rounded-md">
          <div
            className={`flex items-center
                `}
          >
            <img
              src={sapPaymentSelectedPerson?.image}
              alt="profile"
              className="w-24 h-24 rounded-full mr-2 ml-2"
            />
            <div className={`flex flex-col`}>
              <p className="font-bold text-[1.1rem] capitalize">
                {/* {sapPaymentSelectedPerson?.user?.person?.firstName
                  ? capitalize(
                      sapPaymentSelectedPerson?.user?.person?.firstName
                    )
                  : "----"}
                {sapPaymentSelectedPerson?.user?.person?.middleName
                  ? " " +
                    capitalize(
                      sapPaymentSelectedPerson?.user?.person?.middleName
                    )
                  : ""}
                {sapPaymentSelectedPerson?.user?.person?.lastName
                  ? " " +
                    capitalize(sapPaymentSelectedPerson?.user?.person?.lastName)
                  : ""} */}
                {capitalize(sapPaymentSelectedPerson?.name)}
              </p>

              <p className="font-semibold text-gray-400 text-[0.78rem]">
                {sapPaymentSelectedPerson?.phone !== "-------"
                  ? sapPaymentSelectedPerson?.phone
                  : ""}
              </p>
              <p className="font-semibold text-gray-400 text-[0.78rem]">
                {sapPaymentSelectedPerson?.email !== "-------"
                  ? sapPaymentSelectedPerson?.email
                  : ""}
              </p>
            </div>
          </div>
          {/* STORE */}
          {/* <div
            className={`${
              sapPaymentSelectedPerson?.type === "merch-store"
                ? "flex items-center"
                : "hidden"
            }`}
          >
            <img
              src={sapPaymentSelectedPerson?.merchstore?.image}
              alt="profile"
              className="w-24 h-24 rounded-full mr-2 ml-2"
            />
            <div className={`flex flex-col`}>
              <p className="font-bold text-[1.1rem] capitalize">
                {sapPaymentSelectedPerson?.merchstore
                  ? capitalize(
                      sapPaymentSelectedPerson?.merchstore?.customStoreName
                    )
                  : "----"}
              </p>

              <p className="font-semibold text-gray-400 text-[0.78rem]">
                {sapPaymentSelectedPerson?.merchstore !== null
                  ? sapPaymentSelectedPerson?.merchstore?.primaryPhone
                  : ""}
              </p>
              <p className="font-semibold text-gray-400 text-[0.78rem]">
                {sapPaymentSelectedPerson?.merchstore !== null
                  ? sapPaymentSelectedPerson?.merchstore?.primaryEmail
                  : ""}
              </p>
            </div>
          </div> */}
          {/* HEADQUARTER */}
          {/* <div
            className={`${
              sapPaymentSelectedPerson?.type === "merch-headquarter"
                ? "flex items-center"
                : "hidden"
            }`}
          >
            <img
              src={sapPaymentSelectedPerson?.merchheadquarter?.image}
              alt="profile"
              className="w-24 h-24 rounded-full mr-2 ml-2"
            />
            <div className={`flex flex-col`}>
              <p className="font-bold text-[1.1rem] capitalize">
               
                HQ
              </p>

              <p className="font-semibold text-gray-400 text-[0.78rem]">
                {sapPaymentSelectedPerson?.merchheadquarter !== null
                  ? sapPaymentSelectedPerson?.merchheadquarter?.primaryPhone
                  : ""}
              </p>
              <p className="font-semibold text-gray-400 text-[0.78rem]">
                {sapPaymentSelectedPerson?.merchheadquarter !== null
                  ? sapPaymentSelectedPerson?.merchheadquarter?.primaryEmail
                  : ""}
              </p>
            </div>
          </div> */}
        </div>
        {/* Profile Card */}

        <div
          className={`bg-[#F6F4F4] p-4 rounded-lg mt-5 ${
            verify === true ? "hidden" : ""
          }`}
        >
          <h2
            className={`${
              penProfile
                ? "hidden"
                : "text-[0.95rem] font-semibold mb-2 sm:mx-4 text-gray-600"
            }`}
          >
            Sender
          </h2>
          <div
            className={`${
              penProfile
                ? "hidden"
                : "flex sm:space-x-12 space-x-2 text-[0.95rem] bg-white sm:p-6 px-3 py-5 rounded-sm sm:mx-4 sm:w-[75%] mb-5 w-full"
            }`}
          >
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="cashSource"
                value="Store"
                className="form-radio custom-radio"
                onChange={(e) => setSelectedType(e.target.value)}
                checked={selectedType === "Store"}
              />
              <span>
                {merchantSapType === "store"
                  ? merchantSapTypeData?.customStoreName
                  : merchantSapTypeData?.merchuser?.merchCompany?.name}
              </span>
            </label>
            <label
              className={`flex items-center space-x-2 ${
                sapPaymentSelectedPerson?.type === "pensioner" ? "" : "hidden"
              }`}
            >
              <input
                type="radio"
                name="cashSource"
                value="Pensioner"
                className="form-radio custom-radio"
                onChange={(e) => setSelectedType(e.target.value)}
                checked={selectedType === "Pensioner"}
              />
              <span>{capitalize(sapPaymentSelectedPerson?.name)}</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="cashSource"
                value="Other"
                className="form-radio custom-radio"
                onChange={(e) => setSelectedType(e.target.value)}
                checked={selectedType === "Other"}
              />
              <span>Other</span>
            </label>
          </div>
          <div
            className={`${
              selectedType === "Other" ? "px-5 sm:w-[70%] w-full" : "hidden"
            }`}
          >
            <h2 className="text-[0.95rem] font-semibold mb-2 text-gray-600">
              Enter Phone number or Email
            </h2>
            <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[100%]">
              <div>
                <div className="flex flex-col">
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="GY"
                    international
                    value={phone}
                    onChange={(phone) => {
                      const value = phone || "";
                      setPhone(value);
                      setPrompt(value);
                      const wow = parsePhoneNumber(value);

                      let count = wow?.countryCallingCode;
                      let num = wow?.nationalNumber;
                      setCountryCode(count);
                      setPhoneNoo(num);
                    }}
                    countryCallingCodeEditable={false}
                    className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <div className="flex items-center w-full gap-1">
                    <label className="text-gray-700 text-[0.9rem] font-semibold w-[4rem]">
                      Email <span className="text-red-600"></span>
                    </label>
                    <input
                      type="text"
                      placeholder="Email"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mt-2"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                  {!isValid && email && (
                    <p className="text-red-500 text-sm mt-1 ml-[4rem]">
                      Invalid email format
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[100%]">
              <div>
                <div className="flex flex-col">
                  <label className="text-gray-600 text-[0.88rem] font-semibold mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    name=""
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                    placeholder="Name"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <label className="text-gray-600 text-[0.88rem] font-semibold mb-2">
                    GID #
                  </label>
                  <input
                    type="text"
                    name=""
                    onChange={(e) => {
                      setGid(e.target.value);
                    }}
                    value={gid}
                    placeholder="GID #"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
              </div>
            </div>

            <h2 className="text-[0.95rem] font-semibold mb-2 text-gray-600">
              Address
            </h2>
            <div className="flex justify-center items-center">
              <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[100%]">
                <div>
                  <div className="flex flex-col">
                    <input
                      type="text"
                      name=""
                      onChange={(e) => {
                        setLot(e.target.value);
                      }}
                      value={lot}
                      placeholder="Lot #"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>

                  <div className="flex flex-col">
                    <Select
                      placeholder="Region No"
                      optionFilterProp="children"
                      className="wow"
                      style={{ marginBottom: "1.2rem" }}
                      onChange={handleChange1(setRegionNo, "tn")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="middle"
                      options={allRegions}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Select
                      placeholder="Ward/Village"
                      optionFilterProp="children"
                      className="wow"
                      style={{ marginBottom: "1.2rem" }}
                      value={ward}
                      onChange={handleChange1(setWard, "tt")}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="middle"
                      options={allWards}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col">
                    <input
                      type="text"
                      name=""
                      onChange={handleChange1(setRegionName, "tt")}
                      value={regionName}
                      placeholder="Region Name"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4 pointer-events-none"
                    />
                  </div>

                  <div className="flex flex-col">
                    <Select
                      placeholder="Town/City"
                      optionFilterProp="children"
                      className="wow"
                      style={{
                        marginBottom: "1.2rem",
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={handleChange1(setCity, "tt")}
                      value={city}
                      size="middle"
                      options={allCities}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={``}>
            <div className="py-2 px-5 sm:w-[45%] w-full">
              <div className="w-full mb-2">
                <label className="text-gray-600 mb-1 block text-[0.95rem] font-semibold">
                  Enter Amount{" "}
                  <span className="text-red-500">
                    {" "}
                    <span
                      className={`text-sm text-gray-500 ${
                        selectedType === "Pensioner" || selectedType === "Other"
                          ? ""
                          : "hidden"
                      }`}
                    >
                      (Cash Received)
                    </span>
                  </span>
                </label>
                <input
                  type="text"
                  name=""
                  placeholder="Enter Amount"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
              <div className={`w-full mb-2 `}>
                <label className="text-gray-600 mb-1 block text-[0.9rem]  font-semibold">
                  Purpose <span className="text-red-500"></span>
                </label>
                <Select
                  // value={statuss}
                  onChange={(e) => {
                    setPurpose(e);
                  }}
                  placeholder="Select Purpose"
                  showSearch
                  optionFilterProp="children"
                  className="wow capitalize  rounded-[0.3rem] "
                  //   className="sqw lg:text-[1.1rem] h-[4rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={purposeOptions}
                />
              </div>
              {/* Reason Textarea */}
              <div className="w-full mb-2">
                <label className="text-gray-600 mb-1 block text-[0.9rem] font-semibold">
                  Reason <span className="text-red-500"></span>
                </label>
                <textarea
                  onChange={(e) => setReason(e.target.value)}
                  // value={statusReason}
                  type="text"
                  placeholder="Enter Reason"
                  className="sqw lg:text-[1.1rem] h-[4rem] w-full  rounded-[0.3rem] p-2 border-[1px] border-[#C6CFE5] "
                />
              </div>
            </div>
          </div>
        </div>
        {verify && (
          <div className="mt-10">
            <Verification setShowConfirmModal={setShowConfirmModal} />
          </div>
        )}

        <div
          className="flex items-center mt-5"
          onClick={() => {
            // payment();

            if (amount === "") {
              setErr("Please enter amount");
              setShowErrorModal(true);
              return;
            }

            if (purpose === "") {
              setErr("Please select purpose");
              setShowErrorModal(true);
              return;
            }

            if (selectedType === "Pensioner") {
              dispatch({
                type: "PENSIONER_SENDER_TYPE_VERIFICATION",
                num: true,
              });
              return;
            }
            if (selectedType === "Other") {
              if (phone === "" && email === "") {
                setErr("Enter either phone number or email");
                setShowErrorModal(true);
                return;
              }
              if (name === "") {
                setErr("Enter name");
                setShowErrorModal(true);
                return;
              }

              if (gid === "") {
                setErr("Enter GID");
                setShowErrorModal(true);
                return;
              }

              if (lot === "") {
                setErr("Enter lot number");
                setShowErrorModal(true);
                return;
              }

              if (regionNo === "") {
                setErr("Select region number");
                setShowErrorModal(true);
                return;
              }

              if (regionName === "") {
                setErr("Select region name");
                setShowErrorModal(true);
                return;
              }

              if (city === null) {
                setErr("Select city");
                setShowErrorModal(true);
                return;
              }

              if (ward === null) {
                setErr("Select Village");
                setShowErrorModal(true);
                return;
              }
              if (totalBalance < amount) {
                setErr(
                  "You don't have enough balance in your wallet for this payment"
                );
                setShowErrorModal(true);
                return;
              }

              setShowConfirmModal(true);
            }
            if (totalBalance < amount) {
              setErr(
                "You don't have enough balance in your wallet for this payment"
              );
              setShowErrorModal(true);
              return;
            }

            setShowConfirmModal(true);
          }}
        >
          <button
            className={`${
              verify
                ? "hidden"
                : "cursor-pointer rounded-md px-8 py-2 text-white bg-primary  mx-6 text-[0.9rem] font-semibold"
            }`}
          >
            Pay Amount
          </button>
        </div>
      </div>
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      {showAmountModal && (
        <AmountModal
          setShow={setShowAmountModal}
          route={"/SapMoney"}
          title={"Payment Done"}
          value={value}
        />
      )}

      {/* Confirmation Modal */}
      <div
        className={`${
          showConfirmModal ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl sm:w-[28rem] w-[22rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center sm:w-[70%] w-[90%] mt-2 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${amount}</span> to{" "}
            {sapPaymentSelectedPerson?.name}
          </p>

          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                setShowConfirmModal(false);
                if (totalBalance < amount) {
                  setErr(
                    "You don't have enough balance in your wallet for this payment"
                  );
                  setShowErrorModal(true);
                  return;
                }
                payment();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmModal(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
