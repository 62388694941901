import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Biometric from "../../../biometric/main";
let data;
const Identification2 = () => {
  const profileData = useSelector((state) => state.profileData);
  const location = useLocation();
  const dash = location.pathname;
  const benDone = useSelector((state) => state.benDone);
  const [test, setTest] = useState(false);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);

  useEffect(() => {
    if (profileData.pensionerInfo || profileData.beneficiaryInfo) {
      console.log(profileData, "Profile Dataa");
      if (
        dash === "/Dashboard/BeneficiaryAccount" ||
        dash === "/EDashboard/BeneficiaryAccount" ||
        dash === "/superAdminDashboard/BeneficiaryAccount" ||
        dash === "/AdminDashboard/BeneficiaryAccount"
      ) {
        data = profileData.beneficiaryInfo;
      } else {
        data = profileData.pensionerInfo;
      }

      console.log("Data", data);
    }
  }, [profileData]);

  useEffect(() => {
    if (dash === "/Dashboard/PensionerAccount") {
      // setAllDis(true);
    } else {
      // setAllDis(false);
      if (benDone !== "Done") {
        if (profileData?.user?.isDisabled) {
          // setAllDis(true);
        }
      }
    }
    if (benDone !== "Done") {
      // setStatus(profileData?.user?.status);
    }
  }, []);

  return (
    <div>
      {data && (
        <div>
          <div>
            <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-4">
              Biometric
            </h2>

            <Biometric />
          </div>
        </div>
      )}
      <div className={`${data ? "hidden" : ""}`}>No Data</div>
    </div>
  );
};

export default Identification2;
