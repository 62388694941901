export const popupReducer = (state = false, action) => {
  switch (action.type) {
    case "POP":
      return !state;
    default:
      return state;
  }
};
export const resetReducer = (state = false, action) => {
  switch (action.type) {
    case "RESET":
      return !state;
    default:
      return state;
  }
};
export const polReducer = (state = false, action) => {
  switch (action.type) {
    case "pol":
      return !state;
    default:
      return state;
  }
};
export const pol1Reducer = (state = false, action) => {
  switch (action.type) {
    case "pol1":
      return !state;
    default:
      return state;
  }
};
export const polUserReducer = (state = "", action) => {
  switch (action.type) {
    case "polIdSet":
      return action.num;
    default:
      return state;
  }
};
export const polNameReducer = (state = "", action) => {
  switch (action.type) {
    case "polNameSet":
      return action.num;
    default:
      return state;
  }
};
export const polStatusReducer = (state = "", action) => {
  switch (action.type) {
    case "polStatusSet":
      return action.num;
    default:
      return state;
  }
};

export const placePopReducer = (state = false, action) => {
  switch (action.type) {
    case "placePop":
      return !state;
    default:
      return state;
  }
};
export const authStateReducer = (state = false, action) => {
  switch (action.type) {
    case "authStateSet":
      return action.num;
    default:
      return state;
  }
};
