import React from 'react'
import Cheque from "../components/MHSSS Accounting/outstanding/PayOutstanding/cheque/chequeIndex"

const DashB = () => {
  return (
    <div>
     <Cheque/>
    </div>
  )
}

export default DashB