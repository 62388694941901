import React, { useEffect } from 'react';
// import toast, { Toaster } from 'react-hot-toast';
// import { onMessageListener, requestPermission } from '../../utlis/firebase';
const Notification = () => {
  // const [notification, setNotification] = useState({ title: '', body: '' });
  // console.log('notification', notification);
  useEffect(() => {
    // Request permission for notifications
    // requestPermission();
    // const unsubscribe = onMessageListener()
    //   .then((payload) => {
    //     console.log('payload', payload);
    //     setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    //     toast.success(`${payload?.notification?.title} ${payload?.notification?.body}`, {
    //       duration: 4000,
    //       position: 'top-right',
    //     });
    //   })
    //   .catch((err) => console.log('failed: ', err));
    return () => {
      // unsubscribe.catch((err) => console.log('failed: ', err));
    };
  }, []);
  return (
    <div>
      {/* Other components in the Notification component */}
      {/* <button onClick={notify}>Make me a toast</button> */}
      {/* <Toaster />/ */}
    </div>
  );
};

export default Notification;
