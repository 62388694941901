import PcardView from "../components/PAccount/PcardView";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const PViewPage = () => {
  return (
    <div>
      <Nav />
      <PcardView />
      <Whatsapp/>
    </div>
  );
};

export default PViewPage;
