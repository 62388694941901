import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import ReportMain from "./ReportMain";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table";
import { useEffect } from "react";
const Listingh = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const allReports = useSelector((state) => state.allReports);
  useEffect(() => {
    dispatch({ type: "ALL_REPORTS", num: false });
  }, []);
  return (
    <div className="">
      <div
        className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                allReports
                  ? dispatch({ type: "ALL_REPORTS", num: false })
                  : router("/Merchant/dashboard");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              {allReports === "Recent"
                ? "Recent Transactions"
                : allReports === "All"
                ? "Search Pensioner"
                : "Reports"}
            </h2>
          </div>
        </div>
      </div>
      {allReports === "All" ? <Table /> : <ReportMain />}
      {/* <ReportMain /> */}
    </div>
  );
};

export default Listingh;
