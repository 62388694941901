import React, { useEffect, useState } from "react";
import imageUrl from "../../../../../imgs/bgset.png";
import InfoModal from "../../../outstanding/PersonTransaction/Vouchers/infoModal";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
const Main = () => {
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  const token = useSelector((state) => state.token);
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);
  const user = useSelector((state) => state.user);
  const selectedGovAgency = useSelector((state) => state.selectedGovAgency);
  const dataUser = useSelector((state) => state.dataUser);
  const depositMoneyData = useSelector((state) => state.depositMoneyData);
  const [data, setData] = useState(depositMoneyData);
  const [method, setMethod] = useState(data?.method);
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");
  const [chequeData, setChequeData] = useState({});
  const [bank, setBank] = useState({});
  const merchantId = selectedGovAgency?.merchUser?.id;
  const [bankImage, setBankImage] = useState(null);
  const [chequeImage, setChequeImage] = useState(null);
  console.log(data, "Data");

  const firstName =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.merchuser?.merchCompany?.name
      : merchantEmployeeInfo?.data?.merchPerson?.firstName;
  const lastNameShow =
    user === "merchant-owner" || user === "merchant-admin"
      ? ""
      : merchantEmployeeInfo?.data?.merchPerson?.lastName;

  const image =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.merchuser?.merchCompany?.logo
      : merchantEmployeeInfo?.data?.merchPerson?.image;

  useEffect(() => {
    if (isGovernmentAgency) {
      if (data?.type === "requestHistory" && data?.status === "mhsss_paid") {
        BalanceHistory();
      }
    }
  }, []);

  useEffect(() => {
    if (isGovernmentAgency) {
      if (method === "cheque") {
        imageConverter(data?.chequeImg);
      }
      if (method === "bank") {
        imageConverter(data?.bankReceiptImg);
      }
      return;
    }
    if (method === "cheque") {
      Cheque();
      imageConverter(data?.chequeImg);
    }
    if (method === "bank") {
      Banking();
      imageConverter(data?.bankReceiptImg);
    }
  }, []);

  //getCheque
  const Cheque = () => {
    if (method === "bank") {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setChequeData(data?.data);
      })
      .catch(() => {});
  };
  //getBanking
  const Banking = () => {
    if (method === "cheque") {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setBank(data?.data);
      })
      .catch((err) => {});
  };

  //getBanking
  const BalanceHistory = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-histories/get-by-filter/for-govt-agency?bankbalancerequestId=${data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMethod(response?.data?.method);
        const res = {
          type: "requestHistory",
          id: data?.id,
          method: response?.data?.method,
          reqDate: data?.reqDate,
          paymentId: response?.data?.paymentId,
          transactionData: data?.transactionData,
          bankReceiptImg: response?.data?.bankReceiptImg,
          bankReceiptNo: response?.data?.bankReceiptNo,
          chequeImg: response?.data?.chequeImg,
          chequeNo: response?.data?.chequeNo,
          amount: data?.amount,
        };
        setData(res);
        if (response?.data?.method === "cheque") {
          imageConverter(response?.data?.chequeImg, response?.data?.method);
        }
        if ((response?.data?.method === "bank", response?.data?.method)) {
          imageConverter(response?.data?.bankReceiptImg);
        }
      })
      .catch((err) => {});
  };

  const imageConverter = (url, type) => {
    const api =
      user === "admin" || user === "superadmin" || user === "employee"
        ? "v1/file/get-image-url?fileType=paymentRecipet"
        : "v1/file/merchat-file-presigned-url?fileType=paymentRecipet";
    const tok =
      user === "admin" || user === "superadmin" || user === "employee"
        ? dataUser?.data?.tokens?.access?.token
        : dataUser?.data?.merchTokens?.access?.token;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/${api}`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((response) => {
        const image = response?.data?.[0]?.url;
        setChequeImage(image);

        // type === "cheque" ? setChequeImage(image) : setBankImage(image);
      })
      .catch((err) => {
        // Handle error
      });
  };

  console.log(chequeImage, "Cheque Image", method);
  console.log(bankImage, "Bank Image");

  const maskAccountNumber = (accountNumber) => {
    let maskedNumber = "";
    for (let i = 0; i < accountNumber?.length; i++) {
      if (i < accountNumber?.length - 4) {
        maskedNumber += "X";
      } else {
        maskedNumber += accountNumber[i];
      }
    }
    return maskedNumber;
  };

  return (
    <div className="w-full h-full  bg-white rounded-lg py-7 sm:px-3 ">
      <div className="flex items-center justify-center w-full  font-poppins">
        {/* {vcd ? ( */}
        <div className="relative sm:w-[95%] h-full rounded-tl-xl rounded-tr-xl bg-[#E0EFE899] border border-primary shadow-lg mb-10">
          <div
            className={`${
              data?.type === "requestHistory" &&
              data?.status === "mhsss_rejected"
                ? "rounded-tl-lg  rounded-tr-lg w-full p-4 flex justify-between items-center h-[15%] bg-red-600"
                : "rounded-tl-lg  rounded-tr-lg w-full p-4 flex justify-between items-center h-[15%] bg-primary"
            }`}
          >
            <div className="text-left text-center text-white">
              <p className="text-sm">{`${
                isGovernmentAgency
                  ? firstName + " " + lastNameShow
                  : selectedGovAgency?.merchUser?.merchcompany?.name
              }`}</p>
            </div>
            <div className="text-right text-white">
              <p className="text-sm">
                AMOUNT: <span className="font-bold ">${data?.amount}</span>
              </p>
            </div>
          </div>
          <div className="w-full mt-8 gap-7 flex items-center justify-center flex-col">
            <div className="sm:w-[90%] w-full h-auto rounded-lg flex">
              <div
                className="w-full h-full flex rounded-full rounded-tr-none rounded-br-none sm:flex-row flex-col sm:bg-white items-center "
                style={
                  {
                    // backgroundImage: `url(${imageUrl})`,
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                  }
                }
              >
                <div className="ml-1 h-auto w-[9rem] rounded-full border-[5px] border-white overflow-hidden">
                  <img
                    src={
                      isGovernmentAgency
                        ? image
                        : selectedGovAgency?.merchUser?.merchcompany?.logo
                    }
                    alt="Background"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="w-full sm:ml-4 h-full flex items-center border-r flex-col gap-y-1 pr-4 p-2">
                  <div className="flex flex-wrap w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Requested On :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px]">
                      {data?.reqDate !== null && data?.reqDate !== undefined
                        ? moment(data?.reqDate).format("MM/DD/YYYY hh:mm A")
                        : "-------"}
                    </p>
                  </div>

                  <div className="flex flex-wrap w-full justify-between">
                    <h2 className="text-left font-semibold text-[13px]">
                      Payment Id :
                    </h2>
                    <p className="text-right font-semibold text-[12px] break-words">
                      {data?.type === "requestHistory" &&
                      (data?.status === "mhsss_rejected" ||
                        data?.status === "requested")
                        ? "-----"
                        : data?.paymentId}
                    </p>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Payment Method :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words capitalize">
                      {data?.type === "requestHistory" &&
                      (data?.status === "mhsss_rejected" ||
                        data?.status === "requested")
                        ? "-----"
                        : method}
                    </p>
                  </div>
                  <div
                    className={`${
                      data?.type === "requestHistory" &&
                      (data?.status === "mhsss_rejected" ||
                        data?.status === "requested")
                        ? "flex flex-wrap w-full"
                        : "hidden"
                    }`}
                  >
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Status
                    </h2>
                    <p
                      className={`w-[50%] text-right font-semibold text-[12px] break-words ${
                        data?.status === "mhsss_rejected"
                          ? "text-red-500"
                          : "text-blue-500"
                      }`}
                    >
                      {data?.status === "mhsss_rejected"
                        ? "Rejected"
                        : "Requested"}
                    </p>
                  </div>
                  <div
                    className={`${
                      data?.type === "requestHistory" &&
                      (data?.status === "mhsss_rejected" ||
                        data?.status === "requested")
                        ? "hidden"
                        : "flex flex-wrap w-full"
                    }`}
                  >
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Transaction Date :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {moment(data?.transactionData).format(
                        "MM/DD/YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                  <div
                    className={`${
                      data?.type === "requestHistory" &&
                      (data?.status === "mhsss_rejected" ||
                        data?.status === "requested")
                        ? "hidden"
                        : "flex flex-wrap w-full"
                    }`}
                  >
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      {method === "cheque"
                        ? "Cheque Number"
                        : "Bank transaction Id"}
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {method === "cheque"
                        ? data?.chequeNo
                        : data?.bankReceiptNo}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                isGovernmentAgency
                  ? "bg-white flex flex-col gap-4 w-[90%] p-5 ml-10 mr-10 mb-6"
                  : "bg-white grid  sm:grid-cols-2 grid-cols-1 gap-4 w-[90%] p-5 ml-6 mr-6 mb-6"
              }`}
            >
              <div className={`${isGovernmentAgency ? "hidden" : ""}`}>
                <h1 className="font-semibold text-[0.9rem] mb-2 text-gray-700">
                  {method === "cheque" ? "Cheque Detail" : "Bank Detail"}
                </h1>
                <div
                  className={`bg-gray-100  w-[80%] relative rounded-lg cursor-pointer  mb-5 h-auto"
        `}
                >
                  <div className="flex flex-row items-center p-2 ">
                    <div
                      className={`absolute bg-primary top-5 flex items-center justify-center rounded-full w-14 h-14`}
                    >
                      <img
                        src={
                          method === "cheque"
                            ? selectedGovAgency?.merchUser?.merchcompany?.logo
                            : bank?.countrybank?.logo
                        }
                        alt="logo"
                        className={`w-14 h-14 rounded-full`}
                      />
                    </div>
                    <div className="flex items-center ml-14">
                      <div className="ml-3 flex flex-col mb-4">
                        <p className="font-semibold text-basic capitalize">
                          {method === "cheque"
                            ? selectedGovAgency?.merchUser?.merchcompany?.name
                            : bank?.accountTitle}
                        </p>
                        <p
                          className={`${
                            method === "cheque" ? "hidden" : "text-basic"
                          }`}
                        >
                          {bank?.countrybank?.name}
                        </p>
                        <p className="font-semibold">
                          {method === "cheque"
                            ? maskAccountNumber(chequeData?.phone)
                            : maskAccountNumber(bank?.accountNo)}
                        </p>
                        <p
                          className={`${
                            method === "cheque" ? "text-basic" : ""
                          }`}
                        >
                          {chequeData?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  chequeImage === null && bankImage === null ? "hidden" : ""
                }`}
              >
                <h1 className="font-semibold text-[0.9rem] mb-2 text-gray-700">
                  {method === "cheque" ? "Cheque Image" : "Receipt Image"}
                </h1>
                <div
                  className={`${
                    isGovernmentAgency ? "flex items-center justify-center" : ""
                  }`}
                >
                  <img
                    className="h-auto w-auto"
                    // src={method === "cheque" ? chequeImage : bankImage}
                    src={chequeImage}
                    alt="Img"
                  />
                </div>
              </div>

              {/* Note */}
              <div
                className={`${
                  data?.type === "requestHistory" &&
                  (data?.status === "mhsss_rejected" ||
                    data?.status === "requested")
                    ? ""
                    : "hidden"
                }`}
              >
                <h1 className="font-semibold text-[0.9rem] mb-2 text-gray-700">
                  {data?.status === "mhsss_rejected"
                    ? "Rejection Note"
                    : "Note"}
                </h1>
                <textarea
                  className="w-full h-[100px] border border-primary p-2 rounded-lg pointer-events-none"
                  placeholder="Enter Note"
                  value={
                    data?.status === "mhsss_rejected"
                      ? data?.rejectionNote
                      : data?.note
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <InfoModal content={content} setShow={setShow} />}
    </div>
  );
};

export default Main;
