import React, { useEffect, useState } from "react";
import balancebg from "../../../../imgs/balancebg.png";
import gasBill from "../../../../imgs/gasBill.svg";
import wBill from "../../../../imgs/wBill.svg";
import eBill from "../../../../imgs/eBill.svg";
import recharge from "../../../../imgs/recharge.svg";
import ptsm from "../../../../imgs/ptsm.svg";
import Quick from "../../../../imgs/quickp.png";
import paySap from "../../../../imgs/paySap.svg";
import req from "../../../../imgs/req.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import moment from "moment";

const Left = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  console.log(dataUser);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [dataOutStandings, setDataOutStandings] = useState([]);
  const [err, setErr] = useState("");
  const [showError, setShowError] = useState(false);
  const [afterApi, setAfterApi] = useState(false);
  const totalBalance = useSelector((state) => state.totalBalance);
  const otherOptions = [
    {
      name: "Electricity Bill",
      icon: eBill,
    },

    {
      name: "Water Bill",
      icon: wBill,
    },
    {
      name: "Gas Bill",
      icon: gasBill,
    },

    {
      name: "Recharge",
      icon: recharge,
    },
  ];

  useEffect(() => {
    if (user !== "pensioner") {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/favorite-person/pensioner?limit=6&page=1&skip=0&sortBy=createdAt:asc`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setData(data?.data?.rows);
        setAfterApi(true);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    if (
      user === "pensioner" ||
      user === "merchant-employee" ||
      user === "merchant-owner"|| user === "merchant-admin"
    ) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transactions?limit=10&skip=0&page=1&sortBy=createdAt:asc`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setDataOutStandings(data?.data?.rows);
        setAfterApi(true);
      })
      .catch((err) => {});
  }, []);

  const staticData = [
    {
      name: "Smart Store",
      date: "12th Mar 01:50 PM",
      amount: "$5612",
    },
    {
      name: "Smart Store",
      date: "12th Mar 01:50 PM",
      amount: "$5612",
    },
    {
      name: "Smart Store",
      date: "12th Mar 01:50 PM",
      amount: "$5612",
    },
    {
      name: "Smart Store",
      date: "12th Mar 01:50 PM",
      amount: "$5612",
    },
    {
      name: "Smart Store",
      date: "12th Mar 01:50 PM",
      amount: "$5612",
    },
    {
      name: "Smart Store",
      date: "12th Mar 01:50 PM",
      amount: "$5612",
    },
  ];

  return (
    <div className="w-20px">
      

      <div
        className={`p-4 rounded-lg shadow-md  text-white pb-12 mb-3 mx-2 `}
        style={{
          backgroundImage: `url(${balancebg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h3 className="text-md font-semibold mt-2">Available Balance</h3>
        <p className="text-[2rem] font-semibold text-center mt-5">
          ${totalBalance}
        </p>
      </div>

   

      <div className="flex gap-3  items-center justify-center mt-2 mx-2 ">
        {user === "pensioner" ? (
          <div className="flex items-center justify-between bg-[#F6FBF9] rounded-md p-3 w-full px-4">
            <div
              onClick={() => {
                router("/SapMoney/QuickPay");
              }}
              className=" cursor-pointer"
            >
              <img
                src={Quick}
                alt="payDiv"
                className="w-20 h-20 left-shadow rounded-full "
              />
            </div>
            <div
              onClick={() => {
                router("/SapMoney/Pay/PhoneNumber");
              }}
              className=" cursor-pointer "
            >
              <img src={paySap} alt="payDiv" className="w-20 h-20 " />
            </div>
            <div
              onClick={() => {
                router("/SapMoney/RequestMoney");
              }}
              className="cursor-pointer "
            >
              <img src={req} alt="payDiv" className="w-20 h-20 " />
            </div>
            {/* <div className="bg-[#F6FBF9] rounded-md shadow-md p-2 w-2.5/4">
              <div
                className="flex gap-2 h-full items-center justify-center cursor-pointer"
                onClick={() => {
                  router("/SapMoney/Pay/PhoneNumber");
                }}
              >
                <img src={sapMem} alt="payDiv" className="w-8 h-8" />
                <p className="text-[#256091] font-semibold">
                  Pay to SAP Members
                </p>
              </div>
            </div> */}
          </div>
        ) : (
          (user === "merchant-owner" || user === "merchant-employee"|| user === "merchant-admin") && ( // <div
            //   onClick={() => {
            //     router("/SapMoney/merchant/addSapMoney");
            //   }}
            //   className="bg-[#F6FBF9] rounded-md flex items-center shadow-md justify-center h-[4.8rem] mt-2 cursor-pointer shadow-md p-2 w-[92%] ml-2"
            // >
            //   <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center mr-2" />
            //   <p> Add SAP Money</p>
            // </div>
            <div className="flex items-center justify-center bg-[#F6FBF9] rounded-md px-2 py-6 gap-3 w-full">
              <div
                onClick={() => {
                  // if (dataUser?.data?.merchStatus?.status === "approved")
                    router("/SapMoney/Pay/PhoneNumber");
                  // else {
                  //   setErr("User is not Approved");
                  //   setShowError(true);
                  // }
                }}
                className=" cursor-pointer "
              >
                <img src={ptsm} alt="payDiv" className="w-auto h-15 " />
              </div>
              {/* <div
              onClick={() => {
                router("/SapMoney/merchant/addSapMoney");
              }}
              className="cursor-pointer "
            >
              <img src={addsap} alt="payDiv" className="w-auto h-20 " />
            </div> */}
            </div>
          )
        )}
      </div>
      <div
        className={`bg-[#F6FBF9]  mt-3 p-3 relative mx-2 h-full  ${
          user === "merchant-owner" || user === "merchant-employee"|| user === "merchant-admin"
            ? ""
            : "hidden"
        }`}
      >
        <h1 className="font-semibold text-md mt-2">Quick Actions</h1>
        <p className="text-gray-400 text-sm mt-1">List of quick actions</p>
        {/* <p className="absolute top-3 right-3 text-primary font-semibold cursor-pointer">
          View All
        </p> */}
        <div className="grid grid-cols-2 gap-3 mt-4">
          {otherOptions?.map((beneficiary, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center bg-white rounded-md p-4 mt-2"
            >
              <img src={beneficiary.icon} alt="profile" className="w-7 h-7" />
              <p className="text-[13px] font-semibold mt-2 text-center">
                {beneficiary.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`bg-[#F6FBF9] mx-2 mt-3 p-4 ${
          user === "pensioner" ? "" : "hidden"
        } ${data?.length === 0 ? "h-[19.5rem]" : "h-[19.5rem]"}`}
      >
        <h1 className="font-semibold text-md">Your Favorites</h1>
        <p className="text-gray-400 text-sm ">List of your favorite persons</p>
        {afterApi === true && data.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-400 text-sm">No favorite person yet</p>
          </div>
        ) : (
          <div className="grid grid-cols-3  gap-3 mt-4">
            {data?.slice(0, 6)?.map((data, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center cursor-pointer "
                onClick={() => {
                  dispatch({
                    type: "SAP_PAYMENT_SELECTED_PERSON",
                    num: {
                      id: data?.sapwalletinfoId,
                      type: data?.sapwalletinfo?.type,
                      fromId: data?.sapwalletinfo?.user?.id,
                      image:
                        data?.sapwalletinfo?.type === "pensioner"
                          ? data?.sapwalletinfo?.user?.person?.image
                          : data?.sapwalletinfo?.type === "merch-store"
                          ? data?.sapwalletinfo?.merchstore?.image
                          : data?.sapwalletinfo?.merchheadquarter?.image,

                      email:
                        data?.sapwalletinfo?.type === "pensioner"
                          ? data?.sapwalletinfo?.user?.email
                          : data?.sapwalletinfo?.type === "merch-store"
                          ? data?.sapwalletinfo?.merchstore?.primaryEmail
                          : data?.sapwalletinfo?.merchheadquarter?.primaryEmail,

                      name:
                        data?.sapwalletinfo?.type === "pensioner"
                          ? data?.sapwalletinfo?.user?.person?.firstName +
                            " " +
                            data?.sapwalletinfo?.user?.person?.lastName
                          : data?.sapwalletinfo?.type === "merch-store"
                          ? data?.sapwalletinfo?.merchstore?.customStoreName
                          : "HQ",
                      // : data?.sapwalletinfo?.merchcompany?.name,

                      phone:
                        data?.sapwalletinfo?.type === "merch-store"
                          ? data?.sapwalletinfo?.merchstore?.primaryPhone ||
                            "-------"
                          : data?.sapwalletinfo?.type === "merch-headquarter"
                          ? data?.sapwalletinfo?.merchheadquarter
                              ?.primaryPhone || "-------"
                          : data?.sapwalletinfo?.type === "pensioner"
                          ? data?.sapwalletinfo?.user?.countryCode &&
                            data?.sapwalletinfo?.user?.phoneNo
                            ? `${data?.sapwalletinfo?.user?.countryCode}${data?.sapwalletinfo?.user?.phoneNo}`
                            : "-------"
                          : "-------",
                    },
                  });
                  // if (user === "pensioner") {
                  //   dispatch({ type: "PAYMENT", num: true });
                  //   return;
                  // }
                  if (user === "pensioner") {
                    dispatch({ type: "PAYMENT", num: true });
                    router("/SapMoney/Pay/PhoneNumber", {
                      state: { dash: true },
                    });

                    return;
                  }
                }}
              >
                <img
                  src={
                    data?.sapwalletinfo?.type === "pensioner"
                      ? data?.sapwalletinfo?.user?.person?.image
                      : data?.sapwalletinfo?.type === "merch-store"
                      ? data?.sapwalletinfo?.merchstore?.image
                      : data?.sapwalletinfo?.merchheadquarter?.image
                  }
                  alt="profile"
                  className="w-12 h-12 rounded-full"
                />
                <p className="text-xs font-semibold mt-2 text-center capitalize">
                  {data?.sapwalletinfo?.type === "merch-store" &&
                    data?.sapwalletinfo?.merchstore?.customStoreName}
                  {data?.sapwalletinfo?.type === "merch-headquarter" && "HQ"}
                  {data?.sapwalletinfo?.type === "pensioner" &&
                    data?.sapwalletinfo?.user?.person?.firstName +
                      " " +
                      data?.sapwalletinfo?.user?.person?.lastName}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className={`bg-[#F6FBF9] mx-2 mt-3 p-2 ${
          user !== "pensioner" &&
          user !== "merchant-owner" &&
          user !== "merchant-employee" && user !== "merchant-admin"
            ? ""
            : "hidden"
        } ${staticData?.length === 0 ? "h-[19.5rem]" : "h-[25rem]"}`}
      >
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-md">Merchant Outstanding</h1>
          <p className="top-3 right-3 text-primary text-sm font-semibold cursor-pointer">
            View All
          </p>
        </div>
        <p className="text-gray-400 text-sm ">List of merchant outstandings</p>
        {afterApi === true && dataOutStandings?.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-400 text-sm">
              No merchant outstandings yet
            </p>
          </div>
        ) : (
          <div className="flex flex-col w-full items-center mt-2 justify-center">
            {dataOutStandings?.slice(0, 6)?.map((data, index) => (
              <div
                key={data.id}
                className="flex justify-between w-full mb-2 py-1 border-b items-center "
              >
                <div>
                  <p className={`font-semibold capitalize`}>
                    {data?.merchuser?.merchcompany !== null
                      ? data?.merchuser?.merchcompany?.name
                      : "------"}
                  </p>
                  <p className="text-[0.8rem]">
                    {moment(data?.updatedAt).format("MM/DD/YYYY hh:mm A")} {""}
                  </p>
                </div>
                <div>
                  <p className={`bg-gray-200 px-3 rounded-md`}>
                    ${data?.totalAmount ? data?.totalAmount : data?.amountPaid}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {showError && <ErrorModal err={err} setShow={setShowError} />}
    </div>
  );
};

export default Left;
