import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";

let file;
const FacialRecognitionModal = ({
  setValue,
  value,
  setShowSuccessModal,
  setShowErrModal,
  err,
  setErr,
}) => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const token = useSelector((state) => state.token);
  const show = false;
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );
  console.log(err, value);
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    file = imageSrc;
    setCapturedImage(imageSrc);
    // uploadImage(imageSrc);
  }, [webcamRef]);

  const uploadHandler = () => {
    setValue("");

    const byteString = atob(file.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: "image/jpeg" });

    // Create form data
    const formData = new FormData();
    formData.append("file", blob, "image.jpg");
    // let couponIds = [];
    // selectedVoucherForCash?.map((item) => {
    //   couponIds.push(item.id);
    // });

    const couponIds = selectedVoucherForCash?.map((item) => item.id);

    // Construct the query string with each couponId separated by '&'
    const couponIdsQueryString = couponIds
      .map((id, index) => `couponIds[${index}]=${id}`)
      .join("&");
    // bodyFormData.append("couponIds", couponIds);
    axios({
      method: "post",
      url: `${
        process.env.REACT_APP_BASE_URL
      }/v1/merch-redeem-coupon/merch-verify-pensioner/pol?pensionerUserId=${
        selectedPensioner?.user?.id
      }&${couponIdsQueryString}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        if (data?.data?.faceDetection === false) {
          setErr("Sorry the picture did not match");
          setShowErrModal(true);
        } else {
          setErr("Pensioner Successfully Verified ");
          setShowSuccessModal(true);

          // dispatch({ type: "pol" });
          // setRefetch(!refetch)
          // setDisplay4("");
        }
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-slate-500/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="relative lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[30%] sm:mx-0 mx-3 p-4 w-full max-w-md max-h-full bg-white rounded-2xl flex items-center flex-col p-6 pl-5 pr-5">
        <button
          type="button"
          className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          onClick={() => {
            setValue("");
          }}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <h2 className="mb-[1rem] text-[1.5rem] font-bold">
          Facial Recognition
        </h2>

        {capturedImage === null && (
          <div className="w-[20rem] h-[15rem]">
            <Webcam ref={webcamRef} screenshotFormat="image/jpeg" />
          </div>
        )}
        {capturedImage !== null && (
          <div className="relative w-[20rem] h-[15rem]">
            <img src={capturedImage} alt="pol" />
            <div
              className="absolute bottom-0 right-0 cursor-pointer bg-white"
              onClick={() => setCapturedImage(null)}
            >
              <DeleteFilled
                className="w-[3rem]"
                style={{ fontSize: "30px", color: "#1BA397" }}
              />
              {/* <img className="w-[3rem]" src={trash} alt="" /> */}
            </div>
          </div>
        )}
        <button
          onClick={() => {
            if (capturedImage === null) {
              captureImage();
            } else {
              uploadHandler();
            }
          }}
          className={`lg:text-[1.1rem] bg-gradient-to-tr mt-5 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[20rem]`}
        >
          {capturedImage === null ? "Capture" : "Verify"}
        </button>

        <p
          className={`mt-4 text-center text-[0.85rem] ${show ? "" : "hidden"}`}
        >
          Facial recognition in progress. Please wait for the confirmation.
        </p>
      </div>
    </div>
  );
};

export default FacialRecognitionModal;
