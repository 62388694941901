import "./chart.css";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import { Select } from "antd";

const CustomTooltip = ({ active, payload, interval }) => {
  if (active && payload && payload.length) {
    const month = new Date().toLocaleString("default", { month: "long" });

    // Determine the label based on the interval
    const label =
      interval === "monthly"
        ? `${payload[0]?.payload?.name + " " + month}`
        : payload[0]?.payload?.name;

    return (
      <div className="custom-tooltip bg-gray-50 px-3 py-2 rounded shadow">
        <p className="text-gray-600 font-semibold">{label}</p>
        {payload.map((entry, index) => {
          const isCredit = entry.dataKey === "Credit";
          const color = isCredit ? "#1BA397" : "#F55F6D";
          const title = isCredit ? "Credit" : "Debit";

          return (
            <div key={`item-${index}`}>
              <p
                style={{ color }}
                className="label font-semibold"
              >{`${title}: $${entry.value}`}</p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

const Chart = ({
  aspect,
  title,
  finalData,
  setInterval,
  interval,
  totalCredit,
  totalDebit,
}) => {
  const data = finalData;
  const user = useSelector((state) => state.user);

  return (
    <div className="chart">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-md">{title}</div>
        <Select
          defaultValue="Current Week"
          style={{ width: 140 }}
          onChange={(value) => setInterval(value)}
          options={[
            { value: "weekly", label: "Current Week" },
            { value: "monthly", label: "Month" },
            { value: "annually", label: "Yearly" },
          ]}
        />
      </div>

      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 20, left: 20, bottom: 0 }}
        >
          <defs>
            <linearGradient id="credit" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1BA397" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#F6FBF9" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="debit" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#F55F6D" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#F6FBF9" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" interval={0} />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip content={<CustomTooltip interval={interval} />} />
          <Area
            type="monotone"
            dataKey="Credit"
            stroke="#1BA397"
            fillOpacity={1}
            fill="url(#credit)"
          />
          <Area
            type="monotone"
            dataKey="Debit"
            stroke="#F55F6D"
            fillOpacity={1}
            fill="url(#debit)"
          />
        </AreaChart>
      </ResponsiveContainer>
      <div
        className={`grid ${
          user === "pensioner" ||
          user === "merchant-owner" ||
          user === "merchant-employee" ||
          user === "merchant-admin"
            ? "grid-cols-2"
            : " grid-cols-1"
        } gap-4 py-1 px-2`}
      >
        <div className="bg-[#F55F6D] rounded-md text-white p-1 px-2">
          <p className="text-white text-xs">Total Expense</p>
          <p className="font-semibold text-sm text-center">${totalDebit}</p>
        </div>
        {(user === "pensioner" ||
          user === "merchant-owner" ||
          user === "merchant-employee" ||
          user === "merchant-admin") && (
          <div className="bg-[#1BA397]  rounded-md text-white p-1 px-2">
            <p className="text-white text-xs">Total Income</p>
            <p className="font-semibold text-sm text-center">${totalCredit}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
