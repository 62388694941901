import { useSelector } from "react-redux";
import i1 from "../../../imgs/i1.png";
import i2 from "../../../imgs/i2.png";
import i3 from "../../../imgs/i3.png";
import placeholder from "../../../imgs/placeholder.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
let govData = [];
let passData = [];
let birthData = [];
let drivData = [];
let tinData = [];
let nisData = [];
let oneData = [];
let data;
const IdentificationP = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const benDone = useSelector((state) => state.benDone);
  const [test, setTest] = useState(true);
  const [govFront, setGovFront] = useState(placeholder);
  const [govBack, setGovBack] = useState(placeholder);
  const [passFront, setPassFront] = useState(placeholder);
  // const [passBack, setPassBack] = useState(placeholder);
  const [nisImg, setNisImg] = useState(placeholder);
  const [tinImg, setTinImg] = useState(placeholder);
  const [oneImg, setOneImg] = useState(placeholder);
  const [drivImg1, setDrivImg1] = useState(placeholder);
  const [drivImg2, setDrivImg2] = useState(placeholder);
  const [birthFront, setBirthFront] = useState(placeholder);
  useEffect(() => {
    if (
      (benDone !== "Done" && profileData.verificationDocs) ||
      (benDone === "Done" && profileData.beneficiaryVerificationDocs)
    ) {
      if (benDone === "Done") {
        data = profileData.beneficiaryVerificationDocs;
      } else {
        data = profileData.verificationDocs;
      }

      govData = data.filter((item) => {
        return item.type === "government id";
      });
      passData = data.filter((item) => {
        return item.type === "passport";
      });
      
    
      if (govData.length !== 0) {
        if (
          (benDone !== "Done" && govData[0].docimages.length !== 0) ||
          (benDone === "Done" && govData[0].beneficiarydocimages.length !== 0)
        ) {
          let body1;
          if (benDone === "Done") {
            body1 = [govData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body1 = [govData[0].docimages[0].imageUrl];
          }

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setGovFront(data.data[0].url);
            })
            .catch(() => {
              
            });
          
          if (
            (benDone === "Done" &&
              govData[0].beneficiarydocimages.length > 1) ||
            (benDone !== "Done" && govData[0].docimages.length > 1)
          ) {
            
            let body2;
            if (benDone) {
              body2 = [govData[0].beneficiarydocimages[1].imageUrl];
            } else {
              body2 = [govData[0].docimages[1].imageUrl];
            }

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
                {
                  fileNames: body2,
                },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {
                
                setGovBack(data.data[0].url);
              })
              .catch(() => {
                
              });
          }
        }
      }

      if (passData.length !== 0) {
        if (
          (benDone !== "Done" && passData[0].docimages.length !== 0) ||
          (benDone === "Done" && passData[0].beneficiarydocimages.length !== 0)
        ) {
          let body1;
          if (benDone === "Done") {
            body1 = [passData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body1 = [passData[0].docimages[0].imageUrl];
          }
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setPassFront(data.data[0].url);
            })
            .catch(() => {
              
            });
          if (
            (benDone !== "Done" && passData[0].docimages.length > 1) ||
            (benDone === "Done" && passData[0].beneficiarydocimages.length > 1)
          ) {
            let body2;
            if (benDone === "Done") {
              body2 = [passData[0].beneficiarydocimages[1].imageUrl];
            } else {
              body2 = [passData[0].docimages[1].imageUrl];
            }

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
                {
                  fileNames: body2,
                },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then(() => {
                
                // setPassBack(data.data[0].url);
              })
              .catch(() => {
                
              });
          }
        }
      }
      birthData = data.filter((item) => {
        return item.type === "birth certificate";
      });
      if (birthData.length !== 0) {
        if (
          (benDone !== "Done" && birthData[0].docimages.length !== 0) ||
          (benDone === "Done" && birthData[0].beneficiarydocimages.length !== 0)
        ) {
          let body1;
          if (benDone === "Done") {
            body1 = [birthData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body1 = [birthData[0].docimages[0].imageUrl];
          }
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setBirthFront(data.data[0].url);
            })
            .catch(() => {
              
            });
        }
      }
      drivData = data.filter((item) => {
        return item.type === "driver license";
      });
      nisData = data.filter((item) => {
        return item.type === "national insurance";
      });
      tinData = data.filter((item) => {
        return item.type === "taxpayer identification";
      });
      oneData = data.filter((item) => {
        return item.type === "one card";
      });
      if (drivData.length !== 0) {
        if (
          (benDone !== "Done" && drivData[0].docimages.length !== 0) ||
          (benDone === "Done" && drivData[0].beneficiarydocimages.length !== 0)
        ) {
          let body1;
          if (benDone === "Done") {
            body1 = [drivData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body1 = [drivData[0].docimages[0].imageUrl];
          }
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setDrivImg1(data.data[0].url);
            })
            .catch(() => {
              
            });
          if (
            (benDone !== "Done" && drivData[0].docimages.length > 1) ||
            (benDone === "Done" && drivData[0].beneficiarydocimages.length > 1)
          ) {
            let body2;
            if (benDone === "Done") {
              body2 = [drivData[0].beneficiarydocimages[1].imageUrl];
            } else {
              body2 = [drivData[0].docimages[1].imageUrl];
            }

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
                {
                  fileNames: body2,
                },
                {
                  headers: {
                    Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                  },
                }
              )
              .then((data) => {
                
                setDrivImg2(data.data[0].url);
              })
              .catch(() => {
                
              });
          }
        }
      }
      if (nisData.length !== 0) {
        if (
          (benDone !== "Done" && nisData[0].docimages.length !== 0) ||
          (benDone === "Done" && nisData[0].beneficiarydocimages.length !== 0)
        ) {
          let body1;
          if (benDone === "Done") {
            body1 = [nisData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body1 = [nisData[0].docimages[0].imageUrl];
          }
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body1,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setNisImg(data.data[0].url);
            })
            .catch(() => {
              
            });
        }
      }
      if (tinData.length !== 0) {
        if (
          (benDone !== "Done" && tinData[0].docimages.length !== 0) ||
          (benDone === "Done" && tinData[0].beneficiarydocimages.length !== 0)
        ) {
          let body2;
          if (benDone === "Done") {
            body2 = [tinData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body2 = [tinData[0].docimages[0].imageUrl];
          }

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body2,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setTinImg(data.data[0].url);
            })
            .catch(() => {
              
            });
        }
      }
      if (oneData.length !== 0) {
        if (
          (benDone !== "Done" && oneData[0].docimages.length !== 0) ||
          (benDone === "Done" && oneData[0].beneficiarydocimages.length !== 0)
        ) {
          let body3;
          if (benDone === "Done") {
            body3 = [oneData[0].beneficiarydocimages[0].imageUrl];
          } else {
            body3 = [oneData[0].docimages[0].imageUrl];
          }

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body3,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              
              setOneImg(data.data[0].url);
            })
            .catch(() => {
              
            });
        }
      }
    }
    // if (profileData.pensionBooks) {
    // }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
    
  }, [profileData]);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [govData, passData, birthData, drivData, tinData, nisData, oneData]);
  console.log("gov",govData)
  console.log("pass",passData)
  return (
    <div className="flex flex-col items-center justify-center">
        <div className="mt-4 w-full mb-[2rem] flex flex-col items-center justify-center">
        <div
          className={` ${
            govData?.length === 0 &&
            passData?.length === 0 &&
            birthData?.length === 0 &&
            oneData?.length === 0?"hidden":""
          } sm:[95%] w-[100%] bg-[#e0e5ec] flex items-center justify-center flex-col rounded-xl p-4`}
        >
            <div className="flex flex-wrap justify-center">
              {govData?.length !== 0 && (
                <div className="h-full">
                  <p className="mr-6 mb-1">
                    <span className="font-semibold">GID: </span>
                    {govData[0].docIDValue}
                  </p>
                  <div className="bg-white h-full w-[18rem] sm:mr-6  rounded-lg" >
                    <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                      <img src={i2} alt="" />
                    </div>
                    <div className="p-2">
                      <div>
                        <p className="text-[0.9rem] font-bold">Government ID</p>
                        <p className="text-[0.9rem]">{govData[0].docIDValue}</p>
                      </div>
                      <div className="mt-2">
                        <p className="text-[0.9rem] font-bold">
                          Country Issued
                        </p>
                        <p className="text-[0.9rem]">
                          {govData[0].countryIssued}
                        </p>
                      </div>
                      <div className="flex mt-[2.5rem]">
                        <div
                          className={`mt-2 mr-4 ${
                            govFront === placeholder ? "hidden" : ""
                          }`}
                        >
                          <p className="text-[0.9rem] font-bold">Front</p>
                          <div className="w-[8rem] h-[6rem]">
                            <img
                              className="w-[8rem] h-[6rem]"
                              src={govFront}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className={`mt-2 ${
                            govBack === placeholder ? "hidden" : ""
                          }`}
                        >
                          <p className="text-[0.9rem] font-bold">Back</p>
                          <p className="w-[8rem] h-[6rem]">
                            <img
                              className="w-[8rem] h-[6rem]"
                              src={govBack}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {passData?.length !== 0 && (
                <div className="h-full sm:mt-0 mt-4">
                  <p className="mr-6 mb-1">
                    <span className="font-semibold">Passport #: </span>
                    {passData[0].docIDValue}
                  </p>
                  <div className="bg-white h-full w-[18rem] sm:mr-6  rounded-lg">
                    <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                      <img src={i1} alt="" />
                    </div>
                    <div className="p-2">
                      <div>
                        <p className="text-[0.9rem] font-bold">Passport</p>
                        <p className="text-[0.9rem]">
                          {passData[0].docIDValue}
                        </p>
                      </div>
                      <div>
                        <p className="text-[0.9rem] font-bold">Expiration Date</p>
                        <p className="text-[0.9rem]">
                          {passData[0].expiryDate}
                        </p>
                      </div>
                      <div className="mt-1">
                        <p className="text-[0.9rem] font-bold">
                          Country Issued
                        </p>
                        <p className="text-[0.9rem]">
                          {passData[0].countryIssued}
                        </p>
                      </div>
                      <div className="flex">
                        <div
                          className={`mt-2 ${
                            passFront === placeholder ? "hidden" : ""
                          }`}
                        >
                          <p className="text-[0.9rem] font-bold">Front</p>
                          <p className="w-[16.5rem] h-[6rem] ">
                            <img
                              className="w-[16.5rem] h-[6rem]"
                              src={passFront}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {birthData?.length !== 0 && (
                <div className="h-full">
                  <p className="mr-6 mb-1">
                    <span className="font-semibold">Birth Certificate #: </span>
                    {birthData[0].docIDValue}
                  </p>
                  <div className="bg-white h-full w-[18rem] sm:mr-6  rounded-lg">
                    <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                      <img src={i3} alt="" />
                    </div>
                    <div className="p-2">
                      <div>
                        <p className="text-[0.9rem] font-bold">
                          Birth Certificate
                        </p>
                        <p className="text-[0.9rem]">
                          {birthData[0].docIDValue}
                        </p>
                      </div>
                      <div>
                        <p className="text-[0.9rem] font-bold">
                          Issued Date
                        </p>
                        <p className="text-[0.9rem]">
                          {birthData[0].issuedDate}
                        </p>
                      </div>
                      <div className="flex mt-[3rem]">
                        <div
                          className={`mt-2 ${
                            birthFront === placeholder ? "hidden" : ""
                          }`}
                        >
                          <p className="text-[0.9rem] font-bold">Front</p>
                          <p className="w-[16.5rem] h-[6rem]">
                            <img
                              className="w-[16.5rem] h-[6rem]"
                              src={birthFront}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          {oneData?.length !== 0 && (
            <div className="h-full mt-4 ">
              <p className="mr-6 mb-1">
                <span className="font-semibold">One Card #: </span>{" "}
                {oneData[0].docIDValue}
              </p>
              <div className="bg-white h-full w-[18rem] sm:mr-6  rounded-lg">
                <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                  <img src={i2} alt="" />
                </div>
                <div className="p-2">
                  <div>
                    <p className="text-[0.9rem] font-bold">ONE CARD </p>
                    <p className="text-[0.9rem]">{oneData[0].docIDValue}</p>
                  </div>
                  <div>
                    <p className="text-[0.9rem] font-bold">countryIssued </p>
                    <p className="text-[0.9rem]">{oneData[0].countryIssued}</p>
                  </div>
                  <div className="grid grid-cols-2">
                  <div>
                    <p className="text-[0.9rem] font-bold">Issued Date</p>
                    <p className="text-[0.9rem]">{oneData[0].issuedDate}</p>
                  </div>
                  <div>
                    <p className="text-[0.9rem] font-bold">Expiration Date</p>
                    <p className="text-[0.9rem]">{oneData[0].expiryDate}</p>
                  </div>
                  </div>
                  <div className="flex">
                    <div
                      className={`mt-2 ${
                        oneImg === placeholder ? "hidden" : ""
                      }`}
                    >
                      <p className="text-[0.9rem] font-bold">Image</p>
                      <p className="w-[16.5rem] h-[6rem]">
                        <img
                          className="w-[16.5rem] h-[6rem]"
                          src={oneImg}
                          alt=""
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
        {/*<div className={`mt-4 ${profileData.beneficiaryUser ? "hidden" : ""}`}>
          <div className="sm:h-[20rem] sm:w-[58rem] w-[100%] bg-[#e0e5ec] rounded-xl sm:flex-row flex-col flex p-2">
            <div className="bg-white h-full w-[18rem] rounded-lg">
              <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                <img src={i4} alt="" />
              </div>
              <div className="p-2">
                <div>
                  <p className="text-[0.9rem] font-bold">Pension Book No.</p>
                  <p className="text-[0.9rem]">17201-11212-34-9</p>
                </div>
                <div className="flex">
                  <div className="mt-[3.5rem]">
                    <p className="text-[0.9rem] font-bold">Image</p>
                    <p className="w-[16.5rem] h-[6rem]">
                      <img
                        className="w-[16.5rem] h-[6rem]"
                        src={placeholder}
                        alt=""
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white h-full w-[18rem] rounded-lg sm:ml-6 sm:mt-0 mt-3">
              <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                <img src={i4} alt="" />
              </div>
              <div className="p-2">
                <div>
                  <p className="text-[0.9rem] font-bold">
                    Previous Pension Book No.
                  </p>
                  <p className="text-[0.9rem]">17201-11212-34-9</p>
                </div>
                <div className="flex">
                  <div className="mt-[3.5rem]">
                    <p className="text-[0.9rem] font-bold">Image</p>
                    <p className="w-[16.5rem] h-[6rem]">
                      <img
                        className="w-[16.5rem] h-[6rem]"
                        src={placeholder}
                        alt=""
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}
            {/* <div className="bg-white h-full w-[18rem] rounded-lg sm:ml-6 sm:mt-0 mt-3">
              <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                <img src={i2} alt="" />
              </div>
              <div className="p-2">
                <div className="mt-2">
                  <p className="text-[0.9rem] font-bold">ONE-Off Cash Grant</p>
                  <p className="text-[0.9rem]">123ET1D</p>
                </div>
                <div className="flex">
                  <div className="mt-[3.5rem]">
                    <p className="text-[0.9rem] font-bold">Front</p>
                    <p className="w-[16.5rem] h-[6rem] ">
                      <img
                        className="w-[16.5rem] h-[6rem]"
                        src={placeholder}
                        alt=""
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>*/}
        <div className="mt-4 mb-[3rem] w-full">
          <div
            className={`${
              drivData?.length === 0 &&
              tinData?.length === 0 &&
              nisData?.length === 0
                ? "hidden"
                : ""
            }  sm:[95%] w-[100%] bg-[#e0e5ec] flex items-center justify-center flex-col rounded-xl p-4`}
          >
            <div className="flex flex-wrap justify-center">
              {nisData?.length !== 0 && (
                <div className="h-full">
                  <p className="mr-6 mb-1">
                    <span className="font-semibold">NIS #: </span>
                    {nisData[0].docIDValue}
                  </p>
                  <div className="bg-white h-full w-[18rem] sm:mr-6  rounded-lg">
                    <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                      <img src={i2} alt="" />
                    </div>
                    <div className="p-2">
                      <div>
                        <p className="text-[0.9rem] font-bold">NIS</p>
                        <p className="text-[0.9rem]">{nisData[0].docIDValue}</p>
                      </div>
                      <div className="flex mt-[6rem]">
                        <div
                          className={`mt-2 ${
                            nisImg === placeholder ? "hidden" : ""
                          }`}
                        >
                          <p className="text-[0.9rem] font-bold">Image</p>
                          <p className="w-[16.5rem] h-[6rem]">
                            <img
                              className="w-[16.5rem] h-[6rem]"
                              src={nisImg}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tinData?.length !== 0 && (
                <div className="h-full">
                  <p className="ml-6 mb-1">
                    <span className="font-semibold">TIN #: </span>
                    {tinData[0].docIDValue}
                  </p>
                  <div className="bg-white h-full w-[18rem] rounded-lg sm:mr-6 sm:mt-0 mt-3">
                    <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                      <img src={i2} alt="" />
                    </div>
                    <div className="p-2">
                      <div>
                        <p className="text-[0.9rem] font-bold">TIN</p>
                        <p className="text-[0.9rem]">{tinData[0].docIDValue}</p>
                      </div>
                      <div className="flex mt-[6rem]">
                        <div
                          className={`mt-2 ${
                            tinImg === placeholder ? "hidden" : ""
                          }`}
                        >
                          <p className="text-[0.9rem] font-bold">Image</p>
                          <p className="w-[16.5rem] h-[6rem]">
                            <img
                              className="w-[16.5rem] h-[6rem]"
                              src={tinImg}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {}
              {drivData?.length !== 0 && (
                <div className="h-full">
                  <p className="ml-6 mb-1">
                    <span className="font-semibold">Driving License #: </span>
                    {drivData[0].docIDValue}
                  </p>
                  <div className="bg-white h-full w-[18rem] rounded-lg sm:mt-0 mt-3">
                    <div className="bg-primary w-full h-[4rem] rounded-tl-lg rounded-tr-lg flex justify-center items-center">
                      <img src={i2} alt="" />
                    </div>
                    <div className="p-2">
                      <div>
                        <p className="text-[0.9rem] font-bold">
                          Driver's License
                        </p>
                        <p className="text-[0.9rem]">
                          {drivData[0].docIDValue}
                        </p>
                      </div>
                      <div className="mt-1">
                        <p className="text-[0.9rem] font-bold">
                          Expiration Date
                        </p>
                        <p className="text-[0.9rem]">
                          {drivData[0].expiryDate}
                        </p>
                      </div>
                      <div className="mt-1">
                    <p className="text-[0.9rem] font-bold">
                      Country Issued
                    </p>
                    <p className="text-[0.9rem]">
                      {drivData[0].countryIssued}
                    </p>
                  </div>
                      <div className="flex">
                        <div className="mt-2">
                          <p className="text-[0.9rem] font-bold">Front</p>
                          <p className="w-[8rem] h-[6rem]">
                            <img
                              className="w-[8rem] h-[6rem]"
                              src={drivImg1}
                              alt=""
                            />
                          </p>
                        </div>
                        {}
                        <div className="mt-2 ml-2">
                          <p className="text-[0.9rem] font-bold">Back</p>
                          <p className="w-[8rem] h-[6rem] ">
                            <img
                              className="w-[8rem] h-[6rem]"
                              src={drivImg2}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="mt-4 mb-[3rem]">
          <div
            className={`${
              drivData.length === 0 ? "hidden" : ""
            } h-[20rem] w-[58rem] bg-[#e0e5ec] rounded-xl flex p-2`}
          ></div>
        </div> */}
      </div>
  );
};

export default IdentificationP;
