import React from "react";
import { useDispatch, useSelector } from "react-redux";
import back from "../../../imgs/backIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
const Header = ({ type, header, route, routeFind, routeCondition, value }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const Name =
    dash === "/sSapMoney"
      ? dataUser?.data?.user?.person?.firstName +
        " " +
        dataUser?.data?.user?.person?.lastName
      : "John Doe";
  return (
    <div
      className="flex items-center w-[95%] justify-between"
      style={{ flexWrap: "nowrap", alignItems: "center" }}
    >
      <div className="flex items-center">
        {header === false ? (
          <div className="flex items-center justify-center">
            <h2 className="mb-4 text-xl font-bold flex items-center justify-center">
              <img
                onClick={() => {
                  routeFind === false
                    ? dispatch({ type: routeCondition, num: value })
                    : router(route);
                  // router(route);
                }}
                src={back}
                className="w-3 h-3 mr-2 cursor-pointer"
                alt="back"
              />{" "}
              {type}
            </h2>
          </div>
        ) : (
          <h2 className="mb-2 font-bold text-2xl">
            Welcome{" "}
            <span className="text-primary">{Name ? Name : "John Doe"}</span>,
          </h2>
        )}
      </div>
    </div>
  );
};

export default Header;
