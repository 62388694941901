import React from "react";
import { useDispatch, useSelector } from "react-redux";
import search from "../../../imgs/searchM.png";
import person from "../../../imgs/profile.png";
import bell from "../../../imgs/bell.svg";
import back from "../../../imgs/backIcon.png";

import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "antd";
const Header = ({ type, header, route, routeFind, routeCondition, value }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const location = useLocation();
  // const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  // const popData = useSelector((state) => state.popData);
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  const firstName =
    user === "merchant-owner" || user === "merchant-admin"
      ? dataUser?.data?.merchuser?.merchCompany?.name
      : merchantEmployeeInfo?.data?.merchPerson?.firstName;
  const lastNameShow =
    user === "merchant-owner" || user === "merchant-admin"
      ? ""
      : merchantEmployeeInfo?.data?.merchPerson?.lastName;
  const profile =
    user==="merchant-owner" ? dataUser?.data?.merchuser?.merchCompany?.logo: user==="merchant-employee"?  merchantEmployeeInfo?.data?.merchPerson?.image : dataUser?.data?.user?.person?.image;
  const Name =
    user !== "merchant-owner" && user !== "merchant-employee"|| user !== "merchant-admin"
      ? dataUser?.data?.user?.person?.firstName +
        " " +
        dataUser?.data?.user?.person?.lastName
      : (user === "merchant-owner" || user === "merchant-employee" || user === "merchant-admin")
      ? firstName +
        " " +
        lastNameShow
      : "John Doe";
  return (
    <div
      className="flex items-center w-[95%] justify-between"
      style={{ flexWrap: "nowrap", alignItems: "center" }}
    >
      <div className="flex items-center">
        {header === false ? (
          <div className="flex items-center justify-center">
            <h2 className="mb-4 text-xl font-bold flex items-center justify-center">
              <img
                onClick={() => {
                  // if(routeFind === false){
                  //   alert(type)
                  // }
                  routeFind === false
                    ? dispatch({ type: routeCondition, num: value })
                    : router(route);
                  // router(route);
                }}
                src={back}
                className="w-3 h-3 mr-2 cursor-pointer"
                alt="back"
              />{" "}
              {type}
            </h2>
          </div>
        ) : (
          <h2 className="mb-2 font-bold text-2xl">
            Welcome{" "}
            <span className="text-primary">
              {/* {dataUser?.data?.user?.person?.firstName +
                " " +
                dataUser?.data?.user?.person?.lastName} */}
              {Name}
            </span>
            ,
          </h2>
        )}
      </div>
     
    </div>
  );
};

export default Header;
