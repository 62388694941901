import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import ErrorModal from "../Modals/errorModal";
const Paget = ({ search, setSearch }) => {
  const token = useSelector((state) => state.token);
  const [dob, setDob] = useState("");
  const [gid, setGid] = useState("");
  const [lastName, setLastName] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  console.log(search, setSearch);
  const Label = ({ title }) => {
    return (
      <div>
        <label className="font-semibold text-md p-2">
          {title}
          <span
            className={`${
              title === "Date Of Birth" ? "hidden" : "text-red-600"
            }`}
          >
            {" "}
            *
          </span>
        </label>
      </div>
    );
  };

  const router = useNavigate();
  const dispatch = useDispatch();
  const fetchData = () => {
    if (gid === "" && lastName === "") {
      setErr("Please enter both gid and last name to search Pensioner");
      setShowErrModal(true);
      return;
    }

    if (gid === "") {
      setErr("Please enter GID to search Pensioner");
      setShowErrModal(true);
      return;
    }
    if (lastName === "") {
      setErr("Please enter last name to search Pensioner");
      setShowErrModal(true);
      return;
    }

    const params = {};

    if (lastName) params.lastName = lastName;
    if (gid) params.gid = gid;
    if (dob) params.dateOfBirth = dob;

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/reports/search-pensioner`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch({ type: "SELECTED_REPORT_PENSIONER", num: response.data });
        router("/Merchant/PensionerReport");
        console.log(response.data);
      })
      .catch((error) => {
        setErr(error.response.data.message);
        setShowErrModal(true);
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };
  return (
    <section className="p-2">
      <div className="mx-auto max-w-screen-xl sm:px-4 lg:px-12 ">
        <div className="pr-5 pl-5">
          <div className="left-0 mt-5 px-5 sm:px-20 py-10  rounded-md  bg-white ring-1 ring-black ring-opacity-5 flex flex-col gap-4">
            <div className="flex flex-col">
              <Label title="GID" />
              <input
                type="text"
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
                
            `}
                value={gid}
                onChange={(e) => setGid(e.target.value)}
                placeholder="Enter GID"
              />
            </div>
            <div className="flex flex-col">
              <Label title="Last Name" />
              <input
                type="text"
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
            
            `}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter Last Name"
              />
            </div>
            <div className="flex flex-col">
              <Label title="Date Of Birth" />
              <DatePicker
                onChange={(e, f) => setDob(f)}
                value={dob !== "" ? moment(dob) : null}
                placeholder="Date of birth"
              />
            </div>

            <div className="mt-2 flex w-full gap-2 justify-center">
              <button
                className={`bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8 `}
                onClick={() => {
                  fetchData();
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
        {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
      </div>
    </section>
  );
};

export default Paget;
