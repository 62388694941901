import React, { useEffect } from "react";
import Left from "./Left";
import Right from "./Right";
import Center from "./Center";
import { useDispatch } from "react-redux";
const Index = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "TRANSACTION_TYPE", num: "program" });
  }, []);
  return (
    // <div className="flex w-full h-full">
    //   <div className="flex-1/2">
    //     <Left />
    //   </div>
    //   <div className="flex-1 flex justify-center">
    //     {" "}

    //     <div className="w-full md:w-2/3">
    //       <Center />
    //     </div>{" "}

    //   </div>
    //   <div className="flex-1 flex justify-end">
    //     <Right />
    //   </div>
    // </div>
    <div className="flex gap-2 sm:flex-row flex-col">
      <div className="sm:w-2/5 w-full ">
        <Left />
      </div>
      <div className="sm:w-4/5 w-full  ">
        <Center />
      </div>
      <div className="sm:w-2/5 w-full ">
        <Right />
      </div>
    </div>
  );
};

export default Index;
