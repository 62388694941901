import React from 'react'
import Notifications from "../components/MHSSS Accounting/Notifications/index"

const OutStandingListing = () => {
  return (
    <div>
     <Notifications/>
    </div>
  )
}

export default OutStandingListing