import React, { useState } from "react";
import ClientJS from 'clientjs' // Import ClientJS

const Demo = () => {
  const [finger, setFinger] = useState("");
  const takeFingerprint = () => {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    setFinger(fingerprint);
    console.log("Visitor fingerprint:", fingerprint);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Visitor Fingerprint Capture</h1>
      <div>
        <button onClick={takeFingerprint}>Capture Fingerprint</button>
        <p>Visitor Fingerprint: {finger}</p>
      </div>
    </div>
  );
};

export default Demo;
