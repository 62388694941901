import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import across from "../../../imgs/across.png";
import logo from "../../../imgs/logo12.png";
import dayjs from "dayjs";
import gallary from "../../../imgs/picture.png";
import device from "../../../imgs/sign.png";
import figA from "../../../imgs/fingerActive.png";
import { DatePicker, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import codeHandler from "../../Register/codeHandler/codehandle";
import axios from "axios";
import { Button } from "antd";
import country from "../../../constants/country";
import { DeleteFilled, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
let file;
const SelectLocation = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const penVerStatus = useSelector((state) => state.penVerStatus);
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const veriSelectedPensioner = useSelector(
    (state) => state.veriSelectedPensioner
  );
  const [displaysig, setDisplaysig] = useState("");
  const [display, setDisplay] = useState("");
  const [pop, setPop] = useState(false);
  const [url, setUrl] = useState("");
  // const [pId, setPid] = useState("");
  const [booklet, setBooklet] = useState("");
  const [year, setYear] = useState("2024");
  const [issueDate, setIssueDate] = useState(null);
  const [sigD, setSigD] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showVer, setShowVer] = useState(false);
  const [err, setErr] = useState("");
  const [note1, setNote1] = useState("");
  const [type, setType] = useState(true);
  const [dis, setDis] = useState(false);
  const [urlSig, setUrlSig] = useState("");
  console.log("Profile", profileData);

  useEffect(() => {
    if (penVerStatus === "Pension Book Distributed") setDis(true);
    else setDis(false);
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-books/pensioner/${veriSelectedPensioner?.id}?year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        console.log("pensionBook", data?.data);
        setData(data?.data);
      })
      .catch(() => {});
  }, [year]);
  useEffect(() => {
    console.log("test", data?.rows);
    if (data && data?.rows?.length > 0) {
      // setPid(data?.rows[0]?.id);
      setIssueDate(data?.rows[0]?.issueDate);
      setBooklet(data?.rows[0]?.bookletNo);
      const frontImg = data?.rows[0]?.frontImg;
      setUrl(data?.rows[0]?.frontImg);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
          { fileNames: [frontImg] },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setDisplay(data.data[0].url);
        })
        .catch(() => {});
    } else {
      setIssueDate(null);
      setBooklet("");
      setDisplay("");
      setUrl("");
    }
  }, [data]);

  useEffect(() => {
    console.log("Url Test", url, display);
  }, [url]);
  const Update = () => {
    let body;
    let api;
    let method;
    if (data?.rows?.length === 0) {
      api = `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${profileData?.user?.id}`;
      method = "post";
      body = {
        bookInfo: {
          bookletNo: booklet,
          frontImg: url,
          year: year,
          issueDate: issueDate,
        },
        consumerInfo: {
          ip: "yyy.yy.y.y",
          location: "14.37854898945,38.9409095",
        },
      };
    } else {
      api = `${process.env.REACT_APP_BASE_URL}/v1/pension-books/${data?.rows[0]?.id}/update-by-staff`;
      method = "put";
      body = {
        pensionBook: {
          bookletNo: booklet,
          frontImg: url,
          year: year,
          issueDate: issueDate,
        },
        consumerInfo: {
          ip: "yyy.yy.y.y",
          location: "14.37854898945,38.9409095",
        },
      };
    }
    axios({
      method: method,
      url: api,
      data: body,
      headers: {
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        dispatch({ type: "RESET" });
        // Verification();
        console.log("Datapen", data);
        // setPid(data?.id);
        Consent(data?.data?.id);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  const Verification = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${profileData.user.id}/change-pensioner-verification-status`,
        {
          verificationStatus: type
            ? "Pension Book Distributed"
            : "Verification Rejected",
          note: note1,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShowVer(false);
        RemoveQueue();
        router("/pensionerVerification/Profile");
        if (type)
          dispatch({
            type: "PEN_VER_STATUS",
            num: "Pension Book Distributed",
          });
        else
          dispatch({
            type: "PEN_VER_STATUS",
            num: "Verification Rejected",
          });
      })
      .catch((err) => {
        setShowVer(false);
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  const Consent = (id) => {
    let body = {
      pensionbookId: id,
      signature: urlSig,
      consentType: "pensionbook",
      userId: profileData.user.id,
    };
    console.log("Payload body:", body);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/user-consent`, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        setShowVer(false);
        Verification();
      })
      .catch((err) => {
        setShowVer(false);
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  const imageHandler = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplaysig(base641);
    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setPop(false);
        setSigD(false);
        setUrlSig(data.data.url);
      })
      .catch(() => {});
  };
  const imageHandlerSig = (file) => async () => {
    setPop(false);
    setSigD(false);
    const base641 = await convertToBase64(file);
    setDisplaysig(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setUrlSig(data.data.url);
      })
      .catch(() => {});
  };
  const imageHandlerr = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const rr = country?.map((item) => {
    return {
      lable: `${item}`,
      value: `${item}`,
    };
  });
  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }
  function ConfirmSig() {
    setPop(false);
    setSigD(false);
    var json = { action: "14" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        const base64Str = response?.data?.FigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );

        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplaysig("");
      })
      .catch(function () {});
  }

  const dateHandler = (setState, check) => (event) => {
    if (event === null || event === undefined || event === "") {
      // Handle the case when the date picker is cleared
      setState(null);
      return;
    }
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  const RemoveQueue = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/verification-queue/remove?userId=${veriSelectedPensioner?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {})
      .catch((err) => {
        setErr(err?.response?.data.message);
        setShow(true);
      });
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  useEffect(()=>
  {
    console.log("issue",issueDate)
  },[issueDate])
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader
        header={true}
        title={"Pension book Verification"}
        route={-1}
      />
      <div className="flex justify-center bg-[#F4F2F2] p-4  flex-col items-center sm:ml-[2rem] sm:mr-[2rem] ml-2 mr-2 relative">
        <div
          className={`rounded-sm md::mx-20 mx-2 flex mt-[4rem] items-center flex-col gap-2 bg-white m-5 p-6 md:w-[60%] sm:w-[90%] w-full`}
        >
          <h2 className="w-full font-semibold">Pension book details</h2>

          <div className="grid sm:grid-cols-2 sm:gap-y-2 sm:gap-x-5 gap-2 mt-3 grid-cols-1 w-full">
            <Select
              placeholder="Select Year"
              optionFilterProp="children"
              size="middle"
              disabled={dis}
              className="wow"
              onChange={(value) => setYear(value)}
              options={[
                { label: "2020", value: "2020" },
                { label: "2021", value: "2021" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },
                { label: "2024", value: "2024" },
                { label: "2025", value: "2025" },
                { label: "2026", value: "2026" },
              ]}
              value={year}
            />

            <input
              type="text"
              onChange={(e) => {
                setBooklet(e.target.value);
              }}
              value={booklet}
              disabled={dis}
              placeholder="Booklet #"
              className=" lg:text-[1.1rem] w-[100%] bg-[#F8F8F8]  rounded-[0.3rem] p-2  py-[9px]"
            />

            <DatePicker
              placeholder="Date Issued"
              showTime
              disabled={dis}
              value={
                issueDate ? dayjs(`${issueDate}`, "YYYY-MM-DD HH:mm:ss") : null
              }
              // format="MM/DD/YYYY hh:mm A"
              disabledDate={disabledDate1}
              className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              onChange={(date, dateString) => {
                setIssueDate(dateString);
              }}
            />

            {/* <DatePicker
              placeholder="Issued Date"
              style={{
                marginBottom: "1.2rem",
                backgroundColor: "#F8F8F8",
                border: "none",
                paddingBottom: "9px",
                paddingTop: "9px",
              }}
              onChange={dateHandler(setIssueDate, "tgf")}
              value={issueDate ? dayjs(`${issueDate}`, "YYYY-MM-DD") : null}
            /> */}
          </div>
          <div className={`${dis ? "pointer-events-none" : ""} w-full`}>
            <div
              className={`flex items-center flex-col justify-center rounded-lg text-center sm:w-[23rem] h-[13rem] relative`}
            >
              {display ? (
                <>
                  <img
                    src={display}
                    alt="Uploaded"
                    className="w-full h-full  rounded-lg"
                  />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setUrl("");
                      setDisplay("");
                    }}
                  >
                    <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                  </button>
                </>
              ) : (
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                    <div className="flex items-center w-full justify-center flex-col">
                      <UploadOutlined className="text-4xl text-primary cursor-pointer " />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <label
              htmlFor="file"
              className="text-5xl mt-2 text-primary sm:w-[23rem] cursor-pointer flex flex-col gap-2 items-center justify-center"
            >
              <input
                id="file"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(e) => imageHandlerr(e)}
              />
              <div className="w-full text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                Upload
              </div>
            </label>
            <p className="mt-2 ">
              I,{" "}
              <span className="font-semibold">
                {profileData?.person?.firstName} {profileData?.person?.lastName}
              </span>
              , hereby confirm that I have received my pension book for the{" "}
              <span className="font-semibold">year {year}</span>.
            </p>
          </div>
          <h2 className="w-full font-semibold mt-8">Signature/Fingerprint</h2>
          <div className={`${dis ? "pointer-events-none" : ""} w-full`}>
            <div
              className={`border-dashed bg-white border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-full h-[13rem] relative`}
            >
              {displaysig ? (
                <>
                  <img
                    src={displaysig}
                    alt="Uploaded"
                    className="w-full h-full"
                  />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setUrlSig("");
                      setDisplaysig("");
                    }}
                  >
                    <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                  </button>
                </>
              ) : (
                <div className="w-[80%] flex flex-col gap-1 flex items-center ">
                  {/* <UploadOutlined className="text-5xl text-primary cursor-pointer " /> */}
                  <div className="flex items-center justify-center">
                    <label
                      // htmlFor="file-upload"
                      className="text-5xl text-primary cursor-pointer"
                    >
                      <UploadOutlined />
                    </label>
                    {/* <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={imageHandler(
                        setDisplay,
                        setSignature,
                        setIsLoading
                      )}
                    /> */}
                  </div>
                  <p>Upload Signature or Thumbprint Here</p>
                  <Button
                    onClick={() => {
                      setPop(true);
                    }}
                    className="mt-4 w-[80%] cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg"
                  >
                    Upload
                  </Button>
                </div>
              )}
            </div>
            {sigD && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    GetWhitSigPic();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Open Pad
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ConfirmSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Get Sign
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ReSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Reset
                </button>
              </div>
            )}
          </div>
        </div>
        {penVerStatus === "Biometric Verified" && (
          <div
            className={`flex w-[80%] items-center justify-center gap-2 mb-5`}
          >
            <button
              type="button"
              onClick={() => {
                if (issueDate === null) {
                  setErr("Please Enter Issue Date");
                  setShow(true);
                } else if (booklet === "") {
                  setErr("Please Enter Booklet Number");
                  setShow(true);
                } else if (url === "") {
                  setErr("Please Upload pensionbook Image");
                  setShow(true);
                } else if (urlSig === "") {
                  setErr("Please Upload Signature to Verify");
                  setShow(true);
                } else {
                  setShowVer(true);
                  setType(true);
                }
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 `}
            >
              Update and verified
            </button>
            <button
              type="button"
              onClick={() => {
                // router("/pensionerVerification/Profile");
                setType(false);
                setShowVer(true);
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-red-600 text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Unverified
            </button>
          </div>
        )}
      </div>
      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[80%] flex items-center justify-between mt-10 mb-10">
            <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            />
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => imageHandler(e)}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 z-[9999] flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showVer ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowVer(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          {type ? (
            <p className="text-center mt-6 mb-5 font-semibold">
              Are you sure to mark this pensioner as verified?
            </p>
          ) : (
            <p className="text-center mt-6 mb-5 font-semibold">
              Are you sure to reject this Pensioner?
            </p>
          )}
          <textarea
            type="text"
            name=""
            onChange={(e) => setNote1(e.target.value)}
            value={note1}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => setShowVer(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (note1 === "") {
                  setErr("Please Add Note");
                  setShow(true);
                  return;
                }
                if (type) Update();
                else Verification();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
