import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EventDetail from "./eventDetails";

const EventDetails = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sub = useSelector((state) => state.subEvent);

  const eventData = {
    id: 1,
    coverPhoto:
      "https://www.shutterstock.com/image-photo/san-diego-july-12-esri-600nw-57038776.jpg",
    name: "Tech Innovators Conference",
    startDate: "2024-06-01",
    endDate: "2024-06-03",
    location: "New York, NY",
    coordinates: "33.5651, 73.0169",
    Type: "Public",
    members: "50",
    images: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlprAMpIkXNVllhdiT6u3MQZUBQkmTLYnS_HlscuMaUw&s",
      "https://images.ctfassets.net/gl3ev2s7u7gx/5W9fnKM9za9wTQ1ZJtJXLf/7e21e6060d2e9287c2b2f429dace516e/SemiPermanent_250522_CARD_28_4295.jpg?w=900",
      "https://www.liverpool.ac.uk/media/livacuk/schoolofmanagement/conferencesandevents/20240529-BfCE-COEW-Wellbeing-entrepreneurship-workshop---Top-banner.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyApYD8UxGmSXBYSDxYnG9-vg5wnrXz1cAeFtjqjIqHg&s",
    ],
  };
  const subEventData = {
    id: 1,
    coverPhoto:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq4WH4dJrx0UpAWLRapcniQEOrwk2pBxiJJh7whOwSRsPHUIt2jCH30IboWfej6OxWNo0&usqp=CAU",
    name: "Tech Conference",
    startDate: "2024-06-01",
    endDate: "2024-06-02",
    Type: "Private",
    location: "New York, NY",
    coordinates: "33.6995 , 73.0363",
    members: "30",
    images: [
      "https://i.pinimg.com/236x/9a/e8/fc/9ae8fc22197c56c5e5b0c2c22b05186e.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWh9TD8oVmx5avXsNJZErHpz6UgAa-87Qfvk-5tcvwaLHApr01tT4U63LZeZ7okgzjRrY&usqp=CAU",
      "https://i.pinimg.com/originals/39/86/fe/3986fed5bc314b3ea37701b328c2485d.jpg",
      "https://cdn.pixabay.com/photo/2018/01/17/18/43/book-3088775_1280.jpg",
    ],
  };
  console.log("user", user);
  return (
    <div className="flex items-center justify-center pt-[2rem] flex-col">
      <div className="sm:w-[80%] flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                if (!sub) {
                  if (user === "pensioner")
                    router("/PensionerDashBoard/events");
                  else router("/EmployeeDashBoard/events");
                } else if (sub) {
                  dispatch({ type: "NSUB" });
                }
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {!sub ? "Event" : "Sub Event"}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-[80%]">
        {!sub ? (
          <EventDetail data={eventData} />
        ) : (
          <EventDetail data={subEventData} />
        )}
      </div>
    </div>
  );
};

export default EventDetails;
