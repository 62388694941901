import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import PListing from "../components/GeneralRegisterOfficer/plistingGRO"

const pListing = () => {
  return (
    <div>
      <Nav />
     <PListing/>
     <Whatsapp/>
    </div>
  )
}

export default pListing