import Listingt from "../components/Dash/Test/Listing/listingt";
import Nav from "../components/Nav/nav";
import Popup1 from "../components/Popup/popup1";
import { useSelector } from "react-redux";
import Whatsapp from "../Whatsapp/whatsapp";
const PensionL = () => {
  const show = useSelector((state) => state.isLogged);
  return (
    <div>
      <Nav />
      <Listingt />
      <div className={`${show ? "hidden" : ""}`}>
        <Popup1 />
      </div>
      <Whatsapp />
    </div>
  );
};

export default PensionL;
