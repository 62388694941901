import { Collapse } from "antd";
import BankInfo from "./BankInfo";
import Cheque from "./Cheque";
// import Ewallet from "./EWallet";

const { Panel } = Collapse;

const Banking = ({ banking, cheque, ewallet }) => {
  const callback = (key) => {
    console.log(key);
    console.log(ewallet);
  };

  return (
    <div className="sm:p-10">
      <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8 shadow-md ">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-bold leading-none text-gray-900 ">
            Financial Information
          </h3>
        </div>

        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            <Panel
              header="Banking Details"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <BankInfo data={banking} />
            </Panel>
            <Panel
              header="Cheque Details"
              key="2"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Cheque data={cheque} />
            </Panel>

            {/* <Panel
                            header="E-Wallet Details"
                            key="3"
                            className="p-3 text-md text-gray-800 font-medium"
                        >
                        <Ewallet data={ewallet}/>
                        </Panel> */}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Banking;
