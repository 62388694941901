import { Select } from "antd";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import across from "../../../imgs/across.png";
import cross from "../../../imgs/cross.png";
import { DatePicker } from "antd";
import moment from "moment";
import { PlusCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
let file;
const PensionBookDetails = () => {
  const privilege = useSelector((state) => state.myPrivileges);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const dispatch = useDispatch();
  const [issueDate1, setissueDate1] = useState(null);
  const [issueDate2, setissueDate2] = useState(null);
  console.log(issueDate1, issueDate2);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [bookletNo1, setBookletNo1] = useState("");
  const [bookletNo2, setBookletNo2] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [wow, setWow] = useState(0);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [present1, setPresent1] = useState(false);
  const [present2, setPresent2] = useState(false);
  const [allDis1, setAllDis1] = useState(false);
  const [allDis2, setAllDis2] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [year, setYear] = useState("2024");
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [check, setCheck] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const user = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  console.log(isLoading1, isLoading2);
  const imageHandler = (setState, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        if (wow === 0) {
          setImageUrl1(data.data.url);
        } else {
          setImageUrl2(data.data.url);
        }
        load(false);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let body = {};
    let outerBody = {};

    if (wow === 0) {
      body = {
        bookletNo: `${bookletNo1}`,
        issueDate: `${issueDate1}`,
        frontImg: `${imageUrl1}`,
        year: `2024`,
      };
    } else {
      body = {
        bookletNo: `${bookletNo2}`,
        issueDate: `${issueDate2}`,
        frontImg: `${imageUrl2}`,
        year: `2025`,
      };
    }

    if (
      dash === "/EDashboard/PensionerAccount" ||
      dash === "/superAdminDashboard/PensionerAccount" ||
      dash === "/AdminDashboard/PensionerAccount"
    ) {
      let wowBoy;
      let win1 = "";
      if (wow === 0) {
        wowBoy = url1;
        if (present1) {
          outerBody = { pensionBook: body };
          win1 = "put";
        } else {
          outerBody = { bookInfo: body };
          win1 = "post";
        }
      } else {
        wowBoy = url2;
        if (present2) {
          outerBody = { pensionBook: body };
          win1 = "put";
        } else {
          outerBody = { bookInfo: body };
          win1 = "post";
        }
      }

      if (win1 === "put") {
        axios
          .put(`${wowBoy}`, outerBody, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoading(false);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
            setIsLoading(false);
          });
      } else {
        axios
          .post(`${wowBoy}`, outerBody, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoading(false);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
            setIsLoading(false);
          });
      }
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/pension-books`, body, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then(() => {
          dispatch({ type: "RESET" });
          setIsLoading(false);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
          setIsLoading(false);
        });
    }
  };

  const yearChangeHandler = (e) => {
    if (e === "2024") {
      setYear("2024");
      setWow(0);
    } else {
      setYear("2025");
      setWow(1);
    }
  };
  useEffect(() => {
    const apiUrl =
      dash === "/Dashboard/PensionerAccount"
        ? `${process.env.REACT_APP_BASE_URL}/v1/pension-books?year=${year}`
        : `${process.env.REACT_APP_BASE_URL}/v1/pension-books/pensioner/${profileData.user.id}?year=${year}`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        if (year === "2024") setData1(data.data.rows);
        else {
          setData2(data.data.rows);
        }
        setCheck(!check);
      })
      .catch(() => {});
  }, [wow]);
  useEffect(() => {
    if (profileData.pensionerInfo) {
      if (
        profileData.pensionBooks.length === 0 &&
        dash === "/Dashboard/PensionerAccount"
      ) {
        setAllDis1(false);
        setAllDis2(false);
      } else {
        // const wow1 = profileData.pensionBooks.filter((item) => {
        //   return item.year === "2023";
        // });
        // const wow2 = profileData.pensionBooks.filter((item) => {
        //   return item.year === "2024";
        // });
        const wow1 = data1;
        const wow2 = data2;
        if (
          dash === "/EDashboard/PensionerAccount" &&
          profileData.user.isDisabled
        ) {
          setAllDis1(true);
          setAllDis2(true);
        }
        if (wow1.length !== 0) {
          if (dash !== "/EDashboard/PensionerAccount") {
            setAllDis1(true);
          } else if (
            dash === "/EDashboard/PensionerAccount" &&
            profileData.user.isDisabled
          ) {
            setAllDis1(true);
            setAllDis2(true);
          }
          setBookletNo1(wow1[0].bookletNo);
          setissueDate1(wow1[0].issueDate);
          const body4 = [wow1[0].frontImg];
          setImageUrl1(wow1[0].frontImg);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setDisplay(data.data[0].url);
            })
            .catch(() => {});

          setUrl1(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/${wow1[0].id}/update-by-staff`
          );
          setPresent1(true);
        } else {
          setUrl1(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${profileData.user.id}`
          );
          setPresent1(false);
        }
        if (wow2.length !== 0) {
          if (dash !== "/EDashboard/PensionerAccount") {
            setAllDis2(true);
          } else if (
            dash === "/EDashboard/PensionerAccount" &&
            profileData.user.isDisabled
          ) {
            setAllDis1(true);
            setAllDis2(true);
          }
          setBookletNo2(wow2[0].bookletNo);
          setissueDate2(wow2[0].issueDate);
          const body4 = [wow2[0].frontImg];
          setImageUrl2(wow2[0].frontImg);
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
              {
                fileNames: body4,
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then((data) => {
              setDisplay1(data.data[0].url);
            })
            .catch(() => {});
          setUrl2(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/${wow2[0].id}/update-by-staff`
          );
          setPresent2(true);
        } else {
          setUrl2(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${profileData.user.id}`
          );
          setPresent2(false);
        }
      }
    }
  }, [check]);
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  const dateHandler = (setState, check) => (event) => {
    if (event === null || event === undefined || event === "") {
      // Handle the case when the date picker is cleared
      setState(null);
      return;
    }
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  return (
    <div>
      <h2 className="mb-4 text-center text-[1.5rem] font-bold">
        Pension Books / Cash Grant
      </h2>
      <div className="flex justify-start w-[50rem]">
        <Select
          placeholder="Year"
          optionFilterProp="children"
          className="wow"
          style={{
            marginBottom: "1.2rem",
            width: "15%",
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          defaultValue={{
            label: "2024",
            value: "2024",
          }}
          onChange={yearChangeHandler}
          // value={allAddressList[showIndex]}
          options={[
            {
              label: "2024",
              value: "2024",
            },
            {
              label: "2025",
              value: "2025",
            },
          ]}
        />
      </div>
      <div className="lg:mb-[2rem] flex mb-[2rem]">
        <div className="cursor-pointer" onClick={() => setTab(0)}>
          <h2 className={`lg:text-[1rem] ${tab === 0 ? "font-semibold" : ""} `}>
            Pension Book
          </h2>
          <div
            className={`lg:w-[5.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
              tab === 0 ? "bg-primary" : "bg-[#E8E8E8]"
            } `}
          ></div>
        </div>

        <div className="cursor-pointer" onClick={() => setTab(1)}>
          <h2
            className={`lg:text-[1rem] ml-6 ${
              tab === 1 ? "font-semibold" : ""
            } `}
          >
            Cash Grant
          </h2>
          <div
            className={`lg:w-[6.5rem] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
              tab === 1 ? "bg-primary" : "bg-[#E8E8E8]"
            }  `}
          ></div>
        </div>
      </div>
      {tab === 0 && (
        <form onSubmit={updateHandler}>
          <div>
            <h2 className="mb-4 text-[1.1rem] font-semibold">
              Current Booklet #:
            </h2>
            <input
              type="text"
              name=""
              placeholder={"Current Booklet #"}
              onChange={(e) => {
                setBookletNo1(e.target.value);
              }}
              value={bookletNo1}
              className={`${wow === 0 ? "" : "hidden"} ${
                allDis1 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            />
            <input
              type="text"
              name=""
              onChange={(e) => {
                setBookletNo2(e.target.value);
              }}
              value={bookletNo2}
              placeholder={"Current Booklet #"}
              className={`${wow === 0 ? "hidden" : ""} ${
                allDis2 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            />
            <h2 className="mb-4 text-[1.1rem] font-semibold">Issued Date:</h2>
            <DatePicker
              placeholder="Date Issued"
              showTime
              value={
                issueDate1 ? dayjs(issueDate1, "YYYY-MM-DD hh:mm A") : undefined
              }
              format="MM/DD/YYYY hh:mm A"
              disabledDate={disabledDate1}
              className={`${wow === 0 ? "" : "hidden"} ${
                allDis1 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              onChange={(date, dateString) => {
                setissueDate1(date ? date.format("MM/DD/YYYY hh:mm A") : null);
              }}
            />
            <DatePicker
              placeholder="Date Issued"
              showTime
              value={
                issueDate2 ? dayjs(issueDate2, "MM/DD/YYYY hh:mm A") : null
              }
              format="MM/DD/YYYY hh:mm A"
              disabledDate={disabledDate1}
              className={`${wow === 0 ? "hidden" : ""} ${
                allDis2 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              onChange={(date, dateString) => {
                setissueDate2(date ? date.format("MM/DD/YYYY hh:mm A") : null);
              }}
            />

            {/* <DatePicker
              placeholder="Issued Date1"
              value={issueDate1 ? dayjs(issueDate1, "YYYY-MM-DD") : null}
              onChange={dateHandler(setissueDate1, "tgf")}
              disabledDate={disabledDate1}
              className={`${wow === 0 ? "" : "hidden"} ${
                allDis1 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            />
            <DatePicker
              placeholder="Issued Date2"
              value={issueDate2 ? dayjs(issueDate2, "YYYY-MM-DD") : null}
              onChange={dateHandler(setissueDate2, "tgf")}
              disabledDate={disabledDate1}
              className={`${wow === 0 ? "hidden" : ""} ${
                allDis2 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            /> */}
            <h2 className="mb-4 text-[1.1rem] font-semibold">Image Front:</h2>
            <div className={`${wow === 0 ? "" : "hidden"}`}>
              <label
                htmlFor="special-input4"
                className={`${
                  allDis1 ? "pointer-events-none" : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input4"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay, setIsLoading1)}
                />
              </label>
              <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display}
                  alt=""
                />
                {/* <div
                    className={`absolute ${
                      isLoading1 ? "" : "hidden"
                    } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                  >
                    <Spin size="large" />
                  </div> */}
              </div>
              <div
                className={`${
                  iShow ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                </div>
              </div>
            </div>
            <div className={`${wow === 0 ? "hidden" : ""}`}>
              <label
                htmlFor="special-input5"
                className={`${
                  allDis2 ? "pointer-events-none" : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input5"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay1, setIsLoading2)}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
                {/* <div
                    className={`absolute ${
                      isLoading2 ? "" : "hidden"
                    } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                  >
                    <Spin size="large" />
                  </div> */}
              </div>
              <div
                className={`${
                  iShow1 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow1(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              dataUser?.data?.user?.roles[0] === "employee" &&
              dataUser?.data?.user?.status === "pending"
                ? "hidden"
                : ""
            }`}
          >
            <div
              className={`${
                (profileData.user.status === "pending" ||
                  profileData.user.status === "verified" ||
                  profileData.user.status === "deferred" ||
                  profileData.user.status === "in-review" ||
                  profileData.user.status === "re-submitted") &&
                dash === "/Dashboard/PensionerAccount"
                  ? "hidden"
                  : ""
              }`}
            >
              {user === "employee" &&
              !privilege?.includes("Edit pension book") ? null : (
                <button
                  type="submit"
                  className={`${allDis1 ? "hidden" : ""} ${
                    wow === 1 ? "hidden" : ""
                  } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                      style={{ display: `${isLoading ? "" : "none"}` }}
                      indicator={antIcon}
                    /> */}
                  <span>Save</span>
                  <div
                    className={`w-[100%] h-[100%] ${
                      isLoading ? "" : "hidden"
                    } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div>
                </button>
              )}
            </div>
            <div
              className={`${
                (profileData.user.status === "pending" ||
                  profileData.user.status === "verified" ||
                  profileData.user.status === "in-review" ||
                  profileData.user.status === "deferred" ||
                  profileData.user.status === "re-submitted") &&
                dash === "/Dashboard/PensionerAccount"
                  ? "hidden"
                  : ""
              }`}
            >
              {user === "employee" &&
              !privilege?.includes("Edit pension book") ? null : (
                <button
                  type="submit"
                  className={`${allDis2 ? "hidden" : ""}  ${
                    wow === 0 ? "hidden" : ""
                  } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                    style={{ display: `${isLoading ? "" : "none"}` }}
                    indicator={antIcon}
                  /> */}
                  <span>Save</span>
                  <div
                    className={`w-[100%] h-[100%] ${
                      isLoading ? "" : "hidden"
                    } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div>
                </button>
              )}
            </div>
          </div>
        </form>
      )}

      {tab === 1 && (
        <form>
          <div>
            <h2 className="mb-4 text-[1.1rem] font-semibold">
              Current Cash Grant #:
            </h2>
            <input
              type="text"
              name=""
              placeholder={"Current Cash Grant #"}
              // onChange={(e) => {
              //   setBookletNo1(e.target.value);
              // }}
              // value={bookletNo1}
              className={`${wow === 0 ? "" : "hidden"} ${
                allDis1 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            />
            <input
              type="text"
              name=""
              // onChange={(e) => {
              //   setBookletNo2(e.target.value);
              // }}
              // value={bookletNo2}
              placeholder={"Current Booklet #"}
              className={`${wow === 0 ? "hidden" : ""} ${
                allDis2 ? "pointer-events-none" : ""
              } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
            />
            <h2 className="mb-4 text-[1.1rem] font-semibold">Image Front:</h2>
            <div className={`${wow === 0 ? "" : "hidden"}`}>
              <label
                htmlFor="special-input4"
                className={`${
                  allDis1 ? "pointer-events-none" : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input4"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay, setIsLoading1)}
                />
              </label>
              {/* <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                  <img
                    onClick={() => {
                      setIShow(true);
                    }}
                    className="w-[25rem] h-[13rem]"
                    src={display}
                    alt=""
                  />
                 
                </div>
                <div
                  className={`${
                    iShow ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow(false)}
                      alt=""
                    />
                    <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                  </div>
                </div> */}
            </div>
            <div className={`${wow === 0 ? "hidden" : ""}`}>
              <label
                htmlFor="special-input5"
                className={`${
                  allDis2 ? "pointer-events-none" : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Image Front</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input5"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setDisplay1, setIsLoading2)}
                />
              </label>
              {/* <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                  <img
                    onClick={() => {
                      setIShow1(true);
                    }}
                    className="w-[25rem] h-[13rem]"
                    src={display1}
                    alt=""
                  />
                 
                </div> */}
              {/* <div
                  className={`${
                    iShow1 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow1(false)}
                      alt=""
                    />
                    <img className="w-[45rem] h-[28rem]" src={"display1"} alt="" />
                  </div>
                </div> */}
            </div>
          </div>
          <div
            className={`${
              dataUser?.data?.user?.roles[0] === "employee" &&
              dataUser?.data?.user?.status === "pending"
                ? "hidden"
                : ""
            }`}
          >
            <div
              className={`${
                (profileData.user.status === "pending" ||
                  profileData.user.status === "verified" ||
                  profileData.user.status === "in-review" ||
                  profileData.user.status === "deferred" ||
                  profileData.user.status === "in-review" ||
                  profileData.user.status === "re-submitted") &&
                dash === "/Dashboard/PensionerAccount"
                  ? "hidden"
                  : ""
              }`}
            >
              {user === "employee" &&
              !privilege?.includes("Edit pension book") ? null : (
                <button
                  type="submit"
                  className={`${allDis1 ? "hidden" : ""} ${
                    wow === 1 ? "hidden" : ""
                  } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                      style={{ display: `${isLoading ? "" : "none"}` }}
                      indicator={antIcon}
                    /> */}
                  <span>Save</span>
                  <div
                    className={`w-[100%] h-[100%] ${
                      isLoading ? "" : "hidden"
                    } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div>
                </button>
              )}
            </div>
            <div
              className={`${
                (profileData.user.status === "pending" ||
                  profileData.user.status === "verified" ||
                  profileData.user.status === "in-review" ||
                  profileData.user.status === "deferred" ||
                  profileData.user.status === "re-submitted") &&
                dash === "/Dashboard/PensionerAccount"
                  ? "hidden"
                  : ""
              }`}
            >
              {user === "employee" &&
              !privilege?.includes("Edit pension book") ? null : (
                <button
                  type="submit"
                  className={`${allDis2 ? "hidden" : ""}  ${
                    wow === 0 ? "hidden" : ""
                  } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                    style={{ display: `${isLoading ? "" : "none"}` }}
                    indicator={antIcon}
                  /> */}
                  <span>Save</span>
                  <div
                    className={`w-[100%] h-[100%] ${
                      isLoading ? "" : "hidden"
                    } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div>
                </button>
              )}
            </div>
          </div>
        </form>
      )}

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default PensionBookDetails;
