import React from "react";
// import sample from "../../../imgs/sample.jpg";
const SampleImageModal = ({ setShow, img, title }) => {
  return (
    <div
    className={`w-full h-full fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10 `}
  >
    <div className="relative p-4 w-full max-w-[40rem] max-h-[30rem]">
      <div className="relative bg-white rounded-lg shadow ">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
          <h3 className="text-lg font-semibold text-gray-900 ">
            {title}
          </h3>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
            data-modal-toggle="select-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
  
        <img src={img} alt="voucher" className="w-full h-[20rem] p-3" />
      </div>
    </div>
  </div>
  
  );
};

export default SampleImageModal;
