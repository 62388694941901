import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
const VouchersModal = ({ setShowAmountModal, amount }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const router = useNavigate();
  const sapmoney = useSelector((state) => state.sapmoney);
  console.log(sapmoney);
  const user = useSelector((state) => state.user);
  const [stores, setStores] = useState([]);
  //get Stores
  useEffect(() => {
    const api =
      user === "merchant-owner" || user === "merchant-admin"
        ? "v1/merch-store"
        : "v1/merch-employee-store/my";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        if (user === "merchant-owner" || user === "merchant-admin") {
          setStores(data?.data?.rows);
        } else {
          const store = data?.data?.map((item) => item.merchstore);
          setStores(store);
        }
      })
      .catch(() => {});
    // setStores(merchantStores)
  }, []);

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-gray-900 ">
              Total Amount
            </h3>
            <button
              onClick={() => setShowAmountModal(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4 md:p-5">
            <p className="text-gray-700  mb-1 text-center">
              Total Amount selected to pay
            </p>
            <div className="flex items-center justify-center  mb-3">
              <p className=" pr-10 pl-10 pt-2 pb-2 text-primary text-lg font-semibold rounded-md">
                ${amount ? amount.toFixed(2) : 0}
              </p>
            </div>
            <p className="mb-4 text-center px-2">
              We have also identified a supplementary amount of $10,000 for this
              pensioner. Would you like to include this amount in the payment as
              well?
            </p>

            <div className="p-2 flex items-center justify-center">
              <Checkbox
                // checked={copArr[index].isCurrent}
                // onChange={(e) => setOfficeUse(e.target.checked)}
                className={"checkamount"}
              >
                Include Supplementary Amount
              </Checkbox>
            </div>

            <div className="flex gap-2 p-4">
              <button
                onClick={() => {
                  setShowAmountModal(false);
                }}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-[#F35929]  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (sapmoney === true) {
                    router("/Merchant/SapMoney/Verify");
                  } else {
                    if (stores.length === 0) {
                      router("/Merchant/PensionerProfile/Verify");
                    } else {
                      router("/Merchant/Pensioner/SelectStore");
                    }
                  }
                  dispatch({ type: "VOUCHER_AMOUNT", num: amount });

                  setShowAmountModal(false);
                }}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />} */}
    </div>
  );
};

export default VouchersModal;
