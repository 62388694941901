import React, { useState } from "react";
// import cardimg2 from "../../../../imgs/cardimg2.png";
import otp from "../../../../imgs/otp.svg";
import email from "../../../../imgs/email.svg";
import pin from "../../../../imgs/pin.svg";
import ques from "../../../../imgs/ques.svg";
import facer from "../../../../imgs/facer.svg";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PhoneVerification from "../Verify/Modals/PhoneVerification";
import FacialVerification from "../Verify/Modals/FacialVerification";
import QuestionVerification from "../Verify/Modals/QuestionVerification";
import ErrorModal from "../../Modals/errorModal";
import axios from "axios";
// import SuccessModal from "../../Modals/successModal";
import VerifyModal from "../../Modals/verifyModal";
import { useNavigate } from "react-router-dom";
const VerifyMain = () => {
  const router = useNavigate();
  // const dispatch = useDispatch();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const token = useSelector((state) => state.token);
  const [value, setValue] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const sapmoney = useSelector((state) => state.sapmoney);
  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );

  const verification = [
    {
      value: "otp",
      title: "OTP",
      text: "Validate the profile via Registered Phone",
      image: otp,
    },
    {
      value: "email",
      title: "Email Verification",
      text: "Validate the profile via Registered Email",
      image: email,
    },
    {
      value: "pin",
      title: "PIN Code",
      text: "Verify identity with customized PIN code",
      image: pin,
    },
    {
      value: "ques",
      title: "Security Question",
      text: "Verify the identity with the security question ",
      image: ques,
    },
    {
      value: "facer",
      title: "Via Facial Recognition",
      text: "Match candidate with real-time facial match",
      image: facer,
    },
  ];

  // const filteredVerification = verification?.filter((item) => {
  //   if (item.value === "otp" && !selectedPensioner?.user?.countryCode) {
  //     return false; // Exclude "OTP" if phone is not found
  //   }
  //   if (item.value === "email" && !selectedPensioner?.user?.email) {
  //     return false; // Exclude "Email Verification" if email is not found
  //   }
  //   // Add more conditions as needed for other verification methods

  //   return true; // Include the item by default
  // });

  const sendOTP = () => {
    if (!selectedPensioner?.user?.countryCode) {
      setShowErrModal(true);
      setErr("We didn't find any phone number linked with this pensioner");
      return;
    }
    let couponIds = [];
    selectedVoucherForCash?.map((item) => {
      couponIds.push(item.id);
    });
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/merch-verify-pensioner/send-phone-otp?pensionerUserId=${selectedPensioner?.user?.id}`,
        { couponIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setValue("otp");
      })
      .catch(() => {
        setShowErrModal(true);
        setErr("Something went wrong, please try again later.");
      });
  };

  const sendEmail = () => {
    if (!selectedPensioner?.user?.email) {
      setShowErrModal(true);
      setErr("We didn't find any email address linked with this pensioner");
      return;
    }
    let couponIds = [];
    selectedVoucherForCash?.map((item) => {
      couponIds.push(item.id);
    });
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/merch-verify-pensioner/send-email-otp?pensionerUserId=${selectedPensioner?.user?.id}`,
        { couponIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setValue("email");
      })
      .catch(() => {
        setShowErrModal(true);
        setErr("Something went wrong, please try again later.");
      });
  };

  return (
    <div className={`${sapmoney ? "mt-3":"sm:mt-[-2rem] mt-3"}`}>
      <div className="sm:mx-4 mb-12">
        <div className="sm:p-8 p-3 bg-white  rounded-lg">
          <div className="w-full  mb-10 relative">
            <div className="bg-[#F5F5F5] p-6 rounded-md mb-4">
              <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-500"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-3 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div>
              <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
                <div className="flex items-center sm:flex-row flex-col ">
                  <div className="sm:w-28 sm:h-28 w-20 h-20 bg-white border  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                    <img
                      src={selectedPensioner?.user?.person?.image}
                      alt="profile"
                      className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                    />
                  </div>
                  <div className="ml-4 space-y-1 ">
                    <h1 className="text-xl font-semibold text-primary capitalize">
                      {selectedPensioner?.user?.person?.firstName}{" "}
                      {selectedPensioner?.user?.person?.middleName &&
                        selectedPensioner?.user?.person?.middleName + " "}
                      {selectedPensioner?.user?.person?.lastName}{" "}
                      {/* <span className="font-light text-gray-600">27</span> */}
                    </h1>
                    <div className="space-y-1">
                      <p className="text-md font-medium text-gray-700">
                        GID :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.gid}
                        </span>
                      </p>
                      <p
                        className={`text-md font-medium text-gray-600`}
                      >
                        DOB :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.person?.dateOfBirth}
                        </span>
                      </p>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>

            {verification?.map((item, index) => (
              <div
                key={index}
                onClick={() =>
                  item?.value === "otp"
                    ? sendOTP()
                    : item?.value === "email"
                    ? sendEmail():
                    // item?.value==="ques"?
                    // router("/Merchant/PensionerProfile/Verify/Questions")
                    // : 
                    setValue(item.value)
                }
                className={`flex items-center justify-between p-3 rounded-lg mb-4 cursor-pointer 
                ${
                  item.value === ""
                    ? "bg-gray-50 pointer-events-none"
                    : "bg-[#F5F5F5]"
                }
                ${item.value === value ? "bg-primary" : "bg-[#F5F5F5]"}`}
              >
                <div className="flex items-center">
                  <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                    <img src={item.image} alt="vc" className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3
                      className={`text-lg font-semibold mb-1 ${
                        item.value === value ? "text-white" : "text-primary"
                      }`}
                    >
                      {item.title}
                    </h3>
                    <p
                      className={`text-xs ${
                        item.value === value ? "text-white" : "text-gray-600"
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {value === "otp" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {value === "email" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {value === "pin" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {value === "facer" && (
        <FacialVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {value === "ques" && (
        <QuestionVerification   setValue={setValue}
        value={value}
        setShowErrModal={setShowErrModal}
        err={err}
        setErr={setErr}
        setShowSuccessModal={setShowSuccessModal} />
      )}
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
      {showSuccessModal && (
        <VerifyModal
          setShow={setShowSuccessModal}
          err={err}
          setValue={setValue}
          verify={"Verify"}
        />
      )}
    </div>
  );
};

export default VerifyMain;
