import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import vc from "../../../../imgs/vc.svg";
import ErrorModal from "../../Modals/errorModal";
import AmountModal from "./AmountModal";
import axios from "axios";
const VouchersModal = () => {
  const activeVouchers = useSelector((state) => state.activeVouchers);
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [selectedVoucherIds, setSelectedVoucherIds] = useState([]);
  const [amount, setAmount] = useState(0);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const selectedProgramPensionBook = useSelector(
    (state) => state.selectedProgramPensionBook
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [err, setErr] = useState("");
  const handleSelection = (index) => {
    const selectedVoucher = activeVouchers[index];
    setSelectedVouchers((prevSelectedVouchers) => {
      const isSelected = prevSelectedVouchers.includes(selectedVoucher);
      const updatedVouchers = isSelected
        ? prevSelectedVouchers.filter((voucher) => voucher !== selectedVoucher)
        : [...prevSelectedVouchers, selectedVoucher];

      dispatch({ type: "SELECTED_VOUCHER_FOR_CASH", num: updatedVouchers });
      return updatedVouchers;
    });
    setSelectedVoucherIds((prevSelectedIds) => {
      const isSelected = prevSelectedIds.includes(selectedVoucher.id);
      return isSelected
        ? prevSelectedIds.filter((id) => id !== selectedVoucher.id)
        : [...prevSelectedIds, selectedVoucher.id];
    });
  };
  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };
  const getAmount = () => {
    if (selectedVoucherIds?.length === 0) {
      setErr("Please select a voucher");
      setShowErrorModal(true);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/get-total-amount-by-program`,
        {
          pensionProgramId: selectedProgramPensionBook?.pensionprogramId,
          couponIds: selectedVoucherIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setAmount(data?.data?.totalAmount);
        dispatch({ type: "VOUCHER_AMOUNT", num: amount });
        // setShowVouchers(false);
        setShowAmountModal(true);
      })
      .catch(() => {});
  };
  return (
    <>
      <div className="mt-[-2rem] sm:mt-[-4rem]">
        <div className="sm:mx-8 mb-12">
          <div className="sm:p-8 p-3  bg-white  mt-8 rounded-lg">
            <div className="w-full  p-8 rounded-lg mb-10 relative border shadow-md">
              <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-500"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-8 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div>
              <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
                <div className="flex items-center sm:flex-row flex-col">
                  <div className="sm:w-36 sm:h-36 w-20 h-20 bg-indigo-100  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                    <img
                      src={selectedPensioner?.user?.person?.image}
                      alt="profile"
                      className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                    />
                  </div>
                  <div className="ml-4">
                    <h1 className="text-2xl sm:text-3xl font-semibold text-gray-800">
                      {selectedPensioner?.user?.person?.firstName}{" "}
                      {selectedPensioner?.user?.person?.middleName &&
                        selectedPensioner?.user?.person?.middleName + " "}
                      {selectedPensioner?.user?.person?.lastName}{" "}
                      {/* <span className="font-light text-gray-600">27</span> */}
                    </h1>
                    <div className="mt-4 space-y-2">
                      <p className="text-md font-medium text-gray-700">
                        GID:{" "}
                        <span className="text-md text-gray-500">
                          {selectedPensioner?.user?.gid}
                        </span>
                      </p>
                      <p
                        className={`text-md font-medium text-gray-700 
        
                      `}
                      >
                        DOB:{" "}
                        <span className="text-gray-500 text-md">
                          {selectedPensioner?.user?.person?.dateOfBirth}
                        </span>
                      </p>
                      <p className="text-md font-medium text-gray-700">
                        Gender:{" "}
                        <span className="text-gray-500 text-md">
                          {selectedPensioner?.user?.person?.gender}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="h-px my-8 bg-gray-300 border-0 " />

              <div className="bg-[#F4F5FE] text-primary p-2 rounded-lg mb-6 ">
                <h3 className="text-lg font-semibold mb-1 px-2">
                  Step 1 : Select the Available Vouchers
                </h3>
              </div>

              {activeVouchers?.map((voc, index) => (
                <div
                  key={index}
                  onClick={() => handleSelection(index)}
                  className={`flex items-center justify-between p-3 rounded-lg mb-4 cursor-pointer            
                ${
                  selectedVouchers.includes(voc) ? "bg-primary" : "bg-[#F8F3F3]"
                }`}
                >
                  <div className="flex items-center">
                    <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                      <img src={vc} alt="vc" className="w-6 h-6" />
                    </div>
                    <div className="ml-4">
                      <h3
                        className={`text-lg font-semibold mb-1 ${
                          selectedVouchers.includes(voc)
                            ? "text-white"
                            : "text-primary"
                        }`}
                      >
                        {getMonthName(voc.month)}
                      </h3>
                      <p
                        className={`text-md ${
                          selectedVouchers.includes(voc)
                            ? "text-white"
                            : "text-gray-600"
                        }`}
                      >
                        Booklet # {selectedProgramPensionBook?.bookletNo}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex p-4 gap-2 items-center justify-center  px-[30%]">
                {/* <button
                        // onClick={() => setShowVouchers(false)}
                        className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-[#DE2827]  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Cancel
                      </button> */}
                <button
                  onClick={() => getAmount()}
                  className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Calculate
                </button>
              </div>
            </div>
          </div>
        </div>
        {showAmountModal && (
          <AmountModal
            setShowAmountModal={setShowAmountModal}
            amount={amount}
          />
        )}
        {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      </div>
      {/* <div className="mt-[-2rem] sm:mt-[-4rem]">
        <div className="sm:mx-8 mb-12">
          <div className="p-8 bg-white  mt-8 rounded-lg">
            <div className="w-full  p-2 sm:p-8  mb-10 rounded-lg border shadow-md">
              <ProfileCard />
              <hr className="h-px my-8 bg-gray-300 border-0 " />
              <section className="text-gray-700 body-font bg-white p-6 rounded-md">
                <div action="" className="mt-0">
                  <div className="flex justify-center flex-col bg-gray-50 w-full md:px-24">
                   
                    <div className="p-4 md:p-5">
                      <p className="text-gray-500  mb-4 text-lg font-semibold">
                        Select the Vouchers :
                      </p>
                      <ul className="space-y-4 max-h-96 overflow-y-scroll custom-scrollbar p-4">
                        {activeVouchers.map((voc, index) => (
                          <li
                            key={index}
                            onClick={() => handleSelection(index)}
                          >
                            <input
                              type="checkbox"
                              checked={selectedVouchers.includes(voc)}
                              onClick={() => alert("clicked")}
                             
                              className="hidden peer"
                              required
                            />
                            <label
                              className={`inline-flex items-center justify-between w-full p-3 ${
                                selectedVouchers.includes(voc)
                                  ? "bg-[#F5F5F5] text-primary border-primary"
                                  : "text-gray-900 bg-white"
                              } shadow-sm border border-gray-300 rounded-lg cursor-pointer  peer-checked:border-primary peer-checked:text-primary `}
                            >
                              <div className="block">
                                <div className="w-full text-md font-semibold">
                                  {getMonthName(voc.month)}
                                </div>
                                <div className="w-full text-gray-500">
                                  Booklet #{" "}
                                  {selectedProgramPensionBook?.bookletNo}
                                </div>
                              </div>
                              <img src={vc} alt="voc" className="w-6 h-6" />
                            </label>
                          </li>
                        ))}
                      </ul>
                      <div className="flex p-4 gap-2 items-center justify-center  px-[30%]">
                     
                        <button
                          onClick={() => getAmount()}
                          className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Calculate
                        </button>
                      </div>
                    </div>
                  </div>

              
                </div>
              </section>
            </div>
          </div>
        </div>

        {showAmountModal && (
          <AmountModal
            setShowAmountModal={setShowAmountModal}
          
            amount={amount}
          />
        )}
        {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      </div> */}
    </>
  );
};

export default VouchersModal;
