import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import across from "../../../../imgs/across.png";
import SuccessModal from "../../Modals/verifyModal";
import ErrorModal from "../../Modals/errorModal";
import axios from "axios";
const VerifyMain = () => {
  const dispatch = useDispatch();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const token = useSelector((state) => state.token);
  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [reset, setReset] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [value, setValue] = useState(false);
  const [answer, setAnswer] = useState("");
  const sapmoney = useSelector((state) => state.sapmoney);
  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/security-questions/pick-random-user-question?userId=${selectedPensioner?.user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log("dataaaaaa", data);
        setQuestion(data?.data?.question);
        setQuestionId(data?.data?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);
  const Submit = () => {
    let couponIds = [];
    selectedVoucherForCash?.map((item) => {
      couponIds.push(item.id);
    });
    if (answer === "") {
      setErr("Please Provide answer for the question.");
      setShowErr(true);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/merch-verify-pensioner/verify-security-question?pensionerUserId=${selectedPensioner?.user?.id}`,
          {
            securityquestionId: questionId,
            answer: answer,
            couponIds: couponIds,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("question", data);
          setAnswer("");
          dispatch({ type: "ACTIVE_PENSIONER_SESSION", num: "verified" });
          setShowSuccessModal(true);
          setErr("Pensioner Successfully Verified ");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShowErrModal(true);
        });
    }
  };
  return (
    <div className={`sm:mt-[-2rem] mt-3`}>
      <div className="sm:mx-4 mb-12">
        <div className="sm:p-8 p-3 bg-white  rounded-lg">
          <div className="w-full  mb-10 relative">
            <div className="bg-[#F5F5F5] p-6 rounded-md mb-4">
              <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-500"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-3 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div>
              <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
                <div className="flex items-center sm:flex-row flex-col ">
                  <div className="sm:w-28 sm:h-28 w-20 h-20 bg-white border  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                    <img
                      src={selectedPensioner?.user?.person?.image}
                      alt="profile"
                      className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                    />
                  </div>
                  <div className="ml-4 space-y-1 ">
                    <h1 className="text-xl font-semibold text-primary capitalize">
                      {selectedPensioner?.user?.person?.firstName}{" "}
                      {selectedPensioner?.user?.person?.middleName &&
                        selectedPensioner?.user?.person?.middleName + " "}
                      {selectedPensioner?.user?.person?.lastName}{" "}
                      {/* <span className="font-light text-gray-600">27</span> */}
                    </h1>
                    <div className="space-y-1">
                      <p className="text-md font-medium text-gray-700">
                        GID :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.gid}
                        </span>
                      </p>
                      <p className={`text-md font-medium text-gray-600`}>
                        DOB :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.person?.dateOfBirth}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-4">
                Security Questions
              </label>
              <div className="bg-gray-50 md:w-[50%] sm:w-[75%] w-full pt-4 mt-2 p-2 pb-4 flex flex-col gap-1">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                  {question}
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                  value={answer}
                  placeholder="Answer"
                  className="lg:text-[1.1rem] w-full bg-white border-none rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={Submit}
              className={`lg:text-[1rem] mt-6 bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showErr ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowErr(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowErr(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      {showSuccessModal && (
        <SuccessModal
          setShow={setShowSuccessModal}
          err={err}
          setValue={setValue}
          verify={"Verify"}
        />
      )}
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
    </div>
  );
};

export default VerifyMain;
