import React, { useEffect } from "react";
import { Layout, theme } from "antd";
import Sidebar from "./sideBar";
import { useDispatch } from "react-redux";
import profile from "../../imgs/profile.png";
import eWall from "../../imgs/SAP_Money.png"
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
const { Content, Sider } = Layout;
const App = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const image = dataUser?.data?.user?.person?.image;
  const dispatch = useDispatch();
  ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Income",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          2000, 2500, 2500, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
          3100,
        ],
      },
      {
        label: "Expense",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(192,75,192,0.4)",
        borderColor: "rgba(192,75,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(192,75,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(192,75,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          1500, 1400, 2100, 1800, 2000, 2200, 2500, 2000, 1900, 1800, 2500,
          2100,
        ],
      },
    ],
  };

  const options = {};

  useEffect(()=>
  {
    dispatch({ type: "SIDEBARE", num: 1 });
  },[])
  const beneficiaries = [
    {
      profilePicture: profile,
      name: "John",
    },
    {
      profilePicture: profile,
      name: "Jane",
    },
    {
      profilePicture: profile,
      name: "Bakar",
    },
    {
      profilePicture: profile,
      name: "Waqas",
    },
    {
      profilePicture: profile,
      name: "Michael",
    },
    {
      profilePicture: profile,
      name: "Urooj",
    },
  ];

  const recentTransactions = [
    {
      name: "Online Purchase",
      date: "2024-03-05",
      amount: 50.25,
      type: "expense",
    },
    {
      name: "Gas Station",
      date: "2024-03-01",
      amount: 45.75,
      type: "expense",
    },
    { name: "Pension", date: "2024-03-07", amount: 1000, type: "income" },
    {
      name: "Grocery Store",
      date: "2024-02-28",
      amount: 70.0,
      type: "expense",
    },
    {
      name: "Utilities Bill",
      date: "2024-02-25",
      amount: 100.0,
      type: "expense",
    },
  ];
  return (
    <Layout
      style={{
        padding: 5,
        margin: 0,
        background: "#ebfff3",
      }}
    >
      <Sider
        breakpoint="lg"
        width={230}
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        className="flex flex-col justify-between h-full"
        style={{
          borderRadius: borderRadiusLG,
          width: "230px",
          background: "#ebfff3",
          // backgroundColor:"white",
          height: "100vh",
        }}
      >
        <Sidebar />
      </Sider>

      <Layout
        style={{
          background: "#ebfff3",
        }}
      >
        <div
          style={{
            margin: "0px 16px 0",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
            // boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;',
          }}
        >
          {/* <DashHeader /> */}
        </div>
        <Content
        // style={{
        //   margin: "24px 16px 10px",
        //   boxShadow:
        //     "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        //   padding: 26,
        //   background: colorBgContainer,
        //   borderRadius: borderRadiusLG,
        //   minHeight: "fit-content",
        // }}
        >
          <div className="w-full mt-2 flex justify-between items-center mb-4">
            <h1 className="text-2xl pl-2 font-semibold w-[215px]"><span className="text-red-500">eWallet </span>Dashboard</h1>
            <img
                src={eWall}
                alt="eWallet"
                className="h-35 w-40"
              />
            <div className="flex items-center space-x-4">
              {/* Search Field */}
              <input
                type="text"
                placeholder="Search..."
                className="px-4 py-2 bg-white rounded-lg focus:outline-none"
              />
              {/* Profile Image */}
              <img
                src={image}
                alt="Profile"
                className="h-10 w-10 rounded-full"
              />
              <h1 className="text-md pr-4 font-semibold">
                {dataUser?.data?.user?.person?.firstName}
                {" "}
                {dataUser?.data?.user?.person?.lastName}
              </h1>
            </div>
          </div>
          <div className="flex items-center justify-center gap-x-2">
            <div className="w-3/5">
              <div className="bg-white shadow-md rounded-md">
                <div className="p-6">
                  <div className="flex justify-between gap-x-2">
                    {/* Total Balance Card */}
                    <div className="bg-[#F25A29] hover:bg-[#F79371] cursor-pointer text-white p-6 rounded-lg shadow-md w-1/3">
                      <h2 className="text-lg font-semibold">Total Balance</h2>
                      <p className="text-3xl font-bold mt-2">$2400</p>
                    </div>
                    {/* Total Spend Card */}
                    <div className="bg-[#8BD6B5] hover:bg-[#A6E8C9] cursor-pointer text-white p-6 rounded-lg shadow-md w-1/3">
                      <h2 className="text-lg font-semibold">Total Spend</h2>
                      <p className="text-3xl font-bold mt-2">$1200</p>
                    </div>
                    {/* Total Income Card */}
                    <div className="bg-primary cursor-pointer  text-white p-6 rounded-lg shadow-md w-1/3">
                      <h2 className="text-lg font-semibold">Total Income</h2>
                      <p className="text-3xl font-bold mt-2">$1700</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white mt-2 h-[22.5rem] shadow-md rounded-md">
                <div className="p-2">
                  <Line
                    data={data}
                    options={options}
                    className="w-full h-full"
                  />
                </div>
              </div>
            </div>
            <div className="w-2/5 h-[84vh]">
              <div className="bg-white h-[23rem] p-2 shadow-md rounded-md">
                <h2 className="text-lg font-semibold">Recent Transections</h2>
                <div className="mt-3">
                  {recentTransactions.map((transaction, index) => (
                    <div
                      key={index}
                      className={`flex justify-between cursor-pointer items-center p-2 rounded-lg mb-1 ${
                        transaction.type === "income"
                          ? "bg-green-100"
                          : "bg-red-100"
                      }`}
                    >
                      <div className="flex flex-col">
                        <p className="font-semibold">{transaction.name}</p>
                        <p className="text-gray-500 text-sm">
                          {transaction.date}
                        </p>
                      </div>
                      <p
                        className={`font-semibold ${
                          transaction.type === "income"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {transaction.type === "income" ? "+" : "-"}$
                        {transaction.amount}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white mt-2 h-[10.03rem] p-2 shadow-md rounded-md">
                <h2 className="text-lg font-semibold">Quick Transactions</h2>
                <p className="text-gray-400">List of your beneficiaries</p>
                <div className="flex items-center mt-3 justify-center gap-x-6">
                {beneficiaries.map((beneficiary, index) => (
                  <div key={index} className="flex items-center cursor-pointer justify-center flex-col">
                    <img
                      src={beneficiary.profilePicture}
                      alt={beneficiary.name}
                      className="h-12 w-12 rounded-full mb-2"
                    />
                    <p className="font-semibold">{beneficiary.name}</p>
                  </div>
                ))}
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;
