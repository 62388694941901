import { useState } from "react";
import { DownCircleFilled } from "@ant-design/icons";
import { Select } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Checkbox } from "antd";
import { useLocation } from "react-router-dom";
let income = [];
let expense = [];
let firstQ = "";
let secondQ = "";
let question1;
let question2;
let question3;
let question4;
let question5;
const Finance = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const [show1, setShow1] = useState(false);
  const [checkBuffer, setCheckBuffer] = useState(false);
  const [mainShow, setMainShow] = useState(false);
  const appQuestions = useSelector((state) => state.appQuestions);
  const [type, setType] = useState(null);
  const [amount, setAmount] = useState("");
  const [sideShow, setSideShow] = useState(false);
  const [type1, setType1] = useState(null);
  const [amount1, setAmount1] = useState("");
  const [test, setTest] = useState(false);
  const [msgShow, setMsgShow] = useState(false);
  const Questiona = useSelector((state) => state.Questiona);
  const Questionb = useSelector((state) => state.Questionb);
  const Questionc = useSelector((state) => state.Questionc);
  const Questiond = useSelector((state) => state.Questiond);
  const Questione = useSelector((state) => state.Questione);

  useEffect(() => {
    console.log("1", question1,Questiona);
  }, [question1]);
  useEffect(() => {
    console.log("2", question2,Questionb);
  }, [question2]);
  useEffect(() => {
    console.log("3", question3,Questionc);
  }, [question3]);
  useEffect(() => {
    console.log("4", question4,Questiond);
  }, [question4]);
  useEffect(() => {
    console.log("5", question5,Questione);
  }, [question5]);

  const nextHandler = () => {
    // if (question1 !== null) {
    //   Questiona.push(question1);
    // }
    // if (question2 !== null) {
    //   Questionb.push(question2);
    // }
    // if (question3 !== null) {
    //   Questionc.push(question3);
    // }
    // if (question4 !== null) {
    //   Questiond.push(question4);
    // }
    // if (question5 !== null) {
    //   Questione.push(question5);
    // }

    if (Questiona.length !== 0) {
      Questiona.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Questionb.length !== 0) {
      Questionb.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Questionc.length !== 0) {
      Questionc.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Questiond.length !== 0) {
      Questiond.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    if (Questione.length !== 0) {
      Questione.map((item) => {
        if (
          item.type === "text" ||
          item.type === "radio" ||
          item.type === "checkbox" ||
          item.type === "dropdown" ||
          item.type === "only-children"
        ) {
          item.type = "string";
        }
        return item;
      });
    }
    dispatch({ type: "AINCREMENT" });
  };
  // useEffect(() => {
  //   return () => {
  //     // applicationAnswers.push(question1);
  //     // applicationAnswers.push(question2);
  //     // applicationAnswers.push(question3);
  //     // applicationAnswers.push(question4);
  //     // applicationAnswers.push(question5);

  //     // question1 = {};
  //     // question2 = {};
  //     // question3 = {};
  //     // question4 = {};
  //     // question5 = {};
  //   };
  // }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [firstQ, secondQ]);
  useEffect(() => {
    if (localStorage.getItem("electricityBill")) {
      if (localStorage.getItem("electricityBill") !== "y") {
        const wow = {
          type: `Electricity Bill`,
          amount: `${localStorage.getItem("electricityBill")}`,
        };
        expense.push(wow);
      }
    }
    if (localStorage.getItem("waterBill")) {
      if (localStorage.getItem("waterBill") !== "y") {
        const wow = {
          type: `Water Bill`,
          amount: `${localStorage.getItem("waterBill")}`,
        };
        expense.push(wow);
      }
    }
    if (localStorage.getItem("publicAssistance")) {
      if (localStorage.getItem("publicAssistance") !== "y") {
        const type = localStorage.getItem("publicAssistanceType");
        const amount = localStorage.getItem("publicAssistance");
        const modifiedType =
          type === "Old Age Pensioner" ? "Senior Citizen Pensioner" : type;

        const wow = {
          type: modifiedType,
          amount: amount,
        };
        income.push(wow);
      }
    }
  }, []);

  const changeHandler = (item, index) => {
    setCheckBuffer(item);
    question1 = {
      appquestionId: firstQ[0].id,
      belongToIndex: `${income.length}`,
      type: firstQ[0].type,
    };
    question1.answers = [item];
  };
  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const show1Handler = () => {
    if (!show1) {
      setShow1(true);
    } else {
      setShow1(false);
    }
  };

  const submittedHandler = (e) => {
    e.preventDefault();
  };
  const typeHandler = (e) => {
    setType(e);
  };
  const amountHandler = (e) => {
    setAmount(e.target.value);
  };
  const type1Handler = (e) => {
    setType1(e);
  };
  const amount1Handler = (e) => {
    setAmount1(e.target.value);
  };
  const show2Handler = (e) => {
    if (e === "add") {
      setMainShow(true);
    } else if (e === "save") {
      if (amount !== null && type !== null && amount !== "" && type1 !== "") {
        const wow = {
          type: `${type}`,
          amount: `${amount}`,
        };
        income.push(wow);
        setType(null);
        setAmount("");
        setMainShow(false);
        setMsgShow(false);
        Questiona.push(question1)
        Questionb.push(question2)
        Questionc.push(question3)
      } else setMsgShow(true);
    } else if (e === "cancel") {
      setMainShow(false);
      setType(null);
      setAmount("");
      setMsgShow(false);
    }
  };

  const show3Handler = (e) => {
    if (e === "add") {
      setSideShow(true);
    } else if (e === "save") {
      if (amount !== null && type1 !== null && amount1 !== "" && type1 !== "") {
        const wow = {
          type: `${type1}`,
          amount: `${amount1}`,
        };
        expense.push(wow);
        setType1(null);
        setAmount1("");
        setSideShow(false);
        setMsgShow(false);
        Questiond.push(question4)
        Questione.push(question5)
      } else setMsgShow(true);
    } else if (e === "cancel") {
      setSideShow(false);
      setType1(null);
      setAmount1("");
      setMsgShow(false);
    }
  };

  useEffect(() => {
    if (appQuestions) {
      firstQ = appQuestions.filter((item) => {
        return item.order === 10;
      });
      secondQ = appQuestions.filter((item) => {
        return item.order === 11;
      });
    }
  }, [appQuestions]);

  function formatNumber(amount) {
    const number = parseFloat(amount, 10);
    const decimalPlaces = (number.toString().split(".")[1] || []).length;
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimalPlaces,
    });
  }

  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        <div>
          {firstQ && secondQ && (
            <div>
              <div
                onClick={showHandler}
                className="w-full h-[3rem] bg-[#F7F7F7] flex items-center pl-4 pr-4 mb-4 justify-between rounded-xl cursor-pointer"
              >
                <p>{firstQ[0].questionText}</p>
                <div>
                  <DownCircleFilled className=" text-primary text-2xl flex item-center justify-center" />
                </div>
              </div>
              <div className={`ml-7 mr-7 mt-[2.2rem] ${show ? "" : "hidden"}`}>
                {income.map((item, index) => {
                  return (
                    <div className="w-full flex justify-between items-center mb-5">
                      <p>{item.type}</p>
                      <p className="underline">{formatNumber(item.amount)}</p>
                    </div>
                  );
                })}
                <div
                  className="flex justify-center items-center mt-4 mb-6"
                  // onClick={() => addAddressHandler("add")}
                >
                  <div
                    onClick={() => show2Handler("add")}
                    className="flex justify-center items-center cursor-pointer"
                  >
                    <div>
                      <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center mr-2" />
                    </div>
                    <p className="text-primary">Add Income</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {firstQ && (
            <div>
              <div
                onClick={show1Handler}
                className="w-full h-[3rem] bg-[#F7F7F7] flex items-center pl-4 pr-4 justify-between rounded-xl cursor-pointer"
              >
                <p>{secondQ[0].questionText}</p>
                <div>
                  <DownCircleFilled className=" text-primary text-2xl flex item-center justify-center" />
                </div>
              </div>
              <div className={`ml-7 mr-7 mt-[2.2rem] ${show1 ? "" : "hidden"}`}>
                {expense.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex justify-between items-center mb-5"
                    >
                      <p>{item.type}</p>
                      <p className="underline">{formatNumber(item.amount)}</p>
                    </div>
                  );
                })}
                <div className="flex justify-center items-center mt-4 mb-6">
                  <div
                    onClick={() => show3Handler("add")}
                    className="flex justify-center items-center cursor-pointer"
                  >
                    <div>
                      <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center mr-2" />
                    </div>
                    <p className="text-primary">Add Expense</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <form
          onSubmit={submittedHandler}
          className={`${
            mainShow ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          {firstQ !== "" && secondQ !== "" && (
            <div className="relative rounded-xl sm:w-[40rem] w-[20rem] pl-5 pr-5 pb-6 flex pt-4 bg-white flex-col">
              <h2 className="text-[1.3rem] font-bold mb-10 text-center">
                Please Provide your income details
              </h2>
              <div className="flex justify-start items-start mb-3 font-bold">
                <h2>Employment</h2>
              </div>
              {firstQ[0].childrenQuestions.map((item, index) => {
                return (
                  <div>
                    {item.type === "radio" && (
                      <div>
                        {item.choices.map((item, index) => {
                          return (
                            <div>
                              <Checkbox
                                className={`${
                                  dash === "/Dashboard/application"
                                    ? "checkk1"
                                    : "checkk"
                                }`}
                                checked={checkBuffer === item ? true : false}
                                onClick={() => changeHandler(item, index)}
                              >
                                {item}
                              </Checkbox>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {item.type === "dropdown" && (
                      <div>
                        <Select
                          onChange={(val) => {
                            typeHandler(val);

                            question2 = {
                              appquestionId: item.id,
                              belongToIndex: `${income.length}`,
                              type: item.type,
                              answers: [val],
                            };
                          }}
                          value={type}
                          placeholder={`${item.questionText}`}
                          className="mb-2 mt-2 wow"
                        >
                          {}
                          {item.choices.map((option) => (
                            <Select.Option key={option} value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    {item.type === "number" && (
                      <input
                        onChange={(e) => {
                          amountHandler(e);
                          question3 = {
                            appquestionId: item.id,
                            belongToIndex: `${income.length}`,
                            type: item.type,
                            answers: [e.target.value],
                          };
                        }}
                        type="number"
                        value={amount}
                        className={`lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                    )}
                  </div>
                );
              })}
              {msgShow ===  true && (
                <p className="text-red-400">Please Provide all details</p>
              )}
              <div className="flex justify-center mt-3">
                <button
                  onClick={() => show2Handler("cancel")}
                  className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
                >
                  Cancel
                </button>
                <button
                  onClick={() => show2Handler("save")}
                  type="submit"
                  className="ml-5 cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </form>

        {firstQ !== "" && secondQ !== "" && (
          <form
            onSubmit={submittedHandler}
            className={`${
              sideShow ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl sm:w-[40rem] w-[20rem] pl-5 pr-5 pb-6 flex pt-4 bg-white flex-col">
              <h2 className="text-[1.3rem] font-bold mb-10 text-center">
                Please Provide your expense details
              </h2>
              <div className="flex justify-start items-start mb-3 font-bold">
                <h2>Expense</h2>
              </div>
              {secondQ[0].childrenQuestions.map((item, index) => {
                return (
                  <div>
                    {item.type === "radio" && (
                      <div>
                        {item.choices.map((item, index) => {
                          return (
                            <div>
                              <Checkbox
                                className={`${
                                  dash === "/Dashboard/application"
                                    ? "checkk1"
                                    : "checkk"
                                }`}
                                checked={checkBuffer === item ? true : false}
                                onClick={() => changeHandler(item, index)}
                              >
                                {item}
                              </Checkbox>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {item.type === "dropdown" && (
                      <div>
                        <Select
                          onChange={(e) => {
                            type1Handler(e);
                            question5 = {
                              appquestionId: item.id,
                              belongToIndex: `${expense.length}`,
                              type: item.type,
                            };
                            question5.answers = [e];
                          }}
                          value={type1}
                          placeholder={`${item.questionText}`}
                          className="mb-2 mt-2 wow"
                        >
                          {item.choices.sort()}
                          {}
                          {item.choices.map((option) => (
                            <Select.Option key={option} value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    {item.type === "number" && (
                      <input
                        onChange={(e) => {
                          amount1Handler(e);
                          question4 = {
                            appquestionId: item.id,
                            belongToIndex: `${expense.length}`,
                            type: item.type,
                          };
                          question4.answers = [e.target.value];
                        }}
                        value={amount1}
                        type="number"
                        className={`lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                        placeholder={`${item.questionText}`}
                      ></input>
                    )}
                    {/* {item.type === "text" && (
                    <input
                      value={amount1}
                      className={`lg:mb-4 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                      placeholder={`${item.questionText}`}
                    ></input>
                  )} */}
                  </div>
                );
              })}
              {msgShow === true && (
                <p className="text-red-400">Please Provide all details</p>
              )}
              <div className="flex justify-center mt-3">
                <button
                  onClick={() => show3Handler("cancel")}
                  className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
                >
                  Cancel
                </button>
                <button
                  onClick={() => show3Handler("save")}
                  type="submit"
                  className="ml-5 cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Finance;
