import React, { useState, useEffect } from "react";
import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import like from "../../../imgs/heartF.png";
import dislike from "../../../imgs/heartD.png";
import Footer from "../cart/footer";
const EventCard = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const role = useSelector((state) => state.role);
  const fav = useSelector((state) => state.fav);
  const [localCart, setCart] = useState(cart);
  const [favorite, setFavorite] = useState(fav);
  const [initial, setinitial] = useState(true);
  const [initialFav, setinitialFav] = useState(true);
  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const addToCart = (event) => {
    setinitial(false);
    const cartItem = {
      id: event.id,
      image: event.coverPhotos[0],
      name: event.name,
      quantity: event.quantity,
      price: event.price,
      amount: event.amount,
    };

    const updatedCart = [...localCart, cartItem];
    setCart(updatedCart);
  };
  const addToFav = (event) => {
    setinitialFav(false);
    const favItems = {
      id: event.id,
      coverPhotos: event.coverPhotos,
      name: event.name,
      route: event.route,
      quantity: event.quantity,
      price: event.price,
      amount: event.amount,
    };

    const updatedFav = [...favorite, favItems];
    setFavorite(updatedFav);
  };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };
  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };
  const isAddedToFav = (eventId) => {
    return favorite.some((item) => item.id === eventId);
  };
  const events = [
    {
      id: 1,
      coverPhotos: [
        "https://images.unsplash.com/photo-1588165171080-c89acfa5ee83?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyYXdiZXJyeXxlbnwwfHwwfHx8MA%3D%3D",
        "https://i.pinimg.com/originals/c3/5c/84/c35c84121aaea699769bf2623bb8bd56.png",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg/1200px-Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg",
      ],
      name: "Strawberry",
      price: 23,
      quantity: 1,
      items: 23,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 2,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdYAUXvlwNIrC2A_4oIhFUyBtKtLNHm0buQ&s",
        "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmFuYW5hc3xlbnwwfHwwfHx8MA%3D%3D",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFJ45D9B5tq0e9ijztRbX_y7YTbGZzn0T1w&s",
      ],
      name: "Banana",
      price: 22,
      items: 12,
      routeV: "/Inventory-Vendor-Product",
      quantity: 1,
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 3,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIg-NlWsGyHPdsYHRMPHoynmyVvtx9GLNRA&s",
        "https://cdn.britannica.com/24/174524-050-A851D3F2/Oranges.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYq2s3xqHMWVzlAUXGh-LnB20Z3nhC8Y46liCklv90f0ZSaxThNe1EFCAETyg5vnHlK8&usqp=CAU",
      ],
      name: "Orange",
      price: 43,
      items: 97,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 4,
      coverPhotos: [
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Mangos_-_single_and_halved.jpg/640px-Mangos_-_single_and_halved.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmgfExstNMpVm0dVH-xKurYdLlQpZpgJxHaw&s",
        "https://www.finedininglovers.com/sites/g/files/xknfdk626/files/styles/article_1200_800_fallback/public/2021-06/best-mango-varieties%C2%A9iStock.jpg?itok=KHYB1X__",
      ],
      name: "Mango",
      price: 33,
      items: 56,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },

    {
      id: 6,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtywKdongNUiZ8YexgVsgx4hOkSSONFea6eA&s",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Red_Apple.jpg/640px-Red_Apple.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjxtWEWP3_ve_0aBf9k1S8-PgZjeu1ZXmWYQ&s",
      ],
      name: "Apple",
      price: 12,
      items: 33,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 7,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE_i197uwJl2xW0MfAp6oWvcefhLKz85H_HA&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4-zuEp1m6DvI71kXWfNTbMsKJIUdWUO2m7A&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEQB1rnCE5bdACpu6QxKacY9P5DBE6sScoHg&s",
      ],
      name: "Pear",
      price: 91,
      items: 12,
      routeV:"/Inventory-Vendor-Product",
      quantity: 1,
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 8,
      coverPhotos: [
        "https://images.pexels.com/photos/2288692/pexels-photo-2288692.jpeg?cs=srgb&dl=pexels-victorino-2288692.jpg&fm=jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBhTicce5X4rOQxFOb0P7LqHD432IVLjlNdQ&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaZMefppM4pi-ygzOHTbInC84pjJF2bEJWXw&s",
      ],
      name: "Watermelon",
      price: 10,
      items: 22,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
  ];

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <LeftOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <RightOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  return (
    <div className="flex w-full pt-[3rem] pb-[8rem] flex-col justify-center">
      <div className="relative w-[40%]">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 mb-4 text-gray-500 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
          placeholder="Search Items..."
          required=""
        />
      </div>
      <div className="w-full flex flex-wrap gap-5 gap-y-8 mt-2 justify-between">
        {events.map((event) => (
          <div key={event.id} className="w-[22%]">
            <Carousel
              arrows
              prevArrow={<CustomPrevArrow />}
              nextArrow={<CustomNextArrow />}
            >
              {event.coverPhotos.map((coverPhoto, index) => (
                <div key={index}>
                  <img
                    className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-center items-center object-cover"
                    src={coverPhoto}
                    alt={event.name}
                  />
                </div>
              ))}
            </Carousel>
            <div className="px-6 py-3 bg-gray-100 rounded-br-[1.2rem] rounded-bl-[1.2rem] relative">
              <div
                onClick={() => {
                  dispatch({ type: "Product", num: event });
                  router(role === "buyer" ? event?.route : event.routeV);
                }}
                className="font-semibold w-full flex flex-col items-center justify-center text-center text-xl hover:text-[#1BA397] cursor-pointer"
              >
                {event.name}
              </div>
              <p className="text-gray-600 w-full text-center font-semibold ">
                ${event.price}/{event.amount}
              </p>
              {role === "buyer" && (
                <div className="w-full flex items-center mt-2 mb-2 justify-center">
                  {isAddedToCart(event.id) ? (
                    <button
                      className="bg-red-600 rounded-xl cursor-pointer pl-5 pr-5 text-semibold text-white"
                      onClick={() => removeFromCart(event.id)}
                    >
                      Remove from Cart
                    </button>
                  ) : (
                    <button
                      className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white"
                      onClick={() => addToCart(event)}
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
              )}
              {role === "buyer" &&
                (isAddedToFav(event.id) ? (
                  <img
                    onClick={() => removeFromFav(event.id)}
                    src={like}
                    alt=""
                    className="w-4 h-4 absolute cursor-pointer right-[5px] top-[5px]"
                  />
                ) : (
                  <img
                    onClick={() => addToFav(event)}
                    src={dislike}
                    alt=""
                    className="w-4 h-4 cursor-pointer absolute right-[5px] top-[5px]"
                  />
                ))}
              {role === "vendor" && (
                <p className="text-red-500 w-full text-center font-semibold ">
                  {event.items}{" "}
                  <span className="text-gray-600 w-full text-center font-semibold">
                    {" "}
                    Items Left
                  </span>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      {(cart.length > 0 && role!=="vendor") && <Footer />}
    </div>
  );
};

export default EventCard;
