import { useDispatch, useSelector } from "react-redux";
import across from "../../../imgs/across.png";
import { useState } from "react";
import { Select } from "antd";
import { useEffect } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import axios from "axios";
const FourthR = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [test, setTest] = useState(false);
  const [allLocations, setAllLocations] = useState("");
  const [address1, setAddress1] = useState(null);
  const [region1, setRegion1] = useState(null);
  const [regionName1, setRegionName1] = useState(null);
  const [town1, setTown1] = useState(null);
  const [ward1, setWard1] = useState(null);
  const [address, setAddress] = useState(null);
  const [regionNo, setRegionNo] = useState(null);
  const [regionName, setRegionName] = useState(null);
  // const [lot, setLot] = useState(null);
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  const location = useLocation();
  console.log(allCities, allWards);
  const popData = useSelector((state) => state.popData);
  const dash = location.pathname;
  const gro =
    dash === "/superAdminDashboard/addGroAdmin"
      ? true
      : dash === "/GroAdminDash/AddGroEmp"
      ? true
      : false;

  const [addresses, setAddresses] = useState([
    {
      lot: "",
      address: "",
      regionNo: "",
      regionName: "",
      city: "",
      ward: "",
      cityOptions: [],
      wardOptions: [],
      type: "home",
      isCurrent: true,
    },
  ]);

  console.log(addresses);

  const handleInputChange = (type, index, value) => {
    if (type === "regionNo") {
      getRegionName(value, index);
    }
    const updatedAddresses = [...addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      [type]: value,
    };
    setAddresses(updatedAddresses);
  };

  const getRegionName = (regionNum, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}`
      )
      .then((data) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          regionNo: regionNum,
          regionName: data.data.data[0],
        };
        setAddresses(updatedAddresses);
        getCities(regionNum, data.data.data[0], index);
      })
      .catch(() => {});
  };
  const getCities = (regionNum, regionName, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          regionNo: regionNum,
          regionName: regionName,
          cityOptions: wow,
        };
        setAddresses(updatedAddresses);
        // getWards(regionNum, regionName, wow[0].value, index)
        // setCityOptions(wow);
      })
      .catch(() => {});
  };

  const getWards = (regionNum, regionName, city, index) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}&city=${city}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          regionNo: regionNum,
          regionName: regionName,
          city: city,
          wardOptions: wow,
        };
        setAddresses(updatedAddresses);
        // setAllWards(wow);
      })
      .catch(() => {});
  };

  const nextHandler = (e) => {
    // dispatch({ type: "RINCREMENT" });
    // onClick={() => dispatch({ type: "IINCREMENT" })}
    e.preventDefault();
    let newData = addresses.map((item) => {
      const { cityOptions, wardOptions, ...newItem } = item;
      newItem.address = `${newItem.regionNo}, ${newItem.regionName}, ${newItem.city}, ${newItem.ward}`;
      return newItem;
    });
    let data;
    if (
      dash === "/superAdminDashboard/addGroAdmin" ||
      dash === "/GroAdminDash/AddGroEmp"
    ) {
      data = [
        {
          isCurrent: true,
          regionNo: `Region 4`,
          regionName: `Demerara-Mahaica`,
          address: `123 Robb Street, G.P.O Building`,
          type: "office",
          city: `Georgetown`,
          ward: `Lacytown`,
        },
        ...newData,
      ];
    } else {
      data = [
        {
          isCurrent: true,
          regionNo: `Region ${region1}`,
          regionName: regionName1,
          address: address1,
          type: "office",
          city: town1,
          ward: ward1,
        },
        ...newData,
      ];
    }
    let requestData;
    console.log(data);
    if (
      dash === "/superAdminDashboard/addAdmin" ||
      dash === "/superAdminDashboard/addGroAdmin" ||
      dash === "/GroAdminDash/AddGroEmp"
    ) {
      requestData = {
        addresses: data,
        consumerInfo: {
          ip: "111.222.33.4",
          location: "555.44343, 444.343424",
        },
      };
    } else {
      requestData = data;
    }
    const api =
      dash === "/superAdminDashboard/addAdmin"
        ? `v1/addresses/admin-addresses?addressUserId=${popData.id}`
        : dash === "/superAdminDashboard/addGroAdmin"
        ? `v1/addresses/gro-admin-addresses?addressUserId=${popData.id}`
        : dash === "/GroAdminDash/AddGroEmp"
        ? `v1/addresses/gro-employee-addresses?addressUserId=${popData.id}`
        : "v1/addresses";
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch({ type: "IINCREMENT" });
        dispatch({ type: "SIG" });
      })
      .catch((err) => {
        if (
          dash !== "/superAdminDashboard/addGroAdmin" &&
          dash !== "/GroAdminDash/AddGroEmp" &&
          (address1 === null ||
            region1 === null ||
            regionName1 === null ||
            town1 === null ||
            ward1 === null ||
            address1 === "" ||
            region1 === "" ||
            regionName1 === "" ||
            town1 === "" ||
            ward1 === "")
        )
          setErr("Work address is required");
        else if (
          address === null ||
          address === "" ||
          regionNo === null ||
          regionNo === "" ||
          regionName === null ||
          regionName === "" ||
          city === null ||
          city === "" ||
          ward === null ||
          ward === ""
        )
          setErr("Enter full home address.");
        else setErr(err.response.data.message);
        setShow(true);
      });
  };
  // const addAddressHandler = (check) => {
  //   if (check === "add") {
  //     arr.push(1);
  //   } else {
  //     arr.pop(1);
  //   }
  //   if (!test) {
  //     setTest(true);
  //   } else {
  //     setTest(false);
  //   }
  // };

  const addAddressHandler = (check, index) => {
    if (check === "add") {
      setAddresses((prevAddresses) => [
        ...prevAddresses,
        {
          lot: "",
          address: "",
          regionNo: "",
          regionName: "",
          city: "",
          ward: "",
          cityOptions: [],
          wardOptions: [],
          isCurrent: false,
          type: "home",
        },
      ]);
    } else {
      setAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses.splice(index, 1);
        return updatedAddresses;
      });
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/employee-locations`)
      .then((data) => {
        const wow = data.data.map((region) => {
          return {
            label: `${region.name}`,
            value: `${region.id}`,
          };
        });
        setAllLocations(wow);
      })
      .catch(() => {});
  }, []);

  const locationHandler = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/employee-location-address?empLocationId=${e}`
      )
      .then((data) => {
        setAddress1(data.data.address);
        setRegion1(data.data.regionNo);
        setRegionName1(data.data.regionName);
        setTown1(data.data.city);
        setWard1(data.data.ward);
      })
      .catch(() => {});
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          setCity(null);
          // setAllCities("");
          setAllWards("");
          setAddress("");
          setRegionNo("");
          setRegionName("");
          setWard(null);
          setRegionName(data.data.data[0]);
        })
        .catch(() => {});
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
        })
        .catch(() => {});
    }
  }, [regionName]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
        })
        .catch(() => {});
    }
  }, [city]);

  return (
    <div className="flex justify-center">
      {/* {addresses.map((address, index) => (
        <div key={index}>
          
          <input
            type="text"
            name=""
            
            value={address.lot}
            placeholder="Lot #"
            onChange={(e) => handleInputChange("lot", index, e.target.value)}
            className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <Select
            placeholder="Region No"
            optionFilterProp="children"
            className="wow"
            style={{ marginBottom: "1.2rem" }}
            onChange={(value) => handleInputChange("regionNo", index, value)}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            size="middle"
            options={allRegions}
          />
          <input
            type="text"
            name=""
            
            onChange={(e) => handleInputChange("regionName", index, e.target.value)}
            value={address.regionName}
            placeholder="Region Name"
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <Select
            placeholder="Town/City"
            optionFilterProp="children"
            className="wow"
            style={{ marginBottom: "1.2rem" }}
            // onChange={handleChange(setCity, "tt")}
            // onChange={(value) => handleInputChange("city", index, value)}
            onChange={(value) => {
              handleInputChange("city", index, value);
              getWards(address.regionNo, address.regionName, value, index);
            }}
            key={index}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            size="middle"
            options={address.cityOptions}
          />
          <Select
            placeholder="Ward/Village"
            optionFilterProp="children"
            className="wow"
            style={{ marginBottom: "1.2rem" }}
            // value={ward}
            showSearch
            key={index}
            // onChange={handleChange(setWard, "tt")}
            onChange={(value) => handleInputChange("ward", index, value)}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            size="middle"
            options={address.wardOptions}
          />
        </div>
      ))} */}

      <form
        action=""
        className="sm:w-[50%] w-[85%] mt-10"
        onSubmit={nextHandler}
      >
        {gro === false && (
          <div>
            <h2 className="mb-4 text-[1.1rem] font-bold">Work Address:</h2>
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Address
            </h2>
            <Select
              placeholder="Location"
              optionFilterProp="children"
              className="wow"
              style={{ marginBottom: "1.2rem" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              onChange={locationHandler}
              options={allLocations}
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Address
            </h2>
            <input
              type="text"
              name=""
              
              placeholder="Address"
              value={address1}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Region
            </h2>
            <input
              type="text"
              name=""
              
              placeholder="Region"
              value={region1}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Region Name
            </h2>
            <input
              type="text"
              name=""
              
              placeholder="Region Name"
              value={regionName1}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Town / City
            </h2>
            <input
              type="text"
              name=""
              
              placeholder="Town / City"
              value={town1}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Ward / Village
            </h2>
            <input
              type="text"
              name=""
              
              placeholder="Ward / Village"
              value={ward1}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
        )}
        {gro === true && (
          <div>
            <h2 className="mb-4 text-[1.1rem] font-bold">Work Address:</h2>
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2"></h2>
            <input
              placeholder="Location"
              optionFilterProp="children"
              size="middle"
              readOnly
              value={"Guyana General Registry office"}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Address
            </h2>
            <input
              type="text"
              name=""
              readOnly
              
              placeholder="Address"
              value={"123 Robb Street, G.P.O Building"}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Region
            </h2>
            <input
              type="text"
              name=""
              readOnly
              
              placeholder="Region"
              value={"Region 4"}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Region Name
            </h2>
            <input
              type="text"
              name=""
              readOnly
              
              placeholder="Region Name"
              value={"Demerara-Mahaica"}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Town / City
            </h2>
            <input
              type="text"
              name=""
              readOnly
              
              placeholder="Town / City"
              value={"Georgetown"}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Ward / Village
            </h2>
            <input
              type="text"
              name=""
              readOnly
              
              placeholder="Ward / Village"
              value={"Lacytown"}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
        )}
        {addresses.map((add, index) => {
          return (
            <div key={index}>
              <div
                className={`flex justify-end cursor-pointer mt-[3rem] ${
                  index === 0 ? "hidden" : ""
                }`}
                onClick={() => addAddressHandler("remove", index)}
              >
                <p className="text-[red] underline text-[0.94rem]">
                  Remove Address
                </p>
              </div>
              <h2 className="mb-4 text-[1.1rem] font-bold">
                Home Address {index + 1}:
              </h2>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Lot #
              </h2>
              <input
                type="text"
                name=""
                
                value={add.lot}
                placeholder="Lot #"
                onChange={(e) =>
                  handleInputChange("lot", index, e.target.value)
                }
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Address
              </h2>
              <input
                type="text"
                name=""
                
                // value={address.lot}
                // placeholder="Lot #"
                onChange={(e) =>
                  handleInputChange("address", index, e.target.value)
                }
                value={add.address}
                placeholder="Address"
                // onChange={addHadler}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Region No
              </h2>
              <Select
                placeholder="Region No"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                onChange={(value) =>
                  handleInputChange("regionNo", index, value)
                }
                // onChange={handleChange(setRegionNo, "tn")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                key={index}
                options={allRegions}
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Region Name
              </h2>
              <input
                type="text"
                name=""
                
                onChange={(e) =>
                  handleInputChange("regionName", index, e.target.value)
                }
                value={add.regionName}
                placeholder="Region Name"
                key={index}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Town/City
              </h2>
              <Select
                placeholder="Town/City"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                // onChange={handleChange(setCity, "tt")}
                onChange={(value) => {
                  handleInputChange("city", index, value);
                  getWards(add.regionNo, add.regionName, value, index);
                }}
                // value={city}
                key={index}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={add.cityOptions}
                // options={allCities}
              />
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Ward/Village
              </h2>
              <Select
                placeholder="Ward/Village"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                // value={ward}
                showSearch
                key={index}
                // onChange={handleChange(setWard, "tt")}
                onChange={(value) => handleInputChange("ward", index, value)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={add.wardOptions}
              />
            </div>
          );
        })}
        <div
          className="flex justify-center items-center mt-4"
          onClick={() => addAddressHandler("add")}
        >
          <div className="flex justify-center items-center cursor-pointer">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center mr-2" />
            </div>
            <p className="text-primary">Add Address</p>
          </div>
        </div>

        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[100%]">
          {/* <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default FourthR;
