export const mhhsMerchId = (state = null, action) => {
  switch (action.type) {
    case "MHHS_MERCH_ID":
      return action.num;
    default:
      return state;
  }
};

export const mhhsMerchEmpId = (state = null, action) => {
  switch (action.type) {
    case "MHHS_MERCH_EMP_ID":
      return action.num;
    default:
      return state;
  }
};

export const mhhsMerchUser = (state = null, action) => {
  switch (action.type) {
    case "MHHS_MERCH_USER":
      return action.num;
    default:
      return state;
  }
};

export const selectedOutstandingMerchant = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_OUTSTANDING_MERCHANT":
      return action.num;
    default:
      return state;
  }
};

export const selectedOutstandingStatus = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_OUTSTANDING_STATUS":
      return action.num;
    default:
      return state;
  }
};

export const selectedTransactions = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_TRANSACTIONS":
      return action.num;
    default:
      return state;
  }
};

export const selectedTransactionsVouchers = (state = [], action) => {
  switch (action.type) {
    case "SELECTED_TRANSACTIONS_VOUCHERS":
      return action.num;
    default:
      return state;
  }
};

export const selectedPaymentMethod = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_PAYMENT_METHOD":
      return action.num;
    default:
      return state;
  }
};

export const afterPaymentData = (state = null, action) => {
  switch (action.type) {
    case "AFTER_PAYMENT_DATA":
      return action.num;
    default:
      return state;
  }
};

export const selectedPaymentDetail = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_PAYMENT_DETAIL":
      return action.num;
    default:
      return state;
  }
};

//Selected goverment agency from the goverment agencies list
export const selectedGovAgency = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_GOV_AGENCY":
      return action.num;
    default:
      return state;
  }
};

//Selected goverment agency request amount
export const requestedAmount = (state = null, action) => {
  switch (action.type) {
    case "REQ_AMOUNT":
      return action.num;
    default:
      return state;
  }
};

// Store depoit transaction data for both mhsss side and gov agnecy side
export const depositMoneyData = (state = null, action) => {
  switch (action.type) {
    case "DEPOSIT_MONEY_DATA":
      return action.num;
    default:
      return state;
  }
};
