import React from "react";
const Contact = ({ headquarter, type }) => {
  
  return (
    <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
      <div className="sm:px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-600">
          {type} Email Addresses
        </h3>
      </div>
      {(type === "Store" || type === "Head Quarter") && (
        <div className="flex flex-wrap mt-6 border-t border-gray-300 gap-x-9 gap-y-1 sm:px-4 py-2 pt-4 sm:px-0">
          <dt className="text-sm font-semibold whitespace-nowrap leading-6 text-gray-900">
            Primary Email
          </dt>
          <dd className=" text-sm leading-6 text-gray-500 sm:col-span-2">
            {headquarter.primaryEmail}
          </dd>
        </div>
      )}
      <div className=" mt-2 border-t border-gray-300">
        <dl className="divide-y divide-gray-300">
          {headquarter?.emails?.map((email, index) => (
            <div
              key={index}
              className="flex flex-wrap gap-x-6 gap-y-1 sm:px-4 py-4 sm:px-0"
            >
              <dt className="text-sm font-semibold whitespace-nowrap leading-6 text-gray-900">
                Email Address {index + 1}
              </dt>
              <dd className=" text-sm leading-6 text-gray-500 sm:col-span-2">
                {email}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Contact;
