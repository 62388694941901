import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import back5 from "../../../imgs/back5.png";

const Card = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const merchantStores = useSelector((state) => state.merchantStores);
  const dataUser = useSelector((state) => state.dataUser);

  return (
    <>
      <div
        className="sm:p-8 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                router(-1);
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Stores Selection
            </h2>
          </div>
        </div>
      </div>

      <div
        className={`grid   gap-8 p-6 cursor-pointer px-8 ${
          user === "merchant-employee"
            ? "sm:grid-cols-3 grid-cols-1"
            : "sm:grid-cols-3 grid-cols-1"
        }`}
      >
        <div
          className={`border-4 border-green-100 p-10 bg-gray-100 rounded-lg shadow-lg ${
            user === "merchant-employee" &&
            dataUser?.data?.merchuser?.employeeType === "store"
              ? "hidden"
              : ""
          }`}
          onClick={() => {
            dispatch({ type: "MERCHANT_SAP_TYPE", num: "HQ" });
            dispatch({ type: "MERCHANT_SAP_TYPE_DATA", num: dataUser?.data });
            dispatch({ type: "SAP_MONEY_ENABLE", num: true });
            router("/SapMoney");
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="w-[7rem] h-[7rem] mb-4 overflow-hidden rounded-full">
              <img
                src={dataUser?.data?.merchuser?.merchCompany?.logo}
                alt={dataUser?.data?.merchuser?.merchCompany?.logo}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-primary font-bold text-xl mb-2 text-center">
              {dataUser?.data?.merchuser?.merchCompany?.name}
            </div>
            <p className="text-gray-700 text-center font-semibold">
              Headquarter
            </p>
          </div>
          {/* {item.text && (
          <p className="text-center font-semibold mt-8 text-primary italic">
            {item.text}
          </p>
        )} */}
        </div>
        {merchantStores
          ?.filter(
            (item) => !(item.key === "2" && user === "merchant-employee")
          )
          ?.map((item, index) => (
            <div
              key={index}
              className="border-4 border-green-100 p-10 bg-gray-100 rounded-lg shadow-lg"
              onClick={() => {
                dispatch({ type: "MERCHANT_SAP_TYPE", num: "store" });
                dispatch({ type: "MERCHANT_SAP_TYPE_DATA", num: item });
                dispatch({ type: "SAP_MONEY_ENABLE", num: true });
                router("/SapMoney");
              }}
            >
              <div className="flex flex-col justify-center items-center">
                <div className="w-[7rem] h-[7rem] mb-4 overflow-hidden rounded-full">
                  <img
                    src={item?.image}
                    alt={item?.customStoreName}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="text-primary font-bold text-xl mb-2 text-center">
                  {item?.customStoreName}
                </div>
                <p className="text-gray-700 text-center font-semibold">
                  {item?.region}
                  {","} {item?.city}
                </p>
              </div>
              {item.text && (
                <p className="text-center font-semibold mt-8 text-primary italic">
                  {item.text}
                </p>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default Card;
