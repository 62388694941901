import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
let model;
export const loadModel = async () => {
  if (!model) {
    console.log('Loading model...');
    await tf.ready();
    model = await mobilenet.load();
  }
  return model;
};
export const compareImages = async (imageURL1, imageURL2) => {
  console.log(imageURL1);
  const imageElement1 = document.createElement('img');
  const imageElement2 = document.createElement('img');
  return new Promise(async (resolve) => {
    const loadedModel = await loadModel();
    imageElement1.src = imageURL1;
    imageElement1.onload = async () => {
      const embeddings1 = tf.tidy(() => loadedModel.infer(imageElement1, 'conv_preds').toFloat());
      // Check if imageURL2 is a valid URL
      if (typeof imageURL2 === 'string' && imageURL2.trim() !== '') {
        imageElement2.src = imageURL2;
        imageElement2.onload = async () => {
          const embeddings2 = tf.tidy(() => loadedModel.infer(imageElement2, 'conv_preds').toFloat());
          const diff = tf.sub(embeddings1, embeddings2).square().sum();
          const similarity = diff.sqrt().dataSync()[0];
          console.log('Similarity Score:', similarity.toFixed(2));
          resolve(similarity);
          embeddings1.dispose();
          embeddings2.dispose();
        };
      } else {
        console.error('Invalid or empty URL provided for imageURL2.');
      }
    };
  });
};
