import Page from "./BeneficaryTable";
import e1 from "../../../imgs/e1.png";
import e2 from "../../../imgs/e2.png";
import e3 from "../../../imgs/e3.png";
import across from "../../../imgs/across.png";
import { Checkbox } from "antd";
import back5 from "../../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
const Listing = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const pensionerId = useSelector((state) => state.pensionerId);
  const allBenId = useSelector((state) => state.allBenId);
  const reset = useSelector((state) => state.reset);
  const penId = useSelector((state) => state.penId);
  const [test, setTest] = useState(false);
  const [selfBen, setSelfBen] = useState(false);
  const [selfDef, setSelfDef] = useState(false);
  const user=useSelector((state)=>state.user)
  console.log("user",user)
  const privilege = useSelector((state) => state.myPrivileges);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [selfBen]);

  const ownHandler = (e) => {
    let url;
    let body;
    if (dash === "/Dashboard/listing") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/toggle-self`;
      body = {
        programId: penId,
        isActive: e.target.checked,
      };
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/toggle-self/by-staff?pensionerUserId=${pensionerId}`;
      body = {
        statusInfo: {
          programId: penId,
          isActive: e.target.checked,
        },
      };
    }

    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        dispatch({ type: "RESET" });
      })
      .catch((err) => {
        if (
          err.response.data.message === "Default beneficiary cannot be disabled"
        )
          setErr(
            "There must be a default beneficiary, you can't disable only beneficiary"
          );
        else setErr(err.response.data.message);
        setShow(true);
      });
  };

  const ownDefault = (e) => {
    let url;
    let body;
    if (dash === "/Dashboard/listing") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle-self`;
      body = {
        programId: penId,
        isDefault: e.target.checked,
      };
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle-self/by-staff`;
      body = {
        defaultInfo: {
          programId: penId,
          isDefault: e.target.checked,
        },
      };
    }

    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        dispatch({ type: "RESET" });
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  useEffect(() => {
    
    if (
      dash === "/EDashboard/listing" ||
      dash === "/AdminDashboard/listing" ||
      dash === "/superAdminDashboard/listing"
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-staff?pensionerUserId=${allBenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          console.log("data", data);
          let wow;
          wow = data.data.filter((item) => {
            return item.relationship === "Me";
          });
          if (wow.length !== 0) {
            if (wow[0].isActive) {
              setSelfBen(true);
            } else {
              setSelfBen(false);
            }
            if (wow[0].isDefault) {
              setSelfDef(true);
            } else {
              setSelfDef(false);
            }
          }
        })
        .catch(() => {});
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-pensioner`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          let wow;
          wow = data.data.filter((item) => {
            return item.relationship === "Me";
          });
          if (wow.length !== 0) {
            if (wow[0].isActive) {
              setSelfBen(true);
            } else {
              setSelfBen(false);
            }
            if (wow[0].isDefault) {
              setSelfDef(true);
            } else {
              setSelfDef(false);
            }
          }
        })
        .catch(() => {});
    }
  }, [reset]);
  return (
    <>
      <div
        className="sm:p-10 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={`flex justify-between items-center mt-4`}>
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() => {
                  user === "employee"
                    ? router("/EDashboard/profPage")
                    : user === "admin"
                    ? router("/AdminDashboard/profPage")
                    : user === "superadmin"
                    ? router("/superAdminDashboard/profPage")
                    : router("/Dashboard");
                }}
                className="w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Beneficiaries
              </h2>
            </div>

            {user === "employee" &&
            !privilege.includes("Create beneficiary") ? null : (
              // || dash === "/AdminDashboard/listing" || dash === "/superAdminDashboard/listing"
              <button
                onClick={() => {
                  dispatch({ type: "SET", num: 0 });
                  dispatch({ type: "BRSET", num: 0 });
                  dispatch({ type: "BISET", num: 0 });
                  dispatch({ type: "benCreating" });
                  if (dash === "/EDashboard/listing") {
                    router("/EDashboard/BeneficiaryQuestions", {
                      state: { fromEmployeeDashboard: true },
                    });
                  } else if (dash === "/AdminDashboard/listing") {
                    router("/AdminDashboard/BeneficiaryQuestions", {
                      state: { fromEmployeeDashboard: true },
                    });
                  } else if (dash === "/superAdminDashboard/listing") {
                    router("/superAdminDashboard/BeneficiaryQuestions", {
                      state: { fromEmployeeDashboard: true },
                    });
                  } else {
                    router("/Dashboard/BeneficiaryQuestions", {
                      state: { fromDashboard: true },
                    });
                  }
                }}
                className={` ${
                  dataUser?.data?.user?.status === "pending" ||
                  dataUser?.data?.user?.status === "deferred" ||
                  dataUser?.data?.user?.status === "re-submitted" ||
                  dataUser?.data?.user?.status === "in-review" ||
                  dataUser?.data?.user?.status === "verified"
                    ? "pointer-events-none"
                    : ""
                } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
              >
                Create Beneficiary
              </button>
            )}
          </div>

          <div className="flex flex-wrap justify-center sm:ml-0 place-items-center gap-4 mt-[3rem] mb-8">
            <div className="w-[13rem] h-[5.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Total Beneficiaries
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">6</p>
              </div>
              <div>
                <img className="w-[2.1rem] mt-4" src={e2} alt="" />
              </div>
            </div>
            <div className="w-[13rem] h-[5.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Active Beneficiaries
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">3</p>
              </div>
              <div>
                <img className="w-[2.2rem] mt-4" src={e3} alt="" />
              </div>
            </div>
            <div className="w-[13rem] h-[5.5rem] bg-[#E8E8E8] rounded-md p-3 flex  justify-between">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Inactive Beneficiaries
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">2</p>
              </div>
              <div>
                <img className="w-[2rem] mt-5" src={e1} alt="" />
              </div>
            </div>
            <div className="sm:w-[22rem] w-[13rem] sm:h-[5.5rem] h-[8.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-center sm:flex-row flex-col">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Own Beneficiary
                </h2>
                <div className="mt-1">
                  <Checkbox
                    checked={selfBen}
                    onChange={ownHandler}
                    className={"checkk3"}
                  >
                    I am my own Beneficiary
                  </Checkbox>
                </div>
              </div>
              <div>
                <div className="sm:mt-6 mt-1">
                  <Checkbox
                    checked={selfDef}
                    onChange={ownDefault}
                    className={"checkk3"}
                  >
                    Default
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        {}
        {/* <div
        className={`relative  ${dataUser?.data?.user?.status === "pending" 
            ? "pointer-events-none"
            : ""
          }`}
      >
        <Tabs defaultActiveKey="1" items={items} />
        
      </div> */}
      </div>
      <Page />
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Listing;
