import React from 'react'
import Nav from "../components/Nav/nav";
import Whatsapp from '../Whatsapp/whatsapp';
import HistoryStatus from "../components/History/statusHistory"

const historyStatus = () => {
  return (
    <div>
      <Nav />
     <HistoryStatus/>
     <Whatsapp/>
    </div>
  )
}

export default historyStatus