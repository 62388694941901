import { Avatar, Dropdown, Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import axios from "axios";
import profile from "../../../imgs/profile.png";
import def2 from "../../../imgs/default2.png";
import bs1 from "../../../imgs/bs1.png";
import bs2 from "../../../imgs/bs2.png";
import bs3 from "../../../imgs/bs3.png";
import bs4 from "../../../imgs/change.png";
import across from "../../../imgs/across.png";

const BeneficaryTable = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const universalPenId = useSelector((state) => state.universalPenId);
  const pensionerName = useSelector((state) => state.pensionerName);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const [allB, setAllb] = useState();
  const [buffer, setBuffer] = useState("");
  const [buffer1, setBuffer1] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showx, setShowx] = useState(false);
  const [err, setErr] = useState("");
  const [able, setAble] = useState(false);
  const [dis, setDis] = useState();
  const [test, setTest] = useState(false);
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const [benId, setBenId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [signupStepsCompleted, setSignupStepsCompleted] = useState();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const totalPages = 1;
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }

  useEffect(() => {
    if (user === "employee") {
      const hasViewPrivilege = privilege.includes("View beneficiary");
      const hasEditPrivilege = privilege.includes("Edit beneficiary");
      const hasDeletePrivilege = privilege.includes("Delete beneficiary");
      const updatedItems = [];

      if (hasViewPrivilege) {
        updatedItems.push({
          label: "View ",
          key: "1",
          icon: <img style={{ width: "1.5rem" }} src={bs1} />,
        });
      }

      if (hasViewPrivilege && hasEditPrivilege) {
        updatedItems.push({
          label: "Edit ",
          key: "3",
          icon: (
            <img style={{ width: "1.1rem", marginLeft: "0.3rem" }} src={bs2} />
          ),
        });
        updatedItems.push({
          label: "Change Status",
          key: "2",
          icon: (
            <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={bs4} />
          ),
        });

        updatedItems.push({
          label: "Change Default",
          key: "5",
          icon: (
            <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={def2} />
          ),
        });
      }

      if (hasDeletePrivilege) {
        updatedItems.push({
          label: "Delete ",
          key: "4",
          icon: (
            <img style={{ width: "1.1rem", marginLeft: "0.28rem" }} src={bs3} />
          ),
        });
      }
      setItems(updatedItems);
    } else {
      console.log("Steps", signupStepsCompleted);
      if (signupStepsCompleted === true) {
        setItems([
          {
            label: "View ",
            key: "1",
            icon: <img style={{ width: "1.5rem" }} src={bs1} />,
          },
          {
            label: "Change Status",
            key: "2",
            icon: (
              <img
                style={{ width: "1.2rem", marginLeft: "0.1rem" }}
                src={bs4}
              />
            ),
          },
          {
            label: "Change Default",
            key: "5",
            icon: (
              <img
                style={{ width: "1.2rem", marginLeft: "0.1rem" }}
                src={def2}
              />
            ),
          },
          {
            label: "Edit ",
            key: "3",
            icon: (
              <img
                style={{ width: "1.1rem", marginLeft: "0.3rem" }}
                src={bs2}
              />
            ),
          },
        ]);
      } else {
        setItems([
          {
            label: "View ",
            key: "1",
            icon: <img style={{ width: "1.5rem" }} src={bs1} />,
          },
          // {
          //   label: "Change Status",
          //   key: "2",
          //   icon: <img style={{ width: "1.2rem", marginLeft: "0.1rem" }} src={bs4} />,
          // },
          {
            label: "Change Default",
            key: "5",
            icon: (
              <img
                style={{ width: "1.2rem", marginLeft: "0.1rem" }}
                src={def2}
              />
            ),
          },
          {
            label: "Edit ",
            key: "3",
            icon: (
              <img
                style={{ width: "1.1rem", marginLeft: "0.3rem" }}
                src={bs2}
              />
            ),
          },
          {
            label: "Delete ",
            key: "4",
            icon: (
              <img
                style={{ width: "1.1rem", marginLeft: "0.28rem" }}
                src={bs3}
              />
            ),
          },
        ]);
      }
    }
  }, [privilege, signupStepsCompleted]);

  const onClick = (key, item) => {
    console.log("key", key, "item", item);
    if (key.key === "3") {
      dispatch({ type: "benDone", num: "Done" });
      dispatch({ type: "penId", num: buffer1 });
      dispatch({ type: "universalBenSet", num: buffer });
      dispatch({ type: "whichViewSet", num: "beneficiary" });
      if (location.pathname === "/EDashboard/listing") {
        router("/EDashboard/BeneficiaryAccount");
      } else if (location.pathname === "/Dashboard/listing") {
        router("/Dashboard/BeneficiaryAccount");
      } else if (dash === "/superAdminDashboard/listing") {
        router("/superAdminDashboard/BeneficiaryAccount");
      } else if (dash === "/AdminDashboard/listing") {
        router("/AdminDashboard/BeneficiaryAccount");
      }
    } else if (key.key === "1") {
      dispatch({ type: "benDone", num: "Done" });
      dispatch({ type: "penId", num: buffer1 });
      dispatch({ type: "universalBenSet", num: buffer });
      dispatch({ type: "whichViewSet", num: "beneficiary" });
      if (location.pathname === "/EDashboard/PensionL") {
        router("/EDashboard/cardView");
      } else if (location.pathname === "/Dashboard/listing") {
        router("/Dashboard/cardView");
      } else if (dash === "/EDashboard/listing") {
        router("/EDashboard/cardView");
      } else if (dash === "/superAdminDashboard/listing") {
        router("/superAdminDashboard/cardView");
      } else if (dash === "/AdminDashboard/listing") {
        router("/AdminDashboard/cardView");
      }
    } else if (key.key === "2") {
      setShow(true);
    } else if (key.key === "5") {
      setShow1(true);
    } else if (key.key === "4") {
      setBenId(item?.beneficiaryuserId);
      setDeleteModal(true);
    }
  };

  const onClick2 = (e, pen, aa, tt, signupSteps) => {
    console.log(e, "eyr", pen, aa, tt, signupSteps);
    setBuffer(e);
    setBuffer1(pen);
    setAble(aa);
    setDis(tt);
    setSignupStepsCompleted(signupSteps);
  };

  useEffect(() => {
    fetchBeneficaries();
  }, [reset, refetch, page]);

  const fetchBeneficaries = (e) => {
    if (e === "clear") {
      alert("hello");
      fetchBeneficaries();
    } else {
      if (
        dash === "/EDashboard/listing" ||
        dash === "/AdminDashboard/listing" ||
        dash === "/superAdminDashboard/listing"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-staff?pensionerUserId=${universalPenId}`,
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            const filteredData = data.data.filter(
              (item) => item.relationship !== "Me"
            );
            setAllb(filteredData);
          })
          .catch(() => {});
      } else {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/list-by-pensioner`,
            {
              headers: {
                Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
              },
            }
          )
          .then((data) => {
            const filteredData = data.data.filter(
              (item) => item.relationship !== "Me"
            );
            setAllb(filteredData);
          })
          .catch(() => {});
      }
    }
  };

  const ableHandler = (e) => {
    let wow;
    let body;
    if (dash === "/Dashboard/listing") {
      body = {
        programId: penId,
        isActive: e,
      };
      wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/change-status?beneficiaryUserId=${buffer}`;
    } else {
      body = {
        statusInfo: {
          programId: penId,
          isActive: e,
        },
      };
      wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/change-status/by-staff?beneficiaryUserId=${buffer}`;
    }
    axios
      .patch(wow, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        setAble(e);
        dispatch({ type: "RESET" });
        setShow(false);
      })
      .catch((err) => {
        setShowx(true);
        setErr(err.response.data.message);
      });
  };

  useEffect(() => {
    if (test) {
      setTest(false);
    } else {
      setTest(true);
    }
  }, [able, dis]);

  const columnHeaders = [
    "Sr No",
    "Name",
    "Benficiary ID",
    "GID",
    // "DOB",
    // "Gender",
    // "Phone",
    "RelationShip",
    "Pensioner",
    "Monthly Transfer",
    "Status",
    "Profile Status",
    "Created Date/Time",
    "Action",
  ];

  const pageHandler = (e) => {
    setPage(e);
  };
  return (
    <section className="p-2 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50 ">
          {/* table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs  text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader, index) => (
                    <th
                      scope="col"
                      className="px-6 py-3 font-semibold tracking-wider"
                      key={index}
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>

              {allB?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      className="text-center text-[1.5rem] p-8"
                      colSpan={columnHeaders.length}
                    >
                      No Data Found
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {allB?.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-3 py-5 font-medium text-gray-900  whitespace-nowrap "
                      >
                        <div className="flex  items-center gap-2">
                          <p>{index + 1 + (page - 1) * 6}.</p>
                          <p
                            className={`${
                              item.isDefault ? "" : "hidden"
                            } cursor-pointer`}
                          >
                            <Popover
                              content="Default Beneficiary"
                              placement="bottomLeft"
                            >
                              <img
                                src={def2}
                                className="w-[1.7rem] h-[1.7rem]"
                                alt=""
                              />
                            </Popover>
                          </p>
                        </div>
                      </th>

                      <td className="px-2 py-5">
                        <div className="flex items-center">
                          {/* <img
                            className="w-20 ml-0  h-20 rounded-full mr-2 border-2 border-primary "
                            src={
                              item?.beneficiaryuser?.beneficiaryperson !== null
                                ? item?.beneficiaryuser?.beneficiaryperson
                                    ?.image
                                : profile
                            }
                            alt={`pro`}
                          /> */}
                          <Avatar
                            src={
                              item?.beneficiaryuser?.beneficiaryperson !== null
                                ? item?.beneficiaryuser?.beneficiaryperson
                                    ?.image
                                : profile
                            }
                            className="border-2  border-primary mr-2 w-10 h-10"
                          />
                          <p className="mr-6" style={{ whiteSpace: "nowrap" }}>
                            {item?.beneficiaryuser?.beneficiaryperson !== null
                              ? `${item?.beneficiaryuser?.beneficiaryperson?.firstName} ${item?.beneficiaryuser?.beneficiaryperson?.lastName}`
                              : "---"}
                          </p>
                        </div>
                      </td>

                      <td className="px-6  py-5">
                        <Popover content={item?.id}>
                          <div className="inline-block  flex items-center justify-center max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {item?.id?.substring(0, 5)}..
                          </div>
                        </Popover>
                      </td>
                      <td className="px-6  py-5">
                        {item?.beneficiaryuser?.gid}
                      </td>

                      <td className="px-6  py-5">
                        <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 text-center rounded-md  focus:border-none cursor-pointer">
                          {item.relationship}
                        </div>
                      </td>
                      <td className="px-6 py-5  capitalize">
                        {dash === "/EDashboard/listing" ||
                        dash === "/AdminDashboard/listing" ||
                        dash === "/superAdminDashboard/listing"
                          ? `${pensionerName}`
                          : `${dataUser.data.user.person.firstName} ${dataUser.data.user.person.lastName}`}
                      </td>
                      <td className="px-6  py-5">
                        {item.percentage === null
                          ? item.amount === null
                            ? "---"
                            : `$${item.amount}`
                          : `${item.percentage}%`}
                      </td>
                      <td className="px-6  py-5">
                        <Popover
                          content={`${index === 3 ? "Deceased" : ""}`}
                          placement="bottomLeft"
                        >
                          {item.beneficiaryuser && (
                            <div>
                              <div
                                className={`border-none flex items-center justify-center ${
                                  item.beneficiaryuser.status === "pending" &&
                                  item.isActive
                                    ? "bg-[#2F84F1]"
                                    : !item.isActive
                                    ? "bg-[#D64242]"
                                    : " bg-primary"
                                }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer`}
                              >
                                {!item.isActive ? "Disabled" : "Active"}
                              </div>
                            </div>
                          )}
                        </Popover>
                      </td>
                      <td className="px-6  py-5">
                        {" "}
                        {item.signupStepsCompleted ? "Completed" : "Incomplete"}
                      </td>
                      <td
                        className="px-6  py-5"
                      >
                        <p>{moment(item?.createdAt).format("MM/DD/YYYY")}</p>
                        <p> {moment(item?.createdAt).format("hh:mm A")}</p>
                      </td>
                      <td className="px-6  py-5">
                        <Dropdown
                          menu={{
                            items,
                            onClick: (key) => onClick(key, item),
                          }}
                          trigger={["click"]}
                          onClick={() => {
                            onClick2(
                              item.beneficiaryuserId,
                              item.pensionprogram.id,
                              item.isActive,
                              item.isDefault,
                              item.signupStepsCompleted
                            );
                          }}
                        >
                          <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          </div>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </div>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[1rem] mb-5">
            {dis
              ? "Are you sure do you want to unmark this beneficiary as Default?"
              : "Are you sure do you want to mark this beneficiary as Default?"}
          </p>
          <div className="flex">
            <button
              onClick={() => setShow1(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                const wow = !dis;
                let body;
                if (
                  dash === "/EDashboard/listing" ||
                  dash === "/AdminDashboard/listing" ||
                  dash === "/superAdminDashboard/listing"
                ) {
                  body = {
                    defaultInfo: {
                      programId: buffer1,
                      isDefault: wow,
                    },
                  };
                } else {
                  body = {
                    programId: buffer1,
                    isDefault: wow,
                  };
                }
                let Url;
                if (
                  dash === "/EDashboard/listing" ||
                  dash === "/AdminDashboard/listing" ||
                  dash === "/superAdminDashboard/listing"
                ) {
                  Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle/by-staff?beneficiaryUserId=${buffer}`;
                } else {
                  Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle?beneficiaryUserId=${buffer}`;
                }

                axios
                  .post(Url, body, {
                    headers: {
                      Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                    },
                  })
                  .then((data) => {
                    dispatch({ type: "RESET" });
                    setShow1(false);
                  })
                  .catch((err) => {
                    setShowx(true);
                    setErr(err.response.data.message);
                  });
              }}
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[1rem] mb-5">
            Are you sure do you want to {able ? "Disable" : "Enable"} this
            beneficiary?
          </p>
          <div className="flex gap-2">
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                ableHandler(!able);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#57d38c] w-[60%]"
            >
              {able ? "Disable" : "Enable"}
            </button>
          </div>
          {/* <div className="mb-4 mt-2">
            <Switch
              size="large"
              style={{ backgroundColor: able ? "#57d38c" : "#c3c6c9" }}
              checked={able}
              onChange={ableHandler}
             
            />
          </div> */}
          {/* <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Cancel
          </button> */}
        </div>
      </div>
      <div
        className={`${
          showx ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[200]`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowx(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowx(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          deleteModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[200]`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setDeleteModal(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            Are you sure you want to delete this Beneficiary?
          </p>
          <div className="flex">
            <button
              onClick={() => setDeleteModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10  bg-[#F2F4F8] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                let Url;
                // if (dash === "/EDashboard/listing") {
                //   Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle/by-staff?beneficiaryUserId=${buffer}`;
                // } else {
                //   Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/default/toggle?beneficiaryUserId=${buffer}`;
                // }
                Url = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/${benId}/delete-records`;

                axios
                  .delete(Url, {
                    headers: {
                      Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
                    },
                  })
                  .then((data) => {
                    setDeleteModal(false);
                    setRefetch(!refetch);
                  })
                  .catch((err) => {
                    setDeleteModal(false);
                    setTimeout(() => {
                      setShowx(true);
                      setErr(err.response.data.message);
                    }, 1000);
                  });
              }}
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeneficaryTable;
