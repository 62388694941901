import React, { useEffect, useState } from "react";
import Payment from "./TabsComponent/Payment";
import Voucher from "./TabsComponent/Voucher";
import VerifyHistory from "./TabsComponent/VerifyHistory";
import { useSelector } from "react-redux";
import axios from "axios";

const SingleReport = () => {
  const selectedReportPensioner = useSelector(
    (state) => state.selectedReportPensioner
  );
  const [active, setActive] = useState("1");
  const date = new Date();
  const currentYear = date.getFullYear();
  const [paymentYear, setPaymentYear] = useState(currentYear);
  const [programYear, setProgramYear] = useState(currentYear);
  const [verifyYear, setVerifyYear] = useState(currentYear);
  const token = useSelector((state) => state.token);
  const [paymentData, setPaymentData] = useState([]);
  const [porgramData, setProgramData] = useState([]);
  const [verifiedData, setVerifiedData] = useState([]);
  const [verTotalPages, setVerTotalPages] = useState(1);
  const [page, setPage] = useState(0);
  const tabs = [
    {
      key: "1",
      label: "Payment",
      component: (
        <Payment
          year={paymentYear}
          setYear={setPaymentYear}
          data={paymentData}
        />
      ),
    },
    {
      key: "2",
      label: "Verify History",
      component: <VerifyHistory  
      year={verifyYear}
      setYear={setVerifyYear}
      data={verifiedData}
      page={page}
      setPage={setPage}
      totalPages={verTotalPages}
      />,
    },
    {
      key: "3",
      label: "Programs",
      component: (
        <Voucher
          year={programYear}
          setYear={setProgramYear}
          data={porgramData}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Complaints",
    //   component: <Complaints />,
    // },
  ];

  useEffect(() => {
    const params = {
      limit: 6,
      skip: 0,
      page: 1,
      sortBy: "createdAt:desc",
      pensionerUserId: selectedReportPensioner?.id,
      ...(paymentYear && { year: paymentYear }),
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/reports/payments`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPaymentData(response?.data?.rows);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [paymentYear]);


  useEffect(() => {
    const params = {
      limit: 4,
      skip: 0,
      page: page,
      sortBy: "createdAt:desc",
      pensionerUserId: selectedReportPensioner?.id,
      ...(verifyYear && { year: verifyYear }),
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-verify-pensioner-history`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVerifiedData(response?.data?.rows);
        setVerTotalPages(response?.data?.totalPages);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [verifyYear, page]);

  useEffect(() => {
    const params = {
      ...(programYear && { year: programYear }),
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/program-payments/for-merchant`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setProgramData(response?.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [programYear]);
  const handleTabClick = (key) => {
    setActive(key);
  };
  return (
    <div className="sm:mt-[-4rem]">
      <div className="sm:mx-8 mb-12">
        <div className="p-8 bg-white  mt-8 rounded-lg">
          <div className="w-full bg-gray-100 p-8 shadow-lg rounded-lg mb-10 border-2 border-green-100 relative">
            <div
              className={`${
                selectedReportPensioner?.status === "rejected"
                  ? "bg-[#C53F3F]"
                  : selectedReportPensioner?.status === "in-review"
                  ? "bg-blue-600"
                  : selectedReportPensioner?.status === "pending"
                  ? "bg-yellow-500"
                  : "bg-primary"
              }  absolute top-4 right-5 sm:top-8 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
            >
              {selectedReportPensioner?.status}
            </div>
            <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
              <div className="flex items-center sm:flex-row flex-col">
                <div className="sm:w-36 sm:h-36 w-20 h-20 bg-indigo-100  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                  <img
                    src={selectedReportPensioner?.person?.image}
                    alt="profile"
                    className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                  />
                </div>
                <div className="ml-4">
                  <h1 className="text-2xl sm:text-3xl font-semibold text-gray-800">
                    {selectedReportPensioner?.person?.firstName}{" "}
                    {selectedReportPensioner?.person?.middleName &&
                      selectedReportPensioner?.person?.middleName + " "}
                    {selectedReportPensioner?.person?.lastName}{" "}
                    {/* <span className="font-light text-gray-600">27</span> */}
                  </h1>
                  <div className="mt-4 space-y-2">
                    <p className="text-md font-medium text-gray-700">
                      GID:{" "}
                      <span className="text-md text-gray-500">
                        {selectedReportPensioner?.gid}
                      </span>
                    </p>

                    <p className="text-md font-medium text-gray-700">
                      Gender:{" "}
                      <span className="text-gray-500 text-md">
                        {selectedReportPensioner?.person?.gender}
                      </span>
                    </p>
                    <p className="text-md font-medium text-gray-700">
                      DOB:{" "}
                      <span className="text-gray-500 text-md">
                        {selectedReportPensioner?.person?.dateOfBirth}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div>
            <ul className="grid grid-flow-col text-center border-b border-gray-200 text-gray-500 mt-4">
              {tabs.map((tab) => (
                <li
                  key={tab.key}
                  onClick={() => handleTabClick(tab.key)}
                  className={`flex justify-center border-b-2 text-md gap-2 ${
                    active === tab.key
                      ? "border-primary text-primary font-semibold text-[1rem]"
                      : "border-transparent"
                  } hover:text-primary hover:border-primary py-2`}
                >
                  {tab.label}
                </li>
              ))}
            </ul>

            {/* Show the selected component */}
            <div className="sm:p-10 py-2">
              {tabs.find((tab) => tab.key === active)?.component}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleReport;
