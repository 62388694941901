import React, { useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import AppointConfirm from "../modal/appointmentConfirmed";

const SetSlot = () => {
  const [appointmentBooked, setAppointmentBooked] = useState(false);
  const [active, setActive] = useState("1");
  // const [selectedSlot, setSelectedSlot] = useState(null);
  const selectedDay = "1";
  const tabs = [
    {
      key: "1",
      title: "Morning",
    },
    {
      key: "2",
      title: "Afternoon",
    },
    {
      key: "3",
      title: "Evening",
    },
  ];
  const days = [
    {
      key: "1",
      day: "Thurs",
      date: "22 Aug",
    },
    {
      day: "Fri",
      date: "23 Aug",
    },
    {
      day: "Sat",
      date: "24 Aug",
    },
    {
      day: "Mon",
      date: "26 Aug",
    },
    {
      day: "Tues",
      date: "27 Aug",
    },
    {
      day: "Wed",
      date: "28 Aug",
    },
  ];

  const appointments = [
    {
      booked: false,
      time: "8:00 AM - 10:00 AM",
    },
    {
      booked: true,
      time: "10:00 AM - 12:00 PM",
    },
    {
      booked: false,
      time: "12:00 PM - 2:00 PM",
    },
    {
      booked: false,
      time: "2:00 PM - 4:00 PM",
    },
    {
      booked: true,
      time: "4:00 PM - 6:00 PM",
    },
    {
      booked: false,
      time: "6:00 PM - 8:00 PM",
    },
    {
      booked: true,
      time: "8:00 PM - 10:00 PM",
    },
  ];
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader title={"Book Slot"} route={-1} header={true} />
      <div className="flex justify-center">
        <div
          className={`  flex-col bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[90%] w-full`}
        >
          {/* Choose Slot */}
          <h1 className="text-[1rem] mb-3 text-semibold text-gray-700">
            Choose a Slot
          </h1>
          <div className="bg-[#F6F4F4] w-full rounded-md">
            <div className="grid grid-cols-12 gap-4 p-4">
              {days?.map((day, index) => (
                <div
                  className={`bg-white  p-3 flex items-center justify-center flex-col rounded-md py-4 shadow-md ${
                    selectedDay === day.key ? "bg-primary text-white" : ""
                  }`}
                >
                  <h1 className="font-semibold">{day?.date}</h1>
                  <p>{day?.day}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Tabs */}

          <div>
            <ul className="flex text-center  border-gray-200 text-gray-700 mt-4">
              {tabs.map((tab) => (
                <li
                  key={tab.key}
                  onClick={() => setActive(tab.key)}
                  className={`flex justify-center border-b-[2.5px] text-md gap-2 px-4 mr-4 ${
                    active === tab.key
                      ? "border-primary text-primary font-semibold text-[1rem]"
                      : "border-transparent font-semibold"
                  } hover:text-primary hover:border-primary py-2`}
                >
                  {tab.title}
                </li>
              ))}
            </ul>

            {/* Show the selected component */}
            <div className="sm:p-10 py-2 bg-[#F6F4F4]">
              {tabs.find((tab) => tab.key === active)?.component}

              <div className="grid grid-cols-6 gap-4">
                {appointments?.map((appointment, index) => (
                  <div className="bg-white rounded-lg shadow-md text-center w-40 mb-2 ">
                    <div
                      className={`${
                        appointment?.booked
                          ? "bg-gray-300 text-white"
                          : "bg-[#E6F1F0] text-primary "
                      }  rounded-t-lg py-2 font-semibold`}
                    >
                      {appointment?.booked ? "Not Available" : "Available"}
                    </div>
                    <div className="py-4 text-black font-semibold">
                      8:00 AM – 8:15 AM
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Button */}
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            setAppointmentBooked(true);
            // router("/pensionerVerification/selectProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[14rem]`}
        >
          Book Appointment
        </button>
      </div>
      {appointmentBooked && (
        <AppointConfirm
          setShow={setAppointmentBooked}
          date={"19th November 2024"}
          time={"9:00 AM to 10:00 AM"}
        />
      )}
    </div>
  );
};

export default SetSlot;
