import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table";
import ErrorModal from "../Modals/errorModal";
import TransactionCard from "./TransactionCard";
import { DatePicker, Pagination } from "antd";
import axios from "axios";
import dayjs from "dayjs";
const ProfileCard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const token = useSelector((state) => state.token);
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [paymentYear, setPaymentYear] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const allTransactions = useSelector((state) => state.allTransactions);
  const [search, setSearch] = useState("");

  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  // useEffect(() => {
  //   const params = {
  //     limit: 6,
  //     skip: 0,
  //     page: page,
  //     sortBy: "createdAt:desc",
  //     ...(search && { search }),
  //     ...(paymentYear && { paymentDate: paymentYear }),
  //   };

  //   const api =
  //     dash === "/Dashboard/transaction_history"
  //       ? "v1/merch-transactions/for-pensioner"
  //       : "v1/merch-transactions/own";

  //   // Log effect execution
  //   console.log("useEffect triggered:", { paymentYear, page, search });

  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
  //       params,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       setPaymentData(response?.data?.rows);
  //       setTotalPages(response?.data?.totalPages);
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, [paymentYear, page, search]);

  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(paymentYear && { paymentDate: paymentYear }),
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, paymentYear]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api =
      dash === "/Dashboard/transaction_history"
        ? "v1/merch-transactions/for-pensioner"
        : "v1/merch-transactions/own";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setPaymentData(response?.data?.rows);
        setTotalPages(response?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setErr("");
      });
  };

  const pageHandler = (e) => {
    setPage(e);
  };

  const disabledDate1 = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };

  return (
    <div className="mt-[-2rem] sm:mt-[-4rem]">
      <div className="sm:mx-6 mb-12">
        <div className="sm:p-8 py-4 bg-white  mt-8 rounded-lg">
          <div className=" relative w-full  p-8 rounded-lg mb-10 relative border ">
            <div className="flex absolute top-5 right-8 gap-1 ">
              <div
                className="flex items-center justify-center   w-10 h-10 cursor-pointer"
                onClick={() => {
                  dispatch({ type: "ALL_TRANSACTIONS", num: "grid" });
                }}
              >
                {/* <img src={grid} alt="" className="w-12 h-12 p-2" /> */}
                <svg
                  className="w-8 h-8 p-1"
                  viewBox="0 -0.5 21 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="0.1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Dribbble-Light-Preview"
                      transform="translate(-219.000000, -200.000000)"
                      fill={`${
                        allTransactions === "grid" ? "#1BA397" : "#000000"
                      }`}
                    >
                      <g
                        id="icons"
                        transform="translate(56.000000, 160.000000)"
                      >
                        <path
                          d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                          id="grid-[#1526]"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {/* <SlidersFilled style={{ fontSize: "26px", color: "#1BA397" }} /> */}
              </div>
              <div
                className="flex items-center justify-center  w-10 h-10 cursor-pointer"
                onClick={() => {
                  dispatch({ type: "ALL_TRANSACTIONS", num: "table" });
                }}
              >
                {/* <img src={list} alt="" className="w-12 h-12 p-1" /> */}
                <svg
                  className="w-8 h-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 6L21 6.00078M8 12L21 12.0008M8 18L21 18.0007M3 6.5H4V5.5H3V6.5ZM3 12.5H4V11.5H3V12.5ZM3 18.5H4V17.5H3V18.5Z"
                    stroke={`${
                      allTransactions === "table" ? "#1BA397" : "#000000"
                    }`}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className={`sm:ml-10 sm:mr-10 mb-10 mt-12 flex justify-between pt-5 p-4 rounded-lg cursor-pointer 
              bg-[#F4F5FE] shadow-sm  transition duration-200 ease-in-out flex-col
                `}
            >
              <div className="flex items-center w-full ">
                <div className="flex-1 items-center justify-center bg-[#F5F5F5] rounded-full">
                  {/* <SlidersFilled
                    style={{ fontSize: "26px", color: "#1BA397" }}
                  /> */}
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      // id="default-search"
                      className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-md    "
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex-1 ml-4 w-full">
                  <DatePicker
                    placeholder="Select Payment Date"
                    // value={dayjs(paymentYear, 'YYYY')}
                    allowClear={true}
                    disabledDate={disabledDate1}
                    onChange={(date, dateString) => {
                      if (setPaymentYear) {
                        setPaymentYear(dateString);
                      }
                    }}
                  />
                </div>
                {/* <div className="ml-4 w-full">
                  <h3
                    className={`text-lg font-semibold mb-1 
                        text-primary
                      `}
                  >
                    Apply Filters
                  </h3>

                  <p
                    className={`text-xs 
                       text-gray-600
                      `}
                  >
                  
                  </p>
                </div> */}
              </div>
              {/* {open && (
                <div className="pr-5 pl-5">
                  <div className="left-0 mt-5  px-20 py-10  rounded-md  bg-white ring-1 ring-black ring-opacity-5 flex flex-col gap-4">
                    <div>
                      <Label title="Select From Month" />
                      <input
                        type="text"
                        className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
            
            `}
                        placeholder="Select From Month"
                      />
                    </div>
                    <div>
                      <Label title="Select To Month" />
                      <input
                        type="text"
                        className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
            
            `}
                        placeholder="Select To Month"
                      />
                    </div>
                    <div>
                      <Label title="Transaction Type" />
                      <Select
                          placeholder="Transaction Type"
                          optionFilterProp="children"
                          className="wow"
                        //   style={{ marginBottom: "1.2rem" }}
                          // onChange={handleChange(setTitle, "tg")}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={[]}
                        />
                    </div>
                    <div>
                     <Label title="Status" />
                      <Select
                          placeholder="Status"
                          optionFilterProp="children"
                          className="wow"
                       
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={[]}
                        />
                    </div>
                    <div>
                     <Label title="Program" />
                      <Select
                          placeholder="Program"
                          optionFilterProp="children"
                          className="wow"
                      
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={[]}
                        />
                    </div>


                    <div className="mt-2 flex w-full gap-2 justify-center">
                      <button
                        className={`bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8 `}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            {allTransactions === "table" && (
              <Table
                data={paymentData}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
            {allTransactions === "grid" && (
              <>
                <div>
                  {paymentData?.length === 0 && (
                    <h1 className="text-center text-lg text-gray-500 p-10">
                      No Data Found
                    </h1>
                  )}
                </div>
                <div className="sm:px-10 grid sm:grid-cols-2 grid-cols-1 gap-8">
                  {paymentData?.map((item, index) => (
                    <div className="mb-4 flex flex-col" key={index}>
                      <TransactionCard data={item} />
                    </div>
                  ))}
                </div>

                <div
                  className={`flex justify-end mt-7 ${
                    paymentData?.length === 0 ? "hidden" : ""
                  }`}
                >
                  <Pagination
                    defaultCurrent={1}
                    total={totalPages * 10}
                    showSizeChanger={false}
                    onChange={pageHandler}
                    current={page}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showErrModal && <ErrorModal err={err} setShow={setShowErrModal} />}
    </div>
  );
};

export default ProfileCard;
