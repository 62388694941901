import back5 from "../../imgs/back5.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import axios from "axios";
import cross from "../../imgs/cross.png";
import { Select } from "antd";

let file;
const AddPensioner = () => {
  const router = useNavigate();
  const [display, setDisplay] = useState("");
  const [isLoading1, setIsLoading1] = useState(false);
  const [imageUrl1, setImageUrl1] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const [iShow, setIShow] = useState(false);
  console.log(display, "Display", isLoading1, imageUrl1);

  const imageHandler = (setState, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setImageUrl1(data.data.url);

        load(false);
      })
      .catch(() => {});
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  return (
    <div>
      <div className="relative flex justify-between sm:px-[7rem] px-[2rem] sm:mt-[5rem] mt-[2rem] mb-[2rem]">
        <div className="flex items-center">
          <div
            onClick={() => {
              router("/superAdminDashboard/Pensioner_HistoryP");
            }}
            // className="cursor-pointer pt-[5rem] mr-5"
          >
            <img className="w-[1.7rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold ml-[1rem] sm:text-[1.8rem] text-[1.4rem]">
            Add Cash Grant
          </h2>
        </div>
      </div>
      <div className=" mb-[5rem] bg-slate-50 p-4 lg:mx-[20rem] sm:mx-[10rem] mx-6 rounded-md shadow-lg">
        <div className="flex justify-center">
          <form className="w-full max-w-lg">
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">Select Year:</h2>
              <div className="flex justify-start">
                <Select
                  placeholder="Year"
                  optionFilterProp="children"
                  className="wow"
                  style={{
                    marginBottom: "1.2rem",
                    width: "100%",
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  defaultValue={{
                    label: "2023",
                    value: "2023",
                  }}
                  // onChange={yearChangeHandler}
                  // value={allAddressList[showIndex]}
                  options={[
                    {
                      label: "2023",
                      value: "2023",
                    },
                    {
                      label: "2024",
                      value: "2024",
                    },
                  ]}
                />
              </div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Current Cash Grant #:
              </h2>
              <input
                type="text"
                name=""
                
                placeholder={"Current Cash Grant #"}
                // onChange={(e) => {
                //   setBookletNo1(e.target.value);
                // }}
                // value={bookletNo1}
                className={`lg:mb-5 lg:text-[1.1rem] w-[100%] border border-slate-300 rounded-[0.5rem] p-2 mb-4`}
              />

              <h2 className="mb-4 text-[1.1rem] font-semibold">Image Front:</h2>
              <div className={``}>
                <label
                  htmlFor="special-input4"
                  className={` cursor-pointer relative`}
                >
                  <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                    <div>
                      <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                    </div>
                    <p className="text-[#9CA6C2] ml-2">Image Front</p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    id="special-input4"
                    accept="image/png, image/gif, image/jpeg"
                    onInput={imageHandler(setDisplay, setIsLoading1)}
                  />
                </label>
                <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
                  <img
                    onClick={() => {
                      setIShow(true);
                    }}
                    className="w-[25rem] h-[13rem]"
                    src={display}
                    alt=""
                  />
                </div>
                <div
                  className={`${
                    iShow ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow(false)}
                      alt=""
                    />
                    <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className={`flex items-end justify-end`}>
              <button
                // type="submit"
                className={`relative lg:text-[1.1rem] mt-4 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
              >
                <span>Save</span>
              </button>
            </div>
          </form>

          {/* <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddPensioner;
