import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import { Pagination, Popover } from "antd";

// import SearchFilter from "./SearchFilter";

const Paget = () => {
  const router = useNavigate();

  const token = useSelector((state) => state.token);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);

  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);
  const type = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);
  const merchId =
    type === "HQ" ? typeData?.merchuser?.merchheadquarterId : typeData?.id;
  // console.log(merchId);
  const columnHeaders = [
    "Sr No",
    "Name",
    "Payment Date / Time",
    "Transaction Type",
    // "Phone",
    "Amount",
    "Status",
  ];

  useEffect(() => {
    // if (user !== "pensioner") {
    //   return;
    // }
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const pensionerParams = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
      };

      const merchParams = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
      };

      // Conditionally add merchheadquarterId or merchstoreId based on type

      if (
        user === "merchant-owner" ||
        user === "merchant-employee" ||
        user === "merchant-admin"
      ) {
        if (type === "HQ") {
          merchParams.fromMerchHeadquarter =
            typeData?.merchuser?.merchheadquarterId;
        } else if (type === "store") {
          merchParams.fromMerchStore = typeData?.id;
        }
      }

      const params = user === "pensioner" ? pensionerParams : merchParams;

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api =
      user === "pensioner"
        ? "v1/sapwallet-histories/for-pensioner"
        : user === "merchant-owner" ||
          user === "merchant-employee" ||
          user === "merchant-admin"
        ? "v1/sapwallet-histories/for-merch"
        : "v1/sapwallet-histories/for-mhsss?source=mhsss-payment";
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        const updatedRows = data?.data?.rows?.map((row) => {
          if (user === "pensioner") {
            if (row?.source === "sapwallet-to-sapwallet") {
              if (
                dataUser?.data?.user?.id === row?.fromUserId &&
                (row?.FromMerchHeadquarter !== null ||
                  row?.FromMerchStore !== null)
              ) {
                return {
                  ...row,
                  type: "credit",
                  name:
                    row?.FromMerchHeadquarter !== null
                      ? row?.FromMerchCompany?.name
                      : row?.FromMerchStore !== null
                      ? row?.FromMerchStore?.customStoreName
                      : `${row?.FromUser?.person?.firstName || ""} 
       ${
         row?.FromUser?.person?.middleName
           ? row?.FromUser?.person?.middleName + " "
           : ""
       } 
       ${row?.FromUser?.person?.lastName || ""}`,
                };
              }
            }
            if (dataUser?.data?.user?.id === row.fromUserId) {
              return {
                ...row,
                type: "debit",
                name:
                  row?.sapwalletinfo?.type === "merch-headquarter"
                    ? row?.sapwalletinfo?.merchCompany?.name
                    : row?.sapwalletinfo?.type === "merch-store"
                    ? row?.sapwalletinfo?.merchstore?.customStoreName
                    : row?.sapwalletinfo?.user?.person?.firstName +
                      " " +
                      row?.sapwalletinfo?.user?.person?.lastName,
              };
            } else {
              return {
                ...row,
                type: "credit",
                name:
                  row?.FromMerchHeadquarter !== null
                    ? row?.FromMerchCompany?.name
                    : row?.FromMerchStore !== null
                    ? row?.FromMerchStore?.customStoreName
                    : `${row?.FromUser?.person?.firstName || ""} 
   ${
     row?.FromUser?.person?.middleName
       ? row?.FromUser?.person?.middleName + " "
       : ""
   } 
   ${row?.FromUser?.person?.lastName || ""}`,
              };
            }
          }

          if (
            user === "merchant-owner" ||
            user === "merchant-employee" ||
            user === "merchant-admin"
          ) {
            const checkFromUser =
              row?.fromMerchHeadquarter === null &&
              row?.fromMerchStore === null &&
              row?.sapwalletinfo?.user === null;

            if (type === "HQ") {
              if (merchId === row?.fromMerchHeadquarter) {
                return {
                  ...row,
                  type: "debit",
                  name:
                    row?.sapwalletinfo?.type === "merch-headquarter"
                      ? row?.sapwalletinfo?.merchCompany?.name || "HQ"
                      : row?.sapwalletinfo?.type === "merch-store"
                      ? row?.sapwalletinfo?.merchstore?.customStoreName || "Store"
                      : row?.sapwalletinfo?.user?.person?.firstName +
                        " " +
                        row?.sapwalletinfo?.user?.person?.lastName,
                };
              } else {
                return {
                  ...row,
                  type: "credit",
                  name:
                    checkFromUser === true
                      ? row?.FromUser?.person?.firstName +
                        " " +
                        row?.FromUser?.person?.lastName
                      : row?.FromMerchHeadquarter !== null
                      ? row?.FromMerchCompany?.name || "HQ"
                      : row?.FromMerchStore !== null
                      ? row?.FromMerchStore?.customStoreName || "Store"
                      : row?.sapwalletinfo?.user?.person?.firstName +
                        " " +
                        row?.sapwalletinfo?.user?.person?.lastName,
                  // name:
                  //   checkFromUser === true
                  //     ? row?.FromUser?.person?.firstName +
                  //       " " +
                  //       row?.FromUser?.person?.lastName
                  //     : row?.sapwalletinfo?.user?.person?.firstName +
                  //       " " +
                  //       row?.sapwalletinfo?.user?.person?.lastName,
                };
              }
            }
            if (type === "store") {
              if (merchId === row?.fromMerchStore) {
                return {
                  ...row,
                  type: "debit",
                  name:
                    row?.sapwalletinfo?.type === "merch-headquarter"
                      ? row?.sapwalletinfo?.merchCompany?.name
                      : row?.sapwalletinfo?.type === "merch-store"
                      ? row?.sapwalletinfo?.merchstore?.customStoreName
                      : row?.sapwalletinfo?.user?.person?.firstName +
                        " " +
                        row?.sapwalletinfo?.user?.person?.lastName,
                };
              } else {
                return {
                  ...row,
                  type: "credit",
                  name:
                  checkFromUser === true
                    ? row?.FromUser?.person?.firstName +
                      " " +
                      row?.FromUser?.person?.lastName
                    : row?.FromMerchHeadquarter !== null
                    ? row?.FromMerchCompany?.name || "HQ"
                    : row?.FromMerchStore !== null
                    ? row?.FromMerchStore?.customStoreName || "Store"
                    : row?.sapwalletinfo?.user?.person?.firstName +
                      " " +
                      row?.sapwalletinfo?.user?.person?.lastName,
                  // name:
                  //   checkFromUser === true
                  //     ? row?.FromUser?.person?.firstName +
                  //       " " +
                  //       row?.FromUser?.person?.lastName
                  //     : row?.sapwalletinfo?.user?.person?.firstName +
                  //       " " +
                  //       row?.sapwalletinfo?.user?.person?.lastName,
                };
              }
            }
          }

          if (
            user === "employee" ||
            user === "admin" ||
            user === "superadmin"
          ) {
            if (row?.source === "mhsss-payment") {
              return {
                ...row,
                type: "debit",
                name:
                  row?.sapwalletinfo?.type === "merch-headquarter"
                    ? row?.sapwalletinfo?.merchCompany?.name
                    : row?.sapwalletinfo?.type === "merch-store"
                    ? row?.sapwalletinfo?.merchstore?.customStoreName
                    : row?.sapwalletinfo?.user?.person?.firstName +
                      " " +
                      row?.sapwalletinfo?.user?.person?.lastName,
              };
            } else {
              return {
                ...row,
                type: "credit",
                name:
                  row?.sapwalletinfo?.type === "merch-headquarter"
                    ? row?.sapwalletinfo?.merchCompany?.name
                    : row?.sapwalletinfo?.type === "merch-store"
                    ? row?.sapwalletinfo?.merchstore?.customStoreName
                    : row?.sapwalletinfo?.user?.person?.firstName +
                      " " +
                      row?.sapwalletinfo?.user?.person?.lastName,
              };
            }
          }
          return row;
        });

        setData(updatedRows);
        setTotalPages(data?.data?.totalPages);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  console.log(data);

  const pageHandler = (e) => {
    // if (search) {
    //   // If searching, update searchPage
    //   setPagewithsearch(e);
    // } else {
    //   // If not searching, update page
    setPage(e);
    // }
  };

  return (
    <section className="mt-3">
      <div className="mx-auto max-w-screen-xl relative  h-auto bg-white">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#D9EFED]">
          {/* table */}
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders
                    .filter((columnHeader) => {
                      if (
                        user !== "pensioner" &&
                        columnHeader === "Store / HQ"
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-3 py-4 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                </tr>
              </thead>
              {data?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p className="p-10 py-20">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {data?.map((pen, index) => (
                    <tr
                      onClick={() => {
                        if (pen?.source === "merchant-transaction") {
                          router(
                            "/SapMoney/Pensioner/programTransaction/detail",
                            {
                              state: {
                                id: pen?.merchtransactionId,
                                selectedTransactionsVouchers: pen,
                              },
                            }
                          );
                          return;
                        }

                        if (pen?.source === "mhsss-payment") {
                          router("/SapMoney/Merchant/MHSSSTransaction/detail", {
                            state: {
                              id: pen?.merchtransactionpaymentId,
                              selectedTransactionsVouchers: pen,
                            },
                          });
                          return;
                        }

                        router(
                          "/SapMoney/Pensioner/generalTransaction/detail",
                          {
                            state: {
                              id: pen?.id,
                              selectedTransactionsVouchers: pen,
                            },
                          }
                        );
                      }}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900  whitespace-nowrap  "
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>

                      <td className="px-4 py-5">
                        {/* {pen?.sapwalletinfo?.type === "merch-headquarter"
                          ? pen?.sapwalletinfo?.merchCompany?.name
                          : pen?.sapwalletinfo?.type === "merch-store"
                          ? pen?.sapwalletinfo?.merchstore?.customStoreName
                          : pen?.sapwalletinfo?.user?.person?.firstName +
                            " " +
                            pen?.sapwalletinfo?.user?.person?.lastName} */}
                        {pen?.name ? pen?.name : ""}
                      </td>

                      <td className="px-4 py-5">
                        <Popover
                          content={`${
                            pen.status === "pending" ? "In-Process" : ""
                          }`}
                          placement="bottomLeft"
                        >
                          <li className="flex items-center">
                            {/* {pen?.createdAt?.substring(0, 10)}{" "} */}
                            {moment(pen?.createdAt).format(
                              "MM/DD/YYYY hh:mm A"
                            )}
                          </li>
                          {/* <p>{moment(pen?.createdAt).format("hh:mm A")}</p> */}
                        </Popover>
                      </td>

                      <td className="px-4 py-5">
                        {pen?.source === "sapwallet-to-sapwallet"
                          ? "Pay to SAP Members"
                          : pen?.source === "merchant-transaction"
                          ? "Voucher Cashing"
                          : pen?.source === "quick-payee"
                          ? "Third Party"
                          : pen?.source === "mhsss-payment"
                          ? "MHSSS Payment"
                          : " Other"}
                      </td>
                      {/* <td className="px-4 py-5">----</td> */}

                      <td
                        className={`px-4 py-5 font-semibold text-md ${
                          pen?.type === "credit"
                            ? "text-primary"
                            : "text-red-500"
                        }`}
                      >
                        {pen?.amount === null
                          ? "----"
                          : `${pen?.type === "credit" ? "+" : "-"}$${
                              pen?.amount
                            }`}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div
                          className={`border-none ${
                            pen?.type === "credit"
                              ? "bg-primary"
                              : "bg-[#EF4D5C]"
                          }  p-1 pl-3 pr-3 rounded-md text-white focus:border-none cursor-pointer capitalize text-center`}
                        >
                          {pen?.type === "credit" ? "Received" : "Paid"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
                // current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Paget;
