import { Checkbox, Pagination } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const Paget = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [err, setErr] = useState("");
  const [searchDop, setSearchDop] = useState("");
  const [page, setPage] = useState(1);
  const search = "";
  const transactionType = "cr";
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [recordsCr, setRecordsCr] = useState([]);
  const [status, setStatus] = useState(null);
  const [type, setType] = useState(null);
  const columnHeadersCr = [
    "Sr No.",
    "Requested On",
    "Payment ID",
    "Transaction Date/Time",
    // "Payment Method",
    "Amount",
    "Status",
  ];

  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: search ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
      };

      if (type !== null) {
        params.status = type;
      }

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, transactionType, searchDop, status]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api = "v1/bank-balance-requests/list-for-govt-agency";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setRecordsCr(data?.data?.rows);

        setTotalPages(data.data.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTypeChange = (method) => {
    if (type === method) {
      setType(null);
    } else {
      setType(method);
    }
  };

  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const pageHandler = (e) => {
    if (search) {
      setErr("");
      setSearchDop("");
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  return (
    <section className="sm:px-5">
      <div className="mx-auto max-w-screen-xl ">
        <div className="bg-white w-full flex flex-col">
          <div className="flex flex-row items-center justify-end mb-4 gap-2">
            <div className="w-[45%] border rounded-lg md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <div className="relative w-full">
                    <button
                      id="filterDropdownButton"
                      data-dropdown-toggle="filterDropdown"
                      className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900  focus:z-10     "
                      type="button"
                      onClick={showHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter By
                      <svg
                        className="-mr-1 ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>

                    {show && (
                      <div
                        id="filterDropdown"
                        className="z-50 absolute top-full right-0 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                      >
                        <div className="mt-5">
                          <Checkbox
                            onChange={() => handleTypeChange("requested")}
                            checked={type === "requested"}
                            className="checkk4"
                          >
                            Requested
                          </Checkbox>
                          <Checkbox
                            onChange={() => handleTypeChange("mhsss_paid")}
                            checked={type === "mhsss_paid"}
                            className="checkk4"
                          >
                            Paid
                          </Checkbox>
                          <Checkbox
                            onChange={() => handleTypeChange("mhsss_rejected")}
                            checked={type === "mhsss_rejected"}
                            className="checkk4"
                          >
                            Rejected
                          </Checkbox>
                        </div>

                        <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => {
                              if (type !== null) {
                                setStatus(type);
                              }

                              setShow(false);
                            }}
                            className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              setStatus(null);
                              setType(null);
                              setShow(false);
                            }}
                            className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-2 px-4">
              {/* <div className="w-full md:w-[20rem]">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                      placeholder="Search"
                      required=""
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div> */}
            </div>
          </div>
          <div className="mb-8 w-full px-6 h-full rounded-tl-lg rounded-tr-lg">
            <div className="overflow-x-auto w-full h-full mt-2 rounded-tl-lg rounded-tr-lg custom-scrollbar">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase rounded-t-lg bg-primary text-white">
                  <tr>
                    {columnHeadersCr.map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>
                {recordsCr?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="11"
                        className="text-center text-gray-500 text-lg bg-white"
                      >
                        <p className="p-10 py-20">No data found</p>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {recordsCr?.map((data, index) => (
                      <tr
                        onClick={
                          () => {
                            dispatch({
                              type: "DEPOSIT_MONEY_DATA",
                              num: {
                                type: "requestHistory",
                                id: data?.id,
                                method: data?.method,
                                reqDate: data?.createdAt
                                  ? data?.createdAt
                                  : null,
                                paymentId: data?.paymentId,
                                transactionData: data?.updatedAt,
                                bankReceiptImg: data?.bankReceiptImg,
                                bankReceiptNo: data?.bankReceiptNo,
                                chequeImg: data?.chequeImg,
                                chequeNo: data?.chequeNo,
                                amount: data?.amount,
                                status: data?.status,
                                note: data?.note,
                                rejectionNote: data?.rejectReason,
                              },
                            });
                            router(
                              "/Merchant/GovAgency/Request/MHSSS/History/Detail"
                            );
                          }
                          // }
                        }
                        className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
                      >
                        <td className="px-4 py-2 whitespace-nowrap">
                          {index + 1 + (page - 1) * 6}.
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          <div>
                            <p>
                              {moment(data?.createdAt).format("MM/DD/YYYY")}
                            </p>
                            <p> {moment(data?.createdAt).format("hh:mm A")}</p>
                          </div>
                        </td>

                        <td className="px-4 py-2 whitespace-nowrap">
                          <div>
                            {data?.status === "mhsss_paid"
                              ? data?.paymentId
                              : "------"}
                          </div>
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {data?.status === "mhsss_paid" ? (
                            <>
                              <p>
                                {moment(data?.updatedAt).format("MM/DD/YYYY")}
                              </p>
                              <p>
                                {" "}
                                {moment(data?.updatedAt).format("hh:mm A")}
                              </p>
                            </>
                          ) : (
                            "-----"
                          )}
                        </td>
                        {/* <td className="px-4 py-2 whitespace-nowrap capitalize">
                          {data?.method}
                        </td> */}
                        <td className="px-4 py-2 whitespace-nowrap  font-semibold text-primary capitalize">
                          ${data?.amount}
                        </td>

                        <td className="px-4 py-2 whitespace-nowrap">
                          <div
                            className={`border-none ${
                              data?.status === "requested"
                                ? "bg-blue-500"
                                : data?.status === "mhsss_rejected"
                                ? "bg-red-500"
                                : "bg-primary"
                            }  p-1 pl-5 pr-5 rounded-md text-center text-white focus:border-none cursor-pointer capitalize`}
                          >
                            {data?.status === "mhsss_rejected"
                              ? "Rejected"
                              : data?.status === "mhsss_paid"
                              ? "Paid"
                              : "Requested"}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <nav
              className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500">
                Showing{" "}
                <span className="font-semibold text-gray-900 ">
                  Page {page}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 ">
                  {totalPages}
                </span>
              </span>
              <div className={`flex justify-end mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={search ? pagewithsearch : page}
                />
              </div>
            </nav>
          </div>
        </div>
      </div>
      {showError && <ErrorModal err={err} setShow={setShowError} />}
    </section>
  );
};

export default Paget;
