import React, { useState, useEffect } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import cartW from "../../../imgs/cartW.png";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../cart/footer";
const EventCard = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const fav = useSelector((state) => state.fav);
  const [localCart, setCart] = useState(cart);
  const [favorite, setFavorite] = useState(fav);
  const [initial, setinitial] = useState(true);
  const [initialFav, setinitialFav] = useState(true);
  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const addToCart = (event) => {
    setinitial(false);
    const cartItem = {
      id: event.id,
      image: event.coverPhotos[0],
      name: event.name,
      quantity: event.quantity,
      price: event.price,
      amount: event.amount,
    };

    const updatedCart = [...localCart, cartItem];
    setCart(updatedCart);
  };
  // const addToFav = (event) => {
  //   setinitialFav(false);
  //   const favItems = {
  //     id: event.id,
  //     image: event.coverPhotos[0],
  //     name: event.name,
  //     quantity: event.quantity,
  //     price: event.price,
  //     amount: event.amount,
  //   };

  //   const updatedFav = [...favorite, favItems];
  //   setFavorite(updatedFav);
  // };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };
  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };
  // const isAddedToFav = (eventId) => {
  //   return favorite.some((item) => item.id === eventId);
  // };
  const events = fav;
  console.log("tet", fav);
  // const CustomPrevArrow = (props) => {
  //   const { onClick } = props;
  //   return (
  //     <Button
  //       className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
  //       onClick={onClick}
  //     >
  //       <LeftOutlined className="text-white font-semibold" />
  //     </Button>
  //   );
  // };

  const Shop = ({ item }) => {
    return (
      <div className="flex w-full items-center flex-col rounded-xl bg-white shadow-lg justify-between pl-4 pr-4 p-2 border-b border-gray-300">
        <h2
          onClick={() => {
            router("/Inventory-Vendor-Products");
          }}
          className="border-b text-gray-600 p-1 w-full hover:text-[#1BA397] cursor-pointer font-semibold"
        >
          Semule's Grocery Store
        </h2>
        <div className="flex w-full h-full pt-1 items-center justify-between">
          <div className="flex gap-3 items-center">
            <img
              src={item.coverPhotos[0]}
              alt={item.title}
              onClick={() => {
                router(item.route);
              }}
              className="w-24 h-24 rounded-md cursor-pointer"
            />
            <div className="ml-4 flex flex-col gap-4">
              <h2
                onClick={() => {
                  router(item.route);
                }}
                className="text-xl text-gray-700 hover:text-[#1BA397] cursor-pointer font-semibold"
              >
                {item.name}
              </h2>
              <DeleteFilled
                onClick={() => removeFromFav(item.id)}
                className="text-gray-600 p-1 w-full hover:text-[#1BA397] text-left cursor-pointer font-semibold"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-4">
            <div className="flex gap-4 mt-4">
              <h1 className="font-semibold text-xl text-red-600">Price :</h1>
              <p className="font-semibold text-xl text-red-600">
                ${item.price}
              </p>
            </div>
            <div className="flex gap-4">
              <h1 className="text-md text-gray-600  line-through">
                Price : 100
              </h1>
              <p className="">-20%</p>
            </div>
          </div>
          {isAddedToCart(item.id) ? (
            <button
              className="bg-red-600 text-white p-2 pl-3 pr-3 rounded-lg sm:text-lg text-md"
              onClick={() => removeFromCart(item.id)}
            >
              <img src={cartW} alt="" className="w-5 h-5" />
            </button>
          ) : (
            <button
              className="text-white bg-primary p-2 pl-3 pr-3 rounded-lg sm:text-lg text-md"
              onClick={() => addToCart(item)}
            >
              <img src={cartW} alt="" className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    );
  };

  // const CustomNextArrow = (props) => {
  //   const { onClick } = props;
  //   return (
  //     <Button
  //       className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
  //       onClick={onClick}
  //     >
  //       <RightOutlined className="text-white font-semibold" />
  //     </Button>
  //   );
  // };

  return (
    <div className="flex w-full pt-[3rem] pb-[8rem] flex-col justify-center">
      <div className="relative w-[40%]">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 mb-4 text-gray-500 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
          placeholder="Search Items..."
          required=""
        />
      </div>
      {/* <div className="w-full flex flex-wrap gap-5 gap-y-8 mt-2 justify-between">
        {events.length !== 0 ? (
          events.map((event) => (
            <div key={event.id} className="w-[22%]">
              <Carousel
                arrows
                prevArrow={<CustomPrevArrow />}
                nextArrow={<CustomNextArrow />}
              >
                {event.coverPhotos.map((coverPhoto, index) => (
                  <div key={index}>
                    <img
                      className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-center items-center object-cover"
                      src={coverPhoto}
                      alt={event.name}
                    />
                  </div>
                ))}
              </Carousel>
              <div className="px-6 py-3 bg-gray-100 rounded-br-[1.2rem] rounded-bl-[1.2rem] relative">
                <div
                  onClick={() => {
                    dispatch({ type: "Product", num: event });
                    router(event.route);
                  }}
                  className="font-semibold w-full flex flex-col items-center justify-center text-center text-xl hover:text-[#1BA397] cursor-pointer"
                >
                  {event.name}
                </div>
                <p className="text-gray-600 w-full text-center font-semibold ">
                  ${event.price}/{event.amount}
                </p>
                <div className="w-full flex items-center mt-2 mb-2 justify-center">
                  {isAddedToCart(event.id) ? (
                    <button
                      className="bg-red-600 rounded-xl cursor-pointer pl-5 pr-5 text-semibold text-white"
                      onClick={() => removeFromCart(event.id)}
                    >
                      Remove from Cart
                    </button>
                  ) : (
                    <button
                      className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white"
                      onClick={() => addToCart(event)}
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
                {isAddedToFav(event.id) ? (
                  // <button
                  //   className="bg-red-600 rounded-xl cursor-pointer pl-5 pr-5 text-semibold text-white"
                  //   onClick={() => removeFromCart(event.id)}
                  // >
                  //   Remove from Cart
                  // </button>
                  <img
                    onClick={() => removeFromFav(event.id)}
                    src={like}
                    alt=""
                    className="w-4 h-4 absolute cursor-pointer right-[5px] top-[5px]"
                  />
                ) : (
                  // <button
                  //   className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white"
                  //   onClick={() => addToCart(event)}
                  // >
                  //   Add to Cart
                  // </button>
                  <img
                    onClick={() => addToFav(event)}
                    src={dislike}
                    alt=""
                    className="w-4 h-4 cursor-pointer absolute right-[5px] top-[5px]"
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="mt-8 w-full">
            <h1 className="text-xl w-full text-center font-semibold">
              No Favorite Items...
            </h1>
          </div>
        )}
      </div> */}
      <div className="w-full flex flex-wrap mt-[3rem] justify-center items-center gap-8 md:gap-x-8 gap-y-4 sm:min-h-[200px]">
        {events?.map((item, index) => (
          <Shop key={index} item={item} />
        ))}
      </div>
      {cart.length > 0 && <Footer />}
    </div>
  );
};

export default EventCard;
