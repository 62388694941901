import { Select, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import across from "../../../imgs/across.png";
import { PlusCircleFilled } from "@ant-design/icons";
let arr = [1];
let copArr = [
  {
    add: "",
    lot: "",
    aR: [],
    rName: null,
    tList: [],
    tName: null,
    wList: [],
    wName: null,
    isCurrent: true,
    key: 0,
  },
];
let Index = 0;
let nowIndex = 0;
const ThirdR = () => {
  const location = useLocation();
  const benId = useSelector((state) => state.benId);
  const dataUser = useSelector((state) => state.dataUser);
  const popData = useSelector((state) => state.popData);
  const icurrent = useSelector((state) => state.icurrent);
  const dash = location.pathname;
  const token = useSelector((state) => state.token);
  const [address, setAddress] = useState("");
  const [regionNo, setRegionNo] = useState("");
  const [regionName, setRegionName] = useState("");
  const type = "home";
  const [lot, setLot] = useState("");
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);
  const [test, setTest] = useState(false);
  const [show1, setShow1] = useState(false);
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  console.log(allCities, allWards);
  // const defaultOptions = [
  //   {
  //     value: "home",
  //     label: "Home",
  //   },
  //   {
  //     value: "office",
  //     label: "Office",
  //   },
  // ];
  // const isBeneficiaryQuestions =
  //   location.pathname === "/Dashboard/BeneficiaryQuestions";
  useEffect(() => {
    return () => {
      copArr = [
        {
          add: "",
          lot: "",
          aR: [],
          rName: null,
          tList: [],
          tName: null,
          wList: [],
          wName: null,
          isCurrent: null,
          key: 0,
        },
      ];
      Index = 0;
    };
  }, []);

  const nextHandler = (e) => {
    e.preventDefault();
    let data = [];

    const wow = copArr.filter((item) => {
      return item.isCurrent === true;
    });

    if (wow.length === 0) {
      setShow1(true);
      setErr("Please Select a current address");
    } else {
      if (icurrent <= 5) {
        data = [
          {
            isCurrent: true,
            regionNo,
            regionName,
            address,
            type,
            lot,
            city,
            ward,
          },
        ];
        if (dash === "/EDashboard/addPensioner") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/addresses/add-by-staff?addressUserId=${popData.id}`,
              { addresses: data },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data?.tokens?.access?.token}`,
                },
              }
            )
            .then((data) => {
              dispatch({ type: "data2Set", num: data });
              dispatch({ type: "IINCREMENT" });
            })
            .catch((err) => {
              if (lot === null || lot === "") setErr("Lot Number is required");
              else if (address === null || address === "")
                setErr("Address is required");
              else if (regionNo === null || regionNo === "")
                setErr("Region No is required");
              else if (regionName === null || regionName === "")
                setErr("Region Name is required");
              else if (city === null || city === "")
                setErr("Town/City is required");
              else if (ward === null || ward === "")
                setErr("Ward/Village is required");
              else setErr(err.response.data.message);
              setShow1(true);
            });
        } else if (dash === "/Dashboard/BeneficiaryQuestions") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses?beneficiaryUserId=${benId}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "BRSET", num: 3 });
              } else {
                dispatch({ type: "IINCREMENT" });
              }
            })
            .catch((err) => {
              if (lot === null || lot === "") setErr("Lot Number is required");
              else if (address === null || address === "")
                setErr("Address is required");
              else if (regionNo === null || regionNo === "")
                setErr("Region No is required");
              else if (regionName === null || regionName === "")
                setErr("Region Name is required");
              else if (city === null || city === "")
                setErr("Town/City is required");
              else if (ward === null || ward === "")
                setErr("Ward/Village is required");
              else setErr(err.response.data.message);
              setShow1(true);
            });
        } else if (
          dash === "/EDashboard/BeneficiaryQuestions" ||
          dash === "/AdminDashboard/BeneficiaryQuestions" ||
          dash === "/superAdminDashboard/BeneficiaryQuestions"
        ) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses/add-by-staff?beneficiaryUserId=${benId}`,
              { addresses: data },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data?.tokens?.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "BRSET", num: 3 });
            })
            .catch((err) => {
              if (lot === null || lot === "") setErr("Lot Number is required");
              else if (address === null || address === "")
                setErr("Address is required");
              else if (regionNo === null || regionNo === "")
                setErr("Region No is required");
              else if (regionName === null || regionName === "")
                setErr("Region Name is required");
              else if (city === null || city === "")
                setErr("Town/City is required");
              else if (ward === null || ward === "")
                setErr("Ward/Village is required");
              else setErr(err.response.data.message);
              setShow1(true);
            });
        } else {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/v1/addresses`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((data) => {
              dispatch({ type: "data2Set", num: data });
              dispatch({ type: "BRSET", num: 3 });
              dispatch({ type: "IINCREMENT" });
            })
            .catch((err) => {
              if (lot === null || lot === "") setErr("Lot Number is required");
              else if (address === null || address === "")
                setErr("Address is required");
              else if (regionNo === null || regionNo === "")
                setErr("Region No is required");
              else if (regionName === null || regionName === "")
                setErr("Region Name is required");
              else if (city === null || city === "")
                setErr("Town/City is required");
              else if (ward === null || ward === "")
                setErr("Ward/Village is required");
              else setErr(err.response.data.message);
              setShow1(true);
            });
        }
      } else {
        data = [
          {
            isCurrent: true,
            type,
            regionNo,
            regionName,
            address,
            // type,
            lot,
            city,
            ward,
          },
        ];
        if (
          dash === "/EDashboard/BeneficiaryQuestions" ||
          dash === "/AdminDashboard/BeneficiaryQuestions" ||
          dash === "/superAdminDashboard/BeneficiaryQuestions"
        ) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses/add-by-staff?beneficiaryUserId=${benId}`,
              { addresses: data },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data?.tokens?.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "BRSET", num: 3 });
            })
            .catch((err) => {
              if (lot === null || lot === "") setErr("Lot Number is required");
              else if (address === null || address === "")
                setErr("Address is required");
              else if (regionNo === null || regionNo === "")
                setErr("Region No is required");
              else if (regionName === null || regionName === "")
                setErr("Region Name is required");
              else if (city === null || city === "")
                setErr("Town/City is required");
              else if (ward === null || ward === "")
                setErr("Ward/Village is required");
              else setErr(err.response.data.message);
              setShow1(true);
            });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/addresses?beneficiaryUserId=${benId}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              if (dash === "/Dashboard/BeneficiaryQuestions") {
                dispatch({ type: "BRSET", num: 3 });
              } else {
                dispatch({ type: "IINCREMENT" });
              }
            })
            .catch((err) => {
              if (lot === null || lot === "") setErr("Lot Number is required");
              else if (address === null || address === "")
                setErr("Address is required");
              else if (regionNo === null || regionNo === "")
                setErr("Region No is required");
              else if (regionName === null || regionName === "")
                setErr("Region Name is required");
              else if (city === null || city === "")
                setErr("Town/City is required");
              else if (ward === null || ward === "")
                setErr("Ward/Village is required");
              else setErr(err.response.data.message);
              setShow1(true);
            });
        }
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);

        copArr[Index].aR = wow;
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          setRegionName(data.data.data[0]);
          copArr[nowIndex].rName = data.data.data[0];
        })
        .catch(() => {});
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
          copArr[nowIndex].tList = wow;
        })
        .catch(() => {});
    }
  }, [regionName]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
          copArr[nowIndex].wList = wow;
        })
        .catch(() => {});
    }
  }, [city]);
  const handleChange = (setChange, check, ww, i) => (event) => {
    nowIndex = i;

    if (check === "tt" || check === "tn") {
      setChange(event);
      if (ww === "town") {
        copArr[nowIndex].tName = event;
      } else if (ww === "city") {
        copArr[nowIndex].wName = event;
      }
    } else {
      if (ww === "lot") {
        copArr[nowIndex].lot = event.target.value;
      } else if (ww === "address") {
        copArr[nowIndex].add = event.target.value;
      }
      setChange(event.target.value);
    }
  };

  const addAddressHandler = (check) => {
    if (check === "add") {
      arr.push(1);
      Index = Index + 1;
      copArr.push({
        add: "",
        lot: "",
        aR: [],
        rName: null,
        tList: [],
        tName: null,
        wList: [],
        wName: null,
        isCurrent: false,
        key: Index,
      });
    } else {
      arr.pop(1);
      Index = Index - 1;
      copArr.pop({
        add: "",
        lot: "",
        aR: [],
        rName: null,
        tList: [],
        tName: null,
        wList: [],
        wName: null,
        isCurrent: false,
        key: Index,
      });
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });

        copArr[Index].aR = wow;

        setAllRegions(wow);
      })
      .catch(() => {});
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };

  const checkHandler = (e, check) => {
    //
    copArr[check].isCurrent = e.target.checked;

    let jiff = [];
    copArr.map((item) => {
      if (item.isCurrent) {
        jiff.push("1");
      }
    });
    if (jiff.length > 1) {
      copArr[check].isCurrent = false;

      setErr("Sorry, can't have more than one default address");
      setShow1(true);
    }

    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [allRegions]);

  return (
    <div className="flex justify-center">
      <form action="" className="sm:w-[70%] w-[80%]" onSubmit={nextHandler}>
        {copArr?.map((add, index) => {
          console.log(add);
          return (
            <div key={index}>
              <div
                className={`flex justify-end cursor-pointer mt-[3rem] ${
                  index === 0 ? "hidden" : ""
                }`}
                onClick={() => addAddressHandler("remove")}
              >
                <p className="text-[red] underline text-[0.94rem]">
                  Remove Address
                </p>
              </div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Address {index + 1}:
              </h2>
              <div className="flex justify-center items-center">
                <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-[100%]">
                  <div>
                    <div className="flex flex-col">
                      <label
                        className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Lot #
                      </label>
                      <input
                        type="text"
                        name=""
                        onChange={handleChange(setLot, "", "lot", index)}
                        value={copArr[index].lot}
                        placeholder="Lot #"
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Address
                      </label>
                      <textarea
                        type="text"
                        name=""
                        
                        onChange={handleChange(
                          setAddress,
                          "",
                          "address",
                          index
                        )}
                        value={copArr[index].add}
                        placeholder="Address"
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    {/* <div
                      className={`flex flex-col ${
                        option === "Pensioner" && icurrent <= 5 ? "hidden" : ""
                      }`}
                    >
                      <label  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Type
                      </label>
                      <Select
                        placeholder="Type"
                        optionFilterProp="children"
                        className="wow"
                        style={{
                          marginBottom: "1.2rem",
                        }}
                        onChange={() => {
                          handleChange(setType, "tt");
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="middle"
                        options={[
                          {
                            value: "home",
                            label: "Home",
                          },
                          // {
                          //   value: "office",
                          //   label: "Office",
                          // },
                        ]}
                        // options={options}
                      />
                    </div> */}
                    <div className="flex flex-col">
                      <label  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Region No
                      </label>
                      <Select
                        placeholder="Region No"
                        optionFilterProp="children"
                        className="wow"
                        style={{ marginBottom: "1.2rem" }}
                        onChange={handleChange(setRegionNo, "tn", "", index)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="middle"
                        options={copArr[index].aR}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col">
                      <label  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Region Name
                      </label>
                      <input
                        type="text"
                        name=""
                        onChange={handleChange(setRegionName, "tt", "", index)}
                        value={copArr[index].rName}
                        placeholder="Region Name"
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Town/City
                      </label>
                      <Select
                        placeholder="Town/City"
                        optionFilterProp="children"
                        className="wow"
                        style={{ marginBottom: "1.2rem" }}
                        onChange={handleChange(setCity, "tt", "town", index)}
                        size="middle"
                        value={copArr[index].tName}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={copArr[index].tList}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                      >
                        Ward/Village
                      </label>
                      <Select
                        placeholder="Ward/Village"
                        optionFilterProp="children"
                        className="wow"
                        style={{ marginBottom: "1.2rem" }}
                        value={copArr[index].wName}
                        onChange={handleChange(setWard, "tt", "city", index)}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="middle"
                        options={copArr[index].wList}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="ml-4">
                <Checkbox
                  checked={copArr[index].isCurrent}
                  onChange={(e) => checkHandler(e, index)}
                  className={"checkk"}
                >
                  Is current address?
                </Checkbox>
              </div>
            </div>
          );
        })}
        <div
          className="flex justify-center items-center mt-4"
          onClick={() => addAddressHandler("add")}
        >
          <div className="flex justify-center items-center cursor-pointer">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center mr-2" />
            </div>
            <p className="text-primary">Add Address</p>
          </div>
        </div>
        <div
          className={`lg:mt-[3rem] flex mt-6 w-[100%] sm:justify-end justify-center sm:w-[100%]`}
        >
          {/* <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F2F4F8] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            onClick={nextHandler}
            className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default ThirdR;
