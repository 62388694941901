import { useNavigate } from "react-router-dom";
import back5 from "../../imgs/back5.png";

import { useSelector } from "react-redux";
import Main from "./main";

const Index = () => {
  const user = useSelector((state) => state.user);
  const router = useNavigate();

  return (
    <div>
      <div className="md:p-10 md:pl-[7rem] md:pr-[7rem] p-4">
        <div>
          <div className="relative flex items-center mt-4">
            <div className="cursor-pointer">
              <img
                onClick={() => {
                  if (user === "admin") {
                    router("/AdminDashboard/profPage");
                  } else if (user === "superadmin") {
                    router("/superAdminDashboard/profPage");
                  } else {
                    router("/EDashboard/profPage");
                  }
                }}
                className="sm:w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] ml-[1rem] text-[1.2rem]">
              Biometric
            </h2>
          </div>
          <Main />
        </div>
      </div>
    </div>
  );
};

export default Index;
