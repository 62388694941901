import { Checkbox } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import across from "../../../imgs/across.png";
import cross from "../../../imgs/cross.png";
import axios from "axios";
let file;
let firstQ = "";
let question1 = null;
let sett;
const FourthA = () => {
  const appQuestions = useSelector((state) => state.appQuestions);
  const universalPenId = useSelector((state) => state.universalPenId);
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const user = useSelector((state) => state.user);
  const userData = useSelector((state) => state.profileData);
  const token = useSelector((state) => state.token);
  const data3 = useSelector((state) => state.data3);
  const [iimg, setIimg] = useState("");
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [err, setErr] = useState("");
  const [checkBuffer, setCheckBuffer] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const [test, setTest] = useState(false);
  const [test1, setTest1] = useState(0);
  const router = useNavigate();
  const applicationAnswers = useSelector((state) => state.applicationAnswers);
  const Question1 = useSelector((state) => state.Question1);
  const Question2 = useSelector((state) => state.Question2);
  const Question3 = useSelector((state) => state.Question3);
  const Question4 = useSelector((state) => state.Question4);
  const Question5 = useSelector((state) => state.Question5);
  const Question6 = useSelector((state) => state.Question6);
  const Questionx = useSelector((state) => state.Questionx);
  const Questiony = useSelector((state) => state.Questiony);
  const Questiona = useSelector((state) => state.Questiona);
  const Questionb = useSelector((state) => state.Questionb);
  const Questionc = useSelector((state) => state.Questionc);
  const Questiond = useSelector((state) => state.Questiond);
  const Questione = useSelector((state) => state.Questione);
  const Questionz = useSelector((state) => state.Questionz);
  const Question9 = useSelector((state) => state.Question9);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [firstQ]);

  const nextHandler = async () => {
    if (question1 === null) {
      setShow(true);
      setErr("Please agree to the statement");
    } else {
      if (question1 !== null) {
        await Questionz.push(question1);
      }
      if (Question1.length !== 0) {
        await Question1.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Question2.length !== 0) {
        await Question2.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Question3.length !== 0) {
        await Question3.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Question4.length !== 0) {
        await Question4.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Question5.length !== 0) {
        await Question5.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Question6.length !== 0) {
        await Question6.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questionx.length !== 0) {
        await Questionx.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questiony.length !== 0) {
        await Questiony.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Question9.length !== 0) {
        await Question9.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questiona.length !== 0) {
        await Questiona.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questionb.length !== 0) {
        await Questionb.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questionc.length !== 0) {
        await Questionc.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questiond.length !== 0) {
        await Questiond.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questione.length !== 0) {
        await Questione.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (Questionz.length !== 0) {
        await Questionz.map((item) => {
          if (
            item.appquestionId &&
            !item.answers.every((answer) => answer === "")
          ) {
            applicationAnswers.push(item);
          }
        });
      }
      if (
        dash === "/EDashboard/application" ||
        dash === "/AdminDashboard/application" ||
        dash === "/superAdminDashboard/application"
      ) {
        let id;
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/applications/pensioner-recent?pensionerUserId=${universalPenId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            id = data.data[0].program.id;
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/v1/applications/add-by-staff?pensionerUserId=${universalPenId}`,

                {
                  applicationInfo: {
                    application: {
                      pensionprogramId: id,
                    },
                    answers: applicationAnswers,
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((data) => {
                dispatch({
                  type: "appUrlSet",
                  num: data.data.applicationPDF.url,
                });
                dispatch({ type: "ASET", num: 0 });
                dispatch({ type: "answersSet", num: [] });
                dispatch({ type: "question1Set", num: [] });
                dispatch({ type: "question2Set", num: [] });
                dispatch({ type: "question3Set", num: [] });
                dispatch({ type: "question4Set", num: [] });
                dispatch({ type: "question5Set", num: [] });
                dispatch({ type: "question6Set", num: [] });
                dispatch({ type: "questionxSet", num: [] });
                dispatch({ type: "questionySet", num: [] });
                dispatch({ type: "question9Set", num: [] });
                dispatch({ type: "questionaSet", num: [] });
                dispatch({ type: "questionbSet", num: [] });
                dispatch({ type: "questioncSet", num: [] });
                dispatch({ type: "questiondSet", num: [] });
                dispatch({ type: "questioneSet", num: [] });
                dispatch({ type: "questionzSet", num: [] });
                dispatch({ type: "answersSet", num: [] });

                user === "admin"
                  ? router("/AdminDashboard/allApplications")
                  : user === "superadmin"
                  ? router("/superAdminDashboard/allApplications")
                  : router("/EDashboard/allApplications");
              })
              .catch((err) => {
                // dispatch({ type: "question1Set", num: [] });
                // dispatch({ type: "question2Set", num: [] });
                // dispatch({ type: "question3Set", num: [] });
                // dispatch({ type: "question4Set", num: [] });
                // dispatch({ type: "question5Set", num: [] });
                // dispatch({ type: "question6Set", num: [] });
                // dispatch({ type: "questionxSet", num: [] });
                // dispatch({ type: "questionySet", num: [] });
                // dispatch({ type: "question9Set", num: [] });
                // dispatch({ type: "questionaSet", num: [] });
                // dispatch({ type: "questionbSet", num: [] });
                // dispatch({ type: "questioncSet", num: [] });
                // dispatch({ type: "questiondSet", num: [] });
                // dispatch({ type: "questioneSet", num: [] });
                // dispatch({ type: "questionzSet", num: [] });
                // dispatch({ type: "answersSet", num: [] });
                if (err.response.data.code === 409) {
                  setErr("Pending application found for this pensioner");
                  setShow(true);
                } else {
                  setErr(err?.response?.data?.message);
                  setShow(true);
                }
              });
          })
          .catch((err) => {
            // dispatch({ type: "question1Set", num: [] });
            // dispatch({ type: "question2Set", num: [] });
            // dispatch({ type: "question3Set", num: [] });
            // dispatch({ type: "question4Set", num: [] });
            // dispatch({ type: "question5Set", num: [] });
            // dispatch({ type: "question6Set", num: [] });
            // dispatch({ type: "questionxSet", num: [] });
            // dispatch({ type: "questionySet", num: [] });
            // dispatch({ type: "question9Set", num: [] });
            // dispatch({ type: "questionaSet", num: [] });
            // dispatch({ type: "questionbSet", num: [] });
            // dispatch({ type: "questioncSet", num: [] });
            // dispatch({ type: "questiondSet", num: [] });
            // dispatch({ type: "questioneSet", num: [] });
            // dispatch({ type: "questionzSet", num: [] });
            // dispatch({ type: "answersSet", num: [] });
            if (err.response.data.code === 409) {
              setErr("Pending application found for this pensioner");
              setShow(true);
            } else {
              setErr(err?.response?.data?.message);
              setShow(true);
            }
          });
      } else {
        let id;
        if (dash === "/EDashboard/application") {
        } else {
          const tok =
            dash === "/" ? token : dataUser?.data?.tokens?.access?.token;
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/my-recent`,
              {
                headers: {
                  Authorization: `Bearer ${tok}`,
                },
              }
            )
            .then((data) => {
              id = data.data[0].program.id;
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/v1/applications`,
                  {
                    application: {
                      pensionprogramId: id,
                    },
                    answers: applicationAnswers,
                  },

                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((data) => {
                  dispatch({
                    type: "appUrlSet",
                    num: data?.data?.applicationPDF?.url,
                  });
                  dispatch({ type: "question1Set", num: [] });
                  dispatch({ type: "question2Set", num: [] });
                  dispatch({ type: "question3Set", num: [] });
                  dispatch({ type: "question4Set", num: [] });
                  dispatch({ type: "question5Set", num: [] });
                  dispatch({ type: "question6Set", num: [] });
                  dispatch({ type: "questionxSet", num: [] });
                  dispatch({ type: "questionySet", num: [] });
                  dispatch({ type: "question9Set", num: [] });
                  dispatch({ type: "questionaSet", num: [] });
                  dispatch({ type: "questionbSet", num: [] });
                  dispatch({ type: "questioncSet", num: [] });
                  dispatch({ type: "questiondSet", num: [] });
                  dispatch({ type: "questioneSet", num: [] });
                  dispatch({ type: "questionzSet", num: [] });
                  if (dash === "/Dashboard/application") {
                    dispatch({ type: "AINCREMENT" });
                  } else {
                    dispatch({ type: "POP" });
                    dispatch({ type: "ISET", num: 18 });
                  }
                  // router("/EDashboard/allApplications");
                })
                .catch((err) => {
                  // dispatch({ type: "question1Set", num: [] });
                  // dispatch({ type: "question2Set", num: [] });
                  // dispatch({ type: "question3Set", num: [] });
                  // dispatch({ type: "question4Set", num: [] });
                  // dispatch({ type: "question5Set", num: [] });
                  // dispatch({ type: "question6Set", num: [] });
                  // dispatch({ type: "questionxSet", num: [] });
                  // dispatch({ type: "questionySet", num: [] });
                  // dispatch({ type: "question9Set", num: [] });
                  // dispatch({ type: "questionaSet", num: [] });
                  // dispatch({ type: "questionbSet", num: [] });
                  // dispatch({ type: "questioncSet", num: [] });
                  // dispatch({ type: "questiondSet", num: [] });
                  // dispatch({ type: "questioneSet", num: [] });
                  // dispatch({ type: "questionzSet", num: [] });
                  if (err.response.data.code === 409) {
                    setErr("Pending application found for this pensioner");
                    setShow(true);
                  } else {
                    setErr(err?.response?.data?.message);
                    setShow(true);
                  }
                });
            })
            .catch((err) => {
              if (err.response.data.code === 409) {
                setErr("Pending application found for this pensioner");
                setShow(true);
              } else {
                setErr(err?.response?.data?.message);
                setShow(true);
              }
            });
        }
      }

      // if (dash === "/Dashboard/application") {
      //   dispatch({ type: "AINCREMENT" });
      // } else {
      //   dispatch({ type: "POP" });
      //   dispatch({ type: "ISET", num: 20 });
      // }
    }
  };

  useEffect(() => {
    if (appQuestions) {
      firstQ = appQuestions.filter((item) => {
        return item.order === 12;
      });
    }
  }, [appQuestions]);
  useEffect(() => {
    if (
      dash === "/Dashboard/application" ||
      dash === "/EDashboard/application" ||
      dash === "/AdminDashboard/application" ||
      dash === "/superAdminDashboard/application"
    ) {
      if (profileData?.pensionerInfo?.signature !== null) {
        sett = [profileData.pensionerInfo?.signature];
      } else if (profileData.pensionerInfo?.leftIndex !== null) {
        sett = [profileData.pensionerInfo?.leftIndex];
      } else if (profileData.pensionerInfo?.rightIndex !== null) {
        sett = [profileData.pensionerInfo?.rightIndex];
      } else if (profileData.pensionerInfo?.leftThumb !== null) {
        sett = [profileData.pensionerInfo?.leftThumb];
      } else {
        sett = [profileData.pensionerInfo?.rightThumb];
      }
      setTest1(1);
    } else if (userData.length !== 0 && dash !== "/") {
      console.log("sig1", userData);
      if (userData?.pensionerInfo?.signature !== null) {
        sett = [userData.pensionerInfo?.signature];
      } else if (userData.pensionerInfo?.leftIndex !== null) {
        sett = [userData.pensionerInfo?.leftIndex];
      } else if (userData.pensionerInfo?.rightIndex !== null) {
        sett = [userData.pensionerInfo?.rightIndex];
      } else if (userData.pensionerInfo?.leftThumb !== null) {
        sett = [userData.pensionerInfo?.leftThumb];
      } else {
        sett = [userData.pensionerInfo?.rightThumb];
      }
      setTest1(2);
    } else {
      if (data3) {
        console.log("sig2", data3);
        if (data3.data.signature !== null) {
          sett = [data3?.data?.signature];
        } else if (data3.data.leftIndex !== null) {
          sett = [data3.data.leftIndex];
        } else if (data3.data.rightIndex !== null) {
          sett = [data3.data.rightIndex];
        } else if (data3.data.leftThumb !== null) {
          sett = [data3.data.leftThumb];
        } else {
          sett = [data3.data.rightThumb];
        }
        setTest1(3);
      }
      // else {
      //   axios
      //     .get(
      //       `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${dataUser.data.user.id}`,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${token}`,
      //         },
      //       }
      //     )
      //     .then((data) => {

      //       if (data.data.pensionerInfo.signature !== null) {
      //         sett = [data.data.pensionerInfo.signature];
      //       } else if (data.data.pensionerInfo.leftIndex !== null) {
      //         sett = [data.data.pensionerInfo.leftIndex];
      //       } else if (data.data.pensionerInfo.rightIndex !== null) {
      //         sett = [data.data.pensionerInfo.rightIndex];
      //       } else if (data.data.pensionerInfo.leftThumb !== null) {
      //         sett = [data.data.pensionerInfo.leftThumb];
      //       } else {
      //         sett = [data.data.pensionerInfo.rightThumb];
      //       }
      //       setTest1(1);

      //     })
      //     .catch((err) => {

      //     });
      // }
    }
  }, [data3]);

  useEffect(() => {
    if (sett) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
          {
            fileNames: sett,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("sign", data);
          setIimg(data.data[0].url);
        })
        .catch((err) => {});
    }
  }, [test1]);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [iimg]);

  const checkHandler = (e, d) => {
    if (checkBuffer) {
      setCheckBuffer(false);
      question1 = {};
    } else {
      setCheckBuffer(true);
      question1 = {
        appquestionId: firstQ[0].id,
        type: "string",
      };
      question1.answers = [e];
    }
  };

  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col items-center justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        {firstQ !== "" && (
          <form action="" className="w-full mt-4">
            <h2
              className={`mb-4 ${
                dash === "/Dashboard/application"
                  ? "text-[1.1rem]"
                  : "text-[1.1rem] "
              } font-semibold`}
            >
              {firstQ[0].questionText}
            </h2>
            <div className="flex flex-col ml-5 mb-5 mt-5">
              <Checkbox
                className={`${
                  dash === "/Dashboard/application" ? "checkk1" : "checkk"
                }`}
                checked={checkBuffer ? true : false}
                onClick={(e, d) => checkHandler(firstQ[0].choices[0], d)}
              >
                {firstQ[0].choices[0]}
              </Checkbox>
            </div>
            <div>Signature</div>
            {}
            <div className={`mb-4`}>
              <img
                onClick={() => {
                  setIShow(true);
                }}
                className="w-[25rem] h-[13rem]"
                src={iimg}
                alt=""
              />
            </div>
            <div
              className={`${
                iShow ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
            >
              <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                <img
                  className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                  src={cross}
                  onClick={() => setIShow(false)}
                  alt=""
                />
                <img className="w-[45rem] h-[28rem]" src={iimg} alt="" />
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default FourthA;
