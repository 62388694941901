import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";
import AddCashGrant from "../components/CashGrant"

const Account = () => {
  return (
    <div>
      <Nav />
      <AddCashGrant />
      <Whatsapp/>
    </div>
  );
};

export default Account;
