import ListingE from "../components/Dash/Test/Listing/listingE";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const AllEmployee = () => {
  return (
    <div>
      <Nav />
      <ListingE />
      <Whatsapp />
    </div>
  );
};

export default AllEmployee;
