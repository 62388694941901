import Ellipse from "../../../imgs/backg.svg";
import Vegetables from "../../../imgs/Vegetables.png";
import Fruits from "../../../imgs/Fruits.png";
import Meat from "../../../imgs/Meat.png";
import Sea from "../../../imgs/Sea.png";
import back5 from "../../../imgs/back5.png";
import Bakery from "../../../imgs/Bakery.png";
import Dairy from "../../../imgs/Dairy.png";
import Juices from "../../../imgs/Juices.png";
import Snacks from "../../../imgs/Snacks.png";
import img from "../../../imgs/impLogo.png";
import { useNavigate } from "react-router";
import { message } from "antd";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
let items;
const AdminPanel = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const role = useSelector((state) => state.role);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  items = [
    {
      key: "1",
      title: `Vegetables`,
      img: Vegetables,
      items: "47",
      route: "/Inventory-Products",
    },
    {
      key: "2",
      title: `Fruits`,
      img: Fruits,
      items: "32",
      route: "/Inventory-Products",
    },
    {
      key: "3",
      title: `Meat`,
      img: Meat,
      items: "43",
      route: "/Inventory-Products",
    },
    {
      key: "4",
      title: `Sea Food`,
      img: Sea,
      items: "65",
      route: "/Inventory-Products",
    },
    {
      key: "5",
      title: `Bakery`,
      img: Bakery,
      items: "11",
      route: "/Inventory-Products",
    },
    {
      key: "6",
      title: `Dairy`,
      img: Dairy,
      items: "12",
      route: "/Inventory-Products",
    },
    {
      key: "7",
      title: `Juices`,
      img: Juices,
      items: "55",
      route: "/Inventory-Products",
    },
    {
      key: "8",
      title: `Snacks`,
      img: Snacks,
      items: "56",
      route: "/Inventory-Products",
    },
  ];

  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${Ellipse})`,
        backgroundSize: "cover",
        backgroundPosition: "center 10%",
        backgroundPositionY: "-80px",
      }}
    >
      {contextHolder}
      <div className="flex items-center justify-center">
        <div className="md:w-[85%] mt-[3rem] rounded-md w-[95%] p-[4rem] pt-[2rem] mb-16  sm:min-h-[960px] bg-[#F8F5F5] shadow-lg">
          <div
            onClick={() => {
              if (role === "buyer") router("/Inventory-Vendors");
              else router("/Inventory");
            }}
            className="cursor-pointer flex w-full items-center justify-center"
          >
            <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              Categories
            </h2>
          </div>
          <div className="w-full flex flex-wrap mt-[4.5rem] justify-center items-center gap-8 md:gap-x-8 gap-y-14 sm:min-h-[200px]">
            {items?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  if (item.route === "") {
                    Success();
                  } else {
                    dispatch({ type: "Cat", num: item.title });
                    router(item?.route);
                  }
                }}
                className="w-[160px] rounded-md mb-[30px] h-[150px] p-2 cursor-pointer  hover:bg-[#8BD6B5] bg-[#E9F8EE] hover:text-white custom-shadow"
              >
                <div className="w-full h-[80px] flex items-center justify-center rounded-xl">
                  <img src={item?.img} alt="" className="h-[60px] w-[60px]" />
                </div>
                <div className="flex items-center justify-center flex-col w-full">
                  <div className="flex items-center h-full gap-y-3 justify-center w-full flex-col">
                    <div className="text-center h-[20px] ">
                      <h1
                        className="text-black text-[17px] hover:text-white font-bold"
                        style={{ fontFamily: "poppins", fontWeight: "600" }}
                      >
                        {item?.title}
                      </h1>
                      <h1
                        className="text-gray-500 text-[15px] font-bold"
                        style={{ fontFamily: "poppins", fontWeight: "600" }}
                      >
                        {item?.items} Items
                      </h1>
                    </div>
                    {/* <div className="text-center min-h-[60px]">
                      <p
                        className="text-black text-[13px]"
                        style={{ fontFamily: "poppins", fontWeight: "500" }}
                      >
                        {showAllContentIndex === index ||
                        item?.content.length <= 45
                          ? item?.content
                          : `${item?.content.substring(0, 45)}...`}
                        {item?.content.length > 45 && (
                          <p
                            className="text-[#F35929] z-10"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleToggleContent(index);
                            }}
                          >
                            {showAllContentIndex === index
                              ? "Read Less"
                              : "Read More"}
                          </p>
                        )}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
