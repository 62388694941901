const items = [
  { value: "Accounts", label: "Accounts" },
  { value: "Accounting", label: "Accounting" },
  { value: "Administration", label: "Administration" },
  {
    value: "Childcare & Protection Agency",
    label: "Childcare & Protection Agency",
  },
  { value: "Customer service", label: "Customer service" },
  { value: "Domestic Violence", label: "Domestic Violence" },
  { value: "Finance", label: "Finance" },
  {
    value: "Guyana Women's Leadership Institute",
    label: "Guyana Women's Leadership Institute",
  },
  { value: "Human resources", label: "Human resources" },
  { value: "IT", label: "IT" },
  { value: "Legal", label: "Legal" },
  { value: "Management", label: "Management" },
  { value: "Marketing", label: "Marketing" },
  { value: "Minister Secretariat", label: "Minister Secretariat" },
  {
    value: "Office of the Permanent Secretary",
    label: "Office of the Permanent Secretary",
  },
  { value: "Operations", label: "Operations" },
  { value: "Palms", label: "Palms" },
  { value: "Procurement", label: "Procurement" },
  { value: "Production", label: "Production" },
  { value: "Research and development", label: "Research and development" },
  { value: "Sales", label: "Sales" },
  { value: "Special Projects Unit", label: "Special Projects Unit" },
  { value: "Stores", label: "Stores" },
  { value: "Trafficking in Persons", label: "Trafficking in Persons" },
  { value: "Women of Worth", label: "Women of Worth" },
];
export default items;
