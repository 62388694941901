import RegistrationP from "../../viewParts/registrationP";
import IdentificationP from "../../viewParts/identifictionP";
import { useState, useEffect } from "react";
import back5 from "../../../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const EcardView = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const Show = [RegistrationP, IdentificationP];
  const profileData = useSelector((state) => state.profileData);
  const Dis = Show[wow];
  const empId = useSelector((state) => state.universalEmpId);
  const reset = useSelector((state) => state.reset);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();

  useEffect(() => {
    let api;
    if(dash==="/superAdminDashboard/GROEmpCardView" || dash==="/GroAdminDash/Employees/Profile/ViewEmployee")
    api=`v1/users/gro-employee-profile/${empId}`
  else if(dash==="/superAdminDashboard/GROAdminCardView")
  api=`v1/users/gro-admin-profile/${empId}`
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data?.data });
        })
        .catch(() => {

        });

  }, [reset])

  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  return (
    <div className="flex items-center justify-center">
      <div className="md:w-[80%] w-full pt-[5rem] md:pt-[2rem] md:pb-[5rem] p-4">
      {profileData && (
        <div>
          <div className="flex items-center mb-[3rem]">
            <div
              className="cursor-pointer"
            >
              <img onClick={() => {
                if(dash==="/GroAdminDash/Employees/Profile/ViewEmployee")
                {
                  router("/GroAdminDash/Employees/Profile");
                }
                else if(dash==="/superAdminDashboard/GROAdminCardView")
                {
                  router("/superAdminDashboard/GroAdminProfile")
                }
                else if(dash==="/superAdminDashboard/GROEmpCardView")
                {
                  router("/superAdminDashboard/GroempProfile")
                }
              }}
              className="w-[1.7rem]" src={back5} alt="" />
            </div>
            <div className="font-bold sm:text-[1.8rem] text-[1.2rem] ml-[1rem]">
              {profileData.user.roles[0]==="gro-admin"? "View GRO Admin": profileData.user.roles[0]==="gro-employee"?"View GRO employee":"View Employee"}
            </div>
          </div>
          <div className="lg:mb-[2rem] flex mb-[2rem] sm:ml-[0px]  ml-[20px]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${wow === 0 ? "font-semibold" : ""
                  } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                  } `}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${wow === 1 ? "font-semibold" : ""
                  } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                  }  `}
              ></div>
            </div>
          </div>

          <div>
            <Dis />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default EcardView;
