
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/sideBar"
import Main from "./Transaction";

import { useDispatch, useSelector } from "react-redux";
import {MenuUnfoldOutlined } from "@ant-design/icons";

const Index = () => {

  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();



  return (
    <>
      <div className="mobile-screen h-screen flex flex-col">
        {/* Header with hamburger icon */}
        <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
          {/* <MenuOutlined
            className="text-2xl cursor-pointer"
            onClick={toggleSidebar}
          /> */}
          <MenuUnfoldOutlined
            className="text-2xl cursor-pointer"
            onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
          />

          <h1 className="text-xl font-bold">Transactions</h1>
        </header>

        {/* Sidebar with conditional rendering based on showSidebar state */}
        {openSideBar && (
          <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
            <Sidebar />
          </div>
        )}
        <Header />
        {/* Main content area */}
        <div className="flex-grow overflow-y-auto">
          {/* Content area */}
          <main className="flex-grow overflow-y-auto p-4">
            <Main />
          </main>
        </div>
      </div>

      <div className="larger-screen">
        <div className="flex flex-row h-screen p-5 gap-7 bg-[#ebfff3]">
          <aside
            className={`w-1/5 rounded-lg`}
            style={{
              backgroundColor: "white",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
              zIndex: 10, // Ensure sidebar is above the content
            }}
          >
            <Sidebar />
          </aside>
          <div className="flex flex-col w-4/5 gap-5 ">
            <header
              className="h-12 shrink-0 rounded-lg"
            //   style={{
            //     backgroundColor: "white",
            //     boxShadow:
            //       "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
            //   }}
            >
              <Header type={"Recent Transactions"} header={false} route={"/SapMoney/Profile"}/>
            </header>
            <main
              className="flex-grow rounded-lg p-3 overflow-y-scroll"
              style={{
                backgroundColor: "white",
                boxShadow:
                  "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
              }}
            >
              <Main />
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
