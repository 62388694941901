import RegistrationP from "./viewParts/registrationP";
import IdentificationP from "./viewParts/identifictionP";
import { useState } from "react";
import back5 from "../../imgs/back5.png";
import across from "../../imgs/across.png";
import { useLocation, useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import CardFront from "../PensionerCards/CardFront"
import CardBack from "../PensionerCards/CardBack"
import DeathReasons from "../../constants/ReasonDeath";
import Hospitals from "../../constants/Hospitals";
import cross from "../../imgs/cross.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { Select, Input, Checkbox } from "antd";
const apiKey = "AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU";
let filee;
const PcardView = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [wow, setWow] = useState(0);
  const [show1, setShow1] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [reasonOfDeath, setReasonOfDeath] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [village, setVillage] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [category, setCategory] = useState(null);
  const [note, setNote] = useState(null);
  const [regionNo, setRegionNo] = useState(null);
  const [regionName, setRegionName] = useState(null);
  const [otherReason, setOtherReason] = useState(null);
  const [outOfContry, setOutOfCountry] = useState(false);
  const [dateTime, setDateTime] = useState(null);
  const [url, setUrl] = useState(null);
  const [allRegions, setAllRegions] = useState(null);
  const [otherHospital, setOtherHospital] = useState(null);
  const Show = [RegistrationP, IdentificationP];
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const token = useSelector((state) => state.token);
  const Dis = Show[wow];
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${penId}/pensioner-profile-for-gro`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
        dispatch({
          type: "pensionerName",
          num: `${data.data.person.firstName} ${data.data.person.lastName}`,
        });
      })
      .catch(() => {});
  }, [penId, reset]);
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  const categoriesArray = [
    { label: "Doctor", value: "Doctor" },
    { label: "Medical Examiner", value: "Medical Examiner" },
    { label: "Coroner", value: "Coroner" },
  ];
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch(() => {});
  }, []);
  const handlePlaceSelect = (place) => {
    const array = place?.split(",");
    const value = array.length;
    console.log(array);
    console.log(value);

    const cityy = array[0].trim();
    const statee = array[value - 2].trim();
    const countryy = array[value - 1].trim();
    console.log(cityy, statee, countryy);
    setCountry(countryy);
    setCity(cityy);
    setState(statee);
  };
  const getRegionName = (regionNum) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}`
      )
      .then((data) => {
        console.log("dataaaaa", data);
        setRegionName(data.data.data[0]);
        getCities(regionNum, data.data.data[0]);
      })
      .catch(() => {});
  };
  const getCities = (regionNum, regionName) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setCityOptions(wow);
      })
      .catch(() => {});
  };

  const getWards = (regionNum, regionName, city) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}&city=${city}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setWardOptions(wow);
      })
      .catch(() => {});
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    let body;
    if (outOfContry === false) {
      body = {
        reason: reasonOfDeath === "Other" ? otherReason : reasonOfDeath,
        ceasedInfo: {
          diedOutsideCountry: outOfContry,
          medicalPerson: doctor,
          category: category,
          hostpitalName: hospital === "Other" ? otherHospital : hospital,
          region: outOfContry ? "" : regionNo,
          regionName: outOfContry ? "" : regionName,
          city: city,
          village: outOfContry ? "" : village,
        },
      };
    } else {
      body = {
        reason: reasonOfDeath === "Other" ? otherReason : reasonOfDeath,
        ceasedInfo: {
          diedOutsideCountry: outOfContry,
          medicalPerson: doctor,
          category: category,
          city: city,
          country: outOfContry ? country : "",
          state: outOfContry ? state : "",
        },
      };
    }
    if (note !== null && note !== "") {
      body.note = note;
    }
    if (url !== null && url !== "") {
      body.docUrl = url;
    }
    if (dateTime !== null && dateTime !== "") {
      body.deathDate = dateTime;
    }
    if (hospital !== null && hospital !== "" && outOfContry === true) {
      body.ceasedInfo.hostpitalName = hospital;
    }
    console.log(body);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${penId}/pensioner/disable-by-gro`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setReasonOfDeath(null);
        setHospital(null);
        setDoctor("");
        setVillage(null);
        setRegionNo(null);
        setOtherHospital(null);
        setRegionNo(null);
        setRegionName(null);
        setCity(null);
        setState(null);
        setCountry(null);
        setVillage(null);
        setCategory(null);
        setNote("");
        setDateTime("");
        setOtherReason("");
        setOutOfCountry(false);
        setShow1(false);
      })
      .catch((error) => {
        setErr(error.response.data.message);
        setShowErr(true);
      });
  };
  const handleFile = async (e) => {
    filee = e.target.files[0];
    {
      var bodyFormData = new FormData();
      bodyFormData.append("file", filee);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
        })
        .catch(() => {});
    } 
  };
  return (
    <div className="flex items-center justify-center">
      <div className="md:w-[80%] w-full pt-[5rem] md:pt-[2rem] md:pb-[5rem] p-4">
        <div>
          <div className="mb-[3rem] relative flex justify-between items-center">
            <div
              onClick={() =>
                router(
                  location.pathname === "/GroAdminDash/Pensioners/profile"
                    ? "/GroAdminDash/Pensioners"
                    : "/GRO"
                )
              }
              className="absolute top-[1] cursor-pointer sm:left-[-4%] left-[1%]"
            >
              <img className="w-[1.7rem]" src={back5} alt="" />
            </div>
            <div className="font-bold sm:text-[1.8rem] text-[1.2rem] sm:ml-0 ml-[2rem]">
              View Pensioner
            </div>
            <button
              onClick={() => {
                if(profileData?.user?.status==="ceased")
                {
                  setErr("pensioner is already Deactivated")
                  setShowErr(true)
                }
                else
                setShow1(true);
              }}
              className={`text-white bg-[#DC3545] pl-5 pr-5 pt-2 pb-2 rounded-lg cursor-pointer`}
            >
              Deactivate
            </button>
          </div>
          <div className="lg:mb-[2rem] flex mb-[2rem]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                  wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-6">
            {/* <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
              <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                <DigitalCard />
              </div>
              <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                <DigitalCardBack />
              </div>
            </div> */}
            {profileData.user && (
              <div className="">
                {profileData?.user?.roles[0] === "pensioner" && (
                  // <div className="sm:flex-row flex flex-col h-[25rem] w-full">
                  <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
                    <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                      <CardFront />
                    </div>
                    <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                      <CardBack />
                    </div>
                  </div>
                  // </div>
                )}
              </div>
            )}
            <Dis />
          </div>
        </div>
        <div
          className={`${
            show1 ? "" : "hidden"
          } w-full h-full overflow-auto fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <form onSubmit={submitHandler} className="h-[95%]">
            <div className="relative sm:w-[40rem] rounded-xl w-[22rem] pb-4 pt-4 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-3 right-4 cursor-pointer"
                onClick={() => {
                  setShow1(false);
                  setReasonOfDeath(null);
                  setHospital(null);
                  setDoctor("");
                  setOtherHospital(null);
                  setRegionNo(null);
                  setRegionName(null);
                  setCity(null);
                  setState(null);
                  setCountry(null);
                  setVillage(null);
                  setVillage(null);
                  setRegionNo(null);
                  setCategory(null);
                  setNote("");
                  setOtherReason("");
                  setDateTime("");
                }}
              >
                <img className="scale-[0.8]" src={cross} />
              </div>
              <div className="flex flex-col sm:w-[80%] w-[90%]">
                <label
                  htmlFor="reasonOfDeath"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                >
                  Reason of death <span className="text-red-600">*</span>
                </label>
                <Select
                  size="large"
                  id="reasonOfDeath"
                  name="reasonOfDeath"
                  placeholder="Select a reason"
                  required
                  onChange={(val) => setReasonOfDeath(val)}
                  value={reasonOfDeath}
                  options={DeathReasons}
                  showSearch
                  className={`lg:text-[1.1rem] w-[100%] bg-white  rounded-[0.3rem] p-1 mb-1`}
                />
              </div>
              {reasonOfDeath === "Other" && (
                <div className="flex flex-col  sm:w-[80%] w-[90%]">
                  <Input
                    type="text"
                    id="otherReason"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    name="otherReason"
                    placeholder="Specify other reason"
                    className={` lg:text-[1.1rem]  w-[100%] bg-white  rounded-[0.3rem] p-1 mb-1`}
                  />
                </div>
              )}
              <div className="flex flex-col mt-2  sm:w-[80%] w-[90%]">
                <Checkbox
                  checked={outOfContry}
                  className={"checkk2"}
                  onChange={(e) => {
                    setRegionNo(null);
                    setRegionName(null);
                    setCity(null);
                    setState(null);
                    setCountry(null);
                    setVillage(null);
                    setOutOfCountry(e.target.checked);
                    if (outOfContry === true) {
                      setHospital(null);
                    } else {
                      setHospital("Other");
                    }
                  }}
                >
                  Is pensioner died outside the country?
                </Checkbox>
              </div>
              <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row  justify-between  sm:w-[80%] w-[90%]">
                <div className="flex flex-col sm:w-[45%] w-full">
                  <label
                    className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                  >
                    Date & Time of Death <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    placeholder="Date of Death"
                    value={dateTime}
                    onChange={(e) => setDateTime(e?.target?.value)}
                    className={`lg:text-[1.1rem] w-full bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-1 mb-1`}
                  />
                </div>
                <div className="flex flex-col  sm:w-[45%] w-full">
                  <label
                    htmlFor="fileInput"
                    className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                  >
                    Death Certificate:
                  </label>
                  <input
                    type="file"
                    id="file-doc"
                    accept="application/msword, application/vnd.ms-excel, application/pdf"
                    onInput={handleFile}
                    className={`lg:text-[1.1rem] w-full bg-white border mt-[4px] h-[40px] rounded-[0.3rem] p-1 mb-1`}
                  ></input>
                </div>
              </div>
              <div className="flex-col  sm:w-[80%] w-[90%]">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1 flex"
                >
                  Hospital:
                </label>
                {outOfContry === false && (
                  <Select
                    size="large"
                    id="hospital"
                    name="Hospital"
                    placeholder="Select Hospital"
                    required
                    onChange={(val) => setHospital(val)}
                    value={hospital}
                    options={Hospitals}
                    showSearch
                    className={`lg:text-[1.1rem] w-[100%]  bg-white border-[#C6CFE5] rounded-[0.3rem] p-1 mb-1`}
                  />
                )}
                {hospital === "Other" && (
                  <div className="flex flex-col w-[100%]">
                    <Input
                      type="text"
                      id="otherHospital"
                      value={otherHospital}
                      onChange={(e) => setOtherHospital(e.target.value)}
                      name="otherHospital"
                      placeholder="Enter Hospital"
                      className={` lg:text-[1.1rem]  w-[100%] bg-white  rounded-[0.3rem] p-1 mb-1`}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row  justify-between  sm:w-[80%] w-[90%]">
                <div className="flex flex-col sm:w-[45%] w-full">
                  <label
                    className="text-[#596F96] text-[0.88rem] font-semibold mb-[10px]"
                  >
                    Medical person:
                  </label>
                  <input
                    id="doctor"
                    name="doctor"
                    placeholder="Enter medical person"
                    onChange={(e) => {
                      setDoctor(e.target.value);
                    }}
                    value={doctor}
                    className={`lg:text-[1.1rem] w-[100%] h-[40px] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-1 mb-1`}
                  />
                </div>
                <div className="flex flex-col sm:w-[45%] w-full">
                  <label
                    className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                  >
                    Category:
                  </label>
                  <Select
                    size="large"
                    id="Category"
                    name="Category"
                    placeholder="Select Category"
                    required
                    onChange={(val) => setCategory(val)}
                    value={category}
                    options={categoriesArray}
                    showSearch
                    className={`lg:text-[1.1rem] w-[100%] bg-white  rounded-[0.3rem] p-1 mb-1`}
                  />
                </div>
              </div>
              {outOfContry === true && (
                <div className="flex flex-col sm:w-[80%] w-[90%]">
                  <label
                    htmlFor="location"
                    className="text-[#596F96] text-[0.88rem] text-left font-semibold mb-1"
                  >
                    Country:
                  </label>
                  <div className="flex p-2 flex-row mt-2 mb-2 border-black-500 border-2 rounded-[0.6rem] w-full justify-between">
                    <Autocomplete
                      className="h-6 w-full border-none"
                      apiKey={apiKey}
                      placeholder="Enter Other Location"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      onPlaceSelected={(place) => {
                        console.log(place);
                        handlePlaceSelect(place.formatted_address);
                      }}
                    />
                    <SearchOutlined className="text-gray-400 text-[20px]" />
                  </div>
                </div>
              )}
              {outOfContry === true && (
                <div className="flex flex-col sm:flex-row justify-between sm:w-[80%] w-[90%]">
                  <div className="flex flex-col sm:w-[45%] w-full">
                    <label
                      className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                    >
                      State:
                    </label>
                    <div className="flex p-2 flex-row mt-2 mb-2 border-black-500 border-2 rounded-[0.6rem] w-full justify-between">
                      <Autocomplete
                        className="h-full w-full"
                        apiKey={apiKey}
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        onPlaceSelected={(place) => {
                          console.log(place);
                          handlePlaceSelect(place.formatted_address);
                        }}
                      />
                      <SearchOutlined className="text-gray-400 text-[20px]" />
                    </div>
                  </div>
                  <div className="flex flex-col sm:w-[45%] w-full">
                    <label
                      className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                    >
                      City:
                    </label>
                    <div className="flex p-2 flex-row mt-2 mb-2 border-black-500 border-2 rounded-[0.6rem] w-full justify-between">
                      <Autocomplete
                        className="h-full w-full"
                        apiKey={apiKey}
                        placeholder="Village/Ward"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        onPlaceSelected={(place) => {
                          console.log(place);
                          handlePlaceSelect(place.formatted_address);
                        }}
                      />
                      <SearchOutlined className="text-gray-400 text-[20px]" />
                    </div>
                  </div>
                </div>
              )}
              {outOfContry === false && (
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row m-0 justify-between sm:w-[80%] w-[90%]">
                  <div className="flex flex-col sm:w-[45%] w-full">
                    <label
                      className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                    >
                      Region:
                    </label>
                    <Select
                      placeholder="Region No"
                      optionFilterProp="children"
                      value={regionNo}
                      onChange={(value) => {
                        setRegionNo(value);
                        getRegionName(value);
                      }}
                      // onChange={handleChange(setRegionNo, "tn")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="large"
                      // key={index}
                      options={allRegions}
                      className={`lg:text-[1.1rem] w-[100%] bg-white  rounded-[0.3rem] p-1 mb-0`}
                    />
                  </div>
                  <div className="flex flex-col sm:w-[45%] w-full">
                    <label
                      className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                    >
                      Region Name:
                    </label>
                    <input
                      type="text"
                      name=""
                      
                      // onChange={(e) => handleInputChange("regionName", e.target.value)}
                      value={regionName}
                      placeholder="Region Name"
                      // key={index}
                      className={`lg:text-[1.1rem] w-[100%] bg-white border mt-[4px] h-[40px] rounded-[0.3rem] p-1 mb-1`}
                    />
                  </div>
                </div>
              )}
              {outOfContry === false && (
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row  justify-between sm:w-[80%] w-[90%]">
                  <div className="flex flex-col sm:w-[45%] w-full">
                    <label
                      className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                    >
                      City:
                    </label>
                    <Select
                      placeholder="City"
                      optionFilterProp="children"
                      className={`lg:text-[1.1rem] w-[100%] bg-white  rounded-[0.3rem] p-1 mb-1`}
                      onChange={(value) => {
                        setCity(value);
                        getWards(regionNo, regionName, value);
                      }}
                      value={city}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="large"
                      options={cityOptions}
                    />
                  </div>
                  <div className="flex flex-col sm:w-[45%] w-full">
                    <label
                      className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                    >
                      Village/Ward:
                    </label>
                    <Select
                      placeholder="Ward/Village"
                      optionFilterProp="children"
                      className={`lg:text-[1.1rem] w-[100%] bg-white  rounded-[0.3rem] p-1 mb-1`}
                      value={village}
                      onChange={(value) => setVillage(value)}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="large"
                      options={wardOptions}
                    />
                  </div>
                </div>
              )}
              {/* {region === "other" && (
            <div className="flex flex-col md:flex-row p-2 lg:flex-row xl:flex-row mt-2 mb-2 border-black-500 border-2 rounded-[0.6rem]  justify-between w-[80%]">
              <Autocomplete
              className="h-6 w-full border-none"
                apiKey={apiKey}
                placeholder="Enter Other Location"
                value={otherLocation}
                onChange={(e)=> setOtherLocation(e.target.value)}
                onPlaceSelected={(place) => {
                  setOtherLocation(place.formatted_address)
                }}
              />
              <img src={eye} alt="search"/>
            </div>
          )} */}
              <div className="flex flex-col sm:w-[80%] w-[90%]">
                <label
                  htmlFor="notes"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-1"
                >
                  Notes:
                </label>
                <textarea
                  id="notes"
                  name="notes"
                  value={note}
                  onChange={(e) => setNote(e?.target?.value)}
                  placeholder="Add notes here"
                  className={` lg:text-[1.1rem] w-full bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-1 mb-1`}
                />
              </div>
              <div className="w-full flex item-center justify-center">
                <button
                  type="button"
                  onClick={() => {
                    setReasonOfDeath(null);
                    setHospital(null);
                    setDoctor("");
                    setVillage(null);
                    setRegionNo(null);
                    setOtherHospital(null);
                    setRegionNo(null);
                    setRegionName(null);
                    setCity(null);
                    setState(null);
                    setCountry(null);
                    setVillage(null);
                    setCategory(null);
                    setNote("");
                    setDateTime("");
                    setOtherReason("");
                    setOutOfCountry(false);
                    setShow1(false);
                  }}
                  className={`text-white md:w-[100px] bg-[#DC3545] pl-5 pr-5 pt-2 mt-5 mb-2 mr-4 pb-2 rounded-lg cursor-pointer`}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  // onClick={() => setShow1(false)}
                  className={`text-white md:w-[100px] bg-green-500 pl-5 pr-5 pt-2 mt-5 mb-2 pb-2 rounded-lg cursor-pointer`}
                >
                  Deactivate
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          className={`${
            showErr ? "" : "hidden"
          } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShowErr(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShowErr(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-[#DE2827] text-white w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PcardView;
