import React from "react";
import facegif from "../imgs/face.gif";
const FaceVerficationLoaderModal = () => {
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 flex-col">
        <div className="flex items-center justify-center">
          <img src={facegif} alt="...loader" className="h-22 w-22 rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default FaceVerficationLoaderModal;
