import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoModal from "../../MHSSS Accounting/outstanding/PersonTransaction/Vouchers/infoModal";
import ContactInfo from "./contactInfo";
import { useSelector } from "react-redux";
const TransactionCard = ({ data }) => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [content, setContent] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const user = useSelector((state) => state.user);
  const dataUser = useSelector((state) => state.dataUser);
  const Div = ({ title, content }) => {
    return (
      <div className="flex justify-between">
        <span className="text-sm text-gray-700 text-md flex items-center">
          {title}:{" "}
          <p
            className={`${
              title === "Voucher Transaction ID"
                ? "cursor-pointer ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                : "hidden"
            }`}
            onClick={() => {
              setContent(
                "Auto generated transaction ID when Merchant redeemed pensioner’s vouchers."
              );
              setShowInfo(true);
            }}
          >
            i
          </p>{" "}
        </span>{" "}
        <span
          className={`text-md font-semibold ${
            title === "Status" ? "text-primary" : "text-gray-900"
          }`}
        >
          {title === "Amount"
            ? `$${content}`
            : title === "Program Name"
            ? content === "senior-citizen-Pension"
              ? "Senior Citizen Pension"
              : content
            : content}
        </span>
      </div>
    );
  };
  return (
    <div className="bg-[#F4F5FE] shadow-md rounded-md p-1 ">
      <div className="sm:p-6 ">
        <div className="flex flex-col gap-2 relative" id="printableArea">
          <p
            className={`${
              user === "pensioner"
                ? "text-[0.9rem] text-primary font-bold cursor-pointer ml-auto"
                : "absolute top-0 right-0 text-[0.8rem] text-primary font-bold cursor-pointer"
            }`}
            onClick={() => {
              if (user === "pensioner") {
                router("/Dashboard/transaction_history/detail", {
                  state: {
                    id: data?.id,
                    selectedTransactionsVouchers: data,
                  },
                });
              } else {
                router("/Merchant/Transactions/detail", {
                  state: {
                    id: data?.id,
                    selectedTransactionsVouchers: data,
                  },
                });
              }
            }}
          >
            View Details
          </p>

          {dash !== "/Dashboard/transaction_history" && (
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover border-2 border-primary p-1"
                  src={data?.user?.person?.image}
                  alt="Profile"
                />
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center space-x-2">
                  <p className="text-md font-semibold text-gray-900 truncate">
                    {data?.user?.person?.firstName +
                      " " +
                      data?.user?.person?.lastName}
                  </p>
                </div>
                <p className="text-md text-gray-500 truncate "></p>
                <p
                  className={`text-sm text-gray-400 truncate
                `}
                >
                  GID: {data?.user?.gid}
                </p>
              </div>
              {/* <div className="inline-flex items-center text-base text-primary text-[0.9rem] font-semibold cursor-pointer">
            Edit
          </div> */}
            </div>
          )}

          <Div
            title={"Date / Time"}
            content={moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
          />
          <Div title={"Amount"} content={data?.totalAmount} />
          {/* 
          {dash !== "/Dashboard/transaction_history" && (
            <Div
              title={"Paid To"}
              content={
                data?.user?.person?.firstName +
                " " +
                data?.user?.person?.lastName
              }
            />
          )} */}

          <Div
            title={"Program "}
            content={
              data?.pensionbook?.pensionprogram?.name ===
              "senior-citizen-Pension"
                ? "Senior Citizen Pension"
                : data?.pensionbook?.pensionprogram?.name
            }
          />
          {/* <Div
            title={"Voucher Month"}
            content={getMonthName(data?.coupon?.month)}
          /> */}

          <div className="flex justify-between space-y-2">
            <div
              className={`${
                user !== "pensioner"
                  ? "text-gray-600 flex items-center text-[0.8rem]"
                  : "mt-2"
              }`}
            >
              Voucher transaction ID :{" "}
              <span
                onClick={() => {
                  setContent(
                    "Auto generated transaction ID when Merchant redeemed pensioner’s vouchers."
                  );
                  setShowInfo(true);
                }}
                className="ml-1 w-4 h-4 bg-primary text-white rounded-full inline-flex items-center justify-center"
              >
                i
              </span>
            </div>
            <div
              className={`
          ${
            user !== "pensioner"
              ? "font-semibold flex items-center capitalize text-[0.8rem]"
              : "text-sm font-bold flex items-center capitalize"
          } `}
            >
              {data?.transactionId}
            </div>
          </div>
          {/* <Div title={"Voucher Transaction ID"} content={data?.transactionId} /> */}
          <Div title={"Booklet #"} content={data?.pensionbook?.bookletNo} />
          {/* <Div title={"Transaction Type"} content={data?.paymentMethod} /> */}

          {/* <Div title={"Status"} content={data?.coupon?.status} /> */}
          <div className={`${user === "pensioner" ? "hidden" : ""}`}>
            <Div
              title={
                dataUser?.data?.merchuser?.isGovtAgency ? "HQ" : "Store/HQ"
              }
              content={
                data?.merchstore !== null
                  ? data?.merchstore?.customStoreName
                  : data?.merchuser?.merchcompany?.name
              }
            />
          </div>
        </div>
      </div>

      <div
        className={`${
          dash === "/Dashboard/transaction_history"
            ? "px-6 py-3 bg-white flex items-center"
            : "hidden"
        }`}
      >
        <div>
          <h4 className="text-[1rem] font-semibold mb-3">{`Store/HQ`}</h4>
          {/* <p>
            {data?.merchuser?.merchperson?.firstName}{" "}
            {data?.merchuser?.merchperson?.lastName}
          </p> */}
          <div className="flex items-center">
            <img
              src={
                data?.merchstore !== null
                  ? data?.merchstore?.image
                  : data?.merchuser?.merchcompany?.logo
              }
              alt="Profile"
              className="w-16 h-16  border-2 border-primary rounded-full mr-auto "
            />
            <div className="flex flex-col ml-3">
              <p className="text-gray-800 font-semibold text-lg">
                {data?.merchstore !== null
                  ? data?.merchstore?.customStoreName
                  : data?.merchuser?.merchcompany?.name}
              </p>
              <p
                onClick={() => {
                  setShowContact(true);
                  if (data?.merchstore === null) {
                    // setEmail(data?.merchheadquarter?.emails);
                    // setPhone(data?.merchheadquarter?.contacts);

                    //Primary Email
                    // setEmail(...email,data?.merchheadquarter?.primaryEmail)
                    setEmail([
                      data?.merchheadquarter?.primaryEmail,
                      ...(data?.merchheadquarter?.emails || []),
                    ]);
                    setPhone([
                      data?.merchheadquarter?.primaryPhone,
                      ...(data?.merchheadquarter?.contacts || []),
                    ]);
                  } else {
                    setEmail([
                      data?.merchstore?.primaryEmail,
                      ...(data?.merchstore?.emails || []),
                    ]);
                    setPhone([
                      data?.merchstore?.primaryPhone,
                      ...(data?.merchstore?.contacts || []),
                    ]);
                    // setEmail(data?.merchstore?.emails);
                    // setPhone(data?.merchstore?.phones);
                    // setEmail(...email, data?.merchstore?.emails);
                  }
                }}
                className="text-primary font-bold cursor-pointer"
              >
                Contact Info
              </p>
              {/* <div className="flex items-center">
                <span className="mr-2">
                  <MailFilled className="text-gray-500" />
                </span>
                <p>{data?.merchuser?.merchcompany?.emails?.[0]}</p>
              </div> */}

              {/* <div className="flex items-center">
                <p className="mr-2">
                  <PhoneFilled className="text-primary" />
                </p>
                <p>+92 334 5645454</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {showInfo && <InfoModal content={content} setShow={setShowInfo} />}
      {showContact && (
        <ContactInfo setShow={setShowContact} email={email} phone={phone} />
      )}
    </div>
  );
};

export default TransactionCard;
