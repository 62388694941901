import React from 'react'
import Banking from "../components/MHSSS Accounting/balanceandBanking/bankingIndex"

const DashB = () => {
  return (
    <div>
     <Banking/>
    </div>
  )
}

export default DashB