import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import Nationalties from "../../../constants/country";
import { DatePicker, Select } from "antd";
import across from "../../../imgs/across.png";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import { DeleteFilled, UploadOutlined } from "@ant-design/icons";
let file;
let rr;
const SelectLocation = () => {
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const veriSelectedDoc = useSelector((state) => state.veriSelectedDoc);
  const profileData = useSelector((state) => state?.profileData);
  const [num, setNum] = useState("");
  const [country, setCountry] = useState(null);
  const [issue, setIssue] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [urlFront, setUrlFront] = useState("");
  const [urlBack, setUrlBack] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  rr = Nationalties?.map((item) => {
    return {
      lable: `${item}`,
      value: `${item}`,
    };
  });
  const imageHandlerr = async (setDis, setUrl, e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDis(base641);
    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    if (veriSelectedDoc?.docIDValue) setNum(veriSelectedDoc?.docIDValue);
    if (veriSelectedDoc?.countryIssued)
      setCountry(veriSelectedDoc?.countryIssued);
    if (veriSelectedDoc?.issuedDate) setIssue(veriSelectedDoc?.issuedDate);
    if (veriSelectedDoc?.expiryDate) setExpiry(veriSelectedDoc?.expiryDate);
    if (veriSelectedDoc?.docimages?.length > 0) {
      setDisplay(veriSelectedDoc?.docimages[0].imageUrl);
    }
    if (veriSelectedDoc?.docimages?.length > 1) {
      setDisplay1(veriSelectedDoc?.docimages[1].imageUrl);
    }
    const filteredDocs = profileData.verificationDocs.filter(
      (doc) => doc.type === veriSelectedDoc.type
    );
    if (filteredDocs[0]?.docimages?.length > 0) {
      setUrlFront(filteredDocs[0]?.docimages[0].imageUrl);
    }
    if (filteredDocs[0]?.docimages?.length > 1) {
      setUrlBack(filteredDocs[0]?.docimages[1].imageUrl);
    }
    console.log("test", filteredDocs);
  }, []);
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      if (event) {
        let wow;
        if (event.$M <= 9) {
          if (event.$D <= 9) {
            wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
          } else {
            wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
          }
        } else {
          if (event.$D <= 9) {
            wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
          } else {
            wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
          }
        }
        setState(wow);
      }
    } else {
      setState(event.target.value);
    }
  };
  useEffect(() => {
    console.log("testF", urlFront, "testB", urlBack);
  }, [urlBack, urlFront]);
  const UpdateHandler = (e) => {
    let body = [];
    const data = {
      type: `${veriSelectedDoc?.type}`,
      docIDName: `${veriSelectedDoc?.docIDName}`,
      docIDValue: `${num}`,
      docImages: [],
    };
    data.docImages[0] = {
      type: "front image",
      imageUrl: `${urlFront}`,
    };
    if (
      veriSelectedDoc?.type === "government id" ||
      veriSelectedDoc?.type === "passport" ||
      veriSelectedDoc?.type === "one card" ||
      veriSelectedDoc?.type === "driver license"
    ) {
      data.countryIssued = `${country}`;
    }
    if (
      veriSelectedDoc?.type === "one card" ||
      veriSelectedDoc?.type === "birth certificate"
    ) {
      data.issuedDate = `${issue}`;
    }
    if (
      veriSelectedDoc?.type === "passport" ||
      veriSelectedDoc?.type === "one card" ||
      veriSelectedDoc?.type === "driver license"
    ) {
      data.expiryDate = `${expiry}`;
    }
    if (
      veriSelectedDoc?.type === "government id" ||
      veriSelectedDoc?.type === "passport"
    )
      data.docImages[1] = {
        type: "back image",
        imageUrl: `${urlBack}`,
      };
    body.push(data);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/verification-docs/update-by-staff?userId=${profileData.user.id}`,
        {
          verificationDocs: body,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        router("/pensionerVerification/Profile/VerifyIdentification");
      })
      .catch((err) => {
        if (err.response.data.message === '"expiryDate" must be a valid date') {
          setErr("Please verify if expiration date is available on card");
        } else if (
          err.response.data.message === '"imageUrl" is not allowed to be empty'
        ) {
          setErr("Please Upload required images of the document.");
        } else {
          setErr(err.response.data.message);
        }

        setShow(true);
      });
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader header={true} title={"Update Identification"} route={-1} />
      <div className="flex justify-center bg-[#F4F2F2] p-4  sm:ml-[2rem] sm:mr-[2rem] ml-2 mr-2 relative">
        <div
          className={`rounded-sm md::mx-20 mx-2 flex mt-[4rem] items-center flex-col gap-2 bg-white m-5 p-6 md:w-[50%] sm:w-[90%] w-full`}
        >
          <h2 className="w-full font-semibold capitalize">
            {veriSelectedDoc?.type}
          </h2>
          <input
            type="text"
            onChange={(e) => {
              setNum(e.target.value);
            }}
            value={num}
            placeholder={`${veriSelectedDoc?.type} #`}
            className=" lg:text-[1.1rem] w-[100%] bg-[#F8F8F8] rounded-[0.3rem] p-2 mb-2"
          />
          {(veriSelectedDoc?.type === "government id" ||
            veriSelectedDoc?.type === "passport" ||
            veriSelectedDoc?.type === "one card" ||
            veriSelectedDoc?.type === "driver license") && (
            <Select
              placeholder="Country Issued"
              optionFilterProp="children"
              size="middle"
              className="wow"
              onChange={(value) => setCountry(value)}
              options={rr}
              value={country}
            />
          )}
          {(veriSelectedDoc?.type === "one card" ||
            veriSelectedDoc?.type === "birth certificate") && (
            <DatePicker
              placeholder="Issue Date"
              style={{
                marginBottom: "1.2rem",
                backgroundColor: "#F8F8F8",
                border: "none",
              }}
              className="bg-gray-100"
              onChange={handleChange(setIssue, "tt")}
              value={issue ? dayjs(issue) : null}
            />
          )}
          {(veriSelectedDoc?.type === "passport" ||
            veriSelectedDoc?.type === "one card" ||
            veriSelectedDoc?.type === "driver license") && (
            <DatePicker
              placeholder="Expiry Date"
              style={{
                marginBottom: "1.2rem",
                backgroundColor: "#F8F8F8",
                border: "none",
              }}
              className="bg-gray-100"
              onChange={handleChange(setExpiry, "tt")}
              value={expiry ? dayjs(expiry) : null}
            />
          )}
          <div className="w-full flex gap-1">
            <div
              className={`flex items-center justify-center rounded-lg text-center sm:w-[50%] h-[11rem] relative`}
            >
              {display ? (
                <>
                  <img src={display} alt="Uploaded" className="w-full h-full" />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setUrlFront("");
                      setDisplay("");
                    }}
                  >
                    <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                  </button>
                </>
              ) : (
                <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                  <div className="flex items-center w-full justify-center flex-col">
                    <UploadOutlined className="text-4xl text-primary cursor-pointer " />
                    <label
                      htmlFor="file-upload"
                      className="text-5xl text-primary w-full cursor-pointer flex flex-col gap-2 items-center justify-center"
                    >
                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) =>
                          imageHandlerr(setDisplay, setUrlFront, e)
                        }
                      />
                      <div className="mt-4 w-[80%] text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                        Upload Front
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </div>
            {(veriSelectedDoc?.type === "government id" ||
              veriSelectedDoc?.type === "passport") && (
              <div
                className={`flex items-center justify-center rounded-lg text-center sm:w-[50%] h-[11rem] relative`}
              >
                {display1 ? (
                  <>
                    <img
                      src={display1}
                      alt="Uploaded"
                      className="w-full h-full"
                    />
                    <button
                      className="absolute bottom-1 right-1"
                      onClick={() => {
                        setUrlBack("");
                        setDisplay1("");
                      }}
                    >
                      <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                    </button>
                  </>
                ) : (
                  <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                    <div className="flex items-center w-full justify-center flex-col">
                      <UploadOutlined className="text-4xl text-primary cursor-pointer " />
                      <label
                        htmlFor="file-upload"
                        className="text-5xl text-primary w-full cursor-pointer flex flex-col gap-2 items-center justify-center"
                      >
                        <input
                          id="file-upload"
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={(e) =>
                            imageHandlerr(setDisplay1, setUrlBack, e)
                          }
                        />
                        <div className="mt-4 w-[80%] text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                          Upload Back
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            UpdateHandler();
            // router("/pensionerVerification/Profile/VerifyIdentification");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Update
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
