import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import eye from "../../../imgs/eyee.png";
import across from "../../../imgs/across.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../imgs/logo12.png";
let check = "";
const SecondR = () => {
  const route = useNavigate();
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const verificationStart = useSelector((state) => state.verificationStart);
  const [prompt, setPrompt] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [err, setErr] = useState("");
  const [email, setEmail] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const option = useSelector((state) => state.option);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const [verification, setVerification] = useState(false);

  const CheckPensionerByGID = (gid) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-user-by-gid`, {
        params: {
          gid: gid,
        },
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        const response = data.data;
        if (response?.person === null) {
          localStorage.setItem("empAddpen", true);
          dispatch({ type: "universalPenSet", num: data?.data?.user?.id });
          dispatch({ type: "setProfileData", num: data.data });
          dispatch({ type: "popDataSet", num: data?.data?.user });
          dispatch({ type: "IINCREMENT" });
          return;
        }
        setVerification(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const fetchPenioners = (params) => {
    const api = `v1/users/list-pensioners-for-verification?gid=${gid}&programName=${selectedProgram}`;
    const tok = dataUser?.data?.tokens?.access?.token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        params,
      })
      .then((res) => {
        const data = res?.data?.rows?.[0];
        dispatch({
          type: "VERI_SELECTED_PENSIONER",
          num: { data, id: data.id },
        });
        dispatch({
          type: "PEN_VER_QUEUE",
          num:
            data?.verificationqueues?.length > 0
              ? data?.verificationqueues[0]
              : [],
        });
        dispatch({
          type: "PEN_VER_STATUS",
          num:
            data?.verificationStatus === null
              ? "Verification Pending"
              : data?.verificationStatus?.toggle,
        });
        route("/pensionerVerification/Profile");
      })
      .catch((err) => {});
  };

  const nextHandler = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let body;
    if (option === "Pensioner") {
      if (email && prompt === "") {
        body = {
          role: option.toLowerCase(),
          gid: gid,
          email: email,
          password: password,
        };
        check = "email";
      } else if (prompt && email === "") {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        body = {
          role: option.toLowerCase(),
          gid: gid,
          countryCode: `+${countryCode}`,
          phoneNo: number,
          password: password,
        };
        check = "prompt";
      } else if (email && prompt) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        body = {
          role: option.toLowerCase(),
          gid: gid,
          countryCode: `+${countryCode}`,
          phoneNo: number,
          email: email,
          password: password,
        };
        check = "email & prompt";
      } else {
        body = {
          role: option.toLowerCase(),
          gid: gid,
          password: password,
        };
        check = "gid";
      }
      if (dash === "/EDashboard/addPensioner") {
        delete body.role;
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/auth/pensioner-signup`,
            {
              user: body,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            localStorage.setItem("empAddpen", true);
            dispatch({ type: "universalPenSet", num: data?.data?.user?.id });
            dispatch({ type: "setProfileData", num: data.data });
            dispatch({ type: "popDataSet", num: data?.data?.user });
            dispatch({ type: "IINCREMENT" });
            // dispatch({ type: "addPensionerSet", num: data.data });
            // dispatch({
            //   type: "tokenSet",
            //   num: `${data.data.tokens.access.token}`,
            // });
            // setIsLoading(false);
          })
          .catch((err) => {
            if (gid === "" || gid === null) {
              setErr("GID is Required");
              setShow(true);
            } else {
              if (verificationStart) {
                if (err?.response?.data?.message === "gid already exists") {
                  CheckPensionerByGID(gid);
                  // setVerification(true);
                  return;
                }
              }

              setErr(err.response.data.message);
            }
            setShow(true);
          });
      } else {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/signup`, body)
          .then((data) => {
            dispatch({ type: "popDataSet", num: data.data.user });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            if (check === "email") {
              dispatch({ type: "veri" });
            } else if (check === "prompt") {
              dispatch({ type: "veri2" });
            } else if (check === "email & prompt") {
              dispatch({ type: "checkSet", num: "both" });
              dispatch({ type: "veri2" });
            } else if (check === "gid") {
              dispatch({ type: "IINCREMENT" });
            }
          })
          .catch((err) => {
            if (gid === "" || gid === null) {
              setErr("GID is Required");
              setShow(true);
            } else {
              setErr(err.response.data.message);
            }
            setShow(true);
            // setIsLoading(false);
          });
      }
    } else if (prompt) {
      let apii;
      if (dash === "/superAdminDashboard/addAdmin")
        apii = "/v1/auth/admin-signup";
      else if (dash === "/superAdminDashboard/addGroAdmin")
        apii = "/v1/auth/gro-admin-signup";
      else if (dash === "/GroAdminDash/AddGroEmp")
        apii = "/v1/auth/gro-employee-signup";
      if (
        dash === "/superAdminDashboard/addAdmin" ||
        dash === "/superAdminDashboard/addGroAdmin" ||
        dash === "/GroAdminDash/AddGroEmp"
      ) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        const user = {
          countryCode: `+${countryCode}`,
          phoneNo: number,
          email: email,
          password: password,
        };
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}${apii}`,
            { user },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            dispatch({ type: "popDataSet", num: data.data.user });
            // dispatch({
            //   type: "tokenSet",
            //   num: `${data.data.tokens.access.token}`,
            // });
            // dispatch({ type: "veri2" });
            dispatch({ type: "IINCREMENT" });
            dispatch({ type: "checkSet", num: "both" });
            // setIsLoading(false);
          })
          .catch((err) => {
            setErr(err.response.data.message);

            setShow(true);
            // setIsLoading(false);
          });
      } else {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/signup`, {
            role: option.toLowerCase(),
            countryCode: `+${countryCode}`,
            phoneNo: number,
            email: email,
            password: password,
          })
          .then((data) => {
            dispatch({ type: "popDataSet", num: data.data.user });
            dispatch({
              type: "tokenSet",
              num: `${data.data.tokens.access.token}`,
            });
            dispatch({ type: "veri2" });
            dispatch({ type: "checkSet", num: "both" });
            // setIsLoading(false);
          })
          .catch((err) => {
            // setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    } else if (prompt === "") {
      setErr("please enter a valid phone number");
      setShow(true);
      // setIsLoading(false);
    }
  };

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  const passShowHandler = () => {
    // console.log("checkingaaaaaaaa")
    // const wow = document.getElementById("Pass11");
    // if (wow.type === "password") {
    //   wow.type = "text";
    // } else {
    //   wow.type = "password";
    // }
    setShowPass(!showPass);
  };
  return (
    <div>
      <form>
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="mb-4">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  {option === "Pensioner" ? "Phone" : "Phone *"}
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={handleChange(setPrompt, "tt")}
                  international
                  countryCallingCodeEditable={false}
                  required={option === "Employee" ? true : false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
              </div>
              <div
                className={`${
                  option === "Employee" ? "hidden" : ""
                } flex flex-col`}
              >
                <label
                  htmlFor="gid"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  GID *
                </label>
                <input
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  type="text"
                  name="input2"
                  id="gid"
                  // onChange={handleChange(setGid)}
                  onChange={(event) => {
                    const enteredValue = event.target.value;
                    const isValid =
                      enteredValue === "" ||
                      /^[a-zA-Z0-9]+$/.test(enteredValue);
                    if (isValid) {
                      // If it's a valid number, update the state with the value
                      setGid(enteredValue);
                    } else {
                      // If it's not a valid number, display an error message
                      setErr("GID must be a valid alphanumeric value.");
                      setShow(true);
                    }
                  }}
                  value={gid}
                  placeholder="GID"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="input1"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  {option === "Pensioner" ? "Email" : "Email *"}
                </label>
                <input
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                  type="text"
                  placeholder="Email"
                  onChange={handleChange(setEmail)}
                  value={email}
                  id="input1"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col justify-center">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Password *
                </label>
                <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                  <input
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    id="Pass11"
                    onChange={handleChange(setPassword)}
                    value={password}
                    required
                    className="w-[100%]"
                  ></input>
                  <img
                    className="w-[1.6rem] cursor-pointer"
                    onClick={passShowHandler}
                    src={eye}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className={`${
                show ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
            >
              <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
                <div
                  className="absolute top-[-11%] left-[42%]"
                  onClick={() => setShow(false)}
                >
                  <img src={across} alt="" />
                </div>
                <p className="text-center mt-[3rem] mb-5">{err}</p>
                <button
                  onClick={() => setShow(false)}
                  type="button"
                  className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:justify-end justify-center sm:w-[85%]">
          <button
            // type='button'
            onClick={nextHandler}
            className="relative justify-center flex cursor-pointer mt-10 bg-primary pt-2 pb-2 pl-[2rem] pr-[2rem] text-white rounded-xl"
          >
            {/* <Spin
              style={{
                display: `${isLoading ? "" : "none"}`,
                marginRight: "1rem",
              }}
              indicator={antIcon}
            /> */}
            <span>Next</span>
            {/* <div
              className={`w-[100%] h-[100%] ${
                isLoading ? "" : "hidden"
              } absolute top-0 bg-slate-400 opacity-40 rounded-2xl`}
            ></div> */}
          </button>
        </div>
      </form>

      <div
        className={`${
          verification ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] flex items-center p-4 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20" />
          <p className="text-center text-[0.9rem] mt-3 mb-5 w-[85%]">
            This pensioner already exists in our system. Do you want to view /
            update this pensioner?
          </p>
          <div className="flex">
            <button
              onClick={() => setVerification(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-12 pr-12 text-white bg-[#DE2827] w-[60%]"
            >
              No
            </button>
            <button
              onClick={() => {
                // CheckPensionerByGID(gid);
                fetchPenioners();
              }}
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-12 pr-12 text-white bg-primary w-[60%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondR;
