import React, { useState, useEffect } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import across from "../../../imgs/across.png";
import logo from "../../../imgs/logo12.png";
const SelectLocation = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.profileData);
  const veriSelectedPensioner = useSelector(
    (state) => state.veriSelectedPensioner
  );
  const penVerStatus = useSelector((state) => state.penVerStatus);
  const reset = useSelector((state) => state.reset);
  const [data, setData] = useState([]);
  const dataUser = useSelector((state) => state.dataUser);
  const [note1, setNote1] = useState("");
  const [type, setType] = useState(true);
  const [updatedData, setUpdatedData] = useState([]);
  const [show, setShow] = useState(false);
  const [showVer, setShowVer] = useState(false);
  const [err, setErr] = useState("");
  const fetchImageUrls = async (fileNames) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
        { fileNames },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      );
      return response.data.map((imgData) => imgData.url);
    } catch (error) {
      console.error("Error fetching image URLs", error);
      return [];
    }
  };

  const updateDataWithImageUrls = async () => {
    const updatedDataPromises = data.map(async (card) => {
      if (card.docimages && card.docimages.length > 0) {
        const fileNames = card.docimages.map((img) => img.imageUrl);
        const urls = await fetchImageUrls(fileNames);
        const updatedDocImages = card.docimages.map((img, index) => ({
          ...img,
          imageUrl: urls[index],
        }));
        return { ...card, docimages: updatedDocImages };
      }
      return card;
    });

    const updatedData = await Promise.all(updatedDataPromises);
    setUpdatedData(updatedData);
  };

  useEffect(() => {
    updateDataWithImageUrls();
  }, [data]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${veriSelectedPensioner?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch(() => {});
  }, [reset]);
  useEffect(() => {
    setData(profileData?.verificationDocs);
  }, [profileData]);
  useEffect(() => {
    console.log("doccc", data);
  }, [data]);
  const Verification = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${veriSelectedPensioner?.id}/change-pensioner-verification-status`,
        {
          verificationStatus: type
            ? "Identification Detail Verified"
            : "Verification Rejected",
          note: note1,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShowVer(false);
        router("/pensionerVerification/Profile");
        if (type)
          dispatch({
            type: "PEN_VER_STATUS",
            num: "Identification Detail Verified",
          });
        else
          dispatch({
            type: "PEN_VER_STATUS",
            num: "Verification Rejected",
          });
      })
      .catch((err) => {
        setShowVer(false);
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader
        header={true}
        title={"Verify Identification"}
        route={"/pensionerVerification/Profile"}
      />
      <div className="flex justify-center bg-[#F4F2F2] p-4 sm:ml-[2rem] sm:mr-[2rem] ml-2 mr-2 relative">
        {profileData?.verificationDocs?.length < 7 && (
          <button
            type="button"
            onClick={() => {
              router("/pensionerVerification/Profile/AddIdentification");
            }}
            className={`lg:text-[1rem] border border-primary text-primary font-semibold absolute top-[14px] right-[14px]  bg-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Add new
          </button>
        )}
        <div
          className={`rounded-sm md:mx-20 mx-4 grid md:grid-cols-2 grid-cols-1 mt-[4rem] items-center justify-between gap-4 bg-white m-5 p-6 md:w-[50%] sm:w-[90%] w-full`}
        >
          {updatedData.map((card, index) => (
            <div
              key={index}
              className="flex relative pb-12 justify-between w-full gap-4 bg-gray-100 p-4 mb-4 flex-col rounded-sm shadow-md"
            >
              <div className="flex flex-col h-[8rem] bg-white p-2">
                <div className="w-full flex items-center mb-2 font-semibold text-primary justify-between">
                  <p className="capitalize">{card?.type}</p>
                  <p>{card?.docIDValue}</p>
                </div>
                {card?.countryIssued && (
                  <div className="w-full flex items-center mb-2 font-semibold justify-between">
                    <p>Country issued</p>
                    <p>{card.countryIssued}</p>
                  </div>
                )}
                {card?.issuedDate && (
                  <div className="w-full flex items-center mb-2 font-semibold justify-between">
                    <p>Date Issued</p>
                    <p>{card.issuedDate}</p>
                  </div>
                )}
                {card?.expiryDate && (
                  <div className="w-full flex items-center mb-2 font-semibold justify-between">
                    <p>Expiry Date</p>
                    <p>{card.expiryDate}</p>
                  </div>
                )}
              </div>
              <div className="w-full flex gap-1">
                {card?.docimages?.map((docImage, idx) => (
                  <img
                    key={idx}
                    src={docImage?.imageUrl}
                    alt={`Document Image ${idx + 1}`}
                    className="w-[50%] h-[8rem] mb-2"
                  />
                ))}
              </div>
              <p
                onClick={() => {
                  dispatch({ type: "VERI_SELECTED_Doc", num: card });
                  router("/pensionerVerification/Profile/UpdateIdentification");
                }}
                className="font-semibold cursor-pointer absolute bottom-[8px] right-[8px] text-primary"
              >
                Update & Verify
              </p>
            </div>
          ))}
        </div>
      </div>
      {penVerStatus === "Basic Detail Verified" && (
        <div
          className={`flex w-full items-center justify-center mt-6 gap-2 mb-5`}
        >
          <button
            type="button"
            onClick={() => {
              setShowVer(true);
              setType(true);
            }}
            className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 `}
          >
            Verified
          </button>
          <button
            type="button"
            onClick={() => {
              // router("/pensionerVerification/Profile");
              setShowVer(true);
              setType(false);
            }}
            className={`lg:text-[1rem] bg-gradient-to-tr  bg-red-600 text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Unverified
          </button>
        </div>
      )}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 z-[100] bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showVer ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowVer(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          {type ? (
            <p className="text-center mt-6 mb-5 font-semibold">
              Are you sure to mark this pensioner as verified?
            </p>
          ) : (
            <p className="text-center mt-6 mb-5 font-semibold">
              Are you sure to reject this Pensioner?
            </p>
          )}
          <textarea
            type="text"
            name=""
            onChange={(e) => setNote1(e.target.value)}
            value={note1}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => setShowVer(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                Verification();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
