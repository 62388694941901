import React from "react";
import { Layout, theme } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../../dash/sideBar";
import Ewallet from "./eWallet";
import AddWallet from "../eWalletCash/addEwallet";
import back from "../../../../../imgs/backIcon.png";
import { useNavigate } from "react-router-dom";
// import Card from "./Card";
const { Sider } = Layout;
const App = () => {
  const dispatch = useDispatch();
  const aiCurrent = useSelector((state) => state.aiCurrent);
  let Show = [Ewallet, AddWallet];
  const Dis = Show[aiCurrent];
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const router = useNavigate();
  return (
    <Layout
      className=" h-[100vh] rounded-lg"
      style={{
        padding: 35,
        paddingRight: 10,
        margin: 0,
        background: "#D9EFED",
      }}
    >
      <Sider
        breakpoint="lg"
        width={280}
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        className="flex flex-col justify-between h-full"
        style={{
          borderRadius: borderRadiusLG,
          width: "20px",
          height: "screen",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        }}
      >
        <Sidebar />
      </Sider>

      <Layout
        className="flex flex-col items-center justify-center overflow-auto"
        style={{
          background: "#D9EFED",
        }}
      >
        <div className="w-[95%] h-[100vh] overflow-auto">
          <div className="flex items-center w-[95%]">
            <div className="flex items-center justify-center">
              <h2 className="mb-4 text-xl font-bold flex items-center justify-center">
                <img
                  onClick={() => {
                    if (aiCurrent === 1) dispatch({ type: "ISET", num: 0 });
                    else
                    router("/MHSSS/payoutstandings");
                  }}
                  src={back}
                  className="w-3 h-3 mr-2 cursor-pointer"
                  alt="back"
                />{" "}
                {aiCurrent===1?"Add  E-wallet Detail":"e-wallet Payment"}
              </h2>
            </div>
          </div>
          <Dis />
        </div>
      </Layout>
    </Layout>
  );
};
export default App;
