import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const PreFilled = () => {
  const data2 = useSelector((state) => state.data2);
  const data1 = useSelector((state) => state.data1);
  const popData = useSelector((state) => state.popData);
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const location = useLocation();
  const dash = location.pathname;
  const [test, setTest] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/addresses`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        
        dispatch({ type: "data2Set", num: data });
        if (dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application") {
                    setData(profileData.person.addresses);
        } else {
                    setData(data.data);
        }
      })
      .catch(() => {
        
      });
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data, data1]);
  return (
    <div>
      <div className="w-full min-h-[25rem] pb-5 pt-5 bg-[#F0F0F0] flex flex-col items-center justify-center sm:pl-10 sm:pr-10 pl-4 pr-4 rounded-2xl">
        <div className="relative mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
          <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
            Name
          </p>
          <p className="text-[#adafad] text-[0.9rem]">
            {dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application"
              ? `${profileData.person.firstName} ${profileData.person.lastName}`
              : dash === "/Dashboard/application"
              ? `${dataUser.data.user.person.firstName} ${dataUser.data.user.person.lastName}`
              : `${data1.firstName} ${data1.lastName}`}
          </p>
        </div>
        {}
        <div className="relative mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
          <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
            Government ID
          </p>
          <p className="text-[#adafad] text-[0.9rem]">
            {dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application"
              ? `${profileData?.user?.gid}`
              : dash === "/Dashboard/application"
              ? `${dataUser?.data?.user?.gid}`
              : `${
                  popData?.gid ? `${popData?.gid}` : `${dataUser?.data?.user?.gid}`
                }`}
          </p>
        </div>
        <div className="relative mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
          <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
            DOB
          </p>

          <p className="text-[#adafad] text-[0.9rem]">
            {dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application" || 
            dash === "/Dashboard/application"
              ? `${profileData.person.dateOfBirth}`
              : `${data1?.dateOfBirth}`}
          </p>
        </div>
        {/* <div className="relative mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
        <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
          Phone Number
        </p>
        <p className="text-[#adafad] text-[0.9rem]">
          {popData.phoneNo === undefined
            ? "---"
            : `${popData.countryCode}${popData.phoneNo}`}
        </p>
      </div> */}
        <div className="relative mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
          <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
            Gender
          </p>
          {}

          <p className="text-[#adafad] text-[0.9rem]">
            {dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application" ||
            dash === "/Dashboard/application"
              ? `${dataUser?.data?.user?.person?.gender}`
              : `${data1?.gender}`}
          </p>
        </div>
        <div className="relative mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
          <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
            Address
          </p>
          {}
          {data.length !== 0 && (
            <p className="text-[#adafad] text-[0.9rem]">
              <div>
                {`${data[0]?.lot}, ${data[0]?.address}, ${data[0]?.ward}, ${data[0]?.city}`}
              </div>
              {/* {
            ? `${item.lot} ${profileData.person.addresses[0].address}, ${profileData.person.addresses[0].city}, ${profileData.person.addresses[0].ward}, ${profileData.person.addresses[0].regionNo}, ${profileData.person.addresses[0].regionName}`
            : `${data2.data[0].lot}, ${data2.data[0].address}, ${data2.data[0].address.city}, ${data2.data[0].address.ward}, ${data2.data[0].address.regionNo}, ${data2.data[0].address.regionName}`} */}
            </p>
          )}
        </div>
        <div className="relative sm:mb-8 w-[100%] p-3 border-[1px] border-primary rounded-xl">
          <p className="absolute bg-[#F0F0F0] pl-2 pr-2 text-primary text-[0.8rem] top-[-22%] left-[3%]">
            Region
          </p>
          {}
          {data2 && (
            <p
              className={`${
                dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application" ? "hidden" : ""
              } text-[#adafad] text-[0.9rem]`}
            >
              {`${data2.data[0]?.regionNo} , ${data2.data[0]?.regionName}`}
            </p>
          )}

          {(dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application") && (
            <div>
              {profileData.person.addresses.length !== 0 && (
                <p
                  className={`${
                    dash === "/EDashboard/application" || dash === "/superAdminDashboard/application" || dash === "/AdminDashboard/application" ? "" : "hidden"
                  } text-[#adafad] text-[0.9rem]`}
                >
                  {profileData?.person?.addresses[0]?.regionName}
                </p>
              )}
            </div>
          )}
          {/* {data2 && (
          <p className="text-[#adafad] text-[0.9rem]">
            {dash === "/EDashboard/application"
              ? `${profileData.person.addresses[0].regionName}`
              : `${data2.data[0].regionName}`}
          </p>
        )} */}
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={() => dispatch({ type: "AINCREMENT" })}
          className={`lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PreFilled;
