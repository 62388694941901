import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoModal from "../../MHSSS Accounting/outstanding/PersonTransaction/Vouchers/infoModal";
import { useDispatch, useSelector } from "react-redux";
import mhs from "../../../imgs/mhss.png";
const TransactionCard = ({ data }) => {
  const dispatch = useDispatch();
  console.log(data);
  const router = useNavigate();
  const location = useLocation();
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);
  console.log(isGovernmentAgency);

  const dash = location.pathname;
  const [content, setContent] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  // const [showContact, setShowContact] = useState(false);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const user = useSelector((state) => state.user);
  console.log(phone, email);
  const Div = ({ title, content }) => {
    return (
      <div className="flex justify-between">
        <span className="text-sm text-gray-700 text-md flex items-center">
          {title}:{" "}
          <p
            className={`${
              title === "Payment ID"
                ? "cursor-pointer ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                : "hidden"
            }`}
            onClick={() => {
              setContent(
                "Auto generated ID from MHSSS payment to the merchant."
              );
              setShowInfo(true);
            }}
          >
            i
          </p>{" "}
        </span>{" "}
        <span
          className={`text-md font-semibold capitalize 
          
          ${
            title === "Status" || title === "Amount"
              ? "text-primary"
              : "text-gray-900"
          }`}
        >
          {title === "Amount"
            ? `$${content}`
            : title === "Program Name"
            ? content === "senior-citizen-Pension"
              ? "Senior Citizen Pension"
              : content
            : content}
        </span>
      </div>
    );
  };
  return (
    <div className="bg-[#F4F5FE] shadow-md rounded-md p-1 ">
      <div className="  p-6 ">
        <div className="flex flex-col gap-2 relative" id="printableArea">
          <p
            className={`${
              user === "pensioner"
                ? "text-[0.9rem] text-primary font-bold cursor-pointer ml-auto"
                : "absolute top-0 right-0 text-[0.8rem] text-primary font-bold cursor-pointer"
            }`}
            onClick={() => {
              if (isGovernmentAgency) {
                dispatch({
                  type: "DEPOSIT_MONEY_DATA",
                  num: {
                    method: data?.method,
                    reqDate:
                      data?.bankbalancerequest !== null
                        ? data?.bankbalancerequest?.createdAt
                        : null,
                    paymentId: data?.paymentId,
                    transactionData: data?.createdAt,
                    bankReceiptImg: data?.bankReceiptImg,
                    bankReceiptNo: data?.bankReceiptNo,
                    chequeImg: data?.chequeImg,
                    chequeNo: data?.chequeNo,
                    amount: data?.amount,
                  },
                });
              }

              router("/Merchant/MHSSS/Payment/detail", {
                state: {
                  id: data?.id,
                  selectedTransactionsVouchers: data,
                },
              });
            }}
          >
            View Details
          </p>
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover "
                src={mhs}
                alt="Profile"
              />
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center space-x-2">
                <p className="text-md font-semibold text-gray-900 truncate">
                  MHSSS
                </p>
              </div>
              <p className="text-md text-gray-500 truncate "></p>
            </div>
            {/* <div className="inline-flex items-center text-base text-primary text-[0.9rem] font-semibold cursor-pointer">
            Edit
          </div> */}
          </div>
          {/* <p className="text-sm text-gray-500">
            {moment(data?.createdAt).format("DD MMM, YYYY")},{" "}
            {moment(data?.createdAt).format("h:mm a")}
          </p> */}
          {isGovernmentAgency && (
            <Div
              title={"Requested Date / Time"}
              content={
                moment(
                  data?.bankbalancerequest !== null
                    ? data?.bankbalancerequest?.createdAt
                    : ""
                ).isValid()
                  ? moment(data?.bankbalancerequest?.createdAt).format(
                      "MM/DD/YYYY hh:mm A"
                    )
                  : "-----"
              }
            />
          )}

          {!isGovernmentAgency && (
            <Div
              title={" Date / Time"}
              content={
                moment(data ? data?.createdAt : "").isValid()
                  ? moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")
                  : "-----"
              }
            />
          )}

          {/* {
              moment(
                data?.merchtransactionpayment
                  ? data?.merchtransactionpayment?.createdAt
                  : ""
              ).isValid()
                ? moment(data?.merchtransactionpayment?.createdAt).format(
                    "MM/DD/YYYY hh:mm A"
                  )
                : "-----"
            } */}
          <Div
            title={"Amount"}
            content={
              data?.merchtransactionpayment?.amountPaid
                ? data?.merchtransactionpayment?.amountPaid +
                  data?.merchtransactionpayment?.feePaid
                : isGovernmentAgency
                ? data?.amount
                : data?.totalAmount
            }
          />

          <Div
            title={"Payment ID"}
            content={
              data?.merchtransactionpayment?.transactionPaymentId
                ? data?.merchtransactionpayment?.transactionPaymentId
                : isGovernmentAgency
                ? data?.paymentId
                : "-----"
            }
          />

          <div className="flex justify-between">
            <span className="text-sm text-gray-700 text-md flex items-center">
              Status:{" "}
            </span>{" "}
            <span
              className={`text-md font-bold capitalize 
          
          ${
            data?.mhsssPayStatus === "success" || isGovernmentAgency
              ? "text-primary"
              : data?.mhsssPayStatus === "pending"
              ? "text-yellow-500"
              : "text-red-500"
          }`}
            >
              {data?.mhsssPayStatus === "success" || isGovernmentAgency
                ? "Received"
                : data?.mhsssPayStatus}
            </span>
          </div>
          {isGovernmentAgency && (
            <Div
              title={"Transaction Date / Time"}
              content={
                moment(data ? data?.createdAt : "").isValid()
                  ? moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")
                  : "-----"
              }
            />
          )}

          <div
            className={`${
              user === "pensioner" || isGovernmentAgency ? "hidden" : ""
            }`}
          >
            <Div
              title={"Store/HQ"}
              content={
                data?.merchstore !== null
                  ? data?.merchstore?.customStoreName
                  : data?.merchuser?.merchcompany?.name
              }
            />
          </div>
        </div>
      </div>

      <div
        className={`${
          dash === "/Dashboard/transaction_history"
            ? "px-6 py-3 bg-white flex items-center"
            : "hidden"
        }`}
      >
        <div>
          <h4 className="text-[1rem] font-semibold mb-3">Store/HQ</h4>
          {/* <p>
            {data?.merchuser?.merchperson?.firstName}{" "}
            {data?.merchuser?.merchperson?.lastName}
          </p> */}
          <div className="flex items-center">
            <img
              src={
                data?.merchstore !== null
                  ? data?.merchstore?.image
                  : data?.merchuser?.merchcompany?.logo
              }
              alt="Profile"
              className="w-16 h-16  border-2 border-primary rounded-full mr-auto "
            />
            <div className="flex flex-col ml-3">
              <p className="text-gray-800 font-semibold text-lg">
                {data?.merchstore !== null
                  ? data?.merchstore?.customStoreName
                  : data?.merchuser?.merchcompany?.name}
              </p>
              <p
                onClick={() => {
                  // setShowContact(true);
                  if (data?.merchstore === null) {
                    setEmail(data?.merchheadquarter?.emails);
                    setPhone(data?.merchheadquarter?.contacts);
                  } else {
                    setEmail(data?.merchstore?.emails);
                    setPhone(data?.merchstore?.phones);
                  }
                }}
                className="text-primary font-bold cursor-pointer"
              >
                Contact Info
              </p>
              {/* <div className="flex items-center">
                <span className="mr-2">
                  <MailFilled className="text-gray-500" />
                </span>
                <p>{data?.merchuser?.merchcompany?.emails?.[0]}</p>
              </div> */}

              {/* <div className="flex items-center">
                <p className="mr-2">
                  <PhoneFilled className="text-primary" />
                </p>
                <p>+92 334 5645454</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {showInfo && <InfoModal content={content} setShow={setShowInfo} />}
    </div>
  );
};

export default TransactionCard;
