// import { useSelector } from "react-redux";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
const QuestionVerification = ({
  setValue,
  value,
  setShowErrModal,
  err,
  setErr,
  uuid,
  setShowConfirmModal,
  setShowSuccessModal,
}) => {
  const depositMoney = useSelector(
    (state) => state.pensionerSenderTypeVerification
  );
  const sapPaymentSelectedPerson = useSelector(
    (state) => state.sapPaymentSelectedPerson
  );
  const dispatch = useDispatch();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const token = useSelector((state) => state.token);
  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [reset, setReset] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/security-questions/pick-random-user-question?userId=${sapPaymentSelectedPerson?.fromId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setQuestion(data?.data?.question);
        setQuestionId(data?.data?.id);
      })
      .catch((err) => {
        setValue("");
        if (!err) {
          setErr("An error occured");
          setShowErrModal(true);
          return;
        }
        setErr(err?.response?.data?.message);
        setShowErrModal(true);
      });
  }, [reset]);

  const Submit = () => {
    const api = depositMoney
      ? `v1/sap-wallet-info/merch-verify-pensioner/verify-security-question?pensionerUserId=${sapPaymentSelectedPerson?.fromId}`
      : `v1/users/merch-verify-security-question?userId=${sapPaymentSelectedPerson?.fromId}`;

    if (answer === "") {
      setErr("Please Provide answer for the question.");
      setShowErr(true);
    } else {
      setValue("");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            securityquestionId: questionId,
            answer: answer,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          if (depositMoney) {
            dispatch({
              type: "UUID",
              num: data?.data?.tempUUID,
            });
            setErr("Pensioner Successfully Verified ");
            setShowConfirmModal(true);
            return;
          }
          setShowSuccessModal(true);
          setErr("Pensioner Successfully Verified ");
          setAnswer("");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShowErrModal(true);
        });
    }
  };

  return (
    <div
      tabindex="-1"
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-slate-500/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="relative w-full max-w-md max-h-full bg-white rounded-2xl flex items-center flex-col p-4 ">
        <button
          type="button"
          className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
          onClick={() => {
            setValue("");
          }}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <h2 className="mb-[1rem] text-[1.5rem] font-bold">
          Security Questions
        </h2>

        <div className="bg-gray-50  w-full   flex flex-col gap-1 p-3 ">
          <label className="text-gray-500 text-[0.88rem] font-semibold mb-1">
            {question}
          </label>
          <input
            type="text"
            name=""
            id=""
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
            value={answer}
            placeholder="Answer"
            className="lg:text-[1.1rem] w-full bg-white border-none rounded-[0.3rem] p-2 mb-4"
          />
        </div>
        <button
          onClick={Submit}
          className="w-full bg-primary text-white p-3 text-medium  sm:text-[0.9rem]  text-[1.1rem] rounded-lg mt-4"
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default QuestionVerification;
