import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import TransactionListing from "../../../Merchant/Transactions/TransactionListing";
const ListingHistory = () => {
  const router = useNavigate();

  return (
    <div>
      <div
        className="p-10 sm:pl-[7rem] sm:pr-[7rem] pl-2 pr-2"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="flex items-center ">
            <div className="cursor-pointer">
              <img
                onClick={() => router("/Dashboard")}
                className="w-[1.7rem]"
                src={back5}
                alt=""
              />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] text-[1.2rem] ml-4">
              Transactions
            </h2>
          </div>
        </div>
      </div>
      <div className="sm:px-14 px-2">
        <TransactionListing />
      </div>
    </div>
  );
};

export default ListingHistory;
