import Merchant from "../components/MerchantNearBy"
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";
const ListingPage = () => {
  return (
    <div>
      <Nav />
      <Merchant />
      <Whatsapp/>
    </div>
  );
};

export default ListingPage;
