import Nav from "../components/Nav/nav";
import Program from "../components/Programs/index";
import Whatsapp from "../Whatsapp/whatsapp";

const Programs = () => {
  return (
    <div>
      <Nav />
      <Program />
      <Whatsapp/>
    </div>
  );
};

export default Programs;
