import React from 'react'
import SearchDiv from './searchDiv'

const main = () => {
  
  return (
    <div>
        <div className="flex flex-col gap-4">
            <SearchDiv/>
            
            </div>
    </div>
  )
}

export default main