import Page from "./tripsListing";
import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  return (
    <>
      <div
        className="sm:p-10 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={`flex justify-between items-center mt-4`}>
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() => {
                  user === "employee"
                    ? router("/Maintenance")
                    : user === "admin"
                    ? router("//Maintenance")
                    : user === "superadmin"
                    ? router("/Maintenance")
                    : router("/Maintenance");
                }}
                className="w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Recent Trips
              </h2>
            </div>
            {/* <button
              onClick={() => {
                router("/Maintenance-Add-Vehicle");
              }}
              className={`text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Add Vehicle
            </button> */}
          </div>

          {/* <div className="flex flex-wrap justify-between sm:ml-0 place-items-center gap-4 mt-[3rem] mb-8">
            <div className="w-[13rem] h-[5.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Total Vehicles
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">180</p>
              </div>
              <div>
                <img className="w-[2.1rem] mt-4" src={e2} alt="" />
              </div>
            </div>
            <div className="w-[13rem] h-[5.5rem] bg-[#E8E8E8] rounded-md p-3 flex justify-between">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Active Vehicles
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">120</p>
              </div>
              <div>
                <img className="w-[2.2rem] mt-4" src={e3} alt="" />
              </div>
            </div>
            <div className="w-[13rem] h-[5.5rem] bg-[#E8E8E8] rounded-md p-3 flex  justify-between">
              <div>
                <h2 className="text-[0.8rem] font-semibold text-[#797979]">
                  Inactive Vehicles
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">60</p>
              </div>
              <div>
                <img className="w-[2rem] mt-5" src={e1} alt="" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Page />
    </>
  );
};

export default Listing;
