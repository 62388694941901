import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import thumb from "../../../../../imgs/Thumb.png";
import { useNavigate } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import logo from "../../../../../imgs/mhsss_Accounting-logo.png";
import axios from "axios";
let file;
const Main = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const totaPayment = useSelector((state) => state.totalPayment);
  const [show, setShow] = useState(false);
  const [chequeNumber, setChequeNumber] = useState("");
  const [image, setImage] = useState(null);
  const selectedTransactionsVouchers = useSelector(
    (state) => state.selectedTransactionsVouchers
  );
  const selectedPaymentMethod = useSelector(
    (state) => state.selectedPaymentMethod
  );
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [display, setDisplay] = useState(null);
  const [note, setNote] = useState("");
  const Pay = () => {
    const body = {
      merchtransactionId: selectedTransactionsVouchers?.[0]?.merchtransactionId,
      paymentMethod: selectedPaymentMethod,
      chequeImg: image,
      chequeNo: chequeNumber,
      note: note,
    };
    if (note === "") {
      delete body.note;
    }
    if (chequeNumber === "") {
      delete body.chequeNo;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transaction-payments`,

        body,

        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "AFTER_PAYMENT_DATA", num: data.data });
        setShow(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowError(true);
      });
  };

  const imageHandler = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=paymentRecipet`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setDisplay(base641);
        setImage(data.data.url);
      })
      .catch((err) => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDeleteImage = () => {
    setImage(null);
    setDisplay(null);
  };
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/MHSSS/paymentReceipt");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);
  return (
    <div className="flex flex-col w-full overflow-hidden min-h-[82vh] p-4 relative rounded-md bg-white">
      <div className="p-2 mt-2">
        <h1 className="font-semibold">
          Total Amount to be Paid{" "}
          <span className="ml-4 font-bold text-primary text-[16px]">{`$${totaPayment}`}</span>
        </h1>
      </div>
      <h1 className="font-bold p-2 mb-1">
        Upload Cheque Detail Below
        {/* <span className="font-normal ">{subTitle}</span> */}
      </h1>
      <div className="w-full bg-[#D9EFED] flex-col rounded-lg gap-6 flex p-4">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className={`border-dashed border-2 bg-gray-200 border-primary flex items-center justify-center rounded-lg text-center w-[18rem] h-[11rem] relative`}
        >
          {display ? (
            <>
              <img src={display} alt="Uploaded" className="w-full h-full" />
              <button
                className="absolute bottom-1 right-1"
                onClick={handleDeleteImage}
              >
                <DeleteFilled className="text-2xl text-primary cursor-pointer " />
              </button>
            </>
          ) : (
            <div className="w-[80%] flex flex-col gap-1 flex items-center justify-center">
              {/* <UploadOutlined className="text-3xl text-primary cursor-pointer " /> */}
              <p>Drag & Drop Your File(s) here to upload </p>
              <p>Or</p>
              <label className="mt-2 w-[80%] cursor-pointer inline-block bg-primary text-white py-2 px-4 rounded-lg">
                Browse Computer
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={imageHandler}
                />
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="sm:w-[70%] pl-4 pr-4 flex flex-col gap-2 w-full mt-2">
        <div className="flex gap-4">
          <div className="flex gap-1 flex-col">
            <label className="text-sm font-semibold" htmlFor="chequeNumber">
              Cheque Number:
            </label>
            <input
              type="text"
              id="chequeNumber"
              placeholder="Cheque Number"
              value={chequeNumber}
              onChange={(e) => setChequeNumber(e.target.value)}
              className="border bg-gray-200 p-2 rounded-lg h-[2rem]"
            />
          </div>
          {/* <div className="flex gap-1 flex-col">
            <label className="text-sm font-semibold">Date:</label>
            <DatePicker
              onChange={(e) => setDate(e)}
              placeholder="Select Date"
              className="border bg-gray-200 p-2 rounded-lg h-[2rem]"
            />
          </div> */}
        </div>
        <div className="flex gap-1 flex-col">
          <label htmlFor="amount" className="text-sm font-semibold">
            Note:
          </label>
          <textarea
            placeholder="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="border bg-gray-200 p-2 rounded-lg h-[4rem]"
          ></textarea>
        </div>
      </div>

      <div className="w-[18rem] pl-4 pr-4 mt-4 flex items-center">
        <button
          onClick={() => {
            setShowConfirm(true);
          }}
          className={`w-[12rem] p-1 border-2 font-bold text-white rounded-lg  ${
            !image
              ? "bg-gray-300 cursor-not-allowed border-gray-300"
              : "border-primary bg-primary"
          }`}
          disabled={!image}
        >
          Payment Completed
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${totaPayment}</span> has been
            transferred to the Merchant's account
          </p>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${totaPayment}</span> to merchant?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                Pay();
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showError && <ErrorModal setShow={setShowError} err={err} />}
    </div>
  );
};

export default Main;
