const Documentation = ({ documentation }) => {
  return (
    <div className="sm:p-10 ">
      <div className="bg-[#F4F5FE] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 ipad-pro:grid-cols-2 gap-4 p-4 rounded-lg ml-4 mr-4 px-5">
        {documentation
          ?.filter((document) =>
            !["nisNumber", "value-added tax","NIS","tinNumber"].includes(
              document?.docIDName
            )
          )
          .map((document, index) => (
            <div
              key={index}
              className="relative flex flex-col border p-5 rounded-md bg-white"
            >
              <div className="p-2">
                <h1 className="font-bold text-sm text-gray-900 mb-2 capitalize">
                  {/* {document?.type === "business registration information"
                    ? "BRI"
                    : document?.type === "value-added tax"
                    ? "VAT"
                    : document?.type} */}
                  {document?.type}
                </h1>
                <h3 className="font-semibold text-md text-gray-900">
                  NO : <span>{document?.docIDValue}</span>
                </h3>
                <h3
                  className={`font-semibold text-sm text-gray-900 ${
                    document?.countryIssued ? "" : "hidden"
                  }`}
                >
                  Country Issued : <span>{document?.countryIssued}</span>
                </h3>
                {/* <h3 className="font-semibold text-md text-gray-900">Expiry Date : <span>{document?.expiry}</span></h3> */}
                <h3 className="font-semibold text-md text-gray-900 mt-2">
                  Attachments
                </h3>
              </div>

              <div className="flex gap-3">
                <div className="flex-1">
                  <h1
                    className={`font-semibold p-2 text-primary capitalize ${
                      document?.merchdocimages?.[0] ? "" : "hidden"
                    }`}
                  >
                    {document?.merchdocimages?.[0]?.type}
                  </h1>

                  <div className={`relative mb-4`}>
                    <img
                      onClick={() => {
                        // setIShow(true);
                      }}
                      className={`w-full h-24 ${
                        document?.merchdocimages?.[0] ? "" : "hidden"
                      }`}
                      src={document?.merchdocimages?.[0]?.url}
                      alt=""
                    />
                    <div
                      className="absolute bottom-0 right-0 bg-white cursor-pointer"
                      onClick={() => {}}
                    >
                      {/* <img className="w-[2rem]" src={trash} alt="" /> */}
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <h1
                    className={`font-semibold p-2 text-primary capitalize ${
                      document?.merchdocimages?.[1] ? "" : "hidden"
                    }`}
                  >
                    {document?.merchdocimages?.[1]?.type}
                  </h1>

                  <div className={`relative mb-4`}>
                    <img
                      onClick={() => {
                        // setIShow(true);
                      }}
                      className={`w-full h-24 ${
                        document?.merchdocimages?.[1] ? "" : "hidden"
                      }`}
                      src={document?.merchdocimages?.[1]?.url}
                      alt=""
                    />
                    <div
                      className="absolute bottom-0 right-0 bg-white cursor-pointer"
                      onClick={() => {}}
                    >
                      {/* <img className="w-[2rem]" src={trash} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Documentation;
