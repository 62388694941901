import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Cards from "./listing";
import { useSelector } from "react-redux";

const ListingGROAdmin = () => {
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);

  return (
    <div className="flex items-center justify-center pt-[2rem] flex-col">
      <div className="sm:w-[80%] flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                if (user === "superadmin") router("/superAdminDashboard");
                else if (user === "admin") router("/adminDashboard");
                else if (user === "employee") router("/EDashboard");
                else router("/Dashboard");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Events
              </h2>
            </div>

            <button
              onClick={() => {
                router("/addEvent");
              }}
              className={`${
                dataUser.data.user.status === "pending"
                  ? "pointer-events-none"
                  : user === "pensioner"
                  ? "hidden"
                  : ""
              } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Create Event
            </button>
          </div>
        </div>
      </div>
      <div className="sm:w-[80%]">
        <Cards />
      </div>
    </div>
  );
};

export default ListingGROAdmin;
