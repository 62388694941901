import RegistrationP from "./viewParts/registrationP";
import IdentificationP from "./viewParts/identifictionP";
import { useState } from "react";
import back5 from "../../imgs/back5.png";
import logo1 from "../../imgs/logo12.png";
import { useLocation, useNavigate } from "react-router-dom";
import DigitalCard from "../card/card";
import DigitalCardBack from "../card/cardBack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import across from "../../imgs/across.png";

const EcardView = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const emp = location?.state?.emp;
  const [role, setRole] = useState(null);
  const empRole = location?.state?.empRole;
  const [showE, setShowE] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const [roles, setRoles] = useState();
  const Show = [RegistrationP, IdentificationP];
  const [err, setErr] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const user=useSelector((state)=>state.user)
  const Dis = Show[wow];

  useEffect(() => {
    return () => {
      dispatch({ type: "setProfileData", num: null });
      dispatch({ type: "benDone", num: "" });
    };
  }, []);
  useEffect(() => {
    if (empRole === "admin") {
      const api = "v1/privileges/list-roles-privileges/by-superadmin";
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          setRoles(data.data.roles);
          // setAllb(data.data);
        })
        .catch(() => {});
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/admin-profile/${emp}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch(() => {});
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/employee-profile/${emp}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch(() => {});
    }
  }, []);

  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  const makeEmployee = () => {
    if (role === "" || role === null) {
      setErr(
        "Please select role and click on Assign to assign role to employee"
      );
      setShowErr(true);
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${emp}/convert-admin-to-employee`,
          {
            employeeInfo: {
              role: role,
            },
            consumerInfo: {
              ip: "123.32.4.1",
              location: "123.2323, 434.32323",
            },
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          router("/superAdminDashboard/allEmployees");
        })
        .catch(() => {});
    }
  };
  return (
    <div className="flex items-center justify-center">
      <div className="md:w-[80%] w-full pt-[5rem] md:pt-[2rem] md:pb-[5rem] p-4">
      {profileData && (
        <div>
          <div className="flex md:items-center md:flex-row flex-col md:justify-between justify-left mb-[3rem] ml-0">
            <div className="flex flex-row items-center md:mb-0 mb-4 md:justify-center">
              <div
                onClick={() => {
                  if (empRole === "admin") {
                    user === "admin"
                      ? router("/AdminDashboard/allEmployees")
                      : router("/superAdminDashboard/allEmployees");
                  } else {
                    user === "admin"
                      ? router("/AdminDashboard/employeeProfile", {
                          state: { employeeId: emp, empRole: empRole },
                        })
                      : router("/superAdminDashboard/employeeProfile", {
                          state: { employeeId: emp, empRole: empRole },
                        });
                  }

                  //  user === "admin" ? router("/AdminDashboard/allEmployees") : router("/superAdminDashboard/allEmployees");
                }}
                // className="absolute top-[1] cursor-pointer sm:left-[-4%] left-[1%]"
              >
                <img className="sm:w-[1.7rem] w-[1.4rem]" src={back5} alt="" />
              </div>
              <div className="font-bold sm:text-[1.8rem] text-[1.2rem] sm:ml-2 ml-[2rem]">
                {dash === "/superAdminDashboard/ACardView"
                  ? "View Admin"
                  : "View Employee"}
              </div>
            </div>
            <div className="font-bold sm:text-[1.8rem] text-[1.4rem]">
              {dash === "/superAdminDashboard/ACardView" && (
                <button
                  onClick={() => setShowE(true)}
                  className={`${
                    dataUser.data.user.status === "pending"
                      ? "pointer-events-none"
                      : ""
                  } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
                >
                  Assign Employee Role
                </button>
              )}
            </div>
          </div>
          <div className="lg:mb-[2rem] flex mb-[2rem] md:ml-[0px]  ml-[20px]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ml-1 ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 ml-1 w-10 h-[2px] ${
                  wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${
                  wow === 1 ? "font-semibold" : ""
                } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                  wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                }  `}
              ></div>
            </div>
          </div>

          <div>
            {profileData.user && (
              <div>
                {profileData.user.roles[0] === "pensioner" && (
                  // <div className="sm:flex-row flex flex-col h-[25rem] w-full">
                  <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
                  <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                    <DigitalCard />
                  </div>
                  <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                    <DigitalCardBack />
                  </div>
                </div>
                  // </div>
                )}
              </div>
            )}
            <div>
            <Dis />
          </div>
          </div>
        </div>
      )}

      <div
        className={`${
          showE ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[30rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <div className="mt-3">
            <p className="font-bold mb-2 ml-2 text-[1rem]">Employee Roles:</p>
            <div className="grid grid-cols-2 gap-4  mb-2 p-3 ">
              {roles &&
                roles.map((rol, key) => (
                  <label
                    key={key}
                    className="inline-flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <input
                        type="radio"
                        className="form-radio text-indigo-600 h-4 w-4"
                        value={rol}
                        checked={role === rol}
                        onChange={() => setRole(rol)}
                        // disabled={role !== "" ? true : false}
                      />
                      <span className="ml-2 text-gray-700 capitalize">
                        {rol}
                      </span>
                    </div>
                  </label>
                ))}
            </div>
          </div>
          {/* <p className="text-left mt-[1rem] mb-5">Do you want to assign role to employee?</p> */}
          <div className=" w-[80%] flex items-center justify-between">
            <button
              type="button"
              onClick={() => setShowE(false)}
              className="cursor-pointer rounded-xl flex items-center justify-center text-center pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[48%]"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={makeEmployee}
              className="cursor-pointer text-center flex items-center justify-center rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[48%]"
            >
              Assign
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showErr ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowErr(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowErr(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default EcardView;
