import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import trash from "../../../imgs/trash.png";
import axios from "axios";
import tick from "../../../imgs/across.png";
import { PlusCircleFilled } from "@ant-design/icons";
import cross from "../../../imgs/cross.png";
import logo1 from "../../../imgs/logo12.png";
import { Select } from "antd";
import { DeleteFilled } from "@ant-design/icons";
let file;
const ThirdI = () => {
  const location = useLocation();
  const router = useNavigate();
  const icurrent = useSelector((state) => state.icurrent);
  const dataUser = useSelector((state) => state.dataUser);
  const popData = useSelector((state) => state.popData);
  const benId = useSelector((state) => state.benId);
  const dash = location.pathname;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const option = useSelector((state) => state.option);
  const [display, setDisplay] = useState("");
  const [optionn, setOption] = useState(null);
  const [display1, setDisplay1] = useState("");
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [verify, setVerify] = useState(false);
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [nisNo, setNisNo] = useState("");
  const [nisFront, setNisFront] = useState("");
  const [tisNo, setTisNo] = useState("");
  const [tisFront, setTisFront] = useState("");
  const [err, setErr] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  console.log(selectedOptions);
  let optionI;
  optionI = [
    {
      label: "National Insurance",
      value: "National Insurance",
    },
    {
      label: "Tax Information",
      value: "Tax Information",
    },
  ];
  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    if (file === undefined) {
      setIsLoading1(false);
      setIsLoading2(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          e.target.value = null;
          load(false);
        })
        .catch(() => {
          setErr("");
        });
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handleOptionDelete = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((item) => item !== option)
    );
    if (option === "National Insurance") {
      setDisplay("");
      setNisNo("");
      setNisFront("");
    } else if (option === "Tax Information") {
      setDisplay1("");
      setTisNo("");
      setTisFront("");
    }
  };

  const nextHandler = (e) => {
    e.preventDefault();

    if (
      dash !== "/superAdminDashboard/addAdmin" &&
      dash !== "/superAdminDashboard/addGroAdmin" &&
      dash !== "/GroAdminDash/AddGroEmp"
    ) {
      if (
        dash === "/EDashboard/BeneficiaryQuestions" ||
        dash === "/superAdminDashboard/BeneficiaryQuestions" ||
        dash === "/AdminDashboard/BeneficiaryQuestions"
      ) {
        dispatch({ type: "BISET", num: 2 });
      } else {
        if (option === "Pensioner") {
          dispatch({ type: "IINCREMENT" });
        } else {
          dispatch({ type: "POP" });
          dispatch({ type: "ISET", num: 7 });
        }
      }
    }

    const body = [];
    if (nisFront !== "" || nisNo !== "") {
      const nisData = {
        type: "national insurance",
        docIDName: "national insurance",
        docIDValue: `${nisNo}`,
        docImages: [],
      };
      if (nisFront !== "") {
        nisData.docImages[0] = {
          type: "front image",
          imageUrl: `${nisFront}`,
        };
      }
      body.push(nisData);
    }
    if (tisFront !== "" || tisNo !== "") {
      const tisData = {
        type: "taxpayer identification",
        docIDName: "taxpayer identification",
        docIDValue: `${tisNo}`,
        docImages: [],
      };
      {
        tisData.docImages[0] = {
          type: "front image",
          imageUrl: `${tisFront}`,
        };
      }
      body.push(tisData);
    }
    if (
      dash === "/superAdminDashboard/addAdmin" ||
      dash === "/superAdminDashboard/addGroAdmin" ||
      dash === "/GroAdminDash/AddGroEmp"
    ) {
      let apiii;
      let requestData = {
        verificationDocs: body,
        consumerInfo: {
          ip: "111.222.33.4",
          location: "555.44343, 444.343424",
        },
      };
      if (dash === "/superAdminDashboard/addAdmin")
        apiii = `/v1/verification-docs/admin-verification-docs?verificationDocUserId=${popData.id}`;
      else if (dash === "/superAdminDashboard/addGroAdmin")
        apiii = `/v1/verification-docs/gro-admin-verification-docs?verificationDocUserId=${popData.id}`;
      else if (dash === "/GroAdminDash/AddGroEmp")
        apiii = `/v1/verification-docs/gro-employee-verification-docs?verificationDocUserId=${popData.id}`;
      axios
        .post(`${process.env.REACT_APP_BASE_URL}${apiii}`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch({ type: "ISET", num: 0 });
          // dispatch({ type: "IdDoneSet", num: "Yes" });
          // dispatch({ type: "IINCREMENT" });
          // dispatch({ type: "BISET", num: 1 });
          //   if(dash==="/superAdminDashboard/addAdmin")
          //   router("/superAdminDashboard/allEmployees")
          // else if (dash === "/superAdminDashboard/addGroAdmin")
          // router("/superAdminDashboard/allGroAdmins")
          // else if (dash === "/GroAdminDash/AddGroEmp")
          // router("/GroAdminDash/Employees")
        })
        .catch(() => {
          // if (
          //   err.response.data.message === '"expiryDate" must be a valid date'
          // ) {
          //   setErr("Please verify if expiration date is available on card");
          // } else {
          //   setErr(err.response.data.message);
          // }
          // setShow(true);
        });
    } else if (icurrent <= 9) {
      if (dash === "/Dashboard/BeneficiaryQuestions") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs?beneficiaryUserId=${benId}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            // if (dash === "/Dashboard/BeneficiaryQuestions") {
            //   dispatch({ type: "BISET", num: 3 });
            // } else {
            //   dispatch({ type: "IINCREMENT" });
            // }
          })
          .catch(() => {});
      } else if (dash === "/EDashboard/addPensioner") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/verification-docs/add-by-staff?verificationDocUserId=${popData.id}`,
            { verificationDocs: body },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            // if (option === "Pensioner") {
            //   dispatch({ type: "IINCREMENT" });
            // } else {
            //   dispatch({ type: "POP" });
            //   dispatch({ type: "ISET", num: 8 });
            // }
          })
          .catch(() => {});
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/verification-docs`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            // if (option === "Pensioner") {
            //   dispatch({ type: "IINCREMENT" });
            // } else {
            //   dispatch({ type: "POP" });
            //   dispatch({ type: "ISET", num: 8 });
            // }
          })
          .catch(() => {});
      }
    } else {
      if (
        dash === "/EDashboard/BeneficiaryQuestions" ||
        dash === "/superAdminDashboard/BeneficiaryQuestions" ||
        dash === "/AdminDashboard/BeneficiaryQuestions"
      ) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs/add-by-staff?beneficiaryUserId=${benId}`,
            { verificationDocs: body },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            // dispatch({ type: "BISET", num: 3 });
          })
          .catch(() => {});
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/verification-docs?beneficiaryUserId=${benId}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            // if (dash === "/Dashboard/BeneficiaryQuestions") {
            //   dispatch({ type: "BISET", num: 3 });
            // } else {
            //   dispatch({ type: "IINCREMENT" });
            // }
          })
          .catch(() => {});
      }
    }
    if (dash === "/Dashboard/BeneficiaryQuestions") {
      dispatch({ type: "BISET", num: 2 });
    }
    if (dash === "/") {
      dispatch({ type: "BISET", num: 2 });
    } else if (dash === "/superAdminDashboard/addAdmin") {
      dispatch({ type: "ISET", num: 0 });
      router("/superAdminDashboard/allEmployees");
    } else if (dash === "/superAdminDashboard/addGroAdmin") {
      dispatch({ type: "ISET", num: 0 });
      router("/superAdminDashboard/allGroAdmins");
    } else if (dash === "/GroAdminDash/AddGroEmp") {
      dispatch({ type: "ISET", num: 0 });
      router("/GroAdminDash/Employees");
    } else if (
      dash !== "/EDashboard/addPensioner" &&
      dash !== "/superAdminDashboard/BeneficiaryQuestions" &&
      dash !== "/AdminDashboard/BeneficiaryQuestions"
    ) {
      dispatch({ type: "IINCREMENT" });
    }
  };
  const handleChange = (setState, check) => (event) => {
    setState(event.target.value);
  };
  return (
    <div className="flex items-center flex-col justify-center">
      <div className="sm:w-[70%] w-[90%] min-h-[70vh]">
        <h2
          className={`
              text-[0.9rem] text-center text-[#F25A29] font-semibold center mb-6`}
        >
          If you want to add more please select Option and click on Add Now.
        </h2>
        <div className="flex justify-center mt-2 flex-col items-center">
          <div className="w-full flex items-center gap-4">
            <div className="w-3/4">
              <label
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Select Document:
              </label>
              <Select
                placeholder="Document Type"
                optionFilterProp="children"
                // defaultValue={optionI[0].value}
                className="wow9"
                style={{ marginBottom: "1.2rem", marginTop: "5px" }}
                onChange={(val) => {
                  setOption(val);
                }}
                value={optionn}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={optionI.filter(
                  (opt) => !selectedOptions.includes(opt.value)
                )}
              />
            </div>
            <button
              onClick={() => {
                setSelectedOptions([...selectedOptions, optionn]);
                setOption(null);
              }}
              className="w-1/4 lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2"
            >
              Add Now
            </button>
          </div>

          {selectedOptions.map((selectedOption, index) => (
            <div key={index} className="w-full">
              {selectedOption === "National Insurance" && (
                <div className="bg-gray-50 relative p-6 shadow-md mb-3 rounded-lg">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        National Insurance
                      </label>
                      <input
                        type="text"
                        name=""
                        
                        placeholder="NIS #"
                        onChange={handleChange(setNisNo)}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                      Image Front
                    </h2>
                    <label
                      htmlFor="special-input"
                      className={`${
                        isLoading1 || isLoading2 ? "pointer-events-none" : ""
                      } cursor-pointer relative`}
                    >
                      <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                        <div>
                          <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                        </div>
                        <p className="text-[#9CA6C2] ml-2">Image Front</p>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        id="special-input"
                        accept="image/png, image/gif, image/jpeg"
                        onInput={imageHandler(
                          setDisplay,
                          setNisFront,
                          setIsLoading1
                        )}
                      />
                    </label>
                    <div
                      className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                        display ? "" : "hidden"
                      }`}
                    >
                      <img
                        onClick={() => {
                          setIShow(true);
                        }}
                        className="w-[25rem] h-[13rem]"
                        src={display}
                        alt=""
                      />
                      <div
                        className="absolute bottom-0 right-0 bg-white cursor-pointer"
                        onClick={() => {
                          setDisplay("");
                          setNisFront("");
                          setIsLoading1(false);
                          setIsLoading2(false);
                          const wow = document.getElementById("special-input");
                          wow.value = "";
                        }}
                      >
                        <img
                          className="sm:w-[3rem] w-[2rem]"
                          src={trash}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        iShow ? "" : "hidden"
                      } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                    >
                      <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                        <img
                          className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                          src={cross}
                          onClick={() => setIShow(false)}
                          alt=""
                        />
                        <img
                          className="w-[45rem] h-[28rem]"
                          src={display}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedOption === "Tax Information" && (
                <div className="bg-gray-50 relative p-6 shadow-md mb-3 rounded-lg">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Tax Information
                      </label>
                      <input
                        type="text"
                        name=""
                        
                        placeholder="TIN #"
                        onChange={handleChange(setTisNo)}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>

                    <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                      Image Front
                    </h2>
                    <label
                      htmlFor="special-input1"
                      className={`${
                        isLoading1 || isLoading2 ? "pointer-events-none" : ""
                      } cursor-pointer relative`}
                    >
                      <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                        <div>
                          <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                        </div>
                        <p className="text-[#9CA6C2] ml-2">Image Front</p>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        id="special-input1"
                        accept="image/png, image/gif, image/jpeg"
                        onInput={imageHandler(
                          setDisplay1,
                          setTisFront,
                          setIsLoading2
                        )}
                      />
                    </label>
                    <div
                      className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                        display1 ? "" : "hidden"
                      }`}
                    >
                      <img
                        onClick={() => {
                          setIShow1(true);
                        }}
                        className="w-[25rem] h-[13rem]"
                        src={display1}
                        alt=""
                      />
                      <div
                        className="absolute bottom-0 right-0 bg-white cursor-pointer"
                        onClick={() => {
                          setDisplay1("");
                          setTisFront("");
                          setIsLoading1(false);
                          setIsLoading2(false);
                          const wow = document.getElementById("special-input1");
                          wow.value = "";
                        }}
                      >
                        <img
                          className="sm:w-[3rem] w-[2rem]"
                          src={trash}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        iShow1 ? "" : "hidden"
                      } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                    >
                      <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                        <img
                          className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                          src={cross}
                          onClick={() => setIShow1(false)}
                          alt=""
                        />
                        <img
                          className="w-[45rem] h-[28rem]"
                          src={display1}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`mt-3 flex items-center sm:justify-end justify-center sm:w-[85%]`}
      >
        <button
          onClick={nextHandler}
          className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      <div
        className={`${
          verify ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setVerify(false)}
          >
            <img src={tick} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            Please Enter at least one
          </p>

          <button
            type="submit"
            onClick={() => setVerify(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThirdI;
