export const priorityPensioner = (state = false, action) => {
  switch (action.type) {
    case "PRIORITY_PENSIONER":
      return action.num;
    default:
      return state;
  }
};

export const distributionCenter = (state = null, action) => {
  switch (action.type) {
    case "DISTRIBUTION_Center":
      return action.num;
    default:
      return state;
  }
};

export const selectedProgram = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_PROGRAM":
      return action.num;
    default:
      return state;
  }
};

//selectedPensioner for verification card
export const veriSelectedPensioner = (state = null, action) => {
  switch (action.type) {
    case "VERI_SELECTED_PENSIONER":
      return action.num;
    default:
      return state;
  }
};
export const veriSelectedDoc = (state = null, action) => {
  switch (action.type) {
    case "VERI_SELECTED_Doc":
      return action.num;
    default:
      return state;
  }
};
export const veriPriority = (state = [], action) => {
  switch (action.type) {
    case "VERI_PRIORITY":
      return action.num;
    default:
      return state;
  }
};
export const penVerStatus = (state = "", action) => {
  switch (action.type) {
    case "PEN_VER_STATUS":
      return action.num;
    default:
      return state;
  }
};
export const penVerQueue = (state = [], action) => {
  switch (action.type) {
    case "PEN_VER_QUEUE":
      return action.num;
    default:
      return state;
  }
};

//Show search modal one time
export const searchModalCount = (state = null, action) => {
  switch (action.type) {
    case "SEARCH_MODAL_COUNT":
      return action.num;
    default:
      return state;
  }
};

//Result after Search from Modal
export const searchResult = (state = [], action) => {
  switch (action.type) {
    case "SEARCH_RESULT":
      return action.num;
    default:
      return state;
  }
};


export const verificationStart = (state = false, action) => {
  switch (action.type) {
    case "VERIFICATION_START":
      return action.num;
    default:
      return state;
  }
}
