import TransactionDetail from "../components/Merchant/Transactions/transDetail/pensioner"
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const Transactions = () => {
  return (
    <div>
      <Nav />
      <TransactionDetail />
      <Whatsapp/>
    </div>
  );
};

export default Transactions;
