import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const CustomDoughnutChart = ({ data }) => {
  const data1 = [
    { name: "Cash Received", value: data?.totalIn ? data?.totalIn : 0 }, // Green section
    {
      name: "Cash Paid",
      value: data?.totalOutPay ? data?.totalOutPay : 0,
    },
    { name: "Fee Out", value: data?.totalOutFee ? data?.totalOutFee : 0 }, // Blue section
  ];

  const COLORS = ["#2B6CB0", "#38B2AC", "#FFCD69"]; // Colors for the chart segments
  return (
    <div className="flex justify-center items-center">
      <ResponsiveContainer width={200} height={200}>
        <PieChart>
          <Pie
            data={data1}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data1?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {/* <div className="absolute text-center text-gray-600 font-medium">
        <div>Cash</div>
        <div>In/Out</div>
      </div> */}
    </div>
  );
};

export default CustomDoughnutChart;
