import React from "react";
import { Collapse } from "antd";
import Address from "../headquater/address";
const { Panel } = Collapse;
const Index = ({ data, address, identification }) => {
  console.log(data);
  const callback = (key) => {
    console.log(key, address, identification);
  };

  return (
    <div className="sm:p-10">
      <div className="p-4 bg-white rounded-lg border shadow-md sm:p-8   mb-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold leading-none text-gray-900 ">
            Address
          </h3>
        </div>

        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            onChange={callback}
            accordion={true}
          >
            {data?.map((address, index) => {
              return (
                <Panel
                  header={"Address " + (index + 1)}
                  key={index + 1}
                  className="sm:p-3 text-md text-gray-800 font-medium"
                >
                  <Address headquarter={address} title="Address" />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Index;
