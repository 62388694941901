import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import thumb from "../../../../../imgs/Thumb.png";
import { useNavigate } from "react-router-dom";
import Ewallett from "../../../../../imgs/eWallet3.png";
import logo from "../../../../../imgs/mhsss_Accounting-logo.png";
import axios from "axios";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
const Main = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const totaPayment = useSelector((state) => state.totalPayment);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const selectedTransactionsVouchers = useSelector(
    (state) => state.selectedTransactionsVouchers
  );
  const selectedPaymentMethod = useSelector(
    (state) => state.selectedPaymentMethod
  );
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState(false);
  const [showError, setShowError] = useState(false);
  const ewallets = [
    {
      image: Ewallett,
      phone: "+1234567890",
      email: "user1@example.com",
    },
    {
      image: Ewallett,
      phone: "+1987654321",
      email: "user2@example.com",
    },
  ];
  const Pay = () => {
    const body = {
      merchtransactionId: selectedTransactionsVouchers?.[0]?.merchtransactionId,
      paymentMethod: selectedPaymentMethod,
      paymentId: "89998998",
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transaction-payments`,

        body,

        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "AFTER_PAYMENT_DATA", num: data.data });
        setShow(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowError(true);
      });
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/MHSSS/paymentReceipt");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);
  return (
    <div className="flex flex-col w-full overflow-hidden min-h-[82vh] p-4 relative rounded-md bg-white">
      <div className="p-2 mt-2 border-b pb-4">
        <h1 className="font-semibold">
          Total Amount to be Paid{" "}
          <span className="ml-4 font-bold text-primary text-[16px]">{`$${totaPayment}`}</span>
        </h1>
      </div>
      <div className="w-full flex flex-col gap-4 p-2 mt-5">
        <div className=" flex flex-col gap-4 pt-2">
          <p className="text-sm font-semibold">Select e-wallet to be paid</p>
          <div className="flex gap-4">
            {ewallets.map((ewallet, index) => (
              <div
                key={index}
                className="flex gap-2 p-2 cursor-pointer h-[4.5rem] bg-[#D9EFED] rounded-lg"
              >
                <img
                  src={ewallet.image}
                  alt="ewallet"
                  className="h-[90%] w-auto rounded-full"
                />
                <div className="flex flex-col justify-center">
                  <p className="text-sm font-semibold">{ewallet.phone}</p>
                  <p className="text-sm font-semibold">{ewallet.email}</p>
                </div>
              </div>
            ))}
            <div
              onClick={() => {
                dispatch({ type: "ISET", num: 1 });
              }}
              className="flex gap-2 p-2 w-[4.5rem] text-center cursor-pointer h-[4.5rem] flex items-center justify-center font-semibold bg-[#D9EFED] rounded-lg"
            >
              {" "}
              Add another
            </div>
          </div>
          <textarea
            placeholder="Add note here"
            className="w-[60%] h-[5rem] p-2 rounded-lg bg-gray-200"
          ></textarea>
          <button
            onClick={() => {
              setShowConfirm(true);
            }}
            className={`w-[12rem] p-1 border-2 font-bold text-white rounded-lg border-primary bg-primary`}
          >
            Process Payment
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${totaPayment}</span> has been
            transferred to the Merchant's account
          </p>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${totaPayment}</span> to ABC bank of
            merchant?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                setShowConfirm(false);
                Pay();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showError && <ErrorModal setShow={setShowError} err={err} />}
    </div>
  );
};

export default Main;
