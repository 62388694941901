import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import back5 from "../../../imgs/back5.png";
import check from "../../../imgs/add.png";
let file;
const Mid = () => {
  const router = useNavigate();
  const [selectedServices, setSelectedServices] = useState([]);
  const fileInputRef = useRef(null);
  const [display, setDisplay] = useState(
    "https://static.thenounproject.com/png/2351010-200.png"
  );

  const toggleService = (service) => {
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.includes(service)
        ? prevSelectedServices.filter((s) => s !== service)
        : [...prevSelectedServices, service]
    );
  };

  const imageHandler = (setState) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const services = [
    "Battery",
    "Belts",
    "Bodywork",
    "Brakes",
    "Bulbs/Lights Head",
    "Bulbs/Lights High beam",
    "Bulbs/Lights Park",
    "Bulbs/Lights Running",
    "Bulbs/Lights Tail",
    "Bulbs/Lights Turn",
    "Coolant",
    "Cooling",
    "Electrical",
    "Engine",
    "Error lights",
    "Exhaust",
    "Filters Air",
    "Filters Fuel",
    "Filters Oil",
    "Gas",
    "Gas (Mileage)",
    "Inspection",
    "Oil Change",
    "Painting",
    "Tires Alignment",
    "Tires Balance",
    "Tires Repair",
    "Tires Replace",
    "Tires Rotate",
    "Transmission",
    "Wash/Wax",
  ];

  return (
    <div className=" w-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-full flex items-center justify-between sm:w-[70%] w-[80%]">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => router("/Maintenance-Vendors")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Add Vendor
              </h2>
            </div>
          </div>
        </div>
      </div>
      <form action="" className="mt-10 sm:mt-[5rem] mb-[5rem]" onSubmit={""}>
        <div className="flex items-center justify-center mb-4 flex-col">
          <label htmlFor="special-input" className="cursor-pointer relative">
            <div className="relative w-[8.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262]  rounded-full mb-4 ">
              <img
                className="object-cover w-[8.5rem] h-[8.5rem]"
                src={display}
                alt=""
              />
            </div>

            <div
              className={`bg-primary
               rounded-full absolute bottom-4 right-2`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay)}
              ref={fileInputRef}
            />
          </label>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Upload Vendor Logo <span className="text-red-600">*</span>
          </h2>
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Company
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="Company"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  HeadQuarter
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="HeadQuarter"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Service Description
                </label>
                <textarea
                  name="serviceDescription"
                  id="serviceDescription"
                  placeholder="Service Description"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Owner/Administrator First Name
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="First Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Owner/Administrator Middle Name
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="Middle Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Owner/Administrator Last Name
                </label>
                <input
                  type="text"
                  name=""
                  
                  placeholder="Last Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
          </div>
          <div className="sm:w-[70%] w-[80%] mt-8 mb-8 bg-[#F4F5FE] flex flex-col shadow-md p-4 rounded-md">
            <label className="text-[#596F96] mb-4 text-[1rem] font-semibold">
              Services
            </label>
            <div className="grid md:grid-cols-4 md:grid-cols-2 md:grid-cols-1 gap-4">
              {services.map((service) => (
                <div
                  key={service}
                  className={`p-2 rounded-md cursor-pointer ${
                    selectedServices.includes(service)
                      ? "bg-primary text-white"
                      : "bg-gray-300"
                  }`}
                  onClick={() => toggleService(service)}
                >
                  {service}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div
            className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
          >
            <button
              type="button"
              className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Add Vendor
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Mid;
