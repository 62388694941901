import { useState } from "react";
import LoginE from "./tabsR/loginE";
import PersonalE from "./tabsR/personalE";
// import Location1 from "./tabsR/locationE";
// import LocationsE from "./tabsR/locationE";
// import EmployerE from "./tabsR/employerE";
import Location1 from "./tabsR/locationE";
// import Identification2 from "../../PAccount/parts/tabsR/identification";

const Eregistration = () => {
  const [wow, setWow] = useState(0);
  const Show = [LoginE, PersonalE, Location1];
  const Dis = Show[wow];
  return (
    <div className="">
      <div className="flex flex-wrap mt-8 mb-[5rem]">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Login
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Personal
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Location
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        {/* <button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl mr-2 min-w-[5.5rem] pl-4 pr-4 relative sm:mt-0 mt-8`}
        >
          Employer
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button> */}
      </div>
      <Dis />
    </div>
  );
};

export default Eregistration;
