import { useSelector } from "react-redux";

import ProgramTable from "./programTable";

const Employee = () => {
  const data = useSelector((state) => state.programTransactionType);
  const user = useSelector((state) => state.user);

  return (
    <div className="sm:px-8 mt-6 sm:mt-5">
      <div className="flex justify-between items-center mb-7">
        <div className="flex items-center">
          <img
            className="w-16 h-16 rounded-full mr-3"
            src={
              user !== "pensioner"
                ? data?.image
                : data?.type === "store"
                ? data?.merchStore?.image
                : data?.merchCompany?.logo
            }
            alt="profile"
          />
          <div className="text-sm">
            <h4 className="sm:text-lg font-semibold text-primary truncate leading-none">
              {user !== "pensioner"
                ? data?.firstName + " " + data?.lastName
                : data?.type === "store"
                ? data?.merchStore?.customStoreName
                : data?.merchCompany?.name}
            </h4>

            <p className="mt-1">{data?.emails?.[0]}</p>
          </div>
        </div>
        <button className="px-8 py-2 bg-primary text-white rounded-lg ">
          Pay Now
        </button>
      </div>
      <ProgramTable />
    </div>
  );
};

export default Employee;
