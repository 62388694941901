import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import logo from "../../../../imgs/logomhs.png";
import moment from "moment";
export const printFunc = ({ dataVoucher, pen, voucher }) => {
  console.log(pen);
  console.log(dataVoucher);
  console.log(voucher);
  const proName =
    voucher?.pensionprogram?.name === "senior-citizen-Pension"
      ? "Senior Citizen Pension"
      : voucher?.pensionprogram?.name;
  const pro1Name =
    dataVoucher?.pensionbook?.pensionprogram?.name === "senior-citizen-Pension"
      ? "Senior Citizen Pension"
      : dataVoucher?.pensionbook?.pensionprogram?.name;
  const data = {
    transactionId: pen
      ? dataVoucher?.transactionId
      : dataVoucher?.merchtransaction?.transactionId,
    transactionDate: moment(dataVoucher?.createdAt).format(
      "MM/DD/YYYY hh:mm A"
    ),
    transactionType:
      dataVoucher?.paymentMethod?.charAt(0).toUpperCase() +
      dataVoucher?.paymentMethod?.slice(1),
    programName: pen ? proName : pro1Name,
    merchantsName:
      dataVoucher?.merchuser?.merchcompany !== null
        ? dataVoucher?.merchuser?.merchcompany?.name
        : "------",
    store:
      dataVoucher?.merchstore === null
        ? "------"
        : dataVoucher?.merchstore?.customStoreName,
    status: "Paid",
    amount: `$${
      pen
        ? dataVoucher?.totalAmount
        : dataVoucher?.amountPaid + dataVoucher?.feePaid
    }`,
    pensionerName:
      dataVoucher?.user?.person?.firstName +
      " " +
      dataVoucher?.user?.person?.lastName,
    voucherCode: "12X3",
    email: "info@example.com",
    phone: "+234XXXXXXXX",
  };
  const mhsss = logo;
  const callImg = slipcall;
  const emailImg = slipemail;
  const bg = slipbg;
  const printFunc = (mhsss, callImg, emailImg) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const iframeDocument = iframe.contentWindow.document;
    let printableContent = `
        <html>
            <head>
                <title>Print</title>
                <style>
                    body {
                        font-size: 25pt;
                    }
                    @media print {
                        .printable-container {
                            -webkit-print-color-adjust: exact;
                            color-adjust: exact; 
                            background-color: #ffffff;
                            background-image: url('${bg}');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                            margin-bottom: 1.5rem;
                            padding: 0.5rem;
                        }
                        .d1 {
                            border-radius: 0.25rem;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            padding-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .d2 {
                            width: 6rem; 
                            height: 5rem;
                            padding-top: 1rem;
                        }
                        .d3 {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .hh1 {
                            font-weight: 600;
                            text-align: center;
                            width: 65%;
                        }
                        .d4 {
                            display: flex;
                            width: 80%;
                            flex-direction: column;
                            border-bottom: 1px solid #e2e8f0;
                            padding-bottom: 1rem;
                            font-size: 1rem;
                        }
                        .pp {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .ss {
                            color: #718096;
                        }
                        .d5 {
                            display: flex;
                            flex-direction: column;
                            padding-top: 0.5rem;
                            font-size: 1rem;
                            width: 80%;
                        }
                        .d6 {
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                        }
                        .call {
                            width: 1.25rem;
                            height: 1.25rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .mail {
                            width: 1.25rem;
                            height: 1rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .d7 {
                            display: flex;
                            width: 100% ;
                            align-items: center;
                        }
                        .sss {
                            font-weight: 600;
                        }
                    }
                </style>
            </head>
            <body>
                <div class="printable-container">
                    <div class="d1">
                        <img id="mhsss" alt="chippz" class="d2" src="${mhsss}" />
                        <div class="d3">
                            <h4 class="hh1">Ministry of Human Services and Social Security</h4>
                        </div>
                        <div class="d4">
                            <p class="pp">
                                <span class="ss">Payment Id</span>
                                <span>${data.transactionId}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Payment Date</span>
                                <span>${data.transactionDate}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Program Name</span>
                                <span>${data.programName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Merchants Name</span>
                                <span>${data.merchantsName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Store</span>
                                <span class="sss">${data.store}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Status</span>
                                <span class="sss">${data.status}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Amount</span>
                                <span class="sss">${data.amount}</span>
                            </p>
                        </div>
                        <div class="d5">
                            <p class="pp">
                                <span class="ss">Pensioner Name</span>
                                <span>${data.pensionerName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Voucher Code</span>
                                <span>${data.voucherCode}</span>
                                </p>
                                <div class="d6">
                                    <p class="d7">
                                        <img id="emailImg" class="mail" alt="chippz" src="${emailImg}" />
                                        ${data.email}
                                    </p>
                                    <p class="d7">
                                        <img id="callImg" class="call" alt="chippz" src="${callImg}" />
                                        ${data.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        `;
    iframeDocument.open();
    iframeDocument.write(printableContent);
    iframeDocument.close();
    iframe.contentWindow.print();
    document.body.removeChild(iframe);
  };
  const imageSources = [mhsss, callImg, emailImg];
  let loadedImages = 0;
  imageSources.forEach((src) => {
    const img = new Image();
    img.onload = () => {
      loadedImages++;
      if (loadedImages === imageSources.length) {
        printFunc(mhsss, callImg, emailImg);
      }
    };
    img.src = src;
  });
};
