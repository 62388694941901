import React from "react";
import { useDispatch, useSelector } from "react-redux";
import back from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";

const Header = ({ title, header, route, routeFind, routeCondition, value }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);

  const profile = dataUser?.data?.user?.person?.image;
  const Name =
    dataUser?.data?.user?.person?.firstName +
    " " +
    dataUser?.data?.user?.person?.lastName;

  return (
    <div
      className={`flex items-center w-[95%]  ${
        header ? "justify-between mb-6" : "justify-end"
      }`}
      style={{ flexWrap: "nowrap", alignItems: "center" }}
    >
      <div
        className={`${
          header ? "flex items-center justify-center ml-4" : "hidden "
        }`}
      >
        <h2 className="mb-4 text-2xl font-bold flex items-center justify-center capitalize">
          <img
            onClick={() => {
              if (title === "Add Pensioner") {
                dispatch({ type: "SET", num: 0 });
                dispatch({ type: "BRSET", num: 0 });
                dispatch({ type: "BISET", num: 0 });
              }
              routeFind === false
                ? dispatch({ type: routeCondition, num: value })
                : router(route);
            }}
            src={back}
            className="w-5 h-5 mr-2 cursor-pointer"
            alt="back"
          />{" "}
          {title}
        </h2>
      </div>

      <div className="items-center ml-4 flex">
        <div className="flex flex-col">
          <h1 className="text-gray-700 text-[1rem] font-semibold mr-3">
            {Name}
          </h1>
          <p className="capitalize font-normal text-[0.85rem">{user}</p>
        </div>

        <img
          src={
            dataUser?.data?.user?.person
              ? dataUser?.data?.user?.person?.image
              : profile
          }
          alt="Profile"
          className="w-14 h-14 rounded-full"
          style={{ marginLeft: "auto" }}
        />
      </div>
    </div>
  );
};

export default Header;
