import React from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import tik from "../../../../imgs/tik.png";
const ProfileCard = () => {
  // const router = useNavigate();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  return (
    <div>
      <div className="p-6 rounded-lg sm:p-8  mb-8">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-primary">
            Profile Details
          </h3>
        </div>
        <div className="mt-6 border-t border-gray-300">
          <dl className="divide-y divide-gray-300">
            {selectedPensioner?.user?.email !== null && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {selectedPensioner?.user?.email}
                </dd>
              </div>
            )}

            {selectedPensioner?.user?.countryCode !== null && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Phone Number
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.countryCode}{" "}
                  {selectedPensioner?.user?.phoneNo}
                </dd>
              </div>
            )}

            {selectedPensioner?.user?.person?.dateOfBirth !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Date of Birth
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.dateOfBirth}
                </dd>
              </div>
            )}

            {selectedPensioner?.user?.person?.gender !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                  Gender
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.gender}
                </dd>
              </div>
            )}

            {selectedPensioner?.user?.person?.ethnicity !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                  Ethnicity
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.ethnicity}
                </dd>
              </div>
            )}

            {selectedPensioner?.user?.person?.unionStatus !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                  Marital Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.unionStatus}
                </dd>
              </div>
            )}
            {selectedPensioner?.user?.person?.employmentStatus !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                  Employment Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.employmentStatus}
                </dd>
              </div>
            )}
            {selectedPensioner?.user?.person?.nationality !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                  Nationality
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.nationality}
                </dd>
              </div>
            )}
            {selectedPensioner?.user?.person?.religion !== null && (
              <div
                className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
              >
                <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                  Religion
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">
                  {selectedPensioner?.user?.person?.religion}
                </dd>
              </div>
            )}
          </dl>
        </div>
        <h3 className="text-base font-semibold leading-7 text-primary">
          Programs
        </h3>
        <div className="mt-6 border-t border-gray-300">
          <div className="mt-6">
            {selectedPensioner?.pensionPrograms?.map((program, index) => (
              <div
                key={index}
                onClick={() => {}}
                className={`bg-primary
                               text-white
                                p-1 inline-block rounded-xl  pl-1 pr-1 mr-4 mb-2 cursor-pointer`}
              >
                <div className="flex items-center">
                  <div className={`w-[22.8px] h-[22.8px] mr-1`}>
                    <img className="scale-[0.95] " src={tik} alt="" />
                  </div>
                  <p>
                    {program?.name === "senior-citizen-Pension"
                      ? "Senior Citizen Pension"
                      : program?.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
