import Prof from "../components/Dash/Test/Listing/prof";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const ProfPage = () => {
  return (
    <div>
      <Nav />
      <Prof />
      <Whatsapp/>
    </div>
  );
};

export default ProfPage;
