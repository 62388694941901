import React, { useState, useEffect } from "react";
import qrE from "../../../../imgs/qrE.png";
import qrN from "../../../../imgs/qrE.svg";
import logo from "../../../../imgs/logo12.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import QrScanner from "react-qr-scanner";
import axios from "axios";
import moment from "moment/moment";
import ErrorModal from "../../../Merchant/Modals/errorModal";

const Right = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const childData = useSelector((state) => state.childData);
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const [showPaymentIdPopup, setShowPaymentIdPopup] = useState(false);
  const [err, setErr] = useState("");
  const [showError, setShowError] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const [data, setData] = useState([]);
  const [dataTrans, setDataTrans] = useState([]);
  const [afterApi, setAfterApi] = useState(false);
  const type = useSelector((state) => state.merchantSapType);
  const typeData = useSelector((state) => state.merchantSapTypeData);
  const priv = useSelector((state) => state.employeeHeadQuarterPrivileges);
  console.log("pri", priv);
  const merchId =
    type === "HQ" ? typeData?.merchuser?.merchheadquarterId : typeData?.id;

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      setShowScanner(false);
    }
  };

  const checkPayment = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/for-merch?paymentId=${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "Quick", num: data.data });
        setShowPaymentIdPopup(false);
        router("/SapMoney/QuickPayMerch");
      })

      .catch((err) => {
        console.log("err", err?.response?.data.message);
        setErr(err?.response?.data.message);
        setShow(true);
      });
  };

  const handleError = (err) => {
    setErr("An error occurred while scanning. Please try again.");
    setShowScanner(false);
  };

  useEffect(() => {
    // if (user !== "pensioner") {
    //   return;
    // }
    if (
      user === "pensioner" ||
      user === "merchant-owner" ||
      user === "merchant-employee" ||
      user === "merchant-admin"
    ) {
      const tok =
        user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

      const api =
        user === "pensioner"
          ? "v1/sapwallet-histories/for-pensioner?limit=4&sortBy=createdAt:desc"
          : `v1/sapwallet-histories/for-merch?limit=3&${
              type === "store"
                ? `fromMerchStore=${typeData?.id}`
                : `fromMerchHeadquarter=${typeData?.merchuser?.merchheadquarterId}`
            }&sortBy=createdAt:desc`;

      // const api =
      //   user === "pensioner"
      //     ? "v1/sapmoney-dashboard/recent-transactions/for-pensioner?limit=4"
      //     : `v1/sapmoney-dashboard/recent-transactions/for-merch?limit=3&${
      //         type === "store"
      //           ? `merchstoreId=${typeData?.id}`
      //           : `merchheadquarterId=${typeData?.merchuser?.merchheadquarterId}`
      //       }`;

      axios
        .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        })
        .then((data) => {
          const updatedRows = data?.data?.rows?.map((row) => {
            if (user === "pensioner") {
              if (row?.source === "sapwallet-to-sapwallet") {
                if (
                  dataUser?.data?.user?.id === row?.fromUserId &&
                  (row?.FromMerchHeadquarter !== null ||
                    row?.FromMerchStore !== null)
                ) {
                  return {
                    ...row,
                    type: "credit",
                    name:
                      row?.FromMerchHeadquarter !== null
                        ? row?.FromMerchCompany?.name
                        : row?.FromMerchStore !== null
                        ? row?.FromMerchStore?.customStoreName
                        : `${row?.FromUser?.person?.firstName || ""} 
       ${
         row?.FromUser?.person?.middleName
           ? row?.FromUser?.person?.middleName + " "
           : ""
       } 
       ${row?.FromUser?.person?.lastName || ""}`,
                  };
                }
              }
              if (dataUser?.data?.user?.id === row.fromUserId) {
                return {
                  ...row,
                  type: "debit",
                  name:
                    row?.sapwalletinfo?.type === "merch-headquarter"
                      ? row?.sapwalletinfo?.merchCompany?.name
                      : row?.sapwalletinfo?.type === "merch-store"
                      ? row?.sapwalletinfo?.merchstore?.customStoreName
                      : row?.sapwalletinfo?.user?.person?.firstName +
                        " " +
                        row?.sapwalletinfo?.user?.person?.lastName,
                };
              } else {
                return {
                  ...row,
                  type: "credit",
                  name:
                    row?.FromMerchHeadquarter !== null
                      ? row?.FromMerchCompany?.name
                      : row?.FromMerchStore !== null
                      ? row?.FromMerchStore?.customStoreName
                      : `${row?.FromUser?.person?.firstName || ""} 
       ${
         row?.FromUser?.person?.middleName
           ? row?.FromUser?.person?.middleName + " "
           : ""
       } 
       ${row?.FromUser?.person?.lastName || ""}`,
                };
              }
            }

            if (
              user === "merchant-owner" ||
              user === "merchant-employee" ||
              user === "merchant-admin"
            ) {
              const checkFromUser =
                row?.fromMerchHeadquarter === null &&
                row?.fromMerchStore === null &&
                row?.sapwalletinfo?.user === null;

              if (type === "HQ") {
                if (merchId === row?.fromMerchHeadquarter) {
                  return {
                    ...row,
                    type: "debit",
                    name:
                      row?.sapwalletinfo?.type === "merch-headquarter"
                        ? row?.sapwalletinfo?.merchCompany?.name
                        : row?.sapwalletinfo?.type === "merch-store"
                        ? row?.sapwalletinfo?.merchstore?.customStoreName
                        : row?.sapwalletinfo?.user?.person?.firstName +
                          " " +
                          row?.sapwalletinfo?.user?.person?.lastName,
                  };
                } else {
                  return {
                    ...row,
                    type: "credit",
                    // name:
                    //   checkFromUser === true
                    //     ? row?.FromUser?.person?.firstName +
                    //       " " +
                    //       row?.FromUser?.person?.lastName
                    //     : row?.sapwalletinfo?.user?.person?.firstName +
                    //       " " +
                    //       row?.sapwalletinfo?.user?.person?.lastName,
                    name:
                      checkFromUser === true
                        ? row?.FromUser?.person?.firstName +
                          " " +
                          row?.FromUser?.person?.lastName
                        : row?.FromMerchHeadquarter !== null
                        ? row?.FromMerchCompany?.name || "HQ"
                        : row?.FromMerchStore !== null
                        ? row?.FromMerchStore?.customStoreName || "Store"
                        : row?.sapwalletinfo?.user?.person?.firstName +
                          " " +
                          row?.sapwalletinfo?.user?.person?.lastName,
                  };
                }
              }
              if (type === "store") {
                if (merchId === row?.fromMerchStore) {
                  return {
                    ...row,
                    type: "debit",
                    name:
                      row?.sapwalletinfo?.type === "merch-headquarter"
                        ? row?.sapwalletinfo?.merchCompany?.name
                        : row?.sapwalletinfo?.type === "merch-store"
                        ? row?.sapwalletinfo?.merchstore?.customStoreName
                        : row?.sapwalletinfo?.user?.person?.firstName +
                          " " +
                          row?.sapwalletinfo?.user?.person?.lastName,
                  };
                } else {
                  return {
                    ...row,
                    type: "credit",
                    name:
                      checkFromUser === true
                        ? row?.FromUser?.person?.firstName +
                          " " +
                          row?.FromUser?.person?.lastName
                        : row?.FromMerchHeadquarter !== null
                        ? row?.FromMerchCompany?.name || "HQ"
                        : row?.FromMerchStore !== null
                        ? row?.FromMerchStore?.customStoreName || "Store"
                        : row?.sapwalletinfo?.user?.person?.firstName +
                          " " +
                          row?.sapwalletinfo?.user?.person?.lastName,
                    // name:
                    //   checkFromUser === true
                    //     ? row?.FromUser?.person?.firstName +
                    //       " " +
                    //       row?.FromUser?.person?.lastName
                    //     : row?.sapwalletinfo?.user?.person?.firstName +
                    //       " " +
                    //       row?.sapwalletinfo?.user?.person?.lastName,
                  };
                }
              }
            }

            if (
              user === "employee" ||
              user === "admin" ||
              user === "superadmin"
            ) {
              if (row?.source === "mhsss-payment") {
                return {
                  ...row,
                  type: "debit",
                  name:
                    row?.sapwalletinfo?.type === "merch-headquarter"
                      ? row?.sapwalletinfo?.merchCompany?.name
                      : row?.sapwalletinfo?.type === "merch-store"
                      ? row?.sapwalletinfo?.merchstore?.customStoreName
                      : row?.sapwalletinfo?.user?.person?.firstName +
                        " " +
                        row?.sapwalletinfo?.user?.person?.lastName,
                };
              } else {
                return {
                  ...row,
                  type: "credit",
                  name:
                    row?.sapwalletinfo?.type === "merch-headquarter"
                      ? row?.sapwalletinfo?.merchCompany?.name
                      : row?.sapwalletinfo?.type === "merch-store"
                      ? row?.sapwalletinfo?.merchstore?.customStoreName
                      : row?.sapwalletinfo?.user?.person?.firstName +
                        " " +
                        row?.sapwalletinfo?.user?.person?.lastName,
                };
              }
            }
            return row;
          });

          setData(updatedRows);
          setAfterApi(true);
        })
        .catch((err) => {});
    }
  }, []);
  useEffect(() => {
    if (user === "employee" && !privilege.includes("View sap wallet history")) {
      return;
    }
    if (
      user !== "pensioner" &&
      user !== "merchant-owner" &&
      user !== "merchant-employee"
    ) {
      const tok =
        user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

      const api = `v1/sapwallet-histories/for-mhsss?limit=10&skip=0&page=1&sortBy=createdAt%3Adesc&source=mhsss-payment`;
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        })
        .then((data) => {
          setDataTrans(data?.data?.rows);
        })
        .catch((err) => {});
    }
  }, []);

  // useEffect(() => {
  //   if (user !== "pensioner") {
  //     setData(transactions);
  //   }
  // }, []);

  return (
    <div className="h-full">
      <div
        className={`${
          user === "pensioner" ||
          user === "merchant-employee" ||
          user === "merchant-owner" ||
          user === "merchant-admin"
            ? ""
            : "hidden"
        }`}
      >
        <div className="flex justify-center items-center bg-[#F6FBF9] sm:mt-0 mt-2 sm:mx-0 mx-2">
          <div className="flex flex-col items-center">
            <div className="flex flex-col justify-center items-center  p-4">
              <img src={qrE} alt="qrE" className="mx-auto" />

              {user === "merchant-owner" ||
              user === "merchant-employee" ||
              user === "merchant-admin" ? (
                <div
                  onClick={() => {
                    if (dataUser?.data?.merchStatus?.status === "approved") {
                      setShowScanner(true);
                      setErr("");
                    } else if (
                      dataUser?.data?.merchuser?.status === "approved"
                    ) {
                      setShowScanner(true);
                      setErr("");
                    }
                    // else if (
                    //   user === "merchant-employee" &&
                    //   !priv.includes("Accept payment")
                    // ) {
                    //   setErr("Insufficient Privileges");
                    //   setShowError(true);
                    // }
                    else {
                      setErr("User is not Approved");
                      setShowError(true);
                    }
                  }}
                  className="rounded-md flex items-center gap-3 bg-primary justify-center h-[2.5rem] mt-6  cursor-pointer border border-primary p-2 w-[80%]"
                >
                  <img src={qrN} alt="qrE" className="" />
                  <p className="font-bold text-white">Make Payment</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {showScanner && (
        <div className="w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center">
          <div className="relative rounded-xl sm:w-[30rem] w-[24rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div className="mt-4">
              <QrScanner
                delay={200}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "100%" }}
              />
            </div>
            {scanResult && (
              <div className="bg-[#F6FBF9] p-3 mt-5">
                <p>Scanned QR Code: {scanResult}</p>
              </div>
            )}
            {err && (
              <p className="text-center mt-[3rem] mb-5 text-red-500">{err}</p>
            )}
            <div className="mt-4 flex gap-4">
              <button
                onClick={() => {
                  setErr("");
                  setShowScanner(false);
                }}
                className="cursor-pointer rounded-xl pt-2 text-center pb-2 pl-3 pr-3 text-white bg-[#DE2827] sm:w-[12rem] w-[10rem]"
              >
                Close
              </button>
              <button
                onClick={() => {
                  setShowScanner(false);
                  setShowPaymentIdPopup(true);
                }}
                className="cursor-pointer rounded-xl pt-2 text-center pb-2 pl-3 pr-3 text-white bg-primary sm:w-[12rem] w-[10rem]"
              >
                Enter Payment ID
              </button>
            </div>
          </div>
        </div>
      )}

      {showPaymentIdPopup && (
        <div className="w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center">
          <div className="relative rounded-xl sm:w-[20rem] w-[24rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div className="mt-4 w-[80%]">
              <h1 className="mb-4 w-full text-center font-semibold">
                Unable to scan QR Code?
              </h1>
              <label
                htmlFor=""
                className="cursor-pointer mb-2 text-gray-700 font-semibold text-sm inline-flex items-center"
              >
                Enter Payment ID
              </label>
              <input
                type="text"
                value={paymentId}
                onChange={(e) => setPaymentId(e.target.value)}
                placeholder="Enter Payment ID"
                className="border rounded-md p-2 w-full"
              />
            </div>
            <div className="w-[80%] flex gap-2">
              <button
                onClick={() => setShowPaymentIdPopup(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[50%] mt-4"
              >
                Cancel
              </button>
              <button
                onClick={checkPayment}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[50%] mt-4"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className={`bg-[#F6FBF9] p-3 flex flex-col gap-1 sm:mx-0 mx-2 ${
          user === "pensioner"
            ? "h-[20rem] mt-5"
            : user === "merchant-owner" ||
              user === "merchant-employee" ||
              user === "merchant-admin"
            ? "h-[16rem] mt-5"
            : "hidden"
        }`}
      >
        <h1 className="font-semibold text-md mb-2">Recent Transactions</h1>
        {afterApi === true && data?.length === 0 ? (
          <div className="flex items-center h-full justify-center h-full">
            <p className="text-gray-400 text-sm">No transaction yet</p>
          </div>
        ) : (
          <div className="h-full">
            {data?.map((data) => (
              <>
                <div
                  key={data.id}
                  className="flex justify-between items-center cursor-pointer "
                  onClick={() => {
                    if (data?.source === "merchant-transaction") {
                      router("/SapMoney/Pensioner/programTransaction/detail", {
                        state: {
                          id: data?.merchtransactionId,
                          selectedTransactionsVouchers: data,
                        },
                      });
                      return;
                    }

                    if (data?.source === "mhsss-payment") {
                      router("/SapMoney/Merchant/MHSSSTransaction/detail", {
                        state: {
                          id: data?.merchtransactionpaymentId,
                          selectedTransactionsVouchers: data,
                        },
                      });
                      return;
                    }

                    router("/SapMoney/Pensioner/generalTransaction/detail", {
                      state: {
                        id: data?.id,
                        selectedTransactionsVouchers: data,
                      },
                    });
                  }}
                >
                  <div>
                    <p className={`font-semibold capitalize `}>{data?.name}</p>
                    <p className="text-[0.8rem] py-1">
                      {moment(data?.updatedAt).format("MM/DD/YYYY hh:mm A")}{" "}
                      {""}
                    </p>
                  </div>
                  <div>
                    <p
                      className={`${
                        data?.type === "credit" ? "bg-primary" : "bg-[#F55F6D]"
                      } text-white px-3 rounded-md`}
                    >
                      ${data?.amount ? data?.amount : ""}
                    </p>
                  </div>
                </div>
                <hr className="py-1" />
              </>
            ))}
            <p
              className={`text-primary mr-auto cursor-pointer font-semibold ${
                data?.length >= 0 ? "" : "hidden"
              }`}
              onClick={() => {
                // if (user === "pensioner") {
                //   router("/SapMoney/Pensioner/AllTransactions");
                // } else {
                //   router("/SapMoney/Merchant/AllTransactions");
                // }
                router("/SapMoney/WalletTransactions");
              }}
            >
              View All
            </p>
          </div>
        )}
      </div>
      <div
        className={`bg-[#F6FBF9] p-3 flex flex-col gap-1 ${
          user === "pensioner"
            ? "hidden"
            : user === "merchant-owner" ||
              user === "merchant-employee" ||
              user === "merchant-admin"
            ? "hidden"
            : "h-full"
        }`}
      >
        <h1 className="font-semibold mb-2">Recent Transactions</h1>
        {afterApi === true && dataTrans?.length === 0 ? (
          <div className="flex items-center h-full justify-center h-full">
            <p className="text-gray-400 text-sm">No transaction yet</p>
          </div>
        ) : (
          <div className="h-full">
            {dataTrans?.map((data) => (
              <>
                <div
                  key={data.id}
                  className="flex justify-between items-center "
                >
                  <div>
                    <p className={`font-semibold text-sm capitalize`}>
                      {data?.sapwalletinfo?.type === "merch-headquarter"
                        ? data?.sapwalletinfo?.merchCompany?.name
                        : data?.sapwalletinfo?.type === "merch-store"
                        ? data?.sapwalletinfo?.merchstore?.customStoreName
                        : data?.sapwalletinfo?.user?.person?.firstName +
                          " " +
                          data?.sapwalletinfo?.user?.person?.lastName}
                    </p>
                    <p className="text-[0.8rem] py-1">
                      {moment(data?.updatedAt).format("MM/DD/YYYY hh:mm A")}{" "}
                      {""}
                    </p>
                  </div>
                  <div>
                    <p
                      className={`${
                        data?.objectType === "merch-transactions" ||
                        (data?.objectType === "parent-child" &&
                          data?.type === "credit")
                          ? "bg-primary"
                          : "bg-[#F55F6D]"
                      } text-white px-3 rounded-md`}
                    >
                      ${data?.amount ? data?.amount : ""}
                    </p>
                  </div>
                </div>
                <hr className="py-1" />
              </>
            ))}
            <p
              className={`text-primary mr-auto cursor-pointer font-semibold ${
                data?.length >= 0 ? "" : "hidden"
              }`}
              onClick={() => {
                // if (user === "pensioner") {
                //   router("/SapMoney/Pensioner/AllTransactions");
                // } else {
                //   router("/SapMoney/Merchant/AllTransactions");
                // }
                if (
                  user === "employee" &&
                  !privilege.includes("View sap wallet history")
                ) {
                  setErr("You don't have privilege to access this");
                  setShowError(true);
                  return;
                }
                router("/SapMoney/WalletTransactions");
              }}
            >
              View All
            </p>
          </div>
        )}
      </div>

      {show && (
        <div className="w-[100%] h-[100%] fixed z-[300] top-0 left-0 bg-slate-500/50 flex justify-center items-center">
          <div className="relative rounded-xl w-[30rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[25px] left-[42%]"
              onClick={() => setShow(false)}
            >
              <img src={logo} alt="Close" />
            </div>
            <div className="mt-4 w-[80%] mt-[6rem]">
              <h1 className="mb-4 w-full text-center font-semibold">{err}</h1>
            </div>
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%] mt-4"
            >
              OK
            </button>
          </div>
        </div>
      )}

      {showError && <ErrorModal err={err} setShow={setShowError} />}
    </div>
  );
};

export default Right;
