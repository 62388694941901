import React from "react";
import Eaccount from "./main";
const Main = () => {
  return (
    // <div className="flex flex-col w-full min-h-[82vh] relative overflow-hidden rounded-md bg-white">

    //   <div className="w-full h-[3.3rem] bg-[#D9EFED]"></div>
    //   <div className="w-full relative">
    //     <div className="absolute top-[-60px] left-1/2 flex border-[7px] border-[#D9EFED] items-center justify-center transform -translate-x-1/2 rounded-full h-[120px] w-[120px]">
    //       <img
    //         src={prof}
    //         alt=""
    //         className="h-full w-full object-cover rounded-full"
    //       />
    //     </div>
    //     <div className="p-4 sm:pl-10 pt-8 w-[36%] mt-10 flex items-center flex-col gap-2">
    //       <div className="w-full flex items-center justify-between border-b pb-3">
    //         <h1 className="font-weight-regular ">Name</h1>
    //         <h1 className="font-semibold text-sm">Paul Graf</h1>
    //       </div>
    //       <div className="w-full flex items-center justify-between border-b pb-3">
    //         <h1 className="font-weight-regular ">Employee Id</h1>
    //         <h1 className="font-semibold text-sm">12245</h1>
    //       </div>
    //       <div className="w-full flex items-center justify-between border-b pb-3">
    //         <h1 className="font-weight-regular ">DOB</h1>
    //         <h1 className="font-semibold text-sm">26-01-1981</h1>
    //       </div>
    //       <div className="w-full flex items-center justify-between border-b pb-3">
    //         <h1 className="font-weight-regular ">Gender</h1>
    //         <h1 className="font-semibold text-sm">Male</h1>
    //       </div>
    //       <div className="w-full flex items-center justify-between border-b pb-3">
    //         <h1 className="font-weight-regular ">Role</h1>
    //         <h1 className="font-semibold text-sm">Accountant</h1>
    //       </div>
    //       <div className="w-full flex items-center justify-between border-b pb-3">
    //         <h1 className="font-weight-regular ">Email ID</h1>
    //         <h1 className="font-semibold text-sm">Paul@gmail.com</h1>
    //       </div>
    //       <div className="w-full mt-6 flex items-center justify-center">
    //         <button
    //           // onClick={handlePrint}
    //           className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
    //         >
    //           Change Password
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   <img
    //     src={bg}
    //     alt="bg"
    //     className="absolute bottom-[-1.4rem] right-[-1.2rem] mb-4 mr-4 h-[12rem] w-[22rem]"
    //   />
    // </div>
    <div>
      <Eaccount />
    </div>
  );
};

export default Main;
