import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import SearchFilter from "./SearchFilter";
const Paget = () => {
  const router = useNavigate();
  const [sortBy, setSortBy] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const columnHeaders = [
    "SR.",
    "Date/Time",
    "Transection ID",
    "Description",
    "Program Name",
    "Money Out",
    "Money In",
    "Balance",
  ];

  const allData = [
    {
      index: 1,
      DateTime: "2023-01-01 / 02:10:30",
      TransactionID: "T123",
      Description: "Paid to Liana via E-wallet",
      Program: "SCP",
      MO: "$200",
      MI: "",
      Balance: "$45000",
    },
    {
      index: 2,
      DateTime: "2023-02-15 / 08:45:20",
      TransactionID: "T124",
      Description: "Received from John via Bank Transfer",
      Program: "SCP",
      MO: "",
      MI: "$350",
      Balance: "$45350",
    },
    {
      index: 3,
      DateTime: "2023-03-20 / 12:30:15",
      TransactionID: "T125",
      Description: "Paid for groceries",
      Program: "SCP",
      MO: "$100",
      MI: "",
      Balance: "$45250",
    },
    {
      index: 4,
      DateTime: "2023-04-05 / 17:20:10",
      TransactionID: "T126",
      Description: "Received salary",
      Program: "SCP",
      MO: "",
      MI: "$3000",
      Balance: "$48250",
    },
    {
      index: 5,
      DateTime: "2023-05-10 / 21:15:45",
      TransactionID: "T127",
      Description: "Paid rent",
      Program: "SCP",
      MO: "$800",
      MI: "",
      Balance: "$47450",
    },
    {
      index: 6,
      DateTime: "2023-06-25 / 14:55:30",
      TransactionID: "T128",
      Description: "Received investment returns",
      Program: "SCP",
      MO: "$5000",
      MI: "$1500",
      Balance: "$51950",
    },
  ];

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl ">
        <div className=" relative sm:rounded-lg overflow-hidden">
          <div className="flex flex-row items-center justify-end mb-4 gap-2">
            {/* search */}

            {/* filter */}
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="relative flex items-center">
                <h1 className="mr-2 font-semibold text-[15px]">Sort By : </h1>
                <select
                  id="sortByDropdown"
                  className="w-full md:w-auto pl-4 pr-4 h-[40px] text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="all">ALL</option>
                  <option value="name">Sort by Name</option>
                  <option value="date">Sort by Date</option>
                </select>
              </div>
            </div>

            <div className="w-full md:w-[20rem]">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search"
                    required=""
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
              <tr>
                {columnHeaders.map((columnHeader) => (
                  <th
                    scope="col"
                    className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                  >
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            {allData?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center text-gray-500 text-lg">
                    <p className="p-10">No data found</p>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {allData.map((data, index) => (
                  <tr
                    onClick={() => {
                      router("/MHSSS/outstanding/person");
                    }}
                    className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                  >
                    <th
                      scope="row"
                      className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap "
                    >
                      {index + 1}.
                    </th>

                    <td className="px-4 py-4 whitespace-nowrap">
                      <div>{data.DateTime}</div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      {data.TransactionID}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      {data.Description}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      {data.Program}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">{data.MO}</td>
                    <td className="px-4 py-4 whitespace-nowrap">{data.MI}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-semibold text-primary">
                      {data.Balance}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <nav
          className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500 ">
            Showing{" "}
            <span className="font-semibold text-gray-900 ">Page {1} </span>
            of <span className="font-semibold text-gray-900 ">{100}</span>
          </span>
          <div className={`flex justify-end mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={20 * 10}
              showSizeChanger={false}
              onChange={""}
              current={1}
            />
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Paget;
