import { Collapse } from "antd";
import Store from "../../../../Merchant/Profile/Tabs/Stores/Store";
const { Panel } = Collapse;
const Stores = ({ stores }) => {
  return (
    <div className="w-full">
      <div className="p-2 rounded-lg">
        <div className="">
          <Collapse
            // defaultActiveKey={["1"]}
            // accordion={true}
            // onChange={callback}
            className="flex flex-col gap-4 border-none bg-[#F6F4F4]"
          >
            {stores?.map((store, index) => {
              return (
                <Panel
                  header={store?.customStoreName}
                  key={index + 1}
                  className="p-3 text-md text-gray-800 font-medium bg-white rounded-md shadow-none border-none"
                >
                  <Store title={store?.customStoreName} data={store} />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Stores;
