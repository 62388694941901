import React from "react";
import dec from "../../../imgs/dec.svg";
const RejectModal = ({ setShow, title, text, setNote, note, func }) => {
  const handleButtonClick = () => {
    func(); // Call the function passed as a prop
  };
  return (
    <div
      className={` fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
    >
      <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
        {/* <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center rounded-full"> */}
        <img src={dec} alt="" className="w-[4rem] h-[4rem]" />
        {/* </div> */}
        <h1 className="font-bold mt-2 text-red-600 text-[1.1rem] capitalize">
          {title}
        </h1>
        <p className="text-center w-[90%] text-[#757575] mt-3 mb-10">{text}</p>
        <div className="flex flex-col w-[85%]">
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Type reason here
          </label>
          <textarea
            type="text"
            name=""
            onChange={(e) => setNote(e.target.value)}
            value={note}
            placeholder="Type Reason here..."
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
        </div>
        <div className="w-[85%] flex items-center justify-center gap-2">
          <button
            onClick={() => {
              setShow(false);
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[50%]"
          >
            Cancel
          </button>

          <button
            onClick={handleButtonClick}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[50%]"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;
