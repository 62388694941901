import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import img from "../../../imgs/impLogo.png";
import merchImg from "../../../imgs/merchImg.png";
import WalletImg from "../../../imgs/walletImg.png";
import payrol from "../../../imgs/payroll.png";
import AttendanceImg from "../../../imgs/AttendanceImg.png";
import SocialImg from "../../../imgs/SocialImg.png";
import StockImg from "../../../imgs/StockImg.png";
import MaintenanceImg from "../../../imgs/MaintenanceImg.png";
import pensionerImg from "../../../imgs/pensioners.png";
import employeeImg from "../../../imgs/employeeimg.png";
import EventsImg from "../../../imgs/EventsImg.png";
import across from "../../../imgs/impLogo.png";
import { message } from "antd";
const FirstR = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const option = useSelector((state) => state.option);
  const [messageApi, contextHolder] = message.useMessage();
  const items = [
    {
      key: "1",
      title: `Pensioner`,
      img: pensionerImg,
    },
    {
      key: "2",
      title: `Employee`,
      img: employeeImg,
    },
    {
      key: "3",
      title: `Merchant`,
      img: merchImg,
    },
    // {
    //   key: "4",
    //   title: `SAP Money`,
    //   img: WalletImg,
    // },
    {
      key: "5",
      title: `Events`,
      img: EventsImg,
    },
    {
      key: "6",
      title: `Social Benefits`,
      img: SocialImg,
    },
    {
      key: "7",
      title: `Stocks`,
      img: StockImg,
    },
    {
      key: "8",
      title: `Maintenance`,
      img: MaintenanceImg,
    },
    {
      key: "9",
      title: `Attendance`,
      img: AttendanceImg,
    },
    {
      key: "10",
      title: `Payroll`,
      img: payrol,
    },
  ];

  const optHandler = (e) => {
    if (e === "Pensioner") {
      dispatch({ type: "PENSIONER" });
      // setPc(true);
      setFlag(false);
      // setEc(false);
      // setMc(false);
    }
    if (e === "Employee") {
      dispatch({ type: "EMPLOYEE" });
      setFlag(true);
      // setPc(false);
      // setEc(true);
      // setMc(false);
    }
    if (e === "Merchant") {
      dispatch({ type: "MERCHANT" });
      // setPc(false);
      setFlag(false);
      // setEc(false);
      // setMc(true);
    }
  };

  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };
  return (
    <div>
      <div className="w-full flex items-center justify-center flex-col">
        <div className="sm:w-[75%] w-[80%] text-center sm:mt-[3rem] mt-[1rem] flex flex-col items-center">
          {/* <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
          Register
        </h2> */}
          <p className="text-[#596F96] text-[0.9rem]">
            Use the approved credentials provided by the Ministry of Human
            Services and Social Security (MHSSS) to access information on Senior
            Citizen Pension applications, status, and services via your account.
          </p>
        </div>
        <div className="sm:mt-0 mt-4 sm:w-[80%] w-[90%] flex justify-center items-center ">
          <div className="mt-[2rem] flex flex-wrap justify-center sm:gap-y-0 gap-y-4 gap-x-5">
            {contextHolder}
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  dispatch({ type: "answersSet", num: [] });
                  if (item.title === "Pensioner") {
                    optHandler("Pensioner");
                    // setSelectedCard(index);
                  } else if (item.title === "Employee") {
                    optHandler("Employee");
                    // setSelectedCard(index);
                  } else if (item.title === "Merchant") {
                    optHandler("Merchant");
                    // setSelectedCard(index);
                  }
                  // else if (item.title === "SAP Money") {
                  //   optHandler("SAP Money");
                  //   setSelectedCard(index);
                  // }
                  else {
                    Success();
                  }
                }}
                className={`relative w-[150px] rounded-md sm:mt-[30px] mb-[30px] h-[90px] p-2 cursor-pointer ${
                  option === item.title ? "bg-[#1BA397]" : "bg-[#D9EFED]"
                }`}
              >
                <div className="absolute top-[-35px] left-1/2 flex p-5 border-[3px] border-white p-[1px] items-center justify-center transform -translate-x-1/2 bg-[#8BD6B5] rounded-full h-[70px] w-[70px]">
                  <img src={item?.img} alt="" className="h-[35px] w-[35px]" />
                </div>
                <div className="flex items-center justify-center flex-col mt-3 w-full h-full">
                  <div className="text-center mb-1 h-[30px] ">
                    <h1
                      className={`${
                        option === item.title ? "text-white" : "text-black"
                      } text-[15px] font-bold`}
                      style={{ fontFamily: "poppins", fontWeight: "600" }}
                    >
                      {item?.title}
                    </h1>
                  </div>
                </div>
              </div>
            ))}
            {/* <div
            onClick={() => optHandler("Pensioner")}
            className="relative sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={pensioner1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Pensioner</p>
            <div
              className={`bg-primary top-2 right-2 rounded-sm absolute ${
                pc && option === "Pensioner" ? "" : "hidden"
              }`}
            >
              <img src={check} className="lg:w-5 w-5" alt="" />
            </div>
          </div>
          <div
            onClick={() => optHandler("Employee")}
            className="relative sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={employee1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Employee</p>
            <div
              className={`bg-primary  absolute top-2 right-2 rounded-sm ${
                ec && option === "Employee" ? "" : "hidden"
              }`}
            >
              <img src={check} className="lg:w-5 w-5" alt="" />
            </div>
          </div>
          <div
              onClick={() => optHandler("Merchant")}
              className="relative  sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
            >
              <div>
                <img className="w-[4rem] mb-4" src={merchant1} alt="" />
              </div>
              <p className="text-[0.85rem] font-semibold">Merchant</p>
              <div
                className={`bg-primary  absolute top-2 right-2 rounded-sm ${
                  mc && option === "Merchant" ? "" : "hidden"
                }`}
              >
                <img src={check} className="lg:w-5 w-5" alt="" />
              </div>
            </div>
            <div
            onClick={Success}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={wallet1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">SCP eWallet</p>
          </div>
          <div
            onClick={Success}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4 rounded-xl" src={events1} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Events</p>
          </div>
          <div
            onClick={Success}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={social2} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Social Benefits</p>
          </div>
          <div
            onClick={Success}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={Stock} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Stock</p>
          </div>
          <div
            onClick={Success}
            className="sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
          >
            <div>
              <img className="w-[4rem] mb-4" src={Maintenance} alt="" />
            </div>
            <p className="text-[0.85rem] font-semibold">Maintenance</p>
          </div>
          <div
              onClick={Success}
              className=" sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
            >
              <div>
                <img className="w-[4rem] mb-4" src={Attendance} alt="" />
              </div>
              <p className="text-[0.85rem] font-semibold">Attendance</p>
            </div>
            <div
              onClick={Success}
              className=" sm:w-[10rem] w-[9rem] sm:h-[10rem] h-[9rem] bg-[#F2F4F8] rounded-lg flex flex-col items-center justify-center"
            >
              <div>
                <img className="w-[4rem] mb-4" src={payroll} alt="" />
              </div>
              <p className="text-[0.85rem] font-semibold">Payroll</p>
            </div> */}
          </div>
        </div>
        <div className="flex justify-center h-[50px] items-center">
          <button
            onClick={() => {
              if (flag || option === "Employee") setShow(true);
              else dispatch({ type: "RSET", num: 1 });
            }}
            className="cursor-pointer mt-10 bg-gradient-to-tr bg-primary pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
          >
            Next
          </button>
        </div>
        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center p-[5rem]`}
        >
          <div className="relative rounded-xl w-[20rem] flex items-center bg-white flex-col">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "80px", height: "80px", marginTop: "5px" }}
                src={across}
                alt=""
              />
            </div>
            <div
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{ fontWeight: "bold", fontSize: "22px" }}
                className="text-primary"
              >
                SCP
              </h1>
            </div>
            <p className="text-center p-[1rem] mb-1">
              This website is for use by authorized personnel of the Ministry of
              Human Services and Social Security. Any attempt by anyone else is
              considered illegal, and false information provided for
              registration will be dealt with in accordance with the Laws of
              The Country .
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "15px",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => setShow(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Cancel
              </button>
              <button
                style={{ marginLeft: "8px" }}
                onClick={() => dispatch({ type: "RSET", num: 1 })}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstR;
