import React from "react";
import { useSelector } from "react-redux";
import bg from "../../../../imgs/pagebottom.png";
import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import logo from "../../../../imgs/logomhs.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const Main = () => {
  const sapmoney=useSelector((state)=>state.sapmoney)
  const router = useNavigate();
  const totaPayment = useSelector((state) => state.totalPayment);
  const selectedTransactionsVouchers = useSelector(
    (state) => state.selectedTransactionsVouchers
  );

  const selectedTransactions = useSelector(
    (state) => state.selectedTransactions
  );
  console.log("selectedTransactions", selectedTransactions);

  const afterPaymentData = useSelector((state) => state.afterPaymentData);
  console.log("afterPaymentData", afterPaymentData);

  const handlePrint = () => {
    const transactionType = afterPaymentData?.paymentMethod;

    // Capitalize first word
    const capitalizedTransactionType =
      transactionType?.charAt(0).toUpperCase() + transactionType?.slice(1);

    const data = {
      transactionId: afterPaymentData?.transactionPaymentId,
      transactionDate: moment(afterPaymentData?.createdAt).format(
        "MM/DD/YYYY hh:mm A"
      ),
      transactionType:
        transactionType === "sap-wallet"
          ? "SAP-Wallet"
          : capitalizedTransactionType,
      programName:
        selectedTransactionsVouchers?.[0]?.pensionprogram?.name ===
        "senior-citizen-Pension"
          ? "Senior Citizen Pension"
          : selectedTransactionsVouchers?.[0]?.pensionprogram?.name,
      merchantsName: selectedTransactions?.merchuser?.merchcompany?.name
        ? selectedTransactions?.merchuser?.merchcompany?.name
        : "-----",
      // +
      // " " +
      // selectedTransactions?.merchuser?.merchperson?.lastName,
      store: selectedTransactions?.merchstore
        ? selectedTransactions?.merchstore?.customStoreName
        : "------",
      status: "Paid",
      amount: `$${afterPaymentData?.amountPaid + afterPaymentData?.feePaid}`,
      pensionerName: "Pensioner",
      voucherCode: "12X3",
      email: "info@example.com",
      phone: "+234XXXXXXXX",
    };
    const mhsss = logo;
    const callImg = slipcall;
    const emailImg = slipemail;
    const bg = slipbg;

    // Create a temporary iframe
    const printFunc = (mhsss, callImg, emailImg) => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentWindow.document;

      // Construct the printable content
      let printableContent = `
        <html>
            <head>
                <title>Print</title>
                <style>
                    body {
                        font-size: 25pt;
                    }
                    @media print {
                        .printable-container {
                            -webkit-print-color-adjust: exact;
                            color-adjust: exact; 
                            background-color: #ffffff;
                            background-image: url('${bg}');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.2rem;
                            margin-bottom: 1rem;
                            padding: 0.5rem;
                        }
                        .d1 {
                            border-radius: 0.25rem;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            padding-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .d2 {
                            width: 6rem; 
                            height: 5rem;
                            padding-top: 0.5rem;
                        }
                        .d3 {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .hh1 {
                            font-weight: 600;
                            text-align: center;
                            width: 65%;
                        }
                        .d4 {
                            display: flex;
                            width: 80%;
                            flex-direction: column;
                            border-bottom: 1px solid #e2e8f0;
                            padding-bottom: 0.5rem;
                            font-size: 1rem;
                        }
                        .pp {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .ss {
                            color: #718096;
                        }
                        .d5 {
                            display: flex;
                            flex-direction: column;
                            padding-top: 0.5rem;
                            font-size: 1rem;
                            width: 80%;
                        }
                        .d6 {
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                        }
                        .call {
                            width: 1.25rem;
                            height: 1.25rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .mail {
                            width: 1.25rem;
                            height: 1rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .d7 {
                            display: flex;
                            width: 100% ;
                            align-items: center;
                        }
                        .sss {
                            font-weight: 600;
                        }
                    }
                </style>
            </head>
            <body>
                <div class="printable-container">
                    <div class="d1">
                        <img id="mhsss" alt="chippz" class="d2" src="${mhsss}" />
                        <div class="d3">
                            <h4 class="hh1">Ministry of Human Services and Social Security</h4>
                        </div>
                        <div class="d4">
                            <p class="pp">
                                <span class="ss">Payment ID</span>
                                <span>${data.transactionId}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Payment Date</span>
                                <span>${data.transactionDate}</span>
                            </p>
                            <p class="pp">
                            <span class="ss">Payment Method</span>
                            <span>${data.transactionType}</span>
                        </p>
                            <p class="pp">
                                <span class="ss">Program Name</span>
                                <span>${data.programName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Merchants Name</span>
                                <span>${data.merchantsName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Store</span>
                                <span class="sss">${data.store}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Status</span>
                                <span class="sss">${data.status}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Amount</span>
                                <span class="sss">${data.amount}</span>
                            </p>
                        </div>
                        <div class="d5">
                            <p class="pp">
                                <span class="ss">Pensioner Name</span>
                                <span>${data.pensionerName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Voucher Code</span>
                                <span>${data.voucherCode}</span>
                                </p>
                                <div class="d6">
                                    <p class="d7">
                                        <img id="emailImg" class="mail" alt="chippz" src="${emailImg}" />
                                        ${data.email}
                                    </p>
                                    <p class="d7">
                                        <img id="callImg" class="call" alt="chippz" src="${callImg}" />
                                        ${data.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        `;

      // Set the iframe content
      iframeDocument.open();
      iframeDocument.write(printableContent);
      iframeDocument.close();

      // Print the iframe content
      iframe.contentWindow.print();

      // Remove the iframe from the DOM after printing
      document.body.removeChild(iframe);
    };
    const imageSources = [mhsss, callImg, emailImg];

    // Load images and print content
    let loadedImages = 0;
    imageSources.forEach((src) => {
      const img = new Image();
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageSources.length) {
          printFunc(mhsss, callImg, emailImg);
        }
      };
      img.src = src;
    });
  };

  return (
    <div className="flex flex-col w-full overflow-hidden min-h-[82vh] relative rounded-md bg-white">
      <div className="w-full rounded-t-md flex items-center text-white h-[4rem] bg-primary p-2">
        <h1 className="font-semibold ml-6">
          Total Amount Paid{" "}
          <span className="ml-6 font-bold text-white text-[16px]">{`$${totaPayment}`}</span>
        </h1>
      </div>
      <div className="p-4 sm:pl-10 pt-8 w-[60%] sm:w-[40%] flex items-center flex-col gap-2">
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400 text-sm">Payment ID</h1>
          <h1 className="font-semibold text-sm">
            {afterPaymentData?.transactionPaymentId}
          </h1>
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400">Payment Date</h1>
          <h1 className="font-semibold text-sm">
            {moment(afterPaymentData?.createdAt).format("MM/DD/YYYY hh:mm A")}
          </h1>
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400">Payment Method</h1>
          <h1 className="font-semibold text-sm capitalize">
            {afterPaymentData?.paymentMethod === "sap-wallet"
              ? "SAP-Wallet"
              : afterPaymentData?.paymentMethod}
          </h1>
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400">Program Name</h1>
          <h1 className="font-semibold text-sm">
            {selectedTransactionsVouchers?.[0]?.pensionprogram?.name ===
            "senior-citizen-Pension"
              ? "Senior Citizen Pension"
              : selectedTransactionsVouchers?.[0]?.pensionprogram?.name}
          </h1>
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400">Store</h1>
          <h1 className="font-bold text-sm">
            {selectedTransactions?.merchstore
              ? selectedTransactions?.merchstore?.customStoreName
              : "------"}
          </h1>
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400">Merchants Name</h1>
          <h1 className="font-semibold text-sm">
            {selectedTransactions?.merchuser?.merchcompany?.name
              ? selectedTransactions?.merchuser?.merchcompany?.name
              : "-----"}
          </h1>
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="font-semibold text-gray-400">Status</h1>
          <h1 className="font-bold text-sm">Paid</h1>
        </div>
        <div className="w-full mt-8 flex flex-row gap-4">
          <button
            onClick={handlePrint}
            className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
          >
            Print Receipt
          </button>
          <button
            onClick={() => {
              if(sapmoney===true){
                router("/SapMoney/MHSSS/Merchants")
                return
              }
              router("/MHSSS/outstanding");
            }}
            className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
          >
            Done
          </button>
        </div>
      </div>
      <img
        src={bg}
        alt="bg"
        className="absolute bottom-[-1rem] right-[-1.2rem] mb-4 mr-4 h-[16rem] w-[26rem]"
      />
    </div>
  );
};

export default Main;
