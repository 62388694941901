import Ellipse from "../../../imgs/backg.svg";
import car from "../../../imgs/car.png";
import driver from "../../../imgs/driver.png";
import trip from "../../../imgs/trip.png";
import vendor from "../../../imgs/vendor.png";
import back5 from "../../../imgs/back5.png";
import maintenance from "../../../imgs/Maintenance.jpg";
import img from "../../../imgs/impLogo.png";
import { useLocation, useNavigate } from "react-router";
import { message } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
let items;
const AdminPanel = () => {
  const location = useLocation();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const [showAllContentIndex, setShowAllContentIndex] = useState(-1);

  const handleToggleContent = (index) => {
    setShowAllContentIndex(index === showAllContentIndex ? -1 : index);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  items = [
    {
      key: "1",
      title: `Vehicles`,
      content: "View edit or add a Vehicles.",
      img: car,
      route: "/Maintenance-Vehicles",
    },
    {
      key: "2",
      title: `Drivers`,
      content: "View edit or add a Drivers.",
      img: driver,
      route: "/Maintenance-Drivers",
    },
    {
      key: "3",
      title: `Vendors`,
      content:
        "Take a closer look at the vendors and discover the wide range of services they offer.",
      img: vendor,
      route: "/Maintenance-Vendors",
    },
    {
      key: "4",
      title: `Trips`,
      content: "View the history of all of the trips and their locations.",
      img: trip,
      route: "/Maintenance-Trips",
    },
    {
      key: "5",
      title: `Maintenance Records`,
      content: "View Maintenance records and all Maintenances for Vehicles.",
      img: maintenance,
      route: "/Maintenance-Records",
    },
  ];

  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${Ellipse})`,
        backgroundSize: "cover",
        backgroundPosition: "center 10%",
        backgroundPositionY: "-80px",
      }}
    >
      {contextHolder}
      <div className="flex items-center justify-center">
        <div className="md:w-[85%] mt-[3rem] rounded-md w-[95%] p-[4rem] pt-[2rem] mb-16  sm:min-h-[960px] bg-[#F8F5F5] shadow-lg">
          <div
            onClick={() => {
              if (user === "superadmin") router("/superAdminDashboard");
              else if (user === "admin") router("/adminDashboard");
              else if (user === "employee") router("/EDashboard");
              else router("/Dashboard");
            }}
            className="cursor-pointer flex w-full items-center justify-center"
          >
            <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              Maintenance
            </h2>
          </div>
          <div className="w-full flex flex-wrap mt-[4.5rem] justify-center items-center gap-8 md:gap-x-8 gap-y-14 sm:min-h-[200px]">
            {items?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  if (item.route === "") {
                    Success();
                  } else router(item?.route);
                }}
                className="relative w-[220px] rounded-md mb-[30px] h-[150px] p-2 cursor-pointer custom-shadow"
                style={{
                  background: "linear-gradient(white, #F8F5F5)",
                  height: `150px`,
                }}
              >
                <div className="absolute top-[-40px] left-1/2 flex p-5 border-[3px] border-[#F8F5F5] p-[1px] items-center justify-center transform -translate-x-1/2 bg-[#8BD6B5] rounded-full h-[80px] w-[80px]">
                  <img src={item?.img} alt="" className="h-[40px] w-[40px]" />
                </div>
                <div className="flex items-center justify-center flex-col mt-6 w-full h-full">
                  <div className="flex items-center mt-1 h-full gap-y-3 justify-center w-full flex-col">
                    <div className="text-center h-[30px] ">
                      <h1
                        className="text-black text-[16px] font-bold"
                        style={{ fontFamily: "poppins", fontWeight: "600" }}
                      >
                        {item?.title}
                      </h1>
                    </div>
                    <div className="text-center min-h-[60px]">
                      <p
                        className="text-black text-[13px]"
                        style={{ fontFamily: "poppins", fontWeight: "500" }}
                      >
                        {showAllContentIndex === index ||
                        item?.content.length <= 45
                          ? item?.content
                          : `${item?.content.substring(0, 45)}...`}
                        {item?.content.length > 45 && (
                          <p
                            className="text-[#F35929] z-10"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleToggleContent(index);
                            }}
                          >
                            {showAllContentIndex === index
                              ? "Read Less"
                              : "Read More"}
                          </p>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
