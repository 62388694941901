import React, { useEffect, useState } from "react";
import pay from "../../../../../imgs/Activeperson_pin.svg";
import voucher from "../../../../../imgs/voucher.svg";
import o1 from "../../../../../imgs/o1.svg";
import o2 from "../../../../../imgs/o2.svg";
import o3 from "../../../../../imgs/o3.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Complete from "../../../../Merchant/Pensioner/Profile/CompleteProfie";
import moment from "moment";
import { CalendarFilled, SlidersFilled } from "@ant-design/icons";
import axios from "axios";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import { message } from "antd";
import { RightOutlined } from "@ant-design/icons";
const ProfileCard = () => {
  const router = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);

  // console.log(selectedPensioner);
  const dash = useLocation().pathname;
  const completeProfile = useSelector((state) => state.completeProfile);
  const token = useSelector((state) => state.token);
  const [activeCoupons, setActiveCoupons] = useState(0);
  const [pensionBook, setPensionBook] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const sapmoney = useSelector((state) => state.sapmoney);

  const options = [
    {
      title: "Voucher Cashing",
      image: o1,
      router: `/Merchant/SapMoney/SelectVouchers`,
      bg: "#F1FFFE",
      arr: "#1BA397",
    },
    {
      title: "Pay Money",
      image: o2,
      //   router: "/Merchant/PensionerProfile/Vouchers",
      bg: "#FCEFEB",
      arr: "#F35929",
    },
    {
      title: "Request Money",
      image: o3,
      router: "/SapMoney/Sender",
      bg: "#F5ECCD",
      arr: "#F3C729",
    },
  ];

  useEffect(() => {
    dispatch({
      type: "PENSIONER_SENDER_TYPE_VERIFICATION",
      num: false,
    });
  }, []);
  const fetchPenioner = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/search/for-merch?gid=${selectedPensioner?.user?.gid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        dispatch({
          type: "SAP_PAYMENT_SELECTED_PERSON",
          num: {
            id: data?.data?.id,
            type: data?.data?.type,
            fromId: data?.data?.user?.id,
            image:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.image
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.image
                : data?.data?.merchheadquarter?.image,

            email:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.email
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryEmail
                : data?.data?.merchheadquarter?.primaryEmail,

            name:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.firstName +
                  " " +
                  data?.data?.user?.person?.lastName
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.customStoreName
                : data?.data?.merchCompany?.name,

            phone:
              data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryPhone
                : data?.data?.type === "merch-headquarter"
                ? data?.data?.merchheadquarter?.primaryPhone
                : data?.data?.type === "pensioner"
                ? data?.data?.user?.countryCode && data?.data?.user?.phoneNo
                  ? `${data?.data?.user?.countryCode}${data?.data?.user?.phoneNo}`
                  : "-------"
                : "-------",
          },
        });
        router("/SapMoney/Merchant/Pay/PhoneNumber", {
          state: { penProfile: true },
        });
      })
      .catch((err) => {
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowErrModal(true);
        console.log(err);
      });
  };

  const fetchReqPensioner = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/search/for-merch?gid=${selectedPensioner?.user?.gid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        dispatch({
          type: "SAP_PAYMENT_SELECTED_PERSON",
          num: {
            uuid:data?.data?.user?.uniqueId,
            id: data?.data?.id,
            type: data?.data?.type,
            fromId: data?.data?.user?.id,
            image:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.image
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.image
                : data?.data?.merchheadquarter?.image,

            email:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.email
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryEmail
                : data?.data?.merchheadquarter?.primaryEmail,

            name:
              data?.data?.type === "pensioner"
                ? data?.data?.user?.person?.firstName +
                  " " +
                  data?.data?.user?.person?.lastName
                : data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.customStoreName
                : data?.data?.merchCompany?.name,

            phone:
              data?.data?.type === "merch-store"
                ? data?.data?.merchstore?.primaryPhone
                : data?.data?.type === "merch-headquarter"
                ? data?.data?.merchheadquarter?.primaryPhone
                : data?.data?.type === "pensioner"
                ? data?.data?.user?.countryCode && data?.data?.user?.phoneNo
                  ? `${data?.data?.user?.countryCode}${data?.data?.user?.phoneNo}`
                  : "-------"
                : "-------",
          },
        });
        router("/SapMoney/Merchant/Request/Verification");
      })
      .catch((err) => {
        // setShow(false);
        setErr(err?.response?.data?.message);
        setShowErrModal(true);
        console.log(err);
      });
  };

  return (
    <div className={`${sapmoney ? "mt-5" : "sm:mt-[1rem] mt-3"}`}>
      {contextHolder}
      <div className="sm:mx-4 mb-12">
        <div className="sm:p-8 p-3 bg-white rounded-lg">
          <div className="w-full mb-10 relative">
            <div className="bg-[#F5F5F5] p-6 rounded-md">
              <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-400"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-3 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div>
              <div
                className={`flex justify-between items-center mt-8 md:mt-0 lg:mt-0 `}
              >
                <div className="flex items-center sm:flex-row flex-col ">
                  <div className="sm:w-28 sm:h-28 w-20 h-20 bg-white border  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                    <img
                      src={selectedPensioner?.user?.person?.image}
                      alt="profile"
                      className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                    />
                  </div>
                  <div className="ml-4 space-y-1 ">
                    <h1 className="text-xl font-semibold text-primary capitalize">
                      {selectedPensioner?.user?.person?.firstName}{" "}
                      {selectedPensioner?.user?.person?.middleName &&
                        selectedPensioner?.user?.person?.middleName + " "}
                      {selectedPensioner?.user?.person?.lastName}{" "}
                      {/* <span className="font-light text-gray-600">27</span> */}
                    </h1>
                    <div className="space-y-1">
                      <p className="text-md font-medium text-gray-700">
                        GID :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.gid}
                        </span>
                      </p>
                      <p className={`text-md font-medium text-gray-600 `}>
                        DOB :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.person?.dateOfBirth}
                        </span>
                      </p>
                    </div>
                    <button
                      className={`${
                        completeProfile === true ? "hidden" : ""
                      }  text-primary mt-2 font-bold`}
                      onClick={() => {
                        dispatch({ type: "COMPLETE_PROFILE", num: true });
                      }}
                    >
                      View Complete Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${completeProfile === true ? "hidden" : ""}`}>
              {options?.map((option, index) => {
                return (
                  <div
                    key={index}
                    style={{ backgroundColor: option.bg }}
                    onClick={() => {
                      if (option.title === "Pay Money") {
                        fetchPenioner();
                        return;
                      }
                      router(option.router);
                    }}
                    className={`p-5 rounded-md mt-4 flex items-center justify-between border cursor-pointer border-gray-200 shadow-sm bg-[${option.bg}]`}
                  >
                    <div className="flex items-center">
                      <img
                        src={option.image}
                        alt="option"
                        className="h-12 w-12"
                      />
                      <div className="ml-4">
                        <h1 className="text-lg font-semibold text-gray-800 capitalize">
                          {option.title}
                        </h1>
                        <p className="text-md font-semibold text-gray-500">
                          {option.title === "Voucher Cashing"
                            ? "Redeem program vouchers for pensioners"
                            : option.title === "Pay Money"
                            ? "Send/Add money to pensioner’s wallet"
                            : "Request money from pensioner"}
                        </p>
                      </div>
                    </div>
                    <button
                      className="text-primary font-semibold"
                      onClick={() => {
                        if (option.title === "Pay Money") {
                          fetchPenioner();
                          return;
                        }
                        router(option.router);
                      }}
                    >
                      <RightOutlined
                        style={{
                          fontSize: "19px",
                          color: option?.arr,
                          fontWeight: "bold",
                        }}
                      />
                    </button>
                  </div>
                );
              })}

              {/* Request Div */}
              <div
                onClick={() => {}}
                className={`bg-[#F6F4F4] p-5 rounded-md mt-4 flex items-center justify-center flex-col  `}
              >
                <div
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    fetchReqPensioner();
                    // router("/SapMoney/Merchant/Request/Verification");
                  }}
                >
                  <h1 className="text-[1rem] font-semibold text-gray-800 capitalize text-center bg-white px-12 py-1 rounded-md  border-primary border text-primary">
                    View Requests
                  </h1>
                </div>
                <p className="text-md  text-[#898585] text-center sm:w-[70%] mt-3 w-full">
                  You can view and respond to requests on behalf of the
                  pensioner, but to proceed with this process, you must first
                  verify the pensioner
                </p>
              </div>
            </div>
            {/* <hr className="h-px my-8 bg-gray-300 border-0 " /> */}

            <div className={`${completeProfile === true ? "" : "hidden"}`}>
              <Complete />
            </div>
          </div>
        </div>
      </div>
      {showErrModal && <ErrorModal err={err} setShow={setShowErrModal} />}
    </div>
  );
};

export default ProfileCard;
