import { Checkbox, Input } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "./table";

const SearchDiv = () => {
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [amount, setAmount] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [feeType, setFeeType] = useState("");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const dataUser = useSelector((state) => state.dataUser);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [allData, setAllData] = useState([]);
  const [afterSearch, setAfterSearch] = useState(false);
  const paymentMethods = [
    {
      id: 1,
      paymentMethod: "Bank",
      value: "bank",
    },
    {
      id: 2,
      paymentMethod: "SAP-Wallet",
      value: "sap-wallet",
    },
    {
      id: 3,
      paymentMethod: "Cheque",
      value: "cheque",
    },
  ];

  const Search = (pageNumber = 1) => {
    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled by the user.");
    }

    // Create a new cancel token source for this request
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    const params = {
      limit: 4,
      skip: (pageNumber - 1) * 4,
      page: pageNumber, // Separate page for search and non-search scenarios
      sortBy: "createdAt:desc",
      ...(paymentTerms && { paymentTerms }),
      ...(amount && { feeAmount:amount }),
      ...(selectedOption && { voucherCashingCharges: feeType }),
      ...(selectedPayment.length > 0 && {
        defaultPayMethods: selectedPayment,
      }),
    };

    fetchPenioners(params, newCancelTokenSource);
  };

  const fetchPenioners = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-owners-by-payment-filters/for-mhsss`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setTotalPages(data?.data?.totalPages);
        setAllData(data?.data?.rows);
        setAfterSearch(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePaymentClick = (paymentValue) => {
    setSelectedPayment((prevSelected) => {
      if (prevSelected.includes(paymentValue)) {
        return prevSelected.filter((value) => value !== paymentValue);
      } else {
        return [...prevSelected, paymentValue];
      }
    });
  };

  const handlePerTransactionChange = (e) => {
    setSelectedOption(e.target.checked ? "Per Transaction" : "");
    setFeeType(e.target.checked ? "per transaction" : "");
  };

  const handlePerVoucherChange = (e) => {
    setSelectedOption(e.target.checked ? "Per Voucher" : "");
    setFeeType(e.target.checked ? "per voucher" : "");
  };

  useEffect(() => {
    if (afterSearch) {
      Search(page);
    }
  }, [page]);

  const handleSearchClick = () => {
    setPage(1);
    Search(1);
  };

  return (
    <div className="w-full bg-white px-5 py-6 pr-12 rounded-md">
      <p>You can search merchant with following transaction method </p>
      <div className="flex flex-col gap-2 mt-3">
        <h1 className="font-bold">Select payment method</h1>

        <div className="w-full h-auto rounded-lg gap-6 flex items-center p-4 bg-[#F6F4F4]">
          {paymentMethods.map((method) => (
            <Checkbox
              key={method.id}
              className={`checkk5 bg-white rounded-md sm:px-3 px-3 py-1 font-semibold shadow-lg transition-colors duration-200 ease-in-out cursor-pointer`}
              onClick={() => handlePaymentClick(method.value)}
            >
              {method.paymentMethod}
            </Checkbox>
          ))}
        </div>
      </div>
      <div className="flex sm:gap-10 gap-3 mt-5">
        <div className="flex-1 ">
          <h1 className="font-semibold">Voucher Cashing Charges</h1>
          <div className="w-full pl-6 pr-6 pb-4 pt-4 bg-[#F6F4F4] rounded-md mt-2">
            <div className="w-full flex gap-6 ">
              <Checkbox
                className="checkk4"
                checked={selectedOption === "Per Transaction"}
                onChange={handlePerTransactionChange}
              >
                Per Transaction
              </Checkbox>
              <Checkbox
                className="checkk4"
                checked={selectedOption === "Per Voucher"}
                onChange={handlePerVoucherChange}
              >
                Per Voucher
              </Checkbox>
            </div>

            <div className="flex items-center gap-x-4 mt-5">
              <h1 className="font-semibold">Amount :</h1>
              <Input
                className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-white"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Amount"
              />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <h1 className="font-semibold">Payment Terms</h1>
          <div className="w-full mt-2 bg-white rounded-md">
            <div className="flex items-center gap-x-4">
              <select
                value={paymentTerms}
                onChange={(e) => setPaymentTerms(e.target.value)}
                className="w-full h-[2.4rem] pl-4 pr-4 rounded-lg bg-[#F6F4F4]"
              >
                <option value="" selected hidden>
                  Payment Terms
                </option>
                <option value={10}>Net 10</option>
                <option value={15}>Net 15</option>
                <option value={30}>Net 30</option>
                <option value={45}>Net 45</option>
                <option value={60}>Net 60</option>
                <option value={90}>Net 90</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full mb-7">
        <button
          className="bg-primary text-white w-full sm:w-auto px-14 rounded-md p-2 mt-7 text-[0.9rem]"
          onClick={handleSearchClick}
        >
          Search
        </button>
      </div>
      <div className={`${afterSearch ? "" : "hidden"}`}>
        <h1 className="font-semibold py-2">Merchant Listing</h1>
        <Table
          allData={allData}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default SearchDiv;
