import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Main = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const data = useSelector((state) => state.selectedGovAgency);
  console.log(data);
  const [statusData, setStatusData] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const reqDetail =
    dash === "/MHSSS/GovernmentAgency/Requests/detail" ? true : false;

  const hasLowBalance = data?.limit > data?.balance ? true : false;

  const Div = ({ title, content }) => {
    return (
      <div className="flex flex-row justify-between">
        <div
          className={`${
            title === "Total available Balance :"
              ? "font-bold text-md font-medium leading-6 text-gray-800"
              : "text-md font-medium leading-6 text-gray-600 "
          } `}
        >
          {title}
        </div>
        <div
          // className={`${
          //   title === "Total available Balance :"
          //     ? "bg-red-500 text-white px-5 py-1"
          //     : " text-gray-700 break-words"
          // }text-md leading-6 rounded-md`}
          className={`${
            title === "Total available Balance :" && hasLowBalance === true
              ? "bg-red-500 text-white px-5 py-1"
              : title === "Total available Balance :" && hasLowBalance === false
              ? "bg-primary text-white px-5 py-1"
              : "text-gray-700 break-words"
          } text-md leading-6 rounded-md`}
        >
          {content}
        </div>
      </div>
    );
  };
  return (
    <div className="flex gap-5 px-4 py-3 sm:flex-row flex-col">
      <div className="flex-1 bg-[#FAFAFA] p-4 ">
        <header className="px-2 py-2 flex gap-2 items-center flex-col text-center bg-[#FAFAFA]">
          <div className="w-full flex items-center gap-2 p-2 ">
            <img
              className={`${"inline-flex object-cover border-4 border-primary rounded-full  bg-primary text-indigo-600 h-24 w-24"}`}
              src={data?.image}
              alt=""
            />
            <div className="h-full flex flex-col ml-2 items-center text-left">
              <h1 className={"text-gray-700 font-bold"}>{data?.name}</h1>
              <h2 className="text-sm text-primary font-bold">Government</h2>
            </div>
          </div>
          <div className={`${reqDetail ? "hidden" : ""}`}>
            <div className="flex flex-wrap w-full">
              <h2 className="w-[50%] text-left font-semibold text-[15px] text-gray-600">
                Phone Number :
              </h2>
              <p className="w-[50%] text-right  text-[14px] break-words text-gray-700">
                {data?.phone}
              </p>
            </div>
            <div className="flex flex-wrap w-full justify-between">
              <h2 className="text-left font-semibold text-[15px] text-gray-600">
                Email :
              </h2>
              <p className="text-right  text-[14px] text-gray-700 break-words">
                {data?.email}
              </p>
            </div>
          </div>
        </header>
      </div>
      <div className="flex-1 bg-[#FAFAFA] p-4">
        <div className="flex flex-col gap-1">
          <Div title="Phone :" content={data?.phone} />
          <Div title="Email :" content={data?.email} />
          <Div title="Low Limit :" content={`$${data?.limit}`} />
          <Div
            title="Total available Balance :"
            content={`$${data?.balance ? data?.balance : "0"}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
