import Nav from "../components/Nav/nav";
import EMainDash from "../components/Dash/mainDash";
import Whatsapp from "../Whatsapp/whatsapp";
const EDashboard = () => {
  return (
    <div>
      <Nav />
      <EMainDash />
      <Whatsapp/>
    </div>
  );
};

export default EDashboard;
