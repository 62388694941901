import { useNavigate } from "react-router-dom";
import ser from "../../../imgs/ser.svg";
import { useDispatch } from "react-redux";
const Paget = () => {
  const router = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
        {/* <PageHeader header={true} title={selectedProgram} route={"/pensionerVerification/selectProgram"} /> */}

        <div className="flex justify-center">
          <div
            className={`bg-white shadow-sm rounded-sm mx-4 m-5 p-12 sm:w-[90%] w-full flex items-center justify-center flex-col `}
          >
            <img
              src={ser}
              alt="Verify Pensioner"
              className="w-12 h-12 mt-2 mb-3"
            />
            <h1 className="font-semibold text-lg text-gray-500 mb-3">
              No Record Found
            </h1>
            <p className="w-[30%] text-center text-gray-400">
              You can also search for the pensioner using other options. If the
              pensioner is still not found, you may add a new pensioner.
            </p>
            <div className="flex justify-center mt-6">
              <button
                onClick={() => {
                  router("/pensionerVerification/listing");
                }}
                className="bg-[#1BA397] text-white px-6 py-2 rounded-md w-[12rem]"
              >
                Search Again
              </button>
            </div>
            <div className="flex justify-center mt-4">
              <button
                onClick={() => {
                  dispatch({ type: "ISET", num: 0 });
                  dispatch({ type: "PENSIONER" });
                  router("/EDashboard/addPensioner");
                  dispatch({ type: "Reverse" });
                }}
                className="bg-white border border-primary text-[#1BA397] px-6 py-2 rounded-md w-[12rem]"
              >
                Add New Pensioner
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paget;
