import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import trash from "../../../imgs/trash.png";
import across from "../../../imgs/across.png";
import { DatePicker } from "antd";
import moment from "moment";
import cross from "../../../imgs/cross.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  LeftCircleFilled,
  RightCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
let file;
const FourthI = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const popData = useSelector((state) => state.popData);
  const location = useLocation();
  const dash = location.pathname;
  const [display, setDisplay] = useState("");
  const [display1, setDisplay1] = useState("");
  const [bookletNo1, setBookletNo1] = useState("");
  const [bookletNo2, setBookletNo2] = useState("");
  const [issueDate1, setIssueDate1] = useState("");
  const [issueDate2, setIssueDate2] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [pen1done, setPen1Done] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [show1, setShow1] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [err, setErr] = useState(false);
  const [wow, setWow] = useState(4);
  const [allDis, setAllDis] = useState(true);
  console.log(issueDate1, issueDate2);
  const changeHandler = (e) => {
    if (e === "forward" && wow <= 4) {
      setWow(wow + 1);
    } else if (e === "back" && wow >= 1) {
      setWow(wow - 1);
    }
  };

  useEffect(() => {
    if (wow === 4 || wow === 5) {
      setAllDis(false);
    } else {
      setAllDis(true);
    }
  }, [wow]);
  const Year = ["2020", "2021", "2022", "2023", "2024", "2025", "2026"];
  // const Bnumber = ["DFSSD", "ERFEW", "FDERT", "FSDWE", "REFGT"];
  const imageHandler = (setState, check) => async (e) => {
    if (check === "1") {
      setIsLoading(true);
    } else {
      setIsLoading1(true);
    }

    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        if (check === "1") {
          setIsLoading(false);
          setImageUrl1(data.data.url);
          e.target.value = null;
        } else {
          setIsLoading1(false);
          setImageUrl2(data.data.url);
          e.target.value = null;
        }
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const nextHandler = (e) => {
    e.preventDefault();
    let body = {};
    let body1 = {};
    if (bookletNo1) {
      body = {
        bookletNo: `${bookletNo1}`,
        issueDate: `${issueDate1}`,
        frontImg: `${imageUrl1}`,
        year: `${Year[4]}`,
      };
    }
    if (bookletNo2) {
      body1 = {
        bookletNo: `${bookletNo2}`,
        issueDate: `${issueDate2}`,
        frontImg: `${imageUrl2}`,
        year: `${Year[5]}`,
      };
    }
    if (dash === "/EDashboard/addPensioner") {
      if (bookletNo1 && bookletNo2 === "") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData.id}`,
            { bookInfo: body },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            if (
              err.response.data.message ===
              '"frontImg" is not allowed to be empty'
            ) {
              setErr(`Please upload front image for year ${Year[4]}`);
            } else {
              setErr(err.response.data.message);
            }
            setShow1(true);
          });
      } else if (bookletNo2 && bookletNo1 === "") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData.id}`,
            { bookInfo: body1 },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            if (
              err.response.data.message ===
              '"frontImg" is not allowed to be empty'
            ) {
              setErr(`Please upload front image for year ${Year[5]}`);
            } else {
              setErr(err.response.data.message);
            }
            setShow1(true);
          });
      } else if (bookletNo1 && bookletNo2) {
        if (!pen1done) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData.id}`,
              { bookInfo: body },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              setPen1Done(true);
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData.id}`,
                  { bookInfo: body1 },
                  {
                    headers: {
                      Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                    },
                  }
                )
                .then(() => {
                  dispatch({ type: "IINCREMENT" });
                })
                .catch((err) => {
                  if (
                    err.response.data.message ===
                    '"frontImg" is not allowed to be empty'
                  ) {
                    setErr(`Please upload front image for year ${Year[5]}`);
                  } else {
                    setErr(err.response.data.message);
                  }
                  setShow1(true);
                });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                '"frontImg" is not allowed to be empty'
              ) {
                setErr(`Please upload front image for year ${Year[4]}`);
                setShow1(true);
              } else {
                setErr(err.response.data.message);
                setShow1(true);
              }
            });
        } else if (pen1done) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData.id}`,
              { bookInfo: body1 },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "IINCREMENT" });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                '"frontImg" is not allowed to be empty'
              ) {
                setErr(`Please upload front image for year ${Year[5]}`);
              } else {
                setErr(err.response.data.message);
              }
              setShow1(true);
            });
        }
      }
    } else {
      if (bookletNo1 && bookletNo2 === "") {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/pension-books`, body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            if (
              err.response.data.message ===
              '"frontImg" is not allowed to be empty'
            ) {
              setErr(`Please upload front image for year ${Year[4]}`);
            } else {
              setErr(err.response.data.message);
            }
            setShow1(true);
          });
      } else if (bookletNo2 && bookletNo1 === "") {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/pension-books`, body1, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            dispatch({ type: "IINCREMENT" });
          })
          .catch((err) => {
            if (
              err.response.data.message ===
              '"frontImg" is not allowed to be empty'
            ) {
              setErr(`Please upload front image for year ${Year[5]}`);
            } else {
              setErr(err.response.data.message);
            }
            setShow1(true);
          });
      } else if (bookletNo1 && bookletNo2) {
        if (!pen1done) {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/v1/pension-books`, body, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(() => {
              setPen1Done(true);
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/v1/pension-books`,
                  body1,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then(() => {
                  dispatch({ type: "IINCREMENT" });
                })
                .catch((err) => {
                  if (
                    err.response.data.message ===
                    '"frontImg" is not allowed to be empty'
                  ) {
                    setErr(`Please upload front image for year ${Year[5]}`);
                  } else {
                    setErr(err.response.data.message);
                  }
                  setShow1(true);
                });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                '"frontImg" is not allowed to be empty'
              ) {
                setErr(`Please upload front image for year ${Year[4]}`);
              } else {
                setErr(err.response.data.message);
              }
              setShow1(true);
            });
        } else if (pen1done) {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/v1/pension-books`, body1, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(() => {
              dispatch({ type: "IINCREMENT" });
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                '"frontImg" is not allowed to be empty'
              ) {
                setErr(`Please upload front image for year ${Year[5]}`);
              } else {
                setErr(err.response.data.message);
              }
              setShow1(true);
            });
        }
      }
    }
    if (!body.bookletNo && !body1.bookletNo) {
      // dispatch({ type: "IINCREMENT" });
      setErr(`Please add Pensionbook to continue registration.`);
      setShow1(true);
    }
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const bookletHandler = (e, check) => {
    if (check === "1") {
      setBookletNo1(e.target.value);
    } else {
      setBookletNo2(e.target.value);
    }
  };
  // const dateHandler = (e, check) => {
  //   if (check === "1") {
  //     setBookletNo1(e.target.value);
  //   } else {
  //     setBookletNo2(e.target.value);
  //   }
  // };
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  const dateHandler = (setState, check) => (event) => {
    if (event === null || event === undefined || event === "") {
      // Handle the case when the date picker is cleared
      setState(null);
      return;
    }
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[40%] w-[80%] mt-4"
        onSubmit={nextHandler}
      >
        <div
          className={`${allDis ? "pointer-events-none" : ""}  ${
            wow === 4 ? "" : "hidden"
          } flex flex-col`}
        >
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Current Booklet #
          </label>
          <input
            type="text"
            name=""
            placeholder="Current Booklet #"
            onChange={(e) => {
              bookletHandler(e, "1");
            }}
            value={bookletNo1}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
        </div>
        <div
          className={`${allDis ? "pointer-events-none" : ""}  ${
            wow === 4 ? "" : "hidden"
          } flex flex-col`}
        >
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Issue Date :
          </label>
          <DatePicker
            placeholder="Date Issued"
            showTime
            format="MM/DD/YYYY hh:mm A"
            disabledDate={disabledDate1}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            onChange={(date, dateString) => {
              setIssueDate1(dateString);
            }}
          />
          {/* <DatePicker
            placeholder="Date Issued"
            disabledDate={disabledDate1}
            onChange={dateHandler(setIssueDate1, "tgf")}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          /> */}
        </div>
        <div
          className={`${allDis ? "pointer-events-none" : ""} ${
            wow === 5 ? "" : "hidden"
          } flex flex-col`}
        >
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Current Booklet #
          </label>
          <input
            type="text"
            name=""
            placeholder="Current Booklet #"
            onChange={(e) => {
              bookletHandler(e, "2");
            }}
            value={bookletNo2}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
        </div>
        <div
          className={`${allDis ? "pointer-events-none" : ""} ${
            wow === 5 ? "" : "hidden"
          } flex flex-col`}
        >
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Issue Date :
          </label>
          <DatePicker
            placeholder="Date Issued"
            showTime
            format="MM/DD/YYYY hh:mm A"
            disabledDate={disabledDate1}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            onChange={(date, dateString) => {
              setIssueDate2(dateString);
            }}
          />
          {/* <DatePicker
            placeholder="Date Issued"
            disabledDate={disabledDate1}
            onChange={dateHandler(setIssueDate2, "tgf")}
            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          /> */}
        </div>
        <div
          className={`${wow === 5 || wow === 4 ? "hidden" : ""} flex flex-col`}
        >
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Current Booklet #
          </label>
          <input
            type="text"
            name=""
            placeholder="Current Booklet #"
            className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
        </div>
        <div
          className={`${wow === 5 || wow === 4 ? "hidden" : ""} flex flex-col`}
        >
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Issue Date :
          </label>
          <DatePicker
            placeholder="Date Issued"
            disabledDate={disabledDate1}
            // onChange={handleChange(setBirthDate, "tgf")}
            className="pointer-events-none lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
        </div>
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Image Front
        </h2>
        <label
          htmlFor="special-input"
          className={`cursor-pointer relative ${
            allDis ? "pointer-events-none" : ""
          } ${wow === 4 ? "" : "hidden"}`}
        >
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
            </div>
            <p className="text-[#9CA6C2] ml-2">Image Front</p>
          </div>
          <input
            type="file"
            className="hidden"
            id="special-input"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler(setDisplay, "1")}
          />
        </label>
        <div
          className={`relative mb-4 ${display && wow === 4 ? "" : "hidden"}`}
        >
          {/* <div
            className={`absolute ${
              isLoading ? "" : "hidden"
            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
          >
            <Spin size="large" />
          </div> */}
          <img
            onClick={() => {
              setIShow(true);
            }}
            className="w-[25rem] h-[13rem]"
            src={display}
            alt=""
          />
          <div
            className="absolute bottom-0 right-0 bg-white cursor-pointer"
            onClick={() => {
              setDisplay("");
              setImageUrl1("");
            }}
          >
            <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
          </div>
        </div>
        <label
          htmlFor="special-input1"
          className={`cursor-pointer relative ${
            allDis ? "pointer-events-none" : ""
          } ${wow === 5 ? "" : "hidden"}`}
        >
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
            </div>
            <p className="text-[#9CA6C2] ml-2">Image Front</p>
          </div>
          <input
            type="file"
            className="hidden"
            id="special-input1"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler(setDisplay1, "2")}
          />
        </label>
        <label
          htmlFor="special-input5"
          className={`cursor-pointer relative pointer-events-none
           ${wow === 5 || wow === 4 ? "hidden" : ""}`}
        >
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
            </div>
            <p className="text-[#9CA6C2] ml-2">Image Front</p>
          </div>
          <input
            type="file"
            className="hidden"
            id="special-input5"
            accept="image/png, image/gif, image/jpeg"
          />
        </label>
        <div
          className={`relative mb-4 ${display1 && wow === 5 ? "" : "hidden"}`}
        >
          {/* <div
            className={`absolute ${
              isLoading1 ? "" : "hidden"
            } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
          >
            <Spin size="large" />
          </div> */}
          <img className="w-[25rem] h-[13rem]" src={display1} alt="" />
          <div
            className="absolute bottom-0 right-0 bg-white cursor-pointer"
            onClick={() => {
              setDisplay1("");
              setImageUrl2("");
              const wow = document.getElementById("special-input5");
              wow.value = "";
            }}
          >
            <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
          </div>
        </div>
        <div
          className={`${
            iShow ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
        >
          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
            <img
              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
              src={cross}
              onClick={() => setIShow(false)}
              alt=""
            />
            <img className="w-[45rem] h-[28rem]" src={display} alt="" />
          </div>
        </div>
        <div>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Previous Booklets
          </h2>
          <div className="flex w-full items-center mt-4">
            <div
              onClick={() => changeHandler("back")}
              className={` w-[35%] cursor-pointer`}
            >
              <LeftCircleFilled
                className={`${wow === 0 ? "hidden" : ""} text-primary text-2xl`}
              />
            </div>
            <p className="w-[35%] bg-[#F7F7F7] p-1 pl-3 pr-3 rounded-xl text-center text-[#9CABC8]">
              {Year[wow]}
            </p>
            <div
              onClick={() => changeHandler("forward")}
              className={` w-[35%] cursor-pointer flex justify-end`}
            >
              <RightCircleFilled
                className={`${
                  wow === 5 ? "hidden" : ""
                }  text-primary text-2xl`}
              />
            </div>
          </div>
          {/* <p className="text-center mt-4 mb-4 font-bold">{Bnumber[wow]}</p> */}
        </div>

        <div className="lg:mt-[3rem] flex mt-8 items-center justify-end">
          {/* <button
            type="button"
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          <button
            type="submit"
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default FourthI;
