import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import thumb from "../../../../../imgs/Thumb.png";
import logo from "../../../../../imgs/mhsss_Accounting-logo.png";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import { PlusCircleFilled, DeleteFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
let file;
const Main = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const totaPayment = useSelector((state) => state.totalPayment);
  const selectedTransactionsVouchers = useSelector(
    (state) => state.selectedTransactionsVouchers
  );
  const [err, setErr] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const selectedPaymentMethod = useSelector(
    (state) => state.selectedPaymentMethod
  );
  const selectedOutstandingMerchant = useSelector(
    (state) => state.selectedOutstandingMerchant
  );
  const [display, setDisplay] = useState(null);
  const [receiptImg, setReceiptImg] = useState("");
  const [note, setNote] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const mhhsMerchEmpId = useSelector((state) => state.mhhsMerchEmpId);
  const mhhsMerchId = useSelector((state) => state.mhhsMerchId);
  const mhhsMerchUser = useSelector((state) => state.mhhsMerchUser);
  const merchantId =
    mhhsMerchUser === "merchant-employee" ? mhhsMerchEmpId : mhhsMerchId;
  const [data, setData] = useState("");
  useEffect(() => {
    Banking();
  }, []);
  //getBanking
  const Banking = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setData(data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/MHSSS/paymentReceipt");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const Pay = () => {
    const body = {
      merchtransactionId: selectedTransactionsVouchers?.[0]?.merchtransactionId,
      paymentMethod: selectedPaymentMethod,
      receiptImg: receiptImg,
    };

    if (receiptNo !== "") {
      body.receiptNo = receiptNo;
    }

    if (note !== "") {
      body.note = note;
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transaction-payments`,

        body,

        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "AFTER_PAYMENT_DATA", num: data.data });
        setShow(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };

  const imageHandler = (setState, setUrl) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);

    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=paymentRecipet`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setState(base641);
        setUrl(data.data.url);
        e.target.value = null;
      })
      .catch(() => {
        e.target.value = null;
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const maskAccountNumber = (accountNumber) => {
    let maskedNumber = "";
    for (let i = 0; i < accountNumber?.length; i++) {
      if (i < accountNumber?.length - 4) {
        maskedNumber += "X";
      } else {
        maskedNumber += accountNumber[i];
      }
    }
    return maskedNumber;
  };

  return (
    <div>
      <div className="w-full mt-4 px-6 py-3">
        <div
          className={`bg-[#D9EFED] md:w-[40%] sm:w-[50%] w-[80%] relative rounded-lg p-3 cursor-pointer"
        `}
        >
          <div className="flex flex-row items-center p-2 ">
            <div
              className={`absolute top-5 flex items-center justify-center rounded-full w-14 h-14`}
            >
              <img
                src={data?.countrybank?.logo}
                alt="logo"
                className={`"w-14 h-14 rounded-full`}
              />
            </div>
            <div className="flex items-center ml-14">
              <div className="ml-3 flex flex-col mb-4">
                <p className="font-semibold text-lg capitalize">
                  {selectedOutstandingMerchant?.merchuser?.merchcompany?.name}
                </p>
                <p className="text-sm">{data?.countrybank?.name}</p>
                <p>{maskAccountNumber(data?.accountNo)}</p>
              </div>
            </div>

            <div className="absolute bottom-0 right-0 bg-primary text-white p-1 rounded-tl-lg rounded-br-lg px-2">
              Bank
            </div>
          </div>
        </div>

        <div className="mb-4 mt-4">
          <label className="mb-3">Payment Transaction number if any</label>
          <input
            type="text"
            className="w-full p-3 bg-[#F4F5FE]
                rounded-lg mt-2"
            placeholder="Enter transaction #"
            onChange={(e) => {
              setReceiptNo(e.target.value);
            }}
          />
          <textarea
            className="w-full p-3 bg-[#F4F5FE]
                rounded-lg mt-2"
            placeholder="Please provide notes"
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </div>
        <div>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Bank Transfer Receipt
          </h2>
          <label
            //   htmlFor="special-input"
            className={`cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Receipt Image</p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              // id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay, setReceiptImg)}
            />
          </label>
          <div
            className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8]
                          ${display !== null ? "" : "hidden"}`}
          >
            <img
              onClick={() => {
                // setIShow(true);
              }}
              src={display}
              className="w-[13rem] h-[6rem]"
              //   src={GFront}
              alt=""
            />
            <div
              className="absolute bottom-0 right-0 bg-white cursor-pointer"
              onClick={() => {
                setDisplay(null);
                setReceiptImg(null);
              }}
            >
              <DeleteFilled
                className="sm:w-[3rem] w-[2rem]"
                style={{ fontSize: "30px", color: "#1BA397" }}
                onClick={() => {
                  setReceiptImg(null);
                  setDisplay(null);
                }}
              />
            </div>
          </div>
          {/* <div
                  className={`hidden w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      // onClick={() => setIShow(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={"GFront"}
                      alt=""
                    />
                  </div>
                </div> */}
        </div>
        <button
          onClick={() => {
            if (
              receiptImg === "" ||
              receiptImg === null ||
              receiptImg === undefined
            ) {
              setErr("Please upload receipt image");
              setShowErrModal(true);
              return;
            }
            setShowConfirm(true);
          }}
          className={`w-[5rem] mr-1 flex pl-2 pr-2 font-bold items-center justify-center rounded-xl text-white text-[15px] h-[35px]  bg-[#1BA397] cursor-pointer  hover:bg-[#2AB9A7]"
          `}
        >
          Pay Now
        </button>
      </div>

      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${totaPayment}</span> has been
            transferred to the Merchant's account
          </p>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${totaPayment}</span> to{" "}
            {data?.bankName} bank of merchant?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                Pay();
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
    </div>
  );
};

export default Main;
