import React, { useEffect, useState } from "react";
import Header from "../../Dashboard/pageHeader";
import Sidebar from "../../Dashboard/sideBar";
import Main from "../../PayPhoneNumber/merchantPayment/verification";

import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);

  const dispatch = useDispatch();
  const location = useLocation();
  const data = location?.state?.data;
  const  selectedPensioner = useSelector((state) => state.selectedPensioner);
  const merchReq = location?.state?.merchantSideChildReq;
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`${
          windowSize?.width <= 768
            ? "h-screen flex flex-col"
            : "flex flex-row p-5 gap-7 h-screen bg-[#ebfff3] w-full"
        }`}
      >
        {windowSize?.width <= 768 ? (
          <>
            <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
              <MenuUnfoldOutlined
                className="text-2xl cursor-pointer"
                onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
              />
              <h1 className="text-xl font-bold">Verification</h1>
            </header>
            {openSideBar && (
              <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
                <Sidebar />
              </div>
            )}
          </>
        ) : (
          <aside
            className="w-1/5 rounded-lg"
            style={{
              backgroundColor: "white",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
            }}
          >
            <Sidebar />
          </aside>
        )}
        <div
          className={`${
            windowSize?.width <= 768
              ? "flex-grow overflow-y-auto p-4"
              : "flex flex-col w-4/5 gap-5  "
          }`}
        >
          <header className="h-12 shrink-0 rounded-lg">
            <Header
              type={"Verification"}
              header={false}
              // routeFind={payment ? false : true}
              // routeCondition={"PAYMENT"}
              value={false}
              route={-1}
            />
          </header>
          <main
            className={`${
              windowSize?.width <= 768
                ? ""
                : "flex-grow rounded-lg p-3 overflow-y-scroll "
            }`}
            style={
              windowSize?.width <= 768
                ? {}
                : {
                    backgroundColor: "white",
                    boxShadow:
                      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                  }
            }
          >
            {/* Profile */}
            <div className="bg-[#F5F5F5] p-6 rounded-md relative sm:mx-8 mt-4 mx-2">
              <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-400"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-3 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div>
              <div
                className={`flex justify-between items-center mt-8 md:mt-0 lg:mt-0 `}
              >
                <div className="flex items-center sm:flex-row flex-col ">
                  <div className="sm:w-28 sm:h-28 w-20 h-20 bg-white border  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                    <img
                      src={selectedPensioner?.user?.person?.image}
                      alt="profile"
                      className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                    />
                  </div>
                  <div className="ml-4 space-y-1 ">
                    <h1 className="text-xl font-semibold text-primary capitalize">
                      {selectedPensioner?.user?.person?.firstName}{" "}
                      {selectedPensioner?.user?.person?.middleName &&
                        selectedPensioner?.user?.person?.middleName + " "}
                      {selectedPensioner?.user?.person?.lastName}{" "}
                      {/* <span className="font-light text-gray-600">27</span> */}
                    </h1>
                    <div className="space-y-1">
                      <p className="text-md font-medium text-gray-700">
                        GID :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.gid}
                        </span>
                      </p>
                      <p className={`text-md font-medium text-gray-600 `}>
                        DOB :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.person?.dateOfBirth}
                        </span>
                      </p>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
            <Main />
          </main>
        </div>
      </div>
    </>
  );
};

export default Index;
