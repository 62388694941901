import React, { useState } from "react";
import MD1 from "../../../imgs/money.png";
import MD2 from "../../../imgs/merchmhss.png";
import MD3 from "../../../imgs/payroll.png";
import MD4 from "../../../imgs/ReportsImg.png";
import govAge from "../../../imgs/govAge.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
const Card = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [show, setShow] = useState(false);
  const items = [
    {
      key: "1",
      label: `All Payment`,
      img: MD1,
      route: "/MHSSS/payment",
    },
    {
      key: "2",
      label: `Merchants Outstandings`,
      img: MD2,
      route: "/MHSSS/outstanding",
    },
    {
      key: "3",
      label: `Pensioner Financials`,
      img: MD3,
      route: "/MHSSS/pensionerFinancials",
    },
    {
      key: "4",
      label: `Reports`,
      img: MD4,
      route: "/MHSSS/Reports",
    },
    {
      key: "5",
      label: `Government Agencies`,
      img: govAge,
      route: "/MHSSS/GovernmentAgency",
    },
  ];

  return (
    <>
      {/* <div className="flex flex-wrap gap-6 p-4 items-center justify-center cursor-pointer">
      {items.map((item, index) => (
        <div
          key={index}
          className="border-2 h-[11rem] w-[11rem] text-black flex items-center justify-center border-primary p-4 bg-[#D9EFED] rounded-lg shadow-lg hover:bg-[#1BA397] hover:text-white"
          onClick={() => {
            // if (item.key === "3") {
            //   dispatch({ type: "SIDEBAR", num: "3" });
            // }
            if(user === "employee"){
              if(!privilege.includes("View merchant transactions and cashed vouchers")){
                setErr("You don't have privilege to access this")
                setShow(true)
                return
              }
            }
            router(item.route);
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="w-[3rem] h-[3rem] mb-4 rounded-full">
              <img
                src={item.img}
                alt={item.label}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-center font-semibold h-[3rem] text-[16px] mb-2">
              {item.label}
            </div>
          </div>
        </div>
      ))}
    
    </div> */}
      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-8 p-6 cursor-pointer">
        {items.map((item, index) => (
          <div
            key={index}
            className="border-2  text-black flex items-center justify-center border-primary p-4 bg-[#D9EFED] rounded-lg shadow-lg hover:bg-[#1BA397] hover:text-white"
            onClick={() => {
              if (item.key === "3") {
                dispatch({ type: "SIDEBAR", num: "3" });
              }
              router(item.route);
            }}
          >
            <div
              key={index}
              className="flex flex-col justify-center items-center"
            >
              <div className="w-[3rem] h-[3rem] mb-4 overflow-hidden">
                <img
                  src={item.img}
                  alt={item.label}
                  className="w-full h-full"
                />
              </div>
              <div className="text-center font-semibold h-[3rem] text-[16px] mb-2">
                {item.label}
              </div>
            </div>
          </div>
        ))}
      </div>
      {show && <ErrorModal err={""} setShow={setShow} />}
    </>
  );
};

export default Card;
