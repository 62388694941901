import React from 'react'
import Reports from "../components/MHSSS Accounting/Reports"

const paymentM = () => {
  return (
    <div>
     <Reports/>
    </div>
  )
}

export default paymentM