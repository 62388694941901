import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import thumb from "../../../../imgs/Thumb.png";
import logo from "../../../../imgs/sapNew.svg";
import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import logoSCP from "../../../../imgs/logomhs.png";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleFilled } from "@ant-design/icons";
import check from "../../../../imgs/add.png";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import pro from "../../../../imgs/pro.png";
import img from "../../../../imgs/impLogo.png";
import trash from "../../../../imgs/trash.png";
import dec from "../../../../imgs/decline.png";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import FaceVerificatioNModal from "../../../../utlis/FaceVerficationLoaderModal";
import { detectObjectsInImage } from "../../../../utlis/modelLoader";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

let file;
const Main = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location?.pathname;
  const router = useNavigate();
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const selectedPensionerSap = useSelector(
    (state) => state.selectedPensionerSap
  );
  const merchantSapType = useSelector((state) => state.merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);
  const [id, setId] = useState("");
  console.log("merchantSapTypeData", merchantSapTypeData);
  const [isValid, setIsValid] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [display, setDisplay] = useState(pro);
  const [show, setShow] = useState(false);
  const [showR, setShowR] = useState(false);
  const [showReq, setShowReq] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [relation, setRelation] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [reason, setReason] = useState("");
  const [rejReason, setRejReason] = useState("");
  const [gid, setGid] = useState("");
  // const [gidImage, setGidImage] = useState(null);
  const [gidFrontImage, setGidFrontImage] = useState(null);
  const [gidBackImage, setGidBackImage] = useState(null);
  const [image, setImage] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Relation");
  const faceApi = useSelector((state) => state.faceApi);
  const [isPurposeOpen, setIsPurposeOpen] = useState(false);
  const [verify, setVerify] = useState(false);
  const [payStatus, setPayStatus] = useState("merchant_paid");
  const [selectedPurpose, setSelectedPurpose] = useState("Purpose");
  const [gidDisplay, setGidDisplay] = useState(null);
  const [gidBackDisplay, setGidBackDisplay] = useState(null);
  const token = useSelector((state) => state.token);
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);

  useEffect(() => {
    if (user === "pensioner" || dash === "/SapMoney/Sender") {
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/for-merch?pensionerUserId=${selectedPensionerSap?.user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setId(data?.data?.id);
        // setData(data?.data);
        // setAfterApi(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShowError(true);
      });
  }, []);

  useEffect(() => {
    dispatch({ type: "FACE_API", num: false });
  }, []);
  useEffect(() => {
    console.log("Verify", verify);
    if (verify) setPayStatus("merchant_rejected");
    else {
      setPayStatus("merchant_paid");
    }
  }, [verify]);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, type) => {
    if (type === "purpose") {
      setSelectedPurpose(option.label);
      setIsPurposeOpen(false);
      setPurpose(option.value);
    } else {
      setSelectedOption(option.label);
      setIsOpen(false);
      setRelation(option.value);
    }
  };

  const FaceError = () => {
    messageApi.open({
      // type: 'success',
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="mage" width="50" height="50" />
          <h3>
            Please upload your profile image again; it's not verified as a human
            face.
          </h3>
        </div>
      ),
      duration: 6,
    });
  };

  const options = [
    // { label: "Options", value: null },
    { label: "Son", value: "son" },
    { label: "Daughter", value: "daughter" },
    { label: "Grandson", value: "grandson" },
    { label: "Granddaughter", value: "granddaughter" },
    { label: "Father", value: "father" },
    { label: "Mother", value: "mother" },
    { label: "Grandfather", value: "grandfather" },
    { label: "Grandmother", value: "grandmother" },
    { label: "Brother", value: "brother" },
    { label: "Sister", value: "sister" },
    { label: "Uncle", value: "uncle" },
    { label: "Aunt", value: "aunt" },
    { label: "Cousin", value: "cousin" },
    { label: "Nephew", value: "nephew" },
    { label: "Niece", value: "niece" },
  ];

  const purposeOptions = [
    // { label: "Options", value: null },
    { label: "Purchase", value: "purchase" },
    { label: "Subscription", value: "subscription" },
    { label: "Donation", value: "donation" },
    { label: "Membership Fee", value: "membership_fee" },
    { label: "Bill Payment", value: "bill_payment" },
    { label: "Loan Repayment", value: "loan_repayment" },
    { label: "Refund", value: "refund" },
    { label: "Service Fee", value: "service_fee" },
    { label: "Deposit", value: "deposit" },
    { label: "Insurance Premium", value: "insurance_premium" },
    { label: "Birthday Gift", value: "birthday_gift" },
    { label: "Education", value: "education" },
  ];

  const shareLink = (id) => {
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;
    const link = `https://sap-staging.jabcomp.gy/quickPay/${id}`;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${id}/share-link-with-child`,
        { link }, // Correcting the request body format
        {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        }
      )
      .then((response) => {
        // Handle successful response
        console.log("Link shared successfully:", response.data);
        // setShow(true); // Show success modal or message
      })
      .catch((error) => {
        // Handle error response
        console.error("Error sharing link:", error.response.data.message);
        setError(error.response.data.message);
        // setShow(true); // Show error modal or message
      });
  };

  const pay = () => {
    console.log("selected", selectedPensionerSap);
    // if (
    //   user !== "pensioner" ||
    //   user !== "merchant-owner" ||
    //   user !== "merchant-employee"
    // ) {
    //   alert(user)
    //   return;
    // }
    setShowConfirm(false);

    const body = {
      firstName,
      lastName,
      amountPaid,
    };
    if (middleName !== "") {
      body.middleName = middleName;
    }
    if (purpose !== null) {
      body.purpose = purpose;
    }

    if (reason !== "") {
      body.reason = reason;
    }

    if (relation !== null) {
      body.relation = relation;
    }

    if (dateOfBirth !== null) {
      body.dateOfBirth = dateOfBirth;
    }

    if (image !== null) {
      body.image = image;
    }

    if (phoneNoo && countryCode && phone !== "") {
      body.phone = phone;
    }

    if (email !== "") {
      body.email = email;
    }

    if (gid !== "") {
      body.gid = gid;
    }

    if (gidFrontImage !== null) {
      body.gidFrontImage = gidFrontImage;
    }

    if (gidBackImage !== null) {
      body.gidBackImage = gidBackImage;
    }

    if (user === "merchant-owner" || user === "merchant-employee" || user === "merchant-admin") {
      if (merchantSapType === "HQ") {
        body.merchheadquarterId =
          merchantSapTypeData?.merchuser?.merchheadquarterId;
      } else {
        body.merchstoreId = merchantSapTypeData?.id;
      }
      if (dash !== "/SapMoney/Sender") {
        body.payStatus = payStatus;
      }

      if (payStatus === "merchant_rejected") {
        body.rejectionNote = rejReason;
      }
      if (dash === "/SapMoney/Sender") {
        body.pensionerUserId = selectedPensioner?.user?.id;
      } else body.pensionerUserId = selectedPensionerSap?.user?.id;
      if (dash !== "/SapMoney/Sender") {
        body.sapwalletinfoId = id;
        delete body.image;
      }
    }

    // return;

    const api =
      user === "pensioner"
        ? `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/debit`
        : dash === "/SapMoney/Sender"
        ? `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/debit/child-request`
        : `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/credit`;
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

    axios
      .post(api, body, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
      })
      .then((data) => {
        if (dash === `/SapMoney/Sender`) {
          setShowReq(true);
        } else if (
          (user === "merchant-owner" || user === "merchant-employee" || user === "merchant-admin") &&
          payStatus === "merchant_rejected"
        ) {
          setShowR(true);
        } else {
          setShow(true);
        }
        if (user === "pensioner") {
          shareLink(data?.data?.id);
        }
        // router(`/quickpay/${data?.data?.id}`);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShowError(true);
      });
  };

  const handlePrint = () => {
    const data = {
      transactionId: "1214325465",
      transactionDate: "26th Feb 2024",
      transactionType: "Bank Transfer",
      programName: "Senior Citizen Pension",
      merchantsName: "Abu Bakar",
      store: "YUP Store",
      status: "Paid",
      amount: "$100",
      pensionerName: "Pensioner",
      voucherCode: "12X3",
      email: "info@example.com",
      phone: "+234XXXXXXXX",
    };
    const mhsss = logoSCP;
    const callImg = slipcall;
    const emailImg = slipemail;
    const bg = slipbg;
    // Create a temporary iframe
    const printFunc = (mhsss, callImg, emailImg) => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentWindow.document;

      // Construct the printable content
      let printableContent = `
        <html>
            <head>
                <title>Print</title>
                <style>
                    body {
                        font-size: 25pt;
                    }
                    @media print {
                        .printable-container {
                            -webkit-print-color-adjust: exact;
                            color-adjust: exact; 
                            background-color: #ffffff;
                            background-image: url('${bg}');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                            margin-bottom: 1.5rem;
                            padding: 0.5rem;
                        }
                        .d1 {
                            border-radius: 0.25rem;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            padding-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .d2 {
                            width: 6rem; 
                            height: 5rem;
                            padding-top: 1rem;
                        }
                        .d3 {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .hh1 {
                            font-weight: 600;
                            text-align: center;
                            width: 65%;
                        }
                        .d4 {
                            display: flex;
                            width: 80%;
                            flex-direction: column;
                            border-bottom: 1px solid #e2e8f0;
                            padding-bottom: 1rem;
                            font-size: 1rem;
                        }
                        .pp {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .ss {
                            color: #718096;
                        }
                        .d5 {
                            display: flex;
                            flex-direction: column;
                            padding-top: 0.5rem;
                            font-size: 1rem;
                            width: 80%;
                        }
                        .d6 {
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                        }
                        .call {
                            width: 1.25rem;
                            height: 1.25rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .mail {
                            width: 1.25rem;
                            height: 1rem;
                            display: flex;
                            lign-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;
                        }
                        .d7 {
                            display: flex;
                            width: 100% ;
                            align-items: center;
                        }
                        .sss {
                            font-weight: 600;
                        }
                    }
                </style>
            </head>
            <body>
                <div class="printable-container">
                    <div class="d1">
                        <img id="mhsss" alt="chippz" class="d2" src="${mhsss}" />
                        <div class="d3">
                            <h4 class="hh1">Ministry of Human Services and Social Security</h4>
                        </div>
                        <div class="d4">
                            <p class="pp">
                                <span class="ss">Transaction Id</span>
                                <span>${data.transactionId}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Transaction Date</span>
                                <span>${data.transactionDate}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Program Name</span>
                                <span>${data.programName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Merchants Name</span>
                                <span>${data.merchantsName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Store</span>
                                <span class="sss">${data.store}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Status</span>
                                <span class="sss">${data.status}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Amount</span>
                                <span class="sss">${data.amount}</span>
                            </p>
                        </div>
                        <div class="d5">
                            <p class="pp">
                                <span class="ss">Pensioner Name</span>
                                <span>${data.pensionerName}</span>
                            </p>
                            <p class="pp">
                                <span class="ss">Voucher Code</span>
                                <span>${data.voucherCode}</span>
                                </p>
                                <div class="d6">
                                    <p class="d7">
                                        <img id="emailImg" class="mail" alt="chippz" src="${emailImg}" />
                                        ${data.email}
                                    </p>
                                    <p class="d7">
                                        <img id="callImg" class="call" alt="chippz" src="${callImg}" />
                                        ${data.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        `;

      // Set the iframe content
      iframeDocument.open();
      iframeDocument.write(printableContent);
      iframeDocument.close();

      // Print the iframe content
      iframe.contentWindow.print();

      // Remove the iframe from the DOM after printing
      document.body.removeChild(iframe);
    };
    const imageSources = [mhsss, callImg, emailImg];

    // Load images and print content
    let loadedImages = 0;
    imageSources.forEach((src) => {
      const img = new Image();
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageSources.length) {
          printFunc(mhsss, callImg, emailImg);
        }
      };
      img.src = src;
    });
  };

  const imageHandler = async (e) => {
    const file = e.target.files[0];

    if (file) {
      dispatch({ type: "FACE_API", num: true });
      const detectedObjects = await detectObjectsInImage(file);

      const hasHuman = detectedObjects.some(
        (object) => object.class === "person"
      );
      dispatch({ type: "FACE_API", num: false });
      if (hasHuman) {
        imageUploder(e);
        console.log("The image contains a human.");
      } else {
        FaceError();
        console.log("The image does not contain a human.");
      }
      e.target.value = null;
    }
  };

  const imageUploder = async (e, type, side) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    // type !== "gid" ? setDisplay(base641) : setGidDisplay;

    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const tok =
      user === "pensioner" ? dataUser?.data?.tokens?.access?.token : token;

    // const api = type === "gid" ? "document" : "profile";
    const url =
      user === "pensioner"
        ? `v1/file/images?fileType=profile`
        : "v1/file/merchat-files?fileType=gidImage";
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/${url}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tok}`,
      },
    })
      .then((data) => {
        if (type !== "gid") {
          setDisplay(base641);
          setImage(data?.data?.url);
        } else if (type === "gid") {
          side === "front"
            ? setGidDisplay(base641)
            : setGidBackDisplay(base641);
          side === "front"
            ? setGidFrontImage(data?.data?.url)
            : setGidBackImage(data?.data?.url);
        }
        // if (type === "gid") {
        //   side === "front"
        //     ? setGidFrontImage(data?.data?.url)
        //     : setGidBackImage(data?.data?.url);
        // } else {
        //   setImage(data?.data?.url);
        // }
        e.target.value = null;
        // type === "gid"
        //   ? setGidImage(data?.data?.url)
        //   : setImage(data?.data?.url);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShowError(true);
        e.target.value = null;
      });
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const validateEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsValid(validateEmail(value));
  };

  return (
    <>
      {/* Upper Div */}
      <div className="bg-[#F6F4F4] sm:m-6 m-2 rounded-md sm:px-10 px-2 py-8 ">
        {/* Image Upload */}
        {contextHolder}
        <div
          className={`flex items-center gap-4 ${
            user !== "pensioner" ? "" : ""
          }`}
        >
          <label className="cursor-pointer relative">
            <div className="relative w-[6rem] h-[6rem] overflow-hidden border-2 border-white rounded-full mb-4 ">
              <img
                className="object-cover w-[6rem] h-[6rem]"
                alt=""
                src={display}
              />
            </div>

            <div
              className={`bg-primary rounded-full absolute bottom-4 right-2`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              accept="image/png, image/gif, image/jpeg"
              onChange={imageHandler}
            />
          </label>
          <div className="">
            <h4 className="sm:text-md font-semibold text-primary truncate  leading-none">
              Upload Image
            </h4>
            <p className="mt-1 text-sm">
              {user !== "pensioner"
                ? "Image of the person sending request to the pensioner"
                : "Image of the person to whom you want to send money."}
            </p>
          </div>
        </div>

        <h2 className="font-bold text-md mt-4 px-2">Enter Phone number or Email</h2>
        <p className={`text-sm ${user !== "pensioner" ? "hidden" : "p-2"}`}>
          (QR code will be sent to this phone number or email, to collect money
          from any merchant )
        </p>

        {/* email and phoen no */}

        <div className="flex justify-center items-center px-6 py-3 mt-4 rounded-md bg-white">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[10rem]">
                Phone Number <span className="text-red-600"></span>
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                international
                onChange={(phone) => {
                  const value = phone || "";
                  setPhone(value);
                  setPrompt(value);
                  const wow = parsePhoneNumber(value);
                  console.log(prompt);

                  let count = wow?.countryCallingCode;
                  let num = wow?.nationalNumber;
                  setCountryCode(count);
                  setPhoneNoo(num);
                }}
                countryCallingCodeEditable={false}
                className="w-full "
              />
            </div>
            <div className="flex flex-col items-start w-full gap-1">
              <div className="flex items-center w-full gap-1">
                <label className="text-gray-700 text-[0.9rem] font-semibold w-[4rem]">
                  Email <span className="text-red-600"></span>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className={`lg:text-[1.1rem] w-[16rem] bg-[#F6F4F4] border-[1px] rounded-[0.3rem] p-2 ${
                    !isValid ? "border-red-500" : ""
                  }`}
                  value={email}
                  onChange={handleChange}
                />
              </div>
              {!isValid && email && (
                <p className="text-red-500 text-sm mt-1 ml-[4rem]">
                  Invalid email format
                </p>
              )}
            </div>
          </div>
        </div>

        {/* First Name and last name */}
        <div className="flex justify-center items-center px-5 py-3 mt-4 rounded-md">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                First Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Middle Name <span className="text-red-600"></span>
              </label>
              <input
                type="text"
                placeholder="Middle Name"
                className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Last Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Date of Birth{" "}
                <span
                  className={`text-red-600 ${
                    user !== "pensioner" ? "" : "hidden"
                  }`}
                >
                  *
                </span>
              </label>
              {/* <DatePicker
                className="w-[15rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                placeholder="Select Date of Birth"
              /> */}
              <input
                type="date"
                placeholder="Date of Birth"
                className="lg:text-[1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 text-gray-600"
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
            <div className="flex items-center w-full gap-3 ">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Relation <span className="text-red-600">*</span>
              </label>
              <div className="relative inline-block w-[20rem]">
                <div
                  className="bg-white border border-gray-300 rounded px-4 py-2 cursor-pointer text-gray-500 flex justify-between items-center"
                  onClick={toggleSelect}
                >
                  {selectedOption}
                  <svg
                    className="w-4 h-4 inline ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                    />
                  </svg>
                </div>
                {isOpen && (
                  <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-b shadow-md h-32 overflow-y-auto z-10">
                    {options.map((option) => (
                      <>
                        <div
                          key={option.value}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.label}
                        </div>
                        <hr className="text-gray-400" />
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                GID{" "}
                <span
                  className={`text-red-600 ${
                    user !== "pensioner" ? "" : "hidden"
                  }`}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                placeholder="GID"
                className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2"
                value={gid}
                onChange={(e) => setGid(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* GID AND GID IMAGE */}
        <div className="flex justify-center items-center px-5 py-3  rounded-md">
          <div className="grid sm:grid-cols-2 sm:gap-8 grid-cols-1 gap-6 w-full">
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                GID Front Image <span className="text-red-600"></span>
              </label>

              <label className={`cursor-pointer relative w-[20rem]`}>
                <div className="lg:mb-5 lg:text-[1.1rem]  bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Upload Front Image</p>
                </div>
                <input
                  type="file"
                  className="hidden w-[100%] cursor-poniter"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => imageUploder(e, "gid", "front")}
                />
              </label>
            </div>
            <div className="flex items-center w-full gap-3">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                GID Back Image <span className="text-red-600"></span>
              </label>

              <label className={`cursor-pointer relative w-[20rem]`}>
                <div className="lg:mb-5 lg:text-[1.1rem]  bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Upload Back Image</p>
                </div>
                <input
                  type="file"
                  className="hidden w-[100%] cursor-poniter"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => imageUploder(e, "gid", "back")}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="flex gap-4 justify-between">
          <div className="flex-1">
            <label
              className={`text-gray-700 text-[0.9rem] font-semibold w-[8rem] px-5 ${
                gidDisplay ? "" : "hidden"
              }`}
            >
              GID Front <span className="text-red-600"></span>
            </label>
            <div
              className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                gidDisplay ? "" : "hidden"
              }`}
            >
              <img
                onClick={() => {
                  // setIShow(true);
                }}
                className="w-[25rem] h-[13rem]"
                src={gidDisplay}
                alt=""
              />
              <div
                className="absolute bottom-0 right-0 bg-white cursor-pointer"
                onClick={() => {
                  setGidDisplay(null);
                  setGidFrontImage(null);
                }}
              >
                <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
              </div>
            </div>
          </div>

          <div className="flex-1">
            <label
              className={`text-gray-700 text-[0.9rem] font-semibold w-[8rem] px-2 ${
                gidBackDisplay ? "" : "hidden"
              }`}
            >
              GID Back <span className="text-red-600"></span>
            </label>
            <div
              className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                gidBackDisplay ? "" : "hidden"
              }`}
            >
              <img
                onClick={() => {
                  // setIShow(true);
                }}
                className="w-[25rem] h-[13rem]"
                src={gidBackDisplay}
                alt=""
              />
              <div
                className="absolute bottom-0 right-0 bg-white cursor-pointer"
                onClick={() => {
                  setGidBackDisplay(null);
                  setGidBackImage(null);
                }}
              >
                <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Below div */}
      <div className="bg-[#F6F4F4] sm:m-6 m-2 rounded-md sm:px-10 px-2 py-3">
        <div className="flex items-center px-5 mt-4 rounded-md flex-col gap-5">
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
              Amount <span className="text-red-600">*</span>
            </label>

            <div className="flex lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 justify-between">
              <input
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => setAmountPaid(e.target.value)}
                className=""
              />
              <p className="font-bold text-lg">$</p>
            </div>
          </div>
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
              Purpose <span className="text-red-600">*</span>
            </label>
            <div className="relative inline-block w-[20rem]">
              <div
                className="bg-white border border-gray-300 rounded px-4 py-2 cursor-pointer text-gray-500 flex justify-between items-center"
                onClick={() => setIsPurposeOpen(!isPurposeOpen)}
              >
                {selectedPurpose}
                <svg
                  className="w-4 h-4 inline ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                  />
                </svg>
              </div>
              {isPurposeOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-b shadow-md h-32 overflow-y-auto">
                  {purposeOptions.map((option) => (
                    <>
                      <div
                        key={option.value}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
                        onClick={() => handleOptionClick(option, "purpose")}
                      >
                        {option.label}
                      </div>
                      <hr className="text-gray-400" />
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center w-full">
            <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
              Reason <span className="text-red-600"></span>
            </label>
            <textarea
              type="text"
              placeholder="Reason"
              className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 mb-2"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          {(user === "merchant-owner" || user === "merchant-employee" || user === "merchant-admin") &&
            dash !== "/SapMoney/Sender" && (
              <div className="flex w-full rounded-md">
                <Checkbox
                  className={`checkk2`}
                  checked={verify}
                  onClick={(e) => setVerify(e.target.checked)}
                >
                  Select, if you are unable to obtain all of the required
                  information
                </Checkbox>
              </div>
            )}
          {payStatus !== "merchant_paid" && (
            <div className="flex items-center w-full">
              <label className="text-gray-700 text-[0.9rem] font-semibold w-[8rem]">
                Rejection Note <span className="text-red-600"></span>
              </label>
              <textarea
                type="text"
                placeholder="Type Rejection Note"
                className="lg:text-[1.1rem] w-[20rem] bg-white border-[1px] rounded-[0.3rem] p-2 mb-2"
                value={rejReason}
                onChange={(e) => setRejReason(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>

      {payStatus === "merchant_paid" && dash !== "/SapMoney/Sender" && (
        <div className="flex items-center md:justify-start justify-center mb-5 sm:px-10">
          <button
            onClick={() => {
              if (phone === "" && email === "") {
                setError("Enter either phone number or email");
                setShowError(true);
                return;
              }
              if (amountPaid === "") {
                setError("Amount is required");
                setShowError(true);
                return;
              }

              if (firstName === "") {
                setError("First Name is required");
                setShowError(true);
                return;
              }

              if (lastName === "") {
                setError("Last Name is required");
                setShowError(true);
                return;
              }

              if (relation === null) {
                setError("Relation is required");
                setShowError(true);
                return;
              }

              if (purpose === null) {
                setError("Purpose is required");
                setShowError(true);
                return;
              }
              if (user !== "pensioner") {
                if (gid === "") {
                  setError("GID is required");
                  setShowError(true);
                  return;
                }
                if (dateOfBirth === null) {
                  setError("Date of Birth is required");
                  setShowError(true);
                  return;
                }
              }

              setShowConfirm(true);
            }}
            className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
          >
            Send Amount
          </button>
        </div>
      )}
      {payStatus !== "merchant_paid" && dash !== "/SapMoney/Sender" && (
        <div className="flex items-center md:justify-start justify-center mb-5 sm:px-10">
          <button
            onClick={() => {
              if (phone === "" && email === "") {
                setError("Enter either phone number or email");
                setShowError(true);
                return;
              }
              if (amountPaid === "") {
                setError("Amount is required");
                setShowError(true);
                return;
              }

              if (firstName === "") {
                setError("First Name is required");
                setShowError(true);
                return;
              }

              if (lastName === "") {
                setError("Last Name is required");
                setShowError(true);
                return;
              }

              if (relation === null) {
                setError("Relation is required");
                setShowError(true);
                return;
              }

              if (rejReason === "") {
                setError("Rejection Reason is required");
                setShowError(true);
                return;
              }

              if (user !== "pensioner") {
                if (gid === "") {
                  setError("GID is required");
                  setShowError(true);
                  return;
                }
                if (dateOfBirth === null) {
                  setError("Date of Birth is required");
                  setShowError(true);
                  return;
                }
              }

              pay();
            }}
            className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
          >
            Done
          </button>
        </div>
      )}
      {dash === "/SapMoney/Sender" && (
        <div className="flex items-center md:justify-start justify-center mb-5 sm:px-10">
          <button
            onClick={() => {
              if (phone === "" && email === "") {
                setError("Enter either phone number or email");
                setShowError(true);
                return;
              }
              if (amountPaid === "") {
                setError("Amount is required");
                setShowError(true);
                return;
              }

              if (firstName === "") {
                setError("First Name is required");
                setShowError(true);
                return;
              }

              if (lastName === "") {
                setError("Last Name is required");
                setShowError(true);
                return;
              }

              if (relation === null) {
                setError("Relation is required");
                setShowError(true);
                return;
              }

              if (purpose === null) {
                setError("Purpose is required");
                setShowError(true);
                return;
              }
              if (user !== "pensioner") {
                if (gid === "") {
                  setError("GID is required");
                  setShowError(true);
                  return;
                }
                if (dateOfBirth === null) {
                  setError("Date of Birth is required");
                  setShowError(true);
                  return;
                } else {
                  pay();
                }
              }
            }}
            className="bg-primary text-white rounded-md py-2 px-12 cursor-pointer"
          >
            Done
          </button>
        </div>
      )}

      {/* Confirmation Modal */}
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl sm:w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-2 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${amountPaid}</span> to {firstName}{" "}
            {lastName}?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                pay();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>

      {/* recipt Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p
            className={`${
              user === "pensioner" ? "text-center mt-[1rem] mb-5" : "hidden"
            }`}
          >
            Cash Amount <span className="font-bold">${amountPaid}</span> will be
            Paid to {firstName} {lastName} when he/she will represent QR code to
            merchant.
          </p>
          <p
            className={`${
              user === "pensioner" ? "hidden" : "text-center mt-[1rem] mb-5"
            }`}
          >
            Amount has been Transferred to pensioner SAP Money account
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                setShow(false);
                router("/Sap/Money");
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" ? "w-full" : "w-[8rem]"
              }`}
            >
              Done
            </button>
            <button
              onClick={() => {
                handlePrint();
                setShow(false);
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" ? "hidden" : ""
              }`}
            >
              Print Receipt
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showR ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4.5rem] h-[4.5rem] p-3 flex items-center justify-center rounded-full">
            <img src={dec} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2 text-primary">Request Declined</h1>
          <p className="text-center mt-[1rem] mb-5">
            we are unable to process the money transfer at this time.
            Unfortunately, we are unable to <b>verify the identity</b> of the
            sender based on the provided information.
          </p>
          <div className="flex gap-4 w-full items-center justify-center">
            <button
              onClick={() => {
                setShowR(false);
                router("/Sap/Money");
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary `}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showReq ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-4">Requested Successfully</h1>
          <div className="flex gap-4 w-[70%] items-center justify-center mt-6">
            <button
              onClick={() => {
                setShowReq(false);
                router("/Sap/Money");
              }}
              className={`w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary ${
                user === "pensioner" ? "w-full" : "w-[8rem]"
              }`}
            >
              Done
            </button>
          </div>
        </div>
      </div>

      {faceApi === true && <FaceVerificatioNModal />}
      {showError && <ErrorModal setShow={setShowError} err={error} />}
    </>
  );
};

export default Main;
