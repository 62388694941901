import React, { useEffect, useState } from "react";

import gasBill from "../../../../imgs/gasBill.svg";
import wBill from "../../../../imgs/wBill.svg";
import eBill from "../../../../imgs/eBill.svg";
import rent from "../../../../imgs/rent.svg";
import recharge from "../../../../imgs/recharge.svg";
import loan from "../../../../imgs/loan.svg";

import Chart from "./Chart";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Center = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const merchantSapType = useSelector((state) => state.merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [dataMerch, setDataMerch] = useState([]);
  const [afterApi, setAfterApi] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [interval, setInterval] = useState("weekly");
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const otherOptions = [
    {
      name: "Electricity Bill",
      icon: eBill,
    },

    {
      name: "Water Bill",
      icon: wBill,
    },
    {
      name: "Gas Bill",
      icon: gasBill,
    },
    {
      name: "Loan Payment",
      icon: loan,
    },
    {
      name: "Rent",
      icon: rent,
    },
    {
      name: "Recharge",
      icon: recharge,
    },
  ];

  // useEffect(() => {
  //   const isMerchant = [
  //     "merchant-owner",
  //     "merchant-employee",
  //     "merchant-admin",
  //   ].includes(user);

  //   const id =
  //     merchantSapType === "store"
  //       ? merchantSapTypeData.id
  //       : merchantSapTypeData.merchuser?.merchheadquarterId;

  //   const baseUrl = `${process.env.REACT_APP_BASE_URL}/v1/sapwallet-histories/my-sapwallet-report`;
  //   const url =
  //     user === "pensioner"
  //       ? `${baseUrl}/for-pensioner`
  //       : `${baseUrl}/for-merch?${
  //           merchantSapType === "store" ? "merchStoreId" : "merchHeadquarterId"
  //         }=${id}`;

  //   const tok = isMerchant ? token : dataUser.data.tokens.access.token;

  //   const params = { reportType: interval };

  //   axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${tok}`,
  //       },
  //       params,
  //     })
  //     .then((response) => {
  //       const { debit, credit } = response.data;

  //       const prepareData = (debit, credit, interval) => {
  //         const combined = {};

  //         debit.forEach((entry) => {
  //           const key = interval === "annually" ? entry.month : entry.day;
  //           const amount = parseFloat(entry.totalAmount);
  //           if (!combined[key]) {
  //             combined[key] = { debit: 0, credit: 0 };
  //           }
  //           combined[key].debit += amount;
  //         });

  //         credit.forEach((entry) => {
  //           const key = interval === "annually" ? entry.month : entry.day;
  //           const amount = parseFloat(entry.totalAmount);
  //           if (!combined[key]) {
  //             combined[key] = { debit: 0, credit: 0 };
  //           }
  //           combined[key].credit += amount;
  //         });

  //         return combined;
  //       };

  //       const combinedData = prepareData(debit, credit, interval);

  //       const generateTimeRange = () => {
  //         const today = new Date();
  //         if (interval === "weekly") {
  //           // Weekly: Generate current week from Monday to Sunday
  //           const dayOfWeek = today.getDay();
  //           const monday = new Date(today);
  //           monday.setDate(today.getDate() - ((dayOfWeek + 6) % 7));
  //           return Array.from({ length: 7 }, (_, i) => {
  //             const currentDate = new Date(monday);
  //             currentDate.setDate(monday.getDate() + i);
  //             return {
  //               key: currentDate.toISOString().split("T")[0],
  //               name: currentDate.toLocaleDateString("en-US", {
  //                 weekday: "short",
  //               }),
  //             };
  //           });
  //         }
  //         else if (interval === "monthly") {

  //           const daysInMonth = new Date(
  //             today.getFullYear(),
  //             today.getMonth() + 1,
  //             0
  //           ).getDate();
  //           return Array.from({ length: daysInMonth }, (_, i) => {
  //             const date = new Date(
  //               today.getFullYear(),
  //               today.getMonth(),
  //               i + 1
  //             );
  //             return {
  //               key: date.toISOString().split("T")[0],
  //               name: (i + 1).toString(),
  //             };
  //           });
  //         }
  //         else if (interval === "annually") {
  //           // Annually: Generate months of the year
  //           return Array.from({ length: 12 }, (_, i) => {
  //             const date = new Date(today.getFullYear(), i, 1);
  //             return {
  //               key: `${today.getFullYear()}-${String(i + 1).padStart(2, "0")}`,
  //               name: date.toLocaleDateString("en-US", { month: "short" }),
  //             };
  //           });
  //         }
  //       };

  //       const timeRange = generateTimeRange();

  //       const result = timeRange.map((timeInfo) => {
  //         const dailyData = combinedData[timeInfo.key] || {
  //           debit: 0,
  //           credit: 0,
  //         };
  //         return {
  //           day: timeInfo.key,
  //           name: timeInfo.name,
  //           Debit: dailyData.debit,
  //           Credit: dailyData.credit,
  //         };
  //       });

  //       // Calculate total debit and credit for the entire period
  //       const totalDebit = result.reduce((acc, day) => acc + day.Debit, 0);
  //       const totalCredit = result.reduce((acc, day) => acc + day.Credit, 0);

  //       console.log("finalData", result);
  //       console.log("Total Debit:", totalDebit);
  //       console.log("Total Credit:", totalCredit);

  //       setFinalData(result);
  //       setTotalCredit(totalCredit)
  //       setTotalDebit(totalDebit)
  //       setAfterApi(true);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, [user, merchantSapType, merchantSapTypeData, token, dataUser, interval]);

  useEffect(() => {
    const isMerchant = [
      "merchant-owner",
      "merchant-employee",
      "merchant-admin",
    ]?.includes(user);

    const id =
      merchantSapType === "store"
        ? merchantSapTypeData.id
        : merchantSapTypeData?.merchuser?.merchheadquarterId;

    const baseUrl = `${process.env.REACT_APP_BASE_URL}/v1/sapwallet-histories/my-sapwallet-report`;
    const url =
      user === "pensioner"
        ? `${baseUrl}/for-pensioner`
        : `${baseUrl}/for-merch?${
            merchantSapType === "store" ? "merchStoreId" : "merchHeadquarterId"
          }=${id}`;

    const tok = isMerchant ? token : dataUser.data.tokens.access.token;

    const params = { reportType: interval };

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        params,
      })
      .then((response) => {
        const { debit, credit } = response.data;

        const prepareData = (debit, credit, interval) => {
          const combined = {};

          debit.forEach((entry) => {
            const key = interval === "annually" ? entry.month : entry.day;
            const amount = parseFloat(entry.totalAmount);
            if (!combined[key]) {
              combined[key] = { debit: 0, credit: 0 };
            }
            combined[key].debit += amount;
          });

          credit.forEach((entry) => {
            const key = interval === "annually" ? entry.month : entry.day;
            const amount = parseFloat(entry.totalAmount);
            if (!combined[key]) {
              combined[key] = { debit: 0, credit: 0 };
            }
            combined[key].credit += amount;
          });

          return combined;
        };

        const combinedData = prepareData(debit, credit, interval);

        const generateTimeRange = () => {
          const today = new Date();
          if (interval === "weekly") {
            const dayOfWeek = today.getDay();
            const monday = new Date(today);
            monday.setDate(today.getDate() - ((dayOfWeek + 6) % 7));
            return Array.from({ length: 7 }, (_, i) => {
              const currentDate = new Date(monday);
              currentDate.setDate(monday.getDate() + i);
              const key = currentDate.toISOString().split("T")[0];
              return {
                key,
                name: currentDate.toLocaleDateString("en-US", {
                  weekday: "short",
                }),
                debit: combinedData[key]?.debit || 0,
                credit: combinedData[key]?.credit || 0,
              };
            });
          } else if (interval === "monthly") {
            const daysInMonth = new Date(
              today.getFullYear(),
              today.getMonth() + 1,
              0
            ).getDate();
            const specificDays = [1, 5, 10, 15, 20, 25, daysInMonth];
            return specificDays.map((day, index) => {
              const date = new Date(today.getFullYear(), today.getMonth(), day);
              const key = date.toISOString().split("T")[0];
              let sumDebit = 0;
              let sumCredit = 0;

              for (let i = specificDays[index - 1] || 1; i <= day; i++) {
                const rangeKey = new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  i
                )
                  .toISOString()
                  .split("T")[0];
                if (combinedData[rangeKey]) {
                  sumDebit += combinedData[rangeKey].debit;
                  sumCredit += combinedData[rangeKey].credit;
                }
              }

              return {
                key,
                name: day.toString(),
                debit: sumDebit,
                credit: sumCredit,
              };
            });
          } else if (interval === "annually") {
            return Array.from({ length: 12 }, (_, i) => {
              const date = new Date(today.getFullYear(), i, 1);
              const key = `${today.getFullYear()}-${String(i + 1).padStart(
                2,
                "0"
              )}`;
              return {
                key,
                name: date.toLocaleDateString("en-US", { month: "short" }),
                debit: combinedData[key]?.debit || 0,
                credit: combinedData[key]?.credit || 0,
              };
            });
          }
        };

        const timeRange = generateTimeRange();

        const result = timeRange.map((timeInfo) => ({
          day: timeInfo.key,
          name: timeInfo.name,
          Debit: timeInfo.debit,
          Credit: timeInfo.credit,
        }));

        const totalDebit = result.reduce((acc, day) => acc + day.Debit, 0);
        const totalCredit = result.reduce((acc, day) => acc + day.Credit, 0);

        console.log("finalData", result);
        console.log("Total Debit:", totalDebit);
        console.log("Total Credit:", totalCredit);

        setFinalData(result);
        setTotalCredit(totalCredit);
        setTotalDebit(totalDebit);
        setAfterApi(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [user, merchantSapType, merchantSapTypeData, token, dataUser, interval]);

  useEffect(() => {
    if (user === "pensioner") {
      return;
    } else if (
      user === "merchant-owner" ||
      user === "merchant-employee" ||
      user === "merchant-admin"
    ) {
      let id =
        merchantSapType === "store"
          ? merchantSapTypeData.id
          : merchantSapTypeData.merchuser?.merchheadquarterId;
      let url;
      if (merchantSapType === "store") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/sapmoney-dashboard/contacted-pensioners/for-merch?merchstoreId=${id}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/sapmoney-dashboard/contacted-pensioners/for-merch?merchheadquarterId=${id}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setData(data?.data);
          setAfterApi(true);
        })
        .catch((err) => {});
    }
  }, []);
  useEffect(() => {
    if (user !== "pensioner") {
      return;
    } else if (user === "pensioner") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/sapmoney-dashboard/contacted-stores-headquarters/for-pensioner`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setData(data?.data);
          setAfterApi(true);
        })
        .catch((err) => {});
    }
  }, []);
  useEffect(() => {
    if (
      user === "pensioner" ||
      user === "merchant-owner" ||
      user === "merchant-employee" ||
      user === "merchant-admin"
    ) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-owners-by-payment-filters/for-mhsss?limit=12&skip=0&page=1&sortBy=createdAt:desc&defaultPayMethods[]=sap-wallet`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setDataMerch(data?.data?.rows);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="p-2">
        <Chart
          aspect={18 / 9}
          title="Transaction Statistics"
          finalData={finalData}
          setInterval={setInterval}
          interval={interval}
          totalCredit={totalCredit}
          totalDebit={totalDebit}
        />
      </div>
      <div
        className={`grid ${
          user === "pensioner"
            ? "grid-cols-1 sm:grid-cols-2 sm:gap-3 gap-2 sm:mx-0 mx-2"
            : "grid-cols-1 gap-3"
        } `}
      >
        <div
          className={`bg-[#F6FBF9]  mt-3 p-3 ${
            user === "pensioner" ? "" : "hidden"
          }`}
        >
          <h1 className="font-semibold text-md">Quick Actions</h1>
          <p className="text-gray-400 text-sm ">List of other actions</p>
          <div className="grid grid-cols-3  gap-3 mt-4">
            {otherOptions?.map((beneficiary, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center"
              >
                <img src={beneficiary.icon} alt="profile" className="w-7 h-7" />
                <p className="text-[13px] font-semibold mt-2 text-center">
                  {beneficiary.name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`bg-[#F6FBF9] sm:h-[15.5rem] h-auto  mt-3 p-3 relative mx-2 ${
            user === "merchant-owner" ||
            user === "merchant-employee" ||
            user === "merchant-admin"
              ? ""
              : "hidden"
          }`}
        >
          <h1 className="font-semibold text-md">Program Transactions</h1>
          <p className="text-gray-400 text-sm ">List of your Pensioners</p>
          <p
            className="absolute top-3 right-3 text-primary font-semibold cursor-pointer"
            onClick={() => {
              router("/SapMoney/Merchant/AllPensioner/ProgramTransactions");
            }}
          >
            View All
          </p>
          {afterApi === true && data.length === 0 ? (
            <div className="flex items-center justify-center mt-12">
              <p className="text-gray-400 text-sm">No Pensioners yet</p>
            </div>
          ) : (
            <div className="grid md:grid-cols-6  gap-3 mt-4 mb-2  sm:grid-cols-4 grid-cols-4 sm:p-2">
              {data &&
                data?.slice(0, 12)?.map((data, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center cursor-pointer"
                    onClick={() => {
                      dispatch({ type: "PROGRAM_TRANSACTION_TYPE", num: data });
                      router(
                        "/SapMoney/Merchant/Pensioner/ProgramTransactions"
                      );
                    }}
                  >
                    <img
                      src={data?.image}
                      alt="profile"
                      className="w-12 h-12 rounded-full"
                    />
                    <p className="text-sm font-semibold mt-2 text-center">
                    {data?.firstName} {" "}
                    {data?.lastName}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div
          className={`bg-[#F6FBF9] h-[15rem]  mt-3 p-3 relative ${
            user !== "pensioner" &&
            user !== "merchant-owner" &&
            user !== "merchant-employee" &&
            user !== "merchant-admin"
              ? ""
              : "hidden"
          }`}
        >
          <h1 className="font-semibold text-md">Merchants</h1>
          <p className="text-gray-400 text-sm ">
            Merchants with SAP-Wallet as default payment method
          </p>
          <p
            onClick={() => {
              router("/SapMoney/MHSSS/Merchants");
            }}
            className="absolute top-3 right-3 text-primary font-semibold cursor-pointer"
          >
            View All
          </p>
          {afterApi === true && dataMerch.length === 0 ? (
            <div className="flex items-center justify-center mt-12">
              <p className="text-gray-400 text-sm">No Pensioners yet</p>
            </div>
          ) : (
            <div className="grid grid-cols-6  gap-3 mt-4 mb-2">
              {dataMerch?.map((data, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center cursor-pointer"
                  onClick={() => {
                    // dispatch({ type: "PROGRAM_TRANSACTION_TYPE", num: data });
                    // router("/SapMoney/Merchant/Pensioner/ProgramTransactions");
                  }}
                >
                  <img
                    src={data?.merchcompany?.logo}
                    alt="profile"
                    className="w-10 h-10 rounded-full"
                  />
                  <p className="text-sm font-semibold whitespace-nowrap mt-2 text-center">
                    {data?.merchcompany?.name?.split(" ")[0]}
                    {/* {data?.lastName} */}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className={`bg-[#F6FBF9]  mt-3 p-3 ${
            user !== "pensioner" ? "hidden" : ""
          }`}
        >
          <h1 className="font-semibold text-md">Program Transactions</h1>
          <p className="text-gray-400 text-sm ">List of your Merchants</p>
          {afterApi === true && data.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-400 text-sm">No transaction yet</p>
            </div>
          ) : (
            <div className="grid grid-cols-3  gap-3 mt-4">
              {data &&
                data?.slice(0, 6)?.map((data, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center cursor-pointer"
                    onClick={() => {
                      dispatch({ type: "PROGRAM_TRANSACTION_TYPE", num: data });
                      router("/SapMoney/Pensioner/ProgramTransactions");
                    }}
                  >
                    <img
                      src={
                        data?.type === "headquarter"
                          ? data?.merchCompany?.logo
                          : data?.merchStore?.image
                      }
                      alt="profile"
                      className="w-12 h-12 rounded-full"
                    />
                    <p className="text-sm font-semibold mt-2 text-center">
                      {data?.type === "headquarter" && data?.merchCompany?.name}
                      {data?.type === "store" &&
                        data?.merchStore?.customStoreName}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Center;
