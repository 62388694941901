import Listingh from "../components/Dash/Test/Listing/listingh";
import Nav from "../components/Nav/nav";
import Whatsapp from "../Whatsapp/whatsapp";

const PensionP = () => {
  return (
    <div>
      <Nav />
      <Listingh />
      <Whatsapp/>
    </div>
  );
};

export default PensionP;
