import { useDispatch, useSelector } from "react-redux";
import FirstA from "./stepsA/firstA";
import SecondA from "./stepsA/secondA";
import ThirdA from "./stepsA/thirdA";
import FourthA from "./stepsA/fourthA";
import Finance from "./stepsA/finance";
import Pre from "./stepsA/pre";
import Mid from "./stepsA/mid";
import PreFilled from "./stepsA/preFilled";
import { useEffect } from "react";
import axios from "axios";
import Awaited from "../Awaited/Awaited";

const Application = () => {
  const acurrent = useSelector((state) => state.acurrent);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const Show = [
    Pre,
    PreFilled,
    FirstA,
    SecondA,
    ThirdA,
    Mid,
    Finance,
    FourthA,
    Awaited,
  ];
  const Dis = Show[acurrent];

  useEffect(() => {
    return () => {
      localStorage.setItem("electricityBill", "y");
      localStorage.setItem("waterBill", "y");
      localStorage.setItem("publicAssistance", "y");
      localStorage.setItem("publicAssistanceType", "y");
    };
  }, []);
  // useEffect(() => {
  //   if (dash !== "/") {
  //     axios
  //       .get(
  //         `h${process.env.REACT_APP_BASE_URL}/v1/users/profile/${universalPenId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         dispatch({ type: "setProfileData", num: data.data });
  //         dispatch({
  //           type: "nameSet",
  //           num: `${data.data.person.firstName} ${data.data.person.lastName}`,
  //         });
  //         dispatch({
  //           type: "imageSet",
  //           num: `${data.data.person.image}`,
  //         });
  //       })
  //       .catch((err) => {
  //       });
  //   }
  // }, [universalPenId]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/application-questions?programName=senior-citizen-Pension`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "appQuestionsSet", num: data.data });
      })
      .catch(() => {
      });
  }, []);

  // useEffect(() => {
  //   if (dash === "/Dashboard/application") {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/v1/users/profile/${dataUser.data.user.id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         dispatch({ type: "setProfileData", num: data?.data });
  //       })
  //       .catch((err) => {
  //       });
  //   }
  // }, [universalPenId]);
  return (
    <div className="flex justify-center">
      <div className="md:w-[75%] w-[85%]">
        <div>
          <Dis />
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          {/* <button
            onClick={() => nextHandler("p")}
            className={`lg:text-[1.1rem] bg-[#F2F4F8] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button> */}
          {/* <div
            className={`${
              dash === "/Dashboard/application" ? "hidden" : ""
            }`}
          >
            <button
              onClick={() => nextHandler("s")}
              className={`lg:text-[1.1rem] bg-[#F7542E] text-white rounded-[0.5rem] p-2  sm:pr-8 sm:pl-8 pr-5 pl-5 ml-5 ${
                acurrent === 0 ? "" : "hidden"
              }`}
            >
              Skip for now
            </button>
          </div> */}
          {/* <button
            onClick={() => nextHandler("n")}
            className={`lg:text-[1.1rem] ${
              acurrent === 8 ? "hidden" : ""
            } bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            {acurrent === 7 ? "Done" : "Next"}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Application;
