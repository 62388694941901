import back5 from "../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import PolTable from "./PolTable";
const Pol = () => {
  const router = useNavigate();
  return (
    <div className="w-full flex items-center justify-center flex-col">
      <div className="p-4 md:w-[90%] w-full flex items-center justify-center flex-col">
        <div className="w-full flex items-center justify-center flex-col">
          <div className="flex w-full items-center mt-4">
            <div className="cursor-pointer">
              <img
                onClick={() => {
                  router("/EDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem] ml-[1rem]">
              POL Verification
            </h2>
          </div>
        </div>
      </div>
      <PolTable />
    </div>
  );
};

export default Pol;
