import React from "react";

const CompanyDetails = ({ company }) => {
  const Div = ({ title, value }) => (
    <div className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm leading-6 text-gray-900 font-semibold">{title}</dt>
      <dd className={`${title === "Industry" || title === "Business Type" ? "capitalize" :""} mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0`}>
        {value}
      </dd>
    </div>
  );

  return (
    <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
      <div className="sm:px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-600">
          Company Details
        </h3>
        {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Personal details and application.
          </p> */}
      </div>
      <div className="mt-6 border-t border-gray-300">
        <dl className="divide-y divide-gray-300">
          <Div title="Industry" value={company?.industry} />
          <Div title="Business Type" value={company?.businessType} />
          <Div title="Website" value={company?.website} />
          <Div title="Description" value={company?.description} />
        </dl>
      </div>
    </div>
  );
};

export default CompanyDetails;
