import React from 'react'
import Sidebar from '../Dashboard/Sidebar'
import PensionerListing from './listing'
const index = () => {
    return (
        <div className="flex flex-row p-5 gap-7 h-screen bg-[#ebfff3]">
            <aside className="w-1/5 rounded-lg" style={{
                backgroundColor: "white",
                boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',


            }}>
                <Sidebar />
            </aside>
            <div className="flex flex-col w-4/5 gap-5 overflow-y-scroll rounded-lg" style={{
                backgroundColor: "white",
                boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
            }}>
                <PensionerListing />

            </div>
        </div>

    )
}

export default index