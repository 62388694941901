import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import ben from "../../../imgs/ben.png";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import across from "../../../imgs/across.png";
import back5 from "../../../imgs/back5.png";
import edit1 from "../../../imgs/edit1.png";
import { Select, Switch } from "antd";
let optopt1;
const EmployeeProfile = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const location = useLocation();
  const reset = useSelector((state) => state.reset);
  const empId = useSelector((state) => state.universalEmpId);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [firstName, setFirstName] = useState("---");
  const [lastName, setLastName] = useState("---");
  const [isDisabled, setIsDisabled] = useState(false);
  const [status, setStatus] = useState("");
  // const [id, setId] = useState("");
  const [notes, setNotes] = useState("");
  const [show2, setShow2] = useState("");
  const [able, setAble] = useState(false);
  // const [status1, setStatus1] = useState("");
  const [other, setOther] = useState(false);
  // const [test, setTest] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [otherChoice, setOtherChoice] = useState("");
  // const [assignRoleModal, setAssignRoleModal] = useState(true);
  // const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [role, setRole] = useState(null)
  // const [roles, setRoles] = useState()
  // const [employeeId, setEmployeeId] = useState("");
  const [employeeData, setEmployeeData] = useState();
  const [reason, setReason] = useState(null)
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const dash = location.pathname;
  console.log("employeeData", employeeData)

  useEffect(() => {
    let api;
    if(dash==="/superAdminDashboard/GroempProfile" || dash==="/GroAdminDash/Employees/Profile")
       api = `v1/users/gro-employee-profile/${empId}`
      else if(dash==="/superAdminDashboard/GroAdminProfile")
      api=`v1/users/gro-admin-profile/${empId}`
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setEmployeeData(data.data);
          setRole(data.data?.user?.roles[0])
          setFirstName(data.data?.person?.firstName);
          setLastName(data.data?.person?.lastName);
          // setRoles(data.data.roles);
          // setAllb(data.data);
          
          dispatch({ type: "setProfileData", num: data?.data });
          // dispatch({ type: "universalEmpSet", num: data?.data?.user.id });
          setStatus(data.data.user.status);
          setIsDisabled(data.data.user.isDisabled);
          // setId(data.data.user.id);
          if (data.data.user.isDisabled) {
            optopt1 = [
              {
                label: "Rejoin",
                value: "Rejoin",
              },
              {
                label: "Other",
                value: "Other",
              },
            ];
            setAble(false);
          } else {
            optopt1 = [
              {
                label: "Incomplete Profile",
                value: "Incomplete Profile",
              },
              {
                label: "Left organization",
                value: "Out of Country",
              },
              {
                label: "Migrated",
                value: "Migrated",
              },
              {
                label: "Terminated",
                value: "Terminated",
              },
              {
                label: "Education",
                value: "Education",
              },
              {
                label: "Other",
                value: "Other",
              },
            ];
            setAble(true);
          }
        })
        .catch(() => {

        });

  }, [reset])
  const ableHandler = () => {
    setShow2(true);
  };
  const ableSubmitHandler = () => {
    if (reason === null) {
      setShow(true);
      setErr("Please enter Reason");
    } else if (other && otherChoice === "") {
      setShow(true);
      setErr("Please enter a reason");
    } else {
      let wow;
      let wow1;
      if (isDisabled) {
        if(reason==="other")
        wow=otherChoice;
      else
      wow=reason
        wow1 = "enable";
      } else {
        if(reason==="other")
        wow=otherChoice;
      else
      wow=reason
        wow1 = "disable";
      }
      let api;
      if(dash==="/superAdminDashboard/GroAdminProfile")
      api=`v1/users/${empId}/gro-admin/${wow1}`;
    else
    api=`v1/users/${empId}/gro-employee/${wow1}`;
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            reason: wow,
            note: notes,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          if (isDisabled) {
            setAble(true);
          } else {
            setAble(false);
          }
          setReason(null)
          setOther(false);
          setOtherChoice("");
          dispatch({ type: "RESET" });
          setNotes("");
          setShow2(false);
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    }
  };

  return (
    <div className="md:p-8  md:pl-[5rem] md:pr-[5rem] p-6 pl-[2rem] pr-[2rem]">
      <div className="flex items-center mb-[2rem]">
        <div
          className="cursor-pointer"
        >
          <img 
          onClick={() => {
            dash=== "/GroAdminDash/Employees/Profile" ? router("/GroAdminDash/Employees") : router("/superAdminDashboard/allGroAdmins")
          }} className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold text-[1.4rem] sm:text-[1.8rem] ml-[1rem]">
          {role==="gro-admin"?"GRO Admin":"GRO Employee"}
        </h2>
      </div>

      <div className="flex w-full mb-[3rem] sm:flex-row flex-col">
        <div
          className={`relative sm:w-[45%] w-[100%] bg-[#F2F4F8] flex flex-col items-center pt-8 pb-8 pl-8 pr-8`}
        >
          <div className="absolute top-5 right-5">
            <Switch
              size="small"
              style={{ backgroundColor: able ? "#1BA397" : "#c3c6c9" }}
              checked={able}
              onChange={ableHandler}
            />
          </div>
          <div className="w-[3rem] h-[3rem] rounded-full flex justify-center items-center">
            <img
              src={employeeData?.person?.image}
              className="w-[3rem] h-[3rem] rounded-full border-[1px] border-black"
              alt=""
            />
          </div>
          <h2 className="font-semibold mt-1">{`${firstName === undefined ? "----" : firstName} ${lastName === undefined ? "----" : lastName}`}</h2>
          {/* <h3   className="capitalize"  style={{color:'white', backgroundColor:'#57D38C', padding:'6px', borderRadius:'5px'}}>{selectedStatus}</h3> */}
          <p
            className={`text-[0.9rem] ${isDisabled ? "text-[red]" : "text-primary"
              }  font-bold capitalize`}
          >
            {isDisabled ? "Disabled" : status}
          </p>
          <p
            className={`pl-4 pr-4 mt-1.5 rounded-md text-black focus:border-none font-semibold capitalize`}
          >
            {role}
          </p>
          <div className="w-[100%] flex items-center mt-[1rem]">
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit1} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                <b>View Profile of {role==="gro-admin"?"GRO Admin":"GRO Employee"}</b>

              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                    if(dash==="/superAdminDashboard/GroempProfile")
                    {
                      router("/superAdminDashboard/GROEmpCardView");
                    } 
                    else if(dash==="/GroAdminDash/Employees/Profile") {
                      router("/GroAdminDash/Employees/Profile/ViewEmployee");
                    }
                    else if(dash==="/superAdminDashboard/GroAdminProfile")
                    router("/superAdminDashboard/GROAdminCardView")
                  }
                }
              >
                Click Here
              </p>
            </div>
          </div>

          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={ben} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">

                <b> Edit and Update {role==="gro-admin"?"GRO Admin":"GRO Employee"} Details</b>

              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  if(dash==="/superAdminDashboard/GroempProfile")
                  {
                    router("/superAdminDashboard/GroempProfile/EditEmployee");
                  } 
                  else if(dash==="/GroAdminDash/Employees/Profile") {
                    router("/GroAdminDash/Employees/Profile/EditEmployee");
                  }
                  else if(dash==="/superAdminDashboard/GroAdminProfile")
                  router("/superAdminDashboard/GroAdminProfile/EditAdmin")
                }
                }
              >
                Click Here
              </p>
            </div>
          </div>

        </div>
      </div>


      {/* Error Modal */}

      <div
        className={`${show1 ? "" : "hidden"
          } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
                        className="absolute top-[-11%] left-[42%]"
                        onClick={() => setShow1(false)}
                    >
                        <img src={across} alt="" />
                    </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-primary text-white w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${show2 ? "" : "hidden"
          } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[30rem] pb-6 pl-6 pr-6 flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">
            Are you sure you want to {isDisabled ? "enable " : "disable"} this {dash==="/superAdminDashboard/GroAdminProfile"? "GRO Admin": "GRO Employee"}
          </p>
          <Select
            placeholder="Reason"
            onChange={(e) => {
              setReason(e);
              if (e === "Other") {
                setOther(true);
              } else {
                setOther(false);
              }
            }}
            optionFilterProp="children"
            style={{ marginBottom: "0.5rem" }}
            className="wow1"
            value={reason}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={optopt1}
          />
          <input
            type="text"
            placeholder="Enter Reason"
            value={otherChoice}
            onChange={(e) => {
              setOtherChoice(e.target.value);
            }}
            className={`lg:text-[1.1rem] ${other ? "" : "hidden"
              } mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4`}
          />
          <textarea
            type="text"
            placeholder="Enter Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className="sqw lg:text-[1.1rem] h-[6rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mt-2"
          />
          <div className="flex mt-4">
            <button
              onClick={() => {
                setShow2(false);
                setOther(false);
                setOtherChoice("");
                setReason(null)
                setNotes(null);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-black bg-[#F2F4F8] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                ableSubmitHandler();
              }}
              className="cursor-pointer rounded-xl ml-4 pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              {isDisabled ? "Enable" : "Disable"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${show ? "" : "hidden"
          } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfile;
