import React from "react";

import reqCan from "../../../../imgs/reqCan.svg";
import axios from "axios";
const errorModal = ({
  setShow,
  note,
  setNote,
  data,
  setShowError,
  setErr,
  setRefetch,
  refetch,
  type,
  RequestCall,
}) => {
  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const Reject = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-quick-payee/${data?.id}/credit/parent-request/status`,
        {
          payStatus: "request-rejected",
          rejectionNote: note,
        }
      )
      .then((data) => {
        setRefetch(!refetch);
        setShow(false);
        // setType("");
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowError(true);
      });
  };

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className={`bg-white rounded-lg relative`}>
        <div className="relative rounded-xl sm:w-[28rem] w-[22rem]  flex items-center flex-col mb-6 p-6 ">
          <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-full">
            <img src={reqCan} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[80%] mb-1 font-semibold text-[#EF4D5C] text-lg">
            Request Declined
          </p>
          <p className="text-center w-[90%]">
            Do you want to write some note for the {capitalize(data?.firstName)}{" "}
            {data?.middleName && capitalize(data?.middleName)}{" "}
            {capitalize(data?.lastName)}?
          </p>

          <textarea
            className="h-20 border-[1px] border-gray-300 rounded-md p-2 mt-2 bg-gray-100 resize-none w-[80%]"
            placeholder="Type note here"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>

          <div className="flex gap-4 w-[70%] items-center justify-center mt-4">
            <button
              onClick={() => {
                type === "pen" ? RequestCall() : Reject();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary cursor-pointer"
            >
              Send
            </button>
            <button
              onClick={() => {
                setShow(false);
                //   setType("");
                // setOpenForm(true);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg bg-white border-primary cursor-pointer"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default errorModal;
