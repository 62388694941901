import React, { useEffect, useState } from "react";
import axios from "axios";
import { DeleteFilled } from "@ant-design/icons";
import logo from "../../../../imgs/mhsss_Accounting-logo.png";
import { useSelector } from "react-redux";
import thumb from "../../../../imgs/Thumb.png";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import { PlusCircleFilled } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

let file;
const Main = () => {
  const location = useLocation();
  const path = location.pathname;
  const reqPayment =
    path === "/MHSSS/GovernmentAgency/Requests/payment" ? true : false;
  const router = useNavigate();
  const selectedGovAgency = useSelector((state) => state.selectedGovAgency);
  console.log(reqPayment);
  console.log(reqPayment);
  const requestedAmount = useSelector((state) => state.requestedAmount);
  const [selectedBankId, setSelectedBankId] = useState("");
  const [note, setNote] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const [amount, setAmount] = useState("");
  const [display, setDisplay] = useState(null);
  const [chequeNumber, setChequeNumber] = useState("");
  const [image, setImage] = useState(null);
  const merchantId = reqPayment
    ? selectedGovAgency?.merchId
    : selectedGovAgency?.merchUser?.id;
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [data, setData] = useState("");
  const [err, setErr] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [receiptImg, setReceiptImg] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [displayCheque, setDisplayCheque] = useState(null);
  const [chequeImg, setChequeImg] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [chequeData, setChequeData] = useState("");
  console.log(chequeData);

  //get Status API
  useEffect(() => {
    if (reqPayment) {
      setAmount(requestedAmount);
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantId}/status`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setStatusData(data?.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (statusData) {
      if (statusData?.defaultBankInfoId) {
        setSelectedBankId("bank");
        Banking();
      }
      if (statusData?.defaultChequeInfo) {
        setSelectedBankId("cheque");

        Cheque();
        Banking();
      }
    }
  }, [statusData]);

  //getCheque
  const Cheque = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setChequeData(data?.data);
      })
      .catch(() => {});
  };
  //getBanking
  const Banking = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setData(data?.data);
      })
      .catch((err) => {});
  };

  const Pay = () => {
    if (selectedBankId === "") {
      setErr("Please select a payment method");
      setShowErrModal(true);
      return;
    }

    if (amount === "") {
      setErr("Please enter amount");
      setShowErrModal(true);
      return;
    }
    const body = {
      merchbankinfoId: data?.id,
      method: selectedBankId,
      amount: parseInt(amount),
      // purpose: "Purpose",
      bankReceiptImg: receiptImg,
    };

    if (reqPayment) {
      delete body.merchbankinfoId;
    }
    if (selectedBankId === "cheque") {
      delete body.bankReceiptImg;
      body.chequeNo = chequeNumber;
      // body.chequeDate = date;
      body.chequeImg = chequeImg;
    }

    if (receiptNo !== "" && selectedBankId === "bank") {
      body.bankReceiptNo = receiptNo;
    }

    if (note !== "" && selectedBankId === "bank") {
      body.notes = note;
    }

    const api = reqPayment
      ? `v1/bank-balance-requests/${selectedGovAgency?.id}/pay-now`
      : `v1/bank-balance-info/govt-agency-merch/mhsss-deposit`;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/${api}`,

        body,

        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setShow(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };

  const paymentOptions = [
    {
      label: "Bank",
      value: "bank",
      onClick: () => {
        setSelectedBankId("bank");
      },
    },
    {
      label: "Cheque",
      value: "cheque",
      onClick: () => {
        setSelectedBankId("cheque");
      },
    },
  ];

  const imageHandler1 = (setState, setUrl) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);

    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=paymentRecipet`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setState(base641);
        setUrl(data.data.url);
        e.target.value = null;
      })
      .catch(() => {
        e.target.value = null;
      });
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const maskAccountNumber = (accountNumber) => {
    let maskedNumber = "";
    for (let i = 0; i < accountNumber?.length; i++) {
      if (i < accountNumber?.length - 4) {
        maskedNumber += "X";
      } else {
        maskedNumber += accountNumber[i];
      }
    }
    return maskedNumber;
  };

  return (
    <div className="flex flex-col w-full min-h-[75vh] relative rounded-md bg-white shadow-lg flex pt-4 pb-10 p-6">
      <div className="p-2 mt-2 flex gap-6">
        <h1 className="font-semibold mt-2">Enter Amount</h1>
        <div className="flex items-center">
          <div className="relative flex gap-2">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 font-semibold">
              $
            </span>
            <input
              type="text"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              className="rounded-lg bg-gray-200 p-2 pl-8 font-semibold"
              placeholder="0"
            />
          </div>
        </div>
      </div>
      <h1 className="font-bold p-2 mb-1 text-lg mt-3">
        Default Payment Method
      </h1>
      <div className="sm:w-[60%] w-full bg-[#D9EFED] rounded-lg flex flex-col">
        <div className="gap-6 grid  sm:grid-cols-4 grid-cols-2 p-4 bg-white m-4">
          {paymentOptions.map((option, index) => (
            <button
              key={index}
              className={`
            ${
              selectedBankId === option.value
                ? "bg-primary text-white"
                : "hidden"
            }
            rounded-full px-4 py-2 font-semibold hover:bg-[#1BA397] hover:text-white`}
              onClick={() => {
                option.onClick();
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
        {selectedBankId === "cheque" && (
          <div className=" pb-4 flex flex-col gap-2 ">
            <div
              className={`bg-gray-100 md:w-[60%] sm:w-[80%] w-[80%] relative rounded-lg cursor-pointer ml-5 mb-5"
        `}
            >
              <div className="flex flex-row items-center p-2 ">
                <div
                  className={`absolute bg-primary top-5 flex items-center justify-center rounded-full w-14 h-14`}
                >
                  <img
                    src={
                      reqPayment
                        ? selectedGovAgency?.reqByMerchCompany?.logo
                        : selectedGovAgency?.merchUser?.merchcompany?.logo
                    }
                    alt="logo"
                    className={`w-14 h-14 rounded-full`}
                  />
                </div>
                <div className="flex items-center ml-14">
                  <div className="ml-3 flex flex-col mb-4">
                    <p className="font-semibold text-basic capitalize">
                      {reqPayment
                        ? selectedGovAgency?.reqByMerchCompany?.name
                        : selectedGovAgency?.merchUser?.merchcompany?.name}
                    </p>
                    <p className="font-semibold">
                      {maskAccountNumber(chequeData?.phone)}
                    </p>
                    <p className="text-basic">{chequeData?.email}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:w-[70%] pl-4 pr-4 flex flex-col gap-2 w-full mt-2">
              <div className="flex gap-4">
                <div className="flex gap-1 flex-col w-full">
                  <label
                    className="text-sm font-semibold"
                    htmlFor="chequeNumber"
                  >
                    Cheque Number:
                  </label>
                  <input
                    type="text"
                    id="chequeNumber"
                    placeholder="Cheque Number"
                    value={chequeNumber}
                    onChange={(e) => setChequeNumber(e.target.value)}
                    className="border bg-white p-2 rounded-lg h-[2rem] w-full"
                  />
                </div>
              </div>
              <textarea
                className="w-full p-3 bg-white
                rounded-lg mt-2"
                placeholder="Please provide notes"
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
              {/* <div className="flex gap-1 flex-col">
                <label htmlFor="amount" className="text-sm font-semibold">
                  Amount:
                </label>
                <input
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="border bg-gray-100 p-2 rounded-lg h-[2rem]"
                ></input>
              </div> */}
            </div>
            <div className="w-full bg-[#D9EFED] flex-col rounded-lg gap-6 flex pl-5 pr-5 mt-4">
              <label className="text-sm font-semibold" htmlFor="chequeNumber">
                Upload Cheque Image:
              </label>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className={`border-dashed border bg-gray-100 border-primary flex items-center justify-center rounded-lg text-center w-[18rem] h-[11rem] relative`}
              >
                {displayCheque ? (
                  <>
                    <img
                      src={displayCheque}
                      alt="Uploaded"
                      className="w-full h-full"
                    />
                    <button
                      className="absolute bottom-1 right-1"
                      onClick={() => {
                        setChequeImg(null);
                        setDisplayCheque(null);
                      }}
                    >
                      <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                    </button>
                  </>
                ) : (
                  <div className="w-[80%] flex flex-col gap-1 flex items-center justify-center">
                    <p>Drag & Drop Your File(s) here to upload </p>
                    <p>Or</p>
                    <label className="mt-2 w-[80%] cursor-pointer inline-block bg-primary text-white py-2 px-4 rounded-lg">
                      Browse Computer
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        // onChange={imageHandler}
                        onChange={imageHandler1(setDisplayCheque, setChequeImg)}
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {selectedBankId === "bank" && (
          <div className="w-full pl-5 pr-5 pb-5 pt-2">
            <div
              className={`bg-gray-100 md:w-[60%] sm:w-[80%] w-full relative rounded-lg p-3 cursor-pointer"
        `}
            >
              <div className="flex flex-row items-center p-2 ">
                <div
                  className={`absolute bg-primary top-5 flex items-center justify-center rounded-full w-14 h-14`}
                >
                  <img
                    src={data?.countrybank?.logo}
                    alt="logo"
                    className={`w-14 h-14 rounded-full`}
                  />
                </div>
                <div className="flex items-center ml-14">
                  <div className="ml-3 flex flex-col mb-4">
                    <p className="font-semibold text-basic capitalize">
                      {/* {data?.accountTitle} */}
                      {reqPayment
                        ? selectedGovAgency?.reqByMerchCompany?.name
                        : selectedGovAgency?.merchUser?.merchcompany?.name}
                    </p>
                    <p className="text-basic">{data?.countrybank?.name}</p>
                    <p className="font-semibold">
                      {maskAccountNumber(data?.accountNo)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 mt-4">
              <label className="mb-3">Bank Payment Transaction number</label>
              <input
                type="text"
                className="w-full p-3 bg-white
                rounded-lg mt-2"
                placeholder="Enter transaction #"
                onChange={(e) => {
                  setReceiptNo(e.target.value);
                }}
              />
              <textarea
                className="w-full p-3 bg-white
                rounded-lg mt-2"
                placeholder="Please provide notes"
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>
            <div>
              <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Bank Transfer Receipt
              </h2>
              <label
                //   htmlFor="special-input"
                className={`cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Receipt Image</p>
                </div>
                <input
                  type="file"
                  className="hidden w-[100%] cursor-poniter"
                  // id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler1(setDisplay, setReceiptImg)}
                />
              </label>
              <div
                className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8]
                          ${display !== null ? "" : "hidden"}`}
              >
                <img
                  onClick={() => {
                    // setIShow(true);
                  }}
                  src={display}
                  className="w-[13rem] h-[6rem]"
                  //   src={GFront}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setDisplay(null);
                    setReceiptImg(null);
                  }}
                >
                  <DeleteFilled
                    className="sm:w-[3rem] w-[2rem]"
                    style={{ fontSize: "30px", color: "#1BA397" }}
                    onClick={() => {
                      setReceiptImg(null);
                      setDisplay(null);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex sm:justify-start justify-center mt-7">
        <button
          onClick={() => {
            if (selectedBankId === "bank") {
              setShowConfirm(true);
            } else if (selectedBankId === "cheque") {
              setShowConfirm(true);
            }
          }}
          className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Pay Amount
        </button>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${amount}</span> has been
            transferred to the{" "}
            {selectedGovAgency?.merchUser?.merchcompany?.name} account
          </p>
          <div className="flex gap-4 w-full items-center justify-center">
            <button
              onClick={() => {
                setShow(false);
                reqPayment
                  ? router("/MHSSS/GovernmentAgency/Requests")
                  : router("/MHSSS/GovernmentAgency/Details");
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl sm:w-[28rem] w-[24rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center sm:w-[70%] w-[80%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${amount}</span> ?
            {/* to{" "}
            {selectedGovAgency?.merchUser?.merchcompany?.name} via bank? */}
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                Pay();
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirm(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
    </div>
  );
};

export default Main;
