import { useState } from "react";
import LoginE from "./tabsR/loginE";
import {useSelector } from "react-redux";
import EmployerE from "./tabsR/employerE";
import PersonalDetails1 from "../../PAccount/parts/tabsR/personalDetails";
import Location1 from "../../PAccount/parts/tabsR/location";

const Eregistration = () => {
  const [wow, setWow] = useState(0);
  const dataUser = useSelector((state) => state.dataUser);
  const Show = [LoginE, PersonalDetails1, Location1, EmployerE];
  const Dis = Show[wow];
  return (
    <div className="">
      <div className="flex flex-wrap mt-8 mb-[5rem]">
        <button
          onClick={() => setWow(0)}
          className={` ${
            wow === 0 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Login
          <div
            className={`${
              wow === 0 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(1)}
          className={`${
            wow === 1 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Info
          <div
            className={`${
              wow === 1 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        <button
          onClick={() => setWow(2)}
          className={`${
            wow === 2 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Address
          <div
            className={`${
              wow === 2 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>

        { (dataUser.data.user.roles[0]!=="gro-admin" && dataUser.data.user.roles[0]!=="gro-employee" )  &&<button
          onClick={() => setWow(3)}
          className={`${
            wow === 3 ? "bg-primary text-white" : "bg-[#EDEDED]"
          } flex p-2 rounded-xl flex items-center justify-center  mr-2 min-w-[5.5rem] pl-4 pr-4 relative mt-5`}
        >
          Employer
          <div
            className={`${
              wow === 3 ? "" : "hidden"
            } arrow-down absolute right-[35%] bottom-[-38%]`}
          ></div>
        </button>}
      </div>
      <Dis />
    </div>
  );
};

export default Eregistration;
