import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import back5 from "../../../imgs/back5.png";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const EventDetails = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const [localCart, setCart] = useState(cart);
  const [initial, setinitial] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  console.log("Cart", cart);
  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);

  const CartItem = ({ item, onIncrement, onDecrement, onDelete }) => {
    return (
      <div className="flex w-full items-center rounded-xl mt-2 bg-white p-4 border-b border-gray-300 relative">
        <img
          src={item.image}
          alt={item.name}
          className="w-24 h-24 rounded-md "
        />
        <div className="ml-4 flex-grow">
          <h2 className="text-lg font-semibold">{item.name}</h2>
          <p className="text-gray-600">
            {item.price} / {item.amount}
          </p>
          <div className="flex items-center mt-2">
            <Button
              onClick={() => onDecrement(item.id)}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              -
            </Button>
            <span className="mx-2">{item.quantity}</span>
            <Button
              onClick={() => onIncrement(item.id)}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              +
            </Button>
          </div>
        </div>
        <Button
          onClick={() => onDelete(item.id)}
          className="absolute top-2 right-2 text-red-600"
          icon={<DeleteOutlined />}
        />
      </div>
    );
  };

  const handleIncrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleDelete = (id) => {
    setinitial(false);
    setCart(localCart.filter((item) => item.id !== id));
  };
  const handleApplyPromoCode = () => {
    if (promoCode === "SAP") {
      setDiscount(15);
    } else {
      setDiscount(0);
    }
  };

  const total = localCart.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const shipping = 4.6;
  const subtotal = total - discount + shipping;

  return (
    <div className="flex w-full pb-[5rem] pt-[5rem] items-center justify-center">
      <div className="sm:w-[80%] flex flex-col items-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                router("/Inventory-Products");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Shopping Cart
              </h2>
            </div>
          </div>
        </div>
        {localCart.length > 0 && (
          <div className="w-full p-8 mt-6 rounded-xl bg-gray-100">
            {localCart.map((item) => (
              <CartItem
                key={item.id}
                item={item}
                onIncrement={handleIncrement}
                onDecrement={handleDecrement}
                onDelete={handleDelete}
              />
            ))}
          </div>
        )}
        {localCart.length > 0 && (
          <div className="flex gap-4 w-full items-center mt-6">
            <Input
              className="bg-gray-50 border border-gray-300 pl-4 pr-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="Enter promo code"
            />
            <Button
              onClick={handleApplyPromoCode}
              className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
            >
              Apply
            </Button>
          </div>
        )}
        {localCart.length > 0 && (
          <div className="mt-6 p-4 w-full bg-gray-100 rounded-xl">
            <div className="flex w-full justify-between mb-2">
            <span className="font-semibold">Total Items:</span>
            <span className="font-semibold">{localCart.length}</span>
          </div>
            <div className="flex w-full justify-between mb-2">
              <span className="font-semibold">Total:</span>
              <span className="font-semibold">${total.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-semibold">Shipping:</span>
              <span className="font-semibold">${shipping.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-semibold">Discount:</span>
              <span className="font-semibold">${discount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-bold text-lg">
              <span>Subtotal:</span>
              <span>${subtotal.toFixed(2)}</span>
            </div>
          </div>
        )}
        {localCart.length > 0 && (
          <div className="mt-8">
            <Button
              onClick={() => router("/Inventory-CheckOut")}
              className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
            >
              PROCEED TO CHECKOUT
            </Button>
          </div>
        )}

        {localCart.length === 0 && (
          <div className="mt-8">
            <h1 className="text-xl font-semibold">Cart is Empty...</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetails;
