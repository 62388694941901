import React from "react";
// import { useSelector } from "react-redux";
// import axios from "axios";
import { Checkbox } from "antd";
const VouchersModal = ({
  setShowVouchers,
  // setShowErrorModal,
  // setErr,
  setShowAmountModal,
  amount,
}) => {
  // const activeVouchers = useSelector((state) => state.activeVouchers);
  // const token = useSelector((state) => state.token);
  // const selectedProgramPensionBook = useSelector(
  //   (state) => state.selectedProgramPensionBook
  // );
  // const [selectedVouchers, setSelectedVouchers] = useState([]);
  // const [selectedVoucherIds, setSelectedVoucherIds] = useState([]);
  //   const [showErrorModal, setShowErrorModal] = useState(false);
  // console.log(selectedVouchers);
  //   const [err, setErr] = useState("");
  // const handleSelection = (index) => {
  //   const selectedVoucher = activeVouchers[index];

  //   setSelectedVouchers((prevSelectedVouchers) => {
  //     const isSelected = prevSelectedVouchers.includes(selectedVoucher);

  //     return isSelected
  //       ? prevSelectedVouchers.filter((voucher) => voucher !== selectedVoucher)
  //       : [...prevSelectedVouchers, selectedVoucher];
  //   });

  //   setSelectedVoucherIds((prevSelectedIds) => {
  //     const isSelected = prevSelectedIds.includes(selectedVoucher.id);

  //     return isSelected
  //       ? prevSelectedIds.filter((id) => id !== selectedVoucher.id)
  //       : [...prevSelectedIds, selectedVoucher.id];
  //   });
  // };

  // const getMonthName = (month) => {
  //   const monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  //   return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  // };

  // const getAmount = () => {
  //   if (selectedVoucherIds?.length === 0) {
  //     // setShowVouchers(false)
  //     setShowErrorModal(true);
  //     setErr("Please select a voucher");
  //     return;
  //   }
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/get-total-amount-by-program`,
  //       {
  //         pensionProgramId: selectedProgramPensionBook?.pensionprogramId,
  //         couponIds: selectedVoucherIds,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {})
  //     .catch((err) => {});
  // };
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-gray-900 ">
              Total Amount
            </h3>
            <button
              onClick={() => setShowAmountModal(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4 md:p-5">
            <p className="text-gray-600  mb-4">
              Total amount to be paid to the pensioner
            </p>
            <div className="flex items-center justify-center  mb-4">
              <p className="bg-primary pr-10 pl-10 pt-2 pb-2 text-white  rounded-md">
                ${amount ? amount : 0}
              </p>
            </div>
            <p className="text-justify  mb-4 ">
              We have also identified a supplementary amount of $10,000 for this
              pensioner. Would you like to include this amount in the payment as
              well?"
            </p>

            <div className="p-2">
              <Checkbox
                // checked={copArr[index].isCurrent}
                // onChange={(e) => setOfficeUse(e.target.checked)}
                className={"checkamount"}
              >
                Include Supplementary Amount
              </Checkbox>
            </div>

            <div className="flex gap-2 p-4">
              <button
                onClick={() => {
                  setShowAmountModal(false);
                  setShowVouchers(true);
                }}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-[#DE2827]  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Back
              </button>
              <button
                onClick={() => setShowAmountModal(false)}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />} */}
    </div>
  );
};

export default VouchersModal;
