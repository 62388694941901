import { useDispatch, useSelector } from "react-redux";
import Ww from "./ww";
import FirstR from "../Register/stepsR/FirstR";
import ForgotPassword from "../Forgot/ForgotPassword";
import ChangePassword from "../Change/Change"
import Ww2 from "./ww2";
let Show;
const Login = () => {
  const dispatch = useDispatch();
  const brcurrent = useSelector((state) => state.brcurrent);
  Show = [Ww2, FirstR, Ww, ForgotPassword,ChangePassword];
  const Dis = Show[brcurrent];
  return (
    // bg-primary
    <div className="bg-gradient-to-tr bg-[#F8F5F5]">
      <div
        className={` ${
          brcurrent === 3 || brcurrent === 4 ? "hidden" : ""
        } w-[100%] sm:h-[3rem] h-[2.5rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          onClick={() => dispatch({ type: "BRSET", num: 0 })}
          className={`cursor-pointer ${
            brcurrent === 0 || brcurrent === 2
              ? " bg-gradient-to-tr bg-primary"
              : ""
          } flex rounded-tl-[2.5rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 0 || brcurrent === 2
                ? "bg-white text-[lightblue]"
                : "bg-[#898989] text-white"
            }  rounded-full flex justify-center items-center`}
          >
            <p className=" text-[0.7rem]">1</p>
          </div>
          <p
            className={`ml-2 ${
              brcurrent === 0 || brcurrent === 2
                ? "text-white"
                : "text-[#898989]"
            } `}
          >
            Login
          </p>
        </div>
        <div
          onClick={() => dispatch({ type: "BRSET", num: 1 })}
          className={` ${
            brcurrent === 1
              ? "bg-gradient-to-tr bg-primary rounded-bl-[5rem] rounded-tl-[5rem]"
              : ""
          } cursor-pointer rounded-tr-[2.6rem] flex w-[50%] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 1
                ? "bg-white text-[lightblue]"
                : "bg-[#898989] text-white"
            } rounded-full flex justify-center items-center`}
          >
            <p className="text-[0.7rem]">2</p>
          </div>
          <p
            className={`${
              brcurrent === 1 ? "text-white" : "text-[#898989]"
            } ml-2 `}
          >
            Register
          </p>
        </div>
      </div>
      <div
        className={`${
          brcurrent === 3 ? "" : "hidden"
        } w-[100%] h-[3.8rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          onClick={() => dispatch({ type: "BRSET", num: 0 })}
          className={`cursor-pointer ${
            brcurrent === 0 || brcurrent === 2
              ? " bg-gradient-to-tr bg-primary"
              : ""
          } flex rounded-tl-[2.5rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 0 || brcurrent === 2
                ? "bg-white text-[lightblue]"
                : "bg-[#898989] text-white"
            }  rounded-full flex justify-center items-center`}
          >
            <p className=" text-[0.7rem]">1</p>
          </div>
          <p
            className={`ml-2 ${
              brcurrent === 0 || brcurrent === 2
                ? "text-white"
                : "text-[#898989]"
            } `}
          >
            Login
          </p>
        </div>
        <div
          onClick={() => dispatch({ type: "BRSET", num: 1 })}
          className={` ${
            brcurrent === 3
              ? "bg-gradient-to-tr bg-primary rounded-bl-[5rem] rounded-tl-[5rem]"
              : ""
          } cursor-pointer rounded-tr-[2.6rem] flex w-[50%] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 3
                ? "bg-white text-[lightblue]"
                : "bg-[#898989] text-white"
            } rounded-full flex justify-center items-center`}
          >
            <p className="text-[0.7rem]">2</p>
          </div>
          <p
            className={`${
              brcurrent === 3 ? "text-white" : "text-[#898989]"
            } ml-2 `}
          >
            Reset Password
          </p>
        </div>
      </div>
      <div
        className={`${
          brcurrent === 4 ? "" : "hidden"
        } w-[100%] h-[3.8rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          onClick={() => dispatch({ type: "BRSET", num: 0 })}
          className={`cursor-pointer ${
            brcurrent === 0 || brcurrent === 2
              ? " bg-gradient-to-tr bg-primary"
              : ""
          } flex rounded-tl-[2.5rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 0 || brcurrent === 2
                ? "bg-white text-[lightblue]"
                : "bg-[#898989] text-white"
            }  rounded-full flex justify-center items-center`}
          >
            <p className=" text-[0.7rem]">1</p>
          </div>
          <p
            className={`ml-2 ${
              brcurrent === 0 || brcurrent === 2
                ? "text-white"
                : "text-[#898989]"
            } `}
          >
            Login
          </p>
        </div>
        <div
          onClick={() => dispatch({ type: "BRSET", num: 1 })}
          className={` ${
            brcurrent === 4
              ? "bg-gradient-to-tr bg-primary rounded-bl-[5rem] rounded-tl-[5rem]"
              : ""
          } cursor-pointer rounded-tr-[2.6rem] flex w-[50%] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              brcurrent === 4
                ? "bg-white text-[lightblue]"
                : "bg-[#898989] text-white"
            } rounded-full flex justify-center items-center`}
          >
            <p className="text-[0.7rem]">2</p>
          </div>
          <p
            className={`${
              brcurrent === 4 ? "text-white" : "text-[#898989]"
            } ml-2 `}
          >
            Change Password
          </p>
        </div>
      </div>
      <Dis />
    </div>
  );
};

export default Login;
