import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import TransactionListing from "./TransactionListing";
const Listingh = () => {
  const router = useNavigate();
  return (
    <div className="">
      <div
        className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                // allTransactions
                //   ? dispatch({ type: "ALL_TRANSACTIONS", num: false })
                //   : router("/Merchant/dashboard");
                router("/Merchant/dashboard");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              All Transactions
              {/* {allTransactions === "Recent" ? "Recent Transactions" : allTransactions ==="All" ?  "All Transactions" : "Transactions"} */}
            </h2>
          </div>
        </div>
      </div>
      <TransactionListing />
      {/* {allTransactions === "Recent" ? <TransactionListing /> : allTransactions ==="All" ? <Table/> : <TransactionMain />} */}
    </div>
  );
};

export default Listingh;
