import React from "react";
import Sidebar from "../../Dashboard/Sidebar";
import PensionerReport from "./PensionerReport";
import { useDispatch, useSelector } from "react-redux";
import { MenuUnfoldOutlined } from "@ant-design/icons";

const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  return (
    <>
    <div className="mobile-screen h-screen flex flex-col">
      {/* Header with hamburger icon */}
      <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
       
        <MenuUnfoldOutlined
          className="text-2xl cursor-pointer"
          onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
        />

        <h1 className="text-xl font-bold">Reports</h1>
      </header>

      
      {openSideBar && (
        <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
          <Sidebar />
        </div>
      )}

     
      <div className="flex-grow overflow-y-auto">
        {/* Content area */}
        <main className="flex-grow overflow-y-auto p-4">
          <PensionerReport />
        </main>
      </div>
    </div>
    <div className="larger-screen">
      <div className="flex flex-row p-5 gap-7 h-screen bg-[#ebfff3]">
        <aside
          className="w-1/5 rounded-lg"
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <Sidebar />
        </aside>
        <div
          className="flex flex-col w-4/5 gap-5 overflow-y-scroll rounded-lg"
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <PensionerReport />
        </div>
      </div>
    </div>
  </>
  );
};

export default Index;
