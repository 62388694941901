import Listing from "../components/maintenance/trips/Listing"
import Nav from "../components/Nav/nav";
const ListingPage = () => {
  return (
    <div>
      <Nav />
      <Listing />
    </div>
  );
};

export default ListingPage;