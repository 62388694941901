import moment from "moment";
import { useEffect } from "react";
const BusinessHours = ({ company }) => {
  const daysOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const days = () => {
    const data = company?.merchcompanybusinessdays?.sort((a, b) => {
      const dayA = a.dayName.toLowerCase();
      const dayB = b.dayName.toLowerCase();
      return daysOrder.indexOf(dayA) - daysOrder.indexOf(dayB);
    });

    console.log(data);
  };

  useEffect(() => {
    days();
  }, [company]);

  return (
    <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
      <div className="sm:px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-600">
          Company Business Hours
        </h3>
        <div className="mt-6 border-t border-gray-300">
          <dl className="divide-y divide-gray-300">
            {company?.merchcompanybusinessdays
              ?.sort(
                (a, b) =>
                  daysOrder.indexOf(a.dayName) - daysOrder.indexOf(b.dayName)
              )
              ?.map((con, index) => (
                <div
                  key={index}
                  className="sm:px-4 py-6 md:grid md:grid-cols-3 sm:gap-4 sm:px-0"
                >
                  <dt className="text-sm font-medium leading-6 text-gray-900 capitalize">
                    {con.dayName}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 ml-3">
                    {/* {con?.merchcompanyshifts?.map((shift, shiftIndex) => (
                      <div key={shiftIndex}>
                        <div className="font-bold">{shift.shiftName}</div>
                        <div>
                          {moment(shift?.start, "HH:mm").format("hh:mm A")} -{" "}
                          {moment(shift?.end, "HH:mm").format("hh:mm A")}
                        </div>
                      </div>
                    ))} */}

                    {con?.merchcompanyshifts
                      ?.sort((a, b) => {
                        // Extract the first word from shiftName for comparison
                        const firstWordA = a.shiftName.split(" ")[0];
                        const firstWordB = b.shiftName.split(" ")[0];

                        // Compare the first words in reverse alphabetical order
                        return firstWordB.localeCompare(firstWordA);
                      })
                      .map((shift, shiftIndex) => (
                        <div key={shiftIndex}>
                          <div className="font-bold">{shift.shiftName}</div>
                          <div>
                            {moment(shift?.start, "HH:mm").format("hh:mm A")} -{" "}
                            {moment(shift?.end, "HH:mm").format("hh:mm A")}
                          </div>
                        </div>
                      ))}
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default BusinessHours;
