import RegistrationP from "./viewParts/registrationP";
import IdentificationP from "./viewParts/identifictionP";
import { useState } from "react";
import back5 from "../../imgs/back5.png";
import { useLocation, useNavigate } from "react-router-dom";
import CardFront from "../PensionerCards/CardFront"
import CardBack from "../PensionerCards/CardBack"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
const PcardView = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const Show = [RegistrationP, IdentificationP];
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const universalPenId = useSelector((state) => state.universalPenId);
  const universalBenId = useSelector((state) => state.universalBenId);
  const benDone = useSelector((state) => state.benDone);
  const penId = useSelector((state) => state.penId);
  const reset = useSelector((state) => state.reset);
  const Dis = Show[wow];
  useEffect(() => {
    return () => {
      dispatch({ type: "setProfileData", num: null });
      dispatch({ type: "benDone", num: "" });
    };
  }, []);
  useEffect(() => {
    if (benDone === "Done") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/users/profile?beneficiaryUserId=${universalBenId}&programId=${penId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch(() => {});
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({ type: "setProfileData", num: data.data });
        })
        .catch(() => {});
    }
  }, [universalPenId, reset]);
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  return (
    <div className="flex items-center justify-center">
      <div className="md:w-[80%] w-full pt-[5rem] md:pt-[2rem] md:pb-[5rem] p-4">
        {profileData && (
          <div>
            <div className="flex justify-between items-center mt-4 mb-6">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  onClick={() => {
                    if (dash === "/Dashboard/PensionerAccount") {
                      router("/Dashboard");
                    } else if (dash === "/Dashboard/cardView") {
                      router("/Dashboard/listing");
                    } else if (dash === "/EDashboard/cardView") {
                      router("/EDashboard/listing");
                    } else if (dash === "/superAdminDashboard/cardView") {
                      router("/superAdminDashboard/listing");
                    } else if (dash === "/AdminDashboard/cardView") {
                      router("/AdminDashboard/listing");
                    } else if (dash === "/superAdminDashboard/PcardView") {
                      router("/superAdminDashboard/profPage");
                    } else if (dash === "/AdminDashboard/PcardView") {
                      router("/AdminDashboard/profPage");
                    } else {
                      router("/EDashboard/profPage");
                      // router("/EDashboard/Pensioner_HistoryP");
                    }
                  }}
                  className="w-[1.7rem]"
                  src={back5}
                  alt=""
                />
                <div className="font-bold sm:text-[1.8rem] text-[1.2rem]">
                {dash === "/Dashboard/cardView" ||
                dash === "/EDashboard/cardView" ||
                dash === "/superAdminDashboard/cardView" ||
                dash === "/AdminDashboard/cardView"
                  ? "View Beneficiary"
                  : "View Pensioner"}
              </div>
              </div>
              
            </div>
            <div className="lg:mb-[2rem] flex mb-[2rem] ml-[10px]">
              <div
                className="cursor-pointer"
                onClick={() => changeHandler("R")}
              >
                <h2
                  className={`lg:text-[1.1rem] ${
                    wow === 0 ? "font-semibold" : ""
                  } `}
                >
                  Registration Details
                </h2>
                <div
                  className={`lg:w-[9.5rem] lg:h-[2px] mt-2 h-[2px] ${
                    wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                  } `}
                ></div>
              </div>

              <div
                className="cursor-pointer"
                onClick={() => changeHandler("I")}
              >
                <h2
                  className={`lg:text-[1.1rem] ml-6 ${
                    wow === 1 ? "font-semibold" : ""
                  } `}
                >
                  Identification Details
                </h2>
                <div
                  className={`lg:w-[9.5rem] lg:h-[2px]  ml-6 mt-2 h-[2px]  ${
                    wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                  }  `}
                ></div>
              </div>
            </div>

            <div className="w-full">
              {profileData.user && (
                <div>
                  {profileData.user.roles[0] === "pensioner" && (
                    // <div className="sm:flex-row flex flex-col h-[25rem] w-full">
                    <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
                      <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                        <CardFront />
                      </div>
                      <div className="sm:scale-[0.88] scale-y-[0.76] scale-x-[0.68]">
                        <CardBack />
                      </div>
                    </div>
                    // </div>
                  )}
                </div>
              )}
              <div className="mt-8">
                <Dis />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PcardView;
