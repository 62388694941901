import axios from "axios";
import { Checkbox, Select } from "antd";
import { useEffect, useState } from "react";
import across from "../../../../../imgs/across.png";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleFilled } from "@ant-design/icons";
import gallary from "../../../../../imgs/picture.png";
import device from "../../../../../imgs/sign.png";
import codeHandler from "../../../../Register/codeHandler/codehandle";
let file;
const EmployeeInfo = () => {
  const merchantEmployee = useSelector((state) => state.merchantEmployee);
  const empType = useSelector((state) => state.empType);
  const merchantStores = useSelector((state) => state.merchantStores);
  const [privileges, setPrivileges] = useState([]);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [role, setRole] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [sigD, setSigD] = useState(false);
  const [pop, setPop] = useState(false);
  const check = true;
  const token = useSelector((state) => state.token);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState();
  const dispatch = useDispatch();
  console.log("dataaaa", empType);
  const imageHandler = async (e) => {
    setSigD(false)
    setPop(false)
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setImage(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantIdentificationDocument`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setImageUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  //Get privileges list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges/all-merch-privileges`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPrivileges(data?.data);
      })

      .catch(() => {});
  }, []);

  const nextHandler = (e) => {
    e.preventDefault();
    if (role === "" && empType !== "store") {
      setErr("Please Select a role.");
      setShow(true);
    } else if (imageUrl === "") {
      setErr("Please Upload Signature.");
      setShow(true);
    } else {
      var body;
      if (check) {
        // if (role === "") {
        //   body = {
        //     employer,
        //     supervisor,
        //     departmentOrLocation: `${location}`,
        //   };
        // } else {
        body = {
          signature: imageUrl,
        };
        if (empType !== "store") {
          body.role = role;
        }
        // }
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-info?merchUserId=${merchantEmployee?.data?.merchUser?.id}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (empType !== "store") assignedPrivileges();
            // if (merchantStores?.length > 0) {
            //   return;
            // }
            dispatch({ type: "EINCREMENT" });
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };

  const assignedPrivileges = () => {
    // if (selectedRole === null) {
    //   setErr("Please Select Role");
    //   setShow(true);
    // } else {
    // if (!selectedPrivileges.includes("View pensioner")) {
    //   setErr("Please Select View Pensioner Privilege");
    //   setShow(true);
    // } else {
    setSelectedPrivileges(selectedPrivileges.filter((item) => item !== ""));
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges`,
        {
          merchEmployeeUserId: merchantEmployee?.data?.merchUser?.id,
          privileges: selectedPrivileges,
          merchHeadquarterId:
            merchantEmployee?.data?.merchUser?.merchheadquarterId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        // setShow1(true);
        setErr("Added Sucessfully");
        // setSelectedPrivileges([]);
        // setSelectedRole(null);
        // Set a timeout to close the modal after 2 seconds
        // setTimeout(() => {
        //   setShow1(false);
        // }, 1000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => {
        setErr(err?.message);
        setShow(true);
      });
    // }
    // }
  };
  //Select Multiple Priviliges
  const togglePrivilege = (privilege) => {
    // Check if the privilege is already in the selectedPrivileges array
    if (selectedPrivileges.includes(privilege)) {
      // If it's already selected, remove it
      setSelectedPrivileges(
        selectedPrivileges.filter((item) => item !== privilege)
      );
    } else {
      // If it's not selected, add it
      setSelectedPrivileges([...selectedPrivileges, privilege]);
    }
  };
  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setImage(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setImageUrl(data.data.url);
      })
      .catch(() => {});
  };
  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }
  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }
  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setImage("");
      })
      .catch(function () {});
  }
  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[55%] w-[85%] mt-3"
        onSubmit={nextHandler}
      >
        {empType !== "store" && (
          <div className="w-full">
            {/* <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Employer:
        </h2>
        <input
          type="text"
          name=""
          
          placeholder="Employer"
          onChange={handleChange(setEmployer)}
          value={employer}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        /> */}
            {/* <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Supervisor:
        </h2>
        <input
          type="text"
          name=""
          
          onChange={handleChange(setSupervisor)}
          value={supervisor}
          placeholder="Supervisor"
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        /> */}
            <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
              Roles/Position:
            </h2>
            <div>
              <Select
                placeholder="Select Role/Position"
                optionFilterProp="children"
                className="wow"
                style={{
                  marginBottom: "1.2rem",
                }}
                onChange={(val) => setRole(val)}
                // filterOption={(input, option) =>
                //   (option?.label ?? "")
                //     .toLowerCase()
                //     .includes(input.toLowerCase())
                // }
                size="middle"
                options={[
                  {
                    value: "Manager",
                    label: "Manager",
                  },
                  {
                    value: "Financial",
                    label: "Financial",
                  },
                  {
                    value: "Sales Clerk",
                    label: "Sales Clerk",
                  },
                  {
                    value: "Customer Support",
                    label: "Customer Support",
                  },

                  {
                    value: "Staff",
                    label: "Staff",
                  },
                  {
                    value: "Operations",
                    label: "Operations",
                  },
                ]}
              />
            </div>
            <h2 className="text-[#596F96] text-[1rem] font-semibold mb-4">
              Assign Privileges for Head Quarter
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4 mb-[3.125rem]">
              {privileges &&
                privileges.map((privilege) => (
                  <div
                    key={privilege}
                    className="p-2 flex items-center border shadow-sm rounded-md hover:bg-gray-100 transition duration-300 ease-in-out bg-white"
                  >
                    <Checkbox
                      type="checkbox"
                      className="text-slate-500 text-sm font-semibold text-gray-700 flex items-center"
                      checked={selectedPrivileges.includes(privilege)}
                      onChange={() => togglePrivilege(privilege)}

                      // checked={singlePrivilege === privilege}
                    >
                      {privilege}
                    </Checkbox>
                  </div>
                ))}
            </div>
          </div>
        )}

        <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
          Upload Signature:
        </h2>
        <div className="flex flex-col items-center bg-slate-50 p-10 rounded-md">
          <label
            onClick={() => setPop(true)}
            htmlFor="special-input"
            className={` cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Upload Signature</p>
            </div>
            {/* <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler}
            /> */}
          </label>
          <div className="flex flex-col items-center">
            <div className="w-[25rem] h-[13rem]">
              {image && (
                <img
                  className={`w-[25rem] h-[13rem]`}
                  src={image}
                  alt="signature"
                />
              )}
            </div>
            {sigD && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    GetWhitSigPic();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Open Pad
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ConfirmSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Get Sign
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ReSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Reset
                </button>
              </div>
            )}
            {sigD && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <p
                  onClick={downloadFile}
                  className="text-primary hover:underline cursor-pointer font-semibold"
                >
                  Download SDK
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 mb-6`}
          >
            {merchantStores?.length > 0 ? "Next" : "Register"}
          </button>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </form>
      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[50%] flex items-center justify-between mt-10 mb-10">
            {/* <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            /> */}
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={imageHandler}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;
