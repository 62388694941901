import axios from "axios";
import { useEffect, useState } from "react";
import store1 from "../../../../imgs/store1.svg";
import logo from "../../../../imgs/impLogo.png";
import { LinkOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
// import StoreModal from "../../../Pensioner/Pay/StoreModal";
import LinkStoreModal from "./LinkStoreModal";
import SuccessModal from "../../Modals/successModal";
import ErrorModal from "../../Modals/errorModal";
import AddStoreLink from "./AddStoreLink";
const Store = () => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const token = useSelector((state) => state.token);
  const [value, setValue] = useState("");
  console.log(value);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showsuccessModal, setShowsuccessModal] = useState(false);
  const [showerrorModal, setShowerrorModal] = useState(false);
  const selectedMerchantEmployeeInfo = useSelector(
    (state) => state.selectedMerchantEmployeeInfo
  );
  const [linkedStores, setLinkedStores] = useState([]);
  const [store, setStore] = useState([]);
  const [newStoreLink, setNewStoreLink] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const handleDelete = (id) => {
    setShow(false);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/${id}/by-merchant`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setErr("Store unlinked successfully");
        setShowsuccessModal(true);
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowerrorModal(true);
        console.log(err);
      });
  };

  // const nextHandler = (e) => {
  //   e.preventDefault();
  //   var body;

  //   const updatedSelectedItems = selectedItems.map((item) => ({
  //     merchstoreId: item.store,
  //     role: item.role,
  //   }));

  //   body = {
  //     stores: updatedSelectedItems,
  //   };
  //   // }
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/by-merchant?merchUserId=${merchantEmployee?.data?.merchUser?.id}`,
  //       body,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       // if (merchantStores?.length > 0) {
  //       // dispatch({ type: "EINCREMENT" });
  //       //   return;
  //       // }
  //     })
  //     .catch((err) => {
  //       // dispatch({ type: "EINCREMENT" });
  //     });
  // };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store?merchUserId=${selectedMerchantEmployeeInfo?.data?.merchUser?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setLinkedStores(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/unlinked-stores?merchUserId=${selectedMerchantEmployeeInfo?.data?.merchUser?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setStore(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);

  return (
    <div>
      <div className="flex justify-center">
        {linkedStores?.length > 0 ? (
          <div className="sm:w-[85%] w-[85%] mt-6">
            <div className="p-2 rounded-md">
              <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
                Linked Stores :
              </h2>
              <ul className=" space-y-8 p-4 mt-5">
                {linkedStores?.map((voc, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedStore(voc);
                      // setShowStoreModal(false);
                    }}
                  >
                    <div
                      className="w-full 
                     text-gray-900 bg-white
                     relative
                      border border-gray-300 rounded-lg cursor-pointer shadow-sm"
                    >
                      <div className="flex gap-2 px-3 py-2">
                        <img src={store1} alt="store" className="w-6 h-6" />
                        <div className="w-full text-md font-semibold">
                          {voc?.merchstore?.customStoreName}
                        </div>
                      </div>

                      <div className="w-full text-gray-500 text-xs px-3 py-2">
                        {voc?.merchstore?.lot +
                          ", " +
                          voc?.merchstore?.address +
                          ", " +
                          voc?.merchstore?.region +
                          ", " +
                          voc?.merchstore?.regionName}
                      </div>
                      <div
                        className="absolute top-2 right-4"
                        // className={`${selectedItems?.some(item => item.store.id === voc?.id)? "pointer-events-none" :"absolute top-2 right-4"}`}
                        onClick={() => {
                          if (
                            selectedItems?.some(
                              (item) => item.store.id === voc?.id
                            )
                          ) {
                            setErr("Store already linked");
                            setShowerrorModal(true);
                            return;
                          }
                          setShowModal(true);
                        }}
                      >
                        <LinkOutlined
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            color: selectedItems?.some(
                              (item) => item.store.id === voc?.id
                            )
                              ? "#1BA397"
                              : "black",
                          }}
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <button
                          className="bg-primary text-white rounded-b-lg p-1 pr-12 pl-12 text-[0.8rem] w-full"
                          onClick={() => {
                            setShow(true);
                            setSelectedStore(voc);
                          }}
                        >
                          Unlink Store
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
              {/* <button
            onClick={() => {
              dispatch({ type: "EINCREMENT" });
            }}
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 mb-6`}
          >
            Next
          </button> */}
            </div>
          </div>
        ) : (
          <div className="sm:w-[85%] w-[85%] mt-6">
            <div className="p-2 rounded-md">
              <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
                Linked Stores :
              </h2>
              <h1 className="text-xl text-gray-400 text-center">
                No Record Found
              </h1>
            </div>
          </div>
        )}

        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[28rem] pb-6 pt-6 flex items-center p-6 bg-white flex-col">
            <div className="w-[5rem] h-[5rem] flex items-center justify-center rounded-full">
              <img src={logo} alt="" className="w-full h-full" />
            </div>
            <p className="text-center w-full pb-5 pt-2 text-[1rem]">
              Are you sure to unlink employee from this store?
            </p>
            <div className="flex gap-4 w-[90%] items-center justify-between">
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="w-[12rem] p-[7px]  font-bold text-white rounded-lg bg-[#DE2827]"
              >
                No
              </button>
              <button
                onClick={() => {
                  handleDelete(selectedStore?.id);
                }}
                className="w-[12rem] p-[7px]  font-bold text-white rounded-lg bg-primary border-primary"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        {store?.length > 0 && (
          <div className="sm:w-[85%] w-[85%] mt-6">
            <div className="p-2 rounded-md">
              <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
                Other Stores :
              </h2>
              <ul className=" space-y-8 p-4 mt-5">
                {store?.map((voc, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedStore(voc);
                      // setShowStoreModal(false);
                    }}
                  >
                    <div
                      className="w-full 
                     text-gray-900 bg-white
                     relative
                      border border-gray-300 rounded-lg cursor-pointer shadow-sm"
                    >
                      <div className="flex gap-2 px-3 py-2">
                        <img src={store1} alt="store" className="w-6 h-6" />
                        <div className="w-full text-md font-semibold">
                          {voc?.customStoreName}
                        </div>
                      </div>

                      <div className="w-full text-gray-500 text-xs px-3 py-2">
                        {voc?.lot +
                          ", " +
                          voc?.address +
                          ", " +
                          voc?.region +
                          ", " +
                          voc?.regionName}
                      </div>
                      <div
                        className="absolute top-2 right-4"
                        // className={`${selectedItems?.some(item => item.store.id === voc?.id)? "pointer-events-none" :"absolute top-2 right-4"}`}
                        onClick={() => {
                          if (
                            selectedItems?.some(
                              (item) => item.store.id === voc?.id
                            )
                          ) {
                            setErr("Store already linked");
                            setShowerrorModal(true);
                            return;
                          }
                          setShowModal(true);
                        }}
                      >
                        <LinkOutlined
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            color: selectedItems?.some(
                              (item) => item.store.id === voc?.id
                            )
                              ? "#1BA397"
                              : "black",
                          }}
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <button
                          className="bg-primary text-white rounded-b-lg p-1 pr-12 pl-12 text-[0.8rem] w-full"
                          onClick={() => {
                            setNewStoreLink(true);
                            setSelectedStore(voc);
                          }}
                        >
                          Link Store
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
              {/* <button
            onClick={() => {
              dispatch({ type: "EINCREMENT" });
            }}
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 mb-6`}
          >
            Next
          </button> */}
            </div>
          </div>
        )}

        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[28rem] pb-6 pt-6 flex items-center p-6 bg-white flex-col">
            <div className="w-[5rem] h-[5rem] flex items-center justify-center rounded-full">
              <img src={logo} alt="" className="w-full h-full" />
            </div>
            <p className="text-center w-full pb-5 pt-2 text-[1rem]">
              Are you sure to unlink employee from this store?
            </p>
            <div className="flex gap-4 w-[90%] items-center justify-between">
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="w-[12rem] p-[7px]  font-bold text-white rounded-lg bg-[#DE2827]"
              >
                No
              </button>
              <button
                onClick={() => {
                  handleDelete(selectedStore?.id);
                }}
                className="w-[12rem] p-[7px]  font-bold text-white rounded-lg bg-primary border-primary"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <LinkStoreModal
          setShow={setShowModal}
          setSelectedItems={setSelectedItems}
          selectedStore={selectedStore}
          selectedItems={selectedItems}
          setShowerrorModal={setShowerrorModal}
          setShowsuccessModal={setShowsuccessModal}
          setErr={setErr}
          setShowModal={setShowModal}
        />
      )}

      {showerrorModal && <ErrorModal setShow={setShowerrorModal} err={err} />}
      {showsuccessModal && (
        <SuccessModal
          setShow={setShowsuccessModal}
          err={err}
          setValue={setValue}
        />
      )}
      {newStoreLink && (
        <AddStoreLink
          setShow={setNewStoreLink}
          setSelectedItems={setSelectedItems}
          selectedStore={selectedStore}
          selectedItems={selectedItems}
          setShowerrorModal={setShowerrorModal}
          setShowsuccessModal={setShowsuccessModal}
          setErr={setErr}
          setShowModal={setShowModal}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      )}
    </div>
  );
};

export default Store;
