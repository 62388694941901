import across from "../../imgs/across.png";
import back5 from "../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Card from "./Card";
import Table from "./merchEmployeeTable";
import GovernmentAgency from "./govermentAgency/govAgencyTable";
const Listing = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [test, setTest] = useState(false);
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const transactionType = useSelector((state) => state.transactionType);

  const Tabs = [
    {
      key: "program",
      label: "Merchants",
      component: <Table />,
    },
    {
      key: "general",
      label: "Goverment Agencies",
      component: <GovernmentAgency />,
    },
  ];
  const items = [
    {
      key: "1",

      label: `Total Merchants`,
      count: 90,
    },
    {
      key: "2",

      label: `Approved`,
      count: 46,
    },
    {
      key: "3",

      label: `Rejected`,
      count: 44,
    },
    {
      key: "4",

      label: `Newly Added`,
      count: 44,
    },
  ];

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);

  return (
    <>
      <div
        className="sm:p-10 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={`flex justify-between items-center mt-4`}>
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() => {
                  user === "employee"
                    ? router("/EDashboard")
                    : user === "admin"
                    ? router("/AdminDashboard")
                    : user === "superadmin"
                    ? router("/superAdminDashboard")
                    : router("/Dashboard");
                }}
                className="w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Merchants
              </h2>
            </div>

            {/* <button
              onClick={() => {
                // console.log("Add New Merchant clicked");
              }}
              className={` pointer-events-none
                  text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Add New Merchant
            </button> */}
          </div>
        </div>
      </div>

      <div className="sm:px-12 mt-6 sm:mt-5 m-5">
        <div className="grid grid-cols-2 gap-0 ">
          {Tabs.map((tab) => (
            <button
              className={`${
                tab.key === transactionType
                  ? "border-primary bg-primary font-semibold text-white rounded-t-lg"
                  : "text-[#B7B7B7] bg-[#ECECEC] font-semibold rounded-tr-lg"
              }  p-3  text-[0.95rem] shadow-md flex items-center justify-center `}
              onClick={() =>
                dispatch({ type: "TRANSACTION_TYPE", num: tab.key })
              }
            >
              {tab.label}
            </button>
          ))}
        </div>{" "}
      </div>

      
      <div className="bg-[#F6F4F4] mx-16 p-4 mb-4 mt-4 rounded">
        <div className="flex flex-wrap justify-center sm:ml-0 place-items-center gap-4 mt-[1rem] mb-8">
          {items.map((item) => (
            <Card key={item.key} item={item} />
          ))}
        </div>

        <div className="mt-6">
          {Tabs.find((tab) => tab.key === transactionType)?.component}
        </div>
      </div>

      {/* <Table /> */}

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{""}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Listing;
