import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Main = ({ recordsCr }) => {
  const dataUser = useSelector((state) => state.dataUser);
  const data = useSelector((state) => state.selectedGovAgency);
  console.log(data);
  const [statusData, setStatusData] = useState("");
  const location = useLocation();
  const dash = location.pathname;
  const reqDetail =
    dash === "/MHSSS/GovernmentAgency/Requests/detail" ? true : false;

  useEffect(() => {
    merchantStatusInfo();
  }, []);

  //get Status API
  const merchantStatusInfo = () => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${data?.merchId}/status`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setStatusData(data?.data);
      })
      .catch((err) => {});
    // }
  };
  const Div = ({ title, content }) => {
    return (
      <div className="flex flex-row justify-between">
        <div className="text-md font-medium leading-6 text-gray-600">
          {title}
        </div>
        <div
          // className={`${
          //   title === "Total available Balance :"
          //     ? data?.hasLowBalance === true
          //       ? "bg-red-500 text-white px-5 py-1"
          //       : "bg-primary text-white px-5 py-1"
          //     : "text-gray-700 break-words"
          // } text-md leading-6 rounded-md`}

          className={`${
            title === "Total available Balance :" &&
            data?.hasLowBalance === true
              ? "bg-red-500 text-white px-5 py-1"
              : title === "Total available Balance :" &&
                data?.hasLowBalance === false
              ? "bg-primary text-white px-5 py-1"
              : "text-gray-700 break-words"
          } text-md leading-6 rounded-md`}
        >
          {content}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="flex sm:flex-row flex-col">
        <div
          className="sm:w-[30%] bg-white rounded-md p-4 pr-1 w-full
        "
        >
          <header className="px-2 py-2 flex gap-2 items-center flex-col text-center bg-[#FAFAFA]">
            <div className="w-full flex items-center gap-2 p-2 ">
              <img
                className={`${"inline-flex object-cover border-4 border-primary rounded-full  bg-primary text-indigo-600 h-20 w-20"}`}
                src={data?.image}
                alt=""
              />
              <div className="h-full flex flex-col ml-2 items-center text-left">
                <h1 className={"text-gray-700 font-bold"}>{data?.name}</h1>
                <h2 className="text-sm text-primary font-bold">Government</h2>
              </div>
            </div>
            <div className={`${reqDetail ? "hidden" : "w-full"}`}>
              <div className="flex flex-wrap w-full">
                <h2 className="w-[50%] text-left font-semibold text-[15px] text-gray-600">
                  Phone Number :
                </h2>
                <p className="w-[50%] text-right  text-[14px] break-words text-gray-700">
                  {data?.phone}
                </p>
              </div>
              <div className="flex flex-wrap w-full justify-between">
                <h2 className="text-left font-semibold text-[15px] text-gray-600">
                  Email :
                </h2>
                <p className="text-right  text-[14px] text-gray-700 break-words">
                  {data?.email}
                </p>
              </div>
            </div>
          </header>
        </div>
        <div className="sm:w-[70%] w-full bg-white p-5 pl-1 rounded-md">
          <div className="w-full bg-[#FAFAFA] p-2 sm:gap-6 gap-2 flex h-full px-3 flex sm:flex-row flex-col">
            <div className="sm:w-[55%] flex flex-col gap-2">
              <Div title="Location :" content={data?.location} />
              <Div
                title="Last Transfer amount :"
                content={recordsCr?.[0] ? `$${recordsCr?.[0]?.amount}` : "----"}
              />
              <Div
                title="Last Transaction Date :"
                content={
                  recordsCr?.[0]
                    ? moment(recordsCr?.[0]?.createdAt)?.format(
                        "MM/DD/YYYY hh:mm A "
                      )
                    : "----"
                }
              />
            </div>
            <div className="sm:w-[45%] flex flex-col gap-2">
              <Div
                title="Cashing Fee :"
                content={
                  statusData?.feeType === "no fee"
                    ? "No Fee"
                    : statusData?.feeAmount
                    ? statusData?.feeAmount
                    : "" + " / " + statusData?.feeType
                    ? statusData?.feeType
                    : ""
                }
              />
              {/* <Div
                title="Total Amount paid (2024) : "
                content={"----"}
              /> */}

              <Div
                title="Low Limit :"
                content={
                  statusData?.lowLimit ? `$${statusData?.lowLimit}` : "$0"
                }
              />
              <Div
                title="Total available Balance :"
                content={`$${data?.balance ? data?.balance : "0"}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
