var codeHandler = (function(){
    var base64Chars = [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
            'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
            'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f',
            'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
            'o', 'p', 'q', 'r', 's', 't', 'u', 'v',
            'w', 'x', 'y', 'z', '0', '1', '2', '3',
            '4', '5', '6', '7', '8', '9', '+', '/'
        ],
         encode = { 'base64': codeBase64 },
         decode = { 'base64': decodeBase64 },
        
         handleFormat = { 'utf-8': toUTF8Binary };

         function stringToBinary(str, size, encodeType) {
            var i, len, binary = '';
            for (i = 0, len = str.length; i < len; i++) {
                binary = binary + handleFormat[encodeType.toLowerCase()](str.charCodeAt(i));
            }
            return binary;
        }
        
        function toUTF8Binary(unicode) {
            var binary = '', bitStream = unicode.toString(2)//, bitLen = bitStream.length;
            if (unicode >= 0x000000 && unicode <= 0x00007F) {
                binary = bitStream.padStart(8, '0');
            } else if (unicode >= 0x000080 && unicode <= 0x0007FF) {
                binary = bitStream.padStart(11, '0');
                binary = '110' + binary.substr(0, 5) + '10' + binary.substr(5);
            } else if (unicode >= 0x000800 && unicode <= 0x00FFFF) {
                binary = bitStream.padStart(16, '0');
                binary = '1110' + binary.substr(0, 4) + '10' + binary.substr(4, 6) + '10' + binary.substr(10);
            } else if (unicode >= 0x010000 && unicode <= 0x10FFFF) {
                binary = bitStream.padStart(21, '0');
                binary = '11110' + binary.substr(0, 3) + '10' + binary.substr(3, 6) + '10' + binary.substr(9, 6) + '10' + binary.substr(15);
            }
            return binary;
        }
        
    
        function base64Parse(binary24, flag) {
            var i, result = '', decode;
            if (flag === 1) {
                for (i = 0; i < 4; i++) {
                    decode = parseInt(binary24.substr(i * 6, 6), 2);
                    result += base64Chars[decode];
                }
            } else {
                for (i = 0; i < Math.floor(flag / 6) + 1; i++) {
                    decode = parseInt(binary24.substr(i * 6, 6), 2);
                    result += base64Chars[decode];
                }
                for (i = 0; i < 3 - Math.floor(flag / 6); i++) {
                    result += '=';
                }
            }
            return result;
        }
        
        
        function codeBase64(str) {
            var i, len, result = '', binary = stringToBinary(str, 8, 'utf-8');
            len = binary.length;
            for (i = 0; i < Math.floor(len / 24); i++) {
                result += base64Parse(binary.substr(i * 24, 24), 1);
            }
            var remCode = binary.substr(len - (len % 24));
            if (remCode.length > 0) {
                remCode = remCode.padEnd(24, '0');
                result += base64Parse(remCode, remCode.length);
            }
            return result;
        }
        
        function decodeBase64(str) {
            var i, len, t = 0, binary = '', newStr = '', curbinary;
            for (i = 0, len = str.length; i < len; i++) {
                curbinary = base64Chars.indexOf(str.charAt(i)).toString(2).padStart(6, '0');
                if (curbinary !== '-1') binary += curbinary;
                if (str.charAt(i) === '=') t++;
            }
            if (t > 0) len = binary.length - (6 - 2 * t);
            else len = binary.length;
            var start = 0, flag = [{ str: '0', len: 8 }, { str: '110', len: 11 }, { str: '1110', len: 16 }, { str: '11110', len: 21 }];
            while (start < len) {
                for (var j = 0; j < 4; j++) {
                    if (binary.startsWith(flag[j].str, start)) {
                        if (flag[j].len === 8) newStr += String.fromCharCode(parseInt(binary.substr(start, 8), 2));
                        else if (flag[j].len === 11) newStr += String.fromCharCode(parseInt(binary.substr(start + 3, 5) + binary.substr(start + 10, 6), 2));
                        else if (flag[j].len === 16) newStr += String.fromCharCode(parseInt(binary.substr(start + 4, 4) + binary.substr(start + 10, 6) + binary.substr(start + 18, 6), 2));
                        else if (flag[j].len === 21) newStr += String.fromCharCode(parseInt(binary.substr(start + 5, 3) + binary.substr(start + 10, 6) + binary.substr(start + 18, 6) + binary.substr(start + 26, 6), 2));
                        start += flag[j].len;
                        break;
                    }
                }
            }
            return newStr;
        }
        
    return {
        encode:function(str ,type){
            return encode[type](str);
        },
        decode:function(str, type){
            return decode[type](str);
        }
    };
})();
export default codeHandler;