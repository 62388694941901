import React from "react";
import Nav from "../components/Nav/nav";
import ChatPanel from "../components/ChatPanel/index";

const notifications = () => {
  return (
    <div className="flex flex-col h-screen">
      <Nav />

      <div>
        <ChatPanel />
      </div>
    </div>
  );
};

export default notifications;
