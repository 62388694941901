import React, { useRef, useState } from "react";
import { DatePicker } from "antd";
// import { useNavigate } from "react-router-dom";
// import ben from "../../../imgs/ben.svg";
// import pen from "../../../imgs/pen.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
// import ErrorModal from "../Modals/errorModal";
import moment from "moment";
const Header = ({ setPensioners, setRefetch, refetch, setShow }) => {
  // const dispatch = useDispatch();
  // const router = useNavigate();
  // const [searchTerm, setSearchTerm] = useState("");
  const token = useSelector((state) => state.token);
  // const [showDropdown, setShowDropdown] = useState(false);
  // const dataUser = useSelector((state) => state.dataUser);
  const [prompt, setPrompt] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  // const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  // const [searchDob, setSearchDob] = useState("");
  const [gid, setGid] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  console.log(err);
  const [showModal, setShowModal] = useState(false);
  // const [showLastName, setShowLastName] = useState(false);
  // const [showDob, setShowDob] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [addparams, setAddParams] = useState(false);
  const [history, setHistory] = useState(false);
  // const [count, setCount] = useState(1);
  // const [dataFetched, setDataFetched] = useState(false);
  // const dropdownRef = useRef(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  console.log(history, showModal);
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
      if (prompt) {
        const wow = parsePhoneNumber(prompt);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };

  const Search = () => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        ...(gid && { gid }),
        ...(lastName && { lastName }),
        ...(dob && { dateOfBirth: dob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        ...(email && { email }),
      };

      fetchPenioners(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  };
  const fetchPenioners = (params, cancelTokenSource) => {
    setShow(false);
    let api;
    // if (gid !== "" && email === "" && lastName === "" && dob === "" && countryCode === null && phoneNoo === null) {
    //     api = "pensioner-profile-by-gid"
    // }
    // else{
    api = "pensioner-profile-by-filters";
    // }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/${api}/by-merchant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        // if (gid !== "" && email === "" && lastName === "" && dob === "" && countryCode === null && phoneNoo === null) {
        //     dispatch({ type: "SELECTED_PENSIONER", num: data?.data });
        //     router(`/Merchant/PensionerProfile/${data?.data?.person?.id}`)
        // }
        // else{
        if (data?.data?.length === 0) {
          setPensioners([]);
          setShow(false);
        } else {
          // setShowDropdown(true);
          setHistory(false);
          setShow(false);
          setPensioners(data?.data);
        }
        // }
      })
      .catch((err) => {
        setShow(false);
        setErr(err.response.data.message);
        setShowModal(true);
        console.log(err);
      });
  };

  return (
    <div className="left-0 p-3 pr-4 pl-4  max-h-[10rem] z-10 rounded-md  bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto overflow-y-scroll custom-scrollbar">
      <input
        type="text"
        // id="gid-search"
        value={gid}
        onChange={(e) => {
          setGid(e.target.value);
        }}
        className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3
                                
                                `}
        placeholder="Search By GID"
      />
      <button
        onClick={() => {
          setAddParams(true);
        }}
        className={` ${
          addparams ? "hidden" : "text-primary font-semibold p-2  w-full"
        }`}
      >
        Search with additional parameters
      </button>

      <div className={`${addparams ? "" : "hidden"}`}>
        <div className="mt-2">
          <input
            type="text"
            // id="gid-search"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3"
            placeholder="Last Name"
          />
        </div>

        <div className="mt-2">
          <DatePicker
            onChange={(e, f) => setDob(f)}
            value={dob !== "" ? moment(dob) : null}
            placeholder="Date of birth"
          />
        </div>
      </div>
      <div
        onClick={() => {
          setShowPhone(true);
        }}
        className={`
                                ${
                                  showPhone
                                    ? "hidden"
                                    : "mt-2 flex items-center justify-center"
                                }
                                ${
                                  addparams
                                    ? "mt-2 flex items-center justify-center"
                                    : "hidden"
                                }`}
      >
        <button className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center">
          +
        </button>
      </div>

      <div
        className={`${
          showPhone ? "mt-2 flex items-center justify-center" : "hidden"
        }`}
      >
        <PhoneInput
          placeholder="Enter phone number"
          defaultCountry="GY"
          value={prompt}
          onChange={handleChange(setPrompt, "tt")}
          international
          countryCallingCodeEditable={false}
        />

        {/* Button to hide phone number again */}
        <button
          onClick={() => setShowPhone(false)}
          className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center ml-2"
        >
          -
        </button>
      </div>

      <div
        onClick={() => {
          setShowEmail(true);
        }}
        className={`                              
                                ${
                                  showEmail
                                    ? "hidden"
                                    : "mt-2 flex items-center justify-center"
                                }
                                ${
                                  addparams && showPhone
                                    ? "mt-2 flex items-center justify-center"
                                    : "hidden"
                                }`}
      >
        <button className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center">
          +
        </button>
      </div>

      <div
        className={`${
          showEmail ? "mt-2 flex items-center justify-center" : "hidden"
        }`}
      >
        <input
          type="text"
          // id="gid-search"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-2 p-3"
          placeholder="Email"
        />

        {/* Button to hide phone number again */}
        <button
          onClick={() => setShowEmail(false)}
          className="w-6 h-6 bg-primary text-white rounded-full focus:outline-none text-center ml-2"
        >
          -
        </button>
      </div>

      <div className="mt-2 flex w-full gap-2 justify-center">
        <button
          onClick={() => {
            // if (prompt) {
            //     const wow = parsePhoneNumber(prompt);
            //     if (wow) {
            //         let count = wow.countryCallingCode;
            //         let num = wow.nationalNumber;
            //         setCountryCode(count);
            //         setPhoneNoo(num);
            //     }
            // }

            // Set values and make the search call
            if (dob !== null && dob !== "") {
              // setSearchDob(dob);
            }

            // Hide the modal
            // setShow(false);

            // Make the search call
            Search();
          }}
          className={`bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2 pr-8 pl-8 ${
            gid === "" &&
            email === "" &&
            lastName === "" &&
            dob === "" &&
            countryCode === null &&
            phoneNoo === null
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          Search
        </button>

        <button
          onClick={() => {
            // setGid("");
            // setEmail("");
            // setSearchDob("");
            // setDob("");
            // setPhoneNoo(null);
            // setPrompt("");
            // setCountryCode(null);
            setShow(false);
            setAddParams(false);
            setRefetch(!refetch);

            if (
              gid !== "" ||
              email !== "" ||
              lastName !== "" ||
              dob !== "" ||
              countryCode !== null ||
              phoneNoo !== null
            ) {
              setRefetch(!refetch);
              //   setShow(false);
            }
          }}
          className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Header;
