export const selectedChatUser = (state = null, action) => {
  switch (action.type) {
    case "CHAT_SELECTED_USER":
      return action.num;
    default:
      return state;
  }
};

export const selectedChatNewUser = (state = null, action) => {
  switch (action.type) {
    case "CHAT_SELECTED_NEW_USER":
      return action.data;
    default:
      return state;
  }
};

export const selectedChatPhoneNo = (state = null, action) => {
  switch (action.type) {
    case "CHAT_SELECTED_PHONE_NO":
      return action.num;
    default:
      return state;
  }
};

export const chatMessages = (state = [], action) => {
  switch (action.type) {
    case "CHAT_MESSAGES":
      return action.messages;
    default:
      return state;
  }
};
