export const question1Reducer = (state = [], action) => {
  switch (action.type) {
    case "question1Set":
      return action.num;
    default:
      return state;
  }
};
export const question2Reducer = (state = [], action) => {
  switch (action.type) {
    case "question2Set":
      return action.num;
    default:
      return state;
  }
};

export const question3Reducer = (state = [], action) => {
  switch (action.type) {
    case "question3Set":
      return action.num;
    default:
      return state;
  }
};
export const question4Reducer = (state = [], action) => {
  switch (action.type) {
    case "question4Set":
      return action.num;
    default:
      return state;
  }
};
export const question5Reducer = (state = [], action) => {
  switch (action.type) {
    case "question5Set":
      return action.num;
    default:
      return state;
  }
};
export const question6Reducer = (state = [], action) => {
  switch (action.type) {
    case "question6Set":
      return action.num;
    default:
      return state;
  }
};
export const questionxReducer = (state = [], action) => {
  switch (action.type) {
    case "questionxSet":
      return action.num;
    default:
      return state;
  }
};
export const questionyReducer = (state = [], action) => {
  switch (action.type) {
    case "questionySet":
      return action.num;
    default:
      return state;
  }
};
export const question9Reducer = (state = [], action) => {
  switch (action.type) {
    case "question9Set":
      return action.num;
    default:
      return state;
  }
};
export const questionaReducer = (state = [], action) => {
  switch (action.type) {
    case "questionaSet":
      return action.num;
    default:
      return state;
  }
};
export const questionbReducer = (state = [], action) => {
  switch (action.type) {
    case "questionbSet":
      return action.num;
    default:
      return state;
  }
};
export const questioncReducer = (state = [], action) => {
  switch (action.type) {
    case "questioncSet":
      return action.num;
    default:
      return state;
  }
};
export const questiondReducer = (state = [], action) => {
  switch (action.type) {
    case "questiondSet":
      return action.num;
    default:
      return state;
  }
};
export const questioneReducer = (state = [], action) => {
  switch (action.type) {
    case "questioneSet":
      return action.num;
    default:
      return state;
  }
};
export const questionzReducer = (state = [], action) => {
  switch (action.type) {
    case "questionzSet":
      return action.num;
    default:
      return state;
  }
};
export const appUrlReducer = (state = "", action) => {
  switch (action.type) {
    case "appUrlSet":
      return action.num;
    default:
      return state;
  }
};
export const appId = (state = "", action) => {
  switch (action.type) {
    case "appId":
      return action.num;
    default:
      return state;
  }
};
