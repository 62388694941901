import e1 from "../../../../imgs/e1.png";
import e2 from "../../../../imgs/e2.png";
import e3 from "../../../../imgs/e3.png";
import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageE from "./pageE";
import { useEffect } from "react";
import axios from "axios";
import EmployeeTable from "./EmployeeTable";
let wow;

const ListingE = () => {
  const router = useNavigate();
  const [show, setShow] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const user=useSelector((state)=>state.user)
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    active: 0,
    inactive: 0,
    all: 0,
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=employee`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setCount({
          active: data?.data?.active,
          inactive: data?.data?.inactive,
          all: data?.data?.total,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div
        className="sm:p-10 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="flex justify-between w-full items-center mt-4">
            <div className="cursor-pointer flex items-center justify-center">
              <img
                onClick={() => {
                  user === "admin"
                    ? router("/AdminDashboard")
                    : router("/superAdminDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem] ml-[1rem]">
                Employees
              </h2>
            </div>
            {user === "superadmin" ? (
              <button
                onClick={() => {
                  dispatch({ type: "ISET", num: 0 });
                  // dispatch({ type: "PENSIONER" });
                  router("/superAdminDashboard/addAdmin");
                }}
                className={`${
                  dataUser.data.user.status === "pending"
                    ? "pointer-events-none"
                    : ""
                } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
              >
                Create Admin
              </button>
            ) : null}
          </div>
          {/* container w-full mx-auto p-10 md:p-20 grid lg:grid-cols-3 2xl:grid-cols-3 grid-cols-1 gap-y-10 */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:ml-0 place-items-center mt-[3rem] mb-8">
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                  Total Employees
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{count?.all}</p>
              </div>
              <div>
                <img className="w-[2.1rem] mt-4" src={e2} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[1rem] font-bold  text-[#797979] mb-1">
                  Active Employees
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">{count?.active}</p>
              </div>
              <div>
                <img className="w-[2.2rem] mt-4" src={e3} alt="" />
              </div>
            </div>
            <div className="w-[14rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between sm:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
              <div>
                <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                  Inactive Employees
                </h2>
                <p className="font-bold text-[1.4rem] ml-1">
                  {count?.inactive}
                </p>
              </div>
              <div>
                <img className="w-[2rem] mt-5" src={e1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmployeeTable />
    </>
  );
};

export default ListingE;
