import { useLocation, useNavigate } from "react-router-dom";
import AdditionalDocs from "../components/ExtraDocs/AdditionalDocs";
import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import across from "../imgs/across.png";
import AllDocs from "../components/ExtraDocs/AllDocs";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import cross from "../imgs/cross.png";
import Whatsapp from "../Whatsapp/whatsapp";
let body;
let filee;
const AditionalDocuments = () => {
  const user = localStorage.getItem("role");
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [wow, setWow] = useState(0);
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [upload, setUpload] = useState(false);
  const [uploadDoc, setUploadDoc] = useState(false);
  const privilege = useSelector((state) => state.myPrivileges);
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const [url, setUrl] = useState(null);
  const Show = [AdditionalDocs, AllDocs];
  const Dis = Show[wow];
  useEffect(() => {
    if (
      dataUser?.data?.user?.roles[0] === "employee" &&
      privilege?.includes("Upload Death Certificate") &&
      profileData?.user?.status === "ceased" &&
      profileData?.deactivationInfo?.docUrl === null
    ) {
      setUpload(true);
    }
  }, []);

  useEffect(() => {
    if (dash === "/Dashboard/additionalDocs") {
      setWow(1);
    }
  }, []);
  const handleFile = async (e) => {
    filee = e.target.files[0];
    {
      var bodyFormData = new FormData();
      bodyFormData.append("file", filee);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShowErr(true);
        });
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    body = {
      docUrl: url,
      docName: "Death Certificate",
    };
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/users/toggle-user/${profileData.deactivationInfo.id}/by-emp`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setUploadDoc(false);
        setUpload(false);
      })
      .catch((error) => {
        setErr(error.response.data.message);
        setShowErr(true);
      });
  };
  return (
    <div>
      <Nav />
      <div className="relative flex justify-between  sm:px-[7rem] px-[2rem] sm:mt-[5rem] mt-[2rem] mb-[2rem]">
        <div className="flex items-center ">
          <div
            onClick={() => {
              user === "employee"
                ? router("/EDashboard/profPage")
                : user === "admin"
                ? router("/AdminDashboard/profPage")
                : user === "superadmin"
                ? router("/superAdminDashboard/profPage")
                : router("/Dashboard");
            }}
            // className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold ml-[1rem] sm:text-[1.8rem] text-[1.4rem]">
            Documents
          </h2>
        </div>
        <div>
          {upload === true && (
            <button
              onClick={() => setUploadDoc(true)}
              className={`text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg cursor-pointer sm:ml-[2rem]`}
            >
              Upload death certificate
            </button>
          )}
          <button
            onClick={() => {
              // dispatch({ type: "ISET", num: 0 });
              // dispatch({ type: "PENSIONER" });
              // router("/EDashboard/addPensioner");
              if (wow === 0) {
                setWow(1);
              } else {
                setWow(0);
              }
            }}
            className={`${
              dash === "/Dashboard/additionalDocs" ? "hidden" : ""
            } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg cursor-pointer sm:ml-[2rem]`}
          >
            {wow === 1 ? "Add Documents" : "All Documents"}
          </button>
        </div>
      </div>
      <div
        className={`${
          uploadDoc ? "" : "hidden"
        } w-full h-full z-[10] overflow-auto fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <form onSubmit={submitHandler}>
          <div className="relative rounded-xl border w-[25rem] pb-4 pt-4 flex items-center p-2 bg-white flex-col">
            <div
              className="absolute top-3 right-4 cursor-pointer"
              onClick={() => {
                setUploadDoc(false);
              }}
            >
              <img className="scale-[0.8]" src={cross} />
            </div>
            <div className="flex flex-col w-full mt-4 flex items-center justify-center">
              <label
                htmlFor="fileInput"
                className="text-[#596F96] text-[0.88rem] font-bold mb-3"
              >
                Death Certificate
              </label>
              <input
                type="file"
                id="file-doc"
                accept="application/msword, application/vnd.ms-excel, application/pdf"
                onInput={handleFile}
                className={`text-[#596F96] text-[0.88rem] h-[30px]  border font-semibold mb-1`}
              ></input>
            </div>
            <div className="w-full flex item-center justify-center">
              <button
                type="submit"
                className={`text-white md:w-[100px] bg-green-500 pl-5 pr-5 pt-2 mt-5 mb-2 pb-2 rounded-lg cursor-pointer`}
              >
                Upload
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        className={`${
          showErr ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowErr(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowErr(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-[#DE2827] text-white w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <Dis />
      <Whatsapp />
    </div>
  );
};

export default AditionalDocuments;
