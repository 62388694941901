import { useSelector } from "react-redux";

import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";

import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";

// import SearchFilter from "./SearchFilter";

const Paget = () => {
  const router = useNavigate();
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDop, setSearchDop] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [outstanding, setOutstanding] = useState([]);
  const transactionType = useSelector((state) => state.programTransactionType);
  const [afterApi, setAfterApi] = useState(false);
  const merchantSapType = useSelector((state) => state.merchantSapType);
  console.log(transactionType);
  console.log(merchantSapType);
  const merchantSapTypeData = useSelector((state) => state.merchantSapTypeData);

  const [searchTerm, setSearchTerm] = useState("");

  const columnHeaders = [
    "SR.",
    "Date Time",
    "Voucher transaction ID",
    "Program Name",
    "Booklet #",
    "Amount",
    // "Actions",
  ];

  useEffect(() => {
    const delay = 200;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const merchParams = {
        limit: 6,
        skip:
          search || searchDop || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(transactionType?.type === "store"
          ? { merchstoreId: transactionType?.merchStore?.id }
          : { merchheadquarterId: transactionType?.merchHeadquarter?.id }),
      };

      const penParams = {
        limit: 6,
        skip:
          search || searchDop || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page,
        sortBy: "createdAt:desc",
        ...(merchantSapType === "store"
          ? { merchstoreId: merchantSapTypeData?.id }
          : {
              merchheadquarterId:
                merchantSapTypeData?.merchuser?.merchheadquarterId,
            }),
      };

      console.log("params", penParams);

      const params = user === "pensioner" ? merchParams : penParams;
      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [
    page,
    search,
    searchDop,
    countryCode,
    phoneNoo,
    pagewithsearch,
    searchTerm,
  ]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api =
      user === "pensioner"
        ? "v1/sapmoney-dashboard/program-transactions/for-pensioner"
        : `v1/sapmoney-dashboard/program-transactions/for-merch?pensionerUserId=${transactionType?.userId}`;

    const tok =
      user === "pensioner" ? dataUser.data.tokens.access.token : token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setOutstanding(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  const showHandler = (check) => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl relative  h-auto bg-white">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#D9EFED]">
          {/* table */}
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-3 py-4 font-semibold  whitespace-nowrap"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {outstanding?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p
                        className={`p-10 py-20 ${
                          outstanding?.length === 0 && afterApi === true
                            ? ""
                            : "hidden"
                        }`}
                      >
                        No data found
                      </p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {outstanding?.map((data, index) => (
                    <tr
                      onClick={() => {
                        router("/SapMoney/Merchant/MHSSSTransaction/detail", {
                          state: {
                            id: data?.id,
                            selectedTransactionsVouchers: data,
                          },
                        });
                      }}
                      className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {index + 1}.
                      </th>

                      <td className="px-4 py-3 whitespace-nowrap">
                        <div>
                          {moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.transactionId === null
                          ? "------"
                          : data?.transactionId}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.pensionbook?.pensionprogram?.name ===
                        "senior-citizen-Pension"
                          ? "Senior Citizen Pension"
                          : data?.pensionbook?.pensionprogram?.name}
                      </td>

                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.pensionbook?.bookletNo}
                      </td>
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                      {getMonthName(data?.coupon?.month)}
                    </td> */}
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                      {data?.paymentMethod}
                    </td> */}
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                      {data?.transactionId}
                    </td> */}
                      <td className="px-4 py-3">${data?.totalAmount}</td>
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                        <Popover
                        
                          placement="bottomLeft"
                        >
                          <div
                            className={`border-none ${
                              data?.mhsssPayStatus === "pending"
                                ? "bg-blue-500"
                                : data?.mhsssPayStatus === "rejected"
                                ? "bg-[#F35929]"
                                : "bg-primary"
                            }  p-1 pl-5 pr-5 rounded-md text-center text-white focus:border-none cursor-pointer capitalize`}
                          >
                            {data?.mhsssPayStatus === "success"
                              ? "Sent"
                              : data?.mhsssPayStatus}
                          </div>
                        </Popover>
                      </td> */}
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                        <Dropdown
                          menu={{
                            items,
                            // onClick: (key) => onClick(key, item),
                          }}
                          trigger={["click"]}
                          onClick={(e) => {
                            // Stop the propagation of the click event
                            e.stopPropagation();
                          }}
                        >
                          <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          </div>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Paget;
