import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import back5 from "../../imgs/back5.png";
import { Popover } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment/moment";
const History = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const dataUser = useSelector((state) => state.dataUser);
  const appId = useSelector((state) => state.appId);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    let api;
    if (user === "superadmin")
      api = `v1/sign-application/history/by-superadmin?applicationId=${appId}`;
    else if (user === "admin")
      api = `v1/sign-application/history/by-admin?applicationId=${appId}`;
    else if (user === "employee")
      api = `v1/sign-application/history/by-employee?applicationId=${appId}`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        console.log("dattaaa", data.data);
        setAllData(data?.data);
      })

      .catch(() => {});
  }, []);
  console.log("All Data", user);
  const columnHeaders = [
    "ID",
    "Date/Time",
    "Employee ID",
    "Employee Name",
    "Employee Email",
    "Role",
    "Status",
    "Device IP",
    "Location LBS/GEO",
  ];
  return (
    <div className="p-4 sm:p-10 sm:pl-[7rem] sm:pr-[7rem] p-[2rem] pl-[1rem] pr-[1rem] ">
      <div className="relative flex justify-between items-center mt-4">
        <div
          onClick={() => {
            if (user === "superadmin")
              router("/superAdminDashboard/allApplications");
            else if (user === "admin")
              router("/AdminDashboard/allApplications");
            else if (user === "employee") router("/EDashboard/allApplications");
          }}
          className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
        >
          <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold sm:text-[1.8rem] text-[1.6rem] sm:ml-0 ml-[2rem] mb-1">
          Application History
        </h2>
      </div>
      <div className="overflow-x-auto w-full mt-10 bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
            <tr>
              {columnHeaders.map((columnHeader) => (
                <th
                  scope="col"
                  className="px-4 py-3 whitespace-nowrap font-semibold tracking-wider"
                >
                  {columnHeader}
                </th>
              ))}
            </tr>
          </thead>
          {allData.length === 0 ? (
            <h1 className="flex items-center justify-center text-center text-[1.1rem] p-5">
              No Data Found
            </h1>
          ) : (
            <tbody>
              {allData.map((item, index) => (
                <tr className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer">
                  <th
                    scope="row"
                    className="px-4 py-5 font-medium text-gray-900  whitespace-nowrap "
                  >
                    {index + 1}
                  </th>
                  <td className="px-4 py-5 whitespace-nowrap">
                    <p>
                      {moment(item?.createdAt).format("MM/DD/YYYY")}
                    </p>
                    <p> {moment(item?.createdAt).format("hh:mm A")}</p>
                  </td>
                  <td className="px-4 py-5">
                    <Popover content={item?.user?.uniqueId}>
                      <div className="inline-block flex items-center justify-center max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {item?.user?.uniqueId.substring(0, 5)}..
                      </div>
                    </Popover>
                  </td>
                  <td className="px-4 py-5">
                    {item?.user?.person?.firstName}{" "}
                    {item?.user?.person?.lastName}
                  </td>
                  <td className="px-4 py-5">{item?.user?.email}</td>
                  <td className="px-4 py-5">{item?.user?.roles}</td>
                  <td className="px-4 py-5">
                    <div
                      className={`border-none ${
                        item.status === "pending"
                          ? "bg-[#2F84F1]"
                          : item.status === "rejected"
                          ? "bg-[#D64242]"
                          : " bg-primary"
                      }  p-1 pl-5 pr-5 rounded-md flex items-center justify-center text-white focus:border-none cursor-pointer capitalize`}
                    >
                      {item?.status}
                    </div>
                  </td>
                  <td className="px-4 py-5">
                    <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 pl-5 pr-5 rounded-md  focus:border-none cursor-pointer">
                      {item?.ip === null ? "----" : `${item?.ip}`}
                    </div>
                  </td>
                  <td className="px-4 py-5">
                    <div className="flex items-center justify-center ml-6 whitespace-nowrap">
                      {item?.location && (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${item?.location}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline hover:text-blue-700 cursor-pointer"
                        >
                          View Location
                        </a>
                      )}
                      {item?.location === null && "No Location"}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
export default History;
