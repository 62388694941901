import React from 'react'

const Cheque = ({ data }) => {
    console.log("data", data)
    return (
        <div className='p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8'>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-600">
                    Cheque Details
                </h3>

            </div>
            {
                data === null ? (
                    <h1 className='text-lg text-center text-gray-500 mt-6 border-t border-gray-300 p-5'>No Record Found</h1>
                ) :   <div className="mt-6 border-t border-gray-300">
                <dl className="divide-y divide-gray-300">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Email
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data?.email}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ">
                        <dt className="text-sm font-medium leading-6 text-gray-900 ">
                            Phone Number
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data?.phone}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ">
                        <dt className="text-sm font-medium leading-6 text-gray-900 ">
                            Mailing Address
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data?.mailingAddress}
                        </dd>
                    </div>



                </dl>
            </div>
            }
          
        </div>
    )
}

export default Cheque