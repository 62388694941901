import { useState } from "react";
import {
  PictureOutlined,
  FileAddOutlined,
  PlaySquareOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button, Input } from "antd";
let file;
const SendInput = ({ refetch, setRefetch }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [msg, setMsg] = useState("");
  const chatMessages = useSelector((state) => state.chatMessages);
  const selectedChatPhoneNo = useSelector((state) => state.selectedChatPhoneNo);
  const selectedChatUser = useSelector((state) => state.selectedChatUser);
  const [image, setImage] = useState(false);
  const [mediaConfirm, setMediaConfirm] = useState(false);
  const [video, setVideo] = useState(false);
  const [doc, setDoc] = useState(false);
  const [medId, setMedId] = useState(null);
  const [displayx, setDisplayx] = useState("");
  const token = useSelector((state) => state.token);
  const [medUrl, setMedUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileType, setFileType] = useState("");
  const headers = {
    Authorization: `Bearer EAADWiFSM1JMBOyoku3edPprlZAb8UrRFS4ljoEGIWQcEg1591POoLPAjBZAfU4hRk6oFq8NscGE7FgSUB2SZCI9xZCFMsgNjjsuPymZCmc5R5fk2ajVtMntJ8k3ndNZBcZA8Jjbz3eYon8RmcVG3XNawO6ZBBScS2hzeaDmN2SBBY2pyoVUsBluTgLcCiGj0IuCo`,
    "Content-Type": "application/json",
  };
  let url = "https://graph.facebook.com/v18.0/136722186191857/messages";

  let payload = {
    messaging_product: "whatsapp",
    to: selectedChatPhoneNo, //+16123968705
    type: "template",
    template: {
      name: "oap_1st_message",
      language: {
        code: "en",
      },
      components: [
        {
          type: "body",
          parameters: [
            {
              type: "text",
              text: `${"OAP"}`,
            },
          ],
        },
      ],
    },
  };

  let payloadWithoutTemplate = {
    messaging_product: "whatsapp",    
    recipient_type: "individual",
    to: selectedChatPhoneNo,
    type: "text",
    text: {
        preview_url: false,
        body: `${msg}`,
    }
}
  let imageBody = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: selectedChatPhoneNo,
    type: "IMAGE",
    image: {
      id: `${medId}`,
      caption: "",
    },
  };
  let videoBody = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: selectedChatPhoneNo,
    type: "VIDEO",
    video: {
      id: `${medId}`,
      caption: "",
    },
  };
  let docBody = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: selectedChatPhoneNo,
    type: "DOCUMENT",
    document: {
      id: `${medId}`,
      caption: "",
    },
  };

  const imageHandler = async (e) => {
    file = e.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=whatsapp`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("data", data);
        setMedUrl(data?.data?.url);
      })
      .catch(() => {});
  };


  const ForNewMessage=()=>{
   
    axios
      .post(url, payload, {
        headers: headers,
      })
      .then(function () {
        axios
        .post(url, payloadWithoutTemplate, {
          headers: headers,
        })
        .then(function (response) {
          History(response?.data?.messages[0]?.id, msg, "text");        
        })
        .catch(function () {});

       
       
      })
      .catch(function () {});
  }

  const SendMessage = () => {
    setMediaConfirm(false);
    if (!image && !video && !doc) {
      if(chatMessages.length === 0){
        ForNewMessage()
        
      }
     else{
      axios
        .post(url, payloadWithoutTemplate, {
          headers: headers,
        })
        .then(function (response) {
          History(response?.data?.messages[0]?.id, msg, "text");

          // handleSendMessage();
          // setMsgId(responseData.data.messages[0].id);
        })
        .catch(function () {});
    }
  }
    if (image) {
      axios
        .post(url, imageBody, {
          headers: headers,
        })
        .then(function (response) {
          setImage(false);

          History(response?.data?.messages[0]?.id, medUrl, "image");
        })
        .catch(function () {});
    }
    if (video) {
      axios
        .post(url, videoBody, {
          headers: headers,
        })
        .then(function (response) {
          setVideo(false);
          History(response?.data?.messages[0]?.id, medUrl, "video");

          // setMedId(null);
        })
        .catch(function () {});
    }
    if (doc) {
      axios
        .post(url, docBody, {
          headers: headers,
        })
        .then(function (response) {
          setDoc(false);
          History(response?.data?.messages[0]?.id, medUrl, "document");

          // setMedId(null);
        })
        .catch(function (error) {});
    }
  };

  const History = (mesId, content, type) => {
    const history = {
      senderCountryCode: "+592",
      senderPhoneNo: "6203710",
      recipientCountryCode: selectedChatUser?.countryCode,
      recipientPhoneNo: selectedChatUser?.phoneNo,
      content: content,
      messageId: mesId,
      webhookEventType: "message",
      messageStatus: "accepted",
      isOutGoing: false,
      type: type,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/whatsapp-chat?pensionerUserId=${selectedChatUser?.id}`,
        history,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function () {
        setRefetch(!refetch);
        setMsg("");
        setMedId(null);

        // handleSendMessage();
        // setMsgId(responseData.data.messages[0].id);
      })
      .catch(function () {});
  };

  function formatFileSize(bytes) {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;
    const gigabytes = megabytes / 1024;

    if (gigabytes >= 1) {
      return gigabytes.toFixed(2) + " GB";
    } else if (megabytes >= 1) {
      return megabytes.toFixed(2) + " MB";
    } else if (kilobytes >= 1) {
      return kilobytes.toFixed(2) + " KB";
    } else {
      return bytes + " bytes";
    }
  }
  const handleChange = (check) => async (e) => {
    console.log(e?.target?.files[0]);
    setFileName(e?.target?.files[0]?.name);
    const mimeType = e?.target?.files[0]?.type;
    const formattedSize = formatFileSize(e?.target?.files[0]?.size);
    setFileSize(formattedSize);
    const fileType = mimeType?.split("/")[1];
    setFileType(fileType);
    // setMediaConfirm(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplayx(base641);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("messaging_product", "whatsapp");
    formData.forEach((value, key) => {
      // console.log(key, "value", value);
    });
    if (check === "image") {
      setImage(true);
      setVideo(false);
      setDoc(false);
    } else if (check === "video") {
      setImage(false);
      setVideo(true);
      setDoc(false);
    } else if (check === "file") {
      setImage(false);
      setVideo(false);
      setDoc(true);
    }
    const header = {
      Authorization: `Bearer EAADWiFSM1JMBOyoku3edPprlZAb8UrRFS4ljoEGIWQcEg1591POoLPAjBZAfU4hRk6oFq8NscGE7FgSUB2SZCI9xZCFMsgNjjsuPymZCmc5R5fk2ajVtMntJ8k3ndNZBcZA8Jjbz3eYon8RmcVG3XNawO6ZBBScS2hzeaDmN2SBBY2pyoVUsBluTgLcCiGj0IuCo`,
      "Content-Type": "multipart/form-data",
    };
    axios({
      method: "post",
      url: `https://graph.facebook.com/v18.0/136722186191857/media`,
      data: formData,
      headers: header,
    })
      .then((data) => {
        setMedId(data.data.id);
        imageHandler(e);
        console.log("MediaId", medId);
        setShowButtons(false);
        setMediaConfirm(true);
      })
      .catch((err) => {
        console.log("errrrrrrrrrrr", err);
      });
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <div className=" relative px-4 py-4 flex items-center border-2 border-gray-200">
      <div className="relative flex items-center ">
        {showButtons && (
          <div className="absolute bottom-full right-0 mb-4 flex flex-col space-y-3 left-0 z-40">
            <div className="w-12 h-12 border border-gray-300 rounded-full text-black flex justify-center items-center cursor-pointer bg-white">
              <label htmlFor="file-image">
                <PictureOutlined className="iconeatach" />
              </label>
              <input
                id="file-image"
                style={{ display: "none" }}
                accept="image/*"
                onInput={handleChange("image")}
                type="file"
              />
            </div>
            <div className="w-12 h-12 border   border-gray-300 rounded-full text-black flex justify-center items-center cursor-pointer bg-white">
              <label htmlFor="file-video">
                <PlaySquareOutlined className="iconeatach" />
              </label>
              <input
                id="file-video"
                style={{ display: "none" }}
                accept="video/*"
                onInput={handleChange("video")}
                type="file"
              />
            </div>
            <div className="w-12 h-12 border   border-gray-300 rounded-full text-black flex justify-center items-center cursor-pointer bg-white">
              <label htmlFor="file-doc">
                <FileAddOutlined className="iconeatach" />
              </label>
              <input
                id="file-doc"
                style={{ display: "none" }}
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                onInput={handleChange("file")}
                type="file"
              />
            </div>
          </div>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-current text-gray-500 cursor-pointer"
          onClick={() => setShowButtons(!showButtons)}
        >
          <path d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z" />
        </svg>
      </div>
      {mediaConfirm && (
        <div
          // style={{ width: "70%" }}
          className="absolute bottom-full right-0 mb-4 flex flex-col space-y-3 left-0 w-full rounded-lg overflow-hidden sm:w-[60%] w-full"
        >
          <div className="bg-white border border-gray-200 rounded-lg shadow   mr-8 ml-8">
            {image && (
              <img
              className="rounded-t-lg m-auto"
                style={{ width: "100%", height: "250px", objectFit: "cover" }}
                src={displayx}
                alt=""
              />
            )}
            {video && (
              <video
                className="object-cover"
                style={{ width: "100%", height: "250px", objectFit: "cover" }}
                controls
              >
                <source src={displayx} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            {doc && (
              <div className="w-full">
                <div className="flex w-full my-2.5 bg-gray-50  rounded-xl p-6">
                  <div className="me-2">
                    <span className="flex items-center gap-2 text-sm font-medium text-gray-900  pb-2">
                      <svg
                        fill="none"
                        aria-hidden="true"
                        className="w-5 h-5 flex-shrink-0"
                        viewBox="0 0 20 21"
                      >
                        <g clip-path="url(#clip0_3173_1381)">
                          <path
                            fill="#E2E5E7"
                            d="M5.024.5c-.688 0-1.25.563-1.25 1.25v17.5c0 .688.562 1.25 1.25 1.25h12.5c.687 0 1.25-.563 1.25-1.25V5.5l-5-5h-8.75z"
                          />
                          <path
                            fill="#B0B7BD"
                            d="M15.024 5.5h3.75l-5-5v3.75c0 .688.562 1.25 1.25 1.25z"
                          />
                          <path
                            fill="#CAD1D8"
                            d="M18.774 9.25l-3.75-3.75h3.75v3.75z"
                          />
                          <path
                            fill="#F15642"
                            d="M16.274 16.75a.627.627 0 01-.625.625H1.899a.627.627 0 01-.625-.625V10.5c0-.344.281-.625.625-.625h13.75c.344 0 .625.281.625.625v6.25z"
                          />
                          <path
                            fill="#fff"
                            d="M3.998 12.342c0-.165.13-.345.34-.345h1.154c.65 0 1.235.435 1.235 1.269 0 .79-.585 1.23-1.235 1.23h-.834v.66c0 .22-.14.344-.32.344a.337.337 0 01-.34-.344v-2.814zm.66.284v1.245h.834c.335 0 .6-.295.6-.605 0-.35-.265-.64-.6-.64h-.834zM7.706 15.5c-.165 0-.345-.09-.345-.31v-2.838c0-.18.18-.31.345-.31H8.85c2.284 0 2.234 3.458.045 3.458h-1.19zm.315-2.848v2.239h.83c1.349 0 1.409-2.24 0-2.24h-.83zM11.894 13.486h1.274c.18 0 .36.18.36.355 0 .165-.18.3-.36.3h-1.274v1.049c0 .175-.124.31-.3.31-.22 0-.354-.135-.354-.31v-2.839c0-.18.135-.31.355-.31h1.754c.22 0 .35.13.35.31 0 .16-.13.34-.35.34h-1.455v.795z"
                          />
                          <path
                            fill="#CAD1D8"
                            d="M15.649 17.375H3.774V18h11.875a.627.627 0 00.625-.625v-.625a.627.627 0 01-.625.625z"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3173_1381">
                            <path
                              fill="#fff"
                              d="M0 0h20v20H0z"
                              transform="translate(0 .5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {fileName}
                    </span>
                    <span className="flex text-xs font-normal text-gray-500 gap-2">
                      {fileSize}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="self-center"
                        width="3"
                        height="4"
                        viewBox="0 0 3 4"
                        fill="none"
                      >
                        <circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
                      </svg>
                      {fileType}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="flex space-x-4 p-5">
              <button
                onClick={() => {
                  setMediaConfirm(false);
                  setImage(false);
                  setVideo(false);
                  setDoc(false);
                }}
                className="bg-red-500 text-white px-2 py-2 rounded-lg focus:ring-4 "
              >
                Discard Media
              </button>
              <button
                onClick={() => {
                  // setMediaConfirm(false);
                  // setImage(false);
                  // setVideo(false);
                  // setDoc(false);
                  setShowButtons(true);
                }}
                className="bg-primary text-white px-4 py-2 rounded-lg focus:ring-4 focus:outline-none focus:ring-green-300"
              >
                Add new Media
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <div className="flex-1 mx-4">
        <input
          className="w-full border rounded-lg px-2 py-2 bg-gray-200"
          type="text"
          value={msg}
          onChange={(e) => {
            setMsg(e.target.value);
          }}
        />
      </div> */}
      <div className="flex-1 mx-4">
        <Input
          className="w-full border rounded-lg px-2 py-2 bg-gray-200"
          value={msg}
          onChange={(e) => {
            setMsg(e.target.value);
          }}
          onPressEnter={
          SendMessage}
        ></Input>
      </div>
      <Button
        className="rounded-full text-black w-[40px] h-[40px] cursor-pointer bg-primary ml-[3px] flex justify-center items-center"
        onClick={() => {
          SendMessage();
        }}
      >
        <SendOutlined className="text-white" />
      </Button>
    </div>
  );
};

export default SendInput;
