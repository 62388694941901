import e1 from "../../../imgs/e1.png";
import e2 from "../../../imgs/e2.png";
import e3 from "../../../imgs/e3.png";
import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import PageE from "./groAdminElisting";
import { useDispatch, useSelector } from "react-redux";
const GroAdminE = () => {
  const router = useNavigate();
  const dataUser = useSelector((state) => state.dataUser);
  const [total, setTotal] = useState(null);
  const [active, setActive] = useState(null);
  const [inActive, setInActive] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=gro-employee`,
      {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      }
    )
    .then((data) => {
      setTotal(data?.data?.total)
      setActive(data?.data?.active)
      setInActive(data?.data?.inactive)
    })
    .catch(() => {
      
    });
}, []);
  return (
    <>
    <div className="sm:p-10 sm:pl-[7rem] sm:pr-[7rem]" style={{display:'flex', alignItems:'center',justifyContent:'center', flexDirection:'column'}}>
      <div style={{display:'flex', alignItems:'center',justifyContent:'center', flexDirection:'column', width:'100%'}}>
        <div className="relative flex justify-between items-center mt-4 p-1" style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:"100%"}} >
          <div
            onClick={() => {
              router("/GROAdminDash")
            }}
            className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
            GRO Employees
          </h2>
          <button
            onClick={() => {
                dispatch({ type: "ISET", num: 0 });
                router("/GroAdminDash/AddGroEmp");
            }}
            className={`text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
          >
            Create GRO Employee
          </button>
         
        </div>
        <div className="md:flex-row  flex-col ml-0 flex items-center justify-center w-full md:justify-between mt-[3rem] mb-8">
          <div className="w-[16rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between md:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2] ">
            <div>
              <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                Total GRO Employees
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">{total}</p>
            </div>
            <div>
              <img className="w-[2.1rem] mt-4" src={e2} alt="" />
            </div>
          </div>
          <div className="w-[16rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between md:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
            <div>
              <h2 className="text-[1rem] font-bold  text-[#797979] mb-1">
                Active GRO Employees
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">{active}</p>
            </div>
            <div>
              <img className="w-[2.2rem] mt-4" src={e3} alt="" />
            </div>
          </div>
          <div className="w-[16rem] h-[5.5rem] bg-[#f0eded] rounded-md p-3 flex justify-between md:mb-0 mb-4 shadow-lg hover:bg-[#E5E4E2]">
            <div>
              <h2 className="text-[1rem] font-bold text-[#797979] mb-1">
                Inactive GRO Employees
              </h2>
              <p className="font-bold text-[1.4rem] ml-1">{inActive}</p>
            </div>
            <div>
              <img className="w-[2rem] mt-5" src={e1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageE/>
    </>
  );
};

export default GroAdminE;
