import { Select, DatePicker } from "antd";
import { useRef, useState } from "react";
import check from "../../../imgs/add.png";
import trash from "../../../imgs/trash.png";
import back5 from "../../../imgs/back5.png";
import {
  PlusCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
let file;
const Mid = () => {
  const router = useNavigate();
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);
  const [display, setDisplay] = useState("");
  const [test, setTest] = useState(false);
  // const [lName, setLName] = useState("");
  // const [lName2, setLName2] = useState("");
  // const [locationt, setLocationt] = useState("");
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  const imageHandler = (setState) => async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const imageHandlerE = async (e) => {
    const file = e.target.files[0];
    const base64Image = await convertToBase(file);
    setImages([...images, base64Image]);
    console.log("Images", images);
  };
  function convertToBase(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <div className=" w-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-full flex items-center justify-between sm:w-[70%] w-[80%]">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => router("/Inventory")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Add Product
              </h2>
            </div>
          </div>
        </div>
      </div>
      <form action="" className="mt-10 sm:mt-[5rem] mb-[5rem]" onSubmit={""}>
        <div className="flex items-center justify-center mb-4 flex-col">
          <label htmlFor="special-input" className="cursor-pointer relative">
            <div className="relative w-[18rem] h-[10rem] overflow-hidden border-2 border-[#686262] bg-gray-200  rounded-md mb-4 ">
              <img
                className={`w-full h-full ${display === "" ? "hidden" : ""}`}
                src={display}
                alt=""
              />
            </div>

            <div
              className={`bg-primary
               rounded-full absolute bottom-[3px] right-[46%]`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler(setDisplay)}
              ref={fileInputRef}
            />
          </label>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Add BarCode
          </h2>
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Event Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  SKU <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="SKU"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Item Code <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Item Code"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Inventory <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Inventory"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={[
                    {
                      value: "Grocery",
                      label: "Grocery",
                    },
                    {
                      value: "Pharmacy",
                      label: "Pharmacy",
                    },
                    {
                      value: "Electronics",
                      label: "Electronics",
                    },
                    {
                      value: "Books",
                      label: "Books",
                    },
                    {
                      value: "Bakery",
                      label: "Bakery",
                    },
                    {
                      value: "Restaurants",
                      label: "Restaurants",
                    },
                    {
                      value: "Furniture",
                      label: "Furniture",
                    },
                    {
                      value: "Footwear",
                      label: "Footwear",
                    },
                    {
                      value: "Hardware",
                      label: "Hardware",
                    },
                    {
                      value: "Clothes",
                      label: "Clothes",
                    },
                    {
                      value: "Sports",
                      label: "Sports",
                    },
                    {
                      value: "Stationery",
                      label: "Stationery",
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Category <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Category"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={[
                    {
                      value: "Vegetables",
                      label: "Vegetables",
                    },
                    {
                      value: "Fruits",
                      label: "Fruits",
                    },
                    {
                      value: "Meat",
                      label: "Meat",
                    },
                    {
                      value: "Sea Food",
                      label: "Sea Food",
                    },
                    {
                      value: "Bakery",
                      label: "Bakery",
                    },
                    {
                      value: "Dairy",
                      label: "Dairy",
                    },
                    {
                      value: "Juices",
                      label: "Juices",
                    },
                    {
                      value: "Snacks",
                      label: "Snacks",
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Cost <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Cost"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Selling Price <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Selling Price"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Discount <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Discount"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Expiry Date <span className="text-red-600">*</span>
                </label>
                <DatePicker
                  placeholder="Expiry Date"
                  style={{ marginBottom: "1.2rem" }}
                  disabledDate={disabledDate1}
                  className="date-class  h-[2.7rem]"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Low Limit <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Low Limit"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Recorder Quantity <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Address"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Default Vendor <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Default Vendor"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Location <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Location"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                  Long Description <span className="text-red-600">*</span>
                </label>
                <textarea
                  type="text"
                  placeholder="Long Description"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <label
              htmlFor="special"
              className="cursor-pointer  sm:w-[70%] w-[80%] relative"
            >
              <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                <div>
                  <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                </div>
                <p className="text-[#9CA6C2] ml-2">Add Product Images</p>
              </div>
              <input
                type="file"
                className="hidden w-[100%] cursor-pointer"
                id="special"
                accept="image/png, image/gif, image/jpeg"
                onChange={imageHandlerE}
              />
            </label>
            <div className=" sm:w-[70%] w-[80%] flex gap-4 justify-between flex-wrap">
              {images.map((image, index) => (
                <div key={index} className="relative mb-4 w-[30%]">
                  <img
                    onClick={() => {}}
                    className="w-full h-48 rounded-xl object-cove"
                    src={image}
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {
                      setImages(images.filter((_, i) => i !== index));
                    }}
                  >
                    <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div
            className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
          >
            <button
              type="button"
              className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Add Product
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Mid;
