import { Select } from "antd";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import moment from "moment";
import { PlusCircleFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import across from "../../../../imgs/across.png";
import cross from "../../../../imgs/cross.png";
let data;
let defaultDate;
let file;
let isApplicableUser;
const PersonalDetails1 = () => {
  const privilege = useSelector((state) => state.myPrivileges);
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  const benDone = useSelector((state) => state.benDone);
  const options1 = useMemo(() => countryList().getData(), []);
  const location = useLocation();
  const dash = location.pathname;
  const user = useSelector((state) => state.user);
  const penStatus = dataUser?.data?.user?.status;
  const [title, setTitle] = useState(undefined);
  const [allDis, setAllDis] = useState(false);
  const [optDis, setOptDis] = useState(false);
  const [show, setShow] = useState("");
  const [iShow, setIShow] = useState(false);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(undefined);
  const [callName, setCallName] = useState(undefined);
  const [isLoading1, setIsLoading1] = useState(undefined);
  const [display, setDisplay] = useState("");
  const [firstName, setFirstName] = useState(undefined);
  const [middleName, setMiddleName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [ethnicity, setEthnicity] = useState(undefined);
  const [unionStatus, setUnionStatus] = useState(undefined);
  const [employmentStatus, setEmploymentStatus] = useState(undefined);
  const [nationality, setNationality] = useState(undefined);
  const [religion, setReligion] = useState(undefined);
  const [homePhone, setHomePhone] = useState(undefined);
  const [workPhone, setWorkPhone] = useState(undefined);
  const [status, setStatus] = useState("");
  const [age1, setAge1] = useState("");
  const [test, setTest] = useState(true);
  console.log(defaultDate);
  const dispatch = useDispatch();
  const [inital, setInitial] = useState(true);
  useEffect(() => {
    const applicableUsers = ["admin", "superadmin", "employee"];
    isApplicableUser =
      applicableUsers.includes(user) && penStatus === "approved";
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data, status]);
  useEffect(() => {
    if (
      (benDone !== "Done" && profileData.person) ||
      (benDone === "Done" && profileData.beneficiaryPerson)
    ) {
      if (benDone === "Done") {
        data = profileData.beneficiaryPerson;
      } else {
        data = profileData.person;
      }

      if (data.title !== null) {
        setTitle(data.title);
      }
      if (data.image !== null) {
        setImage(data.image);
        setDisplay(data.image);
      }
      if (data.title !== null) {
        setTitle(data.title);
      }
      if (data.callName !== null) {
        setCallName(data.callName);
      }
      if (data.firstName !== null) {
        setFirstName(data.firstName);
      }
      if (data.middleName !== null) {
        setMiddleName(data.middleName);
      }
      if (data.lastName !== null) {
        setLastName(data.lastName);
      }
      if (data.dateOfBirth !== null) {
        setDateOfBirth(data.dateOfBirth);
      }
      if (data.ethnicity !== null) {
        setEthnicity(data.ethnicity);
      }
      if (data.unionStatus !== null) {
        setUnionStatus(data.unionStatus);
      }
      if (data.employmentStatus !== null) {
        setEmploymentStatus(data.employmentStatus);
      }
      if (data.nationality !== null) {
        setNationality(data.nationality);
      }
      if (data.religion !== null) {
        setReligion(data.religion);
      }
      if (data.homePhone !== null) {
        setHomePhone(data.homePhone);
      }
      if (data.workPhone !== null) {
        setWorkPhone(data.workPhone);
      }
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [profileData]);

  const updateHandler = () => {
    setIsLoading(true);

    let body1 = {
      title,
      image,
      callName,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      ethnicity,
      unionStatus,
      employmentStatus,
      nationality,
      religion,
      homePhone,
      workPhone,
    };
    if (
      dash === "/Dashboard/BeneficiaryAccount" ||
      dash === "/EDashboard/BeneficiaryAccount"
    ) {
      let wow;
      if (dash === "/Dashboard/BeneficiaryAccount") {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/persons/${profileData.beneficiaryPerson.id}`;
      } else {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary/persons/${profileData.beneficiaryPerson.id}/update-by-staff`;
      }
      axios
        .put(
          wow,
          {
            person: body1,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "RESET" });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      if (dash !== "/EDashboard/PensionerAccount") {
        axios
          .put(`${process.env.REACT_APP_BASE_URL}/v1/persons`, body1, {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          })
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/persons/${profileData.person.id}/update-by-staff`,
            {
              person: body1,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };
  useEffect(() => {
    const dob = new Date(data.dateOfBirth);
    const now = new Date();
    let age = now.getFullYear() - dob.getFullYear();
    const monthDiff = now.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
      age--;
    }
    setAge1(age);
  }, []);

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      if (event) {
        const birthDate = event.$d;
        var today = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        let wow;
        if (event.$M <= 9) {
          if (event.$D <= 9) {
            wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
          } else {
            wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
          }
        } else {
          if (event.$D <= 9) {
            wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
          } else {
            wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
          }
        }
      } else if (event === null) {
      }
    } else if (check === "tg") {
      setState(event);
    } else {
      setState(event.target.value);
    }
  };

  const imageHandler = async (e) => {
    setIsLoading1(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=profile`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((data) => {
        setImage(data.data.url);
        setIsLoading1(false);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    if (inital) {
      setInitial(false);
    } else {
      defaultDate = moment(
        new Date(
          dateOfBirth.substring(0, 4),
          dateOfBirth.substring(5, 7),
          dateOfBirth.substring(8, 10)
        )
      );
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [dateOfBirth]);

  useEffect(() => {
    if (dash === "/EDashboard/PensionerAccount") {
      if (profileData?.user?.isDisabled) {
        setAllDis(true);
        setOptDis(true);
      } else {
        setAllDis(false);
        setOptDis(false);
      }
    } else {
      if (benDone !== "Done") {
        if (profileData?.user?.status === "approved") {
          setAllDis(true);
          setOptDis(false);
        } else if (
          profileData?.user?.status === "pending" ||
          profileData?.user?.status === "verified" ||
          profileData?.user?.status === "deferred" ||
          profileData?.user?.status === "in-review" ||
          profileData?.user?.status === "re-submitted"
        ) {
          setAllDis(true);
          setOptDis(true);
        }
      }
    }

    if (isApplicableUser) {
      setAllDis(true);
      setOptDis(true);
    }

    if (benDone === "Done") {
      setStatus("Ben");
    } else {
      setStatus(profileData?.user?.status);
    }
  }, [profileData]);

  console.log(data);

  return (
    <div>
      {data && (
        <div>
          {}
          <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-8">
            Personal Information
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold">Profile Image:</h2>
          <label
            htmlFor="special-input"
            className={`${
              isLoading1 || optDis ? "pointer-events-none" : ""
            } cursor-pointer relative`}
          >
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Profile Image</p>
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler}
            />
          </label>

          <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
            <img
              onClick={() => {
                setIShow(true);
              }}
              className="w-[25rem] h-[13rem]"
              src={display}
              alt=""
            />
            {/* <div
              className={`absolute ${
                isLoading1 ? "" : "hidden"
              } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
            >
              <Spin size="large" />
            </div> */}
            {/* <div
                className="absolute bottom-0 right-0 cursor-pointer bg-white"
                onClick={() => setDisplay("")}
              >
                <img className="w-[3rem]" src={trash} alt="" />
              </div> */}
          </div>
          <div
            className={`${
              iShow ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
          >
            <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
              <img
                className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                src={cross}
                onClick={() => setIShow(false)}
                alt=""
              />
              <img className="w-[45rem] h-[28rem]" src={display} alt="" />
            </div>
          </div>
          <h2 className="mb-4 text-[1.1rem] font-semibold mt-4">Title:</h2>
          <Select
            placeholder="Title"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${optDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            onChange={handleChange(setTitle, "tg")}
            defaultValue={{
              value: `${data.title}`,
              label: `${data.title}`,
            }}
            options={[
              {
                value: "Mr.",
                label: "Mr.",
              },
              {
                value: "Miss.",
                label: "Miss.",
              },
              {
                value: "Mrs.",
                label: "Mrs.",
              },
            ]}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">First Name:</h2>
          <input
            type="text"
            name=""
            
            placeholder="First Name"
            onChange={handleChange(setFirstName)}
            value={firstName}
            defaultValue={data.firstName}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 ${
              allDis ? "pointer-events-none" : "pointer-events-auto"
            }`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Middle Name:</h2>
          <input
            type="text"
            name=""
            
            placeholder="Middle Name"
            onChange={handleChange(setMiddleName)}
            value={middleName}
            defaultValue={data.middleName}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 ${
              optDis ? "pointer-events-none" : "pointer-events-auto"
            }`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Last Name:</h2>
          <input
            type="text"
            name=""
            
            placeholder="Last Name"
            onChange={handleChange(setLastName)}
            value={lastName}
            defaultValue={data.lastName}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 ${
              allDis ? "pointer-events-none" : "pointer-events-auto"
            }`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">AKA / Call Name:</h2>
          <input
            type="text"
            name=""
            
            placeholder="AKA / Call Name"
            onChange={handleChange(setCallName)}
            value={callName}
            defaultValue={data.callName}
            className={` ${
              optDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />

          <h2 className="mb-4 text-[1.1rem] font-semibold">Date of Birth:</h2>
          <input
            type="text"
            name=""
            
            placeholder="Date of Birth"
            value={moment(new Date(data.dateOfBirth)).format("MM-DD-YYYY")}
            className={` ${
              allDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          {/* <div className="flex flex-col">
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Date of Birth
            </label>
            {}
            <DatePicker
              style={{ marginBottom: "1.2rem" }}
              disabledDate={disabledDate1}
              defaultValue={defaultDate}
              className="date-class"
              // onChange={handleChange(setDateOfBirth, "tt")}
            />
          </div> */}
          <h2 className="mb-4 text-[1.1rem] font-semibold">Age:</h2>
          <input
            type="text"
            name=""
            
            placeholder="Age"
            value={age1}
            className={` ${
              optDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Gender:</h2>
          <Select
            placeholder="Gender"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            defaultValue={{
              value: `${data.gender}`,
              label: `${data.gender}`,
            }}
            options={[
              {
                value: "Male",
                label: "Male",
              },
              {
                value: "Female",
                label: "Female",
              },
              {
                value: "Non-Binary",
                label: "Non-Binary",
              },
              {
                value: "Transgender",
                label: "Transgender",
              },
            ]}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Ethnicity:</h2>
          <Select
            placeholder="Ethnicity"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${optDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            onChange={handleChange(setEthnicity, "tg")}
            defaultValue={{
              value: `${
                data.ethnicity === null ? "Ethnicity" : `${data.ethnicity}`
              }`,
              label: `${
                data.ethnicity === null ? "Ethnicity" : `${data.ethnicity}`
              }`,
            }}
            options={[
              {
                value: "African",
                label: "African",
              },
              {
                value: "Amerindian",
                label: "Amerindian",
              },
              {
                value: "Chinese",
                label: "Chinese",
              },
              {
                value: "East Indian",
                label: "East Indian",
              },
              {
                value: "European",
                label: "European",
              },
              {
                value: "Mixed Race",
                label: "Mixed Race",
              },
              {
                value: "Portuguese",
                label: "Portuguese",
              },
              {
                value: "Other",
                label: "Other",
              },
            ]}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Marital Status:</h2>
          <Select
            placeholder="Marital Status"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${optDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            defaultValue={{
              value: `${
                data.unionStatus === null
                  ? "Marital Status"
                  : `${data.unionStatus}`
              }`,
              label: `${
                data.unionStatus === null
                  ? "Marital Status"
                  : `${data.unionStatus}`
              }`,
            }}
            onChange={handleChange(setUnionStatus, "tg")}
            options={[
              {
                value: "common law",
                label: "Common Law",
              },
              {
                value: "divorced",
                label: "Divorced",
              },
              {
                value: "married",
                label: "Married",
              },
              {
                value: "separated",
                label: "Separated",
              },
              {
                value: "single",
                label: "Single",
              },
              {
                value: "visiting relation",
                label: "Visiting Relation",
              },
              {
                value: "widowed",
                label: "Widowed",
              },
            ]}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">
            Employment Status:
          </h2>
          <Select
            placeholder="Employment Status"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${optDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            defaultValue={{
              value: `${
                data.employmentStatus === null
                  ? "Employment Status"
                  : `${data.employmentStatus}`
              }`,
              label: `${
                data.employmentStatus === null
                  ? "Employment Status"
                  : `${data.employmentStatus}`
              }`,
            }}
            onChange={handleChange(setEmploymentStatus, "tg")}
            options={[
              {
                value: "employed",
                label: "Employed",
              },
              {
                value: "retired",
                label: "Retired",
              },
              {
                value: "unemployed",
                label: "Un-Employed",
              },
            ]}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Nationality:</h2>
          <Select
            placeholder="Nationality"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${allDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            onChange={handleChange(setNationality, "tg")}
            defaultValue={{
              value: `${
                data.nationality === null
                  ? "Nationality"
                  : `${data.nationality}`
              }`,
              label: `${
                data.nationality === null
                  ? "Nationality"
                  : `${data.nationality}`
              }`,
            }}
            options={options1}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Religion:</h2>
          <Select
            placeholder="Religion"
            optionFilterProp="children"
            className="wow capitalize"
            style={{
              marginBottom: "1.2rem",
              pointerEvents: `${optDis ? "none" : "auto"}`,
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={{
              value: `${
                data.religion === null ? "Religion" : `${data.religion}`
              }`,
              label: `${
                data.religion === null ? "Religion" : `${data.religion}`
              }`,
            }}
            size="middle"
            onChange={handleChange(setReligion, "tg")}
            options={[
              {
                value: "Affrican Traditional Religions",
                label: "Affrican Traditional Religions",
              },
              {
                value: "Animism",
                label: "Animism",
              },
              {
                value: "Baha",
                label: "Baha",
              },
              {
                value: "Buddhism",
                label: "Buddhism",
              },
              {
                value: "Cao Dai",
                label: "Cao Dai",
              },
              {
                value: "Chinese traditional religion",
                label: "Chinese traditional religion",
              },
              {
                value: "Christianity",
                label: "Christianity",
              },
              {
                value: "Islam",
                label: "Islam",
              },
              {
                value: "Jainism",
                label: "Jainism",
              },
              {
                value: "Judaism",
                label: "Judaism",
              },
              {
                value: "Neo-Paganism",
                label: "Neo-Paganism",
              },
              {
                value: "Rastafari",
                label: "Rastafari",
              },
              {
                value: "Secular/Nonreligious/Agnostic/Atheist",
                label: "Secular/Nonreligious/Agnostic/Atheist",
              },
              {
                value: "Shinto",
                label: "Shinto",
              },
              {
                value: "Sikhism",
                label: "Sikhism",
              },
              {
                value: "Spiritism",
                label: "Spiritism",
              },
              {
                value: "Tenrikyo",
                label: "Tenrikyo",
              },
              {
                value: "Unitarian Universalism",
                label: "Unitarian Universalism",
              },
              {
                value: "Zoroastrianism",
                label: "Zoroastrianism",
              },
              {
                value: "Others",
                label: "Others",
              },
            ]}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Home Phone:</h2>
          <input
            type="text"
            name=""
            
            onChange={handleChange(setHomePhone)}
            value={homePhone}
            defaultValue={data.homePhone}
            placeholder="Home Phone Number"
            className={`${
              optDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">Work Phone:</h2>
          <input
            type="text"
            name=""
            
            onChange={handleChange(setWorkPhone)}
            value={workPhone}
            defaultValue={data.workPhone}
            placeholder="Work Phone Number"
            className={` ${
              optDis ? "pointer-events-none" : "pointer-events-auto"
            } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
          />
        </div>
      )}
      {}
      <div
        className={`${
          dataUser.data.user.roles[0] === "employee" &&
          dataUser.data.user.status === "pending"
            ? "hidden"
            : ""
        }`}
      >
        {user === "employee" &&
        !privilege?.includes("Edit pensioner") ? null : (
          <button
            onClick={updateHandler}
            className={`${
              ((status === "pending" ||
                status === "deferred" ||
                status === "in-review" ||
                status === "verified" ||
                status === "in-review" ||
                status === "re-submitted") &&
                dash === "/Dashboard/PensionerAccount") ||
              isApplicableUser
                ? "hidden"
                : ""
            } relative lg:text-[1.1rem] mt-8 flex items-center justify-center bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            {/* <Spin
            style={{ display: `${isLoading ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
            <span>Save</span>
            <div
              className={`w-[100%] h-[100%] ${
                isLoading ? "" : "hidden"
              } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
            ></div>
          </button>
        )}
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails1;
