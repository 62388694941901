import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Coupon = ({ data, year }) => {
  const [isExpired, setIsExpired] = useState(false);
  const [isValid, setIsValid] = useState(false); // Corrected state name
  const { month, status, createdAt } = data;
  const selectedProgramPensionBook = useSelector(
    (state) => state.selectedProgramPensionBook
  );
  const paymentDate = useSelector((state) => state.startPaymentDate);
  const startYear = new Date(paymentDate).getFullYear();
  const startMonth = new Date(paymentDate).getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const checkYear = new Date(year).getFullYear();

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 since getMonth() returns zero-based month index

    // Set validity based on the coupon's year and month
    if (
      parseInt(checkYear) > startYear ||
      (parseInt(checkYear) === startYear && data.month >= startMonth)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    // Set expiration based on the current month and year
    setIsExpired(month > currentMonth && currentYear === checkYear);
  }, [month, data, startYear, startMonth, checkYear, currentYear, status]); // Ensure all relevant dependencies

  return (
    <div className={`container ${isExpired ? "" : ""}`}>
      <div
        className={`${
          isExpired || status === "Redeemed" || !isValid
            ? "bg-gray-300 pointer-event-none"
            : "bg-primary cursor-pointer"
        } text-white text-center py-4 px-4 rounded-md shadow-md relative`}
      >
        <h3 className="text-lg font-semibold mb-2">
          Ministry of Human Services and Social Security
        </h3>
        <hr className="h-px my-2 bg-white border-0 opacity-50" />
        <div className="flex justify-between flex-wrap space-x-1 mb-2">
          <div className="flex flex-col gap-2">
            <span
              className={`border-dashed border text-white px-2 py-1 rounded-l text-sm ${
                isExpired ? "text-gray-600" : ""
              }`}
            >
              {selectedProgramPensionBook?.bookletNo}
            </span>
            <span
              className={`border border-white bg-white text-purple-600 px-2 py-1 rounded-r cursor-pointer text-sm ${
                isExpired ? "text-gray-600" : !isValid ? "text-red-600" : ""
              }`}
            >
              {isExpired
                ? "UpComing"
                : !isValid
                ? "Not valid for this pensioner"
                : status}
            </span>
          </div>
          <div className="ml-auto">
            <span className={`text-xl font-semibold `}>
              {getMonthName(month)}
            </span>
          </div>
        </div>

        <div className="w-4 h-4 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 left-0 -ml-2"></div>
        <div className="w-4 h-4 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 right-0 -mr-2"></div>
      </div>
    </div>
  );
};

// Helper function to get the month name
const getMonthName = (month) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
};

export default Coupon;

// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// const Coupon = ({ data, year }) => {
//   console.log(data);
//   const [isExpired, setIsExpired] = useState(false);
//   const [isVaid, setIsValid] = useState(false);
//   const { month, status, createdAt } = data;
//   const selectedProgramPensionBook = useSelector(
//     (state) => state.selectedProgramPensionBook
//   );
//   const paymentDate = useSelector((state) => state.startPaymentDate);
//   const startYear = new Date(paymentDate).getFullYear();
//   const startMonth = new Date(paymentDate).getMonth() + 1;
//   const currentYear = new Date().getFullYear();
//   const checkYear = new Date(year).getFullYear();

//   useEffect(() => {
//     // Get the current date and month
//     const currentDate = new Date();
//     const currentMonth = currentDate.getMonth() + 1; // Adding 1 since getMonth() returns zero-based month index

//     if (
//       parseInt(checkYear) > startYear ||
//       (parseInt(checkYear) === startYear && data.month >= startMonth)
//     ) {
//       setIsValid(true);
//     }

//     // Check if the coupon month is greater than the current month

//     setIsExpired(month > currentMonth && currentYear === checkYear);
//   }, [month]);

//   return (
//     <div className={`container ${isExpired ? "" : ""}`}>
//       <div
//         className={`${
//           isExpired || status === "Redeemed" || !isVaid
//             ? "bg-gray-300 pointer-event-none"
//             : "bg-primary cursor-pointer"
//         } text-white text-center py-4 px-4 rounded-md shadow-md relative`}
//       >
//         <h3 className="text-lg font-semibold mb-2">
//           Ministry of Human Services and Social Security
//         </h3>
//         <hr className="h-px my-2 bg-white border-0 opacity-50" />
//         <div className="flex justify-between flex-wrap space-x-1 mb-2">
//           <div className="flex flex-col gap-2">
//             <span
//               className={`border-dashed border text-white px-2 py-1 rounded-l text-sm ${
//                 isExpired ? "text-gray-600" : ""
//               }`}
//             >
//               {selectedProgramPensionBook?.bookletNo}
//             </span>
//             <span
//               className={`border border-white bg-white text-purple-600 px-2 py-1 rounded-r cursor-pointer text-sm ${
//                 isExpired ? "text-gray-600" : !isVaid ? "text-red-600" : ""
//               }`}
//             >
//               {isExpired
//                 ? "UpComing"
//                 : !isVaid
//                 ? "Not valid for this pensioner"
//                 : status}
//             </span>
//           </div>
//           <div className="ml-auto">
//             <span className={`text-xl font-semibold `}>
//               {getMonthName(month)}
//             </span>
//           </div>
//         </div>

//         <div className="w-4 h-4 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 left-0 -ml-2"></div>
//         <div className="w-4 h-4 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 right-0 -mr-2"></div>
//       </div>
//     </div>
//   );
// };

// // Helper function to get the month name
// const getMonthName = (month) => {
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
// };

// export default Coupon;
