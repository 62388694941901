import React, { useState } from "react";
import Sidebar from "./sideBar";
import Card from "./Card";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MenuUnfoldOutlined } from "@ant-design/icons";
const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "1" });
  }, []);

  return (
    <div
      className={`${
        windowSize?.width <= 768
          ? "h-screen flex flex-col"
          : "flex flex-row p-7 gap-5 h-screen bg-[#D9EFED] w-full"
      }`}
    >
      {windowSize?.width <= 768 ? (
        <>
          <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
            <MenuUnfoldOutlined
              className="text-2xl cursor-pointer"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
            />
            <h1 className="text-xl font-bold">Dashboard</h1>
          </header>
          {openSideBar && (
            <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
              <Sidebar />
            </div>
          )}
        </>
      ) : (
        <aside
          className="w-[20%] rounded-lg"
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <Sidebar />
        </aside>
      )}
      <div
        className={`${
          windowSize?.width <= 768
            ? "flex-grow overflow-y-auto p-4"
            : "flex flex-col w-[78%] gap-3 p-3 "
        }`}
      >
        <header className="h-24 shrink-0 rounded-lg">
          <Header type={"Payment"} header={false} route={"/MHSSS/payment"} />
        </header>
        <main
          className={`${
            windowSize?.width <= 768
              ? ""
              : "flex-grow rounded-lg p-3 overflow-y-scroll "
          }`}
          style={
            windowSize?.width <= 768
              ? {}
              : {
                  backgroundColor: "white",
                }
          }
        >
          <Card />
        </main>
      </div>
    </div>
  );
};

export default Index;

// const App = () => {
//   const dispatch = useDispatch();
//   const {
//     token: { borderRadiusLG },
//   } = theme.useToken();

//   const dataUser = useSelector((state) => state.dataUser);
//   const profile = dataUser?.data?.user?.person?.image;
//   const Name =
//     dataUser?.data?.user?.person?.firstName +
//     " " +
//     dataUser?.data?.user?.person?.lastName;

//   useEffect(() => {
//     dispatch({ type: "SIDEBAR", num: "1" });
//   }, []);
//   return (
//     <Layout
//       style={{
//         padding: 35,
//         margin: 0,
//         background: "#D9EFED",
//       }}
//     >
//       <Sider
//         breakpoint="lg"
//         width={280}
//         collapsedWidth="0"
//         onBreakpoint={(broken) => {
//           console.log(broken);
//         }}
//         onCollapse={(collapsed, type) => {
//           console.log(collapsed, type);
//         }}
//         className="flex flex-col justify-between h-full"
//         style={{
//           borderRadius: borderRadiusLG,
//           width: "20px",
//           height: "89vh",
//           boxShadow:
//             "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
//         }}
//       >
//         <Sidebar />
//       </Sider>

//       <Layout
//         className="flex flex-col items-center justify-center"
//         style={{
//           background: "#D9EFED",
//         }}
//       >
//         <div className="flex items-center h-[4rem] w-[95%] justify-between text-lg">
//           <h2 className="mb-4 font-bold">Welcome {Name}</h2>
//           {/* <img
//               src={logo}
//               className="h-[8rem] w-[8rem]"
//               alt="Flowbite Logo"
//             /> */}
//           <div className="flex items-center">
//             <span className="mr-2 font-semibold">{Name}</span>
//             <img
//               src={profile}
//               alt="Profile"
//               className="w-10 h-10 rounded-full"
//             />
//           </div>
//         </div>
//         <div className="w-[95%]">
//           <div className="relative w-[35%]">
//             <input
//               type="text"
//               placeholder="Search..."
//               className="pl-4 pr-4 py-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
//             />
//             <div className="absolute inset-y-0 right-0 flex cursor-pointer mr-3 items-center justify-center">
//               <img src={searchImg} className="w-5 h-5" alt="Search icon" />
//             </div>
//           </div>
//         </div>
//         <Content
//           className="w-[95%] mt-6"
//           style={{
//             // margin: "24px 16px 10px",
//             boxShadow:
//               "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
//             padding: 26,
//             background: "#F5F5F5",
//             borderRadius: borderRadiusLG,
//             minHeight: "fit-content",
//           }}
//         >
//           <Card />
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };
// export default App;
