import { DatePicker, Pagination } from "antd";
import moment from "moment";
import React from "react";

const index = ({ year, setYear, data, page, setPage, totalPages }) => {
  const pageHandler = (e) => {
    setPage(e);
  };

  const Div = ({ title, content }) => {
    return (
      <div className="flex flex-row justify-between">
        <div className="text-md font-medium leading-6 text-gray-600">
          {title}
        </div>
        <div
          className={`text-md leading-6 text-gray-700 capitalize ${
            title === "Status"
              ? content === "success"
                ? "text-primary font-semibold"
                : "text-red-600 font-semibold"
              : ""
          }`}
        >
          {content}
        </div>
      </div>
    );
  };

  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };

  return (
    <div>
      <div className="py-4">
        <h1 className="text-[1rem] font-semibold mb-4  ">Filter By year</h1>
        <DatePicker
          picker="year"
          // value={year}
          onChange={(date, dateString) => {
            if (setYear) {
              setYear(dateString);
            }
          }}
        />
      </div>
      {data?.length === 0 && (
        <h1 className="text-center text-lg text-gray-500 p-10">
          No Verification History in {year}{" "}
        </h1>
      )}
      {/* {data.map((history, index) => (
  <div
    key={index}
    className="grid grid-cols-3 gap-4 border-b py-4 items-center"
  >
    <div className="flex items-center">
      {history?.status === "success" ? (
        <div className="bg-green-100 h-10 w-10 flex items-center justify-center rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M5 13l4 4L19 7" />
          </svg>
        </div>
      ) : history?.status === "pending" ? (
        <div className="bg-yellow-100 h-10 w-10 flex items-center justify-center rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-yellow-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      ) : (
        <div className="bg-red-100 h-10 w-10 flex items-center justify-center rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-red-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      )}

      <div className="ml-4">
        <p
          className={`${
            history?.status === "pending"
              ? "text-yellow-500 text-lg font-semibold"
              : history?.status === "success"
              ? "text-green-500 text-lg font-semibold"
              : "text-red-500 text-lg font-semibold"
          }`}
        >
          {history?.status === "pending"
            ? "Verification Pending"
            : history?.status === "success"
            ? "Profile Verified"
            : "Verified Failed"}
          
        </p>

        <p className="text-md font-semibold text-gray-800">
          {moment(history?.createdAt).format("Do MMMM, YYYY")}
        </p>
      </div>
    </div>
    <div className="flex items-center ml-24">
      <div className="">
        <p className="text-lg font-semibold text-gray-800 capitalize">
          Via {history?.verificationType}
        </p>
        <p className="text-sm font-medium text-gray-600">Source</p>
      </div>
    </div>
    <div className="flex items-center ml-24">
      <div className="ml-4">
        <p className="text-md font-semibold text-gray-800">
          {moment(history?.createdAt).format("hh:mm A")}
        </p>
        <p className="text-sm font-medium text-gray-600">Time</p>
      </div>
    </div>
  </div>
))} */}

      <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">
        {data?.map((data, index) => (
          <div
            key={index}
            className="relative flex flex-col border border-gray-100  rounded-md sm:px-10 px-3 py-4  mb-4 shadow-sm gap-1"
          >
            <Div title="Verified By" content={data?.verificationType} />
            <Div
              title="Voucher Month"
              content={getMonthName(data?.coupon?.month)}
            />
            <Div
              title="Booklet Number"
              content={data?.coupon?.pensionbook?.bookletNo}
            />
            <Div title="Status" content={data?.status} />
            <div className="self-end text-sm text-gray-500 mt-4">
              {moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
            </div>
          </div>
        ))}
      </div>

      <div className={`flex justify-end mt-7`}>
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          showSizeChanger={false}
          onChange={pageHandler}
          current={page}
        />
      </div>
    </div>
  );
};

export default index;
