import React, { useEffect, useState } from "react";
import vp1 from "../../imgs/vp11.svg";
import vp2 from "../../imgs/vp22.svg";
import prooff from "../../imgs/proof.png";
import thumb from "../../imgs/Thumb.png";
import tick from "../../imgs/tik.svg";
import vp3 from "../../imgs/vp33.svg";
import { Select, Switch } from "antd";
import vp4 from "../../imgs/pensionBookNew.svg";
import vp5 from "../../imgs/programCard.svg";
import { DeleteFilled } from "@ant-design/icons";
import PageHeader from "./pageHeader/pageHeader";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProfileDiv from "./profileDiv/profileDiv";
import ErrorModal from "../Merchant/Modals/errorModal";
let file;
let optopt1;
const SelectLocation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const veriSelectedPensioner = useSelector(
    (state) => state.veriSelectedPensioner
  );
  const [afterapi, setAfterapi] = useState(false);
  const [data, setData] = useState("");
  const priorityPensioner = useSelector((state) => state.priorityPensioner);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);
  const [showPr, setShowPr] = useState(false);
  const [verify, setVerify] = useState(false);
  const [display, setDisplay] = useState("");
  const [note, setNote] = useState("");
  const [reason, setReason] = useState("");
  const [prior, setPrior] = useState(null);
  const [proof, setProof] = useState("");
  const [url, setUrl] = useState("");
  const router = useNavigate();
  const [able, setAble] = useState(
    veriSelectedPensioner?.isDisabled === false ? true : false
  );
  const penVerStatus = useSelector((state) => state.penVerStatus);
  const [show2, setShow2] = useState(false);
  const [other, setOther] = useState(false);
  const [otherChoice, setOtherChoice] = useState(false);
  const [notes, setNotes] = useState("");
  const [pensionerData, setPensionerData] = useState("");
  const [ip, setIp] = useState("");
  const [location, setLocation] = useState("");
  const [show, setShow] = useState(false);
  const [cardIn, setCardIn] = useState();
  const [err, setErr] = useState("");
  const [checkPerson, setCheckPerson] = useState("");
  const [refetch, setRefetch] = useState(false);
  const imageHandler = async (e) => {
    file = e.target.files[0];
    console.log("Fileee", file);
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setUrl(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    console.log("penState", penVerStatus);
    if (penVerStatus === "Verification Pending") setCardIn(0);
    else if (penVerStatus === "Basic Detail Verified") setCardIn(1);
    else if (penVerStatus === "Identification Detail Verified") setCardIn(2);
    else if (penVerStatus === "Biometric Verified") setCardIn(3);
    else if (penVerStatus === "Pension Book Distributed") setCardIn(4);
  }, [penVerStatus]);
  useEffect(() => {
    console.log("penStateId", cardIn);
  }, [cardIn]);
  const cards = [
    {
      icon: vp1,
      title: "Basic info",
      route: "/pensionerVerification/Profile/VerifyBasicInfo",
    },
    {
      icon: vp2,
      title: "Identification",
      route: "/pensionerVerification/Profile/VerifyIdentification",
    },
    {
      icon: vp3,
      title: "Biometrics",
      route: "/pensionerVerification/Biometrics",
    },
    {
      icon: vp4,
      title: "Pension book",
      route: "/pensionerVerification/PensionBook",
    },
    { icon: vp5, title: "Program Card", route: "" },
  ];
  const Card = ({ index, icon, title, route }) => {
    console.log("Index", index, cardIn);
    return (
      //   <div
      //     className={`relative flex flex-col ${bgColor} items-center p-6 rounded-lg shadow-md w-[20%] ${
      //       clickable ? "cursor-pointer" : "cursor-not-allowed"
      //     }`}
      //     onClick={() => {
      //       router(route);
      //     }}
      //   >
      <div
        className={`${
          index <= cardIn
            ? " cursor-pointer bg-white shadow-lg"
            : "pointer-events-none bg-gray-50 shadow-sm"
        } relative flex flex-col hover:bg-gray-50 items-center p-6 rounded-lg  w-full`}
        onClick={() => {
          router(route);
        }}
      >
        {cardIn > index && (
          // <div className="absolute top-2 right-2 border rounded-lg text-green-500">✓</div>
          <img
            src={tick}
            alt={title}
            className="absolute top-1 right-0 w-8 h-8"
          />
        )}
        <div
          className={`w-20 h-20 mb-3 rounded-full flex items-center justify-center p-3`}
        >
          <img src={icon} alt={title} className="w-20 h-20" />
        </div>
        <h3 className="font-semibold mb-2 text-center">{title}</h3>
      </div>
    );
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${veriSelectedPensioner?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        if (data?.data?.person) {
          setCheckPerson("is null");
        }
        setAble(data?.data?.user?.isDisabled === false ? true : false);
        setPensionerData(data?.data);
        if (data.data.user.isDisabled) {
          optopt1 = [
            {
              label: "Remigrated",
              value: "Remigrated",
            },
            {
              label: "Other",
              value: "Other",
            },
          ];
          setAble(false);
        } else {
          optopt1 = [
            {
              label: "Out of Country",
              value: "Out of Country",
            },
            {
              label: "Hospitalized",
              value: "Hospitalized",
            },
            {
              label: "Migrated",
              value: "Migrated",
            },
            {
              label: "Education",
              value: "Education",
            },
            {
              label: "Other",
              value: "Other",
            },
          ];
          setAble(true);
        }
      })
      .catch(() => {});
  }, [refetch]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/priority-request/get-pensioners-priority/${veriSelectedPensioner?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setAfterapi(true);
        setData(data?.data);
        setReason(data?.data?.reason);
        setProof(data?.data?.status);
      })
      .catch((err) => {
        setAfterapi(true);
        if (err?.response?.data?.message === "priority request not found") {
          setProof("noReq");
        }
      });
  }, [refetch]);
  useEffect(() => {
    console.log("Status", proof);
  }, [proof]);
  const ableSubmitHandler = () => {
    // if (checkPerson === "is null") {
    //   setErr("Personal data is missing or does not exist in profile");
    //   setShow(true);
    //   return
    // }
    if (notes === "") {
      setShow(true);
      setErr("Please enter notes");
    } else if (other && otherChoice === "") {
      setShow(true);
      setErr("Please enter a reason");
    } else {
      let wow;
      let wow1;
      if (!able) {
        wow = "This pensioner was mistakenly disabled";
        wow1 = "enable";
      } else {
        wow = "This pensioner has no sufficient documents";
        wow1 = "disable";
      }
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${veriSelectedPensioner?.id}/${wow1}`,
          {
            reason: wow,
            note: notes,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          setRefetch(!refetch);
          if (!able) {
            setAble(true);
          } else {
            setAble(false);
          }
          setOther(false);
          setOtherChoice("");
          setNotes("");
          setShow2(false);
        })
        .catch((err) => {
          console.log(err);
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    }
  };
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
        console.log("IP", response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation(`${latitude}, ${longitude}`);
            console.log("Loc", `${latitude}, ${longitude}`);
          },
          (error) => {
            console.error("Error fetching location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchIp();
    fetchLocation();
  }, []);
  const RequestHandler = () => {
    if (note === "") {
      setShow(true);
      setErr("Please enter notes");
    } else if (prior === "") {
      setShow(true);
      setErr("Please enter Category...");
    } else if (url === "") {
      setShow(true);
      setErr("Please Upload proof...");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/priority-request/${veriSelectedPensioner?.id}`,
          {
            priorityRequest: {
              priorityCategory: prior,
              notes: note,
              proofUrl: url,
            },
            consumerInfo: {
              ip: ip,
              location: location,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          setRefetch(!refetch);
          setVerify(true);
          setShowPr(false);
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    }
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader
        header={true}
        title={"Pensioner Details"}
        route={"/pensionerVerification/listing"}
      />
      <div className="relative">
        <div className="flex flex-row items-center flex-end gap-2 absolute right-10">
          <p className="font-semibold text-gray-700">Account Enable</p>
          <Switch
            size="small"
            style={{ backgroundColor: able ? "#1BA397" : "#c3c6c9" }}
            checked={able}
            onChange={() => {
              setShow2(true);
            }}
          />
        </div>
        <div className="flex justify-center bg-white sm:ml-[2rem] sm:mr-[2rem] ml-2 mr-2 mt-7">
          <div
            className={`rounded-sm md:mx-20 mx-2 sm:m-5 m-1 sm:p-6 p-1 sm:w-[90%] w-full`}
          >
            <ProfileDiv
              data={pensionerData}
              refetch={refetch}
              setRefetch={setRefetch}
              check={proof}
            />
            <div className="w-full mt-4 bg-[#F5F5F5] sm:pl-6 sm:pr-6 p-2">
              <div className="grid md:grid-cols-5 sm:grid-cols-3 grid-cols-1 sm:gap-6 gap-2 w-full sm:flex-row flex-col">
                {cards.map((card, index) => (
                  <Card
                    key={index}
                    index={index}
                    icon={card.icon}
                    title={card.title}
                    route={card.route}
                    //   onClick={() => handleCardClick(index)}
                  />
                ))}
              </div>
            </div>

            <div
              className={`${
                penVerStatus === "Verification Rejected"
                  ? "w-full mt-4 bg-[#F5F5F5] pl-6 pr-6 p-4 flex  gap-5"
                  : "hidden"
              } `}
            >
              <h1 className="text-[0.9rem] font-semibold w-2/12">
                Rejection Note
              </h1>
              <p className="text-red-600 w-10/12">
                {veriSelectedPensioner?.data?.verificationStatus?.note !== null
                  ? veriSelectedPensioner?.data?.verificationStatus?.note
                  : "--------------"}
              </p>
            </div>

            {user === "employee" &&
            !privilege?.includes("View priority") ? null : (
              <div>
                {!priorityPensioner && (
                  <div
                    className={`${
                      afterapi === true
                        ? "w-full mt-4 bg-[#F5F5F5] pl-6 pr-6 p-4"
                        : "hidden"
                    }`}
                  >
                    <div className="flex items-center justify-center gap-4 w-full flex-col">
                      {proof === "noReq" &&
                      penVerStatus !== "Pension Book Distributed" ? (
                        user === "employee" &&
                        !privilege?.includes("Add priority") ? null : (
                          <div className="w-full flex items-center justify-center flex-col">
                            <button
                              onClick={() => {
                                setShowPr(true);
                              }}
                              className="bg-white border border-primary rounded-md font-semibold text-primary text-center pl-12 pr-12 p-1 "
                            >
                              Generate Request for Priority Status
                            </button>

                            <p className="w-[70%] text-semibold text-center text-[#919191]">
                              You can assign priority status to a pensioner if
                              they fulfill any listed conditions. Be sure to
                              verify all supporting documentation prior to
                              marking them as priority.
                            </p>
                          </div>
                        )
                      ) : proof === "pending" &&
                        penVerStatus !== "Pension Book Distributed" ? (
                        <div className="w-full flex items-center justify-center flex-col">
                          <h2 className="font-semibold text-primary text-center pl-12 pr-12 p-1 ">
                            Application Submitted for Priority Status
                          </h2>

                          <p className="w-[70%] text-semibold text-center text-[#919191]">
                            The application has been successfully submitted for
                            final approval. The pensioner will be added to the
                            priority list once approved.
                          </p>
                          <button
                            onClick={() => {
                              dispatch({ type: "VERI_PRIORITY", num: data });
                              router(
                                "/pensionerVerification/PriorityRequests/Detail",
                                {
                                  state: {
                                    fromProfile: true,
                                    pensionerData: pensionerData,
                                  },
                                }
                              );
                            }}
                            className="mt-5 bg-white border border-primary rounded-md font-semibold text-primary text-center pl-12 pr-12 p-1 "
                          >
                            View Priority Request
                          </button>
                        </div>
                      ) : proof === "rejected" &&
                        penVerStatus !== "Pension Book Distributed" ? (
                        <div className="w-full flex items-center justify-center flex-col">
                          <h2 className="font-semibold text-red-600 text-center pl-12 pr-12 p-1 ">
                            Priority Status Request Rejected
                          </h2>
                          <div className="flex gap-2 items-center justify-center w-[70%]">
                            <h2 className="font-semibold">Reason :</h2>
                            <p className="text-semibold text-center">
                              {reason}
                            </p>
                          </div>
                        </div>
                      ) : penVerStatus === "Pension Book Distributed" ? (
                        <div className="w-full flex items-center justify-center flex-col">
                          <h2 className="font-semibold text-primary text-center pl-12 pr-12 p-1 ">
                            Verification Completed.
                          </h2>
                        </div>
                      ) : (
                        <div className="w-full flex items-center justify-center flex-col">
                          <h2 className="font-semibold text-primary text-center pl-12 pr-12 p-1 ">
                            Application Approved for Priority Status
                          </h2>
                          <p className="w-[70%] text-semibold text-center text-[#919191]">
                            The application has been Approved. The pensioner is
                            added to the priority list.
                          </p>
                          <button
                            onClick={() => {
                              dispatch({ type: "VERI_PRIORITY", num: data });
                              router(
                                "/pensionerVerification/PriorityRequests/Detail",
                                {
                                  state: {
                                    fromProfile: true,
                                    pensionerData: pensionerData,
                                  },
                                }
                              );
                            }}
                            className="mt-5 bg-white border border-primary rounded-md font-semibold text-primary text-center pl-12 pr-12 p-1 "
                          >
                            View Priority Request
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`${
          showPr ? "" : "hidden"
        } w-[100%] z-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[30rem] flex items-center bg-white flex-col">
          <div className="w-full h-[3rem] bg-primary rounded-xl flex items-center justify-center text-white text-bold rounded-bl-none rounded-br-none">
            Generate Request for Priority Status
          </div>
          <div className="p-5 w-full flex flex-col items-center justify-center gap-2">
            <div className="flex flex-col w-[85%]">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Priority category
              </label>
              <Select
                placeholder="Select category"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.1rem" }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                onChange={(e) => setPrior(e)}
                options={[
                  {
                    value: "handicapped/disabled",
                    label: "Handicapped/Disabled",
                  },
                  {
                    value: "travel-time",
                    label: "Travel Time",
                  },
                  {
                    value: "health-issues",
                    label: "Health Issues",
                  },
                  {
                    value: "shut-in",
                    label: "Shut In",
                  },
                ]}
              />
            </div>
            <div
              className={`flex items-center justify-center rounded-lg text-center sm:w-[23rem] h-[13rem] relative`}
            >
              {display ? (
                <>
                  <img src={display} alt="Uploaded" className="w-full h-full" />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setUrl("");
                      setDisplay("");
                    }}
                  >
                    <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                  </button>
                </>
              ) : (
                <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                  <div className="flex items-center w-full justify-center flex-col">
                    <img src={prooff} alt="" className="w-20 h-24" />
                    <label
                      htmlFor="file-upload"
                      className="text-5xl text-primary w-full cursor-pointer flex flex-col gap-2 items-center justify-center"
                    >
                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => imageHandler(e)}
                      />
                      <div className="mt-4 w-[80%] text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                        Upload Proof
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col w-[85%]">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Note
              </label>
              <textarea
                type="text"
                name=""
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder="Type Note Here..."
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
            </div>
            <div className={"flex items-center w-full justify-center gap-3"}>
              <button
                onClick={() => {
                  setShowPr(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[10rem] "
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // setProof(true);
                  RequestHandler();
                  // setVerify(true);
                  // setShowPr(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[10rem] "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          verify ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2 text-primary">
            Successfully submitted
          </h1>
          <p className="text-center mt-[1rem] text-[#757575] mb-5">
            The application has been successfully submitted for final approval.
            The pensioner will be added to the priority list once approved.
          </p>
          <button
            onClick={() => {
              setProof(true);
              setVerify(false);
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
          >
            Okay
          </button>
        </div>
      </div>

      {/* enable disabled Pensioner */}
      <div
        className={`${
          show2 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl sm:w-[30rem] w-[20rem] flex items-center p-5 bg-white flex-col px-10">
          <p className="text-center text-[1.1rem] mt-[1rem] mb-5">
            Are you sure you want to {!able ? "enable " : "disable"} this
            pensioner?
          </p>
          <Select
            placeholder="Reason"
            onChange={(e) => {
              if (e === "Other") {
                setOther(true);
              } else {
                setOther(false);
              }
            }}
            optionFilterProp="children"
            style={{ marginBottom: "0.5rem" }}
            className="wow1"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={optopt1}
          />
          <input
            type="text"
            placeholder="Enter Reason"
            value={otherChoice}
            onChange={(e) => {
              setOtherChoice(e.target.value);
            }}
            className={`lg:text-[1.1rem] ${
              other ? "" : "hidden"
            } mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-2 mb-4`}
          />
          <textarea
            type="text"
            placeholder="Enter Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className="sqw lg:text-[1.1rem] h-[6rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-2 mt-2"
          />
          <div className="flex mt-4">
            <button
              onClick={() => {
                setShow2(false);
                setOther(false);
                setOtherChoice("");
                setNotes("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-black bg-[#F2F4F8] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                ableSubmitHandler();
              }}
              className="cursor-pointer rounded-xl ml-4 pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              {!able ? "Enable" : "Disable"}
            </button>
          </div>
        </div>
      </div>

      {show && <ErrorModal setShow={setShow} err={err} />}
    </div>
  );
};

export default SelectLocation;
