import React from 'react'
import EWalletCash from "../components/MHSSS Accounting/outstanding/PayOutstanding/eWalletCash/eWalletIndex"

const OutStandingListing = () => {
  return (
    <div>
     <EWalletCash/>
    </div>
  )
}

export default OutStandingListing