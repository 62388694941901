import React from 'react'
import PayDetails from "../components/MHSSS Accounting/payment/paymentDetail"

const OutStandingPayment = () => {
  return (
    <div>
     <PayDetails/>
    </div>
  )
}

export default OutStandingPayment