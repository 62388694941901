import React, { useState, useEffect } from "react";
import { Avatar, Collapse } from "antd";
// import Documents from "./Documents";
import Profile from "./Profile";
import axios from "axios";
import { useSelector } from "react-redux";
// import Email from "../headquater/email";
// import Number from "../headquater/number";
const { Panel } = Collapse;
const generatePassword = () => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=";
  const length = 10;
  let password = "";
  const hasRequiredCharacters = (str) => {
    const hasUpperCase = /[A-Z]/.test(str);
    const hasLowerCase = /[a-z]/.test(str);
    const hasNumber = /[0-9]/.test(str);
    const hasSpecialChar = /[!@#$%^&*()_+\-=]/.test(str);
    return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };
  while (!hasRequiredCharacters(password)) {
    password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
  }

  return password;
};
const ContactPerson = ({ title, data }) => {
  const [pass, setPass] = useState("");
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const callback = (key) => {
    console.log(key);
  };
  useEffect(() => {
    setPass(generatePassword());
  }, []);

  const handleShare = (id) => {
    // Previous Api
    // v1/merch-auth/merch-employee/credentials?merchUserId
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/merch-user/credentials?merchUserId=${id}`,
        {
          password: pass,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {})
      .catch(() => {
        // setErr(err.response.data.message);
        // setShow(true);
      });
  };
  return (
    <div className="">
      <div className="p-6 bg-[#F4F5FE] rounded-lg md:p-8  mb-8">
        <div className="flex items-center">
          {/* <img
              className="w-24 h-24 rounded-full mr-4"
              src={data?.image}
              alt="Profile"
            /> */}
          <Avatar
            src={data?.merchperson?.image}
            className="sm:w-24 sm:h-24 w-16 h-16  mr-4 border-2 border-primary"
          />

          <div className="text-sm flex items-center justify-between w-[80%]">
            <h4 className="sm:text-xl text-md font-semibold text-primary capitalize leading-none mb-2">
              {title}
            </h4>
            {/* <p className="text-md text-gray-600 font-medium">
              {data?.position}
            </p> */}
            <button
              onClick={() => handleShare(data?.id)}
              className={`ml-5 lg:text-[1.1rem] bg-primary ${
                user === "merchant-owner" || user === "merchant-admin" ? "" : "hidden"
              } text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Share Credentials
            </button>
          </div>
        </div>
        <div className="md:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            <Panel
              header="Profile"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Profile data={data?.merchperson} />
            </Panel>

            <Panel
              header="Email"
              key="2"
              className="p-2 text-md text-gray-800 font-medium"
            >
              {/* <Email headquarter={data} type={"Contact Person"} /> */}
              <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8 flex gap-6 break-words sm:flex-row flex-col ">
                <h1 className="font-semibold">Email: </h1>
                <h1 className="font-semibold break-words w-auto">{data?.email}</h1>
              </div>
            </Panel>
            <Panel
              header="Contact"
              key="3"
              className="p-2 text-md text-gray-800 font-medium"
            >
              {/* <Number headquarter={data} type={"Contact Person"} /> */}
              <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8 flex gap-6 sm:flex-row flex-col">
                <h1 className="font-semibold">Phone: </h1>
                <h1 className="font-semibold break-words w-auto ">
                  {data?.countryCode}
                  {data?.phoneNo}
                </h1>
              </div>
            </Panel>
            {/* <Panel
                            header="Documents"
                            key="3"
                            className="p-2 text-md text-gray-800 font-medium"
                        >
                            <Documents documentation={data?.merchcontactpersondocs} />
                        </Panel> */}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
