import { Checkbox, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ErrorModal from "../../Merchant/Modals/errorModal";
import { useSelector } from "react-redux";
import { purposeOptions } from "../constants/purpose";
import logo from "../../../imgs/sapNew.svg";
import { useNavigate } from "react-router-dom";
import AmountModal from "../modals/amountSend";

const Payment = () => {
  const [isChecked, setIsChecked] = useState(false);
  const router = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const dataUser = useSelector((state) => state.dataUser);
  const [purpose, setPurpose] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [favorite, setFavorite] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [ewalletInfo, setEwalletInfo] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const sapPaymentSelectedPerson = useSelector(
    (state) => state.sapPaymentSelectedPerson
  );
  console.log("isChecked", sapPaymentSelectedPerson);

  const [showAmountModal, setShowAmountModal] = useState(false);
  const [value, setValue] = useState("");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user !== "pensioner") {
      return;
    }
    const body = {
      sapwalletinfoId: sapPaymentSelectedPerson?.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/favorite-person/pensioner/check`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        console.log("dataaaa", data);
        setFavorite(true);
      })
      .catch((err) => {});
  }, []);

  const payment = () => {
    setShowConfirmModal(false);
    if (amount === "") {
      setErr("Please enter amount");
      setShowErrorModal(true);
      return;
    }

    if (purpose === "") {
      setErr("Please select purpose");
      setShowErrorModal(true);
      return;
    }

    if (reason === "") {
      setErr("Please enter reason");
      setShowErrorModal(true);
      return;
    }

    const body = {
      toSapWalletInfoId: sapPaymentSelectedPerson?.id,
      amount: amount,
      purpose: purpose,
      note: reason,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/pensioner-pay-sap-member`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((response) => {
        // Check if the response status is 200
        if (response.status === 200) {
          let recipientName = "----";

          if (sapPaymentSelectedPerson?.type === "pensioner") {
            const { firstName, middleName, lastName } =
              sapPaymentSelectedPerson?.user?.person || {};
            recipientName = `${capitalize(firstName) || "----"}${
              middleName ? " " + capitalize(middleName) : ""
            }${lastName ? " " + capitalize(lastName) : ""}`;
          } else if (sapPaymentSelectedPerson?.type === "merch-store") {
            recipientName =
              sapPaymentSelectedPerson?.merchstore?.customStoreName || "----";
          } else if (sapPaymentSelectedPerson?.type === "HQ") {
            recipientName = "HQ";
          }

          setValue(`Amount $${amount} has been Paid to  ${sapPaymentSelectedPerson?.name || "----"}`);

          if (isChecked) {
            makeFav();
            return;
          }
          setShowAmountModal(true);
        } else {
          // Handle unexpected status code
          throw new Error(`Unexpected status code: ${response.status}`);
        }
      })
      .catch((err) => {
        console.log("Error:", err); // Log the error for debugging
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const makeFav = () => {
    const body = {
      sapwalletinfoId: sapPaymentSelectedPerson?.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/favorite-person/pensioner`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setShowAmountModal(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="md:w-[40%] sm:w-[60%] w-full">
      <div className="sm:p-5">
        {/* Pesnioer */}
        <div
          className={`${
            sapPaymentSelectedPerson?.type === "pensioner"
              ? "flex items-center"
              : "flex items-center"
          }`}
        >
          <img
            src={sapPaymentSelectedPerson?.image}
            alt="profile"
            className="w-24 h-24 rounded-full mr-2 ml-2"
          />
          <div
            className={`${
              sapPaymentSelectedPerson?.type === "pensioner"
                ? "flex flex-col"
                : "flex flex-col"
            }`}
          >
            <p className="font-bold text-[1.1rem] capitalize">
              {/* {sapPaymentSelectedPerson?.user?.person?.firstName
                ? capitalize(sapPaymentSelectedPerson?.user?.person?.firstName)
                : "----"}
              {sapPaymentSelectedPerson?.user?.person?.middleName
                ? " " +
                  capitalize(sapPaymentSelectedPerson?.user?.person?.middleName)
                : ""}
              {sapPaymentSelectedPerson?.user?.person?.lastName
                ? " " +
                  capitalize(sapPaymentSelectedPerson?.user?.person?.lastName)
                : ""} */}

              {capitalize(sapPaymentSelectedPerson?.name) || "----"}
            </p>

            <p className="font-semibold text-gray-400 text-[0.78rem]">
              {sapPaymentSelectedPerson?.phone !== "-------"
                ? sapPaymentSelectedPerson?.phone
                : ""}
            </p>
            <p className="font-semibold text-gray-400 text-[0.78rem]">
              {sapPaymentSelectedPerson?.email}
            </p>
          </div>
        </div>
        {/* STORE */}
        {/* <div
          className={`${
            sapPaymentSelectedPerson?.type === "merch-store"
              ? "flex items-center"
              : "hidden"
          }`}
        >
          <img
            src={sapPaymentSelectedPerson?.merchstore?.image}
            alt="profile"
            className="w-24 h-24 rounded-full mr-2 ml-2"
          />
          <div className={`flex flex-col`}>
            <p className="font-bold text-[1.1rem] capitalize">
              {sapPaymentSelectedPerson?.merchstore
                ? capitalize(
                    sapPaymentSelectedPerson?.merchstore?.customStoreName
                  )
                : "----"}
            </p>

            <p className="font-semibold text-gray-400 text-[0.78rem]">
              {sapPaymentSelectedPerson?.merchstore !== null
                ? sapPaymentSelectedPerson?.merchstore?.primaryPhone
                : ""}
            </p>
            <p className="font-semibold text-gray-400 text-[0.78rem]">
              {sapPaymentSelectedPerson?.merchstore !== null
                ? sapPaymentSelectedPerson?.merchstore?.primaryEmail
                : ""}
            </p>
          </div>
        </div> */}
        {/* HEADQUARTER */}
        {/* <div
          className={`${
            sapPaymentSelectedPerson?.type === "merch-headquarter"
              ? "flex items-center"
              : "hidden"
          }`}
        >
          <img
            src={sapPaymentSelectedPerson?.merchheadquarter?.image}
            alt="profile"
            className="w-24 h-24 rounded-full mr-2 ml-2"
          />
          <div className={`flex flex-col`}>
            <p className="font-bold text-[1.1rem] capitalize">
             
             
              HQ
            </p>

            <p className="font-semibold text-gray-400 text-[0.78rem]">
              {sapPaymentSelectedPerson?.merchheadquarter !== null
                ? sapPaymentSelectedPerson?.merchheadquarter?.primaryPhone
                : ""}
            </p>
            <p className="font-semibold text-gray-400 text-[0.78rem]">
              {sapPaymentSelectedPerson?.merchheadquarter !== null
                ? sapPaymentSelectedPerson?.merchheadquarter?.primaryEmail
                : ""}
            </p>
          </div>
        </div> */}
        <div className="py-2 px-4 mt-4">
          <div className="w-full mb-2">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Amount <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name=""
              
              placeholder="Enter Amount"
              className="lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2"
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </div>
          <div className="w-full mb-2">
            <label className="text-[#596F96] mb-1 block text-[0.9rem]  font-semibold">
              Purpose <span className="text-red-500">*</span>
            </label>
            <Select
              // value={statuss}
              onChange={(e) => {
                setPurpose(e);
              }}
              placeholder="Select Purpose"
              showSearch
              optionFilterProp="children"
              className="wow capitalize bg-[#F2F4F8] rounded-[0.3rem] p-2"
              //   className="sqw lg:text-[1.1rem] h-[4rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              options={purposeOptions}
            />
          </div>
          {/* Reason Textarea */}
          <div className="w-full mb-2">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Reason <span className="text-red-500">*</span>
            </label>
            <textarea
              onChange={(e) => setReason(e.target.value)}
              // value={statusReason}
              type="text"
              placeholder="Enter Reason"
              className="sqw lg:text-[1.1rem] h-[4rem] w-full bg-[#F2F4F8] rounded-[0.3rem] p-2"
            />
          </div>
          <div className={`${user === "pensioner" ? "" : "hidden"}`}>
            {!favorite && (
              <Checkbox
                className="checkk"
                checked={isChecked}
                onClick={handleCheckboxChange}
              >
                Make Favourite one
              </Checkbox>
            )}
          </div>
        </div>

        <div
          className="w-full flex items-center"
          onClick={() => {
            // payment();
            setShowConfirmModal(true);
          }}
        >
          <button className="cursor-pointer  rounded-md px-6 py-2 text-white bg-primary w-full mx-6 text-md">
            Pay Amount
          </button>
        </div>
      </div>
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      {showAmountModal && (
        <AmountModal
          setShow={setShowAmountModal}
          route={"/SapMoney"}
          title={"Payment Done"}
          value={value}
        />
      )}

      {/* Confirmation Modal */}
      <div
        className={`${
          showConfirmModal ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[7rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-2 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${amount}</span> to{" "}
            {/* {sapPaymentSelectedPerson?.type === "pensioner" ? (
              <>
                {capitalize(
                  sapPaymentSelectedPerson?.user?.person?.firstName
                ) || "----"}
                {sapPaymentSelectedPerson?.user?.person?.middleName
                  ? " " +
                    capitalize(
                      sapPaymentSelectedPerson?.user?.person?.middleName
                    )
                  : ""}
                {sapPaymentSelectedPerson?.user?.person?.lastName
                  ? " " +
                    capitalize(sapPaymentSelectedPerson?.user?.person?.lastName)
                  : ""}
              </>
            ) : sapPaymentSelectedPerson?.type === "merch-store" ? (
              sapPaymentSelectedPerson?.merchstore?.customStoreName || "----"
            ) : (
              "HQ"
            )} */}
            {sapPaymentSelectedPerson?.name || "----"}
          </p>

          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                payment();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmModal(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
