import across from "../../imgs/across.png";
import back5 from "../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import Mtable from "./table";
const Index = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [Location, setLocation] = useState(null);
  // const [error, setError] = useState(null);

  useEffect(() => {
    handleLocationAccess();
  }, []);

  const handleLocationAccess = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          console.log(Location);
          // setError(null);
        },
        (error) => {
          console.error("Error getting location:", error);
          // setError("Please allow access to your location.");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      // setError("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <div
        className="sm:p-10 min-w-full pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="mb-8"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={`flex justify-between items-center mt-4`}>
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() => {
                  user === "employee"
                    ? router("/EDashboard")
                    : user === "admin"
                    ? router("/AdminDashboard")
                    : user === "superadmin"
                    ? router("/superAdminDashboard")
                    : router("/Dashboard");
                }}
                className="w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Merchant
              </h2>
            </div>
          </div>
        </div>
        <Mtable />
      </div>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{""}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Index;
