import React from "react";
import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const EventCard = () => {
  const router = useNavigate();
  const events = [
    {
      id: 1,
      coverPhotos: [
        "https://www.shutterstock.com/image-photo/san-diego-july-12-esri-600nw-57038776.jpg",
        "https://www.shutterstock.com/image-photo/san-diego-july-12-esri-600nw-57038776.jpg",
        "https://www.shutterstock.com/image-photo/san-diego-july-12-esri-600nw-57038776.jpg",
      ],
      name: "Tech Innovators Conference",
      startDate: "2024-06-01",
      endDate: "2024-06-03",
      location: "New York, NY",
      route: "/EventDetails",
    },
    {
      id: 2,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlprAMpIkXNVllhdiT6u3MQZUBQkmTLYnS_HlscuMaUw&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhu773EEB_8cc3lVAsmpDfAzm5K2QBvvOf2mfbhe8AQ&s",
      ],
      name: "Health & Wellness Expo",
      startDate: "2024-06-10",
      endDate: "2024-06-12",
      location: "Los Angeles, CA",
      route: "/EventDetails",
    },
    {
      id: 3,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhu773EEB_8cc3lVAsmpDfAzm5K2QBvvOf2mfbhe8AQ&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhu773EEB_8cc3lVAsmpDfAzm5K2QBvvOf2mfbhe8AQ&s",
      ],
      name: "Digital Marketing Summit",
      startDate: "2024-07-05",
      endDate: "2024-07-07",
      location: "Chicago, IL",
      route: "/EventDetails",
    },
    {
      id: 4,
      coverPhotos: [
        "https://images.ctfassets.net/gl3ev2s7u7gx/5W9fnKM9za9wTQ1ZJtJXLf/7e21e6060d2e9287c2b2f429dace516e/SemiPermanent_250522_CARD_28_4295.jpg?w=900",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhu773EEB_8cc3lVAsmpDfAzm5K2QBvvOf2mfbhe8AQ&s",
      ],
      name: "Art & Design Fair",
      startDate: "2024-07-20",
      endDate: "2024-07-22",
      location: "Miami, FL",
      route: "/EventDetails",
    },
    {
      id: 5,
      coverPhotos: [
        "https://www.liverpool.ac.uk/media/livacuk/schoolofmanagement/conferencesandevents/20240529-BfCE-COEW-Wellbeing-entrepreneurship-workshop---Top-banner.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhu773EEB_8cc3lVAsmpDfAzm5K2QBvvOf2mfbhe8AQ&s",
      ],
      name: "Entrepreneurship Workshop",
      startDate: "2024-08-15",
      endDate: "2024-08-17",
      location: "San Francisco, CA",
      route: "/EventDetails",
    },
    {
      id: 6,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyApYD8UxGmSXBYSDxYnG9-vg5wnrXz1cAeFtjqjIqHg&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhu773EEB_8cc3lVAsmpDfAzm5K2QBvvOf2mfbhe8AQ&s",
      ],
      name: "Music & Arts Festival",
      startDate: "2024-09-10",
      endDate: "2024-09-12",
      location: "Austin, TX",
      route: "/EventDetails",
    },
  ];

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <LeftOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <RightOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  return (
    <div className="flex w-full pt-[4rem] pb-[8rem] flex-col justify-center">
      <div className="relative w-[40%]">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 mb-4 text-gray-500 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
          placeholder="Search Event..."
          required=""
        />
      </div>
      <div className="w-full flex flex-wrap gap-5 gap-y-8 justify-between">
        {events.map((event) => (
          <div key={event.id} className="w-[32%]">
            <Carousel
              arrows
              prevArrow={<CustomPrevArrow />}
              nextArrow={<CustomNextArrow />}
            >
              {event.coverPhotos.map((coverPhoto, index) => (
                <div key={index}>
                  <img
                    className="w-full h-48 rounded-tr-[1.5rem] rounded-tl-[1.5rem] object-cover"
                    src={coverPhoto}
                    alt={event.name}
                  />
                </div>
              ))}
            </Carousel>
            <div className="px-6 py-4 bg-gray-100 rounded-br-[1.5rem] rounded-bl-[1.5rem]">
              <div
                onClick={() => router(event.route)}
                className="font-semibold text-xl mb-2 hover:text-[#1BA397] cursor-pointer"
              >
                {event.name}
              </div>
              <p className="text-gray-700 text-base">
                {event.startDate} - {event.endDate}
              </p>
              <p className="text-gray-700 text-base">{event.location}</p>
              <div className="w-full flex items-center mt-2 justify-center">
                <Button className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white">
                  Join now
                </Button>
              </div>
            </div>
          </div>
        ))}
        <p className="text-primary hover:underline cursor-pointer w-full text-center mt-4 text-semibold font-semibold text-lg">
          Show More{" "}
        </p>
      </div>
    </div>
  );
};

export default EventCard;
