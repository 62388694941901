import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const data = [
  { name: "Jan", Total: 400, Outstanding: 100, Paid: 300 },
  { name: "Mon", Total: 600, Outstanding: 400, Paid: 200 },
  { name: "Tue", Total: 500, Outstanding: 200, Paid: 300 },
  { name: "Wed", Total: 400, Outstanding: 700, Paid: 600 },
  { name: "Thu", Total: 700, Outstanding: 400, Paid: 300 },
  { name: "Fri", Total: 900, Outstanding: 1000, Paid: 700 },
  { name: "Sat", Total: 600, Outstanding: 300, Paid: 200 },
];
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const totalPayload = payload.find((item) => item.dataKey === "Total");
    const outstandingPayload = payload.find(
      (item) => item.dataKey === "Outstanding"
    );
    const paidPayload = payload.find((item) => item.dataKey === "Paid");
    if (totalPayload && outstandingPayload && paidPayload) {
      const day = totalPayload.payload.name;
      return (
        <div className={`custom-tooltip bg-primary text-white p-2`}>
          <p className="font-bold">{day}</p>
          <p className="text-sm font-semibold">
            Balance:{" "}
            <span className="text-sm font-normal">${totalPayload.value}K</span>
          </p>
          <p className="text-sm font-semibold">
            Outstanding:{" "}
            <span className="text-sm font-normal">
              ${outstandingPayload.value}K
            </span>
          </p>
          <p className="text-sm font-semibold">
            Paid:{" "}
            <span className="text-sm font-normal">${paidPayload.value}K</span>
          </p>
        </div>
      );
    }
  }
  return null;
};
const Div = ({label,bgColor}) => {
  return (
    <p>
      <span className={`inline-block w-3 h-3 rounded-full ${bgColor} mr-2 `}></span>{" "}
      <span className="text-[12px] font-semibold">{label}</span>
    </p>
  );
};
const Chart = () => {
  return (
    <div className="w-full">
      {/* <div className="flex items-center p-2 w-full justify-end">
        <select
          id="sortByDropdown"
          className="w-full md:w-auto pl-2 pr-2 h-[40px] text-sm font-bold text-primary focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="all">ALL</option>
          <option value="lWeek">Last Week</option>
          <option value="name">Last Month</option>
          <option value="date">Last Year</option>
        </select>
      </div> */}
      <div className="flex gap-3 justify-between">
        <Div label={"Merchant Outstanding"} bgColor={"bg-[#1E8E87]"}/>
        <Div label={"Paid to Merchants"} bgColor={"bg-[#127EE1]"}/>
        <Div label={"Fee Paid to Merchants"} bgColor={"bg-[#A957AE]"}/>
        <Div label={"Paid to Pensioner"} bgColor={"bg-[#8FC660]"}/>
        
      </div>
      <div className="flex relative flex-col w-full overflow-hidden ">
        <div className=" p-2 shadow-lg rounded-lg bg-[#F6FBF9] mt-3">
          {/* <div className="font-semibold text-md">{title}</div> */}
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              width={730}
              height={250}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1BA397" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F6FBF9" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="Outstanding" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FF0A21" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F6FBF9" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="Paid" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#127EE1" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F6FBF9" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" stroke="gray" />
              <YAxis tickFormatter={(value) => `$${value}`} stroke="gray" />
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                className="chartGrid"
              />
              <Tooltip content={<CustomTooltip label="Total" />} />
              <Tooltip content={<CustomTooltip label="Outstanding" />} />
              <Tooltip content={<CustomTooltip label="Paid" />} />
              <Area
                type="monotone"
                dataKey="Total"
                stroke="#1BA397"
                fillOpacity={1}
                fill="url(#total)"
              />
              <Area
                type="monotone"
                dataKey="Outstanding"
                stroke="#FF0A21"
                fillOpacity={1}
                fill="url(#Outstanding)"
              />
              <Area
                type="monotone"
                dataKey="Paid"
                stroke="#127EE1"
                fillOpacity={1}
                fill="url(#Paid)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Chart;
