import React from "react";
import { useSelector } from "react-redux";
import ReactInputVerificationCode from "react-input-verification-code";
import axios from "axios";
import cross from "../../../../../../imgs/cross.png";
const ResetPin = ({
  setShow,
  setShowS,
  setPin,
  pin,
  pass,
  setPass,
  setErrorShow,
  setErr
}) => {
  const token = useSelector((state) => state.token);
  const ResetPin = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/reset-pincode`,
        {
          pinCode: pin,
          password: pass,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShow(false);
        setPass("");
        setPin("");
        setErr("Pin Reset Sucessfully.");
        setShowS(true);
        setTimeout(() => {
            setShowS(false);
        }, 1000);
        // setShowVPass(false);
        // setPass("");
        // setPin("");
        // setShowPopRPin(false);
        // setErr("Pin Reset Sucessfully.");
        // setShowS(true);
        // setTimeout(() => {
        //   setShowS(false);
        // }, 1000);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setErrorShow(true);
      });
  };
  const completeHandler = (e) => {
    setPin(e);
  };
  return (
    <div
      className={`w-[100%] z-[10%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[22rem] pb-3 flex items-center p-3 bg-white flex-col">
        <div className="flex items-center justify-center flex-col ">
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => {
              setShow(false);
              setPass("");
              setPin("");
            }}
          >
            <img className="scale-[0.8]" src={cross} alt="Close" />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex mt-6 justify-between lg:text-[1.1rem]  bg-white rounded-[0.3rem] p-2">
              <ReactInputVerificationCode
                className="--ReactInputVerificationCode-itemWidth"
                length={4}
                onCompleted={completeHandler}
                value={pin}
                onChange={(code) => setPin(code)}
              />
            </div>

            <h2 className="mt-1 text-[12px] font-bold">
              <span className="text-red-500">Note:</span> Please do not share
              your pin with anyone.
            </h2>
            <button
              onClick={ResetPin}
              className="w-full bg-primary text-white p-2 mb-2 text-medium text-[1rem] rounded-lg mt-3"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPin;
