import React from "react";
import logo from "../../imgs/logo12.png";
import home from "../../imgs/home.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    WalletOutlined,
    HomeOutlined,
    TransactionOutlined,
    PayCircleOutlined,
    ShoppingCartOutlined,
    DollarOutlined,
    ContainerOutlined,
    BankOutlined
  } from '@ant-design/icons';


const Sidebar = () => {
  const sidebarE = useSelector((state) => state.sidebarE);
  const dispatch = useDispatch();
  const router = useNavigate();

  const menuItems = [
    {
      key: 1,
      activeIcon: <HomeOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Dashboard",
      inactiveIcon: home,
      router: "/eWallet",
    },
    {
      key: 2,
      activeIcon: <WalletOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Wallet",
      router:"/eWallet/wallet"
    },
    {
      key: 3,
      activeIcon: <BankOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Banking",
    },
    {
      key: 4,
      activeIcon: <TransactionOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Transfers",
    },

    {
      key: 5,
      activeIcon: <PayCircleOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Bill Pay",
    },
    {
      key: 6,
      activeIcon: <ShoppingCartOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Shopping",
    },
    {
      key: 7,
      activeIcon: <ContainerOutlined className="h-5 w-5 text-xl flex items-center justify-center"/> ,
      text: "Ticket",
    },
    {
      key: 8,
      activeIcon: <DollarOutlined className="h-5 w-5 text-xl flex items-center justify-center"/>,
      text: "Top Up",
    },
  ];
console.log("test", sidebarE)
  return (
    <div className="rounded-lg flex space-between flex-col h-full">
  <aside
    id="logo-sidebar"
    aria-label="Sidebar"
    className="h-full"
    style={{borderRight: "none"}}
  >
    <div className="px-4 py-6">
      <a
        href="#"
        className="flex items-center mb-4"
      >
        <img src={logo} className="h-8 mr-2" alt="Flowbite Logo" />
      </a>

      <ul className="space-y-0 font-medium text-primary text-[1rem]">
        {menuItems.map((menuItem) => (
          <li
            key={menuItem.key}
            onClick={() => {
              dispatch({ type: "SIDEBARE", num: menuItem.key });
            }}
          >
            <a
              href="#"
              className={`flex gap-x-2 items-center p-3 rounded-lg ${sidebarE === menuItem.key
                ? "bg-primary text-white"
                : "text-gray-900 hover:bg-gray-100 hover:text-white"
              }`}
              onClick={(e) => {
                e.preventDefault();
                router(menuItem.router);
              }}
            >
              <span className={`flex items-center text-bold justify-center h-4 w-5 mr-2 ${sidebarE === menuItem.key
                ?  "text-white"
                : "text-primary"
              }`}>{menuItem.activeIcon}</span>
              
              <span className={`${sidebarE === menuItem.key
                ? "text-white"
                : "text-primary"
              }`}>{menuItem.text}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  </aside>
  {/* <p className="text-gray-400 mt-auto mb-6 text-center px-4 py-2">
    Version 1.0.0
  </p> */}
</div>
  );
};

export default Sidebar;
