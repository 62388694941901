import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import NewSingleReport from "./NewSingleReport";
const PensionerReport = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "gidFilterSet", num: "" })
  }, [])
  //   useEffect(() => {
  //     axios.get(`${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=pensioner`, {
  //       headers: {
  //         "Authorization": `Bearer ${dataUser.data.tokens.access.token}`
  //       }
  //     })
  //       .then((data) => {
  //         console.log(data)
  //         setCount({
  //           active: data?.data?.active,
  //           inactive: data?.data?.inactive,
  //           all: data?.data?.total
  //         })
  //       }).catch((err) => {
  //         console.log(err)
  //       })
  //   }, [])

  return (
    <div className="">
      <div className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem] mb-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => router("/Merchant/Reports")}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
                Pensioner Report
            </h2>
           

          </div>
          
        </div>

      </div>
      <NewSingleReport/>
      {/* <SingleReport/> */}
    </div>
  );
};

export default PensionerReport;
