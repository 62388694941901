import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import across from "../../imgs/across.png";
import eye from "../../imgs/eyee.png";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [passShow, setPassShow] = useState("password");
  const [oldPassword, setOldPassword] = useState("");
  const token = useSelector((state) => state.token);
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  console.log(prompt);
  const resetHandler = () => {
    if (newPass !== confirmPass) {
      setShow(true);
      setErr("Please confirm password again");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/change-password`,
        {
          oldPassword: oldPassword,
          newPassword: newPass,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({ type: "MERCHANT" });
        dispatch({ type: "BRSET", num: 2 });
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const passShowHandler = () => {
    if (passShow === "password") {
      setPassShow("text");
    } else {
      setPassShow("password");
    }
  };
  return (
    <div className="flex justify-center">
      <form className="sm:w-[50%] w-[80%]">
        <div className="text-center mt-[3rem] flex flex-col items-center mb-[3rem]">
          <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
            Change Password
          </h2>
          <p className="w-[60%] text-[#596F96] text-[0.9rem]">
            To change password, please enter new password and confirm it.
          </p>
        </div>
        <div className="">
          <div className="flex flex-col">
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Old Password<span className="text-red-600 ml-1">*</span>
            </label>
            <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                type={passShow}
                placeholder="Password"
                onChange={handleChange(setOldPassword)}
                value={oldPassword}
                required
                className="w-[100%]"
              ></input>
              <img
                className="w-[1.6rem] cursor-pointer"
                onClick={passShowHandler}
                src={eye}
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              New Password<span className="text-red-600 ml-1">*</span>
            </label>
            <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                type={passShow}
                placeholder="Password"
                onChange={handleChange(setNewPass)}
                value={newPass}
                required
                className="w-[100%]"
              ></input>
              <img
                className="w-[1.6rem] cursor-pointer"
                onClick={passShowHandler}
                src={eye}
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Confirm Password<span className="text-red-600 ml-1">*</span>
            </label>
            <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                type={passShow}
                placeholder="Password"
                onChange={handleChange(setConfirmPass)}
                value={confirmPass}
                required
                className="w-[100%]"
              ></input>
              <img
                className="w-[1.6rem] cursor-pointer"
                onClick={passShowHandler}
                src={eye}
                alt=""
              />
            </div>
          </div>
        </div>

        <button
          type="button"
          onClick={resetHandler}
          className="justify-center mt-8 flex w-[100%] cursor-pointer bg-primary pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
        >
          Submit
        </button>
      </form>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] z-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
