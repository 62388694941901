import Paccount from "../components/pensionerVerification/pensionerDash/index";
// import Nav from "../components/Nav/nav";
// import Whatsapp from "../Whatsapp/whatsapp";

const PAccount = () => {
  return (
    <div>
      {/* <Nav /> */}
      <Paccount />
      {/* <Whatsapp/> */}
    </div>
  );
};

export default PAccount;
