import { Checkbox, DatePicker, Pagination } from "antd";
import imageP from "../../../../imgs/printer 1.png";
// import c from "../../../../imgs/c.png";
// import i from "../../../../imgs/i.png";
// import v from "../../../../imgs/v.png";
// import s from "../../../../imgs/sa.png";
import { useEffect, useState } from "react";
import Main from "./Main";
import moment from "moment";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const Paget = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const selectedGovAgency = useSelector((state) => state.selectedGovAgency);
  const user = useSelector((state) => state.user);
  const dataUser = useSelector((state) => state.dataUser);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [err, setErr] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [searchDop, setSearchDop] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [transactionType, setTransectionType] = useState("cr");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [recordsDb, setRecordsDb] = useState([]);
  const [recordsCr, setRecordsCr] = useState([]);
  const privilege = useSelector((state) => state.myPrivileges);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [method, setMethod] = useState(null);
  const columnHeadersCr = [
    "Sr No.",
    "Requested On",
    "Payment ID",
    "Transaction Date/Time",
    "Payment Method",
    "Amount",
  ];
  const columnHeadersDb = [
    "Sr No.",
    "Transaction Date/Time",
    // "Payment Method",
    "Pensioner",
    "Phone",
    "GID",
    "Program",
    "Booklet#",
    "Voucher Cashing ID",
    "Amount",
  ];

  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: search ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
      };

      if (searchDop !== "" && transactionType === "db") {
        params.paymentDate = searchDop;
      }

      if (method !== null && transactionType === "cr") {
        params.method = paymentMethod;
      }

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, transactionType, method, searchDop]);

  const fetchEmployees = (params, cancelTokenSource) => {
    const api =
      transactionType === "cr"
        ? `v1/bank-balance-histories/list-govt-agencies-deposits/for-mhsss?toMerchHeadquarter=${selectedGovAgency?.merchUser?.merchheadquarterId}`
        : `v1/merch-transactions/list-by-merchId?merchUserId=${selectedGovAgency?.merchUser?.id}`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        if (transactionType === "cr") {
          setRecordsCr(data?.data?.rows);
        } else {
          setRecordsDb(data.data.rows);
        }

        setTotalPages(data.data.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageHandler = (e) => {
    if (search) {
      setPagewithsearch(e);
    } else {
      setPage(e);
    }
  };
  const Tabs = [
    {
      key: "cr",
      label: "Credited Transactions",
      // component: <Table />,
    },
    {
      key: "db",
      label: "Debited Transactions",
      // component: <GovernmentAgency />,
    },
  ];
  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const disabledDate1 = (current) => {
    return current && current > dayjs().endOf("day");
  };
  // const cards = [
  //   {
  //     id: 1,
  //     title: "Credited Transactions",
  //     text: "View transactions that MHSSS paid to government agencies",
  //     icon: c,
  //     color: "#E5EEFD",
  //     route: "/MHSSS/GovernmentAgency/Details/CreditedTransactions",
  //   },
  //   {
  //     id: 2,
  //     title: "Internal Fund Transfer",
  //     text: "View transactions that agency had made for internal fund transfer.",
  //     icon: i,
  //     color: "#FCEFEB",
  //     route: "/MHSSS/GovernmentAgency/Details/InternalFundTransfer",
  //   },
  //   {
  //     id: 3,
  //     title: "Voucher Cashing",
  //     text: "View voucher cashing transactions by govt. agency via bank, cheque, or cash.",
  //     icon: v,
  //     color: "#D9FDDC",
  //     route: "/MHSSS/GovernmentAgency/Details/VoucherCashing",
  //   },
  //   {
  //     id: 4,
  //     title: "SAP Wallet Transactions",
  //     text: "View Govt. agency’s all SAP wallet transactions",
  //     icon: s,
  //     color: "#F5ECCD",
  //     route: "/MHSSS/GovernmentAgency/Details/SAPWalletTransacions",
  //   },
  // ];
  const handlePaymentMethodChange = (method) => {
    // If the clicked checkbox is already selected, uncheck it by setting state to null
    if (paymentMethod === method) {
      setPaymentMethod(null);
    } else {
      setPaymentMethod(method);
    }
  };
  return (
    <section className="sm:mt-0 mt-5">
      <div className="mx-auto max-w-screen-xl ">
        <div className=" relative sm:rounded-lg overflow-hidden pr-1">
          <div className="w-full flex mb-1 items-center justify-end">
            <div className="w-[120px] flex cursor-pointer items-center justify-center gap-4 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg h-[37px] pl-4 pr-4 p-2">
              <img src={imageP} alt="print" />
              Print
            </div>
          </div>
        </div>
        <div className="bg-white w-full flex flex-col">
          <Main recordsCr={recordsCr} />
          <div className="flex flex-row items-center justify-end mb-4 gap-2">
            <div className="w-[45%] border rounded-lg md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <div className="relative w-full">
                    <button
                      id="filterDropdownButton"
                      data-dropdown-toggle="filterDropdown"
                      className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900  focus:z-10     "
                      type="button"
                      onClick={showHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter By
                      <svg
                        className="-mr-1 ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>

                    {show && (
                      <div
                        id="filterDropdown"
                        className="z-50 absolute top-full right-0 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                      >
                        {transactionType === "cr" ? (
                          <div className="mt-5">
                            <p className="font-semibold text-md mb-2">
                              Select Payment Method:
                            </p>
                            <Checkbox
                              onChange={() => handlePaymentMethodChange("bank")}
                              checked={paymentMethod === "bank"}
                              className="checkk4"
                            >
                              Bank
                            </Checkbox>
                            <Checkbox
                              onChange={() =>
                                handlePaymentMethodChange("cheque")
                              }
                              checked={paymentMethod === "cheque"}
                              className="checkk4"
                            >
                              Cheque
                            </Checkbox>
                          </div>
                        ) : transactionType === "db" ? (
                          <div className="mt-5">
                            <DatePicker
                              onChange={(e, f) => setPaymentDate(f)}
                              value={
                                paymentDate !== "" ? moment(paymentDate) : null
                              }
                              placeholder="Payment Date"
                              disabledDate={disabledDate1}
                            />
                          </div>
                        ) : null}

                        <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => {
                              if (
                                paymentMethod !== null &&
                                transactionType === "cr"
                              ) {
                                setMethod(paymentMethod);
                              }
                              if (paymentDate !== null && paymentDate !== "") {
                                setSearchDop(paymentDate);
                              }
                              setShow(false);
                            }}
                            className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              if (transactionType === "cr") {
                                setMethod(null);
                                setPaymentMethod(null);
                              }
                              if (transactionType === "db") {
                                setSearchDop("");
                                setPaymentDate("");
                              }
                              setShow(false);
                            }}
                            className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-2 px-4">
              {/* <div className="w-full md:w-[20rem]">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                      placeholder="Search"
                      required=""
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div> */}
              <button
                onClick={() => {
                  if (
                    user === "employee" &&
                    !privilege.includes("Deposit to govt agency bank")
                  ) {
                    setShowError(true);
                    setErr("You don't have privilege to access this");
                  } else {
                    router("/MHSSS/GovernmentAgency/AddMoney");
                  }
                }}
                className="w-[120px] flex cursor-pointer items-center justify-center gap-4 bg-primary border border-gray-300 text-white text-md rounded-lg h-[37px] pl-4 pr-4 p-2"
              >
                Add Money
              </button>
            </div>
          </div>
          <div className="mb-8 w-full px-6 h-full rounded-tl-lg rounded-tr-lg">
            <div className=" mt-2 ">
              <div className="grid grid-cols-2 gap-0 ">
                {Tabs.map((tab) => (
                  <button
                    className={`${
                      tab.key === transactionType
                        ? "border-primary bg-primary font-semibold text-white"
                        : "text-[#B7B7B7] bg-[#ECECEC] font-semibold"
                    }  p-2  text-[0.95rem] shadow-md flex items-center ${
                      tab.key === "db" ? "rounded-tr-lg" : "rounded-tl-lg"
                    } justify-center `}
                    onClick={() => setTransectionType(tab?.key)}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>{" "}
            </div>
            {transactionType === "cr" && (
              <>
                <div className="overflow-x-auto w-full h-full mt-2 rounded-tl-lg rounded-tr-lg custom-scrollbar">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase rounded-t-lg bg-primary text-white">
                      <tr>
                        {columnHeadersCr.map((columnHeader) => (
                          <th
                            scope="col"
                            className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                          >
                            {columnHeader}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {recordsCr?.length === 0 ? (
                      <tbody>
                        <tr>
                          <td
                            colSpan="11"
                            className="text-center text-gray-500 text-lg bg-white"
                          >
                            <p className="p-10 py-20">No data found</p>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {recordsCr?.map((data, index) => (
                          <tr
                            onClick={
                              () => {
                                dispatch({
                                  type: "DEPOSIT_MONEY_DATA",
                                  num: {
                                    method: data?.method,
                                    reqDate:
                                      data?.bankbalancerequest !== null
                                        ? data?.bankbalancerequest?.createdAt
                                        : null,
                                    paymentId: data?.paymentId,
                                    transactionData: data?.createdAt,
                                    bankReceiptImg: data?.bankReceiptImg,
                                    bankReceiptNo: data?.bankReceiptNo,
                                    chequeImg: data?.chequeImg,
                                    chequeNo: data?.chequeNo,
                                    amount: data?.amount,
                                  },
                                });
                                router(
                                  "/MHSSS/GovernmentAgency/Details/CreditedTransactions/detail"
                                );
                              }
                              // }
                            }
                            className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
                          >
                            <td className="px-4 py-2 whitespace-nowrap">
                              {index + 1 + (page - 1) * 6}.
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap">
                              <p>
                                {data?.bankbalancerequest !== null
                                  ? moment(
                                      data?.bankbalancerequest?.createdAt
                                    ).format("MM/DD/YYYY")
                                  : "-----"}
                              </p>
                              <p>
                                {" "}
                                {data?.bankbalancerequest !== null
                                  ? moment(
                                      data?.bankbalancerequest?.createdAt
                                    ).format("hh:mm A")
                                  : ""}
                              </p>
                            </td>

                            <td className="px-4 py-2 whitespace-nowrap">
                              <div>{data?.paymentId}</div>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap">
                              <p>
                                {moment(data?.createdAt).format("MM/DD/YYYY")}
                              </p>
                              <p>
                                {" "}
                                {moment(data?.createdAt).format("hh:mm A")}
                              </p>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap capitalize">
                              {data?.method}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap  font-semibold text-primary capitalize">
                              ${data?.amount}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                <nav
                  className="flex bg-gray-50 flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500">
                    Showing{" "}
                    <span className="font-semibold text-gray-900 ">
                      Page {page}{" "}
                    </span>
                    of{" "}
                    <span className="font-semibold text-gray-900 ">
                      {totalPages}
                    </span>
                  </span>
                  <div className={`flex justify-end mt-7`}>
                    <Pagination
                      defaultCurrent={1}
                      total={totalPages * 10}
                      showSizeChanger={false}
                      onChange={pageHandler}
                      current={search ? pagewithsearch : page}
                    />
                  </div>
                </nav>
              </>
            )}
            {transactionType === "db" && (
              <>
                <div className="overflow-x-auto w-full h-full mt-2 rounded-tl-lg rounded-tr-lg custom-scrollbar">
                  <div className="flex flex-col w-full">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase rounded-t-lg bg-primary text-white">
                        <tr>
                          {columnHeadersDb.map((columnHeader, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                            >
                              {columnHeader}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      {recordsDb?.length === 0 ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="11"
                              className="text-center text-gray-500 text-lg bg-white"
                            >
                              <p className="p-10 py-20">No data found</p>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {recordsDb?.map((data, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                router(
                                  "/MHSSS/GovernmentAgency/VoucherDetails",
                                  {
                                    state: { gov: true },
                                  }
                                );
                                dispatch({
                                  type: "SELECTED_PAYMENT_DETAIL",
                                  num: data,
                                });
                              }}
                              className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
                            >
                              <td className="px-4 py-2 whitespace-nowrap">
                                {index + 1 + (page - 1) * 6}.
                              </td>

                              <td className="px-5 py-2 whitespace-nowrap">
                                <p>
                                  {moment(data?.createdAt).format("MM/DD/YYYY")}
                                </p>
                                <p>
                                  {moment(data?.createdAt).format("hh:mm A")}
                                </p>
                              </td>
                              {/* <td className="px-4 py-2 whitespace-nowrap">
                                <div>{data?.PaymentMethod}</div>
                              </td> */}
                              <td className="px-4 py-2 whitespace-nowrap">
                                {data?.user?.person?.firstName +
                                  " " +
                                  data?.user?.person?.lastName}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {data?.user?.countryCode !== null
                                  ? data?.user?.countryCode +
                                    " " +
                                    data?.user?.phoneNo
                                  : "-----"}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {data?.user?.gid}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap capitalize">
                                {data?.pensionbook?.pensionprogram?.name ===
                                "senior-citizen-Pension"
                                  ? "Senior Citizen Pension"
                                  : data?.pensionbook?.pensionprogram?.name}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {data?.pensionbook?.bookletNo}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {data?.transactionId}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap font-semibold text-red-600">
                                ${data?.totalAmount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <nav
                  className="bg-gray-50 flex flex-col h-[5.5rem] md:flex-row w-full justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500">
                    Showing{" "}
                    <span className="font-semibold text-gray-900">
                      Page {page}
                    </span>{" "}
                    of{" "}
                    <span className="font-semibold text-gray-900">
                      {totalPages}
                    </span>
                  </span>
                  <div className={`flex justify-end mt-7`}>
                    <Pagination
                      defaultCurrent={1}
                      total={totalPages * 10}
                      showSizeChanger={false}
                      onChange={pageHandler}
                      current={search ? pagewithsearch : page}
                    />
                  </div>
                </nav>
              </>
            )}
          </div>
        </div>
      </div>
      {showError && <ErrorModal err={err} setShow={setShowError} />}
    </section>
  );
};

export default Paget;
