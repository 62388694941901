import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import thumb from "../../../../../imgs/Thumb.png";
import { useNavigate } from "react-router-dom";
import logo from "../../../../../imgs/mhsss_Accounting-logo.png";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import axios from "axios";
const Main = ({ showWallet, setShowWallet }) => {
  const sapmoney = useSelector((state) => state.sapmoney);
  const dispatch = useDispatch();
  const router = useNavigate();
  const totaPayment = useSelector((state) => state.totalPayment);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const selectedPaymentMethod = useSelector(
    (state) => state.selectedPaymentMethod
  );
  const selectedTransactionsVouchers = useSelector((state)=>state.selectedTransactionsVouchers)

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        // router("/MHSSS/ewalletcashPay/verify");
        if(sapmoney === true){
          router("/SapMoney/MHSSS/outstanding/payment/Receipt")
          return
        }
        router("/MHSSS/paymentReceipt");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const Pay = () => {
    setShowWallet(false);
    const body = {
      merchtransactionId: selectedTransactionsVouchers?.[0]?.merchtransactionId,
      paymentMethod: selectedPaymentMethod,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-transaction-payments`,

        body,

        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "AFTER_PAYMENT_DATA", num: data.data });
        setShow(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };
  return (
    <div className="flex flex-col w-full overflow-hidden min-h-[82vh] p-4 relative rounded-md bg-[#FFFFFFE5]">
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Payment Done</h1>
          <p className="text-center mt-[1rem] mb-5">
            Amount <span className="font-bold">${totaPayment}</span> has been
            transferred to the Merchant's account
          </p>
        </div>
      </div>
      <div
        className={`${
          showWallet ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[28rem] pb-8 pt-6 flex items-center p-6 bg-white flex-col">
          <div className="w-[5rem] h-[5rem] p-3 flex items-center justify-center rounded-full">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <p className="text-center w-[70%] mt-4 mb-6">
            Are you sure you want to pay{" "}
            <span className="font-bold">${totaPayment}</span> via SAP-Wallet?
          </p>
          <div className="flex gap-4 w-[70%] items-center justify-between">
            <button
              onClick={() => {
                // setShowWallet(false);
                // setShow(true);
                // router("/MHSSS/ewalletcashPay/verify");
                Pay();
              }}
              className="w-[8rem] p-1 border-2 font-bold text-white rounded-lg bg-primary border-primary"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowWallet(false);
              }}
              className="w-[8rem] p-1 border-2 font-bold text-primary rounded-lg border-primary"
            >
              No
            </button>
          </div>
        </div>
      </div>
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
    </div>
  );
};

export default Main;
