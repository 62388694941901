import React, { useState } from "react";
import store1 from "../../../../imgs/store1.svg";

const StoreModal = ({ setShowStoreModal, stores, setSelectedStore }) => {
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-gray-900 ">
              Stores
            </h3>
            <button
              onClick={() => {
                setShowStoreModal(false);
                if (setSelectedStore) {
                  setSelectedStore(null);
                }
              }}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {stores?.length === 0 ? (
            <h1 className="text-center p-6 text-md">No Store Available</h1>
          ) : (
            <div className="p-4 md:p-5">
              <p className="text-gray-500  mb-4">
                Select the Store :
              </p>
              <ul className="space-y-4 max-h-96 overflow-y-scroll custom-scrollbar p-4">
                {stores?.map((voc, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedStore(voc);
                      setShowStoreModal(false);
                    }}
                  >
                    <div
                      className="w-full p-3 
                     text-gray-900 bg-white
                    
                      border border-gray-300 rounded-lg cursor-pointer shadow-sm"
                    >
                      <div className="flex gap-2">
                        <img src={store1} alt="store" className="w-6 h-6" />
                        <div className="w-full text-md font-semibold">
                          {voc?.customStoreName}
                        </div>
                      </div>

                      <div className="w-full text-gray-500 text-xs mt-2">
                        {voc?.lot +
                          ", " +
                          voc?.address +
                          ", " +
                          voc?.region +
                          ", " +
                          voc?.regionName}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreModal;
