import across from "../../../../imgs/across.png";
import user from "../../../../imgs/users.png";
import orders from "../../../../imgs/orders.png";
import vendors from "../../../../imgs/vendors.png";
import earning from "../../../../imgs/earning.png";
import Card from "./Card";
import { useState, useEffect } from "react";
import Mtable from "./table";
import {
  Line,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Index = () => {
  const [err, setErr] = useState("");
  // setErr("");
  const [show, setShow] = useState(false);
  const data = [
    { month: "Jan", earnings: 4000, invested: 2400, expenses: 2400 },
    { month: "Feb", earnings: 3000, invested: 1398, expenses: 2210 },
    { month: "Mar", earnings: 2000, invested: 9800, expenses: 2290 },
    { month: "Apr", earnings: 2780, invested: 3908, expenses: 2000 },
    { month: "May", earnings: 1890, invested: 4800, expenses: 2181 },
    { month: "Jun", earnings: 2390, invested: 3800, expenses: 2500 },
    { month: "Jul", earnings: 3490, invested: 4300, expenses: 2100 },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const items = [
    {
      key: "1",
      label: `Total Users`,
      count: 11580,
      icon: user,
      profit: "5.6",
      loss: "",
    },
    {
      key: "2",
      label: `Total Orders`,
      count: 11580,
      icon: orders,
      profit: "4.1",
      loss: "",
    },
    {
      key: "3",
      label: `Total Vendors`,
      count: 45580,
      icon: vendors,
      profit: "",
      loss: "1.6",
    },
    {
      key: "4",
      label: `Total Earnigs`,
      count: 51580,
      icon: earning,
      profit: "12.5",
      loss: "",
    },
  ];
  const recentOrders = [
    {
      productName: "Banana",
      price: "50$",
      orderId: "12345",
      date: "2024-06-10",
    },
    {
      productName: "Apples",
      price: "75$",
      orderId: "12346",
      date: "2024-06-09",
    },
    {
      productName: "Oranges",
      price: "100$",
      orderId: "12347",
      date: "2024-06-08",
    },
    {
      productName: "Mango",
      price: "20$",
      orderId: "12341",
      date: "2024-06-08",
    },
  ];
  return (
    <>
      <div
        className="sm:p-10 min-w-full bg-gray-50 pl-2 pr-2 md:pl-[5.5rem] md:pr-[5.5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="mb-8"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="flex flex-wrap justify-between sm:ml-0 place-items-center gap-4 mt-[1rem] mb-4">
            {items.map((item) => (
              <Card key={item.key} item={item} />
            ))}
          </div>
          <div className="w-full flex gap-4">
            <div className="flex flex-col gap-4 justify-between items-start rounded-md shadow-md bg-white lg:items-center w-[60%] p-4">
              <div className="flex items-center justify-between w-full">
                <h2 className="text-xl font-bold text-gray-600 mb-4 lg:mb-0">
                  Revenue Report
                </h2>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col items-center lg:items-start mb-2 lg:mb-0 lg:mr-4">
                    <h3 className="font-bold text-[#8884d8] underline">
                      Earnings
                    </h3>
                    <p className="text-gray-600 font-semibold">122342k$</p>
                  </div>
                  <div className="flex flex-col items-center lg:items-start mb-2 lg:mb-0 lg:mr-4">
                    <h3 className="font-bold text-[#82ca9d] underline">
                      Invested
                    </h3>
                    <p className="text-gray-600 font-semibold">76543k$</p>
                  </div>
                  <div className="flex flex-col items-center lg:items-start">
                    <h3 className="font-bold text-[#ff7300] underline">
                      Expenses
                    </h3>
                    <p className="text-gray-600 font-semibold">54321k$</p>
                  </div>
                </div>
              </div>
              <ResponsiveContainer className="w-full" width="100%" height={300}>
                <AreaChart
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="earnings"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.3}
                  />
                  <Area
                    type="monotone"
                    dataKey="invested"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                    fillOpacity={0.3}
                  />
                  <Area
                    type="monotone"
                    dataKey="expenses"
                    stroke="#ff7300"
                    fill="#ff7300"
                    fillOpacity={0.3}
                  />
                  <Line type="monotone" dataKey="earnings" stroke="#8884d8" />
                  <Line type="monotone" dataKey="invested" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="expenses" stroke="#ff7300" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="w-[40%] p-4 rounded-md shadow-md bg-white">
              <h2 className="text-xl font-bold mb-4">Recent Orders</h2>
              <ul>
                {recentOrders.map((order) => (
                  <li
                    key={order.orderId}
                    className="pb-4 mb-4 border-b border-dotted border-gray-300"
                  >
                    <h3 className="font-semibold text-lg text-gray-700 mb-2">
                      {order.productName}
                    </h3>
                    <div className=" flex items-center justify-between">
                      <p className="text-sm font-semibold">
                        Price:{" "}
                        <span className="text-red-600">{order.price}</span>
                      </p>
                      <p className="text-sm font-semibold">
                        Order ID:{" "}
                        <span className="text-gray-500">{order.orderId}</span>
                      </p>
                      <p className="text-sm font-semibold">
                        Date:{" "}
                        <span className="text-gray-500">{order.date}</span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Mtable />
      </div>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Index;
