import background from "../../imgs/background.png";
import Logo2 from "../../imgs/Logo2.png";
const DigitalCardBack = () => {
  return (
    <div>
      <div className="w-[32rem] h-[17.9rem] rounded-xl shadow-2xl overflow-hidden">
        <div className="w-full h-[81.5%] rounded-tl-xl rounded-tr-xl">
          <div className="w-full h-[13%] bg-[#BE1E2D] rounded-t-xl rounded-bt-xl p-2"></div>
          <div
            className="flex flex-col items-center justify-center font-sans text-sm font-normal text-[#232B6A]"
          
            style={{ fontFamily: "Arial, Helvetica, sans-serif", backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <img className="w-12 h-12 mt-1" src={Logo2} alt="" />
            <p className="text-center w-[90%] mt-1">
              This card remains the{" "}
              <span className="font-bold">
                {" "}
                Ministry of Human Services and Social Security{" "}
              </span>
              property. If found, please contact the email address below for
              instructions on returning to the rightful owner.
            </p>
            <p className="text-center py-2 font-bold">pension@mhsss.gov.gv</p>
            <p className="text-center w-[70%]">
              For all Senior Citizen Pension Card queries, contact your nearest
              office.
            </p>
          </div>
        </div>
        <div
          className="w-full h-[18.5%] bg-[#BE1E2D] rounded-bl-xl rounded-br-xl p-2"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <p className="text-white text-sm text-center w-[30rem] mb-2 font-sans px-6">
            Elderly Abuse is often invisible. If you suspect or know an elderly
            person being abused or neglected, 
            <span className="font-bold">CALL 914</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DigitalCardBack;
