import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const CustomDoughnutChart = ({ data }) => {
  const data1 = [
    {
      name: "Outstanding",
      value:
        Number(data?.totalPendingPayAndFee ?? 0) +
        Number(data?.totalPastDuePayAndFee ?? 0),
    },
    {
      name: "Cash Received",
      value: Number(data?.totalMhsssPayAndFeePaid ?? 0),
    },
    {
      name: "Cash Paid",
      value:
        Number(data?.totalMerchPaidAmountAndFee ?? 0) +
        Number(data?.totalPendingPayAndFee ?? 0) +
        Number(data?.totalPastDuePayAndFee ?? 0),
    },
    {
      name: "Fee Received",
      value: Number(data?.totalMHsssFeePaid ?? 0),
    },
    {
      name: "Fee Pending",
      value:
        (Number(data?.totalPendingFee) || 0) +
        (Number(data?.totalPastDueFee) || 0),
    },
  ];

  const COLORS = ["#EF4D5C", "#1BA397", "#256091", "#F18700", "#F6B207"]; // Colors for the chart segments
  return (
    <div className="flex justify-center items-center">
      <ResponsiveContainer width={200} height={200}>
        <PieChart>
          <Pie
            data={data1}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data1?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {/* <div className="absolute text-center text-gray-600 font-medium">
        <div>Cash</div>
        <div>In/Out</div>
      </div> */}
    </div>
  );
};

export default CustomDoughnutChart;
