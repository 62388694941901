import Eregistration from "../../Account/Parts/Eregistration";
import Eidentification from "../../Account/Parts/Eidentification";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
const Eaccount = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const [wow, setWow] = useState(0);
  const dataUser = useSelector((state) => state.dataUser);
  const Show = [Eregistration, Eidentification];
  const reset = useSelector((state) => state.reset);
  const Dis = Show[wow];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/my-profile/`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch(() => {});
  }, [reset]);
  return (
    <div className="pt-[2rem] pb-[5rem]  pl-8 pr-8">
      {profileData && (
        <div>
          <div className="lg:mb-[4rem] flex mb-[2rem]">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                  wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${
                  wow === 1 ? "font-semibold" : ""
                } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                  wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                }  `}
              ></div>
            </div>
          </div>
          <div>
            <Dis />
          </div>
        </div>
      )}
    </div>
  );
};

export default Eaccount;
