import Table from "./Table";
import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const Employee = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  useEffect(() => {
    dispatch({ type: "EMPLOYEE_LINKED_STORES", num: [] });
  }, []);
  return (
    <div className="">
      <div
        className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => {
                router("/Merchant/dashboard");
              }}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Employees
            </h2>
            <button
              className={`${
                dataUser?.data?.merchuser?.status === "pending" ||
                dataUser?.data?.merchuser?.status === "deferred" ||
                dataUser?.data?.merchuser?.status === "rejected"
                  ? "pointer-events-none text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md  "
                  : "text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md "
              }`}
              onClick={() => {
                {
                  router("/Merchant/Add/Employee");
                  dispatch({ type: "ESET", num: 0 });
                }
              }}
            >
              Create Employee
            </button>
          </div>
        </div>
      </div>
      <Table />
    </div>
  );
};

export default Employee;
