import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import back5 from "../../imgs/back5.png";
import days from "dayjs";
import { Select, Checkbox, DatePicker } from "antd";
import axios from "axios";
import across from "../../imgs/across.png";
import { useSelector } from "react-redux";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EditOutlined } from "@ant-design/icons";
const Program = () => {
  const user = useSelector((state) => state.user);
  const router = useNavigate();
  const Curryear = new Date().getFullYear().toString();
  const [oldYear, setOldYear] = useState(Curryear);
  const [ecoYear, setEcoYear] = useState(Curryear);
  const [medYear, setMedYear] = useState("2023");
  const [childYear, setChildYear] = useState("2023");
  const [plwdYear, setPlwdYear] = useState("2023");
  const [weYear, setWeYear] = useState("2023");
  const [pghYear, setPghYear] = useState("2023");
  const [labYear, setLabYear] = useState("2023");
  const yearr = new Date().getFullYear() - 1;
  const years = Array.from({ length: 8 }, (_, index) =>
    (yearr + index).toString()
  );
  const optionYear = new Date().getFullYear();
  const optionYears = Array.from({ length: 7 }, (_, index) =>
    (optionYear + index).toString()
  );
  const optionsArray = optionYears.map((year) => ({
    label: year,
    value: year,
  }));
  const [cashId, setCashId] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const [addModal, setAddModal] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [oldage, setOldAge] = useState([]);
  const [Economical, setEconomical] = useState([]);
  const [Medical, setMedical] = useState([]);
  const [child, setChild] = useState([]);
  const [learningLab, setLearningLab] = useState([]);
  const [pghs, setPghs] = useState([]);
  const [plwds, setPlwds] = useState([]);
  const [wemp, setWemp] = useState([]);
  const [oldCash, setOldCash] = useState([]);
  const [EcoCash, setEcoCash] = useState([]);
  const [MedCash, setMedCash] = useState([]);
  const [wempCash, setWempCash] = useState([]);
  const [childCash, setChildCash] = useState([]);
  const [learningLabCash, setLearningLabCash] = useState([]);
  const [pghsCash, setPghsCash] = useState([]);
  const [plwdsCash, setPlwdsCash] = useState([]);
  const [amount, setAmount] = useState();
  const [title, setTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const [cashEdit, setCashEdit] = useState(false);
  const [program, setProgram] = useState("");
  const [id, setId] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [interval, setInterval] = useState("");
  const [enablecash, setEnablecash] = useState(false);
  const [year, setYear] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [grantAmount, setGrantAmount] = useState("");
  console.log("oldAge", oldage);
  useEffect(() => {
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/program-payments`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        const oldage = data?.data?.filter((item) => {
          return item.program === "senior-citizen-Pension";
        });

        setOldAge(oldage);
        const Medical = data?.data?.filter((item) => {
          return item.program === "pa-medical";
        });
        setMedical(Medical);
        const Economical = data?.data?.filter((item) => {
          return item.program === "pa-economical";
        });
        setEconomical(Economical);
        const child = data?.data?.filter((item) => {
          return item.program === "child-protection";
        });
        setChild(child);
        const llab = data?.data?.filter((item) => {
          return item.program === "learning-lab";
        });
        setLearningLab(llab);
        const pgh = data?.data?.filter((item) => {
          return item.program === "palm-geriatric-home";
        });
        setPghs(pgh);
        const plwd = data?.data?.filter((item) => {
          return item.program === "plwd";
        });
        setPlwds(plwd);
        const we = data?.data?.filter((item) => {
          return item.program === "women-empowerment";
        });
        setWemp(we);
      })

      .catch(() => {
        // setLoading(false);
      });
  }, [refetch]);

  //Cash Grant Get
  useEffect(() => {
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/cash-grant-setting`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        console.log("data", data);
        const oldage = data?.data?.rows?.filter((item) => {
          return item.program === "senior-citizen-Pension";
        });

        setOldCash(oldage);
        const Medical = data?.data?.rows?.filter((item) => {
          return item.program === "pa-medical";
        });
        setMedCash(Medical);
        const Economical = data?.data?.rows?.filter((item) => {
          return item.program === "pa-economical";
        });
        setEcoCash(Economical);
        const child = data?.data?.rows?.filter((item) => {
          return item.program === "child-protection";
        });
        setChildCash(child);
        const LAB = data?.data?.rows?.filter((item) => {
          return item.program === "learning-lab";
        });
        setLearningLabCash(LAB);
        const pgh = data?.data?.rows?.filter((item) => {
          return item.program === "palm-geriatric-home";
        });
        setPghsCash(pgh);
        const pl = data?.data?.rows?.filter((item) => {
          return item.program === "plwd";
        });
        setPlwdsCash(pl);
        const women = data?.data?.rows?.filter((item) => {
          return item.program === "women-empowerment";
        });
        setWempCash(women);
      })

      .catch(() => {
        // setLoading(false);
      });
  }, [refetch]);

  const assignedPrivileges = () => {
    const api = edit ? `v1/program-payments/${id}` : "v1/program-payments";
    const method = edit ? "put" : "post";

    // Define the request body based on the operation
    const requestBody = edit
      ? {
          programPayment: {
            amount: amount,
            interval: interval,
            year: year,
          },
          consumerInfo: {
            ip: "999.99.99.9",
            location: "3323.23232, 4545.5454",
          },
        }
      : {
          programPayment: {
            amount: amount,
            program: program,
            interval: interval,
            year: year,
          },
          consumerInfo: {
            ip: "999.99.99.9",
            location: "3323.23232, 4545.5454",
          },
        };

    axios[method](`${process.env.REACT_APP_BASE_URL}/${api}`, requestBody, {
      headers: {
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then(() => {
        if (edit) {
          if (cashEdit) {
            cashGrantSettings();
          } else {
            setShow1(true);
            setEdit(false);
            setAmount("");
            setErr("Added Successfully");
            setRefetch(!refetch);
            setYear(null);
          }
        } else {
          if (enablecash) {
            cashGrantSettings();
          } else {
            setShow1(true);
            setEdit(false);
            setAmount("");
            setErr("Added Successfully");
            setRefetch(!refetch);
            setYear(null);
            setInterval("");
          }
        }

        // Set a timeout to close the modal after 2 seconds
        setTimeout(() => {
          setShow1(false);
        }, 1000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };

  const cashGrantSettings = () => {
    const api = cashEdit
      ? `v1/cash-grant-setting/${cashId}`
      : "v1/cash-grant-setting";
    const method = cashEdit ? "put" : "post";

    // Define the request body based on the operation
    const requestBody = cashEdit
      ? {
          setting: {
            amount: grantAmount,
            program: program,
            year: year,
            active: enablecash,
            issueDate: issueDate,
          },
          consumerInfo: {
            ip: "999.99.99.9",
            location: "3323.23232, 4545.5454",
          },
        }
      : {
          amount: grantAmount,
          program: program,
          year: year,
          active: enablecash,
          issueDate: issueDate,
        };

    axios[method](`${process.env.REACT_APP_BASE_URL}/${api}`, requestBody, {
      headers: {
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then(() => {
        setShow1(true);
        setEdit(false);
        setAmount("");
        setErr("Added Successfully");
        setRefetch(!refetch);
        setInterval("");
        setGrantAmount("");
        setIssueDate("");
        setEnablecash(false);
        setCashEdit(false);
        setYear(null);
        // Set a timeout to close the modal after 2 seconds
        setTimeout(() => {
          setShow1(false);
        }, 1000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => {
        console.log(err);
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;
    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }
    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    vertical: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4">
        <div className="flex items-center mt-4">
          <div
            onClick={() => {
              user === "admin"
                ? router("/AdminDashboard")
                : router("/superAdminDashboard");
            }}
            className="cursor-pointer"
          >
            <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
          </div>
          <h2 className="font-bold sm:text-[1.8rem] ml-[1rem] text-[1.4rem]">
            Programs Payment
          </h2>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          <div className="grid grid-cols-1 w-full sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {/* Program 1: OLD AGE PENSION-65+YEARS */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">
                Senior Citizen Pension-65+Years
              </h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Senior Citizen Pension");
                    setProgram("senior-citizen-Pension");
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === oldYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setOldYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="">
                  {oldage
                    ?.filter((item) => item?.year === oldYear)
                    ?.map((item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />

                        {oldCash
                          ?.filter((cash) => cash?.year === oldYear)
                          ?.map((cash) => (
                            <div
                              className="text-gray-600 capitalize"
                              key={cash?.interval}
                            >
                              <span
                                className={`${
                                  cash?.active
                                    ? "font-semibold text-gray-500 text-[1rem]"
                                    : "hidden"
                                }`}
                              >
                                <span className="text-primary">
                                  Grant Amount{" "}
                                </span>{" "}
                                ${cash?.amount}/year
                              </span>
                            </div>
                          ))}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.amount ? "" : "hidden"
                          }`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = oldCash?.find(
                              (cash) => cash?.year === oldYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Senior Citizen Pension");
                            setProgram("senior-citizen-Pension");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* Program 2: Economical */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">Economical</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Economical");
                    setProgram("pa-economical");
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === ecoYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setEcoYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="">
                  {Economical?.filter((item) => item?.year === ecoYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />

                        {EcoCash?.filter((cash) => cash?.year === ecoYear)?.map(
                          (cash) => (
                            <div
                              className={`${
                                cash?.active === false ? "hidden" : ""
                              }text-gray-600 capitalize`}
                              key={cash?.interval}
                            >
                              <span
                                className={`${
                                  cash?.active
                                    ? "font-semibold text-gray-500 text-[1rem]"
                                    : "hidden"
                                }`}
                              >
                                <span className="text-primary">
                                  Grant Amount{" "}
                                </span>{" "}
                                ${cash?.amount}/Year
                              </span>
                            </div>
                          )
                        )}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.amount ? "" : "hidden"
                          }`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = EcoCash?.find(
                              (cash) => cash?.year === ecoYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Economical");
                            setProgram("pa-economical");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* Program 3: Medical */}

            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">Medical</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Medical");
                    setProgram("pa-medical");
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === medYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setMedYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="">
                  {Medical?.filter((item) => item?.year === medYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        {MedCash?.filter((cash) => cash?.year === medYear)?.map(
                          (cash) => (
                            <div
                              className={`${
                                cash?.active === false ? "hidden" : ""
                              }text-gray-600 capitalize`}
                              key={cash?.interval}
                            >
                              <span
                                className={`${
                                  cash?.active
                                    ? "font-semibold text-gray-500 text-[1rem]"
                                    : "hidden"
                                }`}
                              >
                                <span className="text-primary">
                                  Grant Amount{" "}
                                </span>{" "}
                                ${cash?.amount}
                              </span>
                            </div>
                          )
                        )}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.year ? "" : "hidden"
                          } ${item?.amount ? "" : "hidden"}`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = MedCash.find(
                              (cash) => cash?.year === medYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Medical");
                            setAmount(item?.amount);
                            setProgram("pa-medical");
                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* Program 4: Temporary Disability */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">Learning Lab</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Learning Lab");
                    setProgram("learning-lab");
                    // setId(Temporary?.id);
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === labYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setLabYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="">
                  {learningLab?.filter((item) => item?.year === labYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        {learningLabCash?.filter(
                          (cash) => cash?.year === labYear
                        )?.map((cash) => (
                          <div
                            className={`${
                              cash?.active ? "" : "hidden"
                            }text-gray-600 capitalize`}
                            key={cash?.interval}
                          >
                            <span
                              className={`${
                                cash?.active
                                  ? "font-semibold text-gray-500 text-[1rem]"
                                  : "hidden"
                              }`}
                            >
                              <span className="text-primary">
                                Grant Amount{" "}
                              </span>{" "}
                              ${cash?.amount}/year
                            </span>
                          </div>
                        ))}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.amount ? "" : "hidden"
                          }`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = learningLabCash?.find(
                              (cash) => cash?.year === labYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Learning Lab");
                            setProgram("learning-lab");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* Program 5: Permanent Disability */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">Palm Geriatric Home</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Palm Geriatric Home");
                    setProgram("palm-geriatric-home");
                    // setId(Permanent?.id);
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === pghYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setPghYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="">
                  {pghs?.filter((item) => item?.year === pghYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        {pghsCash?.filter(
                          (cash) => cash?.year === pghYear
                        )?.map((cash) => (
                          <div
                            className={`${
                              cash?.active === false ? "hidden" : ""
                            }text-gray-600 capitalize`}
                            key={cash?.interval}
                          >
                            <span
                              className={`${
                                cash?.active
                                  ? "font-semibold text-gray-500 text-[1rem]"
                                  : "hidden"
                              }`}
                            >
                              <span className="text-primary">
                                Grant Amount{" "}
                              </span>{" "}
                              ${cash?.amount}/year
                            </span>
                          </div>
                        ))}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.year ? "" : "hidden"
                          } ${item?.amount ? "" : "hidden"}`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = pghsCash?.find(
                              (cash) => cash?.year === pghYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Palm Geriatric Home");
                            setProgram("palm-geriatric-home");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* Child Protection */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">Child Protection</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Child Protection");
                    setProgram("child-protection");
                    // setId(Permanent?.id);
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === childYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setChildYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="">
                  {child?.filter((item) => item?.year === childYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        {childCash?.filter(
                          (cash) => cash?.year === childYear
                        )?.map((cash) => (
                          <div
                            className={`${
                              cash?.active === false ? "hidden" : ""
                            }text-gray-600 capitalize`}
                            key={cash?.interval}
                          >
                            <span
                              className={`${
                                cash?.active
                                  ? "font-semibold text-gray-500 text-[1rem]"
                                  : "hidden"
                              }`}
                            >
                              <span className="text-primary">
                                Grant Amount{" "}
                              </span>{" "}
                              ${cash?.amount}/year
                            </span>
                          </div>
                        ))}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.year ? "" : "hidden"
                          } ${item?.amount ? "" : "hidden"}`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = childCash?.find(
                              (cash) => cash?.year === childYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Child Protection");
                            setProgram("child-protection");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">PLWD</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("PLWD");
                    setProgram("plwd");
                    // setId(Permanent?.id);
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === plwdYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setPlwdYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="">
                  {plwds?.filter((item) => item?.year === plwdYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        {plwdsCash?.filter(
                          (cash) => cash?.year === plwdYear
                        )?.map((cash) => (
                          <div
                            className={`${
                              cash?.active === false ? "hidden" : ""
                            }text-gray-600 capitalize`}
                            key={cash?.interval}
                          >
                            <span
                              className={`${
                                cash?.active
                                  ? "font-semibold text-gray-500 text-[1rem]"
                                  : "hidden"
                              }`}
                            >
                              <span className="text-primary">
                                Grant Amount{" "}
                              </span>{" "}
                              ${cash?.amount}/year
                            </span>
                          </div>
                        ))}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.year ? "" : "hidden"
                          } ${item?.amount ? "" : "hidden"}`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = plwdsCash?.find(
                              (cash) => cash?.year === plwdYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("PLWD");
                            setProgram("plwd");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-4">Women Empowerment</h2>
              <div className="mx-auto sm:w-4/5 w-4.5/5">
                <button
                  onClick={() => {
                    setAddModal(true);
                    setTitle("Women Empowerment");
                    setProgram("women-empowerment");
                    // setId(Permanent?.id);
                  }}
                  className={`mx-auto sm:w-3.7/5 flex items-center justify-center w-full text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-md text-md`}
                >
                  Add New Program Amount
                </button>

                <div className="flex items-center justify-center mt-4 mb-4">
                  <Slider {...settings} className="w-full">
                    {years.map((year) => (
                      <div key={year} className="px-1">
                        <button
                          className={`p-2 px-4 rounded-lg ${
                            year === weYear
                              ? "bg-primary text-white"
                              : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-white"
                          } focus:outline-none`}
                          onClick={() => setWeYear(year)}
                        >
                          {year}
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="">
                  {wemp?.filter((item) => item?.year === weYear)?.map(
                    (item) => (
                      <div
                        className="bg-slate-50 shadow-lg border-lg p-3 relative"
                        key={item?.interval}
                      >
                        <div className="text-gray-600 capitalize">
                          <span className="font-semibold text-gray-800 text-[1rem]">
                            <span className="text-primary">
                              Program Amount{" "}
                            </span>{" "}
                            ${item?.amount} / {item?.interval}
                          </span>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        {wempCash?.filter(
                          (cash) => cash?.year === weYear
                        )?.map((cash) => (
                          <div
                            className={`${
                              cash?.active === false ? "hidden" : ""
                            }text-gray-600 capitalize`}
                            key={cash?.interval}
                          >
                            <span
                              className={`${
                                cash?.active
                                  ? "font-semibold text-gray-500 text-[1rem]"
                                  : "hidden"
                              }`}
                            >
                              <span className="text-primary">
                                Grant Amount{" "}
                              </span>{" "}
                              ${cash?.amount}/year
                            </span>
                          </div>
                        ))}

                        {/* Edit button for the entire div */}
                        <EditOutlined
                          className={`absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer ${
                            item?.year ? "" : "hidden"
                          } ${item?.amount ? "" : "hidden"}`}
                          style={{ fontSize: "1.3rem" }}
                          onClick={() => {
                            setEdit(true);
                            setYear(item?.year);
                            setInterval(item?.interval);
                            const cash = wempCash?.find(
                              (cash) => cash?.year === weYear
                            );
                            if (cash?.amount) {
                              setCashEdit(true);
                              setEnablecash(cash?.active);
                              setCashId(cash?.id);
                              setGrantAmount(cash?.amount);
                              setIssueDate(cash?.issueDate);
                            }
                            setAddModal(true);
                            setTitle("Women Empowerment");
                            setProgram("women-empowerment");
                            setAmount(item?.amount);

                            setId(item?.id);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Payment */}
      <div
        className={`${
          addModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem]  pb-6 pt-6 flex flex-col  p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2 text-center">
            {title}
          </label>

          {/* Status Select */}
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Select Year <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Year"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              className="wow"
              disabled={edit}
              onChange={(e) => setYear(e)}
              value={year}
              options={optionsArray}
            />
          </div>
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Amount <span className="text-red-500">*</span>
            </label>
            <form className="flex items-center justify-between w-full bg-white border-[0.3px] border-gray-300 rounded-md text-black text-center py-1 px-2">
              <input
                type="text"
                placeholder="Enter amount"
                className="p-2 rounded-l focus:outline-none"
                style={{ border: "none" }}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <select
                className="p-1 rounded-r focus:outline-none text-gray-500 text-md"
                style={{ border: "none" }}
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Select Interval
                </option>
                <option value="annually">Per Year</option>
                <option value="monthly">Per Month</option>
              </select>
            </form>
          </div>
          <div className="mt-2">
            {}
            <Checkbox
              checked={enablecash}
              className={"checkk text-[#596F96] text-[0.7rem] font-semibold"}
              onChange={(e) => setEnablecash(e.target.checked)}
            >
              Enable Grant
            </Checkbox>
          </div>
          {enablecash && (
            <div className="mb-5">
              <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
                Grant Amount{" "}
              </label>
              <div className="flex mb-2 items-center w-full bg-white border-[0.3px] border-gray-300 rounded-md text-black text-center py-1 px-2">
                <input
                  type="text"
                  placeholder="Enter Grant Amount"
                  className="p-2 rounded-l focus:outline-none"
                  style={{ border: "none" }}
                  value={grantAmount}
                  onChange={(e) => setGrantAmount(e.target.value)}
                />
              </div>
              <DatePicker
                placeholder="Date Issued"
                disabledDate={disabledDate}
                value={issueDate ? days(issueDate, "YYYY-MM-DD") : null}
                className="date-class"
                onChange={(date, dateString) => setIssueDate(dateString)}
              />
            </div>
          )}

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              onClick={() => {
                setAddModal(false);
                setAmount("");
                setYear(null);
                setEnablecash(false);
                setGrantAmount("");
                setIssueDate("");
                setEdit(false);
                setCashEdit(false);
                setInterval("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                assignedPrivileges();
                setAddModal(false);
              }}
            >
              {edit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
      {/* Error Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>

      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          {/* <div
                        className="absolute top-[-11%] left-[42%]"
                        onClick={() => setShow1(false)}
                    >
                        <img src={across} alt="" />
                    </div> */}
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-primary text-white w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Program;
