import React, { useState } from "react";
import print from "../../../../imgs/print.png";
import imageUrl from "../../../../imgs/bgset.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { printFunc } from "./printReceipt";
import InfoModal from "../../outstanding/PersonTransaction/Vouchers/infoModal";
const Main = () => {
  const selectedGovAgency = useSelector((state) => state.selectedGovAgency);
  const data = useSelector((state) => state.selectedPaymentDetail);
  console.log("data", data);
  const dataUser = useSelector((state) => state.dataUser);
  const [voucher, setVouchers] = useState([]);
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");
  const location = useLocation();
  const pensioner = location.state?.pensioner;
  const gov = location.state?.gov;
  const id = pensioner ? data?.id : gov ? data?.id : data?.merchtransactionId;
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/for-mhsss?merchTransactionId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setVouchers(data?.data?.rows);
        data?.data?.rows.map((item, index) => {
          imageConverter(item?.voucherImage, index);
        });
      })
      .catch(() => {
        // Handle error
      });
  }, []);
  const imageConverter = (url, index) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=paymentRecipet`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setVouchers((prev) => {
          let newArr = [...prev];
          newArr[index].url = data?.data[0]?.url;
          return newArr;
        });
        // setVouchers((prev) => {
        //     let newArr = [...prev];
        //     newArr[contactIndex].merchcontactpersondocs[docIndex].images[
        //       imageIndex
        //     ] = data?.data[0]?.url;
        //     return newArr;
        //   });
      })
      .catch(() => {
        // Handle error
      });
  };
  const getMonthName = (month) => {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUNE",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return monthNames[month - 1];
  };
  return (
    <div className="w-full h-full  bg-white rounded-lg py-7 px-3 ">
      <div className="flex items-center justify-center w-full  font-poppins">
        <div className="relative w-[95%] h-full rounded-tl-xl rounded-tr-xl bg-[#E0EFE899] border border-primary shadow-lg mb-10">
          <div className="rounded-tl-lg  rounded-tr-lg w-full p-4 flex justify-between items-center h-[15%] bg-primary">
            <div className="text-left text-center text-white">
              <p className="text-sm">
                {gov
                  ? `${selectedGovAgency?.merchUser?.merchcompany?.name} Debit Detail`
                  : `Sent: ${moment(data?.createdAt).format(
                      "MM/DD/YYYY hh:mm A"
                    )}`}
              </p>
            </div>
            <div className="text-right text-white">
              <p className="text-sm">
                AMOUNT:{" "}
                <span className="font-bold ">
                  $
                  {pensioner || gov
                    ? data?.totalAmount
                    : data?.amountPaid + data?.feePaid}
                </span>
              </p>
            </div>
          </div>
          <div className="w-full  gap-7 flex items-center justify-center flex-col">
            <div
              className={`${
                gov ? "hidden" : "w-[90%] mt-5  h-full flex rounded-full rounded-tr-none rounded-br-none sm:bg-white sm:flex-row flex-col items-center "
              }`}
            >
              <div className="h-[7rem] w-[7rem] rounded-full border-[5px] border-white overflow-hidden">
                <img
                  src={
                    data?.merchuser !== null
                      ? data?.merchuser?.merchcompany?.logo
                      : "------"
                  }
                  alt="Background"
                  className="object-cover w-full h-full"
                />
              </div>
              {/* <div className="bg-white w-full mt-3 p-2"> */}
              <div className="sm:w-[40%] w-full  sm:ml-4 h-full flex items-center border-r flex-col gap-y-1 pr-4 px-2 py-1">
                <div className=" flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Merchant Name :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    {data?.merchuser?.merchcompany !== null
                      ? data?.merchuser?.merchcompany?.name
                      : "------"}
                  </p>
                </div>
           
                <div className="flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Store :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    {data?.merchstore === null
                      ? "------"
                      : data?.merchstore?.customStoreName}
                  </p>
                </div>
              </div>
              <div className="sm:w-[40%] w-full sm:ml-4 h-full flex items-center flex-col gap-y-1 pr-4 px-2 py-1">
                {/* <div className={`${pensioner ? "hidden" : "flex w-full"}`}>
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Notes :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]"></p>
                </div> */}
                <div className={`${pensioner ? "hidden" : "flex w-full"}`}>
                  <h2 className="w-[50%] text-left font-semibold text-[13px] flex items-center">
                    Payment Id :
                    <p
                      className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                      onClick={() => {
                        setContent(
                          "Auto generated ID from MHSSS payment to the merchant."
                        );
                        setShow(true);
                      }}
                    >
                      i
                    </p>
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    {data?.transactionPaymentId}
                  </p>
                </div>
                <div className={`${pensioner ? "hidden" : "flex w-full"}`}>
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Voucher Cashing Fee :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px]">
                    ${data?.feePaid === null ? 0 : data?.feePaid}
                  </p>
                </div>
                {/* <div className={`${pensioner ? "hidden" : "flex w-full"}`}>
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Received On :
                  </h2>
                  <p className="w-[50%] text-right font-semibold flex justify-end text-[12px]">
                    <span className="p-1  rounded-xl bg-[#abe8e3] px-4 flex items-center justify-center text-primary">
                      {moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}
                    </span>
                  </p>
                </div> */}
              </div>
              {/* </div> */}
            </div>

            <div
              className={`${
                gov
                  ? "w-[90%] h-auto rounded-lg flex mt-5"
                  : "w-[90%] h-auto rounded-lg flex"
              }`}
            >
              <div
                className="w-full h-full flex rounded-full rounded-tr-none rounded-br-none sm:bg-white sm:flex-row flex-col items-center"
                style={{
                  // backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="h-[7rem] w-[7rem] rounded-full border-[5px] border-white overflow-hidden flex items-center justify-center">
                  <img
                    src={data?.user?.person?.image}
                    alt="Background"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="sm:w-[40%] w-full sm:ml-4 h-full flex items-center border-r flex-col gap-y-1 pr-4 px-2 py-1 ">
                  <div className="flex flex-wrap w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Pensioner Name :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px]">
                      {data?.user?.person?.firstName +
                        " " +
                        data?.user?.person?.lastName}
                    </p>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Phone Number :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {data?.user?.countryCode !== null
                        ? data?.user?.countryCode + " " + data?.user?.phoneNo
                        : "------"}
                    </p>
                  </div>
                  {/* <div className="flex flex-wrap w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Email Id :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {data?.user !== null ? data?.user?.email : "------"}
                    </p>
                  </div> */}
                  <div className="flex flex-wrap w-full justify-between">
                    <h2 className="text-left font-semibold text-[13px]">
                      Email Id :
                    </h2>
                    <p className="text-right font-semibold text-[12px] break-words">
                      {data?.user?.email !== null ? data?.user?.email : "------"}
                    </p>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      GID :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {data?.user !== null ? data?.user?.gid : "------"}
                    </p>
                  </div>
                </div>
                <div className="sm:w-[40%] w-full sm:ml-4 h-full flex items-center flex-col gap-y-1 pr-4 px-2 py-1">
                  <div
                    className={`${
                      pensioner
                        ? "hidden"
                        : "flex flex-wrap w-full justify-between"
                    }`}
                  >
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Program Name :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {data?.pensionbook?.pensionprogram?.name ===
                      "senior-citizen-Pension"
                        ? "Senior Citizen Pension"
                        : data?.pensionbook?.pensionprogram?.name}
                    </p>
                  </div>
                  <div className="flex w-full justify-between">
                    <h2 className="w-[60%] text-left font-semibold text-[13px] flex items-center ">
                      Voucher Transaction Id :
                      <span
                        className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer"
                        onClick={() => {
                          setContent(
                            "Auto generated transaction ID when Merchant redeemed pensioner’s vouchers."
                          );
                          setShow(true);
                        }}
                      >
                        i
                      </span>
                    </h2>
                    <p className="w-[40%] text-right font-semibold text-[12px] break-words">
                      {pensioner || gov
                        ? data?.transactionId
                        : data?.merchtransaction?.transactionId}
                    </p>
                  </div>

                  <div className="flex w-full">
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Voucher Cashing Date :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                      {data?.merchtransaction?.createdAt !== null &&
                        moment(data?.merchtransaction?.createdAt).format(
                          "MM/DD/YYYY hh:mm A"
                        )}
                    </p>
                  </div>
                  <div className={`${gov ? "flex w-full" : "hidden"}`}>
                    <h2 className="w-[50%] text-left font-semibold text-[13px]">
                      Payment Method :
                    </h2>
                    <p className="w-[50%] text-right font-semibold text-[12px] break-words capitalize">
                      {voucher?.[0] ? voucher?.[0]?.paymentMethod : "------"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                gov ? "w-[90%] rounded-md pb-2 pt-2 bg-white " : "hidden"
              }`}
            >
              <div className="sm:w-[40%] w-full sm:ml-4 h-full flex items-center flex-col gap-y-1 pr-4 p-2">
                <div className="flex w-full">
                  <h2 className="w-[50%] text-left font-semibold text-[13px]">
                    Vouchers Amount :
                  </h2>
                  <p className="w-[50%] text-right font-semibold text-[12px] break-words">
                    {"-----"}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <h2 className="w-[60%] text-left font-semibold text-[13px] flex items-center ">
                    voucher cashing fee :
                    <span
                      className="ml-1 w-[16px] h-[16px] text-center flex items-center justify-center bg-gray-300 text-semibold text-white rounded-full cursor-pointer"
                      onClick={() => {
                        setContent(
                          "Its a fee that has to be paid for vouchers."
                        );
                        setShow(true);
                      }}
                    >
                      i
                    </span>
                  </h2>
                  <p className="w-[40%] text-right font-semibold text-[12px] break-words">
                    {"----"}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-4 grid-cols-2 mb-4 sm:px-12 px-4 py-1 gap-4">
              {voucher?.length > 0 &&
                voucher?.map((item, index) => (
                  <div className="bg-white p-2 rounded-md">
                    <img src={item?.url} alt="" className="" />
                    <div className=" flex w-full">
                      <h2 className="w-[50%] text-left font-semibold text-[11px]">
                        Voucher No :
                      </h2>
                      <p className="w-[50%] text-right font-semibold text-[12px]">
                        {getMonthName(item?.coupon?.month)}{" "}
                        {moment(item?.coupon?.year).format("YYYY")}
                      </p>
                    </div>
                    <div className=" flex w-full">
                      <h2 className="w-[50%] text-left font-semibold text-[11px]">
                        Amount :
                      </h2>
                      <p className="w-[50%] text-right font-semibold text-[12px]">
                        ${item?.amountPaid}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>


          <div
            onClick={() => {
              printFunc({
                dataVoucher: data,
                pen: pensioner,
                voucher: voucher[0],
              }); // Pass the data object here
            }}
            className="absolute bottom-[-5px] cursor-pointer right-[-6px] w-[100px] h-[45px]  flex items-center justify-center"
            style={{
              backgroundImage: `url(${print})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <span className="text-primary flex items-center justify-center hover:text-[#0D6B5A] font-semibold">
              Print
            </span>
          </div>
        </div>
      </div>
      {show && <InfoModal content={content} setShow={setShow} />}
    </div>
  );
};

export default Main;
