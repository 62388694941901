import React from "react";
import GovAgencyVCash from "../components/MHSSS Accounting/govAgencies/govtAgencyDetails/voucherCashingDetails/index";

const Gov = () => {
  return (
    <div>
      <GovAgencyVCash />
    </div>
  );
};

export default Gov;
