import React, { useState } from "react";
import otp from "../../../../imgs/otp.svg";
import email from "../../../../imgs/email.svg";
import pin from "../../../../imgs/pin.svg";
import ques from "../../../../imgs/ques.svg";
import facer from "../../../../imgs/facer.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneVerification from "./Modals/PhoneVerification";
import FacialVerification from "./Modals/FacialVerification";
import QuestionVerification from "./Modals/QuestionVerification";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import axios from "axios";
import VerifyModal from "../../../Merchant/Modals/verifyModal";
const VerifyMain = ({ setShowConfirmModal }) => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location?.state?.data;
  const depositMoney = useSelector(
    (state) => state.pensionerSenderTypeVerification
  );
  const token = useSelector((state) => state.token);
  const [value, setValue] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const sapmoney = useSelector((state) => state.sapmoney);
  const sapPaymentSelectedPerson = useSelector(
    (state) => state.sapPaymentSelectedPerson
  );
  const [uuid, setuuid] = useState("");

  console.log(sapPaymentSelectedPerson);
  const verification = [
    {
      value: "otp",
      title: "OTP",
      text: "Validate the profile via Registered Phone",
      image: otp,
    },
    {
      value: "email",
      title: "Email Verification",
      text: "Validate the profile via Registered Email",
      image: email,
    },
    {
      value: "pin",
      title: "PIN Code",
      text: "Verify identity with customized PIN code",
      image: pin,
    },
    {
      value: "ques",
      title: "Security Question",
      text: "Verify the identity with the security question ",
      image: ques,
    },
    {
      value: "facer",
      title: "Via Facial Recognition",
      text: "Match candidate with real-time facial match",
      image: facer,
    },
  ];

  const sendOTP = () => {
    if (sapPaymentSelectedPerson?.phone === "-------") {
      setShowErrModal(true);
      setErr("We didn't find any phone number linked with this pensioner");
      return;
    }

    const api = depositMoney
      ? `v1/sap-wallet-info/merch-verify-pensioner/send-phone-otp?pensionerUserId=${sapPaymentSelectedPerson?.fromId}`
      : `v1/users/merch-send-phone-otp?userId=${sapPaymentSelectedPerson?.fromId}`;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setuuid(data?.data?.tempUUID);
        setValue("otp");
      })
      .catch(() => {
        setShowErrModal(true);
        setErr("Something went wrong, please try again later.");
      });
  };

  const sendEmail = () => {
    if (
      sapPaymentSelectedPerson?.email === "-------" ||
      !sapPaymentSelectedPerson?.email ||
      sapPaymentSelectedPerson?.email === ""
    ) {
      setShowErrModal(true);
      setErr("We didn't find any email address linked with this pensioner");
      return;
    }

    const api = depositMoney
      ? `v1/sap-wallet-info/merch-verify-pensioner/send-email-otp?pensionerUserId=${sapPaymentSelectedPerson?.fromId}`
      : `v1/users/merch-send-email-otp?userId=${sapPaymentSelectedPerson?.fromId}`;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setuuid(data?.data?.tempUUID);
        setValue("email");
      })
      .catch((err) => {
        setShowErrModal(true);
        setErr(err.response.data.message);
      });
  };

  return (
    <div className={`${sapmoney ? "mt-3" : "sm:mt-[-2rem] mt-3"} `}>
      <div className="sm:mx-4 mb-12">
        <div className="sm:p-5 p-3 bg-white  rounded-lg">
          <div className="w-full  mb-10 relative">
            <div
              className={`flex items-center justify-between p-3 rounded-lg mb-4 cursor-pointer 
               bg-[#D9EFED]
                
             `}
            >
              <div className="flex items-center">
                <div className="ml-4">
                  <h3 className={`text-lg font-semibold mb-1 text-gray-700`}>
                    Verify the Pensioner
                  </h3>
                </div>
              </div>
            </div>
            {verification?.map((item, index) => (
              <div
                key={index}
                onClick={() =>
                  item?.value === "otp"
                    ? sendOTP()
                    : item?.value === "email"
                    ? sendEmail()
                    : setValue(item.value)
                }
                className={`flex items-center justify-between p-3 rounded-lg mb-4 cursor-pointer 
               
                ${item.value === value ? "bg-primary" : "bg-[#F5F5F5]"}`}
              >
                <div className="flex items-center">
                  <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                    <img src={item.image} alt="vc" className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3
                      className={`text-lg font-semibold mb-1 ${
                        item.value === value ? "text-white" : "text-primary"
                      }`}
                    >
                      {item.title}
                    </h3>
                    <p
                      className={`text-xs ${
                        item.value === value ? "text-white" : "text-gray-600"
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <button
            className="bg-primary text-white w-32 py-2 rounded-lg"
            onClick={() => {
              router("/SapMoney/Parent/Respond/Child", {
                state: { data: data, merchantSideChildReq: true },
              });
            }}
          >
            Next
          </button> */}
        </div>
      </div>
      {value === "otp" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
          uuid={uuid}
          setShowConfirmModal={setShowConfirmModal}
        />
      )}
      {value === "email" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
          uuid={uuid}
          setShowConfirmModal={setShowConfirmModal}
        />
      )}
      {value === "pin" && (
        <PhoneVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
          setShowConfirmModal={setShowConfirmModal}
        />
      )}
      {value === "facer" && (
        <FacialVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
          setShowConfirmModal={setShowConfirmModal}
        />
      )}
      {value === "ques" && (
        <QuestionVerification
          setValue={setValue}
          value={value}
          setShowErrModal={setShowErrModal}
          err={err}
          setErr={setErr}
          setShowSuccessModal={setShowSuccessModal}
          setShowConfirmModal={setShowConfirmModal}
        />
      )}
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
      {showSuccessModal && (
        <VerifyModal
          setShow={setShowSuccessModal}
          err={err}
          setValue={setValue}
          verify={depositMoney ? "MerchantResponse" : "reqListing"}
          data={data}
        />
      )}
    </div>
  );
};

export default VerifyMain;
