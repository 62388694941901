import back5 from "../../../imgs/back5.png";
import aactive from "../../../imgs/Aactive.png";
import inactive from "../../../imgs/Iactive.png";
import Uactive from "../../../imgs/Uactive.png";
import Uinative from "../../../imgs/Uinactive.png";
import Pactive from "../../../imgs/Pactive.png";
import Pinactive from "../../../imgs/Pinactive.png";
import { useNavigate } from "react-router-dom";
// import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import Paget from "./Table"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import axios from "axios";
// let wow;
// let set;
const Listingh = () => {
  const router = useNavigate();
  const [active, setActive] = useState("1");
  // const [show, setShow] = useState(false);
  // const [test, setTest] = useState(false);
  // const gidFilter = useSelector((state) => state.gidFilter);
  // const dataUser = useSelector((state) => state.dataUser);
  const privilege = useSelector((state) => state.myPrivileges)
  // const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const user = "employee"
  // const [count, setCount] = useState({
  //   active: 0,
  //   inactive: 0,
  //   all: 0
  // })
  useEffect(() => {
    dispatch({ type: "gidFilterSet", num: "" })
  }, [])

  const items = [
    {
      key: "1",
      activeIcon: Uactive,
      inactiveIcon: Uinative,
      label: `All Pensioners`,
      count: 90
    },
    {
      key: "2",
      activeIcon: aactive,
      inactiveIcon: Pactive,
      label: `Active Pensioners`,
      count: 46
    }, {
      key: "3",
      activeIcon: inactive,
      inactiveIcon: Pinactive,
      label: `Inactive Pensioners`,
      count: 44
    }
  ];

  // const showHandler = (check) => {
  //   if (check === "apply") {
  //     dispatch({ type: "phoneFilterSet", num: wow });
  //   } else if (check === "cancel") {
  //     wow = "";
  //     dispatch({ type: "phoneFilterSet", num: "" });
  //   }
  //   if (!show) {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }
  // };

  // const changeHandler = (e) => {

  //   dispatch({ type: "gidFilterSet", num: e.target.value });
  // };
  // const change1Handler = (event, f) => {

  //   if (event === null) {
  //     wow = "";
  //   } else {
  //     set = event;
  //     wow = f;
  //   }
  //   if (!test) {
  //     setTest(true);
  //   } else {
  //     setTest(false);
  //   }
  // };

  //   useEffect(() => {
  //     axios.get(`${process.env.REACT_APP_BASE_URL}/v1/users/role-counts?role=pensioner`, {
  //       headers: {
  //         "Authorization": `Bearer ${dataUser.data.tokens.access.token}`
  //       }
  //     })
  //       .then((data) => {
  //         console.log(data)
  //         setCount({
  //           active: data?.data?.active,
  //           inactive: data?.data?.inactive,
  //           all: data?.data?.total
  //         })
  //       }).catch((err) => {
  //         console.log(err)
  //       })
  //   }, [])

  return (
    <div className="">
      <div className="sm:p-10 sm:pl-[5rem] sm:pr-[5rem] mt-[1rem] sm:mt-[0rem]" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div className="relative flex justify-between items-center mt-1">
            <div
              onClick={() => router("/Merchant/dashboard")}
              className="absolute top-[2.8] cursor-pointer sm:left-[-3.5%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] sm:ml-0 ml-[2rem] text-[1.4rem] tab:ml-[1rem]">
              Pensioners
            </h2>
            {
              user === "employee" && (privilege && !privilege?.includes("Create pensioner")) ? null : (<button
                onClick={() => {
                  dispatch({ type: "ISET", num: 0 });
                  dispatch({ type: "PENSIONER" });
                  router("/EDashboard/addPensioner");
                }}
                className={`
               
                  pointer-events-none
                 
              text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
              >
                Create Pensioner
              </button>)
            }

          </div>
          {/* className="text-indigo-500 w-12 h-12 mb-3 inline-block"  */}
          {/* sm:flex-row  flex-col sm:ml-0 flex ml-[4rem] w-full sm:justify-between mt-[3rem] mb-8 */}
          <section className="text-gray-700 body-font mt-[2rem] ">
            <div className="container">
              <div className="flex flex-wrap text-center">
                {items.map((item, index) => (
                  <div className="p-4 md:w-1/3 sm:w-1/2 w-full cursor-pointer" onClick={()=>{
                    setActive(item.key)
                  }}>
                    <div className={`${item.key === active ? "bg-primary text-white" :"bg-[#F4F5FE]"} border-4 border-green-100  shadow-md px-2 py-4 rounded-lg transform transition duration-500 hover:scale-100`}>
                      <img src={item.key === active ?  item?.inactiveIcon : item.activeIcon } alt={item?.label} className="mb-1 inline-block"/>
                      <h2 className={`${item.key === active ? "text-white" :"text-gray-900"}title-font font-medium text-3xl `}>{item?.count}</h2>
                      <p className="leading-relaxed">{item?.label}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>

      </div>
      <Paget />
    </div>
  );
};

export default Listingh;
