import { Pagination } from "antd";
import { useState } from "react";
const PensionerTable = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  setTotalPages(1);
  const [search, setSearch] = useState("");
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const columnHeaders = [
    "SR.",
    "Driver Name",
    "Driver Contact",
    "Vehicle Manufacturer",
    "Vehicle Model",
    "Vehicle Year",
    "License Plate",
    "Vehicle Location",
    "Mileage Out",
    "Gas Level",
    "From",
    "To",
    "Return",
    "Stop",
    "Start",
    "Mileage In",
    "Trips Route",
  ];

  const tripData = [
    {
      DriverName: "John Doe",
      DriverContact: "555-1234",
      VehicleManufacturer: "Toyota",
      VehicleModel: "Camry",
      VehicleYear: 2020,
      LicensePlate: "ABC123",
      VehicleLocation: "New York",
      MileageOut: 15000,
      GasLevel: "Full",
      From: "New York Office",
      To: "Boston Office",
      Return: "2024-05-28",
      Stop: "Rest Area",
      Start: "2024-05-28",
      MileageIn: 15200,
      TripsRoute: ["New York Office", "Rest Area", "Boston Office"],
    },
    {
      DriverName: "Alice Johnson",
      DriverContact: "555-5678",
      VehicleManufacturer: "Ford",
      VehicleModel: "F-150",
      VehicleYear: 2019,
      LicensePlate: "DEF456",
      VehicleLocation: "Los Angeles",
      MileageOut: 30000,
      GasLevel: "3/4",
      From: "Los Angeles Office",
      To: "San Diego Office",
      Return: "2024-05-29",
      Stop: "Gas Station",
      Start: "2024-05-29",
      MileageIn: 30250,
      TripsRoute: ["Los Angeles Office", "Gas Station", "San Diego Office"],
    },
    {
      DriverName: "Charlie Davis",
      DriverContact: "555-8765",
      VehicleManufacturer: "Tesla",
      VehicleModel: "Model S",
      VehicleYear: 2021,
      LicensePlate: "GHI789",
      VehicleLocation: "San Francisco",
      MileageOut: 5000,
      GasLevel: "Full",
      From: "San Francisco Office",
      To: "Sacramento Office",
      Return: "2024-05-30",
      Stop: "Charging Station",
      Start: "2024-05-30",
      MileageIn: 5150,
      TripsRoute: [
        "San Francisco Office",
        "Charging Station",
        "Sacramento Office",
      ],
    },
    {
      DriverName: "Eve Foster",
      DriverContact: "555-4321",
      VehicleManufacturer: "BMW",
      VehicleModel: "X5",
      VehicleYear: 2018,
      LicensePlate: "JKL012",
      VehicleLocation: "Chicago",
      MileageOut: 45000,
      GasLevel: "1/2",
      From: "Chicago Office",
      To: "Detroit Office",
      Return: "2024-05-31",
      Stop: "Rest Area",
      Start: "2024-05-31",
      MileageIn: 45350,
      TripsRoute: ["Chicago Office", "Rest Area", "Detroit Office"],
    },
    {
      DriverName: "Grace Hill",
      DriverContact: "555-0987",
      VehicleManufacturer: "Chevrolet",
      VehicleModel: "Bolt EV",
      VehicleYear: 2022,
      LicensePlate: "MNO345",
      VehicleLocation: "Houston",
      MileageOut: 1000,
      GasLevel: "Full",
      From: "Houston Office",
      To: "Dallas Office",
      Return: "2024-06-01",
      Stop: "Charging Station",
      Start: "2024-06-01",
      MileageIn: 1200,
      TripsRoute: ["Houston Office", "Charging Station", "Dallas Office"],
    },
    {
      DriverName: "Ian Jones",
      DriverContact: "555-6543",
      VehicleManufacturer: "Honda",
      VehicleModel: "Civic",
      VehicleYear: 2017,
      LicensePlate: "PQR678",
      VehicleLocation: "Miami",
      MileageOut: 35000,
      GasLevel: "5/8",
      From: "Miami Office",
      To: "Orlando Office",
      Return: "2024-06-02",
      Stop: "Gas Station",
      Start: "2024-06-02",
      MileageIn: 35350,
      TripsRoute: ["Miami Office", "Gas Station", "Orlando Office"],
    },
  ];

  const pageHandler = (e) => {
    setPage(e);
  };

  return (
    <section className="p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by Vehicle"
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto"></div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold whitespace-nowrap tracking-wider"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {tripData.length === 0 ? (
                <h1 className="text-center text-[1.1rem] p-5">No Data Found</h1>
              ) : (
                <tbody>
                  {tripData.map((data, index) => (
                    <tr
                      key={index}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                      onClick={() => {}}
                    >
                      <th
                        scope="row"
                        className="px-4 py-5 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {index + 1 + (page - 1) * 6}.
                      </th>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.DriverName}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.DriverContact}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VehicleManufacturer}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VehicleModel}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VehicleYear}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.LicensePlate}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.VehicleLocation}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.MileageOut}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.GasLevel}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.From}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">{data.To}</td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Return}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Stop}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.Start}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.MileageIn}
                      </td>
                      <td className="px-4 py-5 whitespace-nowrap">
                        {data.TripsRoute.join(", ")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default PensionerTable;
