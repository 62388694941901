import { useEffect } from "react";
import First from "./stepsBR/FirstBR";
import ThirdR from "../Register/stepsR/ThirdR";
import FifthR from "../Register/stepsR/FifthR";
import { useDispatch, useSelector } from "react-redux";
import Mid from "../Register/stepsR/mid";

const BenificiaryR = () => {
  const brcurrent = useSelector((state) => state.brcurrent);
  // useEffect(() => {
  //   dispatch({ type: "BRSET", num: 0 });
  // }, []);

  const dispatch = useDispatch();
  const Show = [First, Mid, ThirdR, FifthR];
  const Dis = Show[brcurrent];
  return (
    <div>
      <div>
        <Dis />
      </div>
    </div>
  );
};

export default BenificiaryR;
