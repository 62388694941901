import React from "react";
import PageHeader from "../pageHeader/pageHeader";
import DetailScreen from "./detailScreen";

const DetailIndex = () => {
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader header={true} title={"Priority Request Detail"} route={-1} />

      <div className="flex justify-center">
        <div className={`w-[95%] mx-5`}>
          <DetailScreen />
        </div>
      </div>
    </div>
  );
};

export default DetailIndex;
