import { Pagination, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import profile from "../../../imgs/profile.png";
import ErrorModal from "../../Merchant/Modals/errorModal";
const Paget = ({ allData, page, setPage, totalPages }) => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const search = "";
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_BANK_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_CHEQUE_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_EWALLET_INFO",
      num: null,
    });
  }, []);

  const columnHeaders = [
    "SR.",
    "Merchant Name",
    "Merchant ID",
    "Phone",
    "Email",
    "Gender",
    "Date of Birth",
    "Status",
    "Created Date/Time",
  ];

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  return (
    <>
      <section className="w-full">
        <div className="mx-auto max-w-screen-xl ">
          <div className="relative sm:rounded-lg overflow-hidden">
            {/* table */}
            <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader) => (
                      <th
                        scope="col"
                        className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>
                {allData?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="9"
                        className="text-center text-gray-500 text-lg"
                      >
                        <p className="p-10">No data found</p>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {allData.map((data, index) => (
                      <tr
                        onClick={() => {
                          if (data?.merchperson === null) {
                            setError("Incomplete Merchant Profile");
                            setShowError(true);
                            return;
                          }
                          dispatch({
                            type: "SELECTED_MERCHANT_FOR_APPROVAL_ID",
                            num: data.id,
                          });
                          // if (dash === "/superAdminDashboard/Merchant/Listing")
                          //   router("/superAdminDashboard/Merchant/Profile");
                          // else if (dash === "/adminDashboard/Merchant/Listing")
                          //   router("/adminDashboard/Merchant/Profile");
                          // else if (dash === "/EDashboard/Merchant/Listing")
                          //   if (
                          //     privilege &&
                          //     !privilege?.includes(
                          //       "View merchant detailed profile"
                          //     )
                          //   ) {
                          //     setError(
                          //       "You do not have the privilege to view merchant details"
                          //     );
                          //     setShowError(true);
                          //     return;
                          //   }
                          // router("/EDashboard/Merchant/Profile");
                          router("/MHSSS/Merchant/Profile");
                        }}
                        className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                      >
                        <th
                          scope="row"
                          className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                        >
                          {search
                            ? index + 1 + (pagewithsearch - 1) * 6
                            : index + 1 + (page - 1) * 6}
                          .
                        </th>
                        <td className="px-4 py-3 whitespace-nowrap capitalize">
                          <div className="flex items-center">
                            <img
                              className="w-10 ml-0  h-10 rounded-full mr-2 border-2 border-primary "
                              src={
                                data?.merchcompany !== null
                                  ? data?.merchcompany?.logo
                                  : profile
                              }
                              alt={`pro`}
                            />
                            <p
                              className="mr-6"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {/* {data?.merchperson !== null
                                ? `${data?.merchperson?.firstName} ${
                                    data?.merchperson?.lastName ?? ""
                                  }`
                                : "----"} */}
                              {data?.merchcompany !== null
                                ? data?.merchcompany?.name
                                : "----"}
                            </p>
                          </div>
                        </td>
                        {/* <td className="px-4 py-3 whitespace-nowrap capitalize">
                          <div>
                            {data?.merchperson !== null
                              ? `${data?.merchperson?.firstName} ${
                                  data?.merchperson?.lastName ?? ""
                                }`
                              : "----"}
                          </div>
                        </td> */}
                        <td className="px-6  py-5">
                          <Popover content={data.uniqueId}>
                            <div className="inline-block flex items-center justify-center max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                              {data.uniqueId.substring(0, 5)}..
                            </div>
                          </Popover>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.countryCode !== null
                            ? `${data?.countryCode} ${data?.phoneNo ?? ""}`
                            : "----"}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.email}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data?.merchperson?.gender}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {dayjs(data?.dateOfBirth)?.format("YYYY-MM-DD")}
                        </td>

                        {/* <td className="px-4 py-3 whitespace-nowrap">
                          {data.Store}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data.location}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {data.Pensioner}
                        </td>

                        <td className="px-4 py-3 whitespace-nowrap">
                          {data.PaymentMode}
                        </td> */}

                        <td className="px-4 py-3 whitespace-nowrap">
                          <Popover
                            content={`${
                              data?.status === "pending" ? "In-Process" : ""
                            }`}
                            placement="bottomLeft"
                          >
                            <div
                              className={`border-none ${
                                data?.status === "pending"
                                  ? "bg-[#2F84F1]"
                                  : data?.status === "rejected"
                                  ? "bg-[#D64242]"
                                  : " bg-primary"
                              }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
                            >
                              {data?.status}
                            </div>
                          </Popover>
                        </td>
                        <td className="px-4 py-3">
                          <p>{moment(data?.createdAt).format("MM/DD/YYYY")}</p>
                          <p> {moment(data?.createdAt).format("hh:mm A")}</p>
                        </td>
                        {/* <td className="px-4 py-3 whitespace-nowrap">
                          <Dropdown
                            menu={{
                              items,
                              // onClick: (key) => onClick(key, item),
                            }}
                            trigger={["click"]}
                            onClick={(e) => {
                              // Stop the propagation of the click event
                              e.stopPropagation();
                            }}
                          >
                            <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                              <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                            </div>
                          </Dropdown>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <nav
              className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 ">
                Showing{" "}
                <span className="font-semibold text-gray-900 ">
                  Page {page}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 ">
                  {totalPages}
                </span>
              </span>
              <div className={`flex justify-end mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </nav>
          </div>
        </div>
      </section>
      {showError && <ErrorModal err={error} setShow={setShowError} />}
    </>
  );
};

export default Paget;
