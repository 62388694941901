import { Checkbox, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
// import store1 from "../../../../imgs/store1.svg";
const StoreModal = ({
  setShow,
  selectedStore,
  setShowerrorModal,
  setErr,
  setShowsuccessModal,
  refetch,
  setRefetch,
}) => {
  const token = useSelector((state) => state.token);
  const merchantEmployee = useSelector((state) => state.merchantEmployee);
  const [privileges, setPrivileges] = useState([]);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  //Get privileges list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges/all-merch-privileges`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPrivileges(data?.data);
      })

      .catch(() => {});
  }, []);

  const linkStore = () => {
    setShow(false);
    var body;

    body = {
      stores: [
        {
          merchstoreId: selectedStore?.id,
          role: selectedRole,
        },
      ],
    };
    // }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/by-merchant?merchUserId=${merchantEmployee?.data?.merchUser?.id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShowsuccessModal(true);
        setErr("Store Linked Successfully");
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowerrorModal(true);
      });
  };

  const assignedPrivileges = () => {
    if (selectedRole === null) {
      setErr("Please Select Role");
      setShow(true);
    } else {
      // if (!selectedPrivileges.includes("View pensioner")) {
      //   setErr("Please Select View Pensioner Privilege");
      //   setShow(true);
      // } else {
      setSelectedPrivileges(selectedPrivileges.filter((item) => item !== ""));
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges`,
          {
            merchEmployeeUserId: merchantEmployee?.data?.merchUser?.id,
            privileges: selectedPrivileges,
            merchStoreId: selectedStore?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          linkStore();
        })
        .catch((err) => {
          setErr(err?.message);
          setShow(true);
        });
    }
    // }
  };

  //Select Multiple Priviliges
  const togglePrivilege = (privilege) => {
    // Check if the privilege is already in the selectedPrivileges array
    if (selectedPrivileges.includes(privilege)) {
      // If it's already selected, remove it
      setSelectedPrivileges(
        selectedPrivileges.filter((item) => item !== privilege)
      );
    } else {
      // If it's not selected, add it
      setSelectedPrivileges([...selectedPrivileges, privilege]);
    }
  };

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative  w-full rounded-lg bg-white max-w-md max-h-full overflow-auto h-[90%]">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-gray-900 ">
              Link Store
            </h3>
            <button
              onClick={() => {
                setShow(false);
              }}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className
          ="p-4 md:p-5">
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Roles/Position:
            </h2>
            <div>
              <Select
                placeholder="Select Role/Position"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                onChange={(val) => setSelectedRole(val)}
                size="middle"
                options={[
                  { value: "Manager", label: "Manager" },
                  { value: "Financial", label: "Financial" },
                  { value: "Sales Clerk", label: "Sales Clerk" },
                  { value: "Customer Support", label: "Customer Support" },
                  { value: "Staff", label: "Staff" },
                  { value: "Operations", label: "Operations" },
                ]}
              />
            </div>
            <h2 className="text-[#596F96] text-[1rem] font-semibold mb-4">
              Assign Privileges
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  gap-4">
              {privileges &&
                privileges.map((privilege) => (
                  <div
                    key={privilege}
                    className="p-2 flex items-center rounded-md hover:bg-gray-100 transition duration-300 ease-in-out bg-white"
                  >
                    <Checkbox
                      type="checkbox"
                      className="text-slate-500 text-sm font-semibold text-gray-700 flex items-center"
                      checked={selectedPrivileges.includes(privilege)}
                      onChange={() => togglePrivilege(privilege)}

                      // checked={singlePrivilege === privilege}
                    >
                      {privilege}
                    </Checkbox>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex justify-center pb-6">
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              Cancel
            </button>
            <button
              onClick={() => assignedPrivileges()}
              className="ml-5 cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreModal;
