import Nav from "../components/Nav/nav";
import Listingb from "../components/Dash/Test/Listing/listingb";
import Whatsapp from "../Whatsapp/whatsapp";
const BeneficiaryP = () => {
  return (
    <div>
      <Nav />
      <Listingb />
      <Whatsapp/>
    </div>
  );
};

export default BeneficiaryP;
